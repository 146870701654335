import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { CANDIDATE_MICROSERVICE } from "Constants";
import { CHECK_FOR_SSN_OR_EMAIL_EXISTANCE } from "../../../../../routes/ApiEndpoints";
import { ApiCall } from "components/common/services/ApiServices";
import { t } from "components/CentralDataMangement/translation/Translation";

export const useSSNorEmailValidation = () => {
  const navigate = useNavigate();
  const [SSNorEmailModal, setSSNorEmailModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [emailOrSSNModalBody, setEmailOrSSNModalBody] = useState("");
  const [canId, setCanId] = useState<string | null>(null);

  const closeModal = () => setSSNorEmailModal(false);

  const handleCandidate = () => {
    if (canId) {
      navigate(`/candidate/edit/${canId}/?tabId=general`);
      window.location.reload();
      setSSNorEmailModal(false);
    }
  };

  const checkForSSNorEmailExistence = async (
    email: string = "",
    ssn: string = ""
  ) => {
    const emailAndSSNData = {
      ssn: ssn.replace(/[.-]/g, ""),
      email,
    };

    const response = await ApiCall.service(
      `${CHECK_FOR_SSN_OR_EMAIL_EXISTANCE}`,
      "POST",
      emailAndSSNData,
      true,
      CANDIDATE_MICROSERVICE
    );

    if (response.status === 200 && response.msg) {
      setCanId(response.candidateId);
      setEmailOrSSNModalBody(response.msg);
      setModalTitle(
        response.candidateId
          ? t("Candidate already exists")
          : t("User already exists")
      );
      setSSNorEmailModal(true);
    }
  };

  return {
    canId,
    SSNorEmailModal,
    modalTitle,
    emailOrSSNModalBody,
    closeModal,
    handleCandidate,
    checkForSSNorEmailExistence,
  };
};
