import { t } from "components/CentralDataMangement/translation/Translation";
import CheckBoxField from "components/common/atoms/CheckBoxField";
import LabelField from "components/common/atoms/LabelField";
import RadioField from "components/common/atoms/RadioField";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import { Carousel } from "react-bootstrap";
import PhoneInputWithCountrySelect from "react-phone-number-input";
export const FormElements = (
  field: any,
  handleChange: any,
  formData: any,
  errors: any,
  formName: string,
  disableField: Object[]
) => {
  const { type, value } = field;
  switch (type) {
    case "text":
    case "string":
      return (
        <div className={value.className ? value.className : "col-sm-12 col-md-6"}>
          <LabelWithInputField
            label={t(value.label)}
            name={value.name}
            id={field.name}
            type={value.fieldtype ?? 'text'}
            placeholder={t(value.placeholder)}
            handleChange={handleChange}
            isMandatory={value.required}
            value={formData[value.name] ?? ''}
            error={errors[value.name]}
          />
        </div>
      );
    case "number":
      return (
        <div className={value.className ? value.className : "col-sm-12 col-md-6 "}>
          <LabelWithInputField
            label={t(value.label)}
            name={value.name}
            id={field.name}
            type={value.fieldtype ?? 'number'}
            placeholder={t(value.placeholder)}
            handleChange={handleChange}
            isMandatory={value.required}
            value={formData[value.name] ?? ''}
            error={errors[value.name]}
          />
        </div>
      );
    case "multi-select":
      return (
        <div className={value.className ? value.className : "col-sm-12 col-md-6"}>
          <SelectWithSearch
            search={value.search}
            onChange={handleChange}
            title={value.label}
            options={field.value.options}
            placeHolder={t("Select")}
            isMulti={value.isMultiSelect}
            name={value.name}
            isMandatory={value.required}
            isDisabled={disableField.includes(value.name)}
            error={errors[value.name]}
            id={field.name}
            value={formData[value.name]}
          />
        </div>
      );
    case "mobile-number":
      return (
        <div className={value.className ? value.className : "col-sm-12 col-md-6"}>
          <LabelField
            title={t("Phone number")}
            isMandatory={true}
            key="PhoneInput"
            htmlfor={field.name}
          />
          <div className="form-control rounded-0 shadow-none">
            <PhoneInputWithCountrySelect
              name={value.name}
              label={value.label}
              required={value.required}
              defaultCountry="BE"
              initialValueFormat="national"
              placeholder={t("Enter phone number")}
              onChange={handleChange}
              value={formData[value.name]}
              id={field.name}
            />
          </div>
          <div className="height-20" style={{marginBottom: "0.5vw"}}>
            {errors[value.name] && (
              <span className="text-danger">{errors[value.name]}</span>
            )}
          </div>
        </div>
      );

    case "status":
      return (
        <div className={value.className ? value.className : "col-sm-12 col-md-6"}>
          <div className="col-sm-12 col-md-6">
            <LabelField
              title={t(value.label)}
              isMandatory={value.required}
              key="Status"
              htmlfor={field.name}
            />
            <div className="row">
              <div className="col-md-12">
                {field.value.options.map((statusValue: any) => (
                  <RadioField
                    key={statusValue.value}
                    name={value.name}
                    value={statusValue.value}
                    ischecked={
                      formData[value.name] === statusValue.value ? true : false
                    }
                    handleChange={handleChange}
                    label={statusValue.label}
                    className={statusValue.value === 1 ? "me-3" : ""}
                    id={field.name}
                  />
                ))}
              </div>
              <div className="height-20" style={{marginBottom: "0.5vw"}}>
                {errors[value.name] && (
                  <span className="text-danger">{errors[value.name]}</span>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    case "checkbox":
      return (
        <div className="col-sm-12 col-md-6 col-lg-3 hotlistWrapper">
          <CheckBoxField
            label={value.label}
            name={value.name}
            onChangeHandler={handleChange}
            value={formData[value.name]}
            isChecked={formData[value.name] === 1}
            id={field.name}
            lineHeight="27px"
          />
        </div>
      )
    default:
      return null;
  }
};

