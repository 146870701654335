import Title from "components/common/atoms/Title";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import React, { useEffect, useState } from "react";
import { t, translate } from "../translation/Translation";
import { Link, useParams } from "react-router-dom";
import { ApiCall } from "components/common/services/ApiServices";
import { VACANCY_DETAILS } from "routes/ApiEndpoints";
import { VacancyOverviewData } from "./annotation/VacancyInterfaces";

const VacancyOverview: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [vacancy, setVacancy] = useState<VacancyOverviewData>({
    function: "",
    office: "",
    jobdomain: "",
    statute: "",
    employment: "",
    jobOffer: "",
    jobDesc: "",
    profileDesc: "",
    contactPerson: "",
  });

  const fetchVacancyDetails = async () => {
    const data = {
      type: "vacancyCorner",
    };

    const url = id ? `${VACANCY_DETAILS}/${id}` : `${VACANCY_DETAILS}`;
    const response = await ApiCall.service(
      url,
      "POST",
      data,
      true,
      "central-data-management"
    );
    if (response.status === 200) {
      setVacancy(response.data);
    }
  };

  useEffect(() => {
    fetchVacancyDetails();
  }, []);

  const renderContent = (content: string | null) => {
    return content ? (
      <div dangerouslySetInnerHTML={{ __html: content }} />
    ) : (
      <span>{t("N/A")}</span>
    );
  };

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Vacancy",
          read: true,
        },
      ]}
      renderNoAccess={true}
    >
      <Title title={t("Vacancy overview")} />
      <div className="row">
        <div className="col-lg-3 col-5">
          <div className="vacancyCornerLeftSection h-100">
            <div className="tab-subtitle">{t("Details")}</div>
            <div style={{ paddingTop: "1vw", paddingBottom: "1vw" }}>
              <div style={{ marginBottom: "1vw" }}>
                <span>{t("Function") + ":"}</span>
                <span className="personal-info ms-2">
                  {t(vacancy.function) ?? t("N/A")}
                </span>
              </div>
              <div style={{ marginBottom: "1vw" }}>
                <span>{t("Job domain") + ":"}</span>
                <span className="personal-info ms-2">
                  {t(vacancy.jobdomain) ?? t("N/A")}
                </span>
              </div>
              <div style={{ marginBottom: "1vw" }}>
                <span>{t("Employment") + ":"}</span>
                <span className="personal-info ms-2">
                  {t(vacancy.employment) ?? t("N/A")}
                </span>
              </div>
              <div style={{ marginBottom: "1vw" }}>
                <span>{t("Employee type") + ":"}</span>
                <span className="personal-info ms-2">
                  {t(vacancy.statute) ?? t("N/A")}
                </span>
              </div>
              <div style={{ marginBottom: "1vw" }}>
                <span>{t("Reference") + ":"}</span>
                <span className="personal-info ms-2">
                  {vacancy.office ?? t("N/A")}
                </span>
              </div>
            </div>
            <div className="tab-subtitle">{t("Contact")}</div>
            <div className="vacancyContactPerson">
              {vacancy.contactPerson ?? t("N/A")}
            </div>
          </div>
        </div>
        <div className="col-xxl-9 col-xl-8 col-7">
          <div className="vacancySubTitle">{t("Job description")}</div>
          <div className="mt-3">{renderContent(vacancy.jobDesc)}</div>
          <div className="vacancySubTitle mt-4">{t("Our offer")}</div>
          <div className="mt-3">{renderContent(vacancy.jobOffer)}</div>
          <div className="vacancySubTitle mt-4">{t("This is you")}</div>
          <div className="mt-3">{renderContent(vacancy.profileDesc)}</div>
        </div>
      </div>
      <div className="row backPadding">
        <div className="col-md-6 align-self-center ">
        <Link
          to="/manage/vacancies"
          className=" back-btn text-decoration-underline"
        >
          {t("Back")}
        </Link>
      </div>
      </div>
      
    </AccessControl>
  );
};

export default translate(VacancyOverview);
