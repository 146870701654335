import React, { ReactNode, MouseEventHandler, CSSProperties } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
interface Props {
  title?: ReactNode;
  icon?: IconProp;
  handleClick?: MouseEventHandler<HTMLButtonElement>;
  style?: CSSProperties;
  className?: string;
  disabled?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
  children?: React.ReactNode;
  toolTipName?: string;
  titleClassName?: string;
}

const Button: React.FC<Props> = ({
  title,
  handleClick,
  className = "",
  toolTipName = "",
  disabled = false,
  type = "button",
  style = {},
  icon,
  children,
  titleClassName,
}) => {
  return (
    <>
      <button
        type={type}
        className={className}
        onClick={handleClick}
        disabled={disabled}
        title={toolTipName}
        style={style}
      >
        <span className={`${titleClassName}`}>{title}</span>
        {icon && <FontAwesomeIcon icon={icon} />}{" "}
        {/* Render the icon if it exists */}
        {children}
      </button>
    </>
  );
};

export default Button;
