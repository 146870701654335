import React, { useState } from "react";
import { useFormContext } from "../../context/Context";
import Navigation from "../form-navigation/Navigation";
import { ChildCompetence, Options } from "../../annotation/VacancyInterfaces";
import { Accordion } from "react-bootstrap";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import HelpInfoIcon from "static/images/HelpInfo";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import { UPDATE_CHILD_COMPETENCES } from "../../annotation/VacancyConstants";
import { scrollToTop } from "components/common/services/ValidationService";
import CustomRangeSlider from "components/common/atoms/RangeSlider";

const VacancyCompetences = () => {
  const { state, dispatch } = useFormContext();
  const [validationStatus, setValidationStatus] = useState({
    isValid: false,
    type: "",
  });

  const validStatus = (validation: any) => {
    setValidationStatus({
      isValid: validation.isValid,
      type: validation.type,
    });
  };

  const handleChildCompChange = (
    competenceIndex: number,
    selectedChildCompetences: Options[],
    competenceName: string
  ) => {
    const updatedCompetences = state.competences?.competence?.map(
      (competence, cIndex) => {
        if (cIndex === competenceIndex) {
          const updatedChildCompetences: ChildCompetence[] = [];

          selectedChildCompetences.forEach((selectedComp) => {
            const existingChildComp = competence.vacancy_child_competences.find(
              (childComp) =>
                childComp.name === selectedComp.value &&
                childComp.vacancy_competence_detail?.range !== -1
            );
            if (existingChildComp) {
              if (
                existingChildComp.vacancy_competence_detail &&
                existingChildComp.vacancy_competence_detail?.range === -1
              ) {
                existingChildComp.vacancy_competence_detail.range = 0;
              }
              updatedChildCompetences.push(existingChildComp);
            } else {
              updatedChildCompetences.push({
                name: selectedComp.value,
                vacancy_competence_detail: {
                  name: "",
                  key: "",
                  level: null,
                  range: 1,
                  competence_levels: [],
                },
              });
            }
          });

          return {
            ...competence,
            vacancy_child_competences: updatedChildCompetences,
          };
        }
        return competence;
      }
    );

    dispatch({
      type: UPDATE_CHILD_COMPETENCES,
      field: "competence",
      value: updatedCompetences,
    });
  };

  const handleInputChange = (
    competenceIndex: number,
    subIndex: number,
    fieldName: string,
    value: any
  ) => {
    const updatedCompetences = state.competences?.competence?.map(
      (competence, cIndex) => {
        if (cIndex === competenceIndex) {
          if (fieldName === "additional_info") {
            return {
              ...competence,
              [fieldName]: value,
            };
          }
          const updatedChildCompetences =
            competence.vacancy_child_competences.map(
              (childCompetence, sIndex) => {
                if (sIndex === subIndex) {
                  const updatedCanCompDetails = {
                    ...childCompetence.vacancy_competence_detail,
                    [fieldName]: value,
                  };
                  return {
                    ...childCompetence,
                    vacancy_competence_detail: updatedCanCompDetails,
                  };
                }
                return childCompetence;
              }
            );

          return {
            ...competence,
            vacancy_child_competences: updatedChildCompetences,
          };
        }
        return competence;
      }
    );

    dispatch({
      type: UPDATE_CHILD_COMPETENCES,
      field: "competence",
      value: updatedCompetences,
    });
  };

  const generateTooltipTitle = (
    competence_levels: { name: string; level: number }[]
  ) => {
    return competence_levels
      .map((level) => `${level.level}. ${level.name}`)
      .join("\n");
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    scrollToTop();
  };
  const title = `A. Basic user (A1/A2: beginner)\nB. Independent user (B1/B2: advanced)\nC. Skilled user (C1/C2: advanced)`;

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12">
            <div className="form-border" style={{ paddingTop: "3.4vw" }}>
              <div className="row ">
                <div className="field-set form pb-4">
                  {state.competences?.competence &&
                    state.competences?.childCompetences &&
                    state.competences?.competence?.map(
                      (competence, competenceIndex) => (
                        <Accordion
                          defaultActiveKey="0"
                          key={competence.competence_name}
                          className=" compentences compentencesCandidate competencesAccordion"
                        >
                          <Accordion.Item eventKey={String(competenceIndex)}>
                            <Accordion.Header>
                              {competence.competence_name}
                            </Accordion.Header>
                            <Accordion.Body className="compentencesCandidateBody">
                              <div className="">
                                <SelectWithSearch
                                  name="Select"
                                  className="mt-1"
                                  placeHolder={t("Select")}
                                  search={true}
                                  isMulti={true}
                                  options={(
                                    state.competences?.childCompetences[
                                      competence.competence_name
                                    ] || []
                                  ).map((filteredChildComp) => ({
                                    label: filteredChildComp.name,
                                    value: filteredChildComp.name.toString(),
                                  }))}
                                  onChange={(selected: Options[]) =>
                                    handleChildCompChange(
                                      competenceIndex,
                                      selected,
                                      competence?.competence_name
                                    )
                                  }
                                  value={competence?.vacancy_child_competences
                                    .filter(
                                      (childComp) =>
                                        childComp.vacancy_competence_detail
                                          .range !== -1
                                    )
                                    .map((filteredChildComp) => ({
                                      label: filteredChildComp.name,
                                      value: filteredChildComp.name.toString(),
                                    }))}
                                  isTranslate={true}
                                />
                              </div>
                              {competence?.vacancy_child_competences.filter(
                                (childComp) =>
                                  childComp.vacancy_competence_detail.range !==
                                  -1
                              ).length > 0 && (
                                <div className="border marginBotttom1">
                                  <div className="col-md-12 p-3 text-end">
                                    <div
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="left"
                                      title={
                                        competence.competence_name ===
                                        "Languages"
                                          ? title
                                          : generateTooltipTitle(
                                              competence.competence_levels
                                            )
                                      }
                                    >
                                      <HelpInfoIcon />
                                    </div>
                                  </div>
                                  <div className="row row-cols-1 row-cols-lg-2">
                                    {competence?.vacancy_child_competences
                                      .filter(
                                        (childComp) =>
                                          childComp.vacancy_competence_detail
                                            .range !== -1
                                      )
                                      .map((eachCompetance, subIndex) => (
                                        <div
                                          className="col"
                                          key={eachCompetance.name}
                                        >
                                          <div className="row">
                                            <div className="col-md-4 align-self-center mb-3 mb-md-0">
                                              <h6 className="m-0 ps-lg-3 competencesName">
                                                {`${t(eachCompetance.name)}`}
                                              </h6>
                                            </div>

                                            <div className="col-md-4 align-self-center range-wrap">
                                              <CustomRangeSlider
                                                min={1}
                                                max={
                                                  competence.competence_levels
                                                    .length
                                                }
                                                step={1} // Step size should match the range
                                                value={
                                                  eachCompetance
                                                    .vacancy_competence_detail
                                                    .range ?? 0
                                                }
                                                handleChange={(e) =>
                                                  handleInputChange(
                                                    competenceIndex,
                                                    subIndex,
                                                    "range",
                                                    e.target.value
                                                  )
                                                }
                                              />
                                              <div className="d-flex justify-content-between marginBottomPoint5">
                                                {competence.competence_levels.map(
                                                  (level) => (
                                                    <span key={level.id}>
                                                      {level?.level}
                                                    </span>
                                                  )
                                                )}
                                              </div>
                                              <output className="bubble">
                                                {t(
                                                  competence.competence_levels[
                                                    parseInt(
                                                      eachCompetance.vacancy_competence_detail.range.toString()
                                                    ) - 1
                                                  ]?.name
                                                )}
                                              </output>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              )}
                              <div>
                                <h5 className="field-label marginBottomPoint5">
                                  {t("Additional info")}
                                </h5>
                                <textarea
                                  name="additional_info"
                                  className="form-control field-shadow"
                                  onChange={(e) =>
                                    handleInputChange(
                                      competenceIndex,
                                      1,
                                      "additional_info",
                                      e.target.value
                                    )
                                  }
                                  value={competence.additional_info ?? ""}
                                />
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      )
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Navigation validStatus={validStatus} />
      </form>
    </>
  );
};

export default translate(VacancyCompetences);
