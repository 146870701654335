import React, { ReactNode, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as CONST from 'Constants';
import { STOP_RECORDING, GET_SOUND_SETTINGS, PHONE_CALL_URL } from 'routes/ApiEndpoints';
import CustomNotify from 'components/common/atoms/CustomNotify';
import { t } from '../translation/Translation';
import { addUserDetails, selectAuth } from 'features/auth/AuthSlice';
import { ApiCall } from 'components/common/services/ApiServices';
import MeetingTypesModal from './MeetingTypesModal';
import DeviceSelectionModal from './DeviceSelectionModal';
import ModalPopup from 'components/common/atoms/ModalPopup';
import { useDispatch } from 'react-redux';

export interface ModalDetails {
    modalType: string;
    showModal: boolean;
    body: string | ReactNode;
    title: string;
    showCloseButton: boolean;
    closeTitle: string;
    confirmTitle: string;
}

export interface SalesNoteProps {
    salesnote: {
        name: string;
        phone?: string;
        id: number | null;
        activeRecordingId?: number | null;
        companyId?: number | null;
        triggerMeeting?: boolean,
        type?: number | null;
        isMeetingTitle?: boolean;
        isPlay?: boolean;
        navigate?: string;
        prevId?: number | null;
    };
    setSalesNote: (salesnote: any) => void;
}

const SalesNote: React.FC<SalesNoteProps> = ({ salesnote, setSalesNote }) => {
    const authUser = useSelector(selectAuth);
    const [modalDetails, setModalDetails] = useState<ModalDetails>({
        modalType: '',
        showModal: false,
        body: '',
        title: '',
        showCloseButton: false,
        closeTitle: '',
        confirmTitle: '',
    });
    const dispatch = useDispatch();
    const [soundSettingsResponse, setSoundSettingsResponse] = useState<any>(null);
    const [soundSettingsModalOpen, setSoundSettingsModalOpen] = useState(false);
    const [showMeetingTypesModal, setShowMeetingTypesModal] = useState(false);
    const [prevId, setPrevId] = useState<number | null | undefined>(null);

    const handleMeeting = async () => {

        // Fetch sound settings for the meeting
        const user = salesnote;

        const soundSettingsResponse = await ApiCall.getService(
            GET_SOUND_SETTINGS,
            'GET',
            CONST.CENTRAL_DATA_MANAGEMENT_MICROSERVICE,
            true
        );

        if (soundSettingsResponse.status === 200) {

            setSoundSettingsResponse(soundSettingsResponse.data);
            setSoundSettingsModalOpen(true);
            setSalesNote({
                ...user,
                activeRecordingId: salesnote.activeRecordingId,
                triggerMeeting: false
            });
        } else if (soundSettingsResponse.status == 500) {
            setModalDetails({
                modalType: STOP_RECORDING,
                body: (
                    <>
                        {t('A recording is already in progress')}{' '}
                        <strong>{soundSettingsResponse.error}</strong>{'. '}
                        {t('Do you want to stop it and start a new recording')}{'?'}
                    </>
                ),
                title: t('Stop recording'),
                showModal: true,
                closeTitle: t('No'),
                confirmTitle: t('Yes'),
                showCloseButton: false,
            });

        } else {
            if (soundSettingsResponse.status === 503) {
                CustomNotify({ type: CONST.ERROR, message: t(soundSettingsResponse.error) });
            } else {
                CustomNotify({ type: CONST.ERROR, message: t("Recording is temporarily unavailable. Please contact the administrator for assistance.") });
            }

            setTimeout(() => {
                if (salesnote.navigate === CONST.CALL_3CX) {
                    const url = salesnote.phone ? `${PHONE_CALL_URL}${salesnote.phone.replace('+', '%2B')}` : '';
                    window.open(url, '_blank');
                }
            }, 1000);

            setSalesNote((prev: any) => ({
                ...prev,
                activeRecordingId: null,
                triggerMeeting: false,
                isPlay: false,
            }));
        }
    };

    const stopRecording = async () => {
        setSalesNote((prev: any) => ({
            ...prev,
            activeRecordingId: null,
            triggerMeeting: false,
            isPlay: false,
        }));
        const recording = {
            isRecording: false
        }
        dispatch(addUserDetails(recording))

        const data = {
            agentId: authUser.userId,
        };
        setModalDetails((prev) => ({
            ...prev,
            showModal: false,
        }));

        const stopRecordingResponse = await ApiCall.service(
            STOP_RECORDING,
            'POST',
            data,
            true,
            CONST.CENTRAL_DATA_MANAGEMENT_MICROSERVICE
        );

        if (stopRecordingResponse.status === 200) {
            CustomNotify({ type: CONST.SUCCESS, message: t('Recording stopped successfully') });
            if (modalDetails.modalType === STOP_RECORDING) {
                handleMeeting();
            }
        }
        else if (stopRecordingResponse.status === 503) {
            CustomNotify({ type: CONST.ERROR, message: t(stopRecordingResponse.error) });
            setTimeout(() => {
                if (salesnote.navigate === CONST.CALL_3CX) {
                    const url = salesnote.phone ? `${PHONE_CALL_URL}${salesnote.phone.replace('+', '%2B')}` : '';
                    window.open(url, '_blank');
                }
            }, 3000);
        }
        else {
            CustomNotify({ type: CONST.ERROR, message: t('An error occurred while stopping the recording') });
        }
    };

    const closeModal = (modalType?: string, closeType?: string) => {
        setSoundSettingsModalOpen(false);

        if (modalType === CONST.DEVICE) {
            setShowMeetingTypesModal(true);
        } else if (modalType === CONST.MEETING_TYPE) {
            setShowMeetingTypesModal(false);
        }

        if (closeType === CONST.CANCEL) {
            setSalesNote((prev: any) => ({
                ...prev,
                activeRecordingId: null,
                isPlay: false
            }));
        }

        setSalesNote((prev: any) => ({
            ...prev,
            triggerMeeting: false
        }))

        if (modalDetails.modalType === STOP_RECORDING && modalDetails.showModal === true) {

            const user = salesnote
            setSalesNote({
                ...user,
                activeRecordingId: prevId,
                triggerMeeting: false,
                isPlay: authUser.isRecording === true ? true : false
            });
        }
        setModalDetails((prev) => ({
            ...prev,
            modalType: '',
            showModal: false,
        }));
    };

    // Automatically call handleMeeting when the trigger is fired
    useEffect(() => {
        if (salesnote.triggerMeeting) {
            handleMeeting(); // Trigger the meeting when `triggerMeeting` is invoked
        }
        if (salesnote.prevId) {
            setPrevId(salesnote.prevId);
        }
    }, [salesnote.triggerMeeting, salesnote.prevId]);


    return (
        <div>
            <MeetingTypesModal
                showModal={showMeetingTypesModal}
                closeModal={closeModal}
                salesnote={salesnote}
                setSalesNote={setSalesNote}
                setPrevId={setPrevId}
            />
            {soundSettingsResponse && (
                <DeviceSelectionModal
                    response={soundSettingsResponse}
                    closeModal={closeModal}
                    showModal={soundSettingsModalOpen}
                />
            )}
            <ModalPopup
                show={modalDetails.showModal}
                title={modalDetails.title}
                body={modalDetails.body}
                onCloseButtonClick={closeModal}
                onConfirmButtonClick={modalDetails.modalType === STOP_RECORDING ? stopRecording : () => { }}
                closeTitle={modalDetails.closeTitle}
                confirmTitle={modalDetails.confirmTitle}
                showCloseButton={modalDetails.showCloseButton}
            />
        </div>
    );
};

export default SalesNote;
