import { t, translate } from "components/CentralDataMangement/translation/Translation";
import CardCircle from "components/common/atoms/CardCircle";
import CardTitle from "components/common/atoms/CardTitle";
import Count from "components/common/atoms/Count";
import DashboardCard from "components/common/atoms/DashboardCard";
import React from "react";
import { Link } from "react-router-dom";
import RenderComponent from "../RenderComponent";
import { EMPLOYER } from "Constants";

interface TileProps {
  path?: string;
  targetAchieved?: number;
  target?: number;
  punctuation?: string;
  countClass?: string;
  cardClass?: string;
  cardTitle?: string;
  icon?: string;
  userRole?: string;
  isRecData?: boolean;
  isMyBudget?: boolean;
  displayDate?: string;
  onClick?: () => void; 
}

const Tile: React.FC<TileProps> = ({
  path = "",
  targetAchieved = 0,
  target = 0,
  punctuation = "of",
  cardTitle = "",
  icon = "",
  userRole = "",
  isRecData = false,
  isMyBudget = false,
  displayDate = "",
  onClick
}) => {
  const colStyle = isMyBudget && isRecData ? 'col-12' : (isMyBudget && !isRecData ? 'col-6' : ((!isMyBudget && isRecData ? 'col-12' : (isMyBudget ? 'col-6' : 'col-4'))));
  return (
    <div className={`${colStyle} recruiter-action-tabs`} onClick={onClick}>
      <Link to={path}>
        <DashboardCard
          className={`card border-0 card-shadow justify-content-center h-100`}
          data-bs-toggle="tooltip"
          data-bs-placement="left"
          toolTipTitle={t(`${displayDate}`)}
        >
          <div className="row">
            <div className="col-xxl-10 col-xl-9 col-lg-8 align-self-center col-md-6">
              {userRole !== EMPLOYER && 
                <Count
                  value={targetAchieved}
                  totalValue={target}
                  value_punctuation={t(`${punctuation}`)}
                  className={`recruitment-card-num`}
                ></Count>
              }
              <CardTitle
                title={t(`${cardTitle}`)}
                className={`recruitment-card-title`}
              />
            </div>
            <div className="col-xxl-2 col-xl-3 col-lg-4 px-0 align-self-center col-md-6 paddingRightPoint5">
              <CardCircle
                width="3.5vw"
                height="3.5vw"
                // backgroundColor={role.circleColor}
                boxShadow="rgb(239 206 224) 0px 0.156vw 0.365vw 0.156vw"
                className="float-end justify-content-center"
              >
                <RenderComponent componentName={icon} />
              </CardCircle>
            </div>
          </div>
        </DashboardCard>
      </Link>
    </div>
  );
};

export default translate(Tile);
