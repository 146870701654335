import RadioField from "components/common/atoms/RadioField";
import { OptionProps } from "components/common/utlis/TypeAnnotations";
import React, { useEffect, useState } from "react";
import { AcceptData } from "./CompanyPreInterviewPage";
import { t, translate } from "../translation/Translation";
import { ApiCall } from "components/common/services/ApiServices";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "Constants";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { mapToSelect } from "components/common/utlis/MapToSelect";
import TimeSlot from "./TimeSlot";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import Title from "components/common/atoms/Title";
import Button from "components/common/atoms/Button";
import LoadingIcon from "components/common/utlis/LoadingIcon";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import CustomNotify from "components/common/atoms/CustomNotify";

const EditInterviewDetails: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [interviewData, setInterviewData] = useState({
    status: null,
    companyName: "",
    functionName: "",
    candidateName: "",
    feedBackByOptions: [] as OptionProps[],
    candidateStatus: null,
    timeSlotDetails: [] as OptionProps[],
  });
  const [formData, setFormData] = useState({
    newTimeSlot: null,
    selectedTimeSlot: null,
  });

  const [timeSlots, setTimeSlots] = useState<AcceptData[]>([
    {
      id: null,
      date: null,
      from: "",
      to: "",
    },
  ]);

  const {
    todoExtensionId,
    companyId,
    candidateId,
    todoId,
    functionName,
    presentationId,
  } = useParams<{
    todoExtensionId: string;
    companyId: string;
    candidateId: string;
    todoId: string;
    functionName: string;
    presentationId: string;
  }>();

  const handleFieldChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    if (name === "status") {
      setInterviewData((prevData: any) => ({
        ...prevData,
        [name]: parseInt(value),
      }));
    } else if (name === "newTimeSlot") {
      setFormData((prevData: any) => ({
        ...prevData,
        [name]: parseInt(value),
      }));
    } else {
      setInterviewData((prevData: any) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  const fetchTimeSlots = async (status: number | null) => {
    const data = {
      status: status,
      todoExtensionId: todoExtensionId,
      companyId: companyId,
      candidateId: candidateId,
      todoId: todoId,
      functionName: functionName,
      presentationId: presentationId,
    };
    const response = await ApiCall.service(
      "getInterviewDetailsForEdit",
      "POST",
      data,
      false,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );
    if (response.status === 200) {
      setInterviewData((prev) => ({
        ...prev,
        timeSlotDetails: mapToSelect(
          response.data.timeSlots.tS,
          "timeinterval"
        ),
        candidateName: response.data.candidateName,
        companyName: response.data.companyName,
        functionName: functionName || "",
      }));
      setFormData((prev) => ({
        ...prev,
        selectedTimeSlot: response.data.timeSlots.selectedTimeSlot,
        newTimeSlot: response.data.timeSlots.selectedTimeSlot,
      }));
    }
  };

  useEffect(() => {
    fetchTimeSlots(null);
  }, []);

  //Time slots storing
  const handleDynamicDateChange = (
    date: Date | null,
    name: string,
    index: number
  ) => {
    const updatedDate = date !== null ? date : new Date();

    setTimeSlots((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = {
        ...updatedData[index],
        [name]: updatedDate !== null ? updatedDate : "",
      };
      return updatedData;
    });
  };

  // Add and remove fields
  const addTimeFieldSet = () => {
    const newTimeSlot = {
      id: null,
      date: null,
      from: "",
      to: "",
    };
    setTimeSlots((prevData) => [...prevData, newTimeSlot]);
  };

  const removeTimeFieldSet = (currentIndex: number) => {
    const updatedData = [...timeSlots];
    updatedData.splice(currentIndex, 1);
    setTimeSlots(updatedData);
  };

  // Time field
  const handleTimeChange = (
    index: number,
    name: string,
    value: string,
    field: string
  ) => {
    if (field === "time" || name === "from" || name === "to") {
      setTimeSlots((prevData) => {
        const updatedData = [...prevData];
        updatedData[index] = {
          ...updatedData[index],
          [name]: value !== null ? value : "",
        };
        return updatedData;
      });
    }
  };

  const handleSubmit = async (event: any, type: string) => {
    event.preventDefault();
    if ((formData.selectedTimeSlot !== null && interviewData.status === 0) || interviewData.status === 1) {
      const data = {
        status: interviewData.status,
        formData: formData,
        timeSlots: timeSlots,
        todoExtensionId: todoExtensionId,
        companyId: companyId,
        candidateId: candidateId,
        todoId: todoId,
        functionName: functionName,
        presentationId: presentationId,
      };
      setLoading(true);
      const response = await ApiCall.service(
        "storeInterviewDetails",
        "POST",
        data,
        true,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.status === 200) {
        navigate(
          location.state?.origin === "candidateCorner"
            ? `/candidate/corner/${candidateId}`
            : "/todos/overview"
        );
      }
    }
    if (formData.selectedTimeSlot === null && interviewData.status === 0) {
      CustomNotify({
        type: "error",
        message: t("Candidate has not yet selected the timeslot"),
      });
    }
    setLoading(false);
  };

  return (
    <>
      <Title title={t("Edit interview information")} />
      <div className="row search-bar">
        <div className="col-sm-12 col-md-4">
          <LabelWithInputField
            name="CandidateName"
            value={interviewData.candidateName}
            id="CandidateName"
            label={t("Candidate name")}
            isMandatory={false}
            isDisabled={true}
          />
        </div>
        <div className="col-sm-12 col-md-4">
          <LabelWithInputField
            name="FunctionName"
            value={interviewData.functionName}
            id="FunctionName"
            label={t("Function name")}
            isMandatory={false}
            isDisabled={true}
          />
        </div>
        <div className="col-sm-12 col-md-4">
          <LabelWithInputField
            name="CompanyName"
            value={interviewData.companyName}
            id="CompanyName"
            label={t("Company name")}
            isMandatory={false}
            isDisabled={true}
          />
        </div>
      </div>
      <div className="editInterviewInformationHeight">
        <div className="form-border">
          <div className="row">
            {(interviewData.status === 0 || interviewData.status === null) && (
              <div className="col-sm-12 col-md-4">
                <RadioField
                  name="status"
                  value={0}
                  disable={false}
                  ischecked={interviewData.status === 0}
                  handleChange={handleFieldChange}
                  label={t("Select new time slot")}
                />
              </div>
            )}
            {(interviewData.status === 1 || interviewData.status === null) && (
              <div className="col-sm-12 col-md-4">
                <RadioField
                  name="status"
                  value={1}
                  disable={false}
                  ischecked={interviewData.status === 1}
                  handleChange={handleFieldChange}
                  label={t("Enter new time slots")}
                />
              </div>
            )}
          </div>
          {interviewData.status === 0 && (
            <div className="row mt-3 ps-4">
              {interviewData.timeSlotDetails.map((option, index) => (
                <div className="col-12 mb-2" key={index}>
                  <RadioField
                    name="newTimeSlot"
                    value={option.value}
                    disable={false}
                    ischecked={formData.newTimeSlot === option.value}
                    handleChange={(e) => handleFieldChange(e)}
                    label={option.label}
                    className="me-1"
                  />
                </div>
              ))}
            </div>
          )}

          {interviewData.status === 1 && (
            <div className="mt-3 ps-4">
              <h1 className="pb-3 pt-2 tab-subtitle mb-0">
                {t("Already present dates")}
              </h1>
              <div className="row ps-4 mb-3">
                {interviewData.timeSlotDetails.map((option, index) => (
                  <div className="my-1" key={index}>
                    <div className="me-1">
                      {/* <RadioField
                        value={option.value}
                        disable={false}
                        handleChange={(e) => handleFieldChange(e)}
                        label={option.label}
                        className="me-1"
                      /> */}
                      {option.label}
                    </div>
                  </div>
                ))}
              </div>
              <h1 className="pb-3 pt-2 tab-subtitle mb-0">
                {t("Enter new time slots")}
              </h1>
              <div className="ps-4">
                <TimeSlot
                  acceptData={timeSlots}
                  handleDynamicDateChange={handleDynamicDateChange}
                  handleTimeChange={handleTimeChange}
                  // errors={errors}
                  removeFieldSet={removeTimeFieldSet}
                  addFieldSet={addTimeFieldSet}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-6 align-self-center">
          <Link
            to={
              location.state?.origin === "todos"
                ? "/todos/overview"
                : `/candidate/corner/${candidateId}`
            }
            className="back-btn text-decoration-underline"
          >
            {t("Back")}
          </Link>
        </div>

        <div className="col-md-6 d-flex justify-content-end">
          <>
            {!loading ? (
              <Button
                title={t("Save")}
                type="submit"
                className="form-button shadow-none px-3"
                handleClick={(e) => handleSubmit(e, "accept")}
              />
            ) : (
              <LoadingIcon
                iconType="bars"
                color="#e55496"
                className="ms-auto"
                width={"3vw"}
                height={"3vw"}
              />
            )}
          </>
        </div>
      </div>
    </>
  );
};

export default translate(EditInterviewDetails);
