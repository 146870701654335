import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import Title from "components/common/atoms/Title";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import React from "react";
import { Link } from "react-router-dom";
import {
  ADD_TASK_SUB_TYPE,
  ADD_TASK_TYPE,
  MANAGE_BUSINESS_UNIT,
  MANAGE_COMPANY_ROLES,
  MANAGE_DOMAIN,
  MANAGE_EXPERIENCE_LEVEL,
  MANAGE_FUNCTION,
  MANAGE_OFFICES,
  MANAGE_PAGE_CONTENTS,
  MANAGE_SHIFTS,
} from "routes/RouteConstants";

const ConfigAndSettingsPage: React.FC = () => {
  const cardStyle = {
    className:
      "card border-0 card-shadow justify-content-center h-100",
  };

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Config and settings",
          read: true,
        },
      ]}
      renderNoAccess={true}
    >
      <Title
        title={t("Configuration and settings")}
        className="tab-section-title "
      />
      <div className="form-height-dashboard mt-2">
        <div
          className="recruiter-action-tabs"
          style={{
            gridTemplateColumns: "repeat(4, 1fr)",
            gap: "1.5vw",
            display: "grid",
          }}
        >
          <AccessControl
            requiredPermissions={[
              {
                permission: "City profiles",
                read: true,
              },
            ]}
          >
            <Link to="/manage-city-profile" className="dashboard-tab-links">
              <div {...cardStyle}>{t("City profiles")}</div>
            </Link>
          </AccessControl>
          {/* <Link to="/manage/function-profile" className='dashboard-tab-links'>
                          <div className="tab">
                              Manage function profile
                          </div>
                      </Link> */}
          {/* <Link to="/manage-checklist" className='dashboard-tab-links'>
                          <div className="tab">
                              {t("Document checklist")}
                          </div>
                      </Link> */}
          {/* <Link to="/manage/default/low-coefficients" className='dashboard-tab-links'>
                          <div className="tab">
                            {t("Low coefficients")}
                          </div>
                      </Link> */}
          <AccessControl
            requiredPermissions={[
              {
                permission: "Work type extra",
                read: true,
              },
            ]}
          >
            <Link to="/work-type/extra" className="dashboard-tab-links">
              <div {...cardStyle}>{t("Work type extra")}</div>
            </Link>
          </AccessControl>
          <AccessControl
            requiredPermissions={[
              {
                permission: "Wage elements",
                read: true,
              },
            ]}
          >
            <Link to="/manage-wage-elements" className="dashboard-tab-links">
              <div {...cardStyle}>{t("Wage elements")}</div>
            </Link>
          </AccessControl>
          <AccessControl
            requiredPermissions={[
              {
                permission: "Inflations",
                read: true,
              },
            ]}
          >
            <Link to="/manage/inflations" className="dashboard-tab-links">
              <div {...cardStyle}>{t("Inflations")}</div>
            </Link>
          </AccessControl>
          <AccessControl
            requiredPermissions={[
              {
                permission: "Coefficient type",
                read: true,
              },
            ]}
          >
            <Link to="/manage/coefficient" className="dashboard-tab-links">
              <div {...cardStyle}>{t("Coefficient type")}</div>
            </Link>
          </AccessControl>
          <AccessControl
            requiredPermissions={[
              {
                permission: "Employee type",
                read: true,
              },
            ]}
          >
            <Link to="/manage/employee-types" className="dashboard-tab-links">
              <div {...cardStyle}>{t("Employee type")}</div>
            </Link>
          </AccessControl>
          <AccessControl
            requiredPermissions={[
              {
                permission: "Paritair committe",
                read: true,
              },
            ]}
          >
            <Link to="/manage-pc" className="dashboard-tab-links">
              <div {...cardStyle}>{t("Paritair committee")}</div>
            </Link>
          </AccessControl>
          <AccessControl
            requiredPermissions={[
              {
                permission: "Coefficient deviation",
                read: true,
              },
            ]}
          >
            <Link to="/manage/coeff-deviation" className="dashboard-tab-links">
              <div {...cardStyle}>{t("Coefficient deviation")}</div>
            </Link>
          </AccessControl>
          {/* <Link to="/manage/pc-coefficient" className='dashboard-tab-links'>
                          <div className="tab">
                              {t("PC coefficients")}
                          </div>
                      </Link> */}
          {/* <Link to={ADD_SIGNATURE} className='dashboard-tab-links'>
                          <div className="tab">
                              {t("Add signature")}
                              </div>
                      </Link> */}
          <AccessControl
            requiredPermissions={[
              {
                permission: "Office cities",
                read: true,
              },
            ]}
          >
            <Link to="/manage/office-locations" className="dashboard-tab-links">
              <div {...cardStyle}>{t("Office cities")}</div>
            </Link>
          </AccessControl>
          <AccessControl
            requiredPermissions={[
              {
                permission: "Business units",
                read: true,
              },
            ]}
          >
            <Link to={MANAGE_BUSINESS_UNIT} className="dashboard-tab-links">
              <div {...cardStyle}>{t("Business units")}</div>
            </Link>
          </AccessControl>
          <AccessControl
            requiredPermissions={[
              {
                permission: "Experience level",
                read: true,
              },
            ]}
          >
            <Link to={MANAGE_EXPERIENCE_LEVEL} className="dashboard-tab-links">
              <div {...cardStyle}>{t("Experience level")}</div>
            </Link>
          </AccessControl>
          <AccessControl
            requiredPermissions={[
              {
                permission: "Default coefficients",
                read: true,
              },
            ]}
          >
            <Link
              to="/manage/default-coefficients"
              className="dashboard-tab-links"
            >
              <div {...cardStyle}>{t("Default coefficients")}</div>
            </Link>
          </AccessControl>
          <AccessControl
            requiredPermissions={[
              {
                permission: "Domain",
                read: true,
              },
            ]}
          >
            <Link to={MANAGE_DOMAIN} className="dashboard-tab-links">
              <div {...cardStyle}>{t("Domain")}</div>
            </Link>
          </AccessControl>
          <AccessControl
            requiredPermissions={[
              {
                permission: "Function",
                read: true,
              },
            ]}
          >
            <Link to={MANAGE_FUNCTION} className="dashboard-tab-links">
              <div {...cardStyle}>{t("Function")}</div>
            </Link>
          </AccessControl>

          {/* <AccessControl
                // requiredPermissions={[
                //   {
                //     permission: "Follow-up",
                //     read: true,
                //   },
                // ]}
              >
                  <Link to={ADD_TASK_TYPE} className="dashboard-tab-links">
                      <div className="tab">{t("Add task type")}</div>
                  </Link>
              </AccessControl>
              <AccessControl
                // requiredPermissions={[
                //   {
                //     permission: "Follow-up",
                //     read: true,
                //   },
                // ]}
              >
                  <Link to={ADD_TASK_SUB_TYPE} className="dashboard-tab-links">
                      <div className="tab">{t("Add task sub type")}</div>
                  </Link>
              </AccessControl> */}
          <AccessControl
            requiredPermissions={[
              {
                permission: "Roles",
                read: true,
              },
            ]}
          >
            <Link to={MANAGE_COMPANY_ROLES} className="dashboard-tab-links">
              <div {...cardStyle}>{t("Company roles")}</div>
            </Link>
          </AccessControl>
          <AccessControl
            requiredPermissions={[
              {
                permission: "Page contents",
                read: true,
              },
            ]}
          >
            <Link to={MANAGE_PAGE_CONTENTS} className="dashboard-tab-links">
              <div {...cardStyle}>{t("Page contents")}</div>
            </Link>
          </AccessControl>
          <AccessControl
            requiredPermissions={[
              {
                permission: "Office cities",
                read: true,
              },
            ]}
          >
            <Link to={MANAGE_OFFICES} className="dashboard-tab-links">
              <div {...cardStyle}>{t("Offices")}</div>
            </Link>
          </AccessControl>
          <Link to={MANAGE_SHIFTS} className="dashboard-tab-links">
            <div {...cardStyle}>{t("Shifts")}</div>
          </Link>
        </div>
      </div>
      <div className="row backPadding">
        <div className="col-md-6 align-self-center">
          <Link to="/dashboard" className="back-btn text-decoration-underline">
            {t("Back")}
          </Link>
        </div>
      </div>
    </AccessControl>
  );
};

export default translate(ConfigAndSettingsPage);
