import React from "react";
import EditIcon from "static/images/EditIcon";
import DeleteIcon from "static/images/DeleteIcon";
import { t, translate } from "components/CentralDataMangement/translation/Translation";

interface dataProps {
  data: object[];
  pcChangeHandler: (value: any, type: string) => void;
  pcDeleteHandler: (value: any, type: string) => void;
  type: string;
}

const PCTable: React.FC<dataProps> = ({
  data,
  pcChangeHandler,
  type,
  pcDeleteHandler,
}) => {
  return (
    <>
      {data && data.length > 0 && (
        <div className="col-lg-12 pwa">
          <table className="table table-hover general-agreement-table">
            <thead>
              <tr className="TableHeader bg-white">
                <th className="ps-lg-4">
                  {t("Paritair committee")} {type}
                </th>
                <th>{t("Employee types")}</th>
                <th>{t("Actions")}</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item: any, index: number) => (
                <tr
                  key={index}
                  className="border-bottom mb-3 box-shadow align-middle"
                >
                  <td
                    className="text-break ps-lg-4"
                    data-label={t("Paritair committee")}
                  >
                    {t(item.PC.label)}
                  </td>
                  <td className="text-break" data-label={t("Employee types")}>
                    {item.employeeType.map((empType: any, index: number) =>
                      index === 0 ? t(empType.label) : `, ${t(empType.label)}`
                    )}
                  </td>
                  <td className="table-action-icons">
                    <div className="d-none d-lg-block">
                      <button
                        type="button"
                        onClick={() => pcChangeHandler(index, type)}
                        className="bg-transparent border-0 shadow-none"
                        title={t("Edit")}
                      >
                        <EditIcon />
                      </button>
                      <button
                        type="button"
                        onClick={() => pcDeleteHandler(index, type)}
                        className="bg-transparent border-0 shadow-none"
                        title={t("Delete")}
                      >
                        <DeleteIcon />
                      </button>
                    </div>
                    <div className="d-block d-lg-none text-center">
                      <button
                        type="button"
                        onClick={() => pcChangeHandler(index, type)}
                        className="shadow-none button-width edit-btn rounded-3 mb-3"
                        title={t("Edit")}
                      >
                       {t("Edit")}
                      </button>
                      <button
                        type="button"
                        onClick={() => pcDeleteHandler(index, type)}
                        className="shadow-none button-width edit-btn rounded-3 mb-3"
                        title={t("Delete")}
                      >
                       {t("Delete")}
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default translate(PCTable);
