import React from 'react'

const MicrophoneIcon2 = () => {
  return (
    <>
      <svg width="1.5vw" height="1.5vw" viewBox="0 0 210 251" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M149.319 5.49735C149.319 5.17397 151.14 2.70825 152.678 0.889276L153.447 0L156.199 2.0615L158.992 4.16343L157.778 5.7803C157.211 6.59364 156.397 7.65448 155.85 8.36739C155.771 8.46929 155.698 8.5643 155.633 8.65023L154.661 9.82246L151.99 7.8418C150.533 6.71 149.319 5.65903 149.319 5.49735Z" fill="black" />
        <path d="M143.936 1.13183C144.098 0.970141 145.717 0.646768 147.498 0.444659C149.278 0.282973 150.816 0.161708 150.897 0.242551C151.1 0.363816 148.995 3.43586 148.267 4.04219C147.862 4.40598 147.255 4.16345 145.676 2.99122C144.543 2.14237 143.733 1.33394 143.936 1.13183Z" fill="black" />
        <path d="M156.361 0.282951C156.483 0.48506 157.333 1.17223 158.264 1.77855L159.923 2.95078L160.692 1.98066C161.785 0.525481 161.663 0.363795 159.761 0.323373C158.79 0.323373 157.616 0.24253 157.09 0.121265C156.564 0.0404213 156.24 0.121265 156.361 0.282951Z" fill="black" />
        <path d="M161.056 3.92083L162.311 2.34438C163.12 1.33384 163.808 0.808362 164.254 0.929627C164.377 0.957002 164.644 1.02146 165.006 1.10886L165.007 1.10905C165.715 1.27977 166.785 1.53798 167.856 1.77848C169.434 2.1827 170.77 2.54649 170.77 2.66776C170.77 2.7486 169.92 3.96125 168.868 5.33559C168.362 6.02238 167.877 6.6688 167.512 7.15377L167.512 7.15458L167.511 7.1552L167.508 7.15932L167.507 7.16095C167.145 7.64246 166.905 7.96317 166.884 8.00342C166.803 8.08426 165.468 7.19498 163.889 6.02276L161.056 3.92083Z" fill="black" />
        <path d="M139.2 4.44632C138.027 5.98235 137.136 7.31626 137.217 7.3971C137.258 7.41731 137.581 7.65977 138.067 8.02347C138.552 8.38729 139.2 8.87242 139.888 9.37777C141.264 10.4287 142.479 11.2776 142.56 11.2776C143.086 11.2776 146.971 5.94192 146.647 5.65897C146.445 5.49729 145.15 4.48674 143.774 3.4762L141.305 1.57638L139.2 4.44632Z" fill="black" />
        <path d="M133.008 4.12292L135.396 3.27407C138.836 2.06142 138.998 2.14226 137.5 4.24419C136.772 5.25473 136.084 6.14401 135.962 6.22485C135.841 6.30569 135.112 5.86105 134.384 5.25473L133.008 4.12292Z" fill="black" />
        <path d="M170.001 6.2653C168.868 7.7609 168.058 9.13524 168.22 9.2565C168.422 9.41819 169.677 10.3883 171.053 11.4393L173.563 13.2987L175.627 10.6308C176.72 9.13524 177.65 7.80132 177.65 7.68006C177.61 7.43753 172.996 3.92084 172.348 3.67831C172.186 3.59747 171.134 4.7697 170.001 6.2653Z" fill="black" />
        <path d="M127.706 10.9542C124.589 8.60975 124.67 8.24596 128.596 6.2653C131.47 4.81012 131.591 4.81012 133.696 6.54825L134.91 7.51837L132.927 10.2266C131.834 11.6818 130.782 12.8944 130.62 12.8944C130.417 12.8944 129.122 12.0052 127.706 10.9542Z" fill="black" />
        <path d="M177.043 5.21437C177.408 5.8207 178.865 6.50787 178.865 6.10365C178.865 5.90154 178.379 5.53775 177.812 5.29522C177.246 5.01226 176.882 4.97184 177.043 5.21437Z" fill="black" />
        <path d="M156.199 10.7117C156.199 10.55 157.049 9.21607 158.102 7.80131C159.154 6.38656 160.044 5.21433 160.125 5.21433C160.206 5.21433 161.542 6.14403 163.08 7.31625L165.873 9.41818L163.768 12.0456C163.077 12.9327 162.446 13.7597 162.05 14.2793C161.796 14.6121 161.639 14.8189 161.623 14.8347C161.461 15.0772 156.24 11.0755 156.199 10.7117Z" fill="black" />
        <path d="M146.364 9.13526C145.393 10.4288 144.462 11.6818 144.34 11.9648C143.976 12.5307 149.278 16.7345 149.724 16.2495C149.893 16.114 150.602 15.2124 151.447 14.1389L151.45 14.1353L151.481 14.0956C151.634 13.9015 151.791 13.7021 151.95 13.5008L153.811 11.0755L152.678 10.1862C152.071 9.66075 150.776 8.69063 149.845 8.04388L148.145 6.83123L146.364 9.13526Z" fill="black" />
        <path d="M185.826 11.4394C185.462 11.6415 180.079 7.72059 180.079 7.19511C180.079 6.50794 181.131 6.87174 183.641 8.44818C185.907 9.90336 186.676 10.9139 185.826 11.4394Z" fill="black" />
        <path d="M131.915 14.0667L134.627 16.1282C136.084 17.2196 137.419 18.1493 137.541 18.1493C137.662 18.1088 138.674 16.8962 139.767 15.441L141.75 12.7732L140.576 11.8031C139.929 11.2776 138.674 10.3479 137.743 9.70114L136.124 8.48849L131.915 14.0667Z" fill="black" />
        <path d="M175.101 14.471C174.898 14.3093 175.627 12.9754 176.72 11.5202C177.772 10.065 178.824 8.89277 179.027 8.85234C179.35 8.85234 184.531 12.5307 184.531 12.7732C184.531 12.8541 183.6 14.188 182.467 15.724L180.362 18.5131L177.893 16.6537C176.517 15.6432 175.263 14.6326 175.101 14.471Z" fill="black" />
        <path d="M120.906 10.9138C119.773 11.7626 119.449 12.4902 120.137 12.4902C120.339 12.4902 120.906 11.9243 121.392 11.2776C122.404 9.86283 122.363 9.8224 120.906 10.9138Z" fill="black" />
        <path d="M122.039 13.6625C121.877 13.5008 122.201 12.7328 122.808 11.9244C123.415 11.116 124.104 10.4692 124.306 10.4692C124.792 10.4692 129.487 14.0667 129.487 14.4305C129.487 14.5518 129.001 15.3602 128.434 16.2091L127.382 17.7047L124.873 15.8453C123.537 14.8347 122.242 13.8242 122.039 13.6625Z" fill="black" />
        <path d="M164.78 13.1775L162.756 15.8857L163.849 16.6942C164.132 16.9165 164.587 17.2701 165.088 17.6591C165.589 18.0482 166.136 18.4727 166.601 18.8365L168.301 20.1704L170.325 17.5026C171.458 16.007 172.389 14.6731 172.389 14.5114C172.389 14.2689 167.37 10.4692 167.006 10.4692C166.884 10.4692 165.913 11.6819 164.78 13.1775Z" fill="black" />
        <path d="M187.07 12.31C187.11 12.1964 187.156 12.0633 187.202 11.9244C187.324 11.5606 187.567 11.2777 187.729 11.2777C187.85 11.2777 189.105 12.2882 190.521 13.5413C192.221 15.0773 192.95 15.9666 192.666 16.2496C192.424 16.4921 191.371 15.9666 189.631 14.6731C188.174 13.6222 186.96 12.6925 186.96 12.652C186.96 12.6271 187.006 12.4941 187.07 12.3104L187.07 12.31Z" fill="black" />
        <path d="M152.961 14.5922C151.949 15.9666 151.1 17.2196 151.019 17.3813C150.897 17.6643 155.349 21.2618 156.118 21.4639C156.321 21.5448 157.454 20.3725 158.587 18.8769L160.651 16.1687L157.94 14.1476C156.402 13.0158 155.106 12.1265 154.985 12.1265C154.864 12.1265 153.973 13.2179 152.961 14.5922Z" fill="black" />
        <path d="M138.836 19.1598L140.86 16.4516C142.114 14.7943 143.126 13.8241 143.41 13.9858C145.393 15.1581 148.469 17.8663 148.226 18.2705C148.105 18.5535 147.174 19.8066 146.202 21.1L144.421 23.4041L142.722 22.1914C141.791 21.5447 140.536 20.5746 139.929 20.0491L138.836 19.1598Z" fill="black" />
        <path d="M183.722 16.5728C182.548 18.1088 181.657 19.4428 181.738 19.5236C181.779 19.5438 182.102 19.7857 182.586 20.1488L182.589 20.1506C183.074 20.5143 183.722 20.9992 184.41 21.5043C185.786 22.5552 187 23.4041 187.081 23.4041C187.607 23.4041 191.452 18.028 191.128 17.7855C190.966 17.6238 189.671 16.6132 188.295 15.6027L185.826 13.7029L183.722 16.5728Z" fill="black" />
        <path d="M107.793 21.5852C110.383 18.1494 113.014 15.0369 113.621 14.7135C114.228 14.3902 115.564 14.1476 116.616 14.1072C118.437 14.1072 118.761 14.3093 138.674 29.3462C149.764 37.7134 162.108 47.0509 166.115 50.0825C167.919 51.4473 170.798 53.6151 174.086 56.0915L174.102 56.1033L174.103 56.1037C178.115 59.1251 182.735 62.6043 186.757 65.6448C194.123 71.1826 201.044 76.4778 202.218 77.4479C204.525 79.3882 205.335 81.1667 204.889 83.3495C204.566 84.8855 195.864 96.4865 194.204 97.4971C193.314 98.1034 192.504 98.2247 191.047 98.1034C189.226 97.9417 188.093 97.1333 172.955 85.7344C119.611 45.4744 104.595 33.9947 103.826 32.9841C103.219 32.1757 102.976 31.246 103.017 29.8716C103.017 27.9718 103.259 27.5676 107.793 21.5852Z" fill="black" />
        <path d="M129.81 16.8963C129.163 17.7855 128.677 18.5535 128.677 18.6344C128.677 18.7152 129.891 19.6853 131.389 20.7767L134.06 22.7574L135.234 21.3831C135.841 20.615 136.367 19.8875 136.367 19.7258C136.367 19.4832 131.308 15.3198 131.025 15.3198C130.944 15.3198 130.417 16.0474 129.81 16.8963Z" fill="black" />
        <path d="M169.596 21.0596L173.805 15.4006L175.424 16.5728C176.315 17.2196 177.57 18.1897 178.217 18.7152L179.431 19.6449L177.448 22.3531C176.355 23.8083 175.343 25.0209 175.182 25.0209C174.979 25.0209 173.684 24.1317 172.227 23.0403L169.596 21.0596Z" fill="black" />
        <path d="M193.84 17.4217C193.84 17.7046 194.609 18.4727 195.581 19.1194L197.28 20.2916L195.662 18.6343C193.921 16.7749 193.84 16.6941 193.84 17.4217Z" fill="black" />
        <path d="M158.061 22.9999C157.859 22.7978 158.547 21.5043 159.68 19.9683C160.813 18.4322 161.866 17.4217 162.149 17.5025C163.08 17.8663 167.127 21.0596 167.127 21.4639C167.127 21.666 166.237 22.9595 165.185 24.3742L163.242 26.9612L160.813 25.1422C159.518 24.1721 158.264 23.202 158.061 22.9999Z" fill="black" />
        <path d="M147.7 21.666L145.636 24.3743L146.566 25.1018L146.755 25.2469C147.354 25.7074 148.458 26.5568 149.4 27.2442L151.302 28.6185L153.407 25.789C154.54 24.253 155.43 22.9191 155.349 22.8382C155.309 22.818 154.985 22.5755 154.499 22.2116C154.014 21.8478 153.366 21.3628 152.678 20.8576C151.302 19.8066 150.088 18.9578 149.966 18.9578C149.845 18.9578 148.833 20.1704 147.7 21.666Z" fill="black" />
        <path d="M188.862 24.0913C188.983 23.8083 189.914 22.5553 190.926 21.2618L192.707 18.9173L194.164 20.0087C194.875 20.5159 195.869 21.2778 196.578 21.8209L196.781 21.9768C196.843 22.024 196.902 22.0687 196.956 22.1106L198.292 23.0807L197.078 24.7785C196.835 25.0921 196.562 25.4561 196.288 25.8224C195.783 26.4947 195.273 27.1751 194.933 27.5675L194.042 28.6994L191.29 26.6379C189.59 25.3444 188.659 24.3742 188.862 24.0913Z" fill="black" />
        <path d="M136.57 22.1914C135.962 23.0807 135.517 23.8892 135.598 23.9296C135.639 23.97 135.973 24.2226 136.473 24.6015L136.474 24.6024L136.477 24.6045C136.978 24.9832 137.644 25.4872 138.35 26.0315L140.941 27.9717L142.074 26.5974C142.722 25.8294 143.207 25.021 143.207 24.8189C143.207 24.4955 138.877 21.0596 137.986 20.6554C137.824 20.615 137.217 21.3022 136.57 22.1914Z" fill="black" />
        <path d="M176.436 26.1932L178.541 23.4041C179.796 21.7468 180.807 20.7363 181.091 20.8979C182.629 21.8681 186.15 24.7784 186.069 25.0209C185.786 25.6273 182.305 30.2353 182.062 30.2758C181.941 30.2758 180.605 29.3461 179.148 28.2547L176.436 26.1932Z" fill="black" />
        <path d="M166.601 25.0209C165.549 26.3549 164.699 27.6079 164.699 27.7696C164.699 28.093 169.677 31.8926 170.082 31.8926C170.325 31.8926 173.563 27.6079 173.846 26.8803C174.048 26.3549 169.677 22.5956 168.827 22.5956C168.625 22.5956 167.613 23.687 166.601 25.0209Z" fill="black" />
        <path d="M152.557 29.5482C152.557 29.4673 153.407 28.2143 154.459 26.7995C155.511 25.3847 156.523 24.2125 156.725 24.2125C156.928 24.2125 158.183 25.0614 159.559 26.1123C160.935 27.1229 162.149 28.0526 162.189 28.093C162.432 28.2547 158.466 33.4691 158.061 33.5095C157.697 33.5095 152.557 29.8311 152.557 29.5482Z" fill="black" />
        <path d="M197.483 26.7187C196.471 28.093 195.621 29.3461 195.54 29.4674C195.5 29.6291 196.673 30.68 198.13 31.8522L200.883 33.9542L201.773 32.8224C203.756 30.3971 203.756 30.1545 202.34 27.6888C201.044 25.4252 200.073 24.2126 199.587 24.2126C199.425 24.2126 198.495 25.3444 197.483 26.7187Z" fill="black" />
        <path d="M142.6 29.0227C142.479 28.8206 142.843 28.0122 143.45 27.2441C144.017 26.4761 144.624 25.8294 144.745 25.8294C145.069 25.8294 150.128 29.5886 150.128 29.8311C150.128 30.0737 148.024 33.1053 147.862 33.1053C147.578 33.1053 142.883 29.4269 142.6 29.0227Z" fill="black" />
        <path d="M185.381 28.5781L183.357 31.2863L184.45 32.1756C185.057 32.7011 186.312 33.6712 187.243 34.3179L188.943 35.5306L190.724 33.2265C191.695 31.933 192.626 30.68 192.747 30.397C192.99 29.9928 189.914 27.2846 187.931 26.1123C187.648 25.9506 186.636 26.9208 185.381 28.5781Z" fill="black" />
        <path d="M171.579 32.984C171.579 32.7819 172.429 31.448 173.482 30.0332C174.534 28.6185 175.505 27.4867 175.627 27.5271C176.436 27.8909 180.848 31.3267 180.888 31.6501C180.888 32.0139 177.205 37.1474 176.922 37.1474C176.517 37.1474 171.579 33.3074 171.579 32.984Z" fill="black" />
        <path d="M161.461 31.731C160.368 33.2266 159.437 34.5605 159.437 34.6818C159.478 34.8839 164.092 38.4006 164.699 38.6835C164.982 38.8048 168.625 34.318 168.827 33.5904C168.908 33.3883 167.734 32.2565 166.237 31.1247L163.525 29.0632L161.461 31.731Z" fill="black" />
        <path d="M149.319 33.9946C149.319 33.8733 149.804 33.0649 150.371 32.216C151.261 30.9225 151.545 30.7608 152.111 31.2055C152.341 31.3584 152.925 31.7845 153.619 32.2913L153.621 32.2926L153.63 32.2994L153.632 32.301C154.035 32.5953 154.475 32.9163 154.904 33.2265C156.037 34.1158 157.009 34.8838 157.009 35.0051C157.009 35.4093 154.864 37.9559 154.499 37.9559C154.014 37.9559 149.319 34.3583 149.319 33.9946Z" fill="black" />
        <path d="M192.18 33.7521C191.047 35.2073 190.238 36.5412 190.44 36.7029C190.845 37.0262 194.649 39.977 195.297 40.4621C195.54 40.6238 196.552 39.6941 197.887 37.9559C199.061 36.3795 199.951 35.0456 199.87 35.0052C196.39 32.3373 194.69 31.0843 194.528 31.0843C194.407 31.0843 193.354 32.2969 192.18 33.7521Z" fill="black" />
        <path d="M202.34 34.803C202.34 34.6818 202.744 33.9542 203.271 33.2266C203.756 32.499 204.282 31.8927 204.444 31.8927C204.728 31.8927 207.197 38.0772 206.994 38.2793C206.832 38.4814 202.34 35.1264 202.34 34.803Z" fill="black" />
        <path d="M180.322 35.2072C179.31 36.622 178.5 37.9155 178.541 38.158C178.581 38.3601 179.755 39.4111 181.131 40.462L183.681 42.4023L185.705 39.7749C186.838 38.3197 187.769 37.0262 187.769 36.8645C187.728 36.5815 183.236 33.1053 182.467 32.7819C182.305 32.7011 181.334 33.7924 180.322 35.2072Z" fill="black" />
        <path d="M166.399 39.6132C166.48 39.4515 167.33 38.1984 168.342 36.8241C169.353 35.4498 170.244 34.318 170.325 34.318C170.406 34.318 171.701 35.2477 173.239 36.3795L176.032 38.441L174.129 40.9067C173.117 42.281 172.106 43.5745 171.944 43.7766C171.62 44.1404 166.196 40.0578 166.399 39.6132Z" fill="black" />
        <path d="M157.252 37.3091C156.685 38.0771 156.199 38.8856 156.199 39.0877C156.199 39.4919 160.206 42.6852 161.178 43.049C161.501 43.1703 162.189 42.5639 162.797 41.7151L163.93 40.179L162.797 39.2898C162.392 38.9613 161.75 38.475 161.108 37.9886C160.723 37.6968 160.338 37.4051 160.004 37.1474L158.304 35.9348L157.252 37.3091Z" fill="black" />
        <path d="M101.964 36.9454C101.762 37.0667 101.56 36.9858 101.56 36.7837C101.56 36.5412 101.762 36.3391 101.964 36.3391C102.207 36.3391 102.369 36.4199 102.369 36.5008C102.369 36.622 102.207 36.8241 101.964 36.9454Z" fill="black" />
        <path d="M198.98 38.9261C197.928 40.3409 197.078 41.5939 197.078 41.6748C197.078 41.8769 202.178 45.6361 202.461 45.6361C202.744 45.6361 206.913 40.3409 206.711 40.2196C206.677 40.2029 206.457 40.0344 206.114 39.7711C205.626 39.3971 204.888 38.8319 204.08 38.2389C202.704 37.188 201.449 36.3391 201.247 36.3391C201.045 36.3391 200.033 37.5113 198.98 38.9261Z" fill="black" />
        <path d="M99.7787 43.2511C99.6573 43.049 100.426 41.7151 101.479 40.2195C102.531 38.7643 103.462 37.5517 103.543 37.5517C103.624 37.5517 104.959 38.4814 106.457 39.6536L109.209 41.7151L107.347 44.1808C106.295 45.5552 105.324 46.8082 105.162 47.0104C104.878 47.2933 100.305 44.1404 99.7787 43.2511Z" fill="black" />
        <path d="M187 40.6237L184.936 43.2915L186.069 44.1404C186.676 44.585 187.931 45.5551 188.862 46.3232L190.521 47.6571L192.585 44.868C193.719 43.332 194.65 42.0385 194.65 41.9172C194.65 41.7151 189.55 37.9559 189.226 37.9559C189.105 37.9559 188.093 39.1685 187 40.6237Z" fill="black" />
        <path d="M173.198 44.9892C173.198 44.8276 174.048 43.5745 175.101 42.1597C176.153 40.745 177.084 39.5727 177.205 39.5727C177.327 39.5727 178.662 40.5024 180.2 41.6342L182.912 43.6958L181.576 45.353C181.334 45.6629 181.059 46.0087 180.785 46.3545C180.236 47.0462 179.688 47.7379 179.391 48.1421L178.541 49.2739L175.87 47.2124C174.413 46.1211 173.198 45.1105 173.198 44.9892Z" fill="black" />
        <path d="M163.93 42.7661L162.635 44.3829L165.346 46.404C166.844 47.5358 168.18 48.4655 168.301 48.4655C168.422 48.4655 169.029 47.7379 169.677 46.8486L170.77 45.2722L168.463 43.4936C167.168 42.5235 165.913 41.5938 165.63 41.4726C165.387 41.3109 164.658 41.8364 163.93 42.7661Z" fill="black" />
        <path d="M204.161 46.5253C204.242 46.2827 205.092 45.0297 206.023 43.817C206.954 42.5639 207.884 41.7151 208.046 41.8768C208.532 42.3618 209.868 50.3653 209.544 50.6887C209.139 51.0929 203.959 47.0912 204.161 46.5253Z" fill="black" />
        <path d="M108.319 45.3935C107.266 46.8082 106.416 48.0613 106.416 48.1826C106.416 48.4655 111.719 52.6289 111.84 52.4268C111.857 52.3935 112.025 52.174 112.289 51.831L112.289 51.8303C112.664 51.3427 113.229 50.6059 113.823 49.7994C114.875 48.4251 115.725 47.172 115.725 46.9699C115.725 46.6465 110.788 42.8065 110.383 42.8065C110.302 42.8065 109.371 43.9787 108.319 45.3935Z" fill="black" />
        <path d="M191.897 48.6272C191.816 48.5868 192.707 47.2529 193.88 45.7168L196.025 42.8469L197.644 44.0595C198.575 44.7063 199.83 45.636 200.478 46.1615L201.651 47.1316L199.668 49.7994C198.575 51.2546 197.523 52.4672 197.361 52.5077C197.159 52.5077 195.904 51.6588 194.528 50.6079C193.152 49.5569 191.978 48.6676 191.897 48.6272Z" fill="black" />
        <path d="M182.143 47.0508C181.212 48.2634 180.362 49.5165 180.241 49.7995C180.038 50.3249 183.681 53.4374 185.057 53.9629C185.583 54.165 188.7 50.5271 189.226 49.1527C189.428 48.6272 185.786 45.5148 184.41 44.9893C184.045 44.868 183.155 45.6764 182.143 47.0508Z" fill="black" />
        <path d="M99.0502 53.68C98.2003 53.2354 98.2003 52.0227 99.0098 46.7275C99.1312 45.919 99.3336 45.2319 99.455 45.2319C99.5764 45.2319 100.629 45.9595 101.843 46.8892L104.029 48.5465L103.017 49.9208C102.733 50.3046 102.349 50.8097 101.965 51.3147L101.964 51.3154C101.58 51.8206 101.195 52.3259 100.912 52.7099C100.022 53.9225 99.7383 54.0438 99.0502 53.68Z" fill="black" />
        <path d="M170.648 47.9804L169.515 49.476L170.648 50.3653C171.136 50.7876 172.068 51.4707 172.899 52.0794C173.102 52.2282 173.299 52.3727 173.482 52.5077L175.141 53.7203L176.436 52.1035C177.165 51.1738 177.691 50.4057 177.61 50.3249C177.569 50.3047 177.246 50.0624 176.761 49.6988L176.756 49.6952C176.271 49.3317 175.625 48.848 174.939 48.3442C173.563 47.2933 172.308 46.4444 172.105 46.4444C171.944 46.4848 171.256 47.1316 170.648 47.9804Z" fill="black" />
        <path d="M113.297 53.2353C113.297 53.0332 114.147 51.7802 115.159 50.4462C116.333 48.9102 117.223 48.1018 117.587 48.2231C118.478 48.5464 122.606 51.7802 122.606 52.1035C122.606 52.7503 118.882 57.1562 118.397 57.1158C117.668 57.035 113.297 53.7204 113.297 53.2353Z" fill="black" />
        <path d="M201.692 49.5974C201.085 50.4059 200.154 51.6589 199.628 52.3865L198.656 53.6396L201.004 55.4181C202.299 56.4287 203.554 57.3584 203.837 57.4796C204.242 57.7222 206.953 54.6501 208.127 52.6695C208.37 52.2652 205.456 49.7187 203.311 48.3848C203.028 48.1827 202.38 48.6677 201.692 49.5974Z" fill="black" />
        <path d="M100.993 55.0543L102.976 52.3461C104.069 50.8909 105.121 49.6782 105.283 49.6782C105.486 49.6782 106.781 50.6079 108.238 51.6993L110.828 53.7608L106.619 59.2582L105 58.1264C104.109 57.4796 102.855 56.5095 102.207 55.984L100.993 55.0543Z" fill="black" />
        <path d="M188.7 52.5481L186.595 55.2968L189.347 57.3178C190.885 58.4497 192.181 59.3794 192.302 59.3794C192.504 59.3794 196.268 54.2862 196.268 54.0033C196.268 53.7607 192.059 50.4866 191.209 50.0015C191.007 49.8803 189.874 51.0121 188.7 52.5481Z" fill="black" />
        <path d="M176.274 54.6501L177.61 52.9524L178.946 51.2951L180.605 52.629C180.915 52.8715 181.262 53.1454 181.608 53.4193L181.608 53.4196C182.301 53.9675 182.993 54.5154 183.398 54.8118L184.491 55.6202L183.276 57.3179L182.022 58.9752L180.362 57.7626C179.431 57.0754 178.136 56.1053 177.489 55.5798L176.274 54.6501Z" fill="black" />
        <path d="M121.877 55.7415L119.813 58.4902L122.485 60.5517C123.942 61.6835 125.237 62.6132 125.399 62.6132C125.642 62.6132 129.487 57.5605 129.487 57.1967C129.487 56.9946 125.318 53.7608 124.387 53.1949C124.144 53.0737 123.051 54.2055 121.877 55.7415Z" fill="black" />
        <path d="M206.265 57.803C206.67 57.2371 207.561 55.984 208.208 55.0543C208.896 54.1246 209.544 53.3566 209.746 53.3162C210.11 53.3162 210.07 59.6219 209.706 60.875C209.422 61.7239 209.382 61.7239 207.48 60.3091L205.537 58.8944L206.265 57.803Z" fill="black" />
        <path d="M110.059 57.2371C108.966 58.6922 108.035 60.0262 108.035 60.1878C108.035 60.3091 109.25 61.3197 110.707 62.411L113.418 64.3917L114.349 63.179C114.875 62.5323 115.847 61.2792 116.494 60.39L117.628 58.7731L114.875 56.6712L112.083 54.5692L110.059 57.2371Z" fill="black" />
        <path d="M193.476 60.3899L195.499 57.6413C196.633 56.1457 197.645 54.933 197.766 54.933C198.211 54.933 203.189 59.0964 203.068 59.3794C202.987 59.5815 202.056 60.8346 201.004 62.1685L199.061 64.6746L197.361 63.4216C196.43 62.7748 195.176 61.8047 194.569 61.2792L193.476 60.3899Z" fill="black" />
        <path d="M99.0097 57.5604C98.4026 58.4093 98.3217 59.0156 98.5645 61.4005C98.8074 63.947 98.9693 64.3108 100.143 65.2001L101.438 66.1702L103.502 63.3811C104.676 61.8451 105.607 60.5516 105.607 60.4708C105.607 60.2282 100.426 56.5499 100.103 56.5499C99.9002 56.5499 99.4145 57.0349 99.0097 57.5604Z" fill="black" />
        <path d="M183.317 59.8644C183.317 59.7836 183.802 59.0156 184.45 58.1263C185.057 57.2774 185.583 56.5498 185.664 56.5498C185.705 56.5498 187.04 57.4795 188.578 58.6518L191.371 60.7537L190.157 62.2897C189.469 63.1386 188.821 63.8257 188.7 63.8257C188.416 63.8257 183.317 60.0665 183.317 59.8644Z" fill="black" />
        <path d="M128.718 60.875L126.694 63.6236L127.787 64.5129C128.394 65.0384 129.648 66.0085 130.579 66.6553L132.279 67.8679L134.06 65.5639C135.032 64.2704 135.962 63.0173 136.084 62.7344C136.246 62.4514 135.315 61.4409 133.736 60.2282C132.32 59.0964 131.065 58.1667 130.944 58.1667C130.863 58.1667 129.851 59.3794 128.718 60.875Z" fill="black" />
        <path d="M114.916 65.3214C114.916 65.1597 115.766 63.8258 116.859 62.4111C117.911 60.9963 118.801 59.8241 118.882 59.7836C118.923 59.7836 120.218 60.7133 121.756 61.8856L124.589 63.9471L122.727 66.4128C121.675 67.7871 120.704 69.0402 120.542 69.2423C120.218 69.6061 114.956 65.9277 114.916 65.3214Z" fill="black" />
        <path d="M202.34 62.8961C201.206 64.3917 200.437 65.7257 200.599 65.8469C200.802 66.0086 202.056 66.9787 203.392 67.9893L205.901 69.8891L207.48 67.7467C209.949 64.4726 209.949 64.1088 207.196 62.0069C205.901 60.9963 204.728 60.1879 204.606 60.1879C204.485 60.1879 203.473 61.4005 202.34 62.8961Z" fill="black" />
        <path d="M102.814 67.2212C102.733 67.1403 103.624 65.8064 104.797 64.2704L106.902 61.4004L109.371 63.3003C110.747 64.3108 112.002 65.2809 112.204 65.4426C112.488 65.6851 108.683 71.1016 108.197 71.1016C108.076 71.1016 106.862 70.2528 105.486 69.2018C104.798 68.6969 104.151 68.2121 103.665 67.8484L103.664 67.8476C103.178 67.4839 102.855 67.2414 102.814 67.2212Z" fill="black" />
        <path d="M191.169 63.4619C190.521 64.4321 190.197 65.2001 190.44 65.3618C190.521 65.4263 190.763 65.607 191.103 65.86L191.104 65.861C191.614 66.2413 192.342 66.7846 193.071 67.3424C194.326 68.3125 195.419 69.0806 195.54 69.0806C195.621 69.0806 196.309 68.353 196.997 67.5041L198.292 65.9277L195.499 63.8662C193.961 62.7344 192.626 61.8047 192.545 61.8047C192.423 61.8047 191.816 62.5322 191.169 63.4619Z" fill="black" />
        <path d="M133.777 69.0401C133.574 68.8784 134.384 67.5445 135.517 66.0893C136.691 64.6342 137.743 63.4215 137.865 63.4215C137.946 63.4215 139.16 64.2704 140.536 65.3213C141.224 65.8266 141.872 66.3116 142.357 66.6754C142.843 67.0393 143.167 67.2818 143.207 67.302C143.288 67.3828 142.398 68.7168 141.224 70.2528L139.119 73.1227L136.651 71.2229C135.274 70.2124 133.979 69.2018 133.777 69.0401Z" fill="black" />
        <path d="M123.577 67.7871L121.513 70.4953L124.306 72.6781C125.844 73.8503 127.139 74.78 127.22 74.6992C127.24 74.6587 127.483 74.3355 127.847 73.8507L127.847 73.8503C128.212 73.3652 128.697 72.7185 129.203 72.0313C130.256 70.657 131.106 69.4039 131.106 69.2018C131.106 68.9189 126.127 65.0384 125.763 65.0384C125.682 65.0384 124.711 66.251 123.577 67.7871Z" fill="black" />
        <path d="M109.654 72.0718C109.654 71.8293 111.759 69.0806 113.054 67.585L113.823 66.6553L115.28 67.7467C115.538 67.9401 115.842 68.1664 116.158 68.402L116.16 68.4037C116.834 68.9062 117.564 69.4507 118.032 69.8082L119.368 70.7783L118.154 72.476C117.911 72.7898 117.638 73.1539 117.363 73.5204C116.859 74.1926 116.349 74.8727 116.009 75.2651L115.118 76.3969L112.366 74.3354C110.869 73.2036 109.654 72.1931 109.654 72.0718Z" fill="black" />
        <path d="M198.13 68.4338C197.564 69.2018 197.078 69.9698 197.078 70.1719C197.119 70.5357 201.935 74.1333 202.421 74.1333C202.744 74.1333 204.768 71.5059 204.768 71.1016C204.768 70.8591 199.749 67.0595 199.385 67.0595C199.304 67.0595 198.738 67.6658 198.13 68.4338Z" fill="black" />
        <path d="M96.9051 76.5586C97.6742 72.3952 98.3217 68.7976 98.3217 68.5955C98.3217 68.3934 98.4836 68.2721 98.7265 68.353C99.2122 68.5147 157.818 112.776 157.818 112.978C157.818 113.059 154.58 114.757 150.614 116.738L143.41 120.335L120.137 102.752C107.347 93.091 96.5814 84.9259 96.2171 84.6429C95.529 84.1983 95.5695 83.7132 96.9051 76.5586Z" fill="black" />
        <path d="M142.438 71.1826C141.264 72.7186 140.455 74.1333 140.657 74.2546C140.752 74.3176 140.988 74.4971 141.317 74.7477L141.32 74.7497L141.32 74.75L141.321 74.7505C141.836 75.1432 142.58 75.7094 143.369 76.2757C144.624 77.2458 145.838 77.933 146.04 77.7713C146.971 77.2054 150.209 72.6377 149.885 72.3548C149.683 72.1931 148.428 71.223 147.052 70.172L144.624 68.353L142.438 71.1826Z" fill="black" />
        <path d="M207.561 71.1016C207.034 71.1016 207.115 70.5357 207.682 69.9698C208.33 69.3231 208.37 69.3635 208.127 70.2932C208.006 70.7378 207.763 71.1016 207.561 71.1016Z" fill="black" />
        <path d="M130.336 72.9611L128.272 75.6694L130.903 77.6096C132.32 78.701 133.655 79.5903 133.858 79.5903C134.262 79.5903 137.46 75.5481 137.824 74.6184C137.986 74.1738 133.291 70.2933 132.563 70.2933C132.441 70.2933 131.429 71.5059 130.336 72.9611Z" fill="black" />
        <path d="M116.535 77.3266C116.535 77.1649 117.385 75.9118 118.437 74.4971C119.489 73.0823 120.461 71.9101 120.582 71.9101C120.744 71.9101 122.08 72.8398 123.577 73.9716L126.289 75.9927L124.144 78.7009C123.699 79.2877 123.273 79.8372 122.92 80.2932C122.372 80.9994 121.999 81.4813 121.999 81.5304C121.877 81.6921 116.535 77.5691 116.535 77.3266Z" fill="black" />
        <path d="M205.011 73.5675L203.837 75.2247L204.849 76.1949L205.821 77.165L206.549 75.1035C207.197 73.3653 207.237 72.9611 206.751 72.5165C206.266 72.0314 206.023 72.1931 205.011 73.5675Z" fill="black" />
        <path d="M147.295 79.1455C147.295 78.9838 148.226 77.6903 149.359 76.2352L151.383 73.5673L154.216 75.6288C155.876 76.8415 156.887 77.852 156.725 78.135C155.916 79.5497 152.84 83.2685 152.597 83.1473C151.95 82.8643 147.336 79.3881 147.295 79.1455Z" fill="black" />
        <path d="M137.258 77.9734L135.193 80.722L136.893 82.1368C138.957 83.7941 140.819 85.0067 140.941 84.8046C140.972 84.7735 141.129 84.5682 141.376 84.2467L141.376 84.246L141.377 84.2455L141.378 84.2437C141.772 83.7308 142.391 82.9238 143.086 82.0559L145.069 79.5094L143.855 78.5393C143.769 78.4736 143.674 78.4011 143.572 78.3231C142.858 77.7765 141.796 76.9629 140.981 76.3969L139.362 75.1843L137.258 77.9734Z" fill="black" />
        <path d="M123.577 82.1368C123.699 81.8538 124.549 80.6007 125.48 79.3881C126.411 78.1754 127.301 77.1649 127.463 77.1649C128.03 77.1649 132.725 80.8433 132.725 81.2879C132.725 82.0963 128.92 86.5023 128.394 86.3002C127.018 85.7747 123.416 82.6622 123.577 82.1368Z" fill="black" />
        <path d="M156.199 81.288C155.187 82.6623 154.337 83.9154 154.256 84.0771C154.135 84.36 158.587 87.9576 159.356 88.1597C159.559 88.2405 160.692 87.0683 161.825 85.5727L163.889 82.9048L161.097 80.8433C159.559 79.7115 158.263 78.7818 158.182 78.7818C158.102 78.7818 157.211 79.9136 156.199 81.288Z" fill="black" />
        <path d="M144.371 82.8133C144.607 82.497 144.834 82.1931 145.028 81.9347C145.717 81.0454 146.364 80.5199 146.647 80.6816C148.793 82.0155 151.707 84.6025 151.464 84.9663C150.29 87.0278 147.578 90.0594 147.174 89.8169C146.89 89.6552 145.636 88.7255 144.34 87.7554L141.993 85.9768L142.964 84.6834C143.32 84.219 143.86 83.4962 144.361 82.8269L144.367 82.8185L144.371 82.8133Z" fill="black" />
        <path d="M132.239 84.6833C131.146 86.0981 130.296 87.3916 130.296 87.5937C130.296 87.8766 135.234 91.7167 135.639 91.7167C135.841 91.7167 139.605 86.6235 139.605 86.3406C139.605 86.1385 135.436 82.8239 134.546 82.3389C134.343 82.2176 133.291 83.2685 132.239 84.6833Z" fill="black" />
        <path d="M160.894 89.4127L162.878 86.7045C163.97 85.2493 164.982 84.0366 165.104 84.0366C165.265 84.0366 166.601 84.9663 168.058 86.0577L170.729 88.0788L168.625 90.8679L166.52 93.6166L164.901 92.4848C164.011 91.838 162.756 90.8679 162.109 90.3424L160.894 89.4127Z" fill="black" />
        <path d="M150.938 88.3617L148.873 91.1104L150.007 91.9996C150.399 92.3393 151.079 92.8479 151.751 93.3507L151.753 93.3516L151.753 93.3522L151.754 93.3525C152.121 93.6269 152.485 93.8996 152.799 94.142L154.499 95.3546L155.552 93.9399C155.835 93.5559 156.209 93.0506 156.584 92.5453C156.958 92.0401 157.333 91.5348 157.616 91.1508L158.668 89.7764L156.321 87.9979C155.026 86.9873 153.771 86.0576 153.488 85.9364C153.204 85.7343 152.233 86.664 150.938 88.3617Z" fill="black" />
        <path d="M92.9386 128.905C91.4411 130.885 90.2269 132.785 90.2269 133.068C90.2269 134.079 95.8932 138.606 97.1074 138.606C97.5122 138.606 99.0097 137.029 100.71 134.766C103.179 131.492 103.543 130.804 103.179 130.117C102.531 128.945 97.5122 125.267 96.5408 125.267C95.9337 125.267 94.8814 126.318 92.9386 128.905Z" fill="black" />
        <path fillRule="evenodd" clipRule="evenodd" d="M65.3355 125.267C79.5418 105.824 91.684 89.2106 92.3316 88.3617C93.0196 87.5533 93.6267 86.8661 93.7077 86.8661C93.8291 86.8661 101.519 92.6464 110.828 99.6798C115.601 103.291 120.712 107.155 125.061 110.444C129.232 113.597 132.702 116.221 134.505 117.587C138.188 120.376 141.143 122.841 141.062 123.084C140.941 123.367 127.989 139.98 112.245 160.07C101.69 173.538 88.77 190.022 78.4122 203.237L78.2962 203.385C73.2519 209.82 68.8219 215.472 65.5784 219.611C46.2723 244.228 45.0581 245.562 39.4727 248.31C32.5921 251.706 24.4974 251.908 18.5477 248.795C15.836 247.421 7.37693 240.953 5.35324 238.73C-0.515475 232.384 -1.68922 221.511 2.47959 212.416C3.96608 209.206 4.21632 208.864 24.2498 181.462L24.2507 181.461L24.6997 180.846C27.6942 176.755 32.0595 170.779 37.0913 163.892L37.0964 163.885L37.1238 163.847C45.7564 152.03 56.344 137.538 65.3355 125.267ZM106.214 130.643C106.659 128.217 105.809 126.681 102.693 124.256C100.183 122.275 99.6573 122.033 97.9574 122.033C96.9051 122.033 95.61 122.356 94.9624 122.761C94.3553 123.124 90.7531 127.571 86.9485 132.623C79.5418 142.446 78.9752 143.578 80.1085 146.326C80.6751 147.66 85.2891 151.42 86.8676 151.783C88.2842 152.147 90.3079 151.662 91.5221 150.611C93.5863 148.913 105.931 132.138 106.214 130.643Z" fill="black" />
        <path d="M136.853 92.8081L138.917 90.0595C140.05 88.5235 141.103 87.3108 141.265 87.3512C142.034 87.715 146.445 91.1509 146.486 91.4338C146.486 91.7168 145.433 93.1719 142.56 96.9312C142.519 97.012 141.184 96.1227 139.646 94.9505L136.853 92.8081Z" fill="black" />
        <path d="M170.001 91.5146C169.07 92.7273 168.22 93.9803 168.099 94.2633C167.896 94.7888 171.539 97.9012 172.915 98.4267C173.441 98.6288 176.558 94.9909 177.084 93.6165C177.286 93.0911 173.644 89.9786 172.268 89.4531C171.903 89.3318 171.013 90.1403 170.001 91.5146Z" fill="black" />
        <path d="M156.078 96.5269C155.876 96.3652 156.604 95.0313 157.697 93.5761C158.749 92.121 159.802 90.9487 159.963 90.9083C160.166 90.9083 161.42 91.7572 162.797 92.8081C163.604 93.4011 164.342 93.9662 164.83 94.3402L164.831 94.3407C165.174 94.6038 165.394 94.7721 165.427 94.7888C165.508 94.8292 164.618 96.1631 163.444 97.6991L161.34 100.569L158.871 98.7097C157.495 97.6587 156.24 96.6886 156.078 96.5269Z" fill="black" />
        <path d="M145.96 94.9504C144.907 96.2843 144.017 97.5374 143.936 97.7395C143.895 97.9416 145.069 99.033 146.567 100.165L149.278 102.226L151.343 99.5585C152.435 98.0629 153.366 96.729 153.366 96.6077C153.326 96.4056 148.752 92.8889 148.105 92.606C147.943 92.5655 147.012 93.6165 145.96 94.9504Z" fill="black" />
        <path d="M174.453 99.7606L176.558 97.0119C177.731 95.4759 178.865 94.3441 179.067 94.4654C179.917 94.9504 184.126 98.2246 184.126 98.4671C184.126 98.75 180.362 103.843 180.16 103.843C180.038 103.843 178.743 102.913 177.205 101.782L174.453 99.7606Z" fill="black" />
        <path d="M164.942 98.2246C163.889 99.5585 162.958 100.892 162.837 101.175C162.675 101.58 167.411 105.46 168.099 105.46C168.22 105.46 169.232 104.247 170.325 102.792L172.348 100.124L171.256 99.2351C170.649 98.75 169.434 97.7799 168.503 97.0523L166.844 95.7993L164.942 98.2246Z" fill="black" />
        <path d="M150.654 102.954C150.695 102.833 151.585 101.58 152.638 100.246C153.73 98.8713 154.702 97.7799 154.823 97.7799C155.147 97.7799 160.247 101.499 160.247 101.741C160.247 102.145 156.402 107.077 156.118 107.077C155.592 107.077 150.574 103.277 150.654 102.954Z" fill="black" />
        <path d="M183.762 101.62C182.872 102.873 182.103 103.964 182.103 104.045C182.103 104.167 183.276 103.479 184.693 102.55C187.121 100.933 187.243 100.771 186.676 100.084C186.312 99.7202 185.907 99.3968 185.745 99.3968C185.583 99.3968 184.693 100.407 183.762 101.62Z" fill="black" />
        <path d="M169.556 106.551C169.556 106.39 170.406 105.056 171.498 103.641C172.551 102.226 173.441 101.054 173.522 101.014C173.563 101.014 174.898 101.943 176.436 103.116C179.755 105.581 179.715 105.662 175.222 107.522L172.267 108.694L170.932 107.804C170.163 107.279 169.556 106.713 169.556 106.551Z" fill="black" />
        <path d="M159.518 105.379L157.414 108.088L158.911 109.421C161.663 111.766 163.646 111.564 165.873 108.694L167.208 106.915L166.075 105.986C165.427 105.46 164.173 104.53 163.242 103.884L161.582 102.671L159.518 105.379Z" fill="black" />
        <path d="M168.341 109.826C166.642 110.23 166.601 110.068 167.856 108.613C168.301 108.087 168.625 108.007 169.191 108.33C170.365 108.936 170.122 109.381 168.341 109.826Z" fill="black" />
      </svg>

    </>
  )
}

export default MicrophoneIcon2;