import { useEffect, useState } from "react";
import {
  JobInterviewDetails,
  OfficeTarget,
  StarterDetails,
  StopperDetails,
  User,
} from "../Annotations";
import { dummyTargetData, dummyTeamLeadsData } from "../DummyData";
import Title from "components/common/atoms/Title";
import { t } from "components/CentralDataMangement/translation/Translation";
import OfficeTargetTable from "../common/OfficeTargetTable";
import { Accordion } from "react-bootstrap";
import StartersTable from "../common/StartersTable";
import StoppersTable from "../common/StoppersTable";
import JobInterviewTable from "../common/JobInterviewTable";

interface TargetData {
  officeQTarget: OfficeTarget;
  officeTarget: OfficeTarget;
}

interface TeamLeadsData {
  teamLeads: User[];
  starters: StarterDetails[];
  stoppers: StopperDetails[];
  jobInterviews: JobInterviewDetails[];
}

const MonthlyReports: React.FC = () => {
  const [targetData, setTargetData] = useState<TargetData>();
  const [teamLeadsData, setTeamLeadsData] = useState<TeamLeadsData>({
    teamLeads: [],
    starters: [],
    stoppers: [],
    jobInterviews: [],
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setTargetData({
      officeQTarget: dummyTargetData.officeTarget,
      officeTarget: dummyTargetData.officeTarget,
    });
    setTeamLeadsData(dummyTeamLeadsData);
  };

  const getStarters = (id: number | string) => {
    return teamLeadsData.starters.filter((data) => data.created_by === id);
  };

  const getStoppers = (id: number | string) => {
    return teamLeadsData.stoppers.filter((data) => data.created_by === id);
  };

  return (
    <>
      <Title title={t("Reporting (monthly team lead)")} />
      <div className="row mb-3">
        <div className="col-md-6">
          <OfficeTargetTable
            data={targetData?.officeQTarget}
            tableTitle="Office"
          />
        </div>
        <div className="col-md-6 float-right">
          <OfficeTargetTable
            data={targetData?.officeQTarget}
            tableTitle="Office Q"
          />
        </div>
      </div>
      <div className="row">
        {teamLeadsData.teamLeads.map((teamLead: User, index) => (
          <Accordion className="mb-3">
            <Accordion.Item eventKey="">
              <Accordion.Header className="rounded-3 text-center align-items-center">
                <strong>{teamLead.name}</strong>
              </Accordion.Header>
              <Accordion.Body className="bg-white rounded-3">
                <StartersTable
                  data={getStarters(teamLead.id)}
                  view="tl"
                  tableTitle="STARTERS"
                />
                <StoppersTable
                  data={getStoppers(teamLead.id)}
                  view="tl"
                  tableTitle="STOPPER"
                />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        ))}
      </div>
      <div className="row my-3">
        <div className="col-12">
          <JobInterviewTable
            data={teamLeadsData.jobInterviews}
            view="tl"
            tableTitle="JOB INTERVIEWS"
          />
        </div>
      </div>
    </>
  );
};

export default MonthlyReports;
