import { t } from "components/CentralDataMangement/translation/Translation";
import LabelField from "components/common/atoms/LabelField";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import React from "react";

interface WorkTypeName {
  id: number | null;
  name: string;
  value: number | null;
}

interface propsObj {
  data: WorkTypeName;
  id: number;
  changeHandler: (value: any, index: number) => void;
}
const ChildComponent: React.FC<propsObj> = ({ data, id, changeHandler }) => {
  return (
    <>
      <AccessControl
        requiredPermissions={[
          {
            permission: "Work type extra",
            read: true,
          },
        ]}
        renderNoAccess={true}
      >
        <tr key={id} className="align-middle border box-shadow mb-3 mb-lg-0">
          <td className="ps-lg-4 p-3" data-label={t("Work type extra")}>
            <LabelField
              title={t(`${data.name}`)}
              className="col-md-12 align-self-center"
            />
          </td>
          <td className="workTypeFactor p-3" data-label={t("Factor")}>
            <LabelWithInputField
              handleChange={(event) => changeHandler(event, id)}
              name="value"
              type="text"
              value={data.value ?? ""}
            />
          </td>
        </tr>
      </AccessControl>
    </>
  );
};

export default ChildComponent;
