import secureLocalStorage from "react-secure-storage";
import { getRefreshToken } from "routes/ApiEndpoints";

export async function GenerateRefreshToken(): Promise<any> {
    const body:any = {
        "api_key":process.env.REACT_APP_SERVICE_API 
      }
    let token:string = '';      
    const response = await fetch((getRefreshToken+'/'), {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
          },
        body: JSON.stringify(body),
    });
    const data = await response.json();
    const refreshToken = {
      token:data.token
    }
    const tokenJson = JSON.stringify(refreshToken);
    secureLocalStorage.setItem("refresh_token", tokenJson);
    
    if(data.token !== '' || data.token !== null )  token = data.token
    return token;
}
