import { t } from "components/CentralDataMangement/translation/Translation";
import Button from "components/common/atoms/Button";
import {
  AdminFicheFormData,
  WorkingHoursProps,
} from "components/CompanyCreation/AdminFiche/annotations/AdminFicheInterface";
import React from "react";
import AddPlusIcon from "static/images/AddPlusIcon";
import DeleteIcon from "static/images/DeleteIcon";

interface props {
  state: AdminFicheFormData;
  addTimeTable: (action: any) => void;
  deleteTimeTable: (tableId: number) => void;
  handleTabClick: (tableId: number) => void;
  activeTab: number | null;
}

const TableNames: React.FC<props> = ({
  state,
  addTimeTable,
  deleteTimeTable,
  handleTabClick,
  activeTab,
}) => {
  const shiftTables = Object.values(state.shiftTables ?? {});

  return (
    <div className="weeklyWorkingHours weeklyWorkingHoursTable">
      <table className="table table-borderless mb-0">
        <thead>
          <tr className="align-middle border-bottom">
            <th className="text-start ps-3">{t("Timetables")}</th>
            <th className="table-action-icons" style={{ width: "5%" }}>
              <Button
                className="btn p-0 border-0 table-action-icons weeklyPlanningDeleteIcon"
                handleClick={addTimeTable}
              >
                <span title={t("Add timetable")}>
                  <AddPlusIcon />
                </span>
              </Button>
            </th>
          </tr>
        </thead>
        <tbody>
          {shiftTables.length > 0 &&
            shiftTables.map(
              (shiftTable: WorkingHoursProps, tableId: number) => {
                const error = shiftTable.nameError !== "" || shiftTable.regimeError !== "" || shiftTable.shiftError !== "";                
                return (
                  <tr
                    className={`${
                      activeTab === tableId ? "activeWeeklyPlanningData" : ""
                    } ${error ? "error-border" : ""} align-middle`}
                    key={`row-${tableId}`}
                  >
                    <td key={`name-${tableId}`} className="text-start py-0 cursor-pointer">
                      <div
                        className="nav flex-column nav-pills"
                        id="v-pills-tab"
                        role="tablist"
                        aria-orientation="vertical"
                      >
                        <button
                          key={tableId}
                          className={`nav-link py-0 ${
                            activeTab === tableId ? "active" : ""
                          } text-start ps-2`}
                          id={`tab-${tableId}-tab`}
                          data-bs-toggle="pill"
                          data-bs-target={`#tab-${tableId}`}
                          type="button"
                          role="tab"
                          aria-controls={`tab-${tableId}`}
                          aria-selected={activeTab === tableId}
                          onClick={() => handleTabClick(tableId)}
                        >
                          {shiftTable.name}
                        </button>
                      </div>
                    </td>
                    <td
                      className="table-action-icons p-2"
                      style={{ width: "5%" }}
                      key={`action-${tableId}`}
                    >
                      <Button
                        className="btn p-0 border-0 table-action-icons weeklyPlanningDeleteIcon"
                        handleClick={() => deleteTimeTable(tableId)}
                      >
                        <span title={t("Delete timetable")}>
                          <DeleteIcon />
                        </span>
                      </Button>
                    </td>
                  </tr>
                );
              }
            )}
        </tbody>
      </table>
    </div>
  );
};

export default React.memo(TableNames);
