import { CSSProperties } from "react";

interface ImageFieldProps {
  id?: string;
  altText?: string;
  className?: string;
  src?:string;
  style?:CSSProperties;
}

export default function ImageField({
  src = "",
  id = "",
  altText = "",
  style,
  className = "border-blue form-control shadow-none rounded-2 text-blue",
}: ImageFieldProps) {
  return (
    <>
      <img
        style={style}
        className={className} 
        src={src} 
        alt={altText} 
        id={id}
    />
    </>
  );
}
