import Calender from "components/common/molecules/Calender";
import TimeInput from "components/common/molecules/TimeInput";
import React from "react";
import { t, translate } from "../translation/Translation";
import Button from "components/common/atoms/Button";
import CloseFile from "static/images/CloseFile";
import { AcceptData } from "./CompanyPreInterviewPage";
import PlusWithoutFillIcon from "static/images/PlusWithoutFillIcon";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";

interface TimeSlots {
  acceptData: AcceptData[];
  handleDynamicDateChange: (
    date: Date | null,
    name: string,
    index: number
  ) => void;
  handleTimeChange: (
    index: number,
    name: string,
    value: string,
    field: string
  ) => void;
  removeFieldSet: (currentIndex: number) => void;
  addFieldSet: () => void;
  errors?: { from: string; to: string }[];
}

const TimeSlot: React.FC<TimeSlots> = ({
  acceptData,
  handleDynamicDateChange,
  handleTimeChange,
  errors,
  removeFieldSet,
  addFieldSet,
}) => {
  return (
      <div>
        <div className="row">
          <div>
            {acceptData.map((item, index) => (
              <div className="row" key={index}>
                <div className="col-md-3 position-relative">
                  <Calender
                    onChange={(date) =>
                      handleDynamicDateChange(date, "date", index)
                    }
                    selectedDate={item.date}
                    label={t("Date")}
                    name="date"
                    minDate={new Date()}
                    isMandatory={errors !== undefined ? index === 0 : false}
                  />
                </div>
                <div className="col-8">
                  <div className="d-flex">
                    <div className="me-4">
                      <div className="col-sm-12 col-md-12 pc-buffer-time time-section">
                        <div className="position-relative">
                          <TimeInput
                            setTime={(e) =>
                              handleTimeChange(index, "from", e, "time")
                            }
                            index={index}
                            type="time"
                            value={item.from}
                            title={t("From")}
                            key={"from" + index}
                            isMandatory={errors !== undefined ? index === 0 : false}
                          />
                        </div>
                        <div className="height-20" style={{marginBottom: "0.5vw"}}>
                          {errors && errors[index] && errors[index].from && (
                            <span className="text-danger">
                              {errors[index].from}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="me-4">
                      <div className="col-sm-12 col-md-12 pc-buffer-time time-section">
                        <div className="position-relative">
                          <TimeInput
                            setTime={(e) =>
                              handleTimeChange(index, "to", e, "time")
                            }
                            index={index}
                            type="time"
                            value={item.to}
                            title={t("To")}
                            key={"to" + index}
                            isMandatory={false}
                          />
                        </div>
                        <div className="height-20" style={{marginBottom: "0.5vw"}}>
                          {errors && errors[index] && errors[index].to && (
                            <span className="text-danger">{errors[index].to}</span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="closeIconScheduling mt-35">
                      <>
                        <div className="d-block d-md-block d-lg-none">
                          {index > 0 && (
                            <Button
                              title={t("Remove")}
                              handleClick={() => removeFieldSet(index)}
                              className="link-button float-end  rounded-0 shadow-none mt-5"
                            />
                          )}
                          {index === acceptData.length - 1 && (
                            <Button
                              title={t("+") + "Add another"}
                              handleClick={addFieldSet}
                              className="btn form-button float-end shadow-none ms-3"
                            />
                          )}
                        </div>
                        <div className="d-none d-md-none d-lg-block">
                          <div className="d-flex align-items-center">
                            {index > 0 && (
                              <span
                                title={t("Delete")}
                                onClick={() => removeFieldSet(index)}
                                className="table-action-icons cursor-pointer rounded-0 shadow-none"
                              >
                                <CloseFile />
                              </span>
                            )}
                            {index === acceptData.length - 1 && (
                              <span
                                title={t("Add another")}
                                onClick={addFieldSet}
                                className="table-action-icons cursor-pointer rounded-0 shadow-none ms-2"
                              >
                                <PlusWithoutFillIcon />
                              </span>
                            )}
                          </div>
                        </div>
                      </>
                    </div>
                  </div>
                </div>

              </div>
            ))}
            <div className="row my-4">
              <div className="col-md-12"></div>
            </div>
          </div>
          {/* <div className="height-20 mb-2">
                    {formData.errorMessage && formData.errorMessage && (
                        <span className="text-danger">{formData.errorMessage}</span>
                    )}
                </div> */}
        </div>
      </div>
  );
};

export default translate(TimeSlot);
