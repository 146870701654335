import Button from "components/common/atoms/Button";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import {
    OptionProps,
    ValidationRules,
} from "components/common/utlis/TypeAnnotations";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { t, translate } from "../translation/Translation";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { ApiCall } from "components/common/services/ApiServices";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "Constants";
import CustomNotify from "components/common/atoms/CustomNotify";
import { mapToSelect } from "components/common/utlis/MapToSelect";
import {
    validateForm,
    validateSelectField,
    Option
} from "components/common/services/ValidationService";
import LoadingIcon from "components/common/utlis/LoadingIcon";
// import { useSearchParams } from "react-router-dom";

interface AssignTodoModalProps {
    show: boolean;
    onHide: () => void;
    title?: string;
    className?: string;
    todoId?: any;
    todoExtensionId?: number | null;
    modalData?: ModalData;
    onAssignTodoSuccess: () => void;
    type: string | null;
}
export interface ModalData {
    officeDropdown: OptionProps[];
    stageId: number | null;
    candidateId: number | null;
}
interface TransferToOfficeData {
    transferToOffice: Option | null;
    transferToOM: Option | null;
    type: string;
}

const AssignTodoModal: React.FC<AssignTodoModalProps> = ({
    show,
    onHide,
    title,
    className,
    todoId,
    modalData,
    todoExtensionId,
    onAssignTodoSuccess,
    type
}) => {
    const [formData, setFormData] = useState({
        assignTo: null,
        type: ""
    });
    const [loading, setLoading] = useState(false);
    const [transferToOfficeData, setTransferToOfficeData] = useState({
        transferToOffice: null,
        transferToOM: null,
        type: ""
    });
    const [options, setOptions] = useState({
        assignToDropdown: [] as OptionProps[],
        OMDropdown: [] as OptionProps[],
    });
    const userAuth = useSelector(selectAuth);
    const [errors, setErrors] = useState<{ [key: string]: string }>({
        assignTo: "",
    });
    const [transferToErrors, setTransferToErrors] = useState<{ [key: string]: string }>({
        transferToOffice: "",
        transferToOM: ""
    });
    const [assignType, setAssignType] = useState("");

    const validation = (
        name: string,
        value: string | boolean | object,
        isSingleFieldValidation: boolean = false
    ) => {
        const validationRules: ValidationRules = {
            assignTo: [validateSelectField],
        };

        const validationErrors = validateForm(
            { ...formData, [name]: value },
            validationRules,
            isSingleFieldValidation ? name : undefined
        );

        if (isSingleFieldValidation && Object.keys(errors).length > 0) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: validationErrors[name],
            }));
        } else {
            setErrors(validationErrors);
        }
        if (Object.keys(validationErrors).length > 0) {
            return false;
        }

        return true;
    };

    const transferToValidation = (
        name: string,
        value: string | boolean | object,
        isSingleFieldValidation: boolean = false
    ) => {
        const validationRules: ValidationRules = {
            transferToOffice: [validateSelectField],
            // transferToOM: [validateSelectField],
        };

        const validationErrors = validateForm(
            { ...transferToOfficeData, [name]: value },
            validationRules,
            isSingleFieldValidation ? name : undefined
        );

        if (isSingleFieldValidation && Object.keys(errors).length > 0) {
            setTransferToErrors((prevErrors) => ({
                ...prevErrors,
                [name]: validationErrors[name],
            }));
        } else {
            setTransferToErrors(validationErrors);
        }
        if (Object.keys(validationErrors).length > 0) {
            return false;
        }

        return true;
    };
    // const [searchParams, setSearchParams] = useSearchParams();

    const handleSelectChange = (selectedOption: any, name: string) => {
        if (name === "assignTo") {
            setFormData((prevData) => ({
                ...prevData,
                [name]: selectedOption !== null ? selectedOption : "",
            }));
            validation(name, selectedOption, true);
        } else if (name === 'transferToOffice') {
            setTransferToOfficeData((prevData) => ({
                ...prevData,
                [name]: selectedOption !== null ? selectedOption : "",
            }));
            // if (selectedOption !== null) {
            //     fetchOfficeManagersList(selectedOption);
            // }
            transferToValidation(name, selectedOption, true);
        } else {
            setTransferToOfficeData((prevData) => ({
                ...prevData,
                [name]: selectedOption !== null ? selectedOption : "",
            }));
            transferToValidation(name, selectedOption, true);
        }
    };

    const fetchRecruitersList = async () => {
        const data = {
            userId: userAuth.userId,
            action: type === "newtodos" ? "assignTo" : "transferToRecruiter",
            todoId: todoId,
            todoExtensionId: todoExtensionId
        };
        const response = await ApiCall.service(
            "getRecruiters",
            "POST",
            data,
            false,
            CENTRAL_DATA_MANAGEMENT_MICROSERVICE
        );
        if (response.status == 200) {
            setOptions((prevData) => ({
                ...prevData,
                assignToDropdown: mapToSelect(response.data),
            }));
        }
    };

    const fetchOfficeManagersList = async (officeId: number) => {
        const data = {
            office: officeId,
        };
        const response = await ApiCall.service(
            "getOfficeManagersList",
            "POST",
            data,
            false,
            CENTRAL_DATA_MANAGEMENT_MICROSERVICE
        );
        if (response.status === 200) {
            const OfficeManagerList = mapToSelect(response.data);
            setOptions((prevData) => ({
                ...prevData,
                OMDropdown: OfficeManagerList,
            }));
            if (response.data.length === 1) {
                setTransferToOfficeData((prevData: any) => ({
                    ...prevData,
                    transferToOM: OfficeManagerList[0],
                }));
            } else {
                setTransferToOfficeData((prevData: any) => ({
                    ...prevData,
                    transferToOM: null,
                }));
            }
        }
    }

    useEffect(() => {
        (todoId !== undefined || todoExtensionId !== undefined) &&
            fetchRecruitersList();
    }, [todoId, todoExtensionId]);

    const assignTodo = async (
        event: any,
        todoId: number | null,
        action: string
    ) => {
        if (type === "newtodos" && userAuth.role === "AREA_MANAGER" && (assignType === "transferToOffice")) {
            const { name, value } = event.target as HTMLInputElement;
            if (transferToValidation(name, value)) {
                const data = {
                    todoId: todoId,
                    userId: userAuth.userId,
                    stageId: modalData?.stageId,
                    assignToOffice: transferToOfficeData.transferToOffice,
                    actionType: "preTransferToOffice",
                    todoExtensionId: todoExtensionId,
                };
                setLoading(true);
                const response = await ApiCall.service(
                    "assignTodo",
                    "POST",
                    data,
                    false,
                    CENTRAL_DATA_MANAGEMENT_MICROSERVICE
                );
                if (response.status === 200) {
                    onHide(); // This will close the modal after assigning todo
                    resetFormData();
                    onAssignTodoSuccess(); //To remove assigned todos from newTodos Tab
                    CustomNotify({ type: "success", message: t(response.msg) });
                }

                setLoading(false);
            }
        }
        else if (type === "newtodos" || assignType === "transferToRecruiter") {
            const data = {
                todoId: todoId,
                userId: userAuth.userId,
                stageId: modalData?.stageId,
                assignTo: formData.assignTo,
                actionType: type === "newtodos" ? "preTransferToRec" : assignType,
                todoExtensionId: todoExtensionId,
            };

            const { name, value } = event.target as HTMLInputElement;
            if (validation(name, value)) {
                setLoading(true);
                const response = await ApiCall.service(
                    "assignTodo",
                    "POST",
                    data,
                    false,
                    CENTRAL_DATA_MANAGEMENT_MICROSERVICE
                );
                if (response.status === 200) {
                    onHide(); // This will close the modal after assigning todo
                    resetFormData();
                    onAssignTodoSuccess(); //To remove assigned todos from newTodos Tab
                    CustomNotify({ type: "success", message: t(response.msg) });
                }
            }
            setLoading(false);
        } else if (assignType === "transferToOffice") {
            const data = {
                todoId: todoId,
                todoExtensionId: todoExtensionId,
                userId: userAuth.userId,
                stageId: modalData?.stageId,
                candidateId: modalData?.candidateId,
                transferToOfficeData: transferToOfficeData,
                actionType: assignType,
            };

            const { name, value } = event.target as HTMLInputElement;
            if (transferToValidation(name, value)) {
                setLoading(true);
                const response = await ApiCall.service(
                    "assignTodo",
                    "POST",
                    data,
                    false,
                    CENTRAL_DATA_MANAGEMENT_MICROSERVICE
                );
                onHide(); // This  close the modal after assigning todo
                resetFormData();
                onAssignTodoSuccess();
                CustomNotify({ type: "success", message: t(response.msg) });
            }
        }
        setLoading(false);
    };
    const resetFormData = () => {
        setFormData({
            assignTo: null,
            type: ""
        });
        setTransferToOfficeData({
            transferToOffice: null,
            transferToOM: null,
            type: ""
        });
        setAssignType("");
    };

    const handleAssignType = (assignType: string) => {
        setAssignType(assignType);
    }

    return (
        <>
            <Modal
                show={show}
                onHide={() => {
                    onHide();
                    resetFormData();
                }}
                centered
                className={`${className}`}
            >
                <Modal.Header
                    closeButton
                    title="Close"
                    className="border-0 px-0"
                >
                    <Modal.Title className="fraunces-italic">
                        {title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-0 border-0 pt-0">
                    {type !== "newtodos" && !assignType && <div className="row">
                        <div className="col-10 mx-auto text-center mb-3">
                            <Button
                                handleClick={(event: any) =>
                                    handleAssignType("transferToRecruiter")
                                }
                                className="form-button rounded-3 px-3 w-100"
                            >
                                {t("Assign to recruiter")}
                            </Button>
                        </div>
                        {modalData?.stageId && (modalData.stageId < 8 || modalData.stageId === 19) &&
                            <div className="col-10 mx-auto text-center">
                                <Button
                                    handleClick={(event: any) =>
                                        handleAssignType("transferToOffice")
                                    }
                                    className="form-button rounded-3 px-3 w-100"
                                >
                                    {t("Transfer to other office")}
                                </Button>
                            </div>
                        }
                    </div>
                    }
                    {type === "newtodos" && userAuth.role === "AREA_MANAGER" && !assignType && <div className="row">
                        <div className="col-10 mx-auto text-center mb-3">
                            <Button
                                handleClick={(event: any) =>
                                    handleAssignType("transferToRecruiter")
                                }
                                className="form-button rounded-3 px-3 w-100"
                            >
                                {t("Assign to recruiter")}
                            </Button>
                        </div>
                        <div className="col-10 mx-auto text-center">
                            <Button
                                handleClick={(event: any) =>
                                    handleAssignType("transferToOffice")
                                }
                                className="form-button rounded-3 px-3 w-100"
                            >
                                {t("Transfer to other office")}
                            </Button>
                        </div>
                    </div>
                    }
                    <div>
                        {type === "newtodos" && userAuth.role === "OFFICE_MANAGER" &&
                            <SelectWithSearch
                                title={t("Transfer to")}
                                name="assignTo"
                                placeHolder={t("Select")}
                                isMandatory={true}
                                search={true}
                                options={options.assignToDropdown}
                                value={formData.assignTo}
                                onChange={(e) => handleSelectChange(e, "assignTo")}
                                isMulti={false}
                                className="select-field"
                                error={errors.assignTo}
                            />
                        }
                        {(assignType === "transferToRecruiter") &&
                            <SelectWithSearch
                                title={t("Transfer to")}
                                name="assignTo"
                                placeHolder={t("Select")}
                                isMandatory={true}
                                search={true}
                                options={options.assignToDropdown}
                                value={formData.assignTo}
                                onChange={(e) => handleSelectChange(e, "assignTo")}
                                isMulti={false}
                                className="select-field"
                                error={errors.assignTo}
                            />
                        }
                        {(assignType === "transferToOffice") &&
                            <>
                                <div className="row">
                                    <div className="col-12">
                                        <SelectWithSearch
                                            title={t(title)}
                                            name="transferToOffice"
                                            placeHolder={t("Select")}
                                            isMandatory={true}
                                            search={true}
                                            options={modalData?.officeDropdown || []}
                                            value={transferToOfficeData.transferToOffice}
                                            onChange={(e) => handleSelectChange(e, "transferToOffice")}
                                            isMulti={false}
                                            className="select-field"
                                            error={transferToErrors.transferToOffice}
                                            isTranslate={true}
                                        />
                                    </div>
                                    {/* <div className="col-12">
                                        <SelectWithSearch
                                            title={t('Transfer to office manager')}
                                            name="transferToOM"
                                            placeHolder={t("Select")}
                                            isMandatory={true}
                                            search={true}
                                            options={options.OMDropdown}
                                            value={transferToOfficeData.transferToOM}
                                            onChange={(e) => handleSelectChange(e, "transferToOM")}
                                            isMulti={false}
                                            className="select-field"
                                            error={transferToErrors.transferToOM}
                                        />
                                    </div> */}
                                </div>
                            </>
                        }
                        {type === "newtodos" && userAuth.role === "OFFICE_MANAGER" &&
                            <>
                                {!loading ? (
                                    <Button
                                        handleClick={(event: any) =>
                                            assignTodo(event, todoId, "assignTodo")
                                        }
                                        className="form-button rounded-3 px-3 float-end"
                                    >
                                        {t("Assign")}
                                    </Button>
                                ) : (
                                    <LoadingIcon
                                        iconType="bars"
                                        color="#e55496"
                                        className="ms-auto"
                                        height={"3vw"}
                                        width={"3vw"}
                                    />
                                )}
                            </>
                        }
                        {(assignType === "transferToRecruiter") &&
                            <>
                                {!loading ? (
                                    <Button
                                        handleClick={(event: any) =>
                                            assignTodo(event, todoId, "transferTodo")
                                        }
                                        className="form-button rounded-3 px-3 float-end"
                                    >
                                        {t("Transfer")}
                                    </Button>
                                ) : (
                                    <LoadingIcon
                                        iconType="bars"
                                        color="#e55496"
                                        className="ms-auto"
                                        height={"3vw"}
                                        width={"3vw"}
                                    />
                                )}
                            </>
                        }
                        {(assignType === "transferToOffice") &&
                            <>
                                {!loading ? (
                                    <Button
                                        handleClick={(event: any) =>
                                            assignTodo(event, todoId, "transferToOffice")
                                        }
                                        className="form-button rounded-3 px-3 float-end"
                                    >
                                        {t("Transfer")}
                                    </Button>
                                ) : (
                                    <LoadingIcon
                                        iconType="bars"
                                        color="#e55496"
                                        className="ms-auto"
                                        height={"3vw"}
                                        width={"3vw"}
                                    />
                                )}
                            </>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default translate(AssignTodoModal);
