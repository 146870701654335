import { ApiCall } from "components/common/services/ApiServices";
import {
  IDENTITY_MANAGER_MICROSERVICE,
  CANDIDATE_MICROSERVICE,
  CENTRAL_DATA_MANAGEMENT_MICROSERVICE,
} from "Constants";
import CustomNotify from "components/common/atoms/CustomNotify";
import { t } from "components/CentralDataMangement/translation/Translation";
import * as ENDPOINTS from "../../../routes/ApiEndpoints";
import { findInflowChannel } from "./JobApplicationsInterface";

export const createCandidate = async (
  formData: any,
  userAuth: any,
  applicationId: string | undefined,
  fetchJobApplicationDetails: any
) => {
  const idmData = {
    firstName: formData.firstName,
    lastName: formData.lastName,
    email: formData.email,
    mobNumber: formData.mobNumber,
    office: {
      value: formData.office?.value !== null ? formData.office?.value : null,
    },
    type: "Candidate",
    submitType: "draft",
  };
  const idmUrl = `registration`;
  const idmResponse = await ApiCall.service(
    idmUrl,
    "POST",
    idmData,
    true,
    IDENTITY_MANAGER_MICROSERVICE
  );
  if (idmResponse.status === 200) {
    const candidateGeneralData = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      mobNumber: formData.mobNumber,
      street: formData.street,
      number: formData.number,
      office: { value: userAuth.officeId },
      consultant: { value: userAuth.userId },
      city: { value: null },
      country: null,
      zipCode: formData.zipCode,
      box: formData.box,
    };
    const jobProfileData = {
      desiredFunctions: formData.functionId
        ? [{ value: formData.functionId }]
        : [],
      desiredSector: [],
      desiredJobDomain: [],
      desiredRegimen: [],
      functionAliasName: formData.jobOffer,
    };
    const candidateCreationData = {
      type: "Candidate",
      general: candidateGeneralData,
      profileCard: {
        cvFileId: formData.cvId,
        cvFileName: formData.cvFileName,
        cvPath: formData.cvFilePath,
        picFileId: null,
        picFileName: "",
        picFilePath: "",
      },
      jobProfile: jobProfileData,
      other: {
        civilStatus: null,
        dependentSpouse: null,
        dependentChildren: 0,
        gender: null,
        educationalLevel: null,
        languageOfDocuments: null,
        drivingLicence: [],
        transport: [],
        withHoldingTax: null,
        additional: null,
        workType: null,
      },
      workExperience: {},
      studies: {},
      competences: {
        competence: [],
        childCompetences: {},
      },
      certificates: {},
      workPermitDetails: {},
      checklistDocDetails: {},
      internalInfo: {
        active: 0,
        inflowChannel: { value: findInflowChannel(formData.channel) },
        externalReference: formData.externalRef,
        selectionPartner: null,
        blocked: 0,
        reasonForBlocking: "",
        blockedBy: null,
        infoBlocking: "",
        internalInfo: formData.externalInfo,
        studentCertificate: null,
        studentYear: "2024",
        studentRemainingQuota: "",
        studentInfo: "",
        greenCertificate: null,
        greenYear: "2024",
        greenRemainingQuota: "",
        greenInfo: "",
        horecaCertificate: null,
        horecaYear: "2024",
        horecaRemainingQuota: "",
        horecaInfo: "",
      },
      personId: idmResponse.userId,
      candidateStatus: 0,
      formStatus: 0,
      submitType: "draft",
      source: "jobApplication",
      sourceId: 3,
      applicationId: applicationId,
      cvId: formData.cvId,
      cvPath: formData.cvFilePath,
      src: "internalRegister",
    };
    const url = `registerCandidate`;
    const candidateCreationResponse = await ApiCall.service(
      url,
      "POST",
      candidateCreationData,
      true,
      CANDIDATE_MICROSERVICE
    );
    if (candidateCreationResponse.status === 200) {
      const applicationData = {
        candidateId: candidateCreationResponse.candidateId,
        status: formData.status,
        externalRef: formData.externalRef,
        externalInfo: formData.externalInfo,
      };
      const url = !applicationId
        ? ENDPOINTS.UPDATE_OR_CREATE_JOB_APPLICATION
        : `${ENDPOINTS.UPDATE_OR_CREATE_JOB_APPLICATION}/${applicationId}`;
      const applicationUpdateResponse = await ApiCall.service(
        url,
        "POST",
        applicationData,
        true,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (applicationUpdateResponse.status === 200) {
        await fetchJobApplicationDetails();
        CustomNotify({
          type: "success",
          message: t("Candidate created successfully"),
        });
      }
    }
  } else {
    if (idmResponse.errors.email && idmResponse.errors.phone_number) {
      CustomNotify({
        type: "error",
        message: t("Email and Phone number already exists"),
      });
    } else if (idmResponse.errors.email) {
      CustomNotify({ type: "error", message: t("Email already exists") });
    } else if (idmResponse.errors.phone_number) {
      CustomNotify({
        type: "error",
        message: t("Phone number already exists"),
      });
    }
  }
};
