import React from 'react'

const Translate: React.FC = () => {
  return (
    <><svg xmlns="http://www.w3.org/2000/svg" width="30" height="40" viewBox="0 0 31.554 31.554">
      <path id="Translate" d="M30.4,6.733H16.01L14.621,2H5.155A3.165,3.165,0,0,0,2,5.155v20.51a3.165,3.165,0,0,0,3.155,3.155H16.2l1.578,4.733H30.4A3.165,3.165,0,0,0,33.554,30.4V9.888A3.165,3.165,0,0,0,30.4,6.733ZM10.157,21.863a6.453,6.453,0,1,1,0-12.905,6.231,6.231,0,0,1,4.323,1.688l.11.095L12.649,12.6l-.095-.079a3.427,3.427,0,0,0-2.4-.931,3.819,3.819,0,0,0,0,7.636,3.233,3.233,0,0,0,3.345-2.3H10.015V14.479h6.232l.016.11a4.953,4.953,0,0,1,.079.962,5.981,5.981,0,0,1-6.185,6.311Zm9.513-2.7a15.748,15.748,0,0,0,1.877,2.682l-.852.836L19.67,19.165Zm1.215-1.2H19.323l-.489-1.641h6.295a12.118,12.118,0,0,1-2.461,4.323,14.485,14.485,0,0,1-1.783-2.682ZM31.976,30.4A1.582,1.582,0,0,1,30.4,31.976H19.355l3.155-3.155-1.278-4.37L22.683,23l4.228,4.244,1.152-1.152-4.276-4.228a13.774,13.774,0,0,0,3.029-5.538h2V14.685H23.078V13.044H21.437v1.641H18.345L16.483,8.311H30.4a1.582,1.582,0,0,1,1.578,1.578Z" transform="translate(-2 -2)" fill="currentColor" />
    </svg>
    </>
  )
}

export default Translate