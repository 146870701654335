import * as CONST from "../../annotations/AdminFicheConstants";
import { ApiCall } from "components/common/services/ApiServices";
import { COMPANY_MICROSERVICE } from "Constants";
import * as ENDPOINTS from "../../../../../routes/ApiEndpoints";

export const GetAdminFicheData = async (
  dispatch: (action: any) => void,
  agreementId: string,
  adminFicheId: string |undefined
) => {
  try {
    const data = {
      agreementId: agreementId ?? null,
      adminFicheId: adminFicheId ?? null,
    };

    const response = await ApiCall.service(
      ENDPOINTS.GET_ADMIN_FICHE_DETAILS,
      "POST",
      data,
      true,
      COMPANY_MICROSERVICE
    );

    if (response.status === 200) {
      const dropDownList = response.data.dropDownList;
      const generalData = response.data.general;
      const contactData = response.data.contacts;
      const selectedContactData = response.data.selectedContact;
      const shiftData = response.data.shiftTables;
      const wageAndPremiums = response.data.wageAndPremiums;
      dispatch({ type: CONST.UPDATE_DROPDOWN_LIST, data: dropDownList });
      dispatch({ type: CONST.UPDATE_TAB_DATA, tab:CONST.GENERAL, data: generalData });
      dispatch({ type: CONST.UPDATE_TAB_DATA, tab:CONST.SHIFT_TABLES, data: shiftData });
      dispatch({ type: CONST.UPDATE_TAB_DATA, tab:CONST.WAGE_AND_PREMIUMS, data: wageAndPremiums });
      dispatch({ type: CONST.UPDATE_TAB_DATA, tab:CONST.CONTACTS, data: contactData });
      dispatch({ type: CONST.UPDATE_TAB_DATA, tab:CONST.SELECTED_CONTACT, data: selectedContactData });
      dispatch({ type: CONST.UPDATE_TAB_DATA, tab:CONST.INITIAL_WAGE_PREMIUMS, data: wageAndPremiums });
    }
  } catch (error) {
    console.error("Failed to fetch admin fiche data:", error);
  }
};
