import Button from "components/common/atoms/Button";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import LabelField from "components/common/atoms/LabelField";
import PhoneInput from "react-phone-number-input";
import CloseFile from "static/images/CloseFile";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import React from "react";
import { ValidationRules } from "components/common/utlis/TypeAnnotations";
import {
  validateForm,
  validateRequired,
  validateTextFieldAlpha,
  validateEmail,
  validateSelectField,
  scrollToTop,
  validatePhoneNumber,
} from "../../../../../common/services/ValidationService";
import Calender from "components/common/molecules/Calender";
import LabelWithTextAreaField from "components/common/molecules/LabelWithTextAreaField";
import CustomNotify from "components/common/atoms/CustomNotify";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import Accordion from "react-bootstrap/Accordion";
import CheckBoxField from "components/common/atoms/CheckBoxField";
import { useFormContext } from "components/CompanyCreation/AdminFiche/context/Context";
import Navigation from "../../form-navigation/Navigation";
import * as CONST from "../../../annotations/AdminFicheConstants";

const Contacts = () => {
  const { state, dispatch } = useFormContext();

  const validation = (
    name: string,
    value: string | boolean | Date | object[] | undefined,
    index: number,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      fName: [validateRequired, validateTextFieldAlpha],
      lName: [validateRequired, validateTextFieldAlpha],
      email: [validateRequired, validateEmail],
      phNumber: [validatePhoneNumber],
      title: [validateSelectField],
    };
    const validationErrors = validateForm(
      { ...state.contacts[index], [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    dispatch({
      type: CONST.UPDATE_CONTACTS_FIELD_ERROR,
      field: name,
      error: validationErrors[name],
      index,
    });
    const isFieldValid = Object.keys(validationErrors).length === 0;

    if (isFieldValid) {
      state.dynamicErrors[index][name] = "";
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }

    return true;
  };

  const handleFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const { name, value, type } = event.target;
    if (type === "checkbox") {
      const checked = (event.target as HTMLInputElement).checked;
      const newValue = checked ? 1 : 0;
      dispatch({
        type: CONST.UPDATE_CONTACTS_FIELD,
        field: name,
        value: newValue,
        index,
      });
      validation(name, checked, index, true);
    } else {
      dispatch({
        type: CONST.UPDATE_CONTACTS_FIELD,
        field: name,
        value,
        index,
      });
      validation(name, value, index, true);
    }
  };

  const handlePhoneNumberChange = (
    phNumber: string | undefined,
    index: number
  ) => {
    dispatch({
      type: CONST.UPDATE_CONTACTS_FIELD,
      index,
      field: "phNumber",
      value: phNumber,
    });
    validation("phNumber", phNumber, index, true);
  };
  const handleAddContact = () => {
    dispatch({ type: CONST.ADD_CONTACT });
    state.errors.selectedContact !== "" &&
      dispatch({
        type: CONST.UPDATE_FIELD_ERROR,
        field: "selectedContact",
        error: "",
      });
  };

  const handleRemoveContact = (indexToRemove: number) => {
    dispatch({ type: CONST.REMOVE_CONTACT, indexToRemove });
  };

  const handleDateChange = (date: Date | null, name: string, index: number) => {
    const updatedDate = date !== null ? date : new Date();
    dispatch({
      type: CONST.UPDATE_CONTACTS_FIELD,
      field: name,
      value: updatedDate,
      index,
    });
    validation(name, updatedDate, index, true);
  };

  const currentDate = new Date();
  const below15 = new Date();
  below15.setFullYear(currentDate.getFullYear() - 15);

  const handleSelectChange = (
    selectedOption: any,
    name: string,
    index: number
  ) => {
    if (name === "selectedContact") {
      const contactId = selectedOption;
      dispatch({
        type: CONST.UPDATE_CONTACT_LIST,
        field: name,
        value: selectedOption,
        contactId,
      });
      state.errors.selectedContact !== "" &&
        dispatch({
          type: CONST.UPDATE_FIELD_ERROR,
          field: "selectedContact",
          error: "",
        });
    } else {
      dispatch({
        type: CONST.UPDATE_CONTACTS_FIELD,
        field: name,
        value: selectedOption,
        index,
      });
      validation(name, selectedOption, index, true);
    }
  };

  const checkForDuplicates = () => {
    const emailSet = new Set();
    const phoneSet = new Set();
    let hasDuplicate = false;

    state.contacts.forEach((contact, index) => {
      if (contact.email?.trim() !== "") {
        if (emailSet.has(contact.email)) {
          //   state.dynamicErrors[index].email = t("Duplicate email");
          hasDuplicate = true;
        } else {
          emailSet.add(contact.email);
        }
      }

      if (contact.phNumber?.trim() !== "") {
        if (phoneSet.has(contact.phNumber)) {
          //   state.dynamicErrors[index].phNumber = t("Duplicate phone number");
          hasDuplicate = true;
        } else {
          phoneSet.add(contact.phNumber);
        }
      }
    });
    hasDuplicate &&
      CustomNotify({
        type: "error",
        message: t("Duplicate email or phone number exists") + "!!",
      });
    return hasDuplicate;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    checkForDuplicates();
    scrollToTop();
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-border" style={{ paddingTop: "3.4vw" }}>
        <div className="col-sm-12 col-md-12">
          <SelectWithSearch
            title={t("Contacts")}
            name="selectedContact"
            isMandatory={false}
            search={true}
            options={state.contactList}
            value={
              Object.keys(state.selectedContact).length > 0
                ? Object.values(state.selectedContact)
                : []
            }
            onChange={(e) => handleSelectChange(e, "selectedContact", 0)}
            isMulti={true}
            error={state.errors.selectedContact}
          />
        </div>
        {state.contacts &&
          Object.values(state.contacts).map((contact, index) => (
            <React.Fragment key={`contact-${contact.id ?? index}`}>
              {contact.type !== 0 && (
                <div className="field-set mb-4 rounded-3" key={`main-${index}`}>
                  <div className="row" key={`row-${index}`}>
                    <div
                      className={"col-lg-11 col-md-12 pe-lg-0 "}
                      key={`body-${index}`}
                    >
                      <Accordion
                        defaultActiveKey="0"
                        key={`accordion-${index}`}
                      >
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                            {contact.fName + " " + contact.lName}
                          </Accordion.Header>

                          <Accordion.Body
                            key={`acc-body-${index}`}
                            className="pb-0"
                          >
                            <div
                              className="row"
                              key={`accordion-body-${index}`}
                            >
                              <div className="col-sm-12 col-md-3">
                                <SelectWithSearch
                                  title={t("Title")}
                                  name="title"
                                  isMandatory={false}
                                  search={true}
                                  options={[]}
                                  value={contact.title}
                                  onChange={(e) =>
                                    handleSelectChange(e, "title", index)
                                  }
                                  isMulti={false}
                                  error={state.dynamicErrors[index]?.title}
                                  isTranslate={true}
                                />
                              </div>
                              <div className="col-sm-12 col-md-5">
                                <LabelWithInputField
                                  isMandatory={true}
                                  name="fName"
                                  handleChange={(event) =>
                                    handleFieldChange(event, index)
                                  }
                                  value={contact.fName ?? ""}
                                  id={`fname-${index}`}
                                  label={t("First name")}
                                  placeholder={t("First name")}
                                  error={state.dynamicErrors[index]?.fName}
                                />
                              </div>
                              <div className="col-sm-12 col-md-4">
                                <LabelWithInputField
                                  isMandatory={true}
                                  name="lName"
                                  handleChange={(event) =>
                                    handleFieldChange(event, index)
                                  }
                                  value={contact.lName ?? ""}
                                  id={`lname-${index}`}
                                  label={t("Last name")}
                                  placeholder={t("Last name")}
                                  error={state.dynamicErrors[index]?.lName}
                                />
                              </div>
                              <div className="col-sm-12 col-lg-4 col-md-6">
                                <LabelWithInputField
                                  isMandatory={true}
                                  name="email"
                                  handleChange={(event) =>
                                    handleFieldChange(event, index)
                                  }
                                  value={contact.email ?? ""}
                                  id={`email-${index}`}
                                  label={t("Email")}
                                  placeholder={t("Email")}
                                  type="email"
                                  error={state.dynamicErrors[index]?.email}
                                />
                              </div>
                              <div className="col-sm-12 col-lg-4 col-md-6">
                                <LabelField
                                  title={t("Phone number")}
                                  isMandatory={true}
                                  key="PhoneInput"
                                />
                                <div
                                  className="form-control field-shadow d-flex"
                                  style={{ borderRadius: "0.5vw" }}
                                >
                                  <PhoneInput
                                    defaultCountry="BE"
                                    international
                                    placeholder={t("Enter phone number")}
                                    disabled={false}
                                    onChange={(phNumber) =>
                                      handlePhoneNumberChange(phNumber, index)
                                    }
                                    value={
                                      contact?.phNumber !== ""
                                        ? "+" + contact?.phNumber
                                        : contact?.phNumber
                                    }
                                    className="w-100"
                                  />
                                </div>
                                <div
                                  className="height-20 text-danger"
                                  style={{ marginBottom: "0.5vw" }}
                                >
                                  {state.dynamicErrors[index]?.phNumber && (
                                    <span className="text-danger">
                                      {state.dynamicErrors[index]?.phNumber}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="col-sm-12 col-lg-4 col-md-6 position-relative">
                                <Calender
                                  onChange={(e) =>
                                    handleDateChange(e, "dob", index)
                                  }
                                  selectedDate={contact.dob}
                                  label={t("Date of birth")}
                                  isMandatory={false}
                                  name="dob"
                                  maxDate={below15}
                                  error={
                                    state.dynamicErrors[index]?.dob
                                      ? state.dynamicErrors[
                                          index
                                        ]?.dob.toString()
                                      : undefined
                                  }
                                />
                              </div>
                              <div className="col-sm-12 col-lg-4 col-md-6">
                                <LabelWithInputField
                                  isMandatory={false}
                                  name="gsm"
                                  handleChange={(event) =>
                                    handleFieldChange(event, index)
                                  }
                                  value={contact.gsm ?? ""}
                                  id={`gsm-${index}`}
                                  label={t("Gsm")}
                                  placeholder={t("Gsm")}
                                  type="text"
                                  error={state.dynamicErrors[index]?.gsm}
                                />
                              </div>
                              <div className="col-sm-12 col-lg-4 col-md-6">
                                <SelectWithSearch
                                  title={t("Role")}
                                  name="roles"
                                  isMandatory={false}
                                  search={true}
                                  options={[]}
                                  value={contact.roles}
                                  onChange={(e) =>
                                    handleSelectChange(e, "roles", index)
                                  }
                                  isMulti={true}
                                  isTranslate={true}
                                />
                              </div>
                              <div className="col-sm-12 col-lg-4 col-md-6">
                                <LabelWithInputField
                                  isMandatory={false}
                                  name="functionTitle"
                                  handleChange={(event) =>
                                    handleFieldChange(event, index)
                                  }
                                  value={contact.functionTitle ?? ""}
                                  id={`functionTitle-${index}`}
                                  label={t("Function")}
                                  placeholder={t("Function")}
                                  type="text"
                                  error={
                                    state.dynamicErrors[index]?.functionTitle
                                  }
                                />
                              </div>
                              <div className="col-sm-12 col-lg-4 col-md-6">
                                <SelectWithSearch
                                  title={t("Preferred language")}
                                  name="language"
                                  isMandatory={false}
                                  search={true}
                                  options={[]}
                                  value={contact.language}
                                  onChange={(e) =>
                                    handleSelectChange(e, "language", index)
                                  }
                                  isMulti={false}
                                  error={state.dynamicErrors[index]?.language}
                                  isTranslate={true}
                                />
                              </div>
                              <div className="col-sm-12 col-md-4">
                                <LabelWithInputField
                                  name="linkedIn"
                                  handleChange={(event) =>
                                    handleFieldChange(event, index)
                                  }
                                  value={contact.linkedIn}
                                  id="linkedIn"
                                  label={t("Linkedin website url")}
                                  type="text"
                                  error={state.dynamicErrors[index]?.linkedIn}
                                />
                              </div>
                              <div className="col-md-12 mb-3">
                                <CheckBoxField
                                  label={t("Decision maker")}
                                  name="allowSignature"
                                  onChangeHandler={(event) =>
                                    handleFieldChange(event, index)
                                  }
                                  className="me-5 pe-4 mb-3 mb-xl-0"
                                  isChecked={contact.allowSignature}
                                  id={`allowSignature-${contact.id}`}
                                  lineHeight="27px"
                                />
                              </div>
                              <div className="col-lg-12">
                                <LabelWithTextAreaField
                                  name="info"
                                  handleChange={(event) =>
                                    handleFieldChange(event, index)
                                  }
                                  value={contact.info ?? ""}
                                  label={t("Info")}
                                />
                              </div>
                            </div>
                          </Accordion.Body>
                          {/* {index > 0 && ( */}
                          <div className="row pb-3 pb-lg-0">
                            <div className="col-12" key={`remove-${index}`}>
                              <div
                                className="d-block d-md-block d-lg-none"
                                key={`pad-remove-${index}`}
                              >
                                <Button
                                  title={t("Remove")}
                                  handleClick={() => handleRemoveContact(index)}
                                  className="link-button float-end pe-4 rounded-0 shadow-none my-2"
                                />
                              </div>
                            </div>
                          </div>
                          {/* )} */}
                        </Accordion.Item>
                      </Accordion>
                    </div>
                    {/* {index > 0 && ( */}
                    <div
                      className="col-lg-1 col-md-2 ps-0"
                      key={`remove-${index}`}
                    >
                      <div
                        className="d-none d-md-none d-lg-block"
                        key={`pad-remove-${index}`}
                      >
                        <span
                          title={t("Remove")}
                          onClick={() => handleRemoveContact(index)}
                          className="table-action-icons cursor-pointer float-end rounded-0 shadow-none"
                        >
                          <CloseFile />
                        </span>
                      </div>
                    </div>
                    {/* )} */}
                  </div>
                </div>
              )}
            </React.Fragment>
          ))}
        <div className="row my-4">
          <div className="col-md-12">
            <Button
              title={"+ " + t("Add another")}
              handleClick={handleAddContact}
              className="form-button float-end shadow-none"
            />
          </div>
        </div>
      </div>
      <Navigation />
    </form>
  );
};

export default translate(Contacts);
