import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import TimeTable from "components/common/molecules/TimeTable";
import {
  AdminFicheFormData,
  WorkingHoursProps,
} from "components/CompanyCreation/AdminFiche/annotations/AdminFicheInterface";
import React from "react";
import { t } from "components/CentralDataMangement/translation/Translation";
import { TimeStates } from "components/common/utlis/PlanningUtility";
import PaidHours from "./PaidHours";

interface props {
  state: AdminFicheFormData;
  activeTab: number | null;
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    tableId: number
  ) => void;
  handleSelectChange: (
    selectedOption: any,
    field: string,
    tableId: number
  ) => void;
  handleWeeklyPlanningChange: (value: TimeStates, tableId: number) => void;
}
const ShiftData: React.FC<props> = ({
  state,
  activeTab,
  handleChange,
  handleSelectChange,
  handleWeeklyPlanningChange,
}) => {
  const shiftTables = Object.values(state.shiftTables ?? {});
  const getShiftListBasedOnRegime = (tableId: number | null) => {
    const selectedRegime = shiftTables?.[tableId as number].regime?.value;
    let availableOptions: any[] = [];
    switch (selectedRegime) {
      case 1: // Regime value is 1
        availableOptions = state.shiftList.filter(
          (option) => option.value === 3
        ); // Return only Day shift
        break;
      case 2: // Regime value is 2
        availableOptions = state.shiftList; // Return all options
        break;
      case 3:
        availableOptions = state.shiftList.filter(
          (option) => option.value === 4
        ); // Return only Night shift
        break;
      default:
        availableOptions = []; // If no regime or unrecognized regime, return empty array
        break;
    }
    return availableOptions;
  };

  return (
    <>
      {shiftTables.length > 0 && (
        <>
          {shiftTables.map((shiftTable: WorkingHoursProps, tableId: number) => {
            return (
              <React.Fragment key={tableId}>
                <div className="tab-content" id="v-pills-tabContent">
                  <div
                    className={`tab-pane fade ${
                      activeTab === tableId ? "show active" : ""
                    }`}
                    id={`tab-${tableId}`}
                    role="tabpanel"
                    aria-labelledby={`tab-${tableId}-tab`}
                    key={tableId}
                  >
                    <div className="row">
                      <div className="col-md-3">
                        <SelectWithSearch
                          isMulti={true}
                          isMandatory={true}
                          search={true}
                          options={[
                            ...state.general.paritairBlue,
                            ...state.general.paritairWhite,
                          ]}
                          value={shiftTable.pc ?? ""}
                          onChange={(e) => handleSelectChange(e, "pc", tableId)}
                          name="pc"
                          title={t("PC")}
                          error={shiftTable.pcError}
                        />
                      </div>
                      <div className="col-md-3">
                        <LabelWithInputField
                          type="text"
                          label={t("Name")}
                          handleChange={(e) => handleChange(e, tableId)}
                          value={shiftTable.name}
                          name="name"
                          error={shiftTable.nameError}
                          isMandatory={true}
                        />
                      </div>
                      <div className="col-md-3">
                        <SelectWithSearch
                          isMulti={false}
                          isMandatory={true}
                          search={true}
                          options={state.regimeList}
                          value={shiftTable.regime ?? ""}
                          onChange={(e) =>
                            handleSelectChange(e, "regime", tableId)
                          }
                          name="regime"
                          title={t("Regime")}
                          error={shiftTable.regimeError}
                        />
                      </div>
                      <div className="col-md-3">
                        <SelectWithSearch
                          isMulti={false}
                          isMandatory={true}
                          search={true}
                          options={getShiftListBasedOnRegime(tableId)}
                          value={shiftTable.shift ?? ""}
                          onChange={(e) =>
                            handleSelectChange(e, "shift", tableId)
                          }
                          name="shift"
                          title={t("Shift")}
                          error={shiftTable.shiftError}
                        />
                      </div>
                      <div className="marginBotttom1">
                        <TimeTable
                          onChange={(e) =>
                            handleWeeklyPlanningChange(e, tableId)
                          }
                          value={shiftTable.timeTable}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <PaidHours
                        shiftTable={shiftTable}
                        handleChange={handleChange}
                        tableId={tableId}
                      />
                    </div>
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </>
      )}
    </>
  );
};

export default React.memo(ShiftData);
