import { useEffect, useState } from "react";
import {
  getShiftOptionForRegime,
  intialShiftConfig,
  shiftProps,
} from "./ShiftInterface";
import Title from "components/common/atoms/Title";
import { t } from "components/CentralDataMangement/translation/Translation";
import { Link, useNavigate, useParams } from "react-router-dom";
import Button from "components/common/atoms/Button";
import LoadingIcon from "components/common/utlis/LoadingIcon";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import {
  OptionProps,
  ValidationRules,
} from "components/common/utlis/TypeAnnotations";
import {
  validateForm,
  validateRequired,
  validateSelectField,
} from "components/common/services/ValidationService";
import { ApiCall } from "components/common/services/ApiServices";
import CustomNotify from "components/common/atoms/CustomNotify";
import { COMPANY_MICROSERVICE } from "Constants";
import * as ENDPOINTS from "../../../routes/ApiEndpoints";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import { mapToSelect } from "components/common/utlis/MapToSelect";
import TimeTable from "components/common/molecules/TimeTable";
import {
  TimeStates,
  calculateWeekTotal,
} from "components/common/utlis/PlanningUtility";

const CreateShift: React.FC = () => {
  const [formData, setFormData] = useState<shiftProps>(intialShiftConfig);
  const initialErrors: { [key: string]: string } = {};
  const [errors, setErrors] = useState<{ [key: string]: string }>(
    initialErrors
  );
  const [dropDownData, setDropDownData] = useState({
    regimeList: [] as OptionProps[],
    shiftList: [] as OptionProps[],
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();

  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    // Update form data with the selected option
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: selectedOption,
    }));

    // Handle specific logic for regime field
    if (fieldName === "regime") {
      const optionId = selectedOption ? selectedOption.value : null;
      if (optionId) {
        // Get shift options based on the selected regime value
        const shiftOptions = getShiftOptionForRegime(optionId) ?? null;
        // Update the form data with the new shift options
        setFormData((prevData) => ({
          ...prevData,
          shift: shiftOptions,
        }));
      }
    }

    // Perform validation
    validation(fieldName, selectedOption, true);
  };

  // Fetch job application details
  const fetchShiftDetails = async () => {
    const url = id
      ? `${ENDPOINTS.GET_SHIFT_DETAILS}/${id}`
      : ENDPOINTS.GET_SHIFT_DETAILS;
    const response = await ApiCall.getService(
      url,
      "GET",
      COMPANY_MICROSERVICE,
      true
    );

    if (response.status === 200) {
      setDropDownData((prev) => ({
        ...prev,
        regimeList: mapToSelect(response.data["regimeList"]),
        shiftList: mapToSelect(response.data["shiftList"]),
      }));

      if (Object.keys(response.data["shiftDetails"]).length > 0) {
        setFormData((prev) => ({
          ...prev,
          ...response.data["shiftDetails"],
        }));
      }
    }
  };

  useEffect(() => {
    fetchShiftDetails();
  }, [id]);

  const changeHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target as HTMLInputElement;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    validation(name, value, true);
  };

  const validation = (
    name: string,
    value: string | boolean,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      regime: [validateSelectField],
      shift: [validateSelectField],
      timeTableName: [validateRequired],
    };

    const validationErrors = validateForm(
      { ...formData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation && Object.keys(errors).length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }

    return true;
  };
  const handleTimeTableChange = (timeStates: TimeStates) => {
    setFormData((prev) => ({
      ...prev,
      timeTable: timeStates,
    }));
  };
  const handleSubmit = async (e: React.FormEvent) => {
    setLoading(true);
    e.preventDefault();
    const data = {
      ...formData,
      totalTime: calculateWeekTotal(formData.timeTable),
    };

    const { name, value } = e.target as HTMLInputElement;
    if (validation(name, value)) {
      const url = id
        ? `${ENDPOINTS.UPDATE_OR_CREATE_SHIFT}/${id}`
        : ENDPOINTS.UPDATE_OR_CREATE_SHIFT;
      const response = await ApiCall.service(
        url,
        "POST",
        data,
        false,
        COMPANY_MICROSERVICE
      );
      if (response.status === 200) {
        CustomNotify({
          type: "success",
          message: t("Shift updated successfully"),
        });
        navigate("/manage/shifts");
      }
    }
    setLoading(false);
  };
  return (
    <>
      <Title title={id ? t("Edit shift") : t("Create shift")} />
      <form onSubmit={handleSubmit}>
        <div>
          <div className="row search-bar">
            <div className="col-4">
              <SelectWithSearch
                id="regime"
                title={t("Regime")}
                isMandatory={true}
                search={true}
                options={dropDownData.regimeList}
                onChange={(e) => handleSelectChange(e, "regime")}
                isMulti={false}
                name="regime"
                value={formData.regime}
                error={errors.regime}
                isDisabled={id ? true : false}
                isTranslate={true}
              />
            </div>
            <div className="col-4">
              <SelectWithSearch
                id="shift"
                title={t("Shift")}
                isMandatory={true}
                search={true}
                options={dropDownData.shiftList}
                onChange={(e) => handleSelectChange(e, "shift")}
                isMulti={false}
                name="shift"
                value={formData.shift}
                error={errors.shift}
                isTranslate={true}
                isDisabled={
                  id
                    ? true
                    : formData.regime?.value === 1 ||
                      formData.regime?.value === 3
                }
              />
            </div>
            <div className="col-4">
              <LabelWithInputField
                isMandatory={true}
                name="timeTableName"
                handleChange={changeHandler}
                value={formData.timeTableName}
                id="shiftName"
                label={t("Shift name")}
                placeholder={t("Shift name")}
                error={errors.timeTableName}
              />
            </div>
          </div>
          <div className="form-border">
            <TimeTable
              onChange={handleTimeTableChange}
              value={formData.timeTable}
            />
          </div>
        </div>
        {/* DESKTOP */}
        <div className="row backPadding">
          <div className="col-md-4 align-self-center">
            <Link
              to="/manage/shifts"
              className=" back-btn text-decoration-underline"
            >
              {t("Back")}
            </Link>
          </div>
          <div className="col-md-8 text-end">
            {!loading ? (
              <Button
                title={id ? t("Update shift") : t("Create shift")}
                type="submit"
                className="form-button shadow-none "
              />
            ) : (
              <LoadingIcon
                iconType="bars"
                color="#ff4dae"
                className="float-end"
                height={"3vw"}
                width={"3vw"}
              />
            )}
          </div>
        </div>
      </form>
    </>
  );
};
export default CreateShift;
