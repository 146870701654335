import { Reducer } from "react";
import {
  AdminFicheFormData,
  Applicability,
  CommProfileProps,
  WageTabProps,
} from "../annotations/AdminFicheInterface";
import { FormAction } from "./Actions";
import * as CONST from "../annotations/AdminFicheConstants";
import { t } from "components/CentralDataMangement/translation/Translation";
import {
  initialTimeStates,
  isValidTime,
} from "components/common/utlis/PlanningUtility";
import { E164Number } from "libphonenumber-js";

const FormReducer: Reducer<AdminFicheFormData, FormAction> = (
  state: any,
  action: any
) => {
  switch (action.type) {
    case CONST.NEXT_STEP:
      const currentTab = state.tabDetails.find((tab: any) => tab.showStatus);
      if (currentTab) {
        const currentIndex = state.tabDetails.findIndex(
          (tab: any) => tab.id === currentTab.id
        );
        let nextIndex = (currentIndex + 1) % state.tabDetails.length;

        if (
          (state.general.encodage === 0 || state.general.encodage === null) &&
          nextIndex === 1
        ) {
          nextIndex = 2;
        }

        const updatedTabDetails = state.tabDetails.map((tab: any) => ({
          ...tab,
          showStatus: tab.id === state.tabDetails[nextIndex].id,
        }));

        return {
          ...state,
          tabDetails: updatedTabDetails,
        };
      }
      return state;

    case CONST.PREVIOUS_STEP:
      const currentTabPrev = state.tabDetails.find(
        (tab: any) => tab.showStatus
      );
      if (currentTabPrev) {
        const currentIndexPrev = state.tabDetails.findIndex(
          (tab: any) => tab.id === currentTabPrev.id
        );
        const previousIndex =
          (currentIndexPrev - 1 + state.tabDetails.length) %
          state.tabDetails.length;

        const updatedTabDetailsPrev = state.tabDetails.map((tab: any) => ({
          ...tab,
          showStatus: tab.id === state.tabDetails[previousIndex].id,
        }));

        return {
          ...state,
          tabDetails: updatedTabDetailsPrev,
        };
      }
      return state;

    case CONST.UPDATE_TAB_DETAILS:
      return {
        ...state,
        tabDetails: action.tabDetails,
      };

    case CONST.UPDATE_TAB_ERROR:
      const { tabIndex, error } = action;
      return {
        ...state,
        tabDetails: state.tabDetails.map((tab: any, index: any) => ({
          ...tab,
          error: index === tabIndex ? error : tab.error,
        })),
      };

    case CONST.UPDATE_FIELDS:
      return {
        ...state,
        [action.template]: {
          ...state[action.template],
          [action.field]: action.value,
        },
      };

    case CONST.UPDATE_FIELD_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.field]: action.error,
        },
      };

    case CONST.ADD_NEW_TIMETABLE:
      const shiftTables = Object.values(state.shiftTables ?? {});
      const index = shiftTables.length;
      const newTable = {
        name: `${t("Timetable")} ${index + 1}`,
        tableId: null,
        regime: null,
        shift: null,
        pc: [],
        timeTable: initialTimeStates,
        totalTime: "",
        EffectvHrsFullTime: "",
        AvrgHrsFullTime: "",
        EffectvHrs: "",
        paidADV: "",
        unPaidADV: "",
        overtimeDay: "",
        overtimeWeek: "",
        nameError: "",
        regimeError: "",
        shiftError: "",
        pcError: "",
      };
      return {
        ...state,
        shiftTables: [...shiftTables, newTable],
      };

    case CONST.DELETE_TIMETABLE: {
      const id = action.tableId;
      // Create a shallow copy of the shiftTables array
      const updatedTable = [...Object.values(state.shiftTables || {})];
      // Remove the element at the given index using splice
      updatedTable.splice(id, 1);
      // Return the new state with the updated shiftTables array
      return {
        ...state,
        shiftTables: updatedTable,
      };
    }

    case CONST.UPDATE_SHIFT_DATA: {
      const { tableId, field, value } = action;

      return {
        ...state,
        shiftTables: Object.values(state.shiftTables || {}).map(
          (shift: any, index: number) => {
            if (index === tableId) {
              return {
                ...shift,
                [field]: value,
              };
            }
            return shift;
          }
        ),
      };
    }

    case CONST.ADD_KB_DATA: {
      const data = {
        hours: null,
        percentage: null,
      };

      return {
        ...state,
        general: {
          ...state.general,
          KBData: [...state.general.KBData, data],
        },
      };
    }

    case CONST.DELETE_KB_DATA: {
      const { KBId } = action;

      // Create a new array excluding the item at the index specified by KBId
      const updatedKBData = state.general.KBData.filter(
        (_: any, kbIndex: number) => kbIndex !== KBId
      );

      // Return the updated state with the modified KBData array
      return {
        ...state,
        general: {
          ...state.general,
          KBData: updatedKBData,
        },
      };
    }

    case CONST.UPDATE_KB_DATA: {
      const { field, value, KBId } = action;

      // Create a new KBData array with the updated item
      const updatedKBData = state.general.KBData.map(
        (item: any, index: number) => {
          if (index === KBId) {
            // Update the specified field for the item at the specified index
            return {
              ...item,
              [field]: value,
            };
          }
          return item; // Return the item unchanged if it's not the one being updated
        }
      );

      // Return the updated state with the modified KBData array
      return {
        ...state,
        general: {
          ...state.general,
          KBData: updatedKBData,
        },
      };
    }

    case CONST.ADD_WAGE_DATA: {
      const { field, value, wageId, profileId } = action;

      switch (field) {
        case "pc": {
          const pcId: any =
            Object.values(state.pcEcoAndMealValues).find(
              (ecoAndMeal: any) => ecoAndMeal.pc_id === value.value
            ) ?? null;
          const newWageTab = {
            pc: value,
            totalValue: pcId?.total_value ?? "",
            employerPart: pcId?.employer_part ?? "",
            minimumHours: pcId?.minimum_hours ?? "",
            isEcoCheque: (pcId?.is_eco_cheque === true ? 1 : 0) ?? null,
            value: pcId?.value ?? "",
            chequeType: pcId?.cheque_type ?? null,
            otherInfo: pcId?.other_info ?? "",
            commProfile: [],
          };
          return {
            ...state,
            wageAndPremiums: [
              ...Object.values(state.wageAndPremiums || {}),
              newWageTab,
            ],
          };
        }

        case "profile": {
          const updatedWageAndPremiums = (
            Object.values(state.wageAndPremiums || {}) as WageTabProps[]
          ).map((item: WageTabProps, index: number) => {
            if (!Array.isArray(item.commProfile)) {
              item.commProfile = []; // Initialize if not already an array
            }
            if (index === wageId) {
              const empTypeId =
                state.commProfileList.find(
                  (profile: any) => profile.value === value.value
                ) ?? null;
              const profileData = {
                profile: value,
                empTypeId: empTypeId.emp_type,
                startDate: new Date(),
                endDate: null,
                mtcTotal: item.totalValue,
                mtcEmployer: item.employerPart,
                mtcMinHrs: item.minimumHours,
                isValueChanged: false,
                brightId: null,
                wageCodes: [],
              };
              return {
                ...item,
                commProfile: [
                  ...Object.values(item.commProfile || {}),
                  profileData,
                ],
              };
            }
            return item;
          });
          return {
            ...state,
            wageAndPremiums: updatedWageAndPremiums,
          };
        }

        case "code": {
          const initializeApplicability = (): Applicability[] => {
            const days = 7;
            const applicability: Applicability[] = [];
            for (let i = 0; i < days; i++) {
              applicability.push({
                isApplicable: false,
                from: "",
                to: "",
                overNight: false,
                minHrs: "",
                maxHrs: "",
                fromError: false,
                toError: false,
              });
            }
            return applicability;
          };
          const updatedWageAndPremiums = (
            Object.values(state.wageAndPremiums || {}) as WageTabProps[]
          ).map((item: WageTabProps, index: number) => {
            if (index === wageId) {
              const updatedCommProfiles = Object.values(
                item.commProfile || {}
              ).map((profile: CommProfileProps, profIndex: number) => {
                if (profIndex === profileId) {
                  if (!Array.isArray(profile.wageCodes)) {
                    profile.wageCodes = []; // Initialize if not already an array
                  }
                  const perfCode =
                    state.perfCodeList.find(
                      (option: any) =>
                        option.paritaircommittee_id === item.pc?.value &&
                        option.salary_benefit_main_id === value?.value
                    ) ?? null;
                  const autoTypeValue =
                    state.autoTypeList.find(
                      (type: any) => type.value === perfCode?.occurence
                    ) ?? null;
                  const codeData = {
                    wageCode: value,
                    amount:
                      perfCode?.variable_type === "amount"
                        ? perfCode.benefit_value
                        : "",
                    percentage:
                      perfCode?.variable_type === "percentage"
                        ? perfCode.benefit_value
                        : "",
                    type: perfCode?.variable_type ?? "",
                    autoType: autoTypeValue ?? null,
                    number: autoTypeValue?.value !== 1 ? "1" : "",
                    paidAbsence: false,
                    holidayApplicable: false,
                    allDaysApplicable: 0,
                    variableType: perfCode?.variable_type ?? "",
                    applicability: initializeApplicability(),
                  };
                  return {
                    ...profile,
                    wageCodes: [
                      ...Object.values(profile.wageCodes || {}),
                      codeData,
                    ],
                  };
                }
                return profile;
              });
              return {
                ...item,
                commProfile: updatedCommProfiles,
              };
            }
            return item;
          });
          return {
            ...state,
            wageAndPremiums: updatedWageAndPremiums,
          };
        }

        default:
          return state;
      }
    }

    case CONST.REMOVE_WAGE_DATA: {
      const { wageId, profileId, codeId } = action;

      // Removing a WageTab
      if (wageId !== null && profileId === undefined && codeId === undefined) {
        return {
          ...state,
          wageAndPremiums: (
            Object.values(state.wageAndPremiums || {}) as WageTabProps[]
          ).filter((_: WageTabProps, index: number) => index !== wageId),
        };
      }

      // Removing a CommProfile
      if (wageId !== null && profileId !== null && codeId === undefined) {
        const updatedWageAndPremiums = (
          Object.values(state.wageAndPremiums || {}) as WageTabProps[]
        ).map((item: WageTabProps, index: number) => {
          if (index === wageId) {
            return {
              ...item,
              commProfile: Object.values(item.commProfile || {}).filter(
                (_, profIndex) => profIndex !== profileId
              ),
            };
          }
          return item;
        });
        return {
          ...state,
          wageAndPremiums: updatedWageAndPremiums,
        };
      }

      // Removing a WageCode
      if (wageId !== null && profileId !== null && codeId !== null) {
        const updatedWageAndPremiums = (
          Object.values(state.wageAndPremiums || {}) as WageTabProps[]
        ).map((item: WageTabProps, index: number) => {
          if (index === wageId) {
            const updatedCommProfiles = Object.values(
              item.commProfile || {}
            ).map((profile, profIndex) => {
              if (profIndex === profileId) {
                return {
                  ...profile,
                  wageCodes: Object.values(profile.wageCodes || {}).filter(
                    (_, codeIndex) => codeIndex !== codeId
                  ),
                };
              }
              return profile;
            });
            return {
              ...item,
              commProfile: updatedCommProfiles,
            };
          }
          return item;
        });
        return {
          ...state,
          wageAndPremiums: updatedWageAndPremiums,
        };
      }

      return state;
    }

    case CONST.CLONE_PAY_PROFILE: {
      const { value, wageId, profileId } = action;

      // Ensure wageId and profileId are valid
      if (wageId === undefined || profileId === undefined) return state;

      const updatedWageAndPremiums = (
        Object.values(state.wageAndPremiums || {}) as WageTabProps[]
      ).map((item: WageTabProps, index: number) => {
        if (index === wageId) {
          // Clone the profile
          const profileToClone = item.commProfile[profileId];
          const clonedProfile = {
            ...profileToClone,
            profile: value,
            wageCodes: [...Object.values(profileToClone?.wageCodes || {})],
            brightId: null,
          };

          // Insert the cloned profile
          const newCommProfile = [...Object.values(item.commProfile || {})];
          newCommProfile.splice(profileId + 1, 0, clonedProfile); // Insert after the original

          return {
            ...item,
            commProfile: newCommProfile,
          };
        }
        return item;
      });

      return {
        ...state,
        wageAndPremiums: updatedWageAndPremiums,
      };
    }

    case CONST.UPDATE_WAGE_FIELD_DATA: {
      const { wageId, profileId, codeId, applicabilityId, field, value } =
        action;

      // Update a WageTab field
      if (wageId !== null && profileId === undefined && codeId === undefined) {
        const updatedWageAndPremiums = (
          Object.values(state.wageAndPremiums || {}) as WageTabProps[]
        ).map((item: WageTabProps, index: number) => {
          if (index === wageId) {
            return {
              ...item,
              [field]: value,
            };
          }
          return item;
        });

        return {
          ...state,
          wageAndPremiums: updatedWageAndPremiums,
        };
      }

      // Update a CommProfile field
      if (
        wageId !== null &&
        profileId !== null &&
        codeId === undefined &&
        applicabilityId === undefined
      ) {
        const updatedWageAndPremiums = (
          Object.values(state.wageAndPremiums || {}) as WageTabProps[]
        ).map((item: WageTabProps, index: number) => {
          if (index === wageId) {
            const updatedCommProfile = Object.values(
              item.commProfile || {}
            ).map((profile, profIndex) => {
              if (profIndex === profileId) {
                return {
                  ...profile,
                  [field]: value,
                };
              }
              return profile;
            });
            return {
              ...item,
              commProfile: updatedCommProfile,
            };
          }
          return item;
        });

        return {
          ...state,
          wageAndPremiums: updatedWageAndPremiums,
        };
      }

      // Update a WageCode field
      if (
        wageId !== null &&
        profileId !== null &&
        codeId !== null &&
        applicabilityId === undefined
      ) {
        const updatedWageAndPremiums = (
          Object.values(state.wageAndPremiums || {}) as WageTabProps[]
        ).map((item: WageTabProps, index: number) => {
          if (index === wageId) {
            const updatedCommProfile = Object.values(
              item.commProfile || {}
            ).map((profile, profIndex) => {
              if (profIndex === profileId) {
                const updatedWageCodes = Object.values(
                  profile.wageCodes || {}
                ).map((code, codeIndex) => {
                  if (codeIndex === codeId) {
                    const updatedCode = {
                      ...code,
                      [field]: value,
                    };
                    if (field === "allDaysApplicable") {
                      updatedCode.applicability = code.applicability.map(
                        (applicable) => ({
                          ...applicable,
                          isApplicable: value,
                        })
                      );
                    }
                    return updatedCode;
                  }
                  return code;
                });
                return {
                  ...profile,
                  wageCodes: updatedWageCodes,
                };
              }
              return profile;
            });
            return {
              ...item,
              commProfile: updatedCommProfile,
            };
          }
          return item;
        });

        return {
          ...state,
          wageAndPremiums: updatedWageAndPremiums,
        };
      }

      if (
        wageId !== null &&
        profileId !== null &&
        codeId !== null &&
        applicabilityId !== null
      ) {
        const updatedWageAndPremiums = (
          Object.values(state.wageAndPremiums || {}) as WageTabProps[]
        ).map((item: WageTabProps, index: number) => {
          if (index === wageId) {
            const updatedCommProfile = Object.values(
              item.commProfile || {}
            ).map((profile, profIndex) => {
              if (profIndex === profileId) {
                const updatedWageCodes = Object.values(
                  profile.wageCodes || {}
                ).map((code, codeIndex) => {
                  if (codeIndex === codeId) {
                    const updatedApplicability = code.applicability.map(
                      (applicable, applicableIndex) => {
                        if (applicabilityId === applicableIndex) {
                          const { fromError, toError } = applicable;

                          const updatedErrors = {
                            fromError:
                              field === "from"
                                ? !isValidTime(value)
                                : fromError,
                            toError:
                              field === "to" ? !isValidTime(value) : toError,
                          };

                          return {
                            ...applicable,
                            ...updatedErrors,
                            [field]: value,
                          };
                        }
                        return applicable;
                      }
                    );
                    return {
                      ...code,
                      applicability: updatedApplicability,
                    };
                  }
                  return code;
                });
                return {
                  ...profile,
                  wageCodes: updatedWageCodes,
                };
              }
              return profile;
            });
            return {
              ...item,
              commProfile: updatedCommProfile,
            };
          }
          return item;
        });

        return {
          ...state,
          wageAndPremiums: updatedWageAndPremiums,
        };
      }

      return state;
    }

    case CONST.UPDATE_DROPDOWN_LIST: {
      const { data } = action;
      return {
        ...state,
        ...data,
      };
    }

    case CONST.UPDATE_TAB_DATA: {
      const { tab, data } = action;
      return {
        ...state,
        [tab]: {
          ...state[tab],
          ...data,
        },
      };
    }

    case CONST.UPDATE_CONTACTS_FIELD:
      return {
        ...state,
        contacts: Object.values(state.contacts).map(
          (contact: any, index: number) => {
            if (index === action.index) {
              return {
                ...contact,
                [action.field]: action.value,
              };
            }
            return contact;
          }
        ),
      };

    case CONST.UPDATE_CONTACT_LIST:
      const contactIds =
        action.contactId && action.contactId.map((item: any) => item.value);
      return {
        ...state,
        contacts: Object.values(state.contacts).map((contact: any) => ({
          ...contact,
          type: contactIds?.includes(contact.id)
            ? 1
            : contact.type !== 2
            ? 0
            : 2,
        })),
        [action.field]: action.value,
      };

    case CONST.ADD_CONTACT:
      // Add a new contact to the contacts array
      const newContact = {
        fName: "",
        lName: "",
        email: "",
        phNumber: "" as E164Number,
        dob: null,
        gsm: "",
        contact: "",
        functionTitle: "",
        linkedIn: "",
        roles: null,
        language: null,
        teleNumber: "",
        decisionMaker: false,
        influencer: false,
        contactCalled: false,
        contactEmailed: false,
        info: "",
        title: null,
        location: null,
        type: 2,
        id: null,
        allowSignature: state.contacts.length === 0 ? true : false,
      };
      const newContactErros = {
        fName: "",
        lName: "",
        email: "",
        phNumber: "",
        teleNumber: "",
        dob: "",
        gsm: "",
        contact: "",
        functionTitle: "",
        linkedIn: "",
        roles: "",
        language: "",
        decisionMaker: "",
        influencer: "",
        contactCalled: "",
        contactEmailed: "",
        info: "",
        title: "",
        location: "",
      };
      return {
        ...state,
        contacts: [...state.contacts, newContact],
        dynamicErrors: [...state.dynamicErrors, newContactErros],
      };

    case CONST.REMOVE_CONTACT:
      // Remove the contact at the specified index
      const updatedContacts: any[] = [...Object.values(state.contacts || {})];
      const updatedContactsErrors = [...state.dynamicErrors];
      if (updatedContacts[action.indexToRemove].type === 2) {
        updatedContacts.splice(action.indexToRemove, 1);
        updatedContactsErrors.splice(action.indexToRemove, 1);
      } else {
        // If type is not 1, update type to 0 for that index
        updatedContacts[action.indexToRemove].type = 0;

        // Remove the matched selectedContact
        const selectedContactId = updatedContacts[action.indexToRemove].id;
        const filteredSelectedContacts = Object.values(
          state.selectedContact
        ).filter((item: any) => item.value !== selectedContactId);

        // Update the state with filtered selectedContact
        return {
          ...state,
          contacts: updatedContacts,
          dynamicErrors: updatedContactsErrors,
          selectedContact: filteredSelectedContacts,
        };
      }
      return {
        ...state,
        contacts: updatedContacts,
        dynamicErrors: updatedContactsErrors,
      };

    case CONST.UPDATE_CONTACTS_FIELD_ERROR:
      return {
        ...state,
        dynamicErrors: state.dynamicErrors.map((error: any, index: number) => {
          if (index === action.index) {
            return {
              ...error,
              [action.field]: action.error,
            };
          }
          return error;
        }),
      };

    case CONST.UPDATE_MEAL_VOUCHERS: {
      const { wageId, field, value } = action;

      // Update a WageTab field
      if (wageId !== null) {
        const updatedWageAndPremiums = (
          Object.values(state.wageAndPremiums || {}) as WageTabProps[]
        ).map((item: WageTabProps, index: number) => {
          if (index === wageId) {
            const updatedCommProfile = Object.values(
              item.commProfile || {}
            ).map((profile) => {
              let updatedProfile = { ...profile };

              // Utility function to replace commas with periods and convert to number
              const formatValue = (value: string): number => {
                const formattedValue = value.replace(/,/g, "."); // Replace all commas with periods
                return formattedValue === "" ? 0 : parseFloat(formattedValue); // Convert to number, using 0 for empty strings
              };

              // Check conditions and update accordingly
              const mtcTotal = formatValue(profile.mtcTotal);
              const mtcEmployer = formatValue(profile.mtcEmployer);
              const mtcMinHrs = formatValue(profile.mtcMinHrs);
              if (field === "totalValue") {
                const totalValue = formatValue(value); // Ensure totalValue is a number
                if (totalValue > mtcTotal) {
                  updatedProfile.mtcTotal = value; // Store the original value or formatted value based on your needs
                }
              }

              if (field === "employerPart") {
                const employerPartValue = formatValue(value); // Ensure employerPartValue is a number
                if (employerPartValue > mtcEmployer) {
                  updatedProfile.mtcEmployer = value; // Store the original value or formatted value based on your needs
                }
              }

              if (field === "minimumHours") {
                const minHrsValue = formatValue(value); // Ensure minHrsValue is a number
                if (minHrsValue > mtcMinHrs) {
                  updatedProfile.mtcMinHrs = value; // Store the original value or formatted value based on your needs
                }
              }

              return updatedProfile;
            });

            return {
              ...item,
              commProfile: updatedCommProfile,
              [field]: value, // Update the main field in the WageTabProps
            };
          }
          return item; // Return unchanged item if wageId does not match
        });

        return {
          ...state,
          wageAndPremiums: updatedWageAndPremiums,
        };
      }
      return state;
    }
    default:
      return state;
  }
};

export default FormReducer;
