import React, { useState } from "react";
import Button from "components/common/atoms/Button";
import {
  scrollToTop,
  validateMultiSelectField,
  validateRequired,
  validateSelectField,
} from "components/common/services/ValidationService";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  t,
} from "components/CentralDataMangement/translation/Translation";
import LoadingIcon from "components/common/utlis/LoadingIcon";
import { useFormContext } from "../../context/Context";
import {
  COMPETENCES,
  EMPLOYMENT,
  GENERAL,
  JOB_DESCRIPTION,
  NEXT,
  NEXT_STEP,
  OFFER,
  PREVIOUS_STEP,
  REQUIREMENT,
  UPDATE_FIELD_ERROR,
  UPDATE_TAB_DETAILS,
  UPDATE_TAB_ERROR,
} from "../../annotation/VacancyConstants";
import {
  CENTRAL_DATA_MANAGEMENT_MICROSERVICE,
  DRAFT,
  DRAFT_SUCCESS_MSG,
  SUBMIT,
  SUCCESS,
} from "Constants";
import { ApiCall } from "components/common/services/ApiServices";
import CustomNotify from "components/common/atoms/CustomNotify";
import { STORE_VACANCY } from "routes/ApiEndpoints";
import { getNextTabId } from "components/common/utlis/TabUtility";

export interface MandatoryFields {
  [key: string]: string[];
}

interface NavProps {
  validStatus: (value: { isValid: boolean; type: string }) => void;
  isLoading?: boolean;
}

const Navigation: React.FC<NavProps> = ({ validStatus, isLoading = false }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { state, dispatch } = useFormContext();
  const location = useLocation();
  const isClone = location.pathname.includes("clone");
  const validateMandatoryFields = (type: string) => {
    const activeTab = state.tabDetails.find((tab) => tab.showStatus);
    const activeTabId = activeTab?.id;

    const mandatoryFieldMapping: { [key: string]: string[] } =
      type === "draft"
        ? {
            general: [
              "company",
              "location",
              "function",
              "domain",
              "office",
              "consultant",
              "language",
              "aliasName",
            ],
          }
        : {
            general: [
              "company",
              "location",
              "function",
              "domain",
              "office",
              "consultant",
              "language",
              "aliasName",
            ],
            employment: ["vdabTemplate", "vdabCompetences"],
            requirement: ["driverLicence"],
          };

    const errorsByTab: {
      [tabId: string]: { [fieldName: string]: string };
    } = {};
    state.tabDetails
      .slice(0, state.tabDetails.findIndex((tab) => tab.id === activeTabId) + 1)
      .forEach((tab) => {
        const tabId = tab.id;
        let tabFields = {};

        switch (tabId) {
          case GENERAL:
            tabFields = state.general;
            break;
          case EMPLOYMENT:
            tabFields = state.employment;
            break;
          case OFFER:
            tabFields = state.offer;
            break;
          case REQUIREMENT:
            tabFields = state.requirement;
            break;
          default:
            break;
        }
        const validationRules: {
          [fieldName: string]: ((value: any) => string | null | undefined)[];
        } = {
          company: [validateSelectField],
          location: [validateSelectField],
          function: [validateSelectField],
          domain: [validateSelectField],
          office: [validateSelectField],
          consultant: [validateSelectField],
          language: [validateSelectField],
          aliasName: [validateRequired],
          vdabTemplate:
            state.general.pushToVDAB === 1 ? [validateSelectField] : [],
          vdabCompetences:
            state.general.pushToVDAB === 1 ? [validateMultiSelectField] : [],
          driverLicence:
            state.general.pushToVDAB === 1 ? [validateMultiSelectField] : [],
        };

        const mandatoryFields = (mandatoryFieldMapping[tabId] ||
          []) as string[];

        const errorsInTab: { [fieldName: string]: string } = {};
        for (const fieldName of mandatoryFields) {
          const fieldValidationRules = validationRules[fieldName];

          const fieldValue = tabFields[fieldName as keyof typeof tabFields];
          if (fieldValidationRules) {
            for (const rule of fieldValidationRules) {
              const validationError = rule(fieldValue);
              if (validationError) {
                errorsInTab[fieldName] = validationError;
                dispatch({
                  type: UPDATE_FIELD_ERROR,
                  field: fieldName,
                  error: validationError,
                });
                break;
              } else {
                dispatch({
                  type: UPDATE_FIELD_ERROR,
                  field: fieldName,
                  error: "",
                });
              }
            }
          }
        }

        if (Object.keys(errorsInTab).length > 0) {
          errorsByTab[tabId] = errorsInTab;
          return false;
        }
      });
    updateTabsStatus(errorsByTab);
    if (Object.keys(errorsByTab).length > 0) {
      return false;
    }

    return true;
  };

  const findTabObjectById = (idToFind: string) => {
    return state.tabDetails.findIndex((tab) => tab.id === idToFind);
  };

  const updateTabsStatus = (errors: object) => {
    const keysToGet = [
      GENERAL,
      EMPLOYMENT,
      COMPETENCES,
      OFFER,
      REQUIREMENT,
      JOB_DESCRIPTION,
    ];
    const selectKeys = keysToGet
      .map((key) => {
        if (errors.hasOwnProperty(key)) {
          const tabIndex = findTabObjectById(key);
          if (tabIndex !== undefined && tabIndex !== null) {
            dispatch({
              type: UPDATE_TAB_ERROR,
              tabIndex,
              error: true,
            });
          }
          return tabIndex;
        }
        return null;
      })
      .filter((key) => key !== null);
    const tabIndexes = [0, 1, 2, 3, 4, 5];
    tabIndexes.forEach((index) => {
      if (!selectKeys.includes(index)) {
        dispatch({
          type: UPDATE_TAB_ERROR,
          tabIndex: index,
          error: false,
        });
      }
    });
  };

  const currentTab = state.tabDetails.find((tab) => tab.showStatus);
  const isGeneralTab = currentTab && currentTab.id === GENERAL;
  const isJobDescriptionTab = currentTab && currentTab.id === JOB_DESCRIPTION;
  const formStatus = state.general.formStatus;

  const handleNextStep = () => {
    dispatch({ type: NEXT_STEP });
    handleSubmitFormData(NEXT);
  };

  const { id } = useParams<{ id: string }>();
  const handleSubmitFormData = async (type: string) => {
    if (type === SUBMIT) {
      setLoading(true);
    }
    const tabId = getNextTabId(state.tabDetails);

    const url = isClone
      ? STORE_VACANCY
      : id
      ? `${STORE_VACANCY}/${id}`
      : STORE_VACANCY;
    const data = {
      general: {
        ...state.general,
        brightId: isClone ? null : state.general.brightId,
      },
      employment: { ...state.employment },
      requirement: { ...state.requirement },
      offer: { ...state.offer },
      jobDescription:
        type === SUBMIT
          ? { ...state.jobDescription, formStatus: 1 }
          : { ...state.jobDescription },
      competences: { ...state.competences.competence },
    };
    const response = await ApiCall.service(
      url,
      "POST",
      data,
      false,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );
    if (response.status === 200) {
      if (type === DRAFT) {
        CustomNotify({
          type: SUCCESS,
          message: t(DRAFT_SUCCESS_MSG),
        });
        navigate("/manage/vacancies");
      } else if (type === SUBMIT) {
        if (isClone) {
          CustomNotify({
            type: "success",
            message: t("Vacancy cloned successfully"),
          });
        } else {
          CustomNotify({
            type: "success",
            message: response.data["msg"],
          });
        }
        navigate("/manage/vacancies");
      } else {
        navigate(`/vacancy/${response.data["id"]}?tabId=${tabId}`);
      }
    } else {
      // response.message
      if (type === SUBMIT) {
        CustomNotify({
          type: "error",
          message: t("Some error occurred"),
        });
      }
    }
    setLoading(false);
  };

  const handlePreviousStep = () => {
    dispatch({ type: PREVIOUS_STEP });
  };

  const handleDraft = () => {
    const update = state.tabDetails.map((tab: any, index: any) => ({
      ...tab,
      draft: true,
    }));

    dispatch({ type: UPDATE_TAB_DETAILS, tabDetails: update });
    const valid = validateMandatoryFields(DRAFT);

    if (!valid) {
      validStatus({ isValid: false, type: DRAFT });
      scrollToTop();
    } else {
      validStatus({ isValid: true, type: DRAFT });
      handleSubmitFormData(DRAFT);
    }
  };

  const handleSubmit = () => {
    const update = state.tabDetails.map((tab, index) => ({
      ...tab,
      draft: false,
    }));
    dispatch({ type: UPDATE_TAB_DETAILS, tabDetails: update });
    const valid = validateMandatoryFields(SUBMIT);
    if (!valid) {
      validStatus({ isValid: false, type: SUBMIT });
      scrollToTop();
    } else {
      validStatus({ isValid: true, type: SUBMIT });
      handleSubmitFormData(SUBMIT);
    }
  };

  return (
    <div className="row backPadding">
      <div className="col-md-6 align-self-center">
        {isGeneralTab && (
          <Link
            to=""
            className="back-btn text-decoration-underline"
            onClick={() => navigate(-1)}
          >
            {t("Back")}
          </Link>
        )}
        {!isGeneralTab && (
          <Button
            type="button"
            title={t("Back")}
            handleClick={handlePreviousStep}
            className="backBtn p-0 text-decoration-underline border-0"
          />
        )}
      </div>
      <div className="col-md-6 ">
        {!loading ? (
          <Button
            type="submit"
            title={isJobDescriptionTab ? t("Submit") : t("Next")}
            handleClick={isJobDescriptionTab ? handleSubmit : handleNextStep}
            className="float-end form-button shadow-none"
          />
        ) : (
          <LoadingIcon
            iconType="bars"
            color="#e5007d"
            className="ms-auto mb-3"
            height={"3vw"}
            width={"3vw"}
          />
        )}
        {(isClone || formStatus !== 1) && !isJobDescriptionTab && (
          <Button
            type="submit"
            title={t("Save as draft")}
            handleClick={handleDraft}
            className="float-end form-button marginRightPoint5 shadow-none"
          />
        )}
      </div>
    </div>
  );
};

export default Navigation;
