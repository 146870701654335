import { LabelWithInputFieldNoError } from "components/common/molecules/LabelWithInputFieldNoError";
import React from "react";
import "../defaultLowCoefficents/defaultLowCoefficient.css";

interface ChildProps {
  typeName: string;
  typeValue: any;
  employeeCategoryId: number;
  employeeType: string;
  expLevelId: string;
  coeffId:string;
  index:string;
  errorStatus:boolean;
  updateCofficientObj: (
    event: React.ChangeEvent<HTMLInputElement>,
    category: number,
    empType: string,
    typeName: string,
    expLevelId:string,
    coeffId:string,
    index:string
  ) => void;
}

const RenderInputFields: React.FC<ChildProps> = ({
  typeName,
  typeValue,
  employeeCategoryId,
  employeeType,
  expLevelId,
  updateCofficientObj,
  coeffId,
  index,
  errorStatus
}) => {  
  return (
    <>
      <td
        key={`emp-${employeeCategoryId}-${employeeType}-${expLevelId}-${coeffId}`}
        className={`form-group p-0 ${ errorStatus ? "error-border" : ""}`}
      >
        <LabelWithInputFieldNoError
        key={`input-${employeeCategoryId}-${employeeType}-${expLevelId}-${coeffId}`}
        type="text"
          className="coefficient w-100 h-100 border-0 rounded-0 text-center"
          value={typeValue ?? ""}
          handleChange={(e) =>
            updateCofficientObj(
              e,
              employeeCategoryId,
              employeeType,
              typeName,
              expLevelId,
              coeffId,
              index
            )
          }
        />
      </td>
    </>
  );
};

export default RenderInputFields;
