import React, { useEffect, useState } from "react";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import { Option, OptionProps } from "components/common/utlis/TypeAnnotations";
import { ApiCall } from "components/common/services/ApiServices";
import { mapToSelect } from "components/common/utlis/MapToSelect";
import { Link, useNavigate } from "react-router-dom";
import Button from "components/common/atoms/Button";
import Pagination from "components/common/atoms/Pagination";
import CustomNotify from "components/common/atoms/CustomNotify";
import EditIcon from "static/images/EditIcon";
import * as ENDPOINTS from "routes/ApiEndpoints";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import Title from "components/common/atoms/Title";
import { faMagnifyingGlass, faPlus } from "@fortawesome/free-solid-svg-icons";
import Reset from "static/images/Reset";
import LinkTo from "components/common/atoms/LinkTo";
import "../defaultLowCoefficents/defaultLowCoefficient.css";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "Constants";
import { Spinner } from "react-bootstrap";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import ResetBtn from "components/common/atoms/ResetBtn";
import SpinnerWrapper from "components/common/atoms/SpinnerWrapper";
import NoRecords from "components/common/atoms/NoRecords";

interface manageData {
  regionName: string;
  countryName: string;
  id: number;
  coeffId: number;
  workUnit: string;
}

interface dropDownsProps {
  region: OptionProps[];
  country: OptionProps[];
  unit: Option | null;
}

const ManageDefaultCoefficients: React.FC = () => {
  //initiliaze the formData state for search fields
  const [formData, setFormData] = useState<dropDownsProps>({
    region: [],
    country: [],
    unit: null,
  });

  //initiliaze the state for dropdown values
  const [dropDowns, setDropDowns] = useState({
    regions: [] as OptionProps[],
    countries: [] as OptionProps[],
    units: [] as OptionProps[],
  });

  const navigate = useNavigate();

  //state for to store the functions linked countries
  const [countries, setCountries] = useState([] as manageData[]);
  const [selectedRegion, setSelectedRegion] = useState<number[]>([]);
  const [loading, setLoading] = useState(true);

  //state for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetchCountryBySearch();
  }, []);

  //page index based on page number
  // let pageIndex = (currentPage - 1) * 20 + 1;

  //API call for to fetch countries
  const fetchCountryBySearch = async (formData = {}, page = 1) => {
    const requestData = {
      ...formData,
      page: page,
      type: "defaultCoefficients",
    };

    const response = await ApiCall.service(
      ENDPOINTS.CONF_SETTINGS_GET,
      "POST",
      requestData,
      false,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );

    if (response.status === 200) {
      const regions = response.data["regions"];
      const countries = response.data["countries"];
      const units = response.data["workUnits"];
      const unitList = mapToSelect(units);
      setDropDowns({
        regions: regions,
        countries: countries,
        units: unitList,
      });
      setCountries(response.data["data"]);
      setTotalPages(response.data["totalPages"]);
      setLoading(false);
    }
  };

  const handleCountryChange = (selectedCountryIds: any) => {
    const region = dropDowns.countries
      .filter((item: any) => selectedCountryIds.includes(item.id))
      .map((item: any) => item.region_id);

    const regionIds = Array.from(new Set(region));
    const selectedRegions = dropDowns.regions
      .filter((item) => regionIds.includes(item.id))
      .map((region) => ({ value: region.id, label: region.name }));
    setFormData((prevData) => ({
      ...prevData,
      region: selectedRegions,
    }));
  };

  const getCountriesForRegion = () => {
    if (selectedRegion.length > 0) {
      const regionBasedCountries = dropDowns.countries.filter((item: any) =>
        selectedRegion.includes(item.region_id)
      );
      return mapToSelect(regionBasedCountries);
    }

    return mapToSelect(dropDowns.countries);
  };

  //changehandler for search fields
  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    if (fieldName === "country") {
      const selectedCountryIds = selectedOption.map(
        (option: OptionProps) => option.value
      );
      handleCountryChange(selectedCountryIds);
      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: selectedOption,
      }));
    }
    if (fieldName === "region") {
      const selectedRegionIds = selectedOption.map(
        (option: OptionProps) => option.value
      );
      setSelectedRegion(selectedRegionIds);
      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: selectedOption,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: selectedOption,
      }));
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (
      formData.region.length === 0 &&
      formData.country.length === 0 &&
      formData.unit === null
    ) {
      CustomNotify({
        type: "warning",
        message: t("Search input not provided") + ".",
      });
      fetchCountryBySearch();
    } else {
      fetchCountryBySearch(formData, 1);
    }
  };

  const handleReset = () => {
    // Clear the selected values in the dropdowns
    setFormData({
      region: [],
      country: [],
      unit: null,
    });
    setCurrentPage(1);
    fetchCountryBySearch();
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    fetchCountryBySearch(formData, newPage);
  };

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Default coefficients",
          read: true,
        },
      ]}
      renderNoAccess={true}
    >
      <Title title={t("Default coefficients")} />
      <div className="row search-bar">
        <div className="col-3">
          <SelectWithSearch
            search={true}
            options={mapToSelect(dropDowns.regions)}
            onChange={(e) => handleSelectChange(e, "region")}
            isMulti={true}
            name="region"
            title={t("Region")}
            value={formData.region}
            isTranslate={true}
          />
        </div>
        <div className="col-3">
          <SelectWithSearch
            search={true}
            options={getCountriesForRegion()}
            onChange={(e) => handleSelectChange(e, "country")}
            isMulti={true}
            name="country"
            title={t("Country")}
            value={formData.country}
          />
        </div>
        <div className="col-3">
          <SelectWithSearch
            search={true}
            options={dropDowns.units}
            onChange={(e) => handleSelectChange(e, "unit")}
            isMulti={false}
            name="unit"
            title={t("Unit type")}
            value={formData.unit}
            isTranslate={true}
          />
        </div>
        <div className="col-3 mt-34">
          <div className="d-flex align-items-center">
            <ResetBtn handleResetClick={handleReset} />
            <Button
              title={t("Search")}
              type="submit"
              icon={faMagnifyingGlass}
              handleClick={handleSubmit}
              className="form-button shadow-none search-btns position-relative text-start"
            />
          </div>
        </div>
      </div>
      {/* Display the table of city profiles */}
      <div className="row">
        <div className="col-12">
          <div className="position-relative tableMainWrapper">
            <AccessControl
              requiredPermissions={[
                {
                  permission: "Default coefficients",
                  create: true,
                },
              ]}
            >
              <div className="row">
                <div className="ManageCreateBtn">
                  <LinkTo
                    pagelink="/coefficients/add"
                    title={t("Add default coefficients")}
                    icon={faPlus}
                  />
                </div>
              </div>
            </AccessControl>
            <div className="table-responsive tableSection paddingTop2">
              <table className="table table-hover">
                <thead>
                  <tr className="TableHeader">
                    <th className="ps-lg-4" style={{ width: "25%" }}>
                      {t("Region")}
                    </th>
                    <th style={{ width: "25%" }}>{t("Country")}</th>
                    <th style={{ width: "25%" }}>{t("Unit type")}</th>
                    <AccessControl
                      requiredPermissions={[
                        {
                          permission: "Default coefficients",
                          update: true,
                          delete: true,
                        },
                      ]}
                      actions={true}
                      strict={false}
                    >
                      <th>{t("Actions")}</th>
                    </AccessControl>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <SpinnerWrapper headerLength={5} />
                  ) : (
                    <>
                      {countries && countries.length > 0 ? (
                        countries.map((country, index) => (
                          <tr
                            key={index}
                            className="border-bottom mb-3 box-shadow align-middle"
                          >
                            <td className="ps-lg-4" data-label={t("Region")}>
                              {t(`${country.regionName}`)}
                            </td>
                            <td data-label={t("Country")}>
                              {t(`${country.countryName}`)}
                            </td>
                            <td data-label={t("Unit type")}>
                              {t(`${country.workUnit}`)}
                            </td>
                            <AccessControl
                              requiredPermissions={[
                                {
                                  permission: "Default coefficients",
                                  update: true,
                                  delete: true,
                                },
                              ]}
                              actions={true}
                              strict={false}
                            >
                              <td className="table-action-icons px-2">
                                <AccessControl
                                  requiredPermissions={[
                                    {
                                      permission: "Default coefficients",
                                      update: true,
                                    },
                                  ]}
                                >
                                  <Link
                                    to={`/coefficients/edit/${country.coeffId}`}
                                    title={t("Edit")}
                                  >
                                    <EditIcon />
                                  </Link>
                                </AccessControl>
                              </td>
                            </AccessControl>
                          </tr>
                        ))
                      ) : (
                        <NoRecords headerLength={7} />
                      )}
                    </>
                  )}
                </tbody>
              </table>
              {totalPages > 1 && (
                <div className="pagination justify-content-center align-items-center">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={(newPage) => handlePageChange(newPage)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="row backPadding">
        <div className="col-md-6 align-self-center">
          <Link
            to=""
            className="back-btn text-decoration-underline"
            onClick={() => navigate(-1)}
          >
            {t("Back")}
          </Link>
        </div>
      </div>
    </AccessControl>
  );
};

export default translate(ManageDefaultCoefficients);
