export const MODAL_STATUS = "MODAL_STATUS";
export const NEXT_STEP = "NEXT_STEP";
export const PREVIOUS_STEP = "PREVIOUS_STEP";
export const UPDATE_TAB_DETAILS = "UPDATE_TAB_DETAILS";
export const UPDATE_TAB_ERROR = "UPDATE_TAB_ERROR";
export const SET_DROPDOWN = "SET_DROPDOWN";
export const UPDATE_GENERAL_FIELD = "UPDATE_GENERAL_FIELD";
export const UPDATE_INVOICE_FIELD = "UPDATE_INVOICE_FIELD";
export const ADD_COMPOSITION_FIELD = "ADD_COMPOSITION_FIELD";
export const UPDATE_COMPOSITION_FIELD = "UPDATE_COMPOSITION_FIELD";
export const UPDATE_FIELD_ERROR = "UPDATE_FIELD_ERROR";
export const ADD_WHITECOLLAR_FIELD = "ADD_WHITECOLLAR_FIELD";
export const REMOVE_WHITE_PC = "REMOVE_WHITE_PC";
export const REMOVE_BLUE_PC = "REMOVE_BLUE_PC";
export const ADD_BLUECOLLAR_FIELD = "ADD_BLUECOLLAR_FIELD";
export const UPDATE_CONTACTS_FIELD = "UPDATE_CONTACTS_FIELD";
export const UPDATE_CONTACT_LIST = "UPDATE_CONTACT_LIST";
export const ADD_CONTACT = "ADD_CONTACT";
export const REMOVE_CONTACT = "REMOVE_CONTACT";
export const ADD_UPDATE_DYNAMIC = "ADD_UPDATE_DYNAMIC";
export const UPDATE_CONTACTS_FIELD_ERROR = "UPDATE_CONTACTS_FIELD_ERROR";
export const UPDATE_BILLING_TAB = "UPDATE_BILLING_TAB";
export const ADD_EMPLOYEE_TYPE = "ADD_EMPLOYEE_TYPE";
export const CLONE_FUNCTION_TYPE = "CLONE_FUNCTION_TYPE";
export const DELETE_FUNCTION_TYPE = "DELETE_FUNCTION_TYPE";
export const DELETE_EMPLOYEE_TYPE = "DELETE_EMPLOYEE_TYPE";
export const UPDATE_BILLING_TAB_FIELD = "UPDATE_BILLING_TAB_FIELD";
export const ADD_FUNCTION_PROFILE = "ADD_FUNCTION_PROFILE";
export const SET_BILLING_TAB_DATA = "SET_BILLING_TAB_DATA";
export const AM_APPROVED_DATA = "AM_APPROVED_DATA";
export const AM_APPROVED_COMPOSITION_DATA = "AM_APPROVED_COMPOSITION_DATA";
export const UPDATE_FEEBASED_ERROR = "UPDATE_FEEBASED_ERROR";
export const UPDATE_FEEBASED_FIELD = "UPDATE_FEEBASED_FIELD";
export const SET_FEEBASED = "SET_FEEBASED";
export const ADD_FEEBASED_FIELD = "ADD_FEEBASED_FIELD";
export const REMOVE_FEEBASED_FIELD = "REMOVE_FEEBASED_FIELD";
export const FEEBASED_ERROR_STATUS = "FEEBASED_ERROR_STATUS";



