import React, { useState } from "react";
import { useFormContext } from "../../context/Context";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import TableData from "./TableData";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import { useNavigate, useParams } from "react-router-dom";
import { CREATE_AGREEMENT } from "routes/ApiEndpoints";
import { ApiCall } from "components/common/services/ApiServices";
import CustomNotify from "components/common/atoms/CustomNotify";
import { scrollToTop } from "components/common/services/ValidationService";
import { MANAGE_COOPERATION_AGREEMENT } from "routes/RouteConstants";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import * as CONST from "../../annotations/CoopAgreementConstants";
import { COMPANY_MICROSERVICE } from "Constants";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import Navigation from "../formNavigation/Navigation";

const BillingTab = () => {
  const { state, dispatch } = useFormContext();
  const { agreementId } = useParams<{ agreementId: string }>();
  const { companyId } = useParams<{ companyId: string }>();
  const userAuth = useSelector(selectAuth);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [validationStatus, setValidationStatus] = useState({
    isValid: false,
    type: "",
  });

  const addEmployeeType = (pcIndex: number, funcIndex: number) => {
    dispatch({ type: CONST.ADD_EMPLOYEE_TYPE, pcIndex, funcIndex });
  };

  const deleteEmployeeType = (
    pcIndex: number,
    funcIndex: number,
    empRowIndex: number
  ) => {
    dispatch({
      type: CONST.DELETE_EMPLOYEE_TYPE,
      pcIndex,
      funcIndex,
      empRowIndex,
    });
  };

  const cloneFunctionType = (pcIndex: number, funcIndex: number) => {
    dispatch({ type: CONST.CLONE_FUNCTION_TYPE, pcIndex, funcIndex });
  };

  const deleteFunctionType = (pcIndex: number, funcIndex: number) => {
    dispatch({ type: CONST.DELETE_FUNCTION_TYPE, pcIndex, funcIndex });
  };

  const addFunctionProfile = (pcIndex: number) => {
    dispatch({ type: CONST.ADD_FUNCTION_PROFILE, pcIndex });
  };

  const handleFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    pcIndex: number | null,
    funcIndex: number | null,
    empRowIndex: number | null,
    coeffIndex: number | null
  ) => {
    const { name, value } = event.target;
    if (name === "retentionCount") {
      dispatch({
        type: CONST.UPDATE_BILLING_TAB_FIELD,
        field: name,
        value,
        pcIndex,
        funcIndex,
        empRowIndex,
        coeffIndex,
      });
    } else {
      const regex = /^(\d{1,2})(,\d{0,4})?$/;
      let matches = regex.exec(value);
      if (matches || value === "") {
        dispatch({
          type: CONST.UPDATE_BILLING_TAB_FIELD,
          field: name,
          value,
          pcIndex,
          funcIndex,
          empRowIndex,
          coeffIndex,
        });
      }
    }
  };

  const acceptOrReject = (
    pcIndex: number,
    funcIndex: number | null,
    empRowIndex: number | null,
    coeffIndex: number,
    status: number
  ) => {
    const coeffObj =
      state.billing[pcIndex]?.functions[funcIndex as number]?.employeeTypes[
        empRowIndex as number
      ]?.coefficients[coeffIndex];
    if (status === 2) {
      Object.keys(coeffObj)
        .filter((key) => key.endsWith("_approval_status"))
        .forEach((key) => {
          const approvalStatus = coeffObj[key];
          if (approvalStatus === 1 || approvalStatus === 3) {
            const errorKey = key.replace("_approval_status", "_error_status");
            dispatch({
              type: CONST.UPDATE_BILLING_TAB_FIELD,
              field: key,
              value: status,
              pcIndex,
              funcIndex,
              empRowIndex,
              coeffIndex,
            });
            dispatch({
              type: CONST.UPDATE_BILLING_TAB_FIELD,
              field: errorKey,
              value: false,
              pcIndex,
              funcIndex,
              empRowIndex,
              coeffIndex,
            });
          }
        });
    } else {
      Object.keys(coeffObj)
        .filter((key) => key.endsWith("_approval_status"))
        .forEach((key) => {
          const approvalStatus = coeffObj[key];
          if (approvalStatus === 1) {
            dispatch({
              type: CONST.UPDATE_BILLING_TAB_FIELD,
              field: key,
              value: status,
              pcIndex,
              funcIndex,
              empRowIndex,
              coeffIndex,
            });
          }
        });
    }

    dispatch({
      type: CONST.UPDATE_BILLING_TAB_FIELD,
      field: "acceptOrRejectMail",
      value: status,
      pcIndex,
      funcIndex,
      empRowIndex,
      coeffIndex,
    });
  };

  const handleSelectChange = (
    selectedOption: any,
    title: string,
    pcIndex: number,
    funcIndex: number | null,
    empRowIndex: number | null
  ) => {
    if (title === "level" || title === "empType") {
      const pcId = state.billing[pcIndex].pc?.value;
      const empType =
        title === "empType"
          ? selectedOption?.value
          : state.billing[pcIndex]?.functions[funcIndex as number]
              ?.employeeTypes[empRowIndex as number]?.empType?.value;
      const levelType =
        title === "level"
          ? selectedOption?.value
          : state.billing[pcIndex]?.functions[funcIndex as number]
              ?.employeeTypes[empRowIndex as number]?.level?.value;
      if (
        empType !== undefined &&
        empType !== null &&
        String(empType) !== "" &&
        levelType !== undefined &&
        String(levelType) !== "" &&
        levelType !== null
      ) {
        Object.entries(state.initialEmpCoeff).forEach(([key, empTypes]) => {
          // Filter based on pcId
          if (pcId === parseInt(key)) {
            Object.entries(empTypes).forEach(([key2, coeff]) => {
              // Filter based on empType
              if (empType === parseInt(key2)) {
                // Explicitly type coeff as an object
                const coefficients: Record<string, any> = coeff as Record<
                  string,
                  any
                >;
                Object.entries(coefficients).forEach(([key3, coeffvalue]) => {
                  if (levelType === parseInt(key3)) {
                    Object.entries(coeffvalue).forEach(
                      ([field, value]: [string, any]) => {
                        dispatch({
                          type: CONST.UPDATE_BILLING_TAB_FIELD,
                          field: field,
                          value: value.niceToHave as
                            | string
                            | number
                            | null
                            | undefined,
                          pcIndex,
                          funcIndex,
                          empRowIndex,
                          coeffIndex: 0,
                        });
                      }
                    );
                  }
                });
              }
            });
          }
        });
      }
    }

    dispatch({
      type: CONST.UPDATE_BILLING_TAB_FIELD,
      field: title,
      value: selectedOption,
      pcIndex,
      funcIndex,
      empRowIndex,
      coeffIndex: null,
    });
  };

  const handleDateChange = (
    date: Date | null,
    name: string,
    pcIndex: number,
    funcIndex: number,
    empRowIndex: number
  ) => {
    dispatch({
      type: CONST.UPDATE_BILLING_TAB_FIELD,
      field: name,
      value: date,
      pcIndex,
      funcIndex,
      empRowIndex,
      coeffIndex: 0,
    });
  };

  const validStatus = (validation: any) => {
    setValidationStatus({
      isValid: validation.isValid,
      type: validation.type,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    const currentIndex = state.tabDetails.findIndex((tab) => tab.showStatus);
    const currentId = state.tabDetails[currentIndex].id;
    e.preventDefault();
    const processedBillingData = state.billing.map(
      ({ empTypeDropdownValues, ...billingData }) => billingData
    );
    const formData = {
      general: {
        ...state.general,
        companyId: companyId,
      },
      contacts: state.contacts,
      billing: processedBillingData,
    };

    if (
      validationStatus.isValid &&
      state.general.vat !== "" &&
      state.general.company !== ""
    ) {
      setLoading(true);
      const url = !agreementId
        ? CREATE_AGREEMENT
        : `${CREATE_AGREEMENT}/${agreementId}`;
      const response = await ApiCall.service(
        url,
        "POST",
        formData,
        false,
        COMPANY_MICROSERVICE
      );
      if (response.status === 200) {
        if (validationStatus.type === "draft") {
          CustomNotify({
            type: "success",
            message: t("Draft saved successfully") + "!",
          });
          navigate(MANAGE_COOPERATION_AGREEMENT);
        } else if (validationStatus.type === "next") {
          navigate(
            `/cooperation-agreement/create/${companyId}/${response.data}?tabId=${currentId}`
          );
        }
      } else if (response.status === 400) {
        CustomNotify({ type: "error", message: response.msg.vat_number });
        dispatch({ type: CONST.UPDATE_TAB_ERROR, tabIndex: 0, error: true });
        scrollToTop();
        state.fieldError.vat = response.msg.vat_number;
      } else {
        if (response?.emails) {
          state.contacts.forEach((contact, index) => {
            if (response.emails.includes(contact.email)) {
              state.dynamicErrors[index].email = t(
                "Email already exist with a different role"
              );
            }
          });

          dispatch({ type: CONST.UPDATE_TAB_ERROR, tabIndex: 1, error: true });
          CustomNotify({
            type: "error",
            message: t(response?.msg),
          });
        }
      }
    }
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12">
          <div className="agreement-billing-tab">
            <div className="form-border" style={{ paddingTop: "3.4vw" }}>
              {state.billing.length > 0 ? (
                state.billing.map((item: any, pcIndex: number) => (
                  <React.Fragment key={`retention-${pcIndex}`}>
                    {pcIndex === 0 && (
                      <div key={`retention-${pcIndex}`}>
                        <div className="row" key={`retention-row-${pcIndex}`}>
                          <div
                            className="col-xl-10 col-md-9"
                            key={`retentionCount-row-${pcIndex}`}
                          >
                            <LabelWithInputField
                              isMandatory={true}
                              name="retentionCount"
                              handleChange={(event) =>
                                handleFieldChange(
                                  event,
                                  pcIndex,
                                  null,
                                  null,
                                  null
                                )
                              }
                              value={item.retentionCount ?? ""}
                              label={t(
                                "Duration after which the candidate can be employed under company or customer payroll"
                              )}
                              error={state.fieldError.retentionCount}
                              type="number"
                            />
                          </div>
                          <div
                            className="col-xl-2 col-md-3 billing-days-dropdwon"
                            key={`retentionPeriod-row-${pcIndex}`}
                            style={{marginTop:"0.2vw"}}
                          >
                            <SelectWithSearch
                              search={false}
                              options={state.retentionPeriods}
                              onChange={(e) =>
                                handleSelectChange(
                                  e,
                                  "retentionPeriod",
                                  pcIndex,
                                  null,
                                  null
                                )
                              }
                              name="retentionPeriod"
                              value={item.retentionPeriod ?? ""}
                              error={state.fieldError.retentionPeriod}
                              isTranslate={true}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <div key={`pc-${pcIndex}`}>
                      <div className="pcName mb-3">{item?.pc?.label ?? ""}</div>
                      <div className="table-responsive">
                        <table
                          className="table table-bordered default-coefficient-table cooperation-billing-table"
                          key={`table-${pcIndex}`}
                        >
                          <thead key={pcIndex}>
                            <tr
                              className="TableHeader bg-white cooperation-billing-first-header"
                              key={`thead-${pcIndex}`}
                            >
                              <th className="text-center" rowSpan={2}>
                                {t("Profile")}
                              </th>
                              <th className="text-center" rowSpan={2}>
                                {t("Employee type")}
                              </th>
                              <th className="text-center" rowSpan={2}>
                                {t("Level")}
                              </th>
                              {state.coeffTypes &&
                                state.coeffTypes.length > 0 &&
                                state.coeffTypes.map(
                                  (item: any, index: number) => (
                                    <th
                                      key={`coeff-type-${index}`}
                                      rowSpan={2}
                                      className="text-center"
                                    >
                                      {t(`${item.coeff_name}`)}
                                    </th>
                                  )
                                )}
                              <th
                                className={`text-center ${
                                  userAuth.role === "AREA_MANAGER"
                                    ? "actionHeader"
                                    : ""
                                }`}
                                rowSpan={2}
                              >
                                {t("Action")}
                              </th>
                            </tr>
                          </thead>
                          <tbody key={`tbody-${pcIndex}`}>
                            {Array.isArray(item.functions) &&
                              item.functions.map(
                                (func: any, funcIndex: number) => (
                                  <React.Fragment
                                    key={`tbody-${pcIndex}-func-${funcIndex}`}
                                  >
                                    {Array.isArray(func.employeeTypes) &&
                                      func.employeeTypes.map(
                                        (empRow: any, empRowIndex: number) => (
                                          <React.Fragment
                                            key={`tbody-${pcIndex}-func-${funcIndex}-emp-${empRowIndex}`}
                                          >
                                            {Array.isArray(
                                              empRow.coefficients
                                            ) &&
                                              empRow.coefficients.map(
                                                (
                                                  coeff: any,
                                                  coeffIndex: number
                                                ) => (
                                                  <tr
                                                    className="align-middle border-bottom"
                                                    key={`tbody-${pcIndex}-func-${funcIndex}-emp-${empRowIndex}-coeff-${coeffIndex}`}
                                                  >
                                                    <TableData
                                                      pcIndex={pcIndex}
                                                      funcData={func}
                                                      funcIndex={funcIndex}
                                                      empTypeData={empRow}
                                                      empRowIndex={empRowIndex}
                                                      coeffIndex={coeffIndex}
                                                      dropDown={item}
                                                      coeffData={coeff}
                                                      stateData={state}
                                                      addFunctionProfile={
                                                        addFunctionProfile
                                                      }
                                                      cloneFunctionType={
                                                        cloneFunctionType
                                                      }
                                                      deleteFunctionType={
                                                        deleteFunctionType
                                                      }
                                                      addEmployeeType={
                                                        addEmployeeType
                                                      }
                                                      deleteEmployeeType={
                                                        deleteEmployeeType
                                                      }
                                                      handleSelectChange={
                                                        handleSelectChange
                                                      }
                                                      handleFieldChange={
                                                        handleFieldChange
                                                      }
                                                      acceptOrReject={
                                                        acceptOrReject
                                                      }
                                                      role={userAuth.role}
                                                      handleDateChange={
                                                        handleDateChange
                                                      }
                                                    />
                                                  </tr>
                                                )
                                              )}
                                          </React.Fragment>
                                        )
                                      )}
                                  </React.Fragment>
                                )
                              )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </React.Fragment>
                ))
              ) : (
                <>
                  {state.general.paritairBlue.length === 0 &&
                  state.general.paritairWhite.length === 0 ? (
                    <div className="text-danger">
                      {t("Please select atleast one Paritair committee")}
                    </div>
                  ) : (
                    <div>{t("Loading")}</div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Navigation validStatus={validStatus} isLoading={loading} />
    </form>
  );
};
export default translate(BillingTab);
