import React, { useEffect, useState } from "react";
import { ApiCall } from "components/common/services/ApiServices";
import { Link, useNavigate } from "react-router-dom";
import Button from "components/common/atoms/Button";
import Pagination from "components/common/atoms/Pagination";
import CustomNotify from "components/common/atoms/CustomNotify";
import EditIcon from "static/images/EditIcon";
import * as ENDPOINTS from "routes/ApiEndpoints";
import DeleteIcon from "static/images/DeleteIcon";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import ModalPopup from "components/common/atoms/ModalPopup";
import Reset from "static/images/Reset";
import { faMagnifyingGlass, faPlus } from "@fortawesome/free-solid-svg-icons";
import LinkTo from "components/common/atoms/LinkTo";
import Title from "components/common/atoms/Title";
import { Spinner } from "react-bootstrap";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import { CREATE_BUSINESS_UNIT } from "routes/RouteConstants";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import SpinnerWrapper from "components/common/atoms/SpinnerWrapper";
import NoRecords from "components/common/atoms/NoRecords";
import ResetBtn from "components/common/atoms/ResetBtn";

interface manageData {
  name: string;
  id: number;
}

interface Props {
  unitName: "";
}

const ManageCoefficient: React.FC = () => {
  //initiliaze the formData state for search fields
  const [formData, setFormData] = useState<Props>({
    unitName: "",
  });

  const navigate = useNavigate();

  //state for to store the functions linked countries
  const [data, setData] = useState([] as manageData[]);

  //state for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [deleteID, setDeleteId] = useState<number | undefined>();

  useEffect(() => {
    fetchDataBySearch();
  }, []);

  //API call for to fetch Employee types
  const fetchDataBySearch = async (formData = {}, page = 1) => {
    const requestData = {
      ...formData,
      page: page,
      type: "businessUnit",
    };

    const response = await ApiCall.service(
      ENDPOINTS.CONF_SETTINGS_GET,
      "POST",
      requestData,
      false,
      "central-data-management"
    );

    if (response.status === 200) {
      setData(response.data["data"]);
      setTotalPages(response.data["totalPages"]);
      setLoading(false);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (formData.unitName.length === 0) {
      CustomNotify({
        type: "warning",
        message: t("Search input not provided") + ".",
      });
      fetchDataBySearch();
    } else {
      fetchDataBySearch(formData, 1);
    }
  };

  const handleReset = () => {
    // Clear the selected values in the dropdowns
    setFormData({
      unitName: "",
    });
    setCurrentPage(1);
    fetchDataBySearch();
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    fetchDataBySearch(formData, newPage);
  };

  const deleteData = (id: number | undefined) => {
    setShowModal(true);
    setDeleteId(id);
  };

  const handleModalClose = () => setShowModal(!showModal);

  const handleSaveChanges = async () => {
    setShowModal(false);
    if (deleteID) {
      const requestData = {
        id: deleteID,
        type: "businessUnit",
      };
      const url = ENDPOINTS.CONF_SETTINGS_DELETE;
      const response = await ApiCall.service(
        url,
        "POST",
        requestData,
        false,
        "central-data-management"
      );
      if (response.status === 200) {
        fetchDataBySearch();
        CustomNotify({ type: "success", message: t(response.msg) });
      }
    }
  };

  const handleFieldChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Business units",
          read: true,
        },
      ]}
      renderNoAccess={true}
    >
      <Title title={t("Business units")} />
      <div className="row search-bar">
        <div className="col-8">
          <LabelWithInputField
            handleChange={handleFieldChange}
            name="unitName"
            value={formData.unitName}
            label={t("Unit")}
          />
        </div>
        <div className="col-4 mt-34">
          <div className="d-flex align-items-center">
            <ResetBtn handleResetClick={handleReset} />
            <Button
              title={t("Search")}
              type="submit"
              icon={faMagnifyingGlass}
              handleClick={handleSubmit}
              className="form-button shadow-none search-btns text-start position-relative"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="position-relative tableMainWrapper">
            <AccessControl
              requiredPermissions={[
                {
                  permission: "Business units",
                  create: true,
                },
              ]}
            >
              <div className="row">
                <div className="ManageCreateBtn">
                  <LinkTo
                    pagelink={CREATE_BUSINESS_UNIT}
                    title={t("Add business unit")}
                    icon={faPlus}
                  />
                </div>
              </div>
            </AccessControl>
            <div className="table-responsive tableSection paddingTop2">
              <table className="table table-hover">
                <thead>
                  <tr className="TableHeader">
                    <th className="ps-lg-4">{t("Unit")}</th>
                    <AccessControl
                      requiredPermissions={[
                        {
                          permission: "Business units",
                          update: true,
                          delete: true,
                        },
                      ]}
                      actions={true}
                      strict={false}
                    >
                      <th>{t("Actions")}</th>
                    </AccessControl>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <SpinnerWrapper headerLength={3} />
                  ) : (
                    <>
                      {data && data.length > 0 ? (
                        data.map((item: any, index: number) => (
                          <tr
                            key={item.id}
                            className="border-bottom mb-3 box-shadow align-middle"
                          >
                            <td
                              className="ps-lg-4"
                              data-label={t("Unit")}
                              style={{ width: "50%" }}
                            >
                              {t(`${item.name}`)}
                            </td>
                            <AccessControl
                              requiredPermissions={[
                                {
                                  permission: "Business units",
                                  update: true,
                                  delete: true,
                                },
                              ]}
                              actions={true}
                              strict={false}
                            >
                              <td className="table-action-icons px-2">
                                <AccessControl
                                  requiredPermissions={[
                                    {
                                      permission: "Business units",
                                      update: true,
                                    },
                                  ]}
                                >
                                  <Link
                                    to={`/unit/edit/${item.id}`}
                                    className="btn p-0 border-0 me-2"
                                    title={t("Edit")}
                                  >
                                    <EditIcon />
                                  </Link>
                                </AccessControl>
                                {/* for future */}
                                <AccessControl
                                  requiredPermissions={[
                                    {
                                      permission: "Business units",
                                      delete: true,
                                    },
                                  ]}
                                >
                                  {item.id > 7 && (
                                    <Button
                                      className="btn p-0 border-0"
                                      handleClick={() => deleteData(item.id)}
                                      toolTipName="Delete"
                                    >
                                      <span title={t("Delete")}>
                                        <DeleteIcon />
                                      </span>
                                    </Button>
                                  )}
                                </AccessControl>
                              </td>
                            </AccessControl>
                          </tr>
                        ))
                      ) : (
                        <NoRecords headerLength={7} />
                      )}
                    </>
                  )}
                </tbody>
              </table>
              {totalPages > 1 && (
                <div className="pagination justify-content-center align-items-center mt-4">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={(newPage) => handlePageChange(newPage)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row backPadding">
        <div className="col-md-6 align-self-center">
          <Link
            to=""
            className="back-btn text-decoration-underline"
            onClick={() => navigate(-1)}
          >
            {t("Back")}
          </Link>
        </div>
      </div>
      <ModalPopup
        show={showModal}
        onHide={handleModalClose}
        title={t("Delete confirmation")}
        body={t("Are you sure want to delete") + "?"}
        onCloseButtonClick={handleModalClose}
        onConfirmButtonClick={handleSaveChanges}
        closeTitle={t("No")}
        confirmTitle={t("Yes")}
      />
    </AccessControl>
  );
};

export default translate(ManageCoefficient);
