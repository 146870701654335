import { ApiCall } from "components/common/services/ApiServices";
import { COMPANY_MICROSERVICE } from "Constants";
import * as ENDPOINTS from "../../../../../../routes/ApiEndpoints";

export const GetTableData = async (
  regime: number | null,
  shift: number | null
): Promise<any | null> => {
  try {
    // Mapping regime and shift to corresponding IDs
    const idMapping: { [key: string]: number } = {
      "1-3": 1,
      "2-1": 2,
      "2-2": 3,
      "2-3": 4,
      "2-4": 5,
      "3-4": 6,
    };

    const id = idMapping[`${regime}-${shift}`];

    if (id) {
      const response = await ApiCall.getService(
        `${ENDPOINTS.GET_SHIFT_DETAILS}/${id}`,
        "GET",
        COMPANY_MICROSERVICE,
        false
      );

      if (response.status === 200) {
        const shiftData = response?.data?.shiftDetails?.timeTable;
        return shiftData;
      }
    } else {
      console.warn("No valid ID found for the provided regime and shift");
    }
  } catch (error) {
    console.error("Failed to fetch shift details:", error);
  }

  return null; // Return null if no valid ID or an error occurred
};
