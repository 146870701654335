import Title from "components/common/atoms/Title";
import { t, translate } from "../../translation/Translation";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import { ValidationRules } from "components/common/utlis/TypeAnnotations";
import { useEffect, useState } from "react";
import { ApiCall } from "components/common/services/ApiServices";
import * as ENDPOINTS from "routes/ApiEndpoints";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "Constants";
import { Link, useNavigate, useParams } from "react-router-dom";
import Button from "components/common/atoms/Button";
import {
  validateForm,
  validateSelectField,
} from "components/common/services/ValidationService";
import { Option } from "components/common/utlis/TypeAnnotations";
import CustomNotify from "components/common/atoms/CustomNotify";
import LoadingIcon from "components/common/utlis/LoadingIcon";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";

interface Props {
  id?: number | string | null;
  region: Option | null;
  offices: Option | null;
}

const LinkOfficeToRegions: React.FC = () => {
  const { id: regionId } = useParams<{ id: string }>();
  const [edit, setEdit] = useState(regionId != null ? true : false);
  const [formData, setFormData] = useState<Props>({
    id: regionId,
    region: null,
    offices: null,
  });
  const navigate = useNavigate();
  const [regions, setRegions] = useState([{}]);
  const [officeOptions, setOfficeOptions] = useState([{}]);
  const initialErrors: { [key: string]: string } = {};
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>(
    initialErrors
  );

  useEffect(() => {
    fetchRegion();
  }, []);

  //API call for to fetch WageElements
  const fetchRegion = async () => {
    const id = regionId ?? null;
    const requestData = {
      id: id,
      type: "officeLinking",
    };

    const response = await ApiCall.service(
      ENDPOINTS.BUDGET_EDIT + "/officeLinking",
      "POST",
      requestData,
      false,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );

    if (response.status === 200) {
      setRegions(response.data["regions"]);
      setOfficeOptions(response.data["offices"]);
      if (id != null) {
        formData.region = response.data["formRegion"];
        formData.offices = response.data["formOffices"];
      }
    }
  };

  const validation = (
    name: string,
    value: string | boolean | null,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      region: [validateSelectField],
      offices: [validateSelectField],
    };

    const validationErrors = validateForm(
      { ...formData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation && Object.keys(errors).length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    setFormData((prevData: any) => ({
      ...prevData,
      [fieldName]: selectedOption,
    }));
    validation(fieldName, selectedOption, true);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    setLoading(true);
    e.preventDefault();

    const { name, value } = e.target as HTMLInputElement;
    const data = { id: regionId, type: "officeLinking", ...formData };
    if (validation(name, value)) {
      let response = await ApiCall.service(
        ENDPOINTS.BUDGET_ADD + "/officeLinking",
        "POST",
        data,
        false,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.status === 200) {
        navigate(-1);
        CustomNotify({ type: "success", message: response.msg });
      } else if (response.status === 400) {
        let errors: any = [];
        for (const key in response.errors) {
          errors[key] = response.errors[key];
        }
        setErrors({ ...errors });
      }
    }
    setLoading(false);
  };

  const permissionType = regionId ? "update" : "create";
  const permissionObject: any = {
    permission: "Link office",
  };
  permissionObject[permissionType] = true;
  return (
    <AccessControl
      requiredPermissions={[permissionObject]}
      renderNoAccess={true}
    >
      <Title title={t("Link offices")} />
      <div className="row">
        <div className="col-12">
          <div className="form-border">
            <div className="col-md-12">
              <SelectWithSearch
                title={t("Select region")}
                search={true}
                options={regions}
                placeHolder={t("Select region")}
                onChange={(e) => handleSelectChange(e, "region")}
                isDisabled={edit}
                name="region"
                value={formData?.region}
                error={errors.region}
                isTranslate={true}
              />
            </div>
            <div className="col-md-12">
              <SelectWithSearch
                title={t("Select offices")}
                search={true}
                options={officeOptions}
                placeHolder={t("Select offices")}
                onChange={(e) => handleSelectChange(e, "offices")}
                isMulti={true}
                name="offices"
                value={formData?.offices}
                error={errors.offices}
                isTranslate={true}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row backPadding">
        <div className="col-md-6 align-self-center">
          <Link
            to=""
            className="back-btn text-decoration-underline"
            onClick={() => navigate(-1)}
          >
            {t("Back")}
          </Link>
        </div>
        <AccessControl
          requiredPermissions={[
            {
              permission: "Office region",
              update: true,
              create: true,
            },
          ]}
          actions={true}
          strict={false}
        >
          <div className="col-6 text-end">
            {!loading ? (
              <Button
                title={t("Save")}
                className="form-button shadow-none"
                handleClick={handleSubmit}
              />
            ) : (
              <LoadingIcon
                iconType="bars"
                color="#ff4dae"
                className="float-end me-5"
                height={"3vw"}
                width={"3vw"}
              />
            )}
          </div>
        </AccessControl>
      </div>
    </AccessControl>
  );
};

export default translate(LinkOfficeToRegions);
