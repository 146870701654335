import React, { useState } from "react";
import Navigation from "../form-navigation/Navigation";
import { t } from "components/CentralDataMangement/translation/Translation";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import { useFormContext } from "../../context/Context";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import FileUpload from "components/common/molecules/FileUpload";
import CheckBoxField from "components/common/atoms/CheckBoxField";
import LabelField from "components/common/atoms/LabelField";
import InfoIcon from "static/images/InfoIcon";
import {
  UPDATE_FIELDS,
  GENERAL_DETAILS,
  UPDATE_FIELD_ERROR,
} from "../../annotation/PlanningConstants";

const GeneralTab: React.FC = () => {
  const { state, dispatch } = useFormContext();

  const fileUploadStyleObject: React.CSSProperties = {
    width: "100%",
  };
  const [validationStatus, setValidationStatus] = useState({
    isValid: false,
    type: "",
  });

  const validStatus = (validation: any) => {
    setValidationStatus({
      isValid: validation.isValid,
      type: validation.type,
    });
  };

  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    dispatch({
      type: UPDATE_FIELDS,
      tab: GENERAL_DETAILS,
      field: fieldName,
      value: selectedOption,
    });
  };

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type } = event.target;
    let updatedValue: string | number | null = value;
    if (type === "checkbox") {
      updatedValue = event.target.checked ? 1 : 0;
    }
    dispatch({
      type: UPDATE_FIELDS,
      tab: GENERAL_DETAILS,
      field: name,
      value: updatedValue,
    });
  };

  const returnFileUploadErrors = (errorResposne: any) => {
    const step = errorResposne["step"];
    const error = errorResposne["error"];
    dispatch({ type: UPDATE_FIELD_ERROR, field: step, error: error });
  };

  const fileUploadResponse = (responseObj: any) => {
    const step = responseObj["step"];
    const response = responseObj["response"];

    const fieldMappings: { [key: string]: string[] } = {
      fileId: ["fileId", "fileName", "filePath"],
    };

    const fieldNames = fieldMappings[step];

    if (fieldNames) {
      fieldNames.forEach((fieldName: string, key: number) => {
        dispatch({
          type: UPDATE_FIELDS,
          tab: GENERAL_DETAILS,
          field: fieldName,
          value: response[key],
        });
      });
    }
  };

  const deleteResponse = (field: string) => {
    const fieldMappings: { [key: string]: string[] } = {
      fileId: ["fileId", "fileName", "filePath"],
    };

    const fieldNames = fieldMappings[field];

    if (fieldNames) {
      fieldNames.forEach((fieldName: string) => {
        dispatch({
          type: UPDATE_FIELDS,
          tab: GENERAL_DETAILS,
          field: fieldName,
          value: fieldName.endsWith("Id") ? null : "",
        });
      });
    }
  };

  return (
    <>
      <div className="form-border"style={{paddingTop:"3.4vw"}}>
        <div className="row">
          <div className="col-md-12">
            <LabelField className="tab-subtitle" title={t("Address")} />
          </div>
          <div className="col-sm-12 col-md-6">
            <LabelWithInputField
              isMandatory={false}
              name="street"
              handleChange={handleFieldChange}
              value={state.generalDetails.street}
              id="street"
              label={t("Street")}
              type="text"
            />
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <LabelWithInputField
                  isMandatory={false}
                  name="number"
                  handleChange={handleFieldChange}
                  value={state.generalDetails.number}
                  id="number"
                  label={t("Number")}
                  type="text"
                />
              </div>
              <div className="col-sm-12 col-md-6">
                <LabelWithInputField
                  isMandatory={false}
                  name="box"
                  handleChange={handleFieldChange}
                  value={state.generalDetails.box}
                  id="box"
                  label={t("Box")}
                  type="text"
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-4">
            <LabelWithInputField
              isMandatory={false}
              name="zipCode"
              handleChange={handleFieldChange}
              value={state.generalDetails.zipCode}
              id="zipCode"
              label={t("Zip code")}
              type="text"
            />
          </div>
          <div className="col-sm-12 col-md-4">
            <SelectWithSearch
              title={t("Country")}
              isMandatory={false}
              search={true}
              options={state.dropDowndata.countryOptions}
              onChange={(e) => handleSelectChange(e, "country")}
              isMulti={false}
              name="country"
              value={state.generalDetails.country}
            />
          </div>
          <div className="col-sm-12 col-md-4">
            <SelectWithSearch
              title={t("City")}
              isMandatory={false}
              search={true}
              options={state.dropDowndata.cityOptions}
              onChange={(e) => handleSelectChange(e, "city")}
              isMulti={false}
              name="city"
              value={state.generalDetails.city}
              isTranslate={true}
            />
          </div>
          {/* {state.generalDetails.country?.value &&
          state.generalDetails.country.value === 29 ? (
            <div className="col-sm-12 col-md-4">
              <SelectWithSearch
                title={t("City")}
                isMandatory={false}
                search={true}
                options={dummyOptions}
                onChange={(e) => handleSelectChange(e, "city")}
                isMulti={false}
                name="city"
                value={state.generalDetails.city}
                isTranslate={true}
              />
            </div>
          ) : (
            <div className="col-sm-12 col-md-4">
              <LabelWithInputField
                isMandatory={false}
                name="cityOther"
                handleChange={handleFieldChange}
                value={state.generalDetails.cityOther}
                id="cityOther"
                label={t("City")}
                type="text"
              />
            </div>
          )} */}
          <div className="col-md-4">
            <FileUpload
              id="fileId"
              name="fileId"
              label={t("Work post sheet")}
              isMandatory={false}
              edit={false}
              fileId={
                state.generalDetails.fileId ? state.generalDetails.fileId : null
              }
              filePath={
                state.generalDetails.filePath
                  ? state.generalDetails.filePath
                  : ""
              }
              fileName={
                state.generalDetails.fileName
                  ? state.generalDetails.fileName
                  : ""
              }
              style={fileUploadStyleObject}
              multiple={false}
              uploadPath="planning-workPostSheet"
              formats=".pdf,.docx,.doc,.jpg,.jpeg"
              returnFileUploadErrors={(errorResponse: any) =>
                returnFileUploadErrors(errorResponse)
              }
              fileUploadResponse={(uploadResposne: any) =>
                fileUploadResponse(uploadResposne)
              }
              deleteResponse={(field: any) => deleteResponse(field)}
              maxFileSize={10 * 1024 * 1024}
              microserviceURL={`${process.env.REACT_APP_COMPANY_SERVICE}`}
            />
            <span className="text-danger">{state.errors?.fileId}</span>
          </div>
          {state.basicDetails?.partiarCommittee?.value === 39 && (
            <>
              <div className="col-md-4">
                <LabelWithInputField
                  isMandatory={false}
                  name="ccCardCurrentMonth"
                  handleChange={handleFieldChange}
                  value={state.generalDetails.ccCardCurrentMonth}
                  id="ccCardCurrentMonth"
                  label={t("Construction control card current month")}
                  type="text"
                />
              </div>
              <div className="col-md-4">
                <LabelWithInputField
                  isMandatory={false}
                  name="ccCardNextMonth"
                  handleChange={handleFieldChange}
                  value={state.generalDetails.ccCardNextMonth}
                  id="ccCardNextMonth"
                  label={t("Construction control card next month")}
                  type="text"
                />
              </div>
              <div className="col-sm-12 col-md-4">
                <SelectWithSearch
                  title={t("RSZ Category")}
                  isMandatory={false}
                  search={true}
                  options={state.dropDowndata.rszCategoryOptions}
                  onChange={(e) => handleSelectChange(e, "rszCategory")}
                  isMulti={false}
                  name="rszCategory"
                  value={state.generalDetails.rszCategory}
                  isTranslate={true}
                />
              </div>
            </>
          )}
          <div className="col-sm-12 col-md-4">
            <SelectWithSearch
              title={t("Work regime exemption BV")}
              isMandatory={false}
              search={true}
              options={state.dropDowndata.exemptionOptions}
              onChange={(e) => handleSelectChange(e, "workRegimeBV")}
              isMulti={false}
              name="workRegimeBV"
              value={state.generalDetails.workRegimeBV}
              isTranslate={true}
            />
          </div>
          <div className="col-sm-12 col-md-4 progressiveReturnToWork">
            <LabelField>
              {t("Progressive return to work")}
              <span
                className="ms-2 cursor-pointer contractBillingInfo"
                data-bs-toggle="tooltip"
                title={t("Progressive part time ZIMA002")}
              >
                <InfoIcon  />
              </span>
            </LabelField>
            <SelectWithSearch
              title=""
              isMandatory={false}
              search={true}
              options={state.dropDowndata.progressiveOptions}
              onChange={(e) => handleSelectChange(e, "progresWork")}
              isMulti={false}
              name="progresWork"
              value={state.generalDetails.progresWork}
              isTranslate={true}
            />
          </div>
          <div className="col-md-4">
            <LabelWithInputField
              isMandatory={false}
              name="empChar"
              handleChange={handleFieldChange}
              value={state.generalDetails.empChar}
              id="empChar"
              label={t("Employment characteristic")}
              type="text"
            />
          </div>
          <div className="col-sm-12 col-md-4">
            <SelectWithSearch
              title={t("Allocate revenue to consultant")}
              isMandatory={false}
              search={true}
              options={state.dropDowndata.consultantOptions}
              onChange={(e) => handleSelectChange(e, "revenueToConsultant")}
              isMulti={false}
              name="revenueToConsultant"
              value={state.generalDetails.revenueToConsultant}
              isTranslate={true}
            />
          </div>
          <div className="col-md-4 checkbox-section hotlistWrapper">
            <div className="ssn-left-sec">
              <CheckBoxField
                label={t("Printed UZM")}
                name="printUZM"
                onChangeHandler={handleFieldChange}
                isChecked={state.generalDetails.printUZM === 1}
                className="me-5"
                disable={true}
              />
              <CheckBoxField
                label={t("Printed customer")}
                name="printCustomer"
                onChangeHandler={handleFieldChange}
                isChecked={state.generalDetails.printCustomer === 1}
                className="me-5"
                disable={true}
              />
            </div>
          </div>
          <div className="col-md-4">
            <LabelWithInputField
              isMandatory={false}
              name="eSignStatus"
              handleChange={handleFieldChange}
              value={state.generalDetails.eSignStatus}
              id="eSignStatus"
              label={t("Status E-signing")}
              type="text"
              isDisabled={true}
            />
          </div>
          <div className="col-md-4">
            <LabelWithInputField
              isMandatory={false}
              name="eSignCancelStatus"
              handleChange={handleFieldChange}
              value={state.generalDetails.eSignCancelStatus}
              id="eSignCancelStatus"
              label={t("E-signing cancellation status")}
              type="text"
              isDisabled={true}
            />
          </div>
        </div>
      </div>
      <Navigation validStatus={validStatus} />
    </>
  );
};

export default GeneralTab;
