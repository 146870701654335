import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ApiCall } from "components/common/services/ApiServices";
import Title from "components/common/atoms/Title";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import * as ENDPOINTS from "routes/ApiEndpoints";
import {
  CENTRAL_DATA_MANAGEMENT_MICROSERVICE,
  COMPANY_MICROSERVICE,
} from "Constants";
import {
  OptionProps,
  Option,
  ValidationRules,
} from "components/common/utlis/TypeAnnotations";
import { mapToSelect } from "components/common/utlis/MapToSelect";
import {
  validateForm,
  validateSelectField,
} from "components/common/services/ValidationService";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import "./Matching.css";
import AutoCompleteDropdown from "components/common/atoms/AutoCompleteDropdown";
import LabelField from "components/common/atoms/LabelField";
import RadioField from "components/common/atoms/RadioField";
import LabelWithTextAreaField from "components/common/molecules/LabelWithTextAreaField";
import CustomNotify from "components/common/atoms/CustomNotify";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import Button from "components/common/atoms/Button";

interface formData {
  vacancyFunc: Option | null;
  company: Option | null;
  candidate: Option | null;
  officeType: number;
  office: Option | null;
  candidateInfo: string;
  contacts: OptionProps[];
}

const ManualMatching: React.FC = () => {
  const [office, setOffice] = useState<OptionProps[]>([]);
  const [vacancyFunc, setVacancyFunc] = useState<OptionProps[]>([]);

  const navigate = useNavigate();
  const [formData, setFormData] = useState<formData>({
    vacancyFunc: null,
    company: null,
    candidate: null,
    officeType: 0,
    office: null,
    candidateInfo: "",
    contacts: [],
  });
  const [loading, setLoading] = useState(false);
  const initialErrors: { [key: string]: string } = {};
  const [errors, setErrors] = useState<{ [key: string]: string }>(
    initialErrors
  );

  const fetchManualMatchingSuggestions = async () => {
    setLoading(true);
    const response = await ApiCall.getService(
      ENDPOINTS.GET_MANUAL_MATCHING_SUGGESTIONS,
      "GET",
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );

    if (response.status === 200) {
      setLoading(false);
      setOffice(mapToSelect(response.data.offices, "name"));
    }
  };
  useEffect(() => {
    fetchManualMatchingSuggestions();
  }, []);

  const fetchCompanyRelatedInfo = async (companyId: string) => {
    setLoading(true);
    const response = await ApiCall.getService(
      `${ENDPOINTS.GET_VACANCY_FOR_COMPANY}/${companyId}`,
      "GET",
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );

    if (response.status === 200) {
      setLoading(false);
      setVacancyFunc(mapToSelect(response.data.vacancyData, "function_name"));
    }
  };

  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: selectedOption,
    }));
    if (
      fieldName === "company" &&
      selectedOption &&
      selectedOption?.value !== null &&
      selectedOption?.value !== ""
    ) {
      fetchCompanyRelatedInfo(selectedOption.value);
      setFormData((prevData) => ({
        ...prevData,
        vacancyFunc: null,
      }));
    }
    validation(fieldName, selectedOption, true);
  };

  const handlePresent = async (e: React.FormEvent) => {
    const { name, value } = e.target as HTMLInputElement;
    e.preventDefault();

    if (validation(name, value)) {
      if (formData.officeType == 1 && formData) {
        const postData = {
          office: formData?.office,
          candidateId: formData.candidate?.value,
          candidateInfo: formData.candidateInfo,
        };
        const response = await ApiCall.service(
          ENDPOINTS.MANUAL_MATCHING_SAVE,
          "POST",
          postData,
          false,
          CENTRAL_DATA_MANAGEMENT_MICROSERVICE
        );

        if (response.status === 200) {
          CustomNotify({
            type: "success",
            message: t("Presented successfully to internal office"),
          });
          setTimeout(() => {
            navigate(0);
          }, 2000);
        } else {
          CustomNotify({
            type: "error",
            message: t(response.message),
          });
        }
      } else {
        const todoData = {
          candidateId: formData.candidate?.value,
          stage: "Matching",
          submitType: "skip",
        };
        const skipScreeningResponse = await ApiCall.service(
          "updateTodo",
          "POST",
          todoData,
          false,
          CENTRAL_DATA_MANAGEMENT_MICROSERVICE
        );

        if (skipScreeningResponse.status === 200) {
          const data = {
            candidateId: formData.candidate?.value,
            vacancyId: formData?.vacancyFunc?.value,
            stage: 4,
            source: 3,
          };
          const response = await ApiCall.service(
            ENDPOINTS.CREATE_TODO,
            "post",
            data,
            true,
            CENTRAL_DATA_MANAGEMENT_MICROSERVICE
          );
          if (response.status === 200) {
            navigate(
              `/todos/presentation/candidate/single/${formData?.vacancyFunc?.value}/${formData.candidate?.value}/${formData?.vacancyFunc?.label}/${formData?.company?.value}?destination=/manual-matching`
            );
          }
        }
      }
    }
  };

  const changeHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target as HTMLInputElement;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      company: null,
      office: null,
      vacancyFunc: null,
      candidateInfo: "",
    }));
    setVacancyFunc([]);
    setErrors((prev) => ({
      ...prev,
      company: "",
      office: "",
    }));
  };

  const validation = (
    name: string,
    value:
      | string
      | boolean
      | null
      | undefined
      | number
      | Option
      | OptionProps[],
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      company: formData.officeType == 0 ? [validateSelectField] : [],
      office: formData.officeType == 1 ? [validateSelectField] : [],
      candidate: [validateSelectField],
    };

    const validationErrors = validateForm(
      { ...formData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation && Object.keys(errors).length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target as HTMLInputElement;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "ManualMatching",
          read: true,
        },
      ]}
      renderNoAccess={true}
    >
      <Title title={t("Manual matching")} />
      <form action="" onSubmit={handlePresent}>
        <div className="row search-bar">
          <div className="col-4 autoCompleteFunction">
            <AutoCompleteDropdown
              label={t("Candidate")}
              placeholder={t("Type to search") + "..."}
              microService={CENTRAL_DATA_MANAGEMENT_MICROSERVICE}
              onChange={(e) => handleSelectChange(e, "candidate")}
              value={formData.candidate}
              modelKey={"manageCandidates"}
              customFetch={true}
              columnName=""
              notNullColumn="bbright_id"
              name="candidate"
              error={errors.candidate}
              isMandatory={true}
            />
          </div>
          <div className="col-6">
            <LabelField title={t("Type")} isMandatory={false} />
            <br />
            <div className="d-flex flex-column flex-xl-row">
              <RadioField
                handleChange={changeHandler}
                label={t("External")}
                name="officeType"
                value={0}
                ischecked={formData.officeType == 0}
              />
              <RadioField
                handleChange={changeHandler}
                label={t("Internal")}
                name="officeType"
                value={1}
                ischecked={formData.officeType == 1}
                className="ms-3"
              />
            </div>
          </div>
          {formData.officeType == 0 && (
            <>
              <div className="col-sm-12 col-md-6 col-lg-4 autoCompleteFunction">
                <AutoCompleteDropdown
                  label={t("Company")}
                  placeholder={t("Type to search") + "..."}
                  microService={COMPANY_MICROSERVICE}
                  onChange={(e) => handleSelectChange(e, "company")}
                  value={formData.company}
                  columnName="company_name"
                  name="company"
                  modelKey="company"
                  filter={true}
                  filterInfo={[
                    {
                      column: "company_status",
                      value: 1,
                      condition: "where",
                    },
                    {
                      column: "company_status",
                      value: 0,
                      condition: "orWhere",
                    },
                  ]}
                  isMandatory={true}
                  error={errors.company}
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <SelectWithSearch
                  name="vacancyFunc"
                  title={t("Vacancies")}
                  isMandatory={false}
                  search={true}
                  options={vacancyFunc}
                  value={formData.vacancyFunc}
                  onChange={(e) => handleSelectChange(e, "vacancyFunc")}
                  isMulti={false}
                  error={errors.vacancyFunc}
                  isTranslate={true}
                />
              </div>
            </>
          )}
          {/* <div className="col-sm-12 col-md-6 col-lg-4">
              <SelectWithSearch
                name="contacts"
                title={t("Contacts")}
                isMandatory={false}
                search={true}
                options={contacts}
                value={formData.contacts}
                onChange={(e) => handleSelectChange(e, "vacancyFunc")}
                isMulti={true}
                error={errors.contacts}
                isTranslate={true}
              />
            </div> */}
          {formData.officeType == 1 && (
            <>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <SelectWithSearch
                  name="office"
                  title={t("Office")}
                  isMandatory={true}
                  search={true}
                  options={office}
                  value={formData.office}
                  onChange={(e) => handleSelectChange(e, "office")}
                  isMulti={false}
                  error={errors.office}
                  isTranslate={true}
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <LabelWithTextAreaField
                  name="candidateInfo"
                  handleChange={(event) => handleTextFieldChange(event)}
                  label={t("Candidate info")}
                  value={formData.candidateInfo}
                />
              </div>
            </>
          )}
          <div className="col-12 mt-35">
            <Button
              title={t("Present")}
              className="form-button button-width float-end"
              type="submit"
            />
        
          </div>
        </div>
      </form>
      <div className="row backPadding">
        <div className="col-md-6 align-self-center">
          <Link to="/dashboard" className="back-btn text-decoration-underline">
            {t("Back")}
          </Link>
        </div>
      </div>
    </AccessControl>
  );
};

export default translate(ManualMatching);
