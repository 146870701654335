import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import Button from "components/common/atoms/Button";
import Title from "components/common/atoms/Title";
import LabelWithCKEditorField from "components/common/molecules/LabelWithCKEditorField";
import React, { useEffect, useState } from "react";
import "./css/todos.css";
import {
  OptionProps,
  Option,
  ValidationRules,
} from "components/common/utlis/TypeAnnotations";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { ApiCall } from "components/common/services/ApiServices";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "Constants";
import { mapToSelect } from "components/common/utlis/MapToSelect";
import LabelField from "components/common/atoms/LabelField";
import PhoneInput from "react-phone-number-input";
import {
  validateForm,
  validateRequired,
} from "components/common/services/ValidationService";
import { GET_PRE_INTERVIEW_DETAILS } from "routes/ApiEndpoints";
import { E164Number } from "libphonenumber-js";

interface PreInterview {
  name: string;
  alternateName: string;
  email: string;
  alternateEmail: string;
  phone: E164Number | string | undefined;
  alternatePhone: E164Number | string | undefined;
  meetingLink: string;
  street: string;
  number: string;
  box: string;
  zipCode: string;
  city: Option | null;
  country: Option | null;
  addInfo: string;
  timeSlot: string;
}

const PreInterviewPage: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const userAuth = useSelector(selectAuth);
  const location = useLocation();
  const navigate = useNavigate();

  //get ids from url
  const {
    todoExtensionId,
    companyId,
    candidateId,
    todoId,
    functionName,
    vacancyId,
    presentationId,
  } = useParams<{
    todoExtensionId: string;
    companyId: string;
    candidateId: string;
    todoId: string;
    functionName: string;
    vacancyId: string;
    presentationId: string;
  }>();

  const [formData, setFormData] = useState<PreInterview>({
    name: "",
    alternateName: "",
    email: "",
    alternateEmail: "",
    phone: "" as E164Number,
    alternatePhone: "" as E164Number,
    meetingLink: "",
    street: "",
    number: "",
    box: "",
    zipCode: "",
    city: null,
    country: null,
    addInfo: "",
    timeSlot: "",
  });

  const [dropDown, setDropDown] = useState({
    cityOptions: [] as OptionProps[],
    countryOptions: [] as OptionProps[],
  });

  useEffect(() => {
    const fetchCanPreInterviewDetails = async () => {
      const data = {
        todoExtensionId: todoExtensionId,
        companyId: companyId,
        candidateId: candidateId,
        vacancyId:
          vacancyId !== "undefined" && vacancyId !== "null" && vacancyId !== ""
            ? vacancyId
            : null,
        presentationId: presentationId,
      };
      const response = await ApiCall.service(
        GET_PRE_INTERVIEW_DETAILS,
        "POST",
        data,
        true,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.status === 200) {
        setDropDown((prevData) => ({
          ...prevData,
          cityOptions: mapToSelect(response.data.cityOptions),
          countryOptions: mapToSelect(response.data.countryOptions),
        }));
        const locationDetails = response.data.locationDetails;
        setFormData((prev) => ({
          ...prev,
          street: locationDetails.street,
          number: locationDetails.number,
          box: locationDetails.box,
          zipCode: locationDetails.zipcode,
          city: locationDetails.city,
          country: locationDetails.country,
          timeSlot: response.data.timeSlot,
        }));
      }
    };
    fetchCanPreInterviewDetails();
  }, []);

  const handleFieldChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;

    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePhoneNumberChange = (e: string | E164Number | undefined) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      phone: e || "",
    }));
  };
  const handleAlternatePhoneNumberChange = (
    e: string | E164Number | undefined
  ) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      alternatePhone: e || "",
    }));
  };

  const handleCKEditorChange = (event: any, editor: any, fieldName: string) => {
    const content = editor.getData(); // Get the content from the editor
    const name = fieldName; // Use the fieldName parameter
    setFormData((prevData) => ({ ...prevData, [name]: content }));
  };

  const handleSelectChange = (selectedOption: any, name: string) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: selectedOption !== null ? selectedOption : "",
    }));
  };

  const handleSubmit = async (event: React.FormEvent, type: string) => {
    event.preventDefault();
    const data = {
      submitType: type,
      formData: formData,
      todoExtensionId: todoExtensionId,
      companyId: companyId,
      candidateId: candidateId,
      userId: userAuth.userId,
      todoId: todoId,
      entityType: "company",
      stage: "Pre-interview",
      presentationId: presentationId,
      functionName: functionName,
    };
    const response = await ApiCall.service(
      "storePreInterviewDetails",
      "POST",
      data,
      true,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );
    if (response.status === 200) {
      navigate(
        location.state?.origin === "todos" ? "/todos/overview" : "/dashboard"
      );
    }
  };

  return (
    <>
      <div>
        <Title title={t("Interviewer details")} />
        <form>
          <div className="form-border mb-4">
            <div className="row">
              {formData.timeSlot !== null ? (
                <span className="tab-subtitle color-dark-pink">
                  {t("The selected time slot is")}: {formData.timeSlot}
                </span>
              ) : (
                <span className="tab-subtitle color-dark-pink">
                  {t("No time slot selected")}
                </span>
              )}
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <LabelWithInputField
                  name="name"
                  handleChange={handleFieldChange}
                  value={formData.name}
                  id="name"
                  label={t("Name")}
                  placeholder={t("Name")}
                  isMandatory={false}
                />
              </div>
              {/* <div className="col-sm-12 col-md-6">
                                            <LabelWithInputField
                                                name="alternateName"
                                                handleChange={
                                                    handleFieldChange
                                                }
                                                value={
                                                    formData.alternateName
                                                }
                                                id="alternateName"
                                                label={t("Alternate name")}
                                                placeholder={t(
                                                    "Alternate name"
                                                )}
                                            />
                                        </div> */}
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <LabelWithInputField
                  name="email"
                  handleChange={handleFieldChange}
                  value={formData.email}
                  id="email"
                  label={t("Email")}
                  placeholder={t("Email")}
                  isMandatory={false}
                />
              </div>
              <div className="col-sm-12 col-md-6">
                <LabelWithInputField
                  name="alternateEmail"
                  handleChange={handleFieldChange}
                  value={formData.alternateEmail}
                  id="alternateEmail"
                  label={t("Alternate email")}
                  placeholder={t("Alternate email")}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-6 mb-3">
                <LabelField title={t("Phone number")} key="PhoneInput" />
                <div
                  className="form-control field-shadow d-flex"
                  style={{ borderRadius: "0.5vw" }}
                >
                  <PhoneInput
                    defaultCountry="BE" //Belgium country code
                    placeholder={t("Enter phone number")}
                    value={
                      formData.phone !== undefined
                        ? (formData.phone as E164Number)
                        : ("" as E164Number)
                    }
                    onChange={handlePhoneNumberChange}
                    className="w-100"
                    international
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-6 mb-3">
                <LabelField
                  title={t("Alternate phone number")}
                  isMandatory={false}
                  key="PhoneInput"
                />
                <div
                  className="form-control field-shadow d-flex"
                  style={{ borderRadius: "0.5vw" }}
                >
                  <PhoneInput
                    defaultCountry="BE" //Belgium country code
                    placeholder={t("Enter phone number")}
                    value={
                      formData.alternatePhone !== undefined
                        ? (formData.alternatePhone as E164Number)
                        : ("" as E164Number)
                    }
                    onChange={handleAlternatePhoneNumberChange}
                    className="w-100"
                    international
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <LabelWithInputField
                  isMandatory={false}
                  name="meetingLink"
                  handleChange={handleFieldChange}
                  value={formData.meetingLink}
                  id="meetingLink"
                  label={t("Meeting link")}
                  placeholder={t("Meeting link")}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-4">
                <LabelWithInputField
                  isMandatory={false}
                  name="street"
                  handleChange={handleFieldChange}
                  value={formData.street}
                  id="street"
                  label={t("Street")}
                  placeholder={t("Street")}
                />
              </div>
              <div className="col-sm-12 col-md-4">
                <LabelWithInputField
                  isMandatory={false}
                  name="number"
                  handleChange={handleFieldChange}
                  value={formData.number}
                  id="number"
                  label={t("Number")}
                  placeholder={t("Number")}
                />
              </div>
              <div className="col-sm-12 col-md-4">
                <LabelWithInputField
                  isMandatory={false}
                  name="box"
                  handleChange={handleFieldChange}
                  value={formData.box}
                  id="box"
                  label={t("Box")}
                  placeholder={t("Box")}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-4">
                <LabelWithInputField
                  isMandatory={false}
                  name="zipCode"
                  handleChange={handleFieldChange}
                  value={formData.zipCode}
                  id="zipCode"
                  label={t("Zip code")}
                  placeholder={t("Zip code")}
                />
              </div>
              <div className="col-sm-12 col-md-4">
                <SelectWithSearch
                  title={t("City")}
                  name="city"
                  placeHolder={t("Select city")}
                  isMandatory={false}
                  search={true}
                  options={dropDown.cityOptions}
                  value={formData.city}
                  onChange={(e) => handleSelectChange(e, "city")}
                  isMulti={false}
                  className="select-field"
                />
              </div>
              <div className="col-sm-12 col-md-4">
                <SelectWithSearch
                  title={t("Country")}
                  name="country"
                  placeHolder={t("Select country")}
                  isMandatory={false}
                  search={true}
                  options={dropDown.countryOptions}
                  value={formData.country}
                  onChange={(e) => handleSelectChange(e, "country")}
                  isMulti={false}
                  className="select-field"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <LabelWithCKEditorField
                  label={t("Additional Information")}
                  name="addInfo"
                  value={""}
                  placeholder={t("Type here")}
                  handleChange={(event, editor) =>
                    handleCKEditorChange(event, editor, "addInfo")
                  }
                  className="field-shadow"
                />
              </div>
            </div>
          </div>
            <div className="row backPadding">
              <div className="col-md-6 align-self-center">
                <Link
                  to={
                    location.state?.origin === "todos"
                      ? "/todos/overview"
                      : "/dashboard"
                  }
                  className="back-btn text-decoration-underline"
                >
                  {t("Back")}
                </Link>
              </div>
              <div className="col-md-6 ">
                <Button
                  title={t("Skip")}
                  type="submit"
                  handleClick={(e) => handleSubmit(e, "skip")}
                  className="float-end form-button  ms-3 shadow-none"
                />
                <Button
                  title={t("Save")}
                  type="submit"
                  handleClick={(e) => handleSubmit(e, "accept")}
                  className="float-end form-button shadow-none"
                />
              </div>
            </div>
        </form>
      </div>
    </>
  );
};

export default translate(PreInterviewPage);
