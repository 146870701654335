import React from 'react'

const ManualMatchingIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 293 258" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M67.3298 39.2486L76.4454 48.705L111.895 44.3146C131.38 41.9022 148.357 39.9241 149.563 39.9241C152.119 39.9241 169.482 43.3496 172.665 44.5075C173.871 44.9418 175.655 45.8102 176.716 46.4857C179.224 48.1743 204.787 68.1485 206.812 70.0301C207.68 70.8503 209.224 73.1179 210.188 75.096C211.973 78.7146 211.973 78.7146 212.166 85.9033L212.311 93.0921L210.237 91.9824C202.616 88.1227 194.61 87.0613 182.311 88.2674C181.25 88.4122 181.105 88.2192 180.961 86.7235C180.816 85.0349 180.72 84.9866 165.045 76.1092C156.411 71.188 149.08 67.1353 148.743 67.0388C148.164 66.8941 132.586 80.2585 132.682 80.8374C132.682 81.0304 135.672 82.6225 139.29 84.4077C142.907 86.1928 147.971 88.8946 150.479 90.3903C157.665 94.6843 182.89 111.281 184.385 112.68C185.157 113.404 186.363 115.044 187.038 116.395C188.726 119.58 188.822 124.887 187.327 128.264C184.964 133.33 180.43 136.177 174.594 136.128C171.17 136.128 170.543 135.935 163.501 132.848C154.289 128.843 142.232 124.742 135.528 123.391C131.09 122.474 129.547 122.426 122.264 122.716C110.207 123.15 101.477 121.944 89.2264 117.987C81.2202 115.43 75.3843 112.536 70.6577 108.869C68.5356 107.228 60.4811 101.68 52.8124 96.5659L38.8256 87.2542L29.903 73.3592C20.8356 59.1746 19.871 57.1482 21.3179 55.2666C21.6556 54.8324 29.276 48.898 38.2951 42.1434C52.9089 31.0949 54.8381 29.7922 56.4297 29.7922C58.166 29.7922 58.6965 30.2747 67.3298 39.2486Z" fill="currentColor"/>
<path d="M198.468 96.566C210.912 99.4608 220.413 108.049 224.609 120.159C226.297 124.935 226.683 133.282 225.478 138.541C223.307 148.383 216.41 157.261 207.343 161.989C197.214 167.248 185.253 167.248 174.932 161.892C169.916 159.287 163.55 153.16 160.897 148.383C159.064 145.102 156.99 139.843 157.376 139.457C157.521 139.361 160.27 140.422 163.55 141.822C169.434 144.282 169.627 144.33 174.353 144.379C180.044 144.379 182.794 143.607 187.183 140.615C196.202 134.633 199.24 122.475 194.272 112.632C192.247 108.579 189.16 105.54 183.421 101.825C180.671 100.088 178.598 98.4476 178.79 98.2546C180.72 96.4212 193.211 95.3598 198.468 96.566Z" fill="currentColor"/>
<path d="M191.716 180.926C170.589 180.926 128.414 191.533 128.414 212.588V228.419H255.019V212.588C255.019 191.533 212.843 180.926 191.716 180.926Z" fill="currentColor"/>
</svg>

  )
}

export default ManualMatchingIcon