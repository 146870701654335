import React, { useState, useEffect } from "react";
import {
  validateForm,
  validateRequired,
  validateSelectField,
  validateTokens,
} from "components/common/services/ValidationService";
import Button from "components/common/atoms/Button";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import LabelWithSelectField from "components/common/molecules/LabelwithSelectField";
import LabelWithCKEditorField from "components/common/molecules/LabelWithCKEditorField";
import LabelWithTextAreaField from "components/common/molecules/LabelWithTextAreaField";
import { ApiCall } from "components/common/services/ApiServices";
import { useParams, useLocation, Link, useNavigate } from "react-router-dom";
import CustomNotify from "components/common/atoms/CustomNotify";
import {
  UPDATE_TEMPLATE,
  GET_TEMPLATE,
  GET_APP_LANG,
} from "routes/ApiEndpoints";
import { t, translate } from "../translation/Translation";
import Title from "components/common/atoms/Title";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";

interface TemplateProps {
  //Properties/ fields and there respective data type in email template form
  module?: string;
  name?: string;
  subject?: string;
  body?: string;
  signature?: string;
  bodyToken?: string;
  signatureToken?: string;
  lang?: string;
}

interface OptionProps {
  ///fields related to module dropdown and their datatype
  value?: number;
  name?: string;
}

interface ValidationRules {
  [key: string]: Function[];
}

interface Lang {
  key: string;
  name: string;
}

const EditEmailTemplate: React.FC = () => {
  //component
  const location = useLocation();
  const isViewMode = location.pathname.includes("view"); //to kno whether it is view mode based location
  const queryParams = new URLSearchParams(location.search);
  const langParam = queryParams.get("lang"); //To get value of lang parameter in url ?lang=..
  const [formData, setFormData] = useState<TemplateProps>({
    //form data with intial values
    module: "",
    name: "",
    subject: "",
    body: "",
    signature: "",
    bodyToken: "",
    signatureToken: "",
    lang: langParam ?? "",
  });
  const [langs, setLangs] = useState<Lang[]>([]); // Annotate the type of langs as an array of Lang objects
  const [errors, setErrors] = useState<TemplateProps>({}); //maintaining errors object
  const navigate = useNavigate();
  const [optionList, setOptionList] = useState<OptionProps[]>([]); //for module dropdown
  const [combinedContent, setCombinedContent] = useState<string>(""); //for view mode content
  const [contentChanged, setContentChanged] = useState(false);
  const [selectedLang, setSelectedLang] = useState(
    formData.lang || "en-source"
  ); // Initialize with default language or formData's language

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    //functionality for edit flow and view
    if (id) {
      const fetchFormData = async () => {
        const editData = { id: id, language_key: langParam };

        const langResponse = await ApiCall.getService(
          `${GET_APP_LANG}/absolute_jobs`,
          "GET",
          "central-data-management"
        );
        const keysArray = langResponse.app_langs.map((lang: any) => ({
          key: lang.key,
          name: lang.name,
        }));
        setLangs(keysArray);

        const response = await ApiCall.service(
          GET_TEMPLATE,
          "POST",
          editData,
          false,
          "central-data-management"
        );
        if (response.status === 200) {
          const moduleData = response.data["module"];
          // Convert the data array into the optionList format
          const updatedOptionList = moduleData.map((item: any) => ({
            value: item.id,
            name: item.name,
          }));
          // Update the state with the new optionList
          setOptionList(updatedOptionList);
          const templateData = response.data["templateData"][0];
          const {
            module_id,
            name,
            subject,
            body,
            signature,
            body_token,
            signature_token,
          } = templateData;

          setFormData({
            name,
            module: module_id,
            subject,
            body,
            signature,
            bodyToken: body_token,
            signatureToken: signature_token,
            lang: langParam ?? "",
          });
        }
      };

      fetchFormData();
    }
  }, [id, langParam]);

  useEffect(() => {
    const { subject, body, signature } = formData;
    const combined = `<b>Subject</b> : ${subject} \n${body}\n${signature}`;
    setCombinedContent(combined);
  }, [formData]);

  const bodyTokens = (formData.bodyToken?.match(/\[\w+\]/g) ?? []) as string[]; //validation of body content for body tokens
  const signatureTokens = (formData.signatureToken?.match(/\[\w+\]/g) ??
    []) as string[]; //validation of signature content for signature tokens

  const validation = (
    name: string,
    value: string | boolean,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      module: [validateSelectField],
      name: [validateRequired],
      subject: [validateRequired],
      body: [validateRequired, validateTokens(bodyTokens)],
      signature: [validateRequired, validateTokens(signatureTokens)],
    };

    const validationErrors = validateForm(
      { ...formData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation && Object.keys(errors).length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }

    return true;
  };

  const changeHandler = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = event.target as HTMLInputElement;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    validation(name, value, true);
  };

  const handleCKEditorChange = (event: any, editor: any, fieldName: string) => {
    const content = editor.getData(); // Get the content from the editor
    const name = fieldName; // Use the fieldName parameter
    setFormData((prevData) => ({ ...prevData, [name]: content }));
    setContentChanged(true);
    if (contentChanged) {
      validation(name, content, true);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    //api call for updating data
    e.preventDefault();
    const { name, value } = e.target as HTMLInputElement;
    const data = { id: id, ...formData };
    if (validation(name, value)) {
      const response = await ApiCall.service(
        UPDATE_TEMPLATE,
        "POST",
        data,
        false,
        "central-data-management"
      );
      if (response.status === 200) {
        navigate("/manage-email-templates");
        CustomNotify({ type: "success", message: t(response.msg) });
      }
    }
  };

  const languageLinks = langs.map((lang) => (
    <Link
      key={lang.key}
      to={{
        pathname: `/email-template/${isViewMode ? "view" : "edit"}/${id}`,
        search: `?lang=${lang.key}`,
      }}
      target="_blank"
      className={`dropdown-item ${selectedLang === lang.key ? "active" : ""}`}
      onClick={() => setSelectedLang(lang.key)}
    >
      {lang.name.toUpperCase()}
    </Link>
  ));

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Email templates",
          update: true,
        },
      ]}
      renderNoAccess={true}
    >
      {isViewMode ? (
        <Title title={t("View email template")} />
      ) : (
        <Title title={t("Edit email template")} />
      )}
      <div className="row marginBotttom1 emailTemplateLanguage">
        <div className="col-md-12">
          <div className="float-end">
            <div className="dropdown">
              <span className="marginRight1">
                {t("Select language for email template:")}
              </span>
              <button
                className="btn dropdown-toggle rounded-0 shadow-none"
                type="button"
                id="languageDropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {selectedLang.toUpperCase()}
              </button>
              <div className="dropdown-menu" aria-labelledby="languageDropdown">
                {languageLinks}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*displaying common data for both view and edit mode */}
      <div>
        {isViewMode ? ( //displaying data when its view mode
          <div className="viewEmailTemplate">
            <div className="row">
              <div className="col-12">
                <div className="form-border">
                  <div className="email-preview">
                    <LabelWithCKEditorField
                      label={t("Preview email")}
                      name=""
                      value={combinedContent}
                      isDisabled={true}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row backPadding">
              <div className="col-md-4">
                <Link
                  to="/manage-email-templates"
                  className=" back-btn text-decoration-underline"
                >
                  {t("Back")}
                </Link>
              </div>
            </div>
          </div>
        ) : (
          //displaying data when its edit mode
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12">
                <div className="form-border">
                  <div className="row">
                    <div className="col-lg-6">
                      {/* Select module field */}
                      <LabelWithSelectField
                        label={t("Select module")}
                        options={optionList}
                        placeholder={t("Select module")}
                        disabled={true}
                        selectedValue={formData.module}
                        handleChange={changeHandler}
                        isMultiSelect={false}
                        isMandatory={true}
                        name="module"
                        className=""
                        error={errors.module}
                      />
                    </div>
                    <div className="col-lg-6">
                      {/* Name field */}
                      <LabelWithInputField
                        isMandatory={true}
                        name="name"
                        handleChange={changeHandler}
                        isDisabled={true}
                        value={formData.name}
                        id="name"
                        label={t("Name")}
                        placeholder={t("Name")}
                        type="text"
                        error={errors.name}
                      />
                    </div>
                    <div className="col-lg-6">
                      {/* Subject field */}
                      <LabelWithInputField
                        isMandatory={true}
                        name="subject"
                        handleChange={changeHandler}
                        value={formData.subject}
                        id="subject"
                        label={t("Subject")}
                        placeholder={t("Subject")}
                        type="text"
                        error={errors.subject}
                      />
                    </div>
                    <div className="col-lg-9">
                      {/* Body field */}
                      <LabelWithCKEditorField
                        label={t("Body")}
                        name="body"
                        value={formData.body}
                        placeholder={t("Type here") + "..."}
                        handleChange={(event, editor) =>
                          handleCKEditorChange(event, editor, "body")
                        }
                        isMandatory={true}
                        error={errors.body}
                      />
                    </div>
                    <div className="col-lg-3 body-tokens">
                      <LabelWithTextAreaField
                        label={t("Body tokens")}
                        isMandatory={true}
                        name="bodyToken"
                        isDisabled={true}
                        value={
                          formData.bodyToken &&
                          Object.values(
                            JSON.parse(formData.bodyToken) as Record<
                              string,
                              string
                            >
                          ).join("\n")
                        }
                        handleChange={changeHandler}
                        rows={4}
                      />
                    </div>
                    <div className="col-lg-9">
                      {/* Signature field */}
                      <LabelWithCKEditorField
                        label={t("Signature")}
                        name="signature"
                        value={formData.signature}
                        placeholder={t("Type here") + "..."}
                        handleChange={(event, editor) =>
                          handleCKEditorChange(event, editor, "signature")
                        }
                        isMandatory={true}
                        error={errors.signature}
                      />
                    </div>
                    {/* Signature Token field */}
                    <div className="col-lg-3 signature-tokens">
                      <LabelWithTextAreaField
                        label={t("Signature tokens")}
                        isMandatory={true}
                        name="signatureToken"
                        isDisabled={true}
                        value={
                          formData.signatureToken &&
                          Object.values(
                            JSON.parse(formData.signatureToken) as Record<
                              string,
                              string
                            >
                          ).join("\n")
                        }
                        handleChange={changeHandler}
                        rows={4}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row backPadding">
              <div className="col-md-4 align-self-center">
                <Link
                  to="/manage-email-templates"
                  className="back-btn text-decoration-underline"
                >
                  {t("Back")}
                </Link>
              </div>
              <div className="col-md-8">
                <Button
                  title={t("Save email template")}
                  type="submit"
                  className="form-button float-end shadow-none"
                />
              </div>
            </div>
          </form>
        )}
      </div>
    </AccessControl>
  );
};

export default translate(EditEmailTemplate);
