import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "Constants";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import Button from "components/common/atoms/Button";
import CustomNotify from "components/common/atoms/CustomNotify";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import { ApiCall } from "components/common/services/ApiServices";
import LoadingIcon from "components/common/utlis/LoadingIcon";
import { useEffect, useState } from "react";
import { Option } from "react-multi-select-component";
import { useLocation, useParams } from "react-router-dom";
import { CONF_SETTINGS_GET, CONF_SETTINGS_UPDATE } from "routes/ApiEndpoints";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import LabelField from "components/common/atoms/LabelField";

interface PcAge {
  id?: number | null;
  age: number;
  minSalary: string | null;
}

const PcAge: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [formData, setFormData] = useState<PcAge[]>([]);
  const [salaryData, setSalaryData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState<Option | null>();
  const initialErrors: { [key: string]: string } = {};
  const [errors, setErrors] = useState<{ [key: string]: string }>(
    initialErrors
  );
  const location = useLocation();
  const path = location.pathname;
  const edit = path.includes("edit") || false;

  const options = [
    { value: 21, label: "21" + t(" Years") },
    { value: 20, label: "20" + t(" Years") },
    { value: 19, label: "19" + t(" Years") },
    { value: 18, label: "18" + t(" Years") },
  ];

  useEffect(() => {
    fetchPcAge();
  }, []);

  const fetchPcAge = async () => {
    const data = {
      pcId: id,
      type: "pcAge",
    };
    const url = CONF_SETTINGS_GET;
    const response: any = await ApiCall.service(
      url,
      "POST",
      data,
      true,
      "central-data-management"
    );

    if (response.status === 200) {
      setSalaryData(response.data);
    }
  };

  useEffect(() => {
    const maxKeyValue: number | undefined = Math.max(
      ...(salaryData?.map((obj: PcAge) => obj.age) ?? [])
    );
    if (maxKeyValue !== undefined) {
      handleSelectChange(
        { value: maxKeyValue, label: `${maxKeyValue} Years` },
        true
      );
    }
  }, [salaryData]);

  const handleSelectChange = (selected: any, load?: boolean) => {
    if (selected.value === -Infinity) {
      return;
    }
    setSelected(selected);
    const newFormData = [];
    for (let i = selected.value; i >= 15; i--) {
      let sal = i === selected.value ? "100" : null;
      let data: PcAge = { id: null, age: i, minSalary: sal };
      if (salaryData) {
        const foundRecord = salaryData.find((record) => record.age == i);
        if (foundRecord) {
          data.id = foundRecord.id;
          if (load) {
            data.minSalary =
              i === selected.value ? "100" : foundRecord.min_salary_perc;
          }
        }
      }
      newFormData.push(data);
    }
    setFormData(newFormData);
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { name, value, type } = event.target;
    const updatedFormData = [...(formData as PcAge[])];

    updatedFormData[index] = {
      ...updatedFormData[index],
      [name]: value,
    };
    setFormData(updatedFormData);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    let hasErrors = false;
    const newErrors: { [key: string]: string } = {};

    formData.forEach((pcAge, index) => {
      if (!pcAge.minSalary || pcAge.minSalary.trim() === "") {
        newErrors[`minSalary_${index}`] = t("Minimum salary is required");
        hasErrors = true;
      }
    });

    if (hasErrors) {
      setErrors(newErrors);
      setLoading(false);
      return;
    }
    setErrors(newErrors);

    const deletedIds = salaryData.reduce((deletedIds, itemA) => {
      const matchingItemB = formData.find((itemB) => itemA.id === itemB.id);
      if (!matchingItemB) {
        deletedIds.push(itemA.id);
      }
      return deletedIds;
    }, []);

    const data = {
      type: "pcAge",
      pcId: id,
      case: "salary",
      agesToDelete: deletedIds,
      data: formData,
    };

    let response = await ApiCall.service(
      CONF_SETTINGS_UPDATE,
      "POST",
      data,
      false,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );
    if (response.status === 200) {
      CustomNotify({ type: "success", message: t(response.msg) });
    }
  };

  return (
    <>
      <AccessControl
        requiredPermissions={[
          {
            permission: "Paritair committe",
            read: true,
          },
        ]}
        renderNoAccess={true}
      >
        <div className="col-md-12 col-lg-5 col-xxl-4 col-xl-4 my-4">
          <div className="form-border padding1">
            <div>
              <LabelField
                className="tab-subtitle"
                title={edit ? t("Edit age") : t("View age")}
              />
            </div>
            <SelectWithSearch
              search={true}
              onChange={(e) => {
                handleSelectChange(e, false);
              }}
              title={t("At which age full salary is paid?")}
              options={options}
              placeHolder={t("Select age")}
              isMulti={false}
              name={""}
              isMandatory={true}
              isDisabled={!edit}
              value={selected}
            />
            {formData && formData.length > 0 && (
              <div>
                {formData.map((pcAge, index) =>
                  index === 0 ? (
                    <div className="d-none">
                      <LabelWithInputField
                        key={index}
                        type="number"
                        name="minSalary"
                        handleChange={() => {}}
                        isDisabled={true}
                        value={
                          formData[index].minSalary == null
                            ? "100"
                            : formData[index].minSalary
                        }
                        error={errors[`minSalary_${index}`]}
                      />
                    </div>
                  ) : (
                    <LabelWithInputField
                      key={index}
                      type="number"
                      name="minSalary"
                      isMandatory={true}
                      label={`${t("Minimum salary for")} ${pcAge.age} ${t(
                        "years"
                      )}?`}
                      handleChange={(e) => handleChange(e, index)}
                      value={
                        formData[index].minSalary == null
                          ? ""
                          : formData[index].minSalary
                      }
                      error={errors[`minSalary_${index}`]}
                      isDisabled={!edit}
                    />
                  )
                )}
                {edit && (
                  <div className="col-md-12 text-end">
                    {!loading ? (
                      <Button
                        title={t("Save")}
                        type="submit"
                        className=" form-button shadow-none"
                        handleClick={handleSubmit}
                      />
                    ) : (
                      <LoadingIcon
                        iconType="bars"
                        color="#e55496"
                        height={"3vw"}
                        width={"3vw"}
                        className="ms-auto"
                      />
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </AccessControl>
    </>
  );
};
export default translate(PcAge);
