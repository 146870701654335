import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "Constants";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import Button from "components/common/atoms/Button";
import CustomNotify from "components/common/atoms/CustomNotify";
import InputTextfield from "components/common/atoms/InputTextField";
import RadioField from "components/common/atoms/RadioField";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import Title from "components/common/atoms/Title";
import { ApiCall } from "components/common/services/ApiServices";
import LoadingIcon from "components/common/utlis/LoadingIcon";
import { useEffect, useState } from "react";
import { Option } from "react-multi-select-component";
import { Link, useNavigate } from "react-router-dom";
import * as ENDPOINTS from "routes/ApiEndpoints";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import CheckBoxField from "components/common/atoms/CheckBoxField";

interface WageProps {
  id?: number | null;
  wageId?: number | null;
  name: string;
  inclusiveOption: boolean | null;
  priceOrCoeff: Option | null;
  min: string;
  nicetohave: string;
  max: string;
  value: Option | null;
  wageCodes: Option | null;
  fixed: number | null;
}

const ManageWageElements: React.FC = () => {
  const [coefficients, setCoefficients] = useState([]);
  const [formData, setFormData] = useState<WageProps[]>([]);
  const [wageCodes, setWageCodes] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const priceOptions = [
    { value: 0, label: t("Amount") },
    { value: 1, label: t("Coefficient") },
    { value: 2, label: t("Coefficient deviations") },
  ];

  const wageTypes = [
    { value: 1, label: "Min", name: "min" },
    { value: 2, label: "Nice to have", name: "nicetohave" },
    { value: 3, label: "Max", name: "max" },
  ];

  useEffect(() => {
    fetchWageElements();
  }, []);

  //API call for to fetch WageElements
  const fetchWageElements = async () => {
    const requestData = {
      type: "wageElements",
    };

    const response = await ApiCall.service(
      ENDPOINTS.CONF_SETTINGS_GET,
      "POST",
      requestData,
      false,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );

    if (response.status === 200) {
      const data = response.data["wage_elements"];

      const fieldData = data.map((item: any) => {
        let priceObj = priceOptions.filter(
          (price: any) => price.value === item.type
        )[0];

        return {
          id: item.id,
          wageId: item.wageId,
          name: item.name,
          inclusiveOption: item.inclusiveOption,
          priceOrCoeff: priceObj,
          min: item.min,
          nicetohave: item.nice_to_have,
          value: item.value,
          wageCodes: item.wageCodes,
          max: item.max,
        };
      });

      setFormData(fieldData);
      setCoefficients(response.data["coefficients"]);
      setWageCodes(response.data["wage_codes"]);
    }
  };

  const changeHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const { name, value, type, checked } = e.target as HTMLInputElement;
    const newFields: WageProps[] = [...formData];
    let updatedValue: string | number | null | Option;
    let updatedName: string;

    if (type === "radio" && name.startsWith("inclusiveOption")) {
      updatedName = "inclusiveOption";
      updatedValue = parseInt(value);
      newFields[index] = {
        ...newFields[index],
        [updatedName]: updatedValue === 1 ? true : false,
      };
      setFormData(newFields);
    } else if (name === "min" || name === "nicetohave" || name === "max") {
      const regex = /^[\d,.]*$/;
      let matches = value.match(regex);
      if (matches || value === "") {
        updatedName = name;
        updatedValue = value;
        newFields[index] = {
          ...newFields[index],
          [updatedName]: updatedValue,
        };
        setFormData(newFields);
      }
    }
  };

  const wageCodesList = () => {
    const selectedCodes: WageProps[] = formData
      .map((item: any) => item.wageCodes)
      .filter((list: any) => list !== undefined && list !== null)
      .flatMap((list) => list);

    const filteredWageCodes = wageCodes.filter(
      (obj1: WageProps) =>
        !selectedCodes.some((obj2: WageProps) => obj1.value === obj2.value)
    );
    return filteredWageCodes;
  };

  const handleSelectChange = (
    selectedOption: any,
    fieldName: string,
    index: number
  ) => {
    const newFormData: WageProps[] = [...formData];

    if (
      (fieldName === "priceOrCoeff" && selectedOption.value === null) ||
      selectedOption.value === 0 ||
      selectedOption.value === 1 ||
      selectedOption.value === 2
    ) {
      newFormData[index] = {
        ...newFormData[index],
        value: { value: "", label: t("Select") },
        min: "",
        nicetohave: "",
        max: "",
      };
    }
    newFormData[index] = {
      ...newFormData[index],
      [fieldName]: selectedOption,
    };

    setFormData(newFormData);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    setLoading(true);
    e.preventDefault();
    const requestData = {
      data: formData,
      wageTypes: wageTypes,
      type: "wageElements",
    };

    const response = await ApiCall.service(
      ENDPOINTS.CONF_SETTINGS_UPDATE,
      "POST",
      requestData,
      false,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );

    if (response.status === 200) {
      navigate(-1);
      CustomNotify({ type: "success", message: response.msg });
    }
    setLoading(false);
  };

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Wage elements",
          create: true,
          update: true,
          read: true,
        },
      ]}
      actions={true}
      strict={false}
      renderNoAccess={true}
    >
      <Title title={t("Manage wage elements")} />
      <form className="form-height-dashboard" onSubmit={handleSubmit}>
        {/* {error && (
                  <div className="text-danger error-coefficients">
                      <span>
                          {t(
                              "Please change the highlighted values, minimum value should be less than maximum value."
                          )}
                      </span>
                      <br />
                      <span>
                          {t(
                              "The desired value should be in between minimum and maximum values."
                          )}
                      </span>
                  </div>
              )} */}
        <div className="row">
          <div className="col-12">
            <div className="table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr className="TableHeader">
                    <th className="text-break" style={{ width: "15%" }}>
                      {t("Title")}
                    </th>
                    <th className="text-break" style={{ width: "10%" }}>
                      {t("Inclusive")}
                    </th>
                    <th className="text-break" style={{ width: "10%" }}>
                      {t("Price / Coeff")}
                    </th>
                    <th className="text-break" style={{ width: "10%" }}>
                      {t("Value")}
                    </th>
                    {wageTypes.map((wagetype, index) => (
                      <th
                        className="text-break"
                        key={index}
                        style={{ width: "10%" }}
                      >
                        {t(wagetype.label)}
                      </th>
                    ))}
                    <th className="text-break" style={{ width: "30%" }}>
                      {t("Wage code")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {formData.map((details, index) => (
                    <tr
                      key={index}
                      className="align-middle border mb-3 box-shadow align-middle p-3 p-lg-0"
                    >
                      <td className="text-break py-3" data-label="Title">
                        {t(`${details.name}`)}
                      </td>
                      <td
                        className="text-break py-3"
                        data-label="Inclu / Exclue"
                      >
                        <RadioField
                          name={`inclusiveOption-${index}`}
                          id={`inclusiveOption-yes-${index}`}
                          value={1}
                          ischecked={formData[index].inclusiveOption === true}
                          handleChange={(event) => {
                            changeHandler(event, index);
                          }}
                          label={t("Yes")}
                          className="me-2"
                        />
                        <RadioField
                          name={`inclusiveOption-${index}`}
                          id={`inclusiveOption-no-${index}`}
                          value={0}
                          ischecked={formData[index].inclusiveOption === false}
                          handleChange={(event) => {
                            changeHandler(event, index);
                          }}
                          label={t("No")}
                        />
                      </td>
                      {details.wageId !== 29 && (
                        <>
                          <td
                            className="text-break price-coeff py-3 wageElementsClass"
                            data-label="Price / Coeff"
                          >
                            <SelectWithSearch
                              search={true}
                              options={priceOptions}
                              onChange={(e) =>
                                handleSelectChange(e, "priceOrCoeff", index)
                              }
                              isMulti={false}
                              name="priceOrCoeff"
                              value={formData[index].priceOrCoeff}
                            />
                          </td>
                          <td
                            className="text-break price-coeff py-3 wageElementsClass"
                            data-label="Value"
                          >
                            <SelectWithSearch
                              search={true}
                              options={coefficients}
                              onChange={(e) =>
                                handleSelectChange(e, "value", index)
                              }
                              isDisabled={
                                formData[index]?.priceOrCoeff?.value !== 1
                              }
                              isMulti={false}
                              name="value"
                              value={formData[index].value}
                            />
                          </td>
                          <td className="text-break py-3" data-label="Min">
                            <div
                              className={`input-group field-shadow rounded-3 wageElementsAmount wageElementsClass`}
                            >
                              <InputTextfield
                                name="min"
                                handleChange={(event) =>
                                  changeHandler(event, index)
                                }
                                value={formData[index].min ?? ""}
                                id={`min-${index}`}
                                type="text"
                                isDisabled={
                                  formData[index]?.priceOrCoeff?.value === 1
                                }
                                className={`form-control shadow-none  ${
                                  formData[index]?.priceOrCoeff?.value == 0
                                    ? "inclusiveAmount"
                                    : ""
                                }`}
                              />
                              {formData[index]?.priceOrCoeff?.value == 0 && (
                                <span
                                  className="input-group-text rounded-start-0 rounded-end-3 field-shadow border-0"
                                  id="basic-addon1"
                                >
                                  €
                                </span>
                              )}
                            </div>
                          </td>
                          <td
                            className="text-break py-3"
                            data-label="Nice to have"
                          >
                            <div
                              className={
                                "input-group field-shadow rounded-3 wageElementsAmount wageElementsClass"
                              }
                            >
                              <InputTextfield
                                name="nicetohave"
                                handleChange={(event) =>
                                  changeHandler(event, index)
                                }
                                value={formData[index].nicetohave ?? ""}
                                id={`nicetohave-${index}`}
                                type="text"
                                isDisabled={
                                  formData[index]?.priceOrCoeff?.value === 1
                                }
                                className={`form-control shadow-none ${
                                  formData[index]?.priceOrCoeff?.value == 0
                                    ? "inclusiveAmount"
                                    : ""
                                }`}
                              />
                              {formData[index]?.priceOrCoeff?.value == 0 && (
                                <span
                                  className="input-group-text rounded-start-0 rounded-end-3 field-shadow border-0"
                                  id="basic-addon1"
                                >
                                  €
                                </span>
                              )}
                            </div>
                          </td>
                          <td
                            className="text-break py-3"
                            data-label="Nice to have"
                          >
                            <div
                              className={
                                "input-group field-shadow rounded-3 wageElementsAmount wageElementsClass"
                              }
                            >
                              <InputTextfield
                                name="max"
                                handleChange={(event) =>
                                  changeHandler(event, index)
                                }
                                value={formData[index].max ?? ""}
                                id={`max-${index}`}
                                type="text"
                                isDisabled={
                                  formData[index]?.priceOrCoeff?.value === 1
                                }
                                className={`form-control shadow-none ${
                                  formData[index]?.priceOrCoeff?.value == 0
                                    ? "inclusiveAmount"
                                    : ""
                                }`}
                              />
                              {formData[index]?.priceOrCoeff?.value == 0 && (
                                <span
                                  className="input-group-text rounded-start-0 rounded-end-3 field-shadow border-0"
                                  id="basic-addon1"
                                >
                                  €
                                </span>
                              )}
                            </div>
                          </td>
                          <td
                            className="text-break wage-codes py-3 wageElementsClass"
                            data-label="Wage code"
                          >
                            <SelectWithSearch
                              isMandatory={false}
                              search={true}
                              options={wageCodesList()}
                              onChange={(e) =>
                                handleSelectChange(e, "wageCodes", index)
                              }
                              isMulti={true}
                              name="wageCodes"
                              value={formData[index].wageCodes}
                              className="scrollBar"
                            />
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="row backPadding">
          <div className="col-md-4 align-self-center">
            <Link
              to="/config/settings"
              className=" back-btn text-decoration-underline"
            >
              {t("Back")}
            </Link>
          </div>
          <AccessControl
            requiredPermissions={[
              {
                permission: "Budget",
                create: true,
                update: true,
              },
            ]}
            actions={true}
            strict={false}
          >
            <div className="col-md-8">
              {!loading ? (
                <Button
                  title={t("Submit")}
                  type="submit"
                  className="form-button float-end shadow-none"
                />
              ) : (
                <LoadingIcon
                  iconType="bars"
                  color="#e55496"
                  height={"3vw"}
                  width={"3vw"}
                  className="ms-auto"
                />
              )}
            </div>
          </AccessControl>
        </div>
      </form>
    </AccessControl>
  );
};

export default translate(ManageWageElements);
