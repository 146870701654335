import * as ENDPOINTS from "../../../../routes/ApiEndpoints";
import Button from "components/common/atoms/Button";
import CustomNotify from "components/common/atoms/CustomNotify";
import LabelField from "components/common/atoms/LabelField";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import { ApiCall } from "components/common/services/ApiServices";
import {
  validateForm,
  validateFunctionField,
  validateSelectField,
} from "components/common/services/ValidationService";
import { mapToSelect } from "components/common/utlis/MapToSelect";
import {
  Option,
  OptionProps,
  ValidationRules,
} from "components/common/utlis/TypeAnnotations";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import CloseFile from "static/images/CloseFile";
import Title from "components/common/atoms/Title";
import { t, translate } from "components/CentralDataMangement/translation/Translation";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";

interface RegionCountry {
  region: Option | null;
  country: Option | null;
}

interface EmployeeFunction {
  employeeType: Option | null;
  low: { lowFunctions: Option[]; lowSelection: string };
  medium: { mediumFunctions: Option[]; mediumSelection: string };
  high: { highFunctions: Option[]; highSelection: string };
  [key: string]: any;
}

const AddFunctions = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const navigate = useNavigate();
  const isEditMode = location.pathname.includes("edit");
  const isViewMode = location.pathname.includes("view");

  //initialize basic state
  const [data, setData] = useState<RegionCountry>({
    region: null,
    country: null,
  });

  //initialize dynamic state contains functions based on employee type
  const [functionsData, setFunctionsData] = useState<EmployeeFunction[]>([
    {
      employeeType: null,
      low: { lowFunctions: [], lowSelection: "" },
      medium: { mediumFunctions: [], mediumSelection: "" },
      high: { highFunctions: [], highSelection: "" },
    },
  ]);

  //initialize state for dependency drown region based countries
  const [selectedRegion, setSelectedRegion] = useState(null);

  //initialize error object for basic fields
  const initialdataErrors: { [key: string]: string } = {};
  const [dataError, setDataError] = useState<{ [key: string]: string }>(
    initialdataErrors
  );

  //initialize error object for dynamic fields
  const initialErrors: { [key: string]: string }[] = [];
  const [errors, setErrors] =
    useState<{ [key: string]: string }[]>(initialErrors);

  //initialize state for dropdowns
  const [dropDowns, setDropDowns] = useState({
    regionDropdown: [] as OptionProps[],
    countryDropdown: [] as OptionProps[],
    employeeCategoriesDropdown: [] as OptionProps[],
    functionDropdown: [] as OptionProps[],
  });

  //validation function for dynamic input fields
  const validation = (
    name: string,
    value: string | boolean | Date | number | null | Option,
    index: number,
    isSingleFieldValidation: boolean = false
  ) => {
    // Define validation rules...
    const validationRules: ValidationRules = {
      employeeType: [validateSelectField],
      high:
        functionsData[index].employeeType !== null &&
        functionsData[index].low.lowFunctions.length === 0 &&
        functionsData[index].medium.mediumFunctions.length === 0 &&
        functionsData[index].high.highFunctions.length === 0
          ? [validateFunctionField]
          : [],
    };

    const validationErrors = validateForm(
      { ...functionsData[index], [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    // Update errors state...
    setErrors((prevErrors) => {
      const newErrors = [...prevErrors];

      if (isSingleFieldValidation) {
        // Single-field validation
        if (!newErrors[index]) {
          newErrors[index] = {};
        }
        newErrors[index][name] = validationErrors[name];
      } else {
        // Full-form validation
        newErrors[index] = validationErrors;
      }

      return newErrors;
    });

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }

    return true;
  };

  //validation function for basic input fields
  const normalValidation = (
    name: string,
    value: string | Option | null,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      region: [validateSelectField],
      country: [validateSelectField],
    };

    const validationErrors = validateForm(
      { ...data, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation && Object.keys(errors).length > 0) {
      setDataError((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setDataError(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }

    return true;
  };

  //changehandler for dynamic input fields
  const dynamicChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { name, value } = event.target as HTMLInputElement;
    const regex = /^(\d{0,2})(,\d{0,4})?$/;
    let matches = regex.exec(value);

    const newFields = [...functionsData];
    if (matches) {
      // Split the fieldName into parts to access nested properties
      const fieldParts = name.split(".");
      newFields[index] = {
        ...newFields[index],
        [fieldParts[0]]: {
          ...newFields[index][fieldParts[0]],
          [fieldParts[1]]: value,
        },
      };
    }

    //update state
    setFunctionsData(newFields);
  };

  //changehandler for normal select fields
  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    if (fieldName === "region") {
      setSelectedRegion(selectedOption.value);
      setData((prevData) => ({
        ...prevData,
        country: null,
      }));
    }
    setData((prevData) => ({
      ...prevData,
      [fieldName]: selectedOption,
    }));

    //onchange validation based on field name
    normalValidation(fieldName, selectedOption, true);
  };

  //changehandler for dynamic select fields
  const handleDynamicSelectChange = (
    selectedOption: any,
    fieldName: string,
    index: number
  ) => {
    const newFields = [...functionsData];

    //for non nested properties
    if (fieldName === "employeeType") {
      newFields[index] = {
        ...newFields[index],
        [fieldName]: selectedOption,
      };
      setFunctionsData(newFields);
    } else {
      // Split the fieldName into parts to access nested properties
      const fieldParts = fieldName.split(".");
      newFields[index] = {
        ...newFields[index],
        [fieldParts[0]]: {
          ...newFields[index][fieldParts[0]],
          [fieldParts[1]]: selectedOption,
        },
      };
      setFunctionsData(newFields);
    }

    //onchange validation based on field name
    validation(fieldName, selectedOption, index, true);
  };

  //fetching countries list based on region
  const getCountriesForRegion = () => {
    if (selectedRegion) {
      const regionBasedCountries = dropDowns.countryDropdown.filter(
        (country) =>
          country.region_id !== undefined &&
          selectedRegion === country.region_id
      );
      return mapToSelect(regionBasedCountries);
    }
    return [];
  };

  //adding new fields for functions
  const addFieldSet = (): void => {
    const newField: EmployeeFunction = {
      employeeType: null,
      low: { lowFunctions: [], lowSelection: "" },
      medium: { mediumFunctions: [], mediumSelection: "" },
      high: { highFunctions: [], highSelection: "" },
    };
    setFunctionsData([...functionsData, newField]);
  };

  //removing dynamic fields based on index
  const removeFieldSet = (index: number): void => {
    const newFields: EmployeeFunction[] = [...functionsData];
    newFields.splice(index, 1);
    setFunctionsData(newFields);
  };

  //displaying unique functions list based on low, medium and high selection.
  const getFunctionsDropdown = (index: number) => {
    //fetchinng all low functions selected
    const lowFunctions = functionsData[index].low.lowFunctions.map(
      (item: any) => item.value
    );

    //fetchinng all medium functions selected
    const mediumFunctions = functionsData[index].medium.mediumFunctions.map(
      (item: any) => item.value
    );

    //fetchinng all high functions selected
    const highFunctions = functionsData[index].high.highFunctions.map(
      (item: any) => item.value
    );

    if (
      lowFunctions.length > 0 ||
      mediumFunctions.length > 0 ||
      highFunctions.length > 0
    ) {
      //combining all the selected functions in one array using spread opreator
      const selectedFunctions = [
        ...lowFunctions,
        ...mediumFunctions,
        ...highFunctions,
      ];

      //fecthing list from functionDropdown not contains selected functions
      const jobFunctions = dropDowns.functionDropdown.filter(
        (func) => !selectedFunctions.includes(func.id as number)
      );
      return mapToSelect(jobFunctions);
    }
    //initially fecthing entire functions list
    return mapToSelect(dropDowns.functionDropdown);
  };

  //displaying unique employee categories in add another
  const getEmployeeCategoriesDropdown = () => {
    //getting initial selected employee category
    const selectedEmployeeCategory = functionsData.map(
      (item) => item.employeeType?.value
    );

    //fecthing list from employeeCategoriesDropdown not contains selected employee category
    if (selectedEmployeeCategory.length > 0) {
      const selectedCategory = dropDowns.employeeCategoriesDropdown.filter(
        (cat) => !selectedEmployeeCategory.includes(cat.id as number)
      );
      return mapToSelect(selectedCategory);
    }
    return mapToSelect(dropDowns.employeeCategoriesDropdown);
  };

  //getting length of employeeCategoriesDropdown to show and hide add another button
  let dataRows =
    functionsData.length === dropDowns.employeeCategoriesDropdown.length;

  useEffect(() => {
    fetchDetails();
  }, []);

  //API call for get data for edit flow and dropdown values
  const fetchDetails = async () => {
    const url = id
      ? `${ENDPOINTS.FETCH_FUNCTION_DETAILS}/${id}`
      : ENDPOINTS.FETCH_FUNCTION_DETAILS;
    const response = await ApiCall.getService(
      url,
      "GET",
      "company-creation",
      false
    );
    if (response.status === 200) {
      const regions = mapToSelect(response.data["region"]);
      const countries = response.data["countries"];
      const categories = response.data["employeeCategories"];
      const functions = response.data["jobFunctions"];
      const funcDetails = response.data["functionDetails"];

      //for edit flow setting data to state
      if (Object.keys(funcDetails).length > 0) {
        const basic = response.data["functionDetails"]["basic"];
        const funcDetails = response.data["functionDetails"]["funcData"];
        setData(basic);
        setFunctionsData(funcDetails);
      }

      //setting data for dropdown fields state
      setDropDowns({
        regionDropdown: regions,
        countryDropdown: countries,
        employeeCategoriesDropdown: categories,
        functionDropdown: functions,
      });
    }
  };

  //form submit function
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const { name, value } = e.target as HTMLInputElement;
    //initialize the error state
    let hasErrors = false;

    //for loop to check validation for dynamic fields
    for (let i = 0; i < functionsData.length; i++) {
      const validationResult = validation(name as string, value, i);
      if (validationResult !== true) {
        hasErrors = true;
      }
    }

    //basicfield validation and dynamic validation not has errors
    if (normalValidation(name, value) && !hasErrors) {
      //initiliaze the formdata object
      const formData = {
        basic: data,
        funcData: functionsData,
      };

      //API call for store and update
      const url = id
        ? `${ENDPOINTS.STORE_FUNCTIONS_TO_COUNTRY}/${id}`
        : ENDPOINTS.STORE_FUNCTIONS_TO_COUNTRY;
      const response = await ApiCall.service(
        url,
        "POST",
        formData,
        false,
        "company-creation"
      );
      if (response.status === 200) {
        CustomNotify({ type: "success", message: response.msg });
        navigate("/manage/function-profile");
      } else if (response.status === 400) {
        setDataError({
          country: response.msg,
        });

        CustomNotify({ type: "error", message: t(response.msg) });
      }
    }
  };

  return (
    <>
      <AccessControl
        requiredPermissions={[
          {
            permission: "Candidate",
            read: true,
          },
        ]}
        renderNoAccess={true}
      >
        <Title
          title={`${
            isEditMode ? t("Edit") : isViewMode ? t("View") : t("Add")
          } ${t("function")}`}
        />
        <form onSubmit={handleSubmit}>
          <div className="form-height">
            <div className="row">
              <div className="col-md-6">
                <SelectWithSearch
                  title={t("Region")}
                  search={true}
                  isMandatory={true}
                  options={dropDowns.regionDropdown}
                  isDisabled={isEditMode || isViewMode}
                  placeHolder={t("Select region")}
                  onChange={(e) => handleSelectChange(e, "region")}
                  isMulti={false}
                  className="select-field"
                  name="region"
                  value={data.region}
                  error={dataError.region}
                  isTranslate={true}
                />
              </div>
              <div className="col-md-6">
                <SelectWithSearch
                  title={t("Country")}
                  search={true}
                  isMandatory={true}
                  isDisabled={isEditMode || isViewMode}
                  options={getCountriesForRegion()}
                  placeHolder={t("Select country")}
                  onChange={(e) => handleSelectChange(e, "country")}
                  isMulti={false}
                  className="select-field"
                  name="country"
                  value={data.country}
                  error={dataError.country}
                />
              </div>
            </div>
            <div>
              {functionsData.map((funcData: any, index: number) => (
                <div
                  className="link-function-table border p-3 pb-2 mb-4"
                  key={index}
                >
                  {!isViewMode && (
                    <div className="row mb-3">
                      <div className="col-md-12">
                        {index > 0 && (
                          <>
                            <div className="d-block d-md-block d-lg-none">
                              <Button
                                title={t("Remove")}
                                handleClick={() => removeFieldSet(index)}
                                className="link-button float-end  rounded-0 shadow-none mt-4"
                              />
                            </div>
                            <div className="d-none d-md-none d-lg-block">
                              <span
                                title={t("Delete")}
                                onClick={() => removeFieldSet(index)}
                                className="table-action-icons cursor-pointer float-end  rounded-0 shadow-none"
                              >
                                <CloseFile />
                              </span>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="col-md-12">
                    <SelectWithSearch
                      title={t("Employee type")}
                      id={`employeeType-${index}`}
                      isMandatory={true}
                      isDisabled={isViewMode}
                      search={true}
                      options={getEmployeeCategoriesDropdown()}
                      placeHolder={t("Select employee type")}
                      onChange={(e) =>
                        handleDynamicSelectChange(e, "employeeType", index)
                      }
                      isMulti={false}
                      className="select-field"
                      name="region"
                      value={funcData.employeeType}
                      error={errors[index]?.employeeType}
                      isTranslate={true}
                    />
                  </div>
                  <table className="table">
                    <thead>
                      <tr className="TableHeader">
                        <th className="ps-4">{t("Profile")}</th>
                        <th>{t("Functions")}</th>
                        <th>{t("Selection")} %</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="align-middle border">
                        <td className="ps-4">
                          <LabelField
                            title={t("Low")}
                            className="col-md-1 align-self-center"
                          />
                        </td>
                        <td className="function-level">
                          <SelectWithSearch
                            id={`low-${index}`}
                            search={true}
                            isDisabled={isViewMode}
                            options={getFunctionsDropdown(index)}
                            placeHolder={t("Select function")}
                            onChange={(e) =>
                              handleDynamicSelectChange(
                                e,
                                "low.lowFunctions",
                                index
                              )
                            }
                            isMulti={true}
                            className="select-field"
                            name="lowFunctions"
                            value={funcData.low.lowFunctions}
                            isTranslate={true}
                          />
                        </td>
                        <td className="function-level">
                          <LabelWithInputField
                            type="text"
                            name="low.lowSelection"
                            isDisabled={isViewMode}
                            handleChange={(event) =>
                              dynamicChangeHandler(event, index)
                            }
                            value={funcData.low.lowSelection}
                          />
                        </td>
                      </tr>
                      <tr className="align-middle border">
                        <td className="ps-4">
                          <LabelField
                            title={t("Medium")}
                            className="col-md-1 align-self-center"
                          />
                        </td>
                        <td className=" function-level">
                          <SelectWithSearch
                            id={`medium-${index}`}
                            search={true}
                            isDisabled={isViewMode}
                            options={getFunctionsDropdown(index)}
                            placeHolder={t("Select function")}
                            onChange={(e) =>
                              handleDynamicSelectChange(
                                e,
                                "medium.mediumFunctions",
                                index
                              )
                            }
                            isMulti={true}
                            className="select-field"
                            name="mediumFunctions"
                            value={funcData.medium.mediumFunctions}
                            isTranslate={true}
                          />
                        </td>
                        <td className="function-level">
                          <LabelWithInputField
                            type="text"
                            name="medium.mediumSelection"
                            isDisabled={isViewMode}
                            handleChange={(event) =>
                              dynamicChangeHandler(event, index)
                            }
                            value={funcData.medium.mediumSelection}
                          />
                        </td>
                      </tr>
                      <tr className="align-middle border">
                        <td className="ps-4">
                          <LabelField
                            title={t("High")}
                            className="col-md-1 align-self-center"
                          />
                        </td>
                        <td className="function-level">
                          <SelectWithSearch
                            id={`high-${index}`}
                            search={true}
                            isDisabled={isViewMode}
                            options={getFunctionsDropdown(index)}
                            placeHolder={t("Select function")}
                            onChange={(e) =>
                              handleDynamicSelectChange(
                                e,
                                "high.highFunctions",
                                index
                              )
                            }
                            isMulti={true}
                            className="select-field"
                            name="highFunctions"
                            value={funcData.high.highFunctions}
                            isTranslate={true}
                          />
                        </td>
                        <td className="function-level">
                          <LabelWithInputField
                            type="text"
                            name="high.highSelection"
                            isDisabled={isViewMode}
                            handleChange={(event) =>
                              dynamicChangeHandler(event, index)
                            }
                            value={funcData.high.highSelection}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="text-danger">{errors[index]?.high}</div>
                </div>
              ))}
              {!dataRows && !isViewMode && (
                <div className="row ">
                  <div className="col-md-12">
                    <Button
                      title={"+ " + t("Add another")}
                      handleClick={addFieldSet}
                      className="btn form-button float-end  rounded-3 shadow-none"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="row my-3">
            <div className="col-md-6 align-self-center">
              <Link
                to="/manage/function-profile"
                className=" back-btn text-decoration-underline"
              >
                {t("Back")}
              </Link>
            </div>
            {!isViewMode && (
              <div className="col-md-6 text-lg-end">
                <Button
                  title={t("Submit")}
                  type="submit"
                  className="btn form-button rounded-3 shadow-none  px-3 button-width"
                />
              </div>
            )}
          </div>
        </form>
      </AccessControl>
    </>
  );
};

export default translate(AddFunctions);
