import { dropDownList } from "./Interface";

export const createFilterConfig = (dropDownList: dropDownList) => {
  return [
    {
      name: "office",
      options: dropDownList.offices,
      fieldType: "multiSelect",
      placeholder: "Office",
      filterType: "search",
    },
    {
      name: "firstName",
      fieldType: "text",
      placeholder: "First name",
      filterType: "search",
    },
    {
      name: "lastName",
      fieldType: "text",
      placeholder: "Last name",
      filterType: "search",
    },
    {
      name: "function",
      options: dropDownList.functions,
      fieldType: "multiSelect",
      placeholder: "Function",
      filterType: "search",
    },
    {
      name: "from",
      fieldType: "date",
      placeholder: "From",
      group: "one",
      label: "From",
      filterType: "search",
    },
    {
      name: "to",
      fieldType: "date",
      placeholder: "To",
      group: "one",
      label: "To",
      filterType: "search",
    },
  ];
};
