import React from 'react'
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";


const GetCrudPermissions = (permission: string) => {
  const authUser = useSelector(selectAuth);

  return authUser?.userPermissions?.find((item:any) => item.permission === permission);  
}

export default GetCrudPermissions