import React, { useState } from "react";
import LabelField from "components/common/atoms/LabelField";
import InputTextfield from "components/common/atoms/InputTextField";
import HidePassword from "static/images/Hide.svg";
import ShowPassword from "static/images/Show.svg";
import { useLocation, useNavigate } from "react-router-dom";
import EncryptDecryptService from "components/common/services/EncryptDecryptService";
import Button from "components/common/atoms/Button";
import { ApiCall } from "components/common/services/ApiServices";
import LoadingIcon from "components/common/utlis/LoadingIcon";
import {
  validateForm,
  validateRequired,
  validatePassword,
  validateMatchPassword,
} from "components/common/services/ValidationService";
import ImageField from "components/common/atoms/ImageField";
import CustomNotify, { NotifyType } from "components/common/atoms/CustomNotify";
import { RESET_PASSWORD } from "routes/ApiEndpoints";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

const ResetPasswordForm: React.FC = () => {
  const navigate = useNavigate();
  interface FormFields {
    password?: string;
    confirmPassword?: string;
  }

  interface PasswordTypes {
    passwordType?: string;
    confirmPasswordType?: string;
  }

  interface ValidationRules {
    [key: string]: Function[];
  }

  let location = useLocation();

  const urlSearchParams = new URLSearchParams(location.search);

  const queryParamValue = urlSearchParams.get("email");

  const decryptedEmail = queryParamValue ? atob(queryParamValue) : "";
  const splitArray = decryptedEmail.split("email-");
  const email = splitArray[1];

  const [passwordTypes, setPasswordTypes] = useState<PasswordTypes>({
    passwordType: "password",
    confirmPasswordType: "password",
  });

  const [formFields, setFormFields] = useState<FormFields>({
    password: "",
    confirmPassword: "",
  });

  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState<FormFields>({});

  const handleSubmit = async (e: React.FormEvent) => {
    setLoading(true);
    e.preventDefault();
    const { name, value } = e.target as HTMLInputElement;
    const formData = {
      password: EncryptDecryptService.encryptData(
        JSON.stringify(formFields.password)
      ),
      confirmPassword: EncryptDecryptService.encryptData(
        JSON.stringify(formFields.confirmPassword)
      ),
      email: email,
    };

    if (validation(name, value)) {
      const response = await ApiCall.service(RESET_PASSWORD, "POST", formData);
      if (response.status === 200) {
        let notificationType: NotifyType = "success";
        setTimeout(() => {
          navigate("/login");
        }, 2000); // Navigate after 5 seconds
        CustomNotify({ type: notificationType, message: t(response.msg) });
      }
    }
    setLoading(false);
  };

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormFields((prevData) => ({ ...prevData, [name]: value }));
    validation(name, value, true);
  };

  const validation = (
    name: string,
    value: string,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      password: [validateRequired, validatePassword],
      confirmPassword: [
        validateRequired,
        validatePassword,
        (value: string) =>
          formFields.password
            ? validateMatchPassword(formFields.password, value)
            : undefined,
      ],
    };

    const validationErrors = validateForm(
      { ...formFields, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation && Object.keys(errors).length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }

    return true;
  };

  const togglePassword = (fieldType: number) => {
    if (fieldType === 0) {
      passwordTypes.passwordType === "password"
        ? setPasswordTypes((prevData) => ({
            ...prevData,
            passwordType: "text",
          }))
        : setPasswordTypes((prevData) => ({
            ...prevData,
            passwordType: "password",
          }));
    } else {
      passwordTypes.confirmPasswordType === "password"
        ? setPasswordTypes((prevData) => ({
            ...prevData,
            confirmPasswordType: "text",
          }))
        : setPasswordTypes((prevData) => ({
            ...prevData,
            confirmPasswordType: "password",
          }));
    }
  };

  return (
    <>
      <div className="container-fluid login-bg">
        <div className="row">
          <div className="col-6 px-0 vh-100">
            <ImageField
              altText={t("Banner")}
              className="w-100 h-100 img-fluid object-fit-cover"
              src="/static/images/Welcome-dashboard.png"
            />
          </div>
          <div className="col-6 align-self-center">
            <div style={{ width: "60%", margin: "auto" }}>
              <div className="text-center" style={{ marginBottom: "3vw" }}>
                <ImageField
                  altText={t("Logo")}
                  className="text-center logo-width"
                  src="static/images/logo.svg"
                />
              </div>
              <div className="text-center" style={{ marginBottom: "2vw" }}>
                <span className="text-center login-title position-relative">
                  {t("Confirm password")}
                  <img
                    src="../static/images/Splash_1.svg"
                    alt="dot-arrow"
                    className="dot-arrow position-absolute"
                  />
                </span>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="col-lg-12">
                  <LabelField
                    title={t("Password")}
                    isMandatory={true}
                    htmlfor="password"
                  />
                  <div className="d-flex position-relative">
                    <InputTextfield
                      handleChange={changeHandler}
                      type={passwordTypes.passwordType}
                      value={formFields.password}
                      id="password"
                      placeholder={t("Enter password")}
                      name="password"
                    />
                    <p
                      onClick={() => togglePassword(0)}
                      className="position-absolute cursor-pointer eye-icon"
                    >
                      {passwordTypes.passwordType === "password" ? (
                        <img src={HidePassword} alt={t("Hide Password")} />
                      ) : (
                        <img src={ShowPassword} alt={t("Show Password")} />
                      )}
                    </p>
                  </div>
                  <div className="height-20" style={{marginBottom: "0.5vw"}}>
                    {errors.password && (
                      <span className="text-danger">{errors.password}</span>
                    )}
                  </div>
                </div>
                <div className="col-lg-12">
                  <LabelField
                    title={t("Confirm password")}
                    isMandatory={true}
                    htmlfor="confirm-password"
                  />
                  <div className="d-flex position-relative">
                    <InputTextfield
                      handleChange={changeHandler}
                      type={passwordTypes.confirmPasswordType}
                      value={formFields.confirmPassword}
                      id="confirm-password"
                      placeholder={t("Confirm password")}
                      name="confirmPassword"
                    />
                    <p
                      onClick={() => togglePassword(1)}
                      className="position-absolute cursor-pointer eye-icon"
                    >
                      {passwordTypes.confirmPasswordType === "password" ? (
                        <img src={HidePassword} alt={t("Hide Password")} />
                      ) : (
                        <img src={ShowPassword} alt={t("Show Password")} />
                      )}
                    </p>
                  </div>
                  <div className="height-20" style={{marginBottom: "0.5vw"}}>
                    {errors.confirmPassword && (
                      <span className="text-danger">
                        {errors.confirmPassword}
                      </span>
                    )}
                  </div>
                </div>
                <div className="row" style={{marginTop:"1.5vw"}}>
                  <div className="col-md-10 mx-auto">
                    {!loading ? (
                      <Button
                        title={t("Submit")}
                        type="submit"
                        icon={faAngleRight}
                        className="form-button w-100"
                      />
                    ) : (
                      <LoadingIcon iconType="bars" color="#ff4dae" />
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default translate(ResetPasswordForm);
