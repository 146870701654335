import { LabelWithInputField } from 'components/common/molecules/LabelWithInputField';
import React, { useEffect, useState } from 'react';
import { t, translate } from '../translation/Translation';
import Calender from 'components/common/molecules/Calender';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Button from 'components/common/atoms/Button';
import InputTextfield from 'components/common/atoms/InputTextField';
import Title from 'components/common/atoms/Title';
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from 'Constants';
import { ApiCall } from 'components/common/services/ApiServices';
import LoadingIcon from 'components/common/utlis/LoadingIcon';
import { validateDate, validateForm, validateRequired, validateSelectField } from 'components/common/services/ValidationService';
import { OptionProps, ValidationRules } from 'components/common/utlis/TypeAnnotations';
import DeleteIcon from 'static/images/DeleteIcon';
import SelectWithSearch from 'components/common/atoms/SelectWithSearch';
import { mapToSelect } from 'components/common/utlis/MapToSelect';

interface FormData {
  withHoldingTax: string;
  fsd: Date | null;
  fed: Date | null;
  ps_mgr: string;
  iban: string;
  businessUnit: string;
}

const ContractInfoPage = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { todoExtensionId, companyId, candidateId, todoId, functionName, presentationId } = useParams<{
    todoExtensionId: string;
    companyId: string;
    candidateId: string;
    todoId: string;
    functionName: string;
    presentationId: string
  }>();

  const [formData, setFormData] = useState<FormData>({
    withHoldingTax: "",
    fsd: null,
    fed: null,
    ps_mgr: "",
    iban: "",
    businessUnit: ""
  });

  const [options, setOptions] = useState({
    businessUnitOptions: [] as OptionProps[]
  });

  const initialErrors: { [key: string]: string } = {};
  const [errors, setErrors] = useState<{ [key: string]: string }>(
    initialErrors
  );

  const validation = (
    name: string,
    value: string | boolean | Date | null,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      fsd: [validateDate],
      fed: [validateDate],
      withHoldingTax: [validateRequired],
      businessUnit: [validateSelectField],
      ps_mgr: [validateRequired],
      iban: [validateRequired],
    };

    const validationErrors = validateForm(
      { ...formData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation && Object.keys(errors).length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }

    return true;
  };

  const handleFieldChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;

    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value
    }));
    validation(name, value, true);
  };

  const handleSelectChange = (selectedOption: any, name: string) => {
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: selectedOption
    }));
    validation(name, selectedOption, true);
  };

  const getNextWorkingDay = (date: Date, daysToAdd: number) => {
    const nextDate = new Date(date);
    nextDate.setDate(nextDate.getDate() + daysToAdd);
    while (nextDate.getDay() === 0 || nextDate.getDay() === 6) {
      nextDate.setDate(nextDate.getDate() + 1); // Skip Saturday (0) and Sunday (6)
    }
    return nextDate;
  };

  const handleDateChange = (date: Date | null, fieldName: string) => {
    if (fieldName === 'fsd') {
      let daysToAdd = 2; // Default number of days to add

      switch (date?.getDay()) {
        case 4: // Thursday  --- Skip Fri, Sat, Sun then Mon
        case 5: // Friday  ---  Skip Sat, Sun then Mon, Tue
        case 6: // Saturday --- Skip  Sat, Sun then Mon, Tue, Wed
          daysToAdd = 4;
          break;
        case 0: // Sunday
          daysToAdd = 3; // Skip Sun, then Mon, Tue, Wed
          break;
        default:
          break;
      }

      // const newFedDate = date ? new Date(date.getTime() + 2 * 24 * 60 * 60 * 1000) : null;

      if (date) {
        setFormData((prevData) => ({
          ...prevData,
          [fieldName]: date,
          fed: getNextWorkingDay(date, daysToAdd)
        }));
      }
      setErrors((prevErrors) => ({
        ...prevErrors,
        fed: "",
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: date,
      }));
    }

    validation(fieldName, date, true);
  }

  const [tableData, setTableData] = useState([
    {
      clothing: "",
      pricePerPiece: "",
      number: "",
      totalCost: ""
    }
  ]);
  const handleAddRow = () => {
    setTableData((prevData) => [
      ...prevData,
      {
        clothing: "",
        pricePerPiece: "",
        number: "",
        totalCost: ""
      }
    ]);
  };

  const handleRemoveRow = (index: number) => {
    setTableData((prevData) => prevData.filter((_, i) => i !== index));
  };

  const handleTableFieldChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { name, value } = e.target;
    const newData = [...tableData];
    newData[index] = {
      ...newData[index],
      [name]: value
    };
    // Calculate total cost
    const unitPrice = parseFloat(newData[index].pricePerPiece.replace(',', '.'));
    const count = parseInt(newData[index].number);
    let totalCost = (isNaN(unitPrice) || isNaN(count)) ? '' : (unitPrice * count).toFixed(2);
    newData[index].totalCost = totalCost.replace('.', ',');;
    setTableData(newData);
  };

  const fetchContractDetails = async () => {
    const data = {
      candidateId: candidateId,
      presentationId: presentationId,
      todoExtensionId: todoExtensionId
    }
    const response = await ApiCall.service("getContractDetails", "POST", data, false, CENTRAL_DATA_MANAGEMENT_MICROSERVICE);
    if (response.status === 200) {
      const contractDetails = response.data.contractDetails;
      // const workWearDetails = response.data.workWearDetails;
      setFormData(prevData => ({
        ...prevData,
        withHoldingTax: contractDetails.withHoldingTax || "",
        fsd: contractDetails.fsd ? new Date(contractDetails.fsd) : null,
        fed: contractDetails.fed ? new Date(contractDetails.fed) : null,
        ps_mgr: contractDetails.ps_mgr || "",
        iban: contractDetails.iban || "",
        businessUnit: contractDetails.businessUnit || ""
      }));

      setOptions((prev) => ({
        ...prev,
        businessUnitOptions: mapToSelect(contractDetails.businessUnitOptions)
      }));

      // Set work wear details
      // if (workWearDetails && workWearDetails.length > 0) {
      //   const formattedWorkWearDetails = workWearDetails.map((workWearDetail: any) => ({
      //     clothing: workWearDetail.clothing || "",
      //     pricePerPiece: workWearDetail.pricePerPiece || "",
      //     number: workWearDetail.number || "",
      //     totalCost: workWearDetail.totalCost || ""
      //   }));
      //   setTableData(formattedWorkWearDetails);
      // }
    }
  }
  useEffect(() => {
    fetchContractDetails();
  }, []);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const data = {
      presentationId: presentationId,
      contractDetails: formData,
      workWearDetails: tableData
    };
    const { name, value } = event.target as HTMLInputElement;
    if (validation(name, value)) {
      setLoading(true);

      const response = await ApiCall.service(
        "storeContractDetails",
        "POST",
        data,
        false,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.status === 200) {
        let link = `/todos/contract/${candidateId}/${todoExtensionId}/${todoId}/${presentationId}/${functionName}/${companyId}/9`;
        navigate(link);
      }
      setLoading(false);
    }
  };

  const filterWeekends = (date: Date | null) => {
    return date?.getDay() !== 0 && date?.getDay() !== 6;
  };

  return (
    <>
      <Title title={t("Contract")} />
      <form>
        <div className="form-border mb-4">
          <div className="row">
            <div className="col-6">
              <LabelWithInputField
                isMandatory={true}
                name="withHoldingTax"
                handleChange={handleFieldChange}
                value={formData.withHoldingTax}
                id="withHoldingTax"
                label={t("Withholding tax")}
                type="text"
                error={errors.withHoldingTax}
              />
            </div>
            <div className="col-sm-6 col-md-3 position-relative">
              <Calender
                isMandatory={true}
                label={t("Formation start date")}
                name="fsd"
                minDate={new Date()}
                selectedDate={formData.fsd}
                onChange={(date) => handleDateChange(date || new Date(), 'fsd')}
                error={errors.fsd}
                filterDate={filterWeekends}
              />
            </div>
            <div className="col-sm-6 col-md-3 position-relative">
              <Calender
                isMandatory={true}
                label={t("Formation end date")}
                name="fed"
                minDate={new Date()}
                selectedDate={formData.fed}
                onChange={(date) => handleDateChange(date || new Date(), 'fed')}
                error={errors.fed}
              />
            </div>
          </div>
          {/* <table className="table table-bordered contractTable">
                    <thead>
                      <tr className='TableHeader'>
                        <th>
                          {t("Clothing")}
                        </th>
                        <th>
                          {t("Price/piece")}
                        </th>
                        <th>
                          {t("Number")}
                        </th>
                        <th>
                          {t("Total cost")}
                        </th>
                        <th>
                          {t("Action")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.map((rowData, index) => (
                        <tr key={index} className='align-middle'>
                          <td className='p-0 position-relative'>
                            <InputTextfield
                              name="clothing"
                              handleChange={(e) => handleTableFieldChange(e, index)}
                              value={rowData.clothing}
                              type="text"
                              className={`form-control shadow-none rounded-0 position-absolute`}
                            />
                          </td>
                          <td className='p-0 position-relative'>
                            <InputTextfield
                              name="pricePerPiece"
                              handleChange={(e) => handleTableFieldChange(e, index)}
                              value={rowData.pricePerPiece}
                              type="text"
                              className={`form-control shadow-none rounded-0 position-absolute`}
                            />
                          </td>
                          <td className='p-0 position-relative'>
                            <InputTextfield
                              name="number"
                              handleChange={(e) => handleTableFieldChange(e, index)}
                              value={rowData.number}
                              type="text"
                              className={`form-control shadow-none rounded-0 position-absolute`}
                            />
                          </td>
                          <td className='p-0 position-relative'>
                            <InputTextfield
                              name="totalCost"
                              handleChange={(e) => handleTableFieldChange(e, index)}
                              value={rowData.totalCost}
                              type="text"
                              className={`form-control shadow-none rounded-0 position-absolute`}
                            />

                          </td>
                          <td className='table-action-icons text-center'>
                            <Link
                              to=""
                            >
                              <span
                                title={t("Delete")}
                                onClick={() => handleRemoveRow(index)}
                                className="link-button rounded-0 shadow-none"
                                data-bs-toggle="tooltip"
                                data-bs-placement="left">
                                <DeleteIcon />
                              </span>
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table> */}
          {/* <div className="row my-4">
                    <div className="col-md-12">
                      <Button
                        title={"+" + t("Add another")}
                        handleClick={handleAddRow}
                        className="btn form-button float-end rounded-3 shadow-none"
                      />
                    </div>
                  </div> */}
          {/* <div className="row">
                    <div className="col-6">
                      <LabelWithInputField
                        isMandatory={true}
                        name="ps_mgr"
                        handleChange={handleFieldChange}
                        value={formData.ps_mgr}
                        id="ps_mgr"
                        label={t("Payroll and social law manager")}
                        type="text"
                        error={errors.ps_mgr}
                      />
                    </div>
                    <div className="col-6">
                      <LabelWithInputField
                        isMandatory={true}
                        name="iban"
                        handleChange={handleFieldChange}
                        value={formData.iban}
                        id="iban"
                        label={t("IBAN of Absolute Jobs")}
                        type="text"
                        error={errors.iban}
                      />
                    </div>
                  </div> */}
          <div className="row">
            <div className="col-6">
              <SelectWithSearch
                title={t("Business unit")}
                name="businessUnit"
                placeHolder={t("Select")}
                search={true}
                options={options.businessUnitOptions}
                value={formData.businessUnit}
                onChange={(e) => handleSelectChange(e, "businessUnit")}
                isMulti={false}
                className="select-field"
                isTranslate={true}
              />
              {/* <LabelWithInputField
                        isMandatory={true}
                        name="businessUnit"
                        handleChange={handleFieldChange}
                        value={formData.businessUnit}
                        id="businessUnit"
                        label={t("Business unit")}
                        type="text"
                        error={errors.businessUnit}
                      /> */}
            </div>
          </div>
        </div>
        <div className="d-none d-md-block d-lg-block pb-4">
          <div className="row">
            <div className="col-md-6 align-self-center">
              <Link
                to={
                  location.state?.origin === "todos"
                    ? "/todos/overview"
                    : `/candidate/corner/${candidateId}`
                }
                className="back-btn text-decoration-underline"
              >
                {t("Back")}
              </Link>
            </div>

            <div className="col-md-6 d-flex justify-content-end">
              <>
                {!loading ? (
                  <Button
                    title={t("Save")}
                    type="submit"
                    className="form-button shadow-none px-3"
                    handleClick={(e) => handleSubmit(e)}
                  />
                ) : (
                  <LoadingIcon
                    iconType="bars"
                    color="#e55496"
                    className="ms-auto"
                    width={"1vw"}
                    height={"1vw"}
                  />
                )}
              </>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default translate(ContractInfoPage);
