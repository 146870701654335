import React, { ChangeEvent, useState, useEffect } from "react";

interface Props {
  min?: number; // Minimum value of the slider
  max?: number; // Maximum value of the slider
  step?: number; // Increment step of the slider
  value?: number; // Current value of the slider
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => void; // Function to handle value changes
  className?:string;
}

const CustomRangeSlider: React.FC<Props> = ({
  min = 1, // Default minimum value
  max = 5, // Default maximum value
  step = 1, // Default step value
  value = min, // Default value starts from min
  handleChange,
  className
}) => {
  const [isDragging, setIsDragging] = useState(false); // State to track dragging status
  const [currentValue, setCurrentValue] = useState(value); // State to track the current value

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  // Calculate the width percentage based on the current value
  const rangeWidth = ((currentValue - min) / (max - min)) * 100;

  // Function to calculate the new value based on mouse position
  const calculateValue = (clientX: number, slider: HTMLDivElement) => {
    const sliderRect = slider.getBoundingClientRect();
    const offsetX = clientX - sliderRect.left;
    const newValue =
      Math.round(((offsetX / sliderRect.width) * (max - min) + min) / step) *
      step;

    // Ensure newValue is within the bounds of min and max
    return Math.min(Math.max(newValue, min), max);
  };

  // Function to handle mouse down event on the slider thumb
  const handleThumbMouseDown = () => {
    setIsDragging(true);
  };

  // Function to handle mouse down event on the slider track
  const handleTrackMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    const slider = event.currentTarget; // Get the current target
    const newValue = calculateValue(event.clientX, slider);
    setCurrentValue(newValue);
    if (handleChange) {
      handleChange({
        target: { value: newValue.toString() },
      } as ChangeEvent<HTMLInputElement>);
    }
    setIsDragging(true); // Start dragging
  };

  // Function to handle mouse move
  const handleMouseMove = (event: MouseEvent) => {
    if (isDragging) {
      const slider = document.querySelector(".sliderTrack") as HTMLDivElement;
      if (slider) {
        const newValue = calculateValue(event.clientX, slider);
        setCurrentValue(newValue);
        if (handleChange) {
          handleChange({
            target: { value: newValue.toString() },
          } as ChangeEvent<HTMLInputElement>);
        }
      }
    }
  };

  // Function to handle mouse up event
  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    // Attach mouse move and mouse up event listeners when dragging
    if (isDragging) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    }

    // Clean up event listeners on unmount or when dragging ends
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging]);

  // Handle touch events for mobile support
  const handleTouchStart = () => {
    setIsDragging(true);
  };

  const handleTouchMove = (event: TouchEvent) => {
    if (isDragging && event.touches.length > 0) {
      const slider = document.querySelector(".sliderTrack") as HTMLDivElement;
      if (slider) {
        const newValue = calculateValue(event.touches[0].clientX, slider);
        setCurrentValue(newValue);
        if (handleChange) {
          handleChange({
            target: { value: newValue.toString() },
          } as ChangeEvent<HTMLInputElement>);
        }
      }
    }
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    // Attach touch event listeners
    if (isDragging) {
      document.addEventListener("touchmove", handleTouchMove);
      document.addEventListener("touchend", handleTouchEnd);
    }

    return () => {
      document.removeEventListener("touchmove", handleTouchMove);
      document.removeEventListener("touchend", handleTouchEnd);
    };
  }, [isDragging]);

  return (
    <div className={`customRangeSlider ${className}`}>
      <div
        className="sliderTrack"
        style={{
          width: "100%",
          height: "0.508vw",
          background: `linear-gradient(to right, #e55496 ${rangeWidth}%, #e0e0e0 ${rangeWidth}%)`,
          position: "relative",
          borderRadius: "0.208vw",
        }}
        onMouseDown={handleTrackMouseDown} // Start dragging from track click
        onTouchStart={handleTouchStart}
      >
        <div
          className="sliderThumb"
          style={{
            position: "absolute",
            left: `${rangeWidth}%`,
            top: "-0.3vw",
            width: "1.042vw",
            height: "1.042vw",
            background: "#e55496",
            borderRadius: "50%",
            cursor: "pointer",
            transform: "translateX(-50%)",
          }}
          onMouseDown={handleThumbMouseDown} // Allow dragging the thumb
          onTouchStart={handleTouchStart} // Touch support
        />
      </div>
      <div className="sliderValue" style={{ marginTop: "0.521vw" }}></div>
    </div>
  );
};

export default CustomRangeSlider;
