import { t, translate } from "components/CentralDataMangement/translation/Translation";
import React from "react";

interface Props {
  params: any;
}

const WelcomeInfo: React.FC<Props> = ({ params }) => {
  const { userAuth } = params;
  return (
    <div className="row mb-4">
      <div className="col-md-12 d-flex justify-content-center flex-column">
        <div className="d-flex align-items-center">
          <span className="welcome-text me-3 color-dark-purple">
            <strong>{t("Hi") + ","}</strong>
          </span>
          <div className="position-relative">
            <span className="name-text color-dark-pink ">
              {userAuth.name ?? ''}
            </span>
          </div>
        </div>
        <div className="welcome-message color-dark-purple">
          {t("Welcome back to")}
          <br />
          <div className="position-relative">{t("your dashboard")}</div>
        </div>
      </div>
    </div>
  );
};

export default translate(WelcomeInfo);