import React, { useState, useEffect } from "react";
import Search from "components/common/atoms/Search";
import Pagination from "components/common/atoms/Pagination";
import { Link } from "react-router-dom";
import EditIcon from "static/images/EditIcon";
import ViewIcon from "static/images/ViewIcon";
import { ApiCall } from "components/common/services/ApiServices";
import Button from "components/common/atoms/Button";
import { GET_TEMPLATES } from "routes/ApiEndpoints";
import { t, translate } from "../translation/Translation";
import Title from "components/common/atoms/Title";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import "./css/emailTemplate.css";
import ResetBtn from "components/common/atoms/ResetBtn";
import NoRecords from "components/common/atoms/NoRecords";
const ITEMS_PER_PAGE = 10;

interface EmailTemplate {
  id: number;
  name: string;
  module_name: string;
}

const ManageEmailTemplatesPage: React.FC = () => {
  const [searchData, setSearchData] = useState({
    name: "",
  });
  const [emailTemplates, setEmailTemplates] = useState<EmailTemplate[]>([]); // State to hold the fetched email templates

  const [currentPage, setCurrentPage] = useState(1);
  // const [isFilter, setIsFilter] = useState<boolean>(true); // Example boolean state

  useEffect(() => {
    fetchAllEmailTemplates();
  }, []);

  const fetchAllEmailTemplates = async () => {
    //api call to get all the templates created
    try {
      const response = await ApiCall.getService(
        GET_TEMPLATES,
        "GET",
        "central-data-management"
      );
      if (response.status === 200) {
        setEmailTemplates(response.data); // Update the state with fetched email templates
      }
    } catch (error) {
      // Handle API error
      console.error("Error fetching email templates:", error);
    }
  };
  const handleReset = () => {
    setSearchData({
      name: "", // Reset the search input to an empty string
    });
    setCurrentPage(1); // Optionally reset the pagination to the first page
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSearchData({ ...searchData, [name]: value });
    setCurrentPage(1);
  };

  const filteredEmailTemplates = emailTemplates.filter((template) =>
    template.name.toLowerCase().includes(searchData.name.toLowerCase())
  );

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const paginatedData = filteredEmailTemplates.slice(startIndex, endIndex);

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Email templates",
          read: true,
        },
      ]}
      renderNoAccess={true}
    >
      <Title title={t("Email templates")} />
      <div className="form-height-dashboard">
        <div className="table-responsive tableSection">
          <table className="table manageEmailTemplate">
            <thead>
              <tr>
                <th className="border-0">
                  <Search
                    className="form-control"
                    handleChange={handleSearchChange}
                    placeholder={t("Search by name")}
                    name="name"
                    value={searchData.name}
                  />
                </th>
                <th className="border-0"></th>
                <th className="border-0">
                  <ResetBtn handleResetClick={handleReset} />
                </th>
              </tr>
            </thead>
          </table>
          <table className="table manageEmailTemplate table-hover">
            <thead>
              <tr className="TableHeader">
                <th>{t("Name")}</th>
                <th>{t("Module name")}</th>
                <AccessControl
                  requiredPermissions={[
                    {
                      permission: "Email templates",
                      update: true,
                      delete: true,
                      read: true,
                    },
                  ]}
                  actions={true}
                  strict={false}
                >
                  <th>{t("Actions")}</th>
                </AccessControl>
              </tr>
            </thead>
            <tbody>
              {paginatedData.length > 0 ? (
                paginatedData.map((template, index) => (
                  <tr
                    key={template.id}
                    className="border-bottom mb-3 box-shadow align-middle"
                  >
                    <td className="text-break ps-lg-4" data-label={t("Name")}>
                      {t(`${template.name}`)}
                    </td>
                    <td className="text-break" data-label={t("Module name")}>
                      {t(`${template.module_name}`)}
                    </td>

                    <td className="table-action-icons px-2">
                      <div className="d-none d-md-none d-lg-block">
                        <AccessControl
                          requiredPermissions={[
                            {
                              permission: "Email templates",
                              update: true,
                            },
                          ]}
                        >
                          <Link
                            to={`/email-template/edit/${template.id}`}
                            className="btn p-0 border-0 me-2"
                            title={t("Edit")}
                          >
                            <EditIcon />
                          </Link>
                        </AccessControl>
                        <Link
                          to={`/email-template/view/${template.id}`}
                          className="btn p-0 border-0 me-2"
                          title={t("View")}
                        >
                          <ViewIcon />
                        </Link>
                      </div>
                      <div className="d-block d-md-block d-lg-none">
                        <AccessControl
                          requiredPermissions={[
                            {
                              permission: "Email templates",
                              update: true,
                            },
                          ]}
                        >
                          <div className="mb-2">
                            <Link
                              to={`/email-template/edit/${template.id}`}
                              className="btn p-0 border-0 me-2 w-100"
                              title={t("Edit")}
                            >
                              <Button
                                title={t("Edit")}
                                className=" mb-2 button-width edit-btn rounded-3"
                              />
                            </Link>
                          </div>
                        </AccessControl>
                        <div className="mb-2">
                          <Link
                            to={`/email-template/view/${template.id}`}
                            className="btn p-0 border-0 me-2 w-100"
                            title={t("View")}
                          >
                            <Button
                              title={t("View")}
                              className=" mb-2 button-width delete-btn rounded-3"
                            />
                          </Link>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <NoRecords headerLength={4} />
              )}
            </tbody>
          </table>
          {Math.ceil(filteredEmailTemplates.length / ITEMS_PER_PAGE) > 1 && (
            <div className="pagination justify-content-center align-items-center mt-4 px-4">
              <Pagination
                currentPage={currentPage}
                totalPages={Math.ceil(
                  filteredEmailTemplates.length / ITEMS_PER_PAGE
                )}
                onPageChange={handlePageChange}
              />
            </div>
          )}
        </div>
      </div>
      <div className="col-md-6 align-self-center py-4">
        <Link to="/dashboard" className=" back-btn text-decoration-underline">
          {t("Back")}
        </Link>
      </div>
    </AccessControl>
  );
};

export default translate(ManageEmailTemplatesPage);
