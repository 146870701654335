import { t } from "components/CentralDataMangement/translation/Translation";
import Title from "components/common/atoms/Title";
import OfficeTargetTable from "../common/OfficeTargetTable";
import { CooAgreementDetails, OfficeTarget, ProspectCallDetails, StarterDetails, StopperDetails, User, VacancyDetails, VisitsDetails, WeeklyReportsData } from "../Annotations";
import { useEffect, useMemo, useState } from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import WeeklyReportTable from "../common/WeeklyReportTable";
import VacanciesTable from "../common/VacanciesTable";
import VisitsTable from "../common/VisitsTable";
import ProspectCallsTable from "../common/ProspectCallsTable";
import CooAgreementTable from "../common/CooAgreementTable";
import TableTitle from "components/common/atoms/TableTitle";
import StartersTable from "../common/StartersTable";
import StoppersTable from "../common/StoppersTable";
import LabelWithTextAreaField from "components/common/molecules/LabelWithTextAreaField";
import secureLocalStorage from "react-secure-storage";
import EncryptDecryptService from "components/common/services/EncryptDecryptService";
import CustomNotify from "components/common/atoms/CustomNotify";
import { ApiCall } from "components/common/services/ApiServices";
import { EDIT_WEEKLY_REPORTS, UPDATE_WEEKLY_REPORTS } from "../../../../routes/ApiEndpoints";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "Constants";
import { Link, useNavigate } from "react-router-dom";
import Button from "components/common/atoms/Button";
import LoadingIcon from "components/common/utlis/LoadingIcon";

interface TargetData {
  officeQTarget: OfficeTarget;
  officeTarget: OfficeTarget;
}

interface ReportsData {
  primaryPeriodId: number | string | null;
  weeklyReports: WeeklyReportsData;
  vacancies: VacancyDetails[];
  visits: VisitsDetails[];
  cooAgreement: CooAgreementDetails[];
  prospectCalls: ProspectCallDetails[];
}

interface WeeklyData {
  created_by: number | string;
  reports: WeeklyReportsData;
}

interface RecruitersData {
  recruiters: User[];
  recruitersData: WeeklyData[];
  starters: StarterDetails[];
  stoppers: StopperDetails[];
}

interface Analysis {
  challenges: string;
  dubi_management: string;
  office_management: string;
}

const WeeklyReports: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [targetData, setTargetData] = useState<TargetData>();
  const [reports, setReports] = useState<ReportsData>({
    primaryPeriodId: null,
    weeklyReports: {},
    vacancies: [],
    visits: [],
    prospectCalls: [],
    cooAgreement: []
  });
  const [recruiters, setRecruiters] = useState<RecruitersData>({
    recruiters: [],
    recruitersData: [],
    starters: [],
    stoppers: []
  });
  const [currentRecData, setCurrentRecData] = useState<WeeklyData>();
  const [analysisData, setAnalysisData] = useState<Analysis>({
    challenges: "",
    dubi_management: "",
    office_management: "",
  });

  let data = secureLocalStorage.getItem('omWeeklyReport') as string; // Get the 'data' query param as a string

  useEffect(() => {

    const timeout = setTimeout(() => {
      fetchData();
    }, 3000); // 3 seconds

    return () => clearTimeout(timeout); // Cleanup the timeout on component unmount
  }, []);

  const fetchData = async () => {
    let parsedData = {};
    try {
      if (data) {
        // Decrypt the recruiterData
        let decryptedData = EncryptDecryptService.decryptData(data);

        // If decryptedData is in JSON format, parse it
        parsedData = JSON.parse(decryptedData);
      }

    } catch (error) {
      CustomNotify({
        type: "error",
        message: t("Something went wrong"),
      });
      return null;
    }

    const response = await ApiCall.service(
      EDIT_WEEKLY_REPORTS,
      "POST",
      { ...parsedData, type: "officeManager" },
      false,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );

    if (response.status === 200) {
      setTargetData(response.data?.targets);
      setReports({ primaryPeriodId: response.data?.primaryPeriodId, weeklyReports: response.data?.weeklyReports, vacancies: response.data?.vacancies, visits: response.data?.visits, prospectCalls: response.data?.prospectCalls, cooAgreement: response.data?.coopAgreements });
      setRecruiters(response.data?.recruiterData);
      setCurrentRecData(response.data?.recruiterData?.recruitersData?.[0]);
      setAnalysisData((prevData) => ({ ...prevData, ...response.data?.analysis }));
    }
    setLoading(false);
  }

  const rows = useMemo(() => [
    { key: 'visits', label: 'Visits' },
    { key: 'vacancies', label: 'Vacancies' },
    { key: 'prospect_calls', label: 'Prospect calls' },
  ], []);

  const setCurrentRecruiter = (id: number | string) => {
    const recruiter = recruiters.recruitersData?.find(data => data.created_by === id);
    setCurrentRecData(recruiter);
  }

  const getStarters = (id: number | string) => {
    return recruiters.starters.filter(data => data.created_by === id);
  }

  const getStoppers = (id: number | string) => {
    return recruiters.stoppers.filter(data => data.created_by === id);
  }

  const getRecName = useMemo(() => (recruiterId: number | string) => {
    const recruiter = recruiters.recruiters.find(r => r.id === recruiterId);
    return recruiter?.name ?? "";
  }, [recruiters.recruiters]);

  const recruiterRows = [{ key: 'calls', label: 'Calls' },
  { key: 'screening', label: 'Screenings' },
  { key: 'presentation', label: 'Presentation' },
  { key: 'interview', label: 'Interview' },
  { key: 'starter', label: 'Starter' },
  { key: 'stopper', label: 'Stopper' }];

  const handleAnalysisFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target as HTMLInputElement;

    setAnalysisData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    const payload = {
      type: "officeManager",
      primaryPeriodId: reports.primaryPeriodId ?? null,
      analysis: analysisData
    };

    const url = UPDATE_WEEKLY_REPORTS;
    const response = await ApiCall.service(
      url,
      "POST",
      payload,
      false,
      "central-data-management"
    );
    if (response.status === 200) {
      CustomNotify({ type: "success", message: response.msg });
      navigate("/manage-reports");
    } else {
      CustomNotify({ type: "error", message: t("Something went wrong") })
    }
    setLoading(false);
  };

  return (
    <>
      <Title title={t("Reporting (weekly office manager)")} />
      {loading ? (
        <div style={{ height: "38vw" }} className="d-flex align-items-center justify-content-center">
          <LoadingIcon
            iconType="bars"
            color="#e55496"
            className="m-auto"
            height={"5vw"}
            width={"5vw"}
          />
        </div>

      ) : (
        <>
          <div className="row py-3">
            <div className="col-md-3 mb-3">
              <OfficeTargetTable
                data={targetData?.officeQTarget}
                tableTitle="Office Q"
              />
            </div>
            <div className="col-md-3 mb-3">
              <div className="position-relative">
                <div
                  className={`circular-progress-bar-custom recruitment-card-title `}
                  data-label={`€ ${targetData?.officeTarget.reached ?? 0}`}
                >
                  <div>
                    <CircularProgressbarWithChildren
                      value={targetData?.officeTarget?.percentage ?? 0}
                      className="CircularProgressbarWithChildren"
                    >
                      <div className="text-center CircularProgressbar-text text-uppercase">
                        {t("Goal")}
                        <br />
                        <div>{`€ ${targetData?.officeTarget.target ?? 0}`}</div>
                      </div>
                    </CircularProgressbarWithChildren>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <WeeklyReportTable data={reports.weeklyReports} rows={rows} />
            </div>
            <div className="col-md-6">
              <VacanciesTable data={reports.vacancies} tableTitle="VACANCIES" />
            </div>
            <div className="col-md-6">
              <VisitsTable data={reports.visits} tableTitle="VISITS" />
            </div>
            <div className="col-md-6">
              <CooAgreementTable
                data={reports.cooAgreement}
                tableTitle="COOPERATION AGREEMENT"
              />
            </div>
            <div className="col-md-6">
              <ProspectCallsTable
                data={reports.prospectCalls}
                tableTitle="PROSPECT CALLS"
              />
            </div>
          </div>
          <div className="row pb-5">
            <div className="col-md-2 ">
              <table className="table mb-0 table-bordered position-sticky OMRecriterTableSticky">
                <thead>
                  <tr className="align-middle border-bottom TableHeader">
                    <th className="text-start ps-3">{t("Recruiters")}</th>
                  </tr>
                </thead>
                <tbody>
                  {recruiters.recruiters.map((recruiter: User, index) => (
                    <tr
                      onClick={(e) => {
                        setCurrentRecruiter(recruiter.id);
                      }}
                      key={index}
                    >
                      <td
                        className={`cursor-pointer ${recruiter.id === currentRecData?.created_by ? "activeRecruiter" : ""}`}
                      >
                        {recruiter.name}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="col-md-10">
              {currentRecData ? (
                <>
                  <TableTitle title={getRecName(currentRecData?.created_by ?? 0)} />
                  <WeeklyReportTable
                    data={currentRecData?.reports as WeeklyReportsData}
                    rows={recruiterRows}
                  />
                  <StartersTable
                    data={getStarters(currentRecData?.created_by ?? 0)}
                    view="om"
                    tableTitle="STARTERS"
                  />
                  <StoppersTable
                    data={getStoppers(currentRecData?.created_by ?? 0)}
                    view="om"
                    tableTitle="STOPPER"
                  />
                </>
              ) : (
                <TableTitle
                  title={t("Please select recruiter")}
                  tableTitleClassName="border py-2 text-center tableTitle TableHeader text-danger"
                />
              )}
            </div>
          </div>
          <div className="row">
            <Title title={t("Analysis")} />
            <div className="col-md-4">
              <LabelWithTextAreaField
                name="challenges"
                handleChange={handleAnalysisFieldChange}
                label={t("Commercial or administrative challenges with customers")}
                value={analysisData.challenges}
              />
            </div>
            <div className="col-md-4">
              <LabelWithTextAreaField
                name="dubi_management"
                handleChange={handleAnalysisFieldChange}
                label={t("Dubi management")}
                value={analysisData.dubi_management}
              />
            </div>
            <div className="col-md-4">
              <LabelWithTextAreaField
                name="office_management"
                handleChange={handleAnalysisFieldChange}
                label={t("Office management (holidays/ADV/illness)")}
                value={analysisData.office_management}
              />
            </div>
            <div className="row py-4">
              <div className="col-md-4 align-self-center">
                <Link
                  to="/manage-reports"
                  className=" back-btn text-decoration-underline"
                >
                  {t("Back")}
                </Link>
              </div>
              {/* <AccessControl
                requiredPermissions={[
                  {
                    permission: "Daily turnover",
                    create: true,
                    update: true,
                  },
                ]}
                actions={true}
                strict={false}
              > */}
              <div className="col-md-8">
                {!loading ? (
                  <Button
                    title={t("Save")}
                    type="submit"
                    className="form-button float-end px-3 shadow-none"
                    handleClick={(e) => handleSubmit(e)}
                  />
                ) : (
                  <LoadingIcon
                    iconType="bars"
                    color="#ff4dae"
                    className="float-end"
                    width={"3vw"}
                    height={"3vw"}
                  />
                )}
              </div>
              {/* </AccessControl> */}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default WeeklyReports;
