import {
  validateMultiSelectField,
  validateRadioField,
  validateRequired,
  validateSelectField,
} from "components/common/services/ValidationService";
import * as CONST from "../../annotations/AdminFicheConstants";

interface ChildProps {
  state: any;
  dispatch: any;
  submitType: string;
}
const ValidateData = ({ state, dispatch, submitType }: ChildProps) => {
  const activeTab = state.tabDetails.find((tab: any) => tab.showStatus);
  const activeTabId = activeTab?.id;

  const updateTabsStatus = (errors: object) => {
    const keysToGet = [CONST.GENERAL, CONST.SHIFT_TABLES, CONST.WAGE];
    const selectKeys = keysToGet
      .map((key) => {
        if (errors.hasOwnProperty(key)) {
          const tabIndex = findTabObjectById(key);
          if (tabIndex !== undefined && tabIndex !== null) {
            dispatch({
              type: CONST.UPDATE_TAB_ERROR,
              tabIndex,
              error: true,
            });
          }
          return tabIndex;
        }
        return null;
      })
      .filter((key) => key !== null);
    const tabIndexes = [0, 1];
    tabIndexes.forEach((index) => {
      if (!selectKeys.includes(index)) {
        dispatch({
          type: CONST.UPDATE_TAB_ERROR,
          tabIndex: index,
          error: false,
        });
      }
    });
  };

  const findTabObjectById = (idToFind: string) => {
    return state.tabDetails.findIndex((tab: any) => tab.id === idToFind);
  };

  const mandatoryFieldMapping: { [key: string]: string[] } =
    submitType === "draft"
      ? {
          general: ["syndicatedDelig", "vca"],
          shiftTables: ["name", "regime", "shift", "pc"],
        }
      : {
          general: ["syndicatedDelig", "vca", "nssoCatg", "catchUpRest", "leaves", "explicitRegime", "isKBApplicable", "exemptionBV"],
          shiftTables: ["name", "regime", "shift", "pc"],
        };

  const errorsByTab: {
    [tabId: string]: { [fieldName: string]: string };
  } = {};
  state.tabDetails
    .slice(
      0,
      state.tabDetails.findIndex((tab: any) => tab.id === activeTabId) + 1
    )
    .forEach((tab: any) => {
      const tabId = tab.id;
      let tabFields = {};

      switch (tabId) {
        case CONST.GENERAL:
          tabFields = state.general;
          break;
        case CONST.SHIFT_TABLES:
          tabFields = state.shiftTables;
          break;
        case CONST.WAGE:
          tabFields = state.wage;
          break;
        default:
          break;
      }
      const isPC124 = state.general.paritairBlue.some((pc: any) => pc.value === 39);
      const validationRules: {
        [fieldName: string]: ((value: any) => string | null | undefined)[];
      } = {
        syndicatedDelig: [validateRadioField],
        vca: [validateSelectField],
        nssoCatg:  isPC124 ? [validateMultiSelectField] : [],
        catchUpRest: [validateRadioField],
        leaves: [validateRadioField],
        name: [validateRequired],
        regime: [validateSelectField],
        shift: [validateSelectField],
        pc: [validateSelectField],
        explicitRegime: [validateRadioField],
        exemptionBV: state.general.explicitRegime === 1 ? [validateMultiSelectField] : [],
        isKBApplicable: isPC124 ? [validateRadioField] : [],
      };

      const mandatoryFields = (mandatoryFieldMapping[tabId] || []) as string[];

      const errorsInTab: { [fieldName: string]: string } = {};
      for (const fieldName of mandatoryFields) {
        const fieldValidationRules = validationRules[fieldName];

        if (tabId === CONST.SHIFT_TABLES && state.shiftTables.length > 0) {
          state.shiftTables.forEach((table: any, index: number) => {
            const fieldValue = table[fieldName as keyof typeof table];
            const tableErrors: string[] = [];

            if (fieldValidationRules) {
              for (const rule of fieldValidationRules) {
                const validationError = rule(fieldValue as any);

                if (validationError) {
                  tableErrors.push(validationError);
                  errorsInTab[fieldName] = validationError;
                  dispatch({
                    type: CONST.UPDATE_SHIFT_DATA,
                    field: fieldName + "Error",
                    value: validationError,
                    tableId: index,
                  });
                  break;
                } else {
                  dispatch({
                    type: CONST.UPDATE_SHIFT_DATA,
                    field: fieldName + "Error",
                    value: "",
                    tableId: index,
                  });
                }
              }
            }
          });
        } else if(tabId === CONST.GENERAL) {
          const fieldValue = tabFields[fieldName as keyof typeof tabFields];
          if (fieldValidationRules) {
            for (const rule of fieldValidationRules) {
              const validationError = rule(fieldValue);
              if (validationError) {
                errorsInTab[fieldName] = validationError;
                dispatch({
                  type: CONST.UPDATE_FIELD_ERROR,
                  field: fieldName,
                  error: validationError,
                });
                break;
              } else {
                dispatch({
                  type: CONST.UPDATE_FIELD_ERROR,
                  field: fieldName,
                  error: "",
                });
              }
            }
          }
        }
      }

      if (Object.keys(errorsInTab).length > 0) {
        errorsByTab[tabId] = errorsInTab;
        return false;
      }
    });
  updateTabsStatus(errorsByTab);
  if (Object.keys(errorsByTab).length > 0) {
    return false;
  }

  return true;
};

export default ValidateData;
