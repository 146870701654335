import React from 'react'

const Candidate = () => {
  return (
    <>
      <svg width="30" height="40" viewBox="0 0 50 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M34.5741 15.9937C38.5179 15.9937 41.9107 12.4723 41.9107 7.88661C41.9107 3.35625 38.5 0 34.5741 0C30.6491 0 27.2375 3.42946 27.2375 7.92321C27.2375 12.4723 30.6304 15.9937 34.5741 15.9937ZM13.4625 16.4161C16.8741 16.4161 19.8464 13.3339 19.8464 9.35357C19.8464 5.41071 16.8554 2.49375 13.4625 2.49375C10.0518 2.49375 7.04286 5.48393 7.06161 9.39107C7.06161 13.3339 10.033 16.4161 13.4634 16.4161M3.22857 32.9786H17.1679C15.2607 30.2089 17.5902 24.633 21.5339 21.5884C19.4982 20.2312 16.8741 19.2223 13.4446 19.2223C5.17232 19.2214 0 25.3286 0 30.4098C0 32.0616 0.916964 32.9786 3.22857 32.9786ZM23.0375 32.9786H46.0929C48.9723 32.9786 50 32.1527 50 30.5384C50 25.8063 44.075 19.2768 34.5562 19.2768C25.0554 19.2768 19.1304 25.8063 19.1304 30.5393C19.1304 32.1527 20.1571 32.9786 23.0375 32.9786Z" fill="currentColor" />
      </svg>

    </>
  )
}

export default Candidate