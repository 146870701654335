import React, { useRef } from "react";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import { Button } from "primereact/button"; // Use PrimeReact button for the clear icon
import LabelField from "./LabelField";
import { t } from "components/CentralDataMangement/translation/Translation";

interface Option {
  value?: number | string | null;
  label?: string;
}

interface MultiSelectWithSearchProps {
  options: Option[];
  value: number[];
  onChange: (value: any) => void;
  placeHolder?: string;
  name: string;
  id?: string;
  error?: number | null | undefined | string;
  className?: string;
  title?: string;
  isMandatory?: boolean;
  isDisabled?: boolean;
  isTranslate?: boolean;
}

const MultiSelectAtom: React.FC<MultiSelectWithSearchProps> = ({
  options,
  value = [],
  onChange,
  placeHolder,
  name,
  id,
  error,
  className,
  title,
  isMandatory,
  isDisabled = false,
  isTranslate = false,
}) => {
  const defaultPlaceholder = placeHolder || t("Select");

  const multiSelectRef = useRef<MultiSelect>(null); // Explicitly set the ref type for MultiSelect

  // Translate options if isTranslate is true
  const translatedOptions = isTranslate
    ? options.map((option) => ({
        ...option,
        label: t(option.label || ""),
      }))
    : options;

  // Function to clear selections
  const clearSelections = () => {
    onChange([]); // Clear selected values
    if (multiSelectRef.current) {
      multiSelectRef.current.hide(); // Close the MultiSelect popup
    }
  };

  // Custom header template for the MultiSelect panel
  const panelHeaderTemplate = (options: any) => (
    <div className="multi-select-panel-header py-2">
      <div className="d-flex align-items-center">
        <div style={{ width: "15%", paddingLeft: "0.5vw" }}>
          {options.checkboxElement}
        </div>
        <div style={{ width: "70%" }}>{options.filterElement}</div>
        <div style={{ width: "15%" }} className="text-center">
          <Button
            icon="pi pi-times"
            onClick={clearSelections}
            className="clear-selection-button bg-transparent border-0 shadow-none justify-content-center"
            aria-label={t("Clear Selection")}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div
      className={`multi-select-with-search ${className}`}
      style={{ position: "relative" }}
    >
      <LabelField title={title} isMandatory={isMandatory} />
      <div
        className="multi-select-container"
        style={{ display: "flex", alignItems: "center", position: "relative" }}
      >
        <MultiSelect
          id={id}
          value={value !== null ? value : []}
          onChange={(e: MultiSelectChangeEvent) => onChange(e.value)}
          options={translatedOptions}
          optionLabel="label"
          placeholder={defaultPlaceholder}
          className="form-control form-select field-shadow primeSelect p-0"
          filter
          disabled={isDisabled}
          name={name}
          maxSelectedLabels={2}
          panelHeaderTemplate={panelHeaderTemplate} // Set custom panel header
          ref={multiSelectRef} // Attach the ref to MultiSelect
        />
      </div>
      <div className="height-20" style={{ marginBottom: "0.5vw" }}>
        {error && (
          <div className="text-danger">{error !== undefined ? error : ""}</div>
        )}
      </div>
    </div>
  );
};

export default MultiSelectAtom;
