import { OptionProps } from "components/common/utlis/TypeAnnotations";

interface dropDownList {
    offices: OptionProps[];
    roles: OptionProps[];
    status: OptionProps[];
}

export const createFilterConfig = (dropDownList: dropDownList) => {
    return [
        {
            name: "office",
            options: dropDownList.offices,
            fieldType: "multiSelect",
            placeholder: "Office",
            filterType: 'search',
            label: 'Office'
        },
        {
            name: "firstName",
            fieldType: "text",
            placeholder: "First name",
            filterType: 'search'
        },
        {
            name: "lastName",
            fieldType: "text",
            placeholder: "Last name",
            filterType: 'search'
        },
        {
            name: "email",
            fieldType: "text",
            placeholder: "Email",
            filterType: 'search'
        },
        {
            name: "role",
            options: dropDownList.roles,
            fieldType: "singleSelect",
            placeholder: "Role",
            filterType: 'search'
        },
        {
            name: "status",
            options: dropDownList.status,
            fieldType: "singleSelect",
            placeholder: "Status",
            filterType: 'search',
        },
        {
            name: "from",
            fieldType: "date",
            placeholder: "From",
            filterType: 'search',
            group: 'createdAt'
        },
        {
            name: "to",
            fieldType: "date",
            placeholder: "To",
            filterType: 'search',
            group: 'createdAt'
        },
    ];
};
