import {
  GET_APP_LANG,
  GET_PAGE_CONTENT,
  STORE_PAGE_CONTENT,
} from "routes/ApiEndpoints";
import { t } from "components/CentralDataMangement/translation/Translation";
import LabelWithCKEditorField from "components/common/molecules/LabelWithCKEditorField";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import { ApiCall } from "components/common/services/ApiServices";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Title from "components/common/atoms/Title";
import { ValidationRules } from "components/common/utlis/TypeAnnotations";
import {
  validateForm,
  validateRequired,
} from "components/common/services/ValidationService";
import CustomNotify from "components/common/atoms/CustomNotify";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import Button from "components/common/atoms/Button";

interface PageContentProps {
  title?: string;
  body?: string;
  lang?: string;
  name?: string;
  url?: string;
}

interface Lang {
  key: string;
  name: string;
}

const PageContents = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const langParam = queryParams.get("lang");
  const [formData, setFormData] = useState<PageContentProps>({
    //formData with intial values
    title: "",
    body: "",
    lang: langParam ?? "en-source",
    url: "",
  });
  const [errors, setErrors] = useState<PageContentProps>({});
  const validation = (
    name: string,
    value: string | boolean | undefined,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      name: [validateRequired],
      title: [validateRequired],
      body: [validateRequired],
    };

    const validationErrors = validateForm(
      { ...formData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation && Object.keys(errors).length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }

    return true;
  };

  const handleChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = event.target as HTMLInputElement;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    validation(name, value, true);
  };

  const handleCKEditorChange = (event: any, editor: any, fieldName: string) => {
    const content = editor.getData(); // Get the content from the editor
    const name = fieldName; // Use the fieldName parameter
    setFormData((prevData) => ({ ...prevData, [name]: content }));
    validation(name, content, true);
  };
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (
      validation("name", formData.name) &&
      validation("body", formData.body) &&
      validation("title", formData.title)
    ) {
      const data = { ...formData, id: id };
      const response = await ApiCall.service(
        STORE_PAGE_CONTENT,
        "POST",
        data,
        false,
        "central-data-management"
      ); //api call to store formdata
      if (response.status === 200) {
        CustomNotify({ type: "success", message: response.msg });
        navigate("/manage/page-contents");
      } else if (response.status === 400) {
        if (response?.errors?.unique_name) {
          CustomNotify({
            type: "error",
            message: t(response?.errors?.unique_name),
          });
        }
      }
    }
  };
  const [selectedLang, setSelectedLang] = useState(
    formData.lang || "en-source"
  ); // Initialize with default language or formData's language

  const [langs, setLangs] = useState<Lang[]>([]); // Annotate the type of langs as an array of Lang objects

  const languageLinks = langs.map((lang) => (
    <Link
      key={lang.key}
      to={{
        pathname: `/page-contents/${id}`,
        search: `?lang=${lang.key}`,
      }}
      target="_blank"
      className={`dropdown-item ${selectedLang === lang.key ? "active" : ""}`}
      onClick={() => setSelectedLang(lang.key)}
    >
      {lang.name.toUpperCase()}
    </Link>
  ));

  useEffect(() => {
    if (id) {
      const fetchFormData = async () => {
        const data = {
          id: id,
          language_key:
            langParam !== null && langParam !== "" && langParam !== undefined
              ? langParam
              : "en-source",
        };

        const langResponse = await ApiCall.getService(
          `${GET_APP_LANG}/absolute_jobs`,
          "GET",
          "central-data-management"
        );
        const keysArray = langResponse.app_langs.map((lang: any) => ({
          key: lang.key,
          name: lang.name,
        }));
        setLangs(keysArray);

        const response = await ApiCall.service(
          GET_PAGE_CONTENT,
          "POST",
          data,
          false,
          "central-data-management"
        );
        if (response.status === 200) {
          if (response?.data) {
            setFormData((prev) => ({
              ...prev,
              body: response.data?.body,
              title: response.data?.title,
              name: response.data?.name,
              url: response.data?.url,
            }));
          }
        }
      };

      fetchFormData();
    }
  }, [id, langParam]);

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Page contents",
          create: true,
        },
      ]}
      renderNoAccess={true}
    >
      <div>
        <Title title={id ? t("Edit page content") : t("Create page content")} />
        <div className="row paddingBottom1 emailTemplateLanguage">
          <div className="col-md-12 text-end">
            <div className="dropdown">
              <span className="marginRightPoint5">{t("Select language")}</span>
              <button
                className="btn dropdown-toggle rounded-0 shadow-none"
                type="button"
                id="languageDropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {selectedLang.toUpperCase()}
              </button>
              <div className="dropdown-menu" aria-labelledby="languageDropdown">
                {languageLinks}
              </div>
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-12">
              <div className="form-border">
                <div className="row">
                  <div className="col-md-6">
                    <LabelWithInputField
                      isMandatory={true}
                      name="name"
                      handleChange={handleChange}
                      value={formData.name}
                      id="name"
                      label={t("Name")}
                      placeholder={t("Name")}
                      type="text"
                      error={errors.name}
                      isDisabled={id ? true : false}
                    />
                  </div>
                  <div className="col-md-6">
                    <LabelWithInputField
                      isMandatory={true}
                      name="title"
                      handleChange={handleChange}
                      value={formData.title}
                      id="title"
                      label={t("Title")}
                      placeholder={t("Title")}
                      type="text"
                      error={errors.title}
                    />
                  </div>
                  <div className="col-md-6">
                    <LabelWithInputField
                      isMandatory={false}
                      name="url"
                      handleChange={handleChange}
                      value={formData.url}
                      id="url"
                      label={t("Page url")}
                      placeholder={t("Page url")}
                      type="text"
                      error={errors.url}
                    />
                  </div>
                  <div className="col-md-12">
                    <LabelWithCKEditorField
                      label={t("Body")}
                      name="body"
                      value={formData.body}
                      placeholder={t("Type here") + "..."}
                      handleChange={(event, editor) =>
                        handleCKEditorChange(event, editor, "body")
                      }
                      isMandatory={true}
                      error={errors.body}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row backPadding">
            <div className="col-md-6 align-self-center">
              <Link
                to="/manage/page-contents"
                className="back-btn text-decoration-underline"
              >
                {t("Back")}
              </Link>
            </div>
            <div className="col-md-6 text-end">
              <Button type="submit" className="form-button shadow-none">
                {t("Save")}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </AccessControl>
  );
};

export default PageContents;
