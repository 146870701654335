import React, { useEffect, useState } from "react";
import { useFormContext } from "../../context/Context";
import { useLocation, useParams } from "react-router-dom";
import { ApiCall } from "components/common/services/ApiServices";
import Title from "components/common/atoms/Title";
import { t, translate } from "components/CentralDataMangement/translation/Translation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleExclamation,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { VACANCY_DETAILS } from "routes/ApiEndpoints";
import {
  COMPETENCES,
  EMPLOYMENT,
  GENERAL,
  JOB_DESCRIPTION,
  OFFER,
  REQUIREMENT,
  SET_OPTIONS,
  UPDATE_CHILD_COMPETENCES_DATA,
  UPDATE_COMPETENCES,
  UPDATE_FIELDS,
  UPDATE_TAB_DETAILS,
} from "../../annotation/VacancyConstants";
import { mapToSelect } from "components/common/utlis/MapToSelect";
import { Option } from "components/common/services/ValidationService";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import VacancyGeneralDetails from "./VacancyGeneralDetails";
import VacancyCompetences from "./VacancyCompetences";
import VacancyOfferDetails from "./VacancyOfferDetails";
import VacancyEmplomentDetails from "./VacancyEmplomentDetails";
import VacancyJobDescriptionDetails from "./VacancyJobDescriptionDetails";
import VacancyRequirementDetails from "./VacancyRequirementDetails";
import { useTabUrlUpdater } from "components/common/utlis/TabUtility";
import { FormatPCList } from "components/common/utlis/FormatPCList";

const VacancyForm = () => {
  const { state, dispatch } = useFormContext();
  const [isSticky, setIsSticky] = useState(false);
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const isClone = location.pathname.includes("clone");

  useEffect(() => {
    const fetchVacancyDetails = async () => {
      const data = {
        type: "company",
      };
      const url = id ? `${VACANCY_DETAILS}/${id}` : `${VACANCY_DETAILS}`;
      const response = await ApiCall.service(
        url,
        "POST",
        data,
        true,
        "central-data-management"
      );
      if (response.status === 200) {
        const data = response.data.dropDownDetails;
        const dropdownOptions = {
          experienceOptions: mapToSelect(data?.experienceOptions) || [],
          driverLicOptions: mapToSelect(data?.driverLicOptions) || [],
          educationOptions: mapToSelect(data?.educationOptions) || [],
          additionalOptions: mapToSelect(data?.additionalOptions) || [],
          sectorOptions: mapToSelect(data?.sectorOptions) || [],
          employeeTypeOptions:
            mapToSelect(data?.employeeTypeOptions, "employeetype_name") || [],
          contractOptions: mapToSelect(data?.contractOptions) || [],
          employmentOptions: mapToSelect(data?.employmentOptions) || [],
          regimeOptions: mapToSelect(data?.regimeOptions) || [],
          shiftOptions: mapToSelect(data?.shiftOptions) || [],
          payTypeOptions: mapToSelect(data?.payTypeOptions) || [],
          languageOptions: mapToSelect(data?.languageOptions) || [],
          reasonForClosureOptions:
            mapToSelect(data?.reasonForClosureOptions) || [],
          consultantOptions: mapToSelect(data?.consultantOptions) || [],
          cityOptions: mapToSelect(data?.cityOptions) || [],
          countryOptions: mapToSelect(data?.countryOptions) || [],
          domainOptions: mapToSelect(data?.domainOptions, "domain_name"),
          functionOptions: mapToSelect(data?.functionOptions, "function_name"),
          vdabTemplateOptions: mapToSelect(data?.vdabTemplateOptions) || [],
          vdabTemplateList: data?.vdabTemplateOptions || [],
          vdabCompetencesOptions: mapToSelect(data?.vdabCompetencesOptions) || [],
          officeOptions: mapToSelect(data?.officeOptions || []),
          // functionOptions: data?.functionOptions,
          // functionDomainLinking: data?.functionDomainLinking,
        };
        //set dropdown options
        dispatch({ type: SET_OPTIONS, payload: dropdownOptions });

        //For setting competences
        dispatch({
          type: UPDATE_COMPETENCES,
          payload: response.data.competenceDetails,
        });
        dispatch({ type: UPDATE_CHILD_COMPETENCES_DATA });

        if (
          response.data.generalDetails &&
          response.data.generalDetails.companyRelatedDetails
        ) {
          const data = response.data.generalDetails.companyRelatedDetails;
          const companyDropdownOptions = {
            locationOptions: mapToSelect(data?.locationOptions) || [],
            pcOptions: FormatPCList(data?.pcOptions) || [],
            pc: data?.pcOptions,
            officeOptions: response?.data?.generalDetails?.officeOptions ? mapToSelect(response.data.generalDetails.officeOptions || []) : [],
            contactPersonOptions: response?.data?.generalDetails?.contactPersonOptions ? mapToSelect(response.data.generalDetails.contactPersonOptions) : []
          };
          dispatch({
            type: SET_OPTIONS,
            payload: companyDropdownOptions,
          });
        }
        settingFormDetails(response);
      }
    };

    fetchVacancyDetails();

    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 50) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const settingFormDetails = (response: any) => {
    const {
      generalDetails,
      employmentDetails,
      offerDetails,
      requirementDetails,
      jobDescDetails,
      competenceDetails,
    } = response.data;

    const fieldMappings = [
      {
        fieldPrefix: GENERAL,
        data: generalDetails.general,
      },
      {
        fieldPrefix: EMPLOYMENT,
        data: employmentDetails,
      },
      {
        fieldPrefix: OFFER,
        data: offerDetails,
      },
      {
        fieldPrefix: REQUIREMENT,
        data: requirementDetails,
      },
      {
        fieldPrefix: JOB_DESCRIPTION,
        data: jobDescDetails,
      },
      {
        fieldPrefix: COMPETENCES,
        data: competenceDetails,
      },
    ];

    type Value = string | number | Option | object[] | null;

    fieldMappings.forEach(({ fieldPrefix, data }) => {
      if (data) {
        Object.entries(data).forEach(([field, value]) => {
          dispatch({
            type: UPDATE_FIELDS,
            template: fieldPrefix,
            field: field as string,
            value: value as Value,
          });
          if (isClone && fieldPrefix === GENERAL) {
            dispatch({
              type: UPDATE_FIELDS,
              template: fieldPrefix,
              field: "aliasName",
              value: '',
            });
          }

          if (fieldPrefix === OFFER) {
            if (data.starterWage !== "") {
              dispatch({
                type: UPDATE_FIELDS,
                template: fieldPrefix,
                field: "starter",
                value: 1,
              });
            }
            if (data.experiencedWage !== "") {
              dispatch({
                type: UPDATE_FIELDS,
                template: fieldPrefix,
                field: "experienced",
                value: 1,
              });
            }
          }
        });
      }
    });
  };

  useTabUrlUpdater(state.tabDetails, dispatch); //To add tabId to url and stay on current tab on refresh

  //For handling the tab navigation
  const handleTabClick = (tabId: string) => {
    const currentTab = state.tabDetails.findIndex(
      (tab: any) => tabId === tab.id
    );
    state.tabDetails[currentTab].error = false;
    const updatedTabDetails = state.tabDetails.map((tab) => ({
      ...tab,
      showStatus: tab.id === tabId,
    }));

    dispatch({ type: UPDATE_TAB_DETAILS, tabDetails: updatedTabDetails });
  };

  const ConstructTabs = (id: string, showStatus: boolean) => {
    switch (id) {
      case "general":
        return <VacancyGeneralDetails />;
      case "employment":
        return <VacancyEmplomentDetails />;
      case "competences":
        return <VacancyCompetences />;
      case "offer":
        return <VacancyOfferDetails />;
      case "requirement":
        return <VacancyRequirementDetails />;
      case "jobDescription":
        return <VacancyJobDescriptionDetails />;
      default:
        break;
    }
  };

  const permissionType = id ? "update" : "create";
  const permissionObject: any = {
    permission: "Vacancy",
  };
  permissionObject[permissionType] = true;
  return (
    <AccessControl
      requiredPermissions={[permissionObject]}
      renderNoAccess={true}
    >
      <Title  title={isClone ? t("Clone vacancy") : t("Vacancy")}  />
      {/* {state.tabDetails[0].error === true && (
        <div className="row">
          <div className="px-4 error-msg-tabs col-12">
            <div
              className="alert alert-danger alert-dismissible fade show"
              role="alert"
            >
              <span className="me-2">
                <FontAwesomeIcon icon={faCircleExclamation} />
              </span>
              {t("Please fill the mandatory fields to create a vacancy.")}
              <button
                type="button"
                className="btn-close shadow-none"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
          </div>
        </div>
      )} */}
      <div className="position-relative" style={{paddingTop:"2vw"}}>
        <nav
          className={`vacancy-step-tabs createFormTabs ${isSticky
            ? "createTabsSticky position-sticky"
            : "vacancy-step-tabs-absolute position-absolute w-100"
            }`}
        >
          <div
            className="nav nav-tabs border-0 col-xxl-10 m-auto col-xl-11 col-lg-10 col-md-6"
            id="nav-tab"
            role="tablist"
          >
            {state.tabDetails.map((item: any, index) => (
              <button
                key={item.id}
                className={`nav-link marginRightPoint5 text-break ${item.error ? "error" : ""
                  } ${item.showStatus ? "active" : "inactive"}`}
                id={`${item.id}-tab`}
                data-bs-toggle="tab"
                data-bs-target={`#${item.id}`}
                type="button"
                role="tab"
                aria-controls={`nav-${item.id}`}
                aria-selected={item.showStatus}
                onClick={() => handleTabClick(item.id)}
                style={{
                  borderRadius: "0.5vw",
                  marginRight: "0.5vw",
                  padding: "0.5vw",
                }}
              >
                <div className="candidate-step-number d-flex align-items-center justify-content-center">
                  {t(`${item.title}`)}

                  {item.error && (
                    <span
                      className="exclamation-icon ms-2"
                      title={`${t("Please fill all the mandatory fields")}`}
                      data-toggle="tooltip"
                    >
                      <FontAwesomeIcon icon={faTriangleExclamation} />
                    </span>
                  )}
                </div>
              </button>
            ))}
          </div>
        </nav>

        <div className="tab-content py-0" id="nav-tabContent">
          {state.tabDetails.map((item: any) => (
            <div
              key={item.id}
              className={`tab-pane fade ${item.showStatus ? "show" : ""} ${item.showStatus ? "active" : ""
                }`}
              id={item.id}
              role="tabpanel"
              aria-labelledby={`${item.id}-tab`}
            >
              {ConstructTabs(item.id, item.showStatus)}
            </div>
          ))}
        </div>
      </div>
    </AccessControl>
  );
};

export default translate(VacancyForm);
