import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import React, { useState } from "react";
import { useFormContext } from "../../context/Context";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import {
  EMPLOYMENT,
  JOB_DESCRIPTION,
  SET_OPTIONS,
  UPDATE_FIELDS,
  UPDATE_FIELD_ERROR,
} from "../../annotation/VacancyConstants";
import Navigation from "../form-navigation/Navigation";
import { ApiCall } from "components/common/services/ApiServices";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "Constants";
import { GET_COMPETENCES_BY_TEMPLATE_ID } from "routes/ApiEndpoints";
import { mapToSelect } from "components/common/utlis/MapToSelect";
import LabelWithCKEditorField from "components/common/molecules/LabelWithCKEditorField";
import {
  scrollToTop,
  validateForm,
  validateMultiSelectField,
  validateSelectField,
} from "components/common/services/ValidationService";
import {
  Option,
  ValidationRules,
} from "components/common/utlis/TypeAnnotations";

const VacancyEmplomentDetails = () => {
  const { state, dispatch } = useFormContext();
  const [validationStatus, setValidationStatus] = useState({
    isValid: false,
    type: "",
  });

  const validStatus = (validation: any) => {
    setValidationStatus({
      isValid: validation.isValid,
      type: validation.type,
    });
  };

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
    const newValue = value.replace(/[^0-9,]/g, ""); // Allow only numbers and commas
    if (
      (parseInt(newValue) >= 0 && parseInt(newValue) <= 300) ||
      newValue === ""
    ) {
      dispatch({
        type: UPDATE_FIELDS,
        template: EMPLOYMENT,
        field: name,
        value: newValue,
      });
    }
  };

  const getCompetencesByTemplateId = (templateId: any) => {
    //To prefill default office for selected consultant
    if (templateId) {
      const fetchCompetencesByTemplateId = async () => {
        const response = await ApiCall.getService(
          `${GET_COMPETENCES_BY_TEMPLATE_ID}/${templateId}`,
          "GET",
          CENTRAL_DATA_MANAGEMENT_MICROSERVICE
        );
        if (response.status === 200) {
          dispatch({
            type: SET_OPTIONS,
            payload: {
              vdabCompetencesOptions: mapToSelect(response.data) || [],
            },
          });
        }
      };
      fetchCompetencesByTemplateId();
    }
  };
  const handleSelectChange = (selectedOption: any, name: string) => {
    dispatch({
      type: UPDATE_FIELDS,
      template: EMPLOYMENT,
      field: "vdabCompetences",
      value: [],
    });
    dispatch({
      type: UPDATE_FIELDS,
      template: EMPLOYMENT,
      field: name,
      value: selectedOption,
    });
    if (name === "vdabTemplate" && selectedOption.value !== null) {
      const template = state.options.vdabTemplateList.find(
        (op) => op.id === selectedOption.value
      );
      if (template) {
        const templateId = template.template_id;
        getCompetencesByTemplateId(templateId);
      }
    }
    validation(name, selectedOption, true);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    scrollToTop();
  };

  const handleCKEditorChange = (event: any, editor: any, fieldName: string) => {
    const content = editor.getData(); // Get the content from the editor
    const name = fieldName; // Use the fieldName parameter
    dispatch({
      type: UPDATE_FIELDS,
      template: JOB_DESCRIPTION,
      field: name,
      value: content,
    });
  };

  const validation = (
    name: string,
    value:
      | string
      | boolean
      | Date
      | object[]
      | Option
      | null
      | undefined
      | number,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      vdabTemplate: [validateSelectField],
      vdabCompetences: [validateMultiSelectField],
    };

    const validationErrors = validateForm(
      { ...state.employment, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    dispatch({
      type: UPDATE_FIELD_ERROR,
      field: name,
      error: validationErrors[name],
    });

    const isFieldValid = Object.keys(validationErrors).length === 0;

    if (isFieldValid) {
      dispatch({ type: UPDATE_FIELD_ERROR, field: name, error: "" });
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12">
            <div className="form-border" style={{ paddingTop: "3.4vw" }}>
              <div className="row ">
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <SelectWithSearch
                    title={t("Contract type")}
                    name="contract"
                    placeHolder={t("Select")}
                    search={true}
                    options={state.options.contractOptions}
                    value={state.employment.contract}
                    onChange={(e) => handleSelectChange(e, "contract")}
                    isMulti={false}
                    className="select-field"
                    isTranslate={true}
                  />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <SelectWithSearch
                    title={t("Employment")}
                    name="employment"
                    placeHolder={t("Select")}
                    search={true}
                    options={state.options.employmentOptions}
                    value={state.employment.employment}
                    onChange={(e) => handleSelectChange(e, "employment")}
                    isMulti={false}
                    className="select-field"
                    isTranslate={true}
                  />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <LabelWithInputField
                    isMandatory={false}
                    name="hrs"
                    handleChange={handleFieldChange}
                    value={state.employment.hrs}
                    id="hrs"
                    label={t("Number of hours per week")}
                    type="text"
                    placeholder={t("Number of hours")}
                  />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <SelectWithSearch
                    title={t("Regime")}
                    name="regime"
                    placeHolder={t("Select")}
                    search={true}
                    options={state.options.regimeOptions}
                    value={state.employment.regime}
                    onChange={(e) => handleSelectChange(e, "regime")}
                    isMulti={false}
                    className="select-field"
                    isTranslate={true}
                  />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <SelectWithSearch
                    title={t("VDAB template")}
                    name="vdabTemplate"
                    placeHolder={t("Select")}
                    search={true}
                    options={state.options.vdabTemplateOptions}
                    value={state.employment.vdabTemplate}
                    onChange={(e) => handleSelectChange(e, "vdabTemplate")}
                    isMulti={false}
                    className="select-field"
                    isTranslate={true}
                    error={state.errors.vdabTemplate}
                    isMandatory={state.general.pushToVDAB === 1}
                  />
                </div>
                {state.employment.vdabTemplate &&
                  state.employment.vdabTemplate?.value !== null && (
                    <div className="col-sm-12 col-md-6 col-lg-4">
                      <SelectWithSearch
                        title={t("VDAB competences")}
                        name="vdabCompetences"
                        placeHolder={t("Select")}
                        search={true}
                        options={state.options.vdabCompetencesOptions}
                        value={state.employment.vdabCompetences}
                        onChange={(e) =>
                          handleSelectChange(e, "vdabCompetences")
                        }
                        isMulti={true}
                        className="select-field"
                        isTranslate={true}
                        error={state.errors.vdabCompetences}
                        isMandatory={state.general.pushToVDAB === 1}
                      />
                    </div>
                  )}
              </div>
              <div className="row hideHeight">
                <div className="col-md-12">
                  <LabelWithCKEditorField
                    label={t("Job description")}
                    name="description"
                    value={state.jobDescription.description}
                    placeholder={t("Type here") + "..."}
                    handleChange={(event, editor) =>
                      handleCKEditorChange(event, editor, "description")
                    }
                    className="field-shadow"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Navigation validStatus={validStatus} />
      </form>
    </>
  );
};

export default translate(VacancyEmplomentDetails);
