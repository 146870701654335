import { Dispatch } from "react";
import {
  validateForm,
  validateSelectField,
  Option,
  validateAttemptField,
  validateRequired,
  validateDate,
} from "components/common/services/ValidationService";
import { ValidationRules } from "components/common/utlis/TypeAnnotations";
import { DRAFT, UPDATE_FIELD_ERROR } from "../../annotation/PlanningConstants";

export const validateField = (
  name: string,
  value:
    | string
    | boolean
    | Date
    | object[]
    | Option
    | null
    | undefined
    | number,
  formData: { [key: string]: any }, // Dynamically pass in the state part to validate
  dispatch: Dispatch<any>,
  isSingleFieldValidation: boolean = false
): boolean => {
  // Define your validation rules here
  const validationRules: ValidationRules = {
    office: [validateSelectField],
    customer: [validateSelectField],
    branch: [validateSelectField],
    collegue: [validateSelectField],
    attempt: [validateAttemptField],
    function: [validateRequired],
    employeeType: [validateSelectField],
    reason: [validateSelectField],
    shift: [validateSelectField],
    from: [validateDate],
    to: [validateDate],
  };

  // Validate either a single field or the entire state part
  const validationErrors = validateForm(
    { ...formData, [name]: value },
    validationRules,
    isSingleFieldValidation ? name : undefined
  );

  // Dispatch validation result for the current field
  dispatch({
    type: UPDATE_FIELD_ERROR,
    field: name,
    error: validationErrors[name],
  });

  const isFieldValid = Object.keys(validationErrors).length === 0;

  // If valid, clear the error for this field
  if (isFieldValid) {
    dispatch({ type: UPDATE_FIELD_ERROR, field: name, error: "" });
  }

  // Return whether the field is valid
  return isFieldValid;
};

// Function to validate basicDetails fields independently
export const validateBasicDetails = (
  basicDetails: { [key: string]: any },
  dispatch: Dispatch<any>,
  type: string = DRAFT
): boolean => {
  let isValid = true;

  // Iterate over each field in basicDetails and validate
  for (const [name, value] of Object.entries(basicDetails)) {
    // Skip validation for 'attempt' if 'reason' is present and its value is not 4
    if (name === "attempt" && basicDetails["reason"]?.value !== 4) {
      dispatch({
        type: UPDATE_FIELD_ERROR,
        field: name,
        error: "",
      });
      continue; // Skip validation for 'attempt' when condition is met
    }
    // Exclude 'from' and 'to' fields from validation if type is DRAFT
    if (type === DRAFT && (name === "from" || name === "to")) {
      // Dispatch validation result for the current field
      dispatch({
        type: UPDATE_FIELD_ERROR,
        field: name,
        error: "",
      });
      continue; // Skip validation for 'from' and 'to' when type is DRAFT
    }
    // Call validateField for each field in basicDetails
    const isFieldValid = validateField(
      name,
      value,
      basicDetails,
      dispatch,
      true
    );
    // Update isValid if any field is invalid
    if (!isFieldValid) {
      isValid = false;
    }
  }

  return isValid;
};
