import LabelField from "components/common/atoms/LabelField";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import React from "react";
import PCTable from "./PCTable";
import { Option } from "components/common/utlis/TypeAnnotations";
import Button from "components/common/atoms/Button";
import { t, translate } from "components/CentralDataMangement/translation/Translation";

interface BlueCollarSectionProps {
  optionsList: any[];
  selectedValue: Option | null;
  selectedEmpType: Option | null;
  handlePCFields: (e: React.ChangeEvent, name: string) => void;
  state: any; // Add the state type
  addParitiarComitee: (type: string) => void;
  pcChangeHandler: (id: number, type: string) => void;
  pcDeleteHandler: (id: number, type: string) => void;
  employeeTypes: any[];
  isBlueCollar: boolean;
  isWhiteCollar: boolean;
}

const BlueCollarSection: React.FC<BlueCollarSectionProps> = ({
  optionsList,
  selectedValue,
  selectedEmpType,
  handlePCFields,
  state,
  addParitiarComitee,
  pcChangeHandler,
  pcDeleteHandler,
  employeeTypes,
  isBlueCollar,
  isWhiteCollar,
}) => {
  return (
    <div className={`${isBlueCollar && isWhiteCollar && state.general.type !== 2 ? " col-lg-6 col-12" : "col-xxl-12 col-12 mx-auto"}`}>
      <h5 className="mb-1">
        <LabelField
          title={t("Paritair committee for blue collar")}
          className="color-dark-purple tab-title"
        />
      </h5>
      <PCTable
        data={state.general.paritairBlue}
        pcChangeHandler={pcChangeHandler}
        type="blueCollar"
        pcDeleteHandler={pcDeleteHandler}
      />
      {state.fieldError.paritairBlue && (
        <div className="text-danger">{state.fieldError.paritairBlue}</div>
      )}
      <div className="row">
        <div className={`${isBlueCollar && isWhiteCollar && state.general.type !== 2 ? " col-12" : "col-xxl-6 col-12"}`}>
          <SelectWithSearch
            isMulti={false}
            isMandatory={true}
            search={true}
            options={optionsList}
            value={selectedValue}
            onChange={(e) => {
              handlePCFields(e, "PC2");
            }}
            name="PC2"
            title={t("Paritair committee (PC) 2")}
            error={state.fieldError.PC2}
            isTranslate={true}
          />
        </div>
        <div className={`${isBlueCollar && isWhiteCollar && state.general.type !== 2 ? " col-12" : "col-xxl-6 col-12"}`}>
          <SelectWithSearch
            isMulti={true}
            isMandatory={true}
            search={true}
            options={employeeTypes}
            value={selectedEmpType}
            onChange={(e) => {
              handlePCFields(e, "employeeType2");
            }}
            name="employeeType2"
            title={t("Selection of employee types (statuut) that can be used")}
            error={state.fieldError.employeeType2}
            isTranslate={true}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-md-12">
          <Button
            title={t("Add Paritair committee for blue collar")}
            handleClick={() => addParitiarComitee("blueCollar")}
            className="form-button float-end shadow-none"
          />
        </div>
      </div>
    </div>
  );
};

export default translate(BlueCollarSection);
