export const NEXT_STEP = "NEXT_STEP";
export const PREVIOUS_STEP = "PREVIOUS_STEP";
export const UPDATE_TAB_DETAILS = "UPDATE_TAB_DETAILS";
export const UPDATE_TAB_ERROR = "UPDATE_TAB_ERROR";
export const UPDATE_GENERAL_FIELD = "UPDATE_GENERAL_FIELD";
export const UPDATE_EMPLOYMENT_FIELD = "UPDATE_EMPLOYMENT_FIELD";
export const UPDATE_OFFER_FIELD ="UPDATE_OFFER_FIELD";
export const UPDATE_REQUIREMENT_FIELD = "UPDATE_REQUIREMENT_FIELD";
export const UPDATE_FIELDS = "UPDATE_FIELDS";
export const GENERAL = "general";
export const OFFER = "offer";
export const REQUIREMENT = "requirement";
export const EMPLOYMENT = "employment";
export const COMPETENCES = "competences";
export const JOB_DESCRIPTION = "jobDescription";
export const SET_OPTIONS  = "SET_OPTIONS";
export const UPDATE_FIELD_ERROR = "UPDATE_FIELD_ERROR";
export const UPDATE_COMPETENCES = "UPDATE_COMPETENCES";
export const UPDATE_CHILD_COMPETENCES = "UPDATE_CHILD_COMPETENCES";
export const UPDATE_CHILD_COMPETENCES_DATA = "UPDATE_CHILD_COMPETENCES_DATA";
export const rangeLabels = ["A1", "A2", "B1", "B2", "C1", "C2"];
export const rangeValues = ["1", "2", "3", "4", "5", "6"];
export const NEXT = "next";
