export const NEXT_STEP = "NEXT_STEP";
export const PREVIOUS_STEP = "PREVIOUS_STEP";
export const NEXT = "next";
export const GENERAL = "general";
export const CONTACTS = "contacts";
export const SHIFT_TABLES = "shiftTables";
export const WAGE_AND_PREMIUMS = "wageAndPremiums";
export const WAGE = "wage";
export const UPDATE_TAB_DETAILS = "UPDATE_TAB_DETAILS";
export const UPDATE_TAB_ERROR = "UPDATE_TAB_ERROR";
export const UPDATE_FIELD_ERROR = "UPDATE_FIELD_ERROR";
export const UPDATE_FIELDS = "UPDATE_FIELDS";
export const ADD_NEW_TIMETABLE = "ADD_NEW_TIMETABLE";
export const DELETE_TIMETABLE = "DELETE_TIMETABLE";
export const UPDATE_SHIFT_DATA = "UPDATE_SHIFT_DATA";
export const ADD_KB_DATA = "ADD_KB_DATA";
export const DELETE_KB_DATA = "DELETE_KB_DATA";
export const UPDATE_WAGE_PREMIUMS = "UPDATE_WAGE_PREMIUMS";
export const ADD_WAGE_DATA = "ADD_WAGE_DATA";
export const REMOVE_WAGE_DATA = "REMOVE_WAGE_DATA";
export const CLONE_PAY_PROFILE = "CLONE_PAY_PROFILE";
export const UPDATE_WAGE_FIELD_DATA = "UPDATE_WAGE_FIELD_DATA";
export const UPDATE_DROPDOWN_LIST = "UPDATE_DROPDOWN_LIST";
export const UPDATE_TAB_DATA = "UPDATE_TAB_DATA";
export const UPDATE_KB_DATA = "UPDATE_KB_DATA";
export const UPDATE_CONTACTS_FIELD = "UPDATE_CONTACTS_FIELD";
export const UPDATE_CONTACT_LIST = "UPDATE_CONTACT_LIST";
export const ADD_CONTACT = "ADD_CONTACT";
export const REMOVE_CONTACT = "REMOVE_CONTACT";
export const UPDATE_CONTACTS_FIELD_ERROR = "UPDATE_CONTACTS_FIELD_ERROR";
export const UPDATE_MEAL_VOUCHERS = "UPDATE_MEAL_VOUCHERS";
export const INITIAL_WAGE_PREMIUMS = "initialWageAndPremiums";
export const SELECTED_CONTACT = "selectedContact";