import React, { useEffect, useState } from "react";
import { ApiCall } from "components/common/services/ApiServices";
import { Link, useParams } from "react-router-dom";
import Button from "components/common/atoms/Button";
import Pagination from "components/common/atoms/Pagination";
import CustomNotify from "components/common/atoms/CustomNotify";
import * as ENDPOINTS from "routes/ApiEndpoints";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import ViewIcon from "static/images/ViewIcon";
import Reset from "static/images/Reset";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import "../questions/questions.css";
import Title from "components/common/atoms/Title";
import { Spinner } from "react-bootstrap";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import Calender from "components/common/molecules/Calender";
import MatchingProfileCard, {
  MatchingCandidateProfileCardType,
} from "components/Matching/MatchingCandidateProfileCard";
import { OptionProps } from "components/common/utlis/TypeAnnotations";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import { mapToSelect } from "components/common/utlis/MapToSelect";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import ResetBtn from "components/common/atoms/ResetBtn";
import SpinnerWrapper from "components/common/atoms/SpinnerWrapper";
import NoRecords from "components/common/atoms/NoRecords";

interface manageData {
  screenedBy: string;
  date: string;
  screeningId: number;
  officeName: string;
}

interface searchData {
  date: Date | null;
  screenedBy: string;
  office: OptionProps[];
}

const ManageScreening: React.FC = () => {
  //initiliaze the formData state for search fields
  const [searchData, setSearchData] = useState<searchData>({
    date: null,
    screenedBy: "",
    office: [],
  });

  const [profileCardData, setProfileCardData] =
    useState<MatchingCandidateProfileCardType>({
      candidateId: null,
      firstName: "",
      lastName: "",
      functions: "",
      mobNumber: "",
      email: "",
      street: "",
      number: "",
      box: "",
      zipCode: "",
      city: "",
      picFileName: "",
      picFilePath: "",
      country: "",
      cvData: "",
      profilePicData: "",
      isScreening: true,
      source: "manageScreening",
    });

  const [formData, setFormData] = useState([] as manageData[]);

  //state for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [loading, setLoading] = useState(true);
  const { candidateId } = useParams<{ candidateId: string }>();

  //initiliaze the state for dropdown values
  const [dropDowns, setDropDowns] = useState({
    offices: [] as OptionProps[],
  });

  useEffect(() => {
    fetchScreeningDataBySearch();
  }, []);

  //API call for to fetch countries
  const fetchScreeningDataBySearch = async (searchData = {}, page = 1) => {
    const requestData = {
      ...searchData,
      page: page,
    };
    const response = await ApiCall.service(
      `${ENDPOINTS.MANAGE_SCREENING}/${candidateId}`,
      "POST",
      requestData,
      false,
      "central-data-management"
    );
    if (response.status === 200) {
      setProfileCardData((prev) => ({
        ...prev,
        ...response.candidateData.candidateInfo.general[0],
        ...{ cvData: response.candidateData.candidateInfo?.cvData },
        ...{
          profilePicData: response.candidateData.candidateInfo?.profilePicData,
        },
        ...{ functions: response.candidateData.candidateInfo?.functions },
      }));
      setFormData(response.data);
      setDropDowns((prevData) => ({
        ...prevData,
        offices: mapToSelect(response.offices),
      }));
      setTotalPages(response.totalPages);
      setLoading(false);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (
      searchData.date === null &&
      searchData.screenedBy === "" &&
      searchData.office.length === 0
    ) {
      CustomNotify({
        type: "warning",
        message: t("Search input not provided") + ".",
      });
      fetchScreeningDataBySearch();
    } else {
      fetchScreeningDataBySearch(searchData, 1);
    }
  };

  const handleReset = () => {
    // Clear the selected values in the dropdowns
    setSearchData({
      date: null,
      screenedBy: "",
      office: [],
    });
    setCurrentPage(1);
    fetchScreeningDataBySearch();
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    fetchScreeningDataBySearch(searchData, newPage);
  };

  const handleDateChange = (date: Date | null, name: string) => {
    const updatedDate = date !== null ? date : new Date();
    setSearchData((prevData) => ({
      ...prevData,
      [name]: updatedDate,
    }));
  };

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setSearchData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  //changehandler for search fields
  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    setSearchData((prevData) => ({
      ...prevData,
      [fieldName]: selectedOption,
    }));
  };

  return (
    <>
      <AccessControl
        requiredPermissions={[
          {
            permission: "Candidate",
            read: true,
          },
        ]}
        renderNoAccess={true}
      >
        <Title title={t("Screening")} />
        <div className="row" style={{ marginBottom: "2vw" }}>
          <MatchingProfileCard profileCardProps={profileCardData} />
        </div>
        <div className="row">
          <div className="col-3">
            <LabelWithInputField
              isMandatory={false}
              name="screenedBy"
              handleChange={handleFieldChange}
              value={searchData.screenedBy}
              id="screenedBy"
              label={t("Screened by")}
              placeholder={t("Screened by")}
            />
          </div>
          <div className="col-3 position-relative">
            <Calender
              onChange={handleDateChange}
              selectedDate={searchData.date}
              label={t("Date")}
              isMandatory={false}
              name="date"
            />
          </div>
          <div className="col-3">
            <SelectWithSearch
              search={true}
              options={dropDowns.offices}
              title={t("Office")}
              onChange={(e) => handleSelectChange(e, "office")}
              isMulti={true}
              name="office"
              value={searchData.office}
              isTranslate={true}
            />
          </div>
          <div className="col-3 mt-34">
            <div className="d-flex align-items-center">
              <ResetBtn handleResetClick={handleReset} />
              <Button
                title={t("Search")}
                type="submit"
                icon={faMagnifyingGlass}
                handleClick={handleSubmit}
                className="form-button shadow-none px-3 search-btns text-start position-relative"
              />
            </div>
          </div>
        </div>
        <div className="table-responsive tableSection">
          <table className="table table-hover">
            <thead>
              <tr className="TableHeader">
                <th className="align-middle ps-lg-4" style={{ width: "25%" }}>
                  {t("Screened by")}
                </th>
                <th className="align-middle" style={{ width: "25%" }}>
                  {t("Date of screening")}
                </th>
                <th className="align-middle" style={{ width: "25%" }}>
                  {t("Office")}
                </th>
                <th className="align-middle" style={{ width: "25%" }}>
                  {t("Actions")}
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <SpinnerWrapper headerLength={4} />
              ) : (
                <>
                  {formData && formData.length > 0 ? (
                    formData.map((screening) => (
                      <tr
                        key={screening.screeningId}
                        className="border-bottom mb-3 box-shadow align-middle"
                      >
                        <>
                          <td
                            data-label={t("Screened By")}
                            className="align-middle ps-lg-4"
                          >
                            {screening.screenedBy}
                          </td>
                          <td
                            data-label={t("Date")}
                            className="align-middle ps-lg-4"
                          >
                            {screening.date}
                          </td>
                          <td
                            data-label={t("Office")}
                            className="align-middle ps-lg-4"
                          >
                            {screening.officeName}
                          </td>
                        </>

                        <td className="table-action-icons align-middle">
                          <Link
                            to={`/screening-assessment/${screening.screeningId}/${candidateId}`}
                            title={t("View")}
                          >
                            <ViewIcon />
                          </Link>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <NoRecords headerLength={7} />
                  )}
                </>
              )}
            </tbody>
          </table>
          {totalPages > 1 && (
            <div className="pagination justify-content-center align-items-center mt-4">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={(newPage) => handlePageChange(newPage)}
              />
            </div>
          )}
        </div>
        <div className="col-md-6 align-self-center backPadding">
          <Link
            to={`/candidate/corner/${candidateId}`}
            className="back-btn text-decoration-underline"
          >
            {t("Back")}
          </Link>
        </div>
      </AccessControl>
    </>
  );
};

export default translate(ManageScreening);
