import React, { useState } from "react";
import { useFormContext } from "../../context/Context";
import RadioField from "components/common/atoms/RadioField";
import InputTextfield from "components/common/atoms/InputTextField";
import { useNavigate, useParams } from "react-router-dom";
import { CREATE_AGREEMENT } from "routes/ApiEndpoints";
import { ApiCall } from "components/common/services/ApiServices";
import CustomNotify from "components/common/atoms/CustomNotify";
import { scrollToTop } from "components/common/services/ValidationService";
import { MANAGE_COOPERATION_AGREEMENT } from "routes/RouteConstants";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import { mapToSelect } from "components/common/utlis/MapToSelect";
import * as CONST from "../../annotations/CoopAgreementConstants";
import Button from "components/common/atoms/Button";
import ThumbsUpIcon from "static/images/ThumbsUpIcon";
import ThumbsUpIconFilled from "static/images/ThumbsUpIconFilled";
import ThumbsDownIcon from "static/images/ThumbsDownIcon";
import ThumbsDownIconFilled from "static/images/ThumbsDownIconFilled";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import InputTextAreaField from "components/common/atoms/InputTextAreaField";
import Navigation from "../formNavigation/Navigation";

const CompositionTab = () => {
  const { state, dispatch } = useFormContext();
  const { agreementId } = useParams<{ agreementId: string }>();
  const { companyId } = useParams<{ companyId: string }>();
  const [loading, setLoading] = useState(false);
  const userAuth = useSelector(selectAuth);
  const navigate = useNavigate();
  const [validationStatus, setValidationStatus] = useState({
    isValid: false,
    type: "",
  });

  const changeHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const { name, value, type } = e.target;
    if (name === "niceToHave") {
      const regex = /^[\d,.]*$/;
      let matches = value.match(regex);
      if (matches || value === "") {
        dispatch({
          type: CONST.UPDATE_COMPOSITION_FIELD,
          field: name,
          value: value,
          index: index,
        });
      }
    } else {
      const newValue = type === "radio" ? parseInt(value) : value;
      const fieldName = type === "radio" ? "inclusiveOption" : name;
      dispatch({
        type: CONST.UPDATE_COMPOSITION_FIELD,
        field: fieldName,
        value: newValue,
        index: index,
      });
    }
  };

  const acceptOrReject = (field: string, id: number, value: number) => {
    dispatch({
      type: CONST.UPDATE_COMPOSITION_FIELD,
      field,
      value,
      index: id,
    });
    dispatch({
      type: CONST.UPDATE_COMPOSITION_FIELD,
      field: "acceptOrRejectMail",
      value,
      index: id,
    });
  };

  const handleSelectChange = (
    selectedOption: any,
    fieldName: string,
    index: number
  ) => {
    dispatch({
      type: CONST.UPDATE_COMPOSITION_FIELD,
      field: fieldName,
      value: selectedOption,
      index: index,
    });
  };

  const validStatus = (validation: any) => {
    setValidationStatus({
      isValid: validation.isValid,
      type: validation.type,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    const currentIndex = state.tabDetails.findIndex((tab) => tab.showStatus);
    const currentId = state.tabDetails[currentIndex].id;
    e.preventDefault();
    const processedBillingData = state.billing.map(
      ({ empTypeDropdownValues, ...billingData }) => billingData
    );
    const formData = {
      general: {
        ...state.general,
        companyId: companyId,
      },
      billing: processedBillingData,
      contacts: state.contacts,
      compositionData: state.compositionData,
    };
    if (
      validationStatus.isValid &&
      state.general.vat !== "" &&
      state.general.company !== "" &&
      !state.invoice.coeffMaxStatus
    ) {
      setLoading(true);
      const url = !agreementId
        ? CREATE_AGREEMENT
        : `${CREATE_AGREEMENT}/${agreementId}`;
      const response = await ApiCall.service(
        url,
        "POST",
        formData,
        false,
        "company-creation"
      );
      if (response.status === 200) {
        if (validationStatus.type === "draft") {
          CustomNotify({
            type: "success",
            message: t("Draft saved successfully") + "!",
          });
          navigate(MANAGE_COOPERATION_AGREEMENT);
        } else if (validationStatus.type === "next") {
          navigate(
            `/cooperation-agreement/create/${companyId}/${response.data}?tabId=${currentId}`
          );
        }
      } else if (response.status === 400) {
        CustomNotify({ type: "error", message: response.msg.vat_number });
        dispatch({ type: CONST.UPDATE_TAB_ERROR, tabIndex: 0, error: true });
        scrollToTop();
        state.fieldError.vat = response.msg.vat_number;
      } else {
        if (response?.emails) {
          state.contacts.forEach((contact, index) => {
            if (response.emails.includes(contact.email)) {
              state.dynamicErrors[index].email = t(
                "Email already exist with a different role"
              );
            }
          });

          dispatch({ type: CONST.UPDATE_TAB_ERROR, tabIndex: 1, error: true });
          CustomNotify({
            type: "error",
            message: t(response?.msg),
          });
        }
      }
    }
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12">
          <div className="form-border" style={{ paddingTop: "3.4vw" }}>
            <table className="table composition-co-efficient-table">
              <thead>
                <tr className="border-0 TableHeader bg-white">
                  <th className="border-0 py-3" style={{ width: "40%" }}>
                    {t("Composition co-efficient")}
                  </th>
                  <th className="border-0 text-center" style={{ width: "10%" }}>
                    {t("Included")}
                  </th>
                  <th className="border-0 text-center" style={{ width: "10%" }}>
                    {t("Not-included")}
                  </th>
                  <th className="border-0" style={{ width: "15%" }}>
                    {t("Value")}
                  </th>
                  <th className="border-0" style={{ width: "25%" }}>
                    {t("Remarks")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {state.compositionData &&
                  state.compositionData.map((item: any, index: number) => (
                    <tr
                      className="border mb-3 box-shadow align-middle agreementData"
                      key={index}
                    >
                      <td
                        className="border-0 text-break align-middle"
                        data-label={t("Composition co-efficient")}
                      >
                        {t(`${item.name}`)}
                      </td>
                      <td
                        className="border-0 text-break text-center"
                        data-label={t("Included")}
                      >
                        <RadioField
                          handleChange={(e) => changeHandler(e, item.wageId)}
                          label=""
                          ischecked={item.inclusiveOption === 1}
                          name={`inclusive-${item.wageId}`}
                          value={1}
                        />
                      </td>
                      <td
                        className="border-0 text-break text-center"
                        data-label={t("Not-included")}
                      >
                        <RadioField
                          handleChange={(e) => changeHandler(e, item.wageId)}
                          ischecked={item.inclusiveOption === 0}
                          label=""
                          name={`inclusive-${item.wageId}`}
                          value={0}
                        />
                      </td>
                      <td
                        className="border-0 text-break "
                        data-label={t("Value")}
                      >
                        <div className={`input-group hideLabel agreement-wage-elements ${item.type !== 1 ? "field-shadow" : ""}`}>
                          {item.type === 1 ? (
                            <SelectWithSearch
                              key={item.wageId}
                              search={true}
                              options={mapToSelect(
                                state.coeffTypes,
                                "coeff_name"
                              )}
                              onChange={(e) =>
                                handleSelectChange(e, "value", item.wageId)
                              }
                              isMulti={false}
                              name="value"
                              value={item.value}
                              isTranslate={true}
                              className={`${
                                item.errorStatus ? "error-border" : ""
                              }`}
                            />
                          ) : item.wageId === 29 &&
                            state.general.vatInclusive !== null ? (
                            <>
                              <InputTextfield
                                name="vatInclusive"
                                handleChange={(event) =>
                                  changeHandler(event, item.wageId)
                                }
                                value={state.general.vatInclusive?.label ?? ""}
                                id={`niceToHave-${index}`}
                                className={
                                  "form-control shadow-none  rounded-start-3"
                                }
                                isDisabled={true}
                              />
                              <div className="input-group-append euroSignAgreement">
                                €
                              </div>
                            </>
                          ) : (
                            <>
                              <InputTextfield
                                name="niceToHave"
                                handleChange={(event) =>
                                  changeHandler(event, item.wageId)
                                }
                                value={item.niceToHave ?? ""}
                                id={`niceToHave-${index}`}
                                className={`form-control shadow-none ${
                                  item.errorStatus && item.approvalStatus !== 2 ? "error-border" : ""
                                } ${
                                  item.errorStatus && item.approvalStatus !== 2 ? "rounded-3" : ""
                                } ${item.type === 0 ? "rounded-end-0" : ""}`}
                              />
                              {item.type === 0 && (
                                <div className="input-group-append euroSignAgreement">
                                  €
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </td>
                      <td
                        className="border-0 text-break"
                        data-label={t("Remarks")}
                      >
                        <div className="row remarksTextArea">
                          <div
                            className={`${
                              userAuth.role === "AREA_MANAGER"
                                ? "col-7 pe-0"
                                : "col-12"
                            }`}
                          >
                            <InputTextAreaField
                              handleChange={(e) =>
                                changeHandler(e, item.wageId)
                              }
                              name="remarks"
                              value={item.remarks ?? ""}
                              rows={1}
                            />
                          </div>
                          <div className="col-5">
                            {userAuth.role === "AREA_MANAGER" &&
                              item.approvalStatus > 0 && (
                                <>
                                  {state.amApprovedCompositionData[index][
                                    "wageId"
                                  ] === item.wageId &&
                                    state.amApprovedCompositionData[index][
                                      "approvalStatus"
                                    ] !== 2 && (
                                      <>
                                        <Button
                                          className="btn p-0 border-0 table-action-icons me-2"
                                          handleClick={() =>
                                            acceptOrReject(
                                              "approvalStatus",
                                              item.wageId,
                                              2
                                            )
                                          }
                                        >
                                          {(item.approvalStatus === 1 ||
                                            item.approvalStatus === 3) && (
                                            <span
                                              title={t("Accept")}
                                              className="ms-2"
                                            >
                                              <ThumbsUpIcon />
                                            </span>
                                          )}
                                          {item.approvalStatus === 2 && (
                                            <span title={t("Accepted")}>
                                              <ThumbsUpIconFilled />
                                            </span>
                                          )}
                                        </Button>
                                        <Button
                                          className="btn p-0 border-0 table-action-icons"
                                          handleClick={() =>
                                            acceptOrReject(
                                              "approvalStatus",
                                              item.wageId,
                                              3
                                            )
                                          }
                                        >
                                          {(item.approvalStatus === 1 ||
                                            item.approvalStatus === 2) && (
                                            <span title={t("Reject")}>
                                              <ThumbsDownIcon />
                                            </span>
                                          )}
                                          {item.approvalStatus === 3 && (
                                            <span title={t("Rejected")}>
                                              <ThumbsDownIconFilled />
                                            </span>
                                          )}
                                        </Button>
                                      </>
                                    )}
                                </>
                              )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Navigation validStatus={validStatus} isLoading={loading} />
    </form>
  );
};
export default translate(CompositionTab);
