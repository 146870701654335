import { ApiCall } from "components/common/services/ApiServices";
import {
  GET_PLANNING_SHIFT_DETAILS,
  TIME_TABLE_DETAILS,
  UPDATE_FIELDS,
  UPDATE_TAB_DATA,
} from "../../annotation/PlanningConstants";
import { COMPANY_MICROSERVICE } from "Constants";

export const fetchShiftDetails = async (
  type: "shift", // Dynamic type handling
  id: number | null | undefined,
  dispatch: React.Dispatch<any>
) => {
  const data = { type, id };

  if (id) {
    const response = await ApiCall.service(
      `${GET_PLANNING_SHIFT_DETAILS}`,
      "POST",
      data,
      false,
      COMPANY_MICROSERVICE
    );

    if (response.status === 200) {
      const responseData = response.data;
      if (Object.keys(responseData).length > 0) {
        dispatch({
          type: UPDATE_TAB_DATA,
          tab: TIME_TABLE_DETAILS,
          data: responseData,
        });
      }
    }
  }
};
