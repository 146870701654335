import { t } from "components/CentralDataMangement/translation/Translation";
import { JobInterviewDetails, StarterDetails, VacancyDetails } from "../Annotations";
import TableTitle from "components/common/atoms/TableTitle";
import Tick from "static/images/Tick";

interface VacanciesTableProps {
    data?: VacancyDetails[];
    tableTitle?: string;
}
const VacanciesTable: React.FC<VacanciesTableProps> = ({ data, tableTitle }) => {

    return (
        <>
            {tableTitle &&
                <TableTitle title={tableTitle} />
            }
            <table className="table table-hover table-bordered targetTable">
                <thead>
                    <tr className="TableHeader">
                        <th>{t("Date")}</th>
                        <th>{t("Company")}</th>
                        <th>{t("Profile vacancy")}</th>
                        <th className="text-center">{t("Online")}</th>
                        <th>{t("Date online")}</th>
                    </tr>
                </thead>
                <tbody>
                    {data?.length && data?.length > 0 ? (
                        data?.map((vacancy: VacancyDetails, index) => (
                            <tr key={index}>
                                <td className="align-middle">{vacancy.date}</td>
                                <td className="align-middle">{vacancy.company}</td>
                                <td className="align-middle">{vacancy.profile}</td>
                                <td className="table-action-icons align-middle text-center">{vacancy?.online ? <Tick /> : ""}</td>
                                <td className="align-middle">{vacancy?.online_date}</td>
                            </tr>
                        ))) : (
                        <tr>
                            <td colSpan={5}>
                                <div className="text-danger text-center">
                                    {t("No data found")}
                                </div>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </>
    );
}

export default VacanciesTable;