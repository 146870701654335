import { Reducer } from "react";
import { FormAction } from "./Actions";
import * as CONST from "../annotation/PlanningConstants";
import { PlaningFormData } from "../annotation/PlanningInterface";

const PlanningReducer: Reducer<PlaningFormData, FormAction> = (
  state: any,
  action: any
) => {
  switch (action.type) {
    case CONST.NEXT_STEP:
      const currentTab = state.tabDetails.find((tab: any) => tab.showStatus);
      if (currentTab) {
        const currentIndex = state.tabDetails.findIndex(
          (tab: any) => tab.id === currentTab.id
        );
        const nextIndex = (currentIndex + 1) % state.tabDetails.length;

        const updatedTabDetails = state.tabDetails.map((tab: any) => ({
          ...tab,
          showStatus: tab.id === state.tabDetails[nextIndex].id,
        }));

        return {
          ...state,
          tabDetails: updatedTabDetails,
        };
      }
      return state;

    case CONST.PREVIOUS_STEP:
      const currentTabPrev = state.tabDetails.find(
        (tab: any) => tab.showStatus
      );
      if (currentTabPrev) {
        const currentIndexPrev = state.tabDetails.findIndex(
          (tab: any) => tab.id === currentTabPrev.id
        );
        const previousIndex =
          (currentIndexPrev - 1 + state.tabDetails.length) %
          state.tabDetails.length;

        const updatedTabDetailsPrev = state.tabDetails.map((tab: any) => ({
          ...tab,
          showStatus: tab.id === state.tabDetails[previousIndex].id,
        }));

        return {
          ...state,
          tabDetails: updatedTabDetailsPrev,
        };
      }
      return state;

    case CONST.UPDATE_TAB_DETAILS:
      return {
        ...state,
        tabDetails: action.tabDetails,
      };
    case CONST.UPDATE_TAB_ERROR:
      const { tabIndex, error } = action;
      return {
        ...state,
        tabDetails: state.tabDetails.map((tab: any, index: any) => ({
          ...tab,
          error: index === tabIndex ? error : tab.error,
        })),
      };

    case CONST.UPDATE_FIELDS:
      return {
        ...state,
        [action.tab]: {
          ...state[action.tab],
          [action.field]: action.value,
        },
      };

    case CONST.REMOVE_CONTRACT_PERFCODE:
      const newPerfcodes = [...state.remenurationDetails.perfCodes];

      // Remove the item at the specified index
      newPerfcodes.splice(action.indexToRemove, 1);

      // Return the new state with updated remenurationDetails
      return {
        ...state,
        remenurationDetails: {
          ...state.remenurationDetails,
          perfCodes: newPerfcodes,
        },
      };

    case CONST.ADD_CONTRACT_PERFCODE:
      const { data: perfcodeData, index: perfCodeIndex } = action;
      const updatedPerfCodes = [...state.remenurationDetails.perfCodes];

      // Update the specific coefficient at the given index
      updatedPerfCodes[perfCodeIndex] = {
        ...perfcodeData,
      };

      // Return the new state, updating remenurationDetails specifically
      return {
        ...state,
        remenurationDetails: {
          ...state.remenurationDetails,
          perfCodes: updatedPerfCodes,
        },
      };
    case CONST.REMOVE_AUTOMATIC_CODE:
      const newAutomaticCodes = [...state.remenurationDetails.automaticCodes];

      // Remove the item at the specified index
      newAutomaticCodes.splice(action.indexToRemove, 1);

      // Return the new state with updated remenurationDetails
      return {
        ...state,
        remenurationDetails: {
          ...state.remenurationDetails,
          automaticCodes: newAutomaticCodes,
        },
      };

    case CONST.ADD_AUTOMATIC_CODE:
      const { data: automaticCodeData, index: automaticIndex } = action;
      const updatedAutomaticCodes = [
        ...state.remenurationDetails.automaticCodes,
      ];

      // Update the specific coefficient at the given index
      updatedAutomaticCodes[automaticIndex] = {
        ...automaticCodeData,
      };

      // Return the new state, updating remenurationDetails specifically
      return {
        ...state,
        remenurationDetails: {
          ...state.remenurationDetails,
          automaticCodes: updatedAutomaticCodes,
        },
      };

    case CONST.REMOVE_DEVIATING_COEFF:
      const newDeviatingCoefficients = [
        ...state.billingDetails.deviatingCoefficients,
      ];

      // Remove the item at the specified index
      newDeviatingCoefficients.splice(action.indexToRemove, 1);

      // Return the new state with updated billingDetails
      return {
        ...state,
        billingDetails: {
          ...state.billingDetails,
          deviatingCoefficients: newDeviatingCoefficients,
        },
      };

    case CONST.ADD_DEVIATING_COEFF:
      const { data: deviCoeffData, index: devCoeffIndex } = action;
      const updatedDeviatingCoefficients = [
        ...state.billingDetails.deviatingCoefficients,
      ];

      // Update the specific coefficient at the given index
      updatedDeviatingCoefficients[devCoeffIndex] = {
        ...deviCoeffData,
      };

      // Return the new state, updating billingDetails specifically
      return {
        ...state,
        billingDetails: {
          ...state.billingDetails,
          deviatingCoefficients: updatedDeviatingCoefficients,
        },
      };

    case CONST.REMOVE_ALLOCATION:
      const updatedAllocations = [...state.internalInfoDetails.allocations];
      updatedAllocations.splice(action.indexToRemove, 1);
      return {
        ...state,
        internalInfoDetails: {
          ...state.internalInfoDetails,
          allocations: updatedAllocations,
        },
      };

    case CONST.ADD_ALLOCATION:
      const { data: allocation, index: allocationIndex } = action;
      const allocations = [...state.internalInfoDetails.allocations];
      allocations[allocationIndex] = {
        ...allocation,
      };
      return {
        ...state,
        internalInfoDetails: {
          ...state.internalInfoDetails,
          allocations: allocations,
        },
      };

    case CONST.REMOVE_COST_CENTER:
      const updatedCostCenters = [...state.costCenterDetails.costCenters];
      updatedCostCenters.splice(action.indexToRemove, 1);
      return {
        ...state,
        costCenterDetails: {
          ...state.costCenterDetails,
          costCenters: updatedCostCenters,
        },
      };

    case CONST.ADD_COST_CENTER:
      const { data: costCenter, index: costCenterIndex } = action;
      const costCenters = [...state.costCenterDetails.costCenters];
      costCenters[costCenterIndex] = {
        ...costCenter,
      };
      return {
        ...state,
        costCenterDetails: {
          ...state.costCenterDetails,
          costCenters: costCenters,
        },
      };

    case CONST.SET_DROPDOWN_DATA:
      return {
        ...state,
        dropDowndata: {
          ...state.dropDowndata, // Spread the previous state to retain all initial values
          ...action.payload, // Overwrite only the provided fields
        },
      };

    case CONST.UPDATE_FIELD_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.field]: action.error,
        },
      };

    case CONST.UPDATE_TAB_DATA: {
      const { tab, data } = action;
      return {
        ...state,
        [tab]: {
          ...state[tab], // Retain the existing state of the tab
          ...data, // Overwrite with the new data
        },
      };
    }

    default:
      return state;
  }
};

export default PlanningReducer;
