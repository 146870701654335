import { useEffect, useState } from "react";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import {
  Option,
  OptionProps,
  ValidationRules,
} from "components/common/utlis/TypeAnnotations";
import Button from "components/common/atoms/Button";
import CloseFile from "static/images/CloseFile";
import {
  validateDichtmsOptionsAnswer,
  validateForm,
  validateMultiChoiceAnswer,
  validateMultiChoice,
  validateMultiSelectField,
  validateRequired,
  validateSelectField,
  validateDichtmsOptions,
  scrollToTop,
} from "components/common/services/ValidationService";
import CheckBoxField from "components/common/atoms/CheckBoxField";
import RadioField from "components/common/atoms/RadioField";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { ApiCall } from "components/common/services/ApiServices";
import * as ENDPOINTS from "../../../routes/ApiEndpoints";
import { mapToSelect } from "components/common/utlis/MapToSelect";
import LabelWithCKEditorField from "components/common/molecules/LabelWithCKEditorField";
import CustomNotify from "components/common/atoms/CustomNotify";
import { t, translate } from "../translation/Translation";
import LoadingIcon from "components/common/utlis/LoadingIcon";
import Title from "components/common/atoms/Title";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";

interface commonDataProps {
  questionCategory: Option | null;
  function: object[];
  isGeneralQuestionsAdded: boolean;
}

interface QuestionProps {
  [key: string]:
    | string
    | number
    | null
    | boolean
    | Option
    | OptionProps[]
    | number[]
    | string[];
  questionLinkedId: null;
  questionType: Option | null;
  questionTitle: string;
  question: string;
  options: string[];
  answers: number[];
  description: string;
  questionStatus: number;
}

const AddQuestions: React.FC = () => {
  ///********* */ Common form start********///
  const { id } = useParams<{ id: string }>();
  const [commonData, setCommonData] = useState<commonDataProps>({
    questionCategory: null,
    function: [],
    isGeneralQuestionsAdded: false,
  });

  const [dropDownData, setDropDownData] = useState({
    questionCategory: [] as OptionProps[],
    function: [] as OptionProps[],
    questionTypes: [] as OptionProps[],
  });

  const initialCommonErrors: { [key: string]: string } = {};
  const [commonErrors, setCommonErrors] = useState<{ [key: string]: string }>(
    initialCommonErrors
  );

  const location = useLocation();
  const navigate = useNavigate();
  const isCloneMode = location.pathname.includes("clone");
  const isEditMode = location.pathname.includes("edit");
  const isViewMode = location.pathname.includes("view");
  const [loading, setLoading] = useState(false);

  const commonValidation = (
    name: string,
    value: string | boolean | Date | object[] | null,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRulesByCategory: any = {
      1: {
        questionCategory: [validateSelectField],
      },
      2: {
        questionCategory: [validateSelectField],
        function: [validateMultiSelectField],
      },
    };
    const questionCategoryValue: number =
      commonData.questionCategory?.value || 2;
    const validationErrors = validateForm(
      { ...commonData, [name]: value },
      validationRulesByCategory[questionCategoryValue],
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation) {
      setCommonErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setCommonErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    if (selectedOption.value === 1) {
      setCommonData((prevData) => ({
        ...prevData,
        function: [],
      }));
    }
    setCommonData((prevData) => ({
      ...prevData,
      [fieldName]: selectedOption,
    }));

    commonValidation(fieldName, selectedOption, true);
  };

  //****Commom form end */

  useEffect(() => {
    const fetchQuestionDetails = async () => {
      const url = id
        ? `${ENDPOINTS.GET_QUESTION_DETAILS}/${id}`
        : ENDPOINTS.GET_QUESTION_DETAILS;
      const response = await ApiCall.getService(
        url,
        "GET",
        "central-data-management",
        true
      );
      if (response.status === 200) {
        const questionsData = response.data["questionData"];
        const questionCategory = questionsData["questionCategory"];
        const functionList = questionsData["function"];
        const questionList = questionsData["questions"];
        if (isCloneMode) {
          // Set questionLinkedId to null for each question
          questionList.forEach((question: any) => {
            question.questionLinkedId = null;
          });
        }

        if (Object.keys(questionsData).length > 0) {
          setCommonData((prevData) => ({
            ...prevData,
            questionCategory: isCloneMode ? null : questionCategory,
            function: isCloneMode ? [] : functionList,
            isGeneralQuestionsAdded: response.data["isGeneralQuestionsAdded"],
          }));
          setQuestions(questionList);
        } else {
          setCommonData((prevData) => ({
            ...prevData,
            isGeneralQuestionsAdded: response.data["isGeneralQuestionsAdded"],
          }));
        }
        const questionCategories = mapToSelect(
          response.data["questionCategories"]
        );
        const functions = mapToSelect(
          response.data["functions"],
          "function_name"
        );
        const questionTypes = mapToSelect(response.data["questionTypes"]);

        setDropDownData((prevData) => ({
          ...prevData,
          questionCategory: questionCategories,
          function: functions,
          questionTypes: questionTypes,
        }));
      }
    };
    fetchQuestionDetails();
  }, [id, isCloneMode]);

  // ***Dynamic form start

  const [questions, setQuestions] = useState<QuestionProps[]>([
    {
      questionLinkedId: null,
      questionType: null,
      questionTitle: "",
      question: "",
      options: [""],
      answers: [],
      description: "",
      questionStatus: 1,
    },
  ]);
  const initialErrors: { [key: string]: string }[] = [];
  const [errors, setErrors] =
    useState<{ [key: string]: string }[]>(initialErrors);

  const validation = (
    name: string,
    value:
      | string
      | boolean
      | number
      | null
      | Option
      | number[]
      | OptionProps[]
      | string[],
    index: number,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRulesByType: any = {
      1: {
        questionType: [validateSelectField],
        questionTitle: [validateRequired],
        question: [validateRequired],
        options: [validateMultiChoice],
        answers: [validateMultiChoiceAnswer],
      },
      2: {
        questionType: [validateSelectField],
        questionTitle: [validateRequired],
        question: [validateRequired],
        description: [validateRequired],
      },
      3: {
        questionType: [validateSelectField],
        questionTitle: [validateRequired],
        question: [validateRequired],
        options: [validateDichtmsOptions],
        answers: [validateDichtmsOptionsAnswer],
      },
      4: {
        questionType: [validateSelectField],
        questionTitle: [validateRequired],
        question: [validateRequired],
      },
    };
    const questionType: number = questions[index].questionType?.value || 4;
    const validationErrors = validateForm(
      { ...questions[index], [name]: value },
      validationRulesByType[questionType],
      isSingleFieldValidation ? name : undefined
    );
    setErrors((prevErrors) => {
      const newErrors = [...prevErrors];

      if (isSingleFieldValidation) {
        // Single-field validation
        if (!newErrors[index]) {
          newErrors[index] = {}; // Initialize the object if it doesn't exist
        }
        newErrors[index][name] = validationErrors[name];
      } else {
        // Full-form validation
        newErrors[index] = validationErrors;
        questions[index].options.forEach((optionValue, optionIndex) => {
          newErrors[index][`option-${index}-${optionIndex}`] =
            optionValue.trim() === "" ? t("This field is required") : "";
        });
      }

      return newErrors;
    });

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }

    return true;
  };

  const addOption = (questionIndex: number) => {
    const newFields = [...questions];

    if (newFields[questionIndex].questionType?.value === 1) {
      if (newFields[questionIndex].options.length < 4) {
        newFields[questionIndex].options.push("");
        setQuestions(newFields);
      }
    }
    if (newFields[questionIndex].questionType?.value === 3) {
      if (newFields[questionIndex].options.length < 2) {
        newFields[questionIndex].options.push("");
        setQuestions(newFields);
      }
    }
  };

  const handleFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const { name, value, type } = event.target;

    if (type === "radio") {
      const answer = parseInt(event.target.value);
      const newAnswers: number[] = [answer];
      const newFields: QuestionProps[] = [...questions];
      newFields[index]["answers"] = newAnswers;
      setQuestions(newFields);
    }
    // Check if the field is a checkbox
    else if (type === "checkbox" && name.startsWith("answers")) {
      const newFields: QuestionProps[] = [...questions];
      const answersList = newFields[index].answers || []; // Get the existing checked options array

      if ((event.target as HTMLInputElement).checked) {
        // If the checkbox is checked, add the value to the array
        answersList.push(parseInt(value));
      } else {
        // If the checkbox is unchecked, remove the value from the array
        const indexToRemove = answersList.indexOf(parseInt(value));
        if (indexToRemove !== -1) {
          answersList.splice(indexToRemove, 1);
        }
      }

      newFields[index].answers = answersList;
      validation(name, answersList, index, true);
      setQuestions(newFields);
    } else if (type === "checkbox" && name === "questionStatus") {
      const newFields: QuestionProps[] = [...questions];

      // Check if the checkbox is checked
      const isChecked = (event.target as HTMLInputElement).checked;

      // Set questionStatus to 1 if checked, else set it to 0
      newFields[index][name] = isChecked ? 1 : 0;

      setQuestions(newFields);
    } else {
      // Handle other input fields as before
      const newFields: QuestionProps[] = [...questions];
      newFields[index][name] = value;
      setQuestions(newFields);
      validation(name, value, index, true);
    }
  };

  const handleDescriptionFieldChange = (
    event: any,
    editor: any,
    index: number,
    name: string
  ) => {
    const content = editor.getData();
    const newFields: QuestionProps[] = [...questions];
    newFields[index][name] = content;
    setQuestions(newFields);
    validation(name, content, index, true);
  };

  const handleOptionFieldChange = (
    event: any,
    editor: any,
    index: number,
    optionIndex: number
  ) => {
    const content = editor.getData();

    // Update the state with the changed option content
    const newQuestions = [...questions];
    newQuestions[index].options[optionIndex] = content;
    setQuestions(newQuestions);
    setErrors((prevErrors) => {
      const newErrors = [...prevErrors];
      if (!newErrors[index]) {
        newErrors[index] = {};
      }
      newErrors[index][`option-${index}-${optionIndex}`] =
        content.trim() === "" ? t("This field is required") : "";
      return newErrors;
    });
  };

  const addFieldSet = (): void => {
    const newField: QuestionProps = {
      questionLinkedId: null,
      questionType: null,
      questionTitle: "",
      question: "",
      options: [""],
      answers: [],
      description: "",
      questionStatus: 1,
    };

    setQuestions([...questions, newField]);
    setErrors([...errors, {}]);
  };

  const removeFieldSet = (index: number): void => {
    const newFields: QuestionProps[] = [...questions];
    newFields.splice(index, 1);
    setQuestions(newFields);
    const newErrors = [...errors];
    newErrors.splice(index, 1);
    setErrors(newErrors);
  };

  const handleDynamicSelectChange = (
    selectedOption: any,
    fieldName: string,
    index: number
  ) => {
    const newFields: QuestionProps[] = [...questions];
    if (
      selectedOption.value === 1 ||
      selectedOption.value === 2 ||
      selectedOption.value === 3
    ) {
      newFields[index] = {
        ...newFields[index],
        questionTitle: "",
        question: "",
        options: [""],
        answers: [],
        description: "",
        [fieldName]: selectedOption,
      };
      const newErrors = [...errors];
      newErrors[index] = {};
      setErrors(newErrors);
    }
    newFields[index] = {
      ...newFields[index],
      [fieldName]: selectedOption,
    };
    setQuestions(newFields);
    validation(fieldName, selectedOption, index, true);
  };

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>
  ) => {
    setLoading(true);
    e.preventDefault();
    const { name, value } = e.target as HTMLInputElement;
    let hasErrors = false;

    for (let i = 0; i < questions.length; i++) {
      const { name, value } = questions[i];
      const validationResult = validation(name as string, value, i);
      if (validationResult !== true) {
        hasErrors = true;
      }
    }

    if (commonValidation(name, value) && !hasErrors) {
      const mode = isCloneMode ? "clone" : isEditMode ? "edit" : "add";
      const formData = {
        mode: mode,
        questionCategory: commonData.questionCategory,
        functions: commonData.function,
        questions: questions,
      };

      //API call for store and update
      const url = isEditMode
        ? `${ENDPOINTS.UPDATE_QUESTIONS}/${id}`
        : ENDPOINTS.STORE_QUESTIONS;
      const response = await ApiCall.service(
        url,
        "POST",
        formData,
        false,
        "central-data-management"
      );
      if (response.status === 200) {
        navigate("/manage-questions");
        CustomNotify({ type: "success", message: t(response.msg) });
      } else if (response.status === 400) {
        scrollToTop();
        setCommonErrors({ function: response.msg });
        CustomNotify({ type: "error", message: t(response.msg) });
      }
    } else {
      scrollToTop();
    }
    setLoading(false);
  };

  const permissionType = id ? "update" : "create";
  const permissionObject: any = {
    permission: "Questions",
  };
  permissionObject[permissionType] = true;
  return (
    <>
      <AccessControl
        requiredPermissions={[permissionObject]}
        renderNoAccess={true}
      >
        <div className="row">
          {isViewMode ? (
            <>
              <Title title={t("View questions")} />
              <div className="col-12">
                <div className="form-border">
                  {questions.map((question: any, index: number) => (
                    <div key={index}>
                      <div
                        className="questionName tab-subtitle pb-0"
                        dangerouslySetInnerHTML={{
                          __html: index + 1 + ") " + question.question,
                        }}
                      />
                      {question.questionType?.value === 1 &&
                        question.options.length > 0 && (
                          <div className="mt-1 marginBotttom1" key={index}>
                            {question.options.map(
                              (option: any, optionIndex: number) => (
                                <div className="d-flex" key={optionIndex}>
                                  <div className="">
                                    <CheckBoxField
                                      className="ps-1"
                                      id={`checkbox-${optionIndex}`}
                                      value={option}
                                      name=""
                                      disable={true}
                                      isChecked={question.answers.includes(
                                        optionIndex
                                      )}
                                    />
                                  </div>

                                  <span
                                    className="questionOptionsInput"
                                    dangerouslySetInnerHTML={{
                                      __html: option.replace(
                                        /<a/g,
                                        '<a target="_blank"'
                                      ),
                                    }}
                                  />
                                </div>
                              )
                            )}
                          </div>
                        )}

                      {question.questionType?.value === 2 && (
                        <p
                          className="questionOptions marginBotttom1"
                          style={{ paddingLeft: "1vw" }}
                          dangerouslySetInnerHTML={{
                            __html: question.description.replace(
                              /<a/g,
                              '<a target="_blank"'
                            ),
                          }}
                        />
                      )}
                      {question.questionType?.value === 3 &&
                        question.options.length > 0 && (
                          <div className="mt-1 marginBotttom1" key={index}>
                            {question.options.map(
                              (option: any, optionIndex: number) => (
                                <div className="d-flex" key={optionIndex}>
                                  <div className="">
                                    <RadioField
                                      className="ps-1"
                                      id={`checkbox-${index}-${optionIndex}`}
                                      value={optionIndex}
                                      name={`dichotomous-${index}-${optionIndex}`}
                                      disable={true}
                                      ischecked={question.answers.includes(
                                        optionIndex
                                      )}
                                    />
                                  </div>

                                  <span
                                    className="questionOptionsInput"
                                    dangerouslySetInnerHTML={{
                                      __html: option.replace(
                                        /<a/g,
                                        '<a target="_blank"'
                                      ),
                                    }}
                                  />
                                </div>
                              )
                            )}
                          </div>
                        )}
                    </div>
                  ))}
                </div>
              </div>
              <div
                className="col-12"
                style={{ paddingTop: "1vw", paddingBottom: "1vw" }}
              >
                <Link
                  to=""
                  className=" back-btn text-decoration-underline"
                  onClick={() => navigate(-1)}
                >
                  {t("Back")}
                </Link>
              </div>
            </>
          ) : (
            <div className="col-12">
              <form onSubmit={handleSubmit}>
                <Title
                  title={
                    isEditMode
                      ? t("Edit questions")
                      : isCloneMode
                      ? t("Clone questions")
                      : t("Add questions")
                  }
                />
                <div className="row">
                  <div className="col-lg-4 col-md-6">
                    <SelectWithSearch
                      title={t("Question category")}
                      isMandatory={true}
                      search={true}
                      options={
                        commonData.isGeneralQuestionsAdded
                          ? dropDownData.questionCategory.filter(
                              (option) => option.value !== 1
                            )
                          : dropDownData.questionCategory
                      }
                      onChange={(e) =>
                        handleSelectChange(e, "questionCategory")
                      }
                      isMulti={false}
                      name="questionCategory"
                      isDisabled={isEditMode}
                      value={commonData.questionCategory}
                      error={commonErrors.questionCategory}
                      isTranslate={true}
                    />
                  </div>

                  {/* <div className="col-md-6 col-lg-4">
                <SelectWithSearch
                  title={t("Domain")}
                  isMandatory={true}
                  search={true}
                  options={dropDownData.domain}
                  onChange={(e) => handleSelectChange(e, "domain")}
                  isMulti={false}
                  name="domain"
                  isDisabled={isEditMode}
                  value={commonData.domain}
                  error={commonErrors.domain}
                />
              </div> */}
                  {commonData.questionCategory?.value !== 1 && (
                    <div className="col-md-6 col-lg-4">
                      <SelectWithSearch
                        title={t("Function")}
                        isMandatory={true}
                        search={true}
                        options={dropDownData.function}
                        onChange={(e) => handleSelectChange(e, "function")}
                        isMulti={true}
                        name="function"
                        value={commonData.function}
                        error={commonErrors.function}
                        isTranslate={true}
                      />
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-border">
                      {questions.map((question: any, index: number) => (
                        <div className="row">
                          <div className="col-12">
                            <div
                              key={index}
                              className="bg-white internalContentWrapper border-0 marginBotttom1"
                            >
                              {index > 0 && (
                                <div className="row">
                                  <div className="col-md-12">
                                    <span
                                      title={t("Remove")}
                                      onClick={() => removeFieldSet(index)}
                                      className="table-action-icons cursor-pointer float-end rounded-0 shadow-none"
                                    >
                                      <CloseFile />
                                    </span>
                                  </div>
                                </div>
                              )}
                              <div className="col-sm-12 col-md-12">
                                <SelectWithSearch
                                  title={t("Question type")}
                                  isMandatory={true}
                                  search={true}
                                  options={dropDownData.questionTypes}
                                  id={`questionType-${index}`}
                                  placeHolder={t("Select question type")}
                                  onChange={(e) =>
                                    handleDynamicSelectChange(
                                      e,
                                      "questionType",
                                      index
                                    )
                                  }
                                  isMulti={false}
                                  className="select-field"
                                  name="questionType"
                                  value={question.questionType}
                                  error={errors[index]?.questionType}
                                  isDisabled={
                                    question.questionLinkedId !== null
                                  }
                                  isTranslate={true}
                                />
                              </div>
                              <div className="col-sm-12 col-md-12">
                                <LabelWithInputField
                                  isMandatory={true}
                                  name="questionTitle"
                                  handleChange={(event) =>
                                    handleFieldChange(event, index)
                                  }
                                  value={question.questionTitle}
                                  id={`questionTitle-${index}`}
                                  label={t("Question title")}
                                  type="text"
                                  error={errors[index]?.questionTitle}
                                />
                              </div>
                              <div className="col-sm-12 col-md-12">
                                <LabelWithInputField
                                  isMandatory={true}
                                  name="question"
                                  handleChange={(event) =>
                                    handleFieldChange(event, index)
                                  }
                                  value={question.question}
                                  id={`question-${index}`}
                                  label={t("Question")}
                                  type="text"
                                  error={errors[index]?.question}
                                />
                              </div>
                              {/**Multiple choice start*/}
                              {question.questionType &&
                                question.questionType.value === 1 && (
                                  <>
                                    <div
                                      className="col-md-12"
                                      style={{ padding: "0 1vw" }}
                                      key={index}
                                    >
                                      <div
                                        className="d-grid"
                                        style={{
                                          gridTemplateColumns: "repeat(2, 1fr)",
                                          gap: "0vw 2.5vw",
                                        }}
                                      >
                                        {question.options.map(
                                          (
                                            option: any,
                                            optionIndex: number
                                          ) => (
                                            <div key={optionIndex}>
                                              <div className="d-flex justify-content-center align-items-center multipleChoiceQns">
                                                <div className="">
                                                  <CheckBoxField
                                                    name={`answers-${index}-${optionIndex}`}
                                                    id={`answers-${index}-${optionIndex}`}
                                                    onChangeHandler={(event) =>
                                                      handleFieldChange(
                                                        event,
                                                        index
                                                      )
                                                    }
                                                    value={optionIndex}
                                                    isChecked={question.answers.includes(
                                                      optionIndex
                                                    )}
                                                  />
                                                </div>
                                                <div className="col-md-12">
                                                  <LabelWithCKEditorField
                                                    isMandatory={true}
                                                    label={`${t("Option")} ${
                                                      optionIndex + 1
                                                    }`}
                                                    name={`option-${index}-${optionIndex}`}
                                                    placeholder={`${t(
                                                      "Option"
                                                    )} ${optionIndex + 1}`}
                                                    handleChange={(
                                                      event,
                                                      editor
                                                    ) =>
                                                      handleOptionFieldChange(
                                                        event,
                                                        editor,
                                                        index,
                                                        optionIndex
                                                      )
                                                    }
                                                    value={
                                                      question.options[
                                                        optionIndex
                                                      ]
                                                    }
                                                    id={`option-${index}-${optionIndex}`}
                                                    error={
                                                      errors[index]?.[
                                                        `option-${index}-${optionIndex}`
                                                      ]
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                    {errors[index]?.options && (
                                      <div className="col-md-12 mb-3">
                                        <span className="text-danger">
                                          {errors[index]?.options}
                                        </span>
                                      </div>
                                    )}
                                    {errors[index]?.answers && (
                                      <div className="col-md-12 mb-3">
                                        <span className="text-danger">
                                          {errors[index]?.answers}
                                        </span>
                                      </div>
                                    )}
                                    {question.options.length < 4 && (
                                      <div className="row">
                                        <div className="col-md-12 px-0">
                                          <Button
                                            title={t("Add option")}
                                            handleClick={() => addOption(index)}
                                            className="form-button float-end shadow-none "
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </>
                                )}
                              {/**Multiple choice end*/}

                              {/**Boolean start */}
                              {question.questionType &&
                                question.questionType.value === 3 && (
                                  <div
                                    className="col-md-12"
                                    style={{ padding: "0 1vw" }}
                                    key={index}
                                  >
                                    <div
                                      className="d-grid"
                                      style={{
                                        gridTemplateColumns: "repeat(2, 1fr)",
                                        gap: "2.5vw",
                                      }}
                                    >
                                      {question.options.map(
                                        (option: any, optionIndex: number) => (
                                          <div key={optionIndex}>
                                            <div className="d-flex justify-content-center align-items-center radioFieldQns">
                                              <div className="">
                                                <RadioField
                                                  name={`answers-${index}`}
                                                  id={`answers-${index}-${optionIndex}`}
                                                  handleChange={(event) => {
                                                    handleFieldChange(
                                                      event,
                                                      index
                                                    );
                                                  }}
                                                  value={optionIndex}
                                                  ischecked={question.answers.includes(
                                                    optionIndex
                                                  )}
                                                />
                                              </div>
                                              <div className="col-md-12">
                                                <LabelWithCKEditorField
                                                  isMandatory={true}
                                                  label={`${t("Option")} ${
                                                    optionIndex + 1
                                                  }`}
                                                  placeholder={`Option ${
                                                    optionIndex + 1
                                                  }`}
                                                  name={`option-${index}-${optionIndex}`}
                                                  handleChange={(
                                                    event,
                                                    editor
                                                  ) =>
                                                    handleOptionFieldChange(
                                                      event,
                                                      editor,
                                                      index,
                                                      optionIndex
                                                    )
                                                  }
                                                  value={
                                                    question.options[
                                                      optionIndex
                                                    ]
                                                  }
                                                  id={`option-${index}-${optionIndex}`}
                                                  error={
                                                    errors[index]?.[
                                                      `option-${index}-${optionIndex}`
                                                    ]
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </div>
                                    <div className="col-md-6 mb-3">
                                      {errors[index]?.options && (
                                        <span className="text-danger">
                                          {errors[index]?.options}
                                        </span>
                                      )}
                                    </div>
                                    <div className="col-md-3 mb-3">
                                      {errors[index]?.answers && (
                                        <span className="text-danger">
                                          {errors[index]?.answers}
                                        </span>
                                      )}
                                    </div>
                                    {question.options.length < 2 && (
                                      <div className="row">
                                        <div className="col-md-12 px-0">
                                          <Button
                                            title={t("Add option")}
                                            handleClick={() => addOption(index)}
                                            className="form-button float-end shadow-none "
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}
                              {/* Boolean end */}

                              {/* Descriptive start */}
                              {question.questionType &&
                                question.questionType.value === 2 && (
                                  <div>
                                    <div className="col-lg-12">
                                      <LabelWithCKEditorField
                                        name="description"
                                        handleChange={(event, editor) =>
                                          handleDescriptionFieldChange(
                                            event,
                                            editor,
                                            index,
                                            "description"
                                          )
                                        }
                                        label={t("Question description")}
                                        isMandatory={true}
                                        value={question.description}
                                        error={errors[index]?.description}
                                      />
                                    </div>
                                  </div>
                                )}
                              {/* Descriptive end*/}
                              <div className="row">
                                <div className="col-md-12">
                                  <CheckBoxField
                                    label={t("Publish question")}
                                    name="questionStatus"
                                    onChangeHandler={(event) =>
                                      handleFieldChange(event, index)
                                    }
                                    isChecked={question.questionStatus === 1}
                                    id="questionStatus"
                                    lineHeight="1.1vw"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                      <div className="row">
                        <div className="col-md-12">
                          <Button
                            title={"+" + t("Add another")}
                            handleClick={addFieldSet}
                            className="form-button float-end shadow-none"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row backPadding">
                  <div className="col-6 align-self-center">
                    <Link
                      to=""
                      className="back-btn text-decoration-underline"
                      onClick={() => navigate(-1)}
                    >
                      {t("Back")}
                    </Link>
                  </div>
                  <div className="col-md-6 text">
                    {!loading ? (
                      <Button
                        title={t("Save")}
                        type="submit"
                        className="float-end form-button shadow-none"
                      />
                    ) : (
                      <LoadingIcon
                        iconType="bars"
                        color="#e55496"
                        className="ms-auto"
                        width={"3vw"}
                        height={"3vw"}
                      />
                    )}
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      </AccessControl>
    </>
  );
};
export default translate(AddQuestions);
