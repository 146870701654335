import {
  CONF_SETTINGS_ADD,
  CONF_SETTINGS_EDIT,
  CONF_SETTINGS_UPDATE,
} from "routes/ApiEndpoints";
import { validateRequired } from "components/common/services/ValidationService";
import FormBuilder from "components/common/services/form/FormBuilder";
import { useParams } from "react-router-dom";
import { t, translate } from "components/CentralDataMangement/translation/Translation";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";

const Domain: React.FC = () => {
  const { id: domainId } = useParams<{ id: string }>();

  const FunctionFormValidation = {
    domainName: [validateRequired],
  };

  const permissionType = domainId ? "update" : "create";
  const permissionObject: any = {
    permission: "Domain",
  };
  permissionObject[permissionType] = true;
  return (
    <AccessControl
      requiredPermissions={[permissionObject]}
      renderNoAccess={true}
    >
      <FormBuilder
        title={domainId === undefined ? t("Add domain") : t("Edit domain")}
        formName={"Domain"}
        validationRules={FunctionFormValidation}
        entryId={domainId}
        getDataAPI={CONF_SETTINGS_EDIT}
        SaveDataAPI={
          domainId === undefined ? CONF_SETTINGS_ADD : CONF_SETTINGS_UPDATE
        }
        microService="central-data-management"
        backButton={true}
        redirect={-1}
        type="domain"
      />
    </AccessControl>
  );
};

export default translate(Domain);
