import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "Constants";
import { ApiCall } from "components/common/services/ApiServices";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as ENDPOINTS from "routes/ApiEndpoints";
import { t, translate } from "../../translation/Translation";
import Button from "components/common/atoms/Button";
import CustomNotify from "components/common/atoms/CustomNotify";
import Title from "components/common/atoms/Title";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import {
  validateForm,
  validateRequired,
} from "components/common/services/ValidationService";
import LoadingIcon from "components/common/utlis/LoadingIcon";
import "./css/regions.css";
import DatePicker from "react-multi-date-picker";
import React from "react";
import LabelField from "components/common/atoms/LabelField";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import NoRecords from "components/common/atoms/NoRecords";

interface Month {
  id: number;
  name: string;
  value: string | null;
}

interface BudgetLocation {
  id: number;
  name: string;
  months: Month[];
}

interface Region {
  id: number;
  name: string;
  budgetLocations: BudgetLocation[];
}

const Budget: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<Region[]>([]);
  const [months, setMonths] = useState<Month[]>([]);
  const navigate = useNavigate();
  const [totalBudget, setTotalBudget] = useState<string>("");
  const [budgetId, setBudgetId] = useState<number | null>(null);
  const initialErrors: { [key: string]: string } = {};
  const [errors, setErrors] = useState<{ [key: string]: string }>(
    initialErrors
  );
  const [year, setYear] = useState(new Date());

  useEffect(() => {
    fetchRegion();
  }, [year]);

  const fetchRegion = async () => {
    const requestData = {
      year: year.getFullYear(),
      type: "budget",
    };

    const response = await ApiCall.service(
      ENDPOINTS.BUDGET_GET + "/budget",
      "POST",
      requestData,
      true,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );

    if (response.status === 200) {
      setTotalBudget(response.data["totalBudget"] ?? "");
      setBudgetId(response.data["budgetId"] ?? null);
      setFormData(response.data["regions"]);
      setMonths(response.data["months"]);
    }
  };

  const totalBudgetChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let newValue = value.replace(/[^0-9\.,]+/g, ""); // Escape the dot character
    const matches = value.match(/,/g);
    const commaCount = matches ? matches.length : 0;

    if (commaCount < 2 && (newValue || newValue === "")) {
      setTotalBudget(newValue);
    }
  };

  const handleChange = (
    regionId: number,
    locationId: number,
    monthId: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;

    let newValue = value.replace(/[^0-9\.,]+/g, "");
    const matches = value.match(/,/g);
    const commaCount = matches ? matches.length : 0;

    if (commaCount < 2 && (newValue === "" || newValue)) {
      setFormData((prevFormData) => {
        return prevFormData.map((region) => {
          if (region.id === regionId) {
            return {
              ...region,
              budgetLocations: region.budgetLocations.map((location) => {
                if (location.id === locationId) {
                  let monthUpdated = false;
                  const updatedMonths = location.months.map((month) => {
                    if (month.id === monthId) {
                      monthUpdated = true;
                      return { ...month, value: newValue };
                    }
                    return month;
                  });
                  // If the month doesn't exist, add it to the months array
                  if (!monthUpdated) {
                    updatedMonths.push({
                      id: monthId,
                      name: "",
                      value: newValue,
                    });
                  }
                  return { ...location, months: updatedMonths };
                }
                return location;
              }),
            };
          }
          return region;
        });
      });
    }
  };

  const monthValue = (
    regionId: number,
    locationId: number,
    monthId: number
  ) => {
    return (
      formData
        .find((region) => region.id === regionId)
        ?.budgetLocations.find((location) => location.id === locationId)
        ?.months.find((month) => month.id === monthId)?.value ?? ""
    );
  };

  const columnTotal = (monthId: number) => {
    let total = 0;

    // Iterate through each region
    formData.forEach((region) => {
      // Iterate through each budget location in the region
      region.budgetLocations.forEach((location) => {
        // Find the month with the specified monthId
        const month = location.months.find((month) => month.id === monthId);
        if (month && month.value) {
          // Replace commas with dots and convert the value to a number
          const numericValue = parseFloat(
            month.value.replace(/\./g, "").replace(/,/g, ".")
          );
          total += numericValue;
        }
      });
    });
    const formattedTotal = numberFormat(total, 2, ".", ",");
    return formattedTotal;
  };

  const rowTotal = (regionId: number, locationId: number) => {
    let total = 0;

    // Find the region with the specified regionId
    const region = formData.find((region) => region.id === regionId);

    // If region is found, find the location with the specified locationId
    if (region) {
      const location = region.budgetLocations.find(
        (location) => location.id === locationId
      );

      // If location is found, iterate through its months and sum up their values
      if (location) {
        location.months.forEach((month) => {
          if (month.value) {
            // Replace commas with dots and convert value to a number
            const numericValue = parseFloat(
              month.value.replace(/\./g, "").replace(/,/g, ".")
            );
            total += numericValue;
          }
        });
      }
    }
    const formattedTotal = numberFormat(total, 2, ".", ",");
    return formattedTotal;
  };

  const validation = (
    name: string,
    value: string | boolean | null | number
  ) => {
    const validationRules = {
      totalBudget: [validateRequired],
    };

    const validationErrors = validateForm({ [name]: value }, validationRules);

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    const locationsData: any[] = [];

    formData.forEach((region) => {
      const regionId = region.id;
      const regionName = region.name;

      // Iterate through each budget location in the region
      region.budgetLocations.forEach((location) => {
        const locationId = location.id;
        const locationName = location.name;
        const months = location.months;

        // Push the extracted data to the new array
        locationsData.push({
          regionId,
          regionName,
          locationId,
          locationName,
          months,
        });
      });
    });

    const budget = parseFloat(
      totalBudget.replace(/\./g, "").replace(/,/g, ".")
    );

    const payload = {
      year: year,
      type: "budget",
      data: { data: locationsData, totalBudget: budget, budgetId: budgetId },
    };

    if (validation("totalBudget", totalBudget)) {
      if (totalAllInputs() <= budget) {
        const url = ENDPOINTS.BUDGET_ADD + "/budget";
        const response = await ApiCall.service(
          url,
          "POST",
          payload,
          false,
          CENTRAL_DATA_MANAGEMENT_MICROSERVICE
        );
        if (response.status === 200) {
          CustomNotify({
            type: "success",
            message: t(response.msg),
          });
          // navigate('/budget-targets');
        } else if (response.status === 400) {
          CustomNotify({
            type: "error",
            message: t(response.msg),
          });
        }
      } else {
        CustomNotify({
          type: "error",
          message: t("Total budget is exceeding the budget of the year"),
        });
      }
    }
    setLoading(false);
  };

  const totalAllInputs = (): number => {
    let total = 0;
    // Iterate through each region
    formData.forEach((region) => {
      // Iterate through each budget location in the region
      region.budgetLocations.forEach((location) => {
        // Iterate through each month in the location
        location.months.forEach((month) => {
          if (month.value) {
            // Replace commas with dots and convert the value to a number
            // const numericValue = parseFloat(month.value.replace(/,/g, ""));
            const numericValue = parseFloat(
              month.value.replace(/\./g, "").replace(/,/g, ".")
            );
            total += numericValue;
          }
        });
      });
    });
    return total;
  };

  const handleYearChange = (date: any, currentYear: any) => {
    const isYearChanged =
      date !== null ? date.format("YYYY") !== currentYear.getFullYear() : false;

    if (isYearChanged) {
      setYear(new Date(date.format("YYYY")));
    }
  };

  function numberFormat(
    value: number,
    decimalPlaces: number,
    thousandsSeparator: string,
    decimalSeparator: string
  ): string {
    const formattedValue = value.toFixed(decimalPlaces); // Fix the number of decimal places
    const parts = formattedValue.split("."); // Split the number into integer and decimal parts
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator); // Add thousands separator
    return parts.join(decimalSeparator); // Join the parts with decimal separator
  }

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Budget",
          read: true,
          create: true,
          update: true,
        },
      ]}
      actions={true}
      strict={false}
      renderNoAccess={true}
    >
      <Title title={t("Budget")} />
      <div className="row search-bar">
        <div className="col-lg-2 col-3 position-relative">
          <LabelField title={t("Year")} />
          <div className="col-12 budgetYearPicker">
            <DatePicker
              value={year}
              inputClass="form-control field-shadow"
              name="date"
              placeholder={new Date().getFullYear().toString()}
              onChange={(e) => handleYearChange(e, year)}
              format="YYYY"
              onlyYearPicker={true}
              minDate={new Date(2014, 0, 1)}
              zIndex={1000}
              // filterDate={(date: Date) => date.getFullYear() >= minimumYear} // Filter out dates before the minimum year
            />
          </div>
          {/* <div className="position-absolute yearPickerIcon">
              <DropdownIcon />
            </div> */}
        </div>
        <div className="budgetYear col-lg-10 col-9">
          <LabelWithInputField
            type="text"
            label={`${t("Budget for")} ${year.getFullYear()}`}
            value={totalBudget ?? ""}
            handleChange={totalBudgetChange}
            error={errors.totalBudget}
            name="totalBudget"
          />
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12">
            <table className="table table-bordered budgetTable">
              <thead
                style={{
                  position: "sticky",
                  top: "1vw",
                  zIndex: "990",
                  backgroundColor: "transparent",
                }}
              >
                <tr className="TableHeader">
                  {/* <th className="text-break ps-lg-4">{t("Region")}</th> */}
                  <th
                    className="text-break"
                    style={{ outline: "0.052vw solid #E3E6E8" }}
                  >
                    {t("Location")}
                  </th>
                  {months &&
                    months.length > 0 &&
                    months.map((month, index) => (
                      <th
                        key={index}
                        className="text-center"
                        style={{ outline: "0.052vw solid #E3E6E8" }}
                      >
                        {t(`${month.name}`)}
                      </th>
                    ))}
                  <th className="text-break text-center">{t("Total")}</th>
                </tr>
              </thead>
              <tbody>
                {formData && formData.length > 0 ? (
                  <>
                    {formData.map((region: Region, regionIndex) => (
                      <React.Fragment key={regionIndex}>
                        <tr className="border-0" key={regionIndex}>
                          <td
                            colSpan={14}
                            className="regionName py-3 text-center border-0 text-uppercase"
                          >
                            {t(`${region.name}`)}
                          </td>
                        </tr>
                        {region.budgetLocations &&
                        region.budgetLocations.length > 0 ? (
                          region.budgetLocations.map(
                            (location: BudgetLocation, locationIndex) => (
                              <tr
                                key={`${regionIndex}-${locationIndex}`}
                                className="align-middle"
                              >
                                <td>{t(`${location.name}`)}</td>
                                {months && months.length > 0 && (
                                  <>
                                    {months.map((month, index) => (
                                      <td
                                        className={`px-0 text-center text-break`}
                                        key={`${regionIndex}-${locationIndex}-${month.id}`}
                                      >
                                        <input
                                          key={`input-${regionIndex}-${locationIndex}-${month.id}`}
                                          type="text"
                                          className="w-100 h-100 rounded-0 form-control shadow-none text-center"
                                          value={monthValue(
                                            region.id,
                                            location.id,
                                            month.id
                                          )}
                                          onChange={(e) =>
                                            handleChange(
                                              region.id,
                                              location.id,
                                              month.id,
                                              e
                                            )
                                          }
                                        />
                                      </td>
                                    ))}
                                    <td>
                                      <p className="mb-0 text-center">
                                        {rowTotal(region.id, location.id)}
                                      </p>
                                    </td>
                                  </>
                                )}
                              </tr>
                            )
                          )
                        ) : (
                          <tr>
                            <td
                              colSpan={14}
                              className="text-center text-danger"
                            >
                              {t("Location is not added for this region")}
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))}
                    <tr>
                      <td
                        colSpan={14}
                        className="border-0 regionName text-center text-uppercase"
                      >
                        {t("Total budget for each month")}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-break ps-lg-4" colSpan={1}>
                        {t("Total")}
                      </td>
                      {months &&
                        months.length > 0 &&
                        months.map((month, index) => (
                          <td key={index} className="text-center">
                            {columnTotal(month.id)}
                          </td>
                        ))}
                      <td className="text-center">
                        {numberFormat(totalAllInputs(), 2, ".", ",")}
                      </td>
                    </tr>
                  </>
                ) : (
                  <NoRecords headerLength={14} />
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="row backPadding">
          <div className="col-md-4 align-self-center">
            <Link
              to=""
              className=" back-btn text-decoration-underline"
              onClick={() => navigate(-1)}
            >
              {t("Back")}
            </Link>
          </div>
          <AccessControl
            requiredPermissions={[
              {
                permission: "Budget",
                create: true,
                update: true,
              },
            ]}
            actions={true}
            strict={false}
          >
            <div className="col-md-8">
              {!loading ? (
                <Button
                  title={t("Save")}
                  type="submit"
                  className="form-button float-end shadow-none"
                />
              ) : (
                <LoadingIcon
                  iconType="bars"
                  color="#ff4dae"
                  className="float-end"
                  width={"3vw"}
                  height={"3vw"}
                />
              )}
            </div>
          </AccessControl>
        </div>
      </form>
    </AccessControl>
  );
};
export default translate(Budget);
