import React, { ChangeEvent } from "react";
import LabelField from "../atoms/LabelField";
import InputTextfield from "../atoms/InputTextField";

interface LabelWithInputFieldProps {
  type?: string;
  className?: string;
  placeholder?: string;
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  handleBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  label?: string;
  value?: string | number | any;
  name?: string;
  id?: string;
  isDisabled?: boolean;
  customStyle?: React.CSSProperties;
  isMandatory?: boolean;
  labelClassName?: string;
  error?: string;
  min?: number;
  step?: number;
  max?: number;
  readOnly?: boolean;
  autoComplete?: string;
  multiLabel?: string;
  isPrefix?: string;
  isSuffix?: string;
}

export const InputGroupText: React.FC<LabelWithInputFieldProps> = ({
  type,
  className,
  placeholder,
  handleBlur,
  label,
  value,
  name,
  id = "",
  isDisabled,
  customStyle,
  isMandatory,
  labelClassName,
  error,
  min,
  step,
  max,
  readOnly,
  autoComplete,
  handleChange,
  multiLabel,
  isPrefix,
  isSuffix,
}) => {
  return (
    <>
      <div className="inputGroup">
        <LabelField
          title={label}
          className={labelClassName}
          isMandatory={isMandatory}
          htmlfor={id}
          subtitle={multiLabel}
        />
        <div className="input-group field-shadow" style={{borderRadius:"0.5vw"}}>
          {isPrefix && (
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                {isPrefix}
              </span>
            </div>
          )}
          <InputTextfield
            id={id}
            type={type}
            className={`form-control shadow-none ${
              isPrefix ? "rounded-start-0" : "rounded-end-0"
            } ${className}`}
            value={value}
            isDisabled={isDisabled}
            placeholder={placeholder}
            customStyle={customStyle}
            handleChange={handleChange ?? (() => {})}
            handleBlur={handleBlur}
            name={name}
            error={error}
            min={min}
            step={step}
            max={max}
            readOnly={readOnly}
            autoComplete={autoComplete}
          />
          {isSuffix && (
            <div className="input-group-append">
              <span
                className="input-group-text border-0"
                id="basic-addon2"
              >
                {isSuffix}
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
