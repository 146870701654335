import { OptionProps } from "components/common/utlis/TypeAnnotations";

interface dropDownList {
    offices: OptionProps[],
}
export const createFilterConfig = (dropDownList: dropDownList) => {
    return [
        {
            name: "office",
            options: dropDownList.offices,
            fieldType: "multiSelect",
            placeholder: "Office",
            filterType: 'search'
        },
        {
            name: "company",
            fieldType: "text",
            placeholder: "Company",
            filterType: 'search'
        },
        {
            name: "location",
            fieldType: "text",
            placeholder: "Location",
            filterType: 'search'
        }
    ];
};
