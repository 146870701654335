import React, { useEffect } from "react";
import "../static/css/dashboard.css";
import { selectAuth } from "features/auth/AuthSlice";
import { useSelector } from "react-redux";
import { t, translate } from "components/CentralDataMangement/translation/Translation";
import { useNavigate } from "react-router-dom";
import { CANDIDATE_ROLE } from "Constants";

const HomePage: React.FC = () => {
  const userAuth = useSelector(selectAuth);
  const navigate = useNavigate();

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (userAuth.role === CANDIDATE_ROLE) {
        navigate(`/todos/overview`);
      } else {
        navigate("/dashboard");
      }
    }, 3000);

    return () => {
      clearTimeout(timerId);
    };
  }, [navigate]);

  return (
    <>
      <div className="row d-flex align-items-center justify-content-center welcome-page" style={{minHeight : "inherit"}}>
        <div className="col-md-12 mx-auto text-center h-100 d-flex align-items-center justify-content-center flex-column">
          <div>
            <span className="welcome-text">{t("Welcome,")}</span> &nbsp;
            <span className="name-text color-dark-pink">
              {userAuth.name.split(" ")[0]}
            </span>
          </div>
          <div className="welcome-message-homepage position-relative mt-2">
            <div>
              {`${t("Let's navigate the")} ${userAuth.role
                .replace("_", " ")
                .toLowerCase()}`}
              <br></br>
              {t("universe together") + "." }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default translate(HomePage);
