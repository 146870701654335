import { Link, useNavigate } from "react-router-dom";
import { t, translate } from "../../translation/Translation";
import { useEffect, useState } from "react";
import { ApiCall } from "components/common/services/ApiServices";
import * as ENDPOINTS from "routes/ApiEndpoints";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "Constants";
import InputTextfield from "components/common/atoms/InputTextField";
import CustomNotify from "components/common/atoms/CustomNotify";
import Button from "components/common/atoms/Button";
import LoadingIcon from "components/common/utlis/LoadingIcon";
import Title from "components/common/atoms/Title";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import { Option } from "react-multi-select-component";
import { OptionProps } from "components/common/utlis/TypeAnnotations";
import { mapToSelect } from "components/common/utlis/MapToSelect";
import { mapEnumToSelect } from "components/common/utlis/MapEnumToSelect";
import { Spinner } from "react-bootstrap";
import { getTargetToBeAchieved } from "./DailyTurnover";
import SpinnerWrapper from "components/common/atoms/SpinnerWrapper";
import NoRecords from "components/common/atoms/NoRecords";

export interface UserOverview {
  overviewId: number | null;
  userId: number;
  user: string;
  designation: string;
  turnoverExp: string | null;
  growthPercent: number;
  goal: string | null;
  qid: number;
  dtoConfigId: number;
  bonus: number | null;
}

export interface QuartersOverview {
  [key: string]: UserOverview[];
}

export interface SearchProps {
  office: Option | null;
  year: Option | null;
}

interface Data {
  years: OptionProps[];
  offices: OptionProps[];
}

const TurnoverOverview: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<QuartersOverview>({});
  const [filterData, setFilterData] = useState<Data>({
    years: [],
    offices: [],
  });
  const currentYear = new Date().getFullYear();

  const [searchData, setSearchData] = useState<SearchProps>({
    year: {
      label: currentYear.toString(),
      value: 0,
    },
    office: null,
  });

  useEffect(() => {
    fetchTurnover();
  }, [searchData.office, searchData.year]);

  //API call for to fetch Turnover data
  const fetchTurnover = async () => {
    setLoading(true);
    const requestData = {
      office: searchData.office,
      year: searchData.year?.label,
    };

    const response = await ApiCall.service(
      `${ENDPOINTS.BUDGET_EDIT}/turnover-overview`,
      "POST",
      requestData,
      false,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );

    if (response.status === 200) {
      setFormData(response.data.result.overview);
      setFilterData((prevData) => ({
        ...prevData,
        offices: mapToSelect(response.data["offices"]),
      }));
      setFilterData((prevData) => ({
        ...prevData,
        years: mapEnumToSelect(response.data["years"]),
      }));
    } else {
      CustomNotify({
        type: "error",
        message: response.message,
      });
    }
    setLoading(false);
  };

  const handleUserChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    quarter: keyof QuartersOverview,
    userId: number,
    key: keyof UserOverview
  ) => {
    const value = event.target.value;
    setFormData((prevData) => ({
      ...prevData,
      [quarter]: prevData[quarter].map((user) =>
        user.userId === userId ? { ...user, [key]: value } : user
      ),
    }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    const requestBody = {
      year: searchData.year?.label,
      overview: formData,
    };

    if (Object.keys(formData).length > 0) {
      const response = await ApiCall.service(
        `${ENDPOINTS.BUDGET_ADD}/turnover-overview`,
        "POST",
        requestBody,
        false,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );

      if (response.status === 200) {
        fetchTurnover();
        CustomNotify({ type: "success", message: response.msg });
      } else {
        CustomNotify({
          type: "error",
          message: t("Some error occured while submitting"),
        });
      }
    } else {
      CustomNotify({
        type: "error",
        message: t("There is no data to submit"),
      });
    }

    setLoading(false);
  };

  const handleSelectChange = async (
    selectedOption: OptionProps,
    fieldName: string
  ) => {
    setSearchData((prevData) => ({ ...prevData, [fieldName]: selectedOption }));
  };

  return (
    <>
      <Title title={t("Daily turnover overview")} />
      <div className="row">
        <div className="col-2">
          <SelectWithSearch
            title={t("Year")}
            options={filterData.years}
            search={false}
            value={searchData.year}
            onChange={(e) => handleSelectChange(e, "year")}
            name={"year"}
          />
        </div>
        <div className="col-5">
          <SelectWithSearch
            title={t("Office")}
            isMandatory={true}
            search={true}
            options={filterData.offices ?? []}
            onChange={(e) => handleSelectChange(e, "office")}
            isMulti={false}
            name="office"
            value={searchData.office}
          />
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12">
            <table className="table table-bordered targetAndBudgetOverviewTable">
              <thead>
                <tr className="TableHeader">
                  <th
                    className="text-center align-middle"
                    style={{ width: "12%" }}
                  >
                    {t("Reference period")}
                  </th>
                  <th className="align-middle" style={{ width: "15%" }}>
                    {t("Name")}
                  </th>
                  <th className="align-middle" style={{ width: "15%" }}>
                    {t("Designation")}
                  </th>
                  <th className="align-middle" style={{ width: "15%" }}>
                    {t("Daily turnover expected")}
                  </th>
                  <th className="align-middle" style={{ width: "15%" }}>
                    {t("Growth") + "%"}
                  </th>
                  <th className="align-middle" style={{ width: "15%" }}>
                    {t("Target to be achieved")}
                  </th>
                  <th className="align-middle" style={{ width: "15%" }}>
                    {t("Bonus")}
                  </th>
                </tr>
              </thead>
              {loading ? (
                <tbody>
                  <SpinnerWrapper headerLength={7} />
                </tbody>
              ) : Object.keys(formData).length > 0 ? (
                Object.entries(formData).map(([quarter, overviews]) => (
                  <tbody key={quarter}>
                    {overviews.map((overview, index) => (
                      <tr key={`${quarter}-${index}`}>
                        {index === 0 && (
                          <td
                            className="align-middle text-center"
                            rowSpan={overviews.length}
                          >
                            {quarter}
                          </td>
                        )}
                        <td className="align-middle">{overview.user}</td>
                        <td className="align-middle">{overview.designation}</td>
                        <td className="align-middle position-relative">
                          <InputTextfield
                            handleChange={(e) => {
                              handleUserChange(
                                e,
                                quarter,
                                overview.userId,
                                "turnoverExp"
                              );
                            }}
                            value={overview.turnoverExp ?? ""}
                            type="text"
                            className="position-absolute start-0 top-0 w-100 h-100 border border-white text-center"
                          />
                        </td>
                        <td className="align-middle position-relative">
                          <InputTextfield
                            handleChange={(e) => {
                              handleUserChange(
                                e,
                                quarter,
                                overview.userId,
                                "growthPercent"
                              );
                            }}
                            value={overview.growthPercent ?? ""}
                            type="number"
                            className="position-absolute start-0 top-0 w-100 h-100 border border-white text-center"
                          />
                        </td>
                        <td className="align-middle position-relative">
                          <InputTextfield
                            handleChange={() => {}}
                            value={getTargetToBeAchieved(
                              overview.turnoverExp !== null
                                ? overview.turnoverExp.toString()
                                : "0",
                              overview.growthPercent
                                ? overview.growthPercent
                                : 0
                            )}
                            isDisabled={true}
                            className="position-absolute start-0 top-0 w-100 h-100 border border-white text-center"
                          />
                        </td>
                        <td className="align-middle position-relative">
                          <InputTextfield
                            handleChange={(e) => {
                              handleUserChange(
                                e,
                                quarter,
                                overview.userId,
                                "bonus"
                              );
                            }}
                            value={overview.bonus ?? ""}
                            type="number"
                            className="position-absolute start-0 top-0 w-100 h-100 border border-white text-center"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ))
              ) : (
                <tbody>
                  <NoRecords
                    message={`${
                      searchData.office === undefined ||
                      searchData.office?.value === null
                        ? t("Please select the office")
                        : t("No records")
                    }`}
                    headerLength={10}
                  />
                </tbody>
              )}
            </table>
          </div>
        </div>

        <div className="row backPadding">
          <div className="col-md-6 align-self-center">
            <Link
              to=""
              className="back-btn text-decoration-underline"
              onClick={() => navigate("/budget-targets")}
            >
              {t("Back")}
            </Link>
          </div>
          <div className="col-6 text-end">
            {!loading ? (
              <Button
                title={t("Save")}
                type="submit"
                className="form-button float-end shadow-none"
              />
            ) : (
              <LoadingIcon
                iconType="bars"
                color="#ff4dae"
                className="float-end"
                width={"3vw"}
                height={"3vw"}
              />
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default translate(TurnoverOverview);
