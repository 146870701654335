import { t, translate } from "components/CentralDataMangement/translation/Translation";
import CardCircle from "components/common/atoms/CardCircle";
import CardTitle from "components/common/atoms/CardTitle";
import DashboardCard from "components/common/atoms/DashboardCard";
import { Link } from "react-router-dom";
import data from "./adminDashboard.json";
import RenderComponent from "../RenderComponent";
import "static/css/welcome-dashboard.css";

const AdminDashboard = () => {
  return (
    <div className="recruiter-action-tabs row equal-cols">
      {data &&
        data.map((item: any, index:number) => (
          <div className="col-4" style={{marginBottom: "1.5vw", paddingLeft:"0.75vw", paddingRight: "0.75vw"}} key={index}>
            <Link key={item.id} to={item.path}>
              <DashboardCard className="card border-0 card-shadow justify-content-center h-100">
                <div className="row">
                  <div className="col-xxl-10 col-xl-9 col-lg-8 align-self-center col-md-6">
                    <CardTitle
                      title={t(item.title)}
                      className="recruitment-card-title"
                    />
                  </div>
                  <div className="col-xxl-2 col-xl-3 col-lg-4 align-self-center col-md-6">
                    <CardCircle
                      width="3.5vw"
                      height="3.5vw"
                      boxShadow="rgb(239 206 224) 0vw 0.156vw 0.365vw 0.156vw"
                      className="float-end justify-content-center"
                    >
                      <RenderComponent componentName={item.component} />
                    </CardCircle>
                  </div>
                </div>
              </DashboardCard>
            </Link>
          </div>
        ))}
    </div>
  );
};

export default translate(AdminDashboard);
