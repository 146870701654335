import { OptionProps } from "components/common/utlis/TypeAnnotations";

export const createViewFilterConfig = () => {
  return [
    {
      name: "fromDate",
      label: "From Date",
      fieldType: "date",
      placeholder: "From",
      filterType: "search",
    },
    {
      name: "toDate",
      label: "To Date",
      fieldType: "date",
      placeholder: "To",
      filterType: "search",
    },
  ];
};
