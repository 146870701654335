import { OptionProps } from "components/common/utlis/TypeAnnotations";

interface dropDownList  {
officeList: OptionProps[],
  roleList: OptionProps[],
}
export const createFilterConfig = (dropDowns:dropDownList) => {
  return [
    {
      name: "office",
      options: dropDowns.officeList,
      fieldType: "multiSelect",
      placeholder: "Office",
      filterType: 'search'
    },
    {
      name: "user",
      fieldType: "text",
      placeholder: "User name",
      filterType: 'search'
    },
    {
      name: "email",
      fieldType: "text",
      placeholder: "Email",
      filterType: 'search'
    },
    {
      name: "role",
      options: dropDowns.roleList,
      fieldType: "singleSelect",
      placeholder: "Role",
      filterType: 'search',
      prefixClass: 'selectSection'
    }
  ];
};
