import { t } from "components/CentralDataMangement/translation/Translation";
import ModalPopup from "components/common/atoms/ModalPopup";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import Calender from "components/common/molecules/Calender";
import { OptionProps } from "components/common/utlis/TypeAnnotations";
import MultiSelectAtom from "../atoms/MultiSelectAtom";
import { LabelWithInputField } from "./LabelWithInputField";
import Button from "../atoms/Button";

export interface AdvanceSearchModalProps<T> {
  show?: boolean;
  onHide: () => void;
  onSearchConfirm: (isAdvSearch: boolean) => void; // New prop
  fieldConfigs: Array<{
    label?: string;
    name: string;
    options?: OptionProps[];
    fieldType: string;
    placeholder?: string;
    isMulti?: boolean;
    isTranslate?: boolean;
    fieldPrefix?: string;
    type?: string;
    filterType?: string;
    autoComplete?: string;
  }>;
  advSearchData: T; // Use the generic type T
  setAdvSearchData: React.Dispatch<React.SetStateAction<T>>; // Use the generic type T
  clearAdvSearchFilter:(action:any) => void;
}

const AdvancedSearch = <T extends Record<string, any>>({
  show,
  onHide,
  onSearchConfirm,
  fieldConfigs,
  advSearchData,
  setAdvSearchData,
  clearAdvSearchFilter
}:
AdvanceSearchModalProps<T>) => {
  const handleFieldChange = (value: any, name: string) => {
    setAdvSearchData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectChange = (selectedOption: any, name: string) => {
    setAdvSearchData((prevData) => ({
      ...prevData,
      [name]: selectedOption,
    }));
  };

  const handleDateChange = (date: Date | null, name: string) => {
    setAdvSearchData((prevData) => ({
      ...prevData,
      [name]: date,
    }));
  };

  const handleSearchModal = () => {
    onHide();
  };

  const handleSearchConfirm = () => {
    onSearchConfirm(true); // Confirm search action
    handleSearchModal(); // Close modal
  };

  return (
    <>
      <div>
        <ModalPopup
          show={show}
          onHide={onHide}
          title={t("Advanced Search")}
          className="advancedSearchModal"
          modalBodyClassName="scrollBarDesign"
          body={
            <div className="container-fluid ps-1">
              <div className="d-grid" style={{gridTemplateColumns:"repeat(3, 1fr)", gap:"0vw 1vw"}}>
                {fieldConfigs
                  .filter((field) => field.filterType === "advSearch")
                  .map((field, idx) => (
                    <div key={idx} className="marginBotttom1 hideHeight">
                      {(() => {
                        switch (field.fieldType) {
                          case "text":
                            return (
                              <LabelWithInputField
                                name={field.name}
                                handleChange={(e:any) => handleFieldChange(e.target.value, field.name)}
                                value={advSearchData[field.name] ?? ""} // Access the dynamic name
                                id={field.name}
                                label={t(field.label)}
                                type={field.type ?? "text"} // Input field type is text
                                placeholder={t(field.placeholder || "")} // Use placeholder if provided
                                autoComplete={field.autoComplete ?? ''}
                              />
                            );

                          case "singleSelect":
                            return (
                              <SelectWithSearch
                                name={field.name}
                                title={t(field.label)}
                                search={true}
                                options={field.options || []}
                                onChange={(e) => handleSelectChange(e, field.name)}
                                isMulti={field.isMulti || false}
                                isTranslate={true}
                                value={advSearchData[field.name] ?? null}
                              />
                            );

                          case "multiSelect":
                            return (
                              <MultiSelectAtom
                                onChange={(selectedOption) =>
                                  handleSelectChange &&
                                  handleSelectChange(selectedOption, field.name)
                                }
                                name={field.name}
                                options={field.options ?? []}
                                value={advSearchData[field.name] ?? []}
                              />
                            );

                          case "date":
                            return (
                              <div className="position-relative">
                                <Calender
                                  onChange={(date) =>
                                    handleDateChange(date, field.name)
                                  }
                                  selectedDate={advSearchData[field.name]}
                                  label={t(field.label)}
                                  isMandatory={false}
                                  name={field.name}
                                />
                              </div>
                            );

                          default:
                            return null; // If no match for fieldType, render nothing
                        }
                      })()}
                    </div>
                  ))}
              </div>
            </div>
          }
          closeTitle={t("Close")}
          confirmTitle={t("Search")}
          buttonTitle={t('Clear filter')}
          onConfirmButtonClick={handleSearchConfirm}
          onCloseButtonClick={handleSearchModal}
          onButtonClick={clearAdvSearchFilter}
        />
      </div>
    </>
  );
};

export default AdvancedSearch;
/**** USAGE example
 * 
 * const [isAdvSearch, setIsAdvSearch] = useState(false);
 

  const [searchModal, setSearchModal] = useState(false);

  const handleCloseModal = () => {
    setSearchModal(false);
  };

  const handleSearchClick = () => {
    setSearchModal(true);
  };

  const handleSearchConfirm = (isAdvSearch: boolean) => {
    // Update state with the search flag and search fields
    setIsAdvSearch(isAdvSearch);
   // Call the fetch function with the updated search data
   fetchJobApplicationsBySearch(advSearchData, 1); // Pass the new search data directly
  };

 * <SearchBtn
        handleSearchClick={handleSearchClick}
        className={`${isAdvSearch ? "advSearchBtn" : ""}`}
   />
    <AdvancedSearch
        show={searchModal}
        onHide={handleCloseModal}
        onSearchConfirm={handleSearchConfirm}
        searchData={advSearchData} // Pass advSearchData to AdvancedSearch
        setAdvSearchData={setAdvSearchData} // Pass setAdvSearchData to AdvancedSearch
        fieldConfigs={[
        { label: "Candidate", name: "candidateName", type: "text" },
        { label: "Postal Code", name: "postalCode", type: "text" },
        { label: "City", name: "city", type: "text" },
        { label: "Country", name: "country", type: "text" },
        { label: "End date", name: "endDate", type: "date" },

        {
            label: "Selection Groups",
            name: "selectionGroup",
            type: "select",
            options: dropDowns.offices,
            isMulti: false,
        },
        {
            label: "Offices",
            name: "offices",
            type: "select",
            options: dropDowns.offices,
            isMulti: true,
        },
        ]}
    />
 * 
 * 
 * 
 * 
 * 
 * 
 */
