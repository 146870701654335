import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import { useFormContext } from "components/CandidateCreation/context/Context";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import LabelWithTextAreaField from "components/common/molecules/LabelWithTextAreaField";
import { useState } from "react";
import Navigation from "../formNavigation/Navigation";
import { ValidationRules } from "components/common/utlis/TypeAnnotations";
import {
  scrollToTop,
  validateForm,
  validateMultiSelectField,
  validatePay,
} from "components/common/services/ValidationService";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import { mapToSelect } from "components/common/utlis/MapToSelect";
import Calender from "components/common/molecules/Calender";

const JobProfileTab: React.FC = () => {
  const { state, dispatch, isViewMode } = useFormContext();

  const [validationStatus, setValidationStatus] = useState({
    isValid: false,
    type: "",
  });

  const validStatus = (validation: any) => {
    setValidationStatus({
      isValid: validation.isValid,
      type: validation.type,
    });
  };

  const validation = (
    name: string,
    value: string | boolean | Date | object[] | null,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      desiredFunctions: [validateMultiSelectField],
      payFrom: [
        (value: string) => validatePay(value, state.jobProfile.payTo, name),
      ],
      payTo: [
        (value: string) => validatePay(state.jobProfile.payFrom, value, name),
      ],
    };

    const validationErrors = validateForm(
      { ...state.general, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    dispatch({
      type: "UPDATE_FIELD_ERROR",
      fieldName: name === "payFrom" || name === "payTo" ? "payError" : name,
      error: validationErrors[name],
    });
    const isFieldValid = Object.keys(validationErrors).length === 0;

    if (isFieldValid) {
      dispatch({ type: "UPDATE_FIELD_ERROR", fieldName: name, error: "" });
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const handleSelectChange = (selectedOption: any, name: string) => {
    if (name === "desiredJobDomain") {
      if (selectedOption.length === 0) {
        dispatch({
          type: "UPDATE_JOB_PROFILE_FIELD",
          field: "desiredFunctions",
          value: [],
        });
      }
    }
    dispatch({
      type: "UPDATE_JOB_PROFILE_FIELD",
      field: name,
      value: selectedOption,
    });
    validation(name, selectedOption, true);
  };

  const handleFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    let newValue: string | number = value;
    if (name === "niceToHave" || name === "minimumGrossSalary") {
      newValue = value.replace(/[^0-9,]/g, ""); // Allow only numbers and commas
    }
    dispatch({
      type: "UPDATE_JOB_PROFILE_FIELD",
      field: name,
      value: newValue,
    });
    validation(name, newValue, true);
  };

  const handleDateChange = (date: Date | null, name: string) => {
    const updatedDate = date !== null ? date : new Date();

    dispatch({
      type: "UPDATE_JOB_PROFILE_FIELD",
      field: name,
      value: updatedDate,
    });
    validation(name, updatedDate, true);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    scrollToTop(400);
  };
  return (
    <form
      onSubmit={handleSubmit}
    >
      <div className={`form-border ${isViewMode ? "form disabled" : "form"}`} style={{ paddingTop: "3.4vw" }}>
        <div className="row">
          <div className="col-lg-4 col-6">
            <SelectWithSearch
              title={t("Sector")}
              isMandatory={false}
              search={true}
              options={state.dropDowndata.sectorList}
              onChange={(e) => handleSelectChange(e, "desiredSector")}
              isMulti={true}
              name="desiredSector"
              value={state.jobProfile.desiredSector}
              error={state.fieldError.desiredSector}
              isTranslate={true}
            />
          </div>
          <div className="col-lg-4 col-6">
            <SelectWithSearch
              title={t("Job domain")}
              isMandatory={false}
              search={true}
              options={state.dropDowndata.jobDomainList}
              onChange={(e) => handleSelectChange(e, "desiredJobDomain")}
              isMulti={true}
              name="desiredJobdomain"
              value={state.jobProfile.desiredJobDomain}
              isTranslate={true}
            />
          </div>
          <div className="col-lg-4 col-6">
            <SelectWithSearch
              title={t("Function")}
              isMandatory={true}
              search={true}
              options={
                state.jobProfile.desiredJobDomain.length === 0
                  ? state.dropDowndata.functionList
                  : state.jobProfile.desiredJobDomain.reduce(
                      (result: any, selectedItem: any) => {
                        const functionsForDomain = (
                          state.dropDowndata.domainFunctionLinking ?? []
                        )
                          .filter(
                            (func: any) => func.domain_id === selectedItem.value
                          )
                          .map((func: any) => func.function_id);
                        const filteredFunctions = (
                          state.dropDowndata.functionList ?? []
                        )
                          .filter((func: any) =>
                            functionsForDomain.includes(func.value)
                          )
                          .map((func: any) => ({
                            value: func.value,
                            label: func.label,
                          }));
                        return result.concat(filteredFunctions);
                      },
                      []
                    )
              }
              onChange={(e) => handleSelectChange(e, "desiredFunctions")}
              isMulti={true}
              name="desiredFunctions"
              isDisabled={false}
              value={state.jobProfile.desiredFunctions}
              error={state.fieldError.desiredFunctions}
              isTranslate={true}
            />
          </div>
          <div className="col-6 col-lg-4">
            <LabelWithInputField
              isMandatory={false}
              name="functionAliasName"
              handleChange={handleFieldChange}
              value={state.jobProfile.functionAliasName}
              id="functionAliasName"
              label={t("Function alias name")}
              placeholder={t("Function alias name")}
              type="text"
            />
          </div>
          <div className="col-lg-4 col-6">
            <SelectWithSearch
              title={t("Desired regimen")}
              isMandatory={false}
              search={true}
              options={state.dropDowndata.desiredRegimeList}
              onChange={(e) => handleSelectChange(e, "desiredRegimen")}
              isMulti={true}
              name="desiredRegimen"
              value={state.jobProfile.desiredRegimen}
              error={state.fieldError.desiredRegimen}
              isTranslate={true}
            />
          </div>
          <div className="col-6 col-lg-4">
            <SelectWithSearch
              title={t("Preferred employment")}
              isMandatory={false}
              search={true}
              options={state.dropDowndata.preferredEmploymentList}
              onChange={(e) => handleSelectChange(e, "preferredEmployment")}
              isMulti={false}
              name="preferredEmployment"
              value={state.jobProfile.preferredEmployment}
              error={state.fieldError.preferredEmployment}
              isTranslate={true}
            />
          </div>
          {/* <div className="col-sm-12 col-md-4">
              <SelectWithSearch
                title={t("Experience")}
                isMandatory={false}
                search={true}
                options={state.experienceList}
                onChange={(e) => handleSelectChange(e, "experience")}
                isMulti={false}
                name="experience"
                value={state.jobProfile.experience}
                error={state.fieldError.experience}
              />
            </div> */}
          <div className="col-6 col-lg-4">
            <SelectWithSearch
              title={t("Maximum distance from home work")}
              isMandatory={false}
              search={true}
              options={state.dropDowndata.maxDistanceList}
              onChange={(e) => handleSelectChange(e, "maximumDistance")}
              isMulti={false}
              name="maximumDistance"
              value={state.jobProfile.maximumDistance}
              error={state.fieldError.maximumDistance}
            />
          </div>
          <div className="col-6 col-lg-4">
            <SelectWithSearch
              title={t("Salary expectation")}
              isMandatory={false}
              search={true}
              options={state.dropDowndata.salaryExpectationList}
              onChange={(e) => handleSelectChange(e, "salaryExpectation")}
              isMulti={false}
              name="salaryExpectation"
              value={state.jobProfile.salaryExpectation}
              error={state.fieldError.salaryExpectation}
              isTranslate={true}
            />
          </div>
          <div className="col-6 col-lg-4">
            <LabelWithInputField
              isMandatory={false}
              name="minimumGrossSalary"
              handleChange={handleFieldChange}
              value={state.jobProfile.minimumGrossSalary}
              id="minimumGrossSalary"
              label={t("Minimum gross salary")}
              placeholder={t("Minimum gross salary")}
              type="text"
              error={state.fieldError.minimumGrossSalary}
            />
          </div>
          <div className="col-6 col-lg-4">
            <LabelWithInputField
              isMandatory={false}
              name="niceToHave"
              handleChange={handleFieldChange}
              value={state.jobProfile.niceToHave}
              id="niceToHave"
              label={t("Nice to have")}
              placeholder={t("Nice to have")}
              type="text"
            />
          </div>
        </div>
        <div className="row">
          {/* <div className="col-xxl-4 col-6">
              <LabelWithInputField
                isMandatory={false}
                name="payFrom"
                handleChange={handleFieldChange}
                value={state.jobProfile.payFrom}
                isDisabled={false}
                id="payFrom"
                label={t("Pay from")}
                type="text"
                error={state.fieldError.payError}
              />
            </div>
            <div className="col-xxl-4 col-6">
              <LabelWithInputField
                isMandatory={false}
                name="payTo"
                handleChange={handleFieldChange}
                value={state.jobProfile.payTo}
                isDisabled={false}
                id="payTo"
                label={t("Pay to")}
                type="text"
              />
            </div> */}
          <div className="col-6 col-lg-4">
            <SelectWithSearch
              title={t("Looking for work")}
              isMandatory={false}
              search={true}
              options={state.dropDowndata.lookingForWorklist}
              onChange={(e) => handleSelectChange(e, "lookingForWork")}
              isMulti={false}
              name="lookingForWork"
              value={state.jobProfile.lookingForWork}
              isTranslate={true}
            />
          </div>
          <div className="col-lg-4 position-relative">
            <Calender
              onChange={handleDateChange}
              selectedDate={state.jobProfile.date}
              label={t("Date")}
              isMandatory={false}
              name="date"
            />
          </div>
          <div className="col-sm-12">
            <LabelWithTextAreaField
              name="infodesiredJobs"
              handleChange={handleFieldChange}
              label={t("Info desired jobs")}
              isMandatory={false}
              value={state.jobProfile.infodesiredJobs}
              error={state.fieldError.infodesiredJobs}
            />
          </div>
          <div className="disclaimerText">
            {t(
              "Any form of discrimination based on sex, perceived race, colour, descent, nationality or ethnic origin, sexual orientation, marital status, birth, property, age, religious or philosophical beliefs, current or future state of health, disability or physical characteristic is prohibited."
            )}
          </div>
        </div>
      </div>
      <Navigation validStatus={validStatus} />
    </form>
  );
};
export default translate(JobProfileTab);
