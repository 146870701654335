import { t } from "components/CentralDataMangement/translation/Translation";
import { TargetAchievedTable } from "../Annotations";

interface RecruiterTargetProps {
  data?: TargetAchievedTable;
}

const RecruiterTargetTable: React.FC<RecruiterTargetProps> = ({ data }) => {
  const cardProps = {
    className: "align-middle",
  };
  return (
    <>
      <table className="table table-hover table-bordered targetTable">
        <thead>
          <tr className="TableHeader">
            <th style={{width:"40%"}}></th>
            <th className={`text-center ${cardProps.className}`}>
              {t("Target")}
            </th>
            <th className={`text-center ${cardProps.className}`}>
              {t("Achieved")}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{t("Calls")}</td>
            <td className={`text-center ${cardProps.className}`}>
              {data?.calls?.target}
            </td>
            <td className={`text-center ${cardProps.className}`}>
              {data?.calls?.achieved}
            </td>
          </tr>
          <tr>
            <td>{t("Screening")}</td>
            <td className={`text-center ${cardProps.className}`}>
              {data?.screening?.target}
            </td>
            <td className={`text-center ${cardProps.className}`}>
              {data?.screening?.achieved}
            </td>
          </tr>
          <tr>
            <td>{t("Presentation")}</td>
            <td className={`text-center ${cardProps.className}`}>
              {data?.presentation?.target}
            </td>
            <td className={`text-center ${cardProps.className}`}>
              {data?.presentation?.achieved}
            </td>
          </tr>
          <tr>
            <td>{t("Interview")}</td>
            <td className={`text-center ${cardProps.className}`}>
              {data?.interview?.target}
            </td>
            <td className={`text-center ${cardProps.className}`}>
              {data?.interview?.achieved}
            </td>
          </tr>
          <tr>
            <td>{t("Starters")}</td>
            <td className={`text-center ${cardProps.className}`}>
              {data?.starter?.target}
            </td>
            <td className={`text-center ${cardProps.className}`}>
              {data?.starter?.achieved}
            </td>
          </tr>
          <tr>
            <td>{t("Stopper")}</td>
            <td className={`text-center ${cardProps.className}`}>
              {data?.stopper?.target}
            </td>
            <td className={`text-center ${cardProps.className}`}>
              {data?.stopper?.achieved}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default RecruiterTargetTable;
