import Button from "components/common/atoms/Button";
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { ApiCall } from "components/common/services/ApiServices";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE, COMPANY_MICROSERVICE } from "Constants";
import { t, translate } from "components/CentralDataMangement/translation/Translation";
import { Link, useNavigate } from "react-router-dom";
import AutoCompleteDropdown from "components/common/atoms/AutoCompleteDropdown";
import { ValidationRules } from "components/common/utlis/TypeAnnotations";
import { validateForm, validateSelectField } from "components/common/services/ValidationService";
import { CREATE_CONTRACT_TODO, UPDATE_TODO } from "routes/ApiEndpoints";

interface SrcreeningAndMatchingModalProps {
  show: boolean;
  onHide: () => void;
  title?: string;
  className?: string;
  todoId?: any;
  onMatching: () => void;
  candidateId: number | null;
  msg: string;
  roleType: string;
  payRoll?: number;
}

const ScreeningAndMatchingModalPopup: React.FC<
  SrcreeningAndMatchingModalProps
> = ({
  show,
  onHide,
  title,
  className,
  todoId,
  onMatching,
  candidateId,
  msg,
  roleType,
  payRoll
}) => {
    const userAuth = useSelector(selectAuth);
    const [showText, setShowText] = useState<Boolean>(false);
    const [formData, setFormData] = useState({
      company: null
    })

    const navigate = useNavigate();
    const handleClick = () => {
      setShowText(true);
    };
    const handleYes = async (candidateId: number | null) => {
      const todoData = {
        candidateId: candidateId,
        stage: "Matching",
        submitType: "skip"
      };
      const response = await ApiCall.service(
        "updateTodo",
        "POST",
        todoData,
        false,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.status === 200) {
        onHide(); // This will close the modal
        navigate(`/matching-list/candidate/${candidateId}`);
      }
    };
    const handleNo = () => {
      setShowText(false);
      navigate(`/screening/${candidateId}`);
    };

    const [errors, setErrors] = useState<{ [key: string]: string }>({
      company: "",
    });

    const validation = (
      name: string,
      value: string | boolean | object,
      isSingleFieldValidation: boolean = false
    ) => {
      const validationRules: ValidationRules = {
        company: [validateSelectField],
      };

      const validationErrors = validateForm(
        { ...formData, [name]: value },
        validationRules,
        isSingleFieldValidation ? name : undefined
      );

      if (isSingleFieldValidation && Object.keys(errors).length > 0) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: validationErrors[name],
        }));
      } else {
        setErrors(validationErrors);
      }
      if (Object.keys(validationErrors).length > 0) {
        return false;
      }

      return true;
    };

    const handleSelectChange = (selectedOption: any, name: string) => {
      setFormData((prev) => ({
        ...prev,
        [name]: selectedOption
      }))
      validation(name, selectedOption, true);
    }

    const handleSave = async (e: React.FormEvent, candidateId: number | null) => {
      e.preventDefault();
      const { name, value } = e.target as HTMLInputElement;
      if (validation(name, value)) {
        const todoData = {
          candidateId: candidateId,
          stage: "Matching",
          submitType: "skip"
        };
        const response = await ApiCall.service(
          UPDATE_TODO,
          "POST",
          todoData,
          false,
          CENTRAL_DATA_MANAGEMENT_MICROSERVICE
        );
        if (response.status === 200) {
          onHide();
          const data = {
            company: formData.company,
            candidateId: candidateId,
          }
          const contractResponse = await ApiCall.service(
            CREATE_CONTRACT_TODO,
            "POST",
            data,
            false,
            CENTRAL_DATA_MANAGEMENT_MICROSERVICE
          );
          if (contractResponse.status === 200) {
            navigate(`/todos/contract-info-page/${candidateId}/${contractResponse?.data?.todoExtensionId}/${contractResponse?.data?.todoId}/${contractResponse?.data?.presentationId}/""/${contractResponse?.data?.companyId}/9`, {
              state: { origin: 'todos' }
            });
          }
        }
      }
    };

    return (
      <>
        <Modal
          show={show}
          onHide={payRoll === 1 ? () => { } : onHide}
          centered
          className={`${className}`}
          size="lg"
        >
          {payRoll !== 1 &&
            <Modal.Header
              closeButton
              title="Close"
              className="border-0 px-0"
            >
            </Modal.Header>
          }
          <Modal.Body className="px-0 border-0">
            <div className="candidate-popup-submit mx-auto text-center">
              <h5 className="fraunces-italic mb-4">{msg}</h5>
              {payRoll === 0 &&
                <>
                  <div className="mb-3">
                    <Link to={`/screening/${candidateId}`}>
                      <span className="form-button d-flex align-items-center justify-content-center w-75 mx-auto">
                        {t("Screening")}
                      </span>
                    </Link>
                  </div>
                  <div className="mb-3">
                    <button
                      onClick={handleClick}
                      className="form-button d-flex align-items-center justify-content-center w-75 mx-auto"
                    >
                      {t("Matching")}
                    </button>
                    {showText && (
                      <div className="mt-3 bg-white w-75 mx-auto p-3 rounded-3">
                        <div className="pb-3">
                          {t("Do you want to skip screening") + "?"}
                        </div>
                        <div>
                          <Button
                            title={t("Yes")}
                            handleClick={() => handleYes(candidateId)}
                            className="form-button shadow-none me-3"
                          />
                          <Button
                            title={t("No")}
                            handleClick={handleNo}
                            className="delete-btn shadow-none"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="link-color mb-3 d-inline-block">
                    {roleType === "user" ? (
                      <Link to={"/manage-users"}>
                        <div className="text-center color-dark-pink">
                          {t("Do it later")}
                        </div>
                      </Link>
                    ) : (
                      <Link to={"/manage-candidates"}>
                        <div className="text-center color-dark-pink">
                          {t("Do it later")}
                        </div>
                      </Link>
                    )}
                  </div>
                </>
              }
            </div>
            {payRoll === 1 &&
              <div className="col-sm-12">
                <div className="mt-3 bg-white mx-auto p-3 rounded-3">
                  <div className="pb-3">
                    {t("Choose a company to establish a contract for a payroll candidate")}.
                  </div>
                  <div className="col-12 autoCompleteFunction ">
                    <AutoCompleteDropdown
                      label={t("Company")}
                      placeholder={t("Type to search") + "..."}
                      microService={COMPANY_MICROSERVICE}
                      onChange={(e) => handleSelectChange(e, "company")}
                      value={formData.company}
                      columnName="company_name"
                      modelKey="company"
                      id="company"
                      isMandatory={true}
                      name="company"
                      filter={true}
                      filterInfo={[
                        { column: "company_status", value: 1, condition: "where" },
                        { column: "company_status", value: 0, condition: "orWhere" }
                      ]}
                      notNullColumn="bbright_id"
                      error={errors.company}
                    />
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <Button
                        title={t("Confirm")}
                        handleClick={(e) => handleSave(e, candidateId)}
                        className="btn form-button rounded-3 shadow-none me-3 float-end"
                      />
                    </div>
                  </div>
                </div>

              </div>}

          </Modal.Body>
        </Modal>
      </>
    );
  };

export default translate(ScreeningAndMatchingModalPopup);
