import React from "react";

const CompanyContacts = () => {
  return (
    <>
      <svg
        width="45"
        height="45"
        viewBox="0 0 79 79"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="companyContactsIcon"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.800781 66.675H5.30678L5.30778 25.34H32.5648V20.875H0.800781V66.675ZM25.1848 66.675H30.3218L30.3228 56.999H25.1848V66.675ZM16.8358 66.675H21.9728V56.999H16.8358V66.675ZM8.48678 66.675H13.6248V56.999H8.48678V66.675ZM65.0008 40.1952V8.617H39.3128V50.4097C41.0554 47.0209 43.6928 44.1676 46.9128 42.162H43.8078L43.8008 38.291H60.5008V39.1474C62.0596 39.3323 63.566 39.688 65.0008 40.1952ZM60.5008 34.421H43.8078L43.8008 30.551H60.5008V34.421ZM60.5008 26.68H43.8078L43.8008 22.809H60.5008V26.68ZM60.5008 18.939H43.8078L43.8008 15.068H60.5008V18.939ZM25.1848 53.128H30.3218L30.3228 47.968H25.1848V53.128ZM16.8358 53.128H21.9728V47.968H16.8358V53.128ZM8.48678 53.128H13.6248V47.968H8.48678V53.128ZM25.1848 44.742H30.3228V39.582H25.1848V44.742ZM16.8358 44.742H21.9728V39.582H16.8358V44.742ZM8.48678 44.742H13.6248V39.582H8.48678V44.742ZM25.1848 36.356H30.3278L30.3228 36.355V31.195H25.1848V36.356ZM16.8358 36.356H21.9788L21.9728 36.355V31.195H16.8358V36.356ZM8.48678 36.356H13.6308L13.6248 36.355V31.195H8.48678V36.356ZM18.7088 16.356H18.7188L18.7088 16.359V16.356ZM14.4008 16.356H18.7088V5.152H48.5088V0.875H14.4008V16.356Z"
          fill="currentColor"
        />
        <circle
          cx="58"
          cy="59"
          r="17.5"
          stroke="currentColor"
          strokeWidth="3"
        />
        <path
          d="M67.8444 63.0025L64.7337 62.6473C64.368 62.6044 63.9972 62.6449 63.6493 62.7658C63.3015 62.8867 62.9855 63.0849 62.7252 63.3454L60.4718 65.5989C56.9952 63.8307 54.1693 61.0048 52.4011 57.5281L54.6668 55.2624C55.1934 54.7358 55.4506 54.001 55.3649 53.2539L55.0097 50.1677C54.9403 49.5703 54.6536 49.0192 54.2042 48.6195C53.7547 48.2198 53.174 47.9993 52.5726 48H50.4539C49.07 48 47.9188 49.1512 48.0045 50.5351C48.6536 60.994 57.0182 69.3464 67.4648 69.9955C68.8487 70.0812 69.9999 68.93 69.9999 67.5461V65.4274C70.0121 64.1905 69.0814 63.1495 67.8444 63.0025Z"
          fill="currentColor"
        />
      </svg>
    </>
  );
};

export default CompanyContacts;
