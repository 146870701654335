import ViewLoginDetails from "components/IdentityManager/user-login-details/ViewLoginDetails";
import React from "react";
import BudgetsAndTargetsIcon from "static/images/BudgetsAndTargetsIcon";
import Call from "static/images/Call";
import Candidate from "static/images/Candidate";
import CandidateCentralIcon from "static/images/CandidateCentralIcon";
import Company from "static/images/Company";
import CompanyContacts from "static/images/CompanyContacts";
import Configurationsandsettings from "static/images/Configurationsandsettings";
import Contract from "static/images/Contract";
import CooperationAgreementIcon from "static/images/CooperationAgreementIcon";
import DashboardIcon from "static/images/DashboardIcon";
import EmailTemplate from "static/images/EmailTemplate";
import EmployeeTypeIcon from "static/images/EmployeeType";
import FollowUpIcon from "static/images/FollowUpIcon";
import JobApplicationIcon from "static/images/JobApplicationIcon";
import ManualMatchingIcon from "static/images/ManualMatchingIcon";
import MatchingIcon from "static/images/MatchingIcon";
import Meeting from "static/images/Meeting";
import Microphone from "static/images/Microphone";
import NewIcon from "static/images/NewIcon";
import Profile from "static/images/Profile";
import Question from "static/images/Question";
import QuestionIcon from "static/images/QuestionIcon";
import ReportsIcon from "static/images/ReportsIcon";
import RolesIcon from "static/images/Roles";
import SignatureIcon from "static/images/SignatureIcon";
import Staricons from "static/images/Staricons";
import StartFlag from "static/images/StartFlag";
import TaskIcon from "static/images/TaskIcon";
import Translate from "static/images/Translate";
import UsersIcon from "static/images/UsersIcon";
import VacancyIcon from "static/images/VacancyIcon";
import VerifiedUser from "static/images/VerifiedUser";
import ViewLoginDetailsIcon from "static/images/ViewLoginDetailsIcon";

interface props {
  componentName: string;
}

const RenderComponent: React.FC<props> = ({ componentName }) => {
  switch (componentName) {
    case "DashboardIcon":
      return <DashboardIcon />;
    case "Question":
      return <Question />;
    case "MatchingIcon":
      return <MatchingIcon />;
    case "UsersIcon":
      return <UsersIcon />;
    case "EmployeeTypeIcon":
      return <EmployeeTypeIcon />;
    case "CompanyIcon":
      return <Company />;
    case "ConfigurationSettingsIcon":
      return <Configurationsandsettings />;
    case "TranslationIcon":
      return <Translate />;
    case "RolesIcon":
      return <RolesIcon />;
    case "EmailTemplateIcon":
      return <EmailTemplate />;
    case "CandidateIcon":
      return <Candidate />;
    case "VacancyIcon":
      return <VacancyIcon />;
    case "CooperationAgreementIcon":
      return <CooperationAgreementIcon />;
    case "PresentIcon":
      return <Staricons />;
    case "InterviewIcon":
      return <Microphone />;
    case "StartIcon":
      return <StartFlag />;
    case "NewIcon":
      return <NewIcon />;
    case "CallIcon":
      return <Call />;
    case "ScreeningIcon":
      return <VerifiedUser />;
    case "QuestionIcon":
      return <QuestionIcon />;
    case "SignatureIcon":
      return <SignatureIcon />;
    case "BudgetsAndTargets":
      return <BudgetsAndTargetsIcon />;
    case "ProfileIcon":
      return <Profile />;
    case "CompanyContactsIcon":
      return <CompanyContacts />;
    case "CandidateCentralIcon":
      return <CandidateCentralIcon />;
    case "TaskIcon":
      return <FollowUpIcon />;
    case "ManualMatching":
      return <ManualMatchingIcon />;
    case "LoginDetails":
      return <ViewLoginDetailsIcon />;
    case "JobApplication":
      return <JobApplicationIcon />;
    case "Reports":
      return <ReportsIcon />;
    case "SalesNote":
      return <Meeting />;
    case "Contract":
      return <Contract />;
    default:
      return null;
  }
};

export default RenderComponent;
