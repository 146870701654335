import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import EditIcon from "../../../../static/images/EditIcon";
import { Link } from "react-router-dom";
import ModalPopup from "components/common/atoms/ModalPopup";
import { ApiCall } from "components/common/services/ApiServices";
import CustomNotify from "components/common/atoms/CustomNotify";
import { ARCHIVE_LOCATION, MANAGE_LOCATION } from "routes/ApiEndpoints";
import Title from "components/common/atoms/Title";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import LinkTo from "components/common/atoms/LinkTo";
import { useSelector } from "react-redux";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import {
  createQueryParams,
  filterAndMerge,
  formatDateAndTime,
  getQueryParamsFromRedux,
} from "components/common/utlis/HelperFunctions";
import {
  clearDestinationPath,
  setDestinationPath,
} from "features/NavigationSlice";
import { useDispatch } from "react-redux";
import { RootState } from "store";
import SearchFields from "components/common/atoms/SearchFields";
import { createFilterConfig } from "./Filters";
import ResetBtn from "components/common/atoms/ResetBtn";
import NavBar from "components/common/atoms/NavBar";
import ActionIconWrapper from "components/common/atoms/ActionIconWrapper";
import InfoIcon from "static/images/InfoIcon";
import GetQueryParams from "components/common/services/GetQueryParams";
import PaginationNew from "components/common/atoms/PaginationNew";
import Sorting from "components/common/atoms/Sorting";
import InactiveLocation from "static/images/InactiveLocation";
import ActiveLocation from "static/images/ActiveLocation";
import "../location.css";
import SpinnerWrapper from "components/common/atoms/SpinnerWrapper";
import UserInfoTooltip from "components/common/atoms/UserInfoTooltip";
import NoRecords from "components/common/atoms/NoRecords";

export interface LocationData {
  id?: number;
  companyName: string;
  location: string;
  status: string;
  office: string;
  created_by: string;
  updated_by: string;
  updated_at: Date | null;
  created_at: Date | null;
  currentPage: number;
  recordsPerPage: number;
}

interface SearchProps {
  office: number[];
  company: string;
  location: string;
  locationStatus: number | null;
  orderIn: "" | "asc" | "desc";
  currentPage: number;
  recordsPerPage: number;
  orderByColumn: string;
}

export const ManageCompanies: React.FC = () => {
  const dispatch = useDispatch();
  const [deleteId, setDeleteId] = useState<number | undefined>();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState<LocationData[]>([]);
  const [paginationData, setPaginationData] = useState({
    totalPages: 1,
    totalRecords: 0,
  });
  const [dropdownList, setDropdownList] = useState({
    offices: [],
    cities: [],
    compStatus: [],
    pc: [],
  });
  const destinationPath = useSelector(
    (state: RootState) => state.navigation.destinationPath
  );

  const getUrlParams = GetQueryParams();
  const paramFilter = getUrlParams.get("filter");
  const queryParams = getQueryParamsFromRedux(destinationPath, paramFilter);

  const filters = createFilterConfig(dropdownList);

  // Initialize search data based on query params
  const defaultSearchData: SearchProps = {
    office: [],
    company: "",
    location: "",
    locationStatus: 1,
    orderByColumn: "",
    orderIn: "",
    currentPage: 1,
    recordsPerPage: 10,
  };

  //active tab status
  const defaultTabData = {
    tabFieldName: "locationStatus",
    tabFieldValue: 1,
  };

  const filteredSearchData = filterAndMerge(defaultSearchData, queryParams);
  const filteredTabData = filterAndMerge(defaultTabData, queryParams);

  // Initialize search data based on query params, merging with default values
  const [searchData, setSearchData] = useState<SearchProps>({
    ...defaultSearchData,
    ...filteredSearchData,
  });

  const [activeTab, setActiveTab] = useState({
    ...defaultTabData,
    ...filteredTabData,
  });

  const navItems = [
    { label: "Active", fieldName: "locationStatus", value: 1 },
    { label: "Inactive", fieldName: "locationStatus", value: 2 },
  ];

  useEffect(() => {
    const filterData = { ...searchData, ...activeTab };
    const searchUrl = createQueryParams(filterData);
    dispatch(setDestinationPath(searchUrl));
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      fetchDetails(filterData);
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [searchData]);

  const fetchDetails = async (filterData: any) => {
    setLoading(true);
    const data = {
      search: filterData,
    };
    const response = await ApiCall.service(
      MANAGE_LOCATION,
      "POST",
      data,
      false,
      "company-creation"
    );
    if (response.status === 200) {
      const { locationsData, dropdownData, paginationData } = response.data;
      setLocation(locationsData);
      setPaginationData(paginationData);
      setDropdownList(dropdownData);
    }
    setLoading(false);
  };

  //** change handlers */
  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: 1,
      [name]: value,
    }));
  };

  const handlePageChange = (pageNumber: number) => {
    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: pageNumber,
    }));
  };

  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: 1,
      [fieldName]: selectedOption,
    }));
  };

  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    setSearchData((prev) => ({
      ...prev,
      recordsPerPage: newItemsPerPage,
      currentPage: 1,
    }));
  };

  //** Archive functions */
  const deleteLocation = (id: number | undefined) => {
    setShowModal(true);
    setDeleteId(id);
  };

  const handleCloseArchiveModal = () => setShowModal(!showModal);

  const handleArchive = async () => {
    setShowModal(false);
    if (deleteId) {
      const response = await ApiCall.getService(
        `${ARCHIVE_LOCATION}/${deleteId}`,
        "GET",
        "company-creation"
      );
      if (response.status === 200) {
        setLocation((prevdata) =>
          prevdata.filter((location) => location.id !== deleteId)
        );
        CustomNotify({ type: "success", message: response.msg });
      }
    }
  };

  const handleReset = async (e: React.FormEvent) => {
    dispatch(clearDestinationPath());
    setSearchData(defaultSearchData);
    setActiveTab(defaultTabData);
  };

  const handleOrderByColumn = (column: string) => {
    let newOrder: "" | "asc" | "desc" = ""; // Explicit type declaration
    let newColumn = column;

    if (searchData.orderByColumn === column) {
      // Cycle through the three possible order values: '', 'asc', 'desc'
      if (searchData.orderIn === "") {
        newOrder = "asc"; // From '' to 'asc'
      } else if (searchData.orderIn === "asc") {
        newOrder = "desc"; // From 'asc' to 'desc'
      } else {
        newOrder = ""; // From 'desc' to ''
        newColumn = ""; // Reset column if no order
      }
    } else {
      // If it's a new column, start with ascending
      newOrder = "asc";
    }

    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: 1, // Reset pagination on sort change
      orderByColumn: newColumn, // Set the new column or empty string
      orderIn: newOrder, // Set the new order (asc, desc, or empty)
    }));
  };

  const handleNavItemClick = (tabFieldName: string, tabFieldValue: any) => {
    setSearchData((prev) => {
      const updatedState: any = { ...prev };
      updatedState[tabFieldName] = tabFieldValue;
      updatedState["currentPage"] = 1;

      navItems.forEach((item) => {
        if (item.fieldName !== tabFieldName) {
          updatedState[item.fieldName] = null;
        }
      });

      return updatedState;
    });
    setActiveTab({ tabFieldName, tabFieldValue });
  };

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Company",
          read: true,
        },
      ]}
      renderNoAccess={true}
    >
      <div className={`main-container`}>
        <Title title={t("Locations")} />
        <div className="form-height-dashboard">
          <div className="search-bar">
            <div className="row">
              <div className="col-md-12 company-tabs">
                <NavBar
                  navItems={navItems}
                  onNavItemClick={handleNavItemClick}
                  className="custom-navbar"
                  activeItem={activeTab}
                />
              </div>
            </div>
          </div>
          <div className="position-relative tableMainWrapper">
            <div className="row">
              <div className="ManageCreateBtn">
                <AccessControl
                  requiredPermissions={[
                    {
                      permission: "Company",
                      create: true,
                    },
                  ]}
                >
                  <LinkTo
                    pagelink="/location"
                    title={t("Create location")}
                    icon={faPlus}
                    className="me-3"
                  />
                </AccessControl>
              </div>
            </div>
            <div className="table-responsive tableSection">
              <table className="table manageLocations border-0 mb-0">
                <thead>
                  <tr className="tableSearchFields">
                    <SearchFields
                      searchData={searchData}
                      filters={filters}
                      handleSearchChange={handleSearchChange}
                      handleSelectChange={handleSelectChange}
                    />
                    <th className="border-0">
                      <div className="d-flex align-items-center w-100">
                        <ResetBtn handleResetClick={handleReset} />
                      </div>
                    </th>
                  </tr>
                </thead>
              </table>
              <div className="tableContainer scrollBarDesign">
                <table className="table table-hover manageLocations">
                  <thead>
                    <tr className="TableHeader">
                      <th onClick={() => handleOrderByColumn("office")}>
                        {t("Office")}
                        <Sorting
                          sortType={
                            searchData.orderByColumn === "office"
                              ? searchData.orderIn
                              : ""
                          }
                        />
                      </th>
                      <th onClick={() => handleOrderByColumn("company")}>
                        {t("Company")}
                        <Sorting
                          sortType={
                            searchData.orderByColumn === "company"
                              ? searchData.orderIn
                              : ""
                          }
                        />
                      </th>
                      <th onClick={() => handleOrderByColumn("location")}>
                        {t("Location")}
                        <Sorting
                          sortType={
                            searchData.orderByColumn === "location"
                              ? searchData.orderIn
                              : ""
                          }
                        />
                      </th>
                      <AccessControl
                        requiredPermissions={[
                          {
                            permission: "Company",
                            update: true,
                            delete: true,
                          },
                        ]}
                        actions={true}
                        strict={false}
                      >
                        <th>{t("Action")}</th>
                      </AccessControl>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <SpinnerWrapper headerLength={4} />
                    ) : (
                      <>
                        {location && location.length > 0 ? (
                          location.map((location, index) => {
                            return (
                              <tr
                                key={location.id}
                                className="border-bottom align-middle"
                              >
                                <td>
                                  {location.office === " - "
                                    ? ""
                                    : location.office}
                                </td>
                                <td>{location.companyName}</td>
                                <td>{location.location}</td>
                                <AccessControl
                                  requiredPermissions={[
                                    {
                                      permission: "Company",
                                      delete: true,
                                      update: true,
                                    },
                                  ]}
                                  actions={true}
                                  strict={false}
                                >
                                  <td className="table-action-icons">
                                    <ActionIconWrapper initialVisibleCount={4}>
                                      <AccessControl
                                        requiredPermissions={[
                                          {
                                            permission: "Company",
                                            update: true,
                                          },
                                        ]}
                                      >
                                        <Link
                                          to={`/location/${location.id}`}
                                          title={t("Edit")}
                                        >
                                          <EditIcon />
                                        </Link>
                                      </AccessControl>

                                      <AccessControl
                                        requiredPermissions={[
                                          {
                                            permission: "Company",
                                            delete: true,
                                          },
                                        ]}
                                      >
                                        <button
                                          className="btn p-0 border-0 candidate-archive"
                                          title={
                                            searchData.locationStatus === 2
                                              ? t("Activate location")
                                              : t("Deactivate location")
                                          }
                                          onClick={() =>
                                            deleteLocation(location.id)
                                          }
                                        >
                                          {searchData.locationStatus === 2 ? (
                                            <InactiveLocation />
                                          ) : (
                                            <ActiveLocation />
                                          )}
                                        </button>
                                      </AccessControl>
                                      <div>
                                        <UserInfoTooltip
                                          createdBy={location.created_by}
                                          createdAt={formatDateAndTime(
                                            location.created_at
                                          )}
                                          updatedAt={formatDateAndTime(
                                            location.updated_at
                                          )}
                                          updatedBy={location.updated_by}
                                        />
                                      </div>
                                    </ActionIconWrapper>
                                  </td>
                                </AccessControl>
                              </tr>
                            );
                          })
                        ) : (
                          <NoRecords headerLength={10} />
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              {!loading && (
                <div className="pagination d-block">
                  <PaginationNew
                    currentPage={searchData.currentPage}
                    totalPages={paginationData.totalPages}
                    totalRecords={paginationData.totalRecords}
                    recordsPerPage={searchData.recordsPerPage}
                    onItemsPerPageChange={handleItemsPerPageChange}
                    onPageChange={(newPage: any) => handlePageChange(newPage)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className="col-md-6 align-self-center"
          style={{ padding: "1vw 0" }}
        >
          <Link
            to="/manage-companies"
            className=" back-btn text-decoration-underline"
          >
            {t("Back")}
          </Link>
        </div>
        <ModalPopup
          show={showModal}
          onHide={handleCloseArchiveModal}
          title={t("Archive confirmation")}
          body={t("Are you sure want to archive") + "?"}
          onCloseButtonClick={handleCloseArchiveModal}
          onConfirmButtonClick={handleArchive}
          closeTitle={t("No")}
          confirmTitle={t("Yes")}
        />
      </div>
    </AccessControl>
  );
};
export default translate(ManageCompanies);
