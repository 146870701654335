import { Reducer } from "react";
import { FormAction } from "./Actions";
import * as CONST from "../annotations/CoopAgreementConstants";
import {
  AgreementFormData,
  CoeffObject,
} from "../annotations/CoopAgreementInterface";
import { Option } from "components/common/utlis/TypeAnnotations";
import { E164Number } from 'libphonenumber-js';


const FormReducer: Reducer<AgreementFormData, FormAction> = (
  state: any,
  action: any
) => {
  switch (action.type) {
    case CONST.NEXT_STEP:
      const currentTab = state.tabDetails.find((tab: any) => tab.showStatus);
      if (currentTab) {
        const currentIndex = state.tabDetails.findIndex(
          (tab: any) => tab.id === currentTab.id
        );
        let nextIndex = (currentIndex + 1) % state.tabDetails.length;
        if (action.feeBased && nextIndex === 3) {
          nextIndex = 4;
        }
        const updatedTabDetails = state.tabDetails.map((tab: any) => ({
          ...tab,
          showStatus: tab.id === state.tabDetails[nextIndex].id,
        }));

        return {
          ...state,
          tabDetails: updatedTabDetails,
        };
      }
      return state;

    case CONST.PREVIOUS_STEP:
      const currentTabPrev = state.tabDetails.find(
        (tab: any) => tab.showStatus
      );
      if (currentTabPrev) {
        const currentIndexPrev = state.tabDetails.findIndex(
          (tab: any) => tab.id === currentTabPrev.id
        );
        let previousIndex = (currentIndexPrev - 1 + state.tabDetails.length) % state.tabDetails.length;
        if (action.feeBased && currentTabPrev.id === "invoice") {
          previousIndex = 2;
        }

        const updatedTabDetailsPrev = state.tabDetails.map((tab: any) => ({
          ...tab,
          showStatus: tab.id === state.tabDetails[previousIndex].id,
        }));

        return {
          ...state,
          tabDetails: updatedTabDetailsPrev,
        };
      }
      return state;

    case CONST.UPDATE_TAB_DETAILS:
      return {
        ...state,
        tabDetails: action.tabDetails,
      };
    case CONST.UPDATE_TAB_ERROR:
      const { tabIndex, error } = action;
      return {
        ...state,
        tabDetails: state.tabDetails.map((tab: any, index: number) => ({
          ...tab,
          error: index === tabIndex ? error : tab.error,
        })),
      };
    case CONST.SET_DROPDOWN:
      return {
        ...state,
        [action.field]: action.dropdownValues,
      };
    case CONST.UPDATE_GENERAL_FIELD:
      return {
        ...state,
        general: {
          ...state.general,
          [action.field]: action.value,
        },
      };
    case CONST.UPDATE_INVOICE_FIELD:
      return {
        ...state,
        invoice: {
          ...state.invoice,
          [action.field]: action.value,
        },
      };
    case CONST.ADD_COMPOSITION_FIELD:
      return {
        ...state,
        compositionData: action.data,
      };

    case CONST.AM_APPROVED_COMPOSITION_DATA:
      return {
        ...state,
        amApprovedCompositionData: action.data,
      };

    case CONST.UPDATE_COMPOSITION_FIELD:
      return {
        ...state,
        compositionData: state.compositionData.map(
          (item: any) => {
            if (item.wageId === action.index) {
              return {
                ...item,
                [action.field]: action.value,
              };
            } else {
              return item;
            }
          }
        ),
      };

    case CONST.UPDATE_FIELD_ERROR:
      return {
        ...state,
        fieldError: {
          ...state.fieldError,
          [action.fieldName]: action.error,
        },
      };

    case CONST.ADD_WHITECOLLAR_FIELD:
      // Check if there's an item with matching PC value
      const pcId = state.general.paritairWhite.findIndex((item: any) => item?.PC?.value === action.newData?.PC?.value);

      if (pcId !== -1) {
        // If found, update the item
        return {
          ...state,
          general: {
            ...state.general,
            paritairWhite: state.general.paritairWhite.map((item: any, idx: number) => {
              if (idx === pcId) {
                return action.newData;
              }
              return item;
            })
          }
        };
      } else {
        // If not found, add newData as a new item
        return {
          ...state,
          general: {
            ...state.general,
            paritairWhite: [...state.general.paritairWhite, action.newData]
          }
        };
      }

    case CONST.ADD_BLUECOLLAR_FIELD:
      // Check if there's an item with matching PC value
      const index = state.general.paritairBlue.findIndex((item: any) => item?.PC?.value === action.newData?.PC?.value);

      if (index !== -1) {
        // If found, update the item
        return {
          ...state,
          general: {
            ...state.general,
            paritairBlue: state.general.paritairBlue.map((item: any, idx: number) => {
              if (idx === index) {
                return action.newData;
              }
              return item;
            })
          }
        };
      } else {
        // If not found, add newData as a new item
        return {
          ...state,
          general: {
            ...state.general,
            paritairBlue: [...state.general.paritairBlue, action.newData]
          }
        };
      }

    case CONST.REMOVE_WHITE_PC:
      const updatedDetails = [...state.general.paritairWhite];
      updatedDetails.splice(action.indexToRemove, 1);
      return {
        ...state,
        general: {
          ...state.general,
          paritairWhite: updatedDetails,
        },
      };

    case CONST.REMOVE_BLUE_PC:
      const updatedData = [...state.general.paritairBlue];
      updatedData.splice(action.indexToRemove, 1);
      return {
        ...state,
        general: {
          ...state.general,
          paritairBlue: updatedData,
        },
      };

    case CONST.UPDATE_CONTACTS_FIELD:
      return {
        ...state,
        contacts: state.contacts.map((contact: any, index: number) => {
          if (index === action.index) {
            return {
              ...contact,
              [action.field]: action.value,
            };
          }
          return contact;
        }),
      };

    case CONST.UPDATE_CONTACT_LIST:
      const contactIds = action.contactId && action.contactId.map((item: any) => item.value);
      return {
        ...state,
        contacts: state.contacts
          .map((contact: any) => ({
            ...contact,
            type: contactIds?.includes(contact.id) ? 1 : (contact.type !== 2 ? 0 : 2),
          })),
        [action.field]: action.value,
      };

    case CONST.ADD_CONTACT:
      // Add a new contact to the contacts array
      const newContact = {
        fName: "",
        lName: "",
        email: "",
        phNumber: '' as E164Number,
        dob: null,
        gsm: "",
        contact: "",
        functionTitle: "",
        linkedIn: "",
        roles: null,
        language: null,
        teleNumber: "",
        decisionMaker: false,
        influencer: false,
        contactCalled: false,
        contactEmailed: false,
        info: "",
        title: null,
        location: null,
        type: 2,
        id: null,
        allowSignature:state.contacts.length === 0 ? true : false
      };
      const newContactErros = {
        fName: "",
        lName: "",
        email: "",
        phNumber: "",
        teleNumber: "",
        dob: "",
        gsm: "",
        contact: "",
        functionTitle: "",
        linkedIn: "",
        roles: "",
        language: "",
        decisionMaker: "",
        influencer: "",
        contactCalled: "",
        contactEmailed: "",
        info: "",
        title: "",
        location: "",
      };
      return {
        ...state,
        contacts: [...state.contacts, newContact],
        dynamicErrors: [...state.dynamicErrors, newContactErros],
      };

    case CONST.ADD_UPDATE_DYNAMIC:
      if (action.index === state.contacts.length) {
        const newContact = {
          fName: "",
          lName: "",
          email: "",
          phNumber: "",
          teleNumber: "",
          dob: null,
          gsm: "",
          contact: "",
          functionTitle: "",
          linkedIn: "",
          roles: null,
          language: null,
          decisionMaker: false,
          influencer: false,
          contactCalled: false,
          contactEmailed: false,
          info: "",
          title: null,
          location: null,
          type: null,
          id: null,
        };

        const newContactErrors = {
          fName: "",
          lName: "",
          email: "",
          phNumber: "",
          teleNumber: "",
          dob: "",
          gsm: "",
          contact: "",
          functionTitle: "",
          language: "",
          decisionMaker: "",
          influencer: "",
          contactCalled: "",
          contactEmailed: "",
          info: "",
          title: "",
          location: "",
        };

        return {
          ...state,
          contacts: [...state.contacts, newContact],
          dynamicErrors: [...state.dynamicErrors, newContactErrors],
        };
      }

      return {
        ...state,
        contacts: state.contacts.map((contact: any, index: number) =>
          index === action.index
            ? { ...contact, [action.field]: action.value }
            : contact
        ),
      };

    case CONST.REMOVE_CONTACT:
      // Remove the contact at the specified index
      const updatedContacts = [...state.contacts];
      const updatedContactsErrors = [...state.dynamicErrors];
      if (updatedContacts[action.indexToRemove].type === 2) {
        updatedContacts.splice(action.indexToRemove, 1);
        updatedContactsErrors.splice(action.indexToRemove, 1);
      } else {
        // If type is not 1, update type to 0 for that index
        updatedContacts[action.indexToRemove].type = 0;

        // Remove the matched selectedContact
        const selectedContactId = updatedContacts[action.indexToRemove].id;
        const filteredSelectedContacts = state.selectedContact.filter(
          (item: any) => item.value !== selectedContactId
        );

        // Update the state with filtered selectedContact
        return {
          ...state,
          contacts: updatedContacts,
          dynamicErrors: updatedContactsErrors,
          selectedContact: filteredSelectedContacts,
        };
      }
      return {
        ...state,
        contacts: updatedContacts,
        dynamicErrors: updatedContactsErrors,
      };

    case CONST.UPDATE_CONTACTS_FIELD_ERROR:
      return {
        ...state,
        dynamicErrors: state.dynamicErrors.map((error: any, index: number) => {
          if (index === action.index) {
            return {
              ...error,
              [action.field]: action.error,
            };
          }
          return error;
        }),
      };

    case CONST.UPDATE_BILLING_TAB:
      const { newData } = action;

      //removed pcFunctions
      // const { newData, pcFunctions } = action;

      // Create a copy of the current billing array
      let updatedBillingArray = [...state.billing];

      // Loop through each pcItem in newData
      newData.map((pcItem: any) => {
        const { PC, employeeType } = pcItem;

        // to set pcs related function
        // const pcFunction = pcFunctions[PC.value];

        // const updatedPcFunctions =
        //   pcFunction !== undefined && pcFunction.length > 0
        //     ? mapToSelect(pcFunction, "function_name")
        //     : [];

        // Find the existing entry index with the same PC, if any
        const existingEntryIndex = updatedBillingArray.findIndex(
          (item) => item.pc?.value === PC.value
        );

        // If PC exists, update the existing entry at the correct index; otherwise, add the new entry
        if (existingEntryIndex !== -1) {
          updatedBillingArray = updatedBillingArray.filter((item) =>
            newData.some((newItem: any) => newItem.PC.value === item.pc?.value)
          );
          const existingEntry = updatedBillingArray[existingEntryIndex];

          if (existingEntry) {
            const updatedFunctions = existingEntry.functions.map(
              (existingFunction: any) => {
                const matchingEmployeeTypes = existingFunction.employeeTypes
                  .filter((emplType: any) => {
                    if (emplType.empType !== null) {
                      return existingFunction.function?.value === null
                        ? employeeType.some(
                          (item: any) =>
                            item.value === emplType.empType?.value
                        )
                        : emplType;
                    } else {
                      return emplType;
                    }
                  })
                  .map((emplType: any) => {
                    const matchingCoefficients: CoeffObject = {};
                    state.coeffTypes.forEach((item: any) => {
                      const coefficientName: string = item.coeff_name.replace(/\s+/g, "_").toLowerCase();
                      const coefficientValue = emplType.coefficients[0]?.[coefficientName] ?? '';
                      const errorStatusValue = emplType.coefficients[0]?.[`${coefficientName}_error_status`] ?? false;
                      const approvalStatusValue = emplType.coefficients[0]?.[`${coefficientName}_approval_status`] ?? 0;
                      const approvedMinValue = emplType.coefficients[0]?.[`${coefficientName}_min`] ?? "";
                      const acceptOrRejectMail = emplType.coefficients[0]?.['acceptOrRejectMail'] ?? 0;
                      const changeStatusValue = emplType.coefficients[0]?.[`${coefficientName}_changed`] ?? false;

                      matchingCoefficients[coefficientName] = coefficientValue;
                      matchingCoefficients[`${coefficientName}_error_status`] = errorStatusValue;
                      matchingCoefficients[`${coefficientName}_approval_status`] = approvalStatusValue;
                      matchingCoefficients[`${coefficientName}_min`] = approvedMinValue;
                      matchingCoefficients['acceptOrRejectMail'] = acceptOrRejectMail;
                      matchingCoefficients[`${coefficientName}_changed`] = changeStatusValue;
                    });
                    return {
                      ...emplType,
                      coefficients: [matchingCoefficients],
                    };
                  });

                // Return the existingFunction with updated employeeTypes
                return {
                  ...existingFunction,
                  employeeTypes: matchingEmployeeTypes,
                };
              }
            );

            // Copy the existing entry and update its fields
            updatedBillingArray[existingEntryIndex] = {
              ...existingEntry,
              pc: PC,
              empTypeDropdownValues: employeeType,
              // functionDropdownValues: state.functionDropdownList,
              functions: updatedFunctions,
            };
          }
        } else {
          // Add a new entry
          const coefficientObject: CoeffObject = {};
          state.coeffTypes.forEach((item: any) => {
            const coefficientName: string = item.coeff_name
              .replace(/\s+/g, "_")
              .toLowerCase();
            coefficientObject[coefficientName] = "";
            coefficientObject[`${coefficientName}_error_status`] = false;
            coefficientObject[`${coefficientName}_min`] = '';
            coefficientObject[`${coefficientName}_approval_status`] = 0;
            coefficientObject['acceptOrRejectMail'] = 0;
            coefficientObject[`${coefficientName}_changed`] = false;
          });
          const defaultItem = {
            pc: PC,
            empTypeDropdownValues: employeeType,
            // functionDropdownValues: state.functionDropdownList,
            functions: [
              {
                function: null,
                status: 1,
                funcErrorStatus: false,
                funcChanged: false,
                referenceId: null,
                employeeTypes: [
                  {
                    empType: null,
                    status: 1,
                    empTypeErrorStatus: false,
                    level: null,
                    levelErrorStatus: false,
                    levelChanged: false,
                    empTypeChanged: false,
                    startDate: new Date(),
                    startDateChanged: false,
                    brightId: null,
                    referenceId: null,
                    coefficients: [coefficientObject],
                  },
                ],
              },
            ],
          };
          if (updatedBillingArray.length === 0) {
            // Add the first item with retentionCount and retentionPeriod
            updatedBillingArray.push({
              retentionCount: 135,
              retentionPeriod: { 'value': 2, 'label': 'Days' },
              ...defaultItem,
            });
          } else {
            // Add a new item
            updatedBillingArray.push({ ...defaultItem });
          }
        }

        // Update the state with the updated array
        const initialAmApprovedData = state.amApprovedData.length > 0 ? state.amApprovedData : updatedBillingArray;

        return {
          ...state,
          billing: updatedBillingArray,
          amApprovedData: initialAmApprovedData
        };
      });
      const initialAmApprovedData = state.amApprovedData.length > 0 ? state.amApprovedData : updatedBillingArray;

      return {
        ...state,
        billing: newData.length > 0 ? updatedBillingArray : [],
        amApprovedData: newData.length > 0 ? initialAmApprovedData : []
      };

    case CONST.UPDATE_BILLING_TAB_FIELD:
      const { field, value, empRowIndex, coeffIndex } = action;

      return {
        ...state,
        billing: state.billing.map((item: any, i: number) => {
          if (i === action.pcIndex) {
            let updateRetention = { ...item };
            if (field === "retentionCount" || field === "retentionPeriod") {
              updateRetention = {
                ...updateRetention,
                [field]: value,
              };
            }
            return {
              ...updateRetention,
              functions: item.functions.map((func: any, j: number) => {
                if (j === action.funcIndex) {
                  // Update the field in the function
                  let updatedFunc = { ...func };

                  // Update the field in the function
                  if (field === "function" || field === "funcErrorStatus" || field === 'funcChanged') {
                    updatedFunc = {
                      ...updatedFunc,
                      [field]: value,
                    };
                  }

                  // Update the field in each employeeType
                  const updatedEmployeeTypes = func.employeeTypes.map(
                    (empRow: any, k: number) => {
                      if (k === empRowIndex) {
                        let updatedEmp = { ...empRow };
                        if (
                          field === "empType" ||
                          field === "empTypeErrorStatus" ||
                          field === "level" ||
                          field === "levelErrorStatus" ||
                          field === "startDate" ||
                          field === 'empTypeChanged' ||
                          field === 'levelChanged' ||
                          field === 'startDateChanged'
                        ) {
                          updatedEmp = {
                            ...updatedEmp,
                            [field]: value as Option | null | boolean,
                          };
                        }
                        return {
                          ...updatedEmp,
                          coefficients: empRow.coefficients.map(
                            (coeff: any, l: number) => {
                              if (l === coeffIndex) {
                                return {
                                  ...coeff,
                                  [field]: value,
                                };
                              }
                              return coeff;
                            }
                          ),
                        };
                      }
                      return empRow;
                    }
                  );

                  // Return the updated function with updated employeeTypes
                  return {
                    ...updatedFunc,
                    employeeTypes: updatedEmployeeTypes,
                  };
                }
                return func;
              }),
            };
          }
          return item;
        }),
      };

    case CONST.SET_BILLING_TAB_DATA:
      return {
        ...state,
        billing: action.data,
      };

    case CONST.AM_APPROVED_DATA:
      return {
        ...state,
        amApprovedData: action.data,
      };

    case CONST.ADD_EMPLOYEE_TYPE:
      const empCoefficientObject: CoeffObject = {};
      state.coeffTypes.forEach((item: any) => {
        const coefficientName: string = item.coeff_name
          .replace(/\s+/g, "_")
          .toLowerCase();
        empCoefficientObject[coefficientName] = "";
        empCoefficientObject[`${coefficientName}_error_status`] = false;
        empCoefficientObject[`${coefficientName}_approval_status`] = 0;
        empCoefficientObject[`${coefficientName}_min`] = "";
        empCoefficientObject['acceptOrRejectMail'] = 0;
        empCoefficientObject[`${coefficientName}_changed`] = false;
      });
      const { pcIndex, funcIndex } = action;
      const newFields = {
        empType: null,
        status: 1,
        empTypeErrorStatus: false,
        level: null,
        levelErrorStatus: false,
        levelChanged: false,
        empTypeChanged: false,
        startDate: new Date(),
        startDateChanged: false,
        brightId: null,
        referenceId: null,
        coefficients: [empCoefficientObject],
      };

      return {
        ...state,
        billing: state.billing.map((pc: any, i: number) => {
          if (i === pcIndex) {
            return {
              ...pc,
              functions: pc.functions.map((func: any, j: number) => {
                if (j === funcIndex) {
                  return {
                    ...func,
                    employeeTypes: [...func.employeeTypes, newFields],
                  };
                }
                return func;
              }),
            };
          }
          return pc;
        }),
      };

    case CONST.CLONE_FUNCTION_TYPE:
      const clonedFunction = {
        ...state.billing[action.pcIndex].functions[action.funcIndex],
        function: null,
      };

      return {
        ...state,
        billing: state.billing.map((pc: any, i: number) => {
          if (i === action.pcIndex) {
            return {
              ...pc,
              functions: [...pc.functions, clonedFunction],
            };
          }
          return pc;
        }),
      };

    case CONST.DELETE_FUNCTION_TYPE:
      return {
        ...state,
        billing: state.billing.map((pc: any, i: number) => {
          if (i === action.pcIndex) {
            const updatedFunctions = pc.functions.filter(
              (func: any, j: number) => j !== action.funcIndex
            );

            return {
              ...pc,
              functions: updatedFunctions,
            };
          }
          return pc;
        }),
      };

    case CONST.DELETE_EMPLOYEE_TYPE:
      return {
        ...state,
        billing: state.billing.map((pc: any, i: number) => {
          if (i === action.pcIndex) {
            return {
              ...pc,
              functions: pc.functions.map((func: any, j: number) => {
                if (j === action.funcIndex) {
                  return {
                    ...func,
                    employeeTypes: func.employeeTypes.filter(
                      (emplType: any, k: number) => k !== action.empRowIndex
                    ),
                  };
                }
                return func;
              }),
            };
          }
          return pc;
        }),
      };

    case CONST.ADD_FUNCTION_PROFILE:
      const funcCoefficientObject: CoeffObject = {};
      state.coeffTypes.forEach((item: any) => {
        const coefficientName: string = item.coeff_name
          .replace(/\s+/g, "_")
          .toLowerCase();
        funcCoefficientObject[coefficientName] = "";
        funcCoefficientObject[`${coefficientName}_error_status`] = false;
        funcCoefficientObject[`${coefficientName}_approval_status`] = 0;
        funcCoefficientObject[`${coefficientName}_min`] = "";
        funcCoefficientObject['acceptOrRejectMail'] = 0;
        funcCoefficientObject[`${coefficientName}_changed`] = false;
      });
      const newFunctionFields = {
        function: null,
        status: 1,
        funcErrorStatus: false,
        funcChanged: false,
        referenceId: null,
        employeeTypes: [
          {
            empType: null,
            status: 1,
            empTypeErrorStatus: false,
            level: null,
            levelErrorStatus: false,
            levelChanged: false,
            empTypeChanged: false,
            startDate: new Date(),
            startDateChanged: false,
            brightId: null,
            referenceId: null,
            coefficients: [funcCoefficientObject],
          },
        ],
      };
      return {
        ...state,
        billing: state.billing.map((pc: any, i: any) => {
          if (i === action.pcIndex) {
            return {
              ...pc,
              functions: [...pc.functions, newFunctionFields],
            };
          }
          return pc;
        }),
      };

    case CONST.UPDATE_FEEBASED_FIELD: {
      const { fieldName, index, value } = action;
      const updatedFeeBased = [...state.general.feeBased];
      updatedFeeBased[index] = { ...updatedFeeBased[index], [fieldName]: value };
      return {
        ...state,
        general: {
          ...state.general,
          feeBased: updatedFeeBased
        }
      };
    };

    case CONST.ADD_FEEBASED_FIELD: {
      const { newData } = action;
      const newError = {
        percentage: "",
        count: "",
        feeBasedType: ""
      };
      return {
        ...state,
        general: {
          ...state.general,
          feeBased: [...state.general.feeBased, newData]
        },
        feeBasedErrors: [
          ...state.feeBasedErrors,
          newError
        ]
      };
    };

    case CONST.REMOVE_FEEBASED_FIELD: {
      const { index } = action;

      const updatedList = [...state.general.feeBased];
      updatedList.splice(index, 1);
      const updatedErrors = [...state.feeBasedErrors];
      updatedErrors.splice(index, 1);

      return {
        ...state,
        general: {
          ...state.general,
          feeBased: updatedList,
        },
        feeBasedErrors: updatedErrors,
      };
    };

    case CONST.UPDATE_FEEBASED_ERROR: {
      const { index, fieldName, error } = action;

      const updatedErrors = [...state.feeBasedErrors];
      updatedErrors[index] = {
        ...updatedErrors[index],
        [fieldName]: error,
      };

      return {
        ...state,
        feeBasedErrors: updatedErrors,
      };
    }

    case CONST.SET_FEEBASED:
      return {
        ...state,
        general: {
          ...state.general,
          feeBased: [{
            id: null,
            percentage: null,
            feeBasedType: null,
            count: null
          }],
        },
      };
    case CONST.FEEBASED_ERROR_STATUS:
      return {
        ...state,
        feeBasedErrorStatus: action.status
      };

    default:
      return state;
  }
};

export default FormReducer;
