import React, { ReactNode } from 'react';
interface Props {
  title: ReactNode;
  icon?: string;
  className?: string;
}
const Title: React.FC<Props> = ({ title, icon, className }) => {
  return (
    <>
      <div className={`header-sticky position-sticky ${className}`}>
        <div className="row pb-1">
          <div className="col-md-12">
            <h1 className="pb-xxl-4 pb-3 page-title mb-0 d-flex align-items-center">
              {icon && <img className="diamond-icon me-2" src={icon} width={35} height={35}></img>
              }
              {title}
            </h1>
          </div>
        </div>
      </div>
    </>
  )
}

export default Title