import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Title from "components/common/atoms/Title";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import Search from "components/common/atoms/Search";
import Button from "components/common/atoms/Button";
import Reset from "static/images/Reset";
import Pagination from "components/common/atoms/Pagination";
import { ApiCall } from "components/common/services/ApiServices";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { CONF_SETTINGS_GET } from "routes/ApiEndpoints";
import EditIcon from "static/images/EditIcon";
import ViewIcon from "static/images/ViewIcon";
import LabelField from "components/common/atoms/LabelField";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import ResetBtn from "components/common/atoms/ResetBtn";
import NoRecords from "components/common/atoms/NoRecords";

/**
 * this will project all the partire committees.
 * @returns
 */

const ManagePc: React.FC = () => {
  const [data, setData] = useState([]);
  const [temp, setTemp] = useState([]);

  const [searchData, setSearchData] = useState({
    number: "",
    name: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setItemOffset] = useState(0);
  const [totalPages, setTotalPages] = useState(Number);
  const [loading, setLoading] = useState(true);

  const recordsPerPage = 10;

  useEffect(() => {
    fetchParitairCommitees();
  }, []);

  //API call for to fetch partire committees.
  const fetchParitairCommitees = async () => {
    const data = {
      type: "paritairCommittee",
    };

    const response = await ApiCall.service(
      CONF_SETTINGS_GET,
      "POST",
      data,
      true,
      "central-data-management"
    );

    if (response.status === 200) {
      setLoading(true);
      setData(response.data);
      setTemp(response.data);
      setPages(1, response.data.length);
      setLoading(false);
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSearchData({ ...searchData, [name]: value });
  };

  const handleReset = () => {
    setSearchData({
      number: "",
      name: "",
    });
    setData(temp);
    setPages(1, temp.length);
  };

  const handleSearch = () => {
    interface PC {
      pc_alias_name: string;
      pc_name: string | null;
      pc_number: string;
    }

    var res: any = [];
    if (searchData.name !== "" && searchData.number !== "") {
      temp.map((val: PC) => {
        if (
          val.pc_number.trim().includes(searchData.number.trim()) &&
          val.pc_alias_name
            .trim()
            .toLowerCase()
            .includes(searchData.name.trim().toLowerCase())
        ) {
          res.push(val);
        } else if (
          // val.pc_alias_name == null &&
          val.pc_name != null &&
          val.pc_number.trim().includes(searchData.number.trim()) &&
          val.pc_name
            .trim()
            .toLowerCase()
            .includes(searchData.name.trim().toLowerCase())
        ) {
          res.push(val);
        }
      });
      setData(res);
      setPages(1, res.length);
    } else if (searchData.number !== "") {
      temp.map((val: PC) => {
        if (val.pc_number.trim().includes(searchData.number.trim())) {
          res.push(val);
        }
      });
      setData(res);
      setPages(1, res.length);
    } else if (searchData.name !== "") {
      temp.map((val: PC) => {
        if (
          val.pc_alias_name
            .trim()
            .toLowerCase()
            .includes(searchData.name.trim().toLowerCase())
        ) {
          res.push(val);
        } else if (
          val.pc_name !== null &&
          val.pc_name
            .trim()
            .toLowerCase()
            .includes(searchData.name.trim().toLowerCase())
        ) {
          res.push(val);
        }
      });
      setData(res);
      setPages(1, res.length);
    }
  };

  const setPages = (pgNo: number, length?: number) => {
    let pages: number;
    let newOffset: number;
    if (length === undefined) {
      pages = Math.ceil(data.length / recordsPerPage);
      newOffset = ((pgNo - 1) * recordsPerPage) % data.length;
    } else {
      pages = Math.ceil(length / recordsPerPage);
      newOffset = ((pgNo - 1) * recordsPerPage) % length;
    }
    setItemOffset(newOffset);
    setCurrentPage(pgNo);
    setTotalPages(pages);
  };

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Paritair committe",
          read: true,
        },
      ]}
      renderNoAccess={true}
    >
      <Title title={t("Paritair committees")} />
      <div className="row search-bar paddingBottom1">
        <div className="col-4">
          <LabelField title={t("PC number")} />
          <Search
            className="form-control"
            handleChange={handleSearchChange}
            name="number"
            value={searchData.number}
          />
        </div>
        <div className="col-4">
          <LabelField title={t("PC name")} />
          <Search
            className="form-control"
            handleChange={handleSearchChange}
            name="name"
            value={searchData.name}
          />
        </div>
        <div className="col-4 mt-34">
          <div className="d-flex align-items-center">
            <ResetBtn handleResetClick={handleReset} />
            <Button
              title={t("Search")}
              type="submit"
              handleClick={handleSearch}
              icon={faMagnifyingGlass}
              className="form-button shadow-none search-btns position-relative text-start"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="tableSection table-responsive">
            <table className="table table-hover">
              <thead>
                <tr className="TableHeader">
                  <th style={{ width: "70%" }}>{t("PC name")}</th>
                  <AccessControl
                    requiredPermissions={[
                      {
                        permission: "Paritair committe",
                        update: true,
                        delete: true,
                      },
                    ]}
                    actions={true}
                    strict={false}
                  >
                    <th>{t("Actions")}</th>
                  </AccessControl>
                </tr>
              </thead>
              <tbody>
                {data && data.length > 0 ? (
                  data
                    .slice(offset, offset + recordsPerPage)
                    .map((pc, index) => (
                      <tr
                        key={index}
                        className="border mb-3 box-shadow align-middle"
                      >
                        {/* <td>{offset + index + 1}</td> */}
                        <td data-label={t("PC name")}>
                          {`(${pc["pc_number"]})` +
                            t(
                              `${pc["pc_name"]} - ${
                                pc["type"] === 1 ? "Bediende" : "Arbeider"
                              }`
                            )}
                        </td>
                        <AccessControl
                          requiredPermissions={[
                            {
                              permission: "Paritair committe",
                              update: true,
                              delete: true,
                              read: true,
                            },
                          ]}
                          actions={true}
                          strict={false}
                        >
                          <td className="table-action-icons px-2">
                            <AccessControl
                              requiredPermissions={[
                                {
                                  permission: "Paritair committe",
                                  update: true,
                                },
                              ]}
                            >
                              <Link
                                to={"/editpc/" + pc["id"]}
                                className="marginRightPoint5"
                                title={t("Edit paritair committee")}
                              >
                                <EditIcon />
                              </Link>
                            </AccessControl>
                            <AccessControl
                              requiredPermissions={[
                                {
                                  permission: "Paritair committe",
                                  read: true,
                                },
                              ]}
                            >
                              <Link
                                to={"/viewpc/" + pc["id"]}
                                title={t("View paritair committee")}
                              >
                                <ViewIcon />
                              </Link>
                            </AccessControl>
                          </td>
                        </AccessControl>
                      </tr>
                    ))
                ) : (
                  <NoRecords headerLength={7} />
                )}
              </tbody>
            </table>
            <div className="pagination justify-content-center align-items-center">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={setPages}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row backPadding">
        <div className="col-md-6 align-self-center">
          <Link
            to="/config/settings"
            className=" back-btn text-decoration-underline"
          >
            {t("Back")}
          </Link>
        </div>
      </div>
    </AccessControl>
  );
};

export default translate(ManagePc);
