import { CONF_SETTINGS_GET, CONF_SETTINGS_UPDATE } from "routes/ApiEndpoints";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "Constants";
import { ApiCall } from "components/common/services/ApiServices";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import CheckBoxField from "components/common/atoms/CheckBoxField";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import RadioField from "components/common/atoms/RadioField";
import LabelField from "components/common/atoms/LabelField";
import Calender from "components/common/molecules/Calender";
import Title from "components/common/atoms/Title";
import Button from "components/common/atoms/Button";
import LoadingIcon from "components/common/utlis/LoadingIcon";
import CustomNotify from "components/common/atoms/CustomNotify";
import CloseFile from "static/images/CloseFile";
import { Option, OptionProps } from "components/common/utlis/TypeAnnotations";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import "./paritaircommittee.css";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import MealVoucherNEcoCheque from "./MealVoucherNEcoCheque";

interface SalaryBenefitProps {
  id: number | null;
  befitLabel: string;
  benefitId: string | null;
  pcId: string | undefined;
  perfCodeId: string | null;
  isMandatory: number | null;
  salesCanChange: number | null;
  status: number | null;
  startDate: Date | null;
  occurence: Option | null;
  isVariable: boolean;
  benefitValue: string | null;
  type: string;
}

interface Props {
  edit?: boolean;
}

interface EcoCheque {
  pcId: number | string | null;
  isEcoCheque: number | null;
  value: string | number;
  chequeType: string | number | null;
  otherInfo: string;
}

interface MealVoucher {
  pcId: number | string | null;
  totalValue: string;
  employerPart: string;
  minimumHours: string;
}

export interface EcoChequeNMealVoucherData {
  ecoCheque: EcoCheque;
  mealVoucher: MealVoucher;
}

const SalaryBenefit: React.FC<Props> = ({ edit = false }) => {
  const { id } = useParams<{ id: string }>();
  const [perfCodes, setPerfcodes] = useState<any[]>([]);
  const [temp, setTempPerfcodes] = useState<any[]>([]);
  const [benefits, setBenifits] = useState([]);
  const navigate = useNavigate();
  const [minDate, setMinDate] = useState(() => {
    let date = new Date();
    return date.setMonth(date.getMonth() - 1);
  });
  const [loading, setLoading] = useState(false);
  const initialErrors: { [key: string]: string } = {};
  const [errors, setErrors] = useState<{ [key: string]: string }>(
    initialErrors
  );
  const [salaryBenefit, setSalaryBenefit] = useState<SalaryBenefitProps[]>([]);
  const [benefitsToDelete, setBenefitsToDelete] = useState<number[]>([]);
  const [altBenefits, setAltBenefits] = useState<OptionProps[]>([]);
  const initialData: EcoChequeNMealVoucherData = {
    ecoCheque: {
      pcId: id ?? null,
      isEcoCheque: null,
      value: "",
      chequeType: null,
      otherInfo: "",
    },
    mealVoucher: {
      pcId: id ?? null,
      totalValue: "",
      employerPart: "",
      minimumHours: "",
    },
  };
  const [ecoChequeNMealVoucher, setEcoChequeNMealVoucher] =
    useState<EcoChequeNMealVoucherData>(initialData);

  const options = [
    { value: 1, label: t("Hour") },
    { value: 2, label: t("Day") },
    { value: 3, label: t("Week") },
    { value: 4, label: t("Contract") },
  ];

  useEffect(() => {
    fetchSalaryBenefit();
  }, []);

  const fetchSalaryBenefit = async () => {
    const data = {
      id: id,
      type: "salaryBenefit",
    };

    const response = await ApiCall.service(
      CONF_SETTINGS_GET,
      "POST",
      data,
      true,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );

    if (response.status === 200) {
      setPerfcodes(response.data["perfcodes"]);
      setTempPerfcodes(response.data["perfcodes"]);
      setBenifits(response.data["benefits"]);
      setAltBenefits(response.data?.["altBenefits"]);
      if ("ecoCheque" in response.data) {
        setEcoChequeNMealVoucher((prevData) => ({
          ...prevData,
          ecoCheque: response.data.ecoCheque,
        }));
      }
      if ("mealVoucher" in response.data) {
        setEcoChequeNMealVoucher((prevData) => ({
          ...prevData,
          mealVoucher: response.data.mealVoucher,
        }));
      }
    }
  };

  useEffect(() => {
    let salBenifts: SalaryBenefitProps[] = [];
    const idsToRemove: any[] = [];
    if (benefits.length > 0) {
      benefits.forEach((element: any) => {
        const perfcodeIndex = temp.findIndex(
          (obj) => obj.value === element.salary_benefit_main_id
        );
        const benefit = temp[perfcodeIndex];

        salBenifts = [
          ...salBenifts,
          {
            id: element["id"],
            befitLabel: benefit.label,
            benefitId: benefit.value,
            pcId: element["paritaircommittee_id"],
            perfCodeId: element["salary_benefit_main_id"],
            isMandatory: element["is_mandatory"],
            salesCanChange: element["is_sales_can_change"],
            status: element["status"],
            startDate: new Date(element["start_date"]),
            occurence: options[element["occurence"] - 1],
            isVariable: benefit.is_value === 1 ? true : false,
            benefitValue:
              benefit.is_value === 1
                ? element["benefit_value"].replace(/\./g, ",")
                : null,
            type: benefit.variable_type === "" ? null : benefit.variable_type,
          },
        ];
        idsToRemove.push(element.salary_benefit_main_id);
      });
      setPerfcodes((prevTemp) =>
        prevTemp.filter((obj) => !idsToRemove.includes(obj.value))
      );
      setSalaryBenefit(salBenifts);
    }
  }, [benefits]);

  const handleSelectChange = (selected: any) => {
    if (selected?.[0]) {
      const filteredObjects = benefits.filter(
        (obj) => obj["salary_benefit_main_id"] === selected[0].value
      );

      const perfcodeIndex = temp.findIndex(
        (obj) => obj.value === selected[0].value
      );
      const benefit = temp[perfcodeIndex];
      setPerfcodes((prevData) =>
        prevData.filter((record) => record.value !== selected[0].value)
      );

      let addBenifit: SalaryBenefitProps = {
        id: null,
        befitLabel: selected[0].label,
        benefitId: selected[0].value,
        pcId: id,
        perfCodeId: selected[0].value,
        isMandatory: 0,
        salesCanChange: 0,
        status: 0,
        startDate: new Date(),
        occurence: null,
        isVariable: benefit.is_value === 1 ? true : false,
        benefitValue: "",
        type: benefit.variable_type === "" ? null : benefit.variable_type,
      };

      if (filteredObjects.length > 0) {
        setBenefitsToDelete((prevArray) =>
          prevArray.filter((value) => value !== filteredObjects[0]["id"])
        );
        addBenifit = {
          id: filteredObjects[0]["id"],
          befitLabel: selected[0].label,
          benefitId: selected[0].value,
          pcId: filteredObjects[0]["paritaircommittee_id"],
          perfCodeId: filteredObjects[0]["salary_benefit_main_id"],
          isMandatory: filteredObjects[0]["is_mandatory"],
          salesCanChange: filteredObjects[0]["is_sales_can_change"],
          status: filteredObjects[0]["status"],
          startDate: new Date(filteredObjects[0]["start_date"]),
          occurence: options[filteredObjects[0]["occurence"] - 1],
          isVariable: benefit.is_value === 1 ? true : false,
          benefitValue:
            filteredObjects[0]["benefit_value"] == null
              ? ""
              : String(filteredObjects[0]["benefit_value"]).replace(/\./g, ","),
          type: benefit.variable_type === "" ? null : benefit.variable_type,
        };
      }
      setSalaryBenefit([...salaryBenefit, addBenifit]);
    }
  };

  const handleFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { name, value, type } = event.target;
    let updatedValue: string | number | null;
    let updatedName: string;
    if (type === "radio") {
      updatedName = "status";
      updatedValue = parseInt(value);
    } else if (type === "checkbox") {
      updatedName = name;
      updatedValue = (event.target as HTMLInputElement).checked ? 1 : 0;
    } else {
      updatedName = name;
      updatedValue = value;
    }

    setSalaryBenefit((prevData) => {
      return prevData.map((obj, i) => {
        if (i === index) {
          return {
            ...obj,
            [updatedName]: updatedValue,
          };
        }
        return obj;
      });
    });
  };

  const changeHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
    type: string
  ) => {
    const { name, value } = event.target as HTMLInputElement;
    let update = false;

    if (type === "amount") {
      const regex = /^(\d{0,2})(,\d{0,4})?$/;
      let matches = regex.exec(value);
      if (matches) {
        update = true;
      }
    } else {
      const regex = /^(100(\.0{1,2})?|\d{0,2}(\.\d{1,2})?)$/;
      let matches = regex.exec(value);
      if (matches) {
        update = true;
      } else if (value === null) {
        update = true;
      }
    }

    if (update) {
      setSalaryBenefit((prevData) => {
        return prevData.map((obj, i) => {
          if (i === index) {
            return {
              ...obj,
              [name]: value,
            };
          }
          return obj;
        });
      });
    }

    clearError(name, index);
  };

  const handleDateChange = (
    date: Date | null,
    fieldName: string,
    index: number
  ) => {
    setSalaryBenefit((prevData) => {
      return prevData.map((obj, i) => {
        if (i === index) {
          return {
            ...obj,
            [fieldName]: date,
          };
        }
        return obj;
      });
    });

    clearError(fieldName, index);
  };

  const handleOccurenceChange = (
    selectedOption: any,
    name: string,
    index: number
  ) => {
    setSalaryBenefit((prevData) => {
      return prevData.map((obj, i) => {
        if (i === index) {
          return {
            ...obj,
            [name]: selectedOption,
          };
        }
        return obj;
      });
    });

    if (selectedOption.label !== "Select") {
      clearError(name, index);
    }
  };

  const removeFieldSet = (index: number, benefitId: string | null): void => {
    const perfcodeIndex = temp.findIndex((obj) => obj.value === benefitId);
    const benefit = temp[perfcodeIndex];
    setPerfcodes((prevObjects) => [
      ...prevObjects.slice(0, perfcodeIndex),
      benefit,
      ...prevObjects.slice(perfcodeIndex),
    ]);

    if (salaryBenefit[index]["id"] !== null) {
      setBenefitsToDelete((prevIds: number[]) => [
        ...prevIds,
        salaryBenefit[index]["id"] || 0,
      ]);
    }

    setSalaryBenefit((prevValues) => {
      const newValues = [...prevValues];
      newValues.splice(index, 1);
      return newValues;
    });
  };

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>
  ) => {
    setLoading(true);
    e.preventDefault();

    // Validation checks
    let hasErrors = false;
    const newErrors: { [key: string]: string } = {};

    salaryBenefit.forEach((benefit, index) => {
      if (
        benefit.occurence === null ||
        benefit.occurence?.label === "" ||
        benefit.occurence?.label === "Select"
      ) {
        newErrors[`occurence${index}`] = t("Occurrence is required");
        hasErrors = true;
      }

      if (benefit.startDate === null) {
        newErrors[`startDate${index}`] = t("Start date is required");
        hasErrors = true;
      }

      if (
        benefit.isVariable &&
        (benefit.benefitValue === "" || benefit.benefitValue == null)
      ) {
        newErrors[`benefitValue${index}`] = t("Benefit is required");
        hasErrors = true;
      }
    });

    if (
      ecoChequeNMealVoucher.ecoCheque.chequeType === 5 &&
      (ecoChequeNMealVoucher.ecoCheque?.otherInfo === "" ||
        ecoChequeNMealVoucher.ecoCheque?.otherInfo == null)
    ) {
      newErrors[`otherInfo`] = t("It is a required field");
      hasErrors = true;
    }

    if (hasErrors) {
      setErrors(newErrors);
      setLoading(false);
      return;
    }

    const data = {
      type: "salaryBenefit",
      deleteIds: benefitsToDelete,
      benefits: salaryBenefit,
      ...ecoChequeNMealVoucher,
    };
    let response = await ApiCall.service(
      CONF_SETTINGS_UPDATE,
      "POST",
      data,
      false,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );

    if (response.status === 200) {
      CustomNotify({ type: "success", message: t(response.msg) });
      navigate(-1);
    } else {
      CustomNotify({ type: "error" });
    }
    setLoading(false);
  };

  const clearError = (fieldName: string, index: number) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[`${fieldName}${index}`];
      return newErrors;
    });
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >,
    dataOf: "ecoCheque" | "mealVoucher"
  ) => {
    const { name, value, type } = e.target;
    let updatedValue: string | number | null;

    if (type === "radio") {
      updatedValue = parseInt(value);
      setEcoChequeNMealVoucher((prevData) => ({
        ...prevData,
        [dataOf]: { ...initialData.ecoCheque, [name]: updatedValue },
      }));
    }

    if ((value === "" || !/[a-zA-Z]/.test(value)) && type === "text") {
      updatedValue = value;
      setEcoChequeNMealVoucher((prevData) => ({
        ...prevData,
        [dataOf]: { ...prevData[dataOf], [name]: updatedValue },
      }));
    }

    if (type === "textarea") {
      setEcoChequeNMealVoucher((prevData) => ({
        ...prevData,
        [dataOf]: { ...prevData[dataOf], [name]: value },
      }));
    }
  };

  const onChangeHandler = (selected: any) => {
    setEcoChequeNMealVoucher((prevData) => ({
      ...prevData,
      ecoCheque: { ...prevData.ecoCheque, chequeType: selected.value },
    }));
  };

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Paritair committe",
          read: true,
        },
      ]}
      renderNoAccess={true}
    >
      <div className="px-4">
        <Title title={t(`${edit ? "Edit" : "View"} salary benefit`)} />
        {edit && (
          <div
            className="row select-bar-salary paddingBottom1 search-bar"
            style={{ paddingTop: "0.1vw" }}
          >
            <div className="col-md-12">
              <SelectWithSearch
                search={true}
                onChange={handleSelectChange}
                title={""}
                options={perfCodes}
                placeHolder={"Select benefit"}
                isMulti={true}
                name={""}
                isMandatory={false}
                value={""}
                isTranslate={true}
              />
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-12 marginBotttom1">
            <MealVoucherNEcoCheque
              data={ecoChequeNMealVoucher}
              ecoChequeOptions={altBenefits}
              handleChange={handleChange}
              onChange={onChangeHandler}
              errors={errors}
            />
          </div>
          <div className="col-12">
            <div>
              {salaryBenefit.length > 0 && (
                <div className="paddingBottom1">
                  {salaryBenefit !== undefined &&
                    salaryBenefit.map((benefit, index) => (
                      <div className="form-border marginBotttom1" key={index}>
                        <div className="row">
                          <div className="col-md-6 tab-subtitle">
                            {benefit.befitLabel}
                          </div>
                          <div className="col-md-6">
                            {edit && (
                              <span
                                title={t("Remove")}
                                onClick={() =>
                                  removeFieldSet(index, benefit.benefitId)
                                }
                                className="table-action-icons cursor-pointer float-end  rounded-0 shadow-none"
                              >
                                <CloseFile />
                              </span>
                            )}
                          </div>
                          <div className="col-md-4 marginBotttom1 PcSalaryBenefits">
                            <CheckBoxField
                              label={t("Is this mandatory?")}
                              name={"isMandatory"}
                              isChecked={benefit.isMandatory === 1}
                              onChangeHandler={(event) =>
                                handleFieldChange(event, index)
                              }
                              disable={!edit}
                              id="isMandatory"
                            />
                          </div>
                          <div className="col-md-8 marginBotttom1 PcSalaryBenefits">
                            <CheckBoxField
                              label={t(
                                "Allow sales agent to update the value during creation of cooperation agreement?"
                              )}
                              name={"salesCanChange"}
                              isChecked={benefit.salesCanChange === 1}
                              onChangeHandler={(event) =>
                                handleFieldChange(event, index)
                              }
                              disable={!edit}
                              id="salesCanChange"
                            />
                          </div>
                          {benefit.isVariable && (
                            <div className="col-md-4">
                              <div className="input-group mealVouchers position-relative">
                                <LabelWithInputField
                                  label={t("Salary benefit value")}
                                  handleChange={(e) =>
                                    changeHandler(e, index, benefit.type)
                                  }
                                  isMandatory={true}
                                  isDisabled={!edit}
                                  value={
                                    benefit.benefitValue == null
                                      ? ""
                                      : benefit.benefitValue
                                  }
                                  name={`benefitValue`}
                                  error={errors[`benefitValue${index}`]}
                                />
                                {benefit.type === "amount" ? (
                                  <div className="input-group-text rounded-start-0 rounded-end-3 border-0 position-absolute">
                                    {"€"}
                                  </div>
                                ) : (
                                  <div className="input-group-text rounded-start-0 rounded-end-3 border-0 position-absolute">
                                    {"%"}
                                  </div>
                                )}
                              </div>
                            </div>
                          )}

                          <div className="col-md-4">
                            <LabelField
                              title={t(
                                "Is the benefit granted in case of absence of the employee?"
                              )}
                            />
                            <div className="mb-2">
                              <RadioField
                                label={t("Yes")}
                                name={`status${index}`}
                                value={1}
                                ischecked={benefit.status === 1}
                                handleChange={(event) =>
                                  handleFieldChange(event, index)
                                }
                                disable={!edit}
                              />
                            </div>
                            <div>
                              <RadioField
                                label={t("No")}
                                name={`status${index}`}
                                value={0}
                                ischecked={benefit.status === 0}
                                handleChange={(event) =>
                                  handleFieldChange(event, index)
                                }
                                disable={!edit}
                              />
                            </div>
                          </div>
                          <div className="col-md-4 position-relative">
                            <Calender
                              onChange={(date) =>
                                handleDateChange(date, "startDate", index)
                              }
                              selectedDate={benefit.startDate}
                              label={t("Start date")}
                              name="startDate"
                              minDate={new Date(minDate)}
                              isMandatory={true}
                              error={errors[`startDate${index}`]}
                              isDisabled={!edit}
                            />
                          </div>
                          <div className="col-md-4">
                            <SelectWithSearch
                              search={false}
                              onChange={(e) =>
                                handleOccurenceChange(e, "occurence", index)
                              }
                              title={t("Occurrence")}
                              options={options}
                              isMulti={false}
                              isMandatory={true}
                              name="occurence"
                              error={errors[`occurence${index}`]}
                              value={benefit.occurence}
                              isDisabled={!edit}
                              isTranslate={true}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row backPadding">
          <div className="col-md-4 align-self-center">
            {
              <Link
                to=""
                onClick={() => navigate(-1)}
                className=" back-btn text-decoration-underline"
              >
                {t("Back")}
              </Link>
            }
          </div>
          {edit && (
            <div className="col-md-8 text-end">
              {!loading ? (
                <Button
                  title={t("Save")}
                  type="submit"
                  className="float-end form-button shadow-none"
                  handleClick={handleSubmit}
                />
              ) : (
                <LoadingIcon
                  iconType="bars"
                  color="#e55496"
                  height="3vw"
                  width="3vw"
                  className="ms-auto"
                />
              )}
            </div>
          )}
        </div>
      </div>
    </AccessControl>
  );
};
export default translate(SalaryBenefit);
