import { t } from "components/CentralDataMangement/translation/Translation";
import Title from "components/common/atoms/Title";
import OfficeTargetTable from "../common/OfficeTargetTable";
import {
  CooAgreementDetails,
  OMTargetAchievedData,
  OfficeTarget,
  ProspectCallDetails,
  StarterDetails,
  StopperDetails,
  TargetAchievedTable,
  User,
  VacancyDetails,
  VisitsDetails,
} from "../Annotations";
import { useEffect, useMemo, useState } from "react";
import OMTargetTable from "../common/OMTargetTable";
import VacanciesTable from "../common/VacanciesTable";
import VisitsTable from "../common/VisitsTable";
import CooAgreementTable from "../common/CooAgreementTable";
import ProspectCallsTable from "../common/ProspectCallsTable";
import TableTitle from "components/common/atoms/TableTitle";
import StartersTable from "../common/StartersTable";
import StoppersTable from "../common/StoppersTable";
import RecruiterTargetTable from "../common/RecruiterTargetTable";
import LabelWithTextAreaField from "components/common/molecules/LabelWithTextAreaField";
import secureLocalStorage from "react-secure-storage";
import EncryptDecryptService from "components/common/services/EncryptDecryptService";
import CustomNotify from "components/common/atoms/CustomNotify";
import { ApiCall } from "components/common/services/ApiServices";
import { EDIT_MONTHLY_REPORTS, UPDATE_MONTHLY_REPORTS } from "../../../../routes/ApiEndpoints";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "Constants";
import LoadingIcon from "components/common/utlis/LoadingIcon";
import { Link, useNavigate } from "react-router-dom";
import Button from "components/common/atoms/Button";

interface TargetData {
  officeQTarget: OfficeTarget;
  officeTarget: OfficeTarget;
  targets: OMTargetAchievedData;
}

interface ReportsData {
  periodId: number | null;
  vacancies: VacancyDetails[];
  visits: VisitsDetails[];
  cooAgreement: CooAgreementDetails[];
  prospectCalls: ProspectCallDetails[];
}

interface RecruiterData {
  created_by: number | string;
  recruiterTargets: TargetAchievedTable;
}

interface RecruitersData {
  recruiters: User[];
  recTargets: RecruiterData[];
  starters: StarterDetails[];
  stoppers: StopperDetails[];
}

interface Analysis {
  analysis: string;
  smile_moments: string;
  growth_points: string;
  thoughts_comments: string;
  am_analysis: string;
  analyzed_by?: number;
}

const MonthlyReports: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [targetData, setTargetData] = useState<TargetData>();
  const navigate = useNavigate();
  const [reports, setReports] = useState<ReportsData>({
    periodId: null,
    vacancies: [],
    visits: [],
    prospectCalls: [],
    cooAgreement: [],
  });
  const [recruiters, setRecruiters] = useState<RecruitersData>({
    recruiters: [],
    recTargets: [],
    starters: [],
    stoppers: [],
  });
  const [currentRecData, setCurrentRecData] = useState<RecruiterData>();
  const [analysisData, setAnalysisData] = useState<Analysis>({
    analysis: "",
    smile_moments: "",
    growth_points: "",
    thoughts_comments: "",
    am_analysis: "",
  });

  let data = secureLocalStorage.getItem('omMonthlyReport') as string; // Get the 'data' query param as a string

  useEffect(() => {
    const timeout = setTimeout(() => {
      fetchData();
    }, 3000); // 3 seconds

    return () => clearTimeout(timeout); // Cleanup the timeout on component unmount
  }, []);

  const fetchData = async () => {
    let parsedData = {};
    try {
      if (data) {
        // Decrypt the recruiterData
        let decryptedData = EncryptDecryptService.decryptData(data);

        // If decryptedData is in JSON format, parse it
        parsedData = JSON.parse(decryptedData);
      }

    } catch (error) {
      CustomNotify({
        type: "error",
        message: t("Something went wrong"),
      });
      setLoading(false);
      return null;
    }

    const response = await ApiCall.service(
      EDIT_MONTHLY_REPORTS,
      "POST",
      { ...parsedData, type: "officeManager" },
      false,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );

    if (response.status === 200) {
      setTargetData((prevData) => (response?.data?.targetData));
      setReports(response?.data?.reportTypes);
      setRecruiters(response?.data?.recruiters);
      setCurrentRecData(response?.data?.recruiters?.recTargets?.[0]);
      if (response?.data?.analysis) {
        setAnalysisData(response?.data?.analysis);
      }
    }
    setLoading(false);
  }

  const setCurrentRecruiter = (id: number | string) => {
    const recruiter = recruiters.recTargets?.find(
      (data) => data.created_by === id
    );
    setCurrentRecData(recruiter);
  };

  const getStarters = (id: number | string) => {
    return recruiters.starters.filter((data) => data.created_by === id);
  };

  const getStoppers = (id: number | string) => {
    return recruiters.stoppers.filter((data) => data.created_by === id);
  };

  const getRecName = useMemo(
    () => (recruiterId: number | string) => {
      const recruiter = recruiters.recruiters.find((r) => r.id === recruiterId);
      return recruiter?.name ?? "";
    },
    [recruiters.recruiters]
  );

  const handleAnalysisFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, type, checked } = event.target as HTMLInputElement;

    setAnalysisData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    const payload = {
      type: "officeManager",
      periodId: reports?.periodId,
      analysis: analysisData
    };

    const url = UPDATE_MONTHLY_REPORTS;
    const response = await ApiCall.service(
      url,
      "POST",
      payload,
      false,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );
    if (response.status === 200) {
      CustomNotify({ type: "success", message: response.msg });
      navigate("/manage-reports");
    } else {
      CustomNotify({ type: "error", message: t("Something went wrong") })
    }
    setLoading(false);
  };

  return (
    <>
      <Title title={t("Reporting (monthly office manager)")} />

      {loading ? (
        <div style={{ height: "38vw" }} className="d-flex align-items-center justify-content-center">
          <LoadingIcon
            iconType="bars"
            color="#e55496"
            className="m-auto"
            height={"5vw"}
            width={"5vw"}
          />
        </div>

      ) : (
        <>
          <div className="row">
            <div className="col-md-4 mb-3">
              <OfficeTargetTable
                data={targetData?.officeTarget}
                tableTitle="Office"
              />
            </div>
            <div className="col-md-4 mb-3">
              <OfficeTargetTable
                data={targetData?.officeQTarget}
                tableTitle="Office Q"
              />
            </div>
            <div className="col-md-4 mb-3">
              <OMTargetTable data={targetData?.targets} />
            </div>
            <div className="col-md-6">
              <VacanciesTable data={reports.vacancies} tableTitle="VACANCIES" />
            </div>
            <div className="col-md-6">
              <VisitsTable data={reports.visits} tableTitle="VISITS" />
            </div>
            <div className="col-md-6">
              <CooAgreementTable
                data={reports.cooAgreement}
                tableTitle="COOPERATION AGREEMENT"
              />
            </div>
            <div className="col-md-6">
              <ProspectCallsTable
                data={reports.prospectCalls}
                tableTitle="PROSPECT CALLS"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-2">
              <table className="table mb-0 table-bordered position-sticky OMRecriterTableSticky">
                <thead>
                  <tr className="align-middle border-bottom TableHeader">
                    <th className="text-start ps-3">{t("Recruiters")}</th>
                  </tr>
                </thead>
                <tbody>
                  {recruiters.recruiters.map((recruiter: User, index) => (
                    <tr
                      onClick={(e) => {
                        setCurrentRecruiter(recruiter.id);
                      }}
                      key={index}
                      className="cursor-pointer"
                    >
                      <td
                        className={`cursor-pointer ${recruiter.id === currentRecData?.created_by
                          ? "activeRecruiter"
                          : ""
                          }`}
                      >
                        {recruiter.name}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="col-md-10">
              <TableTitle title={getRecName(currentRecData?.created_by ?? 0)} />
              <RecruiterTargetTable data={currentRecData?.recruiterTargets} />
              <StartersTable
                data={getStarters(currentRecData?.created_by ?? 0)}
                view="om"
                tableTitle="STARTERS"
              />
              <StoppersTable
                data={getStoppers(currentRecData?.created_by ?? 0)}
                view="om"
                tableTitle="STOPPER"
              />
            </div>
            <div className="row equal-cols">
              <Title title={t("Analysis")} />
              <div className="col-7">
                <div className="row">
                  <div className="col-6">
                    <LabelWithTextAreaField
                      name="analysis"
                      handleChange={handleAnalysisFieldChange}
                      label={t("Make an analysis of your month!")}
                      value={analysisData.analysis}
                    />
                  </div>
                  <div className="col-6">
                    <LabelWithTextAreaField
                      name="smile_moments"
                      handleChange={handleAnalysisFieldChange}
                      label={t(`Share your "smile" moments with us!`)}
                      value={analysisData.smile_moments}
                    />
                  </div>
                  <div className="col-6">
                    <LabelWithTextAreaField
                      name="growth_points"
                      handleChange={handleAnalysisFieldChange}
                      label={t("Share your growth points with us")}
                      value={analysisData.growth_points}
                    />
                  </div>
                  <div className="col-6">
                    <LabelWithTextAreaField
                      name="thoughts_comments"
                      handleChange={handleAnalysisFieldChange}
                      label={t("Share your thoughts and comments with us")}
                      value={analysisData.thoughts_comments}
                    />
                  </div>
                </div>
              </div>
              <div className="col-5">
                <LabelWithTextAreaField
                  name="am_analysis"
                  handleChange={handleAnalysisFieldChange}
                  label={t("Besluit office manager / regio Manager.")}
                  value={analysisData.am_analysis}
                />
              </div>
            </div>
            <div className="row py-4">
              <div className="col-md-4 align-self-center">
                <Link
                  to="/manage-reports"
                  className=" back-btn text-decoration-underline"
                >
                  {t("Back")}
                </Link>
              </div>
              {/* <AccessControl
                requiredPermissions={[
                  {
                    permission: "Daily turnover",
                    create: true,
                    update: true,
                  },
                ]}
                actions={true}
                strict={false}
              > */}
              <div className="col-md-8">
                {!loading ? (
                  <Button
                    title={t("Save")}
                    type="submit"
                    className="form-button float-end px-3 shadow-none"
                    handleClick={(e) => handleSubmit(e)}
                  />
                ) : (
                  <LoadingIcon
                    iconType="bars"
                    color="#ff4dae"
                    className="float-end"
                    width={"3vw"}
                    height={"3vw"}
                  />
                )}
              </div>
              {/* </AccessControl> */}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default MonthlyReports;
