import React, { useState } from "react";
import { t } from "components/CentralDataMangement/translation/Translation";
import { useFormContext } from "../../context/Context";
import Button from "components/common/atoms/Button";
import * as CONST from "../../annotation/PlanningConstants";
import ModalWithInput from "components/common/molecules/ModalWithInput";
import TableWithActions from "components/common/molecules/TableWithActions";
import LabelField from "components/common/atoms/LabelField";

interface FormData {
  id: number | null;
  name: string;
}

const CostCenter: React.FC = () => {
  const { state, dispatch } = useFormContext();
  const [formData, setFormData] = useState<FormData>({ id: null, name: "" });
  const [modal, setModal] = useState(false);
  const [index, setIndex] = useState(0);

  const addData = (index: number) => {
    setIndex(index);
    setModal(true);
  };

  const editData = (index: number) => {
    setIndex(index);
    setModal(true);
    const costCenterToEdit = state.costCenterDetails.costCenters[index];
    if (costCenterToEdit) {
      setFormData({
        id: costCenterToEdit.id,
        name: costCenterToEdit.name,
      });
    }
  };

  const resetFields = () => {
    setFormData({ id: null, name: "" });
  };

  const onConfirm = (data: { [key: string]: any }) => {
    dispatch({
      type: CONST.ADD_COST_CENTER,
      data: data,
      index: index,
    });
    setModal(false);
    resetFields();
  };

  const removeFieldSet = (indexToRemove: number) => {
    dispatch({ type: CONST.REMOVE_COST_CENTER, indexToRemove });
  };

  const closeModal = () => {
    setModal(false);
    resetFields();
  };

  return (
    <>
      <ModalWithInput
        isOpen={modal}
        onClose={closeModal}
        onConfirm={onConfirm}
        fieldName="name"
        fieldLabel={t("Cost center")}
        fieldPlaceholder={t("Cost center")}
        formData={formData}
        setFormData={setFormData}
      />
      <div>
        <LabelField title={t("Cost centers:")} className="tab-subtitle" />
        <TableWithActions
          data={state.costCenterDetails.costCenters}
          onEdit={editData}
          onDelete={removeFieldSet}
          columns={[{ key: "name", label: "Cost center" }]}
        />
        <Button
          title={"+ " + t("Add")}
          handleClick={() =>
            addData(state.costCenterDetails.costCenters.length)
          }
          className="float-end form-button px-3"
        />
      </div>
    </>
  );
};

export default CostCenter;
