import React, { useState } from "react";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import { t } from "components/CentralDataMangement/translation/Translation";
import { useFormContext } from "../../context/Context";
import ModalPopup from "components/common/atoms/ModalPopup";
import { PerfCodeProps } from "../../annotation/PlanningInterface";
import * as CONST from "../../annotation/PlanningConstants";
import LabelField from "components/common/atoms/LabelField";
import InputTextfield from "components/common/atoms/InputTextField";
import Button from "components/common/atoms/Button";
import CheckBoxField from "components/common/atoms/CheckBoxField";
import EditIcon from "static/images/EditIcon";
import DeleteIcon from "static/images/DeleteIcon";
import { ValidationRules } from "components/common/utlis/TypeAnnotations";
import {
  validateForm,
  validateMinValue,
  validateSelectField,
} from "components/common/services/ValidationService";

const ContractPerfcode: React.FC = () => {
  const { state, dispatch } = useFormContext();

  const [contractPerfCodeData, setContractPerfCodeData] =
    useState<PerfCodeProps>({
      id: null,
      codeOption: null,
      code: "",
      description: "",
      amount: "",
      percentage: "",
      contractVisible: 0,
    });

  const [disableFields, setDisableFields] = useState({
    amount: false,
    percentage: false,
  });

  const intialErrors: { [key: string]: string } = {};
  const [errors, setErrors] = useState<{
    [key: string]: string;
  }>(intialErrors);

  const validation = (
    name: string,
    value: string | number | null,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      codeOption: [validateSelectField],
      amount: disableFields.amount ? [] : [validateMinValue],
      percentage: disableFields.percentage ? [] : [validateMinValue],
    };
    const validationErrors = validateForm(
      { ...contractPerfCodeData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const removeFieldSet = (indexToRemove: number) => {
    dispatch({ type: CONST.REMOVE_CONTRACT_PERFCODE, indexToRemove });
  };

  const [isContractPerfCodeModalOpen, setContractPerfCodeModalOpen] =
    useState(false);
  const [contractPerfcodeIndex, setContractPerfcodeIndex] = useState(0);

  const addContractPerfCodeData = (index: number) => {
    setContractPerfcodeIndex(index); // Set the index for dispatch
    setContractPerfCodeModalOpen(true);
  };

  const editContractPerfCodeData = (index: number) => {
    setContractPerfcodeIndex(index); // Set the index for dispatch
    setContractPerfCodeModalOpen(true);
    const contractPerfCodeToEdit = state.remenurationDetails.perfCodes[index]; // Get the certificate to edit
    if (contractPerfCodeToEdit) {
      const perfcodeOption = state.dropDowndata.premiumCodes.find((option:any) => option.value === contractPerfCodeToEdit.codeOption?.value);
      setDisableFields({
        amount: perfcodeOption?.variable_type === "percentage",
        percentage: perfcodeOption?.variable_type === "amount",
      });
      // Populate the  data in the modal fields
      setContractPerfCodeData({
        id: contractPerfCodeToEdit.id,
        codeOption: contractPerfCodeToEdit.codeOption,
        code: contractPerfCodeToEdit.code,
        description: contractPerfCodeToEdit.description,
        amount: contractPerfCodeToEdit.amount,
        percentage: contractPerfCodeToEdit.percentage,
        contractVisible: contractPerfCodeToEdit.contractVisible,
      });
    }
  };

  const resetContractPerfCodeFields = () => {
    setContractPerfCodeData({
      id: null,
      codeOption: null,
      code: "",
      description: "",
      amount: "",
      percentage: "",
      contractVisible: 0,
    });
  };

  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    setContractPerfCodeData((prevData) => ({
      ...prevData,
      [fieldName]: selectedOption,
    }));
    if (selectedOption?.value !== null) {
      const [code, description] = selectedOption?.label.split(" - ") || [];
      setContractPerfCodeData((prevData) => ({
        ...prevData,
        code: code,
        description: description,
      }));

      // Check the variable_type and set the necessary state to disable either amount or percentage
      setContractPerfCodeData((prevData) => ({
        ...prevData,
        percentage: "",
        amount: "",
      }));
      if (selectedOption?.variable_type === "amount") {
        setDisableFields({
          amount: false,
          percentage: true,
        });
      } else if (selectedOption?.variable_type === "percentage") {
        // Disable amount field if variable_type is percentage
        setDisableFields({
          amount: true,
          percentage: false,
        });
      } else {
        setDisableFields({
          amount: false,
          percentage: false,
        });
      }
    }
    validation(fieldName, selectedOption, true);
  };

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type } = event.target as HTMLInputElement;
    let updatedValue: string | number | null = value;
    if (type === "checkbox") {
      updatedValue = event.target.checked ? 1 : 0;
    } else {
      // Filter input to allow only numbers and commas
      updatedValue = value.replace(/[^0-9,]/g, "");
    }
    setContractPerfCodeData((prevData) => ({
      ...prevData,
      [name]: updatedValue,
    }));
    validation(name, updatedValue, true);
  };

  const onOkButtonClick = async (e: React.FormEvent) => {
    e.preventDefault();
    const { name, value } = e.target as HTMLInputElement;
    if (validation(name, value)) {
      dispatch({
        type: CONST.ADD_CONTRACT_PERFCODE,
        data: contractPerfCodeData,
        index: contractPerfcodeIndex,
      });
      setContractPerfCodeModalOpen(false); // Close modal
      resetContractPerfCodeFields();
      setErrors({});
      setDisableFields({
        amount: false,
        percentage: false,
      });
    }
  };

  const closeContractPerfCodeModal = () => {
    setContractPerfCodeModalOpen(false);
    resetContractPerfCodeFields();
    setErrors({});
    setDisableFields({
      amount: false,
      percentage: false,
    });
  };

  return (
    <>
      <div>
        <ModalPopup
          size="xl"
          show={isContractPerfCodeModalOpen}
          onHide={closeContractPerfCodeModal}
          title={t("Contract Perfcode")}
          body={
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-6">
                <SelectWithSearch
                  title={t("Code")}
                  isMandatory={true}
                  search={true}
                  options={state.dropDowndata.premiumCodes}
                  id={`codeOption-${contractPerfcodeIndex}`}
                  onChange={(e) => handleSelectChange(e, "codeOption")}
                  isMulti={false}
                  name="codeOption"
                  value={contractPerfCodeData.codeOption}
                  isTranslate={true}
                  error={errors.codeOption}
                />
              </div>
              <div className="col-6">
                <LabelField title={t("Amount")} isMandatory={false} />
                <div className="input-group field-shadow rounded-3 remunerationInputGroup">
                  <InputTextfield
                    name="amount"
                    handleChange={handleFieldChange}
                    value={contractPerfCodeData.amount}
                    type="text"
                    className="form-control shadow-none border-0 rounded-start-3 rounded-end-0"
                    id={`amount-${contractPerfcodeIndex}`}
                    isDisabled={disableFields.amount}
                  />
                  <span
                    className="textAppendBg input-group-text justify-content-center field-shadow border-0"
                    id="basic-addon1"
                  >
                    {"€"}
                  </span>
                </div>
                <div className="text-danger">{errors.amount}</div>

              </div>
              <div className="col-6">
                <LabelField title={t("Percentage")} isMandatory={false} />
                <div className="input-group field-shadow rounded-3 remunerationInputGroup">
                  <InputTextfield
                    name="percentage"
                    handleChange={handleFieldChange}
                    value={contractPerfCodeData.percentage}
                    type="text"
                    className="form-control shadow-none border-0 rounded-start-3 rounded-end-0"
                    id={`percentage-${contractPerfcodeIndex}`}
                    isDisabled={disableFields.percentage}
                  />
                  <span
                    className="textAppendBg input-group-text justify-content-center field-shadow border-0"
                    id="basic-addon1"
                  >
                    {"%"}
                  </span>
                </div>
                <div className="text-danger">{errors.percentage}</div>
              </div>
              <div className="col-md-6 hotlistWrapper">
                <CheckBoxField
                  label={t("Is visible on the contract")}
                  name="contractVisible"
                  onChangeHandler={handleFieldChange}
                  isChecked={contractPerfCodeData.contractVisible === 1}
                  className="document-checkbox"
                  id={`contractVisible-${contractPerfcodeIndex}`}
                />
              </div>
              <div className="col-12 d-flex justify-content-end">
                <Button
                  handleClick={onOkButtonClick}
                  className="shadow-none form-button px-3"
                >
                  {t("Add")}
                </Button>
              </div>
            </div>
          }
          className="modal-lg contractPerfCodeModal"
        />
      </div>
      <div className="mt-3">
        {/* Certificates section table display data */}
        <LabelField
          title={t("Premiums or reimbursements")}
          className="tab-title"
        />
        <div className="pwa table-responsive">
          <table className="table table-hover">
            <thead>
              <tr className="border-0 TableHeader bg-white">
                <th className="border-0 align-middle" style={{ width: "10%" }}>
                  {t("Code")}
                </th>
                <th className="border-0 align-middle" style={{ width: "30%" }}>
                  {t("Description")}
                </th>
                <th className="border-0 align-middle" style={{ width: "20%" }}>
                  {t("Amount")}
                </th>
                <th className="border-0 align-middle" style={{ width: "20%" }}>
                  {t("Percentage")}
                </th>
                <th className="border-0 align-middle" style={{ width: "20%" }}>
                  {t("Action")}
                </th>
              </tr>
            </thead>
            <tbody>
              {state.remenurationDetails.perfCodes.map((perfcode, index) => (
                <tr key={index}>
                  <td
                    className="align-middle text-break"
                    data-label={t("Code")}
                  >
                    {perfcode.code}
                  </td>
                  <td
                    className="align-middle text-break"
                    data-label={t("Description")}
                  >
                    {perfcode.description}
                  </td>
                  <td
                    className="align-middle text-break"
                    data-label={t("Amount")}
                  >
                    {perfcode.amount !== "" ? `${perfcode.amount}` : 0}
                  </td>
                  <td
                    className="align-middle text-break"
                    data-label={t("Percentage")}
                  >
                    {perfcode.percentage !== ""
                      ? `${perfcode.percentage}%`
                      : `${0}%`}
                  </td>
                  <td className="align-middle text-break">
                    <span
                      title={t("Edit")}
                      onClick={() => editContractPerfCodeData(index)}
                      className="table-action-icons cursor-pointer rounded-0 shadow-none me-2"
                    >
                      <EditIcon />
                    </span>
                    <span
                      title={t("Delete")}
                      onClick={() => removeFieldSet(index)}
                      className="table-action-icons cursor-pointer rounded-0 shadow-none"
                    >
                      <DeleteIcon />
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* Certificates section modal popup */}
        <div className="row">
          <div className="col-12">
            <Button
              title={"+ " + t("Add")}
              handleClick={() =>
                addContractPerfCodeData(
                  state.remenurationDetails.perfCodes.length
                )
              }
              className="form-button float-end px-3 shadow-none"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ContractPerfcode;
