import React, { useEffect, useState } from "react";
import SearchModel from "components/common/atoms/SearchModel";
import Pagination from "components/common/atoms/Pagination";
import { ApiCall } from "components/common/services/ApiServices";
import { Link } from "react-router-dom";
import CustomNotify from "components/common/atoms/CustomNotify";
import ModalPopup from "components/common/atoms/ModalPopup";
import EditIcon from "static/images/EditIcon";
import DeleteIcon from "static/images/DeleteIcon";
import "static/css/permission.css";
import Button from "components/common/atoms/Button";
import { GET_PERMISSIONS, DELETE_PERMISSION } from "routes/ApiEndpoints";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import Title from "components/common/atoms/Title";
import LinkTo from "components/common/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import NoRecords from "components/common/atoms/NoRecords";
// import Menu from "static/images/Menu";
// import FilterClose from "static/images/FilterClose";

const ManagePermissions = () => {
  interface Permission {
    permission_id?: number;
    name?: string;
    classification_name?: string;
  }

  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [permissions, setPermissions] = useState<Permission[]>([]);
  const [deleteID, setDeleteId] = useState<number | undefined>();
  const [showModal, setShowModal] = useState(false);
  const itemsPerPage = 10;
  // const [isFilter, setIsFilter] = useState<boolean>(true); // Example boolean state

  useEffect(() => {
    fetchAllPermissions();
  }, []);

  const fetchAllPermissions = async () => {
    const response = await ApiCall.getService(GET_PERMISSIONS, "GET");
    if (response.status === 200) {
      setPermissions(response.data);
    }
  };

  const deletePermission = (id: number | undefined) => {
    setShowModal(true);
    setDeleteId(id);
  };

  // Filter the data based on the search query
  const filteredData = permissions.filter((item: Permission) =>
    item.name?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Get the current items to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Handle search input change
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to the first page when the search query changes
  };

  // Handle page change
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  // Modal popup for delete confirmation
  const handleModalClose = () => setShowModal(!showModal);
  const handleSaveChanges = async () => {
    setShowModal(false);
    if (deleteID) {
      const url = `${DELETE_PERMISSION}/${deleteID}`;
      const response = await ApiCall.getService(url, "GET");
      if (response.status === 200) {
        setPermissions((prevdata) =>
          prevdata.filter(
            (permissions) => permissions.permission_id !== deleteID
          )
        );
        CustomNotify({ type: "success", message: response.msg });
      }
    }
  };

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Roles",
          read: true,
        },
      ]}
      renderNoAccess={true}
    >
      <Title title={t("Permissions")} />
      <div className="row search-bar pb-3">
        <div className="col-8">
          <SearchModel
            className="form-control"
            placeHolder={t("Search by name")}
            onChange={handleSearchChange}
          />
        </div>
      </div>
      <div className="position-relative tableMainWrapper">
        <div className="d-none d-lg-block">
          <div className="row">
            <div className="ManageCreateBtn">
              <LinkTo
                pagelink="/permission/create"
                title={t("Create permission")}
                icon={faPlus}
              />
            </div>
          </div>
        </div>
        <div className="d-block d-lg-none">
          <div className="row">
            <div className="col-md-6 ms-auto pb-3">
              <LinkTo
                pagelink="/permission/create"
                title={t("Create permission")}
                icon={faPlus}
                className="w-100 align-items-center justify-content-center"
              />
            </div>
          </div>
        </div>
        <div className="table-responsive tableSection">
          <table className="table table-hover">
            <thead>
              <tr className="TableHeader">
                {/* <th className="ps-lg-4">#</th> */}
                <th className="ps-lg-4" style={{ width: "35%" }}>
                  {t("Name")}
                </th>
                <th style={{ width: "35%" }}>{t("Classification")}</th>
                <AccessControl
                  requiredPermissions={[
                    {
                      permission: "Roles",
                      update: true,
                      delete: true,
                    },
                  ]}
                  actions={true}
                  strict={false}
                >
                  <th>{t("Actions")}</th>
                </AccessControl>
                {/* Add other columns as needed */}
              </tr>
            </thead>
            <tbody>
              {currentItems && currentItems.length > 0 ? (
                currentItems?.map((item, index) => (
                  <tr
                    key={item.permission_id}
                    className="border box-shadow mb-3"
                  >
                    {/* <td className="ps-lg-4 text-break" data-label={t("Id")}>
                      {(currentPage - 1) * itemsPerPage + index + 1}
                    </td> */}
                    <td className="text-break ps-lg-4" data-label={t("Name")}>
                      {item.name}
                    </td>
                    <td className="text-break" data-label={t("Classification")}>
                      {item.classification_name}
                    </td>
                    <AccessControl
                      requiredPermissions={[
                        {
                          permission: "Roles",
                          update: true,
                          delete: true,
                        },
                      ]}
                      actions={true}
                      strict={false}
                    >
                      <td className="px-2 table-action-icons">
                        {/* Desktop */}
                        <div className="d-none d-md-none d-lg-block">
                          <AccessControl
                            requiredPermissions={[
                              {
                                permission: "Roles",
                                update: true,
                              },
                            ]}
                          >
                            <Link
                              to={`/permission/edit/${item.permission_id}`}
                              className="btn p-0 border-0 me-1"
                              title={t("Edit")}
                            >
                              <EditIcon />
                            </Link>
                          </AccessControl>
                          <AccessControl
                            requiredPermissions={[
                              {
                                permission: "Roles",
                                delete: true,
                              },
                            ]}
                          >
                            <button
                              className="btn p-0 border-0"
                              title={t("Delete")}
                              onClick={() =>
                                deletePermission(item.permission_id)
                              }
                            >
                              <DeleteIcon />
                            </button>
                          </AccessControl>
                        </div>
                        {/* PWA */}
                        <div className="d-block d-md-block d-lg-none">
                          <AccessControl
                            requiredPermissions={[
                              {
                                permission: "Roles",
                                update: true,
                              },
                            ]}
                          >
                            <div>
                              <Link
                                to={`/permission/edit/${item.permission_id}`}
                                className="btn p-0 border-0 w-100"
                                title={t("Edit")}
                              >
                                <Button
                                  title={t("Edit")}
                                  className="mb-2 button-width edit-btn rounded-3"
                                />
                              </Link>
                            </div>
                          </AccessControl>
                          <AccessControl
                            requiredPermissions={[
                              {
                                permission: "Roles",
                                delete: true,
                              },
                            ]}
                          >
                            <div>
                              <Link
                                to=""
                                className="btn p-0 border-0 w-100"
                                title={t("Delete")}
                                onClick={() =>
                                  deletePermission(item.permission_id)
                                }
                              >
                                <Button
                                  title={t("Delete")}
                                  className="mb-2 button-width delete-btn rounded-3"
                                />
                              </Link>
                            </div>
                          </AccessControl>
                        </div>
                      </td>
                    </AccessControl>
                    {/* Render other columns' data here */}
                  </tr>
                ))
              ) : (
                <NoRecords headerLength={4} />
              )}
            </tbody>
          </table>
          {totalPages > 1 && (
            <div className="pagination justify-content-center mt-4">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          )}
        </div>
      </div>
      <ModalPopup
        show={showModal}
        onHide={handleModalClose}
        title={t("Delete confirmation")}
        body={t("Are you sure want to delete") + "?"}
        onCloseButtonClick={handleModalClose}
        onConfirmButtonClick={handleSaveChanges}
        closeTitle={t("No")}
        confirmTitle={t("Yes")}
      />
    </AccessControl>
  );
};

export default translate(ManagePermissions);
