import React from "react";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import { t } from "components/CentralDataMangement/translation/Translation";
import { useFormContext } from "../../context/Context";
import AutoCompleteDropdown from "components/common/atoms/AutoCompleteDropdown";
import {
  CENTRAL_DATA_MANAGEMENT_MICROSERVICE,
  COMPANY_MICROSERVICE,
} from "Constants";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import Calender from "components/common/molecules/Calender";
import {
  BASIC_DETAILS,
  BILLING_DETAILS,
  REMENUARATION_DETAILS,
  TIME_TABLE_DETAILS,
  UPDATE_FIELDS,
  UPDATE_FIELD_ERROR,
} from "../../annotation/PlanningConstants";
import {
  fetchCompanyDetails,
  setPCRelatedDetails,
  setPlanningProfileRelatedFields,
} from "../form-data/SetCompanyRelatedData";
import {
  resetCoeffDetails,
  resetCompanyRelatedFields,
  resetLocationRelatedFields,
  resetPayProfileDetails,
  resetPlanningProfileRelatedFields,
  resetCommercialProfileDetails,
  resetShiftDetails,
} from "../form-data/ResetPlanningData";
import { fetchCoeffDetails } from "../form-data/SetCoeffData";
import { fetchProfileDetails } from "../form-data/SetProfileData";
import { useLocation } from "react-router-dom";
import { getNextSunday } from "components/common/utlis/PlanningUtility";
import { validateField } from "../form-navigation/PlanningFieldValidation";

const BasicTab: React.FC = () => {
  const { state, dispatch } = useFormContext();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const companyId = queryParams.get("companyId");

  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    dispatch({
      type: UPDATE_FIELDS,
      tab: BASIC_DETAILS,
      field: fieldName,
      value: selectedOption,
    });
    validateField(
      fieldName,
      selectedOption,
      state.basicDetails,
      dispatch,
      true
    );
    // Fetch company information when customer is selected
    if (
      fieldName === "customer" &&
      selectedOption?.value !== undefined &&
      selectedOption?.value !== null
    ) {
      fetchCompanyDetails("company", selectedOption.value, dispatch); // Set data related to company details
    } else if (fieldName === "customer") {
      resetCompanyRelatedFields(dispatch); // Reset only company fields, not location fields
      resetCommercialProfileDetails(dispatch, true); //reset
      resetPayProfileDetails(dispatch, true);
      resetShiftDetails(dispatch, true);
      resetPlanningProfileRelatedFields(state, dispatch, "partiarCommittee");
    }

    // Fetch location information when branch is selected
    if (
      fieldName === "branch" &&
      selectedOption?.value !== undefined &&
      selectedOption?.value !== null
    ) {
      fetchCompanyDetails("branch", selectedOption.value, dispatch); // Set data related to location details
    } else if (fieldName === "branch") {
      resetLocationRelatedFields(dispatch); // Only reset location-related fields
    }

    //employeeType
    if (
      fieldName === "employeeType" &&
      selectedOption?.value !== undefined &&
      selectedOption?.value !== null
    ) {
      fetchCoeffDetails("coeff", selectedOption.value, dispatch);
    } else if (fieldName === "employeeType") {
      resetCoeffDetails(dispatch);
    }

    if (fieldName === "planningProfile" || fieldName === "partiarCommittee") {
      resetPlanningProfileRelatedFields(state, dispatch, fieldName);
      fieldName === "partiarCommittee"
        ? resetCommercialProfileDetails(dispatch, true)
        : resetCommercialProfileDetails(dispatch); //reset
      resetPayProfileDetails(dispatch, true);
      if (fieldName === "planningProfile") {
        dispatch({
          type: UPDATE_FIELDS,
          tab: BILLING_DETAILS,
          field: "commercialProfile",
          value: selectedOption,
        });
      }
    }
    // partiarCommittee
    if (
      fieldName === "partiarCommittee" &&
      selectedOption?.value !== undefined &&
      selectedOption?.value !== null
    ) {
      setPCRelatedDetails(selectedOption.value, state, dispatch);
    }

    // planningProfile
    if (
      fieldName === "planningProfile" &&
      selectedOption?.value !== undefined &&
      selectedOption?.value !== null
    ) {
      setPlanningProfileRelatedFields(selectedOption.value, state, dispatch);
      fetchProfileDetails(
        "planningProfile",
        selectedOption.value,
        state,
        dispatch
      );
      // check is there payProfile option for planning profil
      const payProfileOption = state.dropDowndata.payProfileOptions.find(
        (option: any) => option.empTypeId === selectedOption.empTypeId
      );

      //if exists
      if (payProfileOption) {
        dispatch({
          type: UPDATE_FIELDS,
          tab: REMENUARATION_DETAILS,
          field: "payProfile",
          value: payProfileOption,
        });
        fetchProfileDetails(
          "payProfile",
          payProfileOption.value,
          state,
          dispatch
        );
      }
    }
    //if reason
    if (fieldName === "reason") {
      dispatch({ type: UPDATE_FIELD_ERROR, field: "attempt", error: "" });
      dispatch({
        type: UPDATE_FIELDS,
        tab: BASIC_DETAILS,
        field: "attempt",
        value: 0,
      });
    }
  };

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    dispatch({
      type: UPDATE_FIELDS,
      tab: BASIC_DETAILS,
      field: name,
      value: value,
    });
    validateField(name, value, state.basicDetails, dispatch, true);
  };

  const handleDateChange = (date: Date | null, name: string) => {
    dispatch({
      type: UPDATE_FIELDS,
      tab: BASIC_DETAILS,
      field: name,
      value: date,
    });
    validateField(name, date, state.basicDetails, dispatch, true);
  };

  return (
    <div
      className="form-border"
      style={{ paddingBottom: "1vw", marginBottom: "1vw" }}
    >
      <div className="row">
        <div className="col-lg-3">
          <SelectWithSearch
            title={t("Office")}
            isMandatory={true}
            search={true}
            options={state.dropDowndata.officeOptions}
            onChange={(e) => handleSelectChange(e, "office")}
            isMulti={false}
            name="office"
            value={state.basicDetails.office}
            id="office"
            isTranslate={true}
            error={state.errors.office}
          />
        </div>
        <div className="col-lg-3">
          <LabelWithInputField
            isMandatory={false}
            name="contractNumber"
            handleChange={handleFieldChange}
            value={state.basicDetails.contractNumber}
            id="contractNumber"
            label={t("Contract number")}
            isDisabled={true}
          />
        </div>
        <div className="col-lg-3 autoCompleteFunction">
          <AutoCompleteDropdown
            label={t("Customer")}
            placeholder={t("Type to search") + "..."}
            microService={COMPANY_MICROSERVICE}
            onChange={(e) => handleSelectChange(e, "customer")}
            value={state.basicDetails.customer}
            columnName="company_name"
            modelKey="company"
            id="company"
            isMandatory={true}
            name="customer"
            filter={true}
            filterInfo={[
              { column: "company_status", value: 1, condition: "where" },
              { column: "company_status", value: 0, condition: "orWhere" },
            ]}
            notNullColumn="bbright_id"
            error={state.errors.customer}
            isDisabled={!!companyId}
          />
        </div>
        <div className="col-lg-3">
          <SelectWithSearch
            title={t("Branch")}
            isMandatory={true}
            search={true}
            options={state.dropDowndata.branchOptions}
            onChange={(e) => handleSelectChange(e, "branch")}
            isMulti={false}
            name="branch"
            value={state.basicDetails.branch}
            id="branch"
            isTranslate={true}
            error={state.errors.branch}
          />
        </div>
        <div className="col-lg-3 autoCompleteFunction">
          <AutoCompleteDropdown
            label={t("Colleague")}
            placeholder={t("Type to search") + "..."}
            microService={CENTRAL_DATA_MANAGEMENT_MICROSERVICE}
            onChange={(e) => handleSelectChange(e, "collegue")}
            value={state.basicDetails.collegue}
            modelKey={"manageCandidates"}
            customFetch={true}
            columnName=""
            isMultiSelect={false}
            name="collegue"
            notNullColumn="bbright_id"
            error={state.errors.collegue}
            isMandatory={true}
          />
        </div>
        <div className="col-lg-3">
          <SelectWithSearch
            title={t("Paritair committee")}
            isMandatory={true}
            search={true}
            options={state.dropDowndata.pcOptions}
            onChange={(e) => handleSelectChange(e, "partiarCommittee")}
            isMulti={false}
            name="partiarCommittee"
            value={state.basicDetails.partiarCommittee}
            id="partiarCommittee"
            isTranslate={true}
          />
        </div>
        <div className="col-lg-3">
          <SelectWithSearch
            title={t("Planning profile")}
            isMandatory={true}
            search={true}
            options={state.dropDowndata.pcCommercialProfileOptions}
            onChange={(e) => handleSelectChange(e, "planningProfile")}
            isMulti={false}
            name="planningProfile"
            value={state.basicDetails.planningProfile}
            id="planningProfile"
            isTranslate={true}
          />
        </div>
        <div className="col-lg-3">
          <SelectWithSearch
            title={t("Employee type")}
            isMandatory={true}
            search={true}
            options={state.dropDowndata.employeeTypeOptions}
            onChange={(e) => handleSelectChange(e, "employeeType")}
            isMulti={false}
            name="employeeType"
            value={state.basicDetails.employeeType}
            id="employeeType"
            isTranslate={true}
            error={state.errors.employeeType}
          />
        </div>
        <div className="col-lg-3">
          <LabelWithInputField
            isMandatory={true}
            name="function"
            handleChange={handleFieldChange}
            value={state.basicDetails.function}
            id="function"
            label={t("Function")}
            placeholder={t("Function")}
            error={state.errors.function}
          />
        </div>
        <div className="col-3">
          <div className="row">
            <div className="col-lg-6 position-relative">
              <Calender
                onChange={(date) => handleDateChange(date, "from")}
                selectedDate={state.basicDetails.from}
                label={t("From")}
                name="from"
                maxDate={state.basicDetails.to}
                isMandatory={true}
                minDate={new Date()} //** pass null who can have right to create contract for past dates */
                error={state.errors.from}
              />
            </div>
            <div className="col-lg-6 position-relative">
              <Calender
                onChange={(date) => handleDateChange(date, "to")}
                selectedDate={state.basicDetails.to}
                label={t("To")}
                name="to"
                isMandatory={true}
                minDate={state.basicDetails.from}
                maxDate={
                  state.basicDetails.from
                    ? getNextSunday(new Date(state.basicDetails.from))
                    : getNextSunday(new Date())
                }
                error={state.errors.to}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-3">
          <SelectWithSearch
            title={t("Reason")}
            isMandatory={true}
            search={true}
            options={state.dropDowndata.reasonOptions}
            onChange={(e) => handleSelectChange(e, "reason")}
            isMulti={false}
            name="reason"
            value={state.basicDetails.reason}
            id="reason"
            isTranslate={true}
            error={state.errors.reason}
          />
        </div>
        {state.basicDetails.reason?.value === 4 && (
          <div className="col-lg-3">
            <LabelWithInputField
              isMandatory={true}
              name="attempt"
              handleChange={handleFieldChange}
              value={state.basicDetails.attempt}
              isDisabled={false}
              id="attempt"
              label={t("Attempts")}
              type="number"
              min={1}
              step={1}
              max={3}
              error={state.errors.attempt}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default BasicTab;
