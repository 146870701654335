import React from 'react'

const CooperationAgreementIcon = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 66.117 69.627">
        <g id="cooperation_agreement" data-name="cooperation agreement" transform="translate(-166.038 -69.884)">
          <path id="Path_31552" data-name="Path 31552" d="M399.027,249.434h0l-16.864,21.086-2.713,9.536,8.129-5.459,10.092-14.282h0l3.443-4.874.011-.016,4.824-6.514-4.057-3.06Z" transform="translate(-178.868 -146.832)" fill="currentColor" />
          <path id="Path_31554" data-name="Path 31554" d="M437.31,75.7v5.825h6.99Z" transform="translate(-227.149 -4.855)" fill="currentColor" />
          <path id="Path_31555" data-name="Path 31555" d="M209.785,129.088l-10.174,6.831,0,0,0,0-.01.006a.95.95,0,0,1-.09.051l-.014.007-.021.008c-.024.01-.048.019-.073.028l-.025.009-.017,0a.735.735,0,0,1-.075.016l-.031.006h-.008a.878.878,0,0,1-.109.007h-.01l-.038,0c-.027,0-.054,0-.081-.006l-.008,0c-.014,0-.027-.006-.041-.009s-.051-.011-.076-.019l-.01,0c-.014,0-.028-.012-.042-.017s-.046-.017-.068-.028l-.011-.005c-.014-.007-.028-.017-.042-.025s-.033-.017-.049-.028c-.047-.032-4.725-3.143-7.631-1.691a6.1,6.1,0,0,1-2.729.556c-3.653,0-7.981-2.138-8.211-2.253a.849.849,0,1,1,.759-1.518c1.784.89,6.955,2.931,9.423,1.7,2.864-1.433,6.6.237,8.352,1.208l3.091-10.863a.869.869,0,0,1,.08-.185.843.843,0,0,1,.073-.113l16.786-20.987V78.369h-9.334a.848.848,0,0,1-.849-.849V69.884H166.038v67.033c0,.072.054,2.526,0,2.594,0,0,52.607-.048,52.607-.048V116.645l-8.641,12.228a.85.85,0,0,1-.22.215ZM192.342,74.125h3.394V77.52h-3.394Zm-6.788,0h3.394V77.52h-3.394Zm-6.789,0h3.394V77.52h-3.394Zm-6.788,0h3.394V77.52h-3.394Zm0,7.637h32.244a.849.849,0,0,1,0,1.7H171.978a.849.849,0,1,1,0-1.7Zm0,5.091h37.335a.849.849,0,0,1,0,1.7H171.978a.849.849,0,0,1,0-1.7Zm0,5.091h28.85a.849.849,0,1,1,0,1.7h-28.85a.849.849,0,1,1,0-1.7Zm0,5.091h25.455a.849.849,0,1,1,0,1.7H171.978a.849.849,0,1,1,0-1.7Zm0,5.091h20.366a.849.849,0,1,1,0,1.7H171.978a.849.849,0,1,1,0-1.7Zm0,5.091h28.85a.849.849,0,1,1,0,1.7h-28.85a.849.849,0,1,1,0-1.7Zm-.849,5.939a.848.848,0,0,1,.849-.849h28.85a.849.849,0,1,1,0,1.7h-28.85A.848.848,0,0,1,171.129,113.158Z" transform="translate(0 0)" fill="currentColor" />
          <path id="Path_31556" data-name="Path 31556" d="M525.263,204.89l-4.022,5.436,1.6,1.21,2.431,1.834,4.061-5.435Z" transform="translate(-297.183 -112.652)" fill="currentColor" />
        </g>
      </svg>

    </>
  )
}

export default CooperationAgreementIcon