import { t } from "components/CentralDataMangement/translation/Translation";
import LabelField from "components/common/atoms/LabelField";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import { WorkingHoursProps } from "components/CompanyCreation/AdminFiche/annotations/AdminFicheInterface";
import React from "react";

interface props {
  shiftTable: WorkingHoursProps;
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    tableId: number,
    KBId?: number | null
  ) => void;
  tableId: number;
}

const PaidHours: React.FC<props> = ({ shiftTable, handleChange, tableId }) => {
  return (
    <div className="row paidHoursWrapper">
      <div className="col-md-4">
        <LabelWithInputField
          type="text"
          label={t("Effective hours full-time / week employer")}
          handleChange={(e) => handleChange(e, tableId)}
          value={shiftTable.EffectvHrsFullTime ?? ""}
          name="EffectvHrsFullTime"
        />
      </div>
      <div className="col-md-4">
        <LabelWithInputField
          type="text"
          label={t("Average hours full-time/week employer")}
          handleChange={(e) => handleChange(e, tableId)}
          value={shiftTable.AvrgHrsFullTime ?? ""}
          name="AvrgHrsFullTime"
        />
      </div>
      <div className="col-md-4">
        <LabelWithInputField
          type="text"
          label={t("Effective hours/week employee")}
          handleChange={(e) => handleChange(e, tableId)}
          value={shiftTable.EffectvHrs ?? ""}
          name="EffectvHrs"
        />
      </div>
      <div className="col-md-4 advPerWeek">
        <LabelWithInputField
          type="text"
          label={t("Paid ADV hours per week")}
          handleChange={(e) => handleChange(e, tableId)}
          value={shiftTable.paidADV ?? ""}
          name="paidADV"
        />
      </div>
      <div className="col-md-4 advPerWeek">
        <LabelWithInputField
          type="text"
          label={t("Unpaid ADV hours per week")}
          handleChange={(e) => handleChange(e, tableId)}
          value={shiftTable.unPaidADV ?? ""}
          name="unPaidADV"
        />
      </div>

      <LabelField title={t("Overtime")} className="tab-subtitle pb-0 marginBottomPoint5" />
      <div className="col-4 hideHeight">
        <div className="input-group adminFicheSuffixTextOvertime adminFicheSuffixTextOvertimeLabel position-relative">
          <LabelWithInputField
            type="text"
            label=""
            handleChange={(e) => handleChange(e, tableId)}
            value={shiftTable.overtimeDay ?? ""}
            name="overtimeDay"
          />
          <span
            className="input-group-text rounded-start-0 rounded-end-3 border-0 position-absolute"
            id="basic-addon1"
          >
            {t(" hours/day at 100%")}
          </span>
        </div>
      </div>
      <div className="col-4 hideHeight">
        <div className="input-group adminFicheSuffixTextOvertime adminFicheSuffixTextOvertimeLabel position-relative">
          <LabelWithInputField
            type="text"
            label=""
            handleChange={(e) => handleChange(e, tableId)}
            value={shiftTable.overtimeWeek ?? ""}
            name="overtimeWeek"
          />
          <span
            className="input-group-text rounded-start-0 rounded-end-3 border-0 position-absolute"
            id="basic-addon1"
          >
            {t(" hours/week at 100%")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default React.memo(PaidHours);
