import React, { useState } from "react";
import { ApiCall } from "components/common/services/ApiServices";
import Button from "components/common/atoms/Button";
import CustomNotify from "components/common/atoms/CustomNotify";
import { t, translate } from "components/CentralDataMangement/translation/Translation";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import TimeField from "react-simple-timefield";
import LabelField from "components/common/atoms/LabelField";
import { CONF_SETTINGS_UPDATE } from "routes/ApiEndpoints";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "Constants";
import { validateForm, validateNumberField, validateRequired, validateTimeString } from "components/common/services/ValidationService";
import { ValidationRules } from "components/common/utlis/TypeAnnotations";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";

interface PcFormData {
  id: number;
  pc_number: string;
  pc_name: string;
  pc_alias_name: string;
  full_paid_age: null | number;
  min_start_age: null | number;
  start_working_hours: null | string;
  stop_working_hours: null | string;
  max_hours_plannable: null | string;
  min_hours_plannable: null | string;
  buffer_hrs_btw_plannings: null | string;
  max_working_hours_wk_warning: null | string;
  max_working_hours_wk_error: null | string;
}

interface FormData {
  pc: PcFormData;
}

const ParitairCommittee: React.FC<FormData> = ({ pc }) => {
  //Initialize the formData state fields
  const {
    id,
    pc_number,
    pc_name,
    pc_alias_name,
    full_paid_age,
    min_start_age,
    start_working_hours,
    stop_working_hours,
    max_hours_plannable,
    min_hours_plannable,
    buffer_hrs_btw_plannings,
    max_working_hours_wk_warning,
    max_working_hours_wk_error,
  } = pc;

  // Use PcFormData as the state type for formData
  const [formData, setFormData] = useState({
    id: id,
    pcNumber: pc_number ? pc_number : "",
    pcName: pc_name ? pc_name : "",
    pcAliasName: pc_alias_name ? pc_alias_name : "",
    fullPaidAge: full_paid_age ? full_paid_age : "",
    minStartAge: min_start_age ? min_start_age : "",
    startTime: start_working_hours ? start_working_hours : "",
    stopTime: stop_working_hours ? stop_working_hours : "",
    bufferTime: buffer_hrs_btw_plannings ? convertToTimeFormat(buffer_hrs_btw_plannings) : "",
    maxHours: max_hours_plannable ? convertToTimeFormat(max_hours_plannable) : "",
    minHours: min_hours_plannable ? convertToTimeFormat(min_hours_plannable) : "",
    maxWarning: max_working_hours_wk_warning ? max_working_hours_wk_warning : null,
    maxError: max_working_hours_wk_error ? max_working_hours_wk_error : null,
  });

  function convertToTimeFormat(timeString: string): string {
    const [hours, minutes] = timeString.split('.').map(Number);

    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes ? minutes.toString().padStart(2, '0') : '00';

    return `${formattedHours}:${formattedMinutes}`;
  }

  const initialErrors: { [key: string]: string } = {};
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>(
    initialErrors
  );

  const handleFieldChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    if(name === 'maxWarning' || name === 'maxError'){
      const newValue = Math.min(Math.max(Number(value), 0), 99.99);
      setFormData((prevData) => ({
        ...prevData,
        [name]:  String(newValue),
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const validation = (
    name: string,
    value: string | boolean | null,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      pcNumber: [validateRequired],
      pcName: [validateRequired],
      // fullPaidAge: [validateNumberField],
      // minStartAge: [validateNumberField],
      // bufferTime: [validateTimeString],
      // startTime: [validateTimeString],
      // stopTime: [validateTimeString],
      // maxHours: [validateTimeString],
      // minHours: [validateTimeString],
      // maxWarning: [validateRequired],
      // maxError: [validateRequired]
    };

    const validationErrors = validateForm(
      { ...formData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation && Object.keys(errors).length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }

    return true;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    setLoading(true);

    e.preventDefault();
    const { name, value } = e.target as HTMLInputElement;
    const data = { type: 'paritairCommittee', ...formData };
    if (validation(name, value)) {
      let response = await ApiCall.service(CONF_SETTINGS_UPDATE, "POST", data, false, CENTRAL_DATA_MANAGEMENT_MICROSERVICE);
      if (response.status === 200) {
        CustomNotify({ type: "success", message: t(response.msg) });
        await new Promise(resolve => setTimeout(resolve, 3000));
        window.location.reload();
      } else if (response.status === 400) {
        let errors: any = [];
        for (const key in response.errors) {
          errors[key] = response.errors[key];
        }
        setErrors({ ...errors });
      }
    }
    setLoading(false);
  };

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Paritair committe",
          read: true,
        },
      ]}
      renderNoAccess={true}
    >
      <div className="col-md-12 col-lg-5 col-xl-4 col-xxl-4 my-4">
        <div className="form-border padding1">
          <LabelField
            className="tab-subtitle"
            title={t("Edit paritair committee")}
          />
          <div className="row">
            <div className="col-sm-12 col-md-12">
              <LabelWithInputField
                label={t("Paritair committee number")}
                name={"pcNumber"}
                type="number"
                handleChange={handleFieldChange}
                isMandatory={true}
                value={formData.pcNumber}
                error={errors.pcNumber}
                id="pcNumber"
              />
            </div>
            <div className="col-sm-12 col-md-12">
              <LabelWithInputField
                label={t("Paritair committee name")}
                name={"pcName"}
                value={formData.pcName}
                handleChange={handleFieldChange}
                isMandatory={true}
                error={errors.pcName}
                id="pcName"
              />
            </div>
            <div className="col-sm-12 col-md-12">
              <LabelWithInputField
                label={t("Paritair committee alias name")}
                name={"pcAliasName"}
                handleChange={handleFieldChange}
                value={formData.pcAliasName}
                id="pcAliasName"
              />
            </div>
            <div className="col-sm-12 col-md-12">
              <LabelWithInputField
                label={t("Age to get full paid")}
                name={"fullPaidAge"}
                type="number"
                handleChange={handleFieldChange}
                // isMandatory={true}
                value={formData.fullPaidAge}
                error={errors.fullPaidAge}
                id="fullPaidAge"
              />
            </div>
            <div className="col-sm-12 col-md-12">
              <LabelWithInputField
                label={t("Minimum age to start")}
                name={"minStartAge"}
                type="number"
                handleChange={handleFieldChange}
                // isMandatory={true}
                value={formData.minStartAge}
                error={errors.minStartAge}
                id="minStartAge"
              />
            </div>
            <div className="col-sm-12 col-md-12  time-section">
              <LabelField title={t("Buffer time between two plannings")} />
              <div className="position-relative pc-buffer-time">
                <TimeField
                  value={formData.bufferTime}
                  onChange={handleFieldChange}
                  input={<input name={"bufferTime"}></input>}
                  style={{
                    width: "100%",
                    height: "2.344vw",
                    border: "0px",
                    borderRadius: "0.4vw",
                    paddingLeft: "0.4vw",
                    fontSize: "1vw",
                  }}
                />
                <span className="input-group-text rounded-start-0 rounded-end-3 border-0 position-absolute">
                  {t("Hours")}
                </span>
              </div>
              <div className="height-20" style={{ marginBottom: "0.5vw" }}>
                {errors.bufferTime && (
                  <span className="text-danger">{errors.bufferTime}</span>
                )}
              </div>
            </div>

            <div className="col-sm-12 col-md-12  time-section">
              <LabelField title={t("Starting work time")} />
              <div className="position-relative pc-buffer-time">
                <TimeField
                  value={formData.startTime}
                  onChange={handleFieldChange}
                  input={<input name={"startTime"}></input>}
                  style={{
                    width: "100%",
                    height: "2.344vw",
                    border: "0px",
                    borderRadius: "0.4vw",
                    paddingLeft: "0.4vw",
                    fontSize: "1vw",
                  }}
                />
                <span className="input-group-text rounded-start-0 rounded-end-3 border-0 position-absolute">
                  {t("Hours")}
                </span>
              </div>
              <div className="height-20" style={{ marginBottom: "0.5vw" }}>
                {errors.startTime && (
                  <span className="text-danger">{errors.startTime}</span>
                )}
              </div>
            </div>
            <div className="col-sm-12 col-md-12 time-section">
              <LabelField title={t("Stop work time")} />
              <div className="position-relative pc-buffer-time">
                <TimeField
                  value={formData.stopTime}
                  onChange={handleFieldChange}
                  input={<input name={"stopTime"}></input>}
                  style={{
                    width: "100%",
                    height: "2.344vw",
                    border: "0px",
                    borderRadius: "0.4vw",
                    paddingLeft: "0.4vw",
                    fontSize: "1vw",
                  }}
                />
                <span className="input-group-text rounded-start-0 rounded-end-3 border-0 position-absolute">
                  {t("Hours")}
                </span>
              </div>
              <div className="height-20" style={{ marginBottom: "0.5vw" }}>
                {errors.stopTime && (
                  <span className="text-danger">{errors.stopTime}</span>
                )}
              </div>
            </div>

            <div className="col-sm-12 col-md-12 time-section">
              <LabelField title={t("Minimum work timings per day")} />
              <div className="position-relative pc-buffer-time">
                <TimeField
                  value={formData.minHours}
                  onChange={handleFieldChange}
                  input={<input name={"minHours"}></input>}
                  style={{
                    width: "100%",
                    height: "2.344vw",
                    border: "0px",
                    borderRadius: "0.4vw",
                    paddingLeft: "0.4vw",
                    fontSize: "1vw",
                  }}
                />
                <span className="input-group-text rounded-start-0 rounded-end-3 border-0 position-absolute">
                  {t("Hours")}
                </span>
              </div>
              <div className="height-20" style={{ marginBottom: "0.5vw" }}>
                {errors.minHours && (
                  <span className="text-danger">{errors.minHours}</span>
                )}
              </div>
            </div>
            <div className="col-sm-12 col-md-12 time-section">
              <LabelField title={t("Maximum work timings per day")} />
              <div className="position-relative pc-buffer-time">
                <TimeField
                  value={formData.maxHours}
                  onChange={handleFieldChange}
                  input={<input name={"maxHours"}></input>}
                  style={{
                    width: "100%",
                    height: "2.344vw",
                    border: "0px",
                    borderRadius: "0.4vw",
                    paddingLeft: "0.4vw",
                    fontSize: "1vw",
                  }}
                />
                <span className="input-group-text rounded-start-0 rounded-end-3 border-0 position-absolute">
                  {t("Hours")}
                </span>
              </div>
              <div className="height-20" style={{ marginBottom: "0.5vw" }}>
                {errors.maxHours && (
                  <span className="text-danger">{errors.maxHours}</span>
                )}
              </div>
            </div>

            <div className="col-sm-12 col-md-12 time-section">
              <div className="position-relative maxWorkTiming">
                <LabelWithInputField
                  label={t("Maximum work timings per week (Warning)")}
                  name={"maxWarning"}
                  type="number"
                  handleChange={handleFieldChange}
                  // isMandatory={true}
                  value={formData.maxWarning}
                  error={errors.maxWarning}
                  id="maxWarning"
                />
                <span className="input-group-text rounded-start-0 rounded-end-3 border-0 position-absolute">
                  {t("Hours")}
                </span>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 time-section">
              <div className="position-relative maxWorkTiming">
                <LabelWithInputField
                  label={t("Maximum work timings per week")}
                  multiLabel={t("Error")}
                  name={"maxError"}
                  type="number"
                  handleChange={handleFieldChange}
                  // isMandatory={true}
                  value={formData.maxError}
                  error={errors.maxError}
                  id="maxError"
                />
                <span className="input-group-text rounded-start-0 rounded-end-3 border-0 position-absolute">
                  {t("Hours")}
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Button
                type="submit"
                title={t("Save")}
                handleClick={handleSubmit}
                className="float-end form-button shadow-none"
              />
            </div>
          </div>
        </div>
      </div>
    </AccessControl>
  );
};

export default translate(ParitairCommittee);
