import React from "react";
import AgreementForm from "../create-agreement/formContent/AgreementForm";
import { FormProvider } from "./Context";

export const MultiFormProviderAgreement = () => {
  return (
    <>
      <FormProvider>
        <AgreementForm />
      </FormProvider>
    </>
  );
};