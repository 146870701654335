import React, { useState } from "react";
import CheckBoxField from "components/common/atoms/CheckBoxField";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import "../css/Company.css";
import Button from "components/common/atoms/Button";
import { useFormContext } from "components/CompanyCreation/Company/context/Context";
import { ValidationRules } from "components/common/utlis/TypeAnnotations";
import {
  validateForm,
  validateRequired,
  validateMultiSelectField,
  Option,
  scrollToTop,
  validateVatNumber,
} from "../../../../common/services/ValidationService";
import { ApiCall } from "components/common/services/ApiServices";
import {
  GET_COMPANY,
  GET_OFFICE_FOR_CONSULTANT,
  VERIFY_VAT_NUMBER,
} from "routes/ApiEndpoints";
import { Link, useNavigate, useParams } from "react-router-dom";
import CustomNotify from "components/common/atoms/CustomNotify";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import GetQueryParams from "components/common/services/GetQueryParams";
import Navigation from "../formNavigation/Navigation";
import ModalPopup from "components/common/atoms/ModalPopup";
import {
  CANDIDATE_MICROSERVICE,
  COMPANY_MICROSERVICE,
  WEBCONNECTOR,
} from "Constants";
import LoadingIcon from "components/common/utlis/LoadingIcon";

const Basic = () => {
  const { state, dispatch } = useFormContext();
  const navigate = useNavigate();
  const queryParams = GetQueryParams();
  const [validationStatus, setValidationStatus] = useState({
    isValid: false,
    type: "",
  });
  const [loading, setLoading] = useState(false);

  const validation = (
    name: string,
    value: string | boolean | number | Option | null,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      vat: [validateVatNumber],
      companyName: [validateRequired],
      officialName: [validateRequired],
    };

    const validationErrors = validateForm(
      { ...state.basic, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    dispatch({
      type: "UPDATE_FIELD_ERROR",
      fieldName: name,
      error: validationErrors[name],
    });
    const isFieldValid = Object.keys(validationErrors).length === 0;

    if (isFieldValid) {
      state.fieldError[name] = "";
    }
    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;

    if (type === "checkbox") {
      if (name === "hq" && checked) {
        dispatch({
          type: "UPDATE_BASIC_FIELD",
          field: "parentCompanies",
          value: null,
        });
        dispatch({
          type: "UPDATE_BASIC_FIELD",
          field: "coEfficient",
          value: 0,
        });
      }

      const newValue = checked ? 1 : 0;
      dispatch({ type: "UPDATE_BASIC_FIELD", field: name, value: newValue });
      validation(name, checked, true);
    } else {
      dispatch({ type: "UPDATE_BASIC_FIELD", field: name, value });
      validation(name, value, true);
    }
  };

  const handleSelectChange = (selectedOption: any, name: string) => {
    if (name === "consultant") {
      getOfficeForConsultant(selectedOption);
    }
    dispatch({
      type: "UPDATE_BASIC_FIELD",
      field: name,
      value: selectedOption,
    });
    validation(name, selectedOption, true);
  };

  const validStatus = (validation: any) => {
    setValidationStatus({
      isValid: validation.isValid,
      type: validation.type,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    scrollToTop();
  };

  // Verifying vat number
  const [showModal, setShowModal] = useState(false);
  // const [compId, setCompId] = useState(null);
  const [modalData, setmodalData] = useState({
    compId: null,
    body: "",
    status: null,
  });
  const verifyVatNumber = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      vat_number: state.basic.vat,
      key: "CJL1tYTPAY",
      // `${process.env.REACT_APP_WEBCONNECTOR_UNIQUE_KEY}`
    };
    validation("vat", state.basic.vat, true);
    if (state.fieldError.vat === "" && state.basic.vat.trim() !== "") {
      const vatResponse = await ApiCall.service(
        VERIFY_VAT_NUMBER,
        "POST",
        data,
        false,
        COMPANY_MICROSERVICE
      );
      if (vatResponse.status === 200) {
        if (vatResponse?.data.id !== null) {
          setShowModal(true);
          setmodalData((prev) => ({
            ...prev,
            compId: vatResponse?.data.id,
            status: vatResponse?.data.status,
            body:
              vatResponse?.data.status === 2
                ? `${t("This vat number already extists for archived company")}`
                : `${t(
                    "There is already a company with this VAT number. Would you like to open the company"
                  )}?`,
          }));
        } else if (vatResponse.data.id === null) {
          const response = await ApiCall.service(
            GET_COMPANY,
            "POST",
            data,
            false,
            WEBCONNECTOR,
            false
          );

          if (response?.status !== 200) {
            CustomNotify({ type: "error", message: response?.error });
            dispatch({
              type: "UPDATE_BASIC_FIELD",
              field: "companyName",
              value: "",
            });
            dispatch({ type: "UPDATE_TAB_ERROR", tabIndex: 0, error: true });
            scrollToTop();
            state.fieldError.vat = response?.error;
          } else {
            const value = response?.data?.name;
            const { street, number, box, postal, city } =
              response?.data?.address;

            const cityValueAndLabel = getCityValueAndLabel(city);
            handleAddressUpdate(street, number, box, postal, cityValueAndLabel);

            dispatch({
              type: "UPDATE_BASIC_FIELD",
              field: "companyName",
              value,
            });
            dispatch({
              type: "UPDATE_FIELD_ERROR",
              fieldName: "companyName",
              error: "",
            });
            dispatch({
              type: "UPDATE_BASIC_FIELD",
              field: "officialName",
              value,
            });
            dispatch({
              type: "UPDATE_FIELD_ERROR",
              fieldName: "officialName",
              error: "",
            });
            dispatch({ type: "UPDATE_TAB_ERROR", tabIndex: 0, error: false });
            CustomNotify({
              type: "success",
              message: "VAT number is verified",
            });
          }
        }
      }
    }
    setLoading(false);
  };

  const getCityValueAndLabel = (cityName: string) => {
    const city = state?.cityList?.find((city) =>
      city?.label?.includes(cityName)
    );
    if (city && city.value !== undefined && city.label !== undefined) {
      return { value: city.value, label: city.label };
    } else {
      return null;
    }
  };

  const handleAddressUpdate = (
    street: string,
    number: string,
    unit: string,
    postal: string,
    city: Option | null,
    countryValue: { value: number; label: string } = {
      value: 29,
      label: "Belgium",
    }
  ) => {
    const addressFields = [
      { field: "street", value: street },
      { field: "number", value: number },
      { field: "box", value: unit },
      { field: "zipCode", value: postal },
      { field: "city", value: city },
      { field: "country", value: countryValue },
    ];

    addressFields.forEach((field) => {
      dispatch({
        type: "UPDATE_ADDRESS_FIELD",
        field: field.field,
        value: field.value,
      });
      dispatch({
        type: "UPDATE_FIELD_ERROR",
        fieldName: field.field,
        error: "",
      });
      dispatch({
        type: "UPDATE_ADDRESS_FIELD",
        field: `bi${
          field.field.charAt(0).toUpperCase() + field.field.slice(1)
        }`,
        value: field.value,
      });
      dispatch({
        type: "UPDATE_FIELD_ERROR",
        fieldName: `bi${
          field.field.charAt(0).toUpperCase() + field.field.slice(1)
        }`,
        error: "",
      });
    });
  };

  const handleYes = () => {
    if (modalData.compId) {
      navigate(`/company/${modalData.compId}`);
      window.location.reload();
    }
    setShowModal(false);
  };

  const handleNo = () => {
    setShowModal(false);
    dispatch({ type: "UPDATE_BASIC_FIELD", field: "vat", value: "" });
  };

  const getConsultantList = () => {
    const consultants = state.cosultantsList;
    if (consultants.length > 0) {
      return consultants.map((item: any) => ({
        value: item.userId,
        label: `${item.first_name} ${item.last_name}`,
      }));
    }

    return [];
  };

  const getOfficeForConsultant = (selectedOption: any) => {
    //To prefill default office for selected consultant
    const consultantId = selectedOption.value;
    if (consultantId) {
      const fetchOfficeForConsultant = async () => {
        const response = await ApiCall.getService(
          `${GET_OFFICE_FOR_CONSULTANT}/${consultantId}`,
          "GET",
          CANDIDATE_MICROSERVICE
        );
        if (response.status === 200) {
          dispatch({
            type: "UPDATE_BASIC_FIELD",
            field: "office",
            value: response.office,
          });
        }
      };
      fetchOfficeForConsultant();
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12">
          <div className="form-border" style={{ paddingTop: "3.4vw" }}>
            <div className="row">
              <div className="col-6 generalTabVAT">
                <div className="input-group position-relative">
                  <div className="position-absolute fieldPrefix">BE</div>
                  <LabelWithInputField
                    isMandatory={true}
                    name="vat"
                    handleChange={handleFieldChange}
                    value={state.basic.vat}
                    id="vat"
                    label={t("VAT number")}
                    type="text"
                    error={state.fieldError.vat}
                    placeholder={t("VAT number")}
                    isDisabled={state.basic.companyStatus === 1}
                  />
                  {!(state.basic.companyStatus === 1) && (
                    <>
                      {loading === true ? (
                        <LoadingIcon
                          iconType="bars"
                          color="#ff4dae"
                          className="px-4 shadow-none rounded-0 companyVatVerify position-absolute end-0 rounded-end-3 mt-1"
                          height={"3vw"}
                          width={"3vw"}
                        />
                      ) : (
                        <Button
                          type="submit"
                          title={t("Verify")}
                          handleClick={verifyVatNumber}
                          className="form-button shadow-none button-width companyVatVerify position-absolute end-0"
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <LabelWithInputField
                  isMandatory={true}
                  name="companyName"
                  handleChange={handleFieldChange}
                  value={state.basic.companyName}
                  id="company"
                  label={t("Company name")}
                  type="text"
                  placeholder={t("Company name")}
                  error={state.fieldError.companyName}
                />
              </div>
              <div className="col-sm-12 col-md-6">
                <LabelWithInputField
                  isMandatory={true}
                  name="officialName"
                  handleChange={handleFieldChange}
                  value={state.basic.officialName}
                  id="official"
                  label={t("Official name")}
                  type="text"
                  placeholder={t("Official name")}
                  error={state.fieldError.officialName}
                />
              </div>
              <div className="col-sm-12 col-md-6">
                <LabelWithInputField
                  // isMandatory={true}
                  name="website"
                  handleChange={handleFieldChange}
                  value={state.basic.website}
                  id="website"
                  label={t("Website")}
                  type="text"
                  placeholder={t("Website")}
                  // error={state.fieldError.website}
                />
              </div>
              <div className="col-sm-12 col-md-6">
                <SelectWithSearch
                  title={t("Paritair committee")}
                  name="pc"
                  isMandatory={false}
                  search={true}
                  options={state.pcList}
                  value={state.basic.pc}
                  onChange={(e) => handleSelectChange(e, "pc")}
                  isMulti={true}
                  error={state.fieldError.pc}
                  isTranslate={true}
                />
              </div>
              <div className="col-sm-6">
                <SelectWithSearch
                  title={t("Sector")}
                  name="sector"
                  isMandatory={false}
                  search={true}
                  options={state.sectorList}
                  value={state.basic.sector}
                  onChange={(e) => handleSelectChange(e, "sector")}
                  isMulti={false}
                  isTranslate={true}
                />
              </div>
              <div className="col-sm-6">
                <SelectWithSearch
                  title={t("Domain")}
                  name="domain"
                  isMandatory={false}
                  search={true}
                  options={state.domainList}
                  value={state.basic.domain}
                  onChange={(e) => handleSelectChange(e, "domain")}
                  isMulti={false}
                  isTranslate={true}
                />
              </div>
              <div className="col-sm-6">
                <SelectWithSearch
                  title={t("Function")}
                  name="function"
                  isMandatory={false}
                  search={true}
                  options={state.functionList}
                  value={state.basic.function}
                  onChange={(e) => handleSelectChange(e, "function")}
                  isMulti={true}
                  isTranslate={true}
                />
              </div>
              <div className="col-sm-6">
                <SelectWithSearch
                  title={t("Consultant")}
                  isMandatory={false}
                  search={true}
                  options={getConsultantList()}
                  onChange={(e) => handleSelectChange(e, "consultant")}
                  isMulti={false}
                  name="consultant"
                  value={state.basic.consultant}
                />
              </div>
              <div className="col-sm-6">
                <SelectWithSearch
                  title={t("Office")}
                  isMandatory={false}
                  search={true}
                  options={state.officesList}
                  onChange={(e) => handleSelectChange(e, "office")}
                  isMulti={false}
                  name="office"
                  value={state.basic.office}
                  isTranslate={true}
                />
              </div>
              <div className="col-md-12 marginBotttom1">
                <CheckBoxField
                  className="custom-temp-field-label"
                  label={t("This is parent company")}
                  name="hq"
                  onChangeHandler={handleFieldChange}
                  isChecked={state.basic.hq === 1}
                  lineHeight="1.1vw"
                  id="parentCompany"
                />
              </div>
              <div className="col-sm-6">
                <SelectWithSearch
                  title={t("Select parent company")}
                  name="parentCompanies"
                  isMandatory={false}
                  search={true}
                  options={state.companiesList}
                  isDisabled={state.basic.hq === 1}
                  value={state.basic.parentCompanies}
                  onChange={(e) => handleSelectChange(e, "parentCompanies")}
                  isMulti={false}
                  error={state.fieldError.parentCompanies}
                />
              </div>
              {state.basic.hq === 0 && (
                <div className="col-md-12">
                  <CheckBoxField
                    className="custom-temp-field-label"
                    label={t(
                      "Follow the same coefficient as the parent company"
                    )}
                    name="coEfficient"
                    onChangeHandler={handleFieldChange}
                    isChecked={state.basic.coEfficient === 1}
                    id="coEfficient"
                    lineHeight="1.1vw"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-6 align-self-center">
          <Link
            to={`${queryParams.get("destination") ?? "/manage-companies"}`}
            className=" back-btn text-decoration-underline"
          >
            {t("Back")}
          </Link>
        </div>
        <div className="col-6">
          <Navigation validStatus={validStatus} />
        </div>
      </div>
      <ModalPopup
        show={showModal}
        title={t("Company already exists")}
        body={modalData.body}
        onConfirmButtonClick={() => handleYes()}
        onCloseButtonClick={() => handleNo()}
        closeTitle={modalData.status === 2 ? t("Ok") : t("No")}
        confirmTitle={modalData.status === 2 ? "" : t("Yes")}
        showCloseButton={false}
      />
    </form>
  );
};

export default translate(Basic);
