import React from 'react'

const CoefficientDeviation:React.FC = () => {
  return (
    <>
        <svg xmlns="http://www.w3.org/2000/svg" width="123.55" height="79.987" viewBox="0 0 123.55 79.987">
            <path fill='currentcolor' id="Manage_Coefficients" data-name="Manage Coefficients" d="M33.918,79.988l-8.661-.157a1.747,1.747,0,0,1-1.636-1.46l-1.081-9.133A24.237,24.237,0,0,1,17,65.737l-8.314,3.3a1.643,1.643,0,0,1-.569.1,1.675,1.675,0,0,1-1.458-.88L.215,56.223a1.775,1.775,0,0,1,.417-2.207L7.75,48.43a27.251,27.251,0,0,1-.181-3.4,26.114,26.114,0,0,1,.293-3.343L.934,35.842a1.78,1.78,0,0,1-.341-2.222l6.843-11.8A1.656,1.656,0,0,1,8.864,21a1.6,1.6,0,0,1,.624.125l8.2,3.6a25.337,25.337,0,0,1,5.656-3.3l1.387-9.087a1.748,1.748,0,0,1,1.686-1.448l7.507.136V33.583a11.191,11.191,0,0,0-1.243-.1l-.205,0A11.816,11.816,0,0,0,20.939,45.268a12.169,12.169,0,0,0,3.236,8.54,11.265,11.265,0,0,0,8.1,3.659l.205,0a11.287,11.287,0,0,0,1.439-.093V79.988Zm2.521-2.644V10.885h4.67V72.673h75.7v4.67ZM84.56,60.177l-18.8-31.431L48.208,44.676l-3.138-3.46,21.77-19.761L68.3,23.9,86.5,54.314,113.027,6.881l-9.933,2.866L101.8,5.26,120.026,0l3.524,17.887-4.582.9-1.89-9.581L86.632,63.64Z" transform="translate(0)"/>
        </svg>
    </>
  )
}

export default CoefficientDeviation