import { OptionProps } from "components/common/utlis/TypeAnnotations";

interface dropDownList {
    offices: OptionProps[];
    meetingTypes: OptionProps[];
    meetingStatus: OptionProps[];
}

export const createFilterConfig = (dropDownList: dropDownList) => {
    return [
        {
            name: "office",
            options: dropDownList.offices,
            fieldType: "multiSelect",
            placeholder: "Office",
            filterType: 'search',
            label: 'Office'
        },
        {
            name: "meetingTitle",
            fieldType: "text",
            placeholder: "Meeting title",
            filterType: 'search'
        },
        {
            name: "meetingType",
            options: dropDownList.meetingTypes,
            fieldType: "singleSelect",
            placeholder: "Meeting type",
            filterType: 'search',
        },
        {
            name: "from",
            fieldType: "date",
            placeholder: "From",
            filterType: 'search',
            group: 'meetingDate'
        },
        {
            name: "to",
            fieldType: "date",
            placeholder: "To",
            filterType: 'search',
            group: 'meetingDate'
        },
        {
            name: "meetingBy",
            fieldType: "text",
            placeholder: "Meeting by",
            filterType: 'search'
        },
        {
            name: "meetingWith",
            fieldType: "text",
            placeholder: "Meeting with",
            filterType: 'search'
        },
        {
            name: "meetingStatus",
            options: dropDownList.meetingStatus,
            fieldType: "singleSelect",
            placeholder: "Meeting status",
            filterType: 'search',
        }
    ];
};
