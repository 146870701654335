import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import {
  ADMIN_FICHE_PREVIEW_BACKEND,
  MANAGE_COOPERATION_AGREEMENT,
} from "routes/RouteConstants";
import * as ENDPOINTS from "../../../../routes/ApiEndpoints";
import { COMPANY_MICROSERVICE } from "Constants";
import { ApiCall } from "components/common/services/ApiServices";
import EncryptDecryptService from "components/common/services/EncryptDecryptService";
import { ValidationRules } from "components/common/utlis/TypeAnnotations";
import {
  validateEmail,
  validateForm,
  validatePassword,
  validateRequired,
} from "components/common/services/ValidationService";
import AuthenticationModal from "components/common/utlis/AuthenticationModal";
import ActionButtonsSection from "./ActionButtonsSection";
import { selectAuth } from "features/auth/AuthSlice";
import { useSelector } from "react-redux";
import CustomNotify from "components/common/atoms/CustomNotify";
import { t } from "components/CentralDataMangement/translation/Translation";

interface Preview {
  iframeUrl: string;
  isTermsAccepted: boolean;
  email: string;
  password: string;
  isAuthenticated: boolean;
  signAsEmployerPopup: boolean;
  employerId: number | null;
}

const AdminFichePreview: React.FC = () => {
  const { agreementId, adminFicheId } = useParams<{
    agreementId: string;
    adminFicheId: string;
  }>();
  const userAuth = useSelector(selectAuth);
  const navigate = useNavigate();
  const isEmployer = userAuth.role === "EMPLOYER";
  const langKey = secureLocalStorage.getItem("site_lang") ?? "en";

  const initialState: Preview = {
    iframeUrl: `${process.env.REACT_APP_COMPANY_SERVICE}/${ADMIN_FICHE_PREVIEW_BACKEND}/${agreementId}/${adminFicheId}?lang=${langKey}`,
    isTermsAccepted: false,
    email: isEmployer ? userAuth.email : "",
    password: "",
    isAuthenticated: isEmployer ? true : false,
    signAsEmployerPopup: false,
    employerId: isEmployer ? userAuth.userId : null,
  };

  const [state, setState] = useState<Preview>(initialState);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = event.target;
    if (event.target.type === "checkbox" && !checked) {
      setState(initialState);
    }
    setState((prevState) => ({
      ...prevState,
      [name]: event.target.type === "checkbox" ? checked : value,
    }));
  };

  const signAsEmployer = () => {
    setState((prevData) => ({
      ...prevData,
      signAsEmployerPopup: true,
    }));
  };

  const handleModalClose = (type: string) => {
    setState((prevData) => ({
      ...prevData,
      signAsEmployerPopup: false,
    }));
  };

  const validation = (
    name: string,
    value: string | boolean | number,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      email: [validateRequired, validateEmail],
      password: [validateRequired, validatePassword],
    };

    const validationErrors = validateForm(
      { ...state, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation && Object.keys(errors).length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }

    return true;
  };

  const checkAuthentication = async (event: React.FormEvent) => {
    event.preventDefault();
    const { name, value } = event.target as HTMLInputElement;
    const formData = {
      email: state.email,
      password: EncryptDecryptService.encryptData(
        JSON.stringify(state.password)
      ),
      agreementId: agreementId,
      adminFicheId: adminFicheId,
    };
    if (validation(name, value)) {
      const response = await ApiCall.service(
        ENDPOINTS.CHECK_AUTHENTICATION,
        "POST",
        formData,
        true,
        COMPANY_MICROSERVICE
      );

      if (response.status === 200) {
        setState((prevData) => ({
          ...prevData,
          iframeUrl: `${process.env.REACT_APP_COMPANY_SERVICE}/${ADMIN_FICHE_PREVIEW_BACKEND}/${agreementId}/${adminFicheId}?employer=${response.msg}&verify=1&lang=${langKey}`,
          isAuthenticated: true,
          signAsEmployerPopup: false,
          employerId: response.msg,
        }));
        setErrors({
          email: "",
        });
      } else {
        setErrors({
          email: response.message,
        });
      }
    }
  };

  const handleSubmit = async (sendToEmployer: boolean) => {
    const formData = {
      sendToEmployer: sendToEmployer,
      agreementId: agreementId,
      adminFicheId: adminFicheId,
      loggedInUserId: userAuth.userId,
      langKey: langKey,
      employerId: state.employerId,
      email: state.email
    };
    const response = await ApiCall.service(
      ENDPOINTS.APPROVE_ADMIN_FICHE,
      "POST",
      formData,
      true,
      COMPANY_MICROSERVICE
    );

    if (response.status === 200) {
      CustomNotify({
        type: "success",
        message: t("Mail sent to employer successfully"),
      });
      navigate(MANAGE_COOPERATION_AGREEMENT);
    } else {
      CustomNotify({
        type: "error",
        message: response.message,
      });
    }
  };

  return (
    <div>
      <iframe
        height={window.screen.height - 350}
        width="100%"
        src={state.iframeUrl}
        title="Embedded Content"
        allowFullScreen
      ></iframe>

      <ActionButtonsSection
        isAuthenticated={state.isAuthenticated}
        isTermsAccepted={state.isTermsAccepted}
        changeHandler={changeHandler}
        signAsEmployer={signAsEmployer}
        handleSubmit={handleSubmit}
        agreementId={agreementId || ""}
        adminFicheId={adminFicheId || ""}
        langKey={langKey}
        isEmployer={isEmployer}
      />

      <AuthenticationModal
        show={state.signAsEmployerPopup}
        handleModalClose={handleModalClose}
        checkAuthentication={checkAuthentication}
        state={state}
        errors={errors}
        changeHandler={changeHandler}
      />
    </div>
  );
};

export default AdminFichePreview;
