import React from "react";

const AdvancedSearchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="77"
      height="73"
      viewBox="0 0 77 73"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8195 23H18.8512L23.7023 34.151H20.9303L19.9695 31.7885H15.6383L14.709 34.151H12L16.8195 23ZM17.7645 26.2445L16.41 29.7095H19.1348L17.7645 26.2445Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.8238 23H28.5093C29.4018 23 30.2471 23.0998 31.0451 23.2992C31.8536 23.4988 32.5571 23.8242 33.1556 24.2757C33.7541 24.7167 34.2266 25.2943 34.5731 26.0082C34.9301 26.7222 35.1086 27.5885 35.1086 28.607C35.1086 29.51 34.9353 30.308 34.5888 31.001C34.2528 31.6835 33.7961 32.261 33.2186 32.7335C32.6411 33.1955 31.9743 33.5472 31.2183 33.7887C30.4623 34.0303 29.6696 34.151 28.8401 34.151H24.8238V23ZM27.2808 31.883H28.5566C29.1236 31.883 29.6486 31.8253 30.1316 31.7098C30.6251 31.5942 31.0503 31.4053 31.4073 31.1427C31.7643 30.8698 32.0426 30.518 32.2421 30.0875C32.4521 29.6465 32.5571 29.111 32.5571 28.481C32.5571 27.935 32.4521 27.4625 32.2421 27.0635C32.0426 26.654 31.7696 26.318 31.4231 26.0555C31.0766 25.793 30.6671 25.5987 30.1946 25.4727C29.7326 25.3363 29.2443 25.268 28.7298 25.268H27.2808V31.883Z"
        fill="currentColor"
      />
      <path
        d="M38.4758 23H35.6723L40.1295 34.151H42.0983L46.6973 23H44.067L41.2635 30.308H41.232L38.4758 23Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 28.898C0 12.961 12.968 0 28.898 0L28.9017 0.00039959C44.8317 0.00039959 57.7927 12.9574 57.7927 28.8984C57.7927 34.1288 56.3943 39.0389 53.9529 43.2694L73.9529 56.9494C75.8553 58.6526 76.9334 61.0002 77.0037 63.551C77.074 66.1018 76.1131 68.5002 74.3123 70.301C72.5623 72.051 70.2615 73.0002 67.8123 73.0002H67.5506C64.9998 72.9299 62.6599 71.8478 60.949 69.9494L43.277 53.9374C39.039 56.3906 34.1285 57.789 28.898 57.789C12.969 57.789 0 44.839 0 28.898ZM29 51C40.0457 51 51 40.0457 51 29C51 17.9543 40.0457 7 29 7C17.9543 7 7 17.9543 7 29C7 40.0457 17.9543 51 29 51Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default AdvancedSearchIcon;
