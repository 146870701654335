import { t, translate } from "components/CentralDataMangement/translation/Translation";
import Button from "components/common/atoms/Button";
import CheckBoxField from "components/common/atoms/CheckBoxField";
import { ApiCall } from "components/common/services/ApiServices";
import { useEffect, useState } from "react";
import { CONF_SETTINGS_EDIT, CONF_SETTINGS_UPDATE } from "routes/ApiEndpoints";
import CustomNotify from "components/common/atoms/CustomNotify";
import { useParams, useLocation } from "react-router-dom";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import LabelField from "components/common/atoms/LabelField";

interface PcEmployeeType {
  employeeCategory: number;
}

interface EmployeeType {
  id: number;
  employeetype_name: string;
  status: boolean;
  employeetype_category_id: number;
  contract: null | string;
  bbright_id: number | null;
  check: number[];
}

const PcEmployeeTypes: React.FC<PcEmployeeType> = ({
  employeeCategory,
}) => {
  const [empCategories, setEmpCategories] = useState<EmployeeType[]>([]);
  const [formData, setFormData] = useState<{ empCheck: number[] }>({
    empCheck: [],
  });
  const [loading, setLoading] = useState(false);
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    fetchEmployeeCategory();
  }, []);

  const fetchEmployeeCategory = async () => {
    const data = {
      category: employeeCategory,
      pcId: id,
      type: "pcEmployeeType",
    };
    const url = CONF_SETTINGS_EDIT;

    const response = await ApiCall.service(
      url,
      "POST",
      data,
      true,
      "central-data-management"
    );

    if (response.status === 200) {
      setEmpCategories(response.data.empCategory);
      setFormData({ empCheck: response.data.check });
    }
  };

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setFormData((prevData) => {
      if (prevData.empCheck.includes(parseInt(value))) {
        return {
          empCheck: prevData.empCheck.filter((id) => id !== parseInt(value)),
        };
      } else {
        return { empCheck: [...prevData.empCheck, parseInt(value)] };
      }
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    const data = {
      empCheck: formData.empCheck,
      pcId: id,
      type: "pcEmployeeType",
    };
    e.preventDefault();
    setLoading(true);

    let response = await ApiCall.service(
      CONF_SETTINGS_UPDATE,
      "POST",
      data,
      false,
      "central-data-management"
    );
    if (response.status === 200) {
      CustomNotify({ type: "success", message: t(response.msg) });
    } else if (response.status === 400) {
      let errors: any = [];
      for (const key in response.errors) {
        errors[key] = response.errors[key];
      }
    }
  };
  const location = useLocation(); // Add this line to get the location object
  const path = location.pathname;
  const edit = path.includes("edit") || false;

  return (
    <>
      <AccessControl
        requiredPermissions={[
          {
            permission: "Paritair committe",
            read: true,
          },
        ]}
        renderNoAccess={true}
      >
        <div className="col-md-12 col-lg-5 col-xl-4 col-xxl-4 mt-5 my-lg-4">
          <div className="form-border padding1">
            <div>
              <LabelField className={`tab-subtitle`} title = {edit ? t("Edit employee type") : t("View employee type")} />
            </div>
            <div className="row">
              <div className="col-md-12 PcEditEmployeeType">
                {empCategories && empCategories.length > 0 ? (
                  empCategories.map((employeeType) => (
                    <div className="col-12 marginBottomPoint5">
                      <CheckBoxField
                      disable={!edit}
                      key={employeeType.id}
                      name={employeeType.employeetype_name}
                      label={employeeType.employeetype_name}
                      value={employeeType.id.toString()}
                      onChangeHandler={handleFieldChange}
                      isChecked={formData.empCheck.includes(employeeType.id)}
                      className={
                        edit
                          ? "w-100"
                          : `${!edit ? "disabled opacity-50 w-100" : ""}`
                      }
                    />
                    </div>
                  ))
                ) : (
                  <div className="null"></div>
                )}
              </div>
              {edit && (
                <div className="col-md-12">
                  <Button
                    title={t("Save")}
                    type="submit"
                    className={
                      "float-end form-button shadow-none"
                    }
                    handleClick={handleSubmit}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </AccessControl>
    </>
  );
};

export default translate(PcEmployeeTypes);