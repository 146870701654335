import React, { useState, useEffect } from "react";
import LabelField from "components/common/atoms/LabelField";
import CheckBoxField from "components/common/atoms/CheckBoxField";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import LabelWithTextAreaField from "components/common/molecules/LabelWithTextAreaField";
import { ApiCall } from "components/common/services/ApiServices";
import { Link, useParams, useNavigate } from "react-router-dom";
import Button from "components/common/atoms/Button";
import CustomNotify from "components/common/atoms/CustomNotify";
import * as ENDPOINTS from "routes/ApiEndpoints";
import {
  validateForm,
  validateRequired,
  validateCheckboxes,
  validateUniqueKey,
} from "components/common/services/ValidationService";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import Title from "components/common/atoms/Title";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";

interface RouteParams {
  [key: string]: string | undefined;
}

interface Field {
  id?: string;
  classification: string;
  checkboxInternal: boolean;
  checkboxExternal: boolean;
  permission: string;
  unique_key: string;
  description: string;
}

interface ValidationRules {
  [key: string]: Function[];
}

interface ClassificationsType {
  id?: number;
  classification_name?: string;
}

const CreatePermission: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<RouteParams>();
  const [errors, setErrors] = useState<Partial<Field>>({});
  const [options, SetOptions] = useState<ClassificationsType[]>();
  const [formData, setFormData] = useState<Field>({
    classification: "",
    checkboxInternal: false,
    checkboxExternal: false,
    permission: "",
    unique_key: "",
    description: "",
  });

  useEffect(() => {
    const fetchFormData = async () => {
      const url = id
        ? `${ENDPOINTS.GET_PERMISSION}/${id}`
        : ENDPOINTS.GET_PERMISSION;
      const response = await ApiCall.getService(url, "GET");
      if (response.status === 200) {
        SetOptions(response.data[0]);
        if (response.data[1].length > 0) {
          const {
            id,
            name,
            description,
            per_classification_id,
            unique_key,
            permission_type,
          } = response.data[1][0];
          let checkboxInternal = false;
          let checkboxExternal = false;
          if (permission_type === 2) {
            checkboxInternal = true;
            checkboxExternal = true;
          } else if (permission_type === 1) {
            checkboxExternal = true;
          } else if (permission_type === 0) {
            checkboxInternal = true;
          }
          setFormData({
            id,
            permission: name,
            description,
            unique_key,
            classification: per_classification_id.toString(),
            checkboxInternal,
            checkboxExternal,
          });
        }
      }
    };
    fetchFormData();
  }, [SetOptions, setFormData, id]);

  //**************validations********** */

  const validation = (
    name: string,
    value: string | boolean,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      classification: [validateRequired],
      checkboxInternal: [
        (value: boolean) =>
          validateCheckboxes(value, formData.checkboxExternal),
      ],
      checkboxExternal: [
        (value: boolean) =>
          validateCheckboxes(formData.checkboxInternal, value),
      ],
      permission: [validateRequired],
      unique_key: [validateRequired, validateUniqueKey],
      description: [validateRequired],
    };

    const validationErrors = validateForm(
      { ...formData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation && Object.keys(errors).length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }

    return true;
  };

  //************onchange functionality ******/

  const changeHandler = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value, type } = event.target;
    let updatedValue: string | boolean;

    if (type === "checkbox") {
      updatedValue = (event.target as HTMLInputElement).checked;
    } else {
      updatedValue = value;
    }

    setFormData((formData) => ({ ...formData, [name]: updatedValue }));
    validation(name, updatedValue, true);
  };

  const internalError = errors.checkboxInternal;
  const externalError = errors.checkboxExternal;
  //***********onsubmit functionality**************** */

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { name, value } = event.target as HTMLInputElement;

    let permissionType;

    if (formData.checkboxInternal && formData.checkboxExternal) {
      permissionType = 2;
    } else if (formData.checkboxInternal) {
      permissionType = 0;
    } else if (formData.checkboxExternal) {
      permissionType = 1;
    }

    const data = {
      //storing data in data variable to submit data for both update and create
      id: id,
      name: formData.permission,
      description: formData.description,
      unique_key: formData.unique_key,
      per_classification_id: formData.classification,
      permission_type: permissionType,
    };

    // Run full validation on form submission
    if (validation(name, value)) {
      const endPoint = id
        ? ENDPOINTS.UPDATE_PERMISSION
        : ENDPOINTS.STORE_PERMISSION;
      const response = await ApiCall.service(endPoint, "POST", data);
      if (response.status === 200) {
        CustomNotify({ type: "success", message: t(response.msg) });
        navigate("/manage-permissions");
      } else if (response.status === 400) {
        setErrors({
          permission: response.errors.name,
          unique_key: response.errors.unique_key,
        });
      }
    }
  };

  //***********end onsubmit */

  const permissionText: string = id ? "Edit permission" : "Create permission";
  const buttonText: string = id ? "Save permission" : "Create permission";

  const permissionType = id ? "update" : "create";
  const permissionObject: any = {
    permission: "Roles",
  };
  permissionObject[permissionType] = true;
  return (
    <AccessControl
      requiredPermissions={[permissionObject]}
      renderNoAccess={true}
    >
      <Title title={t(permissionText)} />
      <form onSubmit={handleSubmit}>
        <div className="form-border">
          <div className="row">
            <div className="form-group col-md-12">
              <LabelField
                title={t("Classification")}
                className="field-label mb-2"
                isMandatory={true}
              />
              <select
                className="form-select rounded-3 field-shadow select-field"
                name="classification"
                id="classification"
                onChange={changeHandler}
                value={formData.classification}
              >
                <option value="">{t("Select")}</option>
                {options?.map((optn) => (
                  <option key={optn.id} value={optn.id}>
                    {optn.classification_name}
                  </option>
                ))}
              </select>
              <div className="height-20" style={{ marginBottom: "0.5vw" }}>
                {errors.classification && (
                  <span className="text-danger mt-2">
                    {errors.classification}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <LabelWithInputField
                label={t("Name")}
                id="name"
                isMandatory={true}
                placeholder={t("Name of the permission")}
                type="text"
                value={formData.permission}
                name="permission"
                handleChange={changeHandler}
                error={errors.permission}
              />
            </div>
            <div className="col-md-6">
              <LabelWithInputField
                label={t("Unique key")}
                id="unique_key"
                isMandatory={true}
                placeholder={t("Unique key")}
                type="text"
                value={formData.unique_key}
                name="unique_key"
                handleChange={changeHandler}
                error={errors.unique_key}
              />
            </div>
          </div>
          <div className="col-md-12">
            <LabelWithTextAreaField
              label={t("Description")}
              isMandatory={true}
              name="description"
              placeholder={t("Enter description")}
              isDisabled={false}
              value={formData.description}
              handleChange={changeHandler}
              rows={4}
              error={errors.description}
            />
          </div>
          <div className="row">
            <div className="form-group col-md-6">
              <LabelField
                title={t("Permission type")}
                className="field-label mb-2"
                isMandatory={true}
              />
              <div>
                <div className="d-inline-block me-5">
                  <CheckBoxField
                    name="checkboxInternal"
                    label={t("Internal")}
                    className="my-checkbox"
                    isChecked={formData.checkboxInternal}
                    onChangeHandler={changeHandler}
                    id="checkboxInternal"
                    lineHeight="25px"
                  />
                </div>
                <div className="d-inline-block">
                  <CheckBoxField
                    name="checkboxExternal"
                    label={t("External")}
                    className="my-checkbox"
                    isChecked={formData.checkboxExternal}
                    onChangeHandler={changeHandler}
                    id="checkboxExternal"
                    lineHeight="25px"
                  />
                </div>
              </div>
              <div className="height-20" style={{ marginBottom: "0.5vw" }}>
                {internalError && externalError ? (
                  <span className="text-danger mt-2">{internalError}</span>
                ) : (
                  <>
                    {formData.checkboxInternal === true ? (
                      <span className="text-danger mt-2">{internalError}</span>
                    ) : (
                      <span className="text-danger mt-2">{externalError}</span>
                    )}
                    {formData.checkboxExternal === true ? (
                      <span className="text-danger mt-2">{externalError}</span>
                    ) : (
                      <span className="text-danger mt-2">{internalError}</span>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row py-4">
          <div className="col-md-4 align-self-center">
            <Link
              to="/manage-permissions"
              className="back-btn text-decoration-underline"
            >
              {t("Back")}
            </Link>
          </div>
          <div className="col-md-8">
            <Button
              title={t(buttonText)}
              type="submit"
              className="form-button float-end px-3 shadow-none"
            />
          </div>
        </div>
      </form>
    </AccessControl>
  );
};

export default translate(CreatePermission);
