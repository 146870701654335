import React, { useState } from "react";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import { t } from "components/CentralDataMangement/translation/Translation";
import { useFormContext } from "../../context/Context";
import ModalPopup from "components/common/atoms/ModalPopup";
import { DeviatingCoefficientsProps } from "../../annotation/PlanningInterface";
import * as CONST from "../../annotation/PlanningConstants";
import Button from "components/common/atoms/Button";
import EditIcon from "static/images/EditIcon";
import DeleteIcon from "static/images/DeleteIcon";
import { ValidationRules } from "components/common/utlis/TypeAnnotations";
import {
  validateForm,
  validateRequired,
  validateSelectField,
} from "components/common/services/ValidationService";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import LabelField from "components/common/atoms/LabelField";

const DeviatingCoefficients: React.FC = () => {
  const { state, dispatch } = useFormContext();

  const [formData, setFormData] = useState<DeviatingCoefficientsProps>({
    id: null,
    codeOption: null,
    code: "",
    description: "",
    coefficient: "",
  });

  const intialErrors: { [key: string]: string } = {};
  const [errors, setErrors] = useState<{
    [key: string]: string;
  }>(intialErrors);

  const validation = (
    name: string,
    value: string | boolean | Date | object[] | undefined,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      codeOption: [validateSelectField],
      coefficient: [validateRequired],
    };
    const validationErrors = validateForm(
      { ...formData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const removeFieldSet = (indexToRemove: number) => {
    dispatch({ type: CONST.REMOVE_DEVIATING_COEFF, indexToRemove });
  };

  const [modal, setModal] = useState(false);
  const [index, setIndex] = useState(0);

  const addData = (index: number) => {
    setIndex(index); // Set the index for dispatch
    setModal(true);
  };

  const editData = (index: number) => {
    setIndex(index); // Set the index for dispatch
    setModal(true);
    const deviatingCoeffToEdit =
      state.billingDetails.deviatingCoefficients[index]; // Get the certificate to edit
    if (deviatingCoeffToEdit) {
      // Populate the  data in the modal fields
      setFormData({
        id: deviatingCoeffToEdit.id,
        codeOption: deviatingCoeffToEdit.codeOption,
        code: deviatingCoeffToEdit.code,
        description: deviatingCoeffToEdit.description,
        coefficient: deviatingCoeffToEdit.coefficient,
      });
    }
  };

  const resetFields = () => {
    setFormData({
      id: null,
      codeOption: null,
      code: "",
      description: "",
      coefficient: "",
    });
  };

  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: selectedOption,
    }));
    if (selectedOption && fieldName === "codeOption") {
      const [code, description] = selectedOption?.label.split(" - ") || [];
      setFormData((prevData) => ({
        ...prevData,
        code: code,
        description: description,
      }));
    }
    validation(fieldName, selectedOption, true);
  };

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target as HTMLInputElement;
    let updatedValue: string | number | null = value;
    if (name === "coefficient") {
      // Filter input to allow only numbers and commas
      updatedValue = value.replace(/[^0-9,]/g, "");
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: updatedValue,
    }));
    validation(name, updatedValue, true);
  };

  const onOkButtonClick = async (e: React.FormEvent) => {
    e.preventDefault();
    const { name, value } = e.target as HTMLInputElement;
    if (validation(name, value)) {
      dispatch({
        type: CONST.ADD_DEVIATING_COEFF,
        data: formData,
        index: index,
      });
      setModal(false); // Close modal
      resetFields();
      setErrors({});
    }
  };

  const closeModal = () => {
    setModal(false);
    resetFields();
    setErrors({});
  };

  return (
    <>
      <div>
        <ModalPopup
          size="xl"
          show={modal}
          onHide={closeModal}
          title={t("Contract Coeff")}
          body={
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-6">
                <SelectWithSearch
                  title={t("Code")}
                  isMandatory={true}
                  search={true}
                  options={state.dropDowndata.devCoefficients}
                  id={`codeOption-${index}`}
                  onChange={(e) => handleSelectChange(e, "codeOption")}
                  isMulti={false}
                  name="codeOption"
                  value={formData.codeOption}
                  isTranslate={true}
                  error={errors.codeOption}
                />
              </div>

              <div className="col-sm-12 col-md-6">
                <LabelWithInputField
                  isMandatory={true}
                  name="coefficient"
                  handleChange={handleFieldChange}
                  value={formData.coefficient}
                  id={`coefficient-${index}`}
                  label={t("Coefficient")}
                  error={errors.coefficient}
                />
              </div>

              <div className="col-12 d-flex justify-content-end">
                <Button
                  handleClick={onOkButtonClick}
                  className="form-button shadow-none px-4"
                >
                  {t("Add")}
                </Button>
              </div>
            </div>
          }
          className="modal-lg contractPerfCodeModal"
        />
      </div>
      <div>
        <LabelField title={t("Deviating coefficients")} className="tab-title" />
        <div className="pwa table-responsive deviatingCoefficientTable scrollBar">
          <table className="table table-hover mb-0">
            <thead>
              <tr className="border-0 TableHeader bg-white">
                <th className="border-0 align-middle" style={{ width: "25%" }}>
                  {t("Code")}
                </th>
                <th className="border-0 align-middle" style={{ width: "25%" }}>
                  {t("Description")}
                </th>
                <th className="border-0 align-middle" style={{ width: "25%" }}>
                  {t("Coefficient")}
                </th>
                <th className="border-0 align-middle" style={{ width: "25%" }}>
                  {t("Action")}
                </th>
              </tr>
            </thead>
            <tbody>
              {state.billingDetails.deviatingCoefficients.map(
                (devCoeff, index) => (
                  <tr key={index}>
                    <td
                      className="align-middle text-break"
                      data-label={t("Code")}
                    >
                      {devCoeff.code}
                    </td>
                    <td
                      className="align-middle text-break"
                      data-label={t("Description")}
                    >
                      {devCoeff.description}
                    </td>
                    <td
                      className="align-middle text-break"
                      data-label={t("Coefficient")}
                    >
                      {devCoeff.coefficient}
                    </td>
                    <td className="align-middle text-break">
                      <span
                        title={t("Edit")}
                        onClick={() => editData(index)}
                        className="table-action-icons cursor-pointer rounded-0 shadow-none me-2"
                      >
                        <EditIcon />
                      </span>
                      <span
                        title={t("Delete")}
                        onClick={() => removeFieldSet(index)}
                        className="table-action-icons cursor-pointer rounded-0 shadow-none"
                      >
                        <DeleteIcon />
                      </span>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
        {/* Certificates section modal popup */}
        <div className="row py-3">
          <div className="col-12">
            <Button
              title={"+ " + t("Add")}
              handleClick={() =>
                addData(state.billingDetails.deviatingCoefficients.length)
              }
              className="form-button float-end shadow-none px-4"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DeviatingCoefficients;
