import { t } from "components/CentralDataMangement/translation/Translation";
import { OMTargetAchievedData } from "../Annotations";

interface RecruiterTargetProps {
    data?: OMTargetAchievedData;
}

const OMTargetTable: React.FC<RecruiterTargetProps> = ({ data }) => {


    return (
        <>
            <table className="table table-hover table-bordered targetTable">
                <thead>
                    <tr className="TableHeader">
                        <th></th>
                        <th className="text-center">{t("Target")}</th>
                        <th className="text-center">{t("Achieved")}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{t("Visits")}</td>
                        <td className="text-center">{data?.visits?.target}</td>
                        <td className="text-center">{data?.visits?.achieved}</td>
                    </tr>
                    <tr>
                        <td>{t("Vacancies")}</td>
                        <td className="text-center">{data?.vacancies?.target}</td>
                        <td className="text-center">{data?.vacancies?.achieved}</td>
                    </tr>
                    <tr>
                        <td>{t("Prospect calls")}</td>
                        <td className="text-center">{data?.prospect_calls?.target}</td>
                        <td className="text-center">{data?.prospect_calls?.achieved}</td>
                    </tr>
                </tbody>
            </table>
        </>
    );
}

export default OMTargetTable;