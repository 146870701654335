import React from "react";

const ADVcloseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="74"
      height="80"
      viewBox="0 0 74 80"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.8512 32H16.8195L12 43.151H14.709L15.6383 40.7885H19.9695L20.9303 43.151H23.7023L18.8512 32ZM16.41 38.7095L17.7645 35.2445L19.1348 38.7095H16.41Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.5093 32H24.8238V43.151H28.8401C29.6696 43.151 30.4623 43.0303 31.2183 42.7887C31.9743 42.5472 32.6411 42.1955 33.2186 41.7335C33.7961 41.261 34.2528 40.6835 34.5888 40.001C34.9353 39.308 35.1086 38.51 35.1086 37.607C35.1086 36.5885 34.9301 35.7222 34.5731 35.0082C34.2266 34.2942 33.7541 33.7167 33.1556 33.2757C32.5571 32.8242 31.8536 32.4987 31.0451 32.2992C30.2471 32.0997 29.4018 32 28.5093 32ZM28.5566 40.883H27.2808V34.268H28.7298C29.2443 34.268 29.7326 34.3362 30.1946 34.4727C30.6671 34.5987 31.0766 34.793 31.4231 35.0555C31.7696 35.318 32.0426 35.654 32.2421 36.0635C32.4521 36.4625 32.5571 36.935 32.5571 37.481C32.5571 38.111 32.4521 38.6465 32.2421 39.0875C32.0426 39.518 31.7643 39.8698 31.4073 40.1427C31.0503 40.4053 30.6251 40.5942 30.1316 40.7098C29.6486 40.8253 29.1236 40.883 28.5566 40.883Z"
        fill="currentColor"
      />
      <path
        d="M38.4758 32H35.6723L40.1295 43.151H42.0983L46.6973 32H44.067L41.2635 39.308H41.232L38.4758 32Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.9141 30.8103C48.4753 29.5604 42 22.2864 42 13.5C42 13.0522 42.0168 12.6083 42.0499 12.1689C38.1037 10.1437 33.6337 9.0004 28.9017 9.0004L28.898 9C12.968 9 0 21.961 0 37.898C0 53.839 12.969 66.789 28.898 66.789C34.1285 66.789 39.039 65.3906 43.277 62.9374L57.949 76.9494C59.6599 78.8478 61.9998 79.9299 64.5506 80.0002H64.8123C67.2615 80.0002 69.5623 79.051 71.3123 77.301C73.1131 75.5002 74.074 73.1018 74.0037 70.551C73.9334 68.0002 72.8553 65.6526 70.9529 63.9494L53.9529 52.2694C56.3943 48.0389 57.7927 43.1288 57.7927 37.8984C57.7927 35.4535 57.4878 33.0788 56.9141 30.8103ZM51 38C51 49.0457 40.0457 60 29 60C17.9543 60 7 49.0457 7 38C7 26.9543 17.9543 16 29 16C40.0457 16 51 26.9543 51 38Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.5 27C66.9558 27 73 20.9558 73 13.5C73 6.04416 66.9558 0 59.5 0C52.0442 0 46 6.04416 46 13.5C46 20.9558 52.0442 27 59.5 27ZM59.5 24C65.299 24 70 19.299 70 13.5C70 7.70101 65.299 3 59.5 3C53.701 3 49 7.70101 49 13.5C49 19.299 53.701 24 59.5 24Z"
        fill="currentColor"
      />
      <path
        d="M54.5965 7.26066C54.4214 7.09151 54.1868 6.99792 53.9433 7.00004C53.6999 7.00215 53.467 7.09981 53.2948 7.27197C53.1226 7.44414 53.025 7.67703 53.0229 7.9205C53.0207 8.16397 53.1143 8.39853 53.2835 8.57366L58.1984 13.4886L53.2835 18.4035C53.1948 18.4892 53.1241 18.5916 53.0754 18.7049C53.0267 18.8182 53.0011 18.9401 53 19.0634C52.999 19.1867 53.0225 19.3089 53.0691 19.423C53.1158 19.5372 53.1848 19.6408 53.272 19.728C53.3592 19.8152 53.4628 19.8842 53.577 19.9309C53.6911 19.9775 53.8133 20.001 53.9366 20C54.0599 19.9989 54.1818 19.9733 54.2951 19.9246C54.4084 19.8759 54.5108 19.8052 54.5965 19.7165L59.5114 14.8016L64.4263 19.7165C64.6015 19.8857 64.836 19.9793 65.0795 19.9771C65.323 19.975 65.5559 19.8774 65.728 19.7052C65.9002 19.533 65.9978 19.3001 66 19.0567C66.0021 18.8132 65.9085 18.5786 65.7393 18.4035L60.8244 13.4886L65.7393 8.57366C65.9085 8.39853 66.0021 8.16397 66 7.9205C65.9978 7.67703 65.9002 7.44414 65.728 7.27197C65.5559 7.09981 65.323 7.00215 65.0795 7.00004C64.836 6.99792 64.6015 7.09151 64.4263 7.26066L59.5114 12.1756L54.5965 7.26066Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ADVcloseIcon;
