import { t, translate } from "components/CentralDataMangement/translation/Translation";
import React, { useRef, useCallback, useState } from "react";
import SignaturePad from "react-signature-canvas";
import Button from "./Button";
import ImageField from "./ImageField";
import '../../../static/css/signature.css';
interface SignatureFieldProps {
  onChange?: (dataUrl: string) => void;
  onSave?: (dataUrl: string) => void;
  className?: string;
  height?: string;
  width?: string;
  clearBtnClass?: string;
  saveBtnClass?: string;
  disabled?: boolean;
  dataUrl?: string;
}

const SignatureField: React.FC<SignatureFieldProps> = ({
  onChange = () => { },
  onSave = () => { },
  className,
  height = "300px",
  width = "450px",
  clearBtnClass = "",
  saveBtnClass = "",
  disabled = false,
  dataUrl = "",
}) => {
  const signature = useRef<any>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  // const saveClearBtnWidth = { width: parseInt(width) / 2, margin: "5px 0" };

  const clear = useCallback(() => {
    if (signature.current) {
      signature.current.clear();
      const signatureDataUrl = signature.current.toDataURL();
      onChange(signatureDataUrl);
      setErrorMessage(null); // Clear error message on clear button click
    }
  }, [onChange]);

  const handleSave = useCallback(() => {
    if (signature.current) {
      const empty = signature.current.isEmpty();
      if (!empty) {
        const signatureDataUrl = signature.current.toDataURL();
        onSave(signatureDataUrl);
        setErrorMessage(null); // Clear error message on successful save
      } else {
        setErrorMessage(t("Please add signature before saving")+"!!")
      }
    }
  }, [onSave]);

  const onEnd = useCallback(() => {
    if (signature.current) {
      const empty = signature.current.isEmpty();
      if (!empty) {
        const signatureDataUrl = signature.current.toDataURL();
        onChange(signatureDataUrl);
      }
    }
  }, [onChange]);

  return (
    <div>
      <div className="border rounded-4 mb-3">
        {disabled === false ? (
          <SignaturePad
            ref={signature}
            canvasProps={{ className }}
            onEnd={onEnd}
          />
        ) : (
          <div className="text-center">
            <ImageField
              style={{ opacity: 0.7 }}
              src={dataUrl}
              altText="signature"
            />
          </div>
        )}
      </div>
      <div className="height-20" style={{ marginBottom: "0.5vw" }}>
        {{ errorMessage } && <div className="text-danger">{errorMessage}</div>}
      </div>
      <div className="row">
        <div className="col-6">
          <Button
            disabled={disabled}
            className={`btn delete-btn px-3 ${clearBtnClass}`}
            handleClick={clear}
          >
            {t("Clear")}
          </Button>
        </div>

        <div className="col-6">
          <Button
            disabled={disabled}
            className={`btn form-button float-end px-3 ${saveBtnClass}`}
            handleClick={handleSave}
          >
            {t("Save")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default translate(SignatureField);
