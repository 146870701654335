import { t } from "components/CentralDataMangement/translation/Translation";
import { StarterDetails } from "../Annotations";
import TableTitle from "components/common/atoms/TableTitle";

interface StartersTableProps {
  view?: "recruiter" | "om" | "tl";
  data?: StarterDetails[];
  tableTitle?: string;
}
const StartersTable: React.FC<StartersTableProps> = ({
  view = "recruiter",
  data,
  tableTitle,
}) => {
  return (
    <>
      {tableTitle && <TableTitle title={tableTitle} />}
      <table className="table table-hover table-bordered targetTable">
        <thead>
          <tr className="TableHeader">
            <th>{t("Date")}</th>
            <th>{t("Company")}</th>
            {view === "om" ? (
              <>
                <th>{t("Candidate")}</th>
                <th>{t("Function")}</th>
                <th>{t("Selection / payroll / reduced")}</th>
                <th>{t("Starter / Start-up 1st week")}</th>
              </>
            ) : view === "tl" ? (
              <>
                <th>{t("Candidate")}</th>
                <th>{t("Function")}</th>
              </>
            ) : (
              <>
                <th>{t("Name UZK")}</th>
                <th>{t("Full week")}</th>
                <th>{t("Stop date")}</th>
                <th>{t("Inflow")}</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {data && data?.length > 0 ? (
            data.map((starter: StarterDetails, index) => (
              <tr key={index}>
                <td className="align-middle">{starter.date}</td>
                <td className="align-middle">{starter.company}</td>
                <td className="align-middle">{starter.candidate}</td>
                {view === "om" ? (
                  <>
                    <td className="align-middle">{starter?.function}</td>
                    <td className="align-middle">{starter?.type}</td>
                    <td className="align-middle">{starter?.start_week}</td>
                  </>
                ) : view === "tl" ? (
                  <>
                    <td className="align-middle">{starter?.function}</td>
                  </>
                ) : (
                  <>
                    <td className="align-middle">{starter?.full_week}</td>
                    <td className="align-middle">{starter?.stop_date}</td>
                    <td className="align-middle">{starter?.inflow}</td>
                  </>
                )}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={6}>
                <div className="text-danger text-center">
                  {t("No data found")}
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

export default StartersTable;
