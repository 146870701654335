import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import { LanguageData } from "./ManageTranslation";
import { t, translate } from "./Translation";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";

interface ExportCSVProps {
  languageData: LanguageData; // Pass your languageData as a prop
  language: string;
}

const ExportCSV: React.FC<ExportCSVProps> = ({ languageData, language }) => {
  const jsonToCsv = (val: string): string => {
    const csvValue = `"${val}"`; // Enclose value in double quotes
    return csvValue;
  };

  const handleCSVExport = () => {
    const languages = Object.keys(languageData);
    const headers = ["#", "Developer string", language]; // Original languages array
    const normalizedHeaders = headers.map((header, index) => {
      const normalizedHeader = jsonToCsv(header); // Remove commas from header
      return normalizedHeader;
    });

    const rows: any[] = [normalizedHeaders];
    const uniqueTexts = Array.from(
      new Set(
        Object.values(languageData[language]).map((item) =>
          jsonToCsv(item.text)
        )
      )
    );

    uniqueTexts.forEach((text, rowIndex) => {
      const row: any[] = [];
      const translationId = Object.keys(languageData[language]).find(
        (id) => jsonToCsv(languageData[language][id].text) === text
      );

      if (translationId) {
        const cellData = languageData[language][translationId].translation;
        if (cellData === "") {
          row.push(rowIndex + 1); // Index
          row.push(text); // Developer string
          rows.push(row);
        }
      } else {
        row.push(""); // Leave other cells empty
      }
    });

    const csvContent = rows
      .map((row, rowIndex) => {
        if (rowIndex === 0) {
          return row.join(",");
        }
        return `${row[0]},${row[1]},${row
          .slice(2)
          .map((cell: string) => `"${cell}"`)
          .join(",")}`;
      })
      .join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "translations.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <AccessControl
        requiredPermissions={[
          {
            permission: "Translation",
            read: true,
          },
        ]}
        renderNoAccess={true}
      >
        <div className="col-lg-9 align-self-center table-action-icons">
          <div className="float-end w-auto">
            <button
              onClick={handleCSVExport}
              className="border-0 bg-transparent px-0 download-csv d-flex align-items-center color-dark-purple"
              title={t("Export CSV")}
            >
              <span>
                {t("Export")}
                {":"}
              </span>
              <FontAwesomeIcon icon={faFileExport} className="fa-lg ms-2" style={{width:"1vw", height:"1vw"}} />
            </button>
          </div>
        </div>
      </AccessControl>
    </>
  );
};

export default translate(ExportCSV);
