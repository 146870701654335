
export const exampleWeeklyReport = {
    calls: {
        mon: 1,
        tue: 2,
        wed: 3,
        thu: 6,
        fri: 8,
        sat: 4,
        sun: 9,
        total: 33
    },
    screening: {
        mon: 0,
        tue: 1,
        wed: 2,
        thu: 1,
        fri: 3,
        sat: 0,
        sun: 1,
        total: 8
    },
    presentation: {
        mon: 1,
        tue: 0,
        wed: 2,
        thu: 3,
        fri: 1,
        sat: 0,
        sun: 1,
        total: 8
    },
    interview: {
        mon: 2,
        tue: 3,
        wed: 1,
        thu: 4,
        fri: 2,
        sat: 0,
        sun: 0,
        total: 12
    },
    starter: {
        mon: 1,
        tue: 0,
        wed: 0,
        thu: 2,
        fri: 1,
        sat: 0,
        sun: 0,
        total: 4
    },
    stopper: {
        mon: 0,
        tue: 1,
        wed: 0,
        thu: 1,
        fri: 0,
        sat: 1,
        sun: 0,
        total: 3
    },
    visits: {
        mon: 0,
        tue: 1,
        wed: 2,
        thu: 3,
        fri: 4,
        sat: 2,
        sun: 5,
        total: 17
    },
    vacancies: {
        mon: 2,
        tue: 3,
        wed: 4,
        thu: 5,
        fri: 6,
        sat: 1,
        sun: 0,
        total: 21
    },
    prospect_calls: {
        mon: 5,
        tue: 4,
        wed: 3,
        thu: 2,
        fri: 1,
        sat: 3,
        sun: 2,
        total: 20
    }
};

export const dummyCandidates = [
    {
        candidate: "John Doe",
        profile: "Software Developer",
        registration: false,
        screening: true,
        presentation: 5,
        interview: 7,
        starter: false
    },
    {
        candidate: "Jane Smith",
        profile: "Data Analyst",
        registration: true,
        screening: false,
        presentation: 0,
        interview: 0,
        starter: false
    },
    {
        candidate: "Michael Johnson",
        profile: "Project Manager",
        registration: true,
        screening: true,
        presentation: 6,
        interview: 7,
        starter: true
    },
    {
        candidate: "Emily Davis",
        profile: "UI/UX Designer",
        registration: true,
        screening: false,
        presentation: 0,
        interview: 0,
        starter: false
    },
    {
        candidate: "David Brown",
        profile: "DevOps Engineer",
        registration: true,
        screening: true,
        presentation: 4,
        interview: 5,
        starter: false
    }
];

export const dummyTargetData = {
    officeTarget: {
        target: 50000,    // Total budget allocated for the office target
        reached: 40000,   // Amount of the target that has been reached
        balance: 10000    // Remaining balance to reach the target
    },
    personalTarget: {
        target: 30000,    // Total budget allocated for the personal target
        reached: 25000,   // Amount of the target that has been reached
        balance: 5000,    // Remaining balance to reach the target
        starter: 50,      // Number of starters associated with the target
        stopper: 10       // Number of stoppers associated with the target
    },
    recruiterTarget: {
        calls: { target: 100, achieved: 90 },
        screening: { target: 80, achieved: 75 },
        presentation: { target: 50, achieved: 45 },
        interview: { target: 30, achieved: 28 },
        starters: { target: 10, achieved: 8 },
        stopper: { target: 5, achieved: 3 },
    }
};

export const dummyOMTargetData = {
    officeTarget: {
        target: 50000,    // Total budget allocated for the office target
        reached: 40000,   // Amount of the target that has been reached
        balance: 10000    // Remaining balance to reach the target
    },
    personalTarget: {
        target: 30000,    // Total budget allocated for the personal target
        reached: 25000,   // Amount of the target that has been reached
        balance: 5000,    // Remaining balance to reach the target
        starter: 50,      // Number of starters associated with the target
        stopper: 10       // Number of stoppers associated with the target
    },
    omTarget: {
        visits: { target: 100, achieved: 90 },
        vacancies: { target: 80, achieved: 75 },
        prospect_calls: { target: 50, achieved: 45 }
    }
};

export const dummyRecruiterTargetData = {
    calls: { target: 100, achieved: 90 },
    screening: { target: 80, achieved: 75 },
    presentation: { target: 50, achieved: 45 },
    interview: { target: 30, achieved: 28 },
    starters: { target: 10, achieved: 8 },
    stopper: { target: 5, achieved: 3 },
};

export const candidates = [
    {
        date: "2024-08-21",
        company: "Tech Solutions Inc.",
        candidate: "Alice Johnson",
        full_week: true,
        stop_date: null,
        inflow: "Indeed",
    },
    {
        date: "2024-08-22",
        company: "Innovative Tech",
        candidate: "Bob Smith",
        // full_week is omitted
        stop_date: "2024-09-01",
        inflow: "VDAB",
    },
    {
        date: "2024-08-23",
        company: "Global Enterprises",
        candidate: "Carla Martinez",
        full_week: true,
        // stop_date is omitted
        inflow: "Miles",
    },
    {
        date: "2024-08-24",
        company: "NextGen Innovations",
        candidate: "David Lee",
        full_week: false,
        stop_date: "2024-09-15",
        // inflow is omitted
    },
    {
        date: "2024-08-25",
        company: "Future Tech",
        candidate: "Eva Green",
        full_week: true,
        stop_date: null,
        inflow: "Indeed",
    },
];

export const stoppers = [
    {
        date: "2024-08-21",
        company: "Tech Solutions Inc.",
        candidate: "Alice Johnson",
        reason: "Personal reasons",
    },
    {
        date: "2024-08-22",
        company: "Innovative Tech",
        candidate: "Bob Smith",
        // reason is omitted
    },
    {
        date: "2024-08-23",
        company: "Global Enterprises",
        candidate: "Carla Martinez",
        reason: "Project completion",
    },
    {
        date: "2024-08-24",
        company: "NextGen Innovations",
        candidate: "David Lee",
        reason: "Career change",
    },
    {
        date: "2024-08-25",
        company: "Future Tech",
        candidate: "Eva Green",
        // reason is omitted
    },
]

export const jobInterview = [
    {
        date: "2024-08-01",
        company: "Tech Solutions Inc.",
        candidate: "Alice Johnson",
        reason: "Resigned due to personal reasons",
    },
    {
        date: "2024-08-02",
        company: "Innovative Tech",
        candidate: "Bob Smith",
        reason: "Project completed successfully",
    },
    {
        date: "2024-08-03",
        company: "Global Enterprises",
        candidate: "Carla Martinez",
        // No reason provided
    },
    {
        date: "2024-08-04",
        company: "NextGen Innovations",
        candidate: "David Lee",
        reason: "Pursuing further education",
    },
    {
        date: "2024-08-05",
        company: "Future Tech",
        candidate: "Eva Green",
        // No reason provided
    },
]

export const vacancies = [
    {
        date: "2024-08-01",
        company: "Tech Solutions Inc.",
        profile: "Software Engineer",
        online: true,
        online_date: "2024-08-01T10:15:30Z",
    },
    {
        date: "2024-08-02",
        company: "Innovative Tech",
        profile: "Data Analyst",
        online: false,
        online_date: "2024-08-02T14:30:00Z",
    },
    {
        date: "2024-08-03",
        company: "Global Enterprises",
        profile: "Project Manager",
        online: true,
        online_date: "2024-08-03T09:45:00Z",
    },
    {
        date: "2024-08-04",
        company: "NextGen Innovations",
        profile: "UX Designer",
        online: false,
        online_date: "2024-08-04T11:00:00Z",
    },
    {
        date: "2024-08-05",
        company: "Future Tech",
        profile: "DevOps Engineer",
        online: true,
        online_date: "2024-08-05T16:20:15Z",
    },
]

export const visits = [
    {
        date: "2024-08-10",
        company: "Tech Solutions Inc.",
        info: "Discussed project requirements and timelines.",
    },
    {
        date: "2024-08-12",
        company: "Innovative Tech",
        info: "Reviewed the latest product features and feedback.",
    },
    {
        date: "2024-08-15",
        company: "Global Enterprises",
        info: "Conducted a site inspection and met with the management team.",
    },
    {
        date: "2024-08-18",
        company: "NextGen Innovations",
        info: "Finalized the contract for the upcoming collaboration.",
    },
    {
        date: "2024-08-20",
        company: "Future Tech",
        info: "Presented the quarterly performance report.",
    },
]

export const prospectCalls = [
    {
        date: "2024-08-01",
        company: "Alpha Tech",
        info: "Initial contact made, discussed potential partnership opportunities.",
    },
    {
        date: "2024-08-05",
        company: "Beta Innovations",
        info: "Follow-up call regarding product demo scheduling.",
    },
    {
        date: "2024-08-08",
        company: "Gamma Solutions",
        info: "Discussed pricing and contract terms; awaiting approval.",
    },
    {
        date: "2024-08-12",
        company: "Delta Enterprises",
        info: "Explored integration possibilities with existing systems.",
    },
    {
        date: "2024-08-15",
        company: "Epsilon Tech",
        info: "Call to clarify technical requirements and next steps.",
    },
]

export const cooAgreement = [
    {
        date: "2024-08-01",
        company: "ABC Corp",
        created_at: "2024-08-01T10:15:00Z",
        sent: true,
        signed: false
    },
    {
        date: "2024-08-05",
        company: "XYZ Ltd",
        created_at: "2024-08-04T14:30:00Z",
        sent: true,
        signed: true
    },
    {
        date: "2024-08-10",
        company: "Global Solutions",
        created_at: "2024-08-09T09:45:00Z",
        sent: false,
        signed: false
    },
    {
        date: "2024-08-15",
        company: "Tech Innovators",
        created_at: "2024-08-14T08:00:00Z",
        sent: true,
        signed: true
    },
    {
        date: "2024-08-20",
        company: "Future Enterprises",
        created_at: "2024-08-19T11:25:00Z",
        sent: false,
        signed: false
    }
]

export const omWeeklyRecDummyData = {
    recruiters: [
        { id: 1, name: 'John Doe' },
        { id: 2, name: 'Jane Smith' },
        { id: 3, name: 'Emily Johnson' },
    ],
    recruitersData: [
        {
            calls: { target: 50, achieved: 45 },
            screening: { target: 30, achieved: 28 },
            presentation: { target: 20, achieved: 18 },
            interview: { target: 15, achieved: 10 },
            starters: { target: 5, achieved: 4 },
            stopper: { target: 2, achieved: 1 },
            created_by: 1,
            reports: exampleWeeklyReport
        },
        {
            calls: { target: 40, achieved: 35 },
            screening: { target: 25, achieved: 23 },
            presentation: { target: 18, achieved: 17 },
            interview: { target: 14, achieved: 12 },
            starters: { target: 6, achieved: 5 },
            stopper: { target: 3, achieved: 2 },
            created_by: 2,
            reports: {
                "calls": {
                    "mon": 4,
                    "tue": 5,
                    "wed": 6,
                    "thu": 7,
                    "fri": 8,
                    "sat": 3,
                    "sun": 2,
                    "total": 35
                },
                "screening": {
                    "mon": 1,
                    "tue": 0,
                    "wed": 2,
                    "thu": 1,
                    "fri": 0,
                    "sat": 1,
                    "sun": 0,
                    "total": 5
                },
                "presentation": {
                    "mon": 0,
                    "tue": 2,
                    "wed": 1,
                    "thu": 0,
                    "fri": 3,
                    "sat": 1,
                    "sun": 1,
                    "total": 8
                },
                "interview": {
                    "mon": 3,
                    "tue": 2,
                    "wed": 1,
                    "thu": 4,
                    "fri": 1,
                    "sat": 0,
                    "sun": 2,
                    "total": 13
                },
                "starter": {
                    "mon": 0,
                    "tue": 1,
                    "wed": 0,
                    "thu": 1,
                    "fri": 2,
                    "sat": 0,
                    "sun": 0,
                    "total": 4
                },
                "stopper": {
                    "mon": 1,
                    "tue": 0,
                    "wed": 1,
                    "thu": 0,
                    "fri": 0,
                    "sat": 1,
                    "sun": 0,
                    "total": 3
                },
                "visits": {
                    "mon": 2,
                    "tue": 1,
                    "wed": 3,
                    "thu": 4,
                    "fri": 3,
                    "sat": 0,
                    "sun": 1,
                    "total": 14
                },
                "vacancies": {
                    "mon": 3,
                    "tue": 4,
                    "wed": 2,
                    "thu": 5,
                    "fri": 3,
                    "sat": 0,
                    "sun": 1,
                    "total": 18
                },
                "prospect_calls": {
                    "mon": 4,
                    "tue": 3,
                    "wed": 5,
                    "thu": 4,
                    "fri": 2,
                    "sat": 3,
                    "sun": 1,
                    "total": 22
                }
            }
        },
        {
            calls: { target: 60, achieved: 50 },
            screening: { target: 35, achieved: 30 },
            presentation: { target: 25, achieved: 20 },
            interview: { target: 20, achieved: 15 },
            starters: { target: 8, achieved: 6 },
            stopper: { target: 4, achieved: 3 },
            created_by: 3,
            reports: {
                "calls": {
                    "mon": 3,
                    "tue": 4,
                    "wed": 2,
                    "thu": 5,
                    "fri": 7,
                    "sat": 1,
                    "sun": 6,
                    "total": 28
                },
                "screening": {
                    "mon": 1,
                    "tue": 1,
                    "wed": 0,
                    "thu": 2,
                    "fri": 0,
                    "sat": 1,
                    "sun": 0,
                    "total": 5
                },
                "presentation": {
                    "mon": 1,
                    "tue": 0,
                    "wed": 1,
                    "thu": 2,
                    "fri": 2,
                    "sat": 0,
                    "sun": 1,
                    "total": 7
                },
                "interview": {
                    "mon": 1,
                    "tue": 2,
                    "wed": 3,
                    "thu": 1,
                    "fri": 4,
                    "sat": 1,
                    "sun": 0,
                    "total": 12
                },
                "starter": {
                    "mon": 0,
                    "tue": 0,
                    "wed": 1,
                    "thu": 0,
                    "fri": 2,
                    "sat": 0,
                    "sun": 1,
                    "total": 4
                },
                "stopper": {
                    "mon": 0,
                    "tue": 1,
                    "wed": 0,
                    "thu": 1,
                    "fri": 0,
                    "sat": 0,
                    "sun": 0,
                    "total": 2
                },
                "visits": {
                    "mon": 3,
                    "tue": 2,
                    "wed": 4,
                    "thu": 3,
                    "fri": 1,
                    "sat": 0,
                    "sun": 1,
                    "total": 14
                },
                "vacancies": {
                    "mon": 1,
                    "tue": 2,
                    "wed": 3,
                    "thu": 4,
                    "fri": 1,
                    "sat": 0,
                    "sun": 1,
                    "total": 12
                },
                "prospect_calls": {
                    "mon": 2,
                    "tue": 4,
                    "wed": 3,
                    "thu": 5,
                    "fri": 4,
                    "sat": 2,
                    "sun": 1,
                    "total": 21
                }
            }
        }
    ],
    starters: [
        {
            date: '2024-08-01',
            company: 'ABC Corp',
            candidate: 'Alice Green',
            full_week: true,
            inflow: 'Direct',
            function: 'Developer',
            type: 'Full-time',
            start_week: '2024-08-01',
            created_by: 1
        },
        {
            date: '2024-08-02',
            company: 'XYZ Inc',
            candidate: 'Bob Brown',
            full_week: false,
            inflow: 'Referral',
            function: 'Designer',
            type: 'Part-time',
            start_week: '2024-08-02',
            created_by: 1
        },
        {
            date: '2024-08-03',
            company: 'DEF Ltd',
            candidate: 'Charlie White',
            full_week: true,
            inflow: 'Agency',
            function: 'Manager',
            type: 'Contract',
            start_week: '2024-08-03',
            created_by: 3
        }
    ],
    stoppers: [
        {
            date: '2024-08-10',
            company: 'ABC Corp',
            candidate: 'Alice Green',
            reason: 'Resigned',
            function: 'Developer',
            created_by: 1
        },
        {
            date: '2024-08-11',
            company: 'XYZ Inc',
            candidate: 'Bob Brown',
            reason: 'Laid off',
            function: 'Designer',
            created_by: 2
        },
        {
            date: '2024-08-12',
            company: 'DEF Ltd',
            candidate: 'Charlie White',
            reason: 'End of contract',
            function: 'Manager',
            created_by: 3
        }
    ]

}

export const omMonthlyRecDummyData = {
    recruiters: [
        { id: 1, name: "Alice" },
        { id: 2, name: "Bob" },
        { id: 3, name: "Charlie" }
    ],
    recTargets: [
        {
            created_by: 1,
            recruiterTargets: {
                calls: { target: 50, achieved: 45 },
                screening: { target: 30, achieved: 28 },
                presentation: { target: 20, achieved: 18 },
                interview: { target: 15, achieved: 12 },
                starters: { target: 5, achieved: 5 },
                stopper: { target: 2, achieved: 2 }
            }
        },
        {
            created_by: 2,
            recruiterTargets: {
                calls: { target: 40, achieved: 38 },
                screening: { target: 25, achieved: 23 },
                presentation: { target: 18, achieved: 20 },
                interview: { target: 12, achieved: 14 },
                starters: { target: 6, achieved: 7 },
                stopper: { target: 3, achieved: 1 }
            }
        },
        {
            created_by: 3,
            recruiterTargets: {
                calls: { target: 55, achieved: 50 },
                screening: { target: 35, achieved: 33 },
                presentation: { target: 22, achieved: 25 },
                interview: { target: 16, achieved: 18 },
                starters: { target: 4, achieved: 6 },
                stopper: { target: 1, achieved: 2 }
            }
        }
    ],
    starters: [
        {
            date: '2024-08-01',
            company: 'ABC Corp',
            candidate: 'Alice Green',
            full_week: true,
            inflow: 'Direct',
            function: 'Developer',
            type: 'Full-time',
            start_week: '2024-08-01',
            created_by: 1
        },
        {
            date: '2024-08-02',
            company: 'XYZ Inc',
            candidate: 'Bob Brown',
            full_week: false,
            inflow: 'Referral',
            function: 'Designer',
            type: 'Part-time',
            start_week: '2024-08-02',
            created_by: 1
        },
        {
            date: '2024-08-03',
            company: 'DEF Ltd',
            candidate: 'Charlie White',
            full_week: true,
            inflow: 'Agency',
            function: 'Manager',
            type: 'Contract',
            start_week: '2024-08-03',
            created_by: 3
        }
    ],
    stoppers: [
        {
            date: '2024-08-10',
            company: 'ABC Corp',
            candidate: 'Alice Green',
            reason: 'Resigned',
            function: 'Developer',
            created_by: 1
        },
        {
            date: '2024-08-11',
            company: 'XYZ Inc',
            candidate: 'Bob Brown',
            reason: 'Laid off',
            function: 'Designer',
            created_by: 2
        },
        {
            date: '2024-08-12',
            company: 'DEF Ltd',
            candidate: 'Charlie White',
            reason: 'End of contract',
            function: 'Manager',
            created_by: 3
        }
    ]

}

export const dummyTeamLeadsData = {
    teamLeads: [
        { id: 1, name: "Alice Johnson" },
        { id: 2, name: "Bob Smith" },
        { id: 3, name: "Charlie Brown" }
    ],
    starters: [
        {
            date: "2024-08-01",
            company: "TechCorp",
            candidate: "John Doe",
            function: "Software Developer",
            created_by: 1
        },
        {
            date: "2024-08-02",
            company: "HealthPlus",
            candidate: "Jane Smith",
            function: "Nurse",
            created_by: 2
        },
        {
            date: "2024-08-03",
            company: "EcoWorld",
            candidate: "Tom Brown",
            created_by: 3
        },
        // Records for created_by: 1
        {
            date: "2024-08-01",
            company: "TechCorp",
            candidate: "John Doe",
            function: "Software Developer",
            created_by: 1
        },
        {
            date: "2024-08-02",
            company: "HealthPlus",
            candidate: "Jane Smith",
            function: "Nurse",
            created_by: 1
        },
        {
            date: "2024-08-03",
            company: "EcoWorld",
            candidate: "Tom Brown",
            function: "Environmental Scientist",
            created_by: 1
        },
        {
            date: "2024-08-04",
            company: "GreenEnergy",
            candidate: "Lucy Gray",
            function: "Project Manager",
            created_by: 1
        },

        // Records for created_by: 2
        {
            date: "2024-08-05",
            company: "TechCorp",
            candidate: "Anna White",
            function: "Data Analyst",
            created_by: 2
        },
        {
            date: "2024-08-06",
            company: "HealthPlus",
            candidate: "Mike Johnson",
            function: "Physiotherapist",
            created_by: 2
        },
        {
            date: "2024-08-07",
            company: "EcoWorld",
            candidate: "Sara Blue",
            function: "Biologist",
            created_by: 2
        },
        {
            date: "2024-08-08",
            company: "GreenEnergy",
            candidate: "Chris Black",
            function: "Electrical Engineer",
            created_by: 2
        },

        // Records for created_by: 3
        {
            date: "2024-08-09",
            company: "TechCorp",
            candidate: "Emily Green",
            function: "UI/UX Designer",
            created_by: 3
        },
        {
            date: "2024-08-10",
            company: "HealthPlus",
            candidate: "David Brown",
            function: "General Practitioner",
            created_by: 3
        },
        {
            date: "2024-08-11",
            company: "EcoWorld",
            candidate: "Olivia Blue",
            function: "Geologist",
            created_by: 3
        },
        {
            date: "2024-08-12",
            company: "GreenEnergy",
            candidate: "James White",
            function: "Mechanical Engineer",
            created_by: 3
        }
    ],
    stoppers: [
        {
            date: "2024-08-05",
            company: "TechCorp",
            candidate: "John Doe",
            reason: "Relocation",
            function: "Software Developer",
            created_by: 1,
            housingByJobs: true
        },
        {
            date: "2024-08-06",
            company: "HealthPlus",
            candidate: "Jane Smith",
            reason: "Personal Reasons",
            created_by: 2
        },
        {
            date: "2024-08-07",
            company: "EcoWorld",
            candidate: "Tom Brown",
            reason: "Contract Ended",
            created_by: 3,
            housingByJobs: false
        },
        // Records for created_by: 1
        {
            date: "2024-08-01",
            company: "TechCorp",
            candidate: "John Doe",
            reason: "Relocation",
            function: "Software Developer",
            created_by: 1,
            housingByJobs: true
        },
        {
            date: "2024-08-02",
            company: "HealthPlus",
            candidate: "Jane Smith",
            reason: "Personal Reasons",
            function: "Nurse",
            created_by: 1,
            housingByJobs: false
        },
        {
            date: "2024-08-03",
            company: "EcoWorld",
            candidate: "Tom Brown",
            reason: "Contract Ended",
            function: "Environmental Scientist",
            created_by: 1,
            housingByJobs: true
        },
        {
            date: "2024-08-04",
            company: "GreenEnergy",
            candidate: "Lucy Gray",
            reason: "Job Dissatisfaction",
            function: "Project Manager",
            created_by: 1,
            housingByJobs: false
        },

        // Records for created_by: 2
        {
            date: "2024-08-05",
            company: "TechCorp",
            candidate: "Anna White",
            reason: "Health Issues",
            function: "Data Analyst",
            created_by: 2,
            housingByJobs: true
        },
        {
            date: "2024-08-06",
            company: "HealthPlus",
            candidate: "Mike Johnson",
            reason: "Family Commitments",
            function: "Physiotherapist",
            created_by: 2,
            housingByJobs: false
        },
        {
            date: "2024-08-07",
            company: "EcoWorld",
            candidate: "Sara Blue",
            reason: "Better Opportunity",
            function: "Biologist",
            created_by: 2,
            housingByJobs: true
        },
        {
            date: "2024-08-08",
            company: "GreenEnergy",
            candidate: "Chris Black",
            reason: "Relocation",
            function: "Electrical Engineer",
            created_by: 2,
            housingByJobs: false
        },

        // Records for created_by: 3
        {
            date: "2024-08-09",
            company: "TechCorp",
            candidate: "Emily Green",
            reason: "Pursuing Further Studies",
            function: "UI/UX Designer",
            created_by: 3,
            housingByJobs: true
        },
        {
            date: "2024-08-10",
            company: "HealthPlus",
            candidate: "David Brown",
            reason: "Contract Ended",
            function: "General Practitioner",
            created_by: 3,
            housingByJobs: false
        },
        {
            date: "2024-08-11",
            company: "EcoWorld",
            candidate: "Olivia Blue",
            reason: "Family Relocation",
            function: "Geologist",
            created_by: 3,
            housingByJobs: true
        },
        {
            date: "2024-08-12",
            company: "GreenEnergy",
            candidate: "James White",
            reason: "Personal Reasons",
            function: "Mechanical Engineer",
            created_by: 3,
            housingByJobs: false
        }
    ],
    jobInterviews: [
        {
            date: "2024-08-19",
            company: "NextGen Systems",
            candidate: "James Black",
            info: "HR interview",
            recruiter: "Bob Smith",
            result: "Too far distance",
            office: "I857"
        },

        {
            date: "2024-08-20",
            company: "Tech Solutions Inc.",
            candidate: "John Doe",
            info: "Second interview",
            recruiter: "Alice Johnson",
            result: "No match with company",
            office: "I850"
        },
        {
            date: "2024-08-21",
            company: "Innovative Labs",
            candidate: "Jane Smith",
            info: "First interview",
            recruiter: "Bob Smith",
            result: "Does not match the job profile",
            office: "I851"
        },
        {
            date: "2024-08-22",
            company: "Global Enterprises",
            candidate: "Michael Brown",
            info: "Final round",
            recruiter: "Charlie Brown",
            result: "No match in terms of salary",
            office: "I855"
        },
        {
            date: "2024-08-23",
            company: "Creative Tech",
            candidate: "Emily White",
            info: "Technical interview",
            recruiter: "Alice Johnson",
            result: "Candidate does not show up",
            office: "I856"
        }
    ]
};

export const dummyAnalysisData = {
    analysis: "The project exceeded expectations in terms of delivery time.",
    smile_moments: "The team celebrated a successful launch with a team lunch.",
    growth_points: "Need to improve communication between the design and development teams.",
    thoughts_comments: "Overall, the project was a great success, but there is room for improvement in the handover process.",
    am_om_analysis: "AM noted that the client was pleased with the outcomes, while OM identified areas where resource allocation could be optimized."
}
