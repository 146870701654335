import { ApiCall } from "components/common/services/ApiServices";
import {
  BASIC_DETAILS,
  GENERAL_DETAILS,
  GET_COMPANY_RELATED_DETAILS,
  SET_DROPDOWN_DATA,
  UPDATE_FIELDS,
  UPDATE_TAB_DATA,
} from "../../annotation/PlanningConstants";
import { COMPANY_MICROSERVICE } from "Constants";
import { FormatPCList } from "components/common/utlis/FormatPCList";
import { fetchCoeffDetails } from "./SetCoeffData";

export const fetchCompanyDetails = async (
  type: "company" | "branch", // Dynamic type handling
  id: number | null | undefined | string,
  dispatch: React.Dispatch<any>,
  mode: string = "create"
) => {
  const data = { type, id, mode };

  if (id) {
    const response = await ApiCall.service(
      `${GET_COMPANY_RELATED_DETAILS}`,
      "POST",
      data,
      false,
      COMPANY_MICROSERVICE
    );

    if (response.status === 200) {
      const responseData = response.data;

      if (type === "company") {
        const dropdownOptions = {
          branchOptions: responseData?.branchOptions || [],
          pcOptions: FormatPCList(responseData?.pcOptions) || [],
          commercialProfileOptions:
            responseData?.commercialProfileOptions || [],
          pcCommercialProfileOptions:
            responseData?.commercialProfileOptions || [],
          timeTableOptions: responseData?.timeTableOptions || [],
          payProfileOptions: responseData?.payProfileOptions || [],
          pcPayProfileOptions: responseData?.payProfileOptions || [],
        };
        dispatch({ type: SET_DROPDOWN_DATA, payload: dropdownOptions });

        const fieldsToUpdate = ["partiarCommittee", "branch"];
        fieldsToUpdate.forEach((field) => {
          if (responseData[field]) {
            dispatch({
              type: UPDATE_FIELDS,
              tab: BASIC_DETAILS,
              field: field,
              value: responseData[field], // Access the correct value dynamically
            });
          }
        });
      }
      // Handle branchDetails
      if (responseData.branchDetails && Object.keys(responseData.branchDetails).length > 0) {
        const branchDetail = responseData.branchDetails;
        dispatch({
          type: UPDATE_TAB_DATA,
          tab: GENERAL_DETAILS,
          data: branchDetail,
        });
      }      
    }
  }
};

export const setPlanningProfileRelatedFields = (
  profileId: number | null | undefined,
  state: any,
  dispatch: React.Dispatch<any>
) => {
  if (profileId) {
    //selected profile option
    const profileOption = state.dropDowndata.commercialProfileOptions.find(
      (option: any) => option.value === profileId
    );

    const pcOption = state.dropDowndata.pcOptions.find(
      (option: any) => option.value === profileOption.pcId
    );
    dispatch({
      type: UPDATE_FIELDS,
      tab: BASIC_DETAILS,
      field: "partiarCommittee",
      value: pcOption,
    });
    setPCRelatedDetails(pcOption.value, state, dispatch); //adjust dropdown values for pay and commercial profile
    //empType for selected profile
    const empTypeOption = state.dropDowndata.employeeTypeOptions.find(
      (option: any) => option.value === profileOption.empTypeId
    );
    fetchCoeffDetails("coeff", empTypeOption.value, dispatch);

    //function name for selected profile
    const functionName = profileOption.functionName ?? "";

    dispatch({
      type: UPDATE_FIELDS,
      tab: BASIC_DETAILS,
      field: "employeeType",
      value: empTypeOption,
    });
    dispatch({
      type: UPDATE_FIELDS,
      tab: BASIC_DETAILS,
      field: "function",
      value: functionName,
    });
  }
};

export const setPCRelatedDetails = (
  pcId: number | null | undefined,
  state: any,
  dispatch: React.Dispatch<any>
) => {
  if (pcId) {
    if (
      state.dropDowndata?.commercialProfileOptions &&
      state.dropDowndata.commercialProfileOptions.length > 0
    ) {
      //options for commercial profile
      const filteredCommercialProfileOptions =
        state.dropDowndata.commercialProfileOptions.filter(
          (option: any) => option.pcId === pcId
        );

      //options for pay profile
      const filteredPayProfileOptions =
        state.dropDowndata.payProfileOptions.filter(
          (option: any) => option.pcId === pcId
        );

      const dropdownOptions = {
        pcCommercialProfileOptions: filteredCommercialProfileOptions,
        pcPayProfileOptions: filteredPayProfileOptions,
      };

      dispatch({ type: SET_DROPDOWN_DATA, payload: dropdownOptions });
    }
  }
};
