import React from "react";
import AscendingIcon from "static/images/AscendingIcon";
import DescendingIcon from "static/images/DescendingIcon";
import SortIcon from "static/images/SortIcon";

interface Props {
  sortType: 'asc' | 'desc' | '';
}

const iconMap = {
  '': SortIcon,
  'asc': AscendingIcon,
  'desc': DescendingIcon,
};

const Sorting: React.FC<Props> = ({ sortType = '' }) => {
  const IconComponent = iconMap[sortType] || SortIcon;
  
  return (
    <span className="ms-1 cursor-pointer">
      <IconComponent />
    </span>
  );
};

export default Sorting;
