import React from 'react'

const EmailTemplate = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 68.255 69.945">
        <path id="Open_invoice" data-name="Open invoice" d="M2.185,69.945,18.676,54.032h29.91L64.375,69.945ZM51.893,51.379l15.67-15.3V67.422ZM0,36.076l15.673,15.3L0,67.422ZM21.658,50.9l-5.715-5.765V0H50.89V44.959L45.318,50.9Zm-.972-10.638H45.972V38.983H20.686Zm0-5.155H45.972V33.827H20.686Zm0-5.155H45.972V28.676H20.686Zm0-5.155H45.972V23.521H20.686ZM18.543,14.26h2.831a4.611,4.611,0,0,0,3.247,3.069,4.008,4.008,0,0,0,.965.116,4.315,4.315,0,0,0,3.174-1.434l-.909-.98a3.111,3.111,0,0,1-2.265,1.015,3.849,3.849,0,0,1-.447-.025A3.229,3.229,0,0,1,22.79,14.26h1.524l.447-1.307H22.406a3.69,3.69,0,0,1,0-.786H25.15l.447-1.3H22.79A3.209,3.209,0,0,1,25.15,9.109a3.537,3.537,0,0,1,.437-.035A3.1,3.1,0,0,1,27.852,10.1l.909-.983a4.266,4.266,0,0,0-3.174-1.431,4.337,4.337,0,0,0-.958.106,4.624,4.624,0,0,0-3.254,3.076H19.826l-.447,1.307h1.737a4.827,4.827,0,0,0,0,.779H18.991ZM34.6,17.072H44.637v-1.29H34.6Zm0-5.152h6.764V10.627H34.6ZM58.258,24.264l10,7.072-10,8.894ZM0,31.336l10-7.072V40.23Z" fill="currentColor" />
      </svg>

    </>
  )
}

export default EmailTemplate