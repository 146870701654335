import React from "react";
import { useParams } from "react-router-dom";
import FormBuilder from "components/common/services/form/FormBuilder";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import {
  CONF_SETTINGS_ADD,
  CONF_SETTINGS_EDIT,
  CONF_SETTINGS_UPDATE,
} from "routes/ApiEndpoints";
import { validateRequired } from "components/common/services/ValidationService";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "Constants";

interface Props {
  roleId?: number;
}

const CompanyRole: React.FC<Props> = ({ roleId }) => {
  const { id } = useParams<{ id: string }>();
  const FunctionFormValidation = {
    roleName: [validateRequired],
  };

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Roles",
          read: true,
        },
      ]}
      renderNoAccess={true}
    >
      <div className="row">
        <div className="col-md-12">
          <div>
            <FormBuilder
              title={
                id === undefined
                  ? t("Create company role")
                  : t("Edit company role")
              }
              SaveDataAPI={
                id === undefined ? CONF_SETTINGS_ADD : CONF_SETTINGS_UPDATE
              }
              entryId={id}
              getDataAPI={CONF_SETTINGS_EDIT}
              formName={"CompanyRole"}
              redirect={-1}
              validationRules={FunctionFormValidation}
              microService={CENTRAL_DATA_MANAGEMENT_MICROSERVICE}
              backButton={true}
              type="companyRole"
              dataToInclude={{ id: id }}
            //   subForm={true}
              refresh={true}
            />
          </div>
        </div>
      </div>
    </AccessControl>
  );
};

export default translate(CompanyRole);
