import LabelWithCKEditorField from "components/common/molecules/LabelWithCKEditorField";
import React, { useState } from "react";
import { useFormContext } from "../../context/Context";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import {
  JOB_DESCRIPTION,
  UPDATE_FIELDS,
  UPDATE_FIELD_ERROR,
} from "../../annotation/VacancyConstants";
import Navigation from "../form-navigation/Navigation";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import { scrollToTop } from "components/common/services/ValidationService";
import FileUpload from "components/common/molecules/FileUpload";

const VacancyJobDescriptionDetails = () => {
  const { state, dispatch } = useFormContext();

  const [validationStatus, setValidationStatus] = useState({
    isValid: false,
    type: "",
  });

  const validStatus = (validation: any) => {
    setValidationStatus({
      isValid: validation.isValid,
      type: validation.type,
    });
  };

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    dispatch({
      type: UPDATE_FIELDS,
      template: JOB_DESCRIPTION,
      field: name,
      value: value,
    });
  };
  const handleCKEditorChange = (event: any, editor: any, fieldName: string) => {
    const content = editor.getData(); // Get the content from the editor
    const name = fieldName; // Use the fieldName parameter
    dispatch({
      type: UPDATE_FIELDS,
      template: JOB_DESCRIPTION,
      field: name,
      value: content,
    });
  };

  const handleSelectChange = (selectedOption: any, name: string) => {
    dispatch({
      type: UPDATE_FIELDS,
      template: JOB_DESCRIPTION,
      field: name,
      value: selectedOption,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
  };

  const fileUploadStyleObject: React.CSSProperties = {
    width: "100%",
  };

  const returnFileUploadErrors = (errorResposne: any) => {
    const step = errorResposne["step"];
    const error = errorResposne["error"];
    dispatch({ type: UPDATE_FIELD_ERROR, field: step, error: error });
  };

  const fileUploadResponse = (responseObj: any) => {
    const step = responseObj["step"];
    const response = responseObj["response"];

    const fieldMappings: { [key: string]: string[] } = {
      fileId: ["fileId", "fileName", "filePath"],
    };

    const fieldNames = fieldMappings[step];

    if (fieldNames) {
      fieldNames.forEach((fieldName: string, key: number) => {
        dispatch({
          type: UPDATE_FIELDS,
          template: JOB_DESCRIPTION,
          field: fieldName,
          value: response[key],
        });
      });
    }
  };

  const deleteResponse = (field: string) => {
    const fieldMappings: { [key: string]: string[] } = {
      certificateId: ["fileId", "fileName", "filePath"],
    };

    const fieldNames = fieldMappings[field];

    if (fieldNames) {
      fieldNames.forEach((fieldName: string) => {
        dispatch({
          type: UPDATE_FIELDS,
          template: JOB_DESCRIPTION,
          field: fieldName,
          value: fieldName.endsWith("Id") ? null : "",
        });
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12">
          <div className="form-border" style={{ paddingTop: "3.4vw" }}>
            {/* <div className="row pb-3">
              <div className="col-md-12">
                <LabelWithCKEditorField
                  label={t("Job description")}
                  name="description"
                  value={state.jobDescription.description}
                  placeholder={t("Type here...")}
                  handleChange={(event, editor) =>
                      handleCKEditorChange(
                          event,
                          editor,
                          "description"
                      )
                  }
                  className="field-shadow"
                />
              </div>
            </div> */}
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-4">
                <SelectWithSearch
                  title={t("Reason for closure")}
                  name="reasonForClosure"
                  placeHolder={t("Select")}
                  search={true}
                  options={state.options.reasonForClosureOptions}
                  value={state.jobDescription.reasonForClosure}
                  onChange={(e) => handleSelectChange(e, "reasonForClosure")}
                  isMulti={false}
                  className="select-field"
                  isTranslate={true}
                />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-4">
                <LabelWithInputField
                  isMandatory={false}
                  name="extraInfo"
                  handleChange={handleFieldChange}
                  value={state.jobDescription.extraInfo}
                  id="extraInfo"
                  label={t("Extra info")}
                  type="text"
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-4">
                <FileUpload
                  id="fileId"
                  name="fileId"
                  label={t("Upload file")}
                  isMandatory={false}
                  edit={false}
                  fileId={
                    state.jobDescription.fileId
                      ? state.jobDescription.fileId
                      : null
                  }
                  filePath={
                    state.jobDescription.filePath
                      ? state.jobDescription.filePath
                      : ""
                  }
                  fileName={
                    state.jobDescription.fileName
                      ? state.jobDescription.fileName
                      : ""
                  }
                  style={fileUploadStyleObject}
                  multiple={false}
                  uploadPath="vacancy-certificates"
                  formats=".pdf,.docx,.doc,.jpg,.jpeg"
                  returnFileUploadErrors={(errorResponse: any) =>
                    returnFileUploadErrors(errorResponse)
                  }
                  fileUploadResponse={(uploadResposne: any) =>
                    fileUploadResponse(uploadResposne)
                  }
                  deleteResponse={(field: any) => deleteResponse(field)}
                  maxFileSize={10 * 1024 * 1024}
                  microserviceURL={`${process.env.REACT_APP_CDM_SERVICE}`}
                />
                <span className="text-danger">{state.errors?.fileId}</span>
              </div>
              <div className="col-md-12 hideHeight">
                <LabelWithCKEditorField
                  label={t("Info")}
                  name="info"
                  value={state.jobDescription.info}
                  placeholder={t("Type here") + "..."}
                  handleChange={(event, editor) =>
                    handleCKEditorChange(event, editor, "info")
                  }
                  className="field-shadow"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Navigation validStatus={validStatus} />
    </form>
  );
};

export default translate(VacancyJobDescriptionDetails);
