import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import EditIcon from "../../../../static/images/EditIcon";
import { Link } from "react-router-dom";
import Button from "components/common/atoms/Button";
import ModalPopup from "components/common/atoms/ModalPopup";
import { ApiCall } from "components/common/services/ApiServices";
import CustomNotify from "components/common/atoms/CustomNotify";
import { COMPANY_CONTACTS, DELETE_COMPANY_CONTACT } from "routes/ApiEndpoints";
import Pagination from "components/common/atoms/Pagination";
import Title from "components/common/atoms/Title";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import * as CONST from "../../../../Constants";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import PhoneCallIcon from "static/images/PhoneCall";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import { Option, OptionProps } from "components/common/utlis/TypeAnnotations";
import {
  createQueryParams,
  filterAndMerge,
  getQueryParamsFromRedux,
  stopRecording,
} from "components/common/utlis/HelperFunctions";
import {
  clearDestinationPath,
  setDestinationPath,
} from "features/NavigationSlice";
import { useDispatch } from "react-redux";
import { RootState } from "store";
import SearchFields from "components/common/atoms/SearchFields";
import { createFilterConfig } from "../manageCompanyContacts/Filters";
import ResetBtn from "components/common/atoms/ResetBtn";
import DeleteIcon from "static/images/DeleteIcon";
import StopIcon from "static/images/StopIcon";
import StartIcon from "static/images/StartIcon";
import PaginationNew from "components/common/atoms/PaginationNew";
import Sorting from "components/common/atoms/Sorting";
import ActionIconWrapper from "components/common/atoms/ActionIconWrapper";
import SpinnerWrapper from "components/common/atoms/SpinnerWrapper";
import TextEllipsis from "components/common/atoms/TextEllipsis";
import NoRecords from "components/common/atoms/NoRecords";

interface CompanyContact {
  id: number;
  name: string;
  company_status: number;
  company_id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  office: string;
  gsm: string;
  contact_id: number;
  responsible_person: boolean;
  responsible_person_count: number | null;
  userId: number | null;
  recordingStatus: number | null;
}

interface SearchProps {
  company: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneOrGsm: string;
  office: number[];
  CompStatus: Option | null;
  currentPage: number;
  recordsPerPage: number;
  orderByColumn: string;
  orderIn: "" | "asc" | "desc";
}

interface ModalData {
  body: string;
  size: string | undefined;
  hideButtons: boolean;
}

export const ManageCompanyContacts: React.FC = () => {
  const [modalData, setModalData] = useState<ModalData>({
    body: `${t("Are you sure you want to delete") + "?"}`,
    size: undefined,
    hideButtons: false,
  });
  const authUser = useSelector(selectAuth);
  const dispatch = useDispatch();
  const [deleteID, setDeleteId] = useState<number | undefined>();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [contacts, setContacts] = useState<CompanyContact[]>([]);
  const [paginationData, setPaginationData] = useState({
    totalPages: 1,
    totalRecords: 1,
  });
  const [dropdownList, setDropdownList] = useState({
    offices: [],
    compStatus: [],
  });
  const destinationPath = useSelector(
    (state: RootState) => state.navigation.destinationPath
  );
  const queryParams = getQueryParamsFromRedux(destinationPath);
  const filters = createFilterConfig(dropdownList);

  // Initialize search data based on query params
  const defaultSearchData: SearchProps = {
    company: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneOrGsm: "",
    office: [],
    CompStatus: null,
    currentPage: 1,
    recordsPerPage: 10,
    orderByColumn: "",
    orderIn: "",
  };

  const filteredSearchData = filterAndMerge(defaultSearchData, queryParams);

  // Initialize search data based on query params, merging with default values
  const [searchData, setSearchData] = useState<SearchProps>({
    ...defaultSearchData,
    ...filteredSearchData,
  });

  useEffect(() => {
    const filterData = { ...searchData };
    const searchUrl = createQueryParams(filterData);
    dispatch(setDestinationPath(searchUrl));
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      fetchDetails(filterData);
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [searchData]);

  const fetchDetails = async (filterData: any) => {
    setLoading(true);
    const data = {
      search: filterData,
    };
    const response = await ApiCall.service(
      COMPANY_CONTACTS,
      "POST",
      data,
      false,
      CONST.COMPANY_MICROSERVICE
    );
    if (response.status === 200) {
      const { contactsData, dropdownData, paginationData } = response.data;
      setContacts(contactsData);
      setPaginationData(paginationData);
      setDropdownList(dropdownData);
      if (response.contactsData) {
        response.contactsData.forEach((contact: any) => {
          if (contact.recordingStatus === 1) {
            setSalesNote((prev) => ({
              ...prev,
              activeRecordingId: contact?.contact_id,
              prevId: contact?.contact_id,
            }));
          }
        });
      }
    }

    setLoading(false);
  };

  //** change handlers */
  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: 1,
      [name]: value,
    }));
  };

  const handlePageChange = (pageNumber: number) => {
    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: pageNumber,
    }));
  };

  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: 1,
      [fieldName]: selectedOption,
    }));
  };

  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    setSearchData((prev) => ({
      ...prev,
      recordsPerPage: newItemsPerPage,
      currentPage: 1,
    }));
  };

  //** search filters */
  const handleReset = async (e: React.FormEvent) => {
    dispatch(clearDestinationPath());
    setSearchData(defaultSearchData);
    fetchDetails(defaultSearchData);
  };

  const deleteContact = async (
    employerId: number,
    isResponsiblePerson: boolean | null
  ) => {
    if (isResponsiblePerson === true) {
      setModalData({
        body: t(
          "Are you sure you want to delete this contact? They are the decision maker for the company."
        ),
        size: "lg",
        hideButtons: false,
      });
    }
    setShowModal(true);
    setDeleteId(employerId);
  };

  // Sales note
  const [salesnote, setSalesNote] = useState({
    name: "",
    phone: "",
    id: null,
    navigate: "",
    companyId: null,
    activeRecordingId: null as number | null,
    triggerMeeting: false as boolean,
    type: null as number | null,
    prevId: null as number | null | undefined,
  });

  const handleMeeting = async (
    name: string,
    phone: string | null,
    id: any,
    companyId: any,
    contactId: number | null,
    navigate?: string
  ) => {
    setSalesNote({
      name: name,
      phone: phone !== null ? phone : "",
      id: id,
      companyId: companyId,
      navigate: navigate ?? "",
      activeRecordingId: contactId,
      triggerMeeting: true,
      type: 3, // For company contacts type is 3
      prevId: null,
    });
  };

  const stoprecording = async () => {
    await stopRecording(authUser.userId, setSalesNote);
  };

  const handleModalClose = () => {
    setShowModal(!showModal);
    setModalData({
      body: `${t("Are you sure you want to delete") + "?"}`,
      size: undefined,
      hideButtons: false,
    });
  };

  const closeTitle = !modalData.hideButtons ? t("No") : undefined;
  const confirmTitle = !modalData.hideButtons ? t("Yes") : undefined;

  const handleSaveChanges = async () => {
    setShowModal(false);
    if (deleteID) {
      const response = await ApiCall.getService(
        `${DELETE_COMPANY_CONTACT}/${deleteID}`,
        "POST",
        CONST.COMPANY_MICROSERVICE,
        false
      );
      if (response.status === 200) {
        fetchDetails(searchData);
        CustomNotify({ type: "success", message: t(response.msg) });
      } else {
        const parsedError = JSON.parse(response.message);
        const { company_id, revision_id } = parsedError;
        const url = `/cooperation-agreement/create/${company_id}/${revision_id}?tabId=contacts`;
        const message = `${t(
          "Before deleting this record, please assign a new decision maker due to its link with a cooperation agreement. "
        )} <a href="${url}" target="_blank">${t("Click here")}</a> ${t(
          "to add another decision maker."
        )}`;
        setModalData({
          body: message,
          size: "lg",
          hideButtons: true,
        });
        setShowModal(true);
      }
    }
  };

  const handleOrderByColumn = (column: string) => {
    let newOrder: "" | "asc" | "desc" = ""; // Explicit type declaration
    let newColumn = column;

    if (searchData.orderByColumn === column) {
      // Cycle through the three possible order values: '', 'asc', 'desc'
      if (searchData.orderIn === "") {
        newOrder = "asc"; // From '' to 'asc'
      } else if (searchData.orderIn === "asc") {
        newOrder = "desc"; // From 'asc' to 'desc'
      } else {
        newOrder = ""; // From 'desc' to ''
        newColumn = ""; // Reset column if no order
      }
    } else {
      // If it's a new column, start with ascending
      newOrder = "asc";
    }

    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: 1, // Reset pagination on sort change
      orderByColumn: newColumn, // Set the new column or empty string
      orderIn: newOrder, // Set the new order (asc, desc, or empty)
    }));
  };

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Company",
          read: true,
        },
      ]}
      renderNoAccess={true}
    >
      <Title title={t("Company contacts")} />
      <div className="row">
        <div className="col-12">
          <div className="position-relative tableMainWrapper mt-0">
            <div
              className="table-responsive tableSection"
              style={{ paddingTop: "0.5vw" }}
            >
              <table className="table manageCompanyContacts border-0 mb-0">
                <thead>
                  <tr className="tableSearchFields">
                    <SearchFields
                      searchData={searchData}
                      filters={filters}
                      handleSearchChange={handleSearchChange}
                      handleSelectChange={handleSelectChange}
                    />
                    <th className="border-0">
                      <div className="d-flex align-items-center w-100">
                        <ResetBtn handleResetClick={handleReset} />
                      </div>
                    </th>
                  </tr>
                </thead>
              </table>
              <div className="tableContainerAgreement scrollBarDesign">
                <table className="table table-hover manageCompanyContacts">
                  <thead>
                    <tr className="TableHeader">
                      <th onClick={(e) => handleOrderByColumn("office")}>
                        {t("Office")}{" "}
                        <Sorting
                          sortType={
                            searchData.orderByColumn === "office"
                              ? searchData.orderIn
                              : ""
                          }
                        />
                      </th>
                      <th onClick={(e) => handleOrderByColumn("company")}>
                        {t("Company")}{" "}
                        <Sorting
                          sortType={
                            searchData.orderByColumn === "company"
                              ? searchData.orderIn
                              : ""
                          }
                        />
                      </th>
                      <th onClick={(e) => handleOrderByColumn("firstName")}>
                        {t("First name")}{" "}
                        <Sorting
                          sortType={
                            searchData.orderByColumn === "firstName"
                              ? searchData.orderIn
                              : ""
                          }
                        />
                      </th>
                      <th onClick={(e) => handleOrderByColumn("lastName")}>
                        {t("Last name")}{" "}
                        <Sorting
                          sortType={
                            searchData.orderByColumn === "lastName"
                              ? searchData.orderIn
                              : ""
                          }
                        />
                      </th>
                      <th onClick={(e) => handleOrderByColumn("email")}>
                        {t("Email")}{" "}
                        <Sorting
                          sortType={
                            searchData.orderByColumn === "email"
                              ? searchData.orderIn
                              : ""
                          }
                        />
                      </th>
                      <th onClick={(e) => handleOrderByColumn("phone")}>
                        {t("Phone number")}{" "}
                        <Sorting
                          sortType={
                            searchData.orderByColumn === "phone"
                              ? searchData.orderIn
                              : ""
                          }
                        />
                      </th>
                      <th onClick={(e) => handleOrderByColumn("gsm")}>
                        {t("GSM")}{" "}
                        <Sorting
                          sortType={
                            searchData.orderByColumn === "gsm"
                              ? searchData.orderIn
                              : ""
                          }
                        />
                      </th>
                      <th>{t("Status")}</th>
                      <th>
                        <AccessControl
                          requiredPermissions={[
                            {
                              permission: "Company",
                              update: true,
                              delete: true,
                            },
                          ]}
                          actions={true}
                          strict={false}
                        >
                          {t("Action")}
                        </AccessControl>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <SpinnerWrapper headerLength={9} />
                    ) : (
                      <>
                        {contacts && contacts.length > 0 ? (
                          contacts.map((contact, index) => (
                            <tr
                              key={index}
                              className="border-bottom mb-3 box-shadow align-middle"
                            >
                              <td className="align-middle py-3 ps-lg-4">
                                {contact.office}
                              </td>
                              <td className="align-middle py-3">
                                {authUser.role === CONST.EMPLOYER ? (
                                  contact.name
                                ) : (
                                  <AccessControl
                                    requiredPermissions={[
                                      {
                                        permission: "Company",
                                        update: true,
                                        read: true,
                                      },
                                    ]}
                                  >
                                    <a
                                      href={`/company/${contact.id}?tabId=contacts&destination=/company-contacts`}
                                    >
                                      {contact.name}
                                    </a>
                                  </AccessControl>
                                )}
                              </td>
                              <td className="align-middle py-3">
                                {contact.first_name}
                              </td>
                              <td className="align-middle py-3">
                                {contact.last_name}
                              </td>
                              <td
                                className="text-break"
                                data-label={t("Email")}
                              >
                                <a
                                  href={`mailto:${contact.email}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <TextEllipsis
                                    label={contact.email}
                                    title={contact.email}
                                    width="14.5vw"
                                  />
                                </a>
                              </td>
                              {contact.phone_number &&
                              contact.phone_number !== "" ? (
                                <td className="align-middle py-3">
                                  <a
                                    onClick={() =>
                                      handleMeeting(
                                        contact.name,
                                        contact.phone_number,
                                        contact.userId,
                                        contact.company_id,
                                        contact.contact_id,
                                        CONST.CALL_3CX
                                      )
                                    }
                                    className="cursor-pointer"
                                    title={t("Call")}
                                  >
                                    {contact.phone_number &&
                                    contact.phone_number !== ""
                                      ? `+${contact.phone_number}`
                                      : "-"}
                                  </a>
                                </td>
                              ) : (
                                <td
                                  className="ps-lg-3"
                                  style={{ fontSize: "20px" }}
                                >
                                  {"-"}
                                </td>
                              )}
                              {contact.gsm && contact.gsm !== "" ? (
                                <td className="align-middle py-3">
                                  <a
                                    onClick={() =>
                                      handleMeeting(
                                        contact.name,
                                        contact.phone_number,
                                        contact.userId,
                                        contact.company_id,
                                        contact.contact_id,
                                        CONST.CALL_3CX
                                      )
                                    }
                                    title={t("Call")}
                                    className="cursor-pointer"
                                  >
                                    {contact.gsm && contact.gsm !== ""
                                      ? `+${contact.gsm}`
                                      : "-"}
                                  </a>
                                </td>
                              ) : (
                                <td
                                  className="ps-lg-3"
                                  style={{ fontSize: "20px" }}
                                >
                                  {"-"}
                                </td>
                              )}
                              <td className="align-middle">
                                <span
                                  className={`d-inline-block rounded-circle status-icon ${
                                    !contact.company_status
                                      ? "bg-warning"
                                      : contact.company_status
                                      ? "bg-success"
                                      : "bg-danger"
                                  }`}
                                  data-toggle="tooltip"
                                ></span>
                              </td>
                              <td className="table-action-icons">
                                <ActionIconWrapper initialVisibleCount={4}>
                                  <AccessControl
                                    requiredPermissions={[
                                      {
                                        permission: "Company",
                                        update: true,
                                        read: true,
                                      },
                                    ]}
                                  >
                                    {authUser.role !== CONST.EMPLOYER && (
                                      <div className="d-inline-block">
                                        <Link
                                          to={`/company/${contact.id}?tabId=contacts&destination=/company-contacts`}
                                          className="btn p-0 border-0"
                                          title={t("Edit")}
                                        >
                                          <EditIcon />
                                        </Link>
                                      </div>
                                    )}
                                  </AccessControl>
                                  <AccessControl
                                    requiredPermissions={[
                                      {
                                        permission: "Company",
                                        update: true,
                                        read: true,
                                      },
                                    ]}
                                  >
                                    {contact.phone_number &&
                                      contact.phone_number !== "" && (
                                        <a
                                          onClick={() =>
                                            handleMeeting(
                                              contact.name,
                                              contact.phone_number,
                                              contact.userId,
                                              contact.company_id,
                                              contact.contact_id,
                                              CONST.CALL_3CX
                                            )
                                          }
                                          title={t("Call")}
                                          className="text-decoration-none cursor-pointer"
                                        >
                                          <PhoneCallIcon />
                                        </a>
                                      )}
                                  </AccessControl>
                                  <AccessControl
                                    requiredPermissions={[
                                      {
                                        permission: "Company",
                                        update: true,
                                        read: true,
                                      },
                                    ]}
                                  >
                                    <span
                                      onClick={() =>
                                        deleteContact(
                                          contact.contact_id,
                                          contact.responsible_person
                                        )
                                      }
                                      title={t("Delete")}
                                    >
                                      <DeleteIcon />
                                    </span>
                                  </AccessControl>
                                  <AccessControl
                                    requiredPermissions={[
                                      {
                                        permission: "Sales note",
                                        read: true,
                                      },
                                    ]}
                                  >
                                    {salesnote.activeRecordingId ===
                                      contact.contact_id && (
                                      <>
                                        <span
                                          className="cursor-pointer marginRightPoint5"
                                          onClick={() => stoprecording()}
                                          title={t("Stop")}
                                        >
                                          <StopIcon />
                                        </span>
                                      </>
                                    )}
                                    {salesnote.activeRecordingId === null && (
                                      <span
                                        className="me-2 cursor-pointer"
                                        onClick={() =>
                                          handleMeeting(
                                            contact.name,
                                            contact.phone_number,
                                            contact.userId,
                                            contact.id,
                                            contact.contact_id
                                          )
                                        }
                                        title={t("Start")}
                                      >
                                        <StartIcon />
                                      </span>
                                    )}
                                  </AccessControl>
                                </ActionIconWrapper>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <NoRecords headerLength={10} />
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              {!loading && (
                <div className="pagination d-block">
                  <PaginationNew
                    currentPage={searchData.currentPage}
                    totalPages={paginationData.totalPages}
                    totalRecords={paginationData.totalRecords}
                    recordsPerPage={searchData.recordsPerPage}
                    onItemsPerPageChange={handleItemsPerPageChange}
                    onPageChange={(newPage: any) => handlePageChange(newPage)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6 align-self-center" style={{ padding: "1vw 0" }}>
        <Link to="/dashboard" className=" back-btn text-decoration-underline">
          {t("Back")}
        </Link>
      </div>
      <ModalPopup
        show={showModal}
        onHide={handleModalClose}
        title={t("Delete confirmation")}
        body={modalData.body}
        onCloseButtonClick={handleModalClose}
        onConfirmButtonClick={handleSaveChanges}
        closeTitle={closeTitle}
        confirmTitle={confirmTitle}
        isInnerHTML={true}
        size={modalData.size}
      />
    </AccessControl>
  );
};
export default translate(ManageCompanyContacts);
