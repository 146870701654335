import React from "react";
import { TargetTypeData } from "../Dashboard";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import "static/css/welcome-dashboard.css";
import Tile from "./Tile";
import { AREA_MANAGER, OFFICE_MANAGER, RECRUITER } from "Constants";
import SubTiles from "./SubTiles";
import { t } from "components/CentralDataMangement/translation/Translation";
import { Option } from "components/common/utlis/TypeAnnotations";
import { useDispatch } from "react-redux";
import {
  clearDestinationPath,
  setDestinationPath,
} from "features/NavigationSlice";
import { createQueryParams } from "components/common/utlis/HelperFunctions";
import { getStartAndEndDate } from "components/CentralDataMangement/taskDeterminer/CommonSuccessionFunctions";

interface Props {
  params: any;
  countData: TargetTypeData;
  displayDate: string;
  isMyBudget: boolean;
  office: Option | undefined;
}

const TargetDashboard: React.FC<Props> = ({ params, countData, displayDate, isMyBudget, office = { value: null, label: "" } }) => {
  const dispatch = useDispatch();
  const { userAuth } = params;
  const data = countData && countData["New"]?.[0]?.userName ? true : false;

  const countFiltersForFollowUps = (type: number, userId: number, officeId?: number, isRecruiter?: boolean) => {
    const { startDate, endDate } = getStartAndEndDate(userAuth?.dateInfo?.type, userAuth?.dateInfo?.year, userAuth?.dateInfo?.value);
    dispatch(clearDestinationPath());

    // Add currentPage and recordsPerPage to filters
    const updatedFilters = {
      fromDashboard: true,
      isRecruiter: isRecruiter ?? false,
      userIds: [userId],
      // createdBy: userName,
      // followedBy: userName,
      office: [officeId ?? office?.value],
      taskType: [type],
      taskStart: startDate,
      taskEnd: endDate,
      followUpStart: startDate,
      followUpEnd: endDate,
      currentPage: 1,
      recordsPerPage: 10
    };

    const searchUrl = createQueryParams(updatedFilters);
    dispatch(setDestinationPath(searchUrl));
  }

  return (
    <>

      <div className={`${(userAuth.role === RECRUITER || !data) ? "col-12" : "col-6"}`}>
        <div className="row">
          {(userAuth.role !== OFFICE_MANAGER && userAuth.role !== AREA_MANAGER) &&
            <AccessControl
              requiredPermissions={[
                {
                  permission: "New",
                  read: true,
                },
              ]}
              renderNoAccess={false}
            >
              <Tile
                onClick={() => countFiltersForFollowUps(5, userAuth?.userId)}
                path={`/manage/follow-up?filter=followup-counts`}
                target={countData?.["New"]?.[0]?.target ?? 0}
                targetAchieved={countData?.["New"]?.[0]?.achieved ?? 0}
                cardTitle={t("New")}
                icon="NewIcon"
                userRole={userAuth.role}
                isRecData={data}
                isMyBudget={isMyBudget}
                displayDate={displayDate}
              />
            </AccessControl>
          }

          <AccessControl
            requiredPermissions={[
              {
                permission: "Calls",
                read: true,
              },
            ]}
            renderNoAccess={false}
          >
            <Tile
              onClick={() => countFiltersForFollowUps(3, userAuth?.userId)}
              path={`/manage/follow-up?filter=followup-counts`}
              target={countData?.["Calls"]?.[0]?.target ?? 0}
              targetAchieved={countData?.["Calls"]?.[0]?.achieved ?? 0}
              cardTitle={t("Calls")}
              icon="CallIcon"
              userRole={userAuth.role}
              isRecData={data}
              isMyBudget={isMyBudget}
              displayDate={displayDate}
            />
          </AccessControl>

          <AccessControl
            requiredPermissions={[
              {
                permission: "Screening",
                read: true,
              },
            ]}
            renderNoAccess={false}
          >
            <Tile
              // onClick={() => countFiltersForFollowUps(8, userAuth?.userId)}
              // path={`/manage/follow-up?filter=followup-counts`}
              target={countData?.["Screening"]?.[0]?.target ?? 0}
              targetAchieved={countData?.["Screening"]?.[0]?.achieved ?? 0}
              cardTitle={t("Screening")}
              icon="ScreeningIcon"
              userRole={userAuth.role}
              isRecData={data}
              isMyBudget={isMyBudget}
              displayDate={displayDate}
            />
          </AccessControl>

          <AccessControl
            requiredPermissions={[
              {
                permission: "Presented",
                read: true,
              },
            ]}
            renderNoAccess={false}
          >
            <Tile
              onClick={() => countFiltersForFollowUps(7, userAuth?.userId)}
              path={`/manage/follow-up?filter=followup-counts`}
              target={countData?.["Presented"]?.[0]?.target ?? 0}
              targetAchieved={countData?.["Presented"]?.[0]?.achieved ?? 0}
              cardTitle={t("Presented")}
              icon="PresentIcon"
              userRole={userAuth.role}
              isRecData={data}
              isMyBudget={isMyBudget}
              displayDate={displayDate}
            />
          </AccessControl>

          <AccessControl
            requiredPermissions={[
              {
                permission: "Interview",
                read: true,
              },
            ]}
            renderNoAccess={false}
          >
            <Tile
              onClick={() => countFiltersForFollowUps(10, userAuth?.userId)}
              path={`/manage/follow-up?filter=followup-counts`}
              target={countData?.["Interview"]?.[0]?.target ?? 0}
              targetAchieved={countData?.["Interview"]?.[0]?.achieved ?? 0}
              cardTitle={t("Interview")}
              icon="InterviewIcon"
              userRole={userAuth.role}
              isRecData={data}
              isMyBudget={isMyBudget}
              displayDate={displayDate}
            />
          </AccessControl>

          <AccessControl
            requiredPermissions={[
              {
                permission: "Company visit",
                read: true,
              },
            ]}
            renderNoAccess={false}
          >
            <Tile
              onClick={() => countFiltersForFollowUps(4, userAuth?.userId)}
              path={`/manage/follow-up?filter=followup-counts`}
              target={countData?.["Company visits"]?.[0]?.target ?? 0}
              targetAchieved={countData?.["Company visits"]?.[0]?.achieved ?? 0}
              cardTitle={t("Company visit")}
              icon="CompanyIcon"
              userRole={userAuth.role}
              isRecData={data}
              isMyBudget={isMyBudget}
              displayDate={displayDate}
            />
          </AccessControl>

          <AccessControl
            requiredPermissions={[
              {
                permission: "Cooperation agreement dashboard",
                read: true,
              },
            ]}
            renderNoAccess={false}
          >
            <Tile
              // onClick={() => countFiltersForFollowUps(8, userAuth?.userId)}
              // path={`/manage/follow-up?filter=followup-counts`}
              target={countData?.["Cooperation agreements"]?.[0]?.target ?? 0}
              targetAchieved={countData?.["Cooperation agreements"]?.[0]?.achieved ?? 0}
              cardTitle={t("Cooperation agreement")}
              icon="CooperationAgreementIcon"
              userRole={userAuth.role}
              isRecData={data}
              isMyBudget={isMyBudget}
              displayDate={displayDate}
            />
          </AccessControl>

          <AccessControl
            requiredPermissions={[
              {
                permission: "Vacancies",
                read: true,
              },
            ]}
            renderNoAccess={false}
          >
            <Tile
              onClick={() => countFiltersForFollowUps(8, userAuth?.userId)}
              path={`/manage/follow-up?filter=followup-counts`}
              target={countData?.["Vacancies"]?.[0]?.target ?? 0}
              targetAchieved={countData?.["Vacancies"]?.[0]?.achieved ?? 0}
              cardTitle={t("New vacancies")}
              icon="VacancyIcon"
              userRole={userAuth.role}
              isRecData={data}
              isMyBudget={isMyBudget}
              displayDate={displayDate}
            />
          </AccessControl>

          {(userAuth.role !== OFFICE_MANAGER && userAuth.role !== AREA_MANAGER) &&
            <>
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Selection",
                    read: true,
                  },
                ]}
                renderNoAccess={false}
              >
                <Tile
                  onClick={() => countFiltersForFollowUps(8, userAuth?.userId)}
                  path={`/manage/follow-up?filter=followup-counts`}
                  target={countData?.["Interview"]?.[0]?.target ?? 0}
                  targetAchieved={countData?.["Interview"]?.[0]?.achieved ?? 0}
                  cardTitle={t("Selections")}
                  icon="InterviewIcon"
                  userRole={userAuth.role}
                  isRecData={data}
                  isMyBudget={isMyBudget}
                  displayDate={displayDate}
                />
              </AccessControl>
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Starter",
                    read: true,
                  },
                ]}
                renderNoAccess={false}
              >
                <Tile
                  onClick={() => countFiltersForFollowUps(22, userAuth?.userId)}
                  path={`/manage/follow-up?filter=followup-counts`}
                  target={countData?.["Starter"]?.[0]?.target ?? 0}
                  targetAchieved={countData?.["Starter"]?.[0]?.achieved ?? 0}
                  cardTitle={t("Starter")}
                  icon="StartIcon"
                  userRole={userAuth.role}
                  isRecData={data}
                  isMyBudget={isMyBudget}
                  displayDate={displayDate}
                />
              </AccessControl>
            </>
          }
        </div>
      </div>
      {/* RECRUITER cards section*/}
      {(userAuth.role === OFFICE_MANAGER || userAuth.role === AREA_MANAGER) && data &&
        <div className="col-6">
          <AccessControl
            requiredPermissions={[
              {
                permission: "New",
                read: true,
              },
            ]}
            renderNoAccess={false}
          >
            <SubTiles
              type="new"
              onClick={countFiltersForFollowUps}
              path={`/manage/follow-up?filter=followup-counts`}
              data={countData?.["New"]}
              cardTitle={t("New")}
            />
          </AccessControl>
          <AccessControl
            requiredPermissions={[
              {
                permission: "Selection",
                read: true,
              },
            ]}
            renderNoAccess={false}
          >
            <SubTiles
              type="selection"
              onClick={countFiltersForFollowUps}
              path={`/manage/follow-up?filter=followup-counts`}
              data={countData?.["Interview"]}
              cardTitle={t("Selections")}
            />
          </AccessControl>
          <AccessControl
            requiredPermissions={[
              {
                permission: "Starter",
                read: true,
              },
            ]}
            renderNoAccess={false}
          >
            <SubTiles
              type="starter"
              onClick={countFiltersForFollowUps}
              path={`/manage/follow-up?filter=followup-counts`}
              data={countData?.["Starter"]}
              cardTitle={t("Starter")}
            />
          </AccessControl>
        </div>
      }
    </>
  );
};

export default TargetDashboard;
