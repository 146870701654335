import { FollowUpProps, TaskProps } from "./TaskAnnotations";


export const getCurrentTime = (): string => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
};

export const initialTaskData: TaskProps = {
    id: null,
    office: null,
    date: new Date(),
    time: getCurrentTime(),
    followUpFor: null,
    regarding: null,
    taskType: null,
    info: "",
    hasChild: false,
    vacancy: null,
};
export const initialFollowUpData: FollowUpProps = {
    id: null,
    followedBy: null,
    feedback: "",
    date: new Date(),
    time: null,
    completed: false,
    alwaysShow: false,
    duration: null
};

type DateRange = {
    startDate: Date;
    endDate: Date;
};

type RangeType = "month" | "week";

export function getStartAndEndDate(type: RangeType, year: string | number, value: string | number): DateRange {
    // Convert year and value to numbers in case they are strings
    const numericYear = typeof year === "string" ? parseInt(year, 10) : year;
    const numericValue = typeof value === "string" ? parseInt(value, 10) : value;

    if (type === "month") {
        // For month, 'value' represents the month (1-12)
        const startDate = new Date(numericYear, numericValue - 1, 1);
        const endDate = new Date(numericYear, numericValue, 0);
        return { startDate, endDate };
    } else if (type === "week") {
        // For week, 'value' represents the ISO week number
        const date = new Date(numericYear, 0, 1);
        const dayOfWeek = date.getUTCDay(); // Get the weekday (0-6)
        const daysOffset = (numericValue - 1) * 7 - (dayOfWeek <= 4 ? dayOfWeek - 1 : dayOfWeek + 6);

        date.setDate(date.getDate() + daysOffset);
        const startDate = date;

        const endDate = new Date(date);
        endDate.setDate(date.getDate() + 6);
        return { startDate, endDate: endDate };
    }

    throw new Error("Invalid type. Expected 'month' or 'week'.");
}