import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LabelField from "../atoms/LabelField";
import "static/css/calender.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { useRef } from "react";
import ReactDOM from "react-dom";

interface CalenderProps {
  onChange: (date: Date | null, name: string) => void;
  selectedDate?: Date | null;
  label?: string;
  isMandatory?: boolean;
  name?: string;
  error?: string | undefined | null;
  minDate?: Date | null;
  maxDate?: Date | null;
  isDisabled?: boolean;
  placeHolder?: string;
  showMonthDropdown?: boolean;
  showYearDropdown?: boolean;
  filterDate?: (date: Date) => boolean;
}

const Calender: React.FC<CalenderProps> = ({
  onChange,
  selectedDate,
  label,
  isMandatory = false,
  name = "",
  error,
  minDate = null,
  maxDate = null,
  isDisabled = false,
  placeHolder = "dd-mm-yyyy",
  filterDate,
  showMonthDropdown = true,
  showYearDropdown = true,
}) => {
  const datePickerRef = useRef<ReactDatePicker | null>(null);
  const popperContainer = ({ children }: any) => {
    return ReactDOM.createPortal(children, document.body);
  };

  const handleIconClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };
  return (
    <>
      <LabelField title={label} isMandatory={isMandatory} />
      {/* <div className="position-relative"> */}
      <ReactDatePicker
        selected={selectedDate}
        onChange={(date) => onChange(date, name || "")}
        showMonthDropdown={showMonthDropdown}
        showYearDropdown={showYearDropdown}
        dropdownMode="select"
        dateFormat="dd-MM-yyyy"
        className="form-control field-shadow ps-2 position-relative date-picker-input"
        placeholderText={placeHolder}
        minDate={minDate ? new Date(minDate.getTime()) : null}
        maxDate={maxDate ? new Date(maxDate.getTime()) : null}
        calendarStartDay={1}
        name={name}
        closeOnScroll={true}
        fixedHeight
        autoComplete="off"
        ref={datePickerRef}
        disabled={isDisabled}
        filterDate={filterDate}
        popperPlacement="bottom-start"
        popperClassName="custom-datepicker-popper"
        title={selectedDate ? selectedDate.toLocaleDateString() : ""}
      />

      <div className="input-group-append calender-icon position-absolute ">
        <span
          className="input-group-text bg-transparent border-0"
          onClick={handleIconClick}
        >
          <FontAwesomeIcon icon={faCalendarAlt} className="fa-lg" />
        </span>
      </div>
      {/* </div> */}
      <div className="height-20" style={{ marginBottom: "0.5vw" }}>
        {error && <span className="text-danger mt-2">{error}</span>}
      </div>
    </>
  );
};

export default Calender;