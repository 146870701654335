import React, { useState, useEffect } from "react";
import "../../../../src/static/css/common.css";

interface TimePickerProps {
    setHourMin: (value: string, type: string, index: number, value2: number) => void;
    type: string;
    index: number;
    value: string;
    hour: string;
    minute: string;
    minuteStep?: number;
    isDisabled?: boolean;
}

const TimePicker: React.FC<TimePickerProps> = ({ setHourMin, type, index, value, minuteStep = 1, isDisabled = false }) => {
    const [selectedHour, setSelectedHour] = useState("hh");
    const [selectedMinute, setSelectedMinute] = useState("mm");

    useEffect(() => {
        if (value) {
            setSelectedHour(value.split(":")[0]);
            setSelectedMinute(value.split(":")[1]);
        }
    }, [value]);

    const onHourChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedHour(e.target.value);
        setHourMin(`${e.target.value}:${selectedMinute}`, type, index, 99999);
    };

    const onMinuteChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedMinute(e.target.value);
        setHourMin(`${selectedHour}:${e.target.value}`, type, index, 99999);
    };

    return (
        <div>
            <div className="d-inline-block hourWrapper">
                <select
                    value={selectedHour}
                    onChange={onHourChange}
                    className={`field-shadow ps-2 rounded-3 ${selectedHour === "hh" ? "placeholder-color" : "value-color"}`}
                    disabled={isDisabled}
                >
                    <option value="">hh</option>
                    {Array.from({ length: 24 }, (_, i) => (
                        <option key={i} value={String(i).padStart(2, "0")} className="value-color">
                            {String(i).padStart(2, "0")}
                        </option>
                    ))}
                </select>
            </div>
            <div className="d-inline-block colonWrapper">:</div>
            <div className="d-inline-block minuteWrapper">
                <select
                    value={selectedMinute}
                    onChange={onMinuteChange}
                    className={`field-shadow ps-2 rounded-3 ${selectedMinute === "mm" ? "placeholder-color" : "value-color"}`}
                    disabled={isDisabled}
                >
                    <option value="">mm</option>
                    {Array.from({ length: Math.round(60 / minuteStep) }, (_, i) => (
                        <option key={i * minuteStep} value={String(i * minuteStep).padStart(2, "0")} className="value-color">
                            {String(i * minuteStep).padStart(2, "0")}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
};

export default TimePicker;
