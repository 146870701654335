import "../functionsProfile/function.css";
import { Link, useNavigate } from "react-router-dom";
import Button from "components/common/atoms/Button";
import { useEffect, useState } from "react";
import { ApiCall } from "components/common/services/ApiServices";
import ChildComponent from "./ChildComponent";
import CustomNotify from "components/common/atoms/CustomNotify";
import * as ENDPOINTS from "../../../../routes/ApiEndpoints";
import Title from "components/common/atoms/Title";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";

interface WorkTypeName {
  id: number | null;
  name: string;
  value: number | null;
}

const WorkTypeExtra: React.FC = () => {
  const [formData, setFormData] = useState<WorkTypeName[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const response = await ApiCall.getService(
      ENDPOINTS.GET_WORK_TYPE_COEFFICIENT,
      "GET",
      "company-creation",
      true
    );
    if (response.status === 200) {
      setFormData(response.data);
    }
  };

  const changehandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { name, value } = event.target as HTMLInputElement;
    const regex = /^(\d{0,2})(,\d{0,4})?$/;
    let matches = regex.exec(value);
    if (matches) {
      const newFields = [...formData];
      newFields[index] = {
        ...newFields[index],
        [name]: value,
      };

      setFormData(newFields);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const response = await ApiCall.service(
      ENDPOINTS.STORE_WORK_TYPE_COEFFICIENT,
      "POST",
      formData,
      false,
      "company-creation"
    );
    if (response.status === 200) {
      CustomNotify({ type: "success", message: response.msg });
      navigate("/config/settings");
    }
  };
  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Work type extra",
          create: true,
          update: true,
          read: true,
        },
      ]}
      actions={true}
      strict={false}
      renderNoAccess={true}
    >
      <Title title={t("Work type extra")} />
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12 workTypeTable">
            <div className="table-responsive ">
              <table className="table table-hover">
                <thead>
                  <tr className="TableHeader">
                    <th className="ps-4 border-0" style={{ width: "20%" }}>
                      {t("Work type extra")}
                    </th>
                    <th className="border-0">{t("Factor")}</th>
                  </tr>
                  <tr>
                    <th className="border-0" style={{ height: "0.5vw" }} />
                  </tr>
                </thead>
                <tbody>
                  {formData &&
                    formData.map((item: any, index: number) => (
                      <ChildComponent
                        key={index}
                        data={item}
                        id={index}
                        changeHandler={changehandler}
                      />
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="row backPadding">
          <div className="col-md-6 align-self-center">
            <Link
              to="/config/settings"
              className="back-btn text-decoration-underline"
            >
              {t("Back")}
            </Link>
          </div>
          <AccessControl
            requiredPermissions={[
              {
                permission: "Work type extra",
                create: true,
                update: true,
              },
            ]}
            actions={true}
            strict={false}
          >
            <div className="col-md-6 text-end">
              <Button
                title={t("Submit")}
                type="submit"
                className="form-button shadow-none"
              />
            </div>
          </AccessControl>
        </div>
      </form>
    </AccessControl>
  );
};

export default translate(WorkTypeExtra);
