import React from "react";
import ModalPopup from "components/common/atoms/ModalPopup";
import Button from "components/common/atoms/Button";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import { t } from "components/CentralDataMangement/translation/Translation";

interface AuthenticationModalProps {
  show: boolean;
  handleModalClose: (type: string) => void;
  checkAuthentication: (event: React.FormEvent) => void;
  state: any;
  errors: { [key: string]: string };
  changeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const AuthenticationModal: React.FC<AuthenticationModalProps> = ({
  show,
  handleModalClose,
  checkAuthentication,
  state,
  errors,
  changeHandler,
}) => {
  return (
    <ModalPopup
      show={show}
      onHide={handleModalClose}
      title={t("Sign as employer")}
      body={
        <form onSubmit={checkAuthentication}>
          <LabelWithInputField
            handleChange={changeHandler}
            label={t("Email")}
            type="text"
            isMandatory={true}
            id="email"
            name="email"
            value={state.email}
            error={errors.email}
            autoComplete="off"
          />
          <LabelWithInputField
            handleChange={changeHandler}
            isMandatory={true}
            label={t("Password")}
            type="password"
            id="password"
            name="password"
            value={state.password}
            error={errors.password}
            autoComplete="new-password"
          />
          <Button
            type="submit"
            title={t("Authenticate")}
            className="btn float-end form-button shadow-none rounded-3 me-2"
          />
        </form>
      }
      className="modal-lg"
    />
  );
};

export default AuthenticationModal;
