import { ApiCall } from "components/common/services/ApiServices";
import {
  GET_PLANNING_COEFF_DETAILS,
  SET_DROPDOWN_DATA,
} from "../../annotation/PlanningConstants";
import { COMPANY_MICROSERVICE } from "Constants";

export const fetchCoeffDetails = async (
  type: "coeff", // Dynamic type handling
  id: number | null | undefined,
  dispatch: React.Dispatch<any>
) => {
  const data = { type, id };

  if (id) {
    const response = await ApiCall.service(
      `${GET_PLANNING_COEFF_DETAILS}`,
      "POST",
      data,
      false,
      COMPANY_MICROSERVICE
    );

    if (response.status === 200) {
      const responseData = response.data;
      if (Object.keys(responseData).length > 0) {
        dispatch({ type: SET_DROPDOWN_DATA, payload: responseData });
      }
    }
  }
};
