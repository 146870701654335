import React from "react";

interface NavItem {
  label: string;
  fieldName: string;
  value: any;
  icon?: JSX.Element; // Optional icon for the button
}

interface NavBarAtomProps {
  navItems: NavItem[]; // Array of nav items
  onNavItemClick: (fieldName: string, value: any) => void; // Function to handle button click in parent
  logo?: JSX.Element; // Optional logo or brand icon
  className?: string; // Optional custom class for styling
  activeItem: any;
}

const NavBar: React.FC<NavBarAtomProps> = ({
  navItems,
  onNavItemClick,
  logo,
  className = "",
  activeItem,
}) => {
  return (
    <nav className={`navbar navbar-expand-lg py-0 ${className}`}>
      {logo && <div className="navbar-brand">{logo}</div>}
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
          {navItems.map((item, idx) => (
            <li className="nav-item" key={idx}>
              <button
                type="button"
                className={`link ${
                  activeItem?.tabFieldName === item?.fieldName &&
                  activeItem?.tabFieldValue === item?.value
                    ? "active nav-subtab"
                    : "nav-subtab"
                } text-decoration-none border-0 bg-transparent ps-0`}
                onClick={() => onNavItemClick(item.fieldName, item.value)}
                style={{ cursor: "pointer" , marginRight:"0.8vw"}}
              >
                {item.icon && <span className="me-2">{item.icon}</span>}
                {item.label}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;
