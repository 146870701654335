import { t } from "components/CentralDataMangement/translation/Translation";
import { candidateFormData } from "./CandidateAnnotations";
import { E164Number } from "libphonenumber-js";

export const CandidateIntialState: candidateFormData = {
  tabDetails: [
    {
      id: "general",
      title: t("General"),
      showStatus: false,
      error: false,
      draft: false,
    },
    {
      id: "studies",
      title: t("Studies"),
      showStatus: false,
      error: false,
      draft: false,
    },
    {
      id: "workExperience",
      title: t("Experience"),
      showStatus: false,
      error: false,
      draft: false,
    },
    {
      id: "jobProfile",
      title: t("Desired job"),
      showStatus: false,
      error: false,
      draft: false,
    },
    {
      id: "employeeDocs",
      title: t("Documents"),
      showStatus: false,
      error: false,
      draft: false,
    },
    {
      id: "internalInfo",
      title: t("Internal info"),
      showStatus: false,
      error: false,
      draft: false,
    },
  ],
  profileCard: {
    cvFileId: null,
    cvFileName: "",
    cvPath: "",
    picFileId: null,
    picFileName: "",
    picFilePath: "",
  },
  general: {
    firstName: "",
    lastName: "",
    dateOfBirth: null,
    mobNumber: "" as E164Number, // Ensure this is an empty string cast to E164Number
    ssn: "",
    email: "",
    placeOfBirth: "",
    countryOfBirth: null,
    domCheckbox: true,
    street: "",
    number: "",
    city: null,
    country: null,
    zipCode: "",
    box: "",
    nationality: [],
    domStreet: "",
    domNumber: "",
    domCity: null,
    domCountry: null,
    domZipCode: "",
    domBox: "",
    internalEmployee: 0,
    hiddenEmployee: 0,
    contractReady: 0,
    age: "",
    office: null,
    consultant: null,
    iban: "",
    bic: "",
    electronicDoc: 1,
    esign: 1,
    contractSeasonalWorker: 0,
    score: 0,
    userId: null,
    candidateStatus: 0,
    formStatus: 0,
    contactPreference: [],
    selectionGroup: [],
    cityOther: "",
    domCityOther: "",
    sourceId: 1,
    payRoll: 0,
    consentToTerms: 0,
  },
  emergencyContact: [
    {
      id: null,
      contactFirstName: "",
      contactLastName: "",
      contactEmail: "",
      contactPhone: "" as E164Number,
    },
  ],
  other: {
    civilStatus: null,
    dependentSpouse: null,
    dependentChildren: "0",
    gender: null,
    educationalLevel: null,
    languageOfDocuments: null,
    drivingLicence: [],
    transport: [],
    withHoldingTax: null,
    additional: null,
    workType: null,
  },

  studies: [
    {
      id: null,
      diploma: null,
      school: "",
      study: "",
      from: null,
      to: null,
      info: "",
      completed: 1,
      certificateId: null,
      fileName: "",
      filePath: "",
    },
  ],
  workExperience: [
    {
      id: null,
      title: "",
      domain: null,
      function: null,
      employer: "",
      description: "",
      exp_from: null,
      exp_to: null,
      referenceId: null,
      reference: 0,
      fName: "",
      lName: "",
      refEmail: "",
      refPhone: "" as E164Number,
      referenceTaken: "",
      referenceInfo: "",
      reasonForDeparture: null,
      reason: "",
      workExperience: 0,
    },
  ],
  jobProfile: {
    desiredSector: [],
    desiredJobDomain: [],
    desiredFunctions: [],
    infodesiredJobs: "",
    maximumDistance: null,
    desiredRegimen: [],
    preferredEmployment: null,
    experience: null,
    salaryExpectation: null,
    minimumGrossSalary: "",
    payFrom: "",
    payTo: "",
    niceToHave: "",
    lookingForWork: null,
    date: null,
    functionAliasName: "",
  },
  competences: {
    competence: [],
    childCompetences: {},
  },
  certificates: [],
  internalInfo: {
    active: 0,
    inflowChannel: null,
    externalReference: "",
    selectionPartner: null,
    blocked: 0,
    reasonForBlocking: "",
    blockedBy: null,
    infoBlocking: "",
    internalInfo: "",
    studentCertificate: null,
    studentYear: "2024",
    studentRemainingQuota: "",
    studentInfo: "",
    greenCertificate: null,
    greenYear: "2024",
    greenRemainingQuota: "",
    greenInfo: "",
    horecaCertificate: null,
    horecaYear: "2024",
    horecaRemainingQuota: "",
    horecaInfo: "",
  },
  fieldError: {
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    mobNumber: "",
    ssn: "",
    email: "",
    placeOfBirth: "",
    countryOfBirth: "",
    cvFileId: "",
    picFileId: "",
    domCheckbox: "",
    street: "",
    number: "",
    city: "",
    country: "",
    zipCode: "",
    box: "",
    nationality: "",
    domStreet: "",
    domNumber: "",
    domCity: "",
    domCountry: "",
    domZipCode: "",
    domBox: "",
    civilStatus: "",
    desiredSector: "",
    desiredJobDomain: "",
    desiredFunctions: "",
    infodesiredJobs: "",
    maximumDistance: "",
    desiredRegimen: "",
    preferredEmployment: "",
    experience: "",
    salaryExpectation: "",
    minimumGrossSalary: "",
    competences: "",
    iban: "",
    bic: "",
    office: "",
    payError: "",
    externalReference: "",
    drivingLicence: "",
    cityOther: "",
  },

  dropDowndata: {
    countryList: [],
    citiesList: [],
    nationalityList: [],
    civilStatusList: [],
    dependentSpouseList: [],
    genderList: [],
    educationalLevelList: [],
    languageOfDocumentsList: [],
    drivingLicenceList: [],
    transportList: [],
    withHoldingTaxList: [],
    additionalList: [],
    diplomaList: [],
    jobDomainList: [],
    functionList: [],
    categoriesList: [],
    sectorList: [],
    maxDistanceList: [],
    desiredRegimeList: [],
    preferredEmploymentList: [],
    experienceList: [],
    salaryExpectationList: [],
    attestTypeList: [],
    groupList: [],
    attestTypeAndGroupLinking: [],
    candidateLevelList: [],
    domainFunctionLinking: [],
    officesList: [],
    cosultantsList: [],
    inflowChannelList: [],
    selectionPartnerList: [],
    blockedByPersonList: [],
    reasonForDepartureList: [],
    contactPreferenceList: [],
    lookingForWorklist: [],
    selectionGroupList: [],
  },

  emergencyContactErrors: [
    {
      contactFirstName: "",
      contactLastName: "",
      contactEmail: "",
      contactPhone: "",
    },
  ],
  studiesErrors: [
    {
      diploma: "",
      school: "",
      study: "",
      from: "",
      to: "",
      certificateId: "",
    },
  ],
  workExperienceErrors: [
    {
      title: "",
      domain: "",
      function: "",
      employer: "",
      description: "",
      exp_from: "",
      exp_to: "",
      reference: "",
      fName: "",
      lName: "",
      refEmail: "",
      refPhone: "",
      referenceTaken: "",
      referenceInfo: "",
      reasonForDeparture: "",
      reason: "",
    },
  ],
  certificateErrors: [
    {
      group: "",
      attestType: "",
      documentNo: "",
      valid: "",
      validUntill: "",
      empCertificateId: "",
    },
  ],
  documentChecklist: [],
  workPermit: {
    europeCitizen: null,
    perFrom: null,
    perTo: null,
    permitFileId: null,
    fileName: "",
    filePath: "",
  },
  permitErrors: {
    perFrom: "",
    perTo: "",
    permitFileId: "",
  },
  checklistErrors: [
    {
      checkdocumentId: "",
    },
  ],
};
