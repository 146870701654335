import { ReactElement } from "react";
import BackOverviewIcon from "static/images/BackOverviewIcon";
import FollowUpIcon from "static/images/FollowUpIcon";
import LinkIcon from "static/images/LinkIcon";
import VacancyIcon from "static/images/VacancyIcon";

interface ButtonItem {
  label?: string;
  icon?: ReactElement;
  path?: string;
}

export const actionButtons: ButtonItem[] = [
  { label: "Overview", icon: <BackOverviewIcon />, path: "" },
  { label: "Cooperation agreement", icon: <LinkIcon />, path: "/manage/cooperation-agreement?filter=1" },
  { label: "Vacancies", icon: <VacancyIcon />, path: "/manage/vacancies?filter=1" }
];
