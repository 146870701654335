import React from "react";
import { WeeklyData } from "../Annotations";

interface LabelKeyPair {
  label: string;
  key: string;
}

interface WeeklyReportTableProps {
  rows: LabelKeyPair[];
  data: Record<string, WeeklyData>;
}

const WeeklyReportTable: React.FC<WeeklyReportTableProps> = ({
  rows,
  data,
}) => {
  // const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun', 'Total'];
  const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Total"];

  return (
    <table className="table table-bordered">
      <thead>
        <tr className="TableHeader">
          <th style={{ width: "25%" }}></th>
          {days.map((day) => (
            <th key={day} className="text-center">
              {day}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map(({ label, key }) => (
          <tr key={key}>
            <td className="align-middle">{label}</td>
            {days.map((day) => (
              <td key={day} className="text-center align-middle">
                {data?.[key]?.[day.toLowerCase() as keyof WeeklyData]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default WeeklyReportTable;
