import React, { useEffect, useState } from "react";
import { ApiCall } from "components/common/services/ApiServices";
import { Link } from "react-router-dom";
import Button from "components/common/atoms/Button";
import Pagination from "components/common/atoms/Pagination";
import CustomNotify from "components/common/atoms/CustomNotify";
import * as ENDPOINTS from "routes/ApiEndpoints";
import Title from "components/common/atoms/Title";
import { t } from "components/CentralDataMangement/translation/Translation";
import Reset from "static/images/Reset";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import { Spinner } from "react-bootstrap";
import { COMPANY_MICROSERVICE } from "Constants";
import EditIcon from "static/images/EditIcon";
import ResetBtn from "components/common/atoms/ResetBtn";
import SpinnerWrapper from "components/common/atoms/SpinnerWrapper";
import NoRecords from "components/common/atoms/NoRecords";

interface manageShiftData {
  shiftId: number;
  regimeName: string;
  shiftName: string;
  timeTableName: string;
}

interface searchData {
  timeTableName: string;
}

const ManageShifts: React.FC = () => {
  //initiliaze the formData state for search fields
  const [searchData, setSearchData] = useState<searchData>({
    timeTableName: "",
  });

  const [shifts, setShifts] = useState([] as manageShiftData[]);
  const [loading, setLoading] = useState(true);

  //state for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  // const [isFilter, setIsFilter] = useState<boolean>(true); // Example boolean state

  useEffect(() => {
    fetchShiftData();
  }, []);

  //API call for to fetch email logs
  const fetchShiftData = async (searchData = {}, page = 1) => {
    const requestData = {
      ...searchData,
      page: page,
    };

    const response = await ApiCall.service(
      ENDPOINTS.MANAGE_SHIFT_DETAILS,
      "POST",
      requestData,
      false,
      COMPANY_MICROSERVICE
    );

    if (response.status === 200) {
      const result = response.data;
      setShifts(result["shiftDetails"]);
      setTotalPages(result["totalPages"]);
      setLoading(false);
    }
  };

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setSearchData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  //changehandler for search fields
  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    setSearchData((prevData) => ({
      ...prevData,
      [fieldName]: selectedOption,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (searchData.timeTableName.trim() === "") {
      CustomNotify({
        type: "warning",
        message: t("Search input not provided") + ".",
      });
      fetchShiftData();
    } else {
      fetchShiftData(searchData, 1);
    }
  };

  const handleReset = () => {
    setSearchData({
      timeTableName: "",
    });
    setCurrentPage(1);
    fetchShiftData();
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    fetchShiftData(searchData, newPage);
  };

  return (
    <>
      <Title title={t("Manage shifts")} />
      <div className="row search-bar">
        <div className="col-8">
          <LabelWithInputField
            isMandatory={false}
            name="timeTableName"
            handleChange={handleFieldChange}
            value={searchData.timeTableName}
            id="timeTableName"
            label={t("Table name")}
            placeholder={t("Table name")}
          />
        </div>
        <div className="col-4 mt-34">
          <div className="d-flex align-items-center">
            <ResetBtn handleResetClick={handleReset} />
            <Button
              title={t("Search")}
              type="submit"
              icon={faMagnifyingGlass}
              handleClick={handleSubmit}
              className="form-button shadow-none search-btns position-relative text-start"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="table-responsive tableSection">
            <table className="table table-hover">
              <thead>
                <tr className="TableHeader">
                  <th className="ps-lg-4 align-middle" style={{ width: "25%" }}>
                    {t("Regime")}
                  </th>
                  <th className="align-middle" style={{ width: "25%" }}>
                    {t("Shift")}
                  </th>
                  <th className="align-middle" style={{ width: "25%" }}>
                    {t("Table name")}
                  </th>
                  <th className="align-middle">{t("Actions")}</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <SpinnerWrapper headerLength={7} />
                ) : (
                  <>
                    {shifts && shifts.length > 0 ? (
                      shifts.map((shift) => (
                        <tr
                          key={shift.shiftId}
                          className="border-bottom mb-3 box-shadow align-middle"
                        >
                          <td
                            data-label={t("Regime")}
                            className="align-middle ps-lg-4"
                          >
                            {shift.regimeName}
                          </td>
                          <td data-label={t("Shift")} className="align-middle">
                            {shift.shiftName}
                          </td>
                          <td
                            data-label={t("Table name")}
                            className="align-middle"
                          >
                            {shift.timeTableName}
                          </td>
                          <td className="table-action-icons px-2 align-middle">
                            <Link
                              to={`/shift/edit/${shift.shiftId}`}
                              className="btn p-0 border-0 me-2"
                              title={t("Edit")}
                            >
                              <EditIcon />
                            </Link>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <NoRecords headerLength={7} />
                    )}
                  </>
                )}
              </tbody>
            </table>
            {totalPages > 1 && (
              <div className="pagination justify-content-center align-items-center">
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={(newPage) => handlePageChange(newPage)}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="row backPadding">
        <div className="col-md-6 align-self-center">
          <Link
            to="/config/settings"
            className="back-btn text-decoration-underline"
          >
            {t("Back")}
          </Link>
        </div>
      </div>
    </>
  );
};

export default ManageShifts;
