import { t } from "components/CentralDataMangement/translation/Translation";
import { initialTimeStates } from "components/common/utlis/PlanningUtility";
import {
  BILLING,
  COST_CENTER,
  GENERAL,
  INTERNAL_INFO,
  REMUNERATION,
  TIME_TABLE,
} from "./PlanningConstants";

export const PlanningInitialState = {
  tabDetails: [
    {
      id: GENERAL,
      title: t("General"),
      showStatus: false,
      error: false,
      draft: false,
    },
    {
      id: REMUNERATION,
      title: t("Remuneration"),
      showStatus: false,
      error: false,
      draft: false,
    },
    {
      id: BILLING,
      title: t("Billing"),
      showStatus: false,
      error: false,
      draft: false,
    },
    {
      id: TIME_TABLE,
      title: t("Time table"),
      showStatus: false,
      error: false,
      draft: false,
    },
    {
      id: COST_CENTER,
      title: t("Cost center"),
      showStatus: false,
      error: false,
      draft: false,
    },
    {
      id: INTERNAL_INFO,
      title: t("Internal info"),
      showStatus: false,
      error: false,
      draft: false,
    },
  ],
  basicDetails: {
    office: null,
    contractNumber: "",
    customer: null,
    branch: null,
    collegue: null,
    from: null,
    to: null,
    planningProfile: null,
    employeeType: null,
    partiarCommittee: null,
    function: "",
    reason: null,
    attempt: 0,
  },
  generalDetails: {
    street: "",
    number: "",
    city: null,
    country: null,
    zipCode: "",
    box: "",
    cityOther: "",
    fileId: null,
    filePath: "",
    fileName: "",
    ccCardCurrentMonth: "",
    ccCardNextMonth: "",
    rszCategory: null,
    workRegimeBV: null,
    progresWork: null,
    empChar: "",
    revenueToConsultant: null,
    eSignStatus: "nvt",
    eSignCancelStatus: "nvt",
    printUZM: 0,
    printCustomer: 0,
  },
  remenurationDetails: {
    payProfile: null,
    grossPerHr: "",
    relocationAllowance: null,
    allowanceInfo: "",
    catchUpRest: null,
    typeOfTransport: null,
    numberOfKm: "",
    amntOftravel: "",
    mtcTotalValue: "",
    mtcEmpPart: "",
    mtcMinHr: "",
    perfCodes: [],
    automaticCodes: [],
  },
  billingDetails: {
    commercialProfile: null,
    coeffType: null,
    dimonaInvoice: 0,
    invoiceEcho: 0,
    coefficient: "",
    payrollCoefficient: "",
    reduceCoefficient: "",
    selectionCoefficient: "",
    relocationCoeff: "",
    dimonaCost: "",
    echoCheqCoeff: "",
    bankHoliday: null,
    holidayCoeff: "",
    mealVochrCoeff: "",
    disease: null,
    standardVatRate: null,
    deviatingCoefficients: [],
  },
  timeTableDetails: {
    trailPeriod: "",
    regime: null,
    shift: null,
    workType: null,
    shiftName: "",
    timeTableType: null,
    timeTable: initialTimeStates,
    effectiveHrsFullTime: "40,00",
    avgHrsFullTime: "38,00",
    effectiveHrsAvg: "",
    paidAdv: "",
    unPaidAdv: "",
  },
  costCenterDetails: {
    costCenterRenew: 0,
    costCenters: [],
  },
  internalInfoDetails: {
    contractLate: 0,
    contractTerminated: 0,
    reasonForLate: "",
    reasonForDiscontinue: "",
    selectionPartner: null,
    segment: null,
    internalInfo: "",
    allocations: [],
    formStatus: 0,
  },
  dropDowndata: {
    officeOptions: [],
    pcOptions: [],
    employeeTypeOptions: [],
    shiftOptions: [],
    regimeOptions: [],
    reasonOptions: [],
    branchOptions: [],
    collegueOptions: [],
    countryOptions: [],
    cityOptions: [],
    rszCategoryOptions: [],
    exemptionOptions: [],
    progressiveOptions: [],
    consultantOptions: [],
    catchUpRestOptions: [],
    relocationOptions: [],
    bankHolidayOptions: [],
    diseaseOptions: [],
    vatOptions: [],
    selectionPartnerOptions: [],
    segmentOptions: [],
    automationTypeOptions: [],
    premiumCodes: [],
    payRollCodes: [],
    devCoefficients: [],
    commercialProfileOptions: [],
    pcCommercialProfileOptions: [],
    timeTableOptions: [],
    payProfileOptions: [],
    pcPayProfileOptions: [],
    transportTypeOptions: [],
  },
  errors: {
    fileId: "",
    office: "",
    customer: "",
    branch: "",
    collegue: "",
    shift: "",
    attempt: "",
    function: "",
    employeeType: "",
    reason: "",
    from: "",
    to: "",
  },
};
