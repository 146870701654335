import { dropDownList } from "./Interface";

export const createFilterConfig = (dropDownList: dropDownList) => {
  return [
    {
      name: "office",
      options: dropDownList.offices,
      fieldType: "multiSelect",
      placeholder: "Office",
      filterType: "search",
    },
    {
      name: "firstName",
      fieldType: "text",
      placeholder: "First name",
      filterType: "search",
    },
    {
      name: "lastName",
      fieldType: "text",
      placeholder: "Last name",
      filterType: "search",
    },
    {
      name: "street",
      fieldType: "text",
      placeholder: "Street",
      filterType: "search",
    },
    {
      name: "postalCode",
      fieldType: "text",
      placeholder: "Postal code",
      filterType: "search",
    },
    {
      name: "email",
      fieldType: "text",
      placeholder: "Email",
      filterType: "search",
    },
    {
      name: "phone",
      fieldType: "text",
      placeholder: "Phone number",
      filterType: "search",
    },
    {
      name: "canStatus",
      options: dropDownList.statuses,
      fieldType: "singleSelect",
      placeholder: "Status",
      filterType: "search",
    },
    {
      name: "from",
      fieldType: "date",
      placeholder: "From",
      group: "one",
      label: "From",
      filterType: "search",
    },
    {
      name: "to",
      fieldType: "date",
      placeholder: "To",
      group: "one",
      label: "To",
      filterType: "search",
    },
    {
      name: "ssn",
      fieldType: "text",
      placeholder: "SSN number",
      filterType: "advSearch",
      label: "SSN number",
      autoComplete: "off",
    },
    {
      name: "iban",
      fieldType: "text",
      placeholder: "IBAN",
      filterType: "advSearch",
      label: "IBAN",
      autoComplete: "off",
    },
    {
      name: "function",
      options: dropDownList.functions,
      fieldType: "singleSelect",
      placeholder: "Function",
      filterType: "advSearch",
      label: "Function",
    },
    {
      name: "regime",
      options: dropDownList.regimes,
      fieldType: "singleSelect",
      placeholder: "Regime",
      filterType: "advSearch",
      label: "Regime",
    },
    {
      name: "consultant",
      options: dropDownList.consultants,
      fieldType: "singleSelect",
      placeholder: "Consultant",
      filterType: "advSearch",
      label: "Consultant",
    },
    {
      name: "country",
      fieldType: "text",
      placeholder: "Country",
      filterType: "advSearch",
      label: "Country",
    },
    {
      name: "city",
      fieldType: "text",
      placeholder: "City",
      filterType: "advSearch",
      label: "City",
    },
    {
      name: "extraRef",
      fieldType: "text",
      placeholder: "Extref",
      filterType: "advSearch",
      label: "Extref",
    },
    {
      name: "functnAliasName",
      fieldType: "text",
      placeholder: "Function alias name",
      filterType: "advSearch",
      label: "Function alias name",
    },
    {
      name: "selectionGroup",
      options: dropDownList.selectionGroups,
      fieldType: "singleSelect",
      placeholder: "Selection group",
      filterType: "advSearch",
      label: "Selection group",
    },
    {
      name: "source",
      options: dropDownList.sources,
      fieldType: "singleSelect",
      filterType: "advSearch",
      label: "Source",
    },
    {
      label: "Created at",
      name: "createdAt",
      fieldType: "date",
      placeholder: "Created at",
      filterType: "advSearch",
    },
  ];
};
