import { t } from "components/CentralDataMangement/translation/Translation";
import { CooAgreementDetails, JobInterviewDetails, StarterDetails, VacancyDetails } from "../Annotations";
import TableTitle from "components/common/atoms/TableTitle";
import Tick from "static/images/Tick";

interface CooAgreementTableProps {
    data?: CooAgreementDetails[];
    tableTitle?: string;
}
const CooAgreementTable: React.FC<CooAgreementTableProps> = ({ data, tableTitle }) => {

    return (
        <>
            {tableTitle &&
                <TableTitle title={tableTitle} />
            }
            <table className="table table-hover table-bordered targetTable">
                <thead>
                    <tr className="TableHeader">
                        <th>{t("Date")}</th>
                        <th>{t("Company")}</th>
                        <th>{t("Created")}</th>
                        <th>{t("Sent")}</th>
                        <th>{t("Signed")}</th>
                    </tr>
                </thead>
                <tbody>
                    {data?.length && data?.length > 0 ? (
                        data?.map((cooAgreement: CooAgreementDetails, index) => (
                            <tr key={index}>
                                <td>{cooAgreement.date}</td>
                                <td>{cooAgreement.company}</td>
                                <td>{cooAgreement.created_at}</td>
                                <td className="table-action-icons align-middle">{cooAgreement?.sent ? <Tick /> : ""}</td>
                                <td className="table-action-icons align-middle">{cooAgreement?.signed ? <Tick /> : ""}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={5}>
                                <div className="text-danger text-center">
                                    {t("No data found")}
                                </div>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </>
    );
}

export default CooAgreementTable;