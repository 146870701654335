import { OptionProps } from "components/common/utlis/TypeAnnotations";

interface dropDownList  {
  salesDropdown: OptionProps[],
  officeDropdown: OptionProps[],
  statusDropdown: OptionProps[],
  unitDropdown: OptionProps[],
  agreementTypeDropdown: OptionProps[]
}
export const createFilterConfig = (dropDownList:dropDownList) => {
  return [
    {
      name: "office",
      options: dropDownList.officeDropdown,
      fieldType: "multiSelect",
      placeholder: "Office",
      filterType: 'search'
    },
    {
      name: "company",
      fieldType: "text",
      placeholder: "Company",
      filterType: 'search'
    },
    {
      name: "unit",
      options: dropDownList.unitDropdown,
      fieldType: "singleSelect",
      placeholder: "Unit",
      filterType: 'search'
    },
    {
      name: "agreementType",
      options: dropDownList.agreementTypeDropdown,
      fieldType: "singleSelect",
      placeholder: "Type",
      filterType: 'search'
    },
    {
      name: "sales",
      options: dropDownList.salesDropdown,
      fieldType: "multiSelect",
      placeholder: "Sales",
      filterType: 'search'
    },
    {
      name: "status",
      options: dropDownList.statusDropdown,
      fieldType: "singleSelect",
      placeholder: "Status",
      filterType: 'search'
    },
  ];
};
