import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { MANAGE_CHECKLIST } from "routes/ApiEndpoints";
import { ApiCall } from "components/common/services/ApiServices";
import EditIcon from "static/images/EditIcon";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import ViewIcon from "static/images/ViewIcon";
import { OptionProps, Option } from "components/common/utlis/TypeAnnotations";
import { mapToSelect } from "components/common/utlis/MapToSelect";
import Button from "components/common/atoms/Button";
import Pagination from "components/common/atoms/Pagination";
import "./checklist.css";
import CustomNotify from "components/common/atoms/CustomNotify";
import Title from "components/common/atoms/Title";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import Reset from "static/images/Reset";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import NoRecords from "components/common/atoms/NoRecords";

interface ChecklistProps {
  name: string;
  id: number;
}

interface ChecklistDropDwn {
  checklist: Option | null;
}

const ManageChecklist: React.FC = () => {
  const [checklist, setChecklist] = useState([] as ChecklistProps[]);
  const [checkDrp, setCheckDrp] = useState({
    checklist: [] as OptionProps[],
  });
  const [searchData, setSearchData] = useState<ChecklistDropDwn>({
    checklist: null,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  // const [isFilter, setIsFilter] = useState<boolean>(true); // Example boolean state

  useEffect(() => {
    fetchChecklistDetails();
  }, []);

  const fetchChecklistDetails = async (searchData = {}, page = 1) => {
    const data = {
      ...searchData,
      page: page,
    };

    const response = await ApiCall.service(
      MANAGE_CHECKLIST,
      "POST",
      data,
      false,
      "central-data-management"
    );
    if (response.status === 200) {
      const checklistData = response.data.checklist;
      const checkOptions = Array.isArray(response.data.checkFlowCat)
        ? mapToSelect(response.data.checkFlowCat)
        : [];
      setChecklist(checklistData);
      setCheckDrp({
        checklist: checkOptions,
      });
      setTotalPages(response.totalPages);
    }
  };

  const handleSearch = async (e: React.FormEvent) => {
    e.preventDefault();
    if (
      searchData.checklist === null ||
      searchData.checklist?.label === "Select"
    ) {
      CustomNotify({
        type: "warning",
        message: t("Search input not provided") + ".",
      });
    }
    fetchChecklistDetails(searchData, currentPage);
  };

  const handleReset = async (e: React.FormEvent) => {
    setSearchData({
      checklist: null,
    });
    setCurrentPage(1);
    fetchChecklistDetails();
  };

  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    setSearchData((prevData) => ({
      ...prevData,
      [fieldName]: selectedOption,
    }));
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    fetchChecklistDetails(searchData, newPage);
  };

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Candidate",
          read: true,
        },
      ]}
      renderNoAccess={true}
    >
      <div>
        <Title title={t("Checklists")} />
        <div className="form-height-dashboard">
          <form action="">
            <div className="row search-bar">
              <div className="col-8">
                <SelectWithSearch
                  search={true}
                  options={checkDrp.checklist}
                  value={searchData.checklist}
                  onChange={(e) => handleSelectChange(e, "checklist")}
                  name="checklist"
                  title={t("Checklist")}
                  id="checklist"
                  isMandatory={false}
                  isMulti={false}
                />
              </div>
              <div className="col-4 mt-34">
                <Button
                  title=""
                  type="reset"
                  handleClick={handleReset}
                  className="delete-btn shadow-none"
                >
                  <span title={t("Reset")}>
                    <Reset />
                  </span>
                </Button>
                <Button
                  title={t("Search")}
                  type="submit"
                  icon={faMagnifyingGlass}
                  handleClick={handleSearch}
                  className="form-button shadow-none search-btns position-relative text-start"
                />
              </div>
            </div>
          </form>
          <div className="table-responsive tableSection">
            <table className="table table-hover">
              <thead>
                <tr className="TableHeader">
                  {/* <th className="ps-lg-4">#</th> */}
                  <th className="ps-lg-4" style={{ width: "60%" }}>
                    {t("Checklist")}
                  </th>
                  <th>{t("Actions")}</th>
                </tr>
              </thead>
              <tbody>
                {checklist && checklist.length > 0 ? (
                  checklist.map((item, index) => (
                    <tr
                      key={index}
                      className="border-bottom mb-3 box-shadow align-middle"
                    >
                      {/* <td className='ps-lg-4'>{pageIndex++}</td> */}
                      <td className="ps-lg-4" data-label={t("Checklist")}>
                        {item.name}
                      </td>
                      <td className="table-action-icons px-2">
                        <div className="d-none d-md-none d-lg-block">
                          <Link
                            to={`/checklist/edit/${item.id}`}
                            className="btn p-0 border-0 me-2"
                            title={t("Edit")}
                          >
                            <EditIcon />
                          </Link>
                          <Link
                            to={`/checklist/view/${item.id}`}
                            className="btn p-0 border-0 me-2"
                            title={t("View")}
                          >
                            <ViewIcon />
                          </Link>
                        </div>
                        <div className="d-block d-md-block d-lg-none">
                          <Link
                            to={`/checklist/edit/${item.id}`}
                            className="btn p-0 border-0 w-100"
                          >
                            <Button
                              title={t("Edit")}
                              className=" mb-2 button-width edit-btn rounded-3"
                            />
                          </Link>
                          <Link
                            to={`/checklist/view/${item.id}`}
                            className="btn p-0 border-0 w-100"
                          >
                            <Button
                              title={t("View")}
                              className=" mb-2 button-width delete-btn rounded-3"
                            />
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <NoRecords headerLength={3} />
                )}
              </tbody>
            </table>
            {totalPages > 1 && (
              <div className="pagination justify-content-center align-items-center mt-4 px-4">
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={(newPage) => handlePageChange(newPage)}
                />
              </div>
            )}
          </div>
        </div>

        <div className="col-md-6 align-self-center py-4 px-4">
          <Link
            to="/config/settings"
            className=" back-btn text-decoration-underline"
          >
            {t("Back")}
          </Link>
        </div>
      </div>
    </AccessControl>
  );
};

export default translate(ManageChecklist);
