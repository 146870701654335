import Title from "components/common/atoms/Title";
import { useEffect, useState } from "react";
import { ApiCall } from "components/common/services/ApiServices";
import { BUDGET_ADD, BUDGET_EDIT, TARGET_ADD } from "routes/ApiEndpoints";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "Constants";
import { Link, useNavigate } from "react-router-dom";
import Button from "components/common/atoms/Button";
import LoadingIcon from "components/common/utlis/LoadingIcon";
import CustomNotify from "components/common/atoms/CustomNotify";
import InputTextfield from "components/common/atoms/InputTextField";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";

export interface DailyTurnoverData {
  designation_id?: number;
  turnover_id: number;
  designation: string;
  turnover_expected: string | null;
  growth_percentage: string | null;
  bonus_planned: string | null;
  reference_period?: number;
}

export function getTargetToBeAchieved(expected: string, growthPerc: number) {
  let convertedValue = parseFloat(
    expected.replace(/\./g, "").replace(/,/g, ".")
  );
  const result = (convertedValue / 100) * growthPerc + convertedValue;
  return isNaN(result) || result === 0 ? "" : numberFormat(result, 2, ".", ",");
}

function numberFormat(
  value: number,
  decimalPlaces: number,
  thousandsSeparator: string,
  decimalSeparator: string
): string {
  const formattedValue = value.toFixed(decimalPlaces); // Fix the number of decimal places
  const parts = formattedValue.split("."); // Split the number into integer and decimal parts
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator); // Add thousands separator
  return parts.join(decimalSeparator); // Join the parts with decimal separator
}

const DailyTurnover: React.FC = () => {
  const [referenceData, setReferenceData] = useState<DailyTurnoverData[]>([]);
  const [formData, setFormData] = useState<DailyTurnoverData[]>([]);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchDailyTurnover();
  }, []);

  const fetchDailyTurnover = async () => {
    const requestData = {
      type: "daily_turnover",
    };

    const response = await ApiCall.service(
      BUDGET_EDIT + "/turnover-config",
      "POST",
      requestData,
      true,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );

    if (response.status === 200) {
      setReferenceData(response.data["by_reference"]);
      setFormData(response.data["by_designation"]);
    }
  };

  const updateData = (
    setData: React.Dispatch<React.SetStateAction<DailyTurnoverData[]>>,
    index: number,
    name: string,
    value: string
  ) => {
    setData((prevData: DailyTurnoverData[]) => {
      const newData = [...prevData];
      newData[index] = { ...newData[index], [name]: value };
      return newData;
    });
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    type: "designation" | "reference"
  ) => {
    const { name, value } = event.target;
    const setData = type === "designation" ? setFormData : setReferenceData;
    const regex = /^\d*\.?\d*$/;

    if (
      name === "growth_percentage" &&
      (parseFloat(value) <= 100 || value === "") &&
      regex.test(value)
    ) {
      updateData(setData, index, name, value);
    }
    if (name !== "growth_percentage") {
      let newValue = value.replace(/[^0-9\.,]+/g, "");
      const commaCount = (value.match(/,/g) || []).length;

      if (commaCount < 2 && (newValue === "" || newValue)) {
        updateData(setData, index, name, newValue);
      }
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const payload = {
      type: "turnover-config",
      data: {
        byDesignation: formData,
        byReference: referenceData,
      },
    };

    const url = `${BUDGET_ADD}/turnover-config`;
    const response = await ApiCall.service(
      url,
      "POST",
      payload,
      false,
      "central-data-management"
    );
    if (response.status === 200) {
      CustomNotify({ type: "success", message: response.msg });
      // navigate("/budget-targets");
    }
    setLoading(false);
  };

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Daily turnover",
          read: true,
          create: true,
          update: true,
        },
      ]}
      actions={true}
      strict={false}
      renderNoAccess={true}
    >
      <Title title={t("Daily turnover config")} />
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12">
            <div className="table-responsive dailyTurnOverDashboard ">
              {/* By reference period */}
              <table className="table table-bordered dailyTurnOverTable marginBotttom1">
                <thead>
                  <tr className="TableHeader text-center">
                    <th className="text-break ps-lg-4">
                      {t("Reference period")}
                    </th>
                    <th className="text-break">
                      {t("Daily turnover expected")}
                    </th>
                    <th className="text-break">{t("Growth") + "%"}</th>
                    <th className="text-break">{t("Target to be achieved")}</th>
                    <th className="text-break">{t("Bonus")}</th>
                  </tr>
                </thead>
                <tbody>
                  {referenceData &&
                    referenceData.length > 1 &&
                    referenceData.map(
                      (dailyTurnover: DailyTurnoverData, index) => (
                        <tr key={index}>
                          <td className="align-middle py-3 text-center  ">
                            {t(`${dailyTurnover.reference_period}`)}
                          </td>
                          <td
                            className="p-0 position-relative align-middle"
                            data-label={t("Daily turnover expected")}
                          >
                            <InputTextfield
                              type="text"
                              min={0}
                              handleChange={(e) =>
                                handleInputChange(e, index, "reference")
                              }
                              className="form-control text-center shadow-none rounded-0 position-absolute start-0 h-100 top-0"
                              name="turnover_expected"
                              value={dailyTurnover.turnover_expected ?? ""}
                            />
                          </td>
                          <td
                            className="position-relative p-0 align-middle"
                            data-label={t("Growth") + "%"}
                          >
                            <InputTextfield
                              type="text"
                              handleChange={(e) =>
                                handleInputChange(e, index, "reference")
                              }
                              name="growth_percentage"
                              min={0}
                              max={100}
                              className="form-control text-center shadow-none rounded-0 position-absolute start-0 h-100 top-0"
                              value={dailyTurnover.growth_percentage ?? ""}
                            />
                          </td>
                          <td
                            className="text-center position-relative px-0 py-4 align-middle"
                            data-label={t("Target to be achieved")}
                          >
                            <div className="position-absolute start-0 top-0 w-100 align-items-center justify-content-center d-flex h-100 start-0 disabled">
                              {getTargetToBeAchieved(
                                dailyTurnover.turnover_expected !== null
                                  ? dailyTurnover.turnover_expected.toString()
                                  : "0",
                                dailyTurnover.growth_percentage
                                  ? parseFloat(dailyTurnover.growth_percentage)
                                  : 0
                              )}
                            </div>
                          </td>
                          <td
                            className="position-relative p-0 align-middle"
                            data-label={t("Bonus")}
                          >
                            <InputTextfield
                              type="text"
                              min={0}
                              handleChange={(e) =>
                                handleInputChange(e, index, "reference")
                              }
                              name="bonus_planned"
                              value={dailyTurnover.bonus_planned ?? ""}
                              className="form-control text-center shadow-none rounded-0 position-absolute start-0 h-100 top-0"
                            />
                          </td>
                        </tr>
                      )
                    )}
                </tbody>
              </table>

              {/* By designation */}
              <table className="table table-bordered dailyTurnOverTable ">
                <thead>
                  <tr className="TableHeader">
                    <th className="text-break ps-lg-4">{t("Designation")}</th>
                    <th className="text-break text-center">
                      {t("Growth") + "%"}
                    </th>
                    <th className="text-break text-center">{t("Bonus")}</th>
                  </tr>
                </thead>
                <tbody>
                  {formData &&
                    formData.length > 1 &&
                    formData.map((dailyTurnover: DailyTurnoverData, index) => (
                      <tr key={index}>
                        <td className="align-middle py-3">
                          {t(`${dailyTurnover.designation}`)}
                        </td>
                        <td
                          className="position-relative px-0 align-middle py-3"
                          data-label={t("Growth") + "%"}
                        >
                          <InputTextfield
                            type="text"
                            handleChange={(e) =>
                              handleInputChange(e, index, "designation")
                            }
                            name="growth_percentage"
                            min={0}
                            max={100}
                            className="form-control text-center shadow-none rounded-0 position-absolute start-0 h-100 top-0"
                            value={dailyTurnover.growth_percentage ?? ""}
                          />
                        </td>
                        <td
                          className="position-relative px-0 align-middle py-3"
                          data-label={t("Bonus")}
                        >
                          <InputTextfield
                            type="text"
                            min={0}
                            handleChange={(e) =>
                              handleInputChange(e, index, "designation")
                            }
                            name="bonus_planned"
                            value={dailyTurnover.bonus_planned ?? ""}
                            className="form-control text-center shadow-none rounded-0 position-absolute start-0 h-100 top-0"
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="row backPadding">
          <div className="col-md-4 align-self-center">
            <Link
              to=""
              className=" back-btn text-decoration-underline"
              onClick={() => navigate("/budget-targets")}
            >
              {t("Back")}
            </Link>
          </div>
          <AccessControl
            requiredPermissions={[
              {
                permission: "Daily turnover",
                create: true,
                update: true,
              },
            ]}
            actions={true}
            strict={false}
          >
            <div className="col-md-8">
              {!loading ? (
                <Button
                  title={t("Save")}
                  type="submit"
                  className="form-button float-end shadow-none"
                />
              ) : (
                <LoadingIcon
                  iconType="bars"
                  color="#ff4dae"
                  className="float-end"
                  width={"3vw"}
                  height={"3vw"}
                />
              )}
            </div>
          </AccessControl>
        </div>
      </form>
    </AccessControl>
  );
};

export default translate(DailyTurnover);
