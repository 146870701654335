import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface NavigationState {
  destinationPath: string | null;
}

const initialState: NavigationState = {
  destinationPath: null,
};

const NavigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setDestinationPath: (state, action: PayloadAction<string>) => {
      state.destinationPath = action.payload; // Set the destination path
    },
    clearDestinationPath: (state) => {
      state.destinationPath = null; // Clear the destination path
    },
  },
});

export const { setDestinationPath, clearDestinationPath } = NavigationSlice.actions;

export const selectNavigation = (state: RootState) => state.navigation;

export default NavigationSlice.reducer;
