import React, { useCallback, useState, useRef, useEffect } from "react";
import { AutoComplete } from "primereact/autocomplete";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { ApiCall } from "../services/ApiServices";
import { Debounce } from "../utlis/Debounce";
import LabelField from "./LabelField";
import * as ENDPOINTS from "routes/ApiEndpoints";

interface MultiTableProps {
  label: string;
  group: string;
  modelKey: string;
  column?: string;
  valueColumn?: string;
  concat?: string | null;
  joinTable?: string | null;
  notNullColumn?: string | null;
  customFetch?: boolean;
  separator?: string;
}

export interface FilterProps {
  column: string;
  value: any;
  condition: "where" | "orWhere";
}

interface BaseAutocompleteDropdownProps {
  label?: string;
  labelClassName?: string;
  isMandatory?: boolean;
  id?: string;
  placeholder?: string;
  shouldCreateNew?: boolean;
  isMultiSelect?: boolean;
  microService: string;
  onChange: (value: any) => void;
  value: any;
  showDropdownList?: boolean;
  columnName?: string;
  modelKey: string;
  error?: string;
  concat?: string;
  name?: string;
  valueColumn?: string;
  isGrouped?: boolean;
  multiTables?: MultiTableProps[];
  isDisabled?: boolean;
  customFetch?: boolean;
  separator?: string;
  group?: string;
  notNullColumn?: string;
  className?: string;
  clearString?: boolean;
}

interface AutocompleteDropdownPropsWithoutFilter extends BaseAutocompleteDropdownProps {
  filter?: false;
  filterInfo?: never;
}

interface AutocompleteDropdownPropsWithFilter extends BaseAutocompleteDropdownProps {
  filter: true | undefined;
  filterInfo: FilterProps[] | undefined;
}

type AutocompleteDropdownProps =
  | AutocompleteDropdownPropsWithoutFilter
  | AutocompleteDropdownPropsWithFilter;

interface AutoCompleteCompleteMethodParams {
  query: string;
}

const AutoCompleteDropdown: React.FC<AutocompleteDropdownProps> = ({
  label,
  labelClassName,
  isMandatory,
  id,
  placeholder,
  shouldCreateNew = false,
  isMultiSelect = false,
  microService,
  onChange,
  value,
  showDropdownList = false,
  columnName = "name",
  modelKey,
  error,
  concat = null,
  name,
  valueColumn = 'id',
  isGrouped = false,
  multiTables = [],
  isDisabled = false,
  customFetch = false,
  separator = null,
  filter = false,
  filterInfo = [],
  group = null,
  notNullColumn = null,
  className,
  clearString = false
}) => {
  const [filteredItems, setFilteredItems] = useState<string[]>([]);
  const autoCompleteRef = useRef<HTMLDivElement>(null);
  const latestRequestId = useRef(0);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (autoCompleteRef.current && !autoCompleteRef.current.contains(event.target as Node) && !isMultiSelect && clearString) {
        // Clear the input field when clicking outside
        if (typeof value === 'string') {
          onChange("");
        }
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [autoCompleteRef, value, onChange, isMultiSelect, clearString]);

  const defaultColumn = 'name';
  const defaultValueColumn = 'id';
  const defaultConcatTable = null;
  const defaultJoinTable = null;
  const defaultNotNull = null;

  // Ensure default values for column and valueColumn using destructuring
  const processedMultiTables = multiTables.map(({
    label,
    group,
    modelKey,
    column = defaultColumn,
    valueColumn = defaultValueColumn,
    concat = defaultConcatTable,
    joinTable = defaultJoinTable,//Join is not implemented
    notNullColumn = defaultNotNull,
    customFetch = false,
    separator = null
  }) => ({
    label,
    group,
    modelKey,
    column,
    valueColumn,
    concat,
    joinTable,//Join is not implemented
    notNullColumn,
    customFetch,
    separator
  }));
  const [itemSize, setItemSize] = useState<number>(window.innerWidth * (1.979 / 100)); // Initial calculation

  useEffect(() => {
    const handleResize = () => {
      // Calculate item size in pixels based on vw units
      const newSize = window.innerWidth * (1.979 / 100);

      // Only update state if the new size is different
      setItemSize((prevSize) => (prevSize !== newSize ? newSize : prevSize));
    };

    // Use requestAnimationFrame for optimized resize handling
    const resizeListener = () => requestAnimationFrame(handleResize);

    window.addEventListener('resize', resizeListener);

    return () => window.removeEventListener('resize', resizeListener);
  }, []);

  // Function to fetch items from the API
  const searchItems = async (query: string, currentRequestId: number) => {
    try {
      const response = await ApiCall.service(
        ENDPOINTS.GET_SEARCH_VALUES,
        "POST",
        {
          modelKey: modelKey, value: query, column: columnName, customFetch: customFetch, concat: concat,
          separator: separator, valueColumn: valueColumn, multiTables: processedMultiTables, filter: filter,
          filterInfo: filterInfo, group: group, notNullColumn: notNullColumn
        },
        false,
        microService
      );
      if (response.status === 200 && currentRequestId === latestRequestId.current) {
        const items = response.found;
        setFilteredItems(items);
      } else {
        console.error("Error fetching data:", response?.msg);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Debounced search function to limit API calls while typing
  const debouncedSearchItems = useCallback(
    Debounce((query: string) => {
      // Increment requestId before making the API call
      const currentRequestId = ++latestRequestId.current;
      searchItems(query, currentRequestId);
    }, 500),
    []
  );

  // Handler for the search event from the AutoComplete component
  const handleSearch = (event: AutoCompleteCompleteMethodParams) => {
    debouncedSearchItems(event.query);
  };

  return (
    <div className="p-fluid">
      {label && (
        <LabelField
          title={label}
          className={labelClassName}
          isMandatory={isMandatory}
        />
      )}
      <div ref={autoCompleteRef}>
        <AutoComplete
          name={name}
          value={value}
          suggestions={filteredItems}
          completeMethod={handleSearch}
          onChange={(e) => onChange(e.value)}
          dropdown={showDropdownList}
          multiple={isMultiSelect}
          placeholder={placeholder}
          forceSelection={!shouldCreateNew}
          {...(isGrouped ? { optionGroupLabel: "label", optionGroupChildren: "items" } : {})}
          inputId={id}
          virtualScrollerOptions={{ itemSize }}
          inputClassName={`autoCompleteInput ${className}`}
          panelStyle={{ maxWidth: autoCompleteRef?.current?.querySelector('input')?.offsetWidth }}
          field="label"
          inputStyle={isDisabled ? { color: '#212529', } : {}}
          disabled={isDisabled}
        />
      </div>
      <div className="height-20" style={{marginBottom: "0.5vw"}}>
        {error && <span className="text-danger">{error}</span>}
      </div>
    </div>
  );
};

export default AutoCompleteDropdown;
