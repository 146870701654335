import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import AuthReducer from "../features/auth/AuthSlice";
import encryptTransform from "./encryptTransform";
import NavigationReducer from "../features/NavigationSlice";


const persistConfig = {
  key: "root",
  storage,
  transforms: [encryptTransform],
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    auth: AuthReducer,
    navigation: NavigationReducer,
    // Add reducers for other parts of your state if needed
  })
);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          // Ignore specific action types
          ignoredActions: ["persist/PERSIST"],
        },
      }),
  });
  

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;