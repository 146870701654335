import React from 'react'

const ClappingHands = () => {
    return (
        <>
            <svg width="1.5vw" height="1.5vw" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M54.2553 25.75C51.4013 23.798 49.6113 21.188 48.1803 17.169C47.6483 15.675 46.3213 14.709 44.7993 14.709C43.1633 14.709 41.6673 15.764 40.6953 17.603C40.1496 18.619 39.8143 19.7345 39.7093 20.883C38.7013 19.045 36.6533 18.654 35.6823 19.961C35.6823 19.961 27.6963 13.89 24.9723 11.957C21.3523 9.39097 16.5573 12.233 16.6773 15.168C12.1063 12.69 7.08831 17.065 8.63431 21.65C6.86631 21.326 2.34431 26.013 7.09831 29.613C3.80531 29.951 2.52631 35.107 6.35331 37.454C6.35331 37.454 6.37131 38.167 6.42231 38.517C3.90031 39.283 3.03031 44.051 5.46931 45.556C5.46931 45.556 23.0593 56.788 29.1143 60.167C32.4043 62.003 38.0693 62.724 43.1163 61.085C48.0173 60.577 52.1753 57.979 55.5163 53.349C64.7113 40.607 57.4273 27.92 54.2553 25.75ZM53.1783 52.107C49.3493 57.487 43.8503 58.045 39.6423 58.045C32.7733 58.045 28.5773 54.752 28.5773 54.752L9.72531 40.676C6.15631 38.212 8.30531 34.286 10.8093 34.358L25.8063 44.577L27.6523 44.692C27.6523 44.692 14.2573 35.076 9.92031 31.691C7.38831 29.715 8.22031 25.941 11.4983 25.191L30.2543 38.655L32.0983 38.77L12.0633 23.232C7.83031 20.138 12.0783 14.304 16.1823 17.275C20.9153 20.701 35.1543 31.56 35.1543 31.56L37.0003 31.676L18.2553 16.149C18.0933 13.753 21.2803 11.67 23.8283 13.576C28.9523 17.409 44.3323 28.896 44.3323 28.896C43.9363 27.787 43.7383 26.953 43.7383 26.953C43.7383 26.953 40.8163 22.095 42.3993 18.558C43.7893 15.456 46.6013 15.96 47.1683 17.627C48.6753 22.065 50.2363 24.699 53.3593 26.919C57.3473 29.754 61.4253 40.524 53.1783 52.107Z" fill="black" />
                <path d="M46.0414 2.889L37.4314 2L40.3694 14.26L46.0414 2.889ZM53.1654 5.264L48.7904 15.457L58.9954 12.723L53.1654 5.264ZM29.1114 3.549L22.9014 8.682L31.8454 14.51L29.1114 3.549Z" fill="black" />
            </svg>
        </>
    )
}

export default ClappingHands