import React from "react";

const CreateUser = () => {
  return (
    <>
      <svg
        width="966"
        height="1197"
        viewBox="1 0 966 1260"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M264.661 26.8314C283.757 16.781 318.598 4.38546 336.689 0.700282C341.379 0.0301113 363.155 -0.304821 385.266 0.36535C451.599 2.04032 493.14 20.4662 541.383 69.043C564.833 92.4941 572.874 103.55 583.929 126.331C591.3 141.741 599.675 164.522 602.69 176.583C609.39 204.389 609.725 252.966 602.69 282.447C574.214 408.077 444.564 486.471 318.598 454.309C236.185 433.203 168.177 363.185 150.756 281.442C139.701 228.51 146.736 172.563 171.192 122.645C186.603 91.1541 233.84 42.9122 264.661 26.8314Z"
          fill="currentColor"
        />
        <path
          d="M7.37027 653.642C31.8263 576.589 101.509 516.956 180.908 504.896C191.963 503.221 285.432 502.216 388.616 502.551C568.854 503.221 577.229 503.556 598.67 510.256C669.693 532.702 723.296 583.959 746.411 651.967C749.616 661.582 751.431 668.068 752.552 687.009C744.748 686.341 736.851 686 728.874 686C577.618 686 455 808.617 455 959.874C455 997.009 462.391 1032.42 475.782 1064.71C286.063 1074.27 63.1044 1056.1 10.0504 1014.12L0 999.712V838.57C0 681.113 0.335009 676.423 7.37027 653.642Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M493 960.185C493 830.283 599.283 724 729.185 724C859.086 724 965.37 830.283 965.37 960.185C965.37 1090.09 859.086 1196.37 729.185 1196.37C599.283 1196.37 493 1090.09 493 960.185ZM709.796 1124.05C704.703 1118.95 701.842 1112.04 701.842 1104.84V987.158H584.158C576.955 987.158 570.047 984.296 564.955 979.204C559.861 974.11 557 967.203 557 960C557 952.797 559.861 945.89 564.955 940.796C570.047 935.703 576.955 932.842 584.158 932.842H701.842V815.158C701.842 807.955 704.703 801.047 709.796 795.955C714.89 790.861 721.797 788 729 788C736.203 788 743.11 790.861 748.204 795.955C753.296 801.047 756.158 807.955 756.158 815.158V932.842H873.842C881.045 932.842 887.953 935.703 893.045 940.796C898.139 945.89 901 952.797 901 960C901 967.203 898.139 974.11 893.045 979.204C887.953 984.296 881.045 987.158 873.842 987.158H756.158V1104.84C756.158 1112.04 753.296 1118.95 748.204 1124.05C743.11 1129.14 736.203 1132 729 1132C721.797 1132 714.89 1129.14 709.796 1124.05Z"
          fill="currentColor"
        />
      </svg>
    </>
  );
};

export default CreateUser;
