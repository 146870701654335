import { useEffect, useState } from "react";
import { useFormContext } from "components/CompanyCreation/Company/context/Context";
import Basic from "./Basic";
import Address from "./Address";
import Contacts from "./Contacts";
import { useParams } from "react-router-dom";
import { COMPANY_DETAILS } from "routes/ApiEndpoints";
import { ApiCall } from "components/common/services/ApiServices";
import { mapToSelect } from "components/common/utlis/MapToSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTriangleExclamation,
  faCircleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import Title from "components/common/atoms/Title";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import { useTabUrlUpdater } from "components/common/utlis/TabUtility";
import { FormatPCList } from "components/common/utlis/FormatPCList";
import Documents from "./Documents";
import ActionButtons from "components/common/utlis/ActionButtons";
import { actionButtons } from "./ActionButtons";
import { formatDateAndTime } from "components/common/utlis/HelperFunctions";
import InfoIcon from "static/images/InfoIcon";
import UserInfoTooltip from "components/common/atoms/UserInfoTooltip";
import RightSideBar from "components/common/atoms/RightSideBar";
import ButtonWithChildren from "components/common/atoms/ButtonWithChildren";
import FollowUpIcon from "static/images/FollowUpIcon";

type DynamicActionType = "UPDATE_BASIC_FIELD" | "UPDATE_ADDRESS_FIELD";

const CompanyForm = () => {
  const { state, dispatch } = useFormContext();
  const [isSticky, setIsSticky] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 70) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const { companyId } = useParams<{ companyId: string }>();

  useEffect(() => {
    const fetchDataFromUrl = async () => {
      try {
        const url = !companyId
          ? COMPANY_DETAILS
          : `${COMPANY_DETAILS}/${companyId}`;
        const response = await ApiCall.getService(
          url,
          "GET",
          "company-creation",
          true
        );
        if (response.status === 200) {
          const {
            basicDetails,
            addressDetails,
            contactDetails,
            countries,
            paritairCommittees,
            parentCompany,
            cities,
            genders,
            languages,
            rolesList,
            sectorList,
            domainList,
            functionList,
            offices,
            consultants,
            locations,
            categoriesList,
            documentDetails,
          } = response.data;

          dispatch({
            type: "SET_FIELDS",
            payload: {
              countryList: mapToSelect(countries || []),
              sectorList: mapToSelect(sectorList || []),
              functionList: mapToSelect(functionList || [], "function_name"),
              domainList: mapToSelect(domainList || [], "domain_name"),
              genders: mapToSelect(genders || []),
              languages: mapToSelect(languages || []),
              pcList: FormatPCList(paritairCommittees || []),
              companiesList: parentCompany?.map((item: any) => ({
                value: item.id,
                label: item.company_name,
              })),
              cityList: mapToSelect(cities || []),
              rolesList: mapToSelect(rolesList || []),
              officesList: mapToSelect(offices || []),
              cosultantsList: consultants,
              locations: locations,
              categoriesList: mapToSelect(categoriesList || []),
            },
          });

          // Dispatch actions to update basic and address fields
          const fieldMappings = [
            {
              fieldPrefix: "basic",
              data: basicDetails,
            },
            {
              fieldPrefix: "address",
              data: addressDetails,
            },
          ];

          fieldMappings.forEach(({ fieldPrefix, data }) => {
            if (data) {
              Object.entries(data).forEach(([field, value]) => {
                const dynamicActionType: DynamicActionType =
                  `UPDATE_${fieldPrefix.toUpperCase()}_FIELD` as DynamicActionType;
                dispatch({
                  type: dynamicActionType,
                  field,
                  value: value as string | null | number,
                });
              });
            }
          });

          if (contactDetails && contactDetails.length > 0) {
            dispatch({
              type: "ADD_UPDATE_DYNAMIC",
              data: contactDetails,
            });
          }
          if (documentDetails && documentDetails.length > 0) {
            documentDetails.forEach(
              (document: Record<string, any>, index: number) => {
                Object.entries(document).forEach(([field, value]) => {
                  dispatch({
                    type: "EDIT_DOCUMENT_FIELD",
                    field,
                    value,
                    index,
                  });
                });
              }
            );
          }
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchDataFromUrl();
  }, []);

  useTabUrlUpdater(state.tabDetails, dispatch); //To add tabId to url and stay on current tab on refresh

  const handleTabClick = (tabId: string) => {
    const currentTab = state.tabDetails.findIndex((tab) => tabId === tab.id);
    state.tabDetails[currentTab].error = false;
    const updatedTabDetails = state.tabDetails.map((tab) => ({
      ...tab,
      showStatus: tab.id === tabId,
    }));

    dispatch({ type: "UPDATE_TAB_DETAILS", tabDetails: updatedTabDetails });
  };

  const ConstructTabs = (id: string, showStatus: boolean) => {
    switch (id) {
      case "basic":
        return <Basic />;
      case "address":
        return <Address />;
      case "contacts":
        return <Contacts />;
      case "documents":
        return <Documents />;
      default:
        break;
    }
  };

  const permissionType = companyId ? "update" : "create";
  const permissionObject: any = {
    permission: "Candidate",
  };
  permissionObject[permissionType] = true;

  const filters = {
    companyName: state.basic.companyName,
  };

  return (
    <>
      <AccessControl
        requiredPermissions={[permissionObject]}
        renderNoAccess={true}
      >
        <div className="row header-sticky position-sticky">
          <div className="col-3 align-self-center">
            <Title title={t("Company")} />
          </div>
          {companyId && (
            <>
              <div className="col-9 headerIcons text-end">
                <ActionButtons buttons={actionButtons} filters={filters} />
                <ButtonWithChildren
                  onClick={() => { setIsSidebarOpen(!isSidebarOpen)}}
                  className="d-inline-block form-manage-button headerBtn marginRightPoint5"
                >
                  <FollowUpIcon />
                  <span className="ms-2">{t("Follow up")}</span>
                </ButtonWithChildren>
                <div className=" form-manage-button d-inline-block">
                  <UserInfoTooltip
                    createdBy={state.basic.createdBy}
                    createdAt={formatDateAndTime(state.basic.createdAt)}
                    updatedAt={formatDateAndTime(state.basic.updatedAt)}
                    updatedBy={state.basic.updatedBy}
                  />
                </div>
              </div>
            </>
          )}
        </div>
        {isSidebarOpen && (
          <RightSideBar
            title={"Follow-ups"}
            entityType={"company"}
            entityId={companyId}
            isInBright={!!state.basic?.brightId}
            onClose={() => setIsSidebarOpen(false)}
            recordsLimit={10}
            className="right-sidebar p-3 scrollBarDesign companyRightSideBar"
            titleClassName="mt-4"
            destination="/manage-companies"
          />
        )}
        {(state.basic.vat === "" || state.basic.companyName === "") &&
          state.tabDetails[0].draft === true &&
          state.basic.companyStatus !== 1 && (
            <div className="row">
              <div className="col-md-12">
                <div
                  className="alert alert-danger alert-dismissible fade show "
                  role="alert"
                >
                  <span className="me-2">
                    <FontAwesomeIcon icon={faCircleExclamation} />
                  </span>
                  {t("Please fill the minimum required fields")}{" "}
                  <strong>{t("VAT number")}</strong>
                  {t("and")} <strong>{t("Company name")}</strong>{" "}
                  {t("to Save as draft")}.
                  <button
                    type="button"
                    className="btn-close shadow-none"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                  ></button>
                </div>
              </div>
            </div>
          )}
        <div className="position-relative" style={{ paddingTop: "2vw" }}>
          <nav
            className={`company-step-tabs createFormTabs ${isSticky
                ? "createTabsSticky position-sticky"
                : "company-step-tabs-absolute position-absolute w-100"
              }`}
          >
            <div
              className="nav nav-tabs border-0 col-xxl-6 m-auto col-xl-8 col-lg-6 col-md-6"
              id="nav-tab"
              role="tablist"
            >
              {state.tabDetails.map((item: any, index) => (
                <button
                  key={item.id}
                  className={`nav-link me-lg-3 text-break ${item.error ? "error" : ""
                    } ${item.showStatus ? "active" : "inactive"}`}
                  id={`${item.id}-tab`}
                  data-bs-toggle="tab"
                  data-bs-target={`#${item.id}`}
                  type="button"
                  role="tab"
                  aria-controls={`nav-${item.id}`}
                  aria-selected={item.showStatus}
                  onClick={() => handleTabClick(item.id)}
                  style={{
                    borderRadius: "0.5vw",
                    marginRight: "0.5vw",
                    padding: "0.5vw",
                  }}
                >
                  <div className="candidate-step-number d-flex align-items-center justify-content-center">
                    {t(`${item.title}`)}
                    {item.error && (
                      <span
                        className="exclamation-icon ms-2"
                        title={`Please fill all the mandatory fields`}
                        data-toggle="tooltip"
                      >
                        <FontAwesomeIcon icon={faTriangleExclamation} />
                      </span>
                    )}
                  </div>
                </button>
              ))}
            </div>
          </nav>

          <div className="tab-content py-0" id="nav-tabContent">
            {state.tabDetails.map((item: any) => (
              <div
                key={item.id}
                className={`tab-pane fade ${item.showStatus ? "show" : ""} ${item.showStatus ? "active" : ""
                  }`}
                id={item.id}
                role="tabpanel"
                aria-labelledby={`${item.id}-tab`}
              >
                {ConstructTabs(item.id, item.showStatus)}
              </div>
            ))}
          </div>
        </div>
      </AccessControl>
    </>
  );
};

export default translate(CompanyForm);
