import React, { useState } from "react";
import SearchModel from "components/common/atoms/SearchModel";
import { Link } from "react-router-dom";
import EditIcon from "static/images/EditIcon";
import DeleteIcon from "static/images/DeleteIcon";
import ButtonWithChildren from "components/common/atoms/ButtonWithChildren";
import Pagination from "components/common/atoms/Pagination";
import ModalPopup from "components/common/atoms/ModalPopup";
import { ApiCall } from "components/common/services/ApiServices";
import CustomNotify from "components/common/atoms/CustomNotify";
import { DELETE_ROLE } from "routes/ApiEndpoints";
import "static/css/roles.css";
import Button from "components/common/atoms/Button";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import Title from "components/common/atoms/Title";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import LinkTo from "components/common/atoms/LinkTo";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import NoRecords from "components/common/atoms/NoRecords";

interface RolesType {
  id: number;
  name?: string;
}

interface Props {
  rolesObj: RolesType[];
}

const ManageRolesPage: React.FC<Props> = ({ rolesObj }) => {
  const [roles, setRoles] = useState(rolesObj);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteID, setDeleteId] = useState<number | undefined>();
  const [showModal, setShowModal] = useState(false);
  const [modalBody, setModelBody] = useState(
    t("Are you sure want to delete") + "?"
  );
  const itemsPerPage = 10;
  // const [isFilter, setIsFilter] = useState<boolean>(true); // Example boolean state

  // Filter the data based on the search query
  const filteredData = roles.filter((item: RolesType) =>
    item.name?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Get the current items to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Handle search input change
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to the first page when the search query changes
  };

  const deleteRole = (id: number | undefined) => {
    setShowModal(true);
    setDeleteId(id);
  };

  // Handle page change
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  // Modal popup for delete confirmation
  const handleModalClose = () => {
    setShowModal(!showModal);
    setModelBody(t("Are you sure want to delete") + "?");
  };
  const handleSaveChanges = async () => {
    setShowModal(false);
    let body = {
      roleId: deleteID,
      force: false,
    };
    if (modalBody !== t("Are you sure want to delete") + "?") {
      body.force = true;
    }
    const response = await ApiCall.service(DELETE_ROLE, "POST", body);
    if (response.status === 403) {
      setModelBody(response.msg);
      setShowModal(true);
    } else if (response.status === 200) {
      const newRolesObj = roles.filter(
        (item: RolesType) => item.id !== deleteID
      );
      setRoles(newRolesObj);
      CustomNotify({ type: "success", message: t(response.msg) });
    } else {
      CustomNotify({ type: "error", message: t(response.msg) });
    }
  };

  return (
    <>
      <AccessControl
        requiredPermissions={[
          {
            permission: "Roles",
            read: true,
          },
        ]}
        renderNoAccess={true}
      >
        <div>
          <Title title={t("Roles")} />
          <div className="form-height-dashboard">
            <div className="row pb-4 search-bar" style={{paddingTop:"0.1vw"}}>
              <div className="col-md-12 col-lg-8 col-xl-9">
                <SearchModel
                  className="form-control"
                  placeHolder={t("Search by name")}
                  onChange={handleSearchChange}
                />
              </div>
            </div>
            <div className="position-relative tableMainWrapper">
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Roles",
                    create: true,
                  },
                ]}
              >
                <div className="d-none d-lg-block">
                  <div className="row">
                    <div className="ManageCreateBtn">
                      <LinkTo
                        pagelink="/role/create"
                        title={t("Create role")}
                        icon={faPlus}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-block d-lg-none">
                  <div className="row">
                    <div className="col-md-6 ms-auto pb-3">
                      <LinkTo
                        pagelink="/role/create"
                        title={t("Create role")}
                        icon={faPlus}
                        className="align-items-center justify-content-center w-100"
                      />
                    </div>
                  </div>
                </div>
              </AccessControl>
              <div className="table-responsive manage-roles tableSection">
                <table className="table table-hover">
                  <thead>
                    <tr className="TableHeader">
                      {/* <th className="ps-lg-4">#</th> */}
                      <th className="ps-lg-4">{t("Name")}</th>
                      <AccessControl
                        requiredPermissions={[
                          {
                            permission: "Roles",
                            update: true,
                            delete: true,
                          },
                        ]}
                        actions={true}
                        strict={false}
                      >
                        <th>{t("Actions")}</th>
                      </AccessControl>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems && currentItems.length > 0 ? (
                      currentItems.map((role, index) => (
                        <tr
                          key={role.id}
                          className="border-bottom box-shadow mb-3"
                        >
                          <td
                            className="text-break ps-lg-4 align-middle"
                            data-label="Name"
                          >
                            {t(`${role.name}`)}
                          </td>

                          <AccessControl
                            requiredPermissions={[
                              {
                                permission: "Roles",
                                update: true,
                                delete: true,
                              },
                            ]}
                            actions={true}
                            strict={false}
                          >
                            <td className="table-action-icons px-2">
                              {/* Desktop */}
                              <div className="d-none d-md-none d-lg-block">
                                <AccessControl
                                  requiredPermissions={[
                                    {
                                      permission: "Roles",
                                      update: true,
                                    },
                                  ]}
                                >
                                  <Link
                                    to={`/role/edit/${role.id}`}
                                    className="btn p-0 border-0 me-2"
                                    title={t("Edit")}
                                  >
                                    <EditIcon />
                                  </Link>
                                </AccessControl>

                                <AccessControl
                                  requiredPermissions={[
                                    {
                                      permission: "Roles",
                                      delete: true,
                                    },
                                  ]}
                                >
                                  {role.id > 9 && role.id !== 22 && (
                                    <ButtonWithChildren
                                      className="btn p-0 border-0"
                                      title={t("Delete")}
                                      onClick={() => deleteRole(role.id)}
                                    >
                                      <DeleteIcon />
                                    </ButtonWithChildren>
                                  )}
                                </AccessControl>
                              </div>
                              {/* PWA */}
                              <div className="d-block d-md-block d-lg-none text-center">
                                <AccessControl
                                  requiredPermissions={[
                                    {
                                      permission: "Roles",
                                      update: true,
                                    },
                                  ]}
                                >
                                  <div>
                                    <Link
                                      to={`/role/edit/${role.id}`}
                                      className="btn p-0 border-0 w-100"
                                      title={t("Edit")}
                                    >
                                      <Button
                                        title={t("Edit")}
                                        className=" mb-2 button-width edit-btn rounded-3"
                                      />
                                    </Link>
                                  </div>
                                </AccessControl>
                                {role.id > 9 && role.id !== 22 && (
                                  <AccessControl
                                    requiredPermissions={[
                                      {
                                        permission: "Roles",
                                        delete: true,
                                      },
                                    ]}
                                  >
                                    <div className="mt-2">
                                      <ButtonWithChildren
                                        className="btn p-0 rounded-3  mb-2 button-width delete-btn"
                                        title={t("Delete")}
                                        onClick={() => deleteRole(role.id)}
                                      >
                                        {t("Delete")}
                                      </ButtonWithChildren>
                                    </div>
                                  </AccessControl>
                                )}
                              </div>
                            </td>
                          </AccessControl>
                        </tr>
                      ))
                    ) : (
                      <NoRecords headerLength={3} />
                    )}
                  </tbody>
                </table>
                {totalPages > 1 && (
                  <div className="pagination justify-content-center align-items-center mt-4 px-4">
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageChange}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6 align-self-center py-4">
            <Link
              to="/dashboard"
              className=" back-btn text-decoration-underline"
            >
              {t("Back")}
            </Link>
          </div>
        </div>
        <ModalPopup
          show={showModal}
          onHide={handleModalClose}
          title={t("Delete confirmation")}
          body={modalBody}
          onCloseButtonClick={handleModalClose}
          onConfirmButtonClick={handleSaveChanges}
          closeTitle={t("No")}
          confirmTitle={t("Yes")}
        />
      </AccessControl>
    </>
  );
};

export default translate(ManageRolesPage);
