import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import * as CONST from "Constants";
import WelcomeInfo from "./WelcomeInfo";
import { ApiCall } from "components/common/services/ApiServices";
import { CLOSE_FOLLOW_UP, GET_DASHBOARD } from "routes/ApiEndpoints";
import { Option, OptionProps } from "components/common/utlis/TypeAnnotations";
import { mapToSelect } from "components/common/utlis/MapToSelect";
import TargetDashboard from "./targetDashboard/TargetDashboard";
import "../../../src/static/css/dashboard.css";
import TodoDashboard from "./todoDashboard/TodoDashboard";
import AdminDashboard from "./adminDashboard/AdminDashboard";
import BudgetDashboard from "./budgetDashboard/BudgetDashboard";
import ModalPopup from "components/common/atoms/ModalPopup";
import { t } from "components/CentralDataMangement/translation/Translation";
import { useNavigate } from "react-router-dom";
import CustomNotify from "components/common/atoms/CustomNotify";
import { addUserDetails } from "features/auth/AuthSlice";
import { useDispatch } from "react-redux";
import { createQueryParams } from "components/common/utlis/HelperFunctions";
import { setDestinationPath } from "features/NavigationSlice";

export interface TargetData {
  name: string;
  userName?: string;
  fromDate?: string;
  toDate?: string;
  userId: number;
  target: number;
  achieved: number | null;
  // Assuming 'achieved' can be a number or null
}

export interface FollowUpData {
  id: string;
  time: string;
  status: boolean;
  office: string;
  followUpFor: string;
  taskType: string;
}

export interface TargetTypeData {
  [key: string]: TargetData[]; // Index signature for dynamic keys (e.g., "New", "Presented", "Starter")
}

export interface Budget {
  myBudget: {
    label: string;
    goal: number | string;
    achieved: number | string;
    percentage: number;
    showMessage?: boolean;
  };
  officeBudget: {
    label: string;
    goal: number | string;
    achieved: number | string;
    percentage: number;
  };
}

export interface NewCounts {
  newVacancies: number;
  newCandidates: number;
}

const Dashboard = () => {
  const userAuth = useSelector((state: RootState) => state.auth);
  const [countData, setCountData] = useState<TargetTypeData>({});
  const [offices, setOffices] = useState<OptionProps[]>([]);
  const [office, setOffice] = useState<Option>();
  const [newCounts, setNewCounts] = useState<NewCounts>();
  const userPermission = userAuth.userPermissions;
  const [displayDate, setDisplayDate] = useState<string>("");
  const [initialFetch, setInitialFetch] = useState(true);
  const [totalBudget, setBudgetData] = useState<Budget>({
    myBudget: {
      label: "My budget",
      goal: 0,
      achieved: 0,
      percentage: 0,
      showMessage: true,
    },
    officeBudget: {
      label: "Office budget",
      goal: 0,
      achieved: 0,
      percentage: 0,
    },
  });
  const [followUps, setFollowUps] = useState<FollowUpData[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [deleteID, setDeleteId] = useState<string | undefined>();
  const navigate = useNavigate();

  const myBudget =
    userPermission?.filter((item: any) => item?.permission === "My budget") ||
    [];

  const isMyBudgetReadable = myBudget.length > 0 && myBudget[0]?.read === true;

  //Send common props for dashboard
  const commonParams = {
    //common params needed for all components
    userAuth: userAuth,
    CONST: CONST,
    // Add more parameters here if needed
  };

  useEffect(() => {
    const searchUrl = createQueryParams({});
    dispatch(setDestinationPath(searchUrl));
    if (
      userAuth.isAuthenticated &&
      userAuth.role !== CONST.ADMIN &&
      userAuth.role !== CONST.SUPER_ADMIN
    ) {
      fetchDashBoardData();
      setInitialFetch(false);
    }
    if (userAuth.isSignature === false) {
      setShowSignatureModal(true);
    }
  }, [userAuth.role, userAuth.dateInfo, office]);

  const fetchDashBoardData = async () => {
    const requestData = {
      type: "dashboard",
      dateInfo: userAuth.dateInfo,
      office: office,
    };

    const response = await ApiCall.service(
      GET_DASHBOARD,
      "POST",
      requestData,
      true,
      CONST.CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );

    if (response.status === 200) {
      setDisplayDate(response.data["targetData"]["displayDates"]);
      setCountData(response.data["targetData"]["targets"]);
      setOffices(mapToSelect(response.data["targetData"]["offices"] ?? []));
      const budgetRes = response.data?.["budgetData"];

      setBudgetData((prevState) => ({
        myBudget: {
          ...prevState.myBudget,
          goal: budgetRes.myBudget?.goal ?? prevState.myBudget.goal,
          achieved: budgetRes.myBudget?.achieved ?? prevState.myBudget.achieved,
          showMessage:
            budgetRes.myBudget?.showMessage ?? prevState.myBudget.showMessage,
          percentage:
            budgetRes.myBudget?.percentage ?? prevState.myBudget.percentage,
        },
        officeBudget: {
          ...prevState.officeBudget,
          goal: budgetRes.officeBudget?.goal ?? prevState.officeBudget.goal,
          achieved:
            budgetRes.officeBudget?.achieved ?? prevState.officeBudget.achieved,
          percentage:
            budgetRes.officeBudget?.percentage ??
            prevState.officeBudget.percentage,
        },
      }));

      setNewCounts({
        newVacancies: response.data?.["newVacancies"],
        newCandidates: response.data?.["newCandidates"],
      });

      if (response.data?.followUps) {
        setFollowUps(response.data.followUps);
      }

      if (initialFetch) {
        setOffice({
          label: response.data?.["targetData"]["office"]?.["name"],
          value: response.data?.["targetData"]["office"]?.["value"],
        });
      }
    }
  };

  const confirmationPopup = (id: string) => {
    setDeleteId(id);
    setShowModal(true);
  };

  const handleFollowUpClose = async () => {
    if (deleteID) {
      const requestData = {
        id: deleteID,
      };

      const response = await ApiCall.service(
        CLOSE_FOLLOW_UP,
        "POST",
        requestData,
        true,
        CONST.CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );

      if (response.status === 200) {
        setFollowUps((prevItems) =>
          prevItems.map((item) =>
            item.id === deleteID ? { ...item, status: true } : item
          )
        );
        CustomNotify({ type: "success", message: response.msg });
      }
      setDeleteId(undefined);
      setShowModal(false);
    }
  };

  const handleModalClose = () => setShowModal(!showModal);

  const handleAddFeedback = async () => {
    setShowModal(false);
    navigate(`/edit/follow-up/${deleteID}?close=1`);
  };

  const handleSelectChange = (selectedOption: any) => {
    setOffice(selectedOption);
  };

  //Remainder for adding signature whenever a user login untill he adds his signature
  const dispatch = useDispatch();
  const [showSignatureModal, setShowSignatureModal] = useState(false);

  const handleSignatureModalClose = () => {
    setShowSignatureModal(false);
    dispatch(addUserDetails({ isSignature: true }));
  };

  const handleSignatureModal = () => {
    setShowSignatureModal(false);
    navigate("/add/signature");
    dispatch(addUserDetails({ isSignature: true }));
  };

  return (
    <div style={{ paddingLeft: "0.5vw", paddingRight: "0.5vw" }}>
      <div className="row pt-1">
        <div className="col-12 dashboardPadding">
          {userAuth.role === "ADMIN" || userAuth.role === "SUPER_ADMIN" ? (
            <AdminDashboard />
          ) : (
            <div className="salesDashboard">
              <div className="row equal-cols marginBotttom1 dashboardWrapper">
                <div className="col-12">
                  <div className="row">
                    <div className="col-12">
                      <WelcomeInfo params={commonParams} />
                    </div>
                  </div>
                </div>
                <div className={isMyBudgetReadable ? "col-8" : "col-12"}>
                  <div className="row equal-cols">
                    <TargetDashboard
                      params={commonParams}
                      countData={countData}
                      displayDate={displayDate}
                      isMyBudget={isMyBudgetReadable}
                      office={office}
                    />
                  </div>
                </div>
                {isMyBudgetReadable && (
                  <BudgetDashboard
                    budget={totalBudget}
                    params={commonParams}
                    offices={offices}
                    onChange={handleSelectChange}
                    selected={office}
                  />
                )}
                <div className="col-12" style={{paddingTop:"1.5vw"}}>
                  <div className="row">
                    <div className="col-12">
                      <TodoDashboard
                        followUps={followUps}
                        params={commonParams}
                        newVacancies={newCounts?.newVacancies}
                        newCandidates={newCounts?.newCandidates}
                        handleFollowUpClose={confirmationPopup}
                        office={office}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <ModalPopup
                show={showModal}
                onHide={handleModalClose}
                title={t("Feedback")}
                body={t("Do you want to add feedback") + "?"}
                onCloseButtonClick={handleFollowUpClose}
                onConfirmButtonClick={handleAddFeedback}
                className="modal-lg"
                confirmTitle={t("Yes")}
                closeTitle={t("No")}
              />
              <ModalPopup
                show={showSignatureModal}
                onHide={handleSignatureModalClose}
                title={t("Signature")}
                body={t("Please add signature")}
                onConfirmButtonClick={handleSignatureModal}
                className="modal-md"
                confirmTitle={t("Add")}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
