import { t, translate } from "components/CentralDataMangement/translation/Translation";
import Button from "components/common/atoms/Button";
import Title from "components/common/atoms/Title";
import LabelWithCKEditorField from "components/common/molecules/LabelWithCKEditorField";
import React, { useEffect, useState } from "react";
import "./css/todos.css";
import { OptionProps, Option, ValidationRules } from "components/common/utlis/TypeAnnotations";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import CloseFile from "static/images/CloseFile";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { ApiCall } from "components/common/services/ApiServices";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "Constants";
import { mapToSelect } from "components/common/utlis/MapToSelect";
import { UPDATE_TODO } from "routes/ApiEndpoints";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import RadioField from "components/common/atoms/RadioField";
import LabelField from "components/common/atoms/LabelField";
import { AcceptData } from "./CompanyPreInterviewPage";
import TimeSlot from "./TimeSlot";
import { validateForm, validateTimeRange } from "components/common/services/ValidationService";
import LoadingIcon from "components/common/utlis/LoadingIcon";

interface Interview {
    [key: string]: string | null | Option;
    feedback: string;
    feedbackBy: Option | null;
}

const InterviewFeedbackPage: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState<Interview[]>([
        {
            feedback: "",
            feedbackBy: null,
        },
    ]);
    const [interviewData, setInterviewData] = useState({
        companyName: "",
        functionName: "",
        candidateName: "",
        feedBackByOptions: [] as OptionProps[],
        candidateStatus: null,
        candidateFeedbackBy: false,
        error: ""
    })

    const [acceptData, setAcceptData] = useState<AcceptData[]>([
        {
            id: null,
            date: null,
            from: "",
            to: ""
        }
    ]);

    const userAuth = useSelector(selectAuth);
    const [active, setActive] = useState(1);
    const location = useLocation();

    const navigate = useNavigate();

    const { todoExtensionId, companyId, candidateId, todoId, functionName, presentationId } = useParams<{
        todoExtensionId: string;
        companyId: string;
        candidateId: string;
        todoId: string;
        functionName: string;
        presentationId: string
    }>();

    const handleCKEditorChange = (
        event: any,
        editor: any,
        fieldName: string,
        index: number,
    ) => {
        const content = editor.getData(); // Get the content from the editor
        setFormData((prevData) => {
            const updatedData = [...prevData];
            updatedData[index] = {
                ...updatedData[index],
                [fieldName]: content,
            };
            return updatedData;
        });
    };

    const areDetailsFilled = () => {
        const firstElement = acceptData[0];
        if (firstElement) { // Ensure that the array is not empty
            const { date, from } = firstElement; // Destructure the object
            return date !== null && from !== '' && from !== 'hh:mm';
        }
        return false; // If array is empty, return false
    };

    const handleAccept = async (event: any, type: string) => {
        event.preventDefault();
        const data = {
            candidateFeedbackBy: interviewData.candidateFeedbackBy,
            submitType: interviewData.candidateStatus === 2 ? "reject" : "accept",
            round: interviewData.candidateStatus === 0 ? "nextRound" : (interviewData.candidateStatus === 1 ? "nextStage" : "reject"),
            formData: formData,
            timeSlots: acceptData,
            todoExtensionId: todoExtensionId,
            companyId: companyId,
            candidateId: candidateId,
            todoId: todoId,
            stage: interviewData.candidateStatus === 0 ? 'Presented' : 'Interview',
            todoType: "interview",
            entityType: "company",
            functionName: functionName,
            presentationId: presentationId
        };
        let filled = true;
        let errorsPresent = false;
        if (interviewData.candidateStatus === 0) {
            filled = areDetailsFilled();
            if (!filled) {
                setInterviewData((prevData) => ({
                    ...prevData,
                    error: t("Please schedule interview timings")
                }));
            } else {
                setInterviewData((prevData) => ({
                    ...prevData,
                    error: ""
                }));
            }
            errorsPresent = errors.some(error => error.from !== '' || error.to !== '');
        }
        if (filled && !errorsPresent) {
            setLoading(true);
            const response = await ApiCall.service(
                UPDATE_TODO,
                "POST",
                data,
                false,
                CENTRAL_DATA_MANAGEMENT_MICROSERVICE
            );
            if (response.status === 200) {
                navigate(location.state?.origin === "todos" ? "/todos/overview" : "/dashboard");
            }
            setLoading(false);
        }
    };

    const addFieldSet = () => {
        const newFields = {
            description: "",
            feedbackBy: null,
        };
        setFormData((prevData: any) => [...prevData, newFields]);
        if (interviewData.feedBackByOptions.length === formData.length + 1) {
            setActive(0);
        }
    };

    const removeFieldSet = (currentIndex: number) => {
        setFormData((prevData) => {
            const updatedData = [...prevData];
            updatedData.splice(currentIndex, 1);
            return updatedData;
        });
        if (interviewData.feedBackByOptions.length !== formData.length + 1) {
            setActive(1);
        }
    };
    const handleDynamicSelectChange = (
        selectedOption: any,
        name: string,
        index: number
    ) => {
        setFormData((prevData) => {
            const updatedData = [...prevData];
            updatedData[index] = {
                ...updatedData[index],
                [name]: selectedOption !== null ? selectedOption : "",
            };
            return updatedData;
        });
        setInterviewData((prev) => ({
            ...prev,
            candidateFeedbackBy: selectedOption['value'] === 1 ? true : false
        }))
    };

    useEffect(() => {
        const data = {
            companyId: companyId,
            candidateId: candidateId
        }
        const getInterviewDetails = async () => {
            const response = await ApiCall.service("getInterviewDetails",
                "POST",
                data,
                true,
                CENTRAL_DATA_MANAGEMENT_MICROSERVICE);
            if (response.status === 200) {
                setInterviewData((prev) => ({
                    ...prev,
                    feedBackByOptions: mapToSelect(response.data.feedBackByDropdown),
                    candidateName: response.data.candidateName,
                    companyName: response.data.companyName,
                    functionName: functionName || "",
                }))
            }
        }
        getInterviewDetails();
    }, []);

    const getFeedBackByOptions = () => {
        const updatedDropDown = { ...interviewData };

        formData.forEach((item) => {
            if (item.feedbackBy) {
                updatedDropDown.feedBackByOptions = updatedDropDown.feedBackByOptions.filter(
                    (option) => option.value !== item.feedbackBy?.value
                );
            }
        });

        return updatedDropDown.feedBackByOptions;
    };

    const handleFieldChange = (
        e: React.ChangeEvent<
            HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
    ) => {
        const { name, value, type } = e.target;
        if (type === "radio") {
            setInterviewData((prevData: any) => ({
                ...prevData,
                [name]: parseInt(value)
            }));
            if (name === "candidateStatus") {
                setAcceptData([
                    { id: null, date: null, from: "", to: "" }
                ]);
            }
        } else {
            setInterviewData((prevData: any) => ({
                ...prevData,
                [name]: value
            }));
        }
    };

    const [errors, setErrors] = useState<{ from: string; to: string }[]>([{ from: "", to: "" }]);
    const validation = (
        name: string,
        value: string | boolean | Date | object[] | undefined,
        index: number,
        isSingleFieldValidation: boolean = false
    ) => {
        const validationRules: ValidationRules = {
            from: [(value: any) => validateTimeRange(value, acceptData[index].to, name)],
            to: [(value: any) => validateTimeRange(acceptData[index].from, value, name)],
        };
        const validationErrors = validateForm(
            { ...acceptData[index], [name]: value },
            validationRules,
            isSingleFieldValidation ? name : undefined
        );
        setErrors(prevErrors => {
            const updatedErrors = [...prevErrors];
            updatedErrors[index] = {
                ...updatedErrors[index],
                [name]: validationErrors[name] || ''
            };
            return updatedErrors;
        });;
        const isFieldValid = Object.keys(validationErrors).length === 0;

        if (isFieldValid) {
            setErrors(prevErrors => {
                const updatedErrors = [...prevErrors];
                updatedErrors[index] = {
                    ...updatedErrors[index],
                    from: '',
                    to: ''
                };
                return updatedErrors;
            });;

            if (Object.keys(validationErrors).length > 0) {
                return false;
            }

            return true;
        }
    }

    //Time slots storing
    const handleDynamicDateChange = (
        date: Date | null,
        name: string,
        index: number
    ) => {
        const updatedDate = date !== null ? date : new Date();

        setAcceptData((prevData) => {
            const updatedData = [...prevData];
            updatedData[index] = {
                ...updatedData[index],
                [name]: updatedDate !== null ? updatedDate : ""
            };
            return updatedData;
        });
        setInterviewData((prevData) => ({
            ...prevData,
            error: ""
        }));
    };

    // Add and remove fields
    const addTimeFieldSet = () => {
        const newTimeSlot = {
            id: null,
            date: null,
            from: "",
            to: ""
        };
        setAcceptData((prevData) => [...prevData, newTimeSlot]);
    };

    const removeTimeFieldSet = (currentIndex: number) => {
        const updatedData = [...acceptData];
        updatedData.splice(currentIndex, 1);
        setAcceptData(updatedData);
    };

    // Time field
    const handleTimeChange = (index: number, name: string, value: string, field: string) => {

        if (field === 'time' || name === 'from' || name === 'to') {
            setAcceptData((prevData) => {
                const updatedData = [...prevData];
                updatedData[index] = {
                    ...updatedData[index],
                    [name]: value !== null ? value : ""
                };
                return updatedData;
            });
            validation(name, value, index, true);
        }
    }

  return (
    <>
      <div>
        <Title title={t("Interview feedback")} />
        <form>
          <div className="form-border">
            <div className="row">
              <div className="col-sm-12 col-md-4">
                <LabelWithInputField
                  name="CandidateName"
                  value={interviewData.candidateName}
                  id="CandidateName"
                  label={t("Candidate name")}
                  isMandatory={false}
                  isDisabled={true}
                />
              </div>
              <div className="col-sm-12 col-md-4">
                <LabelWithInputField
                  name="FunctionName"
                  value={interviewData.functionName}
                  id="FunctionName"
                  label={t("Function name")}
                  isMandatory={false}
                  isDisabled={true}
                />
              </div>
              <div className="col-sm-12 col-md-4">
                <LabelWithInputField
                  name="CompanyName"
                  value={interviewData.companyName}
                  id="CompanyName"
                  label={t("Company name")}
                  isMandatory={false}
                  isDisabled={true}
                />
              </div>
            </div>
            {formData.map((item, index) => (
              <div className="row">
                <div className="col-md-12">
                  {index > 0 && (
                    <>
                      <div className="d-block d-md-block d-lg-none">
                        <Button
                          title={t("Remove")}
                          handleClick={() => removeFieldSet(index)}
                          className="link-button float-end rounded-0 shadow-none mt-4"
                        />
                      </div>
                      <div className="d-none d-md-none d-lg-block">
                        <span
                          title={t("Delete")}
                          onClick={() => removeFieldSet(index)}
                          className="table-action-icons cursor-pointer float-end rounded-0 shadow-none"
                        >
                          <CloseFile />
                        </span>
                      </div>
                    </>
                  )}
                </div>
                {userAuth.role !== "EMPLOYER" && (
                  <div className="row">
                    <div className="col-md-4 col-sm-12">
                      <SelectWithSearch
                        title={t("Feedback by")}
                        name="feedbackBy"
                        placeHolder={t("Select")}
                        isMandatory={false}
                        search={true}
                        options={getFeedBackByOptions()}
                        value={item.feedbackBy}
                        onChange={(e) =>
                          handleDynamicSelectChange(e, "feedbackBy", index)
                        }
                        isMulti={false}
                        className="select-field"
                        isTranslate={true}
                      />
                    </div>
                  </div>
                )}
                <div className="col-12">
                  <LabelWithCKEditorField
                    label={t("Feedback")}
                    name="feedback"
                    value={item.feedback}
                    placeholder={t("Type here") + "..."}
                    handleChange={(event, editor) =>
                      handleCKEditorChange(event, editor, "feedback", index)
                    }
                    className="field-shadow"
                  />
                </div>
              </div>
            ))}
            {!interviewData.candidateFeedbackBy && active === 1 && (
              <>
                {userAuth.role !== "EMPLOYER" && (
                  <div className="row my-4">
                    <div className="col-md-12">
                      <Button
                        title={"+ " + t("Add another")}
                        handleClick={addFieldSet}
                        className="form-button float-end px-3 shadow-none"
                      />
                    </div>
                  </div>
                )}
              </>
            )}
            {!interviewData.candidateFeedbackBy && (
              <>
                <div className="row">
                  <div className="col-sm-12 col-md-6">
                    <LabelField
                      title={t("Candidate status?")}
                      isMandatory={false}
                      className="feedbackQnsText pt-0 pb-2"
                    />
                  </div>
                </div>
                <div className="d-flex">
                  <div>
                    <RadioField
                      name="candidateStatus"
                      value={0}
                      disable={false}
                      ischecked={interviewData.candidateStatus === 0}
                      handleChange={handleFieldChange}
                      label={t(
                        "Selected and proceed to next round of interview"
                      )}
                    />
                  </div>
                  <div className="ms-3"> 
                    <RadioField
                      name="candidateStatus"
                      value={1}
                      disable={false}
                      ischecked={interviewData.candidateStatus === 1}
                      handleChange={handleFieldChange}
                      label={t("Selected")}
                      className="ms-xl-3 mt-2 mt-xl-0"
                    />
                  </div>
                  <div className="ms-3">
                    <RadioField
                      name="candidateStatus"
                      value={2}
                      disable={false}
                      ischecked={interviewData.candidateStatus === 2}
                      handleChange={handleFieldChange}
                      label={t("Rejected")}
                      className="ms-xl-3 mt-2 mt-xl-0"
                    />
                  </div>
                </div>
                {interviewData.candidateStatus === 0 && (
                  <>
                    <div className="mt-5">
                      <LabelField
                        title={t("Please add the time slots for next round")}
                        isMandatory={false}
                        className="feedbackQnsText pb-2 pt-0"
                      />
                    </div>
                    <div>
                      <TimeSlot
                        acceptData={acceptData}
                        handleDynamicDateChange={handleDynamicDateChange}
                        handleTimeChange={handleTimeChange}
                        errors={errors}
                        removeFieldSet={removeTimeFieldSet}
                        addFieldSet={addTimeFieldSet}
                      />
                    </div>
                    <div
                      className="height-20"
                      style={{ marginBottom: "0.5vw" }}
                    >
                      {interviewData.error && (
                        <span className="text-danger">
                          {interviewData.error}
                        </span>
                      )}
                    </div>
                  </>
                )}
              </>
            )}
          </div>
            <div className="row backPadding">
              <div className="col-md-6 align-self-center">
                <Link
                  to={
                    location.state?.origin === "todos"
                      ? "/todos/overview"
                      : "/dashboard"
                  }
                  className="back-btn text-decoration-underline"
                >
                  {t("Back")}
                </Link>
              </div>
              <div className="col-md-6 align-self-center d-flex flex-row justify-content-end">
                {interviewData.candidateStatus !== 1 && (
                  <>
                    {!loading ? (
                      <Button
                        title={t("Save")}
                        type="submit"
                        handleClick={(e) => handleAccept(e, "save")}
                        className="form-button shadow-none"
                      />
                    ) : (
                      <LoadingIcon
                        iconType="bars"
                        color="#e55496"
                        className="ms-auto"
                        width={"3vw"}
                        height={"3vw"}
                      />
                    )}
                  </>
                )}
                {interviewData.candidateStatus === 1 && (
                  <>
                    {!loading ? (
                      <Button
                        title={t("Proceed further")}
                        type="submit"
                        handleClick={(e) => handleAccept(e, "accept")}
                        className="form-button px-3 shadow-none"
                      />
                    ) : (
                      <LoadingIcon
                        iconType="bars"
                        color="#e55496"
                        className="ms-auto"
                        height={"3vw"}
                        width={"3vw"}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
        </form>
      </div>
    </>
  );
};

export default translate(InterviewFeedbackPage);
