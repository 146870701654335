import GetQueryParams from "components/common/services/GetQueryParams";
import {
  createQueryParams,
  filterAndMerge,
  formatDateAndTime,
  getQueryParamsFromRedux,
} from "components/common/utlis/HelperFunctions";
import { Option } from "components/common/utlis/TypeAnnotations";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { RootState } from "store";
import { createFilterConfig } from "./Filters";
import {
  clearDestinationPath,
  setDestinationPath,
} from "features/NavigationSlice";
import { ApiCall } from "components/common/services/ApiServices";
import {
  CREATE_USER_IN_SALES_NOTE,
  DELETE_USER,
  FORGOT_PASSWORD,
  GET_USERS,
} from "routes/ApiEndpoints";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import { Link, useNavigate } from "react-router-dom";
import PaginationNew from "components/common/atoms/PaginationNew";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import Title from "components/common/atoms/Title";
import SearchFields from "components/common/atoms/SearchFields";
import ResetBtn from "components/common/atoms/ResetBtn";
import Sorting from "components/common/atoms/Sorting";
import { Spinner } from "react-bootstrap";
import ActionIconWrapper from "components/common/atoms/ActionIconWrapper";
import ViewIcon from "static/images/ViewIcon";
import InfoIcon from "static/images/InfoIcon";
import EditIcon from "static/images/EditIcon";
import CreateUser from "static/images/CreateUser";
import ButtonWithChildren from "components/common/atoms/ButtonWithChildren";
import EnvelopIcon from "static/images/EnvelopIcon";
import {
  CANDIDATE_ROLE,
  CENTRAL_DATA_MANAGEMENT_MICROSERVICE,
  EMPLOYER,
  UserStatus,
} from "Constants";
import ArchiveNew from "static/images/ArchiveNew";
import CustomNotify from "components/common/atoms/CustomNotify";
import ModalPopup from "components/common/atoms/ModalPopup";
import LinkTo from "components/common/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import SpinnerWrapper from "components/common/atoms/SpinnerWrapper";
import NoRecords from "components/common/atoms/NoRecords";
import UserInfoTooltip from "components/common/atoms/UserInfoTooltip";

export interface UsersData {
  office: string;
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  role: string;
  unique_role: string;
  status: number;
  user_id: number;
  salesNoteisActive: boolean;
  createdat: string;
  updated_by: string | null;
  created_by: string | null;
  updated_at: Date | null;
  created_at: Date | null;
}

export interface SearchProps {
  office: number[];
  firstName: string;
  lastName: string;
  email: string;
  role: Option | null;
  status: Option | null;
  from: Date | null | string;
  to: Date | null | string;
  currentPage: number;
  recordsPerPage: number;
  orderByColumn: string;
  orderIn: "" | "asc" | "desc";
}
const ManageUsers: React.FC = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [deleteId, setDeleteId] = useState<number | undefined>();
  const [showModal, setShowModal] = useState(false);
  const [users, setUsers] = useState<UsersData[]>([]);

  const [paginationData, setPaginationData] = useState({
    totalPages: 1,
    totalRecords: 1,
    cursor: null,
  });

  const [dropdownList, setDropdownList] = useState({
    offices: [],
    roles: [],
    status: [],
  });

  const destinationPath = useSelector(
    (state: RootState) => state.navigation.destinationPath
  );

  const getUrlParams = GetQueryParams();
  const paramFilter = getUrlParams.get("filter");
  const queryParams = getQueryParamsFromRedux(destinationPath, paramFilter);

  const filters = createFilterConfig(dropdownList);

  // Initialize search data based on query params
  const defaultSearchData: SearchProps = {
    office: [],
    firstName: "",
    lastName: "",
    email: "",
    role: null,
    status: null,
    from: "",
    to: "",
    currentPage: 1,
    recordsPerPage: 10,
    orderByColumn: "",
    orderIn: "",
  };

  //active tab status
  const defaultTabData = {
    tabFieldName: "companyType",
    tabFieldValue: null,
  };

  const filteredSearchData = filterAndMerge(defaultSearchData, queryParams);

  // Initialize search data based on query params, merging with default values
  const [searchData, setSearchData] = useState<SearchProps>({
    ...defaultSearchData,
    ...filteredSearchData,
  });

  useEffect(() => {
    dispatch(clearDestinationPath());
    const filterData = { ...searchData };
    const searchUrl = createQueryParams(filterData);
    dispatch(setDestinationPath(searchUrl));
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      fetchDetails(filterData);
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [searchData]);

  const fetchDetails = async (filterData: any) => {
    setLoading(true);
    const data = {
      search: filterData,
    };

    const response = await ApiCall.service(GET_USERS, "POST", data, false);
    if (response.status === 200) {
      const { usersData, dropdownData, paginationData } = response.data;
      setUsers(usersData);
      setPaginationData(paginationData);
      setDropdownList(dropdownData);
    }
    setLoading(false);
  };

  //** change handlers */
  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: 1,
      [name]: value,
    }));
  };

  const handlePageChange = (pageNumber: number) => {
    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: pageNumber,
    }));
  };

  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: 1,
      [fieldName]: selectedOption,
    }));
  };

  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    setSearchData((prev) => ({
      ...prev,
      recordsPerPage: newItemsPerPage,
      currentPage: 1,
    }));
  };

  //** search filters */
  const handleReset = async (e: React.FormEvent) => {
    dispatch(clearDestinationPath());
    setSearchData(defaultSearchData);
    fetchDetails({
      ...defaultSearchData,
      ...defaultTabData,
    });
  };

  const handleDateChange = (date: Date | null, fieldName: string) => {
    setSearchData((prevSearch) => ({
      ...prevSearch,
      [fieldName]: date,
    }));
  };

  const handleOrderByColumn = (column: string) => {
    let newOrder: "" | "asc" | "desc" = ""; // Explicit type declaration
    let newColumn = column;

    if (searchData.orderByColumn === column) {
      // Cycle through the three possible order values: '', 'asc', 'desc'
      if (searchData.orderIn === "") {
        newOrder = "asc";
      } else if (searchData.orderIn === "asc") {
        newOrder = "desc";
      } else {
        newOrder = "";
        newColumn = "";
      }
    } else {
      newOrder = "asc";
    }

    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: 1,
      orderByColumn: newColumn,
      orderIn: newOrder,
    }));
  };

  const sendActivationMail = async (email: string) => {
    const data = {
      email: email,
    };
    const response = await ApiCall.service(FORGOT_PASSWORD, "POST", data, true);
    if (response.status === 200) {
      CustomNotify({ type: "success", message: t(response.msg) });
    }
  };

  const createUserInSalesNote = async (e: any, userId: number | undefined) => {
    if (userId) {
      const data = {
        id: userId,
      };
      const response = await ApiCall.service(
        CREATE_USER_IN_SALES_NOTE,
        "POST",
        data,
        false,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.status === 200) {
        fetchDetails({
          ...defaultSearchData,
        });
        CustomNotify({ type: "success", message: response.data });
      }
    }
  };

  //** Archive functions */
  const ArchiveUser = (id: number | undefined) => {
    setShowModal(true);
    setDeleteId(id);
  };

  const handleCloseArchiveModal = () => setShowModal(!showModal);

  const handleArchive = async () => {
    setShowModal(false);
    if (deleteId) {
      const url = `${DELETE_USER}/${deleteId}`;
      const response = await ApiCall.getService(url, "GET");
      if (response.status === 200) {
        const url = `archiveUser/${deleteId}`; //archive user in candidate-creation
        await ApiCall.getService(url, "GET", "candidate-creation");
        CustomNotify({ type: "success", message: t(response.msg) });
        setUsers((prevdata) =>
          prevdata.filter((user) => user.user_id !== deleteId)
        );
      }
    }
  };
  // **^^&^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ EDIT OR VIEW POP UP START !!!!!!!!!!!!!!!!!!!!^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
  const navigate = useNavigate();

  const [canId, setCanId] = useState(null);

  const [editModal, setEditModal] = useState(false);

  const handleEdit = (canId: any) => {
    setCanId(canId);
    setEditModal(true);
  };

  const handleCloseModal = () => {
    setEditModal(false);
  };

  const handleConfirm = () => {
    setEditModal(false);
    navigate(
      `/candidate/edit/${canId}?tabId=general&destination=/manage-users`,
      {
        state: { origin: "manageUsers" },
      }
    );
  };

  const handleClose = () => {
    setEditModal(false);
    navigate(
      `/candidate/view/${canId}?tabId=general&destination=/manage-users`,
      {
        state: { origin: "manageUsers" },
      }
    );
  };

  // **^^&^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ EDIT OR VIEW POP UP END !!!!!!!!!!!!!!!!!!!!^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Users",
          read: true,
        },
      ]}
      renderNoAccess={true}
    >
      <div className={`main-container`}>
        <Title title={t("Users")} />
        <div className="form-height-dashboard">
          <div className="position-relative tableMainWrapper">
            <div className="row">
              <div className="ManageCreateBtn">
                <AccessControl
                  requiredPermissions={[
                    {
                      permission: "Users",
                      create: true,
                    },
                  ]}
                >
                  <LinkTo
                    pagelink="/user/create"
                    title={t("Create user")}
                    icon={faPlus}
                    className="me-3"
                  />
                </AccessControl>
                <AccessControl
                  requiredPermissions={[
                    {
                      permission: "Candidate",
                      create: true,
                    },
                  ]}
                >
                  <LinkTo
                    pagelink="/candidate/create?tabId=general&destination=/manage-users"
                    title={t("Create candidate")}
                    icon={faPlus}
                  />
                </AccessControl>
              </div>
            </div>

            <div className="table-responsive tableSection">
              <table className="table manageUsers border-0 mb-0">
                <thead>
                  <tr className="tableSearchFields">
                    <SearchFields
                      searchData={searchData}
                      filters={filters}
                      handleSearchChange={handleSearchChange}
                      handleSelectChange={handleSelectChange}
                      handleDateChange={handleDateChange}
                    />
                    <th className="border-0">
                      <div className="d-flex align-items-center w-100">
                        <ResetBtn handleResetClick={handleReset} />
                      </div>
                    </th>
                  </tr>
                </thead>
              </table>
              <div className="tableContainerWithoutTabs scrollBarDesign">
                <table className="table table-hover manageUsers">
                  <thead>
                    <tr className="TableHeader">
                      <th onClick={() => handleOrderByColumn("office")}>
                        {t("Office")}
                        <Sorting
                          sortType={
                            searchData.orderByColumn === "office"
                              ? searchData.orderIn
                              : ""
                          }
                        />
                      </th>
                      <th onClick={() => handleOrderByColumn("firstName")}>
                        {t("First name")}
                        <Sorting
                          sortType={
                            searchData.orderByColumn === "firstName"
                              ? searchData.orderIn
                              : ""
                          }
                        />
                      </th>
                      <th onClick={() => handleOrderByColumn("lastName")}>
                        {t("Last name")}
                        <Sorting
                          sortType={
                            searchData.orderByColumn === "lastName"
                              ? searchData.orderIn
                              : ""
                          }
                        />
                      </th>
                      <th onClick={() => handleOrderByColumn("email")}>
                        {t("Email")}
                        <Sorting
                          sortType={
                            searchData.orderByColumn === "email"
                              ? searchData.orderIn
                              : ""
                          }
                        />
                      </th>
                      <th onClick={() => handleOrderByColumn("role")}>
                        {t("Role")}
                        <Sorting
                          sortType={
                            searchData.orderByColumn === "role"
                              ? searchData.orderIn
                              : ""
                          }
                        />
                      </th>
                      <th>{t("Status")}</th>
                      <th onClick={() => handleOrderByColumn("createdAt")}>
                        {t("Created at")}
                        <Sorting
                          sortType={
                            searchData.orderByColumn === "createdAt"
                              ? searchData.orderIn
                              : ""
                          }
                        />
                      </th>
                      <AccessControl
                        requiredPermissions={[
                          {
                            permission: "Users",
                            update: true,
                            delete: true,
                            read: true,
                          },
                        ]}
                        actions={true}
                        strict={false}
                      >
                        <th>{t("Action")}</th>
                      </AccessControl>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <SpinnerWrapper headerLength={8} />
                    ) : (
                      <>
                        {users && users.length > 0 ? (
                          users.map((user: UsersData, index: number) => {
                            let color =
                              user.status === 0
                                ? "bg-warning"
                                : user.status === 1
                                ? "bg-success"
                                : "bg-danger";
                            return (
                              <tr
                                key={index}
                                className="border box-shadow align-middle"
                              >
                                <td
                                  className="text-break"
                                  data-label={t("Office")}
                                >
                                  {user.office}
                                </td>
                                <td
                                  className="text-break"
                                  data-label={t("First name")}
                                >
                                  {user.first_name}
                                </td>
                                <td
                                  className="text-break"
                                  data-label={t("Last name")}
                                >
                                  {user.last_name}
                                </td>
                                <td
                                  className="text-break"
                                  data-label={t("Email")}
                                >
                                  {user.email}
                                </td>
                                <td
                                  className="text-break"
                                  data-label={t("Role")}
                                >
                                  {user.role}
                                </td>
                                <td className="text-break ps-3">
                                  <span
                                    className={`d-inline-block rounded-circle status-icon ${color}`}
                                    data-toggle="tooltip"
                                    title={t(`${UserStatus[user.status ?? 0]}`)}
                                  ></span>
                                </td>
                                <td
                                  className="text-break"
                                  data-label={t("Status")}
                                >
                                  {user.createdat}
                                </td>
                                <AccessControl
                                  requiredPermissions={[
                                    {
                                      permission: "Users",
                                      update: true,
                                      delete: true,
                                      read: true,
                                    },
                                  ]}
                                  actions={true}
                                  strict={false}
                                >
                                  <td className="table-action-icons px-2">
                                    <ActionIconWrapper initialVisibleCount={4}>
                                      {/* Desktop */}
                                      {user.unique_role !== "CANDIDATE" ? (
                                        <AccessControl
                                          requiredPermissions={[
                                            {
                                              permission: "Users",
                                              update: true,
                                            },
                                          ]}
                                        >
                                          <div className="py-1 d-inline-block">
                                            <Link
                                              to={`/user/edit/${user.user_id}`}
                                              title={t("Edit")}
                                            >
                                              <EditIcon />
                                            </Link>
                                          </div>
                                        </AccessControl>
                                      ) : (
                                        <AccessControl
                                          requiredPermissions={[
                                            {
                                              permission: "Candidate",
                                              update: true,
                                            },
                                          ]}
                                        >
                                          {/* <Link
                                            to={`/candidate/edit/${user.user_id}`}
                                            state={{ origin: "manageUsers" }}
                                            title={t("Edit")}
                                          >
                                            <EditIcon />
                                          </Link> */}
                                          <Link
                                            to={""}
                                            title={t("Edit")}
                                            onClick={() =>
                                              handleEdit(user.user_id)
                                            }
                                            state={{ origin: "manageUsers" }}
                                          >
                                            <EditIcon />
                                          </Link>
                                        </AccessControl>
                                      )}

                                      <AccessControl
                                        requiredPermissions={[
                                          {
                                            permission: "Users",
                                            read: true,
                                          },
                                        ]}
                                      >
                                        <Link
                                          to={`/user/view/${user.user_id}`}
                                          title={t("View")}
                                        >
                                          <ViewIcon />
                                        </Link>
                                      </AccessControl>

                                      <AccessControl
                                        requiredPermissions={[
                                          {
                                            permission: "Users",
                                            delete: true,
                                          },
                                        ]}
                                      >
                                        <div
                                          title={t("Archive")}
                                          onClick={() =>
                                            ArchiveUser(user.user_id)
                                          }
                                          className="d-inline-block cursor-pointer"
                                        >
                                          <ArchiveNew />
                                        </div>
                                      </AccessControl>

                                      <AccessControl
                                        requiredPermissions={[
                                          {
                                            permission: "Users",
                                            update: true,
                                          },
                                        ]}
                                      >
                                        <ButtonWithChildren
                                          className="btn p-0 border-0"
                                          title={t("Send activation mail")}
                                          onClick={() =>
                                            sendActivationMail(user.email)
                                          }
                                        >
                                          <EnvelopIcon />
                                        </ButtonWithChildren>
                                      </AccessControl>

                                      {user.unique_role !== CANDIDATE_ROLE &&
                                        user.unique_role !== EMPLOYER &&
                                        (user.salesNoteisActive == null ||
                                          user.salesNoteisActive === false) && (
                                          <Link
                                            className="cursor-pointer"
                                            title={t(
                                              "Create sales note account"
                                            )}
                                            to=""
                                            onClick={(e: any) =>
                                              createUserInSalesNote(
                                                e,
                                                user.user_id
                                              )
                                            }
                                          >
                                            <CreateUser />
                                          </Link>
                                        )}
                                      <div>
                                        <UserInfoTooltip
                                          createdAt={formatDateAndTime(
                                            user.created_at
                                          )}
                                          updatedAt={formatDateAndTime(
                                            user.updated_at
                                          )}
                                          createdBy={user.created_by}
                                          updatedBy={user.updated_by}
                                        />
                                      </div>
                                    </ActionIconWrapper>
                                  </td>
                                </AccessControl>
                              </tr>
                            );
                          })
                        ) : (
                          <NoRecords headerLength={8} />
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              {!loading && (
                <div className="pagination d-block">
                  <PaginationNew
                    currentPage={searchData.currentPage}
                    totalPages={paginationData.totalPages}
                    totalRecords={paginationData.totalRecords}
                    recordsPerPage={searchData.recordsPerPage}
                    onItemsPerPageChange={handleItemsPerPageChange}
                    onPageChange={(newPage: any) => handlePageChange(newPage)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className="col-md-6 align-self-center"
          style={{ padding: "1vw 0" }}
        >
          <Link to="/dashboard" className=" back-btn text-decoration-underline">
            {t("Back")}
          </Link>
        </div>
        <ModalPopup
          show={showModal}
          onHide={handleCloseArchiveModal}
          title={t("Archive confirmation")}
          body={t("Are you sure want to archive") + "?"}
          onCloseButtonClick={handleCloseArchiveModal}
          onConfirmButtonClick={handleArchive}
          closeTitle={t("No")}
          confirmTitle={t("Yes")}
        />
        <ModalPopup
          show={editModal}
          onHide={handleCloseModal}
          title={t("Edit candidate")}
          body={t("Would you like to edit candidate") + "?"}
          onCloseButtonClick={handleClose}
          onConfirmButtonClick={handleConfirm}
          closeTitle={t("No")}
          confirmTitle={t("Yes")}
        />
      </div>
    </AccessControl>
  );
};

export default translate(ManageUsers);
