import React, { useState } from "react";
import Navigation from "../form-navigation/Navigation";
import { useFormContext } from "../../context/Context";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import { t } from "components/CentralDataMangement/translation/Translation";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import CheckBoxField from "components/common/atoms/CheckBoxField";
import TimeTable from "components/common/molecules/TimeTable";
import LabelField from "components/common/atoms/LabelField";
import InputTextfield from "components/common/atoms/InputTextField";
import {
  TIME_TABLE_DETAILS,
  UPDATE_FIELDS,
} from "../../annotation/PlanningConstants";
import {
  TimeStates,
  calculateWeekTotal,
  generateWeekLabelsFromMonday,
  getNextSunday,
} from "components/common/utlis/PlanningUtility";
import { resetShiftDetails } from "../form-data/ResetPlanningData";
import { getShiftOptionForRegime } from "components/CompanyCreation/shiftConfigurations/ShiftInterface";
import { fetchShiftDetails } from "../form-data/SetShiftData";
import { validateField } from "../form-navigation/PlanningFieldValidation";

const TimeTableTab: React.FC = () => {
  const { state, dispatch } = useFormContext();

  const [validationStatus, setValidationStatus] = useState({
    isValid: false,
    type: "",
  });

  const validStatus = (validation: any) => {
    setValidationStatus({
      isValid: validation.isValid,
      type: validation.type,
    });
  };

  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    dispatch({
      type: UPDATE_FIELDS,
      tab: TIME_TABLE_DETAILS,
      field: fieldName,
      value: selectedOption,
    });
    if (fieldName === "shift") {
      validateField(
        fieldName,
        selectedOption,
        state.basicDetails,
        dispatch,
        true
      );
    }
    if (fieldName === "timeTableType") {
      resetShiftDetails(dispatch);
    }

    if (
      fieldName === "timeTableType" &&
      selectedOption?.value !== undefined &&
      selectedOption?.value !== null
    ) {
      fetchShiftDetails("shift", selectedOption.value, dispatch);
    }
    // Handle specific logic for regime field
    if (fieldName === "regime") {
      const optionId = selectedOption ? selectedOption.value : null;
      if (optionId) {
        // Get shift options based on the selected regime value
        const shiftOption = getShiftOptionForRegime(optionId) ?? null;
        dispatch({
          type: UPDATE_FIELDS,
          tab: TIME_TABLE_DETAILS,
          field: "shift",
          value: shiftOption,
        });
      }
    }
  };

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type } = event.target;
    let updatedName: string = name;
    let updatedValue: string | null | number = value;
    // Apply filtering for specific fields that should only allow numbers and commas
    const numberFields = [
      "effectiveHrsFullTime",
      "avgHrsFullTime",
      "effectiveHrsAvg",
      "paidAdv",
      "unPaidAdv",
    ];
    if (type === "checkbox") {
      if (name === "partTimeEmployment") {
        updatedName = "workType";
        updatedValue = event.target.checked ? 0 : null;
      } else if (name === "weekendWorker") {
        updatedName = "workType";
        updatedValue = event.target.checked ? 1 : null;
      }
    } else if (numberFields.includes(name)) {
      updatedValue = value.replace(/[^0-9,]/g, "");
    }
    dispatch({
      type: UPDATE_FIELDS,
      tab: TIME_TABLE_DETAILS,
      field: updatedName,
      value: updatedValue,
    });
  };

  const handleTimeTableChange = (timeStates: TimeStates) => {
    dispatch({
      type: UPDATE_FIELDS,
      tab: TIME_TABLE_DETAILS,
      field: "timeTable",
      value: timeStates,
    });
    dispatch({
      type: UPDATE_FIELDS,
      tab: TIME_TABLE_DETAILS,
      field: "effectiveHrsAvg",
      value: calculateWeekTotal(timeStates),
    });
  };

  const weekdays = generateWeekLabelsFromMonday(
    state.basicDetails.from,
    state.basicDetails.to
  );

  return (
    <>
      <div className="form-border" style={{ paddingTop: "3.4vw" }}>
        <div className="row">
          <div className="col-md-3">
            <LabelWithInputField
              isMandatory={false}
              name="trailPeriod"
              handleChange={handleFieldChange}
              value={state.timeTableDetails.trailPeriod}
              id="trailPeriod"
              label={t("Trial period")}
              placeholder={t("Trial period")}
            />
          </div>
          <div className="col-md-3">
            <SelectWithSearch
              title={t("Select shift")}
              isMandatory={false}
              search={true}
              options={state.dropDowndata.timeTableOptions}
              onChange={(e) => handleSelectChange(e, "timeTableType")}
              isMulti={false}
              name="timeTableType"
              value={state.timeTableDetails.timeTableType}
              id="timeTableType"
              isTranslate={true}
            />
          </div>
          <div className="col-md-3">
            <SelectWithSearch
              title={t("Regime")}
              isMandatory={false}
              search={true}
              options={state.dropDowndata.regimeOptions}
              onChange={(e) => handleSelectChange(e, "regime")}
              isMulti={false}
              name="regime"
              value={state.timeTableDetails.regime}
              id="regime"
              isTranslate={true}
            />
          </div>
          <div className="col-md-3">
            <SelectWithSearch
              title={t("Shift")}
              isMandatory={false}
              search={true}
              options={state.dropDowndata.shiftOptions}
              onChange={(e) => handleSelectChange(e, "shift")}
              isMulti={false}
              name="shift"
              value={state.timeTableDetails.shift}
              id="shift"
              isTranslate={true}
              isDisabled={
                state.timeTableDetails?.regime?.value === 1 ||
                state.timeTableDetails?.regime?.value === 3
              }
              error={state.errors.shift}
            />
          </div>
          <div className="col-md-12 mb-4">
            <CheckBoxField
              label={t("Part time employment")}
              name="partTimeEmployment"
              onChangeHandler={handleFieldChange}
              isChecked={state.timeTableDetails.workType === 0}
              className="me-5"
              lineHeight="1vw"
            />
            <CheckBoxField
              label={t("Weekend worker")}
              name="weekendWorker"
              onChangeHandler={handleFieldChange}
              isChecked={state.timeTableDetails.workType === 1}
              className="me-5"
              lineHeight="1vw"
            />
          </div>
          <div className="col-md-4">
            <LabelWithInputField
              isMandatory={false}
              name="shiftName"
              handleChange={handleFieldChange}
              value={state.timeTableDetails.shiftName}
              id="shiftName"
              label={t("Shift name")}
              placeholder={t("Shift name")}
              isDisabled={true}
            />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-12">
            <TimeTable
              onChange={handleTimeTableChange}
              value={state.timeTableDetails.timeTable}
              weekDaysLabels={weekdays.labels}
              disabledDays={weekdays.disabledDays}
              quickEntryDays={weekdays.quickEntryDays}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4 mb-3">
            <LabelField
              title={t("Effective hours full-time / week employer")}
              isMandatory={false}
            />
            <div className="input-group field-shadow rounded-3 remunerationInputGroup">
              <InputTextfield
                name="effectiveHrsFullTime"
                handleChange={handleFieldChange}
                value={state.timeTableDetails.effectiveHrsFullTime}
                type="text"
                className="form-control shadow-none border-0 rounded-start-3 rounded-end-0"
              />
              <span
                className="textAppendBg input-group-text justify-content-center field-shadow border-0"
                id="basic-addon1"
              >
                {"u"}
              </span>
            </div>
          </div>
          <div className="col-4 mb-3">
            <LabelField
              title={t("Average hours full-time/week employer")}
              isMandatory={false}
            />
            <div className="input-group field-shadow rounded-3 remunerationInputGroup">
              <InputTextfield
                name="avgHrsFullTime"
                handleChange={handleFieldChange}
                value={state.timeTableDetails.avgHrsFullTime}
                type="text"
                className="form-control shadow-none border-0 rounded-start-3 rounded-end-0"
              />
              <span
                className="textAppendBg input-group-text justify-content-center field-shadow border-0"
                id="basic-addon1"
              >
                {"u"}
              </span>
            </div>
          </div>
          <div className="col-4 mb-3">
            <LabelField
              title={t("Effective hours/week employee")}
              isMandatory={false}
            />
            <div className="input-group field-shadow rounded-3 remunerationInputGroup">
              <InputTextfield
                name="effectiveHrsAvg"
                handleChange={handleFieldChange}
                value={state.timeTableDetails.effectiveHrsAvg}
                type="text"
                className="form-control shadow-none border-0 rounded-start-3 rounded-end-0"
              />
              <span
                className="textAppendBg input-group-text justify-content-center field-shadow border-0"
                id="basic-addon1"
              >
                {"u"}
              </span>
            </div>
          </div>
          <div className="col-4">
            <LabelField
              title={t("Paid ADV hours per week")}
              isMandatory={false}
            />
            <div className="input-group field-shadow rounded-3 remunerationInputGroup">
              <InputTextfield
                name="paidAdv"
                handleChange={handleFieldChange}
                value={state.timeTableDetails.paidAdv}
                type="text"
                className="form-control shadow-none border-0 rounded-start-3 rounded-end-0"
              />
              <span
                className="textAppendBg input-group-text justify-content-center field-shadow border-0"
                id="basic-addon1"
              >
                {"u"}
              </span>
            </div>
          </div>
          <div className="col-4">
            <LabelField
              title={t("Unpaid ADV hours per week")}
              isMandatory={false}
            />
            <div className="input-group field-shadow rounded-3 remunerationInputGroup">
              <InputTextfield
                name="unPaidAdv"
                handleChange={handleFieldChange}
                value={state.timeTableDetails.unPaidAdv}
                type="text"
                className="form-control shadow-none border-0 rounded-start-3 rounded-end-0"
              />
              <span
                className="textAppendBg input-group-text justify-content-center field-shadow border-0"
                id="basic-addon1"
              >
                {"u"}
              </span>
            </div>
          </div>
        </div>
      </div>
      <Navigation validStatus={validStatus} />
    </>
  );
};

export default TimeTableTab;
