import React from "react";

const ViewLoginDetailsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 598 615"
      fill="none"
    >
      <path
        d="M485 456.829C516.204 456.829 541.5 431.795 541.5 400.915C541.5 370.033 516.204 345 485 345C453.796 345 428.5 370.033 428.5 400.915C428.5 431.795 453.796 456.829 485 456.829Z"
        fill="currentColor"
      />
      <path
        d="M598 551.329C598 586.324 598 614.699 485 614.699C372 614.699 372 586.324 372 551.329C372 516.335 422.595 487.959 485 487.959C547.405 487.959 598 516.335 598 551.329Z"
        fill="currentColor"
      />
      <path
        d="M286.267 212.391C280.533 215.724 280 220.524 280 266.257C280 301.324 280.4 310.391 281.867 313.324C285.067 319.324 353.6 386.657 357.6 387.591C368.4 390.257 378 378.391 372.933 368.791C371.733 366.657 356.667 350.791 339.467 333.591L308 302.257V262.791C308 221.057 307.467 216.657 302 213.057C298.267 210.657 289.867 210.257 286.267 212.391Z"
        fill="currentColor"
      />
      <path
        d="M319 34V14H279V34H319ZM279 126C279 137.046 287.954 146 299 146C310.045 146 319 137.046 319 126H279ZM279 568V588H319V568H279ZM319 476C319 464.954 310.045 456 299 456C287.954 456 279 464.954 279 476H319ZM566.022 320.701L586.022 320.678L585.977 280.678L565.977 280.701L566.022 320.701ZM473.977 280.804C462.931 280.816 453.987 289.781 454 300.826C454.012 311.872 462.976 320.816 474.022 320.804L473.977 280.804ZM31.9776 281.299L11.9776 281.322L12.0224 321.322L32.0224 321.299L31.9776 281.299ZM124.022 321.196C135.068 321.184 144.012 312.219 144 301.174C143.988 290.128 135.023 281.184 123.978 281.196L124.022 321.196ZM279 34V126H319V34H279ZM319 568V476H279V568H319ZM565.977 280.701L473.977 280.804L474.022 320.804L566.022 320.701L565.977 280.701ZM32.0224 321.299L124.022 321.196L123.978 281.196L31.9776 281.299L32.0224 321.299Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M328.9 546.223C319.097 547.396 309.119 548 299 548C161.481 548 50 436.519 50 299C50 161.481 161.481 50 299 50C436.519 50 548 161.481 548 299C548 311.47 547.083 323.725 545.314 335.703C562.267 338.616 578.438 343.819 593.498 350.98C596.457 334.099 598 316.729 598 299C598 133.867 464.133 0 299 0C133.867 0 0 133.867 0 299C0 464.133 133.867 598 299 598C313.889 598 328.525 596.912 342.831 594.81C336.099 579.577 331.346 563.272 328.9 546.223Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ViewLoginDetailsIcon;
