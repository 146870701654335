import React from 'react';
interface Option {
  value?: number;
  name?: string;
}
interface MultiSelectFieldProps {
  options: Option[];
  placeholder: string;
  selectedValue: string | undefined; // Update the type to allow undefined
  handleChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  disabled?: boolean;
  isMultiSelect?: boolean;
  isMandatory?: boolean;
  name: string;
  className?: string;
  error?: string;  
}

const MultiSelectField: React.FC<MultiSelectFieldProps> = ({
  options,
  placeholder,
  selectedValue,
  handleChange,
  disabled,
  isMultiSelect,
  name,
  className,
  error,
})=>{

  return (
    <div> 
      <select
        className={`form-select ${className} field-shadow form-control`}
        name={name}
        value={selectedValue}
        onChange={handleChange} 
        disabled={disabled}
        multiple={isMultiSelect}
      >
        <option value="">{placeholder}</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.name}
          </option>
        ))}
      </select>
      <div className='height-20' style={{marginBottom: "0.5vw"}}>
      {error && <div className="text-danger">{error}</div>}
      </div>
    </div>
  );
};

export default MultiSelectField;
