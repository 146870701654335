import React from 'react';
import { t, translate } from "components/CentralDataMangement/translation/Translation";
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAuth } from 'features/auth/AuthSlice';
import { CANDIDATE_ROLE } from 'Constants';

const AccessDenied: React.FC = () => {
  const userAuth = useSelector(selectAuth);

  return (
    <div className='row d-flex align-items-center justify-content-center welcome-page' style={{minHeight:"inherit"}}>
      <div className="col-xxl-10 col-xl-11 col-md-12 mx-auto text-center h-100 d-flex align-items-center justify-content-center flex-column">
        <div>
          <div className="access-denied-code pb-4">403</div>
          <div className="access-denied-title pb-xxl-3">
            {t("Access denied")}
          </div>
          <div className="access-denied-content mb-5">
            {t("Oops! You can't access the page you are looking for")}
          </div>
          {userAuth.role === CANDIDATE_ROLE ? (
              <Link to='/' className="form-button page-not-found-back-link">{t("Go to home page")}</Link>
          ):(
            <Link to='/dashboard' className="form-button page-not-found-back-link">{t("Go to dashboard")}</Link> )
          }
        </div>
      </div>
    </div>
  )
}

export default translate(AccessDenied);