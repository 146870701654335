import React from "react";

const JobApplicationIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="28"
      viewBox="0 0 22 28"
      fill="none"
    >
      <path
        d="M8.16116e-06 25.75C0.000571833 26.214 0.185127 26.6587 0.513194 26.9868C0.84126 27.3149 1.28605 27.4994 1.75001 27.5H19.75C20.214 27.4994 20.6588 27.3149 20.9868 26.9868C21.3149 26.6587 21.4994 26.214 21.5 25.75V1.75C21.4994 1.28604 21.3149 0.841252 20.9868 0.513186C20.6588 0.185119 20.214 0.000563672 19.75 0H5.16407C4.70024 0.00143207 4.25567 0.185648 3.92677 0.5127L0.512708 3.92676C0.349596 4.08879 0.220283 4.28159 0.132272 4.49399C0.0442606 4.70639 -0.00069668 4.93415 8.16116e-06 5.16406V25.75ZM20 1.75V25.75C19.9999 25.8163 19.9735 25.8798 19.9266 25.9266C19.8798 25.9735 19.8163 25.9999 19.75 26H1.75001C1.68374 25.9999 1.62023 25.9735 1.57337 25.9266C1.52651 25.8798 1.50013 25.8163 1.50001 25.75V5.5H3.75001C4.21396 5.49944 4.65876 5.31488 4.98682 4.98681C5.31489 4.65875 5.49944 4.21396 5.50001 3.75V1.5H19.75C19.8163 1.50013 19.8798 1.52651 19.9266 1.57336C19.9735 1.62022 19.9999 1.68373 20 1.75ZM4.00001 2.56055V3.75C3.99923 3.81606 3.97264 3.8792 3.92592 3.92592C3.87921 3.97263 3.81607 3.99922 3.75001 4H2.56056L4.00001 2.56055Z"
        fill="currentColor"
      />
      <path
        d="M5.75 7C5.28604 7.00056 4.84125 7.18512 4.51319 7.51319C4.18512 7.84125 4.00056 8.28604 4 8.75V11.75C4.00097 12.0801 4.0954 12.4032 4.27238 12.6818C4.44935 12.9604 4.70163 13.1833 5 13.3245V15.75C5.00056 16.214 5.18512 16.6587 5.51319 16.9868C5.84125 17.3149 6.28604 17.4994 6.75 17.5H14.75C15.214 17.4994 15.6587 17.3149 15.9868 16.9868C16.3149 16.6587 16.4994 16.214 16.5 15.75V13.3245C16.7984 13.1833 17.0506 12.9604 17.2276 12.6818C17.4046 12.4032 17.499 12.0801 17.5 11.75V8.75C17.4994 8.28604 17.3149 7.84125 16.9868 7.51319C16.6587 7.18512 16.214 7.00056 15.75 7H13.5V6.75C13.5 6.02065 13.2103 5.32118 12.6945 4.80546C12.1788 4.28973 11.4793 4 10.75 4C10.0207 4 9.32118 4.28973 8.80546 4.80546C8.28973 5.32118 8 6.02065 8 6.75V7H5.75ZM14.75 16H6.75C6.68394 15.9992 6.6208 15.9726 6.57408 15.9259C6.52737 15.8792 6.50078 15.8161 6.5 15.75V13.5H15V15.75C14.9992 15.8161 14.9726 15.8792 14.9259 15.9259C14.8792 15.9726 14.8161 15.9992 14.75 16ZM16 8.75V11.75C15.9999 11.8163 15.9735 11.8798 15.9266 11.9266C15.8798 11.9735 15.8163 11.9999 15.75 12H5.75C5.68373 11.9999 5.62022 11.9735 5.57336 11.9266C5.52651 11.8798 5.50013 11.8163 5.5 11.75V8.75C5.50013 8.68373 5.52651 8.62022 5.57336 8.57336C5.62022 8.52651 5.68373 8.50013 5.75 8.5H15.75C15.8163 8.50013 15.8798 8.52651 15.9266 8.57336C15.9735 8.62022 15.9999 8.68373 16 8.75ZM9.5 6.75C9.5 6.41848 9.6317 6.10054 9.86612 5.86612C10.1005 5.6317 10.4185 5.5 10.75 5.5C11.0815 5.5 11.3995 5.6317 11.6339 5.86612C11.8683 6.10054 12 6.41848 12 6.75V7H9.5V6.75Z"
        fill="currentColor"
      />
      <path
        d="M16.75 19H4.75C4.55109 19 4.36032 19.079 4.21967 19.2197C4.07902 19.3603 4 19.5511 4 19.75C4 19.9489 4.07902 20.1397 4.21967 20.2803C4.36032 20.421 4.55109 20.5 4.75 20.5H16.75C16.9489 20.5 17.1397 20.421 17.2803 20.2803C17.421 20.1397 17.5 19.9489 17.5 19.75C17.5 19.5511 17.421 19.3603 17.2803 19.2197C17.1397 19.079 16.9489 19 16.75 19Z"
        fill="currentColor"
      />
      <path
        d="M16.75 22H4.75C4.55109 22 4.36032 22.079 4.21967 22.2197C4.07902 22.3603 4 22.5511 4 22.75C4 22.9489 4.07902 23.1397 4.21967 23.2803C4.36032 23.421 4.55109 23.5 4.75 23.5H16.75C16.9489 23.5 17.1397 23.421 17.2803 23.2803C17.421 23.1397 17.5 22.9489 17.5 22.75C17.5 22.5511 17.421 22.3603 17.2803 22.2197C17.1397 22.079 16.9489 22 16.75 22Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default JobApplicationIcon;
