import Button from "components/common/atoms/Button";
import Pagination from "components/common/atoms/Pagination";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import Title from "components/common/atoms/Title";
import { ApiCall } from "components/common/services/ApiServices";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import EditIcon from "static/images/EditIcon";
import Reset from "static/images/Reset";
import {
  faCircleInfo,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { Option, OptionProps } from "components/common/utlis/TypeAnnotations";
import { MANAGE_ADMIN_FICHE } from "routes/ApiEndpoints";
import { COMPANY_MICROSERVICE } from "Constants";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PdfIcon from "static/images/Pdf";
import "../../CooperationAgreement/css/Agreement.css";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import AutoCompleteDropdown from "components/common/atoms/AutoCompleteDropdown";
import { mapToSelect } from "components/common/utlis/MapToSelect";
import CustomNotify from "components/common/atoms/CustomNotify";
import SpinnerWrapper from "components/common/atoms/SpinnerWrapper";
import NoRecords from "components/common/atoms/NoRecords";

interface AdminFicheProps {
  id: number;
  company_id: number;
  companyName: string;
  first_name: string;
  last_name: string;
  saveAsDraft: number;
  file_path: string;
  approved: number;
  unitType: string;
  coeffType: number;
  sendToEmployer: number | null;
  office: string;
  adminFicheId: number | null;
}

interface SearchProps {
  companyName: Option | null;
  salesId: number | null;
  status: number | null;
  office: OptionProps[];
}

const ManageAdminFiche: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [apiData, setApiData] = useState<AdminFicheProps[]>([]);
  const userAuth = useSelector(selectAuth);
  const [loading, setLoading] = useState(false);
  const [dropdowns, setDropdowns] = useState({
    agentDropdown: [] as OptionProps[],
    status: [] as OptionProps[],
    officeDropdown: [] as OptionProps[],
  });
  const [searchData, setSearchData] = useState<SearchProps>({
    companyName: null,
    salesId: null,
    status: null,
    office: [],
  });

  const fetchAdminFicheDetais = async (
    searchData = {},
    page = 1,
    initial = false
  ) => {
    setLoading(true);
    const data = {
      ...searchData,
      page: page,
      role: userAuth.role,
      initial: initial,
    };

    const response = await ApiCall.service(
      MANAGE_ADMIN_FICHE,
      "POST",
      data,
      false,
      COMPANY_MICROSERVICE
    );
    if (response.status === 200) {
      const { salesUsers, data, totalPages } = response.data;
      const usersList = salesUsers.map((item: any) => ({
        value: item.userId,
        label: `${item.first_name} ${item.last_name}`,
      }));

      setDropdowns((prev) => ({
        ...prev,
        officeDropdown: response.data.offices
          ? mapToSelect(response.data.offices)
          : [],
        agentDropdown: usersList,
        status: [
          { value: 1, label: t("Active") },
          { value: 0, label: t("Pending") },
        ],
      }));
      setApiData(data);
      if (initial) {
        setSearchData((prevData) => ({
          ...prevData,
          office: response.data.defaultOffices
            ? mapToSelect(response.data.defaultOffices)
            : [],
        }));
      }
      setTotalPages(totalPages);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchAdminFicheDetais({}, 1, true);
  }, []);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    fetchAdminFicheDetais(searchData, newPage);
  };

  const handleSelectChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | any>,
    fieldName: string
  ) => {
    setSearchData((prevData) => ({
      ...prevData,
      [fieldName]: e,
    }));
  };

  const handleSearch = async (e: React.FormEvent) => {
    e.preventDefault();
    if (
      (searchData.office && searchData.office.length > 0) ||
      searchData.companyName !== null ||
      searchData.salesId !== null ||
      searchData.status !== null
    ) {
      fetchAdminFicheDetais(searchData, 1);
      setCurrentPage(1);
    } else {
      CustomNotify({
        type: "error",
        message: t("Search input not provided" + "."),
      });
    }
  };

  const handleReset = async (e: React.FormEvent) => {
    setSearchData({
      companyName: null,
      salesId: null,
      status: null,
      office: [],
    });
    setCurrentPage(1);
    fetchAdminFicheDetais();
  };

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Admin fiche",
          read: true,
        },
      ]}
      renderNoAccess={true}
    >
      <div>
        <Title title={t("Admin agreements")} />
        <div className="manage-cooperation-agreement-height">
          <div className="row search-bar pb-3">
            <div className="col-3">
              <SelectWithSearch
                search={true}
                options={dropdowns.officeDropdown}
                title={t("Office")}
                onChange={(e) => handleSelectChange(e, "office")}
                isMulti={true}
                name="office"
                value={searchData.office}
                isTranslate={true}
              />
            </div>
            <div className="col-3 autoCompleteFunction">
              <AutoCompleteDropdown
                label={t("Company")}
                placeholder={t("Type to search") + "..."}
                microService={COMPANY_MICROSERVICE}
                onChange={(e) => handleSelectChange(e, "companyName")}
                value={searchData.companyName}
                columnName="company_name"
                modelKey="company"
                filter={true}
                filterInfo={[
                  { column: "company_status", value: 1, condition: "where" },
                  {
                    column: "company_status",
                    value: 0,
                    condition: "orWhere",
                  },
                ]}
              />
            </div>
            <div className="col-3">
              <SelectWithSearch
                name="salesId"
                title={t("Sales agent")}
                isMandatory={false}
                search={true}
                options={dropdowns.agentDropdown}
                value={searchData.salesId}
                onChange={(e) => handleSelectChange(e, "salesId")}
                isMulti={false}
              />
            </div>
            <div className="col-3">
              <SelectWithSearch
                name="status"
                title={t("Status")}
                isMandatory={false}
                search={true}
                options={dropdowns.status}
                value={searchData.status}
                onChange={(e) => handleSelectChange(e, "status")}
                isMulti={false}
                isTranslate={true}
              />
            </div>
            <div className="col-12 text-end">
              <Button
                title=""
                type="reset"
                handleClick={handleReset}
                className="delete-btn shadow-none"
              >
                <span title={t("Reset")}>
                  <Reset />
                </span>
              </Button>
              <Button
                title={t("Search")}
                icon={faMagnifyingGlass}
                type="submit"
                handleClick={handleSearch}
                className="form-button shadow-none px-3 search-btns text-start position-relative"
              />
            </div>
          </div>
          <div className="table-responsive tableSection">
            <table className="table table-hover">
              <thead>
                <tr className="TableHeader">
                  <th className="ps-lg-4" style={{ width: "8%" }}>
                    {t("Office")}
                  </th>
                  <th style={{ width: "18%" }}>{t("Company")}</th>
                  <th style={{ width: "18%" }}>{t("Unit")}</th>
                  <th style={{ width: "12%" }}>{t("Type")}</th>
                  <th style={{ width: "18%" }}>{t("Sales agent")}</th>
                  <th style={{ width: "8%" }}>{t("Status")}</th>
                  <AccessControl
                    requiredPermissions={[
                      {
                        permission: "Admin fiche",
                        update: true,
                        delete: true,
                        read: true,
                      },
                    ]}
                    actions={true}
                    strict={false}
                  >
                    <th style={{ width: "12%" }}>{t("Actions")}</th>
                  </AccessControl>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <SpinnerWrapper headerLength={7} />
                ) : (
                  <>
                    {apiData && apiData.length > 0 ? (
                      apiData.map((item: AdminFicheProps, index: number) => (
                        <tr
                          key={index}
                          className="border-bottom mb-3 box-shadow align-middle"
                        >
                          <td className="d-block d-md-block d-lg-none px-0 pwa-status">
                            <table>
                              <tbody>
                                <tr>
                                  <td className="text-break px-0">
                                    <OverlayTrigger
                                      placement="top"
                                      trigger="click"
                                      rootClose
                                      overlay={
                                        <Tooltip>
                                          {item.approved === 1
                                            ? t("Active")
                                            : item.sendToEmployer === 1
                                            ? t("Sent to Employer")
                                            : t("Pending")}
                                        </Tooltip>
                                      }
                                    >
                                      <div>
                                        <span
                                          className={`d-inline-block status-icon ${
                                            item.approved === 1
                                              ? "bg-success"
                                              : item.sendToEmployer === 1
                                              ? "bg-primary"
                                              : "bg-warning"
                                          }`}
                                        />
                                        <span className="info-icon">
                                          <FontAwesomeIcon
                                            icon={faCircleInfo}
                                            className="ms-2"
                                          />
                                        </span>
                                      </div>
                                    </OverlayTrigger>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td
                            className="text-break ps-lg-4"
                            data-label={t("Office")}
                          >
                            {item.office}
                          </td>
                          <td className="text-break" data-label={t("Company")}>
                            {item.companyName}
                          </td>
                          <td className="text-break" data-label={t("Unit")}>
                            {t(`${item.unitType}`)}
                          </td>
                          <td className="text-break" data-label={t("Type")}>
                            {item.coeffType === 1
                              ? t("Fee based")
                              : t("Coefficient")}
                          </td>
                          <td
                            className="text-break"
                            data-label={t("Sales agent")}
                          >
                            {item.first_name
                              ? item.first_name + " " + item.last_name
                              : ""}
                          </td>
                          <td className="text-break ps-4" data-label={"status"}>
                            <span
                              className={`d-inline-block rounded-circle status-icon ${
                                item.approved === 1
                                  ? "bg-success"
                                  : item.sendToEmployer === 1
                                  ? "bg-primary"
                                  : "bg-warning"
                              }`}
                              data-toggle="tooltip"
                              title={
                                item.approved === 1
                                  ? t("Active")
                                  : item.sendToEmployer === 1
                                  ? t("Sent to Employer")
                                  : t("Pending")
                              }
                            ></span>
                          </td>
                          <AccessControl
                            requiredPermissions={[
                              {
                                permission: "Admin fiche",
                                update: true,
                                delete: true,
                              },
                            ]}
                            actions={true}
                            strict={false}
                          >
                            <td className="table-action-icons px-2">
                              <AccessControl
                                requiredPermissions={[
                                  {
                                    permission: "Admin fiche",
                                    read: true,
                                  },
                                ]}
                              >
                                {userAuth.role === "EMPLOYER" ? (
                                  item.adminFicheId && (
                                    <Link
                                      to={`/admin-fiche/preview/${item.id}/${item.adminFicheId}`}
                                      className="btn p-0 border-0 me-2"
                                      title={t("edit admin fiche")}
                                    >
                                      <EditIcon />
                                    </Link>
                                  )
                                ) : (
                                  <Link
                                    to={`/admin-fiche/create/${item.id}${
                                      item.adminFicheId
                                        ? `/${item.adminFicheId}`
                                        : ""
                                    }`}
                                    className="btn p-0 border-0 me-2"
                                    title={t("Edit admin fiche")}
                                  >
                                    <EditIcon />
                                  </Link>
                                )}
                              </AccessControl>
                              <AccessControl
                                requiredPermissions={[
                                  {
                                    permission: "Admin fiche",
                                    read: true,
                                  },
                                ]}
                              >
                                {item.approved !== null && (
                                  <div className="d-none d-md-none d-lg-inline-block">
                                    <Link
                                      to={item.file_path}
                                      // {`${process.env.REACT_APP_COMPANY_SERVICE}/api/agreement/download/${item.company_id}/${item.id}`}
                                      className="btn p-0 border-0 me-2"
                                      title={t("Download pdf")}
                                      target="_blank"
                                    >
                                      <PdfIcon />
                                    </Link>
                                  </div>
                                )}
                              </AccessControl>
                            </td>
                          </AccessControl>
                        </tr>
                      ))
                    ) : (
                      <NoRecords headerLength={7} />
                    )}
                  </>
                )}
              </tbody>
            </table>
            {totalPages > 1 && (
              <div className="pagination justify-content-center align-items-center my-3 my-lg-0">
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={(newPage) => handlePageChange(newPage)}
                />
              </div>
            )}
          </div>
        </div>

        <div className="col-md-6 align-self-center py-4">
          <Link
            to="/manage-companies"
            className=" back-btn text-decoration-underline"
          >
            {t("Back")}
          </Link>
        </div>
      </div>
    </AccessControl>
  );
};

export default translate(ManageAdminFiche);
