import React, { useState } from "react";
import Button from "components/common/atoms/Button";
import { Link, useNavigate, useParams } from "react-router-dom";
import { t } from "components/CentralDataMangement/translation/Translation";
import LoadingIcon from "components/common/utlis/LoadingIcon";
import { useFormContext } from "../../context/Context";
import {
  DRAFT,
  GENERAL,
  INTERNAL_INFO,
  NEXT,
  NEXT_STEP,
  PLANNING_DRAFT_MSG,
  PREVIOUS_STEP,
  STORE_PLANNING_DETAILS,
  SUBMIT,
  UPDATE_TAB_DETAILS,
} from "../../annotation/PlanningConstants";
import { scrollToTop } from "components/common/services/ValidationService";
import { getNextTabId } from "components/common/utlis/TabUtility";
import { ApiCall } from "components/common/services/ApiServices";
import { COMPANY_MICROSERVICE, SUCCESS } from "Constants";
import CustomNotify from "components/common/atoms/CustomNotify";
import { validateMandatoryFields } from "./FormValidation";
import { validateBasicDetails, validateField } from "./PlanningFieldValidation";

export interface MandatoryFields {
  [key: string]: string[];
}

interface NavProps {
  validStatus: (value: { isValid: boolean; type: string }) => void;
  isLoading?: boolean;
}

const Navigation: React.FC<NavProps> = ({ validStatus, isLoading = false }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { state, dispatch } = useFormContext();
  const { planningId } = useParams<{ planningId: string }>();

  const currentTab = state.tabDetails.find((tab) => tab.showStatus);
  const isGeneralTab = currentTab && currentTab.id === GENERAL;
  const isInternalInfoTab = currentTab && currentTab.id === INTERNAL_INFO;
  const formStatus = state.internalInfoDetails.formStatus;

  const handleNextStep = () => {
    dispatch({ type: NEXT_STEP });
    handleSubmitFormData(NEXT);
  };

  const handlePreviousStep = () => {
    dispatch({ type: PREVIOUS_STEP });
  };

  // Check if there is a companyId in the URL
  const urlParams = new URLSearchParams(window.location.search);
  const destination = urlParams.get("destination");

  const handleSubmitFormData = async (type: string) => {
    if (type === SUBMIT) {
      setLoading(true);
    }
    const tabId = getNextTabId(state.tabDetails);

    const url = STORE_PLANNING_DETAILS;

    //excluding tabDetails,dropDowndata,errors from state
    const { tabDetails, dropDowndata, errors, ...filteredState } = state;

    const data = {
      id: planningId ? planningId : null,
      planningData: filteredState,
      formStatus: type === SUBMIT ? 1 : state.internalInfoDetails.formStatus,
    };
    const response = await ApiCall.service(
      url,
      "POST",
      data,
      false,
      COMPANY_MICROSERVICE
    );
    if (response.status === 200) {
      if (type === DRAFT) {
        CustomNotify({
          type: SUCCESS,
          message: t(PLANNING_DRAFT_MSG),
        });
        destination ? navigate(`${destination}`) : navigate("/manage-planning");
      } else if (type === SUBMIT) {
        CustomNotify({
          type: "success",
          message: response.data["msg"],
        });
        destination ? navigate(`${destination}`) : navigate("/manage-planning");
      } else {
        navigate(`/planning/edit/${response.data["id"]}?tabId=${tabId}`);
      }
    } else {
      CustomNotify({
        type: "error",
        message: response.exception,
      });
    }
    setLoading(false);
  };

  const handleDraft = () => {
    const update = state.tabDetails.map((tab: any, index: any) => ({
      ...tab,
      draft: true,
    }));

    dispatch({ type: UPDATE_TAB_DETAILS, tabDetails: update });
    const valid = validateMandatoryFields(state, dispatch, DRAFT);
    const isBasicDetailsValid = validateBasicDetails(
      state.basicDetails,
      dispatch,
      DRAFT
    );
    if (!valid || !isBasicDetailsValid) {
      validStatus({ isValid: false, type: DRAFT });
      scrollToTop();
    } else {
      validStatus({ isValid: true, type: DRAFT });
      handleSubmitFormData(DRAFT);
    }
  };

  const handleSubmit = () => {
    const update = state.tabDetails.map((tab, index) => ({
      ...tab,
      draft: false,
    }));
    dispatch({ type: UPDATE_TAB_DETAILS, tabDetails: update });
    const valid = validateMandatoryFields(state, dispatch, SUBMIT);
    const isBasicDetailsValid = validateBasicDetails(
      state.basicDetails,
      dispatch,
      SUBMIT
    );
    if (!valid || !isBasicDetailsValid) {
      validStatus({ isValid: false, type: SUBMIT });
      scrollToTop();
    } else {
      validStatus({ isValid: true, type: SUBMIT });
      handleSubmitFormData(SUBMIT);
    }
  };

  return (
    <>
      <div className="d-none d-md-block d-lg-block py-4">
        <div className="row mt-3">
          <div className="col-md-6 align-self-center">
            {isGeneralTab && (
              <Link
                to=""
                className="back-btn text-decoration-underline"
                onClick={() => navigate(-1)}
              >
                {t("Back")}
              </Link>
            )}
            {!isGeneralTab && (
              <Button
                type="button"
                title={t("Back")}
                handleClick={handlePreviousStep}
                className="backBtn p-0 text-decoration-underline border-0"
              />
            )}
          </div>
          <div className="col-md-6 ">
            {!loading ? (
              <Button
                type="submit"
                title={isInternalInfoTab ? t("Submit") : t("Next")}
                handleClick={isInternalInfoTab ? handleSubmit : handleNextStep}
                className="float-end form-button ms-3 shadow-none"
              />
            ) : (
              <LoadingIcon
                iconType="bars"
                color="#e5007d"
                className="ms-auto mb-3"
                height={"3vw"}
                width={"3vw"}
              />
            )}
            {formStatus !== 1 && !isInternalInfoTab && (
              <Button
                type="submit"
                title={t("Save as draft")}
                handleClick={handleDraft}
                className="float-end form-button shadow-none"
              />
            )}
          </div>
        </div>
      </div>
      {/*PWA */}
      <div className="d-block d-md-none d-lg-none my-3">
        <div className="row mt-3">
          <div className="col-md-6 mb-3">
            {formStatus !== 1 && !isInternalInfoTab && (
              <Button
                type="submit"
                title={t("Save as draft")}
                handleClick={handleDraft}
                className="btn float-end form-button shadow-none rounded-3 w-100"
              />
            )}
          </div>
          <div className="col-md-12 mb-3">
            <div className="row">
              <div className="col-6 align-self-center">
                {isGeneralTab && (
                  <Link
                    to="/manage-planning"
                    className="back-btn text-decoration-underline"
                  >
                    {t("Back")}
                  </Link>
                )}
                {!isGeneralTab && (
                  <Button
                    type="button"
                    title={t("Back")}
                    handleClick={handlePreviousStep}
                    className="btn backBtn p-0 text-decoration-underline"
                  />
                )}
              </div>
              <div className="col-6">
                {loading ? (
                  <Button
                    type="submit"
                    title={isInternalInfoTab ? t("Submit") : t("Next")}
                    handleClick={
                      isInternalInfoTab ? handleSubmit : handleNextStep
                    }
                    className="btn float-end form-button ms-3 shadow-none rounded-3"
                  />
                ) : (
                  <LoadingIcon
                    iconType="bars"
                    color="#ff4dae"
                    className="float-end"
                    height={"3vw"}
                    width={"3vw"}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navigation;
