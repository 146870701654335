import React, {
  createContext,
  useContext,
  useReducer,
  ReactNode,
  Reducer,
} from "react";
import { companyFormData, ContactProps, TabProps } from "../../Company/annotations/CompanyAnnotations";
import { Option } from "components/common/services/ValidationService";
import { OptionProps } from "components/common/utlis/TypeAnnotations";
import { t } from "components/CentralDataMangement/translation/Translation";
import { E164Number } from 'libphonenumber-js';


const initialFormData: companyFormData = {
  tabDetails: [
    {
      id: "basic",
      title: t("General"),
      showStatus: false,
      error: false,
      draft: false
    },
    {
      id: "address",
      title: t("Address"),
      showStatus: false,
      error: false,
      draft: false
    },
    {
      id: "contacts",
      title: t("Contacts"),
      showStatus: false,
      error: false,
      draft: false
    },
    {
      id: "documents",
      title: t("Documents"),
      showStatus: false,
      error: false,
      draft: false
    },
  ],
  basic: {
    vat: "",
    companyName: "",
    officialName: "",
    pc: [],
    website: "",
    hq: 0,
    parentCompanies: null,
    coEfficient: 0,
    companyStatus: 0,
    sector: null,
    domain: null,
    function: [],
    type: 1,
    isProspect: 1,
    isCompanyTypeAdded: false,
    consultant: null,
    office: null,
    createdAt: null,
    createdBy: '',
    updatedAt: null,
    updatedBy: ''
  },
  address: {
    location: "",
    street: "",
    number: "",
    box: "",
    zipCode: "",
    country: null,
    city: null,
    biStreet: "",
    biNumber: "",
    biBox: "",
    biZipCode: "",
    biCountry: null,
    biCity: null,
    hqAddress: 0,
    sameAddress: 1
  },
  contacts: [
    {
      id: null,
      fName: "",
      lName: "",
      email: "",
      phNumber: '' as E164Number, // Ensure this is an empty string cast to E164Number,
      dob: null,
      gsm: "",
      functionTitle: "",
      teleNumber: "",
      language: null,
      linkedIn: "",
      roles: [],
      decisionMaker: false,
      influencer: false,
      contactCalled: false,
      contactEmailed: false,
      info: "",
      title: null,
      location: null,
      allowSignature: true,
      locations: []
    },
  ],
  documents: [],
  fieldError: {
    vat: "",
    companyName: "",
    officialName: "",
    pc: "",
    website: "",
    hq: "",
    parentCompanies: "",
    coEfficient: "",
    street: "",
    number: "",
    box: "",
    zipCode: "",
    country: "",
    city: "",
    biStreet: "",
    biNumber: "",
    biBox: "",
    biZipCode: "",
    biCountry: "",
    biCity: "",
    hqAddress: "",
    sameAddress: "",
    location: ""
  },
  countryList: [],
  cityList: [],
  pcList: [],
  companiesList: [],
  rolesList: [],
  cities: [],
  genders: [],
  languages: [],
  sectorList: [],
  domainList: [],
  functionList: [],
  cosultantsList: [],
  officesList: [],
  locations: [],
  categoriesList: [],
  dynamicErrors: [
    {
      fName: "",
      lName: "",
      email: "",
      phNumber: "",
      teleNumber: "",
      dob: "",
      gsm: "",
      contact: "",
      functionTitle: "",
      language: "",
      linkedIn: "",
      decisionMaker: "",
      influencer: "",
      contactCalled: "",
      contactEmailed: "",
      info: "",
      title: "",
      location: "",

    }
  ],
};

// Define form actions
type FormAction =
  | { type: "NEXT_STEP" }
  | { type: "PREVIOUS_STEP" }
  | { type: "SET_DROPDOWN"; dropdownValues: OptionProps[]; field: keyof companyFormData }
  | { type: "UPDATE_TAB_DETAILS"; tabDetails: TabProps[] }
  | { type: "UPDATE_FIELD_ERROR"; fieldName: string; error: string }
  | { type: "UPDATE_TAB_ERROR"; tabIndex: number; error: boolean }
  | {
    type: "UPDATE_CONTACTS_FIELD_ERROR";
    field: string;
    error: string;
    index: number;
  }
  | { type: "UPDATE_BASIC_FIELD"; field: string; value: string | number | null | boolean | Option }
  | { type: "UPDATE_ADDRESS_FIELD"; field: string; value: string | number | null | boolean | Option }
  | { type: "ADD_UPDATE_DYNAMIC"; data: any }
  | {
    type: "UPDATE_CONTACTS_FIELD";
    field: string;
    value: string | number | Date | null | undefined | E164Number;
    index: number;
  }
  | { type: "ADD_CONTACT" }
  | { type: "REMOVE_CONTACT"; indexToRemove: number }
  | { type: "ADD_DOCUMENT_FIELD", data: any, index: number }
  | { type: "REMOVE_DOCUMENT_FIELD"; indexToRemove: number }
  | { type: "SET_FIELDS"; payload: { [key: string]: any[] } }
  | { type: "EDIT_DOCUMENT_FIELD"; field: string; value: string | number | null | Option | Date; index: number }
  ;

type Dispatch = (action: FormAction) => void;

const FormContext = createContext<
  | {
    state: companyFormData;
    dispatch: Dispatch;
  }
  | undefined
>(undefined);

// Define a reducer to handle form actions
const formReducer: Reducer<companyFormData, FormAction> = (state, action) => {
  switch (action.type) {
    case "NEXT_STEP":
      const currentTab = state.tabDetails.find((tab) => tab.showStatus);
      if (currentTab) {
        const currentIndex = state.tabDetails.findIndex(
          (tab) => tab.id === currentTab.id
        );
        const nextIndex = (currentIndex + 1) % state.tabDetails.length;

        const updatedTabDetails = state.tabDetails.map((tab) => ({
          ...tab,
          showStatus: tab.id === state.tabDetails[nextIndex].id,
        }));

        return {
          ...state,
          tabDetails: updatedTabDetails,
        };
      }
      return state;

    case "PREVIOUS_STEP":
      const currentTabPrev = state.tabDetails.find((tab) => tab.showStatus);
      if (currentTabPrev) {
        const currentIndexPrev = state.tabDetails.findIndex(
          (tab) => tab.id === currentTabPrev.id
        );
        const previousIndex =
          (currentIndexPrev - 1 + state.tabDetails.length) %
          state.tabDetails.length;

        const updatedTabDetailsPrev = state.tabDetails.map((tab) => ({
          ...tab,
          showStatus: tab.id === state.tabDetails[previousIndex].id,
        }));

        return {
          ...state,
          tabDetails: updatedTabDetailsPrev,
        };
      }
      return state;

    case 'SET_DROPDOWN':
      return {
        ...state,
        [action.field]: action.dropdownValues,
      };

    case 'SET_FIELDS':
      return {
        ...state,
        ...action.payload,
      };

    case "UPDATE_TAB_DETAILS":
      return {
        ...state,
        tabDetails: action.tabDetails,
      };

    case "UPDATE_TAB_ERROR":
      const { tabIndex, error } = action;
      return {
        ...state,
        tabDetails: state.tabDetails.map((tab, index) => ({
          ...tab,
          error: index === tabIndex ? error : tab.error,
        })),
      };
    case "UPDATE_BASIC_FIELD":
      return {
        ...state,
        basic: {
          ...state.basic,
          [action.field]: action.value,
        },
      };

    case "UPDATE_ADDRESS_FIELD":
      return {
        ...state,
        address: {
          ...state.address,
          [action.field]: action.value,
        },
      };

    case "UPDATE_CONTACTS_FIELD":
      return {
        ...state,
        contacts: state.contacts.map((contact, index) => {
          if (index === action.index) {
            return {
              ...contact,
              [action.field]: action.value,
            };
          }
          return contact;
        }),
      };

    case "ADD_CONTACT":
      // Add a new contact to the contacts array
      const newContact = {
        id: null,
        fName: "",
        lName: "",
        email: "",
        phNumber: '' as E164Number,
        dob: null,
        gsm: "",
        functionTitle: "",
        language: null,
        linkedIn: "",
        roles: [],
        teleNumber: '',
        decisionMaker: false,
        influencer: false,
        contactCalled: false,
        contactEmailed: false,
        info: "",
        title: null,
        location: null,
        allowSignature: false,
        locations: []
      };
      const newContactErros = {
        fName: "",
        lName: "",
        email: "",
        phNumber: "",
        teleNumber: '',
        dob: "",
        gsm: "",
        contact: "",
        functionTitle: "",
        language: "",
        linkedIn: "",
        decisionMaker: "",
        influencer: "",
        contactCalled: "",
        contactEmailed: "",
        info: "",
        title: "",
        location: "",
      }
      return {
        ...state,
        contacts: [...state.contacts, newContact],
        dynamicErrors: [...state.dynamicErrors, newContactErros]
      };

    case "ADD_UPDATE_DYNAMIC":
      if (action.data && action.data.length > 0) {
        const dynamicErrorsCopy = [...state.dynamicErrors]; // Make a shallow copy of existing dynamicErrors
        action.data.forEach((contact: ContactProps, index: number) => {
          const newContactErrors: any = {};
          Object.keys(contact).forEach((key) => {
            newContactErrors[key] = "";
          });

          // Ensure dynamicErrorsCopy[index] exists and merge newContactErrors into it
          dynamicErrorsCopy[index] = { ...dynamicErrorsCopy[index], ...newContactErrors };
        });

        return {
          ...state,
          contacts: action.data,
          dynamicErrors: dynamicErrorsCopy,
        };
      }
      return state;

    case "REMOVE_CONTACT":
      // Remove the contact at the specified index
      const updatedContacts = [...state.contacts];
      updatedContacts.splice(action.indexToRemove, 1);
      const updatedContactsErrors = [...state.dynamicErrors];
      updatedContactsErrors.splice(action.indexToRemove, 1);
      return {
        ...state,
        contacts: updatedContacts,
        dynamicErrors: updatedContactsErrors
      };

    case "UPDATE_FIELD_ERROR":
      return {
        ...state,
        fieldError: {
          ...state.fieldError,
          [action.fieldName]: action.error,
        },
      };

    case "UPDATE_CONTACTS_FIELD_ERROR":
      return {
        ...state,
        dynamicErrors: state.dynamicErrors.map((error, index) => {
          if (index === action.index) {
            return {
              ...error,
              [action.field]: action.error,
            };
          }
          return error;
        }),
      };

    case "ADD_DOCUMENT_FIELD":
      const { data: documentData, index: documentAddIndex } = action;
      const documents = [...state.documents];
      documents[documentAddIndex] = { ...documentData }
      return {
        ...state,
        documents: documents
      };

    case "REMOVE_DOCUMENT_FIELD":
      const updatedDocuments = [...state.documents];
      updatedDocuments.splice(action.indexToRemove, 1);
      // Update the editId for remaining elements
      const documentsWithUpdatedIds = updatedDocuments.map((doc) => {
        // If editId is a number and greater than the removed index, decrement it
        if (typeof doc.editId === "number" && doc.editId > action.indexToRemove) {
          return { ...doc, editId: doc.editId - 1 };
        }

        return { ...doc };
      });

      return {
        ...state,
        documents: documentsWithUpdatedIds,
      };

    case "EDIT_DOCUMENT_FIELD":
      const { index: documentIndex, field: documentField, value: documentValue } = action;
      const documentsList = [...state.documents];
      documentsList[documentIndex] = {
        ...documentsList[documentIndex],
        [documentField]: documentValue,
        editId: documentIndex
      };

      return {
        ...state,
        documents: documentsList,
      };
    default:
      return state;
  }
};

// Create a FormProvider component to wrap your form
interface FormProviderProps {
  children: ReactNode;
}

export const useFormContext = () => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error("useFormContext must be used within a FormProvider");
  }
  return context;
};

export const FormProvider: React.FC<FormProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(formReducer, initialFormData);

  return (
    <FormContext.Provider value={{ state, dispatch }}>
      {children}
    </FormContext.Provider>
  );
};
