import { useFormContext } from "components/CandidateCreation/context/Context";
import { t } from "components/CentralDataMangement/translation/Translation";
import FileUploadForCandidate from "components/common/molecules/FileUploadForCandidate";
import React from "react";
import Modal from "react-bootstrap/Modal";
import { FILE_URL } from "../../../../../routes/ApiEndpoints";

interface UploadPhotoModalProps {
  show: boolean;
  onHide: () => void;
  title?: string;
  className?: string;
}

const UploadPhotoModal: React.FC<UploadPhotoModalProps> = ({
  show,
  onHide,
  title,
  className,
}) => {
  const { state, dispatch } = useFormContext();

  const fileUploadResponse = (responseObj: any) => {
    const step = responseObj["step"];
    const response = responseObj["response"];

    const fieldMappings: { [key: string]: string[] } = {
      picFileId: ["picFileId", "picFileName", "picFilePath"],
    };

    const fieldNames = fieldMappings[step];

    if (fieldNames) {
      fieldNames.forEach((fieldName: string, index: number) => {
        dispatch({
          type: "UPDATE_PROFILE_FIELD",
          field: fieldName,
          value: response[index],
        });
      });
      onHide();
    }
  };

  const returnFileUploadErrors = (errorResposne: any) => {
    const step = errorResposne["step"];
    switch (step) {
      case "picFileId":
        dispatch({
          type: "UPDATE_FIELD_ERROR",
          fieldName: step,
          error: errorResposne["error"],
        });
        break;
      default:
        return 0;
    }
  };

  const fileUploadStyleObject: React.CSSProperties = {
    width: "fit-content",
    margin: "auto",
  };

  const deleteResponse = (field: string, index: number) => {
    const fieldMappings: { [key: string]: string[] } = {
      picFileId: ["picFileId", "picFileName", "picFilePath"],
    };

    const fieldNames = fieldMappings[field];

    if (fieldNames) {
      fieldNames.forEach((fieldName: string, index: number) => {
        dispatch({
          type: "UPDATE_PROFILE_FIELD",
          field: fieldName,
          value: fieldName.endsWith("FileId") ? null : "",
        });
      });
    }
  };

  return (
    <>
      <Modal show={show} onHide={onHide} centered className={`${className}`}>
        <Modal.Header closeButton title="Close" className="border-0 px-0">
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-0 border-0">
          <div className="row">
            <div className="col-md-12 ms-auto position-relative">
              {state.profileCard.picFileName !== "" && (
                <div className="text-center">
                  <>
                    <img
                      src={
                        state.profileCard.picFilePath?.startsWith("https")
                          ? `${state.profileCard.picFilePath}`
                          : `${FILE_URL}/${state.profileCard.picFilePath}`
                      }
                      alt={t("Uploaded")}
                      style={{
                        backgroundColor: "var(--color-white)",
                        borderRadius: "50%",
                        boxShadow: "rgb(248 216 235) 0.052vw 0vw 0.521vw 0.156vw",
                      }}
                      className="profilePicUploaded"
                    />
                  </>
                </div>
              )}
              <div className={`text-center`} style={{padding:"2vw 0"}}>
                <FileUploadForCandidate
                  id="picFileId"
                  name="picFileId"
                  placeholder={t("Upload pic")}
                  edit={false}
                  fileId={
                    state.profileCard.picFileId
                      ? state.profileCard.picFileId
                      : null
                  }
                  filePath={
                    state.profileCard.picFilePath
                      ? state.profileCard.picFilePath
                      : ""
                  }
                  fileName={
                    state.profileCard.picFileName
                      ? state.profileCard.picFileName
                      : ""
                  }
                  style={fileUploadStyleObject}
                  multiple={false}
                  uploadPath="candidate-profilepic"
                  formats=".jpg,.jpeg,.png"
                  returnFileUploadErrors={returnFileUploadErrors}
                  fileUploadResponse={fileUploadResponse}
                  deleteResponse={deleteResponse}
                  maxFileSize={2 * 1024 * 1024}
                />
              </div>
              <div className="text-center">
                <span className="text-danger mb-3">
                  {state.fieldError.picFileId}
                </span>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UploadPhotoModal;
