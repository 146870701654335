import { FILE_URL } from "../../../../routes/ApiEndpoints";
import { AxiosCall } from "../AxiosService";

export class UploadAdapter {
    private loader: any;

    constructor(loader: any) {
        this.loader = loader;
    }

    async upload() {
        try {
            const file = await this.loader.file; // Ensure the file is loaded
            const microserviceURL = process.env.REACT_APP_CDM_SERVICE;
            const data = new FormData();
            data.append('file', file);
            data.append("name", 'image');
            // Perform the upload using Axios
            const response = await AxiosCall.fileUploadService(data, 'ckEditor-images', microserviceURL);

            // Assume the response contains the URL of the uploaded image
            const imageUrl = `${FILE_URL}/${response?.[2]}`; // Adjust based on your response structure

            return {
                default: imageUrl
            };
        } catch (error) {
            if (error instanceof Error) {
                return Promise.reject('Upload failed: ' + error.message);
            } else {
                // Handle unexpected error types
                return Promise.reject('Upload failed: Unknown error occurred');
            }
        }
    }

    abort() {
        // Handle aborting upload if needed
    }
}