import { t } from "components/CentralDataMangement/translation/Translation";
import { StopperDetails } from "../Annotations";
import TableTitle from "components/common/atoms/TableTitle";
import Tick from "static/images/Tick";

interface StoppersTableProps {
    view?: "recruiter" | "om" | "tl";
    data?: StopperDetails[];
    tableTitle?: string;
}
const StoppersTable: React.FC<StoppersTableProps> = ({ view = "recruiter", data, tableTitle }) => {

    return (
        <>
            {tableTitle &&
                <TableTitle title={tableTitle} />
            }
            <table className="table table-hover table-bordered targetTable">
                <thead>
                    <tr className="TableHeader">
                        <th>{t("Date")}</th>
                        <th>{t("Company")}</th>
                        {view === "tl" ? (
                            <>
                                <th>{t("Candidate")}</th>
                                <th>{t("Function")}</th>
                                <th>{t("Reason")}</th>
                                <th>{t("Housing by Absolute Jobs")}</th>
                            </>
                        ) : view === "om" ? (
                            <>
                                <th>{t("Candidate")}</th>
                                <th>{t("Function")}</th>
                            </>
                        ) : (
                            <>
                                <th>{t("Name UZK")}</th>
                                <th>{t("Reason")}</th>
                            </>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {data?.length && data?.length > 0 ? (
                        data?.map((stopper: StopperDetails, index) => (
                            <tr key={index}>
                                <td className="align-middle">{stopper.date}</td>
                                <td className="align-middle">{stopper.company}</td>
                                <td className="align-middle">{stopper.candidate}</td>
                                {view === "tl" ? (
                                    <>
                                        <td className="align-middle">{stopper?.function}</td>
                                        <td className="align-middle">{stopper?.reason}</td>
                                        <td className="table-action-icons align-middle text-center align-middle">
                                            {stopper?.housingByJobs ? <Tick/> : ""}
                                        </td>
                                    </>
                                ) : view === "om" ? (
                                    <>
                                        <td className="align-middle">{stopper?.function}</td>
                                    </>
                                ) : (
                                    <>
                                        <td className="align-middle">{stopper?.reason}</td>
                                    </>
                                )}
                            </tr>
                        ))) : (
                        <tr>
                            <td colSpan={5}>
                                <div className="text-danger text-center">
                                    {t("No data found")}
                                </div>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </>
    );
}

export default StoppersTable;