import PlanningForm from "../create-planning/form-data/PlanningForm";
import { FormProvider } from "./Context";

export const MultiFormProviderPlanning = () => {
  return (
    <>
      <FormProvider>
        <PlanningForm />
      </FormProvider>
    </>
  );
};
