import { t } from "components/CentralDataMangement/translation/Translation";
import React from "react";
import InfoIcon from "static/images/InfoIcon";
import { formatDateAndTime } from "../utlis/HelperFunctions";

interface InfoProps {
  createdBy?: any;
  updatedBy?: any;
  createdAt?: Date | null | any;
  updatedAt?: Date | null | any;
}

const UserInfoTooltip: React.FC<InfoProps> = ({
  createdBy,
  updatedBy,
  createdAt,
  updatedAt,
}) => {
  return (
    <div className="d-inline-block">
      <div className="position-relative manageInfoIcon cursor-pointer">
        <InfoIcon />
        <span className="infoHelpText text-center">
          {t("Created by ")}
          <span className="fw-bold">{createdBy}</span> on <br />
          <span className="fw-bold">
            {/* {formatDateAndTime(createdAt)} */}
            {createdAt}
          </span>
          <br />
          {t("Last modified by")}{" "}
          <span className="fw-bold">{updatedBy}</span> on <br />
          <span className="fw-bold">
            {/* {formatDateAndTime(updatedAt)} */}
            {updatedAt}
          </span>
        </span>
      </div>
    </div>
  );
};

export default UserInfoTooltip;
