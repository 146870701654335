import React, { useEffect, useState } from "react";
import { ApiCall } from "components/common/services/ApiServices";
import { Link } from "react-router-dom";
import * as ENDPOINTS from "routes/ApiEndpoints";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import Title from "components/common/atoms/Title";
import { Spinner } from "react-bootstrap";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import { IDENTITY_MANAGER_MICROSERVICE } from "Constants";
import "./LoginDetails.css";
import ViewIcon from "static/images/ViewIcon";
import { Option, OptionProps } from "components/common/utlis/TypeAnnotations";
import { createFilterConfig } from "./Filter";
import { RootState } from "store";
import { useSelector } from "react-redux";
import GetQueryParams from "components/common/services/GetQueryParams";
import {
  createQueryParams,
  filterAndMerge,
  getQueryParamsFromRedux,
} from "components/common/utlis/HelperFunctions";
import { useDispatch } from "react-redux";
import {
  clearDestinationPath,
  setDestinationPath,
} from "features/NavigationSlice";
import SearchFields from "components/common/atoms/SearchFields";
import ResetBtn from "components/common/atoms/ResetBtn";
import PaginationNew from "components/common/atoms/PaginationNew";
import Sorting from "components/common/atoms/Sorting";
import SpinnerWrapper from "components/common/atoms/SpinnerWrapper";
import NoRecords from "components/common/atoms/NoRecords";

interface SearchProps {
  user: string;
  email: string;
  role: Option | null;
  office: number[];
  orderByColumn: string;
  orderIn: "" | "asc" | "desc";
  currentPage: number;
  recordsPerPage: number;
}

interface dropDown {
  roleList: OptionProps[];
  officeList: OptionProps[];
}

const UserLoginDetails: React.FC = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [paginationData, setPaginationData] = useState({
    totalPages: 1,
    totalRecords: 0,
  });
  const [dropDowns, setDropDowns] = useState<dropDown>({
    roleList: [],
    officeList: [],
  });
  const filters = createFilterConfig(dropDowns);
  const destinationPath = useSelector(
    (state: RootState) => state.navigation.destinationPath
  );
  const getUrlParams = GetQueryParams();
  const paramFilter = getUrlParams.get("filter");
  const queryParams = getQueryParamsFromRedux(destinationPath, paramFilter);

  const defaultSearchData: SearchProps = {
    user: "",
    email: "",
    role: null,
    office: [],
    orderByColumn: "",
    orderIn: "",
    currentPage: 1,
    recordsPerPage: 10,
  };
  const filteredSearchData = filterAndMerge(defaultSearchData, queryParams);
  const [searchData, setSearchData] = useState<SearchProps>({
    ...defaultSearchData,
    ...filteredSearchData,
  });

  useEffect(() => {
    const filterData = { ...searchData };
    const searchUrl = createQueryParams(filterData);
    dispatch(setDestinationPath(searchUrl));
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      fetchDetails(filterData);
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [searchData]);

  //API call for to fetch Employee types
  const fetchDetails = async (filterData: any) => {
    setLoading(true);
    const data = {
      search: filterData,
    };

    const response = await ApiCall.service(
      ENDPOINTS.GET_LOGIN_DETAILS,
      "POST",
      data,
      false,
      IDENTITY_MANAGER_MICROSERVICE
    );

    if (response.status === 200) {
      const { loginData, paginationData, dropdownData } = response.data;
      setData(loginData);
      setDropDowns(dropdownData);
      setPaginationData(paginationData);
      setLoading(false);
    }
  };

  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: 1,
      [name]: value,
    }));
  };

  const handlePageChange = (pageNumber: number) => {
    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: pageNumber,
    }));
  };

  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: 1,
      [fieldName]: selectedOption,
    }));
  };

  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    setSearchData((prev) => ({
      ...prev,
      recordsPerPage: newItemsPerPage,
      currentPage: 1,
    }));
  };

  const handleReset = async (e: React.FormEvent) => {
    dispatch(clearDestinationPath());
    setSearchData(defaultSearchData);
  };

  const handleOrderByColumn = (column: string) => {
    let newOrder: "" | "asc" | "desc" = ""; // Explicit type declaration
    let newColumn = column;

    if (searchData.orderByColumn === column) {
      // Cycle through the three possible order values: '', 'asc', 'desc'
      if (searchData.orderIn === "") {
        newOrder = "asc"; // From '' to 'asc'
      } else if (searchData.orderIn === "asc") {
        newOrder = "desc"; // From 'asc' to 'desc'
      } else {
        newOrder = ""; // From 'desc' to ''
        newColumn = ""; // Reset column if no order
      }
    } else {
      // If it's a new column, start with ascending
      newOrder = "asc";
    }

    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: 1, // Reset pagination on sort change
      orderByColumn: newColumn, // Set the new column or empty string
      orderIn: newOrder, // Set the new order (asc, desc, or empty)
    }));
  };
  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Login details",
          read: true,
        },
      ]}
      renderNoAccess={true}
    >
      <Title title={t("Login details")} />{" "}
      <div className="position-relative tableMainWrapper mt-0">
        <div className="table-responsive tableSection tableSectionWithoutBtn">
          <table className="table manageLoginDetails border-0 mb-0">
            <thead>
              <tr className="tableSearchFields">
                <SearchFields
                  searchData={searchData}
                  filters={filters}
                  handleSearchChange={handleSearchChange}
                  handleSelectChange={handleSelectChange}
                />
                <th className="border-0"></th>
                <th className="border-0"></th>
                <th className="border-0">
                  <div className="d-flex align-items-center w-100">
                    <ResetBtn handleResetClick={handleReset} />
                  </div>
                </th>
              </tr>
            </thead>
          </table>
          <div className="tableContainer scrollBarDesign">
            <table className="table table-hover manageLoginDetails">
              <thead>
                <tr className="TableHeader">
                  <th onClick={() => handleOrderByColumn("office")}>
                    {t("Office")}{" "}
                    <Sorting
                      sortType={
                        searchData.orderByColumn === "office"
                          ? searchData.orderIn
                          : ""
                      }
                    />
                  </th>
                  <th onClick={() => handleOrderByColumn("user")}>
                    {t("User name")}{" "}
                    <Sorting
                      sortType={
                        searchData.orderByColumn === "user"
                          ? searchData.orderIn
                          : ""
                      }
                    />
                  </th>
                  <th>{t("Email")}</th>
                  <th>{t("Role")}</th>
                  <th>{t("Member for")}</th>
                  <th>{t("Last access")}</th>
                  <AccessControl
                    requiredPermissions={[
                      {
                        permission: "Cooperation agreeement",
                        update: true,
                        delete: true,
                        read: true,
                      },
                    ]}
                    actions={true}
                    strict={false}
                  >
                    <th>{t("Actions")}</th>
                  </AccessControl>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <SpinnerWrapper headerLength={7} />
                ) : (
                  <>
                    {data && data.length > 0 ? (
                      data.map((item: any, index: number) => (
                        <tr
                          key={item.id}
                          className="border-bottom mb-3 box-shadow align-middle"
                        >
                          <td className="text-break" data-label={t("Office")}>
                            {item.office_name}
                          </td>
                          <td className="text-break" data-label={t("Name")}>
                            {item.first_name} {item.last_name}
                          </td>
                          <td data-label={t("Email")} className="text-break">
                            {item.email}
                          </td>
                          <td data-label={t("Role")} className="text-break">
                            {item.role}
                          </td>
                          <td className="text-break">{item.member_access}</td>
                          <td className="text-break">{item.last_login}</td>
                          <td className="table-action-icons">
                            <Link
                              to={`/login/details/${item.user_id}`}
                              className="btn p-0 border-0 me-2"
                              title={t("View")}
                            >
                              <ViewIcon />
                            </Link>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <NoRecords headerLength={7} />
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>
          {!loading && (
            <div className="pagination d-block">
              <PaginationNew
                currentPage={searchData.currentPage}
                totalPages={paginationData.totalPages}
                totalRecords={paginationData.totalRecords}
                recordsPerPage={searchData.recordsPerPage}
                onItemsPerPageChange={handleItemsPerPageChange}
                onPageChange={(newPage: any) => handlePageChange(newPage)}
              />
            </div>
          )}
        </div>
      </div>
      <div>
        <div
          className="col-md-6 align-self-center"
          style={{ padding: "1vw 0" }}
        >
          <Link to="/dashboard" className=" back-btn text-decoration-underline">
            {t("Back")}
          </Link>
        </div>
      </div>
    </AccessControl>
  );
};

export default translate(UserLoginDetails);
