import React from "react";

const Meeting = () => {
  return (
    <>
      <svg
        id="Time_registration"
        data-name="Time registration"
        xmlns="http://www.w3.org/2000/svg"
        width="61.278"
        height="69.944"
        viewBox="0 0 61.278 69.944"
      >
        <path
          id="Subtraction_84"
          data-name="Subtraction 84"
          d="M61.278,69.944H0V0H61.278V69.944ZM8.975,57.712v1.857H35.438V57.712Zm0-5.571V54H35.438V52.141Zm0-5.571v1.857H35.438V46.57Zm0-5.571v1.857H35.438V41Zm0-6.19v1.857H52.737V34.81Zm0-5.571V31.1H52.737V29.239Zm0-5.571v1.857H52.737V23.668Zm0-5.571v1.857H52.737V18.1Zm0-5.571v1.857H52.737V12.527Z"
          fill="currentColor"
        />
        <g id="time" transform="translate(38.376 41.471)">
          <path
            id="Path_31485"
            data-name="Path 31485"
            d="M13.5,10.5h1.857v4.642H20V17H13.5Z"
            transform="translate(-7.001 -5.858)"
            fill="currentColor"
          />
          <path
            id="Path_31486"
            data-name="Path 31486"
            d="M21.569,12.285A9.285,9.285,0,1,1,12.285,3,9.285,9.285,0,0,1,21.569,12.285Zm-1.857,0a7.428,7.428,0,1,1-7.428-7.428A7.428,7.428,0,0,1,19.712,12.285Z"
            transform="translate(-3 -3)"
            fill="currentColor"
            fillRule="evenodd"
          />
        </g>
      </svg>
    </>
  );
};

export default Meeting;
