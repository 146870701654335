import Title from "components/common/atoms/Title";
import { t } from "../translation/Translation";
import { selectAuth } from "features/auth/AuthSlice";
import { useSelector } from "react-redux";
import { AREA_MANAGER, OFFICE_MANAGER, RECRUITER } from "Constants";
import { useEffect, useState } from "react";
import ManageRecruiterReports from "./ManageRecruiterReports";
import secureLocalStorage from "react-secure-storage";
import ManageOmReports from "./ManageOmReports";

export const timePeriods = [
  {
    label: "Weekly",
    value: 1
  },
  {
    label: "Monthly",
    value: 2
  }
]

const ManageReports: React.FC = () => {
  const authUser = useSelector(selectAuth);
  const getInitialType = () => {
    let type = "";
    if (authUser.role === RECRUITER) {
      type = "recruiter";
    } else if (authUser.role === OFFICE_MANAGER || authUser.role === AREA_MANAGER) {
      type = "om";
    }
    return type;
  }
  useEffect(() => {
    secureLocalStorage.removeItem('weeklyReport');
    secureLocalStorage.removeItem('monthlyReport');
  }, []);
  const [reportType, setReportType] = useState(getInitialType);


  return (
    <>
      <Title
        title={t("Manage reports")}
      />
      <div className="form-height-config-settings mt-2">
        <div className="row pb-3">
          <div className="col-md-12 vacancy-tabs">
            {(authUser.role === AREA_MANAGER || authUser.role === OFFICE_MANAGER) &&
              <button
                className={`link ${reportType === "om" ? "active" : ""
                  } text-decoration-none me-3 border-0 bg-transparent ps-0`}
                onClick={(e) => setReportType("om")}
              >
                {t("Office manager")}
              </button>
            }
            {(authUser.role === AREA_MANAGER || authUser.role === OFFICE_MANAGER || authUser.role === RECRUITER) &&
              <button
                className={`link ${reportType === "recruiter" ? "active" : ""
                  } text-decoration-none me-3 border-0 bg-transparent ps-0`}
                onClick={(e) => setReportType("recruiter")}
              >
                {t("Recruiter")}
              </button>
            }
            {/* <button
              className={`link cursor-pointer ${reportType === "1" ? "active nav-subtab" : "nav-subtab"
                } text-decoration-none me-3 border-0 bg-transparent ps-0`}
              onClick={(e) => setReportType("1")}
            >
              {t("Team lead")}
            </button> */}
          </div>
        </div>
        {reportType === "om" ? (
          <>
            <ManageOmReports />
          </>
        ) : (reportType === "recruiter" ? (
          <>
            <ManageRecruiterReports />
          </>
        ) : (
          <>
            {/* <h1>{"TL reports"}</h1> */}
          </>
        ))}
      </div>
    </>
  );
}

export default ManageReports;