import { ApiCall } from "components/common/services/ApiServices";
import { FETCH_PLANNINGS } from "../annotation/PlanningConstants";
import { COMPANY_MICROSERVICE } from "Constants";
import { useEffect, useState } from "react";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import { t } from "components/CentralDataMangement/translation/Translation";
import Title from "components/common/atoms/Title";
import LinkTo from "components/common/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Spinner from "react-bootstrap/esm/Spinner";
import Pagination from "components/common/atoms/Pagination";
import { Link } from "react-router-dom";
import EditIcon from "static/images/EditIcon";
import SpinnerWrapper from "components/common/atoms/SpinnerWrapper";
import NoRecords from "components/common/atoms/NoRecords";

interface planningData {
  office: string;
  customer: string;
  collegue: string;
  from: string;
  to: string;
  planningId: number;
}

const ManagePlanning: React.FC = () => {
  const [plannings, setPlannings] = useState([] as planningData[]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [searchData, setSearchData] = useState({});

  useEffect(() => {
    fetchPlanningDetails();
  }, []);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    fetchPlanningDetails(searchData, newPage);
  };

  //API call for to fetch countries
  const fetchPlanningDetails = async (formData = {}, page = 1) => {
    const requestData = {
      ...formData,
      page: page,
    };

    const response = await ApiCall.service(
      FETCH_PLANNINGS,
      "POST",
      requestData,
      false,
      COMPANY_MICROSERVICE
    );

    if (response.status === 200) {
      setPlannings(response.data);
      setTotalPages(response.totalPages);
      setLoading(false);
    }
  };
  return (
    <>
      <AccessControl
        requiredPermissions={[
          {
            permission: "Contract",
            read: true,
          },
        ]}
        renderNoAccess={true}
      >
        <Title title={t("Contracts")} />
        <div className="form-height-dashboard">
          <div className="search-bar">
            <div className="row"></div>
          </div>
          <div className="position-relative tableMainWrapper">
            <AccessControl
              requiredPermissions={[
                {
                  permission: "Contract",
                  create: true,
                },
              ]}
            >
              <div className="d-none d-lg-block">
                <div className="row">
                  <div className="ManageCreateBtn">
                    <LinkTo
                      pagelink="/planning/create"
                      title={t("Create contract")}
                      icon={faPlus}
                    />
                  </div>
                </div>
              </div>
              <div className="d-block d-lg-none">
                <div className="row">
                  <div className="col-md-6 pb-3 ms-auto">
                    <LinkTo
                      pagelink="/plannings/create"
                      title={t("Create contract")}
                      icon={faPlus}
                      className="w-100 align-items-center justify-content-center"
                    />
                  </div>
                </div>
              </div>
            </AccessControl>
            <div className="table-responsive tableSection">
              <table className="table table-hover">
                <thead>
                  <tr className="TableHeader">
                    <th
                      className="align-middle ps-lg-4"
                      style={{ width: "20%" }}
                    >
                      {t("Office")}
                    </th>
                    <th className="align-middle" style={{ width: "20%" }}>
                      {t("Customer")}
                    </th>
                    <th className="align-middle" style={{ width: "20%" }}>
                      {t("Collegue")}
                    </th>
                    <th className="align-middle" style={{ width: "20%" }}>
                      {t("From")}
                    </th>
                    <th className="align-middle" style={{ width: "20%" }}>
                      {t("To")}
                    </th>
                    <th className="align-middle" style={{ width: "20%" }}>
                      {t("Actions")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <SpinnerWrapper headerLength={3} />
                  ) : (
                    <>
                      {plannings && plannings.length > 0 ? (
                        plannings.map((planning) => (
                          <tr
                            key={planning.planningId}
                            className="border-bottom mb-3 box-shadow align-middle"
                          >
                            <td
                              data-label={t("Function")}
                              className="align-middle ps-lg-4"
                            >
                              {planning.office}
                            </td>
                            <td>{planning.customer}</td>
                            <td>{planning.collegue}</td>
                            <td>{planning.from}</td>
                            <td>{planning.to}</td>
                            <td className="table-action-icons px-2 align-middle">
                              <div className="d-none d-md-none d-lg-block">
                                <Link
                                  to={`/planning/edit/${planning.planningId}`}
                                  className="btn p-0 border-0 me-2"
                                  title={t("Edit")}
                                >
                                  <EditIcon />
                                </Link>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <NoRecords headerLength={7} />
                      )}
                    </>
                  )}
                </tbody>
              </table>
              {totalPages > 1 && (
                <div className="pagination justify-content-center align-items-center mt-4">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={(newPage) => handlePageChange(newPage)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-6 align-self-center py-4">
          <Link to="/dashboard" className="back-btn text-decoration-underline">
            {t("Back")}
          </Link>
        </div>
      </AccessControl>
    </>
  );
};

export default ManagePlanning;
