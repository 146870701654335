import { CANDIDATE_MICROSERVICE } from "Constants";
import { ApiCall } from "components/common/services/ApiServices";
import { FILE_URL, GET_PARSING_DETAILS } from "routes/ApiEndpoints";
import { fillingContactForm } from "./SetCandidateData";

export const SetCvParsingData = async (
  data: any,
  dispatch: any,
  state: any
) => {
  const cvPath = data[2] ?? "";
  if (cvPath !== "") {
    const cvUrl = `${FILE_URL}/${cvPath}`;
    const requestData = {
      cvUrl: cvUrl,
    };
    const response = await ApiCall.service(
      `${GET_PARSING_DETAILS}`,
      "POST",
      requestData,
      true,
      CANDIDATE_MICROSERVICE
    );
    if (response.status === 200) {
      fillingContactForm(response.data, dispatch);
      dispatch({
        type: "UPDATE_COMPETENCES",
        payload: response.data?.competences,
      });
      dispatch({ type: "UPDATE_CHILD_COMPETENCES_DATA" });
    }
  }
};
