import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

interface LinkToProps {
  pagelink: string; // Change to mandatory string type
  title?: string;
  icon?: IconProp;
  className?: string;
  state?: any; // Change type to 'any' or a specific type that matches your state data
  type?: string;
}

const LinkTo: React.FC<LinkToProps> = ({
  pagelink,
  title,
  icon,
  className,
  state,
  type,
}) => {
  return (
    <>
      {pagelink && (
        <Link
          to={pagelink}
          className={`float-end d-flex align-items-center  text-decoration-none  ${className} ${
            type === "manage" ? "manageBtn" : "form-button"
          }`}
          state={state}
        >
          {title}
          {icon && (
            <span className="ms-4 ps-2">
              <FontAwesomeIcon icon={icon} />
            </span>
          )}
        </Link>
      )}
    </>
  );
};

export default LinkTo;
