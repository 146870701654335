import React from 'react'

const InactiveLocation = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 22.264 26.058">
      <path id="Inactive_location" data-name="Inactive location" d="M10.2,25.975l-.326-.443C9.663,25.251,7.8,22.7,5.847,19.538L18.992,6.393a9.033,9.033,0,0,1,.4,2.667c0,4.789-8.2,16-8.545,16.473l-.326.443a.2.2,0,0,1-.163.083A.2.2,0,0,1,10.2,25.975Zm-9.9-4a1,1,0,0,1,0-1.414L4.17,16.68C2.281,13.265,1.325,10.7,1.325,9.059A9.03,9.03,0,0,1,17.42,3.43L20.557.293a1,1,0,0,1,1.414,1.414L1.707,21.971a1,1,0,0,1-1.414,0ZM7.123,9.059A3.232,3.232,0,0,0,8.9,11.948L13.248,7.6A3.236,3.236,0,0,0,7.123,9.059Z" transform="translate(0 0)" fill="currentColor" />
    </svg>
  )
}

export default InactiveLocation