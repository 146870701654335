import { t } from "components/CentralDataMangement/translation/Translation";
import React from "react";
import Company from "static/images/Company";
import EnvelopIcon from "static/images/EnvelopIcon";
import RolesIcon from "static/images/Roles";
import User from "static/images/User";

interface props {
  data: any;
}
const LoginDetailsCard: React.FC<props> = ({ data }) => {
  return (
    <div className="card border-0 loginDetailsCard rounded-5">
      <div className="row">
        <div className="col-12" style={{marginBottom:"1vw"}}>
          <div className="d-flex align-items-center text-break">
            <div className="d-inline-flex align-items-center justify-content-center loginImageWrapper">
              <User />
            </div>
            <span className={`ms-3`}>
              {data && data.length > 0 && (
                <>
                  {data[0]?.first_name} {data[0]?.last_name}
                </>
              )}
            </span>
          </div>
        </div>
        <div className="col-12" style={{marginBottom:"1vw"}}>
          <div className="d-flex align-items-center text-break">
            <div className="d-inline-flex align-items-center justify-content-center loginImageWrapper">
              <Company />
            </div>
            <span className={`ms-3`}>
              {data && data.length > 0 && data[0]?.office_name}
            </span>
          </div>
        </div>
        <div className="col-12" style={{marginBottom:"1vw"}}>
          <div className="d-flex align-items-center text-break">
            <div className="d-inline-flex align-items-center justify-content-center loginImageWrapper">
              <EnvelopIcon />
            </div>
            <span className={`ms-3`}>
              {data && data.length > 0 && data[0]?.email}
            </span>
          </div>
        </div>
        <div className="col-12">
          <div className="d-flex align-items-center text-break">
            <div className="d-inline-flex align-items-center justify-content-center loginImageWrapper">
              <RolesIcon />
            </div>
            <span className={`ms-3`}>
              {data && data.length > 0 && data[0]?.role}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginDetailsCard;
