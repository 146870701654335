import { t } from "components/CentralDataMangement/translation/Translation";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import { validateMultiSelectField, validateRequired } from "components/common/services/ValidationService";
import FormBuilder from "components/common/services/form/FormBuilder";
import { useParams } from "react-router-dom";
import {
    CONF_SETTINGS_ADD,
    CONF_SETTINGS_EDIT,
    CONF_SETTINGS_UPDATE,
  } from "routes/ApiEndpoints";

const TaskType: React.FC = () => {
    const { id: typeId } = useParams<{ id: string }>();
    const FunctionFormValidation = {
        name: [validateRequired],
        // domainName: [validateMultiSelectField],
    };

    const permissionType = typeId ? "update" : "create";
    const permissionObject: any = {
        permission: "Follow-up",
    };
    permissionObject[permissionType] = true;
    return (
        <AccessControl
            requiredPermissions={[permissionObject]}
            renderNoAccess={true}
        >
            <FormBuilder
                title={
                    typeId === undefined ? t("Add task type") : t("Edit task type")
                }
                SaveDataAPI={
                    typeId === undefined ? CONF_SETTINGS_ADD : CONF_SETTINGS_UPDATE
                }
                entryId={typeId}
                getDataAPI={CONF_SETTINGS_EDIT}
                formName={"TaskType"}
                redirect={-1}
                validationRules={FunctionFormValidation}
                microService={"central-data-management"}
                backButton={true}
                type="taskType"
            />
        </AccessControl>
    );
}
export default TaskType;
