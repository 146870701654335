import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GET_FORM_DATA } from "routes/ApiEndpoints";
import Title from "components/common/atoms/Title";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import { FormElements } from "./FormElements";
import LoadingIcon from "components/common/utlis/LoadingIcon";
import Button from "components/common/atoms/Button";
import CustomNotify from "components/common/atoms/CustomNotify";
import { ApiCall } from "../ApiServices";
import { validateForm } from "../ValidationService";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";

interface ValidationRules {
  [key: string]: Function[];
}
interface FormBuilderProps {
  entryId?: number | string;
  actionType?: string;
  getDataAPI?: any;
  SaveDataAPI?: any;
  formName: string;
  title?: string;
  redirect: any;
  validationRules: ValidationRules;
  microService: string;
  type?: string;
  backButton?: boolean;
  dataToInclude?: Object;
  subForm?: boolean;
  refresh?: boolean;
  disableField?: Object[];
}

const FormBuilder: React.FC<FormBuilderProps> = ({
  entryId,
  actionType,
  getDataAPI,
  SaveDataAPI,
  formName,
  title,
  redirect,
  validationRules,
  type = "",
  microService = "identity-manager",
  backButton = true,
  dataToInclude = {},
  subForm = false,
  refresh = false,
  disableField = [],
}) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<any>({});
  const [formInputs, setFormInputs] = useState<any[]>([]);
  const [errors, setErrors] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const authUser = useSelector(selectAuth);

  useEffect(() => {
    fetchData(formName);
  }, [formName]);

  const fetchData = async (formName: string) => {
    try {
      const postData = {
        method: "POST",
        form: formName,
      };
      const response = await ApiCall.service(
        GET_FORM_DATA,
        "POST",
        postData,
        true,
        microService
      );
      setFormInputs(response.data);
      Object.values(response.data).forEach((value: any) => {
        setFormData((prevFormData: any) => ({
          ...prevFormData,
          [value.name]: value.value,
        }));
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (entryId) {
      const data = { id: entryId, type: type, dataToInclude };
      const fetchDataForEditing = async (data: { id: string | number }) => {
        try {
          // const response = await ApiCall.getService(`${getDataAPI}/${data.id}`, "GET", microService, false);
          const response = await ApiCall.service(
            getDataAPI,
            "POST",
            data,
            true,
            microService
          );
          if (response.status === 200) {
            const data = response.data;
            let formdata: any = [];
            let formElements = formInputs;

            Object.values(formElements).forEach((value: any) => {
              formdata[value.name] =
                "phone_number" === value.alias
                  ? "+" + data[value.alias]
                  : data[value.alias];
            });
            setFormData((prev: any) => ({ ...prev, ...formdata }));
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchDataForEditing(data);
    }
  }, [entryId, formInputs]);

  const validation = (
    name: string,
    value: string | boolean,
    isSingleFieldValidation: boolean = false
  ) => {
    if (entryId && formData[name as keyof FormBuilderProps] === value) {
      return true;
    }

    const validationErrors = validateForm(
      { ...formData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );
    if (isSingleFieldValidation && Object.keys(errors).length > 0) {
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        [name]: validationErrors[name as keyof FormBuilderProps],
      }));
    } else {
      setErrors(validationErrors);
    }
    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const changeHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value, type } = event.target as HTMLInputElement;
    let newValue: any = value;
    if (name === "minimumSalary") {
      const regex = /^(\d{0,2})(,\d{0,4})?$/;
      let matches = regex.exec(value);
      if (matches) {
        setFormData((prevData: any) => ({ ...prevData, [name]: newValue }));
        const errorMessage = validation(name, newValue, true);
        setErrors((prevErrors: any) => ({
          ...prevErrors,
          [name]: errorMessage,
        }));
      }
    } else if (type === "checkbox") {
      const checked = (event.target as HTMLInputElement).checked;
      setFormData((prevData: any) => ({
        ...prevData,
        [name]: checked ? 1 : 0,
      }));
    } else {
      setFormData((prevData: any) => ({ ...prevData, [name]: newValue }));
      const errorMessage = validation(name, newValue, true);
      setErrors((prevErrors: any) => ({ ...prevErrors, [name]: errorMessage }));
    }
  };

  const handleSelectChange = (selectedOption: any, name: string) => {
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      [name]: selectedOption,
    }));
    const errorMessage = validation(name, selectedOption, true);
    setErrors((prevErrors: any) => ({ ...prevErrors, [name]: errorMessage }));
  };

  const handlePhoneNumberChange = (e: string) => {
    setFormData((prevFormData: any) => ({ ...prevFormData, mobNumber: e }));
    const errorMessage = validation("mobNumber", e, true);
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      mobNumber: errorMessage,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    setLoading(true);
    e.preventDefault();
    const { name, value } = e.target as HTMLInputElement;
    const data = { id: entryId, type: type, ...dataToInclude, ...formData };
    if (validation(name, value)) {
      let response = await ApiCall.service(
        SaveDataAPI,
        "POST",
        data,
        false,
        microService
      );
      if (response.status === 200) {
        navigate(redirect);
        CustomNotify({ type: "success", message: response.msg });
        if (refresh) {
          await new Promise((resolve) => setTimeout(resolve, 3000));
          window.location.reload();
        }
      } else if (response.status === 400) {
        let errors: any = [];
        for (const key in response.errors) {
          errors[key] = response.errors[key];
        }
        setErrors({ ...errors });
      }
    }
    setLoading(false);
  };
  return (
    <div className="row">
      <div className="col-md-12">
          {subForm ? "" : <Title title={title} />}
          <div className={subForm ? "" : "form-height-add-coefficient"}>
            <div className={subForm ? "" : "position-relative"}>
              <div className={subForm ? "form-border padding1" : "form-border"}>
                {subForm ? (
                  <div className="tab-subtitle">{title}</div>
                ) : undefined}
                <div className={subForm ? "" : "form"}>
                  <div className="row">
                    {Object.entries(formInputs).map(([key, value]) => {
                      const updatedClassName =
                        value.hidden &&
                        authUser.email !== "absolute-jobs-team@infanion.com"
                          ? `${value.className} d-none`
                          : value.className;

                      return (
                        <React.Fragment key={key}>
                          {FormElements(
                            {
                              type: value.type,
                              name: key,
                              value: { ...value, className: updatedClassName },
                            },
                            value.type === "mobile-number"
                              ? handlePhoneNumberChange
                              : value.type === "multi-select"
                              ? (
                                  e: React.ChangeEvent<
                                    HTMLInputElement | HTMLSelectElement
                                  >
                                ) => handleSelectChange(e, value.name)
                              : changeHandler,
                            formData,
                            errors,
                            formName,
                            disableField
                          )}
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>
                {subForm ? (
                  <div className="row backPadding">
                    <div className="col-md-4 align-self-center">
                      {backButton && (
                        <Link
                          to=""
                          onClick={() => navigate(-1)}
                          className=" back-btn text-decoration-underline"
                        >
                          {t("Back")}
                        </Link>
                      )}
                    </div>
                    <div className="col-md-8 text-end">
                      {!loading ? (
                        <Button
                          title={t("Save")}
                          type="submit"
                          className="float-end form-button shadow-none"
                          handleClick={handleSubmit}
                        />
                      ) : (
                        <LoadingIcon
                          iconType="bars"
                          color="#e55496"
                          height={"3vw"}
                          width={"3vw"}
                          className="ms-auto"
                        />
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          {subForm ? (
            ""
          ) : (
            <>
              {/* DESKTOP */}
              <div className="row backPadding">
                <div className="col-md-4 align-self-center">
                  {backButton && (
                    <Link
                      to=""
                      onClick={() => navigate(-1)}
                      className=" back-btn text-decoration-underline"
                    >
                      {t("Back")}
                    </Link>
                  )}
                </div>
                <div className="col-md-8 text-end">
                  {!loading ? (
                    <Button
                      title={t("Save")}
                      type="submit"
                      className="float-end form-button shadow-none"
                      handleClick={handleSubmit}
                    />
                  ) : (
                    <LoadingIcon
                      iconType="bars"
                      color="#e55496"
                      height={"3vw"}
                      width={"3vw"}
                      className="ms-auto"
                    />
                  )}
                </div>
              </div>
            </>
          )}
      </div>
    </div>
  );
};

export default translate(FormBuilder);
