import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { OptionProps } from "components/common/utlis/TypeAnnotations";
import { DateInfoProps } from 'components/common/layout/Header';

interface AuthState {
    token: string;
    email: string;
    isAuthenticated: boolean;
    refreshToken: string | undefined;
    userId: number | null;
    role: string;
    name: string;
    userPermissions: any;
    isSuperAdmin: boolean;
    profilePicName: string;
    candidateId: number | null;
    profilePicId: number | null;
    profilePicPath: string;
    lastLogin: Date | null;
    dateInfo: DateInfoProps;
    office: string;
    officeId: number | null;
    isSignature: boolean;
    isRecording: boolean;
    userPrefLang: string;
    consentStatus: number | null;
    consentToTerms: number | null;
}

const initialDate = new Date();

const initialState: AuthState = {
    token: "",
    email: "",
    isAuthenticated: false,
    refreshToken: "",
    userId: null,
    role: "",
    name: "",
    userPermissions: [],
    isSuperAdmin: false,
    profilePicName: "",
    candidateId: null,
    profilePicId: null,
    profilePicPath: "",
    lastLogin: null,
    dateInfo: {
        type: 'month', value: initialDate.getMonth() + 1,
        label: initialDate.toLocaleString('default', { month: 'long' }) + " " + initialDate.getFullYear(),
        year: initialDate.getFullYear()
    },
    office: "",
    officeId: null,
    isSignature: false,
    isRecording: false,
    userPrefLang: "",
    consentStatus: null,
    consentToTerms: null,
}

const AuthSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        addUserDetails: (state, action: PayloadAction<object>) => {
            return { ...state, ...action.payload };
        },
        removeUserDetails: (state, action: PayloadAction<void>) => {
            return initialState;
        },
        addUserProfilePic: (state, action: PayloadAction<string>) => { }
    }
});

export const { addUserDetails, removeUserDetails } = AuthSlice.actions;

export const selectAuth = (state: RootState) => state.auth;

export default AuthSlice.reducer;