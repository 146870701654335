const Euro: React.FC = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="25.677" height="19.361" viewBox="0 0 25.677 19.361">
                <path id="hand-money-euro-coin_1_" data-name="hand-money-euro-coin (1)" d="M0,8.228H5.189V17.46H0ZM17.08,0a4.621,4.621,0,1,1-4.621,4.621A4.621,4.621,0,0,1,17.08,0Zm.882,5.262H16.894a.932.932,0,0,0,.245.667,1.073,1.073,0,0,0,.75.215,1.637,1.637,0,0,0,.6-.1l.146.92a4.047,4.047,0,0,1-.9.1,2.173,2.173,0,0,1-1.473-.479,1.739,1.739,0,0,1-.575-1.323h-.564V4.715h.564V4.3h-.564V3.755H15.7a1.821,1.821,0,0,1,.635-1.323,2.292,2.292,0,0,1,1.509-.474,3.331,3.331,0,0,1,.794.107l-.146.924a1.522,1.522,0,0,0-.571-.107,1.076,1.076,0,0,0-.721.213.952.952,0,0,0-.295.662h1.057V4.3H16.9v.416h1.068v.545h0ZM6.234,16.685V8.967H9.7a13.02,13.02,0,0,1,4.414,1.987h2.7c1.22.073,1.86,1.31.673,2.123a4.987,4.987,0,0,1-3.471.539.58.58,0,1,0,0,1.145c.32.025.667-.05.97-.05,1.6,0,2.909-.307,3.714-1.567l.4-.942,4.01-1.987c2.006-.66,3.431,1.438,1.954,2.9a52.54,52.54,0,0,1-8.93,5.256,6.046,6.046,0,0,1-6.637,0L6.234,16.685Z" fill="currentColor"/>
            </svg>
        </>
    );
}

export default Euro;