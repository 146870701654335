import React from "react";

const AssignTo = () => {
    return (
        <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet"
        >
            <g
                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="currentColor"
                stroke="none"
            >
                <path
                    d="M2976 4714 c-378 -75 -645 -326 -744 -699 -123 -464 32 -1050 391
-1477 51 -60 67 -88 76 -128 6 -28 11 -56 11 -63 0 -7 79 -91 175 -187 l175
-175 123 1 c151 1 248 29 359 103 120 81 121 83 123 208 2 127 18 179 74 238
93 97 191 249 270 416 201 424 226 893 65 1238 -130 278 -361 458 -669 522
-108 23 -324 24 -429 3z"
                />
                <path
                    d="M1385 3109 c-46 -13 -111 -73 -130 -121 -9 -24 -17 -63 -18 -88 -1
-98 -2 -97 421 -522 l396 -398 -924 0 c-626 0 -937 -4 -964 -11 -51 -14 -121
-80 -142 -135 -39 -104 6 -225 105 -276 l53 -28 936 0 936 0 -396 -397 c-426
-429 -422 -424 -421 -528 1 -157 174 -263 318 -196 27 13 234 213 646 627 598
600 607 610 619 662 14 59 7 109 -20 162 -10 19 -290 306 -622 637 -516 515
-609 603 -643 612 -47 13 -107 13 -150 0z"
                />
                <path
                    d="M3850 2027 c0 -7 -42 -98 -94 -202 -84 -170 -192 -348 -259 -428
l-24 -29 -47 87 c-60 114 -130 218 -182 273 -34 35 -48 43 -71 40 -26 -3 -28
-7 -35 -62 -15 -115 -14 -114 -586 -687 l-534 -537 91 -16 c316 -58 513 -71
1091 -71 529 1 652 7 950 51 458 67 756 207 851 400 28 57 32 76 37 176 3 69
0 135 -7 170 -47 226 -295 451 -736 668 -174 85 -400 180 -430 180 -8 0 -15
-6 -15 -13z"
                />
            </g>
        </svg>
    );
};

export default AssignTo;
