import React from "react";
import {
  CommProfileProps,
  WageCodesProps,
  WageTabProps,
} from "../../annotations/AdminFicheInterface";
import { normalizeDate } from "components/common/utlis/HelperFunctions";

interface ChildProps {
  state: any;
}
const ValidateWageTab = ({ state }: ChildProps) => {    
  const updatedWageAndPremiums = { ...state.wageAndPremiums };

  const initialWage = Object.values(
    state.initialWageAndPremiums || {}
  ) as WageTabProps[];

  const normalizeValue = (value: string | number | undefined) => {
    if (typeof value === "string") {
      return value === "" ? 0 : parseFloat(value.replace(",", "."));
    }
    return value ?? null;
  };

  const compareDates = (
    dateA: string | Date | undefined | null,
    dateB: string | Date | undefined | null
  ) => {
    return normalizeDate(dateA) !== normalizeDate(dateB);
  };

  (Object.values(updatedWageAndPremiums || {}) as WageTabProps[]).forEach(
    (wage: WageTabProps, wageIndex: number) => {
      Object.values(wage.commProfile || {}).forEach(
        (profile: CommProfileProps, profileIndex: number) => {
          const initialProfile =
            initialWage[wageIndex]?.commProfile?.[profileIndex];

          // Only compare if both brightId and initialProfile exist
          if (profile?.brightId && initialProfile) {            
            if (
              compareDates(initialProfile?.startDate, profile?.startDate) ||
              normalizeValue(initialProfile?.mtcTotal) !==
                normalizeValue(profile?.mtcTotal) ||
              normalizeValue(initialProfile?.mtcEmployer) !==
                normalizeValue(profile?.mtcEmployer)
            ) {
              updatedWageAndPremiums[wageIndex].commProfile[
                profileIndex
              ].isValueChanged = true;
            }

            // Check wage codes
            profile?.wageCodes?.forEach(
              (code: WageCodesProps, codeIndex: number) => {
                if (
                  normalizeValue(
                    initialProfile?.wageCodes?.[codeIndex]?.amount
                  ) !== normalizeValue(code?.amount)
                ) {
                  updatedWageAndPremiums[wageIndex].commProfile[
                    profileIndex
                  ].isValueChanged = true;
                }
              }
            );
            const currentWageCodeIds = profile?.wageCodes?.map((code) => code?.wageCode?.value) || [];
            const initialWageCodeIds =
              initialProfile?.wageCodes?.map((code) => code?.wageCode?.value) || [];

            if (initialWageCodeIds.some((initialCodeId) => !currentWageCodeIds.includes(initialCodeId))) {                
                updatedWageAndPremiums[wageIndex].commProfile[
                    profileIndex
                  ].isValueChanged = true;
            }
          }
        }
      );
    }
  );

  return updatedWageAndPremiums;
};

export default ValidateWageTab;
