import { Option, OptionProps } from "components/common/utlis/TypeAnnotations";

export interface CandidateCentralSearchData {
  office: number[];
  companyName: OptionProps[];
  function: OptionProps[];
  firstName: string;
  lastName: string;
  from: Date | null | string;
  to: Date | null | string;
  orderByColumn: string;
  orderIn: "" | "asc" | "desc"; // Ensure correct typing here
  currentPage: number;
  recordsPerPage: number;
}

export const intialCandidateCentralSearchData: CandidateCentralSearchData = {
  office: [],
  companyName: [],
  function: [],
  firstName: "",
  lastName: "",
  from: "",
  to: "",
  orderByColumn: "",
  orderIn: "",
  currentPage: 1,
  recordsPerPage: 10,
};

export interface dropDownList {
  offices: OptionProps[];
  functions: OptionProps[];
}

export const intialDropDownList: dropDownList = {
  offices: [] as OptionProps[],
  functions: [] as OptionProps[],
};

export interface CandidateCentralData {
  id?: number;
  firstName: string;
  lastName: string;
  status: number | undefined;
  officename: string;
  email: string;
  phone: string;
  created: string;
  functions: OptionProps[];
  PresentedCount: number;
  MatchingCount: number;
  NewCount: number;
  InterviewCount: number;
  WageCount: number;
  ScreeningCount: number;
  StarterCount: number;
}
