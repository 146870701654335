import React, { ReactElement, useEffect, useState } from "react";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import PhoneInput from "react-phone-number-input";
import LabelField from "components/common/atoms/LabelField";
import InputTextfield from "components/common/atoms/InputTextField";
import {
  GoogleReCaptchaProvider,
  // GoogleReCaptcha,
} from "react-google-recaptcha-v3";
import Button from "components/common/atoms/Button";
import { ApiCall } from "components/common/services/ApiServices";
import { useNavigate, Link } from "react-router-dom";
import LoadingIcon from "components/common/utlis/LoadingIcon";
import {
  validateForm,
  validateEmail,
  validateRequired,
  validateCheckbox,
  validateTextFieldAlpha,
  validatePhoneNumber,
  Option,
  validateSelectField,
} from "components/common/services/ValidationService";
import "static/css/login.css";
import ImageField from "components/common/atoms/ImageField";
import CustomNotify, { NotifyType } from "components/common/atoms/CustomNotify";
import { reCaptchaSiteKey } from "Constants";
import * as ENDPOINTS from "../../../routes/ApiEndpoints";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import {
  OptionProps,
  ValidationRules,
} from "components/common/utlis/TypeAnnotations";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { mapToSelect } from "components/common/utlis/MapToSelect";
import { E164Number } from "libphonenumber-js";
import ModalPopup from "components/common/atoms/ModalPopup";

interface Registration {
  firstName?: string;
  lastName?: string;
  email?: string;
  mobNumber?: E164Number | string | undefined;
  terms?: boolean;
  city?: Option | null;
  country: Option | null;
  cityOther: string;
  isCaptcha?: boolean;
  formStatus?: number;
}

const RegistrationForm: React.FC = () => {
  const navigate = useNavigate();
  const initialErrors: { [key: string]: string } = {};
  const [errors, setErrors] = useState<{ [key: string]: string }>(
    initialErrors
  );
  const [formData, setFormData] = useState<Registration>({
    firstName: "",
    lastName: "",
    email: "",
    mobNumber: "" as E164Number,
    terms: false,
    country: null,
    cityOther: "",
    city: null,
    isCaptcha: false,
    formStatus: 0,
  });

  const [dropDownData, setDropDownData] = useState({
    locations: [] as OptionProps[],
    countries: [] as OptionProps[],
  });

  const [loading, setLoading] = useState(false);

  const validation = (
    name: string,
    value: string | boolean | Option | null,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      firstName: [validateRequired, validateTextFieldAlpha],
      lastName: [validateRequired, validateTextFieldAlpha],
      email: [validateRequired, validateEmail],
      mobNumber: [validatePhoneNumber],
      terms: [validateCheckbox],
      city: [validateSelectField],
      country: [validateSelectField],
      cityOther: [validateRequired],
    };

    const validationErrors = validateForm(
      { ...formData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (formData.country?.value === 29) {
      delete validationErrors.cityOther;
    } else {
      delete validationErrors.city;
    }

    if (isSingleFieldValidation && Object.keys(errors).length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }

    return true;
  };

  /** Update the form data fields statue onChange event*/
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
    let updatedValue: string | boolean;
    if (type === "checkbox") {
      updatedValue = checked;
    } else {
      updatedValue = value;
    }
    setFormData((formData) => ({ ...formData, [name]: updatedValue }));
    validation(name, updatedValue, true);
  };

  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: selectedOption,
    }));
    validation(fieldName, selectedOption, true);
  };

  /** Read the phone number filed state onChange event*/
  const handlePhoneNumberChange = (e: string | E164Number | undefined) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      mobNumber: e || "",
    }));
    validation("mobNumber", e || "", true);
  };

  useEffect(() => {
    fetchDropDownDetails();
  }, []);

  const fetchDropDownDetails = async () => {
    const response = await ApiCall.getService(
      ENDPOINTS.GET_REGISTER_FORM_DETAILS,
      "get"
    );
    if (response.status === 200) {
      setDropDownData({
        locations: mapToSelect(response.data["locations"]),
        countries: mapToSelect(response.data["countries"]),
      });
      setModal(response.data["termsAndConditions"]);
    }
  };

  /** Update ReCAPTCHA value onChange event*/
  // const handleCaptchaVerify = (response: string | null) => {
  //   if (response) {
  //     setFormData((prevFormData) => ({
  //       ...prevFormData,
  //       isCaptcha: true,
  //     }));
  //   }
  // };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const { name, value } = e.target as HTMLInputElement;
    if (validation(name, value)) {
      setShowModal(true);
    }
  };

  //for candidate accept terms modal pop up
  const [showModal, setShowModal] = useState(false);

  const [modal, setModal] = useState({
    body: "",
    title: "",
  });

  const handleModalClose = () => {
    setShowModal(!showModal);
  };

  const handleSaveChanges = async () => {
    setLoading(true);
    setShowModal(false);
    const response = await ApiCall.service(
      ENDPOINTS.REGISTER,
      "POST",
      formData
    );
    if (response.status === 200) {
      let notificationType: NotifyType = "success";
      setTimeout(() => {
        navigate("/login");
      }, 2000); // Navigate after 5 seconds
      CustomNotify({ type: notificationType, message: response.msg });
      if (response.userId) {
        const updatedFormData = {
          type: "Candidate",
          general: formData,
          personId: response.userId,
          candidateStatus: 0,
          formStatus: 0,
          src: "externalRegister",
          submitType: "draft",
        };
        const candiadteResponse = await ApiCall.service(
          ENDPOINTS.RESGISTER_CANDIDATE,
          "POST",
          updatedFormData,
          false,
          "candidate-creation"
        );
        if (candiadteResponse.status === 200) {
          const data = {
            stage: "New",
            source: 1,
            candidateId: candiadteResponse.candidateId,
          };
          await ApiCall.service(
            ENDPOINTS.CREATE_TODO,
            "POST",
            data,
            false,
            "central-data-management"
          );
        }
      }
    } else {
      if (response.errors.email && response.errors.phone_number) {
        setErrors({
          email: response.errors.email,
          mobNumber: response.errors.phone_number,
        });
      } else {
        response.errors.email
          ? setErrors({ email: response.errors.email })
          : setErrors({ mobNumber: response.errors.phone_number });
      }
    }
    setLoading(false);
  };

  return (
    <>
      <div className="container-fluid login-bg">
        <div className="row">
          <div className="col-6 px-0 vh-100">
            <ImageField
              altText="Banner"
              className="w-100 h-100 img-fluid object-fit-cover"
              src="/static/images/login.jpg"
            />
          </div>
          <div className="col-6 align-self-center vh-100 overflow-auto">
            <div style={{ width: "60%", margin: "auto" }}>
              <div
                className="text-center"
                style={{ marginBottom: "3vw", paddingTop: "2vw" }}
              >
                <ImageField
                  altText={t("Logo")}
                  className="text-center logo-width"
                  src="static/images/logo.svg"
                />
              </div>
              <div className="text-center" style={{ marginBottom: "2vw" }}>
                <span className="text-center login-title position-relative">
                  {t("Candidate registration")}
                  <img
                    src="../static/images/Splash_1.svg"
                    alt="dot-arrow"
                    className="dot-arrow position-absolute"
                  />
                </span>
              </div>
              <GoogleReCaptchaProvider reCaptchaKey={reCaptchaSiteKey}>
                <form onSubmit={handleSubmit} className="register-form">
                  <LabelWithInputField
                    isMandatory={true}
                    name="firstName"
                    handleChange={changeHandler}
                    value={formData.firstName}
                    id="fname"
                    label={t("First name")}
                    placeholder={t("First name")}
                    error={errors.firstName}
                  />
                  <LabelWithInputField
                    isMandatory={true}
                    name="lastName"
                    handleChange={changeHandler}
                    value={formData.lastName}
                    id="lname"
                    label={t("Last name")}
                    placeholder={t("Last name")}
                    error={errors.lastName}
                  />
                  <LabelWithInputField
                    isMandatory={true}
                    name="email"
                    handleChange={changeHandler}
                    value={formData.email}
                    id="email"
                    label={t("Email")}
                    placeholder={t("Email")}
                    type="email"
                    error={errors.email}
                  />
                  <LabelField
                    title={t("Phone number")}
                    isMandatory={true}
                    key="PhoneInput"
                  />
                  <div
                    className="form-control field-shadow d-flex"
                    style={{ borderRadius: "0.5vw" }}
                  >
                    <PhoneInput
                      international
                      placeholder={t("Enter phone number")}
                      defaultCountry="BE" //Belgium country code
                      value={
                        formData.mobNumber !== undefined
                          ? (formData.mobNumber as E164Number)
                          : ("" as E164Number)
                      }
                      onChange={handlePhoneNumberChange}
                      className="w-100"
                    />
                  </div>
                  <div className="height-20" style={{ marginBottom: "0.5vw" }}>
                    {errors.mobNumber && (
                      <span className="text-danger">{errors.mobNumber}</span>
                    )}
                  </div>
                  <div className="col-sm-12 col-md-12">
                    <SelectWithSearch
                      title={t("Country")}
                      isMandatory={true}
                      search={true}
                      options={dropDownData.countries}
                      placeHolder={t("Select country")}
                      onChange={(e) => handleSelectChange(e, "country")}
                      isMulti={false}
                      name="country"
                      value={formData.country}
                      error={errors.country}
                      isTranslate={true}
                    />
                  </div>
                  {formData.country?.value && formData.country.value === 29 ? (
                    <div className="col-sm-12 col-md-12">
                      <SelectWithSearch
                        title={t("Location")}
                        isMandatory={true}
                        search={true}
                        options={dropDownData.locations}
                        placeHolder={t("Select location")}
                        onChange={(e) => handleSelectChange(e, "city")}
                        isMulti={false}
                        // className="form-control field-shadow rounded-3 p-0 select-field"
                        name="city"
                        value={formData.city}
                        error={errors.city}
                        isTranslate={true}
                      />
                    </div>
                  ) : (
                    <div className="col-sm-12 col-md-12">
                      <LabelWithInputField
                        isMandatory={true}
                        name="cityOther"
                        handleChange={changeHandler}
                        value={formData.cityOther}
                        id="cityOther"
                        label={t("Location")}
                        placeholder={t("Location")}
                        error={errors.cityOther}
                      />
                    </div>
                  )}
                  <div className="d-flex align-items-center">
                    <InputTextfield
                      type="checkbox"
                      handleChange={changeHandler}
                      checked={formData.terms}
                      name="terms"
                      className="form-check-input mt-0 shadow-none rounded-0 border"
                      id="termsAndConditions"
                    />
                    <Link
                      to="/terms-conditions"
                      target="_blank"
                      about="terms and conditions"
                      className="ms-2 link-color"
                    >
                      {t("Accept terms and conditions")}
                    </Link>
                  </div>
                  <div className="height-20" style={{ marginBottom: "0.5vw" }}>
                    {errors.terms && (
                      <span className="text-danger">
                        {t("Please accept terms and conditions.")}
                      </span>
                    )}
                  </div>
                  {/* <div className="d-flex justify-content-center">
                    <GoogleReCaptcha onVerify={handleCaptchaVerify} />
                  </div> */}
                  <div
                    className="text-center"
                    style={{ marginBottom: "0.5vw" }}
                  >
                    {errors.isCaptcha && (
                      <span className="text-danger text-center">
                        {t("Please verify that you are not a robot.")}
                      </span>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-md-10 mx-auto">
                      {!loading ? (
                        <Button
                          type="submit"
                          icon={faAngleRight}
                          className="form-button w-100"
                          title={t("Register")}
                        />
                      ) : (
                        <LoadingIcon
                          iconType="bars"
                          color="#e55496"
                          height="5vw"
                          width="5vw"
                        />
                      )}
                    </div>
                  </div>
                </form>
              </GoogleReCaptchaProvider>
              <div
                className="text-center"
                style={{ marginTop: "1.7vw", marginBottom: "2vw" }}
              >
                {t("Already registered") + "?"}
                <a href="/login" className="link-color ms-2">
                  {t("Login here")}
                </a>
              </div>
            </div>
          </div>
        </div>
        <ModalPopup
          show={showModal}
          onHide={handleModalClose}
          title={modal.title}
          body={modal.body}
          onConfirmButtonClick={handleSaveChanges}
          className="modal-lg"
          confirmTitle={t("Accept")}
          isInnerHTML={true}
        />
      </div>
    </>
  );
};

export default translate(RegistrationForm);
