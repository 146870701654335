import { t } from "components/CentralDataMangement/translation/Translation";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import React from "react";
import { AdminFicheFormData } from "../../../annotations/AdminFicheInterface";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import RadioField from "components/common/atoms/RadioField";
import LabelField from "components/common/atoms/LabelField";
import CheckBoxField from "components/common/atoms/CheckBoxField";
import LabelWithTextAreaField from "components/common/molecules/LabelWithTextAreaField";
import Online from "./Online";

interface ChildProps {
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleSelectChange: (selectedOption: any, title: string) => void;
  state: AdminFicheFormData;
}

const GeneralDetails: React.FC<ChildProps> = ({
  handleChange,
  handleSelectChange,
  state,
}) => {
  const generalState = state.general;
  const isPC124 = generalState.paritairBlue.some((pc: any) => pc.value === 39);
  return (
    <>
      <div className="row">
        <div className="col-md-4">
          <LabelWithInputField
            isMandatory={false}
            name="company"
            label={t("Company")}
            handleChange={(e) => {
              handleChange(e);
            }}
            type="text"
            value={generalState.company}
            error={state.errors.company}
            isDisabled={true}
          />
        </div>
        <div className="col-md-4 generalTabVAT">
          <div className="input-group mb-3 position-relative">
            <div className="position-absolute fieldPrefix">BE</div>
            <LabelWithInputField
              isMandatory={false}
              name="vatNumber"
              label={t("VAT number")}
              handleChange={handleChange}
              type="text"
              isDisabled={true}
              value={generalState.vatNumber}
            />
          </div>
        </div>
        <div className="col-md-4">
          <SelectWithSearch
            isMulti={true}
            isMandatory={false}
            search={true}
            options={[]}
            value={generalState.paritairWhite ?? ""}
            onChange={(e) => {
              handleSelectChange(e, "paritairWhite");
            }}
            name="paritairWhite"
            title={t("PC for white collar")}
            isDisabled={true}
          />
        </div>
        <div className="col-md-4">
          <SelectWithSearch
            isMulti={true}
            isMandatory={false}
            search={true}
            options={[]}
            value={generalState.paritairBlue ?? ""}
            onChange={(e) => {
              handleSelectChange(e, "paritairBlue");
            }}
            isDisabled={true}
            name="paritairBlue"
            title={t("PC for blue collar")}
          />
        </div>
        <div className="col-md-4">
          <SelectWithSearch
            isMulti={false}
            isMandatory={true}
            search={true}
            options={state.vcaList}
            value={generalState.vca ?? ""}
            onChange={(e) => {
              handleSelectChange(e, "vca");
            }}
            name="vca"
            title={t("VCA")}
            error={state.errors.vca}
          />
        </div>
        {isPC124 && (
          <div className="col-md-4">
            <SelectWithSearch
              isMulti={true}
              isMandatory={true}
              search={true}
              options={state.nssoCategoryList}
              value={generalState.nssoCatg ?? ""}
              onChange={(e) => {
                handleSelectChange(e, "nssoCatg");
              }}
              name="nssoCatg"
              title={t("NSSO category")}
              error={state.errors.nssoCatg}
            />
          </div>
        )}
        <div className="col-md-3 marginBotttom1">
          <div className="mb-2">
            <LabelField title={t("Online encodage")} />
          </div>
          <div className="d-inline-block marginRight1">
            <RadioField
              handleChange={handleChange}
              ischecked={generalState.encodage === 1}
              label={t("Yes")}
              value={1}
              name="encodage"
            />
          </div>
          <div className="d-inline-block">
            <RadioField
              handleChange={handleChange}
              ischecked={generalState.encodage === 0}
              label={t("No")}
              value={0}
              name="encodage"
            />
          </div>
        </div>
        <div className="col-md-3 marginBotttom1">
          <div>
            <LabelField title={t("Syndicated deligation")} isMandatory={true} />
          </div>
          <div className="marginRight1 d-inline-block">
            <RadioField
              handleChange={handleChange}
              ischecked={generalState.syndicatedDelig === 1}
              label={t("Yes")}
              value={1}
              name="syndicatedDelig"
            />
          </div>
          <div className="d-inline-block">
            <RadioField
              handleChange={handleChange}
              ischecked={generalState.syndicatedDelig === 0}
              label={t("No")}
              value={0}
              name="syndicatedDelig"
            />
          </div>
          <div>
            <span className="text-danger">{state.errors.syndicatedDelig}</span>
          </div>
        </div>
        <div className="col-md-3 marginBotttom1">
          <div>
            <LabelField title={t("Consecutive daily contracts possible")} />
          </div>
          <div className="d-inline-block marginRight1">
            <RadioField
              handleChange={handleChange}
              ischecked={generalState.isContracts === 1}
              label={t("Yes")}
              value={1}
              name="isContracts"
            />
          </div>
          <div className="d-inline-block">
            <RadioField
              handleChange={handleChange}
              ischecked={generalState.isContracts === 0}
              label={t("No")}
              value={0}
              name="isContracts"
            />
          </div>
        </div>
        {generalState.isContracts === 1 && (
          <div className="col-md-3 marginBotttom1">
            <div>
              <LabelField
                title={t("40 consecutive daily contracts per semester")}
              />
            </div>
            <div className="marginRight1 d-inline-block">
              <RadioField
                handleChange={handleChange}
                ischecked={generalState.isContractsPerSem === 1}
                label={t("Yes")}
                value={1}
                name="isContractsPerSem"
              />
            </div>
            <div className="d-inline-block">
              <RadioField
                handleChange={handleChange}
                ischecked={generalState.isContractsPerSem === 0}
                label={t("No")}
                value={0}
                name="isContractsPerSem"
              />
            </div>
          </div>
        )}
        {isPC124 && (
          <div className="col-md-3 marginBotttom1">
            <div>
              <LabelField
                title={t("KB 213 (wage supplement 20%) applicable")}
                isMandatory={true}
              />
            </div>
            <div className="d-inline-block marginRight1">
              <RadioField
                handleChange={handleChange}
                ischecked={generalState.isKBApplicable === 1}
                label={t("Yes")}
                value={1}
                name="isKBApplicable"
              />
            </div>
            <div className="d-inline-block">
              <RadioField
                handleChange={handleChange}
                ischecked={generalState.isKBApplicable === 0}
                label={t("No")}
                value={0}
                name="isKBApplicable"
              />
            </div>
            <div>
              <span className="text-danger">{state.errors.isKBApplicable}</span>
            </div>
          </div>
        )}

        <div className="col-md-3 marginBotttom1">
          <div>
            <LabelField
              title={t("Explicit agreement on work regime(s)")}
              isMandatory={true}
            />
          </div>
          <div className="d-inline-block marginRight1">
            <RadioField
              handleChange={handleChange}
              ischecked={generalState.explicitRegime === 1}
              label={t("Yes")}
              value={1}
              name="explicitRegime"
            />
          </div>
          <div className="d-inline-block">
            <RadioField
              handleChange={handleChange}
              ischecked={generalState.explicitRegime === 0}
              label={t("No")}
              value={0}
              name="explicitRegime"
            />
          </div>
          <div>
            <span className="text-danger">{state.errors.explicitRegime}</span>
          </div>
        </div>
        {generalState.explicitRegime ? (
          <div className={`col-md-3`}>
            <SelectWithSearch
              isMulti={true}
              isMandatory={true}
              search={true}
              options={state.workRegimeList}
              value={generalState.exemptionBV ?? ""}
              onChange={(e) => {
                handleSelectChange(e, "exemptionBV");
              }}
              name="exemptionBV"
              title={t("Work regime(s) exemption BV")}
              error={state.errors.exemptionBV}
            />
          </div>
        ) : (
          ""
        )}
        <div className="col-12"/>
        <div className="col-md-4 generalOther">
          <LabelField title={t("Other employment agreements")} />
          <div className="marginBottomPoint5">
            <CheckBoxField
              label={t("Large flexibility")}
              name="largeFlex"
              onChangeHandler={handleChange}
              value={generalState.largeFlex ? "1" : "0"}
              isChecked={
                generalState.largeFlex !== null ? generalState.largeFlex : false
              }
              id="largeFlex"
              lineHeight={"1vw"}
            />
          </div>
          <div className="marginBottomPoint5">
            <CheckBoxField
              label={t("Small flexibility")}
              name="smallFlex"
              onChangeHandler={handleChange}
              value={generalState.smallFlex ? "1" : "0"}
              isChecked={
                generalState.smallFlex !== null ? generalState.smallFlex : false
              }
              id="smallFlex"
              lineHeight={"1vw"}
            />
          </div>
          <div>
            <CheckBoxField
              label={t("Other")}
              name="other"
              onChangeHandler={handleChange}
              value={generalState.other ? "1" : "0"}
              isChecked={
                generalState.other !== null ? generalState.other : false
              }
              id="other"
              lineHeight={"1vw"}
            />
          </div>
          {generalState.other ? (
            <LabelWithTextAreaField
              name="otherExtraInfo"
              handleChange={handleChange}
              isMandatory={false}
              value={generalState.otherExtraInfo ?? ""}
            />
          ) : (
            ""
          )}
        </div>
        <div className="col-md-4">
          <div>
            <LabelField title={t("Catch up rest days")} isMandatory={true} />
          </div>
          <div className="d-block marginBottomPoint5">
            <RadioField
              handleChange={handleChange}
              ischecked={generalState.catchUpRest === 1}
              label={t("Free to take (in consultation with customer)")}
              value={1}
              name="catchUpRest"
            />
          </div>
          <div className="d-block marginBottomPoint5">
            <RadioField
              handleChange={handleChange}
              ischecked={generalState.catchUpRest === 2}
              label={t("Collectively placed")}
              value={2}
              name="catchUpRest"
            />
          </div>
          <div className="d-block">
            <RadioField
              handleChange={handleChange}
              ischecked={generalState.catchUpRest === 3}
              label={t("Combination")}
              value={3}
              name="catchUpRest"
            />
          </div>
          <div>
            <span className="text-danger">{state.errors.catchUpRest}</span>
          </div>
        </div>
        <div className="col-md-4">
          <div>
            <LabelField title={t("Leaves")} isMandatory={true} />
          </div>
          <div className="d-block marginBottomPoint5">
            <RadioField
              handleChange={handleChange}
              ischecked={generalState.leaves === 1}
              label={t("Free to take (in consultation with customer)")}
              value={1}
              name="leaves"
            />
          </div>
          <div className="d-block marginBottomPoint5">
            <RadioField
              handleChange={handleChange}
              ischecked={generalState.leaves === 2}
              label={t("Collectively placed")}
              value={2}
              name="leaves"
            />
          </div>
          <div className="d-block">
            <RadioField
              handleChange={handleChange}
              ischecked={generalState.leaves === 3}
              label={t("Combination")}
              value={3}
              name="leaves"
            />
          </div>
          <div>
            <span className="text-danger">{state.errors.leaves}</span>
          </div>
        </div>
        <Online handleChange={handleChange} generalState={generalState} />
        <div className={`col-md-12 hideHeight`}>
          <LabelWithTextAreaField
            name="extraInfo"
            handleChange={handleChange}
            label={t("Extra info")}
            isMandatory={false}
            value={generalState.extraInfo}
          />
        </div>
      </div>
    </>
  );
};

export default React.memo(GeneralDetails);
