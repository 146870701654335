import { initialTimeStates } from "components/common/utlis/PlanningUtility";
import {
  BASIC_DETAILS,
  BILLING_DETAILS,
  GENERAL_DETAILS,
  REMENUARATION_DETAILS,
  SET_DROPDOWN_DATA,
  TIME_TABLE_DETAILS,
  UPDATE_FIELDS,
} from "../../annotation/PlanningConstants";
import { TabField } from "../../annotation/PlanningInterface";

// COMPANY RELATATED ******************************* ///
// Reset company-related fields and dropdowns
export const resetCompanyRelatedFields = (
  dispatch: React.Dispatch<any>,
  dropdownOptions?: { branchOptions: [] } // Ensure dropdown options are passed as a parameter
) => {
  companyRelatedFields.forEach((object: any) => {
    dispatch({
      type: UPDATE_FIELDS,
      tab: object.tab,
      field: object.field,
      value: object.value,
    });
  });

  // Dispatch dropdown reset options
  dispatch({ type: SET_DROPDOWN_DATA, payload: dropdownOptions });
};

// Reset location-related fields
export const resetLocationRelatedFields = (dispatch: React.Dispatch<any>) => {
  locationRelatedFields.forEach((object: any) => {
    dispatch({
      type: UPDATE_FIELDS,
      tab: object.tab,
      field: object.field,
      value: object.value,
    });
  });
};

// Location-related fields to reset
export const locationRelatedFields = [
  { tab: GENERAL_DETAILS, field: "street", value: "" },
  { tab: GENERAL_DETAILS, field: "number", value: "" },
  { tab: GENERAL_DETAILS, field: "city", value: null },
  { tab: GENERAL_DETAILS, field: "country", value: null },
  { tab: GENERAL_DETAILS, field: "zipCode", value: "" },
  { tab: GENERAL_DETAILS, field: "box", value: "" },
];

// Company-related fields to reset, including location-related fields
export const companyRelatedFields = [
  { tab: BASIC_DETAILS, field: "branch", value: null },
  { tab: BASIC_DETAILS, field: "partiarCommittee", value: null },
  ...locationRelatedFields, // Includes location fields
];

// Default empty dropdown options
export const defaultDropdownOptions = {
  branchOptions: [],
  collegueOptions: [],
};

//Profile RELATED ********************** ///
//reset planning profile details
export const resetPlanningProfileRelatedFields = (
  state: any,
  dispatch: React.Dispatch<any>,
  type: string
) => {
  let fields = [
    { tab: BASIC_DETAILS, field: "employeeType", value: null },
    { tab: BASIC_DETAILS, field: "function", value: "" },
  ];
  if (type === "partiarCommittee") {
    fields = [
      ...fields,
      { tab: BASIC_DETAILS, field: "planningProfile", value: null },
    ];
    const dropdownOptions = {
      pcCommercialProfileOptions: state.dropDowndata.commercialProfileOptions,
      pcPayProfileOptions: state.dropDowndata.payProfileOptions,
    };
    dispatch({ type: SET_DROPDOWN_DATA, payload: dropdownOptions });
  }
  fields.forEach((object: any) => {
    dispatch({
      type: UPDATE_FIELDS,
      tab: object.tab,
      field: object.field,
      value: object.value,
    });
  });
};

//reset coeff details
export const resetCoeffDetails = (dispatch: React.Dispatch<any>) => {
  const dropdownOptions = {
    premiumCodes: [],
    payRollCodes: [],
    devCoefficients: [],
  };
  dispatch({ type: SET_DROPDOWN_DATA, payload: dropdownOptions });
};

//reset shift details
export const resetShiftDetails = (
  dispatch: React.Dispatch<any>,
  timeTableType: boolean = false
) => {
  const fields: TabField[] = [
    { field: "timeTable", value: initialTimeStates },
    { field: "regime", value: null },
    { field: "shift", value: null },
    { field: "shiftName", value: "" },
    { field: "effectiveHrsFullTime", value: "" },
    { field: "avgHrsFullTime", value: "" },
    { field: "effectiveHrsAvg", value: "" },
    { field: "paidAdv", value: "" },
    { field: "unPaidAdv", value: "" },
  ];
  if (timeTableType) {
    fields.push({
      field: "timeTableType",
      value: null,
    });
  }
  fields.forEach((object: any) => {
    dispatch({
      type: UPDATE_FIELDS,
      tab: TIME_TABLE_DETAILS,
      field: object.field,
      value: object.value,
    });
  });
};

//reset profile details
export const resetCommercialProfileDetails = (
  dispatch: React.Dispatch<any>,
  commercialProfile: boolean = false
) => {
  const fields: TabField[] = [
    { field: "selection", value: 0 },
    { field: "reducedRate", value: 0 },
    { field: "dimonaInvoice", value: 0 },
    { field: "invoiceEcho", value: 0 },
    { field: "payrollCoefficient", value: "" },
    { field: "reduceCoefficient", value: "" },
    { field: "relocationCoeff", value: "" },
    { field: "dimonaCost", value: "" },
    { field: "echoCheqCoeff", value: "" },
    { field: "bankHoliday", value: null },
    { field: "holidayCoeff", value: "" },
    { field: "mealVochrCoeff", value: "" },
    { field: "disease", value: null },
    { field: "standardVatRate", value: null },
    { field: "deviatingCoefficients", value: [] },
  ];

  if (commercialProfile) {
    fields.push({
      field: "commercialProfile",
      value: null,
    });
  }
  fields.forEach((object: any) => {
    dispatch({
      type: UPDATE_FIELDS,
      tab: BILLING_DETAILS,
      field: object.field,
      value: object.value,
    });
  });
};

// reset pay profile details
export const resetPayProfileDetails = (
  dispatch: React.Dispatch<any>,
  payProfile: boolean = false
) => {
  const fields: TabField[] = [
    { field: "mtcTotalValue", value: "" },
    { field: "mtcEmpPart", value: "" },
    { field: "mtcMinHr", value: "" },
    { field: "perfCodes", value: [] },
    { field: "automaticCodes", value: [] },
  ];

  // Conditionally add the payProfile field if payProfile is true
  if (payProfile) {
    fields.push({
      field: "payProfile",
      value: null,
    });
  }

  fields.forEach(({ field, value }) => {
    dispatch({
      type: UPDATE_FIELDS,
      tab: REMENUARATION_DETAILS,
      field,
      value,
    });
  });
};
