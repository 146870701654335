import { ReactNode, createContext, useContext, useReducer } from "react";
import { Dispatch } from "./Actions";
import { PlanningInitialState } from "../annotation/PlanningIntialState";
import { PlaningFormData } from "../annotation/PlanningInterface";
import PlanningReducer from "./PlanningReducer";

interface FormProviderProps {
    children: ReactNode;
}

const FormContext = createContext<
    | {
          state: PlaningFormData;
          dispatch: Dispatch;
      }
    | undefined
>(undefined);

export const useFormContext = () => {
    const context = useContext(FormContext);
    if (!context) {
        throw new Error("useFormContext must be used within a FormProvider");
    }
    return context;
};

export const FormProvider: React.FC<FormProviderProps> = ({ children }) => {
    const [state, dispatch] = useReducer(PlanningReducer, PlanningInitialState);
    
    return (
        <FormContext.Provider value={{ state, dispatch }}>
            {children}
        </FormContext.Provider>
    );
};

