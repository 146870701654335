import React, { CSSProperties, useState, useEffect } from "react";
import "static/css/file-upload.css";
import { AxiosCall } from "../services/AxiosService";
import LoadingIcon from "../utlis/LoadingIcon";
import LabelField from "../atoms/LabelField";
import DeleteIcon from "static/images/DeleteIcon";
import { t } from "components/CentralDataMangement/translation/Translation";
import DownloadIcon from "static/images/Download";
import axios from "axios";
import * as ENDPOINTS from "../../../routes/ApiEndpoints";
import IconUploaded from "static/images/IconUploaded";
import UploadIcon from "static/images/Upload";
import ModalPopup from "../atoms/ModalPopup";

type fileUploadErrorsType = (errorInfo: object) => void;
type fileUploadResponseType = (uploadResponse: object, index: number) => void;
type deleteResponseType = (field: string, index: number) => void;

interface fileObject {
  fileName: string | null;
  filePath: string | null;
  fileId: number | null;
}

interface FileUploadProps {
  label?: string;
  isMandatory?: boolean;
  id: string;
  name: string;
  edit: boolean;
  fileId: number | null;
  filePath: string | null;
  fileName: string | null;
  style?: CSSProperties;
  multiple: boolean;
  formats: string;
  index?: number;
  uploadPath: string;
  returnFileUploadErrors: fileUploadErrorsType;
  fileUploadResponse: fileUploadResponseType;
  deleteResponse: deleteResponseType;
  maxFileSize: number;
  placeholder?: string;
  page?: string;
  modalPopup?: boolean;
  modalDetails?: { title: string; description: string };
  isViewMode?: boolean;
}

const FileUploadForCandidate: React.FC<FileUploadProps> = (props) => {
  const {
    page,
    label,
    isMandatory,
    id,
    name,
    // style,
    multiple,
    formats,
    index,
    fileId,
    filePath,
    fileName,
    uploadPath,
    returnFileUploadErrors,
    fileUploadResponse,
    deleteResponse,
    maxFileSize,
    placeholder = t("Upload file"),
    modalPopup = false,
    modalDetails,
    isViewMode = false,
  } = props;
  const [fileObject, setFileObject] = useState<fileObject>({
    fileName: "",
    filePath: "",
    fileId: null,
  });

  useEffect(() => {
    setFileObject((prevObject) => ({
      ...prevObject,
      fileName: fileName,
      filePath: filePath,
      fileId: fileId,
    }));
  }, [props]);

  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    formats: string,
    id: string,
    index?: number
  ) => {
    const file = event.target.files && event.target.files[0];
    const allowedFormats = formats.split(",");
    let errorString = "";
    if (file) {
      let fileExtension = file.name.split(".").pop()?.toLowerCase();
      fileExtension = `.${fileExtension}`;
      if (fileExtension && !allowedFormats.includes(fileExtension)) {
        errorString = `${t("Invalid file format")}.${t(
          " Only files with the extensions"
        )} ${formats} ${t("are allowed")}`;
        buildErrorObject(id, index, errorString);
        return;
      }
      if (file.size > maxFileSize) {
        errorString = t("File size is greater than 10mb") + ".";
        if (file.type.startsWith("image")) {
          errorString = t("Please upload a file less than 2mb") + ".";
        }
        buildErrorObject(id, index, errorString);
        return;
      }
      resolveErrors(id, index, "");
      uploadFileToS3(file, id);
    } else {
      errorString = t("There is some issue with file uploading");
      buildErrorObject(id, index, errorString);
    }
  };

  const uploadFileToS3 = async (file: File, name: string) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", name);
    const response = await AxiosCall.fileUploadService(formData, uploadPath);
    setFileObject((prevObject) => ({
      ...prevObject,
      fileName: response[1],
      filePath: response[2],
      fileId: response[0],
    }));
    const responseObj = {
      step: id,
      response: response,
      file: file,
    };
    await fileUploadResponse(responseObj, index ? index : 0);
    setLoading(false);
  };

  const removeFile = async (
    event: React.MouseEvent<HTMLSpanElement> | undefined,
    id: string
  ) => {
    setLoading(true);
    try {
      if (fileObject.filePath !== null && fileObject.fileId !== null) {
        await AxiosCall.fileDeleteService(
          fileObject.filePath,
          fileObject.fileId
        );
        setFileObject({
          fileName: null,
          filePath: null,
          fileId: null,
        });
      }

      await deleteResponse(id, index ? index : 0);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching download URL:", error);
    }
  };

  const downloadFile = async (
    id: number | null,
    name: string | null,
    path: string | null,
    uploadPath: string | null
  ) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_SERVICE}/${ENDPOINTS.FILE_DOWNLOAD}/${id}/${uploadPath}`
      );
      if (response.status === 200) {
        const fileUrl = response.data.url;
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = fileUrl;
        link.target = "_blank";
        link.download = response.data.filename; // Trigger a click event on the anchor element
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error("Error fetching download URL:", error);
    }
  };
  const handleModalClose = () => setShowPopup(!showPopup);

  const resolveErrors = (
    id: string,
    index: number | undefined,
    error: string
  ) => {
    const errorObject = {
      step: id,
      index: index ? index : 0,
      error: "",
    };
    returnFileUploadErrors(errorObject);
  };

  const buildErrorObject = (
    id: string,
    index: number | undefined,
    error: string
  ) => {
    const errorObject = {
      step: id,
      index: index ? index : 0,
      error: error,
    };
    returnFileUploadErrors(errorObject);
  };

  const deleteFileOnConfirm = () => {
    removeFile(undefined, id);
    setShowPopup(false);
  };

  return (
    <>
      <LabelField title={label} isMandatory={isMandatory} />
      {!loading ? (
        fileObject.fileName ? (
          page === "myProfile" ? (
            <div
              className="table-action-icons cursor-pointer"
              onClick={(event) => removeFile(event, id)}
              title={t("Delete photo")}
            >
              <DeleteIcon />
            </div>
          ) : page === "candidateCv" ? (
            <div className="d-flex align-items-center">
              <span
                onClick={(event) =>
                  downloadFile(
                    fileObject.fileId,
                    fileObject.fileName,
                    fileObject.filePath,
                    uploadPath
                  )
                }
                className="cursor-pointer"
              >
                <div
                  className="form-button candidateDownload"
                  title={t("Download CV")}
                >
                  <span style={{ marginRight: "0.5vw" }}>
                    {t("Download CV")}
                  </span>
                  <DownloadIcon />
                </div>
              </span>
              {!isViewMode && (
                <span
                  className="table-action-icons cursor-pointer"
                  onClick={(event) => {
                    modalPopup ? setShowPopup(true) : removeFile(event, id);
                  }}
                  title={t("Delete")}
                  style={{ marginLeft: "0.5vw" }}
                >
                  <DeleteIcon />
                </span>
              )}
            </div>
          ) : (
            <div className="d-flex justify-content-center align-items-center h-38">
              <div className="d-flex justify-content-end">
                <button
                  className="btn delete-btn shadow-none search-btns"
                  onClick={(event) => removeFile(event, id)}
                >
                  {t("Delete photo")}
                </button>
              </div>
            </div>
          )
        ) : (
          <div className="fileUploadWrapper position-relative">
            <button className="upload-button">
              {page === "candidateCv" ? (
                <div className="px-2 d-flex align-items-center">
                  <span className="me-4">{t("Upload CV")}</span>
                  <IconUploaded />
                </div>
              ) : (
                <span className="view-icon">
                  <UploadIcon />
                </span>
              )}
              <span className={`${page === "candidateCv" ? "" : "ms-3"}`}>
                {placeholder}
              </span>
            </button>
            <input
              type="file"
              id={id}
              name={name}
              className={`cursor-pointer ${
                isViewMode ? "form disabled" : "form"
              }`}
              multiple={multiple}
              accept={formats}
              onChange={(event) => handleFileChange(event, formats, id, index)}
            />
          </div>
        )
      ) : (
        <>
          {page === "candidateCv" ? (
            <div className="loadingicon">
              <LoadingIcon
                iconType="bars"
                color="#ff4dae"
                className=""
                height="3vw"
                width="3vw"
              />
            </div>
          ) : (
            <LoadingIcon
              iconType="bars"
              color="#ff4dae"
              className="m-auto"
              height="3vw"
              width="3vw"
            />
          )}
        </>
      )}
      <ModalPopup
        show={showPopup}
        onHide={handleModalClose}
        title={modalPopup ? t(modalDetails?.title) : ""}
        body={modalPopup ? t(modalDetails?.description) : ""}
        onConfirmButtonClick={deleteFileOnConfirm}
        onCloseButtonClick={handleModalClose}
        closeTitle={t("No")}
        confirmTitle={t("Yes")}
      />
    </>
  );
};

export default FileUploadForCandidate;
