import { OptionProps } from "components/common/utlis/TypeAnnotations";

export interface JobApplicationData {
  office: string;
  absJobsCv: string;
  date: string;
  candidate: string;
  vacancyName: string;
  channel: string;
  status: string;
  applicationId: number;
  email: string;
  phone: string;
  companyName: string;
}

export interface JobApplicationSearchData {
  office: number[];
  candidateName: string;
  channel: string;
  email: string;
  phone: string;
  from: Date | null | string;
  to: Date | null | string;
  companyName: OptionProps[];
  vacancyName: string;
  orderByColumn: string;
  orderIn: "" | "asc" | "desc"; // Ensure correct typing here
  currentPage: number;
  recordsPerPage: number;
  type: number;
}

export const intialJobApplicationSearchData: JobApplicationSearchData = {
  office: [],
  candidateName: "",
  channel: "",
  email: "",
  phone: "",
  from: "",
  to: "",
  companyName: [],
  vacancyName: "",
  orderByColumn: "",
  orderIn: "",
  currentPage: 1,
  recordsPerPage: 10,
  type: 1
};

export interface dropDownList {
  offices: OptionProps[];
}

export const intialDropDownList: dropDownList = {
  offices: [] as OptionProps[],
};
