import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  CONF_SETTINGS_EDIT,
  CONF_SETTINGS_ADD,
  CONF_SETTINGS_UPDATE,
} from "routes/ApiEndpoints";
import {
  validateForm,
  validateRequired,
  validateSelectField,
} from "components/common/services/ValidationService";
import { t, translate } from "components/CentralDataMangement/translation/Translation";
import Title from "components/common/atoms/Title";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
// import LabelField from "components/common/atoms/LabelField";
// import RadioField from "components/common/atoms/RadioField";
import { ValidationRules } from "components/common/utlis/TypeAnnotations";
import { ApiCall } from "components/common/services/ApiServices";
import CustomNotify from "components/common/atoms/CustomNotify";
// import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import Button from "components/common/atoms/Button";
import "../defaultLowCoefficents/lowDefaultCoefficient.css";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";

interface MUserCreationFormProps {
  id?: number | string;
}

const Coefficients: React.FC<MUserCreationFormProps> = ({ id }) => {
  const location = useLocation();
  const path = location.pathname;
  const navigate = useNavigate();

  const { id: routeId } = useParams<{ id: string }>();
  const [formData, setFormData] = useState<any>({
    id: routeId,
    coeffName: "",
    coeffType: null,
    brightId: null,
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    if (routeId !== undefined) {
      const response = await ApiCall.service(
        CONF_SETTINGS_EDIT,
        "POST",
        { id: routeId, type: "coefficient" },
        true,
        "central-data-management"
      );

      if (response.status === 200) {
        const data = response.data;

        setFormData((prevData: any) => ({
          ...prevData,
          id: data.id,
          coeffName: data.coeff_name,
          coeffType: data.reference_type_id,
          brightId: data.bbright_id,
        }));
      }
    }
  };

  const initialErrors: { [key: string]: string } = {};
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>(
    initialErrors
  );

  const handleFieldChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value, type } = e.target;
    if (type === "radio") {
      setFormData((prevData: any) => ({
        ...prevData,
        [name]: parseInt(value),
      }));
    } else {
      setFormData((prevData: any) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  // const coefficientTypeDropdown = [
  //   { value: 1, label: "Selection" },
  //   { value: 2, label: "Selection reduced" },
  //   { value: 3, label: "Payroll" },
  // ];

  // const handleSelectChange = (selectedOption: any, fieldName: string) => {
  //   setFormData((prevData: any) => ({
  //     ...prevData,
  //     [fieldName]: selectedOption,
  //   }));
  //   validation(fieldName, selectedOption, true);
  // };

  const validation = (
    name: string,
    value: string | boolean | null,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      coeffName: [validateRequired],
    };

    const validationErrors = validateForm(
      { ...formData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation && Object.keys(errors).length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    setLoading(true);
    e.preventDefault();
    const { name, value } = e.target as HTMLInputElement;
    const data = { id: id, type: "coefficient", ...formData };
    if (validation(name, value)) {
      let response = await ApiCall.service(
        CONF_SETTINGS_ADD,
        "POST",
        data,
        false,
        "central-data-management"
      );
      if (response.status === 200) {
        navigate(-1);
        CustomNotify({ type: "success", message: t(response.msg) });
      } else if (response.status === 400) {
        let errors: any = [];
        for (const key in response.errors) {
          errors[key] = response.errors[key];
        }
        setErrors({ ...errors });
      }
    }
    setLoading(false);
  };

  const permissionType = id ? "update" : "create";
  const permissionObject: any = {
    permission: "Coefficient type",
  };
  permissionObject[permissionType] = true;
  return (
    <AccessControl
      requiredPermissions={[permissionObject]}
      renderNoAccess={true}
    >
      <Title
        title={
          path === "/add/coefficient"
            ? t("Add coefficient type")
            : t("Edit coefficient type")
        }
      />
        <div className="form-border">
          <div className="row">
            <div className="col-md-6">
              <LabelWithInputField
                label={t("Coefficient name")}
                placeholder={t("Coefficient name")}
                handleChange={handleFieldChange}
                isMandatory={true}
                name="coeffName"
                value={formData.coeffName}
                error={errors.coeffName}
                id="coeffName"
              />
            </div>
            {/* Refer ceff type value  */}
            {/* {(path === "/add/coefficient" || (routeId !== undefined && routeId > '4')) &&
                  <div className="col-md-6">
                    <LabelField
                      title="Refer coefficient type"
                    />
                    <SelectWithSearch
                      search={false}
                      options={coefficientTypeDropdown}
                      value={formData.coeffType}
                      onChange={(e) =>
                        handleSelectChange(e, "coeffType")
                      }
                      name={"coeffType"}
                      isMandatory={false}
                      error={errors.coeffType}
                      id="coeffType"
                    />
                  </div>
                } */}
            {/* <div className="col-md-6">
                  <LabelWithInputField
                    label="Bright id"
                    name="brightId"
                    placeholder="Bright id"
                    handleChange={handleFieldChange}
                    value={formData.brightId ?? ''}
                    id="brightId"
                  />
                </div> */}
            {/* <div className="col-md-6">
                  <LabelField
                    title={t("Day contract")}
                    key="Status"
                    htmlfor={"dayContract"}
                  /><br />
                  <RadioField
                    name="dayContract"
                    value={1}
                    disable={false}
                    ischecked={formData.dayContract === 1}
                    handleChange={handleFieldChange}
                    label={t("Yes")}
                    className="me-3"
                  />
                  <RadioField
                    name="dayContract"
                    value={0}
                    disable={false}
                    ischecked={formData.dayContract === 0}
                    handleChange={handleFieldChange}
                    label={t("No")}
                  />
                </div> */}
          </div>
        </div>
      <div className="row backPadding">
        <div className="col-md-6 align-self-center">
          <Link
            to=""
            onClick={() => navigate(-1)}
            className="back-btn text-decoration-underline"
          >
            {t("Back")}
          </Link>
        </div>
        <div className="col-md-6">
          <Button
            type="submit"
            title={t("Save")}
            handleClick={handleSubmit}
            className="float-end form-button shadow-none"
          />
        </div>
      </div>
    </AccessControl>
  );
};

export default translate(Coefficients);
