import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import CardCircle from "components/common/atoms/CardCircle";
import CardTitle from "components/common/atoms/CardTitle";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import * as CONST from "Constants";
import { Option, OptionProps } from "components/common/utlis/TypeAnnotations";
import { Budget } from "../Dashboard";
import { useState } from "react";

type CurrentBudget = "myBudget" | "officeBudget";

const BudgetDashboard: React.FC<{
  params: any;
  offices: OptionProps[];
  onChange: (value: any) => void;
  selected: Option;
  budget: Budget;
}> = ({ params, offices, onChange, selected, budget }) => {
  const { userAuth } = params;
  const [currentBudgetType, setCurrentBudgetType] = useState<CurrentBudget>(
    userAuth.role === CONST.RECRUITER ? "myBudget" : "officeBudget"
  );
  const handleBudgetType = (budgetType: CurrentBudget) => {
    setCurrentBudgetType(budgetType);
  };

  return (
    <>
      <div className="col-4 ms-auto budgetWrapper" style={{ marginTop: "1vw" }}>
        {(userAuth.role === CONST.OFFICE_MANAGER ||
          userAuth.role === CONST.AREA_MANAGER) && (
          <SelectWithSearch
            // title="Select office"
            placeHolder={t("Office")}
            options={offices}
            value={selected ?? ""}
            name="office"
            onChange={(e) => onChange(e)}
            search
            isTranslate={true}
          />
        )}
        <div
          className="card border-0 card-shadow position-relative dashboard-myBudget-wrapper justify-content-between"
          style={{ padding: "1vw" }}
        >
          <div className="d-flex align-items-center">
            <FontAwesomeIcon
              icon={faAngleLeft}
              className={`${
                currentBudgetType === "myBudget" ||
                userAuth.role !== CONST.RECRUITER
                  ? "disabledArrow"
                  : "color-dark-pink cursor-pointer"
              } fa-lg`}
              onClick={(e) => {
                if (
                  userAuth.role === CONST.RECRUITER &&
                  currentBudgetType !== "myBudget"
                ) {
                  handleBudgetType("myBudget");
                }
              }}
            />
            <CardTitle
              title={`${t(budget?.[currentBudgetType].label)}${
                currentBudgetType === "officeBudget"
                  ? " - " + selected?.label.split("-").pop()
                  : ""
              }`}
              className={`mx-3 my-budget-title`}
            />
            <FontAwesomeIcon
              icon={faAngleRight}
              className={`${
                currentBudgetType === "officeBudget"
                  ? "disabledArrow"
                  : "color-dark-pink cursor-pointer"
              } fa-lg`}
              onClick={(e) => {
                handleBudgetType("officeBudget");
              }}
            />
          </div>
          <div className="position-relative pt-2">
            <div
              className={`circular-progress-bar-custom recruitment-card-title `}
              data-label={`€ ${budget?.[currentBudgetType].achieved ?? 0}`}
            >
              <div>
                <CircularProgressbarWithChildren
                  value={budget?.[currentBudgetType]?.percentage ?? 0}
                  className="CircularProgressbarWithChildren"
                >
                  <div className="text-center CircularProgressbar-text text-uppercase">
                    {currentBudgetType === "myBudget" &&
                    budget?.[currentBudgetType].showMessage ? (
                      <>{t("Coming soon")}</>
                    ) : (
                      <>
                        {t("Goal")}
                        <br />
                        <div>{`€ ${
                          budget?.[currentBudgetType].goal ?? 0
                        }`}</div>
                      </>
                    )}
                  </div>
                </CircularProgressbarWithChildren>
              </div>
            </div>
          </div>
          <div className="my-3">
            <CardCircle
              width={"0.8vw"}
              height={"0.8vw"}
              backgroundColor="var(--color-dark-pink)"
            >
              <span className="achieved-text" style={{marginLeft:"1.25vw"}}>{t("Achieved")}</span>
            </CardCircle>
          </div>
        </div>
      </div>
    </>
  );
};
export default translate(BudgetDashboard);
