import React from "react";
import ModalPopup from "../atoms/ModalPopup";
import FileUpload from "./FileUpload";
import { LabelWithInputField } from "./LabelWithInputField";
import SelectWithSearch from "../atoms/SelectWithSearch";
import Button from "../atoms/Button";
import { t } from "components/CentralDataMangement/translation/Translation";


interface DocumentModalProps {
  isModalOpen: boolean;
  closeModal: () => void;
  documentData: any;
  documentErrors: any;
  onFileUpload: (response: any) => void;
  onFileDelete: (field: string) => void;
  onFileUploadError: (errorResponse: any) => void;
  onFieldChange: (name: string, value: any) => void;
  onSave: () => void;
  categoryOptions: { value: number; label: string }[];
  buttonTitle?: string;
  showDeleteIcon?: boolean;
  isDisabled?:boolean;
}

const DocumentModal: React.FC<DocumentModalProps> = ({
  isModalOpen,
  closeModal,
  documentData,
  documentErrors,
  onFileUpload,
  onFileDelete,
  onFileUploadError,
  onFieldChange,
  onSave,
  categoryOptions,
  buttonTitle = 'Save',
  showDeleteIcon = true,
  isDisabled= false,
}) => {
  return (
    <ModalPopup
      size="md"
      show={isModalOpen}
      onHide={closeModal}
      title="Document"
      headerClassName={`col-8 mx-auto`}
      body={
        <>
          <div className="row justify-content-center">
            {/* File Upload */}
            <div className="col-sm-8 col-lg-8 col-md-12 upload-certificate">
              <FileUpload
                id="documentId"
                name="documentId"
                label={t("File upload")}
                isMandatory={true}
                edit={false}
                fileId={documentData.documentId ?? null}
                fileName={documentData.fileName ?? ""}
                filePath={documentData.filePath ?? ""}
                style={{ width: "100%" }}
                multiple={false}
                uploadPath={"company-cooperationAgreement"}
                formats=".pdf"
                returnFileUploadErrors={onFileUploadError}
                fileUploadResponse={onFileUpload}
                deleteResponse={onFileDelete}
                maxFileSize={10 * 1024 * 1024}
                microserviceURL={`${process.env.REACT_APP_COMPANY_SERVICE}`}
                showDeleteIcon={showDeleteIcon}
              />
              <div className="text-danger height-20" style={{ marginBottom: "0.5vw" }}>
                <span className="text-danger">{documentErrors.documentId}</span>
              </div>
            </div>
            {/* Document Name */}
            <div className="col-sm-8 col-lg-8 col-md-8 d-flex align-items-center">
              <LabelWithInputField
                isMandatory={true}
                name="documentName"
                handleChange={(event) => onFieldChange("documentName", event.target.value)}
                value={documentData.documentName}
                label={t("File name")}
                placeholder="File name"
                type="text"
                error={documentErrors.documentName}
                isDisabled={isDisabled}
              />
              <span className="ms-2 fw-bold">
                {documentData.extension !== "" ? `.${documentData.extension}` : ""}
              </span>
            </div>
            {/* Document Category */}
            <div className="col-sm-8 col-lg-8 col-md-12 upload-certificate">
              <SelectWithSearch
                title="Category"
                isMandatory={true}
                search={true}
                options={categoryOptions}
                onChange={(e) => onFieldChange("documentCategory", e)}
                isMulti={false}
                isDisabled={isDisabled}
                name="documentCategory"
                value={documentData.documentCategory}
                error={documentErrors.documentCategory}
                isTranslate={true}
              />
            </div>
          </div>
          <div className="col-11 mx-auto">
            <Button handleClick={onSave} className="mx-0 shadow-none form-button px-2 float-end">
              {t(buttonTitle)}
            </Button>
          </div>
        </>
      }
      className="modal-lg documentsCertificates"
    />
  );
};

export default DocumentModal;
