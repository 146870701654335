import React from "react";

const PdfIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="-6 -5 34 39"
      >
        <g id="Pdf" transform="translate(-6 -3)">
          <path
            id="Path_32757"
            data-name="Path 32757"
            d="M12.4,22.02a2.246,2.246,0,0,0-.558.054v1.767a2.041,2.041,0,0,0,.453.035c.718,0,1.161-.363,1.161-.977,0-.549-.381-.879-1.056-.879Zm5.231.018a2.758,2.758,0,0,0-.61.054v3.915a2.447,2.447,0,0,0,.47.027,1.854,1.854,0,0,0,2.023-2.094,1.716,1.716,0,0,0-1.882-1.9Z"
            fill="currentColor"
          />
          <path
            id="Path_32758"
            data-name="Path 32758"
            d="M21,3H9A3,3,0,0,0,6,6V30a3,3,0,0,0,3,3H27a3,3,0,0,0,3-3V12ZM14.247,24.285a2.781,2.781,0,0,1-1.944.63,3.345,3.345,0,0,1-.462-.027v2.139H10.5v-5.9A11.337,11.337,0,0,1,12.328,21a2.872,2.872,0,0,1,1.83.479,1.755,1.755,0,0,1,.639,1.385,1.928,1.928,0,0,1-.55,1.422Zm5.71,2.032a4.3,4.3,0,0,1-2.76.772A11.55,11.55,0,0,1,15.661,27V21.124A11.921,11.921,0,0,1,17.49,21a3.849,3.849,0,0,1,2.45.639A2.648,2.648,0,0,1,20.953,23.9a3.033,3.033,0,0,1-1,2.422ZM25.5,22.155H23.2v1.367H25.35v1.1H23.2v2.406H21.843V21.045H25.5ZM21,13.5H19.5V6L27,13.5Z"
            fill="currentColor"
          />
        </g>
      </svg>
    </>
  );
};

export default PdfIcon;
