import Button from "components/common/atoms/Button";
import CustomNotify from "components/common/atoms/CustomNotify";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import Title from "components/common/atoms/Title";
import { ApiCall } from "components/common/services/ApiServices";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import {
  validateForm,
  validateMultiSelectField,
} from "components/common/services/ValidationService";
import { mapToSelect } from "components/common/utlis/MapToSelect";
import {
  OptionProps,
  ValidationRules,
} from "components/common/utlis/TypeAnnotations";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { GET_OFFICE_DETAILS, STORE_OFFICE_DETAILS } from "routes/ApiEndpoints";
import { t, translate } from "../translation/Translation";
import "./offices.css";

interface OfficeProps {
  offices: object[];
  cities: object[];
}

const EditOffices: React.FC = () => {
  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();
  const [formData, setFormData] = useState<OfficeProps>({
    offices: [],
    cities: [],
  });

  const [dropdowns, SetDropdowns] = useState({
    cityDropdown: [] as OptionProps[],
  });

  const [errors, setErrors] = useState<{ [key: string]: string }>({
    cities: "",
  });

  const validation = (
    name: string,
    value: string | boolean | null | number | undefined | object[],
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      cities: [validateMultiSelectField],
    };

    const validationErrors = validateForm(
      { ...formData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );
    if (isSingleFieldValidation) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    const fetchOfficeDetails = async () => {
      const url = `${GET_OFFICE_DETAILS}/${id}`;
      const response = await ApiCall.getService(
        url,
        "GET",
        "central-data-management"
      );
      if (response.status === 200) {
        const { offices, cities, cityDropdown } = response.data;
        SetDropdowns((prevData) => ({
          ...prevData,
          cityDropdown: mapToSelect(cityDropdown),
        }));

        setFormData((prevData) => ({
          ...prevData,
          offices: offices,
          cities: cities,
        }));
      }
    };
    fetchOfficeDetails();
  }, []);

  const handleSelectChange = (selectedOption: any, name: string) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: selectedOption !== null ? selectedOption : "",
    }));
    // validation(name, selectedOption, true);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    // const { name, value } = event.target as HTMLInputElement;
    // if (validation(name, value)) {
    const url = `${STORE_OFFICE_DETAILS}/${id}`;
    const response = await ApiCall.service(
      url,
      "POST",
      formData,
      false,
      "central-data-management"
    );
    if (response.status === 200) {
      CustomNotify({ type: "success", message: t(response.msg) });
      navigate("/manage/office-locations");
    }
    // }
  };

  const permissionType = id ? "update" : "create";
  const permissionObject: any = {
    permission: "Office cities",
  };
  permissionObject[permissionType] = true;
  return (
    <AccessControl
      requiredPermissions={[permissionObject]}
      renderNoAccess={true}
    >
      <Title title={t("Edit office cities")} />
      <form action="" onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12">
            <div className="form-border">
              <div className="row ">
                <div className="col-md-6">
                  <SelectWithSearch
                    title={t("Offices")}
                    name="offices"
                    isMandatory={true}
                    search={true}
                    options={[]}
                    onChange={(e) => handleSelectChange(e, "offices")}
                    isMulti={true}
                    value={formData.offices}
                    isDisabled={true}
                    isTranslate={true}
                  />
                </div>
                <div className="col-md-6">
                  <SelectWithSearch
                    title={t("Cities")}
                    name="cities"
                    isMandatory={false}
                    search={true}
                    options={dropdowns.cityDropdown}
                    onChange={(e) => handleSelectChange(e, "cities")}
                    isMulti={true}
                    value={formData.cities}
                    error={errors.cities}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row backPadding">
          <div className="col-md-4 align-self-center">
            <Link
              to={`/manage/office-locations`}
              className=" back-btn text-decoration-underline"
            >
              {t("Back")}
            </Link>
          </div>
          <div className="col-md-8 text-end">
            <Button
              title={t("Save")}
              type="submit"
              className="form-button shadow-none"
            />
          </div>
        </div>
      </form>
    </AccessControl>
  );
};

export default translate(EditOffices);
