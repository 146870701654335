import React, { ChangeEvent, MouseEvent, MouseEventHandler, ReactNode } from "react";

interface CheckBoxFieldProps {
  name: string;
  label?: string;
  customStyle?: React.CSSProperties;
  className?: string;
  id?: string;
  isChecked?: boolean;
  onChangeHandler?: (event: ChangeEvent<HTMLInputElement>) => void;
  isClicked?: MouseEventHandler<HTMLInputElement>;
  disable?: boolean;
  value?: string | number;
  lineHeight?: string | number;
  children?: ReactNode;
}

let styles_id: React.CSSProperties = {};

const CheckBoxField: React.FC<CheckBoxFieldProps> = ({
  name,
  label,
  customStyle = {},
  className,
  id = "",
  value,
  isChecked,
  onChangeHandler,
  isClicked,
  disable = false,
  lineHeight,
  children,
}) => {
  const handleClick = (event: MouseEvent<HTMLLabelElement>) => {
    // Only trigger the checkbox when clicking on the input element
    if ((event.target as HTMLInputElement).tagName !== "INPUT") {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  return (
    <div className="form-check d-inline-block">
      <input
        name={name}
        type="checkbox"
        checked={isChecked || false}
        id={id}
        value={value}
        disabled={disable}
        onChange={onChangeHandler}
        onClick={isClicked}
        className="form-check-input shadow-none rounded-0 cursor-pointer"
      />

      <label
        style={{ ...styles_id, ...customStyle, lineHeight }}
        className={`${className} color-dark-pink ${disable ? "" : "cursor-pointer"} `}
        htmlFor={id}
      >
        {label}
      </label>
      {children}
    </div>
  );
};

export default CheckBoxField;
