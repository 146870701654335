import React, { useEffect, useState } from "react";
import PaginationButton from "./PaginationButton";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { translate } from "components/CentralDataMangement/translation/Translation";

interface PaginationProps {
  currentPage?: number;
  totalPages?: number;
  totalRecords?: number;
  recordsPerPage?: number;
  onPageChange?: (pageNumber: number) => void;
  maxVisiblePages?: number;
  itemsPerPageOptions?: number[];
  onItemsPerPageChange?: (itemsPerPage: number) => void;
}

const PaginationNew: React.FC<PaginationProps> = ({
  currentPage = 1,
  totalPages = 1,
  recordsPerPage = 10,
  totalRecords = 0,
  onPageChange,
  maxVisiblePages = 3,
  itemsPerPageOptions = [10, 20, 50, 100],
  onItemsPerPageChange,
}) => {
  const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  useEffect(() => {
    // if (currentPage > totalPages) {
    //   onPageChange && onPageChange(1);
    // }
    setItemsPerPage(recordsPerPage);
  }, [itemsPerPage, currentPage, recordsPerPage]);

  // Update itemsPerPage state and call the provided function to lift state up
  const handleItemsPerPageChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(newItemsPerPage);
    if (onItemsPerPageChange) {
      onItemsPerPageChange(newItemsPerPage); // Call the prop function here
    }
  };

  const pageNumbers = Array.from(
    { length: totalPages },
    (_, index) => index + 1
  );

  const renderPageNumbers = () => {
    if (pageNumbers.length <= maxVisiblePages) {
      return pageNumbers.map((pageNumber) => (
        <PaginationButton
          title={pageNumber}
          key={pageNumber}
          handleClick={() => onPageChange && onPageChange(pageNumber)}
          className={`btn border-0 page-num ${
            currentPage === pageNumber ? "page-active" : ""
          }`}
        />
      ));
    } else {
      const firstPage = Math.max(
        1,
        currentPage - Math.floor(maxVisiblePages / 2)
      );
      const lastPage = Math.min(
        pageNumbers.length,
        firstPage + maxVisiblePages - 1
      );

      const pages = [];
      if (firstPage > 1) {
        pages.push(
          <PaginationButton
            title={1}
            key={1}
            handleClick={() => onPageChange && onPageChange(1)}
            className={`btn border-0 page-num`}
          />
        );
        if (firstPage > 2) {
          pages.push(
            <span key="ellipsis1" className="ellipsis">
              ...
            </span>
          );
        }
      }

      for (let i = firstPage; i <= lastPage; i++) {
        pages.push(
          <PaginationButton
            title={i}
            key={i}
            handleClick={() => onPageChange && onPageChange(i)}
            className={`btn border-0 page-num ${
              currentPage === i ? "page-active" : ""
            }`}
          />
        );
      }

      if (lastPage < pageNumbers.length) {
        if (lastPage < pageNumbers.length - 1) {
          pages.push(
            <span key="ellipsis2" className="ellipsis">
              ...
            </span>
          );
        }
        pages.push(
          <PaginationButton
            title={pageNumbers.length}
            key={pageNumbers.length}
            handleClick={() => onPageChange && onPageChange(pageNumbers.length)}
            className={`btn border-0 page-num`}
          />
        );
      }
      return pages;
    }
  };

  const startItem = totalRecords > 0 ? (currentPage - 1) * itemsPerPage + 1 : 0;
  const endItem = Math.min(startItem + itemsPerPage - 1, totalRecords);

  return (
    <>
      <div className="row">
        <div className="col-4 align-self-center">
          <div className="items-per-page d-flex align-items-center">
            <label htmlFor="itemsPerPage">Show </label>
            <div style={{ width: "4vw" }} className="ms-2">
              <select
                id="itemsPerPage"
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
                aria-label="Items per page"
                className="form-control form-select field-shadow"
              >
                {itemsPerPageOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <span className="ms-2">entries</span>
          </div>
        </div>
        <div className="col-4 align-self-center">
          {totalPages > 1 && (
            <div className="d-flex align-items-center justify-content-center">
              <PaginationButton
                title=""
                handleClick={() => {
                  if (currentPage > 1) {
                    onPageChange && onPageChange(currentPage - 1);
                  }
                }}
                className={`btn previous-btn ${
                  currentPage === 1 ? "disabled" : ""
                }`}
                icon={faArrowLeft}
                aria-label="Previous page"
              />
              {renderPageNumbers()}
              <PaginationButton
                title=""
                handleClick={() => {
                  if (currentPage < totalPages) {
                    onPageChange && onPageChange(currentPage + 1);
                  }
                }}
                className={`btn next-btn ${
                  currentPage === totalPages ? "disabled" : ""
                }`}
                icon={faArrowRight}
                aria-label="Next page"
              />
            </div>
          )}
        </div>
        <div className="col-4 align-self-center text-end">
          <div>
            <span className="item-range">
              <span className="fw-bold">{`${startItem}${" "}-${" "}${endItem}`}</span>
              <span className="mx-2">of</span>
              <span className="fw-bold">{`${totalRecords}`}</span>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default translate(PaginationNew);