import DashboardCard from "components/common/atoms/DashboardCard";
import Count from "components/common/atoms/Count";
import CardTitle from "components/common/atoms/CardTitle";
import CardCircle from "components/common/atoms/CardCircle";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ApiCall } from "components/common/services/ApiServices";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "Constants";
import MatchingProfileCard, {
  MatchingCandidateProfileCardType,
} from "components/Matching/MatchingCandidateProfileCard";
import VerifiedUser from "static/images/VerifiedUser";
import Staricons from "static/images/Staricons";
import Microphone from "static/images/Microphone";
import ShortlistIcon from "static/images/ShortlistIcon";
import StarterIcon from "static/images/StarterIcon";
import XmarkIcon from "static/images/XmarkIcon";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import RightSideBar from "components/common/atoms/RightSideBar";

interface PersonalInformation {
  skills: string;
  languages: string;
  internalInfo: string;
  totalExperience: string;
  currentOrganization: string;
  salaryExpectation: string;
  workExperience: string[];
}

const CandidateCorner = () => {
  const [stageCounts, setStageCounts] = useState<any[]>([]);
  const [personalInformation, setPersonalInformation] =
    useState<PersonalInformation>({
      skills: "",
      languages: "",
      internalInfo: "",
      totalExperience: "",
      currentOrganization: "",
      salaryExpectation: "",
      workExperience: [],
    });

  const [profileCardData, setProfileCardData] =
    useState<MatchingCandidateProfileCardType>({
      candidateId: null,
      firstName: "",
      lastName: "",
      functions: "",
      mobNumber: "",
      email: "",
      street: "",
      number: "",
      box: "",
      zipCode: "",
      city: "",
      picFileName: "",
      picFilePath: "",
      country: "",
      cvData: "",
      profilePicData: "",
      isScreening: true,
      isMatching: true,
      isEdit: true,
      brightId: null,
    });

  const { candidateId } = useParams<{ candidateId: string }>();

  const fetchCounts = async () => {
    const data = {
      candidateId: candidateId,
    };
    const response = await ApiCall.service(
      "getCountsForCandidate",
      "POST",
      data,
      true,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );
    if (response.status === 200) {
      setStageCounts(response.data[0]);
      setProfileCardData((prev) => ({
        ...prev,
        ...response.data[1].candidateInfo.general[0],
        ...{ cvData: response.data[1].candidateInfo?.cvData },
        ...{ profilePicData: response.data[1].candidateInfo?.profilePicData },
        ...{ functions: response.data[1].candidateInfo?.functions },
      }));
      setPersonalInformation((prev) => ({
        ...prev,
        skills: response.data[1].candidateInfo?.softskills,
        languages: response.data[1].candidateInfo?.languages,
        internalInfo:
          response.data[1].candidateInfo?.candidateWorkExperience?.[3],
        totalExperience:
          response.data[1].candidateInfo?.candidateWorkExperience?.[0],
        currentOrganization:
          response.data[1].candidateInfo?.candidateWorkExperience?.[1],
        salaryExpectation:
          response.data[1].candidateInfo?.candidateWorkExperience?.[2],
        workExperience:
          response.data[1].candidateInfo?.candidateWorkExperience?.[4],
      }));
    }
  };

  useEffect(() => {
    fetchCounts();
  }, []);

  const getTotalCountForStage = (stageName: string): number => {
    const stage = stageCounts.find((stage) => stage.stageName === stageName);
    return stage ? stage.totalcount : 0;
  };
  const dashboardClass =
    "card border-0 card-shadow justify-content-center h-100";
  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Candidate",
          read: true,
        },
      ]}
      renderNoAccess={true}
    >
      <div className="candidateCornerPage">
        <MatchingProfileCard profileCardProps={profileCardData} />
        <div
          className="row equal-cols recruiter-action-tabs"
          style={{ marginTop: "2vw" }}
        >
          <div className="col-md-3">
            <Link to={`/manage-screening/${candidateId}`}>
              <DashboardCard className={`${dashboardClass}`}>
                <div className="row">
                  <div className="col-xxl-10 col-xl-9 col-lg-8 align-self-center col-md-6">
                    <CardTitle
                      title={t("Screening")}
                      className={`recruitment-card-title`}
                    />
                  </div>
                  <div className="col-xxl-2 col-xl-3 col-lg-4 px-0 align-self-center col-md-6">
                    <CardCircle
                      width={"3vw"}
                      height={"3vw"}
                      boxShadow="rgb(239 206 224) 0px 0.156vw 0.365vw 0.156vw"
                      className="float-end justify-content-center"
                    >
                      {getTotalCountForStage("Screening") === 1 ? (
                        <StarterIcon />
                      ) : (
                        <VerifiedUser />
                      )}
                    </CardCircle>
                  </div>
                </div>
              </DashboardCard>
            </Link>
          </div>
          <div className="col-md-3">
            <Link
              to={`/todos/overview?type=completedtodos&stage=presented&template=candidateCorner&candidateId=${candidateId}`}
              state={{ origin: "candidateCorner" }}
            >
              <DashboardCard className={`${dashboardClass}`}>
                <div className="row">
                  <div className="col-xxl-10 col-xl-9 col-lg-8 align-self-center col-md-6">
                    <Count
                      totalValue={getTotalCountForStage("Presented")}
                      className={`recruitment-card-num`}
                    />
                    <CardTitle
                      title={t("Presented")}
                      className={`recruitment-card-title`}
                    />
                  </div>
                  <div className="col-xxl-2 col-xl-3 col-lg-4 px-0 align-self-center col-md-6">
                    <CardCircle
                      width={"3vw"}
                      height={"3vw"}
                      boxShadow="rgb(239 206 224) 0px 3px 7px 3px"
                      className="float-end justify-content-center"
                    >
                      <Staricons />
                    </CardCircle>
                  </div>
                </div>
              </DashboardCard>
            </Link>
          </div>
          <div className="col-md-3">
            <Link
              to={`/todos/overview?type=completedtodos&stage=interview&template=candidateCorner&candidateId=${candidateId}`}
              state={{ origin: "candidateCorner" }}
            >
              <DashboardCard className={`${dashboardClass}`}>
                <div className="row">
                  <div className="col-xxl-10 col-xl-9 col-lg-8 align-self-center col-md-6">
                    <Count
                      totalValue={getTotalCountForStage("Interview")}
                      className={`recruitment-card-num`}
                    />
                    <CardTitle
                      title={t("Interviewed")}
                      className={`recruitment-card-title`}
                    />
                  </div>
                  <div className="col-xxl-2 col-xl-3 col-lg-4 px-0 align-self-center col-md-6">
                    <CardCircle
                      width={"3vw"}
                      height={"3vw"}
                      boxShadow="rgb(239 206 224) 0px 3px 7px 3px"
                      className="float-end justify-content-center"
                    >
                      <Microphone />
                    </CardCircle>
                  </div>
                </div>
              </DashboardCard>
            </Link>
          </div>
          <div className="col-md-3">
            <Link
              to={`/todos/overview?type=mytodos&stage=shortlist&template=candidateCorner&candidateId=${candidateId}`}
              state={{ origin: "candidateCorner" }}
            >
              <DashboardCard className={`${dashboardClass}`}>
                <div className="row">
                  <div className="col-xxl-10 col-xl-9 col-lg-8 align-self-center col-md-6">
                    <Count
                      totalValue={getTotalCountForStage("Wage")}
                      className={`recruitment-card-num`}
                    />
                    <CardTitle
                      title={t("Shortlist")}
                      className={`recruitment-card-title`}
                    />
                  </div>
                  <div className="col-xxl-2 col-xl-3 col-lg-4 px-0 align-self-center col-md-6">
                    <CardCircle
                      width={"3vw"}
                      height={"3vw"}
                      boxShadow="rgb(239 206 224) 0px 3px 7px 3px"
                      className="float-end justify-content-center"
                    >
                      <ShortlistIcon />
                    </CardCircle>
                  </div>
                </div>
              </DashboardCard>
            </Link>
          </div>
        </div>
        <div className="row marginTop1 equal-cols recruiter-action-tabs">
          <div className="col-md-6 align-self-center recruitment-card-num ">
            <div>
              {t("The candidate")}
              &nbsp;
              <span className="fraunces-italic color-dark-pink">
                {t("corner")} !
              </span>
            </div>
          </div>
          <div className="col-md-3">
            <Link
              to={`/todos/overview?type=mytodos&stage=starter&template=candidateCorner&candidateId=${candidateId}`}
              state={{ origin: "candidateCorner" }}
            >
              <DashboardCard className={`${dashboardClass}`}>
                <div className="row">
                  <div className="col-xxl-10 col-xl-9 col-lg-8 align-self-center col-md-6">
                    <CardTitle
                      title={t("Starter")}
                      subTitle={t("Yes")}
                      className={`recruitment-card-title`}
                    />
                  </div>
                  <div className="col-xxl-2 col-xl-3 col-lg-4 px-0 align-self-center col-md-6">
                    <CardCircle
                      width={"3vw"}
                      height={"3vw"}
                      boxShadow="rgb(239 206 224) 0px 3px 7px 3px"
                      className="float-end justify-content-center"
                    >
                      {/* {getTotalCountForStage("Shortlist") === 1 ? ( */}
                      <StarterIcon />
                      {/* ) : ( */}
                      {/* <VerifiedUser /> */}
                      {/* )} */}
                    </CardCircle>
                  </div>
                </div>
              </DashboardCard>
            </Link>
          </div>
          <div className="col-md-3">
            <Link
              to={`/todos/overview?type=mytodos&stage=stopper&template=candidateCorner&candidateId=${candidateId}`}
              state={{ origin: "candidateCorner" }}
            >
              <DashboardCard className={`${dashboardClass}`}>
                <div className="row">
                  <div className="col-xxl-10 col-xl-9 col-lg-8 align-self-center col-md-6">
                    <CardTitle
                      title={t("Stopper")}
                      subTitle={t("No")}
                      className={`recruitment-card-title`}
                    />
                  </div>
                  <div className="col-xxl-2 col-xl-3 col-lg-4 px-0 align-self-center col-md-6">
                    <CardCircle
                      width={"3vw"}
                      height={"3vw"}
                      boxShadow="rgb(239 206 224) 0px 3px 7px 3px"
                      className="float-end justify-content-center"
                    >
                      <XmarkIcon />
                    </CardCircle>
                  </div>
                </div>
              </DashboardCard>
            </Link>
          </div>
        </div>
        <div
          className="row equal-cols marginTop1"
          style={{ paddingBottom: "1vw" }}
        >
          <div className="col-6">
            <div className="row">
              <div className="col-12" style={{ marginBottom: "1vw" }}>
                <div
                  className="card border-0 card-shadow position-relative bg-pink text-white h-100"
                  style={{ padding: "2vw" }}
                >
                  <div className="position-relative jobDescription mb-5">
                    {t("Over")} {profileCardData.firstName}
                    {/* <span className="position-absolute starIcon">
                    <StarIcon />
                  </span> */}
                  </div>
                  <div className="text-center fw-bold tab-subtitle text-white mb-5">
                    {personalInformation.internalInfo &&
                    personalInformation.internalInfo !== ""
                      ? personalInformation.internalInfo
                      : t("No information available")}
                  </div>
                </div>
                {/* <div className="px-4 mt-4 candidateJobSteps">
              <div className="p-2">
                <div className="row">
                  <div className="col-6 marginBottomPoint5">
                    <Link to="" className="text-decoration-none">
                      <div className="link-btn py-3 px-4 rounded-3 shadow-none d-flex align-items-center justify-content-between">
                        {t("Request an interview")}
                        <FontAwesomeIcon icon={faAngleRight} className="ms-4" />
                      </div>
                    </Link>
                  </div>
                  <div className="col-6 marginBottomPoint5">
                    <Link to="" className="">
                      <div className="link-btn py-3 px-4 rounded-3 shadow-none d-flex align-items-center justify-content-between">
                        {t("Send message")}
                        <FontAwesomeIcon icon={faAngleRight} className="ms-4" />
                      </div>
                    </Link>
                  </div>
                  <div className="col-6 marginBottomPoint5">
                    <Link to="" className="">
                      <div className="link-btn py-3 px-4 rounded-3 shadow-none d-flex align-items-center justify-content-between">
                        {t("Reject")}
                        <FontAwesomeIcon icon={faAngleRight} className="ms-4" />
                      </div>
                    </Link>
                  </div>
                  <div className="col-6 marginBottomPoint5">
                    <Link to="" className="">
                      <div className="link-btn py-3 px-4 rounded-3 shadow-none d-flex align-items-center justify-content-between">
                        {t("Save")}
                        <FontAwesomeIcon icon={faAngleRight} className="ms-4" />
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div> */}
              </div>
              <div className="col-12" style={{ marginBottom: "1vw" }}>
                <div
                  className="card border-0 card-shadow position-relative dashboard-myBudget-wrapper h-100"
                  style={{ padding: "2vw" }}
                >
                  <div className="row">
                    <div className="col-md-12 marginBottomPoint5">
                      <span className="fw-bold">
                        {t("Personal Information")}
                      </span>
                    </div>
                    <div className="col-md-12 position-relative">
                      <div className="marginBottomPoint5">
                        {t(`Current organisation: `)}
                        <span className="personal-info">
                          {personalInformation.currentOrganization &&
                          personalInformation.currentOrganization !== ""
                            ? personalInformation.currentOrganization
                            : t("N/A")}
                        </span>
                      </div>
                      {/* <div className="marginBottomPoint5">
                      {t(`Current salary: `)}
                      <span className="personal-info">
                        {t("NA")}
                      </span>
                    </div> */}
                      <div className="marginBottomPoint5">
                        {t(`Salary expectation: `)}
                        <span className="personal-info">
                          {personalInformation.salaryExpectation &&
                          personalInformation.salaryExpectation !== ""
                            ? personalInformation.salaryExpectation
                            : t("N/A")}
                        </span>
                      </div>
                      <div className="marginBottomPoint5">
                        {t(`Total experience: `)}
                        <span className="personal-info">
                          {personalInformation.totalExperience &&
                          personalInformation.totalExperience !== ""
                            ? personalInformation.totalExperience
                            : t("N/A")}
                        </span>
                      </div>
                      {/* <div className="marginBottomPoint5">
                      {t(`Relevant experience: `)}
                      <span className="personal-info">
                        {t("NA")}
                      </span>
                    </div> */}
                      <div className="marginBottomPoint5">
                        {t(`Language skills: `)}
                        <span className="personal-info">
                          {personalInformation.languages ?? t("N/A")}
                        </span>
                      </div>
                      <div>
                        {t(`Skills: `)}
                        <span className="personal-info">
                          {personalInformation.skills ?? t("N/A")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div
                  className="card border-0 card-shadow justify-content-center h-100 dashboard-myBudget-wrapper"
                  style={{ padding: "2vw" }}
                >
                  <span className="fw-bold"> {t("Work experience")}</span>
                  {personalInformation?.workExperience?.length > 0 ? (
                    personalInformation?.workExperience?.map(
                      (experience, index) => (
                        <ul className="marginTop1 mb-0">
                          <li key={index} className="marginBottomPoint5">
                            <div className="fw-bold marginBottomPoint5">
                              {t("Experience")} {index + 1}
                            </div>
                            <p
                              dangerouslySetInnerHTML={{ __html: experience }}
                            />
                          </li>
                        </ul>
                      )
                    )
                  ) : (
                    <div className="text-center marginTop1 marginBottom1 color-dark-pink">
                      {t("No work experience")}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <RightSideBar
              title={"Follow-ups"}
              entityType={"candidate"}
              entityId={candidateId}
              isInBright={profileCardData?.brightId !== null ? true : false}
              getWidth={() => {}}
              recordsLimit={10}
              className="dashboard-myBudget-wrapper card-shadow candidateCornerRighSideBar scrollBarDesign"
              titleClassName="pb-4 mt-0"
            />
          </div>
        </div>
        {/* <div className="border rounded-3 p-3">
          <div className="p-2">
            <div className="posiiton-relative">
              <div className="row">
                <div className="col-md-12 mb-5">
                  <div className="d-flex align-items-center">
                    <span>
                      <img src='../static/images/arrow-target.png' alt='Arrow dot' width={22} />
                    </span>
                    <span className='ms-3 recruitment-card-num'>
                      Inside the talent treasure vault
                    </span>
                  </div>
                </div>
                <div className="col-md-12 candidateCornerTabs">
                  <TabView scrollable>
                    {scrollableTabs.map((tab) => {
                      return (
                        <TabPanel key={tab.title} header={tab.title}>
                          <ul className='list-unstyled mt-4'>
                            <li>
                              <div className='border rounded-3 p-3 candidateCornerTable'>
                                <table className='table table-hover mb-0'>
                                  <tr>
                                    <td className='p-0'>
                                      <GripVerticalIcon />
                                    </td>
                                    <td>
                                      Title 1
                                    </td>
                                    <td>
                                      <Link to={''} className='link-btn p-3 rounded-3 float-end'>Add experience <span className='ms-4'> + </span></Link>
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            </li>
                          </ul>
                        </TabPanel>
                      );
                    })}
                  </TabView>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </AccessControl>
  );
};

export default translate(CandidateCorner);
