import React, { useState } from 'react';
import ModalPopup from 'components/common/atoms/ModalPopup';
import RadioField from 'components/common/atoms/RadioField'; // Assuming RadioField is imported from this path
import { t } from '../translation/Translation';
import { SET_MICROPHONE } from 'routes/ApiEndpoints';
import { CANCEL, CENTRAL_DATA_MANAGEMENT_MICROSERVICE, CLOSE, DEVICE, ERROR } from 'Constants';
import { ApiCall } from 'components/common/services/ApiServices';
import CustomNotify from 'components/common/atoms/CustomNotify';
import LabelField from 'components/common/atoms/LabelField';

interface Device {
    Id: string;
    Name: string;
    Type: string;
}

interface ResponseData {
    selectedMicrophone: Device;
    selectedSpeaker: Device;
    microphones: Device[];
    speakers: Device[];
}

interface DeviceSelectionModalProps {
    response: ResponseData;
    closeModal: (type?: string, closeType?: string) => void;
    showModal: boolean;
}

const DeviceSelectionModal: React.FC<DeviceSelectionModalProps> = ({ response, closeModal, showModal }) => {

    const [devices, setDevices] = useState({
        microphone: response.selectedMicrophone.Name,
        speaker: response.selectedSpeaker.Name
    });

    const handleDeviceChange = (event: React.ChangeEvent<HTMLInputElement>, deviceType: 'microphone' | 'speaker') => {
        setDevices((prev: any) => ({
            ...prev,
            [deviceType]: event.target.value
        }));
    };

    const handleSubmit = async () => {
        const data = {
            microphone: devices.microphone,
            speaker: devices.speaker
        }
        // Sending setmicrophone action to salesnote 
        const response = await ApiCall.service(
            SET_MICROPHONE,
            "POST",
            data,
            true,
            CENTRAL_DATA_MANAGEMENT_MICROSERVICE
        );
        if (response.status === 200) {
            CustomNotify({ type: "success", message: t("Sound settings saved successfully") });
        }
        else if (response.status === 503) {
            CustomNotify({ type: ERROR, message: t(response.error) });
        }
        else {
            CustomNotify({ type: ERROR, message: t("Recording is temporarily unavailable. Please contact the administrator for assistance.") });
        }
        // Closing the current modal and opening the meeting types modal
        closeModal(DEVICE);
    };

    return (
        <ModalPopup
            show={showModal}
            showCloseButton={false}
            title={t("Select a microphone and speaker")}
            body={
                <>
                    <div className="col-sm-12 col-md-12">
                        <LabelField title={t("Microphones")} />
                        {response.microphones.map((microphone, index) => (
                            <div className="col-sm-12 col-md-12 mt-1" key={index}>
                                <RadioField
                                    key={microphone.Id}
                                    name="microphone"
                                    value={microphone.Name}
                                    ischecked={devices.microphone === microphone.Name}
                                    handleChange={(e) => handleDeviceChange(e, 'microphone')}
                                    label={microphone.Name}
                                />
                            </div>
                        ))}
                    </div>
                    <div className="col-sm-12 col-md-12 mt-2">
                        <LabelField title={t("Speakers")} />
                        {response.speakers.map((speaker, index) => (
                            <div className="col-sm-12 col-md-12 mt-1" key={index}>
                                <RadioField
                                    key={speaker.Id}
                                    name="speaker"
                                    value={speaker.Name}
                                    ischecked={devices.speaker === speaker.Name}
                                    handleChange={(e) => handleDeviceChange(e, 'speaker')}
                                    label={speaker.Name}
                                />
                            </div>
                        ))}
                    </div>
                </>
            }
            onCloseButtonClick={() => closeModal(CLOSE, CANCEL)}
            onConfirmButtonClick={handleSubmit}
            closeTitle={t("Cancel")}
            confirmTitle={t("Save")}
        />
    );
};

export default DeviceSelectionModal;
