export const LOGIN = "/login";
export const REGISTER = "/register";
export const RESET_PASSWORD = "/reset-password";
export const FORGOT_PASSWORD = "/forgot-password";
export const TERMS_AND_CONDTIONS = "/terms-conditions";

export const PERMISSION_CREATE = "/permission/create";
export const PERMISSION_EDIT = "/permission/edit/:id";
export const PERMISSION_UPDATE = "/permission/update/:id";
export const PERMISSION_DELETE = "/permission/delete/:id";
export const MANAGE_PERMISSIONS = "/manage-permissions";

export const MANAGE_ROLES = "/manage-roles";
export const ROLE_CREATE = "/role/create";
export const ROLE_EDIT = "/role/edit/:id";

export const USER_CREATE = "/user/create";
export const USER_EDIT = "/user/edit/:id";
export const USER_UPDATE = "/user/update/:id";
export const MANAGE_USERS = "/manage-users";
export const VIEW_USERS = "/user/view/:id";

export const MANAGE_TRANSLATIONS = "/translation/manage";
export const TRANSLATION_LINK_APP_LANGUAGE = "/translation/link-app-language";

export const EMAIL_TEMPLATE_CREATE = "/email-template/create";
export const EMAIL_TEMPLATE_EDIT = "/email-template/edit/:id";
export const EMAIL_TEMPLATE_VIEW = "/email-template/view/:id";
export const MANAGE_EMAIL_TEMPLATES = "/manage-email-templates";
export const MANAGE_EMAIL_LOGS = "/manage-email-logs";

export const COMPANY = "/company/:companyId?";
export const MANAGE_COMPANIES = "/manage-companies";
export const COMPANY_CONTACTS = "/company-contacts";
export const LOCATION = "/location/:id?";
export const MANAGE_LOCATIONS = "/manage-locations";

export const CREATE_CANDIDATE = "/candidate/create/:candidateId?";
export const EDIT_CANDIDATE = "/candidate/edit/:candidateId?";
export const VIEW_CANDIDATE = "/candidate/view/:candidateId";
export const MANAGE_CANDIDATES = "/manage-candidates";

export const CHECKLIST_EDIT = "/checklist/edit/:id?";
export const CHECKLIST_VIEW = "/checklist/view/:id?";
export const MANAGE_CHECKLIST = "/manage-checklist";

export const CONFIG_AND_SETTINGS = "/config/settings";
export const MANAGE_FUNCTION_PROFILE = "/manage/function-profile";
export const FUNCTION_ADD = "/function/add";
export const FUNCTION_EDIT = "/function/edit/:id";
export const FUNCTION_VIEW = "/function/view/:id";

export const EDIT_LOW_COEFFICIENTS = "/edit/default/low-coefficients/:id?";
export const ADD_LOW_COEFFICIENTS = "/add/default/low-coefficients";
export const MANAGE_LOW_COEFFICIENTS = "/manage/default/low-coefficients";

export const ADD_CITY_PROFILE = "/add/city-profile";
export const EDIT_CITY_PROFILE = "/edit/city-profile/:id";
export const VIEW_CITYPROFILE = "/view/city-profile/:id";
export const MANAGE_CITY_PROFILE = "/manage-city-profile";

export const WORK_TYPE_EXTRA = "/work-type/extra";
export const MANAGE_EXTRAS = "/manage-extras";
export const MANAGE_WAGE_ELEMENTS = "/manage-wage-elements";
export const INFLATION_COEFFICIENTS = "/inflation/coefficients";

export const MANAGE_VACANCIES = "/manage/vacancies/:status?";

export const CREATE_COOPERATION_AGREEMENT = "/cooperation-agreement/create/:companyId/:agreementId?";
export const VIEW_COOPERATION_AGREEMENT = "/cooperation-agreement/view/:companyId/:agreementId?";
export const MANAGE_COOPERATION_AGREEMENT = "/manage/cooperation-agreement";
export const ADD_INFLATION = "/add/inflation";
export const EDIT_INFLATION = "/edit/:id/inflation";
export const VIEW_INFLATION = "/view/:id/inflation";
export const MANAGE_INFLATIONS = "/manage/inflations";
export const ADD_SIGNATURE = "/add/signature";

export const ADD_QUESTION = "/add/question";
export const EDIT_QUESTION = "/edit/question/:id";
export const CLONE_QUESTION = "/clone/question/:id";
export const VIEW_QUESTION = "/view/question/:id";
export const MANAGE_QUESIONS = "/manage-questions/:type?";
export const SCREENING = "/screening/:candidateId";
export const SCREENING_ASSESSMENT = "/screening-assessment/:screeningId/:candidateId";
export const MANAGE_SCREENING = "/manage-screening/:candidateId";

export const DASHBOARD = "/dashboard";
export const ADMIN_CONTENT = "/admin/content";
export const PROFILE_PAGE = "/profile/page";

export const ADD_COEFFICIENT = "/add/coefficient";
export const EDIT_COEFFICIENT = "/edit/coefficient/:id";
export const MANAGE_COEFFICIENT = "/manage/coefficient";
export const EDIT_COEFF_DEVIATION = "/edit/coeff-deviation/:id";
export const MANAGE_COEFF_DEVIATION = "/manage/coeff-deviation";
export const ADD_EMP_TYPE = "/add/employee-type";
export const EDIT_EMP_TYPE = "/edit/employee-type/:id";
export const MANAGE_EMP_TYPE = "/manage/employee-types";

export const MANAGE_OFFICE_LOCATIONS = "/manage/office-locations";
export const EDIT_OFFICE_LOCATIONS = "/edit/office-locations/:id";
export const ADD_PC_COEFF = "/add/pc/coefficient";
export const EDIT_PC_COEFF = "/edit/pc/coefficient/:id";
export const MANAGE_PC_COEFF = "/manage/pc-coefficient";

export const MANAGE_PC = "/manage-pc";
export const EDIT_PC = "/editpc/:id";
export const VIEW_PC = "/viewpc/:id";
export const EDIT_SALARY_BENEFIT = "/edit/salary-benefit/:id";
export const VIEW_SALARY_BENEFIT = "/view/salary-benefit/:id";
export const CANDIDATE_OVERVIEW = "/candidate/todo/overview/:candidateId";
export const MATCHING_LIST = "/matching-list/:type/:id";
export const TODOS_OVERVIEW = "/todos/overview";
export const CONTRACT_INFO_PAGE = "/todos/contract-info-page/:candidateId?/:todoExtensionId?/:todoId?/:presentationId?/:functionName?/:companyId?/:stageId?";
export const CONTRACT_PAGE = "/todos/contract/:candidateId?/:todoExtensionId?/:todoId?/:presentationId?/:functionName?/:companyId?/:stageId?";
export const PRESENTATON_PAGE = "/todos/presentation/:type?/:prestype?/:vacancyId?/:candidateId?/:functionName?/:companyId?/:presentationId?";
export const COMPANY_PRE_INTERVIEW_PAGE = '/todos/company-pre-interview/:candidateId?/:companyId?/:todoExtensionId?/:todoId?/:functionName?/:presentationId?/:vacancyId?';
export const CANDIDATE_PRE_INTERVIEW_PAGE = '/todos/candidate-pre-interview/:candidateId?/:companyId?/:todoExtensionId?/:todoId?/:functionName?/:presentationId?';
export const PRE_INTERVIEW_PAGE = '/todos/pre-interview/:candidateId?/:companyId?/:todoExtensionId?/:todoId?/:functionName?/:vacancyId?/:presentationId?';
export const INTERVIEW_FEEDBACK_PAGE = 'todos/interview-feedback/:candidateId?/:companyId?/:todoExtensionId?/:todoId?/:functionName?/:presentationId?';
export const WAGE_DISCUSSION_PAGE = '/todos/wage-discussion/:candidateId?/:companyId?/:todoExtensionId?/:todoId?/:vacancyId?/:functionName?/:presentationId?';
export const EDIT_INTERVIEW_DETAILS = "/edit/interview/details/:candidateId?/:companyId?/:todoExtensionId?/:todoId?/:functionName?/:vacancyId?/:presentationId?";
export const CANDIDATE_FEEDBACK = "/candidate/feedback/:type?/:todoId?/:todoExtensionId/:stageId?/:candidateId?/:presentationId?";

export const COOP_AGREEMENT_PREVIEW = "/cooperation-agreement/preview/:companyId?/:agreementId?";
export const COOP_PREVIEW_BACKEND = "api/agreement/preview";

export const VACANCY = "/vacancy/:id?";
export const CLONE_VACANCY = "/clone/vacancy/:id?";
export const CANDIDATE_CORNER = "/candidate/corner/:candidateId";

export const MANAGE_BUSINESS_UNIT = "/unit/manage";
export const CREATE_BUSINESS_UNIT = "/unit/create";
export const EDIT_BUSINESS_UNIT = "/unit/edit/:id";
export const MANAGE_EXPERIENCE_LEVEL = "/experience-level/manage";
export const CREATE_EXPERIENCE_LEVEL = "/experience-level/create";
export const EDIT_EXPERIENCE_LEVEL = "/experience-level/edit/:id";
export const ADD_DEFAULT_COEFFS = "/coefficients/add";
export const EDIT_DEFAULT_COEFFS = "/coefficients/edit/:id";
export const MANAGE_DEFAULT_COEFFS = "/manage/default-coefficients";

export const ADD_DOMAIN = "/add-domain";
export const ADD_FUNCTION = "/add-function";
export const MANAGE_DOMAIN = "/manage-domain";
export const MANAGE_FUNCTION = "/manage-function";
export const EDIT_DOMAIN = "/edit/domain/:id";
export const EDIT_FUNCTION = "/edit/function/:id";

export const BUDGET_AND_TARGETS = "/budget-targets";
export const MANAGE_BUDGET_REGIONS = "/budget/manage-regions";
export const BUDGET_REGION = "/budget/region";
export const LINK_OFFICE_REGION = "/budget/link-region";
export const EDIT_OFFICE_REGION = "/budget/link-region/:id";
export const BUDGET = "/budget/fetch";
export const BUDGET_AND_TARGET = "/targets";
export const TARGET_OVERVIEW = "/target-overview";
export const DAILY_TURNOVER = "/daily-turnover";
export const CANDIDATE_CENTRAL = "/candidate-central";
export const DAILY_TURNOVER_OVERVIEW = "/turnover-overview";

export const MANAGE_JOB_APPLICATIONS = '/manage/job-applications';
export const VIEW_JOB_APPLICATION = '/view/job-application/:applicationId?';

export const MANUAL_MATCHING = "/manual-matching";

//task determiner
export const ADD_TASK_TYPE = "/add/task-type";
export const EDIT_TASK_TYPE = "/edit/task-type";
export const ADD_TASK_SUB_TYPE = "/add/task-sub-type";
export const EDIT_TASK_SUB_TYPE = "/edit/task-sub-type";
export const ADD_FOLLOW_UP = "/add/follow-up/:type?/:id?/:officeId?";
export const MANAGE_FOLLOW_UPS = "/manage/follow-up/:type?/:id?/:officeId?";
export const EDIT_FOLLOW_UP = "/edit/follow-up/:id";
export const VIEW_FOLLOW_UP = "/view/follow-up/:id";

export const COMPANY_ROLE = "/company-role/:id?";
export const MANAGE_COMPANY_ROLES = "/manage/company-roles";
export const USER_LOGIN_DETAILS = "/login/details";
export const VIEW_LOGIN_DETAILS = "/login/details/:id";
export const VACANCY_OVERVIEW = "/vacancy/overview/:id";
export const PAGE_CONTENTS = "/page-contents/:id?";
export const MANAGE_PAGE_CONTENTS = "/manage/page-contents";

//adminfiche
export const CREATE_ADMIN_FICHE = "/admin-fiche/create/:agreementId/:adminFicheId?";
export const ADMIN_FICHE_PREVIEW_BACKEND = "api/admin-fiche/preview";
export const ADMIN_FICHE_PREVIEW = "/admin-fiche/preview/:agreementId/:adminFicheId";
export const MANAGE_ADMIN_FICHE = "/manage/admin-fiche";


export const OFFICE = "/office/:id?";
export const MANAGE_OFFICES = "/manage/offices";

export const CREATE_SHIFT = '/shift/create';
export const EDIT_SHIFT = '/shift/edit/:id';
export const MANAGE_SHIFTS = '/manage/shifts';

export const CREATE_PLANNING = "/planning/create/:planningId?";
export const EDIT_PLANNING = "/planning/edit/:planningId?";
export const MANAGE_PLANNING = "/manage-planning";

//Reports
export const RECRUITER_WEEKLY_REPORTS = '/recruiter/weekly/report';
export const RECRUITER_MONTHLY_REPORTS = '/recruiter/monthly/report';
export const OM_WEEKLY_REPORTS = '/om/weekly/report';
export const OM_MONTHLY_REPORTS = '/om/monthly/report';
export const TL_WEEKLY_REPORTS = '/reports5';
export const TL_MONTHLY_REPORTS = '/reports6';
export const MANAGE_REPORTS = '/manage-reports';

//SalesNote
export const MANAGE_SALES_NOTE_MEETINGS = '/manage/sales-note-meetings';
export const VIEW_SALES_NOTE_MEETING = '/view/meeting/:id?';

//candidate consent form
export const CANDIDATE_CONSENT_FORM = '/candidate-consent-form/:candidateId';