
import { COMPANY_MICROSERVICE } from "Constants";
import * as ENDPOINTS from "../../../../../routes/ApiEndpoints";
import { ApiCall } from "components/common/services/ApiServices";

const GetFunctions = async(Pcdata:any, location:any, workType:number|null, unitType:number|null, coeffType:number | null | undefined) => {
    try {
        const pcIds = Pcdata.map((item:any) => item.PC.value);
        const locationIds = location.map((item:any) => item.value);
        const data = {
          pcIds:pcIds,
          location:locationIds,
          workTypeId:workType,
          unitType: unitType,
          coeffType:coeffType
        }
        const response = await ApiCall.service(
          ENDPOINTS.GET_PC_FUNCTIONS,
          'POST',
          data,
          false,
          COMPANY_MICROSERVICE
        );
    
        if (response.status === 200) {               
          return response.data;
        } else {
          return [];
        }
      } catch (error) {
        console.error('Error fetching PC functions:', error);
        return [];
      }
}

export default GetFunctions;
