import React, { ReactNode, CSSProperties } from "react";

const style = {};

interface Props {
  title?: ReactNode;
  customStyle?: CSSProperties;
  isMandatory?: boolean;
  className?: string;
  htmlfor?: string;
  subtitle?: string;
  children?: ReactNode;
}

const LabelField: React.FC<Props> = ({
  title,
  customStyle = {},
  isMandatory = false,
  className = "form-label field-label",
  htmlfor,
  subtitle,
  children,
}) => {
  return (
    <>
      <label
        htmlFor={htmlfor}
        style={{ ...style, ...customStyle }}
        className={className}
      >
        {title}
        {children}
        {subtitle && (
          <>
            <span className="subTitleWrapper">{subtitle}</span>
          </>
        )}
        {isMandatory && <span className="text-danger">*</span>}
      </label>
    </>
  );
};

export default LabelField;
