import { t } from 'components/CentralDataMangement/translation/Translation';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { TimeStates } from '../utlis/PlanningUtility';

interface ValidationErrors {
  [key: string]: string;
}

export interface Option {
  value: number
  label: string
}

interface pcProps {
  PC: Option | null,
  employeeType: any[],
}

export function validateRequired(value: string): string | null | undefined {
  if (!value || value.trim() === "") {
    return t("This field is required");
  }
  return null;
}

export function validateNumberField(value: number): number | null | undefined {
  if (!value) {
    return t("This field is required");
  }
  return null;
}

export function validateDate(value: string): string | null {
  if (value === null) {
    return t("This field is required");
  }

  return null;
}

export function validateAlphaNumeric(value: string): string | null {
  // Regular expression to check for alphanumeric characters, hyphen, and spaces
  if (!/^[a-zA-Z0-9\s\-']+$/u.test(value)) {
    return t("This field is invalid");
  }

  return null;
}

export function validateFileId(value: number): string | null {
  if (value === null) {
    return t("This field is required");
  }

  return null;
}

export function validatePhoneNumber(value: string): string | null {
  if (value === "" || typeof value !== "string") {
    return t("This field is required");
  }

  if (!isValidPhoneNumber(value)) {
    return t("This field is invalid.");
  }

  return null;
}

export const validateSelectField = (selectedObject: { value: any }) => {
  if (!selectedObject || selectedObject.value === null || selectedObject.value === "") {
    return t("This field is required");
  }
  return null;
};

export const validateMultiSelectField = (value: object[]) => {
  if (!Array.isArray(value) || value.every(item => item === undefined)) {
    return t("This field is required");
  }
  return null;
};

export const validatePCEmployeeType = (value: pcProps[]) => {
  if (value.length > 0 && value.some((item) => item.employeeType.length === 0)) {
    return "Please select the employee types for PC";
  }
  return null;
};



export const validateFunctionField = (value: object[]) => {
  if (!Array.isArray(value) || value.every(item => item === undefined)) {
    return t("Please select atleast one function");
  }
  return null;
};

export const scrollToTop = (top = 0) => {
  return window.scrollTo({
    top: top,
    behavior: 'smooth',
  });
}

export const scrollToBottom = () => {
  return window.scrollTo({
    top: document.body.scrollHeight,
    behavior: 'smooth',
  });
}

export function validateTextFiled(value: string): string | null {
  if (!/^[A-Za-z]+$/.test(value)) {
    return t("Only alphabets are allowed");
  }

  return null;
}

export function validateTextFiledSpace(value: string): string | null {
  if (!/^[A-Za-z\s]+$/.test(value)) {
    return t("Only alphabets and spaces are allowed");
  }

  return null;
}

export function validateTextFieldAlpha(value: string): string | null {
  // Regular expression to check for alphanumeric characters, accented characters, spaces, apostrophes, and hyphens
  if (!/^[a-zA-Z0-9\u00C0-\u024F\u1E00-\u1EFF\s'-]+$/u.test(value)) {
    return t("Only alphanumeric characters, accented characters, spaces, apostrophes, and hyphens are allowed");
  }

  return null;
}


export function validateUniqueKey(value: string): string | null {
  if (!/^[a-z_]+$/.test(value)) {
    return t("Unique Key can only contain lowercase letters and underscores");
  }

  return null;
}

export function validateCheckbox(value: boolean): string | null {
  if (value !== true) {
    return t("This field is required");
  }

  return null;
}

export const validateFileRequired = (file: File | null): string | null => {
  return !file ? t("This field is required") : null;
};

export function validateMinLength(
  value: string,
  minLength: number
): string | null {
  if (value.length < minLength) {
    return `${t("Value must be at least")} ${minLength} ${t("characters long")}`;
  }

  return null;
}

export function validateMaxLength(
  value: string,
  maxLength: number
): string | null {
  if (value.length > maxLength) {
    return `${t("Value must be at most")} ${maxLength} ${t("characters long")}`;
  }

  return null;
}

export function validateCheckboxes(
  internal: boolean,
  external: boolean
): string | null {
  if (!internal && !external) {
    return t("This field is required");
  }

  return null;
}

export function validateWage(
  starterWage: string | undefined,
  experiencedWage: string | undefined,
  name: string
): string | null {

  if ((starterWage === undefined || starterWage === '') ||
    (experiencedWage === undefined || experiencedWage === '')) {
    return null;
  }

  // replace commas with dots
  let newStarterWage = parseFloat((starterWage || '').replace(/,/g, "."));
  let newExperiencedWage = parseFloat((experiencedWage || '').replace(/,/g, "."));

  if (newStarterWage > newExperiencedWage) {
    if (name === 'starterWage') {
      return t("Starter wage cannot be greater than experienced wage.");
    } else if (name === "experiencedWage") {
      return t("Experienced wage should be greater than starter wage.");
    }
  }

  return null;
}

export function validatePay(
  payFrom: string | undefined,
  payTo: string | undefined,
  name: string
): string | null {
  if ((payFrom === undefined || payFrom === '') ||
    (payTo === undefined || payTo === '')) {
    return null;
  }

  // replace commas with dots
  let newpayFrom = parseFloat((payFrom || '').replace(/,/g, "."));
  let newpayTo = parseFloat((payTo || '').replace(/,/g, "."));

  if (newpayFrom > newpayTo) {
    if (name === 'payFrom') {
      return t("Pay from cannot be greater than pay to.");
    } else if (name === "payTo") {
      return t("Pay to should be greater than pay from.");
    }
  }

  return null;
}

export function validatePassword(value: string): string | null {
  if (value.trim() === "") {
    return t("Password is required");
  }

  if (value.length < 8) {
    return t("Password must be at least 8 characters long");
  }

  if (value.length > 50) {
    return t("Password cannot exceed 50 characters");
  }

  if (!/[A-Z]/.test(value)) {
    return t("Password must contain at least one uppercase letter");
  }

  if (!/[a-z]/.test(value)) {
    return t("Password must contain at least one lowercase letter");
  }

  if (!/\d/.test(value)) {
    return t("Password must contain at least one number");
  }

  if (!/[!@#$%^&*()\-=_+[\]{}|\\;:'",.<>?]/.test(value)) {
    return t("Password must contain at least one special character");
  }

  return null;
}

export const validateMatchPassword = (
  password: string,
  confirmPassword: string
): string | null => {
  if (password !== confirmPassword) {
    return t("Passwords do not match");
  }
  return null;
};

// ValidationService.ts

export const validateTokens = (expectedTokens: string[]) => {
  return (value: string) => {
    const tokens = value.match(/\[[^\]]+\]/g) ?? [];
    const invalidTokens = tokens.filter(token => !expectedTokens.includes(token));
    if (invalidTokens.length > 0) {
      return t(`Tokens must match the list of expected tokens`);
    }
    return undefined;
  };
};

export function validateEmail(value: string): string | null {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (!value || value?.trim() === "") {
    return t("This field is required");
  }
  if (!emailRegex.test(value)) {
    return t("Invalid email address");
  }

  if (/[A-Z]/.test(value)) {
    return t("Email adress should not contain uppercase letters");
  }

  return null;
}

export function validateIBAN(value: string): string | null {
  const belgiumIBANRegex = /^BE\d{14}$/;
  if (value === null || value === "") {
    return null;
  }
  if (!belgiumIBANRegex.test(value)) {
    return t("IBAN is not valid");
  }
  return null;
}

export function validatePhoneNumberCanBeNull(value: string): string | null {

  if (value === "" || typeof value !== "string" || value === '+null' || value === '+') {
    return null;
  }

  if (!isValidPhoneNumber(value)) {
    return t("This field is invalid.");
  }

  return null;
}

export function validateTextFieldAlphaCanBeNull(value: string): string | null {
  // Regular expression to check for alphanumeric characters, accented characters, and spaces
  if (value === null || value === "") {
    return null;
  } else if (!/^[a-zA-Z0-9\u00C0-\u024F\u1E00-\u1EFF\s']+$/u.test(value)) {
    return t("Only alphanumeric characters, accented characters, and spaces are allowed");
  }

  return null;
}

export function validateEmailCanBeNull(value: string): string | null {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (value === null || value === "") {
    return null;
  }

  if (!emailRegex.test(value)) {
    return t("Invalid email address");
  }

  if (/[A-Z]/.test(value)) {
    return t("Email adress should not contain uppercase letters");
  }

  return null;
}

export function validateSSN(ssn: string): string | null {
  const ssnNumber = ssn.replace(/[-. ]/g, ""); // remove -, ., and space from ssn
  if (ssn === null || ssn === "") {
    return null;
  }
  if (ssnNumber.length !== 11) {
    return t("This field is invalid");
  }
  const ssnLastTwoDigits = parseInt(ssnNumber.substring(9, 11)); //get the last two digits
  const yyMMdd = ssnNumber.substring(0, 6); // get first 6 digits
  const XXX = ssnNumber.substring(6, 9); // get 3 digits after 6th index

  // Combine the portions into a single numeric value
  const combinedValue = parseInt(yyMMdd + XXX);
  const secondNo = 2000000000 + combinedValue;

  if (checkSum(combinedValue) === ssnLastTwoDigits) {
    return null;
  }
  if (checkSum(secondNo) === ssnLastTwoDigits) {
    return null;
  } else {
    return t(`${ssnNumber} is not a valid ssnumber (incorrect checksum)`);
  }
}

function checkSum(number: any, modulusValue = 97) {
  const remainder = number % modulusValue;
  return modulusValue - remainder;
}


export function validateRadioField(value: number | null | undefined) {
  if (value === null || value === undefined) {
    return t("This field is required")
  }
  return null;
}

export function validateMultiChoiceAnswer(value: number[]) {
  if (value.length === 0) {
    return t("Check atleast one option as answer");
  }
  return undefined;
}

export function validateMultiChoice(value: string[]) {
  if (value.length < 4) {
    return t('Add 4 options for multiple choice question');
  }
  return undefined;
}

export function validateDichtmsOptionsAnswer(value: number[]) {
  if (value.length === 0) {
    return t("Check any one option as answer");
  }
  return undefined;
}

export function validateDichtmsOptions(value: string[]) {
  if (value.length < 2) {
    return t('Add 2 options for dichotomous question');
  }
  return undefined;
}

const getValidationRules = (
  validationRules: { [key: string]: Function[] },
  fieldName?: string
) => {
  if (fieldName && validationRules.hasOwnProperty(fieldName)) {
    return {
      [fieldName]: validationRules[fieldName],
    };
  }

  return validationRules;
};

export function validateForm(
  formData: { [key: string]: string | boolean | number | Date | File | null | object[] | Option | undefined | number[] | string[] | TimeStates },
  validationRules: { [key: string]: Function[] },
  fieldName?: string
): ValidationErrors {
  const errors: ValidationErrors = {};
  const vRules: any = getValidationRules(validationRules, fieldName);
  if (fieldName && !vRules[fieldName]) {
    return errors;
  }
  for (const key in formData) {
    if (formData.hasOwnProperty(key)) {
      const value = formData[key];
      const rules = vRules[key];

      if (rules) {
        for (const rule of rules) {
          const error = rule(value);

          if (error) {
            errors[key] = error;
            break;
          }
        }
      }
    }
  }

  return errors;
}

export function validateTimeString(value: string) {
  if (!value || value === "00:00") {
    return "This field is required";
  }
}

export function validateTimeStringPattern(value: string) {
  const timeRegex = /^([01][0-9]|2[0-3]):([0-5][0-9])$/;

  if (value === null || !value) {
    return;
  }

  if (!timeRegex.test(value) || value === ":") {
    return "Please select both hour and minutes";
  }
}

export function validateLinkedinUrl(value: string | null) {
  let linkedinRegex = /^https:\/\/[a-z]{2,3}\.linkedin\.com\/.*$/;
  if (value) {
    return !linkedinRegex.test(value) ? t("Incorrect format") : null;
  }
  return null;
}

export function validateTimeRange(from: string, to: string, name: string) {
  const [fromHours, fromMinutes] = from.split(":");
  const [toHours, toMinutes] = to.split(":");

  const fromTotalMinutes = parseInt(fromHours) * 60 + parseInt(fromMinutes);
  const toTotalMinutes = parseInt(toHours) * 60 + parseInt(toMinutes);

  if (name === "to") {
    if (fromTotalMinutes >= toTotalMinutes) {
      return t('"To" time must be greater than "From" time');
    }
  } else if (name === "from") {
    if (fromTotalMinutes >= toTotalMinutes) {
      return t('"From" time must be lesser than "To" time');
    }
  }
};

export function validateVatNumber(value: string): string | null {
  if (!value || value.trim() === "") {
    return t("VAT number is required");
  }

  if (!/^\d+$/.test(value)) {
    return t("VAT number must contain only numbers");
  }

  if (value.length < 8) {
    return `${t("VAT number must be at least")} 8 ${t("characters long")}`;
  }

  if (value.length > 15) {
    return `${t("VAT number must be at most")} 15 ${t("characters long")}`;
  }

  return null;
}

export function validateMinValue(value: string | number): string | null {

  // Check if the value is null or undefined
  if (value === null || value === undefined || value === '') {
    return "This field is required.";
  }

  // Convert the value to a string if it's not already a string
  const stringValue = typeof value === 'string' ? value : value.toString();

  // Replace commas with periods for decimal conversion
  const numericValue = parseFloat(stringValue.replace(/,/g, '.'));

  // Validate if the number is greater than zero
  if (isNaN(numericValue) || numericValue <= 0) {
    return "The value must be greater than zero.";
  }

  // If everything is fine, return null (no error)
  return null;
}



export function validateAttemptField(value: string | number): string | null {
  const numValue = typeof value === "string" ? parseFloat(value) : value;

  if (isNaN(numValue)) {
    return "Invalid input: not a number";
  }

  if (numValue <= 0) {
    return "Value must be greater than 0";
  }

  if (numValue > 3) {
    return "Value must be less than 3";
  }

  return null; // Value is valid
}
