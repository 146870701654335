import React, { createContext, useContext, useReducer, ReactNode } from "react";

import { Dispatch } from "./Actions";
import CandidateReducer from "./CandidateReducer";
import { CandidateIntialState } from "../annotations/CandidateIntialState";
import { candidateFormData } from "../annotations/CandidateAnnotations";
import { useLocation } from "react-router-dom";

const FormContext = createContext<
  | {
      state: candidateFormData;
      dispatch: Dispatch;
      isViewMode: boolean;
    }
  | undefined
>(undefined);

// Create a FormProvider component to wrap your form
interface FormProviderProps {
  children: ReactNode;
}

export const useFormContext = () => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error("useFormContext must be used within a FormProvider");
  }
  return context;
};

export const FormProvider: React.FC<FormProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(CandidateReducer, CandidateIntialState);
  const location = useLocation();

  // Determine isDisabled based on the URL (or other conditions)
  const isViewMode = location.pathname.includes("view");

  return (
    <FormContext.Provider value={{ state, dispatch, isViewMode }}>
      {children}
    </FormContext.Provider>
  );
};
