export const UserStatus = ['Not yet activated', 'Active', 'Inactive'];
export const vacancyStatus = ['Open', 'Closed'];

export const reCaptchaSiteKey = "6Lf-YWAnAAAAAMQdpyIuyzbF65oE1hjGzihyVFWW";
export const ABSOLUTE_JOBS_APP = 'absolute_jobs';
export const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB in bytes
export const CENTRAL_DATA_MANAGEMENT_MICROSERVICE = 'central-data-management';
export const COMPANY_MICROSERVICE = 'company-creation';
export const CANDIDATE_MICROSERVICE = 'candidate-creation';
export const IDENTITY_MANAGER_MICROSERVICE = 'identity-manager';
export const WEBCONNECTOR = 'webconnector';

export const CandidateStatus = ['Not yet active', 'Active'];
export const formStatus = ['Drafted form', 'Completed form'];

export const CREATE = "create";
export const UPDATE = "update";
export const DELETE = "delete";
export const READ = "read";

export const COMPANY = "company";
export const VACANCY = "vacancies"
export const EMAIL_TEMPLATES = "email_templates";
export const QUESTIONS = "questions";
export const ROLES = "roles";
export const USERS = "users";

export const ADMIN = "ADMIN";
export const SUPER_ADMIN = "SUPER_ADMIN";
export const C_LEVEL = 'C_LEVEL';
export const CANDIDATE_ROLE = 'CANDIDATE';
export const EMPLOYER = 'EMPLOYER';
export const THREECX = 'THREECX';
export const RECRUITER = 'RECRUITER';
export const AREA_MANAGER = "AREA_MANAGER";
export const OFFICE_MANAGER = "OFFICE_MANAGER";

export const SUCCESS = "success";
export const ERROR = "error";
export const DRAFT = "draft";
export const SUBMIT = "submit";
export const DRAFT_SUCCESS_MSG = "Draft saved successfully";
export const SOME_ERROR_OCCURRED = "Some error occurred";
export const CertificateStatus = ['Inactive', 'Active'];
export const CANCEL = 'cancel';
export const MEETING_TYPE = 'meetingType';
export const CLOSE = 'close';
export const DEVICE = 'deviceSelection';
export const CALL_3CX = '3cx';
export const SAVE = 'save';