import React from 'react';

interface SearchProps {
    onChange? : (e: React.ChangeEvent<HTMLInputElement>) => void;
    className?: string;
    placeHolder?: string
}

const SearchModel: React.FC<SearchProps> = ({onChange, className, placeHolder}) => {
    return <>
        <div>
            <input className={`${className} field-shadow`} type="text" placeholder={placeHolder} onChange={onChange}/>
        </div>
    </>
}

export default SearchModel;