import { t, translate } from "../../translation/Translation";
import * as ENDPOINTS from "routes/ApiEndpoints";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "Constants";
import FormBuilder from "components/common/services/form/FormBuilder";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";

const CreateRegion: React.FC = () => {
  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Office region",
          create: true,
        },
      ]}
      renderNoAccess={true}
    >
      <FormBuilder
        title={t("Create region")}
        formName={"Region"}
        validationRules={{}}
        getDataAPI={`${ENDPOINTS.BUDGET_EDIT}/region`}
        SaveDataAPI={`${ENDPOINTS.BUDGET_ADD}/region`}
        microService={CENTRAL_DATA_MANAGEMENT_MICROSERVICE}
        backButton={true}
        redirect={-1}
        type="region"
      />
    </AccessControl>
  );
};

export default translate(CreateRegion);
