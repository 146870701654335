import Button from "components/common/atoms/Button";
import Pagination from "components/common/atoms/Pagination";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import Title from "components/common/atoms/Title";
import { ApiCall } from "components/common/services/ApiServices";
import React, { Suspense, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import EditIcon from "static/images/EditIcon";
import Reset from "static/images/Reset";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { OptionProps } from "components/common/utlis/TypeAnnotations";
import { mapToSelect } from "components/common/utlis/MapToSelect";
import { MANAGE_OFFICES } from "routes/ApiEndpoints";
import { t, translate } from "../translation/Translation";
import "./offices.css";
import CustomNotify from "components/common/atoms/CustomNotify";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import ResetBtn from "components/common/atoms/ResetBtn";
import NoRecords from "components/common/atoms/NoRecords";
interface OfficeProps {
  offices: string;
  id: number;
  cities: string;
  postalCodeId: number;
}

interface OfficeDropdown {
  offices: object[];
}

const ManageOffices: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [office, setOffice] = useState<OfficeProps[]>([]);
  const [dropdowns, setDropdowns] = useState({
    officeDropdown: [] as OptionProps[],
  });

  const [searchData, setSearchData] = useState<OfficeDropdown>({
    offices: [],
  });

  const fetchOfficesDetais = async (searchData = {}, page = 1) => {
    const data = {
      ...searchData,
      page: page,
    };

    const response = await ApiCall.service(
      MANAGE_OFFICES,
      "POST",
      data,
      false,
      "central-data-management"
    );
    if (response.status === 200) {
      const { totalpages, offices, officeDropdown } = response.data;
      if (offices) {
        // Convert the associative array to an array of objects
        const officesArray: OfficeProps[] = Object.values(offices);
        setOffice(officesArray);
      }
      setDropdowns((prevData) => ({
        ...prevData,
        officeDropdown: mapToSelect(officeDropdown),
      }));

      setTotalPages(totalpages);
    }
  };
  useEffect(() => {
    fetchOfficesDetais();
  }, []);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    fetchOfficesDetais(searchData, newPage);
  };

  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    setSearchData((prevData) => ({
      ...prevData,
      [fieldName]: selectedOption,
    }));
  };

  const handleSearch = async (e: React.FormEvent) => {
    e.preventDefault();
    if (searchData.offices && searchData.offices.length > 0) {
      fetchOfficesDetais(searchData, 1);
    } else {
      CustomNotify({
        type: "error",
        message: t("Search input not provided") + ".",
      });
    }
  };

  const handleReset = async (e: React.FormEvent) => {
    setSearchData({
      offices: [],
    });
    setCurrentPage(1);
    fetchOfficesDetais();
  };

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Office cities",
          read: true,
        },
      ]}
      renderNoAccess={true}
    >
      <div>
        <Suspense
          fallback={
            <div className="text-center text-danger">{t("Loading")}</div>
          }
        >
          <Title title={t("Office cities")} />
          <div className="row search-bar">
            <div className="col-8">
              <SelectWithSearch
                name="offices"
                title={t("Office")}
                isMandatory={false}
                search={true}
                options={dropdowns.officeDropdown}
                value={searchData.offices}
                onChange={(e) => handleSelectChange(e, "offices")}
                isMulti={true}
                isTranslate={true}
              />
            </div>
            <div className="col-4 mt-34">
              <div className="d-flex align-items-center">
                <ResetBtn handleResetClick={handleReset} />
                <Button
                  title={t("Search")}
                  icon={faMagnifyingGlass}
                  type="submit"
                  handleClick={handleSearch}
                  className="form-button shadow-none search-btns position-relative text-start"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="table-responsive tableSection">
                <table className="table table-hover">
                  <thead>
                    <tr className="TableHeader">
                      <th className="align-middle" style={{ width: "20%" }}>
                        {t("Offices")}
                      </th>
                      <th className="align-middle" style={{ width: "70%" }}>
                        {t("Cities")}
                      </th>
                      <AccessControl
                        requiredPermissions={[
                          {
                            permission: "Office cities",
                            update: true,
                            delete: true,
                          },
                        ]}
                        actions={true}
                        strict={false}
                      >
                        <th>{t("Actions")}</th>
                      </AccessControl>
                    </tr>
                  </thead>
                  <tbody>
                    {office && office.length > 0 ? (
                      office.map((item: OfficeProps, index: number) => (
                        <tr
                          key={index}
                          className="border mb-3 box-shadow align-middle"
                        >
                          <td className="text-break" data-label={t("Offices")}>
                            {item.offices}
                          </td>
                          <td className="text-break" data-label={t("Cities")}>
                            {item.cities}
                          </td>
                          <AccessControl
                            requiredPermissions={[
                              {
                                permission: "Office cities",
                                update: true,
                                delete: true,
                              },
                            ]}
                            actions={true}
                            strict={false}
                          >
                            <td className="table-action-icons px-2">
                              <AccessControl
                                requiredPermissions={[
                                  {
                                    permission: "Office cities",
                                    update: true,
                                  },
                                ]}
                              >
                                <Link
                                  to={`/edit/office-locations/${item.id}`}
                                  className="btn p-0 border-0 me-2"
                                  title={t("Edit")}
                                >
                                  <EditIcon />
                                </Link>
                              </AccessControl>
                            </td>
                          </AccessControl>
                        </tr>
                      ))
                    ) : (
                      <NoRecords headerLength={4} />
                    )}
                  </tbody>
                </table>
                {totalPages > 1 && (
                  <div className="pagination justify-content-center align-items-center">
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={(newPage) => handlePageChange(newPage)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row backPadding">
            <div className="col-md-6 align-self-center">
              <Link
                to="/config/settings"
                className="back-btn text-decoration-underline"
              >
                {t("Back")}
              </Link>
            </div>
          </div>
        </Suspense>
      </div>
    </AccessControl>
  );
};

export default translate(ManageOffices);
