import React, { ReactNode } from 'react';
import { IconProp } from "@fortawesome/fontawesome-svg-core"; // Import the icon type
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
    title: ReactNode;
    className?: string;
    disabled?: boolean;
    handleClick?: () => void;
    type?: 'button' | 'submit' | 'reset' | undefined;
    icon?: IconProp
}

const PaginationButton: React.FC<Props> = ({
    title,
    className,
    disabled = false,
    handleClick,
    type = 'button',
    icon
}) => {
    return <>
        <button
            type={type}
            disabled={disabled}
            className={className}
            onClick={handleClick}
        >
            {icon && <FontAwesomeIcon icon={icon} />} {/* Render the icon if it exists */}
            {title}
        </button>
    </>
};

export default PaginationButton;