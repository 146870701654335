import { Reducer } from "react";
import { ChildCompetence, candidateFormData } from "../annotations/CandidateAnnotations";
import { FormAction } from "./Actions";
import { E164Number } from 'libphonenumber-js';

// Define a reducer to handle form actions
const CandidateReducer: Reducer<candidateFormData, FormAction> = (state, action) => {
    switch (action.type) {
      case "NEXT_STEP":
        const currentTab = state.tabDetails.find((tab) => tab.showStatus);
        if (currentTab) {
          const currentIndex = state.tabDetails.findIndex(
            (tab) => tab.id === currentTab.id
          );
          const nextIndex = (currentIndex + 1) % state.tabDetails.length;
  
          const updatedTabDetails = state.tabDetails.map((tab) => ({
            ...tab,
            showStatus: tab.id === state.tabDetails[nextIndex].id,
          }));
  
          return {
            ...state,
            tabDetails: updatedTabDetails,
          };
        }
        return state;
  
      case "PREVIOUS_STEP":
        const currentTabPrev = state.tabDetails.find((tab) => tab.showStatus);
        if (currentTabPrev) {
          const currentIndexPrev = state.tabDetails.findIndex(
            (tab) => tab.id === currentTabPrev.id
          );
          const previousIndex =
            (currentIndexPrev - 1 + state.tabDetails.length) %
            state.tabDetails.length;
  
          const updatedTabDetailsPrev = state.tabDetails.map((tab) => ({
            ...tab,
            showStatus: tab.id === state.tabDetails[previousIndex].id,
          }));
  
          return {
            ...state,
            tabDetails: updatedTabDetailsPrev,
          };
        }
        return state;
  
      case "UPDATE_TAB_DETAILS":
        return {
          ...state,
          tabDetails: action.tabDetails,
        };
      case "UPDATE_TAB_ERROR":
        const { tabIndex, error } = action;
        return {
          ...state,
          tabDetails: state.tabDetails.map((tab, index) => ({
            ...tab,
            error: index === tabIndex ? error : tab.error,
          })),
        };
  
      case "ADD_EMERGENCY_CONTACT_FIELD":
        const newEmergencyContact = {
          id:null,
          contactFirstName: "",
          contactLastName: "",
          contactEmail:"",
          contactPhone:'' as E164Number
        }
        const newEmergencyContactErrors ={
          contactFirstName: "",
          contactLastName: "",
          contactEmail:"",
          contactPhone:""
        }
        return {
          ...state,
          emergencyContactErrors:[...state.emergencyContactErrors,newEmergencyContactErrors],
          emergencyContact:[...state.emergencyContact, newEmergencyContact]
        }
  
      case "ADD_STUDY_FIELD":
        const newStudy = {
          id:null,
          diploma: null,
          school: "",
          study: "",
          from: null,
          to: null,
          info: "",
          completed: 1,
          certificateId: null,
          fileName: null,
          filePath: null,
        };
        const newStudyErros = {
          diploma: '',
          school: '',
          study: '',
          from: '',
          to: '',
          certificateId: '',
        }
        return {
          ...state,
          studies: [...state.studies, newStudy],
          studiesErrors: [...state.studiesErrors, newStudyErros]
        };
      
      case "REMOVE_EMERGENCY_CONTACT_FIELD":
        const updatedEmergencyContacts = [...state.emergencyContact];
        updatedEmergencyContacts.splice(action.indexToRemove,1);
        const updatedEmergencyContactsErrors = [...state.emergencyContactErrors];
        updatedEmergencyContactsErrors.splice(action.indexToRemove, 1);
        return {
          ...state,
          emergencyContact: updatedEmergencyContacts,
          emergencyContactErrors: updatedEmergencyContactsErrors
        }
  
      case "REMOVE_STUDY_FIELD":
        const updatedStudies = [...state.studies];
        updatedStudies.splice(action.indexToRemove, 1);
        const updatedStudiesErrors = [...state.studiesErrors];
        updatedStudiesErrors.splice(action.indexToRemove, 1);
        return {
          ...state,
          studies: updatedStudies,
          studiesErrors: updatedStudiesErrors
        };
  
      case "EDIT_EMERGENCY_CONTACT_FIELD":
        const { index: emergencyIndex, field: emergencyField, value: emergencyValue } = action;     
        const emergencyContactsList = [...state.emergencyContact];
        emergencyContactsList[emergencyIndex] = {
          ...emergencyContactsList[emergencyIndex],
          [emergencyField]: emergencyValue,
        };
  
        return {
          ...state,
          emergencyContact: emergencyContactsList,
        };
  
      case "EDIT_STUDY_FIELD":
  
        const { index: studyIndex, field: studyField, value: studyValue } = action;     
        const studiesList = [...state.studies];
        studiesList[studyIndex] = {
          ...studiesList[studyIndex],
          [studyField]: studyValue,
        };
  
        return {
          ...state,
          studies: studiesList,
        };
  
      case "ADD_WORK_FIELD":
        const newWork = {
          id:null,
          title: "",
          domain: null,
          function: null,
          employer: "",
          description: "",
          exp_from: null,
          exp_to: null,
          referenceId: null,
          reference: 0,
          fName: "",
          lName: "",
          refEmail:"",
          refPhone:'' as E164Number,
          referenceTaken: "",
          referenceInfo: "",
          reasonForDeparture:null,
          reason: "",
          workExperience: 0
        };
        const newWorkErrors = {
          title: '',
          domain: '',
          function: '',
          employer: '',
          description: '',
          exp_from: '',
          exp_to: '',
          reference: '',
          fName: "",
          lName: "",
          refEmail:"",
          refPhone:"",
          referenceTaken: "",
          referenceInfo: "",
          reasonForDeparture:"",
          reason: ""
        }
        return {
          ...state,
          workExperience: [...state.workExperience, newWork],
          workExperienceErrors: [...state.workExperienceErrors, newWorkErrors]
        };
  
      case "REMOVE_WORK_FIELD":
        const updatedWork = [...state.workExperience];
        updatedWork.splice(action.indexToRemove, 1);
        const updatedWorkErrors = [...state.workExperienceErrors];
        updatedWorkErrors.splice(action.indexToRemove, 1);
        return {
          ...state,
          workExperience: updatedWork,
          workExperienceErrors: updatedWorkErrors
        };
  
      case "EDIT_WORK_FIELD":
  
        const { index, field, value } = action;      
        const workList = [...state.workExperience];
        workList[index] = {
          ...workList[index],
          [field]: value,
        };
  
        return {
          ...state,
          workExperience: workList,
        };
  
      case "ADD_CERTIFICATE_FIELD":
      const { data: certificateData, index: certificateAddIndex } = action;
      const certificates = [...state.certificates];
      certificates[certificateAddIndex] ={ ...certificateData}
      return {
        ...state,
        certificates: certificates
      };
  
      case "REMOVE_CERTIFICATE_FIELD":
        const updatedCertificate = [...state.certificates];
        updatedCertificate.splice(action.indexToRemove, 1);
        const updatedCertificateErrors = [...state.certificateErrors];
        updatedCertificateErrors.splice(action.indexToRemove, 1);
        return {
          ...state,
          certificates: updatedCertificate,
          certificateErrors: updatedCertificateErrors
        };
  
      case "EDIT_CERTIFICATE_FIELD":
  
        const { index: certificateIndex, field: certificateField, value: certificateValue } = action;
        const certificatesList = [...state.certificates];
        certificatesList[certificateIndex] = {
          ...certificatesList[certificateIndex],
          [certificateField]: certificateValue,
        };
  
        return {
          ...state,
          certificates: certificatesList,
        };
  
        case "EDIT_DOCUMENT_FIELD":
  
        const { index: documentIndex, field: documentField, value: documentValue } = action;
        const documentsList = [...state.documentChecklist];
        documentsList[documentIndex] = {
          ...documentsList[documentIndex],
          [documentField]: documentValue,
          editId: documentIndex, 
        };
  
        return {
          ...state,
          documentChecklist: documentsList,
        };
      
      case "ADD_DOCUMENT_FIELD":
        const { data: documentData, index: documentAddIndex } = action;
        const documents = [...state.documentChecklist];
        documents[documentAddIndex] ={ ...documentData}
        return {
          ...state,
          documentChecklist: documents
        };
      
      case "REMOVE_DOCUMENT_FIELD":
        const updatedDocuments = [...state.documentChecklist];
        updatedDocuments.splice(action.indexToRemove, 1);
        const documentsWithUpdatedIds = updatedDocuments.map((doc) => {
          // If editId is a number and greater than the removed index, decrement it
          if (typeof doc.editId === "number" && doc.editId > action.indexToRemove) {
            return { ...doc, editId: doc.editId - 1 };
          }
  
          return doc;
        });
        return {
          ...state,
          documentChecklist: documentsWithUpdatedIds,
        };
  
      case 'SET_DROPDOWN':
        return {
          ...state,
          [action.field]: action.dropdownValues,
        };
  
        case "UPDATE_PROFILE_FIELD":
          return {
            ...state,
            profileCard: {
              ...state.profileCard,
              [action.field]: action.value,
            },
          };
  
      case "UPDATE_GENERAL_FIELD":
        return {
          ...state,
          general: {
            ...state.general,
            [action.field]: action.value,
          },
        };
  
      case "UPDATE_OTHER_FIELD":
        return {
          ...state,
          other: {
            ...state.other,
            [action.field]: action.value,
          },
        };
    
    
  
      case "UPDATE_EMERGENCY_CONTACT_FIELD":
        return {
          ...state,
          emergencyContact: state.emergencyContact.map((contact, index) => {
            if (index === action.index) {
              return {
                ...contact,
                [action.field]: action.value,
              };
            }
            return contact;
          }),
        };
  
      case "UPDATE_STUDY_FIELD":
        return {
          ...state,
          studies: state.studies.map((study, index) => {
            if (index === action.index) {
              return {
                ...study,
                [action.field]: action.value,
              };
            }
            return study;
          }),
        };
  
      case "UPDATE_WORK_FIELD":
        return {
          ...state,
          workExperience: state.workExperience.map((work, index) => {
            if (index === action.index) {
              return {
                ...work,
                [action.field]: action.value,
              };
            }
            return work;
          }),
        };
  
      case "UPDATE_JOB_PROFILE_FIELD":
        return {
          ...state,
          jobProfile: {
            ...state.jobProfile,
            [action.field]: action.value,
          },
        };
  
      case "UPDATE_CERTIFICATE_FIELD":
        return {
          ...state,
          certificates: state.certificates.map((certificate, index) => {
            if (index === action.index) {
              return {
                ...certificate,
                [action.field]: action.value,
              };
            }
            return certificate;
          }),
        };
  
      case "UPDATE_FIELD_ERROR":
        return {
          ...state,
          fieldError: {
            ...state.fieldError,
            [action.fieldName]: action.error,
          },
        };
  
        case "UPDATE_EMERGENCY_CONTACT_FIELD_ERROR":
          return {
            ...state,
            emergencyContactErrors: state.emergencyContactErrors.map((error, index) => {
              if (index === action.index) {
                return {
                  ...error,
                  [action.field]: action.error,
                };
              }
              return error;
            }),
          };
  
      case "UPDATE_STUDY_FIELD_ERROR":
        return {
          ...state,
          studiesErrors: state.studiesErrors.map((error, index) => {
            if (index === action.index) {
              return {
                ...error,
                [action.field]: action.error,
              };
            }
            return error;
          }),
        };
  
      case "UPDATE_WORK_FIELD_ERROR":
        return {
          ...state,
          workExperienceErrors: state.workExperienceErrors.map((error, index) => {
            if (index === action.index) {
              return {
                ...error,
                [action.field]: action.error,
              };
            }
            return error;
          }),
        };
  
      case "UPDATE_CERTIFICATE_FIELD_ERROR":
        return {
          ...state,
          certificateErrors: state.certificateErrors.map((error, index) => {
            if (index === action.index) {
              return {
                ...error,
                [action.field]: action.error,
              };
            }
            return error;
          }),
        };
  
      case "UPDATE_COMPETENCES":
        return {
          ...state,
          competences: action.payload, // Update the competences state
        };
      case "UPDATE_CHILD_COMPETENCES":
        return {
          ...state,
          competences: {
            ...state.competences,
            [action.field]: action.value,
          },
  
        };
      case "UPDATE_CHILD_COMPETENCES_DATA":
        if (state.competences && state.competences?.competence) {
          const groupedChildCompetences: {
            [competenceName: string]: ChildCompetence[];
          } = {};
          state.competences.competence.forEach(function (competence) {
            competence.child_competences.forEach(function (childCompetence) {
              if (!groupedChildCompetences[competence.competence_name]) {
                groupedChildCompetences[competence.competence_name] = [];
              }
  
              groupedChildCompetences[competence.competence_name].push(
                childCompetence
              );
            });
          });
  
          return {
            ...state,
            competences: {
              ...state.competences,
              childCompetences: groupedChildCompetences,
              selectedCompetenceNames: [],
            },
          };
        }
        return state;
  
      case "UPDATE_PERMIT_FIELD":
        return {
          ...state,
          workPermit: {
            ...state.workPermit,
            [action.field]: action.value,
          },
        };
      case "UPDATE_WORK_PERMIT_ERROR":
        return {
          ...state,
          permitErrors: {
            ...state.permitErrors,
            [action.field]: action.error,
          },
        };
      
      case "UPDATE_INTERNAL_INFO_FIELD":
        return {
          ...state,
          internalInfo: {
            ...state.internalInfo,
            [action.field]: action.value
          }
        }
      case "RESET_ALL_FIELDS":
        return {
          ...state,
          general: {
            ...state.general,
            firstName: "",
            lastName: "",
            mobNumber: '' as E164Number,
            email: "",
            domCheckbox: true,
            dateOfBirth: null,
            street: "",
            number: "",
            city: null,
            country: null,
            zipCode: "",
            box: "",
            cityOther: "",
            domStreet: "",
            domNumber: "",
            domCity: null,
            domCountry: null,
            domZipCode: "",
            domBox: "",
            domCityOther: ""
          },
          other: {
            ...state.other,
            gender: null,
          },
          jobProfile:{
            ...state.jobProfile,
            functionAliasName:"",
            infodesiredJobs:""
          },
          studies: [
            {
              ...state.studies[0],
              school: "",
              study:"",
              info: "",
              from: null,
              to: null,
              completed: 1
            },
          ],
          workExperience: [
            {
              ...state.workExperience[0],
              title: "",
              employer: "",
              description: "",
              exp_from: null,
              exp_to: null,
              reference: 0,
              workExperience: 0
            },
          ],
        }
        case 'SET_DROPDOWN_DATA':
        return {
          ...state,
          dropDowndata: {
            ...state.dropDowndata, // Spread the previous state to retain all initial values
            ...action.payload, // Overwrite only the provided fields
          },
        };
  
     
      default:
        return state;
    }
  };

  export default CandidateReducer;