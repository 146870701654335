import { t, translate } from "components/CentralDataMangement/translation/Translation";
import { MouseEventHandler } from "react";
import { Accordion } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Age from "static/images/Age";
import Clock from "static/images/Clock";
import CoefficientDeviation from "static/images/CoefficientDeviation";
import EditIcon from "static/images/EditIcon";
import EmployeeTypeIcon from "static/images/EmployeeType";
import Euro from "static/images/Euro";



interface PcProps {
  type: 'parent' | 'child' | 'subchild';
  header: { name: string; number: string | null; id?: number | null, pcType?:number | null };
  minSalary?: number | null;
  className?: string;
  handleClick?: MouseEventHandler<HTMLSpanElement>;
  eventHandlers?: {
    onClickEdit: React.MouseEventHandler<Element>;
    onClickAge: React.MouseEventHandler<Element>;
    onClickEmpType: React.MouseEventHandler<Element>;
    onClickEndTime: React.MouseEventHandler<Element>;
    onCoeffDev: React.MouseEventHandler<Element>;
  };
}

const PcCommon: React.FC<PcProps> = ({
  type = "",
  header,
  minSalary,
  className,
  eventHandlers,
  handleClick,
}) => {
  const location = useLocation();
  const path = location.pathname;
  const edit = path.includes('edit') || false;

  return (
    <ul className="list-unstyled">
      <li>
        <Accordion className={className}>
          <Accordion.Item eventKey="">
            <Accordion.Header className="rounded-3">
              <div className="row w-100">
                {type === 'parent' ? 
                  <div className="col-md-5">
                    <strong>{`(${header.number}) `+ t(`${header.name} - ${header.pcType === 1 ? "Bediende" : "Arbeider"}`)}</strong>
                  </div>
                :
                <div className="col-md-5">
                  <strong>{t(`${header.name}`)}</strong>
                </div>
                }
              </div>
            </Accordion.Header>
            <Accordion.Body className="bg-white rounded-3">
              <div className="row">
                <div className="col-md-5 align-self-center">
                  <table className="table table-borderless">
                    <tbody>
                      <tr>
                        <td className="text-break">
                          {type === 'parent' ? (<span>{t("Paritair committee number")}</span>) : (type === 'child' ? (<span>{t("Category name")}</span>) : (<span>{t("Function name")}</span>))}
                          <br />
                          <span><strong>{type === 'parent' ? header.number : header.name}</strong></span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-md-5 ps-0 align-self-center">
                  <table className="table table-borderless">
                    <tbody>
                      <tr>
                        <td className="word-break">
                          {type === 'parent' ? (<span>{t("Paritair committee name")}</span>) : (<span>{t("Minimum salary")}</span>)}
                          <br />
                          <span><strong>{type === 'parent' ? `(${header.number})` + t(`${header.name} - ${header.pcType === 1 ? "Bediende" : "Arbeider"}`) : minSalary != null ? "€" + minSalary : "€"}</strong></span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {type === 'parent' &&
                  <div className="col-md-2 align-self-center">
                    <div className="d-flex flex-wrap">
                      {edit &&
                        <Link
                          to={""}
                          onClick={eventHandlers !== undefined ? eventHandlers.onClickEdit : () => { }}
                          className="marginBottomPoint5 marginRightPoint5"
                        >
                          <span className="table-action-icons" title={t("Edit paritair committee")}>
                            <EditIcon />
                          </span>
                        </Link>
                      }
                      <Link
                        to={''}
                        onClick={eventHandlers !== undefined ? eventHandlers.onClickAge : () => { }}
                        className="marginBottomPoint5 marginRightPoint5"
                      >
                        <span className="table-action-icons" title={edit ? t("Edit age") : t("View age")}>
                          <Age />
                        </span>
                      </Link>
                      <Link
                        to={""}
                        onClick={eventHandlers !== undefined ? eventHandlers.onClickEmpType : () => { }}
                        className="marginBottomPoint5 marginRightPoint5"
                      >
                        <span className="table-action-icons" title={edit ? t("Edit employee type") : t("View employee type")}>
                          <EmployeeTypeIcon />
                        </span>
                      </Link>
                      <Link
                        to={`/${edit ? 'edit' : 'view'}/salary-benefit/${header.id}`}
                        onClick={() => { }}
                        className="marginBottomPoint5 marginRightPoint5"
                      >
                        <span className="table-action-icons" title={edit ? t("Edit salary benefits") : t("View salary benefits")}>
                          <Euro />
                        </span>
                      </Link>
                      <Link
                        to={""}
                        onClick={eventHandlers !== undefined ? eventHandlers.onClickEndTime : () => { }}
                        className="marginBottomPoint5 marginRightPoint5"
                      >
                        <span className="table-action-icons" title={edit ? t("Edit end time") : t("View end time")}>
                          <Clock />
                        </span>
                      </Link>
                      <Link
                        to={''}
                        onClick={eventHandlers !== undefined ? eventHandlers.onCoeffDev : () => { }}
                      >
                        <span className="table-action-icons" title={edit ? t("Edit coefficient deviations") : t("View coefficient deviations")}>
                          <CoefficientDeviation />
                        </span>
                      </Link>

                    </div>
                  </div>
                }
                {path.includes('edit') && type !== 'parent' &&
                  <div className="col-md-2 table-action-icons align-self-center ps-0">
                    <div className="cursor-pointer" onClick={handleClick} data-bs-toggle="tooltip" title={t("Edit")}>
                      <EditIcon />
                    </div>
                  </div>
                }

              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </li>
    </ul>
  );

}
export default translate(PcCommon);