import React, { useEffect, useState } from "react";
import { ApiCall } from "components/common/services/ApiServices";
import { Link, useNavigate } from "react-router-dom";
import Button from "components/common/atoms/Button";
import Pagination from "components/common/atoms/Pagination";
import CustomNotify from "components/common/atoms/CustomNotify";
import EditIcon from "static/images/EditIcon";
import * as ENDPOINTS from "routes/ApiEndpoints";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import Reset from "static/images/Reset";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import Title from "components/common/atoms/Title";
import { Spinner } from "react-bootstrap";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import ResetBtn from "components/common/atoms/ResetBtn";
import SpinnerWrapper from "components/common/atoms/SpinnerWrapper";
import NoRecords from "components/common/atoms/NoRecords";

interface manageData {
  name: string;
  code: string;
  id: number;
}

interface formDataProps {
  code: number | null;
  coeffDevname: string;
}

const ManageCoeffDeviation: React.FC = () => {
  //initiliaze the formData state for search fields
  const [formData, setFormData] = useState<formDataProps>({
    code: null,
    coeffDevname: "",
  });

  const navigate = useNavigate();

  //state for to store the functions linked countries
  const [data, setData] = useState([] as manageData[]);

  //state for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchDataBySearch();
  }, []);

  //API call for to fetch Employee types
  const fetchDataBySearch = async (formData = {}, page = 1) => {
    const requestData = {
      ...formData,
      page: page,
      type: "coeffDeviation",
    };

    const response = await ApiCall.service(
      ENDPOINTS.CONF_SETTINGS_GET,
      "POST",
      requestData,
      false,
      "central-data-management"
    );

    if (response.status === 200) {
      setData(response.data["data"]);
      setTotalPages(response.data["totalPages"]);
      setLoading(false);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (formData.coeffDevname.length === 0 && formData.code === null) {
      CustomNotify({
        type: "warning",
        message: t("Search input not provided") + ".",
      });
      fetchDataBySearch();
    } else {
      fetchDataBySearch(formData, 1);
    }
  };

  const handleReset = () => {
    // Clear the selected values in the dropdowns
    setFormData({
      code: null,
      coeffDevname: "",
    });
    setCurrentPage(1);
    fetchDataBySearch();
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    fetchDataBySearch(formData, newPage);
  };

  const handleFieldChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Coefficient deviation",
          read: true,
        },
      ]}
      renderNoAccess={true}
    >
      <Title title={t("Coefficient deviations")} />
      <div className="row search-bar">
        <div className="col-4">
          <LabelWithInputField
            handleChange={handleFieldChange}
            name="code"
            value={formData.code ?? ""}
            label={t("Coefficient code")}
            type="number"
          />
        </div>
        <div className="col-4">
          <LabelWithInputField
            handleChange={handleFieldChange}
            name="coeffDevname"
            value={formData.coeffDevname ?? ""}
            label={t("Coefficient name")}
          />
        </div>
        <div className="col-4 mt-34">
          <div className="d-flex align-items-center">
            <ResetBtn handleResetClick={handleReset} />
            <Button
              title={t("Search")}
              type="submit"
              icon={faMagnifyingGlass}
              handleClick={handleSubmit}
              className="form-button shadow-none  px-3 search-btns text-start position-relative"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="table-responsive tableSection">
            <table className="table table-hover">
              <thead>
                <tr className="TableHeader">
                  <th className="ps-lg-4" style={{ width: "20%" }}>
                    {t("Code")}
                  </th>
                  <th style={{ width: "40%" }}>{t("Name")}</th>
                  <AccessControl
                    requiredPermissions={[
                      {
                        permission: "Coefficient deviation",
                        update: true,
                        delete: true,
                      },
                    ]}
                    actions={true}
                    strict={false}
                  >
                    <th>{t("Actions")}</th>
                  </AccessControl>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <SpinnerWrapper headerLength={3} />
                ) : (
                  <>
                    {data && data.length > 0 ? (
                      data.map((item) => (
                        <tr
                          key={item.id}
                          className="border-bottom mb-3 box-shadow align-middle"
                        >
                          {/* <td className="ps-lg-4">{pageIndex++}</td> */}
                          <td className="ps-lg-4" data-label={t("Code")}>
                            {item.code}
                          </td>
                          <td data-label={t("Name")}>{t(`${item.name}`)}</td>
                          <AccessControl
                            requiredPermissions={[
                              {
                                permission: "Coefficient deviation",
                                update: true,
                                delete: true,
                              },
                            ]}
                            actions={true}
                            strict={false}
                          >
                            <td className="table-action-icons px-2">
                              <AccessControl
                                requiredPermissions={[
                                  {
                                    permission: "Coefficient deviation",
                                    update: true,
                                  },
                                ]}
                              >
                                <div className="d-none d-md-none d-lg-block">
                                  <Link
                                    to={`/edit/coeff-deviation/${item.id}`}
                                    className="btn p-0 border-0 me-2"
                                    title={t("Edit")}
                                  >
                                    <EditIcon />
                                  </Link>
                                </div>
                                {/* PWA */}
                                <div className="d-block d-md-block d-lg-none">
                                  <Link
                                    to={`/edit/coeff-deviation/${item.id}`}
                                    className="btn p-0 border-0 w-100"
                                    title={t("Edit")}
                                  >
                                    <Button
                                      title={t("Edit")}
                                      className=" mb-2 button-width edit-btn rounded-3"
                                    />
                                  </Link>
                                </div>
                              </AccessControl>
                            </td>
                          </AccessControl>
                        </tr>
                      ))
                    ) : (
                      <NoRecords headerLength={7} />
                    )}
                  </>
                )}
              </tbody>
            </table>
            {totalPages > 1 && (
              <div className="pagination justify-content-center align-items-center">
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={(newPage) => handlePageChange(newPage)}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="row backPadding">
        <div className="col-md-6 align-self-center">
          <Link
            to=""
            className="back-btn text-decoration-underline"
            onClick={() => navigate(-1)}
          >
            {t("Back")}
          </Link>
        </div>
      </div>
    </AccessControl>
  );
};

export default translate(ManageCoeffDeviation);
