import { t } from "components/CentralDataMangement/translation/Translation";
import Title from "components/common/atoms/Title";
import { useEffect, useState } from "react";
import {
  Candidate,
  OfficeTarget,
  PersonalTarget,
  WeeklyReportsData,
} from "../Annotations";
import WeeklyReportTable from "../common/WeeklyReportTable";
import Tick from "static/images/Tick";
import OfficeTargetTable from "../common/OfficeTargetTable";
import PersonalTargetTable from "../common/PersonalTargetTable";
import { ApiCall } from "components/common/services/ApiServices";
import { EDIT_WEEKLY_REPORTS } from "../../../../routes/ApiEndpoints";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "Constants";
import EncryptDecryptService from "components/common/services/EncryptDecryptService";
import CustomNotify from "components/common/atoms/CustomNotify";
import secureLocalStorage from "react-secure-storage";
import { Link } from "react-router-dom";
import LoadingIcon from "components/common/utlis/LoadingIcon";

interface TargetData {
  officeTarget: OfficeTarget;
  personalTarget: PersonalTarget;
}

const WeeklyReports: React.FC = () => {
  const [reports, setReports] = useState<WeeklyReportsData>({});
  const [candidates, setCandidates] = useState<Candidate[]>([]);
  const [targetData, setTargetData] = useState<TargetData>();
  const [loading, setLoading] = useState(true);
  let data = secureLocalStorage.getItem('weeklyReport') as string; // Get the 'data' query param as a string

  useEffect(() => {

    const timeout = setTimeout(() => {
      fetchData();
    }, 3000); // 3 seconds

    return () => clearTimeout(timeout); // Cleanup the timeout on component unmount
  }, []);

  const fetchData = async () => {
    let parsedData = {};
    try {
      if (data) {
        // Decrypt the recruiterData
        let decryptedData = EncryptDecryptService.decryptData(data);

        // If decryptedData is in JSON format, parse it
        parsedData = JSON.parse(decryptedData);
      }

    } catch (error) {
      CustomNotify({
        type: "error",
        message: t("Something went wrong"),
      });
      return null;
    }
    const response = await ApiCall.service(
      EDIT_WEEKLY_REPORTS,
      "POST",
      { ...parsedData, type: "recruiter" },
      false,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );

    if (response.status === 200) {

      setReports(response?.data?.reports);
      setCandidates(response?.data?.candidates);
      setTargetData(response?.data?.targets);
    }
    setLoading(false);
  };

  const rows = [
    { key: "calls", label: "Calls" },
    { key: "screening", label: "Screenings" },
    { key: "presentation", label: "Presentation" },
    { key: "interview", label: "Interview" },
    { key: "starter", label: "Starter" },
    { key: "stopper", label: "Stopper" },
  ];

  return (
    <>
      <Title title={t("Reporting (weekly recruiter)")} />
      {loading ? (
        <div style={{ height: "38vw" }} className="d-flex align-items-center justify-content-center">
          <LoadingIcon
            iconType="bars"
            color="#e55496"
            className="m-auto"
            height={"5vw"}
            width={"5vw"}
          />
        </div>

      ) : (
        <>
          <div className="row">
            <div className="col-md-4">
              <OfficeTargetTable
                data={targetData?.officeTarget}
                tableTitle="OFFICE"
              />
            </div>
            <div className="col-md-8">
              <PersonalTargetTable
                data={targetData?.personalTarget}
                tableTitle={targetData?.personalTarget?.name ?? "-"}
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-12">
              <WeeklyReportTable data={reports as WeeklyReportsData} rows={rows} />
            </div>
          </div>
          <div className="pb-4">
            <table className="table table-hover table-bordered targetTable">
              <thead>
                <tr className="TableHeader">
                  <th className="text-break" style={{ width: "15%" }}>
                    {t("Candidate")}
                  </th>
                  <th className="text-break" style={{ width: "15%" }}>
                    {t("Profile")}
                  </th>
                  <th className="text-break text-center" style={{ width: "10%" }}>
                    {t("Registration")}
                  </th>
                  <th className="text-break text-center" style={{ width: "10%" }}>
                    {t("Screening")}
                  </th>
                  <th className="text-break text-center" style={{ width: "20%" }}>
                    {t("Presentation")}
                  </th>
                  <th className="text-break text-center" style={{ width: "20%" }}>
                    {t("Interview")}
                  </th>
                  <th className="text-break text-center" style={{ width: "10%" }}>
                    {t("Starter")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {candidates && candidates.length > 0 ? (
                  <>
                    {candidates.map((candidate: Candidate, index) => (
                      <tr key={index}>
                        <td className="align-middle">{candidate.candidate}</td>
                        <td className="align-middle">{candidate.profile}</td>
                        <td className="table-action-icons align-middle text-center">
                          {candidate.registration > 0 ? <Tick /> : ""}
                        </td>
                        <td className="table-action-icons align-middle text-center">
                          {candidate.screening > 0 ? <Tick /> : ""}
                        </td>
                        <td className="text-center">
                          <div className="numberWrapper">
                            {candidate.presentation}
                          </div>
                        </td>
                        <td className="text-center">
                          <div className="numberWrapper">
                            {candidate.interview}
                          </div>
                        </td>
                        <td className="table-action-icons align-middle text-center">
                          {candidate.starter > 0 ? <Tick /> : ""}
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr className="border rounded-3">
                    <td colSpan={7}>
                      <div className="text-danger text-center">
                        {t("No candidates found")}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="row py-4">
              <div className="col-md-4 align-self-center">
                <Link
                  to="/manage-reports"
                  className=" back-btn text-decoration-underline"
                >
                  {t("Back")}
                </Link>
              </div>
            </div>
          </div>
        </>
      )}

    </>
  );
};

export default WeeklyReports;
