import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import Button from "components/common/atoms/Button";
import LoginDetailsCard from "components/common/atoms/LoginDetailsCard";
import Title from "components/common/atoms/Title";
import Calender from "components/common/molecules/Calender";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import Reset from "static/images/Reset";
import * as ENDPOINTS from "routes/ApiEndpoints";
import { ApiCall } from "components/common/services/ApiServices";
import { IDENTITY_MANAGER_MICROSERVICE } from "Constants";
import Pagination from "components/common/atoms/Pagination";
import CustomNotify from "components/common/atoms/CustomNotify";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import { useDispatch } from "react-redux";
import { createViewFilterConfig } from "./ViewFilter";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { selectAuth } from "features/auth/AuthSlice";
import { RootState } from "store";
import GetQueryParams from "components/common/services/GetQueryParams";
import {
  createQueryParams,
  filterAndMerge,
  getQueryParamsFromRedux,
} from "components/common/utlis/HelperFunctions";
import {
  clearDestinationPath,
  setDestinationPath,
} from "features/NavigationSlice";
import PaginationNew from "components/common/atoms/PaginationNew";
import SearchFields from "components/common/atoms/SearchFields";
import ResetBtn from "components/common/atoms/ResetBtn";
import SpinnerWrapper from "components/common/atoms/SpinnerWrapper";
import NoRecords from "components/common/atoms/NoRecords";

interface SearchProps {
  fromDate: Date | null;
  toDate: Date | null;
  currentPage: number;
  recordsPerPage: number;
}
const ViewLoginDetails = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [paginationData, setPaginationData] = useState({
    totalPages: 1,
    totalRecords: 0,
  });
  const filters = createViewFilterConfig();
  const destinationPath = useSelector(
    (state: RootState) => state.navigation.destinationPath
  );
  const getUrlParams = GetQueryParams();
  const paramFilter = getUrlParams.get("filter");
  const queryParams = getQueryParamsFromRedux(destinationPath, paramFilter);

  const defaultSearchData: SearchProps = {
    fromDate: null,
    toDate: null,
    currentPage: 1,
    recordsPerPage: 10,
  };
  const filteredSearchData = filterAndMerge(defaultSearchData, queryParams);
  const [searchData, setSearchData] = useState<SearchProps>({
    ...defaultSearchData,
    ...filteredSearchData,
  });

  //state for to store the functions linked countries
  const [userData, setUserData] = useState([]);
  const [loginData, setLoginData] = useState([]);

  useEffect(() => {
    const filterData = { ...searchData };
    const searchUrl = createQueryParams(filterData);
    dispatch(setDestinationPath(searchUrl));
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      fetchDetails(filterData);
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [searchData]);

  //API call for to fetch Employee types
  const fetchDetails = async (filterData: any) => {
    setLoading(true);
    const data = {
      search: filterData,
    };

    const response = await ApiCall.service(
      `${ENDPOINTS.GET_USER_LOGIN_DETAILS}/${id}`,
      "POST",
      data,
      false,
      IDENTITY_MANAGER_MICROSERVICE
    );

    if (response.status === 200) {
      const { userData, loginRecords, pagination } = response.data;
      setUserData(userData);
      setLoginData(loginRecords);
      setPaginationData(pagination);
      setLoading(false);
    }
  };

  const handleDateChange = (date: Date | null, fieldName: string) => {
    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: 1,
      [fieldName]: date,
    }));
  };

  const handlePageChange = (pageNumber: number) => {
    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: pageNumber,
    }));
  };

  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    setSearchData((prev) => ({
      ...prev,
      recordsPerPage: newItemsPerPage,
      currentPage: 1,
    }));
  };

  const handleReset = async (e: React.FormEvent) => {
    dispatch(clearDestinationPath());
    setSearchData(defaultSearchData);
  };
  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Login details",
          read: true,
        },
      ]}
      renderNoAccess={true}
    >
      <Title title={t("Login details")} />
      <div className="row">
        <div className="col-7 mt-3 marginBotttom1">
          <LoginDetailsCard data={userData} />
        </div>
        <div className="col-12 viewLoginDetails">
          <table className="table border-0  marginBottomPoint5">
            <thead>
              <tr className="tableSearchFields">
                <SearchFields
                  searchData={searchData}
                  filters={filters}
                  handleDateChange={handleDateChange}
                />
                <th className="border-0">
                  <div className="d-flex align-items-center w-100">
                    <ResetBtn handleResetClick={handleReset} />
                  </div>
                </th>
              </tr>
            </thead>
          </table>
        </div>
        <div className="col-12">
          <div className="table-responsive viewLoginDetails internalContentWrapper">
            <table className="table table-hover viewLoginDetails">
              <thead>
                <tr className="TableHeader">
                  <th>{t("Last login")}</th>
                  <th>{t("Last logout")}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <SpinnerWrapper headerLength={6} />
                ) : (
                  <>
                    {loginData && loginData.length > 0 ? (
                      loginData.map((item: any, index: number) => (
                        <tr
                          key={item.id}
                          className="border-bottom mb-3 box-shadow align-middle"
                        >
                          <td className="text-break" data-label={t("Name")}>
                            {item.last_login}
                          </td>
                          <td className="text-break" data-label={t("Office")}>
                            {item.last_logout}
                          </td>
                          <td></td>
                        </tr>
                      ))
                    ) : (
                      <NoRecords headerLength={7} />
                    )}
                  </>
                )}
              </tbody>
            </table>
            {!loading && (
              <div className="pagination d-block">
                <PaginationNew
                  currentPage={searchData.currentPage}
                  totalPages={paginationData.totalPages}
                  totalRecords={paginationData.totalRecords}
                  recordsPerPage={searchData.recordsPerPage}
                  onItemsPerPageChange={handleItemsPerPageChange}
                  onPageChange={(newPage: any) => handlePageChange(newPage)}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="row backPadding">
        <div className="col-md-6 align-self-center py-4">
          <Link
            to="/login/details"
            className=" back-btn text-decoration-underline"
          >
            {t("Back")}
          </Link>
        </div>
      </div>
    </AccessControl>
  );
};

export default translate(ViewLoginDetails);
