import React, { useState, useEffect } from "react";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import {
  ValidationRules,
  OptionProps,
} from "components/common/utlis/TypeAnnotations";
import { ApiCall } from "components/common/services/ApiServices";
import { mapToSelect } from "components/common/utlis/MapToSelect";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  validateForm,
  validateRequired,
  validateSelectField,
  Option,
} from "../../common/services/ValidationService";
import { LOCATION_DETAILS, ADD_LOCATION } from "routes/ApiEndpoints";
import Button from "components/common/atoms/Button";
import CustomNotify from "components/common/atoms/CustomNotify";
import Title from "components/common/atoms/Title";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import GetQueryParams from "components/common/services/GetQueryParams";
import ModalPopup from "components/common/atoms/ModalPopup";
import { MANAGE_LOCATIONS } from "routes/RouteConstants";
import LoadingIcon from "components/common/utlis/LoadingIcon";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import {
  CENTRAL_DATA_MANAGEMENT_MICROSERVICE,
  COMPANY_MICROSERVICE,
  RECRUITER,
} from "Constants";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import AutoCompleteDropdown from "components/common/atoms/AutoCompleteDropdown";

interface LocationProps {
  company: Option | null;
  location: string;
  manager: object[];
  street: string;
  number: string;
  box: string;
  zipcode: string;
  city: Option | null;
  country: Option | null;
}

const Location: React.FC = () => {
  const navigate = useNavigate();
  const queryParams = GetQueryParams();
  const paramsCompanyId = queryParams.get("company_id");
  const paramsAgreementId = queryParams.get("agreement_id");
  const [loading, setLoading] = useState(false);
  const authUser = useSelector(selectAuth);

  const { id } = useParams<{ id: string }>();
  const [formData, setFormData] = useState<LocationProps>({
    company: null,
    location: "",
    manager: [],
    street: "",
    number: "",
    box: "",
    zipcode: "",
    city: null,
    country: null,
  });

  const [errors, setErrors] = useState<{ [key: string]: string }>({
    company: "",
    location: "",
    manager: "",
    street: "",
    number: "",
    box: "",
    zipcode: "",
    city: "",
    country: "",
  });

  const [showModal, setShowModal] = useState<boolean>(false);

  const validation = (
    name: string,
    value: string | boolean | object | Option,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      company: [validateSelectField],
      location: [validateRequired],
      street: [validateRequired],
      number: [validateRequired],
      zipcode: [validateRequired],
      city: [validateSelectField],
      country: [validateSelectField],
    };

    const validationErrors = validateForm(
      { ...formData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation && Object.keys(errors).length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }
    if (Object.keys(validationErrors).length > 0) {
      return false;
    }

    return true;
  };

  const [options, setOptions] = useState({
    countryOptions: [] as OptionProps[],
    contactOptions: [] as OptionProps[],
    cityOptions: [] as OptionProps[],
  });

  useEffect(() => {
    const fetchLocationDetails = async () => {
      const url = id ? LOCATION_DETAILS + `/${id}` : LOCATION_DETAILS;
      const data = {
        companyId: paramsCompanyId,
      };
      const response = await ApiCall.service(
        url,
        "POST",
        data,
        true,
        "company-creation"
      );
      if (response.status === 200) {
        const { locationDetails, company, countries, cities } = response.data;

        setOptions((prevData) => ({
          ...prevData,
          countryOptions: mapToSelect(countries),
          cityOptions: mapToSelect(cities),
        }));

        if (locationDetails && Object.keys(locationDetails).length > 0) {
          await fetchResponsiblePerson(locationDetails.company.value);

          setFormData({
            ...formData,
            company: locationDetails.company,
            location: locationDetails.location,
            manager: locationDetails.locationManagers,
            street: locationDetails.street,
            number: locationDetails.number,
            box: locationDetails.box,
            zipcode: locationDetails.zipcode,
            city: locationDetails.city,
            country: locationDetails.country,
          });
        } else if (paramsCompanyId !== null) {
          setFormData({
            ...formData,
            company: company,
          });
          fetchResponsiblePerson(Number(paramsCompanyId));
        }
      }
    };
    fetchLocationDetails();
  }, [id]);

  const fetchResponsiblePerson = async (companyId: number) => {
    const response = await ApiCall.getService(
      `getCompanyResponsiblePerson/${companyId}`,
      "GET",
      "company-creation"
    );
    if (response.status === 200) {
      const contactOptions = mapToSelect(response.data.managerOptions);
      setOptions((prevData) => ({
        ...prevData,
        contactOptions: contactOptions,
      }));
      setFormData((prevData) => ({
        ...prevData,
        manager: response.data.manager,
      }));
    }
  };

  const handleSelectChange = (selectedOption: any, name: string) => {
    if (name === "company") {
      const id = selectedOption ? selectedOption?.value : null;

      if (
        selectedOption &&
        typeof selectedOption === "object" &&
        "value" in selectedOption &&
        "label" in selectedOption &&
        selectedOption.label !== null
      ) {
        fetchResponsiblePerson(id);
      }

      setFormData((prevData) => ({
        ...prevData,
        manager: [],
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        manager: "",
      }));
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: selectedOption !== null ? selectedOption : "",
    }));
    validation(name, selectedOption, true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    validation(name, value, true);
  };

  const handleCountryChange = (selectedOption: any) => {
    setFormData((prevData) => ({
      ...prevData,
      country: selectedOption !== null ? selectedOption : "",
    }));
    validation("country", selectedOption, true);
  };

  const navigateToAgreementPage = () => {
    return navigate(`/cooperation-agreement/create/${paramsCompanyId}`);
  };

  const navigateToManagePage = () => {
    return navigate(MANAGE_LOCATIONS);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const { name, value } = event.target as HTMLInputElement;
    if (validation(name, value)) {
      setLoading(true);
      const url = id ? ADD_LOCATION + `/${id}` : ADD_LOCATION;
      const response = await ApiCall.service(
        url,
        "POST",
        formData,
        false,
        "company-creation"
      );
      if (response.status === 200) {
        CustomNotify({ type: "success", message: response.msg });
        if (authUser.role === RECRUITER) {
          navigate(MANAGE_LOCATIONS);
          return;
        }

        paramsAgreementId && paramsCompanyId
          ? setShowModal(true)
          : setShowModal(false);
        !paramsAgreementId && !paramsCompanyId && navigate(MANAGE_LOCATIONS);
      } else if (response.status === 409) {
        CustomNotify({ type: "error", message: response.msg });
      }
    }
    setLoading(false);
  };

  const permissionType = id ? "update" : "create";
  const permissionObject: any = {
    permission: "Company",
  };
  permissionObject[permissionType] = true;
  return (
    <AccessControl
      requiredPermissions={[permissionObject]}
      renderNoAccess={true}
    >
      <Title title={id ? t("Edit location") : t("Create location")} />
      <div>
        <form action="" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-12">
              <div className="form-border">
                <div className="row">
                  <div className="col-sm-12 col-lg-4 col-md-6 autoCompleteFunction">
                    <AutoCompleteDropdown
                      label={t("Company")}
                      placeholder={t("Type to search") + "..."}
                      microService={COMPANY_MICROSERVICE}
                      onChange={(e) => handleSelectChange(e, "company")}
                      value={formData.company}
                      columnName="company_name"
                      modelKey="company"
                      filter={true}
                      filterInfo={[
                        {
                          column: "company_status",
                          value: 1,
                          condition: "where",
                        },
                        {
                          column: "company_status",
                          value: 0,
                          condition: "orWhere",
                        },
                      ]}
                      isMandatory={true}
                      error={errors.company}
                      notNullColumn="bbright_id"
                    />
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <LabelWithInputField
                      label={t("Location")}
                      name="location"
                      isMandatory={true}
                      value={formData.location}
                      handleChange={handleChange}
                      id="location"
                      type="text"
                      error={errors.location}
                    />
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <SelectWithSearch
                      title={t("Responsible person")}
                      name="manager"
                      isMandatory={false}
                      search={true}
                      // isDisabled={
                      //   selectedCompany === null && paramsCompanyId === null
                      // }
                      options={options.contactOptions}
                      value={formData.manager}
                      onChange={(e) => handleSelectChange(e, "manager")}
                      isMulti={true}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4 col-md-6">
                    <LabelWithInputField
                      label={t("Street")}
                      name="street"
                      isMandatory={true}
                      value={formData.street}
                      handleChange={handleChange}
                      id="street"
                      type="text"
                      error={errors.street}
                    />
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <LabelWithInputField
                      label={t("Number")}
                      name="number"
                      isMandatory={true}
                      value={formData.number}
                      handleChange={handleChange}
                      id="number"
                      type="text"
                      error={errors.number}
                    />
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <LabelWithInputField
                      label={t("Box")}
                      name="box"
                      isMandatory={false}
                      value={formData.box}
                      handleChange={handleChange}
                      id="box"
                      type="text"
                      error={errors.box}
                    />
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <LabelWithInputField
                      label={t("Zipcode")}
                      name="zipcode"
                      isMandatory={true}
                      value={formData.zipcode}
                      handleChange={handleChange}
                      id="zipcode"
                      type="text"
                      error={errors.zipcode}
                    />
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <SelectWithSearch
                      title={t("Country")}
                      name="country"
                      isMandatory={true}
                      search={true}
                      options={options.countryOptions}
                      value={formData.country}
                      onChange={(e) => handleCountryChange(e)}
                      isMulti={false}
                      error={errors.country}
                      // isDisabled={formData.company !== null}
                    />
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <SelectWithSearch
                      title={t("City")}
                      name="city"
                      isMandatory={true}
                      search={true}
                      options={options.cityOptions}
                      value={formData.city}
                      onChange={(e) => handleSelectChange(e, "city")}
                      isMulti={false}
                      error={errors.city}
                    />
                    {/* <div className="col-lg-4 col-md-6 autoCompleteFunction">
                    <AutoCompleteDropdown
                      label={t("City")}
                      placeholder={t("Type to search") + "..."}
                      microService={CENTRAL_DATA_MANAGEMENT_MICROSERVICE}
                      onChange={(e) => handleSelectChange(e, "city")}
                      value={formData.city}
                      columnName="postal_code"
                      concat="name"
                      modelKey="city"
                      name="city"
                      separator=" - "
                      isMandatory={true}
                      error={errors.city}
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row backPadding">
            <div className="col-md-4 align-self-center">
              <Link
                to="/manage-locations"
                className=" back-btn text-decoration-underline"
              >
                {t("Back")}
              </Link>
            </div>
            <div className="col-md-8 text-end">
              {!loading ? (
                <Button
                  title={t("Submit")}
                  type="submit"
                  className="form-button shadow-none "
                />
              ) : (
                <LoadingIcon
                  iconType="bars"
                  color="#e55496"
                  className="ms-auto mb-3"
                  height={"3vw"}
                  width={"3vw"}
                />
              )}
            </div>
          </div>
        </form>
      </div>
      <ModalPopup
        show={showModal}
        onHide={() => navigateToManagePage()}
        title={t("Create cooperation agreement")}
        body={
          t(`Location created successfully for company`) +
          `${formData.company?.label},` +
          t(`Go ahead and create cooperation agreement`)
        }
        onConfirmButtonClick={() => navigateToAgreementPage()}
        onCloseButtonClick={() => navigateToManagePage()}
        closeTitle={t("No")}
        confirmTitle={t("Create cooperation agreement")}
      />
    </AccessControl>
  );
};

export default translate(Location);
