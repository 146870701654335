import { useEffect, useState } from "react";
import Title from "components/common/atoms/Title";
import * as ENDPOINTS from "../../../routes/ApiEndpoints";
import { ApiCall } from "components/common/services/ApiServices";
import { t, translate } from "../translation/Translation";
import { Accordion } from "react-bootstrap";
import CheckBoxField from "components/common/atoms/CheckBoxField";
import RadioField from "components/common/atoms/RadioField";
import LabelWithTextAreaField from "components/common/molecules/LabelWithTextAreaField";
import { Link, useParams } from "react-router-dom";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";

interface ScreeningProps {
  questions: any;
}

const ScreeningAssessment: React.FC = () => {
  const [formData, setFormData] = useState<ScreeningProps>({
    questions: [],
  });
  const { screeningId, candidateId } = useParams<{
    screeningId: string;
    candidateId: string;
  }>();

  const handleFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    groupIndex: number,
    questionIndex: number
  ) => {};

  useEffect(() => {
    const fetchScreeningDetails = async () => {
      const response = await ApiCall.getService(
        `${ENDPOINTS.GET_SCREENING_ASSESSMENT}/${screeningId}`,
        "GET",
        "central-data-management",
        true
      );
      setFormData((prevData) => ({
        ...prevData,
        questions: response.data,
      }));
    };
    fetchScreeningDetails();
  }, []);

  return (
    <>
      <AccessControl
        requiredPermissions={[
          {
            permission: "Screening",
            read: true,
          },
        ]}
        renderNoAccess={true}
      >
        <Title title={t("View screening")} />
        {formData.questions.length > 0 && (
            <div className="form-border">
              {formData.questions.map(
                (questionGroup: any, groupIndex: number) => (
                  <div key={groupIndex}>
                    <div className="marginBottom1">
                      <Accordion>
                        <Accordion.Item eventKey={`${groupIndex}`}>
                          <Accordion.Header className="fw-bold">
                            {questionGroup.function.label}
                          </Accordion.Header>
                          <Accordion.Body className="bg-white padding1">
                            {questionGroup.questions.length &&
                            questionGroup.questions.length > 0 ? (
                              <>
                                {questionGroup.questions.map(
                                  (question: any, index: number) => (
                                    <div
                                      key={index}
                                      className={`marginBotttom1 internalContentWrapper border ${
                                        question.disabled
                                          ? " border question-not-applicable-section opacity-50 p-3 rounded-3 mb-3"
                                          : ""
                                      }`}
                                    >
                                      <div className="d-flex justify-content-between align-items-center">
                                        <h5
                                          className="questionName"
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              index +
                                              1 +
                                              ") " +
                                              question.question,
                                          }}
                                        />
                                      </div>
                                      {question.questionType === 1 &&
                                        question.options.length > 0 && (
                                          <div className="my-1" key={index}>
                                            {question.options.map(
                                              (
                                                option: any,
                                                optionIndex: number
                                              ) => (
                                                <div
                                                  key={optionIndex}
                                                  className="screening-checkbox d-flex align-items-center marginBottomPoint5"
                                                >
                                                  <CheckBoxField
                                                    name={`answers-${index}-${optionIndex}`}
                                                    id={`answers-${index}-${optionIndex}`}
                                                    onChangeHandler={(event) =>
                                                      handleFieldChange(
                                                        event,
                                                        groupIndex,
                                                        index
                                                      )
                                                    }
                                                    value={optionIndex}
                                                    disable={true}
                                                    isChecked={
                                                      Array.isArray(
                                                        question.answers
                                                      ) &&
                                                      question.answers.includes(
                                                        String(optionIndex)
                                                      )
                                                    }
                                                  />

                                                  <span
                                                    dangerouslySetInnerHTML={{
                                                      __html: option.replace(
                                                        /<a/g,
                                                        '<a target="_blank"'
                                                      ),
                                                    }}
                                                  />
                                                </div>
                                              )
                                            )}
                                          </div>
                                        )}

                                      {question.questionType === 3 &&
                                        question.options.length > 0 && (
                                          <div className="my-1" key={index}>
                                            {question.options.map(
                                              (
                                                option: any,
                                                optionIndex: number
                                              ) => (
                                                <div
                                                  key={optionIndex}
                                                  className="d-flex screening-checkbox align-items-center marginBottomPoint5"
                                                >
                                                  <RadioField
                                                    className="ps-1"
                                                    id={`answers-${index}-${optionIndex}`}
                                                    value={optionIndex}
                                                    name={`answers-${groupIndex}-${index}`}
                                                    handleChange={(event) => {
                                                      handleFieldChange(
                                                        event,
                                                        groupIndex,
                                                        index
                                                      );
                                                    }}
                                                    disable={true}
                                                    ischecked={
                                                      Array.isArray(
                                                        question.answers
                                                      ) &&
                                                      question.answers.includes(
                                                        String(optionIndex)
                                                      )
                                                    }
                                                  />
                                                  <span
                                                    dangerouslySetInnerHTML={{
                                                      __html: option,
                                                    }}
                                                  />
                                                </div>
                                              )
                                            )}
                                          </div>
                                        )}
                                      {question.questionType === 2 && (
                                        <LabelWithTextAreaField
                                          name="description"
                                          handleChange={(event) =>
                                            handleFieldChange(
                                              event,
                                              groupIndex,
                                              index
                                            )
                                          }
                                          label=""
                                          value={
                                            question.answers.length > 0
                                              ? question.answers[0]
                                              : ""
                                          }
                                          isDisabled={true}
                                        />
                                      )}
                                    </div>
                                  )
                                )}
                              </>
                            ) : (
                              <div className="text-danger p-2 text-center">
                                {t(
                                  "No general and function related questions added"
                                )}
                              </div>
                            )}
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </div>
                )
              )}
          </div>
        )}
        <div className="col-md-6 align-self-center backPadding">
          <Link
            to={`/manage-screening/${candidateId}`}
            className=" back-btn text-decoration-underline"
          >
            {t("Back")}
          </Link>
        </div>
      </AccessControl>
    </>
  );
};
export default translate(ScreeningAssessment);
