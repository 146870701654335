import React from 'react';
import MultiSelectField from '../atoms/MultiSelectField';
import LabelField from '../atoms/LabelField';

interface Option {
  value?: number;
  name?: string;
}
interface LabelWithSelectFieldProps {
  label: string;
  options: Option[];
  placeholder: string;
  selectedValue: string | undefined; // Update the type to allow undefined
  handleChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  disabled?: boolean;
  isMultiSelect?: boolean;
  isMandatory?: boolean;
  name: string;
  className?: string;
  error?: string;
  labelClassName?:string;
  id?:string;
}

const LabelWithSelectField: React.FC<LabelWithSelectFieldProps> = ({
  label,
  options,
  placeholder,
  selectedValue,
  handleChange,
  disabled,
  isMultiSelect,
  isMandatory,
  name,
  labelClassName,
  className,
  error,
  id,
}) => {

  return (
    <div>
      <LabelField
        title = {label}
        className={labelClassName}
        isMandatory={isMandatory}
        htmlfor={id}
      />
      <MultiSelectField
        className={className}
        name={name}
        selectedValue={selectedValue } 
        handleChange={handleChange} 
        disabled={disabled}
        isMultiSelect={isMultiSelect}
        placeholder={placeholder}
        options={options}
        error={error}
      />
    </div>
  );
};

export default LabelWithSelectField;
