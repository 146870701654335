import { t } from "components/CentralDataMangement/translation/Translation";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import { OptionProps } from "components/common/utlis/TypeAnnotations";
import React from "react";
import MultiSelectAtom from "./MultiSelectAtom";
import Calender from "../molecules/Calender";
import AutoCompleteDropdown, { FilterProps } from "./AutoCompleteDropdown";

interface SearchFieldsProps {
  searchData: any;
  filters: Array<{
    label?: string;
    name: string;
    options?: OptionProps[];
    fieldType: string;
    placeholder?: string;
    isMulti?: boolean;
    isTranslate?: boolean;
    fieldPrefix?: string;
    type?: string;
    filterType?: string;
    autoComplete?: string;
    group?: string;
    prefixClass?: string;

    /* autocomplete-dropdown props, make sure to use necessary props
    to implement autocomplete without any issues */
    microservice?: string;
    model?: string;
    column?: string;
    filter?: boolean;
    filterInfo?: FilterProps[];
    concat?: string;
    valueColumn?: string;
    notNullColumn?: string;
    customFetch?: boolean;
    shouldCreateNew?: boolean;
    clearString?: boolean;
  }>;
  handleSearchChange?: (action: any) => void;
  handleSelectChange?: (action: any, fieldName: string) => void;
  handleAutoSelectChange?: (action: any, fieldName: string) => void;
  handleDateChange?: (date: Date | null, fieldName: string) => void;
}

const SearchFields: React.FC<SearchFieldsProps> = ({
  searchData,
  filters,
  handleAutoSelectChange,
  handleSearchChange,
  handleSelectChange,
  handleDateChange,
}) => {
  // Function to render filter element based on fieldType
  const renderFilter = (filter: SearchFieldsProps["filters"][0]) => {
    switch (filter.fieldType) {
      case "multiSelect":
        return (
          <MultiSelectAtom
            onChange={(selectedOption) =>
              handleSelectChange &&
              handleSelectChange(selectedOption, filter.name)
            }
            name={filter.name}
            options={filter.options ?? []}
            value={searchData[filter.name]}
          />
        );
      case "singleSelect":
        return (
          <SelectWithSearch
            search={true}
            options={filter.options ?? []}
            title={t(filter.label)}
            onChange={(selectedOption) =>
              handleSelectChange &&
              handleSelectChange(selectedOption, filter.name)
            }
            isMulti={filter.isMulti ?? false}
            name={filter.name}
            value={searchData[filter.name] ?? null} // Access the dynamic name
            isTranslate={filter.isTranslate ?? false}
            placeHolder={filter.placeholder ?? ""}
            prefixClassName="CustomSelectWidth"
          />
        );
      case "autoSearchDropdown":
        return (
          <AutoCompleteDropdown
            label={t(filter.label)}
            placeholder={t(filter.placeholder)}
            microService={filter.microservice ?? ""}
            onChange={(selectedOption) =>
              handleAutoSelectChange &&
              handleAutoSelectChange(selectedOption, filter.name)
            }
            value={searchData[filter.name]}
            columnName={filter.column ?? ""}
            modelKey={filter.model ?? ""}
            concat={filter.concat}
            valueColumn={filter.valueColumn}
            notNullColumn={filter.notNullColumn}
            {...(filter.filter
              ? { filter: true, filterInfo: filter.filterInfo }
              : {})}
            isMultiSelect={filter.isMulti}
            customFetch={filter.customFetch}
            shouldCreateNew={filter.shouldCreateNew}
            clearString={filter.clearString}
          />
        );
      case "text":
        return (
          <LabelWithInputField
            name={filter.name}
            handleChange={handleSearchChange}
            value={searchData[filter.name] ?? ""} // Access the dynamic name
            id={filter.name}
            label={t(filter.label)}
            type={filter.type ?? "text"} // Input field type is text
            placeholder={t(filter.placeholder || "")} // Use placeholder if provided
            autoComplete={filter.autoComplete ?? ""}
          />
        );

      case "textWithPrefix":
        return (
          <div className="input-group position-relative prefixWrapper">
            <div className="position-absolute searchFieldPrefix">
              {filter.fieldPrefix}
            </div>
            <LabelWithInputField
              name={t(filter.name)}
              label={t(filter.label)}
              handleChange={handleSearchChange}
              value={searchData[filter.name] ?? ""} // Access the dynamic name
              id={filter.name}
              type={filter.type} // Input field type is text
              placeholder={t(filter.placeholder || "")} // Use placeholder if provided
              autoComplete={filter.autoComplete ?? ""}
            />
          </div>
        );

      case "date":
        return (
          <div className={`position-relative calendarWithoutLabel ${searchData[filter.name] ? 'date-selected' : ''}`}>
            <Calender
              onChange={
                (date) =>
                  handleDateChange && handleDateChange(date, filter.name) // Check if handleDateChange is defined
              }
              placeHolder={filter.placeholder}
              selectedDate={searchData[filter.name] ?? null}
              label={t(filter.label)}
              isMandatory={false}
              name={filter.name}
            />
          </div>
        );

      // You can add more cases for different input types here if needed
      default:
        return null; // For unsupported types, return nothing
    }
  };

  let renderedGroups = new Set<string>(); // To track which groups have already been rendered

  return (
    <>
      {filters
        .filter((filter) => filter.filterType === "search") // Filter for filterType "search"
        .map((filter) => {
          // If the filter is part of a group, render the group in one <th>
          if (filter.group && !renderedGroups.has(filter.group)) {
            renderedGroups.add(filter.group); // Mark the group as rendered
            const groupFilters = filters.filter(
              (f) => f.group === filter.group
            );

            return (
              <th key={filter.group} className="searchFields border-0">
                <div className="d-flex align-items-center">
                  {groupFilters.map((groupFilter) => (
                    <div key={groupFilter.name} className="filter-group">
                      {renderFilter(groupFilter)}
                    </div>
                  ))}
                </div>
              </th>
            );
          }

          // Render individual filters (those without a group or already rendered group filters)
          if (!filter.group) {
            return (
              <th key={filter.name} className="searchFields border-0">
                <div className="filter-individual ">{renderFilter(filter)}</div>
              </th>
            );
          }

          // Skip rendering for filters that are part of already rendered groups
          return null;
        })}
    </>
  );
};

export default SearchFields;
