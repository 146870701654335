import React, { useState, useEffect } from "react";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import PhoneInput from "react-phone-number-input";
import LabelField from "components/common/atoms/LabelField";
import Button from "components/common/atoms/Button";
import { ApiCall } from "components/common/services/ApiServices";
import { Link, useNavigate, useParams } from "react-router-dom";
import RadioField from "components/common/atoms/RadioField";
import { GET_USER, GET_ROLES, UPDATE_USER } from "routes/ApiEndpoints";
import {
  validateForm,
  validateEmail,
  validateRequired,
  validateTextFieldAlpha,
  validatePhoneNumber,
  validateSelectField,
  validateDate,
  validateNumberField,
} from "components/common/services/ValidationService";
import CustomNotify from "components/common/atoms/CustomNotify";
import LoadingIcon from "components/common/utlis/LoadingIcon";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import { mapToSelect } from "components/common/utlis/MapToSelect";
import { Option, OptionProps } from "components/common/utlis/TypeAnnotations";
import Title from "components/common/atoms/Title";
import Calender from "components/common/molecules/Calender";
import { E164Number } from "libphonenumber-js";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";

interface EditAjUserFormProps {
  id?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  mobNumber?: E164Number | string | undefined;
  role?: Option | null;
  office?: Option | null;
  userStatus?: string;
  formStatus?: number;
  additionalOffice?: Option[];
  dateOfJoining?: Date | null;
  experience?: number | null;
  designation?: Option | null;
  amOromOffices?: Option[];
  preferredLanguages?: Option | null;
  brightId?: string | number | null;
}

interface ValidationRules {
  [key: string]: Function[];
}

const EditUserPage: React.FC<EditAjUserFormProps> = () => {
  const navigate = useNavigate();

  const initialErrors: { [key: string]: string } = {};
  const [errors, setErrors] = useState<{ [key: string]: string }>(
    initialErrors
  );
  const [rolesList, setRolesList] = useState<OptionProps[]>([]);
  const [officeList, setOfficeList] = useState<OptionProps[]>([]);
  const [preferredLanguagesList, setPreferredLanguagesList] = useState<
    OptionProps[]
  >([]);
  const [designations, setDesignations] = useState<OptionProps[]>([]);
  const { id } = useParams<{ id: string }>();
  const [roleVal, setRoleVal] = useState<Boolean>(false);
  const [loading, setLoading] = useState(false);
  const [recruiterId, setRecruiterId] = useState();
  const userAuth = useSelector(selectAuth);

  const [formData, setFormData] = useState<EditAjUserFormProps>({
    firstName: "",
    lastName: "",
    email: "",
    mobNumber: "" as E164Number,
    role: null,
    office: null,
    designation: null,
    userStatus: "",
    formStatus: 1,
    additionalOffice: [],
    dateOfJoining: null,
    experience: 0,
    amOromOffices: [],
    preferredLanguages: { value: 39, label: t("Dutch, Flemish") },
    brightId: "",
  });
  const [officeListForRoles, setOfficeListForRoles] = useState({
    AMOfficeList: [] as OptionProps[],
    OMOfficeList: [] as OptionProps[],
  });

  useEffect(() => {
    if (id) {
      const fetchUserDetails = async () => {
        const data = {
          template: "user",
          id: id,
        };
        const response = await ApiCall.service(GET_USER, "POST", data, true);
        if (response.status === 200) {
          setRoleVal(response.data.details["role"] ? true : false);
          const data = response.data.details;
          setFormData((prevData) => ({
            ...prevData,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            mobNumber: data.mobNumber,
            role: data.role,
            office: data.office,
            designation: data.designation,
            userStatus: data.userStatus,
            experience: data.experience,
            formStatus: 1,
            dateOfJoining: new Date(data.dateOfJoining),
            additionalOffice: data.additionalOffices,
            amOromOffices: data.amOrOmOffices,
            preferredLanguages: data.userPreferredLanguages,
            brightId: data.brightId,
          }));
          const roles = response.data["roles"];
          const offices = response.data["offices"];
          const filteredRoles = roles.filter(
            (role: any) =>
              role.unique_name !== "CANDIDATE" &&
              role.unique_name !== "INFANION_ADMIN"
          );
          const recruiterRole = filteredRoles.find(
            (role: any) => role.unique_name === "RECRUITER"
          );
          if (recruiterRole !== undefined) {
            setRecruiterId(recruiterRole.id);
          }
          // Convert the data array into the optionList format
          const role = mapToSelect(filteredRoles);
          const office = mapToSelect(offices);

          // Update the state with the new optionList
          setRolesList(role);
          setOfficeList(office);
          setDesignations(response.data["designations"]);
          setPreferredLanguagesList(
            mapToSelect(response.data["preferredLanguages"])
          );
          setOfficeListForRoles((prev) => ({
            ...prev,
            AMOfficeList: mapToSelect(response.data["officesWithoutAM"]),
            OMOfficeList: mapToSelect(response.data["officesWithoutOM"]),
          }));
        }
      };
      fetchUserDetails();
    }
  }, [id]);

  const validation = (
    name: string,
    value: string | boolean,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      firstName: [validateRequired, validateTextFieldAlpha],
      lastName: [validateRequired, validateTextFieldAlpha],
      email: [validateRequired, validateEmail],
      mobNumber: [validatePhoneNumber],
      office: [validateSelectField],
      role: !formData.role ? [] : [validateSelectField],
      dateOfJoining: [validateDate],
    };

    if (formData.role?.value === recruiterId) {
      validationRules.designation = [validateSelectField];
    }

    const validationErrors = validateForm(
      { ...formData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation && Object.keys(errors).length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }

    return true;
  };

  const changeHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target as HTMLInputElement;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    validation(name, value, true);
  };

  const handlePhoneNumberChange = (e: string | E164Number | undefined) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      mobNumber: e || "",
    }));
    validation("mobNumber", e || "", true);
  };

  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    if (fieldName === "role") {
      formData.designation = null;
      setErrors((prevErrors) => ({
        ...prevErrors,
        ["designation"]: "",
      }));
      setFormData((prevData) => ({
        ...prevData,
        amOromOffices: [],
        office: null,
      }));
    }
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: selectedOption,
    }));
    validation(fieldName, selectedOption, true);
  };

  const handleDateChange = (date: Date, fieldName: string) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: date,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    setLoading(true);
    e.preventDefault();
    const { name, value } = e.target as HTMLInputElement;

    const data = {
      id: id,
      ...formData,
    };

    if (validation(name, value)) {
      const response = await ApiCall.service(UPDATE_USER, "POST", data);
      if (response.status === 200) {
        navigate("/manage-users");
        CustomNotify({ type: "success", message: response.msg });
      } else if (response.status === 400) {
        setErrors({
          email: response.errors.email,
          mobNumber: response.errors.phone_number,
        });
      }
    }
    setLoading(false);
  };

  const filterDesignations = () => {
    const roleId = formData.role?.value;
    const filteredData = designations.filter(
      (item: any) => item.role_id === roleId
    );
    return mapToSelect(filteredData);
  };

  // To get the default offices list
  const getDefaultOffices = () => {
    const roleId = formData.role?.value;
    const filterFunction = (officeList: OptionProps[]) =>
      officeList.filter(
        (item) =>
          !formData.additionalOffice?.some(
            (office) => office.value === item.value
          ) &&
          !formData.amOromOffices?.some((office) => office.value === item.value)
      );
    if (roleId === 5) {
      return filterFunction(officeListForRoles.AMOfficeList);
    } else if (roleId === 6) {
      return filterFunction(officeListForRoles.OMOfficeList);
    } else {
      return filterFunction(officeList);
    }
  };

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Users",
          update: true,
        },
      ]}
      renderNoAccess={true}
    >
      <Title title={t("Edit user")} />
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12">
            <div className="form-border">
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <LabelWithInputField
                    isMandatory={true}
                    name="firstName"
                    handleChange={changeHandler}
                    value={formData.firstName}
                    id="fname"
                    label={t("First name")}
                    placeholder={t("First name")}
                    error={errors.firstName}
                  />
                </div>
                <div className="col-sm-12 col-md-6">
                  <LabelWithInputField
                    isMandatory={true}
                    name="lastName"
                    handleChange={changeHandler}
                    value={formData.lastName}
                    id="lname"
                    label={t("Last name")}
                    placeholder={t("Last name")}
                    error={errors.lastName}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <LabelWithInputField
                    isMandatory={true}
                    name="email"
                    handleChange={changeHandler}
                    value={formData.email}
                    id="email"
                    label={t("Email")}
                    placeholder={t("Email")}
                    type="email"
                    error={errors.email}
                  />
                </div>
                <div className="col-sm-12 col-md-6">
                  <LabelField
                    title={t("Phone number")}
                    isMandatory={true}
                    key="PhoneInput"
                  />
                  <div
                    className="mb-3 form-control field-shadow d-flex"
                    style={{ borderRadius: "0.5vw" }}
                  >
                    <PhoneInput
                      defaultCountry="BE" //Belgium country code
                      placeholder={t("Enter phone number")}
                      value={
                        formData.mobNumber !== undefined
                          ? (formData.mobNumber as E164Number)
                          : ("" as E164Number)
                      }
                      onChange={handlePhoneNumberChange}
                      className="w-100"
                      international
                    />
                  </div>
                  {errors.mobNumber && (
                    <span className="text-danger">{errors.mobNumber}</span>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-6 position-relative">
                  <Calender
                    isMandatory={true}
                    label={t("Date of joining")}
                    name="dateOfJoining"
                    minDate={new Date(2011, 0, 1)}
                    selectedDate={formData.dateOfJoining}
                    onChange={(date) =>
                      handleDateChange(date || new Date(), "dateOfJoining")
                    }
                    error={errors.dateOfJoining}
                  />
                </div>
                <div className="col-sm-12 col-md-6">
                  <LabelWithInputField
                    type="number"
                    label={t("Experience")}
                    name="experience"
                    step={0.1}
                    min={0}
                    max={100}
                    handleChange={changeHandler}
                    value={formData.experience}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <SelectWithSearch
                    title={t("Role")}
                    isMandatory={true}
                    search={true}
                    options={rolesList}
                    placeHolder={t("Select role")}
                    onChange={(e) => handleSelectChange(e, "role")}
                    isMulti={false}
                    className="select-field"
                    name="role"
                    value={formData.role}
                    error={errors.role}
                    isTranslate={true}
                    isDisabled={
                      userAuth.role !== "ADMIN" &&
                      userAuth.role !== "SUPER_ADMIN" &&
                      userAuth.role !== "C_LEVEL"
                    }
                  />
                </div>
                <div className="col-sm-12 col-md-6">
                  <SelectWithSearch
                    title={t("Designation")}
                    search={true}
                    options={filterDesignations()}
                    onChange={(e) => handleSelectChange(e, "designation")}
                    isMandatory={formData.role?.value === recruiterId}
                    isDisabled={
                      userAuth.role !== "ADMIN" &&
                      userAuth.role !== "SUPER_ADMIN" &&
                      userAuth.role !== "C_LEVEL"
                    }
                    isMulti={false}
                    name="designation"
                    value={formData.designation}
                    error={errors.designation}
                    isTranslate={true}
                  />
                </div>
                <div className="col-sm-12 col-md-6">
                  <SelectWithSearch
                    title={t("Default office")}
                    isMandatory={true}
                    search={true}
                    options={getDefaultOffices()}
                    placeHolder={t("Select")}
                    onChange={(e) => handleSelectChange(e, "office")}
                    isMulti={false}
                    className="select-field"
                    name="office"
                    value={formData.office}
                    error={errors.office}
                    isTranslate={true}
                  />
                </div>
                <div className="col-sm-12 col-md-6">
                  <SelectWithSearch
                    title={t("Additional office")}
                    isMandatory={false}
                    search={true}
                    options={officeList.filter(
                      (item) =>
                        item.value !== formData.office?.value &&
                        !(
                          formData.amOromOffices &&
                          formData.amOromOffices.some(
                            (office) => office.value === item.value
                          )
                        )
                    )}
                    placeHolder={t("Select")}
                    onChange={(e) => handleSelectChange(e, "additionalOffice")}
                    isMulti={true}
                    className=" select-field"
                    name="additionalOffice"
                    value={formData.additionalOffice}
                    isTranslate={true}
                  />
                </div>
                {formData.role && formData.role.value === 5 && (
                  <div className="col-sm-12 col-md-6">
                    <SelectWithSearch
                      title={t("AM offices")}
                      isMandatory={false}
                      search={true}
                      options={officeList.filter(
                        (item) =>
                          item.value !== formData.office?.value &&
                          !(
                            formData.additionalOffice &&
                            formData.additionalOffice.some(
                              (office) => office.value === item.value
                            )
                          ) &&
                          officeListForRoles.AMOfficeList &&
                          officeListForRoles.AMOfficeList.some(
                            (office) => office.value === item.value
                          )
                      )}
                      placeHolder={t("Select office")}
                      onChange={(e) => handleSelectChange(e, "amOromOffices")}
                      isMulti={true}
                      name="amOromOffices"
                      value={formData.amOromOffices}
                      isTranslate={true}
                    />
                  </div>
                )}
                {formData.role && formData.role.value === 6 && (
                  <div className="col-sm-12 col-md-6">
                    <SelectWithSearch
                      title={t("OM offices")}
                      isMandatory={false}
                      search={true}
                      options={officeList.filter(
                        (item) =>
                          item.value !== formData.office?.value &&
                          !(
                            formData.additionalOffice &&
                            formData.additionalOffice.some(
                              (office) => office.value === item.value
                            )
                          )
                      )}
                      placeHolder={t("Select")}
                      onChange={(e) => handleSelectChange(e, "amOromOffices")}
                      isMulti={true}
                      className=" select-field"
                      name="amOromOffices"
                      value={formData.amOromOffices}
                      isTranslate={true}
                    />
                  </div>
                )}
                <div className="col-sm-12 col-md-6">
                  <SelectWithSearch
                    title={t("Preferred languages")}
                    isMandatory={false}
                    search={true}
                    options={preferredLanguagesList}
                    placeHolder={t("Select")}
                    onChange={(e) =>
                      handleSelectChange(e, "preferredLanguages")
                    }
                    isMulti={false}
                    className=" select-field"
                    name="preferredLanguages"
                    value={formData.preferredLanguages}
                  />
                </div>
                <div className="col-sm-12 col-md-6">
                  <LabelField
                    title={t("Status")}
                    isMandatory={true}
                    key="PhoneInput"
                  />
                  <div className="row pb-3">
                    <div className="col-md-12">
                      <RadioField
                        name="userStatus"
                        value="1"
                        {...(formData.userStatus === "0"
                          ? { disabled: true }
                          : {})}
                        ischecked={formData.userStatus === "1"}
                        handleChange={changeHandler}
                        label={t("Active")}
                        className="mx-3"
                      />
                      <RadioField
                        name="userStatus"
                        value="2"
                        {...(formData.userStatus === "0"
                          ? { disabled: true }
                          : {})}
                        ischecked={
                          formData.userStatus === "2" ||
                          formData.userStatus === "0"
                        }
                        handleChange={changeHandler}
                        label={t("Inactive")}
                      />
                    </div>
                  </div>
                </div>
                {userAuth.email === "absolute-jobs-team@infanion.com" && (
                  <div className="col-sm-12 col-md-6">
                    <LabelWithInputField
                      isMandatory={false}
                      name="brightId"
                      handleChange={changeHandler}
                      value={formData.brightId}
                      id="brightId"
                      label={t("Bright ID")}
                      placeholder={t("Bright ID")}
                      type="number"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row backPadding">
          <div className="col-6 align-self-center">
            <Link
              to="/manage-users"
              className="back-btn text-decoration-underline"
            >
              {t("Back")}
            </Link>
          </div>
          <div className="col-6 text-end">
            {!loading ? (
              <Button
                title={t("Update")}
                type="submit"
                className="form-button shadow-none"
              />
            ) : (
              <LoadingIcon
                iconType="bars"
                color="#ff4dae"
                className="float-end"
              />
            )}
          </div>
        </div>
      </form>
    </AccessControl>
  );
};

export default translate(EditUserPage);
