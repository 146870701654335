import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { selectAuth, removeUserDetails } from "features/auth/AuthSlice";
import { useSelector, useDispatch } from "react-redux";
import Translate from "static/images/Translate";
import { ApiCall } from "../services/ApiServices";
import * as CONST from "Constants";
import {
  t,
  setLanguage,
  getLanguage,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import { Dropdown, Nav, Navbar } from "react-bootstrap";
import { FILE_URL, LOGOUT } from "routes/ApiEndpoints";
import secureLocalStorage from "react-secure-storage";
import { addUserDetails } from "features/auth/AuthSlice";
import { Button } from "primereact/button";
import { useLocation } from "react-router-dom";
import StartIcon from "static/images/StartIcon";
import StopIcon from "static/images/StopIcon";
import SalesNote from "components/CentralDataMangement/salesNote/SalesNoteModalPopups";
import { stopRecording } from "../utlis/HelperFunctions";
import { clearDestinationPath } from "features/NavigationSlice";

export interface DateInfoProps {
  type: string;
  value: number;
  label: string;
  year: number;
}

const Header: React.FC = () => {
  const userAuth = useSelector(selectAuth);

  const [languages, setLanguages] = useState<
    Array<{ id: number; key: string; name: string }>
  >([]);
  const [activeLanguage, setActiveLanguage] = useState(getLanguage());
  const getInitialDate = () => {
    const savedDate = localStorage.getItem("savedDate");
    return savedDate ? new Date(savedDate) : new Date();
  };
  const [date, setDate] = useState(getInitialDate);
  const location = useLocation();
  const isDashboard = location.pathname.includes("/dashboard");

  useEffect(() => {
    localStorage.setItem("savedDate", date.toISOString());
  }, [date]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchLanguages();
  }, []);

  const fetchLanguages = async () => {
    const response = await ApiCall.getService(
      "getAppLang/absolute_jobs",
      "GET",
      CONST.CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );
    if (response.status === 200) {
      setLanguages(response.app_langs);
    }
  };

  const handleLangChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedLang = e.target.value;
    setLanguage(selectedLang); // Call the language change callback
    setActiveLanguage(selectedLang); //it will not work because of re-render
    secureLocalStorage.setItem("site_lang", selectedLang);
  };

  const logOutClick = async () => {
    const lastActivityTime =
      secureLocalStorage.getItem("lastActivityTime") || Date.now().toString();
    const data = {
      userId: userAuth.userId,
      lastActivityTime,

    };
    if (authUser.isRecording) {
      await stopRecording(authUser.userId, setSalesNote);
    }
    await ApiCall.service(LOGOUT, "POST", data, true);

    secureLocalStorage.clear();
    dispatch(removeUserDetails());
    dispatch(clearDestinationPath());
    navigate("/login");
  };

  const getStartOfWeek = (date: Date): Date => {
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is Sunday
    return new Date(date.setDate(diff));
  };

  const incrementWeek = (date: Date): Date => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() + 7);
  };

  const decrementWeek = (date: Date): Date => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7);
  };

  const getWeekNumber = (date: Date): number => {
    const startDate = new Date(date.getFullYear(), 0, 1);
    const days = Math.floor(
      (date.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000)
    );
    return Math.ceil((date.getDay() + 1 + days) / 7);
  };

  const setToCurrentDate = (selected: string) => {
    const currentDate = new Date();
    setDate(new Date(currentDate));
    if (selected === "month") {
      const user = {
        dateInfo: {
          type: "month",
          value: currentDate.getMonth() + 1,
          label:
            currentDate.toLocaleString("default", { month: "long" }) +
            " " +
            currentDate.getFullYear(),
          year: currentDate.getFullYear(),
        },
      };
      dispatch(addUserDetails(user));
    } else if (selected === "year") {
      const user = {
        dateInfo: {
          type: "year",
          value: currentDate.getFullYear(),
          label: currentDate.getFullYear(),
          year: currentDate.getFullYear(),
        },
      };
      dispatch(addUserDetails(user));
    } else if (selected === "week") {
      const startOfWeek = getStartOfWeek(currentDate);
      const weekNumber = getWeekNumber(startOfWeek);
      const user = {
        dateInfo: {
          type: "week",
          value: weekNumber,
          label: `Week ${weekNumber} of ${startOfWeek.getFullYear()}`,
          year: startOfWeek.getFullYear(),
        },
      };
      dispatch(addUserDetails(user));
    }
  };

  const increment = () => {
    let newDate;
    if (userAuth.dateInfo.type === "year") {
      newDate = new Date(date.getFullYear() + 1, date.getMonth(), 1);
    } else if (userAuth.dateInfo.type === "month") {
      newDate = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    } else if (userAuth.dateInfo.type === "week") {
      newDate = incrementWeek(date);
    } else {
      // This case should not happen if userAuth.dateInfo.type is correctly managed.
      newDate = date;
    }
    setDate(newDate);

    const user = {
      dateInfo: {
        type: userAuth.dateInfo.type,
        value:
          userAuth.dateInfo.type === "year"
            ? newDate.getFullYear()
            : userAuth.dateInfo.type === "month"
              ? newDate.getMonth() + 1
              : getWeekNumber(newDate), // for week
        label:
          userAuth.dateInfo.type === "year"
            ? newDate.getFullYear().toString()
            : userAuth.dateInfo.type === "month"
              ? newDate.toLocaleString("default", { month: "long" }) +
              " " +
              newDate.getFullYear()
              : `Week ${getWeekNumber(newDate)} of ${newDate.getFullYear()}`, // for week
        year: newDate.getFullYear(),
      },
    };
    dispatch(addUserDetails(user));
  };

  const decrement = () => {
    let newDate;
    if (userAuth.dateInfo.type === "year") {
      newDate = new Date(date.getFullYear() - 1, date.getMonth(), 1);
    } else if (userAuth.dateInfo.type === "month") {
      newDate = new Date(date.getFullYear(), date.getMonth() - 1, 1);
    } else if (userAuth.dateInfo.type === "week") {
      newDate = decrementWeek(date);
    } else {
      // This case should not happen if userAuth.dateInfo.type is correctly managed.
      newDate = date;
    }
    setDate(newDate);

    const user = {
      dateInfo: {
        type: userAuth.dateInfo.type,
        value:
          userAuth.dateInfo.type === "year"
            ? newDate.getFullYear()
            : userAuth.dateInfo.type === "month"
              ? newDate.getMonth() + 1
              : getWeekNumber(newDate), // for week
        label:
          userAuth.dateInfo.type === "year"
            ? newDate.getFullYear().toString()
            : userAuth.dateInfo.type === "month"
              ? newDate.toLocaleString("default", { month: "long" }) +
              " " +
              newDate.getFullYear()
              : `Week ${getWeekNumber(newDate)} of ${newDate.getFullYear()}`, // for week
        year: newDate.getFullYear(),
      },
    };
    dispatch(addUserDetails(user));
  };

  // Sales note
  const authUser = useSelector(selectAuth);
  const [salesnote, setSalesNote] = useState({
    name: '',
    phone: '' as string | undefined,
    id: null as number | null,
    navigate: '',
    isPlay: false as boolean,
    triggerMeeting: false as boolean,
    type: null as number | null,
    isMeetingTitle: false
  });

  const isRecording = authUser.isRecording;
  useEffect(() => {
    setSalesNote((prev) => ({
      ...prev,
      isPlay: isRecording,
    }));
  }, [isRecording])

  const handleMeeting = (name: string, phone: string, id: number | null) => {
    setSalesNote({
      name: name,
      phone: phone,
      id: id,
      navigate: '',
      isPlay: true,
      triggerMeeting: true,
      type: 4, // For candidate type is 1 , 4 is doing in header not using play button or 3cx
      isMeetingTitle: true,
    });
  }

  const stoprecording = async () => {
    await stopRecording(authUser.userId, setSalesNote);
    const recording = {
      isRecording: false
    }
    dispatch(addUserDetails(recording))
  }

  const updateSalesNote = (updatedSalesNote: any) => {
    setSalesNote(updatedSalesNote);
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <Navbar collapseOnSelect expand="lg" style={{ paddingTop: "0.25vw", paddingBottom: "0.25vw" }}>
          <>
            {/* <div className="d-block d-lg-none">
              <Navbar.Brand href="/">
                <img
                  src="/static/images/logo.svg"
                  alt={t("logo")}
                  className="img-fluid"
                />
              </Navbar.Brand>
            </div> */}
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          </>
          <Navbar.Collapse id="responsive-navbar-nav">

            <Nav className="ms-auto">
              <div className="table-action-icons" style={{ paddingTop: "0.1vw" }}>
                {salesnote.isPlay == true ? (
                  <>
                    <span
                      className="cursor-pointer me-2"
                      onClick={() => stoprecording()}
                      title={t("Stop")}>
                      <StopIcon />
                    </span>
                  </>
                ) : (
                  <span
                    className="me-2 cursor-pointer"
                    onClick={() => handleMeeting(authUser.name, authUser.phone, null)}
                    title={t("Start")}>
                    <StartIcon />
                  </span>
                )}
              </div>
              {isDashboard && (
                <Dropdown className="this-week-section ms-5">
                  <Button
                    icon="pi pi-chevron-left"
                    className="monthPrevIcon dashboardCurrentCalendarIcon"
                    onClick={decrement}
                  />
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    className="this-week-link d-flex align-items-center"
                  >
                    {userAuth.dateInfo.label}
                  </Dropdown.Toggle>
                  <Button
                    icon="pi pi-chevron-right"
                    className="monthNextIcon dashboardCurrentCalendarIcon"
                    onClick={increment}
                  />

                  <Dropdown.Menu className="profile-dropdown-links">
                    <Dropdown.Item
                      onClick={() => {
                        setToCurrentDate("week");
                      }}
                    >
                      {t("Current week")}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setToCurrentDate("month");
                      }}
                    >
                      {t("Current month")}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setToCurrentDate("year");
                      }}
                    >
                      {t("Current year")}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
              <Nav>
                <div className=" d-flex align-items-center mb-3 mb-lg-0" style={{ marginRight: "1vw" }}>
                  <div className="d-block d-lg-none me-4">
                    <Translate />
                  </div>
                  <select
                    id="lang-select"
                    className="border cursor-pointer bg-transparent lang-options"
                    onChange={handleLangChange}
                    value={activeLanguage}
                  >
                    {languages &&
                      languages.map((language) => (
                        <option key={language.id} value={language.key}>
                          {language.key}
                        </option>
                      ))}
                  </select>
                </div>
                {/* <div className="pe-0 position-relative mb-3 mb-lg-0 me-lg-3">
                    <div className="d-flex align-items-center notification-section h-100">
                      <Notification />
                      <div className="d-block d-lg-none ms-4 color-dark-pink">
                        {t("Notification")}
                      </div>
                    </div>
                  </div> */}
              </Nav>
            </Nav>
          </Navbar.Collapse>

          <div className="d-none d-lg-block pe-0 position-relative header-profile-pic">
            <Dropdown>
              <Dropdown.Toggle
                className="bg-transparent border-0 p-0"
                id="dropdown-basic"
              >
                {userAuth.profilePicId !== null ? (
                  <img
                    src={`${FILE_URL}/${userAuth.profilePicPath}`}
                    alt="default text"
                    className="object-fit-cover updated-profile"
                    width={"60px"}
                    height={"60px"}
                  />
                ) : (
                  <div className="name-circle d-flex align-items-center justify-content-center text-white">
                    {userAuth.profilePicName}
                  </div>
                )}
              </Dropdown.Toggle>

              <Dropdown.Menu className="profile-dropdown-links">
                <Dropdown.Item
                  href={
                    userAuth.role === "CANDIDATE"
                      ? `/candidate/edit/${userAuth.candidateId}`
                      : "/profile/page"
                  }
                >
                  {t("My profile")}
                </Dropdown.Item>
                <Dropdown.Item href="/add/signature" className="text-wrap">
                  {t("Signature")}
                </Dropdown.Item>
                <Dropdown.Item onClick={logOutClick}>
                  {t("Logout")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Navbar>
      </div>

      {/* Sales note */}
      <SalesNote salesnote={salesnote} setSalesNote={updateSalesNote} />
    </div>
  );
};

export default translate(Header);
