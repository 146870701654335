import React from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  CONF_SETTINGS_EDIT,
  CONF_SETTINGS_ADD,
  CONF_SETTINGS_UPDATE,
} from "routes/ApiEndpoints";
import FormBuilder from "components/common/services/form/FormBuilder";
import {
  validateRequired,
  validateSelectField,
} from "components/common/services/ValidationService";
import { t, translate } from "components/CentralDataMangement/translation/Translation";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";

interface MUserCreationFormProps {
  id?: number | string;
}

const EmployeeType: React.FC<MUserCreationFormProps> = ({ id }) => {
  const location = useLocation();
  const path = location.pathname;
  const { id: routeId } = useParams<{ id: string }>();
  const FormValidation = {
    employeeType: [validateRequired],
    empCategory: [validateSelectField],
  };

  const permissionType = id ? "update" : "create";
  const permissionObject: any = {
    permission: "Employee type",
  };
  permissionObject[permissionType] = true;
  return (
    <AccessControl
      requiredPermissions={[permissionObject]}
      renderNoAccess={true}
    >
      <FormBuilder
        microService="central-data-management"
        actionType={path === "/add/employee-type" ? "create" : "edit"} // action type create or edit
        entryId={id || routeId} // required only if actionType is edit
        getDataAPI={CONF_SETTINGS_EDIT} //required only if actionType is edit
        SaveDataAPI={
          path === "/add/employee-type"
            ? CONF_SETTINGS_ADD
            : CONF_SETTINGS_UPDATE
        } //Post's data to this endpoint
        formName={"EmployeeType"} //Form name
        title={
          path === "/add/employee-type"
            ? t("Add employee type")
            : t("Edit employee type")
        } // Form title
        redirect={-1} // After submit redirect to this screen/route
        validationRules={FormValidation} //Validation rules to validate form on submit
        type="employeeType"
      />
    </AccessControl>
  );
};

export default translate(EmployeeType);
