import React from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useRef } from "react";
import "static/css/calender.css";
import CalendarIcon from "static/images/CalendarIcon";

interface DatePickerComponentProps {
  onDateChange: (date: Date | null, name: string) => void;
  selectedDate: Date | null;
  name?: string;
  minDate?: Date | null;
  maxDate?: Date | null;
  title?: string;
}

const CalenderWithoutInputField: React.FC<DatePickerComponentProps> = ({
  onDateChange,
  selectedDate,
  name = "",
  minDate = null,
  maxDate = null,
  title = "",
}) => {
  const datePickerRef = useRef<ReactDatePicker | null>(null);

  const handleIconClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };

  return (
    <>
        <ReactDatePicker
          selected={selectedDate}
          onChange={(date) => onDateChange(date, name || "")}
          showMonthDropdown
          showYearDropdown
          minDate={minDate ? new Date(minDate.getTime()) : null}
          maxDate={maxDate ? new Date(maxDate.getTime()) : null}
          customInput={<div style={{border: "0px"}} className="inputWidthwithoutInput" />}
          className=""
          ref={datePickerRef}
          closeOnScroll={true}
          fixedHeight
          autoComplete="off"
          calendarStartDay={1}
          portalId="true"
          name={name}
        />
      <div className="input-group-append calender-icon calenderNoInputPosition table-action-icons">
        <span
          className="input-group-text bg-transparent border-0 p-0"
          onClick={handleIconClick}
          data-bs-toggle="tooltip"
          title={title}
        >
          <CalendarIcon />
        </span>
      </div>
    </>
  );
};

export default CalenderWithoutInputField;
