import React, { useState, useEffect } from "react";
import Search from "components/common/atoms/Search";
import Pagination from "components/common/atoms/Pagination";
import { Link } from "react-router-dom";
import EditIcon from "static/images/EditIcon";
import ViewIcon from "static/images/ViewIcon";
import { ApiCall } from "components/common/services/ApiServices";
import Button from "components/common/atoms/Button";
import { MANAGE_PAGE_CONTENT } from "routes/ApiEndpoints";
import Title from "components/common/atoms/Title";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import LinkTo from "components/common/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import NoRecords from "components/common/atoms/NoRecords";

const ITEMS_PER_PAGE = 10;

interface PageContents {
  id: number;
  name: string;
}

const ManagePageContents: React.FC = () => {
  const [searchData, setSearchData] = useState({
    name: "",
  });
  const [pageContents, setPageContents] = useState<PageContents[]>([]); // State to hold the fetched email templates

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchPageContents();
  }, []);

  const fetchPageContents = async () => {
    const response = await ApiCall.getService(
      MANAGE_PAGE_CONTENT,
      "GET",
      "central-data-management"
    );
    if (response.status === 200) {
      setPageContents(response.data);
    }
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSearchData({ ...searchData, [name]: value });
    setCurrentPage(1);
  };

  const filteredPageContents = pageContents.filter((content) =>
    content.name.toLowerCase().includes(searchData.name.toLowerCase())
  );

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const paginatedData = filteredPageContents.slice(startIndex, endIndex);

  return (
    <div>
      <Title title={t("Page contents")} />
      <div className="row search-bar paddingBottom1">
        <div className="col-8">
          <Search
            className="form-control"
            handleChange={handleSearchChange}
            placeholder={t("Search by name")}
            name="name"
            value={searchData.name}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="position-relative tableMainWrapper">
            <AccessControl
              requiredPermissions={[
                {
                  permission: "Page contents",
                  create: true,
                },
              ]}
            >
              <div className="row">
                <div className="ManageCreateBtn">
                  <LinkTo
                    pagelink="/page-contents"
                    title={t("Create page content")}
                    icon={faPlus}
                    className="me-3"
                  />
                </div>
              </div>
            </AccessControl>
            <div className="table-responsive tableSection paddingTop2">
              <table className="table table-hover">
                <thead>
                  <tr className="TableHeader">
                    <th className="ps-lg-4" style={{ width: "60%" }}>
                      {t("Name")}
                    </th>
                    <th>{t("Actions")}</th>
                  </tr>
                </thead>
                <tbody>
                  {paginatedData.length > 0 ? (
                    paginatedData.map((content, index) => (
                      <tr
                        key={content.id}
                        className="border-bottom mb-3 box-shadow align-middle"
                      >
                        <td className="text-break ps-lg-4" data-label="Name">
                          {t(`${content.name}`)}
                        </td>
                        <AccessControl
                          requiredPermissions={[
                            {
                              permission: "Page contents",
                              update: true,
                            },
                          ]}
                        >
                          <td className="table-action-icons px-2">
                            <div className="d-none d-md-none d-lg-block">
                              <Link
                                to={`/page-contents/${content.id}`}
                                className="btn p-0 border-0 me-2"
                                title={t("Edit")}
                              >
                                <EditIcon />
                              </Link>
                            </div>
                            <div className="d-block d-md-block d-lg-none">
                              <div className="mb-2">
                                <Link
                                  to={`/page-contents/${content.id}`}
                                  className="btn p-0 border-0 me-2 w-100"
                                  title={t("Edit")}
                                >
                                  <Button
                                    title={t("Edit")}
                                    className=" mb-2 button-width edit-btn rounded-3"
                                  />
                                </Link>
                              </div>
                            </div>
                          </td>
                        </AccessControl>
                      </tr>
                    ))
                  ) : (
                    <NoRecords headerLength={3} />
                  )}
                </tbody>
              </table>
              {Math.ceil(filteredPageContents.length / ITEMS_PER_PAGE) > 1 && (
                <div className="pagination justify-content-center align-items-center mt-4 px-4">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={Math.ceil(
                      filteredPageContents.length / ITEMS_PER_PAGE
                    )}
                    onPageChange={handlePageChange}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row backPadding">
        <div className="col-md-6 align-self-center py-4">
          <Link
            to="/config/settings"
            className="back-btn text-decoration-underline"
          >
            {t("Back")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default translate(ManagePageContents);
