import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import React, { useState } from "react";
import { useFormContext } from "../../context/Context";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import { useParams } from "react-router-dom";
import {
  GENERAL,
  SET_OPTIONS,
  UPDATE_FIELDS,
  UPDATE_FIELD_ERROR,
} from "../../annotation/VacancyConstants";
import Navigation from "../form-navigation/Navigation";
import {
  ADD_LOCATION,
  COMPANY_RELATED_DETAILS,
  GET_OFFICE_FOR_CONSULTANT,
  VACANCY_DETAILS,
} from "routes/ApiEndpoints";
import { ApiCall } from "components/common/services/ApiServices";
import { mapToSelect } from "components/common/utlis/MapToSelect";
import CustomNotify from "components/common/atoms/CustomNotify";
import {
  validateSelectField,
  validateForm,
  Option,
  validateRequired,
  scrollToTop,
} from "components/common/services/ValidationService";
import { ValidationRules } from "components/common/utlis/TypeAnnotations";
import {
  CENTRAL_DATA_MANAGEMENT_MICROSERVICE,
  COMPANY_MICROSERVICE,
} from "Constants";
import Button from "components/common/atoms/Button";
import ModalPopup from "components/common/atoms/ModalPopup";
import AddPlusIcon from "static/images/AddPlusIcon";
import AutoCompleteDropdown from "components/common/atoms/AutoCompleteDropdown";
import { FormatPCList } from "components/common/utlis/FormatPCList";
import CheckBoxField from "components/common/atoms/CheckBoxField";
import LabelField from "components/common/atoms/LabelField";
import RadioField from "components/common/atoms/RadioField";

interface Location {
  modalLocation: string;
  modalCity: Option | null;
  modalCountry: Option | null;
  modalStreet: string;
  modalNumber: string;
  modalBox: string;
  modalZipCode: string;
}

const VacancyGeneralDetails = () => {
  const { state, dispatch } = useFormContext();
  const { id } = useParams<{ id: string }>();

  //Modal popup for location
  const [modalState, setModalState] = useState<Location>({
    modalLocation: "",
    modalCity: null,
    modalCountry: null,
    modalStreet: "",
    modalNumber: "",
    modalBox: "",
    modalZipCode: "",
  });

  const [modalErrors, setModalErrors] = useState<{ [key: string]: string }>({
    modalLocation: "",
    modalCity: "",
    modalCountry: "",
  });

  const [showModal, setShowModal] = useState(false);

  const addLocation = () => {
    if (
      state.general.company?.value === null ||
      state.general.company?.value === undefined
    ) {
      CustomNotify({ type: "error", message: t("Please select company") });
    } else {
      setShowModal(true);
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    setModalStates();
  };

  const modalValidation = (
    name: string,
    value: string | boolean | Option | null | object[],
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      modalCity: [validateSelectField],
      modalCountry: [validateSelectField],
      modalLocation: [validateRequired],
    };

    const validationErrors = validateForm(
      { ...modalState, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation && Object.keys(modalErrors).length > 0) {
      setModalErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setModalErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }

    return true;
  };

  const setModalStates = () => {
    setModalState((prev) => ({
      ...prev,
      modalLocation: "",
      modalCity: null,
      modalCountry: null,
      modalStreet: "",
      modalNumber: "",
      modalBox: "",
      modalZipCode: "",
    }));
    setModalErrors((prev) => ({
      ...prev,
      modalLocation: "",
      modalCity: "",
      modalCountry: "",
    }));
  };

  const handleSubmitLocation = async (e: React.FormEvent) => {
    e.preventDefault();
    const formData = {
      company: state.general.company,
      location: modalState.modalLocation,
      city: modalState.modalCity,
      country: modalState.modalCountry,
      zipcode: modalState.modalZipCode,
      box: modalState.modalBox,
      number: modalState.modalNumber,
      street: modalState.modalStreet,
    };
    const { name, value } = e.target as HTMLInputElement;
    if (formData.company?.value !== null) {
      if (modalValidation(name, value)) {
        const url = ADD_LOCATION;
        const response = await ApiCall.service(
          url,
          "POST",
          formData,
          false,
          "company-creation"
        );
        if (response.status === 200) {
          setShowModal(false);
          CustomNotify({ type: "success", message: t(response.msg) });
          setModalStates();
          fetchCompanyDetails(state.general.company?.value);
        } else if (response.status === 409) {
          CustomNotify({ type: "error", message: t(response.msg) });
        }
      }
    }
  };

  const [validationStatus, setValidationStatus] = useState({
    isValid: false,
    type: "",
  });

  const validStatus = (validation: any) => {
    setValidationStatus({
      isValid: validation.isValid,
      type: validation.type,
    });
  };

  const fetchCompanyDetails = async (companyId: number | null | undefined) => {
    const data = {
      type: "company",
      companyId: companyId,
    };
    if (companyId) {
      const url = `${COMPANY_RELATED_DETAILS}`;
      const response = await ApiCall.service(
        url,
        "POST",
        data,
        false,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.status === 200) {
        const data = response.data.companyDetails;
        const dropdownOptions = {
          locationOptions: data?.locationOptions
            ? mapToSelect(data?.locationOptions)
            : [],
          pcOptions: FormatPCList(data?.pcOptions) || [],
          pc: data?.pcOptions,
          contactPersonOptions: data?.contactPersonOptions
            ? mapToSelect(data.contactPersonOptions)
            : [],
        };

        const fieldsToUpdate = [
          "sector",
          "domain",
          "pc",
          "function",
          "location",
        ];

        fieldsToUpdate.forEach((field: string) => {
          if (data[field]) {
            dispatch({
              type: UPDATE_FIELDS,
              template: GENERAL,
              field: field,
              value: data[field],
            });
          }
        });

        dispatch({ type: SET_OPTIONS, payload: dropdownOptions });
      }
    }
  };

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type } = event.target;
    if (type === "radio") {
      dispatch({
        type: UPDATE_FIELDS,
        template: GENERAL,
        field: name,
        value: parseInt(value),
      });
    } else if (name === "positions") {
      const newValue = value.replace(/[^0-9,]/g, "");
      dispatch({
        type: UPDATE_FIELDS,
        template: GENERAL,
        field: name,
        value: newValue,
      });
    } else if (name === "aliasName") {
      dispatch({
        type: UPDATE_FIELDS,
        template: GENERAL,
        field: name,
        value: value,
      });
      validation(name, value, true);
    } else if (name === "pushToWebsite" || name === "pushToVDAB") {
      const checked = (event.target as HTMLInputElement).checked;
      dispatch({
        type: UPDATE_FIELDS,
        template: GENERAL,
        field: name,
        value: checked ? 1 : 0,
      });
      if (name === "pushToVDAB") {
        const fieldsToUpdate = ["vdabTemplate", "vdabCompetences", "driverLicence"];
        fieldsToUpdate.forEach((field: string) => {
          dispatch({
            type: UPDATE_FIELD_ERROR,
            field: field,
            error: "",
          });
        });
      }
    } else {
      setModalState((prev) => ({
        ...prev,
        [name]: value,
      }));
      modalValidation(name, value, true);
    }
  };

  const fetchPrimaryOffice = async (consultantId: number) => {
    const data = {
      consultantId: consultantId,
    };
    const response = await ApiCall.service(
      GET_OFFICE_FOR_CONSULTANT,
      "POST",
      data,
      false,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );
    if (response.status === 200) {
      dispatch({
        type: UPDATE_FIELDS,
        template: GENERAL,
        field: "office",
        value: response.data["office"],
      });
      const dropdownOptions = {
        officeOptions: mapToSelect(response.data["officeOptions"] || []),
      };

      dispatch({ type: SET_OPTIONS, payload: dropdownOptions });
    }
  };

  const handleSelectChange = (selectedOption: any, name: string) => {
    let fieldsToReset: string[] = [];
    if (name === "modalCity" || name === "modalCountry") {
      setModalState((prev) => ({
        ...prev,
        [name]: selectedOption,
      }));
      modalValidation(name, selectedOption, true);
    } else if (name === "company") {
      fieldsToReset = [
        "location",
        "pc",
        "domain",
        "function",
        "empType",
        "sector",
      ];
      if (selectedOption?.label !== "Select") {
        fetchCompanyDetails(selectedOption?.value);
      }
    }
    // else if (name === "domain") {
    // fieldsToReset = ["function"];
    // }
    else if (name === "consultant") {
      fetchPrimaryOffice(selectedOption.value);
    }

    fieldsToReset.forEach((field) => {
      dispatch({
        type: UPDATE_FIELDS,
        template: GENERAL,
        field: field,
        value: null,
      });
    });

    dispatch({
      type: UPDATE_FIELDS,
      template: GENERAL,
      field: name,
      value: selectedOption,
    });
    validation(name, selectedOption, true);
  };

  const validation = (
    name: string,
    value:
      | string
      | boolean
      | Date
      | object[]
      | Option
      | null
      | undefined
      | number,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      company: [validateSelectField],
      location: [validateSelectField],
      function: [validateSelectField],
      domain: [validateSelectField],
      office: [validateSelectField],
      consultant: [validateSelectField],
      language: [validateSelectField],
      aliasName: [validateRequired],
    };

    const validationErrors = validateForm(
      { ...state.general, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    dispatch({
      type: UPDATE_FIELD_ERROR,
      field: name,
      error: validationErrors[name],
    });

    const isFieldValid = Object.keys(validationErrors).length === 0;

    if (isFieldValid) {
      dispatch({ type: UPDATE_FIELD_ERROR, field: name, error: "" });
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    scrollToTop();
  };

  // const getFunctionOptions = () => {
  // const domain = state.general.domain;
  // if (domain?.value) {
  //   //function related to domain
  //   const linkedIds = state.options.functionDomainLinking
  //     .filter((linking) => linking.domain_id === domain?.value)
  //     .map((linking) => linking.function_id);

  //   const filteredOptions = state.options.functionOptions.filter((option) =>
  //     linkedIds.includes(option.id)
  //   );
  //   return mapToSelect(filteredOptions, "function_name");
  // }
  // when pc and domain are not selected show all the functions
  // return mapToSelect(state.options.functionOptions, "function_name") || [];

  // };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12">
            <div className="form-border" style={{ paddingTop: "3.4vw" }}>
              <div className="row">
                {/* <div className="col-sm-12 col-md-12 col-lg-6"> */}
                {/* <SelectWithSearch
                title={t("Company")}
                name="company"
                placeHolder={t("Select company")}
                isMandatory={true}
                search={true}
                options={state.options.companyOptions}
                value={state.general.company}
                onChange={(e) => handleSelectChange(e, "company")}
                isMulti={false}
                className="select-field"
                error={state.errors.company}
                id="company"
              /> */}
                <div className="col-sm-12 col-md-6 col-lg-4 autoCompleteFunction">
                  <AutoCompleteDropdown
                    label={t("Company")}
                    placeholder={t("Type to search") + "..."}
                    microService={COMPANY_MICROSERVICE}
                    onChange={(e) => handleSelectChange(e, "company")}
                    value={state.general.company}
                    columnName="company_name"
                    modelKey="company"
                    id="company"
                    isMandatory={true}
                    name="company"
                    filter={true}
                    filterInfo={[
                      {
                        column: "company_status",
                        value: 1,
                        condition: "where",
                      },
                      {
                        column: "company_status",
                        value: 0,
                        condition: "orWhere",
                      },
                    ]}
                    notNullColumn="bbright_id"
                  />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <SelectWithSearch
                    title={t("Contact person")}
                    name="contactPerson"
                    id="contactPerson"
                    placeHolder={t("Select contact Person")}
                    isMandatory={false}
                    search={true}
                    options={state.options.contactPersonOptions}
                    value={state.general.contactPerson}
                    onChange={(e) => handleSelectChange(e, "contactPerson")}
                    isMulti={false}
                    className="select-field"
                  />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-3">
                  <SelectWithSearch
                    title={t("Location")}
                    name="location"
                    id="location"
                    placeHolder={t("Select location")}
                    isMandatory={true}
                    search={true}
                    options={state.options.locationOptions}
                    value={state.general.location}
                    onChange={(e) => handleSelectChange(e, "location")}
                    isMulti={false}
                    className="select-field"
                    error={state.errors.location}
                  />
                </div>
                <div className="col-md-6 col-lg-1">
                  <div className="d-none d-lg-block table-action-icons mt-35">
                    <Button
                      handleClick={addLocation}
                      toolTipName={t("Add location")}
                      className="border-0 bg-transparent shadow-none"
                    >
                      <AddPlusIcon />
                    </Button>
                  </div>
                  <div className="d-block d-lg-none">
                    <Button
                      handleClick={addLocation}
                      className="btn edit-btn float-end rounded-3 shadow-none addLocationBtn"
                    >
                      {t("Add location")}
                    </Button>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <SelectWithSearch
                    title={t("Consultant")}
                    name="consultant"
                    id="consultant"
                    placeHolder={t("Select consultant")}
                    isMandatory={true}
                    search={true}
                    options={state.options.consultantOptions}
                    value={state.general.consultant}
                    onChange={(e) => handleSelectChange(e, "consultant")}
                    isMulti={false}
                    className="select-field"
                    error={state.errors.consultant}
                  />
                </div>
                <div className="col-sm-12  col-md-6 col-lg-4">
                  <SelectWithSearch
                    title={t("Office")}
                    name="office"
                    id="office"
                    placeHolder={t("Select office")}
                    isMandatory={true}
                    search={true}
                    options={state.options.officeOptions}
                    value={state.general.office}
                    onChange={(e) => handleSelectChange(e, "office")}
                    isMulti={false}
                    className="select-field"
                    error={state.errors.office}
                    isTranslate={true}
                  />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <SelectWithSearch
                    title={t("Language")}
                    name="language"
                    id="language"
                    placeHolder={t("Select language")}
                    isMandatory={true}
                    search={true}
                    options={state.options.languageOptions}
                    value={state.general.language}
                    onChange={(e) => handleSelectChange(e, "language")}
                    isMulti={false}
                    className="select-field"
                    error={state.errors.language}
                    isTranslate={true}
                  />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <SelectWithSearch
                    title={t("Paritair committee")}
                    name="pc"
                    placeHolder={t("Select paritair comite")}
                    search={true}
                    options={state.options.pcOptions}
                    value={state.general.pc}
                    onChange={(e) => handleSelectChange(e, "pc")}
                    isMulti={false}
                    isMandatory={false}
                    className="select-field"
                    error={state.errors.pc}
                    id="pc"
                    isTranslate={true}
                  />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <SelectWithSearch
                    title={t("Sector")}
                    name="sector"
                    id="sector"
                    placeHolder={t("Select sector")}
                    search={true}
                    options={state.options.sectorOptions}
                    value={state.general.sector}
                    onChange={(e) => handleSelectChange(e, "sector")}
                    isMulti={false}
                    className="select-field"
                    isTranslate={true}
                  />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <SelectWithSearch
                    title={t("Domain")}
                    name="domain"
                    id="domain"
                    placeHolder={t("Select domain")}
                    search={true}
                    isMandatory={true}
                    options={state.options.domainOptions}
                    value={state.general.domain}
                    onChange={(e) => handleSelectChange(e, "domain")}
                    isMulti={false}
                    className="select-field"
                    error={state.errors.domain}
                    isTranslate={true}
                  />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <SelectWithSearch
                    title={t("Function")}
                    name="function"
                    id="function"
                    placeHolder={t("Select function")}
                    isMandatory={true}
                    search={true}
                    options={state.options.functionOptions}
                    value={state.general.function}
                    onChange={(e) => handleSelectChange(e, "function")}
                    isMulti={false}
                    className="select-field"
                    error={state.errors.function}
                    isMenuPlacement={true}
                    isTranslate={true}
                  />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <LabelWithInputField
                    isMandatory={true}
                    name="aliasName"
                    handleChange={handleFieldChange}
                    value={state.general.aliasName}
                    id="aliasName"
                    label={t("Function alias name")}
                    type="text"
                    placeholder={t("Function alias name")}
                    error={state.errors.aliasName}
                  />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <LabelWithInputField
                    isMandatory={false}
                    name="positions"
                    handleChange={handleFieldChange}
                    value={state.general.positions}
                    id="positions"
                    label={t("Number of employees")}
                    type="text"
                    placeholder={t("Number of employees")}
                  />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                  <SelectWithSearch
                    title={t("Employee type")}
                    name="empType"
                    id="empType"
                    placeHolder={t("Select employee type")}
                    search={true}
                    options={state.options.employeeTypeOptions}
                    value={state.general.empType}
                    onChange={(e) => handleSelectChange(e, "empType")}
                    isMulti={false}
                    className="select-field"
                    isMenuPlacement={true}
                    isTranslate={true}
                  />
                </div>
                <div className="col-sm-12 col-lg-4 mb-4 mb-xl-0 col-md-6 mt-1">
                  <LabelField title={t("Vacancy status")} isMandatory={false} />
                  <br />
                  <div className="d-flex flex-column flex-xl-row mt-1">
                    <RadioField
                      name="isActive"
                      value={0}
                      disable={false}
                      ischecked={state.general.isActive === 0}
                      handleChange={handleFieldChange}
                      label={t("Open")}
                      className="marginRight1"
                    />
                    <RadioField
                      name="isActive"
                      value={1}
                      disable={false}
                      ischecked={state.general.isActive === 1}
                      handleChange={handleFieldChange}
                      label={t("Closed")}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4 work-experience-checkbox mt-35">
                  <div className="d-inline-block marginRight1">
                    <CheckBoxField
                      label={t("Push to website")}
                      name="pushToWebsite"
                      onChangeHandler={handleFieldChange}
                      isChecked={state.general.pushToWebsite === 1}
                      id="pushToWebsite"
                      lineHeight="1vw"
                    />
                  </div>
                  <CheckBoxField
                    label={t("Push to VDAB")}
                    name="pushToVDAB"
                    onChangeHandler={handleFieldChange}
                    isChecked={state.general.pushToVDAB === 1}
                    id="pushToVDAB"
                    lineHeight="1vw"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Navigation validStatus={validStatus} />
      </form>
      <ModalPopup
        show={showModal}
        onHide={() => handleModalClose()}
        title={t("Add location")}
        className="vacancyAddLocation modal-lg"
        body={
          <form onSubmit={handleSubmitLocation}>
            <div>
              <div className="row">
                <div className="col-md-6">
                  <LabelWithInputField
                    handleChange={handleFieldChange}
                    label={t("Location")}
                    type="text"
                    id="modalLocation"
                    name="modalLocation"
                    value={modalState.modalLocation}
                    error={modalErrors.modalLocation}
                    isMandatory={true}
                  />
                </div>
                <div className="col-md-6">
                  <LabelWithInputField
                    handleChange={handleFieldChange}
                    label={t("Street")}
                    type="text"
                    id="modalStreet"
                    name="modalStreet"
                    value={modalState.modalStreet}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <LabelWithInputField
                    handleChange={handleFieldChange}
                    label={t("Number")}
                    type="text"
                    id="modalNumber"
                    name="modalNumber"
                    value={modalState.modalNumber}
                  />
                </div>
                <div className="col-md-4">
                  <LabelWithInputField
                    handleChange={handleFieldChange}
                    label={t("Box")}
                    type="text"
                    id="modalBox"
                    name="modalBox"
                    value={modalState.modalBox}
                  />
                </div>
                <div className="col-md-4">
                  <LabelWithInputField
                    handleChange={handleFieldChange}
                    label={t("Zip code")}
                    type="text"
                    id="modalZipCode"
                    name="modalZipCode"
                    value={modalState.modalZipCode}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <SelectWithSearch
                    title={t("City")}
                    name="modalCity"
                    id="modalCity"
                    placeHolder={t("Select city")}
                    search={true}
                    options={state.options.cityOptions}
                    value={modalState.modalCity}
                    onChange={(e) => handleSelectChange(e, "modalCity")}
                    isMulti={false}
                    isMandatory={true}
                    className="select-field modal-select"
                    error={modalErrors.modalCity}
                  />
                </div>
                <div className="col-md-6">
                  <SelectWithSearch
                    title={t("Country")}
                    name="modalCountry"
                    id="modalCountry"
                    placeHolder={t("Select country")}
                    search={true}
                    options={state.options.countryOptions}
                    value={modalState.modalCountry}
                    onChange={(e) => handleSelectChange(e, "modalCountry")}
                    isMulti={false}
                    isMandatory={true}
                    className="select-field modal-select"
                    error={modalErrors.modalCountry}
                  />
                </div>
              </div>
              <Button
                type="submit"
                title={t("Save")}
                className="float-end form-button shadow-none px-3"
              />
            </div>
          </form>
        }
      />
    </>
  );
};

export default translate(VacancyGeneralDetails);
