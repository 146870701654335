import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import "./target.css";
import { useEffect, useState } from "react";
import { TARGET_ADD, TARGET_EDIT } from "routes/ApiEndpoints";
import { ApiCall } from "components/common/services/ApiServices";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "Constants";
import { mapToSelect } from "components/common/utlis/MapToSelect";
import { OptionProps } from "components/common/utlis/TypeAnnotations";
import { Link } from "react-router-dom";
import Button from "components/common/atoms/Button";

import LoadingIcon from "components/common/utlis/LoadingIcon";
import CustomNotify from "components/common/atoms/CustomNotify";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import Title from "components/common/atoms/Title";
import InputTextfield from "components/common/atoms/InputTextField";
import { Spinner } from "react-bootstrap";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import NoRecords from "components/common/atoms/NoRecords";

interface TargetType {
  targetLineDetId: number | null;
  targetTypeId: number | null;
  type: string;
  value: string;
}

interface TargetTypes {
  id: number;
  typeId: number;
  targetPer: number;
  type: string;
}

interface FormData {
  office: {
    value: number;
    label: string;
  };
  omName: string;
  targetHeaderId: string;
  year: number | string;
  lines: Role;
}

interface UserTargets {
  targetLineId: string | null;
  targetConfigId: string | null;
  userId: number;
  name: string;
  fromDate: string;
  toDate: string;
  designation: string | null;
  targets: TargetType[];
}

interface RoleMonth {
  [month: string]: UserTargets[]; // This allows any month name with associated MonthTarget array
}

interface Role {
  [roleName: string]: RoleMonth;
}

type FrequencyMap = {
  [key: string]: string;
};

const TargetOverview: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    office: { value: 0, label: "" },
    omName: "",
    targetHeaderId: "",
    year: new Date().getFullYear(),
    lines: {},
  });
  const [offices, setOffices] = useState<OptionProps[]>([]);
  const [office, setOffice] = useState<OptionProps>();
  const [recTargetTypes, setRecTargetTypes] = useState<TargetTypes[]>([]);
  const [omTargetTypes, setOmTargetTypes] = useState<TargetTypes[]>([]);
  const [loading, setLoading] = useState(false);
  const [years, setYears] = useState<OptionProps[]>([]);
  const initialErrors: { [key: string]: string } = {};
  const [errors, setErrors] = useState<{ [key: string]: string }>(
    initialErrors
  );
  const [currentTab, setCurrentTab] = useState<string>("RECRUITER");
  const [typeList, setTypeList] = useState<FrequencyMap>({});

  useEffect(() => {
    fetchTargets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (office) {
      fetchTargets();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [office, formData.year]);

  const fetchTargets = async () => {
    const requestData = {
      type: "targets",
      office: office,
      year: formData.year,
    };

    const response = await ApiCall.service(
      TARGET_EDIT + "/target",
      "POST",
      requestData,
      true,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );

    if (response.status === 200) {
      const yearObjects = response.msg.years.map((year: number) => ({
        value: year,
        label: year.toString(),
      }));
      setTypeList(response.msg["targetPerList"]);
      setYears(yearObjects);
      setFormData(response.msg.data);
      setOffices(mapToSelect(response.msg["offices"]));
      setRecTargetTypes(response.msg["targetTypes"]["RECRUITER"]);
      setOmTargetTypes(response.msg["targetTypes"]["OFFICE_MANAGER"]);
    } else if (response.status === 400) {
      CustomNotify({
        type: "error",
        message: t("Some error occured while fetching data"),
      });
    }
  };

  const handleSelectChange = async (
    selectedOption: OptionProps,
    fieldName: string
  ) => {
    setErrors({});
    if (fieldName === "year") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        year: selectedOption.value ?? "",
      }));
    } else {
      setOffice(selectedOption);
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    month: string,
    index: number,
    targetType: TargetTypes
  ) => {
    const newValue = e.target.value;
    const regex = /^[0-9]+$/;

    if (regex.test(newValue) || newValue === "") {
      // Update formData state
      setFormData((prevFormData) => {
        if (!prevFormData) return prevFormData; // If prevFormData is undefined, return it directly
        const newLines = { ...prevFormData.lines };
        const targetIndex = newLines[currentTab][month][
          index
        ].targets.findIndex((target) => target.type === targetType.type);

        // If targetType doesn't exist for the current target, push a new target object
        if (targetIndex === -1) {
          newLines[currentTab][month][index].targets.push({
            targetLineDetId: null,
            targetTypeId: targetType.typeId,
            type: targetType.type,
            value: newValue,
          });
        } else {
          // Update existing target value
          newLines[currentTab][month][index].targets[targetIndex].value =
            newValue;
        }
        return { ...prevFormData, lines: newLines };
      });
    }
  };

  // // Function to check if any targets have a null or empty value
  // const hasNullOrBlankValue = (formData: FormData): boolean => {
  //   for (const month in formData.lines) {
  //     const userTargetsList = formData.lines[month];
  //     for (const userTargets of userTargetsList) {
  //       for (const target of userTargets.targets) {
  //         if (target.value === null || target.value === "") {
  //           return true; // Return true immediately upon finding a null or empty value
  //         }
  //       }
  //     }
  //   }
  //   return false; // Return false if all targets have valid values
  // };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);

    const payload = {
      type: "targets",
      targetHeaderId: formData?.targetHeaderId
        ? parseInt(formData.targetHeaderId)
        : null,
      year: formData?.year,
      data: formData?.lines,
    };

    if (!office || office.value === null) {
      await new Promise((resolve) => setTimeout(resolve, 300));
      setErrors({ office: t("Please select office") });
      setLoading(false);
      return;
    }

    if (Object.keys(formData.lines).length > 0) {
      // if (hasNullOrBlankValue(formData)) {
      //   CustomNotify({ type: "error", message: t("Please fill the highlighted fields") });
      //   setLoading(false);
      //   return;
      // }
      const url = `${TARGET_ADD}/targets`;
      const response = await ApiCall.service(
        url,
        "POST",
        payload,
        false,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.status === 200) {
        fetchTargets();
        CustomNotify({ type: "success", message: response.msg });
      } else if (response.status === 400) {
        CustomNotify({
          type: "error",
          message: t("Some error occured while submitting"),
        });
      }
    } else {
      CustomNotify({
        type: "error",
        message: t("There is no data to submit"),
      });
    }

    setLoading(false);
  };

  const constructTypesHeaders = (targetTypes: TargetTypes[]) => {
    return (
      targetTypes &&
      targetTypes.length > 0 &&
      targetTypes.map((targetType, index) => {
        return (
          <th
            className="align-middle py-3 text-center"
            key={index}
            style={{ width: "11%" }}
          >
            {`${targetType.type + " / " + typeList[targetType.targetPer]}`}
          </th>
        );
      })
    );
  };

  const constructTypeData = (
    targetTypes: TargetTypes[],
    data: any,
    month: any,
    index: number
  ) => {
    return (
      targetTypes &&
      targetTypes.length > 0 &&
      targetTypes.map((targetType, targetTypeIndex) => {
        let targetData: TargetType = {
          targetLineDetId: null,
          targetTypeId: null,
          type: "",
          value: "",
        };
        data.targets.forEach((target: TargetType) => {
          if (target.type === targetType.type) {
            targetData.value = target.value;
            targetData.targetLineDetId = target.targetLineDetId;
            targetData.type = target.type;
            targetData.targetTypeId = target.targetTypeId;
          }
        });
        return (
          <td
            key={`${targetTypeIndex}`}
            className={`p-0 position-relative align-middle`}
          >
            <InputTextfield
              type="text"
              className={`w-100 h-100 rounded-0 form-control shadow-none text-center position-absolute
                        start-0 top-0 dataField`}
              value={targetData.value ?? "0"}
              handleChange={(e) => handleChange(e, month, index, targetType)}
            />
          </td>
        );
      })
    );
  };

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Target overview",
          read: true,
          create: true,
          update: true,
        },
      ]}
      actions={true}
      strict={false}
      renderNoAccess={true}
    >
      <Title title={t("Target and budget overview")} />
      <div className="row search-bar">
        <div className="col-2">
          <SelectWithSearch
            title={t("Year")}
            options={years}
            search={false}
            value={{ value: formData.year, label: formData.year }}
            onChange={(e) => handleSelectChange(e, "year")}
            name={"year"}
          />
        </div>
        <div className="col-5">
          <SelectWithSearch
            title={t("Office")}
            isMandatory={true}
            search={true}
            options={offices}
            onChange={(e) => handleSelectChange(e, "office")}
            isMulti={false}
            name="office"
            value={office ?? ""}
            isTranslate={true}
          />
        </div>
        <div className="col-5">
          <LabelWithInputField
            label={t("Office manager")}
            isDisabled
            value={formData?.omName ?? ""}
          />
        </div>
        <div
          className="col-md-12 company-tabs"
          style={{ paddingBottom: "1vw" }}
        >
          <button
            className={`link ${
              currentTab === "RECRUITER" ? "active nav-subtab" : "nav-subtab"
            } text-decoration-none marginRight1 border-0 bg-transparent ps-0`}
            onClick={(e) => {
              setCurrentTab("RECRUITER");
            }}
            style={{ cursor: "pointer" }}
          >
            {t("Recruiter")}
          </button>
          <button
            className={`link ${
              currentTab === "OFFICE_MANAGER"
                ? "active nav-subtab"
                : "nav-subtab"
            } text-decoration-none me-3 border-0 bg-transparent ps-0`}
            onClick={(e) => {
              setCurrentTab("OFFICE_MANAGER");
            }}
            style={{ cursor: "pointer" }}
          >
            {t("Office manager")}
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="targetBudgetWrapper">
            <table className="table table-bordered">
              <thead>
                <tr className="TableHeader align-middle">
                  <th
                    className="align-middle py-3 text-center"
                    style={{ width: "6%" }}
                  >
                    {t("Month")}
                  </th>
                  <th
                    className="align-middle py-3 text-center"
                    style={{ width: "9%" }}
                  >
                    {t("Date")}
                  </th>
                  <th className="align-middle py-3" style={{ width: "10%" }}>
                    {t("Name")}
                  </th>
                  <th className="align-middle py-3" style={{ width: "10%" }}>
                    {t("Designation")}
                  </th>
                  {currentTab === "OFFICE_MANAGER"
                    ? constructTypesHeaders(omTargetTypes)
                    : constructTypesHeaders(recTargetTypes)}
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr className="border-0">
                    <td
                      className="text-center border-0 spinner-wrapper"
                      colSpan={10}
                    >
                      <Spinner size="sm" /> {t("Loading")}
                    </td>
                  </tr>
                ) : office?.value &&
                  formData?.lines &&
                  currentTab in formData?.lines &&
                  Object.entries(formData.lines[`${currentTab}`]).length > 0 ? (
                  Object.entries(formData.lines[`${currentTab}`]).map(
                    ([month, targetConfigs]) =>
                      targetConfigs.length > 0 &&
                      targetConfigs.map((data, index) => (
                        <tr key={`${month}-${index}`}>
                          {index === 0 && (
                            <td
                              rowSpan={targetConfigs.length}
                              className="text-center align-middle"
                            >
                              {t(`${month}`)}
                            </td>
                          )}
                          <td className="align-middle text-center">
                            {`${data.fromDate ?? ""}`}
                            <br />
                            {`${t("to")} `}
                            <br />
                            {`${data.toDate ?? ""}`}
                          </td>
                          <td className="align-middle">{data.name}</td>
                          <td className="align-middle">
                            {t(`${data.designation}`)}
                          </td>
                          {currentTab === "OFFICE_MANAGER"
                            ? constructTypeData(
                                omTargetTypes,
                                data,
                                month,
                                index
                              )
                            : constructTypeData(
                                recTargetTypes,
                                data,
                                month,
                                index
                              )}
                        </tr>
                      ))
                  )
                ) : (
                  <NoRecords
                    message={`${
                      office === undefined || office.value === null
                        ? t("Please select the office")
                        : t("No records")
                    }`}
                    headerLength={10}
                  />
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="row backPadding">
        <div className="col-md-4 align-self-center">
          <Link
            to="/budget-targets"
            className=" back-btn text-decoration-underline"
          >
            {t("Back")}
          </Link>
        </div>
        <AccessControl
          requiredPermissions={[
            {
              permission: "Target overview",
              create: true,
              update: true,
            },
          ]}
          actions={true}
          strict={false}
        >
          <div className="col-md-8">
            {!loading ? (
              <Button
                title={t("Save")}
                type="submit"
                className="form-button float-end shadow-none"
                handleClick={handleSubmit}
              />
            ) : (
              <LoadingIcon
                iconType="bars"
                color="#ff4dae"
                className="float-end"
                width={"3vw"}
                height={"3vw"}
              />
            )}
          </div>
        </AccessControl>
      </div>
    </AccessControl>
  );
};

export default translate(TargetOverview);
