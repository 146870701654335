import { useEffect, useState } from "react";
import { Option } from "components/common/utlis/TypeAnnotations";
import Title from "components/common/atoms/Title";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import { Link, useParams } from "react-router-dom";
import { ApiCall } from "components/common/services/ApiServices";
import { GET_USER } from "routes/ApiEndpoints";
import LabelField from "components/common/atoms/LabelField";
import "../../../static/css/users.css";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import PhoneInput from "react-phone-number-input";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import { E164Number } from "libphonenumber-js";

interface ViewAjUserFormProps {
  id?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  mobNumber?: E164Number | string;
  office?: Option | null;
}
const ViewUserPage = () => {
  const { id } = useParams<{ id: string }>();
  const [formData, setFormData] = useState<ViewAjUserFormProps>({
    firstName: "",
    lastName: "",
    email: "",
    mobNumber: "" as E164Number,
    office: null,
  });

  useEffect(() => {
    if (id) {
      const fetchDataForEditing = async () => {
        const data = {
          id: id,
          template: "user",
        };
        const response = await ApiCall.service(GET_USER, "POST", data);
        if (response.status === 200) {
          const data = response.data.details;

          setFormData((prevData) => ({
            ...prevData,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            mobNumber: data.mobNumber,
            office: data.office,
          }));
        }
      };
      fetchDataForEditing();
    }
  }, [id]);
  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Users",
          read: true,
        },
      ]}
      renderNoAccess={true}
    >
      <Title title={t("View user")} />
      <div className="row">
        <div className="col-12">
          <div className="form-border">
            <div className="row">
              <div className="col-6">
                <LabelWithInputField
                  label={t("Name")}
                  value={`${formData.firstName} ${formData.lastName}`}
                  isDisabled
                />
              </div>
              <div className="col-6">
                <LabelWithInputField
                  label={t("Email")}
                  value={formData.email}
                  isDisabled
                />
              </div>
              <div className="col-6">
                <LabelField title={t("Phone number")} key="PhoneInput" />
                <div
                  className="mb-3 form-control field-shadow d-flex disabled"
                  style={{ borderRadius: "0.5vw" }}
                >
                  <PhoneInput
                    defaultCountry="BE" //Belgium country code
                    placeholder={t("Enter phone number")}
                    value={
                      formData.mobNumber !== undefined
                        ? (formData.mobNumber as E164Number)
                        : ("" as E164Number)
                    }
                    onChange={() => {}}
                    className="w-100"
                    international
                  />
                </div>
              </div>
              <div className="col-6">
                <LabelWithInputField
                  label={t("Office")}
                  value={formData.office?.label}
                  isDisabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>
        <div className="row backPadding">
          <div className="col-6">
            <Link
              to="/manage-users"
              className="back-btn text-decoration-underline"
            >
              {t("Back")}
            </Link>
          </div>
        </div>
    </AccessControl>
  );
};

export default translate(ViewUserPage);
