import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ApiCall } from "components/common/services/ApiServices";
import { MANAGE_SALES_NOTE_MEETINGS } from "routes/ApiEndpoints";
import Title from "components/common/atoms/Title";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import * as CONST from "../../../../Constants";
import { useSelector } from "react-redux";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import { Option } from "components/common/utlis/TypeAnnotations";
import {
  createQueryParams,
  filterAndMerge,
  formatDateAndTime,
  getQueryParamsFromRedux,
} from "components/common/utlis/HelperFunctions";
import {
  clearDestinationPath,
  setDestinationPath,
} from "features/NavigationSlice";
import { useDispatch } from "react-redux";
import { RootState } from "store";
import SearchFields from "components/common/atoms/SearchFields";
import ResetBtn from "components/common/atoms/ResetBtn";
import ActionIconWrapper from "components/common/atoms/ActionIconWrapper";
import InfoIcon from "static/images/InfoIcon";
import GetQueryParams from "components/common/services/GetQueryParams";
import ViewIcon from "static/images/ViewIcon";
import { createFilterConfig } from "./Filters";
import "./css/salesNote.css";
import PaginationNew from "components/common/atoms/PaginationNew";
import Sorting from "components/common/atoms/Sorting";
import SpinnerWrapper from "components/common/atoms/SpinnerWrapper";
import TextEllipsis from "components/common/atoms/TextEllipsis";
import NoRecords from "components/common/atoms/NoRecords";

export interface MeetingsData {
  id: number;
  meetingType: string;
  meetingdate: string;
  meetingwith: string;
  meetingby: string;
  status: string;
  office: string;
  companyName: string | null;
  title: string;
  updated_at: Date | null;
  created_at: Date | null;
}

export interface SearchProps {
  office: number[];
  meetingTitle: string;
  meetingType: Option | null;
  from: Date | null | string;
  to: Date | null | string;
  meetingBy: string;
  meetingWith: string;
  meetingStatus: Option | null;
  currentPage: number;
  recordsPerPage: number;
  orderByColumn: string;
  orderIn: "" | "asc" | "desc";
}

const SalesNoteMeetingsManagePage: React.FC = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [meetings, setMeetings] = useState<MeetingsData[]>([]);

  const [paginationData, setPaginationData] = useState({
    totalPages: 1,
    totalRecords: 1,
    cursor: null,
  });

  const [dropdownList, setDropdownList] = useState({
    offices: [],
    meetingTypes: [],
    meetingStatus: [],
  });

  const destinationPath = useSelector(
    (state: RootState) => state.navigation.destinationPath
  );

  const getUrlParams = GetQueryParams();
  const paramFilter = getUrlParams.get("filter");
  const queryParams = getQueryParamsFromRedux(destinationPath, paramFilter);

  const filters = createFilterConfig(dropdownList);

  // Initialize search data based on query params
  const defaultSearchData: SearchProps = {
    office: [],
    currentPage: 1,
    recordsPerPage: 10,
    meetingTitle: "",
    meetingType: null,
    from: "",
    to: "",
    meetingBy: "",
    meetingWith: "",
    meetingStatus: null,
    orderByColumn: "",
    orderIn: "",
  };

  //active tab status
  const defaultTabData = {
    tabFieldName: "companyType",
    tabFieldValue: null,
  };

  const filteredSearchData = filterAndMerge(defaultSearchData, queryParams);

  const filteredTabData = filterAndMerge(defaultTabData, queryParams);

  // Initialize search data based on query params, merging with default values
  const [searchData, setSearchData] = useState<SearchProps>({
    ...defaultSearchData,
    ...filteredSearchData,
  });

  useEffect(() => {
    dispatch(clearDestinationPath());
    const filterData = { ...searchData };
    const searchUrl = createQueryParams(filterData);
    dispatch(setDestinationPath(searchUrl));
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      fetchDetails(filterData);
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [searchData]);

  const fetchDetails = async (filterData: any) => {
    setLoading(true);
    const data = {
      search: filterData,
    };

    const response = await ApiCall.service(
      MANAGE_SALES_NOTE_MEETINGS,
      "POST",
      data,
      false,
      CONST.CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );
    if (response.status === 200) {
      const { meetingData, dropdownData, paginationData } = response.data;
      setMeetings(meetingData);
      setPaginationData(paginationData);
      setDropdownList(dropdownData);
    }
    setLoading(false);
  };

  //** change handlers */
  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: 1,
      [name]: value,
    }));
  };

  const handlePageChange = (pageNumber: number) => {
    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: pageNumber,
    }));
  };

  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: 1,
      [fieldName]: selectedOption,
    }));
  };

  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    setSearchData((prev) => ({
      ...prev,
      recordsPerPage: newItemsPerPage,
      currentPage: 1,
    }));
  };

  //** search filters */
  const handleReset = async (e: React.FormEvent) => {
    dispatch(clearDestinationPath());
    setSearchData(defaultSearchData);
    fetchDetails({
      ...defaultSearchData,
      ...defaultTabData,
    });
  };

  const handleDateChange = (date: Date | null, fieldName: string) => {
    setSearchData((prevSearch) => ({
      ...prevSearch,
      [fieldName]: date,
    }));
  };

  const handleOrderByColumn = (column: string) => {
    let newOrder: "" | "asc" | "desc" = ""; // Explicit type declaration
    let newColumn = column;

    if (searchData.orderByColumn === column) {
      // Cycle through the three possible order values: '', 'asc', 'desc'
      if (searchData.orderIn === "") {
        newOrder = "asc";
      } else if (searchData.orderIn === "asc") {
        newOrder = "desc";
      } else {
        newOrder = "";
        newColumn = "";
      }
    } else {
      newOrder = "asc";
    }

    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: 1,
      orderByColumn: newColumn,
      orderIn: newOrder,
    }));
  };

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Sales note",
          read: true,
        },
      ]}
      renderNoAccess={true}
    >
      <div className={`main-container`}>
        <Title title={t("Meetings")} />
        <div className="row">
          <div className="col-12">
            <div className="position-relative tableMainWrapper mt-0">
              <div className="table-responsive tableSection" style={{paddingTop:"0.5vw"}}>
                <table className="table manageSalesNotes border-0 mb-0">
                  <thead>
                    <tr className="tableSearchFields">
                      <SearchFields
                        searchData={searchData}
                        filters={filters}
                        handleSearchChange={handleSearchChange}
                        handleSelectChange={handleSelectChange}
                        handleDateChange={handleDateChange}
                      />
                      <th className="border-0">
                        <div className="d-flex align-items-center w-100">
                          <ResetBtn handleResetClick={handleReset} />
                        </div>
                      </th>
                    </tr>
                  </thead>
                </table>
                <div className="tableContainerAgreement scrollBarDesign">
                  <table className="table table-hover manageSalesNotes">
                    <thead>
                      <tr className="TableHeader">
                        <th onClick={() => handleOrderByColumn("office")}>
                          {t("Office")}
                          <Sorting
                            sortType={
                              searchData.orderByColumn === "office"
                                ? searchData.orderIn
                                : ""
                            }
                          />
                        </th>
                        <th onClick={() => handleOrderByColumn("meetingTitle")}>
                          {t("Meeting title")}
                          <Sorting
                            sortType={
                              searchData.orderByColumn === "meetingTitle"
                                ? searchData.orderIn
                                : ""
                            }
                          />
                        </th>
                        <th onClick={() => handleOrderByColumn("meetingType")}>
                          {t("Meeting type")}
                          <Sorting
                            sortType={
                              searchData.orderByColumn === "meetingType"
                                ? searchData.orderIn
                                : ""
                            }
                          />
                        </th>
                        <th onClick={() => handleOrderByColumn("meetingDate")}>
                          {t("Meeting date")}
                          <Sorting
                            sortType={
                              searchData.orderByColumn === "meetingDate"
                                ? searchData.orderIn
                                : ""
                            }
                          />
                        </th>
                        <th>{t("Meeting by")}</th>
                        <th>{t("Meeting with")}</th>
                        <th>{t("Status")}</th>
                        <AccessControl
                          requiredPermissions={[
                            {
                              permission: "Sales note",
                              update: true,
                              delete: true,
                              read: true,
                            },
                          ]}
                          actions={true}
                          strict={false}
                        >
                          <th>{t("Action")}</th>
                        </AccessControl>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <SpinnerWrapper headerLength={8} />
                      ) : (
                        <>
                          {meetings && meetings.length > 0 ? (
                            meetings.map(
                              (item: MeetingsData, index: number) => (
                                <tr
                                  key={index}
                                  className="border mb-3 box-shadow align-middle"
                                >
                                  <td
                                    className="text-break"
                                    data-label={t("Office")}
                                  >
                                    {item.office}
                                  </td>
                                  <td
                                    className="text-break"
                                    data-label={t("Meeting type")}
                                  >
                                   <TextEllipsis title= {item.title} label= {item.title} width="11.5vw" />
                                  </td>
                                  <td
                                    className="text-break"
                                    data-label={t("Meeting type")}
                                  >
                                    {item.meetingType}
                                  </td>
                                  <td
                                    className="text-break"
                                    data-label={t("Meeting date")}
                                  >
                                    {item.meetingdate}
                                  </td>
                                  <td
                                    className="text-break"
                                    data-label={t("Meeting date")}
                                  >
                                    {item.meetingby}
                                  </td>
                                  <td
                                    className="text-break"
                                    data-label={t("Meeting date")}
                                  >
                                    {item.meetingwith}
                                    <br />
                                    {/* {item.companyName && item.companyName} */}
                                  </td>
                                  <td
                                    className="text-break"
                                    data-label={t("Meeting date")}
                                  >
                                    {item.status}
                                  </td>

                                  <td className="table-action-icons px-2">
                                    <ActionIconWrapper initialVisibleCount={4}>
                                      <AccessControl
                                        requiredPermissions={[
                                          {
                                            permission: "Sales note",
                                            read: true,
                                          },
                                        ]}
                                      >
                                        <Link
                                          to={`/view/meeting/${item.id}`}
                                          className="btn p-0 border-0 me-2"
                                          title={t("View")}
                                        >
                                          <ViewIcon />
                                        </Link>
                                      </AccessControl>
                                      <div className="d-inline-block">
                                        <div className="position-relative manageInfoIcon cursor-pointer">
                                          <InfoIcon />
                                          <span className="infoHelpText text-center">
                                            {t("Created at")}{" "}
                                            <span className="fw-bold">
                                              {formatDateAndTime(
                                                item.created_at
                                              )}
                                            </span>
                                            <br />
                                            {t("Updated at")}{" "}
                                            <span className="fw-bold">
                                              {formatDateAndTime(
                                                item.updated_at
                                              )}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </ActionIconWrapper>
                                  </td>
                                </tr>
                              )
                            )
                          ) : (
                            <NoRecords headerLength={8} />
                          )}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
                {!loading && (
                  <div className="pagination d-block">
                    <PaginationNew
                      currentPage={searchData.currentPage}
                      totalPages={paginationData.totalPages}
                      totalRecords={paginationData.totalRecords}
                      recordsPerPage={searchData.recordsPerPage}
                      onItemsPerPageChange={handleItemsPerPageChange}
                      onPageChange={(newPage: any) => handlePageChange(newPage)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-md-6 align-self-center"
          style={{ padding: "1vw 0" }}
        >
          <Link to="/dashboard" className=" back-btn text-decoration-underline">
            {t("Back")}
          </Link>
        </div>
      </div>
    </AccessControl>
  );
};
export default translate(SalesNoteMeetingsManagePage);
