import { ApiCall } from "components/common/services/ApiServices";
import {
  BILLING_DETAILS,
  GET_PROFILE_DETAILS,
  REMENUARATION_DETAILS,
  UPDATE_TAB_DATA,
} from "../../annotation/PlanningConstants";
import { COMPANY_MICROSERVICE } from "Constants";

export const fetchProfileDetails = async (
  type: "planningProfile" | "payProfile", // Dynamic type handling
  id: number | null | undefined,
  state: any,
  dispatch: React.Dispatch<any>
) => {
  if (type === "planningProfile") {
    //selected profile option
    const profileOption = state.dropDowndata.commercialProfileOptions.find(
      (option: any) => option.value === id
    );
    const data = {
      type: type,
      agreementId: profileOption.agreementId,
      functionId: profileOption.functionId,
    };
    if (id) {
      const response = await ApiCall.service(
        `${GET_PROFILE_DETAILS}`,
        "POST",
        data,
        false,
        COMPANY_MICROSERVICE
      );

      if (response.status === 200) {
        const responseData = response.data;
        if (Object.keys(responseData).length > 0) {
          dispatch({
            type: UPDATE_TAB_DATA,
            tab: BILLING_DETAILS,
            data: responseData,
          });
        }
      }
    }
  } else if (type === "payProfile") {
    const payProfileOption = state.dropDowndata.payProfileOptions.find(
      (option: any) => option.value === id
    );
    const adminFicheId = payProfileOption.adminFicheId;
    if (adminFicheId) {
      const data = {
        type: type,
        adminFicheId: adminFicheId,
        empTypeId: id,
      };
      const response = await ApiCall.service(
        `${GET_PROFILE_DETAILS}`,
        "POST",
        data,
        false,
        COMPANY_MICROSERVICE
      );
      if (response.status === 200) {
        const responseData = response.data;
        if (Object.keys(responseData).length > 0) {
          dispatch({
            type: UPDATE_TAB_DATA,
            tab: REMENUARATION_DETAILS,
            data: responseData,
          });
        }
      }
    }
  }
};
