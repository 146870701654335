import { ReactElement } from "react";
import BackOverviewIcon from "static/images/BackOverviewIcon";
import Company from "static/images/Company";

interface ButtonItem {
  label?: string;
  icon?: ReactElement;
  path?: string;
}

export const actionButtons: ButtonItem[] = [
  { label: "Overview", icon: <BackOverviewIcon />, path: "/manage/cooperation-agreement" },
  { label: "Company", icon: <Company />, path: "/manage-companies?filter=1" },
];
