import React from 'react';

const QuestionIcon = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 54 70">
        <path id="My_todo_" data-name="My todo " d="M52,70H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0H52a2,2,0,0,1,2,2V68A2,2,0,0,1,52,70ZM8,45.634a1.114,1.114,0,0,0-1.114,1.111v6.664A1.114,1.114,0,0,0,8,54.519h6.682A1.113,1.113,0,0,0,15.8,53.409V46.745a1.113,1.113,0,0,0-1.113-1.111ZM21.06,52.5v1.616h16.2V52.5Zm0-4.307v1.616H45.9V48.192ZM8,27.327a1.114,1.114,0,0,0-1.114,1.111V35.1A1.114,1.114,0,0,0,8,36.211h6.682A1.113,1.113,0,0,0,15.8,35.1V28.437a1.113,1.113,0,0,0-1.113-1.111ZM21.06,34.192v1.616h16.2V34.192Zm0-4.307V31.5H45.9V29.884ZM8,9.019A1.114,1.114,0,0,0,6.885,10.13v6.663A1.114,1.114,0,0,0,8,17.9h6.682A1.114,1.114,0,0,0,15.8,16.793V10.13a1.114,1.114,0,0,0-1.113-1.111ZM21.06,15.885V17.5h16.2V15.885Zm0-4.308v1.615H45.9V11.577Zm-9.991,40.3h-.006a.423.423,0,0,1-.3-.122L9.292,50.29a.416.416,0,0,1,.181-.711.435.435,0,0,1,.1-.013.42.42,0,0,1,.307.135l1.166,1.162,1.934-2.457,0-.005.006-.007a.419.419,0,0,1,.59-.005.416.416,0,0,1,.006.589l-2.224,2.771A.416.416,0,0,1,11.069,51.881Zm0-18.308h-.006a.419.419,0,0,1-.3-.122L9.292,31.982a.416.416,0,0,1,.181-.711.435.435,0,0,1,.1-.013.42.42,0,0,1,.307.135l1.166,1.162L12.983,30.1l0,0,.006-.008a.419.419,0,0,1,.59-.005.417.417,0,0,1,.006.589l-2.224,2.771A.419.419,0,0,1,11.069,33.574Zm0-18.308h-.006a.419.419,0,0,1-.3-.122L9.292,13.675a.416.416,0,0,1,.181-.711.435.435,0,0,1,.1-.013.42.42,0,0,1,.307.135l1.166,1.162,1.934-2.457,0,0,.006-.008a.419.419,0,0,1,.59-.005.417.417,0,0,1,.006.589l-2.224,2.771A.419.419,0,0,1,11.069,15.266Z" fill="currentColor" />
      </svg>

    </>
  )
}

export default QuestionIcon