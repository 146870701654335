import { t } from "components/CentralDataMangement/translation/Translation";
import { ProspectCallDetails } from "../Annotations";
import TableTitle from "components/common/atoms/TableTitle";

interface ProspectCallsTableProps {
    data?: ProspectCallDetails[];
    tableTitle?: string;
}
const ProspectCallsTable: React.FC<ProspectCallsTableProps> = ({ data, tableTitle }) => {

    return (
        <>
            {tableTitle &&
                <TableTitle title={tableTitle} />
            }
            <table className="table table-hover table-bordered">
                <thead>
                    <tr className="TableHeader">
                        <th style={{width:"15%"}}>{t("Date")}</th>
                        <th style={{width:"20%"}}>{t("Company")}</th>
                        <th>{t("Info")}</th>
                    </tr>
                </thead>
                <tbody>
                    {data?.length && data?.length > 0 ? (
                        data?.map((call: ProspectCallDetails, index) => (
                            <tr key={index}>
                                <td className="align-middle text-break">{call.date}</td>
                                <td className="align-middle text-break">{call.company}</td>
                                <td className="align-middle text-break">{call.info}</td>
                            </tr>
                        ))) : (
                        <tr>
                            <td colSpan={5}>
                                <div className="text-danger text-center">
                                    {t("No data found")}
                                </div>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </>
    );
}

export default ProspectCallsTable;