import React from "react";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import { t } from "components/CentralDataMangement/translation/Translation";
import PCAccordion from "./PCAccordion";
import { useFormContext } from "components/CompanyCreation/AdminFiche/context/Context";
import { Option } from "components/common/utlis/TypeAnnotations";
import * as CONST from "../../../annotations/AdminFicheConstants";
import { formatTime } from "components/common/utlis/PlanningUtility";
import { scrollToTop } from "components/common/services/ValidationService";
import Navigation from "../../form-navigation/Navigation";

const WagePemiums = () => {
  const { state, dispatch } = useFormContext();
  const wagePremiums = Object.values(state.wageAndPremiums ?? {});

  const addWageData = (
    selectedOption: Option,
    field: string,
    wageId?: number | null,
    profileId?: number | null
  ) => {
    if (selectedOption?.value !== null) {
      dispatch({
        type: CONST.ADD_WAGE_DATA,
        field,
        value: selectedOption,
        wageId,
        profileId,
      });
    }
  };

  const clonePayProfile = (
    selectedOption: Option | null,
    wageId: number,
    profileId: number
  ) => {
    if (selectedOption?.value !== null) {
      dispatch({
        type: CONST.CLONE_PAY_PROFILE,
        value: selectedOption,
        wageId,
        profileId,
      });
    }
  };

  const removeWageData = (
    wageId?: number | null,
    profileId?: number | null,
    codeId?: number | null
  ) => {
    dispatch({
      type: CONST.REMOVE_WAGE_DATA,
      wageId,
      profileId,
      codeId,
    });
  };

  const pcOptions = [
    ...state.general.paritairBlue,
    ...state.general.paritairWhite,
  ];

  const getPcDropdown = () => {
    if (!wagePremiums) return [];
    const selectedPcs = wagePremiums.map((item: any) => item.pc);
    const availableOptions = pcOptions.filter(
      (option) => !selectedPcs.some((pc: any) => pc.value === option.value)
    );
    return availableOptions.length ? availableOptions : [];
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    wageId: number,
    profileId?: number,
    codeId?: number,
    applicabilityId?: number
  ) => {
    const { name, value, type, checked } = e.target as HTMLInputElement;
    let updatedValue: any;

    // Time and numeric fields with validation for alphabet input
    if (name === "from" || name === "to") {
      updatedValue = formatTime(value);
    } else if (
      [
        "totalValue",
        "employerPart",
        "minimumHours",
        "value",
        "mtcTotal",
        "mtcEmployer",
        "mtcMinHrs",
        "amount",
        "percentage",
        "minHrs",
        "maxHrs",
      ].includes(name)
    ) {
      updatedValue =
        value === "" || !/[a-zA-Z]/.test(value) ? value : undefined;
      if (updatedValue === undefined) return; // Abort dispatch if value contains alphabets
    } else {
      updatedValue =
        type === "radio"
          ? parseInt(value)
          : type === "checkbox"
          ? checked
            ? 1
            : 0
          : value;
    }

    // Special handling for EcoCheque
    if (name === "isEcoCheque") {
      dispatch({
        type: CONST.UPDATE_WAGE_FIELD_DATA,
        field: updatedValue === 1 ? "chequeType" : "value",
        value: updatedValue === 1 ? null : "",
        wageId,
        profileId,
        codeId,
        applicabilityId,
      });
      if (updatedValue === 1) {
        dispatch({
          type: CONST.UPDATE_WAGE_FIELD_DATA,
          field: "otherInfo",
          value: "",
          wageId,
          profileId,
          codeId,
          applicabilityId,
        });
      }
    }

    // Main dispatch
    if (
      name === "totalValue" ||
      name === "employerPart" ||
      name === "minimumHours"
    ) {
      dispatch({
        type: CONST.UPDATE_MEAL_VOUCHERS,
        field: name,
        value: updatedValue,
        wageId
      });
    } else {
      dispatch({
        type: CONST.UPDATE_WAGE_FIELD_DATA,
        field: name,
        value: updatedValue,
        wageId,
        profileId,
        codeId,
        applicabilityId,
      });
    }
  };

  const handleDateChange = (
    date: Date | null,
    name: string,
    wageId: number,
    profileId?: number,
    codeId?: number,
    applicabilityId?: number
  ) => {
    const updatedDate = date !== null ? date : new Date();
    dispatch({
      type: CONST.UPDATE_WAGE_FIELD_DATA,
      field: name,
      value: updatedDate,
      wageId,
      profileId,
      codeId,
      applicabilityId,
    });
  };

  const handleSelectChange = (
    selectedOption: Option | null,
    name: string,
    wageId: number,
    profileId?: number,
    codeId?: number,
    applicabilityId?: number
  ) => {
    dispatch({
      type: CONST.UPDATE_WAGE_FIELD_DATA,
      field: name,
      value: selectedOption,
      wageId,
      profileId,
      codeId,
      applicabilityId,
    });
    if (name === "autoType" && selectedOption?.value !== 1) {
      dispatch({
        type: CONST.UPDATE_WAGE_FIELD_DATA,
        field: "number",
        value: 1,
        wageId,
        profileId,
        codeId,
        applicabilityId,
      });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    scrollToTop();
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="position-relative">
        <div className="form-border px-lg-5 py-5 px-4">
          <div className="form pt-2">
            <PCAccordion
              WageAndPremiums={wagePremiums}
              addWageData={addWageData}
              removeWageData={removeWageData}
              handleChange={handleChange}
              handleDateChange={handleDateChange}
              handleSelectChange={handleSelectChange}
              clonePayProfile={clonePayProfile}
              commProfileList={state.commProfileList}
              perfCodeList={state.perfCodeList}
              autoTypeList={state.autoTypeList}
              ecoBenefitList={state.ecoBenefitList}
            />
            <SelectWithSearch
              search={true}
              options={getPcDropdown()}
              value={""}
              onChange={(e) => addWageData(e, "pc")}
              name="pc"
              placeHolder={t("Select pc")}
            />
          </div>
        </div>
      </div>
      <Navigation />
    </form>
  );
};

export default WagePemiums;
