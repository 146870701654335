import React, { useState } from "react";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import { t } from "components/CentralDataMangement/translation/Translation";
import { useFormContext } from "../../context/Context";
import ModalPopup from "components/common/atoms/ModalPopup";
import { automaticCodeProps } from "../../annotation/PlanningInterface";
import * as CONST from "../../annotation/PlanningConstants";
import Button from "components/common/atoms/Button";
import CheckBoxField from "components/common/atoms/CheckBoxField";
import EditIcon from "static/images/EditIcon";
import DeleteIcon from "static/images/DeleteIcon";
import { ValidationRules } from "components/common/utlis/TypeAnnotations";
import {
  validateForm,
  validateMinValue,
  validateSelectField,
} from "components/common/services/ValidationService";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import LabelField from "components/common/atoms/LabelField";
import InfoIcon from "static/images/InfoIcon";
import HelpInfoIcon from "static/images/HelpInfo";

const AutomaticCode: React.FC = () => {
  const { state, dispatch } = useFormContext();

  const [formData, setFormData] = useState<automaticCodeProps>({
    id: null,
    codeOption: null,
    code: "",
    description: "",
    number: "",
    automation: null,
    skipAbsence: null,
  });

  const intialErrors: { [key: string]: string } = {};
  const [errors, setErrors] = useState<{
    [key: string]: string;
  }>(intialErrors);

  const validation = (
    name: string,
    value: string | number | null,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      codeOption: [validateSelectField],
      automation: [validateSelectField],
      number: [validateMinValue],
    };
    const validationErrors = validateForm(
      { ...formData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const removeFieldSet = (indexToRemove: number) => {
    dispatch({ type: CONST.REMOVE_AUTOMATIC_CODE, indexToRemove });
  };

  const [modal, setModal] = useState(false);
  const [index, setIndex] = useState(0);

  const addData = (index: number) => {
    setIndex(index); // Set the index for dispatch
    setModal(true);
  };

  const editData = (index: number) => {
    setIndex(index); // Set the index for dispatch
    setModal(true);
    const payRollCodeToEdit = state.remenurationDetails.automaticCodes[index]; // Get the certificate to edit
    if (payRollCodeToEdit) {
      // Populate the  data in the modal fields
      setFormData({
        id: payRollCodeToEdit.id,
        codeOption: payRollCodeToEdit.codeOption,
        code: payRollCodeToEdit.code,
        description: payRollCodeToEdit.description,
        number: payRollCodeToEdit.number,
        automation: payRollCodeToEdit.automation,
        skipAbsence: payRollCodeToEdit.skipAbsence,
      });
    }
  };

  const resetFields = () => {
    setFormData({
      id: null,
      codeOption: null,
      code: "",
      description: "",
      number: "",
      automation: null,
      skipAbsence: null,
    });
  };

  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: selectedOption,
    }));
    if (selectedOption && fieldName === "codeOption") {
      const [code, description] = selectedOption?.label.split(" - ") || [];
      setFormData((prevData) => ({
        ...prevData,
        code: code,
        description: description,
      }));
    }
    if (fieldName === "automation") {
      setFormData((prevData) => ({
        ...prevData,
        skipAbsence: 0,
      }));
    }
    validation(fieldName, selectedOption, true);
  };

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type } = event.target as HTMLInputElement;
    let updatedValue: string | number | null = value;
    if (type === "checkbox") {
      updatedValue = event.target.checked ? 1 : 0;
    } else {
      // Filter input to allow only numbers and commas
      updatedValue = value.replace(/[^0-9,]/g, "");
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: updatedValue,
    }));
    validation(name, updatedValue, true);
  };

  const onOkButtonClick = async (e: React.FormEvent) => {
    e.preventDefault();
    const { name, value } = e.target as HTMLInputElement;
    if (validation(name, value)) {
      dispatch({
        type: CONST.ADD_AUTOMATIC_CODE,
        data: formData,
        index: index,
      });
      setModal(false); // Close modal
      resetFields();
      setErrors({});
    }
  };

  const closeModal = () => {
    setModal(false);
    resetFields();
    setErrors({});
  };

  return (
    <>
      <div>
        <ModalPopup
          size="xl"
          show={modal}
          onHide={closeModal}
          title={t("Payroll profile Automatic payroll code")}
          body={
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-6">
                <SelectWithSearch
                  title={t("Code")}
                  isMandatory={true}
                  search={true}
                  options={state.dropDowndata.payRollCodes}
                  id={`codeOption-${index}`}
                  onChange={(e) => handleSelectChange(e, "codeOption")}
                  isMulti={false}
                  name="codeOption"
                  value={formData.codeOption}
                  isTranslate={true}
                  error={errors.codeOption}
                />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6">
                <SelectWithSearch
                  title={t("Type of automation")}
                  isMandatory={true}
                  search={true}
                  options={state.dropDowndata.automationTypeOptions}
                  id={`automation-${index}`}
                  onChange={(e) => handleSelectChange(e, "automation")}
                  isMulti={false}
                  name="automation"
                  value={formData.automation}
                  isTranslate={true}
                  error={errors.automation}
                />
              </div>
              <div className="col-sm-12 col-md-6">
                <LabelWithInputField
                  isMandatory={true}
                  name="number"
                  handleChange={handleFieldChange}
                  value={formData.number}
                  id={`=number-${index}`}
                  label={t("Number")}
                  error={errors.number}
                />
              </div>
              {(formData.automation?.value === 1 ||
                formData.automation?.value === 2) && (
                <div className="col-md-6 mb-3 hotlistWrapper">
                  <CheckBoxField
                    label={t("Skip absence")}
                    name="skipAbsence"
                    onChangeHandler={handleFieldChange}
                    isChecked={formData.skipAbsence === 1}
                    className="document-checkbox"
                    id={`skipAbsence-${index}`}
                  />
                  <span className="ms-2 contractBillingInfo" title={t("Skip hours/days with certain absence codes encoded")}>
                    <HelpInfoIcon />
                  </span>
                </div>
              )}
              <div className="col-12 d-flex justify-content-end">
                <Button
                  handleClick={onOkButtonClick}
                  className="shadow-none form-button px-3"
                >
                  {t("Add")}
                </Button>
              </div>
            </div>
          }
          className="modal-lg contractPerfCodeModal"
        />
      </div>
      <div className="mt-3">
        {/* Certificates section table display data */}
        <LabelField
          title={t("Automatic payroll codes")}
          className="tab-title d-flex align-items-center"
        >
          <span
            className="ms-3 contractBillingInfo"
            title={t(
              "Codes easily added to encoding on an hourly, daily or weekly basis"
            )}
          >
            <InfoIcon />
          </span>
        </LabelField>
        <div className="pwa table-responsive">
          <table className="table table-hover">
            <thead>
              <tr className="border-0 TableHeader bg-white">
                <th className="border-0 align-middle" style={{ width: "20%" }}>
                  {t("Code")}
                </th>
                <th className="border-0 align-middle" style={{ width: "30%" }}>
                  {t("Description")}
                </th>
                <th className="border-0 align-middle" style={{ width: "30%" }}>
                  {t("Type of automation")}
                </th>
                <th className="border-0 align-middle" style={{ width: "20%" }}>
                  {t("Action")}
                </th>
              </tr>
            </thead>
            <tbody>
              {state.remenurationDetails.automaticCodes.map(
                (payRoll, index) => (
                  <tr key={index}>
                    <td
                      className="align-middle text-break"
                      data-label={t("Code")}
                    >
                      {payRoll.code}
                    </td>
                    <td
                      className="align-middle text-break"
                      data-label={t("Description")}
                    >
                      {payRoll.description}
                    </td>
                    <td
                      className="align-middle text-break"
                      data-label={t("automation")}
                    >
                      {payRoll.automation?.label}
                    </td>
                    <td className="align-middle text-break">
                      <span
                        title={t("Edit")}
                        onClick={() => editData(index)}
                        className="table-action-icons cursor-pointer rounded-0 shadow-none me-2"
                      >
                        <EditIcon />
                      </span>
                      <span
                        title={t("Delete")}
                        onClick={() => removeFieldSet(index)}
                        className="table-action-icons cursor-pointer rounded-0 shadow-none"
                      >
                        <DeleteIcon />
                      </span>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
        {/* Certificates section modal popup */}
        <div className="row">
          <div className="col-12">
            <Button
              title={"+ " + t("Add")}
              handleClick={() =>
                addData(state.remenurationDetails.automaticCodes.length)
              }
              className="form-button float-end px-3 shadow-none"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AutomaticCode;
