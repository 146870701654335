import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectAuth, removeUserDetails } from "features/auth/AuthSlice";
import { ApiCall } from "../services/ApiServices";
import { LOGOUT } from "routes/ApiEndpoints";
import secureLocalStorage from "react-secure-storage";

const AutoLogout: React.FC = () => {
  const userAuth = useSelector(selectAuth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const inactivityTime = 6 * 60 * 60 * 1000; // 6 hrs

  const logout = async () => {
    const lastActivityTime = secureLocalStorage.getItem("lastActivityTime") || Date.now().toString();
    const data = {
      userId: userAuth.userId,
      lastActivityTime
    };

    try {
      await ApiCall.service(LOGOUT, "POST", data, true);
    } catch (error) {
      console.error("Failed to log out:", error);
    }

    secureLocalStorage.clear();
    dispatch(removeUserDetails());
    navigate("/login");
  };

  const resetTimer = () => {
    if (!userAuth || !userAuth.userId) {
      console.error("User authentication data is not available.");
      return;
    }
    const currentTime = Date.now();
    secureLocalStorage.setItem("lastActivityTime", currentTime.toString());
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      logout();
    }, inactivityTime);
  };

  const checkSessionExpiry = () => {
    const lastActivityTime = parseInt(secureLocalStorage.getItem("lastActivityTime") as string || "0", 10);
    const currentTime = Date.now();
    if (currentTime - lastActivityTime >= inactivityTime) {
      logout();
    } else {
      resetTimer();
    }
  };

  const handleVisibilityChange = () => {
    if (!document.hidden) {
      checkSessionExpiry(); // Check session expiry when the tab becomes visible
    }
  };

  useEffect(() => {
    if (userAuth.isAuthenticated) {
      // Initialize the last activity time when the component mounts
      const lastActivityTime = secureLocalStorage.getItem("lastActivityTime") as string;
      if (lastActivityTime) {
        const currentTime = Date.now();
        const lastActivity = parseInt(lastActivityTime, 10);
        if (currentTime - lastActivity >= inactivityTime) {
          logout(); // Session has expired
        } else {
          resetTimer(); // Session is still active
        }
      } else {
        secureLocalStorage.setItem("lastActivityTime", Date.now().toString());
        resetTimer(); // Initialize the timer if no previous activity time
      }

      // Setup event listeners for user activity
      const events = ["click", "mousemove", "keypress"];
      events.forEach((event) => window.addEventListener(event, resetTimer));
      document.addEventListener("visibilitychange", handleVisibilityChange);

      return () => {
        // Clean up event listeners on component unmount
        events.forEach((event) => window.removeEventListener(event, resetTimer));
        document.removeEventListener("visibilitychange", handleVisibilityChange);
        if (timerRef.current) {
          clearTimeout(timerRef.current);
        }
      };
    }
  }, [userAuth.isAuthenticated]);

  return null; // This component does not render anything
};

export default AutoLogout;
