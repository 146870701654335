import React, { useEffect, useState } from "react";
// import './App.css';
import { Routes } from "./routes/Routes";
import { RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./store"; // Import your Redux store and persistor
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingOverlay from "components/common/utlis/LoadingOverlay";
import { ApiCall } from "components/common/services/ApiServices";
import {
  ABSOLUTE_JOBS_APP,
  CENTRAL_DATA_MANAGEMENT_MICROSERVICE,
} from "Constants";
import {
  getLanguage,
  setTranslations,
  setLanguageChangeCallback,
  setDefaultLanguage,
} from "components/CentralDataMangement/translation/Translation";
import secureLocalStorage from "react-secure-storage";

function App() {
  const [rerenderKey, setRerenderKey] = useState(0);

  useEffect(() => {
    setDefaultLanguage(secureLocalStorage.getItem('site_lang') ?? 'en');
    fetchTranslations(getLanguage());
    setLanguageChangeCallback(handleLanguageChange); // Set the callback
  }, []);

  const handleLanguageChange = (lang: any) => {
    // Fetch translations when language changes
    fetchTranslations(lang);
    setRerenderKey((prevKey) => prevKey + 1);
  };

  const fetchTranslations = async (lang_key: any) => {
    const response = await ApiCall.service(
      `translationsByLanguage`,
      "POST",
      {
        app_name: ABSOLUTE_JOBS_APP,
        lang: lang_key,
      },
      false,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );

    setTranslations(response.translations);
  };

  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <LoadingOverlay display="none" />
          <RouterProvider router={Routes} />
          <ToastContainer limit={1} />
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;
