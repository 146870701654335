import { t } from "components/CentralDataMangement/translation/Translation";
import { PersonalTarget } from "../Annotations";
import TableTitle from "components/common/atoms/TableTitle";

interface PersonalTargetProps {
  data?: PersonalTarget;
  tableTitle?: string;
}

const PersonalTargetTable: React.FC<PersonalTargetProps> = ({
  data,
  tableTitle,
}) => {
  const cardProps = {
    className: "card card-shadow text-center border-0",
  };
  const target = data?.target ?? 0;
  const reached = data?.reached ?? 0;
  const balance = data?.balance ?? 0;
  const starter = data?.starter ?? 0;
  const stopper = data?.stopper ?? 0;

  return (
    <>
      {tableTitle && (
        <TableTitle
          title={tableTitle}
          tableTitleClassName="py-2 text-center tableTitle TableHeader border"
        />
      )}
      <div
        className="d-grid mt-3"
        style={{ gridTemplateColumns: "repeat(5, 1fr)", gap: "1vw" }}
      >
        <div {...cardProps} style={{ padding: "1vw" }}>
          <div className="roobert-semibold">{t("Target")}</div>
          <div className="mt-2">{target}</div>
        </div>
        <div {...cardProps} style={{ padding: "1vw" }}>
          <div className="roobert-semibold">{t("Achieved")}</div>
          <div className="mt-2">{reached}</div>
        </div>
        <div {...cardProps} style={{ padding: "1vw" }}>
          <div className="roobert-semibold">{t("Balance")}</div>
          <div className="mt-2 ">{balance}</div>
        </div>
        <div {...cardProps} style={{ padding: "1vw" }}>
          <div className="roobert-semibold">{t("Starters")}</div>
          <div className="mt-2 ">{starter}</div>
        </div>
        <div {...cardProps} style={{ padding: "1vw" }}>
          <div className="roobert-semibold">{t("Stopper")}</div>
          <div className="mt-2 ">{stopper}</div>
        </div>
      </div>
    </>
  );
};

export default PersonalTargetTable;
