import { FilterProps } from "components/common/atoms/AutoCompleteDropdown";
import { OptionProps } from "components/common/utlis/TypeAnnotations";

interface dropDownList {
    offices: OptionProps[];
    functions: OptionProps[];
    status: OptionProps[];
    posting: OptionProps[];
}

export const createFilterConfig = (dropDownList: dropDownList) => {
    return [
        {
            name: "office",
            options: dropDownList.offices,
            fieldType: "multiSelect",
            placeholder: "Office",
            filterType: 'search',
            label: 'Office'
        },
        {
            name: "companyName",
            fieldType: "text",
            placeholder: "Company",
            filterType: 'search'
        },
        {
            name: "function",
            options: dropDownList.functions,
            fieldType: "multiSelect",
            placeholder: "Function",
            filterType: 'search'
        },
        {
            name: "aliasName",
            fieldType: "text",
            placeholder: "Function alias name",
            filterType: 'search'
        },

        {
            name: "posting",
            options: dropDownList.posting,
            fieldType: "singleSelect",
            placeholder: "Posting",
            filterType: 'search',
        },
        {
            name: "vacancyStatus",
            options: dropDownList.status,
            fieldType: "singleSelect",
            placeholder: "Status",
            filterType: 'search',
        },
        {
            name: "consultant",
            fieldType: "text",
            placeholder: "Consultant",
            filterType: 'search'
        },
        {
            name: "from",
            fieldType: "date",
            placeholder: "From",
            filterType: 'search',
            group: 'createdAt'
        },
        {
            name: "to",
            fieldType: "date",
            placeholder: "To",
            filterType: 'search',
            group: 'createdAt'
        },
    ];
};
