import { t, translate } from "components/CentralDataMangement/translation/Translation";
import Button from "components/common/atoms/Button";
import LabelField from "components/common/atoms/LabelField";
import RadioField from "components/common/atoms/RadioField";
import Title from "components/common/atoms/Title";
import React, { useEffect, useState } from "react";
import {
  Option,
  validateForm,
  validateTimeRange,
} from "components/common/services/ValidationService";
import "./css/todos.css";
import LabelWithTextAreaField from "components/common/molecules/LabelWithTextAreaField";
import {
  OptionProps,
  ValidationRules,
} from "components/common/utlis/TypeAnnotations";
import { ApiCall } from "components/common/services/ApiServices";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "Constants";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { mapToSelect } from "components/common/utlis/MapToSelect";
import * as ENDPOINTS from "../../../routes/ApiEndpoints";
import { GET_PRE_INTERVIEW_DETAILS } from "routes/ApiEndpoints";
import TimeSlot from "./TimeSlot";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";

interface PreInterview {
  rejectionOptions: OptionProps[];
  lastIndex: number | null;
  errorMessage: string;
}
interface PresentationProps {
  pdfName: string;
  pdfLink: string;
  candidateName: string;
}

export interface AcceptData {
  [key: string]: string | Date | null | number | boolean | Option | undefined;
  id: number | null;
  date: Date | null;
  from: string;
  to: string;
}

interface RejectData {
  answerId: number | null;
  feedback: string;
}

const CompanyPreInterviewPage: React.FC = () => {
  const userAuth = useSelector(selectAuth);
  const navigate = useNavigate();
  const location = useLocation();

  const {
    todoExtensionId,
    companyId,
    candidateId,
    todoId,
    functionName,
    presentationId,
    vacancyId
  } = useParams<{
    todoExtensionId: string;
    companyId: string;
    candidateId: string;
    todoId: string;
    functionName: string;
    presentationId: string;
    vacancyId: string;
  }>();

  const [formData, setFormData] = useState<PreInterview>({
    rejectionOptions: [],
    lastIndex: null,
    errorMessage: "",
  });

  const [presentationData, setPresentationData] = useState<PresentationProps>({
    pdfName: "",
    pdfLink: "",
    candidateName: "",
  });

  const [rejectData, setRejectData] = useState<RejectData>({
    answerId: null,
    feedback: "",
  });

  const [acceptData, setAcceptData] = useState<AcceptData[]>([
    {
      id: null,
      date: null,
      from: "",
      to: "",
    },
  ]);

  const [submitType, setSubmitType] = useState<string>("");

  const [textField, setTextField] = useState(false);
  const fetchComPreInterviewDetails = async () => {
    const data = {
      stage: "Presented",
      todoExtensionId: todoExtensionId,
      companyId: companyId,
      candidateId: candidateId,
      presentationId: presentationId,
    };
    const response = await ApiCall.service(
      GET_PRE_INTERVIEW_DETAILS,
      "POST",
      data,
      true,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );
    if (response.status === 200) {
      setFormData((prevData) => ({
        ...prevData,
        rejectionOptions: mapToSelect(response.data.reasons, "reasons"),
        lastIndex: response.data.reasons.length,
      }));
      if (response.data.companySlots && response.data.companySlots.length > 0) {
        const formattedAcceptData = response.data.companySlots.map(
          (data: AcceptData) => ({
            id: data.id,
            date: data.date !== null ? new Date(data.date) : "",
            from: formatTime(data.from),
            to: formatTime(data.to),
          })
        );
        setAcceptData(formattedAcceptData);
        setSubmitType("accept");
      }
    }
  };

  function formatTime(timeString: any) {
    const [hours, minutes] = timeString.split(":");
    return `${hours}:${minutes}`;
  }

  const getPresentationDocument = async () => {
    const docDetails = {
      candidateId: candidateId,
      functionName: functionName,
      vacancyId: vacancyId !== 'undefined' && vacancyId !== 'null' && vacancyId !== '' ? vacancyId : null,
      companyId: companyId !== 'undefined' && companyId !== 'null' && companyId !== '' ? companyId : null,
    };
    const url = ENDPOINTS.CREATE_PRESENTATION_DOCUMENT;
    const response = await ApiCall.service(
      url,
      "POST",
      docDetails,
      true,
      "candidate-creation"
    );
    if (response.status === 200) {
      setPresentationData({
        pdfName: response.data.fileName,
        pdfLink: response.data.filePath,
        candidateName: response.data.candidateName,
      });
    }
  };

  useEffect(() => {
    getPresentationDocument();
    fetchComPreInterviewDetails();
  }, []);

  const handleAccept = (event: any, type: string) => {
    event.preventDefault();
    setSubmitType(type);
    setRejectData({ answerId: null, feedback: "" });

    setAcceptData([{ id: null, date: null, from: "", to: "" }]);
  };

  const handleFieldChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value, type } = e.target;
    if (type === "radio") {
      setRejectData((prevData: any) => ({
        ...prevData,
        [name]: parseInt(value),
      }));
      if (parseInt(value) === 2) {
        setTextField(true);
      }
    } else {
      setRejectData((prevData: any) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  const [errors, setErrors] = useState<{ from: string; to: string }[]>([
    { from: "", to: "" },
  ]);
  const validation = (
    name: string,
    value: string | boolean | Date | object[] | undefined,
    index: number,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      from: [
        (value: any) => validateTimeRange(value, acceptData[index].to, name),
      ],
      to: [
        (value: any) => validateTimeRange(acceptData[index].from, value, name),
      ],
    };
    const validationErrors = validateForm(
      { ...acceptData[index], [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );
    setErrors((prevErrors) => {
      const updatedErrors = [...prevErrors];
      updatedErrors[index] = {
        ...updatedErrors[index],
        [name]: validationErrors[name] || "",
      };
      return updatedErrors;
    });
    const isFieldValid = Object.keys(validationErrors).length === 0;

    if (isFieldValid) {
      setErrors((prevErrors) => {
        const updatedErrors = [...prevErrors];
        updatedErrors[index] = {
          ...updatedErrors[index],
          from: "",
          to: "",
        };
        return updatedErrors;
      });

      if (Object.keys(validationErrors).length > 0) {
        return false;
      }

      return true;
    }
  };

  //For dynmaic or accept form
  const handleDynamicFieldChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    index: number
  ) => {
    const { name, value, type } = e.target;

    if (type === "radio") {
      if (formData.lastIndex && index === formData.lastIndex - 1) {
        setTextField(true);
      } else {
        setTextField(false);
      }

      setRejectData((prevData: any) => ({
        ...prevData,
        [name]: parseInt(value),
      }));
    } else {
      setAcceptData((prevData) => {
        const updatedData = [...prevData];
        updatedData[index] = {
          ...updatedData[index],
          [name]: value,
        };
        return updatedData;
      });
    }
  };
  const handleDynamicDateChange = (
    date: Date | null,
    name: string,
    index: number
  ) => {
    const updatedDate = date !== null ? date : new Date();

    setAcceptData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = {
        ...updatedData[index],
        [name]: updatedDate !== null ? updatedDate : "",
      };
      return updatedData;
    });
    const isFilled = areDetailsFilled();
    if (isFilled) {
      setFormData((prev) => ({
        ...prev,
        errorMessage: "",
      }));
    }
  };

  // Add and remove fields
  const addFieldSet = () => {
    const newTimeSlot = {
      id: null,
      date: null,
      from: "",
      to: "",
    };
    setAcceptData((prevData) => [...prevData, newTimeSlot]);
  };

  const removeFieldSet = (currentIndex: number) => {
    const updatedData = [...acceptData];
    updatedData.splice(currentIndex, 1);
    setAcceptData(updatedData);
  };

  const areDetailsFilled = () => {
    const firstElement = acceptData[0];
    if (firstElement) {
      // Ensure that the array is not empty
      const { id, to, ...rest } = firstElement; // Destructure the object, excluding the 'id' field
      return Object.values(rest).every(
        (value) => value !== null && value !== "" && value !== "hh:mm"
      );
    }
    return false; // If array is empty, return false
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const data = {
      submitType: submitType,
      formData: submitType === "reject" ? rejectData : [],
      timeSlots: acceptData,
      todoExtensionId: todoExtensionId,
      companyId: companyId,
      candidateId: candidateId,
      userId: userAuth.userId,
      todoId: todoId,
      entityType: "company",
      stage: "Presented",
      presentationId: presentationId,
      functionName: functionName !== 'undefined' && functionName !== 'null' && functionName !== '' && functionName !== '--' ? functionName : null,
      vacancyId: vacancyId !== 'undefined' && vacancyId !== 'null' && vacancyId !== '' ? vacancyId : null,
    };
    const filled = submitType === "reject" ? true : areDetailsFilled();

    if (filled) {
      let errorsPresent = errors.some(
        (error) => error.from !== "" || error.to !== ""
      );
      if (submitType === "reject") {
        errorsPresent = false;
      }

      // If ersrors are present, prevent form submission
      if (!errorsPresent) {
        const response = await ApiCall.service(
          ENDPOINTS.UPDATE_TODO,
          "POST",
          data,
          true,
          CENTRAL_DATA_MANAGEMENT_MICROSERVICE
        );
        if (response.status === 200) {
          navigate(
            location.state?.origin === "todos"
              ? "/todos/overview"
              : "/dashboard"
          );
        }
      }
    } else {
      setFormData((prev) => ({
        ...prev,
        errorMessage: t("Please schedule interview timings"),
      }));
    }
  };

  // Time field
  const handleTimeChange = (
    index: number,
    name: string,
    value: string,
    field: string
  ) => {
    if (field === "time" || name === "from" || name === "to") {
      setAcceptData((prevData) => {
        const updatedData = [...prevData];
        updatedData[index] = {
          ...updatedData[index],
          [name]: value !== null ? value : "",
        };
        return updatedData;
      });
      validation(name, value, index, true);
      const isFilled = areDetailsFilled();
      if (isFilled) {
        setFormData((prev) => ({
          ...prev,
          errorMessage: "",
        }));
      }
    }
  };

  return (
    <>
      <Title title={t("Scheduling")} />
      <form>
        <div className="form-border mb-4">
          <div className="form pb-4">
            <div className="col-md-12">
              <p>
                {t(
                  "We are delighted to inform you that we have found an ideal match in"
                )}
                &nbsp;
                {presentationData.candidateName} {t("for the")}&nbsp;
                {functionName} {t("role at your company")}.&nbsp;
                {t(
                  "We believe their skills and experience align perfectly with your needs"
                )}
                .&nbsp;
                <a
                  href={presentationData.pdfLink}
                  target="_blank"
                  className="link-color"
                >
                  {t("Click here")}&nbsp;
                </a>
                {t("to know more about the candidate")}.
              </p>
              <p>
                {t("If the candidate meet your requirements")},
                {t(
                  "proceed further by clicking on accept and entering the time slots"
                )}
              </p>
            </div>
            <div className="row mt-5">
              <div className="col-12 d-flex flex-row justify-content-end">
                <Button
                  title={t("Reject")}
                  type="submit"
                  handleClick={(e) => handleAccept(e, "reject")}
                  className="btn delete-btn rounded-3 shadow-none  me-3"
                />
                <Button
                  title={t("Accept")}
                  type="submit"
                  handleClick={(e) => handleAccept(e, "accept")}
                  className="btn form-button rounded-3 shadow-none "
                />
              </div>
            </div>
            <div>
              {submitType === "reject" && (
                <div className="col-md-12">
                  <LabelField
                    title={t("Reason for rejection")}
                    key="type"
                    htmlfor={"answerId"}
                    className="feedbackQnsText pb-2"
                  />
                  {formData.rejectionOptions.map((option, index) => (
                    <div className="col-md-12" key={index}>
                      <RadioField
                        name="answerId"
                        value={option.value}
                        disable={false}
                        ischecked={rejectData.answerId === option.value}
                        handleChange={(e) => handleDynamicFieldChange(e, index)}
                        label={t(`${option.label}`)}
                        className="mb-2"
                      />
                    </div>
                  ))}
                  {textField && (
                    <div className="col-md-12 pt-4">
                      <LabelWithTextAreaField
                        name="feedback"
                        handleChange={handleFieldChange}
                        label={t("Additional info")}
                        value={t(`${rejectData.feedback}`)}
                      />
                    </div>
                  )}
                </div>
              )}
              {submitType === "accept" && (
                <div>
                  <div className="text-start pb-4 mb-0`">
                    <div className="pt-5">
                      <p className="">
                        {t(
                          "Thank you for showing your love towards the candiates potential"
                        )}
                        .&nbsp;
                        {t(
                          "Lets find the perfect interview date to make it official"
                        )}
                        .
                      </p>
                    </div>
                  </div>
                  <div>
                    <TimeSlot
                      acceptData={acceptData}
                      handleDynamicDateChange={handleDynamicDateChange}
                      handleTimeChange={handleTimeChange}
                      errors={errors}
                      removeFieldSet={removeFieldSet}
                      addFieldSet={addFieldSet}
                    />
                  </div>
                  <div className="height-20" style={{ marginBottom: "0.5vw" }}>
                    {formData.errorMessage && formData.errorMessage && (
                      <span className="text-danger">
                        {formData.errorMessage}
                      </span>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
          <div className="row backPadding">
            <div className="col-md-6 align-self-center">
              <Link
                to={
                  location.state?.origin === "todos"
                    ? "/todos/overview"
                    : "/dashboard"
                }
                className="back-btn text-decoration-underline"
              >
                {t("Back")}
              </Link>
            </div>
            {(submitType === "accept" || submitType === "reject") && (
              <div className="col-md-6 ">
                <Button
                  title={t("Save")}
                  type="submit"
                  handleClick={handleSubmit}
                  className="form-button float-end shadow-none"
                />
              </div>
            )}
          </div>
      </form>
    </>
  );
};

export default translate(CompanyPreInterviewPage);
