import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { faMagnifyingGlass, faPlus } from "@fortawesome/free-solid-svg-icons";
import EditIcon from "../../../../static/images/EditIcon";
import { Link } from "react-router-dom";
import Button from "components/common/atoms/Button";
import Search from "components/common/atoms/Search";
import ModalPopup from "components/common/atoms/ModalPopup";
import { ApiCall } from "components/common/services/ApiServices";
import CustomNotify from "components/common/atoms/CustomNotify";

import Pagination from "components/common/atoms/Pagination";
import Title from "components/common/atoms/Title";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import Reset from "static/images/Reset";
import ArchiveNew from "static/images/ArchiveNew";
import LinkTo from "components/common/atoms/LinkTo";
import * as CONST from "../../../../Constants";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import { CONF_SETTINGS_DELETE, CONF_SETTINGS_GET } from "routes/ApiEndpoints";
import LabelField from "components/common/atoms/LabelField";
import ResetBtn from "components/common/atoms/ResetBtn";
import SpinnerWrapper from "components/common/atoms/SpinnerWrapper";
import NoRecords from "components/common/atoms/NoRecords";

export interface CompanyRoleData {
  id?: number;
  name: string;
  status: number;
}

export const ManageCompanyRole: React.FC = () => {
  const itemsPerPage = 10;

  const [deleteID, setDeleteId] = useState<number | undefined>();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState({
    name: "",
  });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [companyRoles, setCompanyRoles] = useState<CompanyRoleData[]>([]);

  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setSearchData((prevSearch) => ({
      ...prevSearch,
      [name]: value,
    }));
  };

  const handleSearch = async (e: React.FormEvent) => {
    e.preventDefault();
    if (searchData.name === "") {
      CustomNotify({
        type: "error",
        message: t("Search input not provided"),
      });
      fetchCompanyRoles();
    } else {
      fetchCompanyRoles(searchData, 1);
      setCurrentPage(1);
    }
  };

  const handleReset = async (e: React.FormEvent) => {
    setSearchData({
      name: "",
    });
    setCurrentPage(1);
    fetchCompanyRoles();
  };

  const deleteCompany = (id: number | undefined) => {
    setShowModal(true);
    setDeleteId(id);
  };

  const handleModalClose = () => setShowModal(!showModal);

  const handleSaveChanges = async () => {
    setShowModal(false);
    if (deleteID) {
      const requestData = {
        id: deleteID,
        type: "companyRole",
      };
      const url = CONF_SETTINGS_DELETE;
      const response = await ApiCall.service(
        url,
        "POST",
        requestData,
        false,
        CONST.CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.status === 200) {
        fetchCompanyRoles();
        CustomNotify({ type: "success", message: t(response.msg) });
      }
    }
  };

  useEffect(() => {
    fetchCompanyRoles();
  }, []);

  const fetchCompanyRoles = async (searchData = {}, page = 1) => {
    setLoading(true);
    const formData = {
      page: page,
      nOR: itemsPerPage,
      search: searchData,
      type: "companyRole",
    };
    const response = await ApiCall.service(
      CONF_SETTINGS_GET,
      "POST",
      formData,
      false,
      CONST.CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );
    if (response.status === 200) {
      setTotalPages(response.data.totalPages);
      setCompanyRoles(response.data.data);
    }
    setLoading(false);
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    fetchCompanyRoles(searchData, pageNumber);
  };

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "roles",
          read: true,
        },
      ]}
      renderNoAccess={true}
    >
      <Title title={t("Company roles")} />
      <div className="row search-bar paddingBottom1">
        <div className="col-8">
          <LabelField title={t("Role name")} />
          <Search
            className="form-control"
            handleChange={handleSearchChange}
            name="name"
            value={searchData.name}
          />
        </div>
        <div className="col-4 mt-34">
          <div className="d-flex align-items-center">
            <ResetBtn handleResetClick={handleReset} />
            <Button
              title={t("Search")}
              type="submit"
              handleClick={handleSearch}
              icon={faMagnifyingGlass}
              className="form-button shadow-none search-btns position-relative text-start"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="position-relative tableMainWrapper">
            <div className="row">
              <div className="ManageCreateBtn">
                <LinkTo
                  pagelink="/company-role"
                  title={t("Create company role")}
                  icon={faPlus}
                  className="me-3"
                />
              </div>
            </div>
            <div className={`table-responsive tableSection paddingTop2`}>
              <table className="table table-hover">
                <thead>
                  <tr className="TableHeader">
                    <th className="ps-lg-4" style={{ width: "60%" }}>
                      {t("Name")}
                    </th>
                    <th>{t("Action")}</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <SpinnerWrapper headerLength={2} />
                  ) : (
                    <>
                      {companyRoles && companyRoles.length > 0 ? (
                        companyRoles.map((companyRole, index) => {
                          return (
                            <tr
                              key={companyRole.id}
                              className="border-bottom mb-3 box-shadow align-middle ps-lg-4"
                            >
                              <td className="ps-lg-4">
                                {t(`${companyRole.name}`)}
                              </td>
                              <td className="table-action-icons px-2">
                                {/* Desktop */}
                                <Link
                                  to={`/company-role/${companyRole.id}`}
                                  title={t("Edit")}
                                >
                                  <EditIcon />
                                </Link>
                                {/* <button
                                    className="btn p-0 border-0 me-2"
                                    title={t("Archive")}
                                    onClick={() => deleteCompany(companyRole.id)}
                                >
                                    <ArchiveNew />
                                </button> */}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <NoRecords headerLength={8} />
                      )}
                    </>
                  )}
                </tbody>
              </table>
              {totalPages > 1 && (
                <div className="pagination justify-content-center align-items-center">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={(newPage) => handlePageChange(newPage)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="row backPadding">
        <div className="col-md-6 align-self-center">
          <Link
            to="/config/settings"
            className="back-btn text-decoration-underline"
          >
            {t("Back")}
          </Link>
        </div>
      </div>

      <ModalPopup
        show={showModal}
        onHide={handleModalClose}
        title={t("Archive confirmation")}
        body={t("Are you sure want to archive") + "?"}
        onCloseButtonClick={handleModalClose}
        onConfirmButtonClick={handleSaveChanges}
        closeTitle={t("No")}
        confirmTitle={t("Yes")}
      />
    </AccessControl>
  );
};
export default translate(ManageCompanyRole);
