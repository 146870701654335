import React, { useState, ChangeEvent } from "react";

const style = {};

interface Props {
  id?: string;
  type?: string;
  className?: string;
  value?: string | number;
  checked?: boolean
  isDisabled?: boolean;
  placeholder?: string;
  customStyle?: React.CSSProperties;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  min?: number;
  step?: number;
  max?:number;
  readOnly?:boolean;
  pattern?: string;

}

const InputTextFieldNoError: React.FC<Props> = ({
  id = "",
  type = "text",
  min,
  max,
  step,
  className = "form-control",
  value,
  isDisabled = false,
  placeholder = "",
  customStyle = {},
  handleChange,
  name = "",
  checked,
  readOnly,
  pattern
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <>
      <input
        style={{ ...style, ...customStyle }}
        id={id}
        type={type}
        className={className}
        disabled={isDisabled}
        placeholder={isFocused ? "" : placeholder}
        name={name}
        value={value}
        checked={checked}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        min = {min}
        max={max}
        step = {step}
        readOnly = {readOnly}
        pattern={pattern}
      />
    </>
  );
};

export default InputTextFieldNoError;
