import CardTitle from "components/common/atoms/CardTitle";
import Count from "components/common/atoms/Count";
import React from "react";
import { Link } from "react-router-dom";
import { TabPanel, TabView } from "primereact/tabview";
import { TargetData } from "../Dashboard";
import RecruitmentCard from "components/common/atoms/DashboardCard";
import { t } from "components/CentralDataMangement/translation/Translation";

const types = {
  new: 5,
  selection: 0,
  starter: 22
};
interface TileProps {
  data: TargetData[];
  path: string;
  cardTitle: string;
  type: keyof typeof types;
  onClick: (type: number, userId: number,  officeId?: number, isRecruiter?: boolean) => void;
}

const SubTiles: React.FC<TileProps> = ({ data, path, cardTitle, onClick, type }) => {
  const headerTemplate = (recruiter: TargetData, path: string) => {
    const tooltipTitle = recruiter.fromDate
      ? `${recruiter.fromDate} to ${recruiter.toDate}`
      : "";
    return (
      <div className="col-12 h-100" onClick={() => onClick(types[type], recruiter?.userId, undefined, true)}>
        <Link
          to={`${path}`}
          className="position-relative card h-100"
          style={{ padding: "0.4vw", marginRight: "0.8vw" }}
        >
          <RecruitmentCard
            className={``}
            data-bs-toggle="tooltip"
            data-bs-placement="left"
            toolTipTitle={t(`${tooltipTitle}`)}
          >
            <CardTitle
              title={recruiter.userName}
              className={`recruitment-card-title fw-bold`}
            />
            <Count
              value={recruiter.achieved ?? "0"}
              totalValue={recruiter.target}
              value_punctuation={t("of")}
              className={`recruitment-card-num`}
            ></Count>
          </RecruitmentCard>
        </Link>
      </div>
    );
  };

  return (
    <div
      className="card rounded-3 border-0 card-shadow justify-content-evenly"
      style={{ marginTop: "1vw", padding: "1vw" }}
    >
      <CardTitle
        title={cardTitle}
        className={`recruitment-card-title nameTitle`}
      />
      <TabView scrollable>
        {data &&
          data.length > 0 &&
          data.map((item, index) => (
            <TabPanel
              key={`${index}${item.userName}`}
              header={item.userName}
              headerTemplate={headerTemplate(item, path)}
            />
          ))}
      </TabView>
    </div>
  );
};

export default SubTiles;
