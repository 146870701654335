import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import Calender from "components/common/molecules/Calender";
import LabelField from "components/common/atoms/LabelField";
import InputTextfield from "components/common/atoms/InputTextField";
import LabelWithTextAreaField from "components/common/molecules/LabelWithTextAreaField";
import { useFormContext } from "components/CandidateCreation/context/Context";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import Button from "components/common/atoms/Button";
import { ValidationRules } from "components/common/utlis/TypeAnnotations";
import {
  validateDate,
  validateForm,
  validateRequired,
} from "components/common/services/ValidationService";

interface InternalInfoModalProps {
  show?: boolean;
  onHide: () => void;
  title?: string;
  className?: string;
  type?: string;
}

interface formDataProps {
  certificate: Date | null;
  year: string;
  remainingQuota: string;
  info: string;
}

const InternalInfoModal: React.FC<InternalInfoModalProps> = ({
  show,
  onHide,
  title,
  className,
  type,
}) => {
  const { state, dispatch } = useFormContext();
  const initialErrors: { [key: string]: string } = {};
  const [errors, setErrors] = useState<{ [key: string]: string }>(
    initialErrors
  );
  const [formData, setFormData] = useState<formDataProps>({
    certificate: new Date(),
    year: "2024",
    remainingQuota: "",
    info: "",
  });

  const validation = (
    name: string,
    value: string | boolean | Date | object[] | undefined | null,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      certificate: [validateDate],
      remainingQuota: [validateRequired],
    };
    const validationErrors = validateForm(
      { ...formData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (show) {
      setFormData((prevState) => ({
        ...prevState,
        certificate:
          (state.internalInfo as any)[type + "Certificate"] || new Date(),
        year: (state.internalInfo as any)[type + "Year"],
        remainingQuota: (state.internalInfo as any)[type + "RemainingQuota"],
        info: (state.internalInfo as any)[type + "Info"] ?? prevState.info,
      }));
    }
  }, [show, state.internalInfo, type]);

  const handleFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name === "remainingQuota") {
      validation(name, value, true);
    }
  };

  const handleDateChange = (date: Date | null) => {
    setFormData((prevState) => ({
      ...prevState,
      certificate: date,
    }));
    validation("certificate", date, true);
  };

  const onHideWithReset = () => {
    onHide();
    setErrors({
      certificate: "",
      remainingQuota: "",
    });
  };

  const onOkButtonClick = async (e: React.FormEvent) => {
    e.preventDefault();
    const { name, value } = e.target as HTMLInputElement;
    if (validation(name, value)) {
      dispatch({
        type: "UPDATE_INTERNAL_INFO_FIELD",
        field: type + "Certificate",
        value: formData.certificate,
      });
      dispatch({
        type: "UPDATE_INTERNAL_INFO_FIELD",
        field: type + "Year",
        value: formData.year,
      });
      dispatch({
        type: "UPDATE_INTERNAL_INFO_FIELD",
        field: type + "RemainingQuota",
        value: formData.remainingQuota,
      });
      dispatch({
        type: "UPDATE_INTERNAL_INFO_FIELD",
        field: type + "Info",
        value: formData.info,
      });
      onHide();
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHideWithReset}
      centered
      className={`${className} `}
    >
      <Modal.Header closeButton title="Close" className="border-0 px-0">
        <Modal.Title>
          {title && title.charAt(0).toUpperCase() + title.slice(1)}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-0 border-0">
        <div className="row">
          <div className="col-6 position-relative">
            <Calender
              onChange={(date) => handleDateChange(date || new Date())}
              selectedDate={formData.certificate}
              label={t("Date attestation")}
              isMandatory={false}
              name="certificate"
              error={errors.certificate}
              maxDate={new Date()}
            />
          </div>
          <div className="col-6">
            <LabelWithInputField
              isMandatory={false}
              name="year"
              handleChange={handleFieldChange}
              value={formData.year}
              isDisabled={true}
              id="year"
              label={t("Year")}
              type="text"
              placeholder={t("year")}
            />
          </div>
          <div className="col-6 remaining-section">
            <LabelField title={t("Remaining balance")} isMandatory={true} />
            <div className="input-group field-shadow rounded-3">
              <InputTextfield
                name="remainingQuota"
                handleChange={handleFieldChange}
                value={formData.remainingQuota}
                type="number"
                className="form-control shadow-none border-end-white rounded-end-0"
              />
              <span className="textAppendBg input-group-text justify-content-center field-shadow border-0" id="basic-addon1">
                {type === "student" ? t("hours") : t("days")}
              </span>
            </div>
            <div
              className="text-danger height-20"
              style={{ marginBottom: "0.5vw" }}
            >
              {errors.remainingQuota}
            </div>
          </div>
          <div className="col-12 hideHeight">
            <LabelWithTextAreaField
              name="info"
              handleChange={handleFieldChange}
              label={t("Info")}
              isMandatory={false}
              value={formData.info}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0 px-0">
        {/* <Button
          handleClick={onHideWithReset}
          className="me-3 modal-cancel-btn rounded-3 shadow-none"
        >
          {t("Cancel")}
        </Button> */}
        <Button
          handleClick={onOkButtonClick}
          className="form-button shadow-none"
        >
          {t("OK")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default translate(InternalInfoModal);
