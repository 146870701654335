import React, { useState, ReactNode, Children, isValidElement } from "react";
import LeftArrow from "static/images/LeftArrow";
import RightArrow from "static/images/RightArrow";

interface ActionIconWrapperProps {
  initialVisibleCount?: number; // Number of icons to show initially
  children: ReactNode; // Children to render, the icons in this case
}

const ActionIconWrapper: React.FC<ActionIconWrapperProps> = ({
  initialVisibleCount = 3,
  children,
}) => {
  const [showAll, setShowAll] = useState(false);

  const handleViewMore = () => {
    setShowAll((prev) => !prev);
  };

  // Filter function to exclude empty elements or divs with no content
  const childrenArray = Children.toArray(children).filter((child) => {
    if (isValidElement(child)) {
      // Ensure child has meaningful content (non-empty)
      const hasContent =
        child.props.children &&
        (typeof child.props.children === "string"
          ? child.props.children.trim() !== ""
          : true);

      // Return child only if it has permissions and meaningful content
      return hasContent;
    }
    return false; // Exclude non-valid elements
  });

  const visibleChildren = showAll
    ? childrenArray
    : childrenArray.slice(0, initialVisibleCount);

  // Determine if more icons exist beyond the initialVisibleCount
  const showRightArrow = childrenArray.length > initialVisibleCount;

  return (
    <div className="action-icon-wrapper d-inline-block">
      {visibleChildren.map((child, index) => (
        <div key={index} className="d-inline-block" style={{ marginBottom: showAll ? "0.2vw" : "0.2vw" ,
      marginRight:"0.2vw"}}
        >
          {child}
        </div>
      ))}
      {/* Show 'View More' icon only if there are more valid icons than the initialVisibleCount */}
      {showRightArrow && (
        <div
          className={`${showAll ? "showLessIcon" : ""} d-inline-block`}
          onClick={handleViewMore}
          title={showAll ? "View Less" : "View More"}
          style={{ cursor: "pointer" }}
        >
          {showAll ? <LeftArrow /> : <RightArrow />}
        </div>
      )}
    </div>
  );
};

export default ActionIconWrapper;
