import React from "react";
import ReactLoading from "react-loading";

interface Props {
  iconType?: any;
  color?: string;
  height?: number | string;
  width?: number | string;
  className?: string;
}

const LoadingIcon: React.FC<Props> = ({
    iconType = "bars",
    color = "#ff4dae",
    width = "3vw",
    className='m-auto',
    height="3vw"
}) => {
  return (
    <>
      <ReactLoading
        type={iconType}
        color={color}
        className={className}
        width={width}
        height={height}
      />
    </>
  );
};

export default LoadingIcon;
