import React, { useEffect, useState } from "react";
import { useFormContext } from "../../context/Context";
import * as CONST from "../../annotations/AdminFicheConstants";
import WagePemiums from "./WageAndPremiums/WagePemiums";
import { useParams } from "react-router-dom";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import Title from "components/common/atoms/Title";
import { t } from "components/CentralDataMangement/translation/Translation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import "../../css/AdminFiche.css";
import { useTabUrlUpdater } from "components/common/utlis/TabUtility";
import General from "./GeneralAndWorkAgreements/General";
import { GetAdminFicheData } from "./GetAdminFicheData";
import WorkingHours from "./Timetable/WorkingHours";
import Contacts from "./Contacts/Contacts";

const AdminFicheForm = () => {
  const { state, dispatch } = useFormContext();
  const [isSticky, setIsSticky] = useState(false);
  useTabUrlUpdater(state.tabDetails, dispatch); //To add tabId to url and stay on current tab on refresh
  const { adminFicheId } = useParams<{ adminFicheId: string }>();
  const { agreementId } = useParams<{ agreementId: string }>();

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 46) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (agreementId) {
      GetAdminFicheData(dispatch, agreementId, adminFicheId);
    }
  }, [agreementId]);

  //For handling the tab navigation
  const handleTabClick = (tabId: string) => {
    const currentTab = state.tabDetails.findIndex(
      (tab: any) => tabId === tab.id
    );
    state.tabDetails[currentTab].error = false;
    const updatedTabDetails = state.tabDetails.map((tab) => ({
      ...tab,
      showStatus: tab.id === tabId,
    }));

    dispatch({ type: CONST.UPDATE_TAB_DETAILS, tabDetails: updatedTabDetails });
  };

  const ConstructTabs = (id: string, showStatus: boolean) => {
    switch (id) {
      case "general":
        return <General />;
      case "contacts":
        return state?.general?.encodage === 1 ? <Contacts /> : null;
      case "shiftTables":
        return <WorkingHours />;
      case "wage":
        return <WagePemiums />;
      default:
        break;
    }
  };

  const permissionType = adminFicheId ? "update" : "create";
  const permissionObject: any = {
    permission: "Admin fiche",
  };
  permissionObject[permissionType] = true;
  return (
    <AccessControl
      requiredPermissions={[permissionObject]}
      renderNoAccess={true}
    >
      <Title title={t("Admin fiche")} />
      <div className="position-relative" style={{ paddingTop: "2vw" }}>
        <nav
          className={`admin-fiche-step-tabs createFormTabs ${
            isSticky
              ? "createTabsSticky position-sticky"
              : "admin-fiche-step-tabs-absolute position-absolute w-100"
          }`}
        >
          <div
            className="nav nav-tabs border-0 col-xl-9 m-auto col-lg-10 col-md-6"
            id="nav-tab"
            role="tablist"
          >
            {state.tabDetails.map((item: any, index) =>
              (state?.general?.encodage === 0 || state?.general?.encodage === null) &&
              item.id === CONST.CONTACTS ? null : (
                <button
                  key={item.id}
                  className={`nav-link text-break ${
                    item.error ? "error" : ""
                  } ${item.showStatus ? "active" : "inactive"}`}
                  id={`${item.id}-tab`}
                  data-bs-toggle="tab"
                  data-bs-target={`#${item.id}`}
                  type="button"
                  role="tab"
                  aria-controls={`nav-${item.id}`}
                  aria-selected={item.showStatus}
                  onClick={() => handleTabClick(item.id)}
                  style={{
                    borderRadius: "0.5vw",
                    marginRight: "0.5vw",
                    padding: "0.5vw",
                  }}
                >
                  <div className="candidate-step-number d-flex align-items-center justify-content-center">
                    {t(`${item.title}`)}

                    {item.error && (
                      <span
                        className="exclamation-icon ms-2"
                        title={`${t("Please fill all the mandatory fields")}`}
                        data-toggle="tooltip"
                      >
                        <FontAwesomeIcon icon={faTriangleExclamation} />
                      </span>
                    )}
                  </div>
                </button>
              )
            )}
          </div>
        </nav>

        <div className="tab-content py-0" id="nav-tabContent">
          {state.tabDetails.map((item: any) => (
            <div
              key={item.id}
              className={`tab-pane fade ${item.showStatus ? "show" : ""} ${
                item.showStatus ? "active" : ""
              }`}
              id={item.id}
              role="tabpanel"
              aria-labelledby={`${item.id}-tab`}
            >
              {ConstructTabs(item.id, item.showStatus)}
            </div>
          ))}
        </div>
      </div>
    </AccessControl>
  );
};

export default AdminFicheForm;
