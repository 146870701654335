import { FormProvider } from "./Context";
import CandidateForm from "../create-candidate/formData/CandidateForm";

export const MultiFormProviderCandidate = () => {
  return (
    <>
      <FormProvider>
        <CandidateForm />
      </FormProvider>
    </>
  );
};