import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import { useFormContext } from "components/CandidateCreation/context/Context";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import FileUpload from "components/common/molecules/FileUpload";
import Calender from "components/common/molecules/Calender";
import Button from "components/common/atoms/Button";
import LabelWithTextAreaField from "components/common/molecules/LabelWithTextAreaField";
import { useState } from "react";
import {
  validateDate,
  validateForm,
  validateSelectField,
} from "components/common/services/ValidationService";
import { ValidationRules } from "components/common/utlis/TypeAnnotations";
import CheckBoxField from "components/common/atoms/CheckBoxField";
import { t } from "components/CentralDataMangement/translation/Translation";
import { CertificateStatus } from "Constants";
import { formatDate } from "components/common/utlis/HelperFunctions";
import ModalPopup from "components/common/atoms/ModalPopup";

import { CertificateProps } from "components/CandidateCreation/annotations/CandidateAnnotations";
import EditIcon from "static/images/EditIcon";
import DeleteIcon from "static/images/DeleteIcon";
import LabelField from "components/common/atoms/LabelField";
import ViewIcon from "static/images/ViewIcon";

const Certificates: React.FC = () => {
  //// *******************Certificates section code  start ************
  const { state, dispatch, isViewMode } = useFormContext();

  const [certificateData, setCertificateData] = useState<CertificateProps>({
    id: null,
    group: null,
    attestType: null,
    documentNo: "",
    valid: null,
    validUntill: null,
    addInfo: "",
    empCertificateId: null,
    fileName: "",
    filePath: "",
    isCertificateActive: 1,
  });

  const fileUploadStyleObject: React.CSSProperties = {
    width: "100%",
  };

  const removeFieldSet = (indexToRemove: number) => {
    dispatch({ type: "REMOVE_CERTIFICATE_FIELD", indexToRemove });
  };

  const [isCertificateModalOpen, setCertificateModalOpen] = useState(false);
  const [certificateIndex, setCertificateIndex] = useState(0);

  const handleCertificateFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, type, checked } = event.target as HTMLInputElement;
    let updatedValue: string | number | null = value;
    if (type === "checkbox") {
      updatedValue = checked ? 1 : 0;
    }
    setCertificateData((prevState) => ({
      ...prevState,
      [name]: updatedValue,
    }));
  };

  const handleCertificateDateChange = (date: Date | null, name: string) => {
    setCertificateData((prevState) => ({
      ...prevState,
      [name]: date,
    }));
    validation(name, date, true);
  };

  const handleCertificateSelectChange = (
    selectedOption: any,
    fieldName: string
  ) => {
    if (fieldName === "attestType") {
      prefillGroupBasedOnAttestType(selectedOption);
    }
    setCertificateData((prevState) => ({
      ...prevState,
      [fieldName]: selectedOption,
    }));
    validation(fieldName, selectedOption, true);
  };

  const initialCertificateErrors: { [key: string]: string } = {};
  const [certificateErrors, setCertificateErrors] = useState<{
    [key: string]: string;
  }>(initialCertificateErrors);

  const validation = (
    name: string,
    value: string | boolean | Date | object[] | undefined | null,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      attestType: [validateSelectField],
      valid: [validateDate],
      validUntill: [validateDate],
    };
    const validationErrors = validateForm(
      { ...certificateData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation) {
      setCertificateErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setCertificateErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const certificateUploadResponse = (responseObj: any) => {
    const response = responseObj["response"];
    const fieldNames = ["empCertificateId", "fileName", "filePath"];
    fieldNames.forEach((fieldName: string, key: number) => {
      setCertificateData((prevData) => ({
        ...prevData,
        [fieldName]: response[key],
      }));
    });
  };

  const certificateDeleteResponse = (field: string) => {
    const fieldNames = ["empCertificateId", "fileName", "filePath"];
    fieldNames.forEach((fieldName: string, key: number) => {
      setCertificateData((prevData) => ({
        ...prevData,
        [fieldName]: fieldName.endsWith("Id") ? null : "",
      }));
    });
  };

  const certificateUploadErrors = (errorResposne: any) => {
    setCertificateErrors({
      empCertificateId: errorResposne.error,
    });
  };

  const addCertificateData = (index: number) => {
    setCertificateIndex(index); // Set the index for dispatch
    setCertificateModalOpen(true);
  };

  const editCertificateData = (index: number) => {
    setCertificateIndex(index); // Set the index for dispatch
    setCertificateModalOpen(true);
    const certificateToEdit = state.certificates[index]; // Get the certificate to edit
    if (certificateToEdit) {
      // Populate the certificate data in the modal fields
      setCertificateData({
        id: certificateToEdit.id,
        group: certificateToEdit.group,
        attestType: certificateToEdit.attestType,
        documentNo: certificateToEdit.documentNo,
        valid: certificateToEdit.valid,
        validUntill: certificateToEdit.validUntill,
        addInfo: certificateToEdit.addInfo,
        empCertificateId: certificateToEdit.empCertificateId,
        fileName: certificateToEdit.fileName,
        filePath: certificateToEdit.filePath,
        isCertificateActive: certificateToEdit.isCertificateActive,
      });
    }
  };

  const resetCertificateFields = () => {
    setCertificateData({
      id: null,
      group: null,
      attestType: null,
      documentNo: "",
      valid: null,
      validUntill: null,
      addInfo: "",
      empCertificateId: null,
      fileName: "",
      filePath: "",
      isCertificateActive: 1,
    });
  };

  const resetCertificateErrors = () => {
    setCertificateErrors({
      empCertificateId: "",
      attestType: "",
      valid: "",
      validUntill: "",
    });
  };

  const onOkButtonClick = async (e: React.FormEvent) => {
    e.preventDefault();
    const { name, value } = e.target as HTMLInputElement;
    if (validation(name, value)) {
      dispatch({
        type: "ADD_CERTIFICATE_FIELD",
        data: certificateData,
        index: certificateIndex,
      });
      setCertificateModalOpen(false); // Close modal
      resetCertificateFields();
      resetCertificateErrors();
    }
  };

  const closeCertificateModal = () => {
    setCertificateModalOpen(false);
    resetCertificateFields();
    resetCertificateErrors();
  };

  const prefillGroupBasedOnAttestType = (selectedOption: any) => {
    let prefillGroupOption: any = null;
    if (selectedOption.value !== null) {
      const groupId = state.dropDowndata.attestTypeAndGroupLinking.find(
        (item) => item.id === selectedOption.value
      )?.attest_group_id;
      if (groupId !== undefined) {
        const groupObject = state.dropDowndata.groupList.find(
          (groupObj) => groupObj.attest_group_id === groupId
        );
        prefillGroupOption = groupObject
          ? {
              value: groupObject.id as number,
              label: groupObject.name as string,
            }
          : null;
      }
    }
    setCertificateData((prevState) => ({
      ...prevState,
      group: prefillGroupOption,
    }));
  };

  // ***************** Certificates code end ****************
  return (
    <>
      <div className="row">
        <div className="col-12">
          <LabelField
            title={t("Certficates")}
            className="tab-subtitle pb-0 marginBottomPoint5"
          />
        </div>
        <div className="col-12">
          <div className="pwa table-responsive marginBotttom1">
            <table className="table table-hover">
              <thead>
                <tr className="TableHeader bg-white">
                  <th className="border-0 align-middle ps-lg-4">
                    {t("Group")}
                  </th>
                  <th className="border-0 align-middle">{t("Type")}</th>
                  <th className="border-0 align-middle">{t("Document no")}</th>
                  <th className="border-0 align-middle">{t("Valid from")}</th>
                  <th className="border-0 align-middle">{t("Valid until")}</th>
                  <th className="border-0 align-middle">{t("Status")}</th>
                  <th className="border-0 align-middle">{t("Action")}</th>
                </tr>
              </thead>
              <tbody>
                {state.certificates.map((certificate, index) => (
                  <tr key={index}>
                    <td
                      className="align-middle ps-lg-4 text-break"
                      data-label={t("Group")}
                    >
                      {certificate.group?.label}
                    </td>
                    <td
                      className="align-middle text-break"
                      data-label={t("Type")}
                    >
                      {certificate.attestType?.label}
                    </td>
                    <td
                      className="align-middle text-break"
                      data-label={t("Document no")}
                    >
                      {certificate.documentNo}
                    </td>
                    <td
                      className="align-middle text-break"
                      data-label={t("Valid from")}
                    >
                      {formatDate(certificate.valid)}
                    </td>
                    <td
                      className="align-middle text-break"
                      data-label={t("Valid until")}
                    >
                      {formatDate(certificate.validUntill)}
                    </td>
                    <td className="align-middle text-break">
                      <span
                        className={`d-inline-block rounded-circle status-icon ${
                          certificate.isCertificateActive === 0
                            ? "bg-danger"
                            : "bg-success"
                        }`}
                        data-toggle="tooltip"
                        title={t(
                          `${
                            CertificateStatus[
                              certificate.isCertificateActive ?? 1
                            ]
                          }`
                        )}
                      ></span>
                    </td>
                    <td className="align-middle text-break">
                      <span
                        title={isViewMode ? t("View") : t("Edit")}
                        onClick={() => editCertificateData(index)}
                        className="table-action-icons cursor-pointer rounded-0 shadow-none marginRightPoint5"
                      >
                        {isViewMode ? <ViewIcon /> : <EditIcon />}
                      </span>
                      {!isViewMode && (
                        <span
                          title={t("Delete")}
                          onClick={() => removeFieldSet(index)}
                          className="table-action-icons cursor-pointer rounded-0 shadow-none"
                        >
                          <DeleteIcon />
                        </span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* Certificates section modal popup */}
      <div className="row">
        {!isViewMode && (
          <div className="col-12">
            <Button
              title={"+" + t("Add")}
              handleClick={() => addCertificateData(state.certificates.length)}
              className="form-button float-end shadow-none"
            />
          </div>
        )}
      </div>
      <ModalPopup
        size="xl"
        show={isCertificateModalOpen}
        onHide={closeCertificateModal}
        title={t("Certificate")}
        body={
          <div className="row">
            <div
              className={`col-lg-4 ${isViewMode ? "form disabled" : "form"}`}
            >
              <SelectWithSearch
                title={t("Attest type")}
                isMandatory={true}
                search={true}
                options={state.dropDowndata.attestTypeList}
                id={`attest-${certificateIndex}`}
                onChange={(e) => handleCertificateSelectChange(e, "attestType")}
                isMulti={false}
                name="attestType"
                value={certificateData.attestType}
                error={certificateErrors?.attestType}
                isTranslate={true}
              />
            </div>
            <div
              className={`col-lg-4 ${isViewMode ? "form disabled" : "form"}`}
            >
              <SelectWithSearch
                title={t("Group")}
                isMandatory={false}
                search={true}
                options={[]}
                id={`group-${certificateIndex}`}
                onChange={(e) => handleCertificateSelectChange(e, "group")}
                isMulti={false}
                name="group"
                isDisabled={true}
                value={certificateData.group}
                isTranslate={true}
              />
            </div>
            <div className="col-lg-4 upload-certificate">
              <FileUpload
                id="empCertificateId"
                name="empCertificateId"
                label={t("Upload certificate")}
                isMandatory={false}
                edit={false}
                fileId={certificateData.empCertificateId ?? null}
                fileName={certificateData.fileName ?? ""}
                filePath={certificateData.filePath ?? ""}
                style={fileUploadStyleObject}
                multiple={false}
                uploadPath="candidate-certificate"
                formats=".pdf,.docx,.doc,.jpg,.jpeg"
                returnFileUploadErrors={(errorResponse) =>
                  certificateUploadErrors(errorResponse)
                }
                fileUploadResponse={(uploadResposne) =>
                  certificateUploadResponse(uploadResposne)
                }
                deleteResponse={(field) => certificateDeleteResponse(field)}
                maxFileSize={10 * 1024 * 1024}
                showDeleteIcon={!isViewMode}
                isViewMode={isViewMode}
              />
              <span className="text-danger">
                {certificateErrors?.empCertificateId}
              </span>
            </div>
            <div
              className={`col-lg-4 ${isViewMode ? "form disabled" : "form"}`}
            >
              <LabelWithInputField
                isMandatory={false}
                name="documentNo"
                handleChange={(event) => handleCertificateFieldChange(event)}
                value={certificateData.documentNo}
                id={`documentNo-${certificateIndex}`}
                label={t("Document no")}
                placeholder={t("Document no")}
                type="text"
              />
            </div>
            <div
              className={`col-lg-4 position-relative ${
                isViewMode ? "form disabled" : "form"
              }`}
            >
              <Calender
                onChange={(date) => handleCertificateDateChange(date, "valid")}
                selectedDate={certificateData.valid}
                label={t("Valid from")}
                name="valid"
                maxDate={certificateData.validUntill}
                isMandatory={true}
                error={certificateErrors?.valid}
              />
            </div>
            <div
              className={`col-lg-4 position-relative ${
                isViewMode ? "form disabled" : "form"
              }`}
            >
              <Calender
                onChange={(date) =>
                  handleCertificateDateChange(date, "validUntill")
                }
                selectedDate={certificateData.validUntill}
                label={t("Valid to")}
                name="validUntill"
                isMandatory={true}
                minDate={certificateData.valid}
                error={certificateErrors?.validUntill}
              />
            </div>
            <div
              className={`col-md-12 ${isViewMode ? "form disabled" : "form"}`}
            >
              <LabelWithTextAreaField
                name="addInfo"
                handleChange={(event) => handleCertificateFieldChange(event)}
                label={t("Additional info")}
                value={certificateData.addInfo}
              />
            </div>
            <div
              className={`col-md-6 ${isViewMode ? "form disabled" : "form"}`}
            >
              <CheckBoxField
                label={t("Active")}
                name="isCertificateActive"
                onChangeHandler={handleCertificateFieldChange}
                isChecked={certificateData.isCertificateActive === 1}
                className="document-checkbox"
                id="isCertificateActive"
                lineHeight="1.1vw"
              />
            </div>
            {!isViewMode && (
              <div className="col-12 d-flex justify-content-end">
                <Button
                  handleClick={onOkButtonClick}
                  className="mx-0 form-button shadow-none"
                >
                  {t("Add")}
                </Button>
              </div>
            )}
          </div>
        }
        className="modal-lg documentsCertificates"
      />
      {/* Certificate modal pop-up end */}
    </>
  );
};
export default Certificates;
