import React from "react";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import Title from "components/common/atoms/Title";
import { Link } from "react-router-dom";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";

const BudgetAndTargetsPage: React.FC = () => {
  const cardStyle = {
    className:
      "card border-0 card-shadow justify-content-center h-100",
  };
  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Budget and target",
          read: true,
        },
      ]}
      renderNoAccess={true}
    >
      <div className="row">
        <div className="col-12">
          <Title
            title={t("Budgets and Targets")}
            className="tab-section-title "
          />
          <div className="form-height-dashboard recruiter-action-tabs">
            <div
              className="d-grid pt-1"
              style={{
                gridTemplateColumns: "repeat(4, 1fr)",
                gap: "1.5vw",
              }}
            >
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Office region",
                    read: true,
                  },
                ]}
              >
                <a
                  className="dashboard-tab-links"
                  href="/budget/manage-regions"
                >
                  <div {...cardStyle}>{t("Office region")}</div>
                </a>
              </AccessControl>
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Budget",
                    read: true,
                  },
                ]}
              >
                <a className="dashboard-tab-links" href="/budget/fetch">
                  <div {...cardStyle}>{t("Budgets")}</div>
                </a>
              </AccessControl>
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Daily turnover",
                    read: true,
                  },
                ]}
              >
                <a className="dashboard-tab-links" href="/daily-turnover">
                  <div {...cardStyle}>{t("Daily turnover config")}</div>
                </a>
                <a className="dashboard-tab-links" href="/turnover-overview">
                  <div {...cardStyle}>{t("Daily turnover overview")}</div>
                </a>
              </AccessControl>
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Config targets",
                    read: true,
                  },
                ]}
              >
                <a className="dashboard-tab-links" href="/targets">
                  <div {...cardStyle}>{t("Config targets")}</div>
                </a>
              </AccessControl>
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Target overview",
                    read: true,
                  },
                ]}
              >
                <a className="dashboard-tab-links" href="/target-overview">
                  <div {...cardStyle}>{t("Target overview")}</div>
                </a>
              </AccessControl>
            </div>
          </div>
        </div>
      </div>
      <div className="row backPadding">
        <div className="col-md-6 align-self-center">
          <Link to="/dashboard" className="back-btn text-decoration-underline">
            {t("Back")}
          </Link>
        </div>
      </div>
    </AccessControl>
  );
};

export default translate(BudgetAndTargetsPage);
