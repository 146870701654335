export const NEXT_STEP = "NEXT_STEP";
export const PREVIOUS_STEP = "PREVIOUS_STEP";
export const UPDATE_TAB_DETAILS = "UPDATE_TAB_DETAILS";
export const UPDATE_TAB_ERROR = "UPDATE_TAB_ERROR";
export const BASIC = "basic";
export const GENERAL = "general";
export const REMUNERATION = "remuneration";
export const BILLING = "billing";
export const TIME_TABLE = "timeTable";
export const COST_CENTER = "costCenter";
export const INTERNAL_INFO = "internalInfo";
export const REMOVE_CONTRACT_PERFCODE = "REMOVE_CONTRACT_PERFCODE";
export const ADD_CONTRACT_PERFCODE = "ADD_CONTRACT_PERFCODE";
export const REMOVE_AUTOMATIC_CODE = "REMOVE_AUTOMATIC_CODE";
export const ADD_AUTOMATIC_CODE = "ADD_AUTOMATIC_CODE";
export const REMOVE_DEVIATING_COEFF = "REMOVE_DEVIATING_COEFF";
export const ADD_DEVIATING_COEFF = "ADD_DEVIATING_COEFF";
export const REMOVE_ALLOCATION = "REMOVE_ALLOCATION";
export const ADD_ALLOCATION = "ADD_ALLOCATION";
export const UPDATE_FIELDS = "UPDATE_FIELDS";
export const BASIC_DETAILS = "basicDetails";
export const GENERAL_DETAILS = "generalDetails";
export const REMENUARATION_DETAILS = "remenurationDetails";
export const BILLING_DETAILS = "billingDetails";
export const TIME_TABLE_DETAILS = "timeTableDetails";
export const COST_CENTER_DETAILS = "costCenterDetails";
export const INTERNAL_INFO_DETAILS = "internalInfoDetails";
export const UPDATE_FIELD_ERROR = "UPDATE_FIELD_ERROR";
export const ERRORS = "errors";
export const SET_DROPDOWN_DATA = "SET_DROPDOWN_DATA";
export const DRAFT = "draft";
export const SUBMIT = "submit";
export const PLANNING_DRAFT_MSG = "Contract drafted successfully";
export const NEXT = "next";
export const RESET_TAB_DETAILS = "resetTabDetails";
export const UPDATE_TAB_DATA = "updateTabData";
export const ADD_COST_CENTER = 'addCostCenter';
export const REMOVE_COST_CENTER = 'removeCostCenter';
//api end points
export const PLANNING_DETAILS = "planningDetails";
export const GET_COMPANY_RELATED_DETAILS = "getCompanyRelatedDetails";
export const GET_LOCATION_RELATED_DETAILS = "getLocationRelatedDetails";
export const GET_PLANNING_COEFF_DETAILS = "getPlanningCoeffDetails";
export const STORE_PLANNING_DETAILS = "storePlanningDetails";
export const GET_PLANNING_SHIFT_DETAILS = "getPlanningShiftDetails";
export const GET_PROFILE_DETAILS = "getProfileDetails";
export const FETCH_PLANNINGS = "fetchPlannings";
