import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "Constants";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import Button from "components/common/atoms/Button";
import LabelField from "components/common/atoms/LabelField";
import { ApiCall } from "components/common/services/ApiServices";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TimeField from "react-simple-timefield";
import CustomNotify from "components/common/atoms/CustomNotify";
import { CONF_SETTINGS_GET, CONF_SETTINGS_UPDATE } from "routes/ApiEndpoints";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";

interface TimeRange {
  id?: string | null;
  startTime: string;
  endTime: string;
  age: number;
}

interface FormData {
  year15: TimeRange;
  year16: TimeRange;
  year17: TimeRange;
  year18: TimeRange;
}

interface Props {
  edit?: boolean;
}

const EndTime: React.FC<Props> = ({ edit = false }) => {
  const { id } = useParams<{ id: string }>();
  const [formData, setFormData] = useState<FormData>({
    year15: { id: null, startTime: "", endTime: "", age: 15 },
    year16: { id: null, startTime: "", endTime: "", age: 16 },
    year17: { id: null, startTime: "", endTime: "", age: 17 },
    year18: { id: null, startTime: "", endTime: "", age: 18 },
  });

  useEffect(() => {
    fetchPcAge();
  }, []);

  const fetchPcAge = async () => {
    const data = {
      pcId: id,
      type: "pcAge",
    };
    const url = CONF_SETTINGS_GET;

    const response: any = await ApiCall.service(
      url,
      "POST",
      data,
      true,
      "central-data-management"
    );

    if (response.status === 200) {
      const newFormData = { ...formData }; // Create a new object based on the current state

      response.data.forEach((item: any) => {
        const timeRange: TimeRange = {
          id: item.id?.toString() || null,
          startTime: item.start_working_hours,
          endTime: item.stop_working_hours,
          age: item.age,
        };

        switch (item.age) {
          case 15:
            newFormData.year15 = timeRange;
            break;
          case 16:
            newFormData.year16 = timeRange;
            break;
          case 17:
            newFormData.year17 = timeRange;
            break;
          case 18:
            newFormData.year18 = timeRange;
            break;
        }
      });
      setFormData(newFormData);
    }
  };

  const handleFieldChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    year: string
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [year]: {
        ...prevData[year as keyof FormData],
        [name]: value,
      },
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const { name, value } = e.target as HTMLInputElement;
    const data = { type: "pcAge", pcId: id, case: "time", data: formData };

    let response = await ApiCall.service(
      CONF_SETTINGS_UPDATE,
      "POST",
      data,
      false,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );
    if (response.status === 200) {
      CustomNotify({ type: "success", message: t(response.msg) });
    }
  };

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Paritair committe",
          read: true,
        },
      ]}
      renderNoAccess={true}
    >
      <div className="time-section col-md-12 col-lg-5 col-xxl-4 col-xl-4 my-4">
        <div className="form-border pcEndTime padding1">
          <div>
            <LabelField
              className="tab-subtitle"
              title={t(`${edit ? "Edit e" : "E"}nd time`)}
            />
          </div>
          <div className="row">
            <div className="col-md-12">
              <LabelField title={t("Age") + " 15 " + t("years")} />
              <div className="position-relative marginBottomPoint5 pc-buffer-time">
                <TimeField
                  value={formData.year15.startTime}
                  onChange={(e) => handleFieldChange(e, "year15")}
                  input={
                    <input
                      name={"startTime"}
                      value={formData.year15.startTime}
                      disabled={!edit}
                    ></input>
                  }
                  style={{
                    width: "100%",
                    height: "2.344vw",
                    border: "0px",
                    borderRadius: "0.4vw",
                    paddingLeft: "0.4vw",
                    fontSize: "1vw",
                  }}
                />
                <span className="input-group-text rounded-start-0 rounded-end-3 border-0 position-absolute">
                  {t("Start time")}
                </span>
              </div>
              <div className="position-relative marginBotttom1 pc-buffer-time">
                <TimeField
                  value={formData.year15.endTime}
                  onChange={(e) => handleFieldChange(e, "year15")}
                  input={<input name={"endTime"} disabled={!edit}></input>}
                  style={{
                    width: "100%",
                    height: "2.344vw",
                    border: "0px",
                    borderRadius: "0.4vw",
                    paddingLeft: "0.4vw",
                    fontSize: "1vw",
                  }}
                />
                <span className="input-group-text rounded-start-0 rounded-end-3 border-0 position-absolute">
                  {t("End time")}
                </span>
              </div>
            </div>
            <div className="col-md-12 marginBotttom1">
              <LabelField title={t("Age") + " 16 " + t("years")} />
              <div className="position-relative marginBottomPoint5 pc-buffer-time">
                <TimeField
                  value={formData.year16.startTime}
                  onChange={(e) => handleFieldChange(e, "year16")}
                  input={<input name={"startTime"} disabled={!edit}></input>}
                  style={{
                    width: "100%",
                    height: "2.344vw",
                    border: "0px",
                    borderRadius: "0.4vw",
                    paddingLeft: "0.4vw",
                    fontSize: "1vw",
                  }}
                />
                <span className="input-group-text rounded-start-0 rounded-end-3 border-0 position-absolute">
                  {t("Start time")}
                </span>
              </div>
              <div className="position-relative marginBotttom1 pc-buffer-time">
                <TimeField
                  value={formData.year16.endTime}
                  onChange={(e) => handleFieldChange(e, "year16")}
                  input={<input name={"endTime"} disabled={!edit}></input>}
                  style={{
                    width: "100%",
                    height: "2.344vw",
                    border: "0px",
                    borderRadius: "0.4vw",
                    paddingLeft: "0.4vw",
                    fontSize: "1vw",
                  }}
                />
                <span className="input-group-text rounded-start-0 rounded-end-3 border-0 position-absolute">
                  {t("End time")}
                </span>
              </div>
            </div>
            <div className="col-md-12 marginBotttom1">
              <LabelField title={t("Age") + " 17 " + t("years")} />
              <div className="position-relative marginBottomPoint5 pc-buffer-time">
                <TimeField
                  value={formData.year17.startTime}
                  onChange={(e) => handleFieldChange(e, "year17")}
                  input={<input name={"startTime"} disabled={!edit}></input>}
                  style={{
                    width: "100%",
                    height: "2.344vw",
                    border: "0px",
                    borderRadius: "0.4vw",
                    paddingLeft: "0.4vw",
                    fontSize: "1vw",
                  }}
                />
                <span className="input-group-text rounded-start-0 rounded-end-3 border-0 position-absolute">
                  {t("Start time")}
                </span>
              </div>
              <div className="position-relative marginBotttom1 pc-buffer-time">
                <TimeField
                  value={formData.year17.endTime}
                  onChange={(e) => handleFieldChange(e, "year17")}
                  input={<input name={"endTime"} disabled={!edit}></input>}
                  style={{
                    width: "100%",
                    height: "2.344vw",
                    border: "0px",
                    borderRadius: "0.4vw",
                    paddingLeft: "0.4vw",
                    fontSize: "1vw",
                  }}
                />
                <span className="input-group-text rounded-start-0 rounded-end-3 border-0 position-absolute">
                  {t("End time")}
                </span>
              </div>
            </div>
            <div className="col-md-12 marginBotttom1">
              <LabelField title={t("Age") + " 18 + " + t("years")} />
              <div className="position-relative marginBottomPoint5 pc-buffer-time">
                <TimeField
                  value={formData.year18.startTime}
                  onChange={(e) => handleFieldChange(e, "year18")}
                  input={<input name={"startTime"} disabled={!edit}></input>}
                  style={{
                    width: "100%",
                    height: "2.344vw",
                    border: "0px",
                    borderRadius: "0.4vw",
                    paddingLeft: "0.4vw",
                    fontSize: "1vw",
                  }}
                />
                <span className="input-group-text rounded-start-0 rounded-end-3 border-0 position-absolute">
                  {t("Start time")}
                </span>
              </div>
              <div className="position-relative pc-buffer-time">
                <TimeField
                  value={formData.year18.endTime}
                  onChange={(e) => handleFieldChange(e, "year18")}
                  input={<input name={"endTime"} disabled={!edit}></input>}
                  style={{
                    width: "100%",
                    height: "2.344vw",
                    border: "0px",
                    borderRadius: "0.4vw",
                    paddingLeft: "0.4vw",
                    fontSize: "1vw",
                  }}
                />
                <span className="input-group-text rounded-start-0 rounded-end-3 border-0 position-absolute">
                  {t("End time")}
                </span>
              </div>
            </div>
          </div>
          {edit && (
            <div className="row">
              <div className="col-md-12">
                <Button
                  type="submit"
                  title={t("Save")}
                  handleClick={handleSubmit}
                  className="float-end form-button shadow-none"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </AccessControl>
  );
};
export default translate(EndTime);
