import React, { useEffect, useState } from 'react';
import { t, translate } from '../translation/Translation';
import { GET_SALES_NOTE_MEETING } from "routes/ApiEndpoints";
import Title from 'components/common/atoms/Title';
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from 'Constants';
import { ApiCall } from 'components/common/services/ApiServices';
import { Link, useParams } from 'react-router-dom';

interface MeetingProps {
    meetingType: string;
    meetingDate: string;
    meetingWith: string;
    meetingBy: string;
    status: string;
    office: string;
    summary: string;
    title: string;
}

const SalesNoteMeeting: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [meeting, setMeeting] = useState<MeetingProps>({
        meetingType: "",
        meetingDate: "",
        meetingWith: "",
        meetingBy: "",
        status: "",
        office: "",
        summary: "",
        title: "",
    });

    const fetchMeetingDetails = async () => {
        const url = id ? `${GET_SALES_NOTE_MEETING}/${id}` : `${GET_SALES_NOTE_MEETING}`;
        const response = await ApiCall.getService(
            url,
            "GET",
            CENTRAL_DATA_MANAGEMENT_MICROSERVICE,
            true);

        if (response.status === 200) {
            setMeeting((prevData) => ({
                ...prevData,
                meetingType: response?.data?.meetingType ?? '-',
                meetingDate: response.data?.meetingdate ?? '-',
                meetingWith: response.data.meetingwith ?? '-',
                meetingBy: response.data?.meetingby ?? '-',
                status: response.data?.status ?? '-',
                office: response.data?.office ?? '-',
                summary: response.data?.summary ?? '-',
                title: response.data?.title ?? '-'
            }));
        }
    };

    useEffect(() => {
        fetchMeetingDetails();
    }, []);

    return (
        <div>
            <Title title={t("Meeting")} />
            <div className="form-height-vacancy-overview">
                <div className="row">
                    <div className="col-lg-3 col-5">
                        <div className="vacancyCornerLeftSection h-100">
                            <div className="tab-subtitle">{t("")}</div>
                            <div style={{ marginBottom: "1vw" }}>
                                <span>{t("Meeting title") + ":"}</span>
                                <span className="personal-info ms-2">
                                    {meeting.title ?? "-"}
                                </span>
                            </div>
                            <div style={{ marginBottom: "1vw" }}>
                                <span>{t("Meeting type") + ":"}</span>
                                <span className="personal-info ms-2">
                                    {meeting.meetingType ?? "-"}
                                </span>
                            </div>
                            <div style={{ marginBottom: "1vw" }}>
                                <span>{t("Date") + ":"}</span>
                                <span className="personal-info ms-2">
                                    {meeting.meetingDate ?? "-"}
                                </span>
                            </div>
                            <div style={{ marginBottom: "1vw" }}>
                                <span>{t("With") + ":"}</span>
                                <span className="personal-info ms-2">
                                    {meeting.meetingWith ?? "-"}
                                </span>
                            </div>
                            <div style={{ marginBottom: "1vw" }}>
                                <span>{t("By") + ":"}</span>
                                <span className="personal-info ms-2">
                                    {meeting.meetingBy ?? "-"}
                                </span>
                            </div>
                            <div style={{ marginBottom: "1vw" }}>
                                <span>{t("Status") + ":"}</span>
                                <span className="personal-info ms-2">
                                    {meeting.status ?? "-"}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-9 col-xl-8 col-7">
                        <div className="vacancySubTitle">{t("Summary")}</div>
                        {meeting.summary !== '' ?
                            <div className="mt-3 color-dark-purple salesNoteMeeting">
                                <div dangerouslySetInnerHTML={{ __html: meeting.summary }} />
                            </div> :
                            <div className='text-center' style={{ paddingTop: "5vw" }}>Preparing meeting details ....</div>}
                    </div>
                </div>
            </div>
            <div className="col-md-6 align-self-center py-4">
                <Link
                    to="/manage/sales-note-meetings"
                    className=" back-btn text-decoration-underline"
                >
                    {t("Back")}
                </Link>
            </div>
        </div>
    )
}

export default translate(SalesNoteMeeting);