import {
  TimeStates,
  initialTimeStates,
} from "components/common/utlis/PlanningUtility";
import { Option } from "components/common/utlis/TypeAnnotations";

export interface shiftProps {
  regime: Option | null;
  shift: Option | null;
  timeTableName: string;
  timeTable: TimeStates;
}

export const intialShiftConfig: shiftProps = {
  regime: null,
  shift: null,
  timeTableName: "",
  timeTable: initialTimeStates,
};


// Function to get shift option for a given regime value
export const getShiftOptionForRegime = (value:number) => {
  switch (value) {
      case 1:
          return { value: 3, label: "Day" };
      case 3:
          return { value: 4, label: "Night" };
  }
};

