import { CONF_SETTINGS_EDIT, CONF_SETTINGS_UPDATE } from "routes/ApiEndpoints";
import { useParams } from "react-router-dom";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import { useEffect, useState } from "react";
import { ApiCall } from "components/common/services/ApiServices";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "Constants";
import Button from "components/common/atoms/Button";
import LoadingIcon from "components/common/utlis/LoadingIcon";
import CustomNotify from "components/common/atoms/CustomNotify";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import LabelField from "components/common/atoms/LabelField";

interface Props {
  editableId?: number;
  edit?: boolean;
}

interface FormData {
  coeffDeviations: object[];
}

const PcCoeffDeviations: React.FC<Props> = ({ editableId, edit = false }) => {
  const { id: pcId } = useParams<{ id: string }>();
  const [deviations, setDeviations] = useState<Object[]>([]);
  const [formData, setFormData] = useState<FormData>({
    coeffDeviations: [],
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchDeviations();
  }, []);

  const fetchDeviations = async () => {
    const requestData = {
      id: pcId,
      type: "pcCoeffDeviation",
    };

    const response = await ApiCall.service(
      CONF_SETTINGS_EDIT,
      "POST",
      requestData,
      false,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );

    if (response.status === 200) {
      setDeviations(response.data["options"]);
      formData.coeffDeviations = response.data["selected"];
    }
  };

  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: selectedOption,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    setLoading(true);
    e.preventDefault();

    const data = {
      type: "pcCoeffDeviation",
      pcId: pcId,
      ...formData,
    };
    let response = await ApiCall.service(
      CONF_SETTINGS_UPDATE,
      "POST",
      data,
      false,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );
    if (response.status === 200) {
      CustomNotify({ type: "success", message: t(response.msg) });
    } else if (response.status === 400) {
    }
    setLoading(false);
  };

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Paritair committe",
          read: true,
        },
      ]}
      renderNoAccess={true}
    >
      <div className="col-md-12 col-lg-5 col-xl-4 my-4">
        <div className="form-border padding1">
          <div className="row">
            <div className="col-12">
              <LabelField
                title={t("Coefficient deviations")}
                className="tab-subtitle"
              />
              <div className="pcCoefficientdeviation">
                <SelectWithSearch
                  title={t("Select coefficient deviations")}
                  search={true}
                  options={deviations}
                  placeHolder={t("Select coefficient deviations")}
                  onChange={(e) => handleSelectChange(e, "coeffDeviations")}
                  isMulti={true}
                  isDisabled={!edit}
                  name="coeffDeviations"
                  value={formData.coeffDeviations}
                />
              </div>
            </div>
          </div>

          {edit && (
            <div className="row">
              <div className="col-md-12 text-end">
                {!loading ? (
                  <Button
                    title={t("Save")}
                    type="submit"
                    className="float-end form-button shadow-none"
                    handleClick={handleSubmit}
                  />
                ) : (
                  <LoadingIcon
                    iconType="bars"
                    color="#e55496"
                    height={"3vw"}
                    width={"3vw"}
                    className="ms-auto"
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </AccessControl>
  );
};

export default translate(PcCoeffDeviations);
