import { Reducer } from "react";
import {
    ChildCompetence,
    VacancyFormData
} from "../annotation/VacancyInterfaces";
import { FormAction } from "./Actions";
import * as CONST from "../annotation/VacancyConstants";

const FormReducer: Reducer<VacancyFormData, FormAction> = (
    state: any,
    action: any
) => {
    switch (action.type) {
        case CONST.NEXT_STEP:
            const currentTab = state.tabDetails.find(
                (tab: any) => tab.showStatus
            );
            if (currentTab) {
                const currentIndex = state.tabDetails.findIndex(
                    (tab: any) => tab.id === currentTab.id
                );
                const nextIndex = (currentIndex + 1) % state.tabDetails.length;

                const updatedTabDetails = state.tabDetails.map((tab: any) => ({
                    ...tab,
                    showStatus: tab.id === state.tabDetails[nextIndex].id
                }));

                return {
                    ...state,
                    tabDetails: updatedTabDetails
                };
            }
            return state;

        case CONST.PREVIOUS_STEP:
            const currentTabPrev = state.tabDetails.find(
                (tab: any) => tab.showStatus
            );
            if (currentTabPrev) {
                const currentIndexPrev = state.tabDetails.findIndex(
                    (tab: any) => tab.id === currentTabPrev.id
                );
                const previousIndex =
                    (currentIndexPrev - 1 + state.tabDetails.length) %
                    state.tabDetails.length;

                const updatedTabDetailsPrev = state.tabDetails.map(
                    (tab: any) => ({
                        ...tab,
                        showStatus:
                            tab.id === state.tabDetails[previousIndex].id
                    })
                );

                return {
                    ...state,
                    tabDetails: updatedTabDetailsPrev
                };
            }
            return state;

        case CONST.UPDATE_TAB_DETAILS:
            return {
                ...state,
                tabDetails: action.tabDetails
            };
        case CONST.UPDATE_TAB_ERROR:
            const { tabIndex, error } = action;
            return {
                ...state,
                tabDetails: state.tabDetails.map((tab: any, index: any) => ({
                    ...tab,
                    error: index === tabIndex ? error : tab.error
                }))
            };

        case CONST.UPDATE_FIELDS:
            return {
                ...state,
                [action.template]: {
                    ...state[action.template],
                    [action.field]: action.value
                }
            };

        case CONST.SET_OPTIONS:
            return {
                ...state,
                options: {
                    ...state.options,
                    ...action.payload
                }
            };
        case CONST.UPDATE_FIELD_ERROR:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [action.field]: action.error
                }
            };

        case CONST.UPDATE_COMPETENCES:
            return {
                ...state,
                competences: action.payload
            };
        case CONST.UPDATE_CHILD_COMPETENCES:
            return {
                ...state,
                competences: {
                    ...state.competences,
                    [action.field]: action.value
                }
            };
        case CONST.UPDATE_CHILD_COMPETENCES_DATA:
            if (state.competences && state.competences?.competence) {
                const groupedChildCompetences: {
                    [competenceName: string]: ChildCompetence[];
                } = {};
                state.competences.competence.forEach(function (
                    competence: any
                ) {
                    competence.vacancy_child_competences.forEach(function (
                        childCompetence: any
                    ) {
                        if (
                            !groupedChildCompetences[competence.competence_name]
                        ) {
                            groupedChildCompetences[
                                competence.competence_name
                            ] = [];
                        }

                        groupedChildCompetences[
                            competence.competence_name
                        ].push(childCompetence);
                    });
                });

                return {
                    ...state,
                    competences: {
                        ...state.competences,
                        childCompetences: groupedChildCompetences,
                        selectedCompetenceNames: []
                    }
                };
            }
            return state;

        default:
            return state;
    }
};

export default FormReducer;
