import React from 'react'

const Profile:React.FC = () => {
  return (
    <>
      <svg width="30" height="30" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.69363 3.08686C4.44359 3.43137 3.20204 3.80598 1.97007 4.21036C1.85757 4.2468 1.75757 4.31411 1.68146 4.40461C1.60535 4.49511 1.55619 4.60516 1.53957 4.72224C0.812445 10.1783 2.49244 14.1591 4.49663 16.7815C5.34518 17.9029 6.35698 18.8908 7.49832 19.7123C7.95244 20.0326 8.35407 20.2636 8.67038 20.4119C8.82788 20.4867 8.95651 20.5366 9.05494 20.5667C9.098 20.5818 9.14238 20.5928 9.18751 20.5996C9.2321 20.5923 9.276 20.5813 9.31876 20.5667C9.41851 20.5366 9.54713 20.4867 9.70463 20.4119C10.0196 20.2636 10.4226 20.0312 10.8767 19.7123C12.018 18.8908 13.0298 17.9029 13.8784 16.7815C15.8826 14.1604 17.5626 10.1783 16.8354 4.72224C16.819 4.60511 16.7699 4.49498 16.6937 4.40446C16.6176 4.31393 16.5175 4.24667 16.4049 4.21036C15.5505 3.9308 14.1081 3.47536 12.6814 3.08818C11.2245 2.69311 9.88444 2.40043 9.18751 2.40043C8.49188 2.40043 7.15051 2.6918 5.69363 3.08686ZM5.34451 1.73499C6.76857 1.3478 8.5 1 9.18751 0.891296C10 1 11.6064 1.3478 13.0305 1.73499C14.4874 2.12874 15.9561 2.59468 16.8197 2.87686C17.1808 2.99611 17.5009 3.21479 17.7433 3.50778C17.9857 3.80078 18.1405 4.15621 18.1899 4.53324C18.9722 10.4093 17.157 14.7642 14.9546 17.6451C14.0207 18.8775 12.9071 19.9628 11.6511 20.8647C11.2167 21.1768 10.7566 21.4512 10.2756 21.685C9.90807 21.8582 9.51301 22 9.18751 22C8.86201 22 8.46826 21.8582 8.09944 21.685C7.61843 21.4512 7.15826 21.1768 6.72394 20.8647C5.46792 19.9628 4.35433 18.8775 3.42038 17.6451C1.21801 14.7642 -0.59718 10.4093 0.18507 4.53324C0.234534 4.15621 0.389351 3.80078 0.631735 3.50778C0.87412 3.21479 1.19424 2.99611 1.55532 2.87686C2.80901 2.4658 4.07244 2.08507 5.34451 1.73499Z" fill="currentColor" />
        <path d="M3.6875 17C7.66773 14.1548 10.0731 13.5828 14.6875 17" stroke="currentColor" />
        <circle cx="9.4375" cy="7.75" r="3.25" stroke="currentColor" />
      </svg>

    </>
  )
}

export default Profile