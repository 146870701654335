import React, { useEffect, useState } from "react";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import { OptionProps } from "components/common/utlis/TypeAnnotations";
import { ApiCall } from "components/common/services/ApiServices";
import { mapToSelect } from "components/common/utlis/MapToSelect";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Button from "components/common/atoms/Button";
import Pagination from "components/common/atoms/Pagination";
import CustomNotify from "components/common/atoms/CustomNotify";
import EditIcon from "static/images/EditIcon";
import * as ENDPOINTS from "routes/ApiEndpoints";
import DeleteIcon from "static/images/DeleteIcon";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import ModalPopup from "components/common/atoms/ModalPopup";
import CloneIcon from "static/images/CloneIcon";
import ViewIcon from "static/images/ViewIcon";
import Reset from "static/images/Reset";
import { faMagnifyingGlass, faPlus } from "@fortawesome/free-solid-svg-icons";
import LinkTo from "components/common/atoms/LinkTo";
import "./questions.css";
import Title from "components/common/atoms/Title";
import { Spinner } from "react-bootstrap";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import ResetBtn from "components/common/atoms/ResetBtn";
import SpinnerWrapper from "components/common/atoms/SpinnerWrapper";
import NoRecords from "components/common/atoms/NoRecords";

interface manageData {
  domainName: string;
  functnName: string;
  questionId: number;
  id: number;
}

interface dropDownsProps {
  domain: OptionProps[];
  functn: OptionProps[];
}

const ManageQuestions: React.FC = () => {
  //initiliaze the formData state for search fields
  const [formData, setFormData] = useState<dropDownsProps>({
    domain: [],
    functn: [],
  });
  const [searchParams, setSearchParams] = useSearchParams();

  //initiliaze the state for dropdown values
  const [dropDowns, setDropDowns] = useState({
    functn: [] as OptionProps[],
  });

  //state for to store the functions linked countries
  const [questions, setQuestions] = useState([] as manageData[]);

  //state for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [deleteID, setDeleteId] = useState<number | undefined>();
  // const [isFilter, setIsFilter] = useState<boolean>(true); // Example boolean state

  useEffect(() => {
    fetchQuestionsBySearch();
  }, []);

  //API call for to fetch countries
  const fetchQuestionsBySearch = async (formData = {}, page = 1) => {
    const type = searchParams.get("type");
    const requestData = {
      type: type === null ? "1" : type,
      ...formData,
      page: page,
    };

    const response = await ApiCall.service(
      ENDPOINTS.MANAGE_QUESTIONS,
      "POST",
      requestData,
      false,
      "central-data-management"
    );

    if (response.status === 200) {
      const functions = mapToSelect(response.functn, "function_name");
      setDropDowns({
        functn: functions,
      });
      setQuestions(response.data);
      setTotalPages(response.totalPages);
      setLoading(false);
    }
  };

  //changehandler for search fields
  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: selectedOption,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (formData.domain.length === 0 && formData.functn.length === 0) {
      CustomNotify({
        type: "warning",
        message: t("Search input not provided") + ".",
      });
      fetchQuestionsBySearch();
    } else {
      fetchQuestionsBySearch(formData, 1);
    }
  };

  const handleReset = () => {
    // Clear the selected values in the dropdowns
    setFormData({
      domain: [],
      functn: [],
    });
    setCurrentPage(1);
    fetchQuestionsBySearch();
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    fetchQuestionsBySearch(formData, newPage);
  };

  const deleteQuestions = (id: number | undefined) => {
    setShowModal(true);
    setDeleteId(id);
  };

  const handleModalClose = () => setShowModal(!showModal);

  const handleSaveChanges = async () => {
    setShowModal(false);
    if (deleteID) {
      const url = `${ENDPOINTS.DELETE_QUESTIONS}/${deleteID}`;
      const response = await ApiCall.getService(
        url,
        "GET",
        "central-data-management"
      );
      if (response.status === 200) {
        fetchQuestionsBySearch();
        setCurrentPage(1);
        CustomNotify({ type: "success", message: t(response.msg) });
      }
    }
  };

  const handleTypeFilter = (
    type: string,
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    if (type === "") {
      const currentUrl = window.location.href;
      if (currentUrl.includes("?")) {
        const newUrl = currentUrl.split("?")[0];
        window.location.replace(newUrl);
      }
    } else {
      searchParams.set("type", type);
      setSearchParams(searchParams);
      setFormData({
        domain: [],
        functn: [],
      });
    }

    setCurrentPage(1);
    setLoading(true);
    fetchQuestionsBySearch();
  };

  const type = searchParams.get("type");
  const navigate = useNavigate();

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Questions",
          read: true,
        },
      ]}
      renderNoAccess={true}
    >
      <Title title={t("Questions")} />
      <div className="search-bar">
        <div className="row">
          <div className="col-md-12 vacancy-tabs">
            <button
              className={`link ${
                type === "1" || type === null ? "active" : ""
              } text-decoration-none border-0 bg-transparent marginRight1 ps-0`}
              onClick={(e) => handleTypeFilter("1", e)}
              style={{ cursor: "pointer" }}
            >
              {t("General")}
            </button>
            <button
              className={`link ${
                type === "2" ? "active" : ""
              } text-decoration-none border-0 bg-transparent ps-0`}
              onClick={(e) => handleTypeFilter("2", e)}
              style={{ cursor: "pointer" }}
            >
              {t("Function")}
            </button>
          </div>
        </div>
        <div className="row" style={{ padding: "1vw 0" }}>
          <div className="col-8">
            {type === "2" && (
              <div className="col-md-12 hideHeight hideLabel">
                <SelectWithSearch
                  search={true}
                  options={dropDowns.functn}
                  onChange={(e) => handleSelectChange(e, "functn")}
                  isMulti={true}
                  name="functn"
                  value={formData.functn}
                  isTranslate={true}
                  placeHolder={t("Function")}
                />
              </div>
            )}
          </div>
          <div className="col-4">
            {type === "2" && (
              <div className="d-flex align-items-center">
                <ResetBtn handleResetClick={handleReset} />
                <Button
                  title={t("Search")}
                  type="submit"
                  icon={faMagnifyingGlass}
                  handleClick={handleSubmit}
                  className="form-button shadow-none search-btns text-start position-relative"
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="position-relative tableMainWrapper">
            <AccessControl
              requiredPermissions={[
                {
                  permission: "Questions",
                  create: true,
                },
              ]}
            >
              <div className="row">
                <div className="ManageCreateBtn">
                  <LinkTo
                    pagelink="/add/question"
                    title={t("Add question")}
                    icon={faPlus}
                  />
                </div>
              </div>
            </AccessControl>
            <div
              className="table-responsive tableSection"
              style={{ paddingTop: "2vw" }}
            >
              <table className="table table-hover">
                <thead>
                  <tr className="TableHeader">
                    <th
                      className="align-middle ps-lg-4"
                      style={{ width: "50%" }}
                    >
                      {type === "2" ? t("Function") : t("Category")}
                    </th>
                    <AccessControl
                      requiredPermissions={[
                        {
                          permission: "Questions",
                          update: true,
                          delete: true,
                        },
                      ]}
                      actions={true}
                      strict={false}
                    >
                      <th className="align-middle">{t("Actions")}</th>
                    </AccessControl>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <SpinnerWrapper headerLength={3} />
                  ) : (
                    <>
                      {questions && questions.length > 0 ? (
                        questions.map((question) => (
                          <tr
                            key={question.questionId}
                            className="border-bottom mb-3 box-shadow align-middle"
                          >
                            <td
                              data-label={t("Function")}
                              className="align-middle ps-lg-4"
                            >
                              {type === "2"
                                ? t(`${question.functnName}`)
                                : t("General")}
                            </td>

                            <AccessControl
                              requiredPermissions={[
                                {
                                  permission: "Questions",
                                  update: true,
                                  delete: true,
                                },
                              ]}
                              actions={true}
                              strict={false}
                            >
                              <td className="table-action-icons px-2 align-middle">
                                <div className="d-none d-md-none d-lg-block">
                                  <AccessControl
                                    requiredPermissions={[
                                      {
                                        permission: "Questions",
                                        update: true,
                                      },
                                    ]}
                                  >
                                    <Link
                                      to={`/edit/question/${question.questionId}`}
                                      className="btn p-0 border-0 me-2"
                                      title={t("Edit")}
                                    >
                                      <EditIcon />
                                    </Link>
                                  </AccessControl>
                                  <Link
                                    to={`/view/question/${question.questionId}`}
                                    className="btn p-0 border-0 me-2"
                                    title={t("View")}
                                  >
                                    <ViewIcon />
                                  </Link>
                                  <AccessControl
                                    requiredPermissions={[
                                      {
                                        permission: "Questions",
                                        create: true,
                                      },
                                    ]}
                                  >
                                    <Link
                                      to={`/clone/question/${question.questionId}`}
                                      className="btn p-0 border-0 me-2"
                                      title={t("Clone")}
                                    >
                                      <CloneIcon />
                                    </Link>
                                  </AccessControl>
                                  <AccessControl
                                    requiredPermissions={[
                                      {
                                        permission: "Questions",
                                        delete: true,
                                      },
                                    ]}
                                  >
                                    <button
                                      className="btn p-0 border-0"
                                      title={t("Delete")}
                                      onClick={() =>
                                        deleteQuestions(question.questionId)
                                      }
                                    >
                                      <DeleteIcon />
                                    </button>
                                  </AccessControl>
                                </div>
                                {/* PWA */}
                                <div className="d-block d-md-block d-lg-none">
                                  <AccessControl
                                    requiredPermissions={[
                                      {
                                        permission: "Questions",
                                        update: true,
                                      },
                                    ]}
                                  >
                                    <Link
                                      to={`/edit/question/${question.questionId}`}
                                      className="btn p-0 border-0 w-100"
                                    >
                                      <Button
                                        title={t("Edit")}
                                        className=" mb-2 button-width edit-btn rounded-3"
                                      />
                                    </Link>
                                  </AccessControl>
                                  <Link
                                    to={`/view/question/${question.questionId}`}
                                    className="btn p-0 border-0 w-100"
                                  >
                                    <Button
                                      title={t("View")}
                                      className=" mb-2 button-width delete-btn rounded-3"
                                    />
                                  </Link>
                                  <AccessControl
                                    requiredPermissions={[
                                      {
                                        permission: "Questions",
                                        create: true,
                                      },
                                    ]}
                                  >
                                    <Link
                                      to={`/clone/question/${question.questionId}`}
                                      className="btn p-0 border-0 w-100"
                                    >
                                      <Button
                                        title={t("Clone")}
                                        className=" mb-2 button-width edit-btn rounded-3"
                                      />
                                    </Link>
                                  </AccessControl>
                                  <AccessControl
                                    requiredPermissions={[
                                      {
                                        permission: "Questions",
                                        delete: true,
                                      },
                                    ]}
                                  >
                                    <div className="text-center">
                                      <Button
                                        className="btn p-0 mb-2 button-width delete-btn rounded-3 text-center"
                                        handleClick={() =>
                                          deleteQuestions(question.questionId)
                                        }
                                      >
                                        {t("Delete")}
                                      </Button>
                                    </div>
                                  </AccessControl>
                                </div>
                              </td>
                            </AccessControl>
                          </tr>
                        ))
                      ) : (
                        <NoRecords headerLength={7} />
                      )}
                    </>
                  )}
                </tbody>
              </table>
              {totalPages > 1 && (
                <div className="pagination justify-content-center align-items-center mt-4">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={(newPage) => handlePageChange(newPage)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row backPadding">
        <div className="col-md-6 align-self-center">
          <Link to="/dashboard" className="back-btn text-decoration-underline">
            {t("Back")}
          </Link>
        </div>
      </div>
      <ModalPopup
        show={showModal}
        onHide={handleModalClose}
        title={t("Delete confirmation")}
        body={t("Are you sure want to delete") + "?"}
        onCloseButtonClick={handleModalClose}
        onConfirmButtonClick={handleSaveChanges}
        closeTitle={t("No")}
        confirmTitle={t("Yes")}
      />
    </AccessControl>
  );
};

export default translate(ManageQuestions);
