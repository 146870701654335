import { ApiCall } from 'components/common/services/ApiServices';
import React, { useEffect, useState } from 'react';
import Button from 'components/common/atoms/Button';
import { Option, OptionProps, ValidationRules } from 'components/common/utlis/TypeAnnotations';
import { LabelWithInputField } from 'components/common/molecules/LabelWithInputField';
import SelectWithSearch from 'components/common/atoms/SelectWithSearch';
import CheckBoxField from 'components/common/atoms/CheckBoxField';
import CloseFile from 'static/images/CloseFile';
import { mapToSelect } from 'components/common/utlis/MapToSelect';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { validateSelectField, validateForm, validateRequired, validateMultiSelectField } from 'components/common/services/ValidationService';
import { ADD_CHECKLIST, GET_CHECKLIST } from 'routes/ApiEndpoints';
import CustomNotify from 'components/common/atoms/CustomNotify';
import Title from 'components/common/atoms/Title';
import { t, translate } from 'components/CentralDataMangement/translation/Translation';
import AccessControl from 'components/common/services/RolesAndPermissions/AccessControl';

interface ChecklistProps {
  [key: string]: string | number | null | boolean | Option | object[];
  checkName: string;
  checkCat: Option | null;
  extensions: object[];
  mandatory: boolean;
}

const Checklist: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const isView = window.location.pathname.includes('view');
  const text = isView ? "View" : "Edit";
  const navigate = useNavigate();
  const initialState: ChecklistProps = {
    checkName: '',
    checkCat: null,
    extensions: [],
    mandatory: false,
  };

  const [dropdowns, setDropdowns] = useState({
    checkFlowCat: [] as OptionProps[],
    checkCat: [] as OptionProps[],
    extensions: [] as OptionProps[],
  });
  const [checkFlowCat, setCheckFlowCat] = useState<string>("");
  const [checklist, setChecklist] = useState<ChecklistProps[]>([initialState]);
  const initialErrors: { [key: string]: string }[] = [];
  const [errors, setErrors] =
    useState<{ [key: string]: string }[]>(initialErrors);

  const fetchChecklistDetails = async () => {
    const url = id ? GET_CHECKLIST + `/${id}` : GET_CHECKLIST;
    const response = await ApiCall.getService(url, "GET", "central-data-management");
    if (response.status === 200) {
      const checkFlowCatOptions = mapToSelect(response.data["checkFlowCat"]);
      const docCatOptions = mapToSelect(response.data["documentCat"]);
      const extensionOptions = mapToSelect(response.data["fileExtensions"]);

      if (Array.isArray(response.data["checklistDetails"]) && response.data["checklistDetails"].length > 0) {
        const checklistData = response.data["checklistDetails"].map((list: any) => {
          return {
            checkName: list.checkName,
            checkCat: list.checkCat,
            extensions: list.extensions,
            mandatory: list.mandatory,
          };
        });
        setChecklist(checklistData);
      }
      const checkFlowCat = response.data["checklistDetails"][0]?.checkFlowCat["label"];
      setCheckFlowCat(checkFlowCat);

      setDropdowns({
        checkFlowCat: checkFlowCatOptions,
        checkCat: docCatOptions,
        extensions: extensionOptions,
      });
    }
  }

  useEffect(() => {
    fetchChecklistDetails();
    // eslint-disable-next-line
  }, []);

  const addFields = (): void => {
    const newField: ChecklistProps = {
      checkName: '',
      checkCat: null,
      extensions: [],
      mandatory: false,
    };
    setChecklist([...checklist, newField]);
  }
  const removeFields = (index: number): void => {
    const newFields: ChecklistProps[] = [...checklist];
    newFields.splice(index, 1);
    setChecklist(newFields);
    const newErrors = [...errors];
    newErrors.splice(index, 1);
    setErrors(newErrors);
  };
  const validation = (
    name: string,
    value: string | boolean | number | null | Option | object[],
    index: number,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      checkName: [validateRequired],
      checkCat: [validateSelectField],
      extensions: [validateMultiSelectField],
    };

    const validationErrors = validateForm(
      { ...checklist[index], [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    setErrors((prevErrors) => {
      const newErrors = [...prevErrors];

      if (isSingleFieldValidation) {
        if (!newErrors[index]) {
          newErrors[index] = {};
        }
        newErrors[index][name] = validationErrors[name];
      } else {
        newErrors[index] = validationErrors;
      }

      return newErrors;
    });

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }

    return true;
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { name, value, checked } = e.target;
    const newFields: ChecklistProps[] = [...checklist];
    if (name === 'mandatory') {
      newFields[index] = {
        ...newFields[index],
        [name]: checked,
      };
    } else {
      newFields[index] = {
        ...newFields[index],
        [name]: value,
      };
    }
    setChecklist(newFields);
    validation(name, value, index, true);
  }

  const handleSelectChange = (
    selectedOption: any,
    fieldName: string,
    index: number
  ) => {
    const newFields: ChecklistProps[] = [...checklist];
    newFields[index] = {
      ...newFields[index],
      [fieldName]: selectedOption,
    };
    setChecklist(newFields);
    validation(fieldName, selectedOption, index, true);
  };

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>
  ) => {
    const data = { checklist, checkFlowCat };
    let hasErrors = false;
    for (let i = 0; i < checklist.length; i++) {
      const { name, value } = checklist[i];
      const validationResult = validation(name as string, value, i);
      if (validationResult !== true) {
        hasErrors = true;
      }
    }

    if (!hasErrors) {
      const url = id ? ADD_CHECKLIST + `/${id}` : ADD_CHECKLIST;
      const response = await ApiCall.service(url, "POST", data, false, "central-data-management");
      if (response.status === 200) {
        CustomNotify({ type: "success", message: t(response.msg) });
        navigate('/manage-checklist');
      }
    };
  }

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Candidate",
          read: true,
        },
      ]}
      renderNoAccess={true}
    >
      <div>
        <Title title={t(`${text} checklist : ${checkFlowCat}`)} />
        <div>
          <form onSubmit={handleSubmit}>
            <div className="form-border">
              {checklist.map((checklist, index) => (
                <div
                  key={index}
                  className="field-set p-3 mb-4 rounded-4 bg-white"
                >
                  {index > 0 && (
                    <div className="row mb-3">
                      <div className="col-md-12">
                        <>
                          <div className="d-block d-md-block d-lg-none">
                            <Button
                              title={t("Remove")}
                              handleClick={() => removeFields(index)}
                              className="link-button float-end  rounded-0 shadow-none mt-4"
                              disabled={isView}
                            />
                          </div>
                          {!isView && (
                            <div className="d-none d-md-none d-lg-block">
                              <span
                                title={t("Remove")}
                                onClick={() => removeFields(index)}
                                className="table-action-icons cursor-pointer float-end  rounded-0 shadow-none"
                              >
                                <CloseFile />
                              </span>
                            </div>
                          )}
                        </>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div className="col-sm-12 col-md-6">
                      <LabelWithInputField
                        isMandatory={true}
                        name="checkName"
                        handleChange={(event) =>
                          handleInputChange(event, index)
                        }
                        value={checklist.checkName}
                        id={`checkName-${index}`}
                        label={t("Checklist name")}
                        type="text"
                        error={errors[index]?.checkName}
                        isDisabled={isView}
                      />
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <SelectWithSearch
                        search={true}
                        options={dropdowns.checkCat}
                        id={`checkCat-${index}`}
                        onChange={(e) =>
                          handleSelectChange(e, "checkCat", index)
                        }
                        isMulti={false}
                        name="checkCat"
                        title={t("Checklist category")}
                        value={checklist.checkCat}
                        isMandatory={true}
                        error={errors[index]?.checkCat}
                        isDisabled={isView}
                      />
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <SelectWithSearch
                        search={true}
                        id={`extensions-${index}`}
                        name="extensions"
                        title={t("Checklist allowed extensions")}
                        options={dropdowns.extensions}
                        onChange={(e) =>
                          handleSelectChange(e, "extensions", index)
                        }
                        isMulti={true}
                        value={checklist.extensions}
                        isMandatory={true}
                        error={errors[index]?.extensions}
                        isDisabled={isView}
                      />
                    </div>
                    <div className="col-sm-12 col-md-12">
                      <CheckBoxField
                        label={t("Mandatory")}
                        name="mandatory"
                        onChangeHandler={(event) =>
                          handleInputChange(event, index)
                        }
                        isChecked={checklist.mandatory}
                        disable={isView}
                      />
                    </div>
                  </div>
                </div>
              ))}
              {!isView &&  <div className="row my-4">
                <div className="col-md-12">
                  <Button
                    title={"+ " + t("Add another")}
                    handleClick={addFields}
                    className="form-button float-end px-3 shadow-none"
                    disabled={isView}
                  />
                </div>
              </div>}
             
            </div>
            <div className="row py-4">
              <div className="col-md-4 align-self-center">
                <Link
                  to="/manage-checklist"
                  className=" back-btn text-decoration-underline"
                >
                  {t("Back")}
                </Link>
              </div>
              {!isView && (
                <div className="col-md-8 text-end">
                  <Button
                    title={t("Save")}
                    handleClick={handleSubmit}
                    className="form-button float-end px-3 shadow-none"
                  />
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </AccessControl>
  );
};
export default translate(Checklist);
