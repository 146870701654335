import { ReactNode, createContext, useContext, useReducer } from "react";
import { Dispatch } from "./Actions";
import { initialState } from "../annotations/AdminFicheInitialState";
import FormReducer from "./AdminFicheReducer";
import { AdminFicheFormData } from "../annotations/AdminFicheInterface";

interface FormProviderProps {
  children: ReactNode;
}

const FormContext = createContext<
  | {
      state: AdminFicheFormData;
      dispatch: Dispatch;
    }
  | undefined
>(undefined);

export const useFormContext = () => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error("useFormContext must be used within a FormProvider");
  }
  return context;
};

export const FormProvider: React.FC<FormProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(FormReducer, initialState);

  return (
    <FormContext.Provider value={{ state, dispatch }}>
      {children}
    </FormContext.Provider>
  );
};
