import { Link, useLocation, useParams } from "react-router-dom";
import "./paritaircommittee.css";
import Button from "components/common/atoms/Button";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import Title from "components/common/atoms/Title";
import { ReactNode, useEffect, useState } from "react";
import { ApiCall } from "components/common/services/ApiServices";
import PcCommon from "./PcCommon";
import ParitairCommittee from "./ParitairCommittee";
import { CONF_SETTINGS_EDIT } from "routes/ApiEndpoints";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "Constants";
import EndTime from "./EndTime";
import PcAge from "./PcAge";
import { scrollToBottom } from "components/common/services/ValidationService";
import PcDomain from "./PcDomain";
import ExistingFunctions from "./ExistingFunctions";
import PcCoeffDeviations from "./PcCoeffDeviations";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import PcEmployeeTypes from "./PcEmployeeTypes";

interface FunctionItem {
  id: number;
  function_name: string;
  min_salary: null | number;
  status: boolean;
  created_at: null | string;
  updated_at: null | string;
  created_by: number;
  updated_by: number;
  bbright_id: number;
  link_id: number;
}

interface DomainItem {
  id: number;
  domain_name: string;
  min_salary: null | number;
  status: boolean;
  created_at: null | string;
  updated_at: null | string;
  created_by: number;
  updated_by: number;
  bbright_id: number;
  functions: FunctionItem[];
}

interface PcItem {
  id: number;
  pc_number: string;
  pc_name: string;
  pc_alias_name: string;
  status: boolean;
  type: number;
  pc_unique_key: string;
  created_at: null | string;
  updated_at: null | string;
  created_by: number;
  updated_by: number;
  bbright_id: number;
  full_paid_age: null | number;
  min_start_age: null | number;
  start_working_hours: null | string;
  stop_working_hours: null | string;
  max_hours_plannable: null | string;
  min_hours_plannable: null | string;
  buffer_hrs_btw_plannings: null | string;
  max_working_hours_wk_warning: null | string;
  max_working_hours_wk_error: null | string;
  domains: { [key: string]: DomainItem };
}

const EditPc: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [subForm, setSubForm] = useState<ReactNode | null>(null);
  const [pc, setPcDetails] = useState({} as PcItem);
  const location = useLocation();
  const edit = location.pathname.includes("edit");

  useEffect(() => {
    fetchParitairCommitee();
  }, []);

  const fetchParitairCommitee = async () => {
    const data = {
      id: id,
      type: "paritairCommittee",
    };

    const response = await ApiCall.service(
      CONF_SETTINGS_EDIT,
      "POST",
      data,
      true,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );

    if (response.status === 200) {
      setPcDetails(response.data);
    }
  };

  const handleEdit: React.MouseEventHandler<Element> = (event) => {
    const { domains, ...pcDetails } = pc;
    setSubForm(<ParitairCommittee pc={{ ...pcDetails }} />);
    scrollToBottom();
  };

  const handleAge: React.MouseEventHandler<Element> = (event) => {
    setSubForm(<PcAge />);
  };

  const handleEmpType: React.MouseEventHandler<Element> = (event) => {
    setSubForm(<PcEmployeeTypes employeeCategory={pc.type} />);
  };

  const handleEndTime: React.MouseEventHandler<Element> = (event) => {
    setSubForm(<EndTime edit={edit} />);
  };

  const handleCoeffDev: React.MouseEventHandler<Element> = (event) => {
    setSubForm(<PcCoeffDeviations editableId={pc.id} edit={edit} />);
  };

  const getForm = (prop: string, key: string, id?: any) => {
    if (prop === "category") {
      setSubForm(<PcDomain key={key} domainId={id} />);
    } else if (prop === "function") {
      setSubForm(<ExistingFunctions key={key} funcLinkId={id} />);
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const permissionType = id ? "update" : "create";
  const permissionObject: any = {
    permission: "Paritair committe",
  };
  permissionObject[permissionType] = true;
  return (
    <AccessControl
      requiredPermissions={[permissionObject]}
      renderNoAccess={true}
    >
      <Title
        title={
          edit ? t("Edit paritair committee") : t("View paritair committee")
        }
      />
      <div className="form-height-dashboard">
        <div className="row">
          {edit && (
            <div className="d-flex justify-content-end">
              <Button
                title={t("Add domain")}
                className="marginRightPoint5 form-button shadow-none"
                handleClick={() => getForm("category", "add")}
              />
              <Button
                title={t("Add function")}
                className="form-button shadow-none"
                handleClick={() => getForm("function", "add")}
              />
            </div>
          )}
          <div
            className={
              subForm == null
                ? "col-md-12"
                : "col-md-12 col-lg-7 col-xl-8 col-xxl-8 position-relative"
            }
          >
            <div className={"position-relative my-4"}>
              <div className="form-border edit-pc-form">
                <div className="edit-pc-structure position-relative">
                  <ul className="list-unstyled">
                    <li className="structure-li">
                      <PcCommon
                        eventHandlers={{
                          onClickEdit: handleEdit,
                          onClickAge: handleAge,
                          onClickEmpType: handleEmpType,
                          onClickEndTime: handleEndTime,
                          onCoeffDev: handleCoeffDev,
                        }}
                        header={{
                          name: pc.pc_name,
                          number: pc.pc_number,
                          id: pc.id,
                          pcType: pc.type,
                        }}
                        type="parent"
                      />
                      {Object.values(pc.domains ?? {}).map((domain, index) => (
                        <ul key={index} className="list-unstyled">
                          <li>
                            <div
                              className="manage-pc-function-without-domain position-relative"
                              style={{ marginLeft: "3vw" }}
                            >
                              {domain.id === undefined ? (
                                <ul className="list-unstyled manage-pc-function-without-domain">
                                  <li className="py-3">
                                    <div className="p-3 bg-white rounded-3 current-color manage-pc-without-domain ms-3">
                                      <strong>
                                        {t("Functions without Domain")}
                                      </strong>
                                    </div>
                                  </li>
                                </ul>
                              ) : (
                                <ul className="list-unstyled manage-pc-domain" style={{paddingTop:"1vw"}}>
                                  <li>
                                    <PcCommon
                                      handleClick={() =>
                                        getForm("category", "edit", domain.id)
                                      }
                                      className={" marginLeft1 position-relative paddingBottom1"}
                                      type="child"
                                      header={{
                                        name: domain.domain_name,
                                        number: "",
                                      }}
                                      minSalary={domain.min_salary}
                                    />
                                  </li>
                                </ul>
                              )}
                              <ul className="list-unstyled">
                                <li className="manage-pc-function-with-domain">
                                  {Array.isArray(domain.functions) &&
                                  domain.functions.length > 0 ? (
                                    domain.functions.map((func, index) => (
                                      <PcCommon
                                        handleClick={() =>
                                          getForm(
                                            "function",
                                            "edit",
                                            func.link_id
                                          )
                                        }
                                        key={index}
                                        className={
                                          "merginLeft2 position-relative paddingBottom1 domain-function-section"
                                        }
                                        type="subchild"
                                        header={{
                                          name: func.function_name,
                                          number: null,
                                        }}
                                        minSalary={func.min_salary}
                                      />
                                    ))
                                  ) : (
                                    <p>
                                      {t(
                                        "No functions available for this domain."
                                      )}
                                    </p>
                                  )}
                                </li>
                              </ul>
                            </div>
                          </li>
                        </ul>
                      ))}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {subForm}
        </div>
      </div>
      <div className="col-md-6 align-self-center py-4">
        <Link to="/manage-pc" className=" back-btn text-decoration-underline">
          {t("Back")}
        </Link>
      </div>
    </AccessControl>
  );
};
export default translate(EditPc);
