import { OptionProps } from "./TypeAnnotations";

//CAUTION
//THIS FUNCTION WILL RETURN KEY OR INDEX AS (value) AND LABEL IS VALUE OF THAT KEY
export function mapEnumToSelect(data: any[]): OptionProps[] {
  if (data.length === 0) {
    return [];
  }
  return Object.entries(data).map(([key, value]) => ({
    value: parseInt(key),
    label: value
  }));
}