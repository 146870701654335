import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import React, { ReactElement, ReactNode } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

interface CommonModalProps {
  size?: "sm" | "lg" | "xl";
  show: boolean;
  onHide?: () => void;
  title: string;
  body: ReactNode | string;
  onCloseButtonClick?: () => void;
  onConfirmButtonClick?: (event?: any) => void;
  onButtonClick?: () => void;
  closeTitle?: string;
  confirmTitle?: string;
  buttonTitle?: string;
  className?: string;
  inputClass?: string;
  headerClassName?: string;
  showCloseButton?: boolean;
  isInnerHTML?: boolean;
  modalBodyClassName?: string;
}

const ModalPopup: React.FC<CommonModalProps> = ({
  size,
  show,
  onHide,
  title,
  body,
  onCloseButtonClick,
  onConfirmButtonClick,
  onButtonClick,
  closeTitle,
  confirmTitle,
  buttonTitle,
  className,
  inputClass,
  headerClassName,
  showCloseButton = true,
  isInnerHTML = false,
  modalBodyClassName,
}) => {
  return (
    <>
      <Modal
        size={size}
        show={show}
        onHide={onHide}
        centered
        className={`${className}`}
      >
        <Modal.Header
          closeButton={showCloseButton}
          title={t("Close")}
          className={`modal-border px-0 ${headerClassName}`}
        >
          <Modal.Title className={`${inputClass}`}>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={`px-0 ${modalBodyClassName}`}>
          {!isInnerHTML && <div>{body}</div>}
          {isInnerHTML && typeof body === "string" && (
            <div dangerouslySetInnerHTML={{ __html: body }} />
          )}
        </Modal.Body>
        <Modal.Footer className="border-0 px-0 d-block">
          <div className="row">
            <div className="col-4 ps-0">
              {buttonTitle && onButtonClick && (
                <Button
                  onClick={onButtonClick}
                  className="me-3 modal-cancel-btn shadow-none"
                >
                  {buttonTitle}
                </Button>
              )}
            </div>
            <div className="col-8 ms-auto pe-0">
              <div className="d-flex align-items-center justify-content-end">
                {closeTitle && onCloseButtonClick && (
                  <Button
                    onClick={onCloseButtonClick}
                    className="marginRightPoint5 modal-cancel-btn shadow-none"
                  >
                    {closeTitle}
                  </Button>
                )}
                {confirmTitle && onConfirmButtonClick && (
                  <Button
                    onClick={onConfirmButtonClick}
                    className="mx-0 modal-confirm-btn shadow-none"
                  >
                    {confirmTitle}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default translate(ModalPopup);
