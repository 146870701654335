import { GenerateRefreshToken } from "./RefreshTokenService";
import EncryptDecryptService from "./EncryptDecryptService";
import store, { RootState } from "store";
import secureLocalStorage from "react-secure-storage";
/*
 *Call to the API
 *@param urlendpoint=urlendpoint of the API
 *@param httpmethod=METHOD
 *@param data=data to the API
 *@returns response from the API
 */

type Headers = {
  "Content-Type": string;
  Authorization: any;
  //   Access-Control-Allow-Origin": string
};

const getCurrentUserId = () => {
  const currentState: RootState = store.getState(); // Get the current state
  const userId = currentState.auth.userId;
  const role = currentState.auth.role;
  return {userId, role};
};

async function getService(endpoint: string = "", method: string = "GET",microService = 'identity-manager',   loadingParam: boolean = false,) {
  const externalElement = document.getElementById('overlay') as HTMLDivElement | null;
  if (externalElement && loadingParam) {
    externalElement.style.display = "flex";
  }

  const refreshToken = secureLocalStorage.getItem('refresh_token');
  let token;
  if (refreshToken === '' || refreshToken === null) {
    token = GenerateRefreshToken();
  } else {
    const refreshTokenObj = JSON.parse(refreshToken as string);
    token = refreshTokenObj.token;
  }

  const headers: Headers = {
    "Content-Type": "application/json",
    // "Access-Control-Allow-Origin": "*",
    Authorization: token,
  };
  try {
    const {userId, role} = getCurrentUserId();
    const appLangKey = secureLocalStorage.getItem('site_lang') ?? 'en-source';
    const dataObj = { userId: userId, userRole: role, appLangKey:appLangKey}
    const url = `${process.env.REACT_APP_SERVICE_REGISTRY}service/${microService}/${endpoint}`;
    const response = await fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        method: "GET",
        data: EncryptDecryptService.encryptData(JSON.stringify(dataObj)),
      }),
    });
    const data = await response.json();

    // return data;


    let decrypteddata = await JSON.parse(
      EncryptDecryptService.decryptData(data)
    );
    if (externalElement && loadingParam) {
      externalElement.style.display = "none";
    }
    return decrypteddata;

  } catch (error) {

  }
}

async function service(
  endpoint: string = "",
  method: string = "POST",
  dataObj: any = "",
  loadingParam: boolean = false,
  microService:string = "identity-manager",
  entryptDecrypt: boolean = true
) {
  try {
    const {userId, role} = getCurrentUserId();
    const externalElement = document.getElementById('overlay') as HTMLDivElement | null;
    if (externalElement && loadingParam) {
      externalElement.style.display = "flex";
    }

    const refreshToken = secureLocalStorage.getItem('refresh_token');
    const appLangKey = secureLocalStorage.getItem('site_lang') ?? 'en-source';

    let token;
    
    if (refreshToken === '' || refreshToken === null) {
      token = GenerateRefreshToken();
    } else {
      const refreshTokenObj = JSON.parse(refreshToken as string);
      token = refreshTokenObj.token;
    }
    
    const headers: Headers = {
      "Content-Type": "application/json",
      // "Access-Control-Allow-Origin": "*",
      Authorization: token,
    };
    // const dataObj = {userId: userId, role: role};
    dataObj = {...dataObj, userId: userId, userRole: role, appLangKey:appLangKey}
    const url = `${process.env.REACT_APP_SERVICE_REGISTRY}service/${microService}/${endpoint}`;
    const response = await fetch(url, {
      method: method,
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      referrer: "no-referrer",
      redirect: "follow",
      body: JSON.stringify({
        method: "POST",
        data: entryptDecrypt ? EncryptDecryptService.encryptData(JSON.stringify(dataObj)) : dataObj,
      }),
      headers: headers,
    });
    const data = await response.json();
    
    // return data;

    let decrypteddata = entryptDecrypt ? await JSON?.parse(
      EncryptDecryptService?.decryptData(data)
    ) : data;
    if (externalElement && loadingParam) {
      externalElement.style.display = "none";
    }
    return decrypteddata;
  } catch (error) {
    console.log(error);
  }
}

async function fileSendService(
  endpoint: string = "",
  method: string = "POST",
  dataObj: any = "",
  loadingParam: boolean = false,
  microService:string = "identity-manager",

) {
  try {
    const externalElement = document.getElementById('overlay') as HTMLDivElement | null;
    if (externalElement && loadingParam) {
      externalElement.style.display = "flex";
    }

    const refreshToken = secureLocalStorage.getItem('refresh_token');
    let token;
    
    if (refreshToken === '' || refreshToken === null) {
      token = GenerateRefreshToken();
    } else {
      const refreshTokenObj = JSON.parse(refreshToken as string);
      token = refreshTokenObj.token;
    }
    
    const headers: Headers = {
      "Content-Type": "multipart/form-data",
      // "Access-Control-Allow-Origin": "*",
      Authorization: token,
    };
    const url = `${process.env.REACT_APP_SERVICE_REGISTRY}service/${microService}/${endpoint}`;
    const response = await fetch(url, {
      method: method,
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      referrer: "no-referrer",
      redirect: "follow",
      body: JSON.stringify({
        method: "POST",

        // data: dataObj,

        data: EncryptDecryptService.encryptData(JSON.stringify(dataObj)),

      }),
      headers: headers,
    });
    const data = await response.json();
    
    // return data;

    let decrypteddata = await JSON.parse(
      EncryptDecryptService.decryptData(data)
    );
    if (externalElement && loadingParam) {
      externalElement.style.display = "none";
    }
    return decrypteddata;
  } catch (error) {
    console.log(error);
  }
}

export const ApiCall = {
  service,
  getService,
  fileSendService,
};
