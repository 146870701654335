import React, { ChangeEvent } from "react";

interface Props {
  name?: string;
  value?: string | number;
  ischecked?: boolean;
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  label?: React.ReactNode;
  customStyle?: React.CSSProperties;
  id?: string;
  className?: string;
  disable?: boolean;
}

const RadioField: React.FC<Props> = ({
  name = "",
  value = "",
  ischecked = false,
  handleChange,
  label,
  customStyle = {},
  id = "",
  className,
  disable = false,
}) => {
  return (
    <>
      <label style={{ cursor: "pointer", lineHeight:'1.5vw' }} className={className || ""}>
        <input
          style={{ ...customStyle, ...{ marginRight: "0.5vw" } }}
          type="radio"
          name={name}
          checked={ischecked}
          onChange={handleChange}
          id={id}
          value={value}
          disabled={disable}
          className="form-check-input shadow-none cursor-pointer select_border min-width1em"
        />
        {label}
      </label>
    </>
  );
};

export default RadioField;
