import { Outlet, useNavigate } from "react-router-dom";
import Header from "./Header";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { addUserDetails, selectAuth } from "../../../features/auth/AuthSlice";
import { ApiCall } from "../services/ApiServices";
import { GET_USER_PERMISSIONS } from "../../../routes/ApiEndpoints";
import { IDENTITY_MANAGER_MICROSERVICE } from "Constants";
import { useDispatch } from "react-redux";
import Sidebar from "dashboard/leftSideBar/Sidebar";
import AutoLogout from "../utlis/Autologout";

export const NewRootLayout = () => {
  const navigate = useNavigate();
  const authData = useSelector(selectAuth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!authData.isAuthenticated) {
      navigate("/login");
    }
  }, [navigate, authData]);

  useEffect(() => {
    if (authData.isAuthenticated) {
      getUserPermissions();
    }
  }, [authData.isAuthenticated]);

  const getUserPermissions = async () => {
    const userId = { id: authData.userId };
    await ApiCall.service(
      GET_USER_PERMISSIONS,
      "POST",
      userId,
      false,
      IDENTITY_MANAGER_MICROSERVICE
    ).then((response) => {
      if (response && response.status === 200) {
        const userPermissions = {
          userPermissions: response.data.permission,
          isSuperAdmin: response.data.isSuperAdmin,
        };
        dispatch(addUserDetails(userPermissions));
      } else {
        console.error(response.message);
      }
    });
  };

  return (
    <>
      <AutoLogout />
      <div className="container-fluid">
        <div className="d-flex">
          <Sidebar authData={authData} />
          <div className="page-width">
            <div className="page-header position-sticky" style={{paddingLeft:"1vw", paddingRight:"1vw"}}>
              <Header />
            </div>
            <div className="body-height bg-white" >
              <div style={{paddingLeft:"1vw", paddingRight:"1vw"}} className="mainWrapper">
              <Outlet /> {/* This is where nested routes are rendered */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default NewRootLayout;
