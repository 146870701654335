const EmployeeTypeIcon: React.FC = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 36.291 18.776">
                <path id="Union_118" data-name="Union 118" d="M25.637,18.776H9.893s0-.023,0-.059c.042-.472.108-1.207.18-2H0s0-.021.007-.053c.1-1.1.334-3.775.464-4.819a1.247,1.247,0,0,1,.944-1.073c.344-.091.752-.2,1.183-.308v0c.776-.2,1.578-.41,2-.525l2.886,2.008,2.907-2.008c.709.218,2.461.656,3.181.835a1.258,1.258,0,0,1,.831.7l.536-.144,3.169,2.2,3.189-2.2c.153.047.351.1.576.165a1.228,1.228,0,0,1,.85-.723c.346-.091.752-.2,1.183-.308l0,0c.774-.2,1.576-.41,2-.525l2.886,2.008L31.7,9.941c.707.218,2.459.656,3.181.835a1.292,1.292,0,0,1,.94,1.073c.158,1.241.47,4.835.474,4.871H26.155c.1,1.108.182,2.035.183,2.054h-.7Zm0,0ZM36.29,16.721h0ZM16.13,10.286a3.241,3.241,0,0,1-1.1-1.869l-.452-2.524a3.4,3.4,0,0,1,.791-2.684,3.3,3.3,0,0,1,1.1-.841,3.259,3.259,0,0,1,1.405-.314h.57a3.253,3.253,0,0,1,1.4.314,3.316,3.316,0,0,1,1.1.841,3.406,3.406,0,0,1,.79,2.684l-.49,2.524a3.2,3.2,0,0,1-1.082,1.869,3.15,3.15,0,0,1-4.047,0ZM26.783,8.231a3.263,3.263,0,0,1-1.1-1.87l-.452-2.524a3.4,3.4,0,0,1,.792-2.683,3.326,3.326,0,0,1,1.1-.841A3.261,3.261,0,0,1,28.533,0h.57a3.251,3.251,0,0,1,1.4.314,3.333,3.333,0,0,1,1.1.841,3.4,3.4,0,0,1,.79,2.683l-.491,2.524a3.193,3.193,0,0,1-1.08,1.87,3.152,3.152,0,0,1-4.048,0Zm-21.306,0a3.24,3.24,0,0,1-1.1-1.87L3.927,3.838A3.444,3.444,0,0,1,4.044,2.38,3.387,3.387,0,0,1,4.72,1.155a3.312,3.312,0,0,1,1.1-.841A3.266,3.266,0,0,1,7.229,0H7.8A3.26,3.26,0,0,1,9.2.314a3.306,3.306,0,0,1,1.1.841A3.4,3.4,0,0,1,11.1,3.838l-.491,2.524a3.185,3.185,0,0,1-1.081,1.87,3.151,3.151,0,0,1-4.047,0Z" fill="currentColor"/>
            </svg>
        </>
    );
}

export default EmployeeTypeIcon;