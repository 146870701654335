import React, { useState } from "react";
import Navigation from "../form-navigation/Navigation";
import { useFormContext } from "../../context/Context";
import CheckBoxField from "components/common/atoms/CheckBoxField";
import { t } from "components/CentralDataMangement/translation/Translation";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import LabelWithTextAreaField from "components/common/molecules/LabelWithTextAreaField";
import {
  INTERNAL_INFO_DETAILS,
  UPDATE_FIELDS,
} from "../../annotation/PlanningConstants";
import Allocation from "./Allocation";

const InternalInfoTab: React.FC = () => {
  const { state, dispatch } = useFormContext();

  const [validationStatus, setValidationStatus] = useState({
    isValid: false,
    type: "",
  });

  const validStatus = (validation: any) => {
    setValidationStatus({
      isValid: validation.isValid,
      type: validation.type,
    });
  };

  const handleSelectChange = (selectedOption: any, fieldName: string) =>
    dispatch({
      type: UPDATE_FIELDS,
      tab: INTERNAL_INFO_DETAILS,
      field: fieldName,
      value: selectedOption,
    });
  {
  }

  const handleFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, type } = event.target;
    let updatedValue: string | null | number = value;
    if (type === "checkbox" && event.target instanceof HTMLInputElement) {
      updatedValue = event.target.checked ? 1 : 0;
    }
    dispatch({
      type: UPDATE_FIELDS,
      tab: INTERNAL_INFO_DETAILS,
      field: name,
      value: updatedValue,
    });
  };

  return (
    <>
      <div className="form-border" style={{paddingTop:"3.4vw"}}>
        <div className="row">
          <div className="col-3 hotlistWrapper">
            <CheckBoxField
              label={t("Contract late?")}
              name="contractLate"
              onChangeHandler={handleFieldChange}
              isChecked={state.internalInfoDetails.contractLate === 1}
              className="me-5"
            />
          </div>
          <div className="col-3">
            <LabelWithInputField
              isMandatory={false}
              name="reasonForLate"
              handleChange={handleFieldChange}
              value={state.internalInfoDetails.reasonForLate}
              id="lateReason"
              label={t("Reason for late")}
              placeholder={t("Reason for late")}
            />
          </div>
          <div className="col-md-3 hotlistWrapper">
            <CheckBoxField
              label={t("Contract terminated")}
              name="contractTerminated"
              onChangeHandler={handleFieldChange}
              isChecked={state.internalInfoDetails.contractTerminated === 1}
              className="me-5"
            />
          </div>
          <div className="col-md-3">
            <LabelWithInputField
              isMandatory={false}
              name="reasonForDiscontinue"
              handleChange={handleFieldChange}
              value={state.internalInfoDetails.reasonForDiscontinue}
              id="disContinueReason"
              label={t("Reason for discontinuation")}
              placeholder={t("Reason for discontinuation")}
            />
          </div>
          <div className="col-md-6">
            <SelectWithSearch
              title={t("Selection partner")}
              isMandatory={false}
              search={true}
              options={state.dropDowndata.selectionPartnerOptions}
              onChange={(e) => handleSelectChange(e, "selectionPartner")}
              isMulti={false}
              name="selectionPartner"
              value={state.internalInfoDetails.selectionPartner}
              id="selectionPartner"
              isTranslate={true}
            />
          </div>
          <div className="col-md-6">
            <SelectWithSearch
              title={t("Segment")}
              isMandatory={false}
              search={true}
              options={state.dropDowndata.segmentOptions}
              onChange={(e) => handleSelectChange(e, "segment")}
              isMulti={false}
              name="segment"
              value={state.internalInfoDetails.segment}
              id="segment"
              isTranslate={true}
            />
          </div>
          <div className="col-md-12">
            <LabelWithTextAreaField
              name="internalInfo"
              handleChange={handleFieldChange}
              label={t("Internal info")}
              isMandatory={false}
              value={state.internalInfoDetails.internalInfo}
            />
          </div>
          <Allocation />
        </div>
      </div>
      <Navigation validStatus={validStatus} />
    </>
  );
};

export default InternalInfoTab;
