import React, { useState } from "react";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import { t } from "components/CentralDataMangement/translation/Translation";
import { useFormContext } from "../../context/Context";
import ModalPopup from "components/common/atoms/ModalPopup";
import { AllocationProps } from "../../annotation/PlanningInterface";
import * as CONST from "../../annotation/PlanningConstants";
import Button from "components/common/atoms/Button";
import EditIcon from "static/images/EditIcon";
import DeleteIcon from "static/images/DeleteIcon";
import { ValidationRules } from "components/common/utlis/TypeAnnotations";
import {
  validateForm,
  validateMinValue,
  validateSelectField,
} from "components/common/services/ValidationService";
import LabelField from "components/common/atoms/LabelField";
import InputTextfield from "components/common/atoms/InputTextField";
import { selectAuth } from "features/auth/AuthSlice";
import { useSelector } from "react-redux";

const Allocation: React.FC = () => {
  const { state, dispatch } = useFormContext();

  const [formData, setFormData] = useState<AllocationProps>({
    id: null,
    office: null,
    consultant: null,
    percentage: "",
  });

  const userAuth = useSelector(selectAuth);

  const intialErrors: { [key: string]: string } = {};
  const [errors, setErrors] = useState<{
    [key: string]: string;
  }>(intialErrors);

  const validation = (
    name: string,
    value: string | number | null,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      office: [validateSelectField],
      consultant: [validateSelectField],
      percentage: [validateMinValue],
    };
    const validationErrors = validateForm(
      { ...formData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const removeFieldSet = (indexToRemove: number) => {
    dispatch({ type: CONST.REMOVE_ALLOCATION, indexToRemove });
  };

  const [modal, setModal] = useState(false);
  const [index, setIndex] = useState(0);

  const addData = (index: number) => {
    setIndex(index); // Set the index for dispatch
    setModal(true);
  };

  const editData = (index: number) => {
    setIndex(index); // Set the index for dispatch
    setModal(true);
    const allocationToEdit = state.internalInfoDetails.allocations[index]; // Get the certificate to edit
    if (allocationToEdit) {
      // Populate the  data in the modal fields
      setFormData({
        id: allocationToEdit.id,
        office: allocationToEdit.office,
        consultant: allocationToEdit.consultant,
        percentage: allocationToEdit.percentage,
      });
    }
  };

  const resetFields = () => {
    setFormData({
      id: null,
      office: null,
      consultant: null,
      percentage: "",
    });
  };

  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: selectedOption,
    }));
    if (selectedOption && fieldName === "codeOption") {
      const [code, description] = selectedOption?.label.split(" - ") || [];
      setFormData((prevData) => ({
        ...prevData,
        codeField: code,
        description: description,
      }));
    }
    if (fieldName === "automation") {
      setFormData((prevData) => ({
        ...prevData,
        skipAbsence: 0,
      }));
    }
    validation(fieldName, selectedOption, true);
  };

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type } = event.target as HTMLInputElement;
    let updatedValue: string | number | null = value;
    if (name === "percentage") {
      // Filter input to allow only numbers and commas
      updatedValue = value.replace(/[^0-9,]/g, "");
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: updatedValue,
    }));
    validation(name, updatedValue, true);
  };

  const onOkButtonClick = async (e: React.FormEvent) => {
    e.preventDefault();
    const { name, value } = e.target as HTMLInputElement;
    if (validation(name, value)) {
      dispatch({
        type: CONST.ADD_ALLOCATION,
        data: formData,
        index: index,
      });
      setModal(false); // Close modal
      resetFields();
      setErrors({});
    }
  };

  const closeModal = () => {
    setModal(false);
    resetFields();
    setErrors({});
  };

  return (
    <>
      <div>
        <ModalPopup
          size="xl"
          show={modal}
          onHide={closeModal}
          title={t("Allocation")}
          body={
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-6">
                <SelectWithSearch
                  title={t("Office")}
                  isMandatory={true}
                  search={true}
                  options={state.dropDowndata.officeOptions}
                  id={`office-${index}`}
                  onChange={(e) => handleSelectChange(e, "office")}
                  isMulti={false}
                  name="office"
                  value={formData.office}
                  isTranslate={true}
                  error={errors.office}
                />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6">
                <SelectWithSearch
                  title={t("Consultant")}
                  isMandatory={true}
                  search={true}
                  options={state.dropDowndata.consultantOptions}
                  id={`consultant-${index}`}
                  onChange={(e) => handleSelectChange(e, "consultant")}
                  isMulti={false}
                  name="consultant"
                  value={formData.consultant}
                  isTranslate={true}
                  error={errors.consultant}
                />
              </div>
              <div className="col-6">
                <LabelField title={t("Percentage")} isMandatory={true} />
                <div className="input-group field-shadow rounded-3 remunerationInputGroup">
                  <InputTextfield
                    name="percentage"
                    handleChange={handleFieldChange}
                    value={formData.percentage}
                    type="text"
                    className="form-control shadow-none border-0 rounded-start-3 rounded-end-0"
                    id={`percentage-${index}`}
                  />
                  <span
                    className="textAppendBg input-group-text justify-content-center field-shadow border-0"
                    id="basic-addon1"
                  >
                    {"%"}
                  </span>
                </div>
                <div className="text-danger">{errors.percentage}</div>
              </div>
              <div className="col-12 d-flex justify-content-end">
                <Button
                  handleClick={onOkButtonClick}
                  className="modal-confirm-btn shadow-none px-4"
                >
                  {t("Add")}
                </Button>
              </div>
            </div>
          }
          className="modal-lg contractPerfCodeModal"
        />
      </div>
      <div>
        <LabelField title={t("Allocations")} className="tab-title" />
        {/* Certificates section table display data */}
        <div className="pwa table-responsive">
          <table className="table table-hover">
            <thead>
              <tr className="border-0 TableHeader bg-white">
                <th className="border-0 align-middle" style={{ width: "25%" }}>
                  {t("Office")}
                </th>
                <th className="border-0 align-middle" style={{ width: "25%" }}>
                  {t("Consultant")}
                </th>
                <th className="border-0 align-middle" style={{ width: "25%" }}>
                  {t("Percentage")}
                </th>
                {userAuth.role === "ADMIN" && (
                  <th
                    className="border-0 align-middle"
                    style={{ width: "25%" }}
                  >
                    {t("Action")}
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {state.internalInfoDetails.allocations.map(
                (allocation, index) => (
                  <tr key={index}>
                    <td
                      className="align-middle text-break"
                      data-label={t("Office")}
                    >
                      {allocation.office?.label}
                    </td>
                    <td
                      className="align-middle text-break"
                      data-label={t("Consultant")}
                    >
                      {allocation.consultant?.label}
                    </td>
                    <td
                      className="align-middle text-break"
                      data-label={t("Percentage")}
                    >
                      {allocation.percentage}%
                    </td>
                    {userAuth.role === "ADMIN" && (
                      <td className="align-middle text-break">
                        <span
                          title={t("Edit")}
                          onClick={() => editData(index)}
                          className="table-action-icons cursor-pointer rounded-0 shadow-none me-2"
                        >
                          <EditIcon />
                        </span>
                        <span
                          title={t("Delete")}
                          onClick={() => removeFieldSet(index)}
                          className="table-action-icons cursor-pointer rounded-0 shadow-none"
                        >
                          <DeleteIcon />
                        </span>
                      </td>
                    )}
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
        {/* Certificates section modal popup */}
        <div className="row">
          <div className="col-12">
            <Button
              title={"+ " + t("Add")}
              handleClick={() =>
                addData(state.internalInfoDetails.allocations.length)
              }
              className="form-button float-end px-3 shadow-none"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Allocation;
