import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import Button from "components/common/atoms/Button";
import Title from "components/common/atoms/Title";
import LabelWithCKEditorField from "components/common/molecules/LabelWithCKEditorField";
import React, { useEffect, useState } from "react";
import "./css/todos.css";
import { OptionProps, Option } from "components/common/utlis/TypeAnnotations";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import CloseFile from "static/images/CloseFile";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import { ApiCall } from "components/common/services/ApiServices";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "Constants";
import { mapToSelect } from "components/common/utlis/MapToSelect";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";

interface Interview {
  [key: string]: string | null | Option | number;
  feedback: string;
  wageCandidte: string;
  wageVacancy: string;
  negotiationStatus: Option | null;
  wageDiscussionId: number | null;
}

const WageDiscusisionPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [vacancySalary, setVacancySalary] = useState("");
  const [candidateSalary, setCandidateSalary] = useState("");
  const [formDataLength, setFormDataLength] = useState(0); // to disable the wage details alredy present

  const [formData, setFormData] = useState<Interview[]>([
    {
      wageDiscussionId: null,
      feedback: "",
      wageCandidte: "",
      wageVacancy: vacancySalary,
      negotiationStatus: null,
    },
  ]);
  const {
    todoExtensionId,
    companyId,
    candidateId,
    todoId,
    vacancyId,
    presentationId,
  } = useParams<{
    todoExtensionId: string;
    companyId: string;
    candidateId: string;
    todoId: string;
    vacancyId: string;
    presentationId: string;
  }>();

  const [activeButton, setActiveButton] = useState(0);
  const [negotiatedWage, setNegotiatedWage] = useState("");

  const [dropDown, setDropDown] = useState({
    negotiationStatusOptions: [] as OptionProps[],
  });

  const handleCKEditorChange = (
    event: any,
    editor: any,
    fieldName: string,
    index: number
  ) => {
    const content = editor.getData();
    setFormData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = {
        ...updatedData[index],
        [fieldName]: content,
      };
      return updatedData;
    });
  };

  const handleAccept = async (event: any, type: string) => {
    event.preventDefault();
    const data = {
      submitType: type,
      formData: formData,
      todoExtensionId: todoExtensionId,
      companyId: companyId,
      candidateId: candidateId,
      todoId: todoId,
      stage: "Wage",
      todoType: "wageDiscussion",
      presentationId: presentationId,
      negotiatedWage: negotiatedWage,
    };
    const response = await ApiCall.service(
      "updateTodo",
      "POST",
      data,
      true,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );
    if (response.status === 200) {
      navigate(
        location.state?.origin === "todos" ? "/todos/overview" : "/dashboard"
      );
    }
  };

  const addFieldSet = () => {
    const newFields = {
      feedback: "",
      wageCandidte: candidateSalary,
      wageVacancy: vacancySalary,
      negotiationStatus: null,
      wageDiscussionId: null,
    };
    setFormData((prevData: any) => [...prevData, newFields]);
  };

  const removeFieldSet = (currentIndex: number) => {
    setFormData((prevData) => {
      const updatedData = [...prevData];
      updatedData.splice(currentIndex, 1);
      return updatedData;
    });
    const isNegotiationStatus = formData.some(
      (item) => item.negotiationStatus?.value === 2
    );
    if (isNegotiationStatus) {
      setActiveButton(0);
    } else {
      setActiveButton(1);
    }
  };

  const handleDynamicFieldChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    index: number
  ) => {
    const { name, value, type } = e.target;
    const newValue = value.replace(/[^0-9,]/g, "");
    setFormData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = {
        ...updatedData[index],
        [name]: newValue,
      };
      return updatedData;
    });
  };
  const handleDynamicSelectChange = (
    selectedOption: any,
    name: string,
    index: number
  ) => {
    setFormData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = {
        ...updatedData[index],
        [name]: selectedOption !== null ? selectedOption : "",
      };
      return updatedData;
    });
    if (selectedOption.value === 2) {
      setActiveButton(1);
    } else {
      setActiveButton(0);
    }
  };

  useEffect(() => {
    const fetchWageDiscussionDetails = async () => {
      const data = {
        todoExtensionId: todoExtensionId,
        companyId: companyId,
        candidateId: candidateId,
        vacancyId: vacancyId !== 'undefined' && vacancyId !== 'null' && vacancyId !== '' ? vacancyId : null,
        presentationId: presentationId,
      };
      const response = await ApiCall.service(
        "getWageDiscussionDetails",
        "POST",
        data,
        true,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.status === 200) {
        setDropDown((prevData) => ({
          ...prevData,
          negotiationStatusOptions: mapToSelect(response.data.negotationStatus),
        }));
        if (response.data.wageDetails.length > 0) {
          setFormData(response.data.wageDetails);
          setFormDataLength(response.data.wageDetails.length);
        } else {
          const newFields = {
            wageDiscussionId: null,
            feedback: "",
            wageCandidte: response.data.candidateSalary,
            wageVacancy: response.data.vacancySalary,
            negotiationStatus: null,
          };
          setFormData([newFields]);
        }
        setCandidateSalary(response.data.candidateSalary);
        setVacancySalary(response.data.vacancySalary);
      }
    };
    fetchWageDiscussionDetails();
  }, []);

  const handleFieldChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    if (name === "negotiatedWage") {
      setNegotiatedWage(value);
    }
  };
  return (
    <>
      {/* <AccessControl
        requiredPermissions={[
          {
            permission: "Candidate",
            read: true,
          },
        ]}
        renderNoAccess={true}
      > */}
      <div>
        <Title title={t("Wage discussion")} />
        <form>
          <div className="form-border mb-4">
            <div>
              {formData.map((item, index) => (
                <>
                  <div className="row" key={index}>
                    <div className="col-md-12">
                      {index > 0 && (
                        <>
                          {index + 1 > formDataLength && (
                            <>
                              <div className="d-block d-md-block d-lg-none">
                                <Button
                                  title={t("Remove")}
                                  handleClick={() => removeFieldSet(index)}
                                  className="link-button float-end rounded-0 shadow-none mt-4"
                                />
                              </div>
                              <div className="d-none d-md-none d-lg-block">
                                <span
                                  title={t("Delete")}
                                  onClick={() => removeFieldSet(index)}
                                  className="table-action-icons cursor-pointer float-end rounded-0 shadow-none"
                                >
                                  <CloseFile />
                                </span>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 col-sm-12">
                      <div className="position-relative euroWrapper">
                        <LabelWithInputField
                          isMandatory={false}
                          name="wageVacancy"
                          handleChange={(e) =>
                            handleDynamicFieldChange(e, index)
                          }
                          value={item.wageVacancy}
                          id="wageVacancy"
                          label={t("Wage as per vacancy")}
                          placeholder={t("")}
                          isDisabled={index < formDataLength ? true : false}
                        />
                        <div className="position-absolute end-0 euroSign rounded-end-3">
                          &#8364;
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                      <div className="position-relative euroWrapper">
                        <LabelWithInputField
                          isMandatory={false}
                          name="wageCandidte"
                          handleChange={(e) =>
                            handleDynamicFieldChange(e, index)
                          }
                          value={item.wageCandidte}
                          id="wageCandidte"
                          label={t("Wage desired by candidate")}
                          placeholder={t("")}
                          isDisabled={index < formDataLength ? true : false}
                        />
                        <div className="position-absolute end-0 euroSign rounded-end-3">
                          &#8364;
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                      <SelectWithSearch
                        title={t("Negotiation status")}
                        name="negotiationStatus"
                        placeHolder={t("Select")}
                        isMandatory={false}
                        search={true}
                        options={dropDown.negotiationStatusOptions}
                        value={item.negotiationStatus}
                        onChange={(e) =>
                          handleDynamicSelectChange(
                            e,
                            "negotiationStatus",
                            index
                          )
                        }
                        isMulti={false}
                        className="select-field"
                        isDisabled={index < formDataLength ? true : false}
                        isTranslate={true}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <LabelWithCKEditorField
                        label={t("Feedback")}
                        name="feedback"
                        value={item.feedback}
                        placeholder={t("Type here") + "..."}
                        handleChange={(event, editor) =>
                          handleCKEditorChange(event, editor, "feedback", index)
                        }
                        className="field-shadow"
                        isDisabled={index < formDataLength ? true : false}
                      />
                    </div>
                  </div>
                </>
              ))}
            </div>
            {activeButton !== 1 && (
              <div className="row my-4">
                <div className="col-md-12">
                  <Button
                    title={"+ " + t("Add another")}
                    handleClick={addFieldSet}
                    className="form-button float-end px-3 shadow-none"
                  />
                </div>
              </div>
            )}
            {activeButton === 1 && (
              <div className="row my-4">
                <div className="col-md-6">
                  <div className="position-relative euroWrapper">
                    <LabelWithInputField
                      isMandatory={false}
                      name="negotiatedWage"
                      handleChange={handleFieldChange}
                      value={negotiatedWage}
                      id="negotiatedWage"
                      label={t("Final agreed wage")}
                      placeholder={t("")}
                    />
                    <div className="position-absolute end-0 euroSign rounded-end-3">
                      &#8364;
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-12 d-flex flex-row justify-content-end">
                <Button
                  title={t("Reject")}
                  type="submit"
                  handleClick={(e) => handleAccept(e, "reject")}
                  className="delete-btn px-3 shadow-none me-3"
                />
                <Button
                  title={t("Accept")}
                  type="submit"
                  handleClick={(e) => handleAccept(e, "accept")}
                  className="form-button px-3 shadow-none"
                />
              </div>
            </div>
          </div>
            <div className="row backPadding">
              <div className="col-md-6 align-self-center">
                <Link
                  to={
                    location.state?.origin === "todos"
                      ? "/todos/overview"
                      : "/dashboard"
                  }
                  className="back-btn text-decoration-underline"
                >
                  {t("Back")}
                </Link>
              </div>
            </div>
        </form>
      </div>
      {/* </AccessControl> */}
    </>
  );
};

export default translate(WageDiscusisionPage);
