import React, { useState } from "react";
import Button from "components/common/atoms/Button";
import { useFormContext } from "components/CandidateCreation/context/Context"; // Modify the import path accordingly
import {
  scrollToTop,
  validateEmail,
  validateMultiSelectField,
  validatePhoneNumber,
  validateRequired,
  validateSelectField,
  validateTextFieldAlpha,
} from "components/common/services/ValidationService";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import LoadingIcon from "components/common/utlis/LoadingIcon";
import { ApiCall } from "components/common/services/ApiServices";
import { VALIDATION_FOR_CANDIDATE_FIELDS } from "routes/ApiEndpoints";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "Constants";
import CustomNotify from "components/common/atoms/CustomNotify";
import { selectAuth } from "features/auth/AuthSlice";
import { useSelector } from "react-redux";
import { getNextTabId } from "components/common/utlis/TabUtility";
import ModalPopup from "components/common/atoms/ModalPopup";
import { useSSNorEmailValidation } from "../formContent/tabContentComponents/SSNOrEmailValidation";

export interface MandatoryFields {
  [key: string]: string[];
}

interface NavProps {
  validStatus: (value: { isValid: boolean; type: string }) => void;
  isLoading?: boolean;
}

const Navigation: React.FC<NavProps> = ({ validStatus, isLoading = false }) => {
  const navigate = useNavigate();
  const { state, dispatch, isViewMode } = useFormContext();
  const { candidateId } = useParams<{ candidateId: string }>();
  const location = useLocation();
  const type = location.state?.origin === "manageUsers" ? "user" : "candidate";
  const userAuth = useSelector(selectAuth);

  //SSN and email validation pop up

  ////******** SSN OR EMAIL VALIDATION ****
  const {
    canId,
    SSNorEmailModal,
    modalTitle,
    emailOrSSNModalBody,
    closeModal,
    handleCandidate,
    checkForSSNorEmailExistence,
  } = useSSNorEmailValidation();
  /* */

  const validateMandatoryFields = (type: string) => {
    const activeTab = state.tabDetails.find((tab) => tab.showStatus);
    const activeTabId = activeTab?.id;
    const generalFields = [
      "firstName",
      "lastName",
      "mobNumber",
      "email",
      "street",
      "number",
      "zipCode",
      "city",
      "country",
      "office",
      "countryOfBirth",
      "drivingLicence",
    ];
    // Check the country value and add the appropriate city field
    if (state.general.country?.value === 29) {
      const cityIndex = generalFields.indexOf("cityOther");

      if (cityIndex > -1) {
        generalFields.splice(cityIndex, 1);
      }
      // Clear error for cityOther if country is 29
      dispatch({
        type: "UPDATE_FIELD_ERROR",
        fieldName: "cityOther",
        error: "",
      });
      generalFields.push("city");
    } else {
      // Remove "city" if it exists, ensuring only "cityOther" is validated
      const cityIndex = generalFields.indexOf("city");
      if (cityIndex > -1) {
        generalFields.splice(cityIndex, 1);
      }

      dispatch({
        type: "UPDATE_FIELD_ERROR",
        fieldName: "city",
        error: "",
      });

      generalFields.push("cityOther");
    }

    if (userAuth.role === "CANDIDATE" || userAuth.role === "EMPLOYER") {
      const index = generalFields.indexOf("office");
      if (index !== -1) {
        generalFields.splice(index, 1);
      }
    }
    const mandatoryFieldMapping: { [key: string]: string[] } =
      type === "draft"
        ? {
            general: ["firstName", "lastName", "mobNumber", "email"],
          }
        : {
            general: generalFields,
            jobProfile: ["desiredFunctions"],
          };

    // Check if functionAliasName is not empty and remove desiredFunctions if true
    if (state.jobProfile?.functionAliasName?.trim() !== "") {
      const jobProfileFields = mandatoryFieldMapping["jobProfile"] || [];
      const desiredFunctionsIndex =
        jobProfileFields.indexOf("desiredFunctions");
      if (desiredFunctionsIndex > -1) {
        jobProfileFields.splice(desiredFunctionsIndex, 1);
        dispatch({
          type: "UPDATE_FIELD_ERROR",
          fieldName: "desiredFunctions",
          error: "",
        });
      }
    }

    // Remove drivingLicence if state.general.selectionGroup has values 1 or 2
    const selectionGroupValues =
      state.general?.selectionGroup?.map((group) => group?.value) || [];
    if (
      selectionGroupValues.includes(1) ||
      selectionGroupValues.includes(2) ||
      selectionGroupValues.length === 0
    ) {
      const drivingLicenceIndex = generalFields.indexOf("drivingLicence");
      if (drivingLicenceIndex !== -1) {
        generalFields.splice(drivingLicenceIndex, 1);
        dispatch({
          type: "UPDATE_FIELD_ERROR",
          fieldName: "drivingLicence",
          error: "",
        });
      }
    }

    const errorsByTab: { [tabId: string]: { [fieldName: string]: string } } =
      {};
    state.tabDetails
      .slice(0, state.tabDetails.findIndex((tab) => tab.id === activeTabId) + 1)
      .forEach((tab) => {
        const tabId = tab.id;
        let tabFields = {};

        switch (tabId) {
          case "general":
            tabFields = {
              ...state.general,
              drivingLicence: state.other.drivingLicence,
            };
            break;
          case "jobProfile":
            tabFields = state.jobProfile;
            break;
          case "workExperience":
            tabFields = state.workExperience;
            break;
          case "studies":
            tabFields = state.studies;
            break;
          case "employeeDocs":
            tabFields = state.certificates;
            break;
          case "internalInfo":
            tabFields = state.internalInfo;
            break;
          default:
            break;
        }

        const validationRules: {
          [fieldName: string]: ((value: any) => string | null | undefined)[];
        } = {
          firstName: [validateRequired, validateTextFieldAlpha],
          lastName: [validateRequired, validateTextFieldAlpha],
          email: [validateRequired, validateEmail],
          mobNumber: [validatePhoneNumber],
          desiredFunctions: [validateMultiSelectField],
          street: [validateRequired],
          number: [validateRequired],
          zipCode: [validateRequired],
          city: [validateSelectField],
          country: [validateSelectField],
          office: [validateSelectField],
          drivingLicence: [validateMultiSelectField],
          cityOther: [validateRequired],
          countryOfBirth: [validateSelectField],
        };

        const mandatoryFields = (mandatoryFieldMapping[tabId] ||
          []) as string[];
        const errorsInTab: { [fieldName: string]: string } = {};
        for (const fieldName of mandatoryFields) {
          const fieldValidationRules = validationRules[fieldName];
          if (tabId === "general" || tabId === "jobProfile") {
            const fieldValue = tabFields[fieldName as keyof typeof tabFields];
            if (fieldValidationRules) {
              for (const rule of fieldValidationRules) {
                const validationError = rule(fieldValue);

                if (validationError) {
                  errorsInTab[fieldName] = validationError;
                  dispatch({
                    type: "UPDATE_FIELD_ERROR",
                    fieldName: fieldName,
                    error: validationError,
                  });
                  break;
                } else {
                  dispatch({
                    type: "UPDATE_FIELD_ERROR",
                    fieldName: fieldName,
                    error: "",
                  });
                }
              }
            }
          }
        }
        if (Object.keys(errorsInTab).length > 0) {
          errorsByTab[tabId] = errorsInTab;
          return false;
        }
      });
    updateTabsStatus(errorsByTab);
    if (Object.keys(errorsByTab).length > 0) {
      return false;
    }

    return true;
  };

  const findTabObjectById = (idToFind: string) => {
    return state.tabDetails.findIndex((tab) => tab.id === idToFind);
  };
  const updateTabsStatus = (errors: object) => {
    const keysToGet = [
      "general",
      "jobProfile",
      "workExperience",
      "studies",
      "employeeDocs",
      "internalInfo",
    ];
    const selectKeys = keysToGet
      .map((key) => {
        if (errors.hasOwnProperty(key)) {
          const tabIndex = findTabObjectById(key);
          if (tabIndex !== undefined && tabIndex !== null) {
            dispatch({ type: "UPDATE_TAB_ERROR", tabIndex, error: true });
          }
          return tabIndex;
        }
        return null;
      })
      .filter((key) => key !== null);
    const tabIndexes = [0, 1, 2, 3, 4, 5];
    tabIndexes.forEach((index) => {
      if (!selectKeys.includes(index)) {
        dispatch({ type: "UPDATE_TAB_ERROR", tabIndex: index, error: false });
      }
    });
  };

  const currentTab = state.tabDetails.find((tab) => tab.showStatus);
  const isGeneralTab = currentTab && currentTab.id === "general";
  const isInternnalInfoTab = currentTab && currentTab.id === "internalInfo";
  const formStatus = state.general.formStatus;

  const handleNextStep = () => {
    dispatch({ type: "NEXT_STEP" });
    if (!isViewMode) {
      handleSubmitFormData("next");
    }
  };

  const handlePreviousStep = () => {
    dispatch({ type: "PREVIOUS_STEP" });
  };

  const handleDraft = () => {
    const update = state.tabDetails.map((tab, index) => ({
      ...tab,
      draft: true,
    }));
    dispatch({ type: "UPDATE_TAB_DETAILS", tabDetails: update });
    const valid = validateMandatoryFields("draft");

    if (!valid) {
      validStatus({ isValid: false, type: "draft" });
      scrollToTop();
    } else {
      validStatus({ isValid: true, type: "draft" });
      handleSubmitFormData("draft");
    }
  };

  const handleSubmit = () => {
    const update = state.tabDetails.map((tab, index) => ({
      ...tab,
      draft: false,
    }));
    dispatch({ type: "UPDATE_TAB_DETAILS", tabDetails: update });
    const valid = validateMandatoryFields("submit");
    if (!valid) {
      validStatus({ isValid: false, type: "submit" });
      scrollToTop();
    } else {
      validStatus({ isValid: true, type: "submit" });
    }
  };
  // common functions
  const dispatchAndNotifySingleField = (
    fieldName: string,
    errorMessage: string
  ) => {
    dispatch({
      type: "UPDATE_FIELD_ERROR",
      fieldName: fieldName,
      error: t(errorMessage),
    });
    CustomNotify({ type: "error", message: t(errorMessage) });
  };

  const dispatchAndNotifyMultipleFields = (
    fieldA: string,
    fieldB: string,
    fieldAErrMsg: string,
    fieldBErrMsg: string,
    commonMessage: string
  ) => {
    dispatch({
      type: "UPDATE_FIELD_ERROR",
      fieldName: fieldA,
      error: t(fieldAErrMsg),
    });
    dispatch({
      type: "UPDATE_FIELD_ERROR",
      fieldName: fieldB,
      error: t(fieldBErrMsg),
    });
    CustomNotify({ type: "error", message: t(commonMessage) });
  };

  const destination = new URLSearchParams(location.search).get('destination') || "/manage-candidates";

  //submit function function to save data on draft and next buttons clcik

  const handleSubmitFormData = async (submitType: string) => {
    if (!candidateId) {
      const ssn = state.general.ssn.replace(/[.-]/g, "");
      const email = state.general.email;
      checkForSSNorEmailExistence(email, ssn);
      if (SSNorEmailModal) {
        return;
      }
    }
    // Find the index of the current tab
    const tabId = getNextTabId(state.tabDetails);
    if (
      state.general.firstName !== "" &&
      state.general.lastName !== "" &&
      state.general.email !== "" &&
      state.general.mobNumber !== ""
    ) {
      const idmData = {
        ...state.general,
        profilePicId: state.profileCard.picFileId,
        profilePicName: state.profileCard.picFileName,
        profilePicPath: state.profileCard.picFilePath,
        ...(candidateId ? { id: state.general.userId } : {}),
        ...(candidateId ? { userStatus: state.general.candidateStatus } : {}),
        ...(candidateId
          ? { formStatus: state.general.formStatus }
          : { formStatus: 0 }),
      };
      let validationStatus;
      let validationApiCall;
      if (state.general.ssn === "" && state.general.iban === "") {
        validationStatus = 200;
      } else {
        const validationFields = {
          type: type,
          ssn: state.general.ssn.replace(/[.-]/g, ""),
          iban: state.general.iban,
        };
        const validationUrl = !candidateId
          ? `${VALIDATION_FOR_CANDIDATE_FIELDS}`
          : `${VALIDATION_FOR_CANDIDATE_FIELDS}/${candidateId}`;
        validationApiCall = await ApiCall.service(
          validationUrl,
          "POST",
          validationFields,
          true,
          "candidate-creation"
        );
        validationStatus = validationApiCall.status;
      }

      if (validationStatus === 200) {
        const idmUrl = !candidateId ? `registration` : `updateCandidates`;

        const getUserId = await ApiCall.service(
          idmUrl,
          "POST",
          idmData,
          true,
          "identity-manager"
        );
        if (getUserId.status === 200) {
          const updatedFormData = {
            type: type,
            profileCard: state.profileCard,
            general: state.general,
            emergencyContacts: state.emergencyContact,
            other: state.other,
            jobProfile: state.jobProfile,
            workExperience: state.workExperience,
            studies: state.studies,
            competences: state.competences,
            certificates: state.certificates,
            workPermitDetails: state.workPermit,
            checklistDocDetails: state.documentChecklist,
            internalInfo: state.internalInfo,
            ...(candidateId
              ? { personId: state.general.userId }
              : { personId: getUserId.userId }),
            candidateStatus: state.general.candidateStatus,
            formStatus: state.general.formStatus,
            sourceId: state.general.sourceId,
            submitType: submitType,
            src: "internalRegister",
          };
          const url = !candidateId
            ? `registerCandidate`
            : `registerCandidate/${candidateId}`;
          const response = await ApiCall.service(
            url,
            "POST",
            updatedFormData,
            true,
            "candidate-creation"
          );
          if (response.status === 200) {
            if (submitType === "draft") {
              CustomNotify({
                type: "success",
                message: t("Candidate drafted successfully") + "!",
              });
              if (
                userAuth.role === "CANDIDATE" ||
                userAuth.role === "EMPLOYER"
              ) {
                navigate("/dashboard");
              } else {
                navigate(destination);
              }

              const data = {
                candidateId: candidateId,
                stage: "New",
                source: 2,
              };
              const createTodoResponse = await ApiCall.service(
                "createTodo",
                "POST",
                data,
                false,
                CENTRAL_DATA_MANAGEMENT_MICROSERVICE
              );
              if (submitType === "draft") {
                if (
                  createTodoResponse.status === 200 &&
                  userAuth.role === "RECRUITER"
                ) {
                  const data = {
                    todoId: createTodoResponse.todoId,
                    userId: userAuth.userId,
                    stageId: 1,
                    actionType: "pickUpTodo",
                  };
                  const assignTodoResponse = await ApiCall.service(
                    "assignTodo",
                    "POST",
                    data,
                    false,
                    CENTRAL_DATA_MANAGEMENT_MICROSERVICE
                  );
                }
              }
            } else {
              // Get the current URL path
              const currentPath =
                window.location.pathname ?? "/candidate/create";
              if (currentPath.includes("/candidate/edit")) {
                navigate(
                  `/candidate/edit/${response.candidateId}?tabId=${tabId}&destination=${destination}`
                );
              } else if (isViewMode) {
                navigate(
                  `/candidate/view/${response.candidateId}?tabId=${tabId}&destination=${destination}`
                );
              } else {
                // If candidateId doesn't exist, go to create URL
                navigate(
                  `/candidate/create/${response.candidateId}?tabId=${tabId}&destination=${destination}`
                );
              }
            }
          }
        } else if (getUserId.status === 400) {
          dispatch({ type: "UPDATE_TAB_ERROR", tabIndex: 0, error: true });
          if (getUserId.errors.email && getUserId.errors.phone_number) {
            dispatchAndNotifyMultipleFields(
              "email",
              "mobNumber",
              getUserId.errors.email,
              getUserId.errors.phone_number,
              t("Email and Phone number already exists")
            );
          } else if (getUserId.errors.email) {
            dispatchAndNotifySingleField("email", t("Email already exists"));
          } else if (getUserId.errors.phone_number) {
            dispatchAndNotifySingleField(
              "mobNumber",
              t("Phone number already exists")
            );
          }
        }
      } else if (validationApiCall.status === 400) {
        dispatch({ type: "UPDATE_TAB_ERROR", tabIndex: 0, error: true });
        if (validationApiCall.errors.ssn && validationApiCall.errors.iban) {
          dispatchAndNotifyMultipleFields(
            "ssn",
            "iban",
            validationApiCall.errors.ssn,
            validationApiCall.errors.iban,
            t("SSN number and IBAN already exist")
          );
        } else if (validationApiCall.errors.ssn) {
          dispatchAndNotifySingleField("ssn", validationApiCall.errors.ssn);
        } else if (validationApiCall.errors.iban) {
          dispatchAndNotifySingleField("iban", validationApiCall.errors.iban);
        }
      }
    }
  };


  return (
    <>
      <div className="row backPadding">
        <div className="col-md-6 align-self-center">
          {isGeneralTab && (
            <Link
              to={`${destination}`}
              className="back-btn text-decoration-underline"
            >
              {t("Back")}
            </Link>
          )}
          {!isGeneralTab && (
            <Button
              type="button"
              title={t("Back")}
              handleClick={handlePreviousStep}
              className="backBtn p-0 text-decoration-underline border-0"
            />
          )}
        </div>
        <div className="col-md-6 ">
          {!isLoading ? (
            <>
              {!isViewMode && (
                <Button
                  type="submit"
                  title={isInternnalInfoTab ? t("Submit") : t("Next")}
                  handleClick={
                    isInternnalInfoTab ? handleSubmit : handleNextStep
                  }
                  className="float-end form-button shadow-none"
                />
              )}
              {isViewMode && !isInternnalInfoTab && (
                <Button
                  type="button"
                  title={t("Next")}
                  handleClick={handleNextStep}
                  className="float-end form-button shadow-none"
                />
              )}
            </>
          ) : (
            <LoadingIcon
              iconType="bars"
              color="#e55496"
              className="ms-auto mb-3"
              height={"3vw"}
              width={"3vw"}
            />
          )}
          {formStatus !== 1 && !isInternnalInfoTab && !isViewMode && (
            <Button
              type="submit"
              title={t("Save as draft")}
              handleClick={handleDraft}
              className="float-end form-button marginRight1 shadow-none"
            />
          )}
        </div>
      </div>
      <ModalPopup
        size="lg"
        show={SSNorEmailModal}
        onHide={closeModal}
        title={modalTitle}
        body={emailOrSSNModalBody}
        className="modal-lg"
        onConfirmButtonClick={handleCandidate}
        confirmTitle={canId ? t("Yes") : false}
      />
    </>
  );
};

export default translate(Navigation);
