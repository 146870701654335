import { useEffect, useState } from "react";
import { useFormContext } from "../../context/Context";
import { useParams } from "react-router-dom";
import { ApiCall } from "components/common/services/ApiServices";
import Title from "components/common/atoms/Title";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleExclamation,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import {
  BILLING,
  COST_CENTER,
  GENERAL,
  INTERNAL_INFO,
  PLANNING_DETAILS,
  REMUNERATION,
  TIME_TABLE,
  UPDATE_TAB_DETAILS,
} from "../../annotation/PlanningConstants";
import { useTabUrlUpdater } from "components/common/utlis/TabUtility";
import BasicTab from "../form-content/BasicTab";
import GeneralTab from "../form-content/GeneralTab";
import RemunerationTab from "../form-content/RemunerationTab";
import BillingTab from "../form-content/BillingTab";
import TimeTableTab from "../form-content/TimeTableTab";
import CostCenterTab from "../form-content/CostCenterTab";
import InternalInfoTab from "../form-content/InternalInfoTab";
import { COMPANY_MICROSERVICE } from "Constants";
import SetPlanningData from "./SetPlanningData";
import { fetchCompanyDetails } from "./SetCompanyRelatedData";

const PlanningForm = () => {
  const { state, dispatch } = useFormContext();
  const [isSticky, setIsSticky] = useState(false);
  const { planningId } = useParams<{ planningId: string }>();

  useEffect(() => {
    const fetchPlanningDetails = async () => {
      // Check if there is a companyId in the URL
      const urlParams = new URLSearchParams(window.location.search);
      const companyId = urlParams.get("companyId");
      const data = {
        type: "planning",
        companyId: companyId ? companyId : null,
      };
      const url = planningId
        ? `${PLANNING_DETAILS}/${planningId}`
        : `${PLANNING_DETAILS}`;
      const response = await ApiCall.service(
        url,
        "POST",
        data,
        true,
        COMPANY_MICROSERVICE
      );
      if (response.status === 200) {
        const id = planningId ? planningId : null;
        // Call the separate component to handle the data setting logic
        SetPlanningData(response.data, dispatch, id, state);

        // If companyId exists, fetch company details
        if (companyId) {
          fetchCompanyDetails("company", companyId, dispatch);
        }
      }
    };
    fetchPlanningDetails();
  }, []);

  useTabUrlUpdater(state.tabDetails, dispatch); //To add tabId to url and stay on current tab on refresh

  //For handling the tab navigation
  const handleTabClick = (tabId: string) => {
    const currentTab = state.tabDetails.findIndex(
      (tab: any) => tabId === tab.id
    );
    state.tabDetails[currentTab].error = false;
    const updatedTabDetails = state.tabDetails.map((tab) => ({
      ...tab,
      showStatus: tab.id === tabId,
    }));

    dispatch({ type: UPDATE_TAB_DETAILS, tabDetails: updatedTabDetails });
  };

  const ConstructTabs = (id: string, showStatus: boolean) => {
    switch (id) {
      case GENERAL:
        return <GeneralTab />;
      case REMUNERATION:
        return <RemunerationTab />;
      case BILLING:
        return <BillingTab />;
      case TIME_TABLE:
        return <TimeTableTab />;
      case COST_CENTER:
        return <CostCenterTab />;
      case INTERNAL_INFO:
        return <InternalInfoTab />;
      default:
        break;
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 485) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Title title={t("Contract")} />
      <BasicTab />
      {state.tabDetails[0].error === true && (
        <div>
          <div className="row">
            <div className="error-msg-tabs col-12">
              <div
                className="alert alert-danger alert-dismissible fade show "
                role="alert"
              >
                <span className="me-2">
                  <FontAwesomeIcon icon={faCircleExclamation} />
                </span>
                {t("Please fill the mandatory fields to create a contract.")}
                <button
                  type="button"
                  className="btn-close shadow-none"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                ></button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="position-relative" style={{ paddingTop: "2vw" }}>
        <nav
          className={`createFormTabs
         ${
           isSticky
             ? "createTabsSticky position-sticky"
             : "vacancy-step-tabs-absolute position-absolute w-100"
         }`}
        >
          <div
            className="nav nav-tabs border-0 col-xxl-10 m-auto col-xl-11 col-lg-10 col-md-6"
            id="nav-tab"
            role="tablist"
          >
            {state.tabDetails.map((item: any, index) => (
              <button
                key={item.id}
                className={`nav-link me-lg-3 text-break ${
                  item.error ? "error" : ""
                } ${item.showStatus ? "active" : "inactive"}`}
                id={`${item.id}-tab`}
                data-bs-toggle="tab"
                data-bs-target={`#${item.id}`}
                type="button"
                role="tab"
                aria-controls={`nav-${item.id}`}
                aria-selected={item.showStatus}
                onClick={() => handleTabClick(item.id)}
                style={{ borderRadius: "0.4vw", padding: "0.5vw" }}
              >
                <div className="d-flex align-items-center justify-content-center">
                  {t(`${item.title}`)}

                  {item.error && (
                    <span
                      className="exclamation-icon ms-2"
                      title={`${t("Please fill all the mandatory fields")}`}
                      data-toggle="tooltip"
                    >
                      <FontAwesomeIcon icon={faTriangleExclamation} />
                    </span>
                  )}
                </div>
              </button>
            ))}
          </div>
        </nav>

        <div className="tab-content py-0" id="nav-tabContent">
          {state.tabDetails.map((item: any) => (
            <div
              key={item.id}
              className={`tab-pane fade ${item.showStatus ? "show" : ""} ${
                item.showStatus ? "active" : ""
              }`}
              id={item.id}
              role="tabpanel"
              aria-labelledby={`${item.id}-tab`}
            >
              {ConstructTabs(item.id, item.showStatus)}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default translate(PlanningForm);
