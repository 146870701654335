import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import React, { useEffect, useState } from "react";
import {
  Option,
  OptionProps,
  ValidationRules,
} from "components/common/utlis/TypeAnnotations";
import LabelField from "components/common/atoms/LabelField";
import PhoneInput from "react-phone-number-input";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import Calender from "components/common/molecules/Calender";
import { addUserDetails, selectAuth } from "features/auth/AuthSlice";
import {
  validateEmail,
  validateForm,
  validatePhoneNumber,
  validateRequired,
  validateSelectField,
  validateTextFieldAlpha,
  validateDate,
} from "components/common/services/ValidationService";
import Button from "components/common/atoms/Button";
import { useSelector, useDispatch } from "react-redux";
import { ApiCall } from "components/common/services/ApiServices";
import { GET_USER, UPDATE_USER, FILE_URL } from "routes/ApiEndpoints";
import { mapToSelect } from "components/common/utlis/MapToSelect";
import { useNavigate } from "react-router-dom";
import CustomNotify from "components/common/atoms/CustomNotify";
import LoadingIcon from "components/common/utlis/LoadingIcon";
import * as ENDPOINTS from "../../../routes/ApiEndpoints";
import FileUploadForCandidate from "components/common/molecules/FileUploadForCandidate";
import Title from "components/common/atoms/Title";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import { E164Number } from "libphonenumber-js";

interface ProfilePageProps {
  firstName: string;
  lastName: string;
  email: string;
  mobNumber: E164Number | string | undefined;
  dob: Date | null;
  country: Option | null;
  city: Option | null;
  gender: Option | null;
  role: Option | null;
  profilePicId: null;
  profilePicName: string;
  profilePicPath: string;
  preferredLanguages: Option | null;
}

const ProfilePage: React.FC = () => {
  const [formData, setFormData] = useState<ProfilePageProps>({
    firstName: "",
    lastName: "",
    email: "",
    mobNumber: "" as E164Number,
    dob: null,
    country: { label: t("Belgium"), value: 29 },
    city: null,
    gender: null,
    role: null,
    profilePicId: null,
    profilePicName: "",
    profilePicPath: "",
    preferredLanguages: { value: 39, label: t("Dutch, Flemish") },
  });

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [options, setOptions] = useState({
    country: [] as OptionProps[],
    city: [] as OptionProps[],
    gender: [] as OptionProps[],
    preferredLanguagesList: [] as OptionProps[],
  });

  const [errors, setErrors] = useState<{ [key: string]: string }>({
    firstName: "",
    lastName: "",
    email: "",
    mobNumber: "",
    dob: "",
    city: "",
    gender: "",
  });
  const auth = useSelector(selectAuth);
  useEffect(() => {
    fetchProfileDetails();
  }, []);

  const fetchProfileDetails = async () => {
    const data = {
      id: auth.userId,
    };
    const response = await ApiCall.service(GET_USER, "POST", data);

    if (response.status === 200) {
      const {
        city,
        gender,
        lastName,
        firstName,
        mobNumber,
        role,
        dob,
        email,
        profilePicId,
        profilePicName,
        profilePicPath,
        userPreferredLanguages,
      } = response.data.details;
      setFormData((prev) => ({
        ...prev,
        city: city,
        gender: gender,
        firstName: firstName,
        lastName: lastName,
        email: email,
        mobNumber: mobNumber,
        role: role,
        dob: dob ? new Date(dob) : null,
        profilePicId: profilePicId,
        profilePicName: profilePicName,
        profilePicPath: profilePicPath,
        preferredLanguages: userPreferredLanguages,
      }));
      setOptions((prev) => ({
        ...prev,
        gender: mapToSelect(response.data.genders),
        city: mapToSelect(response.data.cities),
        preferredLanguagesList: mapToSelect(response.data.preferredLanguages),
      }));
    }
  };

  const validation = (
    name: string,
    value: string | boolean | Date | Option | number | null,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      firstName: [validateRequired, validateTextFieldAlpha],
      lastName: [validateRequired, validateTextFieldAlpha],
      email: [validateRequired, validateEmail],
      mobNumber: [validatePhoneNumber],
      city: [validateSelectField],
      gender: [validateSelectField],
      dob: [validateDate],
    };

    const validationErrors = validateForm(
      { ...formData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation && Object.keys(errors).length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }

    return true;
  };

  const handleCountryChange = (selectedOption: any) => {};

  const handleDateChange = (date: Date | null, name: string) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: date,
    }));
    validation(name, date, true);
  };
  const handleSelectChange = (selectedOption: any, name: string) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: selectedOption !== null ? selectedOption : "",
    }));

    validation(name, selectedOption, true);
  };

  const handlePhoneNumberChange = (e: string | E164Number | undefined) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      mobNumber: e || "",
    }));
    validation("mobNumber", e || "", true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    validation(name, value, true);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    setLoading(true);
    e.preventDefault();
    const { name, value } = e.target as HTMLInputElement;

    const data = {
      id: auth.userId,
      ...formData,
    };
    const user = {
      name: data.firstName + " " + data.lastName,
      profilePicName: data.firstName.charAt(0) + data.lastName.charAt(0),
      profilePicId: data.profilePicId,
      profilePicPath: data.profilePicPath,
    };

    if (validation(name, value)) {
      const response = await ApiCall.service(UPDATE_USER, "POST", data);
      if (response.status === 200) {
        dispatch(addUserDetails(user));
        navigate("/");
        CustomNotify({ type: "success", message: t(response.msg) });
      } else if (response.status === 400) {
        setErrors({
          email: response.errors.email,
          mobNumber: response.errors.phone_number,
        });
      }
    }
    setLoading(false);
  };

  const returnFileUploadErrors = (errorResposne: any) => {
    setErrors((prevObject) => ({
      ...prevObject,
      picFileId: errorResposne["error"],
    }));

    setFormData((prevObject) => ({
      ...prevObject,
      profilePicId: null,
      profilePicName: "",
      profilePicPath: "",
    }));
  };

  const fileUploadResponse = (responseObj: any) => {
    const response = responseObj["response"];
    setFormData((prevObject) => ({
      ...prevObject,
      profilePicId: response[0],
      profilePicName: response[1],
      profilePicPath: response[2],
    }));
  };

  const deleteResponse = async (field: string, index: number) => {
    const picDetails = {
      profilePicId: formData.profilePicId,
    };
    await ApiCall.service(ENDPOINTS.DELETE_PROFILE_PIC, "POST", picDetails);
    const user = {
      profilePicId: null,
      profilePicPath: "",
    };
    dispatch(addUserDetails(user));
    setFormData((prevObject) => ({
      ...prevObject,
      profilePicId: null,
      profilePicName: "",
      profilePicPath: "",
    }));
  };

  const currentDate = new Date();
  const below15 = new Date(
    currentDate.getFullYear() - 15,
    currentDate.getMonth(),
    currentDate.getDate()
  );

  return (
    <form action="" onSubmit={handleSubmit}>
      <Title title={t("My profile")}  />
      <div className="row">
        <div className="col-12">
          <div className="form-border">
            <div className="row">
              <div className="col-md-12 mb-4">
                <div className="row">
                  <LabelField title={t("Profile pic")} />
                  <div className="text-left col-md-12 d-flex align-items-start">
                    <div className="position-relative my-profile-picture d-flex flex-row align-items-end ">
                      {formData.profilePicId !== null && (
                        <img
                          src={`${FILE_URL}/${formData.profilePicPath}`}
                          alt={formData.profilePicName}
                          style={{
                            backgroundColor: "var(--color-white)",
                            borderRadius: "50%",
                            boxShadow:
                              "rgb(248 216 235) 0.052vw 0px 0.521vw 0.156vw",
                          }}
                          // className="m-auto"
                        />
                      )}
                      <div
                        className={
                          formData.profilePicId != null
                            ? "my-profile-pic ms-3 "
                            : ""
                        }
                      >
                        <FileUploadForCandidate
                          page="myProfile"
                          id="profilePicId"
                          name="profilePicId"
                          // label="Profile pic"
                          placeholder={t("Upload pic")}
                          isMandatory={false}
                          edit={false}
                          fileId={
                            formData.profilePicId ? formData.profilePicId : null
                          }
                          filePath={
                            formData.profilePicPath
                              ? formData.profilePicPath
                              : ""
                          }
                          fileName={
                            formData.profilePicName
                              ? formData.profilePicName
                              : ""
                          }
                          multiple={false}
                          uploadPath="candidate-profilepic"
                          formats=".jpg,.jpeg,.png"
                          returnFileUploadErrors={returnFileUploadErrors}
                          fileUploadResponse={fileUploadResponse}
                          deleteResponse={deleteResponse}
                          maxFileSize={2 * 1024 * 1024}
                        />
                        {errors.picFileId && (
                          <div className="text-danger">{errors.picFileId}</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <LabelWithInputField
                  isMandatory={true}
                  name="firstName"
                  handleChange={handleInputChange}
                  value={formData.firstName}
                  id="fname"
                  label={t("First name")}
                  placeholder={t("First name")}
                  error={errors.firstName}
                />
              </div>
              <div className="col-md-6">
                <LabelWithInputField
                  isMandatory={true}
                  name="lastName"
                  handleChange={handleInputChange}
                  value={formData.lastName}
                  id="lname"
                  label={t("Last name")}
                  placeholder={t("Last name")}
                  error={errors.lastName}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <LabelWithInputField
                  isMandatory={true}
                  name="email"
                  handleChange={handleInputChange}
                  value={formData.email}
                  id="email"
                  label={t("Email")}
                  placeholder={t("Email")}
                  error={errors.email}
                />
              </div>
              <div className="col-md-6">
                <LabelField
                  title={t("Phone number")}
                  isMandatory={true}
                  key="PhoneInput"
                />
                <div
                  className="form-control field-shadow d-flex"
                  style={{ borderRadius: "0.5vw" }}
                >
                  <PhoneInput
                    defaultCountry="BE"
                    placeholder={t("Enter phone number")}
                    value={
                      formData.mobNumber !== undefined
                        ? (formData.mobNumber as E164Number)
                        : ("" as E164Number)
                    }
                    onChange={handlePhoneNumberChange}
                    className="w-100"
                    international
                  />
                </div>
                <div className="height-20" style={{ marginBottom: "0.5vw" }}>
                  {errors.mobNumber && (
                    <span className="text-danger">{errors.mobNumber}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <SelectWithSearch
                  title={t("Country")}
                  name="country"
                  placeHolder={t("Select")}
                  isMandatory={true}
                  search={true}
                  options={[]}
                  value={formData.country}
                  onChange={(e) => handleCountryChange(e)}
                  isMulti={false}
                  className="select-field"
                  isDisabled={true}
                />
              </div>
              <div className="col-md-6">
                <SelectWithSearch
                  title={t("City")}
                  name="city"
                  placeHolder={t("Select")}
                  isMandatory={true}
                  search={true}
                  options={options.city}
                  value={formData.city}
                  onChange={(e) => handleSelectChange(e, "city")}
                  isMulti={false}
                  className="select-field"
                  error={errors.city}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <SelectWithSearch
                  title={t("Gender")}
                  name="gender"
                  placeHolder={t("Select")}
                  isMandatory={true}
                  search={true}
                  options={options.gender}
                  value={formData.gender}
                  onChange={(e) => handleSelectChange(e, "gender")}
                  isMulti={false}
                  className="select-field"
                  error={errors.gender}
                  isTranslate={true}
                />
              </div>
              <div className="col-sm-12 col-md-6 position-relative">
                <Calender
                  onChange={handleDateChange}
                  selectedDate={formData.dob}
                  label={t("Date of birth")}
                  isMandatory={true}
                  name="dob"
                  maxDate={below15}
                  error={errors.dob ? errors.dob.toString() : undefined}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <SelectWithSearch
                  title={t("Role")}
                  name="role"
                  placeHolder={t("Select")}
                  isMandatory={true}
                  search={true}
                  options={[]}
                  value={formData.role}
                  onChange={(e) => handleSelectChange(e, "role")}
                  isMulti={false}
                  className="select-field"
                  isDisabled={true}
                  isTranslate={true}
                />
              </div>
              <div className="col-md-6">
                <SelectWithSearch
                  title={t("Preferred languages")}
                  isMandatory={false}
                  search={true}
                  options={options.preferredLanguagesList}
                  placeHolder={t("Select")}
                  onChange={(e) => handleSelectChange(e, "preferredLanguages")}
                  isMulti={false}
                  className=" select-field"
                  name="preferredLanguages"
                  value={formData.preferredLanguages}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row backPadding">
        <div className="col-12 text-end">
          {!loading ? (
            <Button
              title={t("Save")}
              type="submit"
              className="form-button shadow-none px-3"
            />
          ) : (
            <LoadingIcon
              iconType="bars"
              color="#ff4dae"
              className="float-end"
            />
          )}
        </div>
      </div>
    </form>
  );
};
export default translate(ProfilePage);
