
interface TableTitleProps {
    title?: string | number | undefined;
    tableTitleClassName?:string;
}

const TableTitle: React.FC<TableTitleProps> = ({ title,
    tableTitleClassName
 }) => {

    return (
            <div className={tableTitleClassName ?? "border border-bottom-0 py-2 text-center tableTitle TableHeader"}>{title}</div>
    );
}

export default TableTitle;