import Button from 'components/common/atoms/Button';
import SelectWithSearch from 'components/common/atoms/SelectWithSearch';
import { LabelWithInputField } from 'components/common/molecules/LabelWithInputField';
import AddPlusIcon from 'static/images/AddPlusIcon';
import DeleteIcon from 'static/images/DeleteIcon';
import { Option } from 'components/common/utlis/TypeAnnotations';
import { t } from 'components/CentralDataMangement/translation/Translation';

export interface FeeBasedItem {
  id: number | null;
  percentage: string | number | null;
  feeBasedType: Option | null;
  count: Option | null;
}

interface FeeBasedProps {
  feeBasedList: FeeBasedItem[];
  feeBasedErrors: { [key: number]: { [key: string]: string } };
  handleInputField: (value: any, name: string, index: number) => void;
  handleSelectField: (selectedOption: any, name: string, index: number) => void;
  addAnotherFeeBased: () => void;
  removeFeeBased: (index: number) => void;
  disableAddButton: boolean;
}

const FeeBased = ({
  feeBasedList,
  feeBasedErrors,
  handleInputField,
  handleSelectField,
  addAnotherFeeBased,
  removeFeeBased,
  disableAddButton
}: FeeBasedProps) => {


  return (
    <>
      {feeBasedList.map((feeBased, index) => (
        <div key={index} className="row mt-3">
          <div className="col-md-4">
            <LabelWithInputField
              isMandatory={false}
              name="percentage"
              label="Percentage"
              handleChange={(e) => handleInputField(e.target.value, e.target.name, index)}
              type="number"
              isDisabled={false}
              value={feeBased.percentage ?? ''}
              error={feeBasedErrors[index]?.percentage}
            />
          </div>
          <div className="col-md-3">
            <SelectWithSearch
              search={true}
              isMandatory={false}
              options={[
                { value: 1, label: "At Start up" },
                { value: 2, label: "Start of Month"},
                { value: 3, label: "Upon signing contract" },
              ]}
              value={feeBased.feeBasedType}
              onChange={(selectedOption) => handleSelectField(selectedOption, 'feeBasedType', index)}
              name="feeBasedType"
              title={t("Type")}
              error={feeBasedErrors[index]?.feeBasedType || ''}
              isTranslate={true}
            />
          </div>
          <div className="col-md-3">
            <SelectWithSearch
              search={true}
              isMandatory={false}
              options={[
                { value: 0, label: "0" },
                { value: 1, label: "1" },
                { value: 2, label: "2" },
                { value: 3, label: "3" },
                { value: 4, label: "4" },
                { value: 5, label: "5" },
                { value: 6, label: "6" },
              ]}
              value={feeBased.count}
              onChange={(selectedOption) => handleSelectField(selectedOption, 'count', index)}
              name="count"
              title={t("Month")}
              error={feeBasedErrors[index]?.count || ''}
            />
          </div>
          <div className="col-md-2">
            <div className="text-center mb-2 mt-35 pt-1 d-flex align-items-center">
              {index !== 0 && (
                <Button
                  className="btn p-0 border-0 table-action-icons me-2"
                  handleClick={() => removeFeeBased(index)}
                >
                  <span title="Delete">
                    <DeleteIcon />
                  </span>
                </Button>
              )}
              {index === feeBasedList.length - 1 && !disableAddButton &&
                <Button
                  className="btn p-0 border-0 table-action-icons"
                  handleClick={addAnotherFeeBased}
                >
                  <AddPlusIcon />
                </Button>
              }
            </div>
          </div>
        </div>
      ))}


    </>
  );
};

export default FeeBased;
