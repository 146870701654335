import React from 'react'

const Configurationsandsettings = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="40" viewBox="0 0 69.71 67.722">
        <g id="Configurations_and_settings" data-name="Configurations and settings" transform="translate(0 74.572) rotate(-90)">
          <g id="Group_27051" data-name="Group 27051" transform="translate(6.85)">
            <path id="Path_32578" data-name="Path 32578" d="M184.051,33.977V1.959a1.959,1.959,0,1,0-3.919,0V33.977a9.95,9.95,0,0,0,0,19.507V67.736a1.959,1.959,0,1,0,3.919,0V53.484a9.95,9.95,0,0,0,0-19.507Zm-1.959,15.777a6.023,6.023,0,1,1,6.023-6.023A6.027,6.027,0,0,1,182.092,49.754Z" transform="translate(-148.159)" fill="currentColor" />
            <path id="Path_32579" data-name="Path 32579" d="M18.751,17.547V1.959a1.959,1.959,0,1,0-3.919,0V17.547a9.95,9.95,0,0,0,0,19.507V67.736a1.959,1.959,0,1,0,3.919,0V37.04a9.941,9.941,0,0,0,0-19.492ZM16.792,33.324A6.023,6.023,0,1,1,22.815,27.3,6.027,6.027,0,0,1,16.792,33.324Z" transform="translate(-6.85)" fill="currentColor" />
            <path id="Path_32580" data-name="Path 32580" d="M348.351,17.547V1.959a1.959,1.959,0,0,0-3.919,0V17.547a9.95,9.95,0,0,0,0,19.507v30.7a1.959,1.959,0,0,0,3.919,0V37.04a9.941,9.941,0,0,0,0-19.492Zm-1.959,15.777a6.023,6.023,0,1,1,6.023-6.023A6.027,6.027,0,0,1,346.392,33.324Z" transform="translate(-288.612)" fill="currentColor" />
          </g>
        </g>
      </svg>

    </>
  )
}

export default Configurationsandsettings