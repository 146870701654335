import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import React from "react";
import CloneIcon from "static/images/CloneIcon";
import DeleteIcon from "static/images/DeleteIcon";
import AddPlusIcon from "static/images/AddPlusIcon";
import Button from "components/common/atoms/Button";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import { AgreementFormData } from "components/CompanyCreation/CooperationAgreement/annotations/CoopAgreementInterface";
import ThumbsUpIcon from "static/images/ThumbsUpIcon";
import ThumbsDownIcon from "static/images/ThumbsDownIcon";
import ThumbsUpIconFilled from "static/images/ThumbsUpIconFilled";
import ThumbsDownIconFilled from "static/images/ThumbsDownIconFilled";
import CalenderWithoutInputField from "components/common/atoms/CalenderWithoutInputField";
import AutoCompleteDropdown from "components/common/atoms/AutoCompleteDropdown";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "Constants";

interface ChildProps {
  empRowIndex: number;
  funcData: any;
  empTypeData: any;
  funcIndex: number;
  pcIndex: number;
  coeffIndex: number;
  coeffData: any;
  dropDown: any;
  role: string;
  stateData: AgreementFormData;
  addFunctionProfile: (pcIndex: number) => void;
  cloneFunctionType: (pcIndex: number, funcIndex: number) => void;
  deleteFunctionType: (pcIndex: number, funcIndex: number) => void;
  addEmployeeType: (pcIndex: number, funcIndex: number) => void;
  deleteEmployeeType: (
    pcIndex: number,
    funcIndex: number,
    empRowIndex: number
  ) => void;
  handleSelectChange: (
    selectedOption: any,
    title: string,
    pcIndex: number,
    funcIndex: number,
    empRowIndex: number
  ) => void;
  handleFieldChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    pcIndex: number,
    funcIndex: number,
    empRowIndex: number,
    coeffIndex: number
  ) => void;
  acceptOrReject: (
    pcIndex: number,
    funcIndex: number,
    empRowIndex: number,
    coeffIndex: number,
    status: number
  ) => void;
  handleDateChange: (
    date: Date | null,
    name: string,
    pcIndex: number,
    funcIndex: number,
    empRowIndex: number
  ) => void;
}

const TableData: React.FC<ChildProps> = ({
  pcIndex,
  empRowIndex,
  funcData,
  empTypeData,
  funcIndex,
  coeffIndex,
  coeffData,
  dropDown,
  stateData,
  role,
  addFunctionProfile,
  cloneFunctionType,
  deleteFunctionType,
  addEmployeeType,
  deleteEmployeeType,
  handleSelectChange,
  handleFieldChange,
  acceptOrReject,
  handleDateChange,
}) => {
  const approvalStatusArray = Object.keys(coeffData)
    .filter((key) => key.endsWith("_approval_status"))
    .map((key) => coeffData[key]);
  const notApproved = approvalStatusArray.filter((status) => status !== 2);
  const approvalStatus =
    notApproved.length > 0 && !notApproved.every((status) => status === 0)
      ? Math.max(...notApproved)
      : Math.max(...approvalStatusArray);

  //am data which contains last status value
  const amData: any =
    Object.keys(stateData.amApprovedData).length > 0 &&
    stateData.amApprovedData[pcIndex]?.functions[funcIndex]?.employeeTypes[
      empRowIndex
    ]?.coefficients[coeffIndex];
  let amApprovalStatus = 0;
  if (amData) {
    const amApprovalStatusArray = Object.keys(amData)
      .filter((key) => key.endsWith("_approval_status"))
      .map((key) => amData[key]);

    const notAmApproved = amApprovalStatusArray.filter(
      (status) => status !== 2
    );
    amApprovalStatus =
      notAmApproved.length > 0 && !notAmApproved.every((status) => status === 0)
        ? Math.max(...notAmApproved)
        : Math.max(...amApprovalStatusArray);
  }
  return (
    <>
      {empRowIndex === 0 && (
        <td
          className={`text-center agreement-profile position-relative ${
            funcData.funcErrorStatus ? "error-border" : ""
          }`}
          rowSpan={funcData.employeeTypes.length}
        >
          {/* <SelectWithSearch
            search={true}
            options={dropDown.functionDropdownValues}
            onChange={(e) =>
              handleSelectChange(e, "function", pcIndex, funcIndex, empRowIndex)
            }
            name="function"
            value={funcData.function ?? ""}
            className="border-0 mb-3 pt-2"
            isTranslate={true}
            // isMenuPlacement={}
          /> */}
          <AutoCompleteDropdown
            microService={CENTRAL_DATA_MANAGEMENT_MICROSERVICE}
            onChange={(e) =>
              handleSelectChange(e, "function", pcIndex, funcIndex, empRowIndex)
            }
            value={funcData.function ?? ""}
            filter={true}
            modelKey="functions"
            columnName="function_name"
            shouldCreateNew={true}
            filterInfo={[
              { column: "status", value: 1, condition: "where" }
            ]}
            className="text-center mb-3 shadow-none"
            placeholder={t("Type to search")+ "..."}
            name="function"
          />
          <div className="text-center mb-2">
            <Button
              className="btn p-0 border-0 table-action-icons me-2"
              handleClick={() => addFunctionProfile(pcIndex)}
            >
              <span title={t("Add profile")}>
                <AddPlusIcon />
              </span>
            </Button>
            <Button
              className="btn p-0 border-0 table-action-icons"
              handleClick={() => cloneFunctionType(pcIndex, funcIndex)}
            >
              <span title={t("Clone profile")}>
                <CloneIcon />
              </span>
            </Button>
            {funcIndex !== 0 && (
              <Button
                className="btn p-0 border-0 table-action-icons ms-2"
                handleClick={() => deleteFunctionType(pcIndex, funcIndex)}
              >
                <span title={t("Delete profile")}>
                  <DeleteIcon />
                </span>
              </Button>
            )}
          </div>
        </td>
      )}
      <td
        className={`text-center employee-type ${
          empTypeData.empTypeErrorStatus ? "error-border" : ""
        }`}
      >
        <SelectWithSearch
          search={true}
          options={dropDown.empTypeDropdownValues}
          onChange={(e) =>
            handleSelectChange(e, "empType", pcIndex, funcIndex, empRowIndex)
          }
          name="empType"
          value={empTypeData.empType ?? ""}
          style={{
            minHeight: funcData.employeeTypes.length === 1 ? "7.8vw" : "4.63vw",
            border: "0px !important",
            boxShadow: "none !important",
            outline: "0vw !important",
            cursor: "pointer",
          }}
          isTranslate={true}
          // isMenuPlacement={true}
        />
      </td>
      <td
        className={`text-center employee-type ${
          empTypeData.levelErrorStatus ? "error-border" : ""
        }`}
      >
        <SelectWithSearch
          search={true}
          options={stateData.levelDropDown}
          onChange={(e) =>
            handleSelectChange(e, "level", pcIndex, funcIndex, empRowIndex)
          }
          name="level"
          value={empTypeData.level ?? ""}
          style={{
            minHeight: funcData.employeeTypes.length === 1 ? "7.8vw" : "4.63vw",
            border: "0vw !important",
            boxShadow: "none !important",
            outline: "0vw !important",
            cursor: "pointer",
          }}
          isTranslate={true}
          // isMenuPlacement={true}
        />
      </td>
      {Object.keys(coeffData)
        .filter(
          (key) =>
            !key.endsWith("_error_status") &&
            !key.endsWith("_changed") &&
            !key.endsWith("startDate") &&
            !key.endsWith("Id") &&
            !key.includes("_approval_status") &&
            !key.endsWith("_min") &&
            !key.includes("acceptOrRejectMail")
        )
        .map((key: string, index: number) => {
          const item = coeffData[key]; // Access the corresponding item using the key
          const dynamicErrorStatus = coeffData[key + "_error_status"];

          // Construct dynamic name based on the key
          const dynamicName: string = key;

          return (
            <td
              colSpan={1}
              key={`coeff-${dynamicName}-${index}`} // Assign a unique key
              className={`${
                dynamicErrorStatus ? "error-border" : ""
              } position-relative coeffDataDynamic`}
            >
              <LabelWithInputField
                key={`coeff-name-${index}`}
                handleChange={(event) =>
                  handleFieldChange(
                    event,
                    pcIndex,
                    funcIndex,
                    empRowIndex,
                    coeffIndex
                  )
                }
                name={dynamicName}
                value={item ?? ""}
                className="text-center"
              />
            </td>
          );
        })}
      <td className="text-center py-3 agreement-actions">
        <div>
          <div className="d-inline-block position-relative me-2" style={{width:"2vw"}}>
            <CalenderWithoutInputField
              onDateChange={(date) => {
                handleDateChange(
                  date,
                  "startDate",
                  pcIndex,
                  funcIndex,
                  empRowIndex
                );
              }}
              selectedDate={
                empTypeData.startDate != null
                  ? new Date(empTypeData.startDate)
                  : new Date()
              }
              name="startDate"
              title={`Start date - ${
                empTypeData.startDate != null
                  ? new Date(empTypeData.startDate).toLocaleDateString()
                  : new Date().toLocaleDateString()
              }`}
            />
          </div>
          <Button
            className="btn p-0 border-0 table-action-icons me-2"
            handleClick={() => addEmployeeType(pcIndex, funcIndex)}
          >
            <span title={t("Add employee type")}>
              <AddPlusIcon />
            </span>
          </Button>
          {empRowIndex !== 0 && (
            <Button
              className="btn p-0 border-0 table-action-icons me-2"
              handleClick={() =>
                deleteEmployeeType(pcIndex, funcIndex, empRowIndex)
              }
            >
              <span title={t("Delete employee type")}>
                <DeleteIcon />
              </span>
            </Button>
          )}
          {role === "AREA_MANAGER" && approvalStatus > 0 && (
            <>
              {amApprovalStatus !== 2 && (
                <>
                  <Button
                    className="btn p-0 border-0 table-action-icons mt-2"
                    handleClick={() =>
                      acceptOrReject(
                        pcIndex,
                        funcIndex,
                        empRowIndex,
                        coeffIndex,
                        2
                      )
                    }
                  >
                    {(approvalStatus === 1 || approvalStatus === 3) && (
                      <span title={t("Accept")} className="me-2">
                        <ThumbsUpIcon />
                      </span>
                    )}
                    {approvalStatus === 2 && (
                      <span title={t("Accepted")}>
                        <ThumbsUpIconFilled />
                      </span>
                    )}
                  </Button>
                  <Button
                    className="btn p-0 border-0 table-action-icons me-2"
                    handleClick={() =>
                      acceptOrReject(
                        pcIndex,
                        funcIndex,
                        empRowIndex,
                        coeffIndex,
                        3
                      )
                    }
                  >
                    {approvalStatus === 1 && (
                      <span title={t("Reject")}>
                        <ThumbsDownIcon />
                      </span>
                    )}
                    {approvalStatus === 3 && (
                      <span title={t("Rejected")}>
                        <ThumbsDownIconFilled />
                      </span>
                    )}
                  </Button>
                </>
              )}
            </>
          )}
        </div>
      </td>
    </>
  );
};

export default translate(TableData);
