import { t } from "components/CentralDataMangement/translation/Translation";
import CheckBoxField from "components/common/atoms/CheckBoxField";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import { WageCodesProps } from "components/CompanyCreation/AdminFiche/annotations/AdminFicheInterface";
import React from "react";
import Accordion from "react-bootstrap/Accordion";
import ApplicabilityTable from "./ApplicabilityTable";
import DeleteIcon from "static/images/DeleteIcon";
import { Option, OptionProps } from "components/common/utlis/TypeAnnotations";

interface Props {
  wageCode: WageCodesProps[];
  wageId: number;
  profileId: number;
  removeWageData: (
    wageId?: number,
    profileId?: number,
    codeId?: number
  ) => void;
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    wageId: number,
    profileId?: number,
    codeId?: number,
    applicabilityId?: number
  ) => void;
  handleSelectChange: (
    value: Option | null,
    filed: string,
    wageId: number,
    profileId?: number,
    codeId?: number
  ) => void;
  autoTypeList: OptionProps[];
}
const WageCodeAccordion: React.FC<Props> = ({
  wageCode,
  wageId,
  profileId,
  removeWageData,
  handleChange,
  handleSelectChange,
  autoTypeList,
}) => {
  const wageCodesArray = Object.values(wageCode ?? {});
  return (
    <>
      {wageCodesArray?.length > 0 &&
        wageCodesArray.map((code: WageCodesProps, codeId: number) => (
          <div
            className="d-flex align-items-start  w-100 mb-3"
            key={`${wageId}-${profileId}-${codeId}`}
          >
            <Accordion
              defaultActiveKey=""
              key={`${wageId}-${profileId}-${codeId}`}
              className="w-100 coomercialProfileAccordion"
            >
              <Accordion.Item eventKey="">
                <Accordion.Header>
                  <div className="d-flex justify-content-between w-100 fw-bold">
                    <span>{code.wageCode?.label}</span>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="row">
                    {code.variableType === "amount" && (
                      <div className="col-3">
                        <div className="input-group mealVouchers">
                          <LabelWithInputField
                            type="text"
                            label={t("Amount")}
                            handleChange={(e) =>
                              handleChange(e, wageId, profileId, codeId)
                            }
                            value={code.amount ?? ""}
                            name="amount"
                          />
                          <span
                            className="input-group-text rounded-start-0 rounded-end-3 border-0 position-absolute end-0"
                            id="basic-addon1"
                          >
                            €
                          </span>
                        </div>
                      </div>
                    )}
                    {code.variableType === "percentage" && (
                      <div className="col-3">
                        <div className="input-group adminFicheSuffixText">
                          <LabelWithInputField
                            type="text"
                            label={t("Percentage")}
                            handleChange={(e) =>
                              handleChange(e, wageId, profileId, codeId)
                            }
                            value={code.percentage ?? ""}
                            name="percentage"
                          />
                          <span
                            className="input-group-text rounded-start-0 rounded-end-3 border-0 position-absolute end-0"
                            id="basic-addon1"
                          >
                            %
                          </span>
                        </div>
                      </div>
                    )}
                    <div className="col-3">
                      <SelectWithSearch
                        search={true}
                        title={t("Type of automation")}
                        options={autoTypeList}
                        value={code.autoType}
                        onChange={(e) =>
                          handleSelectChange(
                            e,
                            "autoType",
                            wageId,
                            profileId,
                            codeId
                          )
                        }
                        name={"autoType"}
                        placeHolder={t("Type of automation")}
                      />
                    </div>
                    {code.autoType?.value !== 1 && (
                      <div className="col-3">
                        <LabelWithInputField
                          type="text"
                          label={t("Number")}
                          handleChange={(e) =>
                            handleChange(e, wageId, profileId, codeId)
                          }
                          value={code.number ?? ""}
                          name="number"
                        />
                      </div>
                    )}
                    {code.autoType?.value !== 3 && code.autoType?.value !== 4 &&(
                      <div className="col-3 mt-5">
                        <CheckBoxField
                          label={t("Paid during absence")}
                          name="paidAbsence"
                          onChangeHandler={(e) =>
                            handleChange(e, wageId, profileId, codeId)
                          }
                          value={code.paidAbsence ? "1" : "0"}
                          isChecked={
                            code.paidAbsence !== null ? code.paidAbsence : false
                          }
                          id="paidAbsence"
                          lineHeight={"27px"}
                        />
                      </div>
                    )}
                    <ApplicabilityTable
                      code={code}
                      wageId={wageId}
                      profileId={profileId}
                      codeId={codeId}
                      handleChange={handleChange}
                    />
                    <div className="col-4">
                      <CheckBoxField
                        label={t("Applicable on holiday")}
                        name="holidayApplicable"
                        onChangeHandler={(e) =>
                          handleChange(e, wageId, profileId, codeId)
                        }
                        value={code.holidayApplicable ? "1" : "0"}
                        isChecked={
                          code.holidayApplicable !== null
                            ? code.holidayApplicable
                            : false
                        }
                        id="holidayApplicable"
                        lineHeight={"27px"}
                      />
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <div className="ms-3 wagePremiumActions">
              <span
                title={t("Delete")}
                onClick={() => removeWageData(wageId, profileId, codeId)}
                className="table-action-icons cursor-pointer rounded-0 shadow-none"
              >
                <DeleteIcon />
              </span>
            </div>
          </div>
        ))}
    </>
  );
};

export default React.memo(WageCodeAccordion);
