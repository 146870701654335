import Title from "components/common/atoms/Title";
import { t, translate } from "../../translation/Translation";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ApiCall } from "components/common/services/ApiServices";
import * as ENDPOINTS from "routes/ApiEndpoints";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "Constants";
import EditIcon from "static/images/EditIcon";
import LinkTo from "components/common/atoms/LinkTo";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import "./css/regions.css";
import { Spinner } from "react-bootstrap";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import SpinnerWrapper from "components/common/atoms/SpinnerWrapper";
import NoRecords from "components/common/atoms/NoRecords";
interface Office {
  name: string;
}

interface BudgetLocation {
  name: string;
  offices: Office[];
}

interface Region {
  id: number;
  name: string;
  budgetLocations: BudgetLocation[];
}

const ManageRegions: React.FC = () => {
  const [regions, setRegions] = useState<Region[]>([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchRegion();
  }, []);

  //API call for to fetch WageElements
  const fetchRegion = async () => {
    setLoading(true);
    const requestData = {
      type: "officeLinking",
    };

    const response = await ApiCall.service(
      `${ENDPOINTS.BUDGET_GET}/officeLinking`,
      "POST",
      requestData,
      false,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );

    if (response.status === 200) {
      setRegions(response.data);
    }
    setLoading(false);
  };

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Office region",
          read: true,
        },
      ]}
      renderNoAccess={true}
    >
      <Title title={t("Manage regions")} />
      <div className="row">
        <div className="col-12">
          <div className="position-relative tableMainWrapper">
            <div className="row">
              <div className="ManageCreateBtn">
                <AccessControl
                  requiredPermissions={[
                    {
                      permission: "Office region",
                      create: true,
                    },
                  ]}
                >
                  <LinkTo
                    pagelink="/budget/region"
                    title={t("Create region")}
                    icon={faPlus}
                    className="marginRightPoint5"
                  />
                </AccessControl>
                <AccessControl
                  requiredPermissions={[
                    {
                      permission: "Link office",
                      create: true,
                    },
                  ]}
                >
                  <LinkTo
                    pagelink="/budget/link-region"
                    title={t("Link offices")}
                    icon={faPlus}
                  />
                </AccessControl>
              </div>
            </div>
            <div className="table-responsive tableSection paddingTop2">
              <table className="table table-bordered">
                <thead>
                  <tr className="TableHeader">
                    <th className="text-break ps-lg-4" style={{ width: "20%" }}>
                      {t("Region")}
                    </th>
                    <th className="text-break ps-lg-4" style={{ width: "35%" }}>
                      {t("Location")}
                    </th>
                    <th className="text-break ps-lg-4" style={{ width: "35%" }}>
                      {t("Office ID's")}
                    </th>
                    <AccessControl
                      requiredPermissions={[
                        {
                          permission: "Link office",
                          update: true,
                        },
                      ]}
                      actions={true}
                      strict={false}
                    >
                      <th className="text-break ps-lg-4 text-center">
                        {t("Actions")}
                      </th>
                    </AccessControl>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <SpinnerWrapper headerLength={4} />
                  ) : (
                    <>
                      {regions && regions.length > 0 ? (
                        regions.map((region: Region, regionIndex) =>
                          region.budgetLocations &&
                          region.budgetLocations.length > 0 ? (
                            region.budgetLocations.map(
                              (location: BudgetLocation, locationIndex) => (
                                <tr
                                  key={`${regionIndex}-${locationIndex}`}
                                  className={` align-middle ${
                                    locationIndex ==
                                    region.budgetLocations.length - 1
                                      ? "border-bottom"
                                      : ""
                                  }`}
                                >
                                  {/* Render Region only in the first row */}
                                  {locationIndex === 0 && (
                                    <td
                                      rowSpan={region.budgetLocations.length}
                                      className="ps-lg-4"
                                      data-label={t("Region")}
                                    >
                                      {t(`${region.name}`)}
                                    </td>
                                  )}
                                  {/* Render Budget Location */}
                                  <td
                                    className="ps-lg-4 align-middle py-3"
                                    data-label={t("Location")}
                                  >
                                    {t(`${location.name}`)}
                                  </td>
                                  {/* Render Offices */}
                                  <td
                                    className="align-middle ps-lg-4 py-3"
                                    data-label={t("Office ID's")}
                                  >
                                    {location.offices.map(
                                      (office: Office, officeIndex) => (
                                        <span
                                          key={`${regionIndex}-${locationIndex}-${officeIndex}`}
                                        >
                                          {`${
                                            officeIndex > 0 &&
                                            officeIndex <
                                              location.offices.length - 1
                                              ? ", "
                                              : location.offices.length == 2 &&
                                                officeIndex > 0
                                              ? ", "
                                              : ""
                                          }${office.name} `}
                                        </span>
                                      )
                                    )}
                                  </td>
                                  <AccessControl
                                    requiredPermissions={[
                                      {
                                        permission: "Link office",
                                        update: true,
                                      },
                                    ]}
                                    actions={true}
                                    strict={false}
                                  >
                                    {locationIndex === 0 && (
                                      <td
                                        rowSpan={region.budgetLocations.length}
                                        className="align-middle table-action-icons px-2 text-center"
                                      >
                                        <AccessControl
                                          requiredPermissions={[
                                            {
                                              permission: "Link office",
                                              update: true,
                                            },
                                          ]}
                                        >
                                          <Link
                                            to={`/budget/link-region/${region.id}`}
                                            title={t("Edit")}
                                          >
                                            <EditIcon />
                                          </Link>
                                        </AccessControl>
                                      </td>
                                    )}
                                  </AccessControl>
                                </tr>
                              )
                            )
                          ) : (
                            // If region has no budget locations, render only region name
                            <tr
                              key={regionIndex}
                              className="border-bottom align-middle"
                            >
                              <td
                                className="ps-lg-4 align-middle"
                                data-label={t("Region")}
                              >
                                {region.name}
                              </td>
                              <td className="align-middle border-0"></td>
                              {/* Empty cell for Budget Location */}
                              <td className="align-middle border-0"></td>
                              {/* Empty cell for Offices */}
                              <AccessControl
                                requiredPermissions={[
                                  {
                                    permission: "Office region",
                                    update: true,
                                    delete: true,
                                  },
                                ]}
                                actions={true}
                                strict={false}
                              >
                                <td className="align-middle table-action-icons px-2 text-center">
                                  <AccessControl
                                    requiredPermissions={[
                                      {
                                        permission: "Office region",
                                        update: true,
                                      },
                                    ]}
                                  >
                                    <Link
                                      to={`/budget/link-region/${region.id}`}
                                      title={t("Edit")}
                                    >
                                      <EditIcon />
                                    </Link>
                                  </AccessControl>
                                </td>
                              </AccessControl>
                            </tr>
                          )
                        )
                      ) : (
                        <NoRecords headerLength={7} />
                     
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="row backPadding">
        <div className="col-md-6 align-self-center py-4">
          <Link
            to=""
            className="back-btn text-decoration-underline"
            onClick={(e) => navigate(-1)}
          >
            {t("Back")}
          </Link>
        </div>
      </div>
    </AccessControl>
  );
};

export default translate(ManageRegions);
