import { ADD_SIGNATURE, GET_SIGNATURE } from "routes/ApiEndpoints";
import { t } from "components/CentralDataMangement/translation/Translation";
import Title from "components/common/atoms/Title";
import SignatureDetails from "components/common/molecules/SignatureDetails";
import { ApiCall } from "components/common/services/ApiServices";
import React, { useState, useEffect } from "react";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "Constants";
import CustomNotify from "components/common/atoms/CustomNotify";
import { useSelector } from "react-redux";
import { addUserDetails, selectAuth } from "features/auth/AuthSlice";
import { useDispatch } from "react-redux";

const AddSignature = () => {
  const userAuth = useSelector(selectAuth);
  const [state, setState] = useState({
    loaded: false,
    sign: "",
    showPopup: false,
    disabled: false,
  });

  useEffect(() => {
    fetchData();
  }, []);
  const dispatch = useDispatch();

  const fetchData = async () => {
    const url = `${GET_SIGNATURE}/${userAuth.userId}`;
    await ApiCall.getService(
      url,
      "GET",
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE,
      true
    )
      .then((response) => {
        if (response.status === 200)
          setState({
            ...state,
            sign: response.data ? response.data.sign || "" : "",
            loaded: true,
          });
        dispatch(addUserDetails({ isSignature: true }));
      })
      .catch((error) => console.error(error));
  };

  const submitSignData = async (signData: string) => {
    const data = {
      sign: signData,
      entityId: userAuth.userId,
    };
    await ApiCall.service(
      ADD_SIGNATURE,
      "POST",
      data,
      true,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    )
      .then((response) => {
        if (response.status === 200)
          setState({
            ...state,
            sign: signData,
            showPopup: false,
            loaded: true,
          });
        CustomNotify({ type: "success", message: response.msg });
      })
      .catch((error) => console.error(error));
  };
  return (
    <>
      {state.loaded === true ? (
        <>
          <Title title={t("Manage signature")} />
          <SignatureDetails
            state={state}
            setState={setState}
            submitSignData={submitSignData}
          />
        </>
      ) : (
        <p>{t("Loading...")}</p>
      )}
    </>
  );
};

export default React.memo(AddSignature);
