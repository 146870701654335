import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import Calender from "components/common/molecules/Calender";
import TimeInput from "components/common/molecules/TimeInput";
import LabelWithTextAreaField from "components/common/molecules/LabelWithTextAreaField";
import { t, translate } from "../translation/Translation";
import "./taskDeterminer.css";
import CheckBoxField from "components/common/atoms/CheckBoxField";
import { ConfigData, FollowUpProps, TodoErrors } from "./TaskAnnotations";

interface FormProps {
  data: ConfigData;
  formData: FollowUpProps;
  handleChange: (
    e: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >,
    type: string
  ) => void;
  handleSelectChange: (
    selectedOption: any,
    fieldName: string,
    type: string
  ) => void;
  handleTimeChange: (name: string, value: string, type: string) => void;
  handleDateChange: (date: Date | null, type: string) => void;
  handleCheckBoxChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  errors: Partial<TodoErrors>;
  type: string;
  isEdit: boolean;
}

const FollowUp: React.FC<FormProps> = ({
  data,
  formData,
  handleChange,
  errors,
  handleTimeChange,
  handleDateChange,
  handleSelectChange,
  handleCheckBoxChange,
  type,
  isEdit = false,
}) => {
  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <SelectWithSearch
            title={t("To be followed up by")}
            search={true}
            options={data.users ?? []}
            onChange={(e) => handleSelectChange(e, "followedBy", type)}
            isMandatory={true}
            isMulti={false}
            name="followedBy"
            value={formData.followedBy}
            isDisabled={isEdit}
            error={errors.followedBy}
          />
        </div>
        <div className="col-md-6 hotlistWrapper">
          <div className="d-inline-block">
            <CheckBoxField
              label={t("Completed")}
              name="completed"
              onChangeHandler={handleCheckBoxChange}
              isChecked={formData.completed}
              className="document-checkbox marginRight1"
              disable={isEdit}
              id="completed"
              lineHeight="1vw"
            />
          </div>
          <div className="d-inline-block">
            <CheckBoxField
              label={t("Always show")}
              name="alwaysShow"
              onChangeHandler={handleCheckBoxChange}
              isChecked={formData.alwaysShow}
              className="document-checkbox"
              disable={isEdit}
              id="alwaysShow"
              lineHeight="1vw"
            />
          </div>
        </div>
            <div className="position-relative col-3">
              <Calender
                isMandatory={true}
                onChange={(date) => handleDateChange(date || new Date(), type)}
                name="date"
                label={t("Date")}
                selectedDate={formData.date ? new Date(formData.date) : null}
                error={errors.date}
                isDisabled={isEdit}
              />
            </div>
            <div className="time-section followUpTimeSection col-3">
              <TimeInput
                setTime={(e) => handleTimeChange("time", e, type)}
                index={1}
                type="time"
                value={formData.time ?? ""}
                title={t("Time")}
                error={errors.time}
                isDisabled={isEdit}
              />
            </div>

        <div className="col-md-6">
          <SelectWithSearch
            title={t("Estimated duration")}
            search={true}
            options={data.durations ?? []}
            onChange={(e) => handleSelectChange(e, "duration", type)}
            isMulti={false}
            name="duration"
            value={formData.duration}
            isDisabled={isEdit}
          />
        </div>
        <div className="hideLabel">
          <LabelWithTextAreaField
            name="feedback"
            handleChange={(e) => handleChange(e, type)}
            label={t("Feedback")}
            value={formData.feedback}
            isDisabled={isEdit}
          />
        </div>
      </div>
    </>
  );
};
export default translate(FollowUp);
