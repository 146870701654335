import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useFormContext } from "../../context/Context";
import "../../css/Agreement.css";
import GeneralTab from "./GeneralTab";
import BillingTab from "./BillingTab";
import ContactsTab from "./ContactsTab";
import CompositionTab from "./CompositionTab";
import InvoiceTab from "./InvoiceTab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { ApiCall } from "components/common/services/ApiServices";
import { mapToSelect } from "components/common/utlis/MapToSelect";
import * as ENDPOINTS from "../../../../../routes/ApiEndpoints";
import { COMPANY_MICROSERVICE } from "Constants";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import Title from "components/common/atoms/Title";
import GetFunctions from "./GetFunctions";
import * as CONST from "../../annotations/CoopAgreementConstants";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import { Option } from "components/common/utlis/TypeAnnotations";
import { useTabUrlUpdater } from "components/common/utlis/TabUtility";
import InfoIcon from "static/images/InfoIcon";
import { formatDateAndTime } from "components/common/utlis/HelperFunctions";
import ActionButtons from "components/common/utlis/ActionButtons";
import { actionButtons } from "./ActionButtons";
import UserInfoTooltip from "components/common/atoms/UserInfoTooltip";

type DynamicActionType = "UPDATE_GENERAL_FIELD";

const AgreementForm = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const { agreementId } = useParams<{ agreementId: string }>();
  const { state, dispatch } = useFormContext();
  const [isSticky, setIsSticky] = useState(false);

  const currentTab = state.tabDetails.find((tab) => tab.showStatus);
  const isCoeffTab = currentTab && currentTab.id === "coefficients";

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 46) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const isEditCalled = useRef(false);

  // Edit Flow: If `agreementId` is present, it should only call once
  useEffect(() => {
    if (agreementId && !isEditCalled.current) {
      getAgreementDetails();
      isEditCalled.current = true; // Prevent further calls in the edit flow
    }
  }, [agreementId]);

  // Create Flow: If `agreementId` is not present, call based on `type` and `coeffType`
  useEffect(() => {
    if (!agreementId) {
      getAgreementDetails();
    }
  }, [state.general.type, state.general.coeffType]);

  const getAgreementDetails = async () => {
    const data = {
      companyId: companyId,
      agreementId: agreementId ?? null,
      unitType: state.general.type,
      coeffType: state.general.coeffType,
    };

    const response = await ApiCall.service(
      ENDPOINTS.GET_AGREEMENT_DETAILS,
      "POST",
      data,
      true,
      COMPANY_MICROSERVICE
    );
    if (response.status === 200) {
      const {
        blueCollarPCs,
        whiteCollarPCs,
        employeeTypes,
        generalDetails,
        billingDetails,
        wageElementsDetails,
        contactDetails,
        invoiceDetails,
        // pcEmpTypes,
        location,
        retentionPeriods,
        payCondition,
        consultant,
        genders,
        languages,
        rolesList,
        expLevels,
        WorkUnits,
        coeffTypes,
        // functionsList,
        initialEmpCoeff,
        vatCodes,
        officesList
      } = response.data;

      const genderOptions = mapToSelect(genders);
      const languageOptions = mapToSelect(languages);
      const rolesOptions = mapToSelect(rolesList);
      const expLevelOptions = mapToSelect(expLevels);
      // const functionOptions = mapToSelect(functionsList, "function_name");
      const coeffTypeOptions = coeffTypes;
      const contactListOptions = contactDetails.contactList ?? [];
      const vatCodeList = mapToSelect(vatCodes);
      dispatch({
        type: CONST.SET_DROPDOWN,
        dropdownValues: initialEmpCoeff.coeffValues,
        field: "initialEmpCoeff",
      });
      dispatch({
        type: CONST.SET_DROPDOWN,
        dropdownValues: vatCodeList,
        field: "vatCodes",
      });
      dispatch({
        type: CONST.SET_DROPDOWN,
        dropdownValues: officesList,
        field: "offices",
      });
      dispatch({
        type: CONST.SET_DROPDOWN,
        dropdownValues: coeffTypeOptions,
        field: "coeffTypes",
      });
      // dispatch({
      //   type: CONST.SET_DROPDOWN,
      //   dropdownValues: functionOptions,
      //   field: "functionDropdownList",
      // });
      dispatch({
        type: CONST.SET_DROPDOWN,
        dropdownValues: expLevelOptions,
        field: "levelDropDown",
      });
      dispatch({
        type: CONST.SET_DROPDOWN,
        dropdownValues: contactListOptions,
        field: "contactList",
      });
      dispatch({
        type: CONST.SET_DROPDOWN,
        dropdownValues: genderOptions,
        field: "genders",
      });
      dispatch({
        type: CONST.SET_DROPDOWN,
        dropdownValues: languageOptions,
        field: "languages",
      });
      dispatch({
        type: CONST.SET_DROPDOWN,
        dropdownValues: consultant,
        field: "consultantList",
      });
      dispatch({
        type: CONST.SET_DROPDOWN,
        dropdownValues: blueCollarPCs,
        field: "blueCollarPCs",
      });
      dispatch({
        type: CONST.SET_DROPDOWN,
        dropdownValues: whiteCollarPCs,
        field: "whiteCollarPCs",
      });
      dispatch({
        type: CONST.SET_DROPDOWN,
        dropdownValues: employeeTypes,
        field: "employeeTypes",
      });
      const locationDropdown = mapToSelect(location, "location");
      dispatch({
        type: CONST.SET_DROPDOWN,
        dropdownValues: locationDropdown,
        field: "location",
      });

      const retentionDropdown = mapToSelect(retentionPeriods);
      dispatch({
        type: CONST.SET_DROPDOWN,
        dropdownValues: retentionDropdown,
        field: "retentionPeriods",
      });

      const payDropdown = mapToSelect(payCondition);
      dispatch({
        type: CONST.SET_DROPDOWN,
        dropdownValues: payDropdown,
        field: "payCondition",
      });

      const fieldMappings = [
        {
          fieldPrefix: "general",
          data: generalDetails,
        },
        {
          fieldPrefix: "invoice",
          data: invoiceDetails,
        },
      ];

      dispatch({
        type: CONST.SET_DROPDOWN,
        dropdownValues: rolesOptions,
        field: "rolesList",
      });

      fieldMappings.forEach(({ fieldPrefix, data }) => {
        if (data) {
          Object.entries(data).forEach(([field, value]) => {
            const dynamicActionType: DynamicActionType =
              `UPDATE_${fieldPrefix.toUpperCase()}_FIELD` as DynamicActionType;

            if (
              (field === "startDate" && typeof value === "string") ||
              (field === "dateOfSign" && typeof value === "string")
            ) {
              // Convert the string to a Date object
              const dateObject = new Date(value);
              value = dateObject;
            }

            dispatch({
              type: dynamicActionType,
              field,
              value: value as string | null | number,
            });
          });
        }
      });

      const selectedPc = generalDetails?.paritairBlue ?? [];
      const isPc124 = selectedPc.find((item: any) => item.PC.value === 39);
      //if pc is 124 vat rate is set to 0% medecontract
      if (isPc124) {
        const vatCode = state.vatCodes.filter(
          (code) => code.value === 8 || code.value === 4
        );
        dispatch({
          type: CONST.UPDATE_GENERAL_FIELD,
          field: "vatInclusive",
          value: vatCode[0] as Option,
        });
      }

      if (
        wageElementsDetails?.wage_elements &&
        wageElementsDetails.wage_elements.length > 0
      ) {
        dispatch({
          type: CONST.ADD_COMPOSITION_FIELD,
          data: wageElementsDetails.wage_elements,
        });
        dispatch({
          type: CONST.AM_APPROVED_COMPOSITION_DATA,
          data: wageElementsDetails.wage_elements,
        });
        dispatch({
          type: CONST.UPDATE_COMPOSITION_FIELD,
          field: "remarks",
          value: "At cost",
          index: 21,
        });
        dispatch({
          type: CONST.UPDATE_COMPOSITION_FIELD,
          field: "remarks",
          value: "At cost",
          index: 22,
        });
      }

      if (billingDetails && billingDetails.length > 0) {
        dispatch({
          type: CONST.SET_BILLING_TAB_DATA,
          data: billingDetails,
        });
        dispatch({
          type: CONST.AM_APPROVED_DATA,
          data: billingDetails,
        });
      }

      if (
        contactDetails?.selectedContacts &&
        contactDetails.selectedContacts.length > 0
      ) {
        contactDetails.selectedContacts.map((item: any) => {
          dispatch({
            type: CONST.UPDATE_CONTACT_LIST,
            field: "selectedContact",
            value: item,
          });
        });
      }
      if (contactDetails.contacts && contactDetails.contacts.length > 0) {
        contactDetails.contacts.forEach(
          (contact: Record<string, any>, index: number) => {
            const updatedContact = { ...contact };
            // Modify the 'phNumber' field
            if (updatedContact.hasOwnProperty("phNumber")) {
              updatedContact.phNumber = "+" + updatedContact.phNumber;
            }
            if (
              updatedContact.hasOwnProperty("dob") &&
              typeof updatedContact.dob === "string"
            ) {
              updatedContact.dob = new Date(updatedContact.dob);
            }
            // Dispatch an action for each field in the modified contact
            Object.entries(updatedContact).forEach(([field, value]) => {
              dispatch({
                type: CONST.ADD_UPDATE_DYNAMIC,
                field,
                value,
                index,
              });
            });
          }
        );
      }

      let workUnitList;
      if (agreementId) {
        workUnitList = WorkUnits.filter(
          (item: any) => item.id === generalDetails.type
        );
      } else {
        workUnitList = WorkUnits;
      }

      dispatch({
        type: CONST.SET_DROPDOWN,
        dropdownValues: workUnitList,
        field: "workUnitList",
      });
      if (!agreementId && state.general.type === null) {
        dispatch({
          type: CONST.UPDATE_GENERAL_FIELD,
          field: "type",
          value: WorkUnits[0]?.id,
        });
      }
      // else {
      //   const newDataArray = [
      //     ...state.general.paritairWhite,
      //     ...state.general.paritairBlue,
      //   ];
      //   dispatch({
      //     type: CONST.UPDATE_BILLING_TAB,
      //     newData: newDataArray,
      //   });
      // }
      const newDataArray = [
        ...generalDetails.paritairBlue,
        ...generalDetails.paritairWhite,
      ];

      if (isCoeffTab && newDataArray.length > 0) {
        const pcFunctions = await GetFunctions(
          newDataArray,
          state.general.province,
          state.general.workType,
          state.general.type,
          state.general.coeffType
        );
        dispatch({
          type: CONST.SET_DROPDOWN,
          dropdownValues: pcFunctions.coeffValues,
          field: "initialEmpCoeff",
        });
        dispatch({
          type: CONST.UPDATE_BILLING_TAB,
          newData: newDataArray,
        });
      }
    }
  };

  useTabUrlUpdater(state.tabDetails, dispatch);
  const handleTabClick = async (tabId: string) => {
    const currentTab = state.tabDetails.findIndex((tab) => tabId === tab.id);
    state.tabDetails[currentTab].error = false;
    const updatedTabDetails = state.tabDetails.map((tab) => ({
      ...tab,
      showStatus: tab.id === tabId,
    }));
    dispatch({ type: CONST.UPDATE_TAB_DETAILS, tabDetails: updatedTabDetails });

    const newDataArray = [
      ...state.general.paritairWhite,
      ...state.general.paritairBlue,
    ];

    if (currentTab === 2) {
      const pcFunctions = await GetFunctions(
        newDataArray,
        state.general.province,
        state.general.workType,
        state.general.type,
        state.general.coeffType
      );
      dispatch({
        type: CONST.SET_DROPDOWN,
        dropdownValues: pcFunctions.coeffValues,
        field: "initialEmpCoeff",
      });
      dispatch({
        type: CONST.UPDATE_BILLING_TAB,
        newData: newDataArray,
      });
    } else if (currentTab === 1 && !agreementId) {
      const selectedContacts = Array.isArray(state.selectedContact)
        ? state.selectedContact // If it's already an array, use it as is
        : [state.selectedContact];
      if (selectedContacts && selectedContacts.length > 0) {
        selectedContacts.map((item: any) => {
          dispatch({
            type: CONST.UPDATE_CONTACT_LIST,
            field: "selectedContact",
            value: item,
            contactId: Array.isArray(item) ? item : [item],
          });
        });
      }
    }
  };

  const ConstructTabs = (id: string, showStatus: boolean) => {
    switch (id) {
      case "general":
        return <GeneralTab />;
      case "contacts":
        return <ContactsTab />;
      case "coefficients":
        return <BillingTab />;
      case "compositionData":
        return state?.general?.coeffType === 1 ? null : <CompositionTab />;
      case "invoice":
        return <InvoiceTab />;
      default:
        break;
    }
  };

  const permissionType = agreementId ? "update" : "create";
  const permissionObject: any = {
    permission: "Cooperation agreeement",
  };
  permissionObject[permissionType] = true;

  const filters = {
    company: state.general.company,
  };
  return (
    <AccessControl
      requiredPermissions={[permissionObject]}
      renderNoAccess={true}
    >
      <div className="row header-sticky position-sticky">
        <div className="col-3 align-self-center">
          <Title title={t("Cooperation agreement")} />
        </div>
        {agreementId && (
          <>
            <div className="col-9 headerIcons text-end">
              <ActionButtons buttons={actionButtons} filters={filters} />
              <div className=" form-manage-button d-inline-block">
                <UserInfoTooltip
                  createdBy={state.general.createdBy}
                  createdAt={formatDateAndTime(state.general.createdAt)}
                  updatedAt={formatDateAndTime(state.general.updatedAt)}
                  updatedBy={state.general.updatedBy}
                />
              </div>
            </div>
          </>
        )}
      </div>

      <div className="position-relative" style={{ paddingTop: "2vw" }}>
        <nav
          className={`agreement-step-tabs createFormTabs ${
            isSticky
              ? "createTabsSticky position-sticky"
              : "agreement-step-tabs-absolute position-absolute w-100"
          }`}
        >
          <div
            className="nav nav-tabs border-0 col-xxl-10 m-auto col-xl-11 col-lg-11 col-md-6"
            id="nav-tab"
            role="tablist"
          >
            {state.tabDetails.map((item: any, index) =>
              state?.general?.coeffType === 1 &&
              item.id === "compositionData" ? null : (
                <button
                  key={item.id}
                  className={`nav-link marginRight1 text-break ${
                    item.error ? "error" : ""
                  } ${item.showStatus ? "active" : "inactive"}`}
                  id={`${item.id}-tab`}
                  data-bs-toggle="tab"
                  data-bs-target={`#${item.id}`}
                  type="button"
                  role="tab"
                  aria-controls={`nav-${item.id}`}
                  aria-selected={item.showStatus}
                  onClick={() => handleTabClick(item.id)}
                  style={{
                    borderRadius: "0.5vw",
                    marginRight: "0.5vw",
                    padding: "0.5vw",
                  }}
                >
                  <div className="step-title">
                    {t(`${item.title}`)}
                    {item.error && (
                      <span
                        className="exclamation-icon ms-2"
                        title={t("Please fill all the mandatory fields")}
                        data-toggle="tooltip"
                      >
                        <FontAwesomeIcon icon={faTriangleExclamation} />
                      </span>
                    )}
                  </div>
                </button>
              )
            )}
          </div>
        </nav>

        <div className="tab-content py-0" id="nav-tabContent">
          {state.tabDetails.map((item: any) => (
            <div
              key={item.id}
              className={`tab-pane fade ${item.showStatus ? "show" : ""} ${
                item.showStatus ? "active" : ""
              }`}
              id={item.id}
              role="tabpanel"
              aria-labelledby={`${item.id}-tab`}
            >
              {ConstructTabs(item.id, item.showStatus)}
            </div>
          ))}
        </div>
      </div>
    </AccessControl>
  );
};

export default translate(AgreementForm);
