import React, { useEffect, useState } from 'react';
import { t, translate } from '../translation/Translation';
import { LabelWithInputField } from 'components/common/molecules/LabelWithInputField';
import Title from 'components/common/atoms/Title';
import LabelWithCKEditorField from 'components/common/molecules/LabelWithCKEditorField';
import PhoneCallIcon from 'static/images/PhoneCall';
import { PHONE_CALL_URL } from "routes/ApiEndpoints";
import { ApiCall } from 'components/common/services/ApiServices';
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from 'Constants';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Button from 'components/common/atoms/Button';
import GetQueryParams from "components/common/services/GetQueryParams";

const CandidateFeedback = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { todoExtensionId, candidateId, presentationId, type, todoId, stageId } = useParams<{
        todoExtensionId: string;
        candidateId: string;
        presentationId: string;
        type: string;
        todoId: string;
        stageId: string;
    }>();
    const queryParams = GetQueryParams();

    const [formData, setFormData] = useState({
        candidateName: "",
        reasonForRejection: "",
        feedbackByCandidate: "",
        candidatePhoneNumber: "",
    });

    const handleFieldChange = (
        e: React.ChangeEvent<
            HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
    ) => {
        const { name, value } = e.target;

        setFormData((prevData: any) => ({
            ...prevData,
            [name]: value
        }));
    };


    const handleCKEditorChange = (
        event: any,
        editor: any,
        fieldName: string,
    ) => {
        const content = editor.getData(); // Get the content from the editor
        setFormData((prevData: any) => ({
            ...prevData,
            [fieldName]: content
        }));
    };

    const fetchCandidateFeedback = async () => {
        const data = {
            candidateId: candidateId,
            todoExtensionId: todoExtensionId,
            presentationId: presentationId
        }
        const response = await ApiCall.service("getCandidateFeedback", "POST", data, false, CENTRAL_DATA_MANAGEMENT_MICROSERVICE);
        if (response.status === 200) {
            setFormData((prev) => ({
                ...prev,
                candidateName: response.data.candidateName,
                reasonForRejection: response.data.reasonForRejection,
                feedbackByCandidate: response.data.feedBack,
                candidatePhoneNumber: response.data.candidatePhoneNumber,
            }))
        }
    }

  useEffect(() => {
        if (type === 'create') {
            const candidateName = queryParams.get('candidateName');
            setFormData((prev: any) => ({
                ...prev,
                candidateName: candidateName,
            }));
        } else {
            fetchCandidateFeedback();
        }
    }, []);
    const storeCandidateFeedBack = async (event: any, type: string) => {
        event.preventDefault();
        setLoading(true);
        const data = {
            formData: formData,
            todoExtensionId: todoExtensionId,
            todoId: todoId,
            stageId: stageId,
            candidateId: candidateId,
            presentationId: presentationId,
        };

        const response = await ApiCall.service(
            "rejectCandidate",
            "POST",
            data,
            true,
            CENTRAL_DATA_MANAGEMENT_MICROSERVICE
        );
        if (response.status === 200) {
            navigate("/todos/overview");
        }
        setLoading(false);
    };

  return (
    <>
      <div>
        <Title title={t("Candidate feedback")} />
        <form>
          <div className="form-border">
            <div className="row">
              <div className="col-sm-12 col-md-5">
                <LabelWithInputField
                  name="candidateName"
                  handleChange={handleFieldChange}
                  value={formData.candidateName}
                  id="name"
                  label={t("Candidate name")}
                  placeholder={t("Name")}
                  isMandatory={false}
                  isDisabled={type === "view"}
                />
              </div>
              <div className="col-sm-12 col-md-5">
                <LabelWithInputField
                  name="reasonForRejection"
                  handleChange={handleFieldChange}
                  value={formData.reasonForRejection}
                  id="reasonForRejection"
                  label={t("Reason for rejection")}
                  placeholder={t("Reason for rejection")}
                  isMandatory={false}
                  isDisabled={type === "view"}
                />
              </div>
              <div className="col-2 mt-35">
                <span className="table-action-icons float-end">
                  <a
                    href={`${PHONE_CALL_URL}${formData.candidatePhoneNumber.replace(
                      "+",
                      "%2B"
                    )}`}
                    target="_blank"
                    title={t("Call")}
                    className="text-decoration-none me-2"
                    rel="noreferrer"
                  >
                    <PhoneCallIcon />
                  </a>
                </span>
              </div>
            </div>
            <div className="col-12">
              <LabelWithCKEditorField
                label={t("Feedback")}
                name="feedbackByCandidate"
                value={formData.feedbackByCandidate}
                placeholder={t("Type here") + "..."}
                handleChange={(event, editor) =>
                  handleCKEditorChange(event, editor, "feedbackByCandidate")
                }
                className="field-shadow"
                isDisabled={type === "view"}
              />
            </div>
          </div>
          <div className="row py-4">
            <div className="col-md-6 align-self-center">
              <Link
                to={"/todos/overview"}
                className="back-btn text-decoration-underline"
              >
                {t("Back")}
              </Link>
            </div>
            {type === 'create' && (
              <div className="col-md-6 d-flex justify-content-end">
                <Button
                  title={t("Save")}
                  type="submit"
                  className="form-button shadow-none px-3"
                  handleClick={(e) => storeCandidateFeedBack(e, "reject")}
                />
              </div>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default translate(CandidateFeedback);
