import React from "react";
import { useFormContext } from "../../../context/Context";
import GeneralDetails from "../GeneralAndWorkAgreements/GeneralDetails";
import Navigation from "../../form-navigation/Navigation";
import * as CONST from "../../../annotations/AdminFicheConstants";
import {
  scrollToTop,
  validateForm,
  validateMultiSelectField,
  validateRadioField,
  validateSelectField,
} from "components/common/services/ValidationService";
import {
  Option,
  ValidationRules,
} from "components/common/utlis/TypeAnnotations";

const General = () => {
  const { state, dispatch } = useFormContext();

  const validation = (
    name: string,
    value:
      | string
      | boolean
      | Date
      | object[]
      | Option
      | null
      | undefined
      | number,
    isSingleFieldValidation: boolean = false
  ) => {
    const isPC124 = state.general.paritairBlue.some(
      (pc: any) => pc.value === 39
    );
    const validationRules: ValidationRules = {
      syndicatedDelig: [validateRadioField],
      vca: [validateSelectField],
      nssoCatg: isPC124 ? [validateMultiSelectField] : [],
      catchUpRest: [validateRadioField],
      leaves: [validateRadioField],
      explicitRegime: [validateRadioField],
      exemptionBV:
        state.general.explicitRegime === 1 ? [validateMultiSelectField] : [],
      isKBApplicable: isPC124 ? [validateRadioField] : [],
      encodageContacts: [validateMultiSelectField],
    };

    const validationErrors = validateForm(
      { ...state.general, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    dispatch({
      type: CONST.UPDATE_FIELD_ERROR,
      field: name,
      error: validationErrors[name],
    });

    const isFieldValid = Object.keys(validationErrors).length === 0;

    if (isFieldValid) {
      dispatch({ type: CONST.UPDATE_FIELD_ERROR, field: name, error: "" });
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, checked, type } = e.target as HTMLInputElement;

    // Determine processed value based on input type
    const processedValue =
      type === "radio"
        ? parseInt(value)
        : type === "checkbox"
        ? checked
          ? 1
          : 0
        : value;

    // Helper function to dispatch updates
    const dispatchFieldUpdate = (fieldName: string, fieldValue: any) => {
      dispatch({
        type: CONST.UPDATE_FIELDS,
        template: CONST.GENERAL,
        field: fieldName,
        value: fieldValue,
      });
    };

    // Handle mutually exclusive fields (largeFlex and smallFlex)
    if (name === "largeFlex") {
      dispatchFieldUpdate("smallFlex", 0);
    } else if (name === "smallFlex") {
      dispatchFieldUpdate("largeFlex", 0);
    } else if (name === "isContracts" && processedValue === 0) {
      dispatchFieldUpdate("isContractsPerSem", null);
    } else if (name === "explicitRegime" && processedValue === 0) {
      dispatchFieldUpdate("exemptionBV", []);
    } else if (name === "encodage" && processedValue === 0) {
      dispatchFieldUpdate("encodageContacts", []);
    }

    // Dispatch the field change
    dispatchFieldUpdate(name, processedValue);

    // Trigger validation
    validation(name, value, true);
  };

  const handleSelectChange = (selectedOption: any, title: string) => {
    dispatch({
      type: CONST.UPDATE_FIELDS,
      template: CONST.GENERAL,
      field: title,
      value: selectedOption,
    });
    validation(title, selectedOption, true);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    scrollToTop();
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12">
          <div className="form-border" style={{ paddingTop: "3.4vw" }}>
            <GeneralDetails
              handleChange={handleChange}
              handleSelectChange={handleSelectChange}
              state={state}
            />
          </div>
        </div>
      </div>
      <Navigation />
    </form>
  );
};

export default General;
