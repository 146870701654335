import React from 'react'

const Question:React.FC = () => {
  return (
    <>
      <svg width="30" height="30" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="3" y="0.5" width="14" height="14" rx="0.5" stroke="currentColor" />
        <path d="M1 3.5V14C1 15.3807 2.11929 16.5 3.5 16.5H14.5" stroke="currentColor" />
        <path d="M7.50021 5.286C7.49884 5.31829 7.5041 5.35053 7.51564 5.38072C7.52719 5.41091 7.54479 5.43841 7.56737 5.46155C7.58994 5.48468 7.61701 5.50296 7.6469 5.51524C7.6768 5.52753 7.70889 5.53357 7.74121 5.533H8.56621C8.70421 5.533 8.81421 5.42 8.83221 5.283C8.92221 4.627 9.37221 4.149 10.1742 4.149C10.8602 4.149 11.4882 4.492 11.4882 5.317C11.4882 5.952 11.1142 6.244 10.5232 6.688C9.85021 7.177 9.31721 7.748 9.35521 8.675L9.35821 8.892C9.35926 8.95761 9.38606 9.02017 9.43284 9.0662C9.47961 9.11222 9.5426 9.13801 9.60821 9.138H10.4192C10.4855 9.138 10.5491 9.11166 10.596 9.06478C10.6429 9.01789 10.6692 8.9543 10.6692 8.888V8.783C10.6692 8.065 10.9422 7.856 11.6792 7.297C12.2882 6.834 12.9232 6.32 12.9232 5.241C12.9232 3.73 11.6472 3 10.2502 3C8.98321 3 7.59521 3.59 7.50021 5.286ZM9.05721 11.049C9.05721 11.582 9.48221 11.976 10.0672 11.976C10.6762 11.976 11.0952 11.582 11.0952 11.049C11.0952 10.497 10.6752 10.109 10.0662 10.109C9.48221 10.109 9.05721 10.497 9.05721 11.049Z" fill="currentColor" />
      </svg>

    </>
  )
}

export default Question