import { t } from "components/CentralDataMangement/translation/Translation";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import { validateMultiSelectField, validateRequired } from "components/common/services/ValidationService";
import FormBuilder from "components/common/services/form/FormBuilder";
import { useParams } from "react-router-dom";
import {
    CONF_SETTINGS_ADD,
    CONF_SETTINGS_EDIT,
    CONF_SETTINGS_UPDATE,
  } from "routes/ApiEndpoints";

const TaskSubType: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const FunctionFormValidation = {
        name: [validateRequired],
        // domainName: [validateMultiSelectField],
    };

    const permissionType = id ? "update" : "create";
    const permissionObject: any = {
        permission: "Follow-up",
    };
    permissionObject[permissionType] = true;
    return (
        <AccessControl
            requiredPermissions={[permissionObject]}
            renderNoAccess={true}
        >
            <FormBuilder
                title={
                    id === undefined ? t("Add task sub type") : t("Edit task sub type")
                }
                SaveDataAPI={
                    id === undefined ? CONF_SETTINGS_ADD : CONF_SETTINGS_UPDATE
                }
                entryId={id}
                getDataAPI={CONF_SETTINGS_EDIT}
                formName={"TaskSubType"}
                redirect={-1}
                validationRules={FunctionFormValidation}
                microService={"central-data-management"}
                backButton={true}
                type="taskSubType"
            />
        </AccessControl>
    );
}
export default TaskSubType;