import React, { useEffect, useState } from "react";
import { useFormContext } from "../../context/Context";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import Calender from "components/common/molecules/Calender";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import CheckBoxField from "components/common/atoms/CheckBoxField";
import LabelField from "components/common/atoms/LabelField";
import RadioField from "components/common/atoms/RadioField";
import {
  CREATE_AGREEMENT,
  GET_OFFICE_FOR_CONSULTANT,
} from "routes/ApiEndpoints";
import {
  validateForm,
  validateRequired,
  validateDate,
  validateRadioField,
  validateSelectField,
  validateMultiSelectField,
  Option,
  scrollToTop,
} from "components/common/services/ValidationService";
import { ValidationRules } from "components/common/utlis/TypeAnnotations";
import WhiteCollarSection from "./WhiteCollarSection";
import BlueCollarSection from "./BlueCollarSection";
import { useNavigate, useParams } from "react-router-dom";
import { ApiCall } from "components/common/services/ApiServices";
import CustomNotify from "components/common/atoms/CustomNotify";
import { MANAGE_COOPERATION_AGREEMENT } from "routes/RouteConstants";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import { mapToSelect } from "components/common/utlis/MapToSelect";
import GetFunctions from "./GetFunctions";
import { COMPANY_MICROSERVICE } from "Constants";
import * as CONST from "../../annotations/CoopAgreementConstants";
import Navigation from "../formNavigation/Navigation";
import FeeBased, { FeeBasedItem } from "./FeeBased";
import { FormatPCList } from "components/common/utlis/FormatPCList";

interface pcProps {
  PC: Option | null;
  employeeType: Option | null;
}

const GeneralTab: React.FC = () => {
  const { state, dispatch } = useFormContext();
  const { agreementId } = useParams<{ agreementId: string }>();
  const { companyId } = useParams<{ companyId: string }>();
  const { revisionId } = useParams<{ revisionId: string }>();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [validationStatus, setValidationStatus] = useState({
    isValid: false,
    type: "",
  });

  const [PCWhite, setPCWhite] = useState<pcProps>({
    PC: null,
    employeeType: null,
  });

  const [PCBlue, setPCBlue] = useState<pcProps>({
    PC: null,
    employeeType: null,
  });

  const validation = (
    name: string,
    value:
      | string
      | number
      | boolean
      | Date
      | Option
      | object[]
      | null
      | undefined
      | object,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      company: [validateRequired],
      startDate: [validateDate],
      consultant: [validateSelectField],
      officeNumber: [validateSelectField],
      province: [validateMultiSelectField],
      type: [validateRadioField],
      vatInclusive: [validateRadioField],
      coefficient: [validateSelectField],
      // paritairBlue:
      //   state.general.paritairWhite.length > 0 ? [] : [validatePCEmployeeType],
      // paritairWhite:
      //   state.general.paritairBlue.length > 0 ? [] : [validatePCEmployeeType],
      empBlue: [validateMultiSelectField],
      empWhite: [validateMultiSelectField],
      PC: [validateSelectField],
      employeeType: [validateMultiSelectField],
      PC2: [validateSelectField],
      employeeType2: [validateMultiSelectField],
    };

    const validationErrors = validateForm(
      { ...state.general, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );
    dispatch({
      type: CONST.UPDATE_FIELD_ERROR,
      fieldName: name,
      error: validationErrors[name],
    });
    const isFieldValid = Object.keys(validationErrors).length === 0;

    if (isFieldValid) {
      state.fieldError[name] = "";
    }
    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const handleInputField = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = e.target;
    if (type === "radio") {
      const newValue = parseInt(value);
      dispatch({
        type: CONST.UPDATE_GENERAL_FIELD,
        field: name,
        value: newValue,
      });
      if (name === "type") {
        dispatch({
          type: CONST.UPDATE_GENERAL_FIELD,
          field: "officeNumber",
          value: "",
        });
        dispatch({
          type: CONST.UPDATE_BILLING_TAB,
          newData: [],
        });
      }

      if (name === "coeffType") {
        dispatch({
          type: CONST.UPDATE_CONTACT_LIST,
          field: "selectedContact",
          value: [],
        });
      }
      validation(name, newValue, true);
    } else if (type === "checkbox") {
      dispatch({
        type: CONST.UPDATE_GENERAL_FIELD,
        field: name,
        value: checked,
      });
    } else {
      dispatch({ type: CONST.UPDATE_GENERAL_FIELD, field: name, value: value });
      validation(name, value, true);
    }
  };

  const handleDateChange = (date: Date | null, name: string) => {
    dispatch({ type: CONST.UPDATE_GENERAL_FIELD, field: name, value: date });
    validation(name, date, true);
  };

  const handleSelectField = (selectedOption: any, name: string) => {
    if (name === "consultant") {
      const userId = selectedOption?.value;
      userId !== null && fetchPrimaryOffice(userId);
    }
    dispatch({
      type: CONST.UPDATE_GENERAL_FIELD,
      field: name,
      value: selectedOption,
    });
    validation(name, selectedOption, true);
  };

  const handlePCFields = (selectedOption: any, name: string) => {
    if (name === "PC" || name === "employeeType") {
      setPCWhite({
        ...PCWhite,
        [name]: selectedOption,
      });
      validation(name, selectedOption, true);
    } else {
      const fieldName = name === "PC2" ? "PC" : "employeeType";
      setPCBlue({
        ...PCBlue,
        [fieldName]: selectedOption,
      });
      validation(name, selectedOption, true);
    }
  };

  const getWhiteCollarPCs = () => {
    const selectedPCs = state.general.paritairWhite.map(
      (item: any) => item.PC?.value
    );
    const PCs = state.whiteCollarPCs.filter(
      (item) => !selectedPCs.includes(item.id)
    );
    const pcOptions = FormatPCList(PCs);
    return pcOptions;
  };

  const getBlueCollarPCs = () => {
    const unitType = state.general.type;
    const selectedPCs = state.general.paritairBlue.map(
      (item: any) => item.PC?.value
    );
    const PCs = state.blueCollarPCs.filter(
      (item) => !selectedPCs.includes(item.id) && item.id !== 39
    );

    let PCOptions = FormatPCList(PCs);
    if (unitType === 2) {
      if (selectedPCs.length === 0) {
        const PC124 = state.blueCollarPCs.filter((item) => item.id === 39);
        PCOptions = FormatPCList(PC124);
      } else {
        PCOptions = [];
      }
    }
    return PCOptions;
  };

  const fetchPrimaryOffice = async (userId: number | null) => {
    const data = {
      consultantId: userId,
    };
    const response = await ApiCall.service(
      GET_OFFICE_FOR_CONSULTANT,
      "POST",
      data,
      false,
      COMPANY_MICROSERVICE
    );

    if (response.status === 200) {
      dispatch({
        type: CONST.UPDATE_GENERAL_FIELD,
        field: "officeNumber",
        value: "",
      });
      const dropdownOptions = response.data["office"]
        ? response.data["office"]
        : [];
      dispatch({
        type: CONST.UPDATE_GENERAL_FIELD,
        value: dropdownOptions,
        field: "officeList",
      });
    }
  };

  const addParitiarComitee = (type: string) => {
    let hasValidationErrors = false;
    if (type === "whiteCollar") {
      if (PCWhite.PC === null || PCWhite.PC.value === null) {
        dispatch({
          type: CONST.UPDATE_FIELD_ERROR,
          fieldName: "PC",
          error: t("This field is required"),
        });
        hasValidationErrors = true;
      }
      if (
        PCWhite.employeeType === null ||
        (typeof PCWhite.employeeType === "object" &&
          Object.keys(PCWhite.employeeType).length === 0)
      ) {
        dispatch({
          type: CONST.UPDATE_FIELD_ERROR,
          fieldName: "employeeType",
          error: t("This field is required"),
        });
        hasValidationErrors = true;
      }

      if (
        !hasValidationErrors &&
        (state.fieldError.PC === "" || state.fieldError.PC === undefined) &&
        (state.fieldError.employeeType === "" ||
          state.fieldError.employeeType === undefined)
      ) {
        dispatch({ type: CONST.ADD_WHITECOLLAR_FIELD, newData: PCWhite });
        setPCWhite({
          PC: null,
          employeeType: null,
        });
      }
    } else {
      if (PCBlue.PC === null) {
        dispatch({
          type: CONST.UPDATE_FIELD_ERROR,
          fieldName: "PC2",
          error: t("This field is required"),
        });
        hasValidationErrors = true;
      }
      if (
        PCBlue.employeeType === null ||
        (typeof PCBlue.employeeType === "object" &&
          Object.keys(PCBlue.employeeType).length === 0)
      ) {
        dispatch({
          type: CONST.UPDATE_FIELD_ERROR,
          fieldName: "employeeType2",
          error: t("This field is required"),
        });
        hasValidationErrors = true;
      }

      if (
        !hasValidationErrors &&
        (state.fieldError.PC2 === "" || state.fieldError.PC2 === undefined) &&
        (state.fieldError.employeeType2 === "" ||
          state.fieldError.employeeType2 === undefined)
      ) {
        dispatch({ type: CONST.ADD_BLUECOLLAR_FIELD, newData: PCBlue });
        setPCBlue({
          PC: null,
          employeeType: null,
        });

        //if pc is 124 vat rate is set to 0% medecontract
        if (PCBlue.PC?.value === 39) {
          const vatCode = state.vatCodes.filter(
            (code) => code.value === 8 || code.value === 4
          );
          dispatch({
            type: CONST.UPDATE_GENERAL_FIELD,
            field: "vatInclusive",
            value: vatCode[0] as Option,
          });
        }
      }
    }
  };

  const getVatcodeForPC124 = () => {
    const codes = state.vatCodes;
    if (codes.length > 0) {
      const selectedPC: any = [
        ...state.general.paritairWhite,
        ...state.general.paritairBlue,
      ];
      if (selectedPC.length > 0) {
        const isPC124 = selectedPC.find((pc: any) => pc?.PC?.value === 39);
        if (isPC124) {
          const vatCode = codes.filter(
            (code) => code.value === 8 || code.value === 4
          );
          if (vatCode) {
            return vatCode;
          } else {
            return codes;
          }
        } else {
          return codes;
        }
      }
    }
    return [];
  };

  const pcChangeHandler = (id: number, type: string) => {
    if (type === "whiteCollar") {
      const record = state.general.paritairWhite.filter(
        (item, index) => index === id
      );
      let data: pcProps = record[0] as pcProps;
      setPCWhite(data);
      dispatch({
        type: CONST.UPDATE_FIELD_ERROR,
        fieldName: "employeeType",
        error: "",
      });
      dispatch({
        type: CONST.UPDATE_FIELD_ERROR,
        fieldName: "PC",
        error: "",
      });
    } else {
      const record = state.general.paritairBlue.filter(
        (item, index) => index === id
      );
      let data: pcProps = record[0] as pcProps;
      setPCBlue(data);
      dispatch({
        type: CONST.UPDATE_FIELD_ERROR,
        fieldName: "employeeType2",
        error: "",
      });
      dispatch({
        type: CONST.UPDATE_FIELD_ERROR,
        fieldName: "PC2",
        error: "",
      });
    }
  };

  const pcDeleteHandler = (indexToRemove: number, type: string) => {
    if (type === "whiteCollar") {
      dispatch({ type: CONST.REMOVE_WHITE_PC, indexToRemove });
    } else {
      dispatch({ type: CONST.REMOVE_BLUE_PC, indexToRemove });
    }
  };

  const getPcEmployeeTypes = (data: any, empCategoryId: number) => {
    const employeetypesList = state.employeeTypes.filter(
      (item: any) => item.employeetype_category_id === empCategoryId
    );
    const employeetypes = mapToSelect(employeetypesList, "employeetype_name");
    return employeetypes ?? [];
  };

  const validStatus = (validation: any) => {
    setValidationStatus({
      isValid: validation.isValid,
      type: validation.type,
    });
  };

  const getConsultantList = () => {
    const consultants = state.consultantList;
    if (consultants.length > 0) {
      return consultants.map((item: any) => ({
        value: item.userId,
        label: `${item.first_name} ${item.last_name}`,
      }));
    }

    return [];
  };

  const getOfficeList = () => {
    const userOffices = state.general.officeList;
    const offices = state.offices;
    const unit = state.general.type;

    if (userOffices.length > 0 || offices.length > 0) {
      let filteredOffices = [];
      const excludedCodes = [
        "I851",
        "I850",
        "I856",
        "I857",
        "I855",
        "W020",
        "W021",
        "W022",
        "W050",
        "W023",
      ];

      switch (unit) {
        case 1:
          // Filter offices starting with 'W' and exclude specific office types
          filteredOffices = userOffices.filter(
            (item: any) =>
              item.office_type.startsWith("W") &&
              !excludedCodes.includes(item.office_type)
          );
          break;
        case 2:
          // Filter offices starting with 'C' and exclude specific office types
          filteredOffices = userOffices.filter(
            (item: any) =>
              item.office_type.startsWith("C") &&
              !excludedCodes.includes(item.office_type)
          );
          break;
        case 3:
          // Filter specific office types for unit 3
          filteredOffices = offices.filter((item: any) =>
            // item.office_type.includes(["I851", "I850", "I856", "I857", "I855"])
            ["I851", "I850", "I856", "I857", "I855"].includes(item.office_type)
          );

          break;
        case 4:
          // Filter specific office types for unit 4
          filteredOffices = offices.filter((item: any) =>
            ["W020", "W021", "W022"].includes(item.office_type)
          );
          break;
        case 5:
          // Filter specific office type for unit 5
          filteredOffices = offices.filter(
            (item: any) => item.office_type === "W050"
          );
          break;
        case 6:
          // Filter specific office type for unit 6
          filteredOffices = offices.filter(
            (item: any) => item.office_type === "W023"
          );
          break;
        default:
          return mapToSelect(userOffices);
      }

      // Map filtered offices to the format required for the select dropdown
      return mapToSelect(filteredOffices);
    }

    return [];
  };

  const employeeCategoryType = state.workUnitList.find(
    (item) => item.id === state.general.type
  );

  // Check if WhiteCollar (emp_category_id === 1) exists
  const WhiteCollar = !!employeeCategoryType?.business_unit_emp_category.find(
    (category) => category.emp_category_id === 1
  );

  // Check if BlueCollar (emp_category_id === 2) exists
  const BlueCollar = !!employeeCategoryType?.business_unit_emp_category.find(
    (category) => category.emp_category_id === 2
  );

  const handleSubmit = async (event: React.FormEvent) => {
    const currentIndex = state.tabDetails.findIndex((tab) => tab.showStatus);
    const currentId = state.tabDetails[currentIndex].id;
    event.preventDefault();
    const newDataArray = [
      ...state.general.paritairWhite,
      ...state.general.paritairBlue,
    ];
    const pcFunctions = await GetFunctions(
      newDataArray,
      state.general.province,
      state.general.workType,
      state.general.type,
      state.general.coeffType
    );
    dispatch({
      type: "SET_DROPDOWN",
      dropdownValues: pcFunctions.coeffValues,
      field: "initialEmpCoeff",
    });
    dispatch({
      type: CONST.UPDATE_BILLING_TAB,
      newData: newDataArray,
    });
    const formData = {
      general: {
        ...state.general,
        paritairBlue: BlueCollar ? state.general.paritairBlue : [],
        paritairWhite: WhiteCollar ? state.general.paritairWhite : [],
        companyId: companyId,
        agreementId: agreementId,
      },
    };

    if (state.general.vat !== "" && state.general.company !== "") {
      setLoading(true);
      const url = !revisionId
        ? CREATE_AGREEMENT
        : `${CREATE_AGREEMENT}/${revisionId}`;
      const response = await ApiCall.service(
        url,
        "POST",
        formData,
        false,
        "company-creation"
      );
      if (response.status === 200) {
        if (validationStatus.type === "draft") {
          CustomNotify({
            type: "success",
            message: t("Draft saved successfully") + "!",
          });
          navigate(MANAGE_COOPERATION_AGREEMENT);
        } else if (validationStatus.type === "next") {
          navigate(
            `/cooperation-agreement/create/${companyId}/${response.data}?tabId=${currentId}`
          );
        }
      } else if (response.status === 400) {
        CustomNotify({ type: "error", message: response.msg.vat_number });
        dispatch({ type: CONST.UPDATE_TAB_ERROR, tabIndex: 0, error: true });
        scrollToTop();
        state.fieldError.vat = response.msg.vat_number;
      }
    }
    setLoading(false);
  };

  /********  FeeBased  **********/
  const [totalPercentage, setTotalPercentage] = useState(0);

  const validationfeeBased = (
    name: string,
    value: string | boolean | Date | object[] | undefined,
    index: number,
    isSingleFieldValidation: boolean = false
  ): boolean => {
    const validationRules: ValidationRules = {
      percentage: [validateRequired],
      feeBasedType: [validateSelectField],
      count: [validateSelectField],
    };
    const validationErrors = validateForm(
      { ...state.general.feeBased[index], [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );
    dispatch({
      type: CONST.UPDATE_FEEBASED_ERROR,
      fieldName: name,
      index: index,
      error: validationErrors[name],
    });

    const isFieldValid = Object.keys(validationErrors).length === 0;

    if (isFieldValid) {
      dispatch({
        type: CONST.UPDATE_FEEBASED_ERROR,
        fieldName: name,
        index: index,
        error: "",
      });
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }

    return true;
  };

  const handleFeebasedInputField = (
    value: any,
    name: string,
    index: number
  ) => {
    if (name === "percentage") {
      const currentPercentage =
        Number(state.general.feeBased[index].percentage) || 0;
      const newTotalPercentageSum =
        totalPercentage - currentPercentage + Number(value);
      if (newTotalPercentageSum <= 100) {
        dispatch({
          type: CONST.UPDATE_FEEBASED_FIELD,
          fieldName: name,
          index: index,
          value: value,
        });
        // validationfeeBased(name, value, index, true);
      }
      // else {
      //   dispatch({
      //     type: CONST.UPDATE_FEEBASED_ERROR,
      //     fieldName: name,
      //     index: index,
      //     error: "Total percentage should not exceed 100"
      //   });
      // }
    }
  };

  const handleFeeBasedSelectField = (
    selectedOption: any,
    name: string,
    index: number
  ) => {
    if (name === "feeBasedType" && selectedOption.value === 2) {
      // Check if "Sign" is selected
      const isSignSelected = state.general.feeBased.some(
        (feeBased, idx) => feeBased.feeBasedType?.value === 2 && idx !== index
      );
      if (isSignSelected) {
        dispatch({
          type: CONST.UPDATE_FEEBASED_ERROR,
          fieldName: name,
          index: index,
          error: "Sign can only be selected once",
        });
        return;
      } else {
        dispatch({
          type: CONST.UPDATE_FEEBASED_FIELD,
          fieldName: name,
          index: index,
          value: selectedOption,
        });
        // validationfeeBased(name, selectedOption, index, true);
      }
    } else {
      dispatch({
        type: CONST.UPDATE_FEEBASED_FIELD,
        fieldName: name,
        index: index,
        value: selectedOption,
      });
      // validationfeeBased(name, selectedOption, index, true);
    }
  };

  const addAnotherFeeBased = () => {
    const newFeeBasedItem: FeeBasedItem = {
      id: null,
      percentage: null,
      feeBasedType: null,
      count: null,
    };
    dispatch({
      type: CONST.ADD_FEEBASED_FIELD,
      newData: newFeeBasedItem,
    });
  };

  const removeFeeBased = (index: number) => {
    dispatch({
      type: CONST.REMOVE_FEEBASED_FIELD,
      index: index,
    });
  };

  const hasErrorsInLatestIndex = () => {
    const latestIndex = state.general.feeBased.length - 1;
    const latestFeeBased = state.general.feeBased[latestIndex];
    const latestErrors = state?.feeBasedErrors[latestIndex];

    const hasEmptyFields =
      latestFeeBased &&
      Object.entries(latestFeeBased).some(
        ([key, value]) =>
          key !== "id" &&
          (value === "" || value === null || value === undefined)
      );

    if (latestErrors) {
      const hasFieldErrors = ["count", "feeBasedType", "percentage"].some(
        (key) => latestErrors[key] !== ""
      );
      dispatch({
        type: CONST.FEEBASED_ERROR_STATUS,
        status: hasEmptyFields || hasFieldErrors,
      });
    } else {
      dispatch({
        type: CONST.FEEBASED_ERROR_STATUS,
        status: hasEmptyFields,
      });
    }
  };

  useEffect(() => {
    dispatch({
      type: CONST.SET_FEEBASED,
    });
  }, [state.general.coeffType]);

  useEffect(() => {
    if (state.general.coeffType === 1) {
      const sum = state.general.feeBased.reduce(
        (total, feeBased) => total + (Number(feeBased.percentage) || 0),
        0
      );
      setTotalPercentage(sum);
      hasErrorsInLatestIndex();
    } else {
      setTotalPercentage(0);
      dispatch({
        type: CONST.FEEBASED_ERROR_STATUS,
        status: false,
      });
    }
  }, [state.general.feeBased]);

  const officeValue = getOfficeList().some(
    (office) => office.value === state.general.officeNumber?.value
  )
    ? state.general.officeNumber
    : null;

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12">
          <div className="form-border" style={{ paddingTop: "3.4vw" }}>
            <div className="row">
              <div className="col-md-6 generalTabVAT">
                <div className="input-group mb-3 position-relative">
                  <div className="position-absolute fieldPrefix">BE</div>
                  <LabelWithInputField
                    isMandatory={true}
                    name="vat"
                    label={t("VAT number")}
                    handleChange={(e) => {
                      handleInputField(e);
                    }}
                    type="text"
                    isDisabled={true}
                    value={state.general.vat}
                    error={state.fieldError.vat}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <LabelWithInputField
                  isMandatory={true}
                  name="company"
                  label={t("Company")}
                  handleChange={(e) => {
                    handleInputField(e);
                  }}
                  type="text"
                  error={state.fieldError.company}
                  value={state.general.company}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 position-relative">
                <Calender
                  isMandatory={true}
                  onChange={(date) => {
                    handleDateChange(date, "startDate");
                  }}
                  label={t("Start date of agreement")}
                  minDate={new Date()}
                  error={state.fieldError.startDate}
                  selectedDate={state.general.startDate}
                />
              </div>
              <div className="col-md-6">
                <SelectWithSearch
                  isMulti={true}
                  isMandatory={true}
                  search={true}
                  options={state.location}
                  value={state.general.province ?? ""}
                  onChange={(e) => {
                    handleSelectField(e, "province");
                  }}
                  name="province"
                  title={t("Location")}
                  error={state.fieldError.province}
                />
              </div>
              <div
                className={`${
                  state.general.type === 3 ? "col-md-4" : "col-md-6"
                } mb-3 `}
              >
                <div className="row">
                  <LabelField title={t("Unit type")} isMandatory={true} />
                </div>
                {state.workUnitList &&
                  state.workUnitList.map((item: any, index: number) => (
                    <div className="row mb-2" key={`unit-${item.id}`}>
                      <RadioField
                        handleChange={(e) => {
                          handleInputField(e);
                        }}
                        ischecked={
                          state.general.type === item.id ||
                          (index === 0 && state.general.type === null)
                        }
                        label={t(`${item.name}`)}
                        value={item.id}
                        name="type"
                      />
                    </div>
                  ))}
                <div className="row text-danger height-20">
                  <div className="co-md-12">{state.fieldError.type}</div>
                </div>
              </div>

              {state.general.type === 3 && (
                <div className="col-md-4">
                  <div className="row">
                    <LabelField title={t("Work type")} isMandatory={false} />
                  </div>
                  <div className="row mb-2">
                    <RadioField
                      handleChange={(e) => {
                        handleInputField(e);
                      }}
                      ischecked={state.general.workType === 1}
                      label={t("Work + home (AJ)")}
                      value={1}
                      name="workType"
                    />
                  </div>
                  <div className="row mb-2">
                    <RadioField
                      handleChange={(e) => {
                        handleInputField(e);
                      }}
                      ischecked={state.general.workType === 2}
                      label={t("Work + home (Client)")}
                      value={2}
                      name="workType"
                    />
                  </div>
                </div>
              )}
              <div
                className={`${
                  state.general.type === 3 ? "col-md-4" : "col-md-6"
                } mb-3 `}
              >
                <LabelField title={t("Type")} isMandatory={false} />
                <div className="col-md-12">
                  <RadioField
                    handleChange={(e) => {
                      handleInputField(e);
                    }}
                    ischecked={state.general.coeffType === 2}
                    label={t("Coefficient based")}
                    value={2}
                    name="coeffType"
                    className="me-3"
                    disable={
                      agreementId !== undefined &&
                      agreementId !== null &&
                      state.general.coeffType === 1
                    }
                  />
                  <RadioField
                    handleChange={(e) => {
                      handleInputField(e);
                    }}
                    ischecked={state.general.coeffType === 1}
                    label={t("Fee based")}
                    value={1}
                    name="coeffType"
                    disable={
                      agreementId !== undefined &&
                      agreementId !== null &&
                      state.general.coeffType === 2
                    }
                  />
                </div>
                {state.general.coeffType === 1 && (
                  <FeeBased
                    feeBasedList={state.general.feeBased}
                    feeBasedErrors={state.feeBasedErrors}
                    handleInputField={handleFeebasedInputField}
                    handleSelectField={handleFeeBasedSelectField}
                    addAnotherFeeBased={addAnotherFeeBased}
                    removeFeeBased={removeFeeBased}
                    disableAddButton={
                      totalPercentage >= 100 || state.feeBasedErrorStatus
                    }
                  />
                )}
              </div>
              <div className="col-md-6">
                <SelectWithSearch
                  isMandatory={true}
                  search={true}
                  options={getConsultantList()}
                  value={state.general.consultant ?? ""}
                  placeHolder="Select"
                  onChange={(e) => {
                    handleSelectField(e, "consultant");
                  }}
                  name="consultant"
                  title={t("Absolute jobs consultant")}
                  error={state.fieldError.consultant}
                />
              </div>
              <div className="col-md-6">
                <SelectWithSearch
                  isMandatory={true}
                  search={false}
                  isDisabled={false}
                  placeHolder="Select"
                  options={getOfficeList()}
                  value={officeValue ?? ""}
                  onChange={(e) => {
                    handleSelectField(e, "officeNumber");
                  }}
                  name="officeNumber"
                  title={t("Absolute jobs office number")}
                  error={state.fieldError.officeNumber}
                />
              </div>
              <div className="col-lg-6 billing-days-dropdwon">
                <SelectWithSearch
                  isMandatory={false}
                  isMulti={false}
                  onChange={(e) => {
                    handleSelectField(e, "vatInclusive");
                  }}
                  search={true}
                  options={getVatcodeForPC124()}
                  value={state.general.vatInclusive}
                  name="vatInclusive"
                  error={state.fieldError.vatInclusive}
                  title={t("Standard VAT rate")}
                />
              </div>
              <div className="col-md-12 marginBotttom1">
                <CheckBoxField
                  name="addProject"
                  label={t("Activate 'add project'")}
                  onChangeHandler={(e) => {
                    handleInputField(e);
                  }}
                  isChecked={state.general.addProject}
                  id="addProject"
                  lineHeight="1.1vw"
                />
              </div>
            </div>

            {/* paritiar commitee */}
            <div className={`internalContentWrapper`}>
              <div className="row">
                {WhiteCollar && state.general.type !== 2 && (
                  <WhiteCollarSection
                    optionsList={getWhiteCollarPCs()}
                    selectedValue={PCWhite.PC}
                    handlePCFields={handlePCFields}
                    state={state}
                    addParitiarComitee={addParitiarComitee}
                    pcChangeHandler={pcChangeHandler}
                    pcDeleteHandler={pcDeleteHandler}
                    selectedEmpType={PCWhite.employeeType}
                    employeeTypes={getPcEmployeeTypes(PCWhite, 1)}
                    isWhiteCollar={WhiteCollar}
                    isBlueCollar={BlueCollar}
                  />
                )}
                {BlueCollar && (
                  <BlueCollarSection
                    optionsList={getBlueCollarPCs()}
                    selectedValue={PCBlue.PC}
                    handlePCFields={handlePCFields}
                    state={state}
                    addParitiarComitee={addParitiarComitee}
                    pcChangeHandler={pcChangeHandler}
                    pcDeleteHandler={pcDeleteHandler}
                    selectedEmpType={PCBlue.employeeType}
                    employeeTypes={getPcEmployeeTypes(PCBlue, 2)}
                    isBlueCollar={BlueCollar}
                    isWhiteCollar={WhiteCollar}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Navigation validStatus={validStatus} isLoading={loading} />
    </form>
  );
};
export default translate(GeneralTab);
