import { t, translate } from "components/CentralDataMangement/translation/Translation";
import XmarkIcon from "static/images/XmarkIcon";

interface Designation {
  index?: number | null;
  id?: number | null;
  name: string;
}

interface dataProps {
  data: Designation[];
  changeHandler: (value: any) => void;
  deleteHandler: (value: any) => void;
}

const DesignationTable: React.FC<dataProps> = ({
  data,
  deleteHandler,
  changeHandler,
}) => {
  return (
    <div className="designationWrapper">
      <div className="tab-subtitle mb-3">
        {t("Designation")}
      </div>
      <div className="container-fluid designationTable px-0">
        {data && data.length > 0 && (
          <div className="row row-cols-2 row-cols-lg-4">
            {data.map((designation, index) => (
              <div className="col mb-3" key={index}>
                <div className="designationName d-flex align-items-center justify-content-between p-3 w-100 cursor-pointer">
                  <button
                    onClick={() => changeHandler(index)}
                    className="border-0 bg-transparent designationName text-start"
                  >
                    {t(`${designation.name}`)}
                  </button>
                  <button
                    type="button"
                    onClick={() => deleteHandler(index)}
                    className="bg-transparent border-0 shadow-none closeIconDesignation"
                    title={t("Delete")}
                  >
                    <XmarkIcon />
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default translate(DesignationTable);
