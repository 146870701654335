import React from "react";
import { OptionProps } from "./TypeAnnotations";

export function FormatPCList(data: any[]): OptionProps[] {
  if (data.length === 0) {
    return [];
  }
  return data.map((item: any) => ({
    value: item.id,
    label: `(${item.pc_number}) ${item.pc_name} - ${item.type === 1 ? "Bediende" : "Arbeider"}`,
  }));
}


export function FormatPerfcodeList(data: any[]): OptionProps[] {
  if (data.length === 0) {
    return [];
  }
  return data.map((item: any) => ({
    value: item.id,
    label: `${item.code} - ${item.name}`,
  }));
}
