import LabelField from "components/common/atoms/LabelField";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import React from "react";
import PCTable from "./PCTable";
import { Option } from "components/common/utlis/TypeAnnotations";
import Button from "components/common/atoms/Button";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";

interface WhiteCollarSectionProps {
  optionsList: any[];
  selectedValue: Option | null;
  selectedEmpType: Option | null;
  handlePCFields: (e: React.ChangeEvent, name: string) => void;
  state: any; // Add the state type
  addParitiarComitee: (type: string) => void;
  pcChangeHandler: (id: number, type: string) => void;
  pcDeleteHandler: (id: number, type: string) => void;
  employeeTypes: any[];
  isWhiteCollar: boolean;
  isBlueCollar: boolean;
}

const WhiteCollarSection: React.FC<WhiteCollarSectionProps> = ({
  optionsList,
  selectedValue,
  selectedEmpType,
  handlePCFields,
  state,
  addParitiarComitee,
  pcChangeHandler,
  pcDeleteHandler,
  employeeTypes = [],
  isWhiteCollar,
  isBlueCollar,
}) => {
  return (
    <div
      className={`${
        isWhiteCollar && isBlueCollar
          ? "col-lg-6 col-12 border-end"
          : "col-xxl-10 col-12 mx-auto"
      }`}
    >
      <div className="">
        <h5 className="mb-1">
          <LabelField
            title={t("Paritair committee for white collar")}
            className="color-dark-purple tab-title"
          />
        </h5>
        <PCTable
          data={state.general.paritairWhite}
          pcChangeHandler={pcChangeHandler}
          type="whiteCollar"
          pcDeleteHandler={pcDeleteHandler}
        />
        {state.fieldError.paritairWhite && (
          <div className="text-danger">{state.fieldError.paritairWhite}</div>
        )}
        <div className="row">
          <div
            className={`${
              isWhiteCollar && isBlueCollar ? "col-12" : "col-xxl-6 col-12"
            }`}
          >
            <SelectWithSearch
              isMulti={false}
              isMandatory={true}
              search={true}
              options={optionsList}
              value={selectedValue}
              onChange={(e) => {
                handlePCFields(e, "PC");
              }}
              name="PC"
              title={t("Paritair committee (PC) 1")}
              error={state.fieldError.PC}
              isTranslate={true}
            />
          </div>
          <div
            className={`${
              isWhiteCollar && isBlueCollar ? "col-12" : "col-xxl-6 col-12"
            }`}
          >
            <SelectWithSearch
              isMulti={true}
              isMandatory={true}
              search={true}
              options={employeeTypes}
              value={selectedEmpType}
              onChange={(e) => {
                handlePCFields(e, "employeeType");
              }}
              name="employeeType"
              title={t(
                "Selection of employee types (statuut) that can be used"
              )}
              error={state.fieldError.employeeType}
              isTranslate={true}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-12">
            <Button
              title={t("Add Paritair committee for white collar")}
              handleClick={() => addParitiarComitee("whiteCollar")}
              className="form-button float-end shadow-none"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default translate(WhiteCollarSection);
