import React, { useEffect, useState } from "react";
import { ApiCall } from "components/common/services/ApiServices";
import { Link } from "react-router-dom";
import Button from "components/common/atoms/Button";
import Pagination from "components/common/atoms/Pagination";
import CustomNotify from "components/common/atoms/CustomNotify";
import * as ENDPOINTS from "routes/ApiEndpoints";
import Title from "components/common/atoms/Title";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import Reset from "static/images/Reset";
import { faMagnifyingGlass, faPlus } from "@fortawesome/free-solid-svg-icons";
import LinkTo from "components/common/atoms/LinkTo";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import { Spinner } from "react-bootstrap";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import SpinnerWrapper from "components/common/atoms/SpinnerWrapper";
import NoRecords from "components/common/atoms/NoRecords";

interface manageLogData {
  requestId: number;
  requestData: string;
  to: string;
  status: number;
  count: number;
  createdAt: string;
  response: string;
}

interface formData {
  to: string;
  status: string;
}

const ManageEmailLogs: React.FC = () => {
  //initiliaze the formData state for search fields
  const [formData, setFormData] = useState<formData>({
    to: "",
    status: "",
  });

  const [emailLogs, setEmailLogs] = useState([] as manageLogData[]);
  const [loading, setLoading] = useState(true);

  //state for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  // const [isFilter, setIsFilter] = useState<boolean>(true); // Example boolean state

  useEffect(() => {
    fetchEmailLogs();
  }, []);

  //page index based on page number
  // let pageIndex = (currentPage - 1) * 20 + 1;

  //API call for to fetch email logs
  const fetchEmailLogs = async (formData = {}, page = 1) => {
    const requestData = {
      ...formData,
      page: page,
    };

    const response = await ApiCall.service(
      ENDPOINTS.GET_EMAIL_LOGS,
      "POST",
      requestData,
      false,
      "central-data-management"
    );

    if (response.status === 200) {
      setEmailLogs(response.data);
      setTotalPages(response.totalPages);
      setLoading(false);
    }
  };

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (formData.to.trim() === "" && formData.status.trim() === "") {
      CustomNotify({
        type: "warning",
        message: t("Search input not provided") + ".",
      });
      fetchEmailLogs();
    } else {
      fetchEmailLogs(formData, 1);
    }
  };

  const handleReset = () => {
    setFormData({
      to: "",
      status: "",
    });
    setCurrentPage(1);
    fetchEmailLogs();
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    fetchEmailLogs(formData, newPage);
  };

  const handleResendEmail = async (requestId: number) => {
    const data = {};
    const url = `${ENDPOINTS.SEND_EMAIL}/${requestId}`;
    const response = await ApiCall.service(
      url,
      "POST",
      data,
      true,
      "central-data-management"
    );
    if (response.status === 200) {
      CustomNotify({ type: "success", message: response.msg });
      fetchEmailLogs();
    }
  };

  return (
    <>
      <AccessControl
        requiredPermissions={[
          {
            permission: "Email templates",
            read: true,
          },
        ]}
        renderNoAccess={true}
      >
        <Title title={t("Manage email logs")} />
        <div className="form-height-dashboard">
          <div className="row search-bar">
            <div className="col-4">
              <LabelWithInputField
                isMandatory={false}
                name="to"
                handleChange={handleFieldChange}
                value={formData.to}
                id="to"
                label={t("To")}
                placeholder={t("To")}
              />
            </div>
            <div className="col-4">
              <LabelWithInputField
                isMandatory={false}
                name="status"
                handleChange={handleFieldChange}
                value={formData.status}
                id="status"
                label={t("Status")}
                placeholder={t("Status")}
              />
            </div>
            <div className="col-4 mt-34">
              <Button
                title=""
                type="reset"
                handleClick={handleReset}
                className="delete-btn shadow-none"
              >
                <span title={t("Reset")}>
                  <Reset />
                </span>
              </Button>
              <Button
                title={t("Search")}
                type="submit"
                icon={faMagnifyingGlass}
                handleClick={handleSubmit}
                className="form-button shadow-none search-btns position-relative text-start"
              />
            </div>
          </div>
          {/* Display the table of city profiles */}
          <div className="table-responsive tableSection">
            <table className="table table-hover">
              <thead>
                <tr className="TableHeader">
                  <th className="ps-lg-4" style={{ width: "40%" }}>
                    {t("Request data")}
                  </th>
                  <th style={{ width: "15%" }}>{t("To")}</th>
                  <th style={{ width: "5%" }}>{t("Status")}</th>
                  <th style={{ width: "5%" }}>{t("Count")}</th>
                  <th style={{ width: "15%" }}>{t("Response")}</th>
                  <th style={{ width: "10%" }}>{t("Created at")}</th>
                  <th>{t("Actions")}</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <SpinnerWrapper headerLength={7} />
                ) : (
                  <>
                    {emailLogs && emailLogs.length > 0 ? (
                      emailLogs.map((emailLog) => (
                        <tr
                          key={emailLog.requestId}
                          className="border-bottom mb-3 box-shadow align-middle"
                        >
                          <td
                            className="ps-lg-4 text-break"
                            data-label={t("Request data")}
                          >
                            {emailLog.requestData}
                          </td>
                          <td data-label={t("To")}>{emailLog.to}</td>
                          <td data-label={t("Status")}>{emailLog.status}</td>
                          <td data-label={t("Count")}>{emailLog.count}</td>
                          <td data-label={t("Response")}>
                            {emailLog.response}
                          </td>
                          <td data-label={t("Created at")}>
                            {emailLog.createdAt.split(" ")[0]}
                            <br />
                            {emailLog.createdAt.split(" ")[1]}
                          </td>
                          <td className="table-action-icons px-2">
                            <div className="d-none d-md-none d-lg-block">
                              {emailLog.status === 500 && (
                                <>
                                  <Button
                                    title={t("Resend")}
                                    handleClick={() =>
                                      handleResendEmail(emailLog.requestId)
                                    }
                                    className="form-button shadow-none"
                                  />
                                </>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <NoRecords headerLength={7} />
                    )}
                  </>
                )}
              </tbody>
            </table>
            {totalPages > 1 && (
              <div className="pagination justify-content-center align-items-center mt-4 px-4">
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={(newPage) => handlePageChange(newPage)}
                />
              </div>
            )}
          </div>
        </div>
        <div className="col-md-6 align-self-center py-4">
          <Link
            to="/config/settings"
            className=" back-btn text-decoration-underline"
          >
            {t("Back")}
          </Link>
        </div>
      </AccessControl>
    </>
  );
};

export default translate(ManageEmailLogs);
