import React from 'react'

const SignatureIcon = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 54.921 44.426">
  <g id="noun-contract-1012062" transform="translate(-177.989 -79.511)">
    <path id="Path_31767" data-name="Path 31767" d="M180.878,122.014a3.292,3.292,0,0,1-.9-2.712,3.547,3.547,0,0,1,.634-1.4c-.791-.567-2.8-1.286-2.615-2.094a.939.939,0,0,1,1.02-.785,8.892,8.892,0,0,1,2.915,1.564c5.524-5.324,15.707,4.977,16.293,4.211.411-.539-1.8-3.326-.99-4.594a1.319,1.319,0,0,1,1.143-.6c2.4,0,6.4,2.316,10.4,4.868l-.9-6.911c-.028-.276,14.529-33.7,14.529-33.7.157-.355,2.545-.769,6.161.7,3.646,1.485,4.478,3.528,4.32,3.886,0,0-14.834,33.168-15.192,33.429l-3.139,2.3-4.059,2.858a.926.926,0,0,1-.543.175h-.028c-.341-.009-7.25-5.179-10.908-5.67.186.51.794,1.752.794,1.752,1.178,2.747.381,3.29-.105,3.528-3.254,1.594-10.7-8.637-16.236-5.034,1.8,1.512,3.424,3.314,3.469,4.508a1.54,1.54,0,0,1-1.35,1.595,5.047,5.047,0,0,1-4.719-1.863Zm1.207-2.975c-1.212,1.832,1.7,3.191,2.913,3.049A13.254,13.254,0,0,0,182.085,119.039Zm27.664-5.577.933,7.158,5.825-4.188Z" fill="currentColor"/>
  </g>
</svg>


    </>
  )
}

export default SignatureIcon