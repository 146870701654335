import {
  BASIC_DETAILS,
  BILLING_DETAILS,
  COST_CENTER_DETAILS,
  GENERAL_DETAILS,
  INTERNAL_INFO_DETAILS,
  REMENUARATION_DETAILS,
  SET_DROPDOWN_DATA,
  TIME_TABLE_DETAILS,
  UPDATE_TAB_DATA,
} from "../../annotation/PlanningConstants";
import {
  fetchCompanyDetails,
} from "./SetCompanyRelatedData";

const processBasicDetailsDateFields = (basicDetails: any) => {
  if (basicDetails.from && typeof basicDetails.from === "string") {
    basicDetails.from = new Date(basicDetails.from);
  }

  if (basicDetails.to && typeof basicDetails.to === "string") {
    basicDetails.to = new Date(basicDetails.to);
  }
};

// Helper function to dispatch tab data if valid
const dispatchTabData = (dispatch: any, tab: string, tabData: any) => {
  if (Object.keys(tabData).length > 0) {
    dispatch({
      type: UPDATE_TAB_DATA,
      tab,
      data: tabData,
    });
  }
};

const SetPlanningData = (
  data: any,
  dispatch: any,
  id: string | null | undefined = null,
  state: any
) => {
  const dropdownData = data.dropDownDetails;
  // Dispatch the action to update the dropdown data
  dispatch({ type: SET_DROPDOWN_DATA, payload: dropdownData });

  // Choose the source of data based on the if id exist edit flow else create flow
  const planningData = id ? data.planningDetails : data;
  if (id) {
    fetchCompanyDetails(
      "company",
      planningData?.basicDetails?.customer?.value,
      dispatch,
      "edit"
    );
  }
  // Process basic details (with 'from' and 'to' date fields)
  const basicTabData = planningData.basicDetails || [];
  processBasicDetailsDateFields(basicTabData); // Convert `from` and `to` fields to Date objects
  dispatchTabData(dispatch, BASIC_DETAILS, basicTabData);

  // Define an array of tab mappings to avoid repetitive code for other tabs (without 'from' and 'to' fields)
  const tabMappings = [
    { tab: GENERAL_DETAILS, dataKey: "generalDetails" },
    { tab: REMENUARATION_DETAILS, dataKey: "remunerationDetails" },
    { tab: BILLING_DETAILS, dataKey: "billingDetails" },
    { tab: TIME_TABLE_DETAILS, dataKey: "timeTableDetails" },
    { tab: COST_CENTER_DETAILS, dataKey: "costCenterDetails" },
    { tab: INTERNAL_INFO_DETAILS, dataKey: "internalInfoDetails" },
  ];

  // Iterate over each tab and dispatch its data if valid
  tabMappings.forEach(({ tab, dataKey }) => {
    const tabData = planningData[dataKey] || [];
    dispatchTabData(dispatch, tab, tabData);
  });
};

export default SetPlanningData;
