import { useFormContext } from "components/CompanyCreation/Company/context/Context";
import CheckBoxField from "components/common/atoms/CheckBoxField";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import { ValidationRules } from "components/common/utlis/TypeAnnotations";
import {
  Option,
  scrollToTop,
  validateForm,
  validateRequired,
  validateSelectField,
} from "components/common/services/ValidationService";
import { useState } from "react";
import { AddressProps } from "components/CompanyCreation/Company/annotations/CompanyAnnotations";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import Navigation from "../formNavigation/Navigation";
import LabelField from "components/common/atoms/LabelField";

const Address = () => {
  const { state, dispatch } = useFormContext();
  const [countryId, setCountryId] = useState<number | null>(null);
  const [validationStatus, setValidationStatus] = useState({
    isValid: false,
    type: "",
  });

  const validation = (
    name: string,
    value: string | boolean | Date | object[] | null | Option | number,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      location: [validateRequired],
      street: [validateRequired],
      number: [validateRequired],
      zipCode: [validateRequired],
      city: [validateSelectField],
      country: [validateSelectField],
      // biStreet: [validateRequired],
      // biZipCode: [validateRequired],
      // biNumber: [validateRequired],
      // biCity: [validateSelectField],
      // biCountry: [validateSelectField],
    };

    const validationErrors = validateForm(
      { ...state.address, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    dispatch({
      type: "UPDATE_FIELD_ERROR",
      fieldName: name,
      error: validationErrors[name],
    });
    const isFieldValid = Object.keys(validationErrors).length === 0;

    if (isFieldValid) {
      state.fieldError[name] = "";
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const copyBillingFieldsFromOfficial = (isChecked: boolean) => {
    if (isChecked) {
      const billingFields = [
        "street",
        "number",
        "box",
        "zipCode",
        "city",
        "country",
      ];
      billingFields.forEach((fieldName) => {
        const billingFieldName =
          "bi" + fieldName.charAt(0).toUpperCase() + fieldName.slice(1);
        const fieldValue = state.address[fieldName as keyof AddressProps];
        dispatch({
          type: "UPDATE_ADDRESS_FIELD",
          field: billingFieldName,
          value: fieldValue,
        });
        validation(billingFieldName, fieldValue, true);
      });
    } else {
      const billingFields = [
        "biStreet",
        "biNumber",
        "biBox",
        "biZipCode",
        "biCity",
        "biCountry",
      ];
      billingFields.forEach((fieldName) => {
        dispatch({
          type: "UPDATE_ADDRESS_FIELD",
          field: fieldName,
          value: "",
        });
        validation(fieldName, "", true);
      });
    }
  };

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;

    if (type === "checkbox") {
      const newValue = checked ? 1 : 0;
      dispatch({ type: "UPDATE_ADDRESS_FIELD", field: name, value: newValue });
      validation(name, checked, true);
      if (name === "sameAddress") {
        copyBillingFieldsFromOfficial(checked);
      }
    } else {
      dispatch({ type: "UPDATE_ADDRESS_FIELD", field: name, value });
      validation(name, value, true);
      if (state.address.sameAddress) {
        const billingFieldName =
          "bi" + name.charAt(0).toUpperCase() + name.slice(1);
        dispatch({
          type: "UPDATE_ADDRESS_FIELD",
          field: billingFieldName,
          value,
        });
        validation(billingFieldName, value, true);
      }
    }
  };

  const handleSelectChange = (selectedOption: any, name: string) => {
    dispatch({
      type: "UPDATE_ADDRESS_FIELD",
      field: name,
      value: selectedOption,
    });

    if (name === "country") {
      setCountryId(selectedOption.value);
      // dispatch({
      //   type: "UPDATE_ADDRESS_FIELD",
      //   field: "city",
      //   value: null,
      // });
    } else if (name === "biCountry") {
      setCountryId(selectedOption.value);
      // dispatch({
      //   type: "UPDATE_ADDRESS_FIELD",
      //   field: "biCity",
      //   value: null,
      // });
    }

    if (state.address.sameAddress) {
      const billingFieldName =
        "bi" + name.charAt(0).toUpperCase() + name.slice(1);
      dispatch({
        type: "UPDATE_ADDRESS_FIELD",
        field: billingFieldName,
        value: selectedOption,
      });
      validation(billingFieldName, selectedOption, true);
    }
    validation(name, selectedOption, true);
  };

  const validStatus = (validation: any) => {
    setValidationStatus({
      isValid: validation.isValid,
      type: validation.type,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    scrollToTop();
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12">
            <div className="form-border" style={{ paddingTop: "3.4vw" }}>
              <LabelField
                title={t("Official address")}
                className="tab-subtitle"
              />
              <div className="row">
                <div className="col-sm-12 col-lg-4 col-md-12">
                  <LabelWithInputField
                    isMandatory={true}
                    name="location"
                    handleChange={handleFieldChange}
                    value={state.address.location}
                    id="location"
                    label={t("Location")}
                    type="text"
                    placeholder={t("Location")}
                    error={state.fieldError.location}
                  />
                </div>
                <div className="col-sm-12 col-lg-4 col-md-7">
                  <LabelWithInputField
                    isMandatory={true}
                    name="street"
                    handleChange={handleFieldChange}
                    value={state.address.street}
                    id="street"
                    label={t("Street")}
                    type="text"
                    placeholder={t("Street")}
                    error={state.fieldError.street}
                  />
                </div>
                <div className="col-sm-12 col-lg-3 col-md-3">
                  <LabelWithInputField
                    isMandatory={true}
                    name="number"
                    handleChange={handleFieldChange}
                    value={state.address.number}
                    id="number"
                    label={t("Number")}
                    type="text"
                    placeholder={t("Number")}
                    error={state.fieldError.number}
                  />
                </div>
                <div className="col-sm-12 col-lg-1 col-md-2">
                  <LabelWithInputField
                    isMandatory={false}
                    name="box"
                    handleChange={handleFieldChange}
                    value={state.address.box}
                    id="box"
                    label={t("Box")}
                    placeholder={t("Box")}
                    type="text"
                  />
                </div>
                <div className="col-sm-12 col-md-4">
                  <LabelWithInputField
                    isMandatory={true}
                    name="zipCode"
                    handleChange={handleFieldChange}
                    value={state.address.zipCode}
                    id="zipCode"
                    label={t("Zip code")}
                    placeholder={t("Zip code")}
                    type="text"
                    error={state.fieldError.zipCode}
                  />
                </div>
                <div className="col-sm-12 col-md-4">
                  <SelectWithSearch
                    title={t("City")}
                    name="city"
                    isMandatory={true}
                    search={true}
                    options={state.cityList}
                    value={state.address.city}
                    onChange={(e) => handleSelectChange(e, "city")}
                    isMulti={false}
                    error={state.fieldError.city}
                    // isDisabled={state.address.country === null}
                  />
                </div>
                <div className="col-sm-12 col-md-4">
                  <SelectWithSearch
                    title={t("Country")}
                    name="country"
                    isMandatory={true}
                    search={true}
                    options={state.countryList}
                    value={state.address.country}
                    onChange={(e) => handleSelectChange(e, "country")}
                    isMulti={false}
                    error={state.fieldError.country}
                  />
                </div>

                <div className="col-md-12 marginBotttom1">
                  <CheckBoxField
                    className="custom-temp-field-label"
                    label={t("This is the Headquarters address")}
                    name="hqAddress"
                    onChangeHandler={handleFieldChange}
                    isChecked={state.address.hqAddress === 1}
                    id="hqAddress"
                    lineHeight="1.6vw"
                  />
                </div>
                <div className="col-md-12 marginBotttom1">
                  <CheckBoxField
                    className="custom-temp-field-label"
                    label={t("Same as official address")}
                    name="sameAddress"
                    onChangeHandler={handleFieldChange}
                    isChecked={state.address.sameAddress === 1}
                    id="sameAddress"
                    lineHeight="1.6vw"
                  />
                </div>
                <LabelField
                  title={t("Billing address")}
                  className="tab-subtitle"
                />

                <div className="col-sm-12 col-md-7">
                  <LabelWithInputField
                    // isMandatory={true}
                    name="biStreet"
                    handleChange={handleFieldChange}
                    value={state.address.biStreet}
                    isDisabled={state.address.sameAddress === 1}
                    id="biStreet"
                    label={t("Street")}
                    placeholder={t("Street")}
                    type="text"
                    // error={state.fieldError.biStreet}
                  />
                </div>
                <div className="col-sm-12 col-md-5  ">
                  <div className="row">
                    <div className="col-sm-12 col-md-8">
                      <LabelWithInputField
                        // isMandatory={true}
                        name="biNumber"
                        handleChange={handleFieldChange}
                        value={state.address.biNumber}
                        isDisabled={state.address.sameAddress === 1}
                        id="biNumber"
                        label={t("Number")}
                        placeholder={t("Number")}
                        type="text"
                        // error={state.fieldError.biNumber}
                      />
                    </div>
                    <div className="col-sm-12 col-md-4">
                      <LabelWithInputField
                        isMandatory={false}
                        name="biBox"
                        handleChange={handleFieldChange}
                        value={state.address.biBox}
                        isDisabled={state.address.sameAddress === 1}
                        id="biBox"
                        label={t("Box")}
                        placeholder={t("Box")}
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-4">
                  <LabelWithInputField
                    // isMandatory={true}
                    name="biZipCode"
                    handleChange={handleFieldChange}
                    value={state.address.biZipCode}
                    isDisabled={state.address.sameAddress === 1}
                    id="biZipCode"
                    label={t("Zip code")}
                    placeholder={t("Zip code")}
                    type="text"
                    // error={state.fieldError.biZipCode}
                  />
                </div>
                <div className="col-sm-12 col-md-4 pb-4">
                  <SelectWithSearch
                    title={t("City")}
                    name="biCity"
                    // isMandatory={true}
                    search={true}
                    options={state.cityList}
                    value={state.address.biCity}
                    onChange={(e) => handleSelectChange(e, "biCity")}
                    isMulti={false}
                    // error={state.fieldError.biCity}
                    isDisabled={
                      state.address.biCountry === null ||
                      state.address.sameAddress === 1
                    }
                  />
                </div>
                <div className="col-sm-12 col-md-4">
                  <SelectWithSearch
                    title={t("Country")}
                    name="biCountry"
                    // isMandatory={true}
                    search={true}
                    options={state.countryList}
                    isDisabled={state.address.sameAddress === 1}
                    value={state.address.biCountry}
                    onChange={(e) => handleSelectChange(e, "biCountry")}
                    isMulti={false}
                    // error={state.fieldError.biCountry}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Navigation validStatus={validStatus} />
      </form>
    </>
  );
};

export default translate(Address);
