import React from "react";
import { t } from "components/CentralDataMangement/translation/Translation";
import CheckBoxField from "components/common/atoms/CheckBoxField";
import InputTextfield from "components/common/atoms/InputTextField";
import LabelField from "components/common/atoms/LabelField";
import { WageCodesProps } from "components/CompanyCreation/AdminFiche/annotations/AdminFicheInterface";

interface Props {
  code: WageCodesProps;
  wageId: number;
  profileId: number;
  codeId: number;
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    wageId: number,
    profileId?: number,
    codeId?: number,
    applicabilityId?: number
  ) => void;
}

const ApplicabilityTable: React.FC<Props> = ({
  code,
  wageId,
  profileId,
  codeId,
  handleChange,
}) => {
  const daysOfWeek = [
    { key: 1, name: "Monday" },
    { key: 2, name: "Tuesday" },
    { key: 3, name: "Wednesday" },
    { key: 4, name: "Thursday" },
    { key: 5, name: "Friday" },
    { key: 6, name: "Saturday" },
    { key: 0, name: "Sunday" },
  ];

  return (
    <>
      <div className="col-12">
        <LabelField title={t("Applicability")} className="tab-subtitle" />
      </div>
      <div className="container mt-3 wagePreminumApplicabilityTable">
        <table className="table table-bordered">
          <thead>
            <tr className="align-middle text-center">
              <th scope="col" className="text-center">
                <CheckBoxField
                  name="allDaysApplicable"
                  onChangeHandler={(e) =>
                    handleChange(e, wageId, profileId, codeId)
                  }
                  value={code.allDaysApplicable ? "1" : "0"}
                  isChecked={
                    code.allDaysApplicable !== null
                      ? code.allDaysApplicable
                      : false
                  }
                  id="allDaysApplicable"
                  lineHeight={"27px"}
                />
              </th>
              <th scope="col">Day</th>
              <th scope="col">From</th>
              <th scope="col">To</th>
              <th scope="col">Overnight</th>
              <th scope="col">Minimum hours</th>
              <th scope="col">Maximum hours</th>
            </tr>
          </thead>
          <tbody>
            {daysOfWeek.map(({ key, name }) => (
              <tr key={key} className="align-middle text-center">
                <td className="text-center py-3">
                  <CheckBoxField
                    name="isApplicable"
                    onChangeHandler={(e) =>
                      handleChange(e, wageId, profileId, codeId, key)
                    }
                    value={code.applicability[key]?.isApplicable ? "1" : "0"}
                    isChecked={
                      code.applicability[key]?.isApplicable !== null
                        ? code.applicability[key]?.isApplicable
                        : false
                    }
                    id={`isApplicable_${key}`}
                    lineHeight={"27px"}
                    disable={code.allDaysApplicable}
                  />
                </td>
                <td className="py-3">{name}</td>
                <td
                  className={`p-0 position-relative ${
                    code.applicability[key]?.fromError === true
                      ? "error-border"
                      : ""
                  }`}
                >
                  <InputTextfield
                    name="from"
                    value={code.applicability[key]?.from || ""}
                    handleChange={(e) =>
                      handleChange(e, wageId, profileId, codeId, key)
                    }
                    className="position-absolute start-0 top-0 w-100 h-100 form-control rounded-0 text-center shadow-none"
                  />
                </td>
                <td
                  className={`p-0 position-relative ${
                    code.applicability[key]?.toError === true
                      ? "error-border"
                      : ""
                  }`}
                >
                  <InputTextfield
                    name="to"
                    value={code.applicability[key]?.to || ""}
                    handleChange={(e) =>
                      handleChange(e, wageId, profileId, codeId, key)
                    }
                    className="position-absolute start-0 top-0 w-100 h-100 form-control rounded-0 text-center shadow-none"
                  />
                </td>
                <td className="text-center">
                  <CheckBoxField
                    name="overNight"
                    onChangeHandler={(e) =>
                      handleChange(e, wageId, profileId, codeId, key)
                    }
                    value={code.applicability[key]?.overNight ? "1" : "0"}
                    isChecked={
                      code.applicability[key]?.overNight !== null
                        ? code.applicability[key]?.overNight
                        : false
                    }
                    id={`overNight_${key}`}
                    lineHeight={"27px"}
                  />
                </td>
                <td className="p-0 position-relative">
                  <InputTextfield
                    type="text"
                    name="minHrs"
                    value={code.applicability[key]?.minHrs || ""}
                    handleChange={(e) =>
                      handleChange(e, wageId, profileId, codeId, key)
                    }
                    className="position-absolute start-0 top-0 w-100 h-100 form-control rounded-0 text-center shadow-none"
                  />
                </td>
                <td className="p-0 position-relative">
                  <InputTextfield
                    type="text"
                    name="maxHrs"
                    value={code.applicability[key]?.maxHrs || ""}
                    handleChange={(e) =>
                      handleChange(e, wageId, profileId, codeId, key)
                    }
                    className="position-absolute start-0 top-0 w-100 h-100 form-control rounded-0 text-center shadow-none"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default React.memo(ApplicabilityTable);
