import React from 'react'

const DashboardIcon:React.FC = () => {
  return (
    <>
      <svg width="30" height="30" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_291_322" maskUnits="userSpaceOnUse" x="0" y="0" width="21" height="20">
          <rect width="21" height="20" fill="currentColor" />
        </mask>
        <g mask="url(#mask0_291_322)">
          <rect x="1.5" y="1.5" width="8" height="10" stroke="currentColor" strokeWidth={'1.5px'}/>
          <rect x="-0.5" y="0.5" width="8" height="10" transform="matrix(1 0 0 -1 12 19)" stroke="currentColor" strokeWidth={'1.5px'}/>
          <rect x="1.5" y="13.5" width="8" height="5" stroke="currentColor" strokeWidth={'1.5px'} />
          <rect x="-0.5" y="0.5" width="8" height="5" transform="matrix(1 0 0 -1 12 7)" stroke="currentColor" strokeWidth={'1.5px'}/>
        </g>
      </svg>

    </>
  )
}

export default DashboardIcon