import React, { useState } from "react";
import Navigation from "../form-navigation/Navigation";
import { useFormContext } from "../../context/Context";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import { t } from "components/CentralDataMangement/translation/Translation";
import LabelField from "components/common/atoms/LabelField";
import InputTextfield from "components/common/atoms/InputTextField";
import "../../css/planning.css";
import ContractPerfcode from "./ContractPerfcode";
import {
  REMENUARATION_DETAILS,
  UPDATE_FIELDS,
} from "../../annotation/PlanningConstants";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import { resetPayProfileDetails } from "../form-data/ResetPlanningData";
import { fetchProfileDetails } from "../form-data/SetProfileData";
import AutomaticCode from "./AutomaticCode";
const RemunerationTab: React.FC = () => {
  const { state, dispatch } = useFormContext();

  const [validationStatus, setValidationStatus] = useState({
    isValid: false,
    type: "",
  });

  const validStatus = (validation: any) => {
    setValidationStatus({
      isValid: validation.isValid,
      type: validation.type,
    });
  };

  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    if (fieldName === "relocationAllowance") {
      dispatch({
        type: UPDATE_FIELDS,
        tab: REMENUARATION_DETAILS,
        field: "typeOfTransport",
        value: null,
      });
    }
    dispatch({
      type: UPDATE_FIELDS,
      tab: REMENUARATION_DETAILS,
      field: fieldName,
      value: selectedOption,
    });
    if (fieldName === "payProfile") {
      resetPayProfileDetails(dispatch);
    }
    // payProfile
    if (
      fieldName === "payProfile" &&
      selectedOption?.value !== undefined &&
      selectedOption?.value !== null
    ) {
      fetchProfileDetails("payProfile", selectedOption.value, state, dispatch);
    }
  };

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let updatedValue: string | number = value;
    // Apply filtering for specific fields that should only allow numbers and commas
    const numberFields = [
      "numberOfKm",
      "amntOftravel",
      "relocationAllowance",
      "mtcEmpPart",
      "mtcMinHr",
      "mtcTotalValue",
    ];
    if (numberFields.includes(name)) {
      updatedValue = value.replace(/[^0-9,]/g, "");
    }
    // Dispatch the updated value
    dispatch({
      type: UPDATE_FIELDS,
      tab: REMENUARATION_DETAILS,
      field: name,
      value: updatedValue,
    });
  };

  return (
    <>
      <div className="form-border" style={{ paddingTop: "3.4vw" }}>
        <div className="row">
          <div className="col-12">
            <SelectWithSearch
              title={t("Pay profile")}
              isMandatory={false}
              search={true}
              options={state.dropDowndata.pcPayProfileOptions}
              onChange={(e) => handleSelectChange(e, "payProfile")}
              isMulti={false}
              name="payProfile"
              value={state.remenurationDetails.payProfile}
            />
          </div>
          <div className="col-6">
            <div className="row">
              <div className="col-4">
                <LabelField title={t("Gross/hour")} isMandatory={false} />
                <div className="input-group field-shadow rounded-3 remunerationInputGroup">
                  <InputTextfield
                    name="grossPerHr"
                    handleChange={handleFieldChange}
                    value={state.remenurationDetails.grossPerHr}
                    type="text"
                    className="form-control shadow-none border-0 rounded-start-3 rounded-end-0"
                  />
                  <span
                    className="textAppendBg input-group-text justify-content-center field-shadow border-0"
                    id="basic-addon1"
                  >
                    {"€"}
                  </span>
                </div>
              </div>
              <div className="col-4">
                <SelectWithSearch
                  title={t("Relocation allowance")}
                  isMandatory={false}
                  search={true}
                  options={state.dropDowndata.relocationOptions}
                  onChange={(e) => handleSelectChange(e, "relocationAllowance")}
                  isMulti={false}
                  name="relocationAllowance"
                  value={state.remenurationDetails.relocationAllowance}
                  isTranslate={true}
                />
              </div>
              {state.remenurationDetails.relocationAllowance?.value === 2 && (
                <div className="col-4">
                  <SelectWithSearch
                    title={t("Type of transport")}
                    isMandatory={false}
                    search={true}
                    options={state.dropDowndata.transportTypeOptions}
                    onChange={(e) => handleSelectChange(e, "typeOfTransport")}
                    isMulti={false}
                    name="typeOfTransport"
                    value={state.remenurationDetails.typeOfTransport}
                    isTranslate={true}
                  />
                </div>
              )}
            </div>
            <div className="row">
              {/* <div className="col-4">
                <SelectWithSearch
                  title={t("Catch-up rest")}
                  isMandatory={false}
                  search={true}
                  options={state.dropDowndata.catchUpRestOptions}
                  onChange={(e) => handleSelectChange(e, "catchUpRest")}
                  isMulti={false}
                  name="catchUpRest"
                  value={state.remenurationDetails.catchUpRest}
                  isTranslate={true}
                />
              </div> */}
              <div className="col-3">
                <LabelField title={t("Number of Km")} isMandatory={false} />
                <div className="input-group field-shadow rounded-3 remunerationInputGroup">
                  <InputTextfield
                    name="numberOfKm"
                    handleChange={handleFieldChange}
                    value={state.remenurationDetails.numberOfKm}
                    type="text"
                    className="form-control shadow-none border-0 rounded-start-3 rounded-end-0"
                  />
                  <span
                    className="textAppendBg input-group-text justify-content-center field-shadow border-0"
                    id="basic-addon1"
                  >
                    {"km"}
                  </span>
                </div>
              </div>
              <div className="col-5">
                <LabelField
                  title={t("Amount of travel (fixed amount)")}
                  isMandatory={false}
                />
                <div className="input-group field-shadow rounded-3 remunerationInputGroup">
                  <InputTextfield
                    name="amntOftravel"
                    handleChange={handleFieldChange}
                    value={state.remenurationDetails.amntOftravel}
                    type="text"
                    className="form-control shadow-none border-0 rounded-start-3 rounded-end-0"
                  />
                  <span
                    className="textAppendBg input-group-text justify-content-center field-shadow border-0"
                    id="basic-addon1"
                  >
                    {"€"}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="row">
              <div className="col-4">
                <LabelField title={t("MTC total value")} isMandatory={false} />
                <div className="input-group field-shadow rounded-3 remunerationInputGroup">
                  <InputTextfield
                    name="mtcTotalValue"
                    handleChange={handleFieldChange}
                    value={state.remenurationDetails.mtcTotalValue}
                    type="text"
                    className="form-control shadow-none border-0 rounded-start-3 rounded-end-0"
                  />
                  <span
                    className="textAppendBg input-group-text justify-content-center field-shadow border-0"
                    id="basic-addon1"
                  >
                    {"€"}
                  </span>
                </div>
              </div>
              <div className="col-4">
                <LabelField
                  title={t("MTC employer part")}
                  isMandatory={false}
                />
                <div className="input-group field-shadow rounded-3 remunerationInputGroup">
                  <InputTextfield
                    name="mtcEmpPart"
                    handleChange={handleFieldChange}
                    value={state.remenurationDetails.mtcEmpPart}
                    type="text"
                    className="form-control shadow-none border-0 rounded-start-3 rounded-end-0"
                  />
                  <span
                    className="textAppendBg input-group-text justify-content-center field-shadow border-0"
                    id="basic-addon1"
                  >
                    {"€"}
                  </span>
                </div>
              </div>
              <div className="col-4">
                <LabelField
                  title={t("MTC minimum hours")}
                  isMandatory={false}
                />
                <div className="input-group field-shadow rounded-3 remunerationInputGroup">
                  <InputTextfield
                    name="mtcMinHr"
                    handleChange={handleFieldChange}
                    value={state.remenurationDetails.mtcMinHr}
                    type="text"
                    className="form-control shadow-none border-0 rounded-start-3 rounded-end-0"
                  />
                  <span
                    className="textAppendBg input-group-text justify-content-center field-shadow border-0"
                    id="basic-addon1"
                  >
                    {"u"}
                  </span>
                </div>
              </div>
              <div className="col-12 mt-4">
                <LabelWithInputField
                  isMandatory={false}
                  name="allowanceInfo"
                  handleChange={handleFieldChange}
                  value={state.remenurationDetails.allowanceInfo}
                  id="allowanceInfo"
                  label={t("Travel allowance info")}
                  type="text"
                />
              </div>
            </div>
          </div>

          <div className="col-3"></div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <ContractPerfcode />
          </div>

          <div className="col-md-6 remuneraionPayroll">
            <AutomaticCode />
          </div>
        </div>
      </div>

      <Navigation validStatus={validStatus} />
    </>
  );
};

export default RemunerationTab;
