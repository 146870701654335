import { Suspense } from 'react';
import { ApiCall } from 'components/common/services/ApiServices';
import { useLoaderData, defer, Await } from 'react-router-dom';
import ManageRolesPage from './ManageRolesPage';
import { GET_ROLES } from 'routes/ApiEndpoints';
import { t, translate } from 'components/CentralDataMangement/translation/Translation';

const ManageRoles = () => {
    const { roles } : any = useLoaderData();

    return <>
        <Suspense fallback={<p style={{ textAlign: 'center' }}>{t("Loading") + "..."}</p>}>
            <Await resolve={roles}>
                {(loadedRoles) => <ManageRolesPage rolesObj={loadedRoles}/>}
            </Await>
        </Suspense>
    </>;
}

export default translate(ManageRoles);

async function loadEvents() {
    const response  = await ApiCall.getService(GET_ROLES, "GET");
    if (response.status === 200) {
        return await response.data['roles'];
    } else {
        return [];
    }
}

export function loader() {
    return defer({
      roles: loadEvents(),
    });
  }