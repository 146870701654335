import { t } from "components/CentralDataMangement/translation/Translation";
import { FeeBasedItem } from "./CoopAgreementInterface";

export const initialState = {
  tabDetails: [
    {
      id: "general",
      title: t("General"),
      showStatus: false,
      error: false,
      draft: false,
    },
    {
      id: "contacts",
      title: t("Contacts"),
      showStatus: false,
      error: false,
      draft: false,
    },
    {
      id: "coefficients",
      title: t("Coefficients"),
      showStatus: false,
      error: false,
      draft: false,
    },
    {
      id: "compositionData",
      title: t("Special agreements"),
      showStatus: false,
      error: false,
      draft: false,
    },
    {
      id: "invoice",
      title: t("Info"),
      showStatus: false,
      error: false,
      draft: false,
    },
  ],

  general: {
    vat: "",
    company: "",
    startDate: new Date(),
    consultant: null,
    officeNumber: null,
    province: [],
    addProject: false,
    type: null,
    workType: null,
    vatInclusive: null,
    feeType: null,
    paritairBlue: [],
    paritairWhite: [],
    agreementStatus: 1,
    wageIncluded: null,
    officeList: [],
    coeffType: 2,
    feeBased: [
      { id: null, percentage: null, count: null, feeBasedType: null } as FeeBasedItem
    ],
    createdAt: null,
    createdBy: '',
    updatedAt: null,
    updatedBy: '',
    amApproveButton: 0
  },
  billing: [],
  amApprovedData: [],
  contacts: [],
  invoice: {
    paymentTerm: null,
    billingType: 0,
    corrections: null,
    invoiceWorker: false,
    invoicePerLocation: false,
    invoicePerEmployee: false,
    invoiceSelcOrPay: false,
    costCenter: false,
    hrsCostCenter: false,
    mergeTempWorker: false,
    hrsWorked: false,
    contractFunction: false,
    paymentDebit: false,
    accountNo: '',
    reference: '',
    invoiceType: null,
    dateOfSign: null,
    comments: '',
    coeffStatus: false,
    coeffMaxStatus: false,
  },
  consultantList: [],
  blueCollarPCs: [],
  whiteCollarPCs: [],
  employeeTypes: [],
  retentionPeriods: [],
  rolesList: [],
  offices: [],
  genders: [],
  payCondition: [],
  location: [],
  initialEmpCoeff: [],
  pcEmpTypes: [],
  languages: [],
  selectedContact: [],
  levelDropDown: [],
  workUnitList: [],
  contactList: [],
  coeffTypes: [],
  // functionDropdownList: [],
  compositionData: [],
  amApprovedCompositionData: [],
  vatCodes: [],
  fieldError: {
    vat: "",
    company: "",
    startDate: "",
    consultant: "",
    officeNumber: "",
    province: "",
    addProject: "",
    type: "",
    vatInclusive: "",
    feeType: "",
    PC: "",
    PC2: "",
    employeeType2: "",
    employeeType: "",
    paymentTerm: "",
    billingType: "",
    accountNo: "",
    refernece: "",
    dateOfSign: "",
    invoiceType: "",
    corrections: "",
    comments: "",
    paritairBlue: "",
    paritairWhite: "",
    retentionPeriod: "",
    retentionCount: "",
    paymentPeriod: "",
    selectedContact: "",
    coeffType: "",
    vatRate: ""
  },
  dynamicErrors: [
    {
      fName: "",
      lName: "",
      email: "",
      phNumber: "",
      teleNumber: "",
      dob: "",
      gsm: "",
      contact: "",
      functionTitle: "",
      linkedIn: "",
      roles: "",
      language: "",
      decisionMaker: "",
      influencer: "",
      contactCalled: "",
      contactEmailed: "",
      info: "",
      title: "",
      location: "",
    },
  ],
  feeBasedErrors: [
    {
      percentage: "",
      count: "",
      feeBasedType: ""
    },
  ],
  feeBasedErrorStatus: false,
};
