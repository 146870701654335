import React from 'react'

const Call = () => {
  return (
    <>
      <svg width="30" height="30" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M29.924 25.33C29.924 25.89 30.374 26.33 30.924 26.33C32.064 26.33 32.984 27.26 32.984 28.39C32.984 28.94 33.434 29.39 33.984 29.39C34.534 29.39 34.984 28.94 34.984 28.39C34.984 26.15 33.164 24.33 30.924 24.33C30.374 24.33 29.924 24.78 29.924 25.33ZM46.204 29.39C46.764 29.39 47.204 28.94 47.204 28.39C47.204 19.41 39.904 12.11 30.924 12.11C30.374 12.11 29.924 12.56 29.924 13.11C29.924 13.66 30.374 14.11 30.924 14.11C38.804 14.11 45.204 20.52 45.204 28.39C45.204 28.94 45.654 29.39 46.204 29.39Z" fill="currentColor" />
        <path d="M39.094 28.39C39.094 28.94 39.544 29.39 40.094 29.39C40.644 29.39 41.094 28.94 41.094 28.39C41.094 22.78 36.534 18.22 30.924 18.22C30.374 18.22 29.924 18.67 29.924 19.22C29.924 19.78 30.374 20.22 30.924 20.22C35.434 20.22 39.094 23.89 39.094 28.39ZM30.924 6C30.374 6 29.924 6.45 29.924 7C29.924 7.55 30.374 8 30.924 8C42.174 8 51.324 17.15 51.324 28.39C51.324 28.94 51.764 29.39 52.324 29.39C52.874 29.39 53.324 28.94 53.324 28.39C53.324 16.04 43.274 6 30.924 6ZM40.694 38.93L39.384 39.64C37.174 40.85 34.374 40.44 32.584 38.65L22.024 28.09C20.234 26.3 19.824 23.5 21.034 21.28L21.744 19.98C22.544 18.5 22.834 16.84 22.594 15.18C22.3673 13.5234 21.6011 11.9875 20.414 10.81C18.954 9.35 17.014 8.55 14.944 8.55C12.874 8.55 10.924 9.35 9.464 10.81L8.954 11.33C6.254 14.03 5.944 18.68 8.074 24.44C10.084 29.94 14.134 35.9 19.454 41.22C27.564 49.33 36.594 54 43.074 54C45.634 54 47.804 53.27 49.344 51.73L49.864 51.21C50.5841 50.4907 51.1553 49.6364 51.5448 48.6961C51.9343 47.7557 52.1345 46.7478 52.134 45.73C52.12 43.6803 51.3054 41.7173 49.864 40.26C47.454 37.85 43.684 37.31 40.694 38.93Z" fill="CurrentColor" />
      </svg>

    </>
  )
}

export default Call