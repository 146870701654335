import React from 'react'

const ArchiveNew: React.FC = () => {
  return (
    <>
      <svg width="40" height="40" viewBox="14 -4 8 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.12709 0.0736505C4.76479 0.14201 4.23843 0.394938 3.84194 0.682046C3.67105 0.811928 3.03531 1.58439 2.26968 2.58243C0.827309 4.47597 0.635904 4.74257 0.396647 5.22792C-0.0203432 6.0619 0.000164464 5.26894 0.000164464 18.9407C0.000164464 27.4993 0.0206722 31.5462 0.0753594 31.8264C0.19157 32.5032 0.635904 33.3235 1.15543 33.843C1.67496 34.3626 2.49527 34.8069 3.17202 34.9231C3.7394 35.0256 31.2608 35.0256 31.8281 34.9231C32.5049 34.8069 33.3252 34.3626 33.8447 33.843C34.3643 33.3235 34.8086 32.5032 34.9248 31.8264C34.9795 31.5462 35 27.4993 35 18.9407C35 7.641 34.9863 6.42421 34.8906 6.02089C34.7676 5.5287 34.5488 5.05703 34.2275 4.57851C33.79 3.93594 30.9668 0.572672 30.7481 0.435953C30.625 0.360759 30.3858 0.237712 30.2149 0.162517C29.9141 0.0326351 29.6065 0.0326351 17.671 0.0257992C10.8419 0.0189632 5.29799 0.039471 5.12709 0.0736505ZM29.8184 2.89004L30.6934 3.86074L24.0694 3.88125C20.4259 3.88809 14.4376 3.88809 10.7736 3.88125L4.10171 3.86074L4.59389 3.24551C4.86049 2.90372 5.21596 2.46622 5.38002 2.26798L5.68764 1.91251H17.3155H28.9502L29.8184 2.89004ZM21.3965 15.5501V17.4984H24.794H28.1983L22.8458 22.8441L17.5001 28.1966L12.1544 22.8441L6.80189 17.4984H10.2062H13.6036V15.5501V13.6019H17.5001H21.3965V15.5501Z" fill="currentColor" />
      </svg>


    </>
  )
}

export default ArchiveNew