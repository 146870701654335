import Title from "components/common/atoms/Title";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import { useEffect, useState } from "react";
import { ApiCall } from "components/common/services/ApiServices";
import { TARGET_ADD, TARGET_EDIT } from "routes/ApiEndpoints";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "Constants";
import {
  OptionProps,
  ValidationRules,
} from "components/common/utlis/TypeAnnotations";
import { mapEnumToSelect } from "components/common/utlis/MapEnumToSelect";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "components/common/atoms/Button";
import LoadingIcon from "components/common/utlis/LoadingIcon";
import CustomNotify from "components/common/atoms/CustomNotify";
import {
  validateForm,
  validateSelectField,
} from "components/common/services/ValidationService";
import LabelField from "components/common/atoms/LabelField";
import { Spinner } from "react-bootstrap";
import InputTextfield from "components/common/atoms/InputTextField";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";

interface TargetData {
  targetConfDetId: number | null;
  type: {
    value: number;
    label: string;
  };
  targetPer: {
    value: number;
    label: string;
  };
  value: null | string;
}

export interface Roles {
  value?: number;
  label?: string;
  uniqueName?: string;
}

interface MonthData {
  [monthKey: string]: {
    target_config_id: number | null;
    targets: {
      [categoryName: string]: TargetData;
    };
  };
}

interface Designations {
  RECRUITER: OptionProps[];
  OFFICE_MANAGER: OptionProps[];
  // Add more designations as needed
}

const TargetConfig: React.FC = () => {
  const [designations, setDesignations] = useState<Designations>({
    RECRUITER: [],
    OFFICE_MANAGER: [],
    // Initialize with appropriate default values or fetch from API
  });
  const [designation, setDesignation] = useState<OptionProps>();
  const [role, setRole] = useState<OptionProps>();
  const initialErrors: { [key: string]: string } = {};
  const [errors, setErrors] = useState<{ [key: string]: string }>(
    initialErrors
  );
  const [targetPer, setTargetPer] = useState<OptionProps[]>([]);
  const [category, setCategory] = useState([]);
  const [monthData, setMonthData] = useState<MonthData>({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [roles, setRoles] = useState<Roles[]>([]);
  const [isSave, setIsSave] = useState<boolean>(false);

  const fetchTargets = async () => {
    const selectedRole = roles.find((item) => item.value === role?.value);

    const requestData = {
      type: "config",
      designation: designation,
      role: {
        value: selectedRole?.value,
        label: selectedRole?.uniqueName,
      },
    };

    const response = await ApiCall.service(
      TARGET_EDIT + "/config",
      "POST",
      requestData,
      true,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );

    if (response.status === 200) {
      const targetPeriod = mapEnumToSelect(response.msg["targetPerList"]);

      setRoles(response.msg["designations"]["roles"]);
      setMonthData(response.msg["config"]);
      setTargetPer(targetPeriod);
      setCategory(response.msg["targetTypes"]);

      setDesignations(response.msg["designations"]["designations"]);
    }
  };

  useEffect(() => {
    fetchTargets();
  }, [designation]);

  const validation = (
    name: string,
    value: string | boolean | Date | object[] | undefined | null | OptionProps,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      designation: [validateSelectField],
    };
    const validationErrors = validateForm(
      { ...designation, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const handleRoleChange = (selectedOption: any) => {
    setErrors({});
    setRole(selectedOption);
    setDesignation({ label: "Select", value: 0 });
  };

  const handleDesignationChange = (selectedOption: any) => {
    setErrors({});
    setDesignation(selectedOption);
  };

  const handleSelectChange = (
    selectedOption: any,
    categoryName: string,
    monthIndex: number
  ) => {
    setMonthData((prevData) => {
      const newData = { ...prevData };
      // Iterate over each month in monthData
      Object.keys(newData).forEach((monthKey: string) => {
        // Check if the categoryName exists in the targets of the current month
        if (newData[monthKey]?.targets?.[categoryName]) {
          // Update the targetPer value for the categoryName
          newData[monthKey].targets[categoryName].targetPer = selectedOption;
        }
      });

      return newData;
    });
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    categoryName: string,
    monthIndex: number
  ) => {
    const { value } = e.target;
    const regex = /^[0-9]+$/;

    if (regex.test(value) || value === "") {
      setMonthData((prevData) => {
        const newData: any = { ...prevData };
        const monthKey = `month-${monthIndex + 1}`;
        if (newData[monthKey].targets[categoryName]) {
          // Update the state immutably
          newData[monthKey].targets[categoryName] = {
            ...newData[monthKey].targets[categoryName], // Preserve other properties
            value: value, // Update the value property
          };
        } else {
          newData[monthKey].targets[categoryName] = {
            targetConfDetId: null,
            type: { value: "", label: "Select" }, // Provide default values
            targetPer: { value: "", label: "Select" },
            value: value, // Provide default value or null
          };
        }

        return newData;
      });
    }
  };

  // Function to check if any value meets the condition
  function hasNullOrBlankValue(data: MonthData): boolean {
    for (const monthKey in data) {
      const month = data[monthKey];
      const targets = month.targets;

      for (const categoryName in targets) {
        const target = targets[categoryName];
        const value = target.value;

        // Check if value is "" (empty string), null, or 0
        if (value === "" || value === null) {
          return true; // Return true as soon as a valid value is found
        }
      }
    }

    return false; // Return false if no valid value is found
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const selectedRole = roles.find((item) => item.value === role?.value);
    setLoading(true);
    const payload = {
      type: "config",
      designation: designation,
      config: monthData,
      role: {
        label: selectedRole?.uniqueName,
        value: selectedRole?.value,
      },
    };

    if (validation("designation", designation) && designation?.value !== 0) {
      setIsSave(true);
      if (hasNullOrBlankValue(monthData)) {
        setLoading(false);
        CustomNotify({
          type: "error",
          message: t("Please fill the highlighted fields"),
        });
        return;
      }
      const url = `${TARGET_ADD}/config`;
      const response = await ApiCall.service(
        url,
        "POST",
        payload,
        false,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.status === 200) {
        CustomNotify({ type: "success", message: response.msg });
        // navigate("/budget-targets");
      }
    }
    // await new Promise((resolve) => setTimeout(resolve, 300));
    setLoading(false);
  };

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Config targets",
          read: true,
          create: true,
          update: true,
        },
      ]}
      actions={true}
      strict={false}
      renderNoAccess={true}
    >
      <Title title={t("Targets")} />
      <div className="row search-bar">
        <div className="col-md-6">
          <SelectWithSearch
            options={roles}
            search={true}
            value={role}
            onChange={handleRoleChange}
            name="role"
            title={t("Roles")}
            isTranslate={true}
          />
        </div>
        <div className="col-md-6">
          <SelectWithSearch
            options={
              designations[role?.value?.toString() as keyof Designations] || []
            }
            search={true}
            value={designation}
            isDisabled={role?.value ? false : true}
            onChange={handleDesignationChange}
            name="designation"
            title={t("Designation")}
            isTranslate={true}
          />
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12">
            <div className="targetConfigWrapper table-responsive">
              <table className="table table-hover table-bordered targetTable mb-0">
                <thead>
                  <tr className="TableHeader text-center">
                    <th className="text-break" style={{ width: "10%" }}>
                      {t("Category")}
                    </th>
                    <th className="text-break" style={{ width: "10%" }}>
                      {t("Target per")}
                    </th>
                    <th className="text-break" style={{ width: "12%" }}>
                      {t("Month") + " 1"}
                    </th>
                    <th className="text-break" style={{ width: "12%" }}>
                      {t("Month") + " 2"}
                    </th>
                    <th className="text-break" style={{ width: "12%" }}>
                      {t("Month") + " 3"}
                    </th>
                    <th className="text-break" style={{ width: "12%" }}>
                      {t("Month") + " 4"}
                    </th>
                    <th className="text-break" style={{ width: "12%" }}>
                      {t("Month") + " 5"}
                    </th>
                    <th className="text-break" style={{ width: "12%" }}>
                      {t("Month") + " 6"}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr className="border-0">
                      <td
                        className="text-center border-0 spinner-wrapper"
                        colSpan={8}
                      >
                        <Spinner size="sm" /> {t("Loading")}
                      </td>
                    </tr>
                  ) : designation &&
                    designation.value !== null &&
                    designation.value !== 0 ? (
                    category &&
                    category.length > 0 &&
                    category.map((categoryItem: any, index) => {
                      return (
                        <tr key={`${categoryItem.id}${index}`}>
                          <td className="align-middle text-center py-3">
                            {t(`${categoryItem.type}`)}
                          </td>
                          {Object.values(monthData).map(
                            (month: any, index: number) => {
                              const categoryData =
                                month.targets[categoryItem.type];
                              if (categoryData) {
                                return (
                                  <React.Fragment key={index}>
                                    {index === 0 && (
                                      <td
                                        key={`targetper-${index}`}
                                        className="align-middle targetPer text-center p-0 position-relative"
                                      >
                                        <SelectWithSearch
                                          options={targetPer}
                                          onChange={(e) => {
                                            handleSelectChange(
                                              e,
                                              categoryItem.type,
                                              index
                                            );
                                          }}
                                          name="targetPer"
                                          search
                                          value={categoryData["targetPer"]}
                                          className="targetPerSelect"
                                          isTranslate={true}
                                        />
                                      </td>
                                    )}
                                    <td
                                      key={`target-${index}`}
                                      className={`align-middle monthTarget text-center position-relative 
                                      ${
                                        categoryData.value ||
                                        parseInt(categoryData.value) === 0
                                          ? "dataField"
                                          : !isSave
                                          ? "dataField"
                                          : "error-border"
                                      }`}
                                    >
                                      <InputTextfield
                                        handleChange={(e) =>
                                          handleInputChange(
                                            e,
                                            categoryItem.type,
                                            index
                                          )
                                        }
                                        name="target"
                                        value={categoryData.value ?? ""}
                                        type="text"
                                        className={`form-control text-center shadow-none rounded-0 position-absolute start-0 top-0 w-100 h-100`}
                                      />
                                    </td>
                                  </React.Fragment>
                                );
                              } else {
                                return null; // Ensure a value is returned when categoryData is falsy
                              }
                            }
                          )}
                        </tr>
                      );
                    })
                  ) : (
                    <tr className="border rounded-3">
                      <td
                        colSpan={8}
                        className="border-0 text-center no-records"
                      >
                        <div className="text-danger text-center py-3">
                          {t("Please select designation")}
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="row backPadding">
          <div className="col-md-4 align-self-center">
            <Link
              to=""
              className="back-btn text-decoration-underline"
              onClick={() => navigate(-1)}
            >
              {t("Back")}
            </Link>
          </div>
          <AccessControl
            requiredPermissions={[
              {
                permission: "Config targets",
                create: true,
                update: true,
              },
            ]}
            actions={true}
            strict={false}
          >
            <div className="col-md-8">
              {!loading ? (
                <Button
                  title={t("Save")}
                  type="submit"
                  className="form-button float-end shadow-none"
                />
              ) : (
                <LoadingIcon
                  iconType="bars"
                  color="#ff4dae"
                  className="float-end"
                  width={"3vw"}
                  height={"3vw"}
                />
              )}
            </div>
          </AccessControl>
        </div>
      </form>
    </AccessControl>
  );
};

export default translate(TargetConfig);
