import React, { useState, useEffect } from "react";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import { ApiCall } from "components/common/services/ApiServices";
import {
  Option,
  OptionProps,
  ValidationRules,
} from "components/common/utlis/TypeAnnotations";
import { mapToSelect } from "components/common/utlis/MapToSelect";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import Button from "components/common/atoms/Button";
import CustomNotify from "components/common/atoms/CustomNotify";
import { useLocation, useParams, Link, useNavigate } from "react-router-dom";
import LabelField from "components/common/atoms/LabelField";
import {
  validateForm,
  validateSelectField,
} from "components/common/services/ValidationService";
import * as ENDPOINTS from "../../../../routes/ApiEndpoints";
import Title from "components/common/atoms/Title";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";

// Define the interface for CityProps
export interface CityProps {
  region: Option | null;
  country: Option | null;
  lowPercentage: string;
  mediumPercentage: string;
  highPercentage: string;
  lowCity: OptionProps[];
  mediumCity: OptionProps[];
  highCity: OptionProps[];
}

const AddCityProfile: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const isViewMode = location.pathname.includes("view");
  const isEditMode = location.pathname.includes("edit");
  const navigate = useNavigate();

  // State for form data and city profile details
  const [formData, setFormData] = useState<CityProps>({
    region: null,
    country: null,
    lowCity: [],
    mediumCity: [],
    highCity: [],
    lowPercentage: "",
    mediumPercentage: "",
    highPercentage: "",
  });
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [cityProfileDetails, setCityProfileDetails] = useState({
    region: [] as OptionProps[],
    country: [] as OptionProps[],
    city: [] as OptionProps[],
  });
  const [cityError, setCityError] = useState("");
  const initialErrors: { [key: string]: string } = {};
  const [errors, setErrors] = useState<{ [key: string]: string }>(
    initialErrors
  );
  useEffect(() => {
    const fetchCityProfileDetails = async () => {
      const url = id
        ? `${ENDPOINTS.GET_CITY_DETAILS}/${id}`
        : `${ENDPOINTS.GET_CITY_DETAILS}`;

      const response = await ApiCall.getService(
        url,
        "GET",
        "central-data-management",
        true
      );

      if (response.status === 200) {
        const countryRegionDetails = response.data["countryRegionDetails"];
        setFormData((prevData) => ({
          ...prevData,
          ...countryRegionDetails,
        }));

        // Extract and set linked cities from the response
        const linkedCities = response.data["linkedCities"];
        if (linkedCities) {
          setFormData((prevData) => ({
            ...prevData,
            ...countryRegionDetails,
            ...linkedCities,
          }));
        }

        const regions = mapToSelect(response.data["region"]);
        const countries = response.data["countries"];
        const cities = response.data["cities"];

        setCityProfileDetails((prevData) => ({
          ...prevData,
          city: cities,
          region: regions,
          country: countries,
        }));
      }
    };

    fetchCityProfileDetails();
  }, [id]);

  const validation = (
    //validation function related field validations
    name: string,
    value: string | Option | null,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      region: [validateSelectField],
      country: [validateSelectField],
    };

    const validationErrors = validateForm(
      { ...formData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation && Object.keys(errors).length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }

    return true;
  };

  const getCountriesForRegion = () => {
    if (selectedRegion) {
      const dropDown = cityProfileDetails.country.filter(
        (func) =>
          func.region_id !== undefined && selectedRegion === func.region_id
      );

      return mapToSelect(dropDown);
    }

    return [];
  };

  // Handle changes in input fields
  const changeHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target as HTMLInputElement;
    const regex = /^(\d{0,2})(,\d{0,4})?$/;
    let matches = regex.exec(value);
    if (matches) {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleCountryChange = (selectedOption: any) => {
    const countryId = selectedOption.value;
    if (countryId) {
      const fetchCityDetailsForCountry = async () => {
        const response = await ApiCall.getService(
          `${ENDPOINTS.GET_CITY_DETAILS}/${countryId}`,
          "GET",
          "central-data-management"
        );
        const cities = response.data["cities"];
        if (response.status === 200) {
          setCityProfileDetails((prevData) => ({
            ...prevData,
            city: cities,
          }));
        }
      };
      fetchCityDetailsForCountry();
    }
  };

  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    if (fieldName === "region") {
      setSelectedRegion(selectedOption.value);
      setFormData((prevData) => ({
        ...prevData,
        country: null,
        [fieldName]: selectedOption,
      }));
    }
    if (fieldName === "country") {
      setFormData((prevData) => ({
        ...prevData,
        lowCity: [],
        mediumCity: [],
        highCity: [],
        lowPercentage: "",
        mediumPercentage: "",
        highPercentage: "",
      }));
      handleCountryChange(selectedOption);
      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: selectedOption,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: selectedOption,
      }));
    }
    validation(fieldName, selectedOption, true);
  };

  const filterCity = () => {
    const lowCities = formData.lowCity.map((city) => city.value);
    const mediumCities = formData.mediumCity.map((city) => city.value);
    const highCities = formData.highCity.map((city) => city.value);

    if (
      lowCities.length > 0 ||
      mediumCities.length > 0 ||
      highCities.length > 0
    ) {
      const selectedCities = [...lowCities, ...mediumCities, ...highCities];

      const filteredCities = cityProfileDetails.city.filter(
        (func) => !selectedCities.includes(func.id as number)
      );
      return mapToSelect(filteredCities);
    }
    return mapToSelect(cityProfileDetails.city);
  };

  const validateCitySelection = () => {
    if (
      formData.lowCity.length === 0 &&
      formData.mediumCity.length === 0 &&
      formData.highCity.length === 0
    ) {
      setCityError(t("At least one city profile should be filled") + ".");
      // return false;
      return [false, t("At least one city profile should be filled") + "."];
    } else {
      setCityError("");
      // return true;
      return [true, ""];
    }
  };

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const { name, value } = e.target as HTMLInputElement;
    const isCitySelected = validateCitySelection();

    if (validation(name, value) && isCitySelected[0]) {
      const url = id
        ? `${ENDPOINTS.UPDATE_CITY}/${id}`
        : `${ENDPOINTS.STORE_CITY}`;
      const response = await ApiCall.service(
        url,
        "POST",
        formData,
        false,
        "central-data-management"
      );
      if (response.status === 200) {
        CustomNotify({ type: "success", message: t(response.msg) });
        navigate("/manage-city-profile");
      } else if (response.status === 400) {
        setErrors({
          country: response.msg,
        });
        CustomNotify({ type: "error", message: t(response.msg) });
      }
    } else {
      CustomNotify({ type: "error", message: t(isCitySelected[1]) });
    }
  };

  const permissionType = id ? "update" : "create";
  const permissionObject: any = {
    permission: "City profiles",
  };
  permissionObject[permissionType] = true;
  return (
    <AccessControl
      requiredPermissions={[permissionObject]}
      renderNoAccess={true}
    >
      <Title
        title={t(
          ` ${
            isEditMode
              ? "Edit city profile"
              : isViewMode
              ? "View city profile"
              : "Add city profile"
          }`
        )}
      />
      <form onSubmit={handleSubmit}>
        <div
          className={
            isEditMode
              ? "edit-city-height"
              : isViewMode
              ? "view-city-height"
              : "add-city-height"
          }
        >
          <div className="row search-bar">
            <div className="col-6">
              <SelectWithSearch
                title={t("Region")}
                search={true}
                options={cityProfileDetails.region}
                isDisabled={isEditMode || isViewMode}
                onChange={(e) => handleSelectChange(e, "region")}
                isMulti={false}
                name="region"
                value={formData.region}
                error={errors.region}
                isTranslate={true}
              />
            </div>
            <div className="col-6">
              <SelectWithSearch
                title={t("Country")}
                search={true}
                options={getCountriesForRegion()}
                isDisabled={isEditMode || isViewMode}
                onChange={(e) => handleSelectChange(e, "country")}
                isMulti={false}
                name="country"
                value={formData.country}
                error={errors.country}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="tableSection">
                <table className="table">
                  <thead>
                    <tr className="TableHeader">
                      <th className="ps-3" style={{ width: "15%" }}>
                        {t("Profile")}
                      </th>
                      <th style={{ width: "65%" }}>{t("Cities")}</th>
                      <th style={{ width: "20%" }}>{t("Addends")}</th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {/* Low City */}
                    <tr className="border align-middle box-shadow mb-3 mb-lg-0">
                      <td
                        className="ps-lg-4 city-label"
                        data-label={t("Profile")}
                      >
                        <LabelField
                          title={t("Low")}
                          className="align-self-center"
                        />
                      </td>
                      <td data-label={t("Cities")} className="addCityProfile">
                        <SelectWithSearch
                          search={true}
                          options={filterCity()}
                          placeHolder={t("Select city")}
                          isDisabled={isViewMode}
                          onChange={(e) => handleSelectChange(e, "lowCity")}
                          isMulti={true}
                          name="lowCity"
                          value={formData.lowCity}
                          className="scrollBar"
                        />
                      </td>
                      <td data-label={t("Factor")} className="addCityProfile">
                        <LabelWithInputField
                          isMandatory={false}
                          name="lowPercentage"
                          handleChange={changeHandler}
                          value={formData.lowPercentage}
                          readOnly={isViewMode}
                          id="lowPercentage"
                          type="text"
                          className={`${
                            isViewMode ? "disabled scrollBar" : ""
                          } scrollBar`}
                        />
                      </td>
                    </tr>
                    {/* Medium City */}
                    <tr className="border align-middle box-shadow mb-3 mb-lg-0">
                      <td
                        className="ps-lg-4 city-label"
                        data-label={t("Profile")}
                      >
                        <LabelField
                          title={t("Medium")}
                          className="align-self-center"
                        />
                      </td>
                      <td data-label={t("Cities")} className="addCityProfile">
                        <SelectWithSearch
                          search={true}
                          options={filterCity()}
                          placeHolder={t("Select city")}
                          isDisabled={isViewMode}
                          onChange={(e) => handleSelectChange(e, "mediumCity")}
                          isMulti={true}
                          name="mediumCity"
                          value={formData.mediumCity}
                          className="scrollBar"
                        />
                      </td>
                      <td data-label={t("Factor")} className="addCityProfile">
                        <LabelWithInputField
                          isMandatory={false}
                          name="mediumPercentage"
                          handleChange={changeHandler}
                          value={formData.mediumPercentage}
                          readOnly={isViewMode}
                          id="mediumPercentage"
                          type="text"
                          className={`${
                            isViewMode ? "disabled" : ""
                          } scrollBar`}
                        />
                      </td>
                    </tr>
                    {/* High City */}
                    <tr className="border align-middle box-shadow">
                      <td
                        className="ps-lg-4 city-label"
                        data-label={t("Profile")}
                      >
                        <LabelField
                          title={t("High")}
                          className="align-self-center"
                        />
                      </td>
                      <td data-label={t("Cities")} className="addCityProfile">
                        <SelectWithSearch
                          search={true}
                          options={filterCity()}
                          placeHolder={t("Select city")}
                          isDisabled={isViewMode}
                          onChange={(e) => handleSelectChange(e, "highCity")}
                          isMulti={true}
                          name="highCity"
                          value={formData.highCity}
                          className="scrollBar"
                        />
                      </td>
                      <td data-label={t("Factor")} className="addCityProfile">
                        <LabelWithInputField
                          isMandatory={false}
                          name="highPercentage"
                          handleChange={changeHandler}
                          value={formData.highPercentage}
                          readOnly={isViewMode}
                          id="highPercentage"
                          type="text"
                          className={`${
                            isViewMode ? "disabled" : ""
                          } scrollBar`}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                {cityError && <p className="text-danger">{cityError}</p>}
              </div>
            </div>
          </div>
        </div>
        <div className="row backPadding">
          <div className="col-md-6 align-self-center">
            <Link
              to="/manage-city-profile"
              className=" back-btn text-decoration-underline"
            >
              {t("Back")}
            </Link>
          </div>
          <div className="col-md-6 text-end">
            {!isViewMode && (
              <Button
                title={isEditMode ? t("Update city") : t("Add city")}
                type="submit"
                className="form-button shadow-none"
              />
            )}
          </div>
        </div>
      </form>
    </AccessControl>
  );
};

export default translate(AddCityProfile);
