import React, { ChangeEvent, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Button from "components/common/atoms/Button";
import InputTextField from "components/common/atoms//InputTextField";
import { ApiCall } from "components/common/services/ApiServices";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import {
  validateRequired,
  validateTextFiledSpace,
  validateForm,
} from "components/common/services/ValidationService";
import { Link, useNavigate, useParams } from "react-router-dom";
import CustomNotify from "components/common/atoms/CustomNotify";
import LoadingIcon from "components/common/utlis/LoadingIcon";
import {
  EDIT_ROLE,
  CLASS_BY_PERMISSIONS,
  CREATE_ROLE,
} from "routes/ApiEndpoints";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import Title from "components/common/atoms/Title";
import DesignationTable from "./DesignationTable";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";

interface CheckboxProps {
  classification: string;
  userPermission: string;
  action: string;
  checked: boolean;
  onChange: (userPermission: string, action: string, checked: boolean) => void;
}

interface PermissionCred {
  id: number;
  crud_name: string;
  unique_key: string;
}

interface TabsProps {
  data: {
    permissionsWithClassifications: Record<
      string,
      { permissions: Record<number, string> }
    >;
    permissionCred: PermissionCred[];
  };
  activeTab?: string;
  onTabChange: (userPermission: string) => void;
}

interface RoleData {
  roleName: string;
  action: Record<string, Record<string, boolean>>;
  type?: string;
  roleId?: string;
}

const Checkbox: React.FC<CheckboxProps> = ({
  classification,
  userPermission,
  action,
  checked,
  onChange,
}) => {
  const checkboxName = `${classification.toLowerCase()}-${action}`;

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    onChange(userPermission, action, checked);
  };

  return (
    <InputTextField
      className="form-check-input rounded-0 shadow-none"
      type="checkbox"
      name={checkboxName}
      checked={checked}
      handleChange={handleCheckboxChange}
    />
  );
};

interface Designation {
  index?: number | null;
  id?: number | null;
  name: string;
}

const Tabs: React.FC<TabsProps> = ({ data, activeTab, onTabChange }) => (
  <>
    <ul className="nav nav-tabs border-0 create-role-tabs">
      {Object.keys(data.permissionsWithClassifications).map(
        (classification) => (
          <li className="nav-item marginRight1" key={classification}>
            <Button
              title={t(`${classification}`)}
              handleClick={() => onTabChange(classification)}
              className={`btn ${
                activeTab === classification
                  ? "active border-0"
                  : "border-0 ps-0"
              }`}
            />
          </li>
        )
      )}
    </ul>
  </>
);

const RolePage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [designationError, setDesignationError] = useState<
    Record<string, string>
  >({});
  const [activeTab, setActiveTab] = useState<string | undefined>();
  const [message, setMessage] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [roleData, setRoleData] = useState<RoleData>({
    roleName: "",
    action: {},
  });
  const [data, setData] = useState<{
    permissionsWithClassifications: Record<
      string,
      { permissions: Record<number, string> }
    >;
    permissionCred: PermissionCred[];
  } | null>(null);
  const navigate = useNavigate();
  const [designations, setDesignations] = useState<Designation[]>([]);
  const [currentDesignation, setCurrentDesignation] = useState<Designation>({
    name: "",
    id: null,
  });

  useEffect(() => {
    fetchRolesAndPermission();

    if (id) {
      const fetchRoleById = async () => {
        const response = await ApiCall.getService(`${EDIT_ROLE}/${id}`, "GET");
        setMessage(response.msg);
        if (response.status === 200) {
          setDesignations(response.data["designations"]);
          const { roleName, action } = response.data;
          setRoleData({ roleName, action });
        }
      };

      fetchRoleById();
    }
  }, [id]);

  const fetchRolesAndPermission = async () => {
    const response = await ApiCall.service(CLASS_BY_PERMISSIONS, "POST", {});

    if (response.status === 200) {
      const permissionsWithClassifications =
        response.data.permissionsWithClassifications;
      const classificationKeys = Object.keys(permissionsWithClassifications);
      setData({
        permissionsWithClassifications,
        permissionCred: response.data.permissionCred,
      });
      setActiveTab(classificationKeys[0]);
    }
  };

  const handleCheckboxChange = (
    userPermission: string,
    action: string,
    checked: boolean
  ) => {
    setRoleData((prevRoleData) => {
      const updatedAction = {
        ...prevRoleData.action,
        [userPermission]: {
          ...(prevRoleData.action[userPermission] || {}),
        },
      };

      if (checked) {
        updatedAction[userPermission][action] = true;
      } else {
        delete updatedAction[userPermission][action];
        if (Object.keys(updatedAction[userPermission]).length === 0) {
          delete updatedAction[userPermission];
        }
      }

      const hasAction = Object.keys(updatedAction).length > 0;
      setErrors((prevErrors) => {
        if (hasAction) {
          const { action, ...remainingErrors } = prevErrors;
          return remainingErrors;
        }
        return prevErrors;
      });

      return {
        ...prevRoleData,
        action: updatedAction,
      };
    });
  };

  const validation = (
    name: string,
    value: string,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules = {
      roleName: [validateRequired, validateTextFiledSpace],
    };

    const validationErrors = validateForm(
      { roleName: value, ...roleData.action },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );
    let error = false;

    if (isSingleFieldValidation && Object.keys(errors).length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (!Object.keys(roleData.action).length) {
      error = true;
      setErrors(() => ({
        ...validationErrors,
        action: t("Please select atleast one action."),
      }));
    }

    return Object.keys(validationErrors).length === 0 && !error;
  };

  const handleTabChange = (userPermission: string | undefined) => {
    setActiveTab(userPermission);
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (
      validation("roleName", roleData.roleName, true) &&
      (Object.keys(errors).length === 0 || errors.roleName === undefined)
    ) {
      if (id) {
        roleData.type = "update";
        roleData.roleId = id;
      }
      const response = await ApiCall.service(CREATE_ROLE, "POST", {
        data: roleData,
        designations: designations,
      });
      if (response.status === 200) {
        navigate("/manage-roles");
        CustomNotify({ type: "success", message: t(response.msg) });
      } else {
        CustomNotify({ type: "error", message: t(response.msg) });
      }
    }
    setLoading(false);
  };

  const handleRoleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setRoleData((prevRoleData) => ({
      ...prevRoleData,
      roleName: value,
    }));

    validation(name, value, true);
  };

  const handleDesignationChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setDesignationError({});
    setCurrentDesignation((prevData) => ({
      ...prevData,
      name: value,
    }));

    validation(name, value, true);
  };

  const buttonText: string = id ? t("Update role") : t("Create role");

  const addDesignation = () => {
    const searchLower = currentDesignation.name.toLowerCase(); // Convert the search string to lowercase

    // Search for the string within the array
    const foundItem = designations.find(
      (item) => item.name.toLowerCase() === searchLower
    );

    if (
      foundItem &&
      currentDesignation.name !== "" &&
      currentDesignation.id !== foundItem.id
    ) {
      setDesignationError((prevErrors) => ({
        ...prevErrors,
        ["designation"]: t("Entered designation is alredy exist"),
      }));
    } else {
      if (
        currentDesignation.index !== null &&
        currentDesignation.index !== undefined
      ) {
        if (currentDesignation.name !== "") {
          const updatedDesignations = [...designations];
          updatedDesignations.splice(
            currentDesignation.index,
            1,
            currentDesignation
          );
          setDesignations(updatedDesignations);
          setCurrentDesignation({ id: null, name: "", index: null });
        } else {
          setDesignationError((prevErrors) => ({
            ...prevErrors,
            ["designation"]: t("Please enter name"),
          }));
        }
      } else {
        if (currentDesignation.name !== "") {
          const newDesignation = {
            ...currentDesignation,
            index: designations.length,
          };

          setCurrentDesignation(newDesignation);
          setDesignations([...designations, newDesignation]);
          setCurrentDesignation({ id: null, name: "", index: null });
        } else {
          setDesignationError((prevErrors) => ({
            ...prevErrors,
            ["designation"]: t("Please enter name"),
          }));
        }
      }
    }
  };

  const handleDeleteDesignation = (indexToDelete: number) => {
    setDesignations((prevDesignations) => {
      const updatedDesignations = [...prevDesignations];
      updatedDesignations.splice(indexToDelete, 1);
      return updatedDesignations;
    });
  };

  const handleGetDesignation = (index: number) => {
    const { id, name } = { ...designations[index] };
    setCurrentDesignation({ id: id, name: name, index: index });
  };

  let permissionCrud = data?.permissionCred;
  if (activeTab === "Dashboard") {
    permissionCrud = permissionCrud?.filter(
      (crud: any) => crud.unique_key === "read"
    );
  }

  const permissionType = id ? "update" : "create";
  const permissionObject: any = {
    permission: "Roles",
  };
  permissionObject[permissionType] = true;
  return (
    <AccessControl
      requiredPermissions={[permissionObject]}
      renderNoAccess={true}
    >
      {data && (
        <>
          <Title title={t(`${id ? t("Update") : t("Create")} ${t("role")}`)} />
          <div className="create-role-height">
            <div className="row">
              <div className="col-6">
                <LabelWithInputField
                  name="roleName"
                  type="text"
                  value={roleData.roleName}
                  handleChange={handleRoleNameChange}
                  // placeholder={t("Role name")}
                  label={t("Role name")}
                  error={errors.roleName}
                />
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="col-lg-6 col-md-8">
                    <LabelWithInputField
                      name="designation"
                      type="text"
                      value={currentDesignation?.name}
                      handleChange={handleDesignationChange}
                      label={t("Designation name")}
                      error={designationError.designation}
                    />
                  </div>
                  <div className="col-lg-4 col-md-4 mt-34">
                    <Button
                      title={t("Add")}
                      handleClick={addDesignation}
                      className="form-button shadow-none"
                    />
                  </div>
                </div>
              </div>

              {designations.length > 0 && (
                <div className="col-12 marginBotttom1">
                  <DesignationTable
                    data={designations}
                    changeHandler={handleGetDesignation}
                    deleteHandler={handleDeleteDesignation}
                  />
                </div>
              )}
              <div className="col-12 marginBotttom1">
                <Tabs
                  data={data}
                  activeTab={activeTab}
                  onTabChange={handleTabChange}
                />
              </div>
            </div>
            <div className="position-relative mt-3">
              <div className="row">
                <div className="col-md-12">
                  <div className="tab-content create-roles tableSection">
                    {activeTab &&
                      data.permissionsWithClassifications[activeTab] && (
                        <div className={`tab-pane active pwa`}>
                          <table className="table table-hover">
                            <thead>
                              <tr className="border-0 TableHeader">
                                <th className="border-0"></th>
                                {permissionCrud?.map((cred) => (
                                  <th
                                    key={cred.unique_key}
                                    className="border-0"
                                  >
                                    {t(`${cred.crud_name}`)}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {Object.entries(
                                data.permissionsWithClassifications[activeTab]
                                  .permissions
                              ).map(([permissionId, permissionName]) => (
                                <tr
                                  key={permissionId}
                                  className="border-bottom mb-3 box-shadow"
                                >
                                  <td className="text-break px-2 pwa-permission ">
                                    {t(`${permissionName}`)}
                                  </td>
                                  {permissionCrud?.map((cred) => (
                                    <td
                                      key={cred.unique_key}
                                      data-label={cred.crud_name}
                                    >
                                      <Checkbox
                                        classification={activeTab}
                                        userPermission={permissionId}
                                        action={cred.unique_key}
                                        checked={
                                          roleData.action[permissionId]?.[
                                            cred.unique_key
                                          ] || false
                                        }
                                        onChange={handleCheckboxChange}
                                      />
                                    </td>
                                  ))}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
            {errors.action && (
              <div className="text-danger">{errors.action}</div>
            )}
          </div>
          {/* Desktop */}
          <div className="row">
            <div className="col-md-4 align-self-center">
              <Link
                to="/manage-roles"
                className=" back-btn text-decoration-underline"
              >
                {t("Back")}
              </Link>
            </div>
            <div className="col-md-8">
              {!loading ? (
                <Button
                  title={buttonText}
                  className="btn form-button rounded-3 px-3 float-end "
                  handleClick={handleSubmit}
                />
              ) : (
                <LoadingIcon
                  iconType="bars"
                  color="#ff4dae"
                  className="float-end"
                  height={"3vw"}
                  width={"3vw"}
                />
              )}
            </div>
          </div>
          {message && <div className="col-md-12">{message}</div>}
        </>
      )}
    </AccessControl>
  );
};

export default translate(RolePage);
