import React, { useState, useEffect } from "react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import EditIcon from "../../../../static/images/EditIcon";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import ModalPopup from "components/common/atoms/ModalPopup";
import { ApiCall } from "components/common/services/ApiServices";
import CustomNotify from "components/common/atoms/CustomNotify";
import { CLOSE_FOLLOW_UP, GET_FOLLOW_UPS } from "routes/ApiEndpoints";
import Title from "components/common/atoms/Title";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import LinkTo from "components/common/atoms/LinkTo";
import * as CONST from "../../../../Constants";
import { useSelector } from "react-redux";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import { Option, OptionProps } from "components/common/utlis/TypeAnnotations";
import {
  createQueryParams,
  filterAndMerge,
  getQueryParamsFromRedux,
} from "components/common/utlis/HelperFunctions";
import {
  clearDestinationPath,
  setDestinationPath,
} from "features/NavigationSlice";
import { useDispatch } from "react-redux";
import { RootState } from "store";
import SearchFields from "components/common/atoms/SearchFields";
import { createFilterConfig } from "./Filters";
import ResetBtn from "components/common/atoms/ResetBtn";
import { FollowUpData } from "../TaskAnnotations";
import CloseFile from "static/images/CloseFile";
import PaginationNew from "components/common/atoms/PaginationNew";
import Sorting from "components/common/atoms/Sorting";
import TextEllipsis from "components/common/atoms/TextEllipsis";
import SpinnerWrapper from "components/common/atoms/SpinnerWrapper";
import NoRecords from "components/common/atoms/NoRecords";

interface SearchProps {
  taskType: OptionProps[];
  office: number[];
  company: string;
  candidate: string;
  vacancy: string;
  taskStart: Date | string | null;
  taskEnd: Date | string | null;
  followUpStart: Date | string | null;
  followUpEnd: Date | string | null;
  createdBy: Option | null;
  followedBy: Option | null;
  userIds: number[];
  currentPage: number;
  recordsPerPage: number;
  orderByColumn: string;
  orderIn: "" | "asc" | "desc";
  fromDashboard: boolean;
  isRecruiter: boolean;
}

export const ManageFollowUps: React.FC = () => {
  const dispatch = useDispatch();
  const [deleteID, setDeleteId] = useState<string | undefined>();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [followUps, setFollowUps] = useState<FollowUpData[]>([]);
  const [paginationData, setPaginationData] = useState({
    totalPages: 1,
    totalRecords: 1,
    cursor: null,
  });
  const [dropdownList, setDropdownList] = useState({
    offices: [],
    taskTypes: [],
  });
  const destinationPath = useSelector(
    (state: RootState) => state.navigation.destinationPath
  );
  const queryParams = getQueryParamsFromRedux(destinationPath);
  const filters = createFilterConfig(dropdownList);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  // Initialize search data based on query params
  const defaultSearchData: SearchProps = {
    taskType: [],
    office: [],
    userIds: [],
    company: "",
    candidate: "",
    vacancy: "",
    taskStart: "",
    taskEnd: "",
    followUpStart: "",
    followUpEnd: "",
    createdBy: null,
    followedBy: null,
    currentPage: 1,
    recordsPerPage: 10,
    orderByColumn: "",
    orderIn: "",
    fromDashboard: false,
    isRecruiter: false
  };

  //advanced search
  const defaultAdvSearchData = {
    street: "",
  };

  //active tab status
  const defaultTabData = {
    tabFieldName: "companyType",
    tabFieldValue: null,
  };

  const filteredSearchData = filterAndMerge(defaultSearchData, queryParams);
  const filteredAdvSearchData = filterAndMerge(
    defaultAdvSearchData,
    queryParams
  );
  const filteredTabData = filterAndMerge(defaultTabData, queryParams);

  // Initialize search data based on query params, merging with default values
  const [searchData, setSearchData] = useState<SearchProps>({
    ...defaultSearchData,
    ...filteredSearchData,
  });

  const [advSearchData, setAdvSearchData] = useState({
    ...defaultAdvSearchData,
    ...filteredAdvSearchData,
  });

  const [activeTab, setActiveTab] = useState({
    ...defaultTabData,
    ...filteredTabData,
  });

  useEffect(() => {
    const filterData = { ...searchData, ...advSearchData, ...activeTab };
    const searchUrl = createQueryParams(filterData);
    dispatch(setDestinationPath(searchUrl));
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      fetchDetails(filterData);
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [searchData]);

  const fetchDetails = async (filterData: any) => {
    setLoading(true);
    const data = {
      search: filterData,
    };

    const statusParam = searchParams.get("status");
    const response = await ApiCall.service(
      `${GET_FOLLOW_UPS}/${statusParam}`,
      "POST",
      data,
      false,
      CONST.CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );

    if (response.status === 200) {
      const { followUpsData, dropdownData, paginationData } = response.data;

      setFollowUps(followUpsData);
      setPaginationData(paginationData);
      setDropdownList(dropdownData);
    }
    setLoading(false);
  };

  //** change handlers */
  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: 1,
      fromDashboard: false,
      [name]: value,
    }));
  };

  const handlePageChange = (pageNumber: number) => {
    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: pageNumber,
    }));
  };

  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: 1,
      fromDashboard: false,
      [fieldName]: selectedOption,
    }));
  };

  const handleAutoSelectChange = (selectedOption: any, fieldName: string) => {
    // const differenceOption = selectedOption.filter(
    //     (item1: Option) => !searchData.company.some(item2 => item1.value === item2.value)
    //   );
    // if (searchData.company.length === 0) {
    //     setSearchData((prevSearch) => ({
    //         ...prevSearch,
    //         currentPage: 1,
    //         [fieldName]: selectedOption,
    //     }));
    // } else if (differenceOption) {
    //     setSearchData((prevSearch) => ({
    //         ...prevSearch,
    //         currentPage: 1,
    //         [fieldName]: differenceOption,
    //     }));
    // }
  };

  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    setSearchData((prev) => ({
      ...prev,
      recordsPerPage: newItemsPerPage,
      currentPage: 1,
    }));
  };

  //** search filters */
  const handleReset = async (e: React.FormEvent) => {
    dispatch(clearDestinationPath());
    setSearchData(defaultSearchData);
    setAdvSearchData(defaultAdvSearchData);
    setActiveTab(defaultTabData);
  };

  const handleDateChange = (date: Date | null, fieldName: string) => {
    setSearchData((prevSearch) => ({
      ...prevSearch,
      fromDashboard: false,
      [fieldName]: date,
    }));
  };

  const handleModalClose = () => setShowModal(!showModal);
  const confirmationPopup = (id: string) => {
    setDeleteId(id);
    setShowModal(true);
  };

  const handleAddFeedback = async () => {
    setShowModal(false);
    navigate(`/edit/follow-up/${deleteID}?close=1`);
  };

  const handleFollowUpClose = async () => {
    if (deleteID) {
      const requestData = {
        id: deleteID,
      };

      const response = await ApiCall.service(
        CLOSE_FOLLOW_UP,
        "POST",
        requestData,
        true,
        CONST.CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );

      if (response.status === 200) {
        setFollowUps((prevItems) =>
          prevItems.map((item) =>
            item.id === deleteID ? { ...item, completed: true } : item
          )
        );
        CustomNotify({ type: "success", message: response.msg });
      }
      setDeleteId(undefined);
      setShowModal(false);
    }
  };

  const handleOrderByColumn = (column: string) => {
    let newOrder: "" | "asc" | "desc" = ""; // Explicit type declaration
    let newColumn = column;

    if (searchData.orderByColumn === column) {
      // Cycle through the three possible order values: '', 'asc', 'desc'
      if (searchData.orderIn === "") {
        newOrder = "asc"; // From '' to 'asc'
      } else if (searchData.orderIn === "asc") {
        newOrder = "desc"; // From 'asc' to 'desc'
      } else {
        newOrder = ""; // From 'desc' to ''
        newColumn = ""; // Reset column if no order
      }
    } else {
      // If it's a new column, start with ascending
      newOrder = "asc";
    }

    setSearchData((prevSearch) => ({
      ...prevSearch,
      currentPage: 1, // Reset pagination on sort change
      orderByColumn: newColumn, // Set the new column or empty string
      orderIn: newOrder, // Set the new order (asc, desc, or empty)
    }));
  };

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Company",
          read: true,
        },
      ]}
      renderNoAccess={true}
    >
      <Title title={t("Manage follow up")} />
      <div className="row">
        <div className="col-12">
          <div className="position-relative tableMainWrapper">
            <div className="row">
              <div className="ManageCreateBtn">
                <AccessControl
                  requiredPermissions={[
                    {
                      permission: "Follow-up",
                      create: true,
                    },
                  ]}
                >
                  <LinkTo
                    pagelink={"/add/follow-up"}
                    title={t("Create follow up")}
                    icon={faPlus}
                  />
                </AccessControl>
              </div>
            </div>
            <div className="table-responsive tableSection">
              <table className="table border-0 mb-0 manageFollowup">
                <thead>
                  <tr className="tableSearchFields">
                    <SearchFields
                      searchData={searchData}
                      filters={filters}
                      handleSearchChange={handleSearchChange}
                      handleSelectChange={handleSelectChange}
                      handleAutoSelectChange={handleAutoSelectChange}
                      handleDateChange={handleDateChange}
                    />
                    <th className="border-0">
                      <div className="d-flex align-items-center w-100">
                        <ResetBtn handleResetClick={handleReset} />
                      </div>
                    </th>
                  </tr>
                </thead>
              </table>
              <div className="tableContainer scrollBarDesign">
                <table className="table table-hover manageFollowup">
                  <thead>
                    <tr className="TableHeader">
                      <th onClick={(e) => handleOrderByColumn("office")}>
                        {t("Office")}
                        <Sorting
                          sortType={
                            searchData.orderByColumn === "office"
                              ? searchData.orderIn
                              : ""
                          }
                        />
                      </th>
                      <th onClick={(e) => handleOrderByColumn("taskDate")}>
                        <div className="d-flex">
                          <TextEllipsis
                            label={t("Time of follow-up")}
                            title={t("Time of follow-up")}
                            width="8vw"
                          />
                          <Sorting
                            sortType={
                              searchData.orderByColumn === "taskDate"
                                ? searchData.orderIn
                                : ""
                            }
                          />
                        </div>
                      </th>
                      <th onClick={(e) => handleOrderByColumn("createdBy")}>
                        {t("Created by")}
                        <Sorting
                          sortType={
                            searchData.orderByColumn === "createdBy"
                              ? searchData.orderIn
                              : ""
                          }
                        />
                      </th>
                      <th
                        onClick={(e) => handleOrderByColumn("task_type.name")}
                      >
                        <div className="d-flex">
                          <TextEllipsis
                            label={t("Follow up type")}
                            title={t("Follow up type")}
                            width="5vw"
                          />

                          <Sorting
                            sortType={
                              searchData.orderByColumn === "task_type.name"
                                ? searchData.orderIn
                                : ""
                            }
                          />
                        </div>
                      </th>
                      <th>{t("Company")}</th>
                      <th>{t("Candidate")}</th>
                      <th>{t("Vacancy / Function")}</th>
                      <th onClick={(e) => handleOrderByColumn("followDate")}>
                        {t("Next follow-up")}
                        <Sorting
                          sortType={
                            searchData.orderByColumn === "followDate"
                              ? searchData.orderIn
                              : ""
                          }
                        />
                      </th>
                      <th onClick={(e) => handleOrderByColumn("followedBy")}>
                        <div className="d-flex">
                          <TextEllipsis
                            label={t("To be followed by")}
                            title={t("To be followed by")}
                            width="7vw"
                          />
                          <Sorting
                            sortType={
                              searchData.orderByColumn === "followedBy"
                                ? searchData.orderIn
                                : ""
                            }
                          />
                        </div>
                      </th>
                      <AccessControl
                        requiredPermissions={[
                          {
                            permission: "Follow-up",
                            update: true,
                          },
                        ]}
                        actions={true}
                      >
                        <th>{t("Action")}</th>
                      </AccessControl>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <SpinnerWrapper headerLength={10} />
                    ) : (
                      <>
                        {followUps && followUps.length > 0 ? (
                          followUps.map((followUp, index) => (
                            <tr className="align-middle" key={index}>
                              <td className="text-break">{followUp.office}</td>
                              <td className="text-break">{followUp.time}</td>
                              <td className="text-break">
                                {followUp.createdby}
                              </td>
                              <td className="text-break">
                                <TextEllipsis
                                  label={t(`${followUp.taskType}`)}
                                  title={t(`${followUp.taskType}`)}
                                  width="6vw"
                                />
                              </td>
                              <td className="text-break">
                                <Link
                                  to={`/company/${followUp.company?.id}`}
                                  target="_blank"
                                >
                                  {followUp.company?.name}
                                </Link>
                              </td>
                              <td className="text-break">
                                <Link
                                  to={`/candidate/edit/${followUp.candidate?.id}`}
                                  target="_blank"
                                >
                                  {followUp.candidate?.name}
                                </Link>
                              </td>
                              <td className="text-break">
                                <Link
                                  to={`/vacancy/${followUp.vacancy?.id}`}
                                  target="_blank"
                                >
                                  {followUp.vacancy?.name}
                                </Link>
                              </td>
                              <td className="text-break">
                                {followUp.nextfollowup}
                              </td>
                              <td className="text-break">
                                <TextEllipsis
                                  label={followUp.followedby}
                                  title={followUp.followedby}
                                  width="7vw"
                                />
                              </td>
                              <AccessControl
                                requiredPermissions={[
                                  {
                                    permission: "Follow-up",
                                    update: true,
                                  },
                                ]}
                              >
                                <td className="table-action-icons">
                                  <Link
                                    to={`/edit/follow-up/${followUp.id}?destination=/manage/follow-up`}
                                    title={t("Edit")}
                                    className="marginRightPoint5 cursor-pointer"
                                  >
                                    <EditIcon />
                                  </Link>
                                  {/* <Link
                                                                  to={`/view/follow-up/${followUp.id}`}
                                                                  className="border-0 me-2"
                                                                  title={t("View")}
                                                                >
                                                                  <ViewIcon />
                                                                </Link> */}
                                  {followUp.hasChild && !followUp.status && (
                                    <span
                                      className="border-0 cursor-pointer"
                                      title={t("Close")}
                                      onClick={(e) =>
                                        confirmationPopup(followUp.id)
                                      }
                                    >
                                      <CloseFile />
                                    </span>
                                  )}
                                </td>
                              </AccessControl>
                            </tr>
                          ))
                        ) : (
                          <NoRecords
                            headerLength={10}
                            message={t("No follow-ups found")}
                          />
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              {!loading && (
                <div className="pagination d-block">
                  <PaginationNew
                    currentPage={searchData.currentPage}
                    totalPages={paginationData.totalPages}
                    totalRecords={paginationData.totalRecords}
                    recordsPerPage={searchData.recordsPerPage}
                    onItemsPerPageChange={handleItemsPerPageChange}
                    onPageChange={(newPage: any) => handlePageChange(newPage)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6 align-self-center" style={{ padding: "1vw 0" }}>
        <Link to="/dashboard" className=" back-btn text-decoration-underline">
          {t("Back")}
        </Link>
      </div>
      <ModalPopup
        show={showModal}
        onHide={handleModalClose}
        title={t("Feedback")}
        body={t("Do you want to add feedback") + "?"}
        onCloseButtonClick={handleFollowUpClose}
        onConfirmButtonClick={handleAddFeedback}
        className="modal-lg"
        confirmTitle={t("Yes")}
        closeTitle={t("No")}
      />
    </AccessControl>
  );
};
export default translate(ManageFollowUps);
