import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { faMagnifyingGlass, faPlus } from "@fortawesome/free-solid-svg-icons";
import EditIcon from "../../../../static/images/EditIcon";
import { Link } from "react-router-dom";
import Button from "components/common/atoms/Button";
import { ApiCall } from "components/common/services/ApiServices";
import CustomNotify from "components/common/atoms/CustomNotify";
import { CONF_SETTINGS_GET } from "routes/ApiEndpoints";
import Pagination from "components/common/atoms/Pagination";
import Title from "components/common/atoms/Title";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import Reset from "static/images/Reset";
import LinkTo from "components/common/atoms/LinkTo";
import * as CONST from "../../../../Constants";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import { OptionProps } from "components/common/utlis/TypeAnnotations";
import { mapToSelect } from "components/common/utlis/MapToSelect";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import ResetBtn from "components/common/atoms/ResetBtn";
import SpinnerWrapper from "components/common/atoms/SpinnerWrapper";
import NoRecords from "components/common/atoms/NoRecords";

export interface OfficeData {
  id?: number;
  name: string;
}

export const ManageOffice: React.FC = () => {
  const itemsPerPage = 10;
  const [loading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState({
    office: [] as OptionProps[],
  });
  const [dropDown, setDropdown] = useState({
    offices: [] as OptionProps[],
  });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [offices, setOffices] = useState<OfficeData[]>([]);

  const handleSearch = async (e: React.FormEvent) => {
    e.preventDefault();
    if (searchData.office.length === 0) {
      CustomNotify({
        type: "error",
        message: t("Search input not provided"),
      });
      fetchOfficeDetails();
    } else {
      fetchOfficeDetails(searchData, 1);
      setCurrentPage(1);
    }
  };

  const handleReset = async (e: React.FormEvent) => {
    setSearchData({
      office: [],
    });
    setCurrentPage(1);
    fetchOfficeDetails();
  };

  useEffect(() => {
    fetchOfficeDetails();
  }, []);

  const fetchOfficeDetails = async (searchData = {}, page = 1) => {
    setLoading(true);
    const data = {
      page: page,
      nOR: itemsPerPage,
      search: searchData,
      type: "office",
    };
    const response = await ApiCall.service(
      CONF_SETTINGS_GET,
      "POST",
      data,
      true,
      CONST.CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );
    if (response.status === 200) {
      setDropdown((prev) => ({
        ...prev,
        offices: mapToSelect(response.data.offices),
      }));
      setOffices(response.data.data);
      setTotalPages(response.data.totalPages);
    }
    setLoading(false);
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    fetchOfficeDetails(searchData, pageNumber);
  };

  //changehandler for search fields
  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    setSearchData((prevSearch) => ({
      ...prevSearch,
      [fieldName]: selectedOption,
    }));
  };

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Office cities",
          read: true,
        },
      ]}
      renderNoAccess={true}
    >
      <Title title={t("Offices")} />
      <div className="row search-bar">
        <div className="col-6">
          <SelectWithSearch
            search={true}
            options={dropDown.offices}
            title={t("Office")}
            onChange={(e) => handleSelectChange(e, "office")}
            isMulti={true}
            name="office"
            value={searchData.office}
            isTranslate={true}
          />
        </div>
        <div className="col-6 mt-34">
          <div className="d-flex align-items-center">
            <ResetBtn handleResetClick={handleReset} />
            <Button
              title={t("Search")}
              type="submit"
              handleClick={handleSearch}
              icon={faMagnifyingGlass}
              className="form-button shadow-none search-btns position-relative text-start"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="position-relative tableMainWrapper">
            <div className="row">
              <div className="ManageCreateBtn">
                <AccessControl
                  requiredPermissions={[
                    {
                      permission: "Office cities",
                      create: true,
                    },
                  ]}
                >
                  <LinkTo
                    pagelink="/office"
                    title={t("Create office")}
                    icon={faPlus}
                  />
                </AccessControl>
              </div>
            </div>
            <div className="table-responsive tableSection paddingTop2">
              <table className="table table-hover">
                <thead>
                  <tr className="TableHeader">
                    <th className="ps-lg-4" style={{ width: "60%" }}>
                      {t("Office")}
                    </th>
                    <AccessControl
                      requiredPermissions={[
                        {
                          permission: "Office cities",
                          update: true,
                          delete: true,
                        },
                      ]}
                      actions={true}
                      strict={false}
                    >
                      <th>{t("Action")}</th>
                    </AccessControl>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <SpinnerWrapper headerLength={2} />
                  ) : (
                    <>
                      {offices && offices.length > 0 ? (
                        offices.map((office, index) => {
                          return (
                            <tr
                              key={office.id}
                              className="border-bottom mb-3 box-shadow align-middle"
                            >
                              <td
                                className="text-break ps-lg-4"
                                data-label={t("Office")}
                              >
                                {office.name}
                              </td>
                              <AccessControl
                                requiredPermissions={[
                                  {
                                    permission: "Office cities",
                                    delete: true,
                                    update: true,
                                  },
                                ]}
                                actions={true}
                                strict={false}
                              >
                                <td className="table-action-icons px-2">
                                  <AccessControl
                                    requiredPermissions={[
                                      {
                                        permission: "Office cities",
                                        update: true,
                                      },
                                    ]}
                                  >
                                    <Link
                                      to={`/office/${office.id}`}
                                      title={t("Edit")}
                                    >
                                      <EditIcon />
                                    </Link>
                                  </AccessControl>
                                </td>
                              </AccessControl>
                            </tr>
                          );
                        })
                      ) : (
                        <NoRecords headerLength={2} />
                      )}
                    </>
                  )}
                </tbody>
              </table>
              {totalPages > 1 && (
                <div className="pagination justify-content-center align-items-center">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={(newPage) => handlePageChange(newPage)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row backPadding">
        <div className="col-md-6 align-self-center">
          <Link
            to="/config/settings"
            className="back-btn text-decoration-underline"
          >
            {t("Back")}
          </Link>
        </div>
      </div>
    </AccessControl>
  );
};
export default translate(ManageOffice);
