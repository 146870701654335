import React from "react";
import { useLocation, useParams } from "react-router-dom";
import {CONF_SETTINGS_EDIT, CONF_SETTINGS_ADD, CONF_SETTINGS_UPDATE } from "routes/ApiEndpoints";
import FormBuilder from "components/common/services/form/FormBuilder";
import { validateRequired } from "components/common/services/ValidationService";
import { CREATE_EXPERIENCE_LEVEL } from "routes/RouteConstants";
import { t, translate } from "components/CentralDataMangement/translation/Translation";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";


interface IdProps {
  id?: number | string;
}

const ExperienceLevel: React.FC<IdProps> = ({ id }) => {
  const location = useLocation();
  const path = location.pathname;
  const { id: routeId } = useParams<{ id: string }>();
  const FormValidation = {
    unitName: [validateRequired],
  };

  const permissionType = id ? "update" : "create";
  const permissionObject: any = {
    permission: "Experience level",
  };
  permissionObject[permissionType] = true;
  return (
    <AccessControl
      requiredPermissions={[permissionObject]}
      renderNoAccess={true}
    >
      <FormBuilder
        microService="central-data-management"
        actionType={path === CREATE_EXPERIENCE_LEVEL ? "create" : "edit"} // action type create or edit
        entryId={id || routeId} // required only if actionType is edit
        getDataAPI={CONF_SETTINGS_EDIT} //required only if actionType is edit
        SaveDataAPI={path === CREATE_EXPERIENCE_LEVEL ? CONF_SETTINGS_ADD : CONF_SETTINGS_UPDATE} //Post's data to this endpoint
        formName={"ExperienceLevel"} //Form name
        title={path === CREATE_EXPERIENCE_LEVEL ? t("Add experience level") : t("Edit experience level")} // Form title
        redirect={-1} // After submit redirect to this screen/route
        validationRules={FormValidation} //Validation rules to validate form on submit
        type='experienceLevel'
      />
    </AccessControl>
  );
};

export default translate(ExperienceLevel);
