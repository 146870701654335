import React from "react";
// Make sure the import statements are like this:
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import LabelField from "../atoms/LabelField";
import { UploadAdapterPlugin } from "../services/ckEditor/UploadAdapterPlugin";

interface LabelWithCKEditorFieldProps {
  id?:string;
  label?: string;
  name: string;
  value?: string;
  placeholder?: string;
  isDisabled?: boolean;
  isMandatory?: boolean;
  handleChange?: (event: any, editor: any) => void; // CKEditor passes the editor object too
  error?: string;
  className? :string;
}

const LabelWithCKEditorField: React.FC<LabelWithCKEditorFieldProps> = ({
  label,
  id,
  name,
  value,
  placeholder = "",
  isDisabled = false,
  isMandatory,
  handleChange,
  error,
  className,
}) => {
  return (
    <div>
      <LabelField title={label} isMandatory={isMandatory} />
      <CKEditor
        editor={ClassicEditor}
        id={id}
        data={value}
        disabled={isDisabled}
        config={{
          placeholder: placeholder,
          extraPlugins: [UploadAdapterPlugin],
        }}
        onChange={handleChange}
      />
      <div className='height-20' style={{marginBottom: "0.5vw"}}>
        {error && <div className='text-danger'>{error}</div>}
      </div>
    </div>
  );
};

export default LabelWithCKEditorField;
