import { ApiCall } from "components/common/services/ApiServices";
import { useEffect, useState } from "react";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import { Option } from "components/common/utlis/TypeAnnotations";
import { Link, useNavigate } from "react-router-dom";
import Button from "components/common/atoms/Button";
import InputTextfield from "components/common/atoms/InputTextField";
import RadioField from "components/common/atoms/RadioField";
import CustomNotify from "components/common/atoms/CustomNotify";
import "./manageExtras.css";
import { scrollToTop } from "components/common/services/ValidationService";
import Title from "components/common/atoms/Title";
import { t, translate } from "components/CentralDataMangement/translation/Translation";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";

interface ExtraProps {
  name: string;
  priceOrCoeff: Option | null;
  min: string;
  desired: string;
  max: string;
  negotiability: number | null;
  inclusiveOption: number | null;
  applicableType: string;
  minStatus: boolean;
  desiredStatus: boolean;
  maxStatus: boolean;
}

const priceOptions = [
  { value: 0, label: t("Amount") },
  { value: 1, label: t("Coefficient") },
];

const ManageExtras: React.FC = () => {
  useEffect(() => {
    const fetchExtraInfoDetails = async () => {
      const url = `getExtraInfo`;
      const response = await ApiCall.getService(
        url,
        "GET",
        "central-data-management",
        true
      );
      if (response.status === 200) {
        setFormData(response.data);
      }
    };
    fetchExtraInfoDetails();
  }, []);

  const [formData, setFormData] = useState<ExtraProps[]>([
    {
      name: "",
      priceOrCoeff: null,
      min: "",
      max: "",
      desired: "",
      negotiability: null,
      inclusiveOption: null,
      applicableType: "",
      minStatus: false,
      desiredStatus: false,
      maxStatus: false,
    },
  ]);

  const [dirty, setDirty] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const changeHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    setDirty(true);
    const { name, value, type } = e.target as HTMLInputElement;
    const newFields: ExtraProps[] = [...formData];
    let updatedValue: string | number | null | Option;
    let updatedName: string;

    if (type === "radio" && name.startsWith("negotiability")) {
      updatedName = "negotiability";
      updatedValue = parseInt(value);
      newFields[index] = {
        ...newFields[index],
        [updatedName]: updatedValue,
      };
      setFormData(newFields);
    } else if (type === "radio" && name.startsWith("inclusiveOption")) {
      updatedName = "inclusiveOption";
      updatedValue = parseInt(value);
      newFields[index] = {
        ...newFields[index],
        [updatedName]: updatedValue,
      };
      setFormData(newFields);
    } else if (
      name === "min" ||
      name === "max" ||
      name === "desired" ||
      name === "applicableType"
    ) {
      const newValue = value.replace(/[^0-9,]/g, "");
      const regex = /^(\d{1,2})(,\d{0,4})?$/;
      let matches = regex.exec(newValue);

      if (matches || newValue === "") {
        updatedName = name;
        updatedValue = newValue;
        newFields[index] = {
          ...newFields[index],
          [updatedName]: updatedValue,
        };
        setFormData(newFields);
      }

      const currentObject = newFields[index];
      const { min, desired, max } = currentObject;
      if (
        min !== null &&
        min !== "" &&
        max !== null &&
        max !== "" &&
        parseFloat(min.replace(",", ".")) >= parseFloat(max.replace(",", "."))
      ) {
        newFields[index]["minStatus"] = true;
      } else {
        newFields[index]["minStatus"] = false;
      }

      if (
        desired !== null &&
        min !== null &&
        max !== null &&
        desired !== "" &&
        min !== "" &&
        max !== "" &&
        (parseFloat(desired.replace(",", ".")) <=
          parseFloat(min.replace(",", ".")) ||
          parseFloat(desired.replace(",", ".")) >=
            parseFloat(max.replace(",", ".")))
      ) {
        newFields[index]["desiredStatus"] = true;
      } else {
        newFields[index]["desiredStatus"] = false;
      }
      setError(hasTrueValue(newFields));
    }
  };

  const hasTrueValue = (formData: any) => {
    for (const item of formData) {
      if (item.minStatus || item.desiredStatus) {
        return true; // Return true if either minStatus or desiredStatus is true
      }
    }
    return false; // Return false if neither minStatus nor desiredStatus is true
  };

  const handleSelectChange = (
    selectedOption: any,
    fieldName: string,
    index: number
  ) => {
    setDirty(true);
    const newFormData: ExtraProps[] = [...formData];

    if (
      selectedOption.value === "" ||
      selectedOption.value === 0 ||
      selectedOption.value === 1
    ) {
      newFormData[index] = {
        ...newFormData[index],
        min: "",
        max: "",
        desired: "",
        negotiability: null,
        inclusiveOption: null,
        applicableType: "",
      };
    }
    newFormData[index] = {
      ...newFormData[index],
      [fieldName]: selectedOption,
    };
    setFormData(newFormData);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const data = formData.map((item, index) => ({
      ...item,
      extraInfoId: index + 1,
    }));
    if (!hasTrueValue(data) && dirty) {
      const response = await ApiCall.service(
        "updateExtraInfo",
        "POST",
        data,
        false,
        "central-data-management"
      );
      if (response.status === 200) {
        navigate("/config/settings");
        CustomNotify({ type: "success", message: t(response.msg) });
      }
    } else {
      scrollToTop();
      let msg = !dirty
        ? t("Please update the fields")+ ("!")
        : t("Please correct the errors as per error message shown");
      CustomNotify({ type: "error", message: msg, autoClose: 2000 });
    }
    setDirty(false);
  };

  return (
    <>
      <AccessControl
        requiredPermissions={[
          {
            permission: "Candidate",
            read: true,
          },
        ]}
        renderNoAccess={true}
      >
        <Title title={t("Extras")} />
        <form onSubmit={handleSubmit} className="form-height">
          {error && (
            <div className="text-danger error-coefficients">
              <span>
                {t(
                  "Please change the highlighted values, minimum value should be less than maximum value."
                )}
              </span>
              <br />
              <span>
                {t(
                  "The desired value should be in between minimum and maximum values."
                )}
              </span>
            </div>
          )}
          <div className="table-responsive manage-extras pwa">
            <table className="table table-hover">
              <thead>
                <tr className="TableHeader">
                  <th className="text-break ps-lg-4">{t("Title")}</th>
                  <th className="text-break">{t("Price / Coeff")}</th>
                  <th className="text-break">{t("Min")}</th>
                  <th className="text-break">{t("Desired")}</th>
                  <th className="text-break">{t("Max")}</th>
                  <th className="text-break">{t("Negotiable")}</th>
                  <th className="text-break">{t("Inclusive")}</th>
                  <th className="text-break">
                    {t("Applicable amount/coefficient")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {formData.map((info, index) => (
                  <tr
                    key={index}
                    className="align-middle border mb-3 box-shadow align-middle p-3 p-lg-0"
                  >
                    <td className="text-break ps-lg-4" data-label={t("Title")}>
                      {t(`${info.name}`)}
                    </td>
                    <td
                      className="text-break price-coeff"
                      data-label="Price / Coeff"
                    >
                      <SelectWithSearch
                        search={true}
                        options={priceOptions}
                        onChange={(e) =>
                          handleSelectChange(e, "priceOrCoeff", index)
                        }
                        isMulti={false}
                        name="priceOrCoeff"
                        value={formData[index].priceOrCoeff}
                        isMenuPlacement={index > 4 ? true : false}
                      />
                    </td>
                    <td className="text-break" data-label={t("Min")}>
                      <div
                        className={`input-group field-shadow rounded-3 ${
                          formData[index].desiredStatus
                            ? "extraBorder rounded-end-3"
                            : ""
                        }`}
                      >
                        <InputTextfield
                          name="min"
                          handleChange={(event) => changeHandler(event, index)}
                          value={formData[index].min}
                          id={`min-${index}`}
                          type="text"
                          readOnly={
                            formData[index]?.priceOrCoeff?.value !== 0 &&
                            formData[index]?.priceOrCoeff?.value !== 1
                          }
                          className={`form-control shadow-none border-0 rounded-start-3 ${
                            formData[index]?.priceOrCoeff?.value === 0
                              ? "rounded-end-0"
                              : "rounded-end-3"
                          }`}
                        />
                        {formData[index]?.priceOrCoeff?.value === 0 && (
                          <span
                            className="input-group-text rounded-start-0 rounded-end-3 field-shadow border-0"
                            id="basic-addon1"
                          >
                            €
                          </span>
                        )}
                      </div>
                    </td>
                    <td className="text-break" data-label={t("Def")}>
                      <div
                        className={`input-group field-shadow rounded-3 ${
                          formData[index].desiredStatus
                            ? "extraBorder rounded-end-3"
                            : ""
                        }`}
                      >
                        <InputTextfield
                          name="desired"
                          handleChange={(event) => changeHandler(event, index)}
                          value={formData[index].desired}
                          id={`desired-${index}`}
                          type="text"
                          readOnly={
                            formData[index]?.priceOrCoeff?.value !== 0 &&
                            formData[index]?.priceOrCoeff?.value !== 1
                          }
                          className={`form-control shadow-none border-0 rounded-start-3  ${
                            formData[index]?.priceOrCoeff?.value === 0
                              ? "rounded-end-0"
                              : "rounded-end-3"
                          }`}
                        />
                        {formData[index]?.priceOrCoeff?.value === 0 && (
                          <span
                            className="input-group-text rounded-start-0 rounded-end-3 field-shadow border-0"
                            id="basic-addon1"
                          >
                            €
                          </span>
                        )}
                      </div>
                    </td>
                    <td className="text-break" data-label={t("Max")}>
                      <div className="input-group field-shadow rounded-3">
                        <InputTextfield
                          name="max"
                          handleChange={(event) => changeHandler(event, index)}
                          value={formData[index].max}
                          id={`max-${index}`}
                          type="text"
                          readOnly={
                            formData[index]?.priceOrCoeff?.value !== 0 &&
                            formData[index]?.priceOrCoeff?.value !== 1
                          }
                          className={`form-control shadow-none border-0 rounded-start-3 ${
                            formData[index]?.priceOrCoeff?.value === 0
                              ? "rounded-end-0"
                              : "rounded-end-3"
                          }`}
                        />
                        {formData[index]?.priceOrCoeff?.value === 0 && (
                          <span
                            className="input-group-text rounded-start-0 rounded-end-3 field-shadow border-0"
                            id="basic-addon1"
                          >
                            €
                          </span>
                        )}
                      </div>
                    </td>
                    <td className="text-break" data-label={t("Nego / Non neg")}>
                      <RadioField
                        name={`negotiability-${index}`}
                        id={`negotiability-yes-${index}`}
                        value={0}
                        ischecked={formData[index].negotiability === 0}
                        handleChange={(event) => {
                          changeHandler(event, index);
                        }}
                        label={t("Yes")}
                        className="me-2"
                        disable={
                          formData[index]?.priceOrCoeff?.value !== 0 &&
                          formData[index]?.priceOrCoeff?.value !== 1
                        }
                      />
                      <RadioField
                        name={`negotiability-${index}`}
                        id={`negotiability-no-${index}`}
                        value={1}
                        ischecked={formData[index].negotiability === 1}
                        handleChange={(event) => {
                          changeHandler(event, index);
                        }}
                        label={t("No")}
                        className="ms-xxl-2"
                        disable={
                          formData[index]?.priceOrCoeff?.value !== 0 &&
                          formData[index]?.priceOrCoeff?.value !== 1
                        }
                      />
                    </td>
                    <td className="text-break" data-label={t("Inclu / Exclue")}>
                      <RadioField
                        name={`inclusiveOption-${index}`}
                        id={`inclusiveOption-yes-${index}`}
                        value={0}
                        ischecked={formData[index].inclusiveOption === 0}
                        handleChange={(event) => {
                          changeHandler(event, index);
                        }}
                        label={t("Yes")}
                        className="me-2"
                        disable={
                          formData[index]?.priceOrCoeff?.value !== 0 &&
                          formData[index]?.priceOrCoeff?.value !== 1
                        }
                      />
                      <RadioField
                        name={`inclusiveOption-${index}`}
                        id={`inclusiveOption-no-${index}`}
                        value={1}
                        ischecked={formData[index].inclusiveOption === 1}
                        handleChange={(event) => {
                          changeHandler(event, index);
                        }}
                        label={t("No")}
                        disable={
                          formData[index]?.priceOrCoeff?.value !== 0 &&
                          formData[index]?.priceOrCoeff?.value !== 1
                        }
                      />
                    </td>
                    <td
                      className="text-break"
                      data-label={t("Applicable/amount/coefficient")}
                    >
                      <div className="input-group field-shadow rounded-3">
                        <InputTextfield
                          name="applicableType"
                          handleChange={(event) => changeHandler(event, index)}
                          value={formData[index].applicableType}
                          id={`applicableType-${index}`}
                          type="text"
                          readOnly={
                            formData[index]?.priceOrCoeff?.value !== 0 &&
                            formData[index]?.priceOrCoeff?.value !== 1
                          }
                          className={`form-control shadow-none border-0 rounded-start-3 ${
                            formData[index]?.priceOrCoeff?.value === 0
                              ? "rounded-end-0"
                              : "rounded-end-3"
                          }`}
                        />
                        {formData[index]?.priceOrCoeff?.value === 0 && (
                          <span
                            className="input-group-text rounded-start-0 rounded-end-3 field-shadow border-0"
                            id="basic-addon1"
                          >
                            €
                          </span>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="row py-4">
            <div className="col-md-4 align-self-center">
              <Link
                to="/config/settings"
                className=" back-btn text-decoration-underline"
              >
                {t("Back")}
              </Link>
            </div>
            <div className="col-md-8">
              <Button
                title={t("Submit")}
                type="submit"
                className="btn form-button float-end  rounded-3 shadow-none"
              />
            </div>
          </div>
        </form>
      </AccessControl>
    </>
  );
};

export default translate(ManageExtras);
