import React, { useState } from "react";
import ModalPopup from "components/common/atoms/ModalPopup";
import Button from "components/common/atoms/Button";
import {
  validateForm,
  validateRequired,
} from "components/common/services/ValidationService";
import { ValidationRules } from "components/common/utlis/TypeAnnotations";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import { t } from "components/CentralDataMangement/translation/Translation";

interface ModalWithInputProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (formData: { [key: string]: any }) => void;
  fieldName: string;
  fieldLabel: string;
  fieldPlaceholder: string;
  formData: { [key: string]: any };
  setFormData: any;
}

const ModalWithInput: React.FC<ModalWithInputProps> = ({
  isOpen,
  onClose,
  onConfirm,
  fieldName,
  fieldLabel,
  fieldPlaceholder,
  formData,
  setFormData,
}) => {
  const initialErrors: { [key: string]: string } = {};
  const [errors, setErrors] = useState<{ [key: string]: string }>(
    initialErrors
  );

  const validation = (name: string, value: string | number | null) => {
    const validationRules: ValidationRules = {
      [name]: [validateRequired],
    };
    const validationErrors = validateForm(
      { [name]: value },
      validationRules,
      name
    );
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevData: any) => ({ ...prevData, [name]: value }));
    validation(name, value);
  };

  const onOkButtonClick = (e: React.FormEvent) => {
    e.preventDefault();
    if (validation(fieldName, formData[fieldName])) {
      onConfirm(formData);
    }
  };
  const handleClose = () => {
    setErrors({});
    onClose();
  };

  return (
    <ModalPopup
      show={isOpen}
      onHide={handleClose}
      title={fieldLabel}
      body={
        <div className="row">
          <div className="col-lg-12 mt-3">
            <LabelWithInputField
              isMandatory={true}
              name={fieldName}
              handleChange={handleFieldChange}
              value={formData[fieldName]}
              id={fieldName}
              label={fieldLabel}
              placeholder={fieldPlaceholder}
              error={errors[fieldName]}
            />
          </div>
          <div className="col-12 d-flex justify-content-end">
            <Button
              handleClick={onOkButtonClick}
              className="modal-confirm-btn shadow-none px-4"
            >
              {t("Add")}
            </Button>
          </div>
        </div>
      }
    />
  );
};

export default ModalWithInput;

/** USAGE
 <ModalWithInput
        isOpen={modal}
        onClose={closeModal}
        onConfirm={onConfirm}
        fieldName="name"
        fieldLabel={t("Cost center")}
        fieldPlaceholder={t("Cost center")}
        formData={formData}
        setFormData={setFormData}
      />
*/
