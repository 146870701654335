import React from "react";
import EditIcon from "static/images/EditIcon";
import DeleteIcon from "static/images/DeleteIcon";
import { t } from "components/CentralDataMangement/translation/Translation";

interface Column {
  key: string;
  label: string;
}

interface TableWithActionsProps {
  data: Array<{ [key: string]: any }>;
  columns: Column[];
  onEdit: (index: number) => void;
  onDelete: (index: number) => void;
}

const TableWithActions: React.FC<TableWithActionsProps> = ({
  data,
  columns,
  onEdit,
  onDelete,
}) => {
  return (
    <div className="table-responsive">
      <table className="table table-hover">
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th key={index}>{t(column.label)}</th>
            ))}
            <th>{t("Actions")}</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              {columns.map((column, colIndex) => (
                <td key={colIndex}>{item[column.key]}</td>
              ))}
              <td className="table-action-icons">
                <span
                  onClick={() => onEdit(index)}
                  className="cursor-pointer me-2"
                  title={t("Edit")}
                >
                  <EditIcon />
                </span>
                <span
                  onClick={() => onDelete(index)}
                  className="cursor-pointer"
                  title={t("Delete")}
                >
                  <DeleteIcon />
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableWithActions;

/* USAGE ***
 <TableWithActions
    data={formData} //  formData = [{id:1,name:"aaa"},{id:2,name:"bbb"}...]
    onEdit={editData}
    onDelete={removeFieldSet}
    columns={[{ key: "id", label: "Id" },{ key: "name", label: "Cost center" }]} //for multiple columns
 />
 */
