import { t } from "components/CentralDataMangement/translation/Translation";

export const initialState = {
  tabDetails: [
    {
      id: "general",
      title: t("General and work agreements"),
      showStatus: false,
      error: false,
      draft: false,
    },
    {
      id: "contacts",
      title: t("Encoding User"),
      showStatus: false,
      error: false,
      draft: false,
    },
    {
      id: "shiftTables",
      title: t("Time table"),
      showStatus: false,
      error: false,
      draft: false,
    },
    {
      id: "wage",
      title: t("Wage and premiums"),
      showStatus: false,
      error: false,
      draft: false,
    },
  ],
  general: {
    company: "",
    companyId: null,
    vatNumber: "",
    paritairBlue: [],
    paritairWhite: [],
    nssoCatg: [],
    encodage: null,
    syndicatedDelig: null,
    vca: null,
    isContracts: null,
    isContractsPerSem: null,
    largeFlex: false,
    smallFlex: false,
    other: false,
    otherExtraInfo: "",
    // restFreeTake: null,
    // restCollectivePlaced: null,
    // restCombination: null,
    // leaveFreeTake: null,
    // leaveCollectivePlaced: null,
    // leaveCombination: null,
    catchUpRest: null,
    leaves: null,
    explicitRegime: null,
    exemptionBV: [],
    extraInfo: "",
    isKBApplicable: null,
    // KBData: [
    //   {
    //     hours: "",
    //     percentage: "",
    //   },
    // ],
    electronicInvoice: false,
    electronicInvoiceMail: "",
    electronicContract: false,
    electronicContractMail: "",
    // performanceSheet: false,
    // performanceSheetMail: "",
    reminderMail: "",
    status: null,
  },
  contacts: [],
  selectedContact: [],
  dynamicErrors: [
    {
      fName: "",
      lName: "",
      email: "",
      phNumber: "",
      teleNumber: "",
      dob: "",
      gsm: "",
      contact: "",
      functionTitle: "",
      linkedIn: "",
      roles: "",
      language: "",
      decisionMaker: "",
      influencer: "",
      contactCalled: "",
      contactEmailed: "",
      info: "",
      title: "",
      location: "",
    },
  ],
  shiftTables: [],
  wageAndPremiums: [],
  commProfileList: [],
  nssoCategoryList: [],
  perfCodeList: [],
  vcaList: [],
  regimeList: [],
  shiftList: [],
  autoTypeList: [],
  workRegimeList: [],
  ecoBenefitList: [],
  contactList: [],
  pcEcoAndMealValues: [],
  initialWageAndPremiums: [],
  errors: {
    syndicatedDelig: "",
    vca: "",
    nssoCatg: "",
    catchUpRest: "",
    leaves: "",
    explicitRegime: "",
    exemptionBV: "",
    isKBApplicable: "",
    selectedContact: "",
  },
};
