import React from 'react';

const ReportsIcon = () => {
    return (
        <>
            {/* <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 54 70">
        <path id="My_todo_" data-name="My todo " d="M52,70H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0H52a2,2,0,0,1,2,2V68A2,2,0,0,1,52,70ZM8,45.634a1.114,1.114,0,0,0-1.114,1.111v6.664A1.114,1.114,0,0,0,8,54.519h6.682A1.113,1.113,0,0,0,15.8,53.409V46.745a1.113,1.113,0,0,0-1.113-1.111ZM21.06,52.5v1.616h16.2V52.5Zm0-4.307v1.616H45.9V48.192ZM8,27.327a1.114,1.114,0,0,0-1.114,1.111V35.1A1.114,1.114,0,0,0,8,36.211h6.682A1.113,1.113,0,0,0,15.8,35.1V28.437a1.113,1.113,0,0,0-1.113-1.111ZM21.06,34.192v1.616h16.2V34.192Zm0-4.307V31.5H45.9V29.884ZM8,9.019A1.114,1.114,0,0,0,6.885,10.13v6.663A1.114,1.114,0,0,0,8,17.9h6.682A1.114,1.114,0,0,0,15.8,16.793V10.13a1.114,1.114,0,0,0-1.113-1.111ZM21.06,15.885V17.5h16.2V15.885Zm0-4.308v1.615H45.9V11.577Zm-9.991,40.3h-.006a.423.423,0,0,1-.3-.122L9.292,50.29a.416.416,0,0,1,.181-.711.435.435,0,0,1,.1-.013.42.42,0,0,1,.307.135l1.166,1.162,1.934-2.457,0-.005.006-.007a.419.419,0,0,1,.59-.005.416.416,0,0,1,.006.589l-2.224,2.771A.416.416,0,0,1,11.069,51.881Zm0-18.308h-.006a.419.419,0,0,1-.3-.122L9.292,31.982a.416.416,0,0,1,.181-.711.435.435,0,0,1,.1-.013.42.42,0,0,1,.307.135l1.166,1.162L12.983,30.1l0,0,.006-.008a.419.419,0,0,1,.59-.005.417.417,0,0,1,.006.589l-2.224,2.771A.419.419,0,0,1,11.069,33.574Zm0-18.308h-.006a.419.419,0,0,1-.3-.122L9.292,13.675a.416.416,0,0,1,.181-.711.435.435,0,0,1,.1-.013.42.42,0,0,1,.307.135l1.166,1.162,1.934-2.457,0,0,.006-.008a.419.419,0,0,1,.59-.005.417.417,0,0,1,.006.589l-2.224,2.771A.419.419,0,0,1,11.069,15.266Z" fill="currentColor" />
      </svg> */}
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">

                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="currentColor" stroke="none">
                    <path d="M1430 5111 c-71 -22 -127 -72 -486 -433 -340 -341 -383 -388 -398 -432 -15 -45 -16 -207 -16 -1804 0 -1275 3 -1765 11 -1792 18 -59 78 -130 139 -164 49 -28 65 -31 157 -34 l103 -4 0 -113 c0 -105 2 -116 31 -174 36 -72 97 -124 172 -146 76 -23 3168 -23 3244 0 75 22 136 74 172 146 l31 62 0 2111 0 2111 -24 50 c-34 74 -73 114 -141 146 -52 25 -72 29 -152 29 l-93 0 0 113 c0 107 -2 117 -31 176 -21 41 -46 73 -76 96 -90 69 -15 65 -1382 64 -680 0 -1247 -4 -1261 -8z m2518 -155 c15 -8 38 -26 52 -41 l25 -27 5 -1775 5 -1775 28 -24 c34 -29 56 -30 91 -3 l26 20 0 1595 0 1594 76 0 c90 0 135 -18 164 -65 20 -32 20 -40 18 -2128 l-3 -2095 -25 -27 c-55 -59 56 -55 -1645 -55 -1701 0 -1590 -4 -1645 55 -23 24 -25 35 -28 136 l-4 109 1444 2 1443 3 41 22 c67 36 101 70 
        133 135 l31 61 0 189 c0 175 -1 189 -20 208 -25 25 -71 26 -101 1 -23 -19 -24 -25 -29 -203 -6 -203 -11 -222 -75 -252 -30 -14 -190 -16 -1600 -16 -1737 0 -1612 -5 -1655 65 -20 32 -20 45 -20 1764 l0 1731 301 0 c200 0 315 4 340 12 51 15 114 69 142 122 21 39 22 53 25 359 l3 317 1215 0 c1024 0 1219 -2 1242 -14z m-2613 -573 c-3 -18 -17 -41 -32 -53 -24 -19 -39 -20 -272 -20 l-246 0 275 275 275 275 3 -222 c2 -123 0 -237 -3 -255z"/>
                    <path d="M3131 4092 c-30 -24 -31 -76 -2 -105 17 -18 37 -23 102 -27 l81 -5 -358 -277 c-351 -272 -358 -277 -380 -260 -12 9 -105 92 -208 184 -203 182
        -227 195 -297 165 -35 -15 -934 -706 -957 -735 -38 -50 19 -137 76 -116 15 6 229 167 475 358 247 191 454 345 460 342 7 -2 98 -82 202 -177 105 -94 200
        -175 213 -180 38 -15 95 -10 127 10 17 10 196 147 400 305 203 157 372 281 373 276 2 -6 -1 -48 -7 -93 -10 -75 -10 -84 7 -107 30 -40 101 -40 122 0 11
        21 50 310 50 378 0 29 -6 48 -20 62 -19 19 -33 20 -229 20 -184 0 -211 -2 -230 -18z"/>
                    <path d="M3444 3407 c-42 -25 -234 -179 -249 -200 -13 -19 -15 -95 -15 -569 l0 -548 -130 0 -130 0 0 364 0 364 -26 31 c-29 34 -83 49 -119 33 -30 -14
        -232 -171 -265 -207 l-25 -27 -3 -279 -3 -279 -124 0 -125 0 0 471 c0 526 0 522 -67 554 -51 25 -78 13 -215 -88 -165 -123 -158 -111 -158 -279 0 -134 1
        -140 25 -163 31 -32 69 -32 100 0 24 23 25 31 25 137 l1 113 61 45 c34 25 65 47 70 48 4 2 8 -186 8 -417 l0 -421 -70 0 -70 0 0 110 c0 104 -1 112 -25 135
        -31 32 -69 32 -100 0 -24 -23 -25 -31 -25 -135 l0 -110 -130 0 -130 0 0 210 c0 226 -5 252 -51 276 -63 32 -87 22 -251 -109 -140 -111 -132 -97 -136 -247
        l-4 -128 -70 -4 c-59 -3 -74 -8 -89 -27 -25 -30 -24 -76 1 -101 20 -20 33 -20 1425 -20 1392 0 1405 0 1425 20 25 25 26 71 1 101 -16 19 -30 24 -89 27 l-71
        4 -3 622 c-2 490 -6 626 -16 644 -30 52 -111 77 -158 49z m26 -747 l0 -570 -70 0 -70 0 0 520 0 520 63 49 c34 28 65 50 70 51 4 0 7 -256 7 -570z m-700
        -270 l0 -300 -70 0 -70 0 0 249 0 248 67 52 c37 28 68 51 70 51 2 0 3 -135 3 -300z m-1390 -150 l0 -150 -65 0 -65 0 0 103 0 102 62 48 c34 26 63 47 65 47
        2 0 3 -67 3 -150z"/>
                    <path d="M932 1527 c-31 -33 -29 -80 4 -106 26 -20 37 -21 429 -21 392 0 403 1 429 21 33 26 36 79 6 109 -19 19 -33 20 -433 20 l-414 0 -21 -23z" />
                    <path d="M930 1180 c-25 -25 -26 -71 -1 -101 l19 -24 415 0 c394 0 416 1 436 19 27 25 29 86 3 109 -17 15 -62 17 -435 17 -404 0 -418 -1 -437 -20z" />
                </g>
            </svg>

        </>
    )
}

export default ReportsIcon