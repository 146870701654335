import { OptionProps } from "./TypeAnnotations";


  export function mapToSelect(data: any[], selectFieldLabel:string = "name"): OptionProps[] {
    if (data.length === 0) {
      return [];
    }
    return data.map((item: any) => ({
      value: item.id,
      label: selectFieldLabel === "name" ? item.name : item[selectFieldLabel]
    }));
  }
