import {
  CONF_SETTINGS_ADD,
  CONF_SETTINGS_EDIT,
  CONF_SETTINGS_UPDATE,
} from "routes/ApiEndpoints";
import { t } from "components/CentralDataMangement/translation/Translation";
import FormBuilder from "components/common/services/form/FormBuilder";
import React from "react";
import { useParams } from "react-router-dom";
import {
  validateRequired,
  validateSelectField,
} from "components/common/services/ValidationService";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";

const Offices = () => {
  const { id } = useParams<{ id: string }>();
  const FunctionFormValidation = {
    name: [validateRequired],
    email: [validateRequired],
    unit: [validateSelectField],
    firm: [validateSelectField],
  };

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Office cities",
          create: true,
          update: true,
        },
      ]}
      actions={true}
      strict={false}
    >
      <div>
        <div className="col-md-12">
          <FormBuilder
            title={id === undefined ? t("Create office") : t("Edit office")}
            SaveDataAPI={
              id === undefined ? CONF_SETTINGS_ADD : CONF_SETTINGS_UPDATE
            }
            entryId={id}
            getDataAPI={CONF_SETTINGS_EDIT}
            formName={"Office"}
            redirect={"/manage/offices"}
            validationRules={FunctionFormValidation}
            microService={"central-data-management"}
            backButton={true}
            type="office"
          />
        </div>
      </div>
    </AccessControl>
  );
};

export default Offices;
