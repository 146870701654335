import React, { useEffect, useState } from "react";
import {
  Option,
  validateDate,
  validateForm,
  validateRequired,
  validateSelectField,
  validateTimeString,
  validateTimeStringPattern,
} from "components/common/services/ValidationService";
import Title from "components/common/atoms/Title";
import { t, translate } from "../translation/Translation";
import { ValidationRules } from "components/common/utlis/TypeAnnotations";
import Task from "./Task";
import Button from "components/common/atoms/Button";
import {
  EDIT_FOLLOW_UP,
  FETCH_COMPANY_VACANCIES,
  STORE_FOLLOW_UP,
} from "routes/ApiEndpoints";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "Constants";
import CustomNotify from "components/common/atoms/CustomNotify";
import { ApiCall } from "components/common/services/ApiServices";
import CheckBoxField from "components/common/atoms/CheckBoxField";
import { Link, useLocation, useParams } from "react-router-dom";
import { mapToSelect } from "components/common/utlis/MapToSelect";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import { useNavigate } from "react-router-dom";
import FollowUp from "./FollowUp";
import {
  ConfigData,
  FollowUpProps,
  TaskProps,
  TodoErrors,
} from "./TaskAnnotations";
import GetQueryParams from "components/common/services/GetQueryParams";
import { selectAuth } from "features/auth/AuthSlice";
import { useSelector } from "react-redux";

const Succession: React.FC = () => {
  const getCurrentTime = (): string => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    return `${hours}:${minutes}`;
  };
  const userAuth = useSelector(selectAuth);
  const initialTaskData: TaskProps = {
    id: null,
    office: null,
    date: new Date(),
    time: getCurrentTime(),
    followUpFor: null,
    regarding: null,
    taskType: null,
    info: "",
    hasChild: false,
    vacancy: null,
  };
  const initialFollowUpData: FollowUpProps = {
    id: null,
    followedBy: null,
    feedback: "",
    date: new Date(),
    time: null,
    completed: false,
    alwaysShow: false,
    duration: null,
  };
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const path = location.pathname;
  const isView = path.includes("view") || false;
  const queryParams = GetQueryParams();
  const close = queryParams.get("close");
  const [parentTodo, setParentData] = useState<TaskProps>(initialTaskData);
  const [childTodo, setChildTodo] =
    useState<FollowUpProps>(initialFollowUpData);
  const [parentTodoErrors, setParentTodoErrors] = useState<Partial<TodoErrors>>(
    {}
  );
  const [childTodoErrors, setChildTodoErrors] = useState<Partial<TodoErrors>>(
    {}
  );
  const [hasChild, setHasChild] = useState<boolean>(false);
  const navigate = useNavigate();
  const destination = queryParams.get("destination");

  const [data, setData] = useState<ConfigData>({
    offices: [],
    users: [],
    taskTypes: [],
    taskSubTypes: [],
    durations: [],
    vacancies: [],
  });

  useEffect(() => {
    fetchTaskDetails();
  }, []);

  const fetchTaskDetails = async () => {
    const data = {
      id: id,
      type: queryParams.get("type"),
      typeId: queryParams.get("id"),
      office: queryParams.get("office"),
    };

    const response = await ApiCall.service(
      EDIT_FOLLOW_UP,
      "POST",
      data,
      true,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );

    if (response.status === 200) {
      const resData = response.data;
      setData({
        offices: mapToSelect(resData?.["offices"]),
        users: resData?.["users"],
        taskTypes: resData?.["taskTypes"],
        taskSubTypes: resData?.["taskSubTypes"],
        durations: resData?.["durations"],
        vacancies: resData?.["vacancies"],
      });
      setParentData((prevData) => ({
        ...prevData,
        office: resData?.["defaultOffice"],
        followUpFor: formatFollowUpFor(resData?.navData?.entity),
      }));
      if (resData?.parentTodo) {
        const parentOffice = {
          value: resData?.parentTodo?.office?.id,
          label: resData?.parentTodo?.office?.name,
        };

        setParentData(resData?.parentTodo);
        setParentData((prevData) => ({ ...prevData, office: parentOffice }));
        setHasChild(resData?.parentTodo?.hasChild);
      }

      if (resData?.childTodo) {
        setChildTodo(resData?.childTodo);
        if (close === "1") {
          setChildTodo((prevData) => ({ ...prevData, completed: true }));
        }
      }
    }
  };

  const fetchCompanyVacancy = async (companyId: string | number) => {
    const response = await ApiCall.service(
      FETCH_COMPANY_VACANCIES,
      "POST",
      { companyId: companyId },
      true,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );

    if (response.status === 200) {
      setData((prevData) => ({ ...prevData, vacancies: response?.data ?? [] }));
    }
  };

  const formatFollowUpFor = (entity: any) => ({
    value: entity?.value ?? null,
    label: entity?.label ?? "",
    group: getGroupValue(entity?.group),
    bbright_id: entity?.bbright_id,
  });

  type GroupType = "company" | "candidate" | "vacancy" | string;

  const getGroupValue = (group?: GroupType): number | "" => {
    const groupMapping: { [key in GroupType]?: number } = {
      company: 1,
      candidate: 2,
      vacancy: 3,
    };
    return groupMapping[group as GroupType] || "";
  };

  const validateTaskField = (
    formData: TaskProps,
    name: string,
    value: string | boolean | Date | null | number | Option,
    setErrors: React.Dispatch<React.SetStateAction<TodoErrors>>,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      office: [validateSelectField],
      followUpFor: [validateSelectField],
      date: [validateDate],
      taskType: [validateSelectField],
      time: [validateTimeString, validateTimeStringPattern],
      info: [validateRequired],
      // vacancy:
      //   (formData.taskType?.value === 22 || formData.taskType?.value === 24) &&
      //   formData.regarding?.group == "1"
      //     ? [validateSelectField]
      //     : [],
    };

    const validationErrors = validateForm(
      { ...formData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation && Object.keys(validationErrors).length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const validateFollowUpField = (
    formData: FollowUpProps,
    name: string,
    value: string | boolean | Date | null | number | Option,
    setErrors: React.Dispatch<React.SetStateAction<TodoErrors>>,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      followedBy: [validateSelectField],
      date: [validateDate],
      time: [validateTimeStringPattern],
    };

    const validationErrors = validateForm(
      { ...formData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation && Object.keys(validationErrors).length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const handleTimeChange = (name: string, value: string, type: string) => {
    if (type === "task") {
      setParentData((prevData) => ({ ...prevData, [name]: value }));
      validateTaskField(parentTodo, name, value, setParentTodoErrors, true);
    } else if (type === "followUp") {
      setChildTodo((prevData) => ({ ...prevData, [name]: value }));
      validateFollowUpField(childTodo, name, value, setChildTodoErrors, true);
    }
  };

  const handleSelectChange = (
    selectedOption: any,
    fieldName: string,
    type: string
  ) => {
    if (fieldName === "followUpFor" || fieldName === "taskType") {
      setParentData((prevData) => ({
        ...prevData,
        taskType: null,
        regarding: null,
      }));
    }

    if (type === "task") {
      if (fieldName === "regarding") {
        setParentData((prevData) => ({
          ...prevData,
          [fieldName]: selectedOption,
          vacancy: null,
        }));
        if (selectedOption?.group === 1 && parentTodo.taskType?.value !== 10) {
          fetchCompanyVacancy(selectedOption?.value);
        }
      } else {
        setParentData((prevData) => ({
          ...prevData,
          [fieldName]: selectedOption,
        }));
      }
      validateTaskField(
        parentTodo,
        fieldName,
        selectedOption,
        setParentTodoErrors,
        true
      );
    } else if (type === "followUp") {
      setChildTodo((prevData) => ({
        ...prevData,
        [fieldName]: selectedOption,
      }));
    }
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >,
    type: string
  ) => {
    const { name, value } = e.target;
    if (type === "task") {
      setParentData((prevData) => ({ ...prevData, [name]: value }));
      validateTaskField(parentTodo, name, value, setParentTodoErrors, true);
    } else if (type === "followUp") {
      setChildTodo((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleDateChange = (date: Date | null, type: string) => {
    if (type === "task") {
      setParentData((prevState) => ({
        ...prevState,
        date: date,
      }));
      validateTaskField(parentTodo, "date", date, setParentTodoErrors, true);
    } else if (type === "followUp") {
      setChildTodo((prevState) => ({
        ...prevState,
        date: date,
      }));
      validateFollowUpField(childTodo, "date", date, setChildTodoErrors, true);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const validateTask = (
      todo: TaskProps,
      setErrors: React.Dispatch<React.SetStateAction<TodoErrors>>
    ): boolean => {
      let isValid = true;
      for (const key in todo) {
        if (todo.hasOwnProperty(key)) {
          const value = todo[key as keyof TaskProps];
          const valid = validateTaskField(
            todo,
            key,
            value != null ? value : "",
            setErrors
          );
          if (!valid) {
            isValid = false;
          }
        }
      }
      return isValid;
    };

    const validateFollowUp = (
      todo: FollowUpProps,
      setErrors: React.Dispatch<React.SetStateAction<TodoErrors>>
    ): boolean => {
      let isValid = true;

      for (const key in todo) {
        if (todo.hasOwnProperty(key)) {
          const value = todo[key as keyof FollowUpProps];
          const valid = validateFollowUpField(
            todo,
            key,
            value != null ? value : "",
            setErrors
          );

          if (!valid) {
            isValid = false;
          }
        }
      }
      return isValid;
    };
    const isParentValid = validateTask(parentTodo, setParentTodoErrors);
    const isChildValid = parentTodo.hasChild
      ? validateFollowUp(childTodo, setChildTodoErrors)
      : true;

    if (isParentValid && isChildValid) {
      const data = {
        parentTodo: parentTodo,
        childTodo: parentTodo.hasChild ? childTodo : null,
      };

      let response = await ApiCall.service(
        STORE_FOLLOW_UP,
        "POST",
        data,
        true,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.status === 200) {
        CustomNotify({ type: "success", message: t(response.msg) });
        navigate(destination ?? "/manage/follow-up");
      } else {
        CustomNotify({ type: "error", message: t("Something went wrong") });
      }
    }
  };

  const handleCheckBoxChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, checked } = event.target as HTMLInputElement;
    const value = checked ? 1 : 0;
    setChildTodo((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name } = event.target as HTMLInputElement;
    const user = data?.users.find(
      (item) =>
        item?.value === userAuth.userId &&
        item?.value !== undefined &&
        item?.label !== undefined
    );

    setParentData((prevData) => ({
      ...prevData,
      [name]: parentTodo.hasChild ? false : true,
    }));
    if (!parentTodo.hasChild) {
      setChildTodo(initialFollowUpData);
      setChildTodo((prevData) => ({
        ...prevData,
        followedBy: user?.value
          ? {
              value: user?.value,
              label: user?.label ?? "",
            }
          : null,
      }));
    }
  };

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Follow-up",
          read: true,
        },
      ]}
      renderNoAccess={true}
    >
      <div>
        <Title
          title={
            isView
              ? t("View follow up")
              : id === undefined
              ? t("Create follow up")
              : t("Edit follow up")
          }
        />
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="row">
            <div className="col-12">
              <div className="form-border marginBotttom1">
                <Task
                  data={data}
                  formData={parentTodo}
                  handleChange={handleChange}
                  handleSelectChange={handleSelectChange}
                  errors={parentTodoErrors}
                  handleTimeChange={handleTimeChange}
                  handleDateChange={handleDateChange}
                  type="task"
                />
              </div>
              <div className="marginBotttom1">
                <CheckBoxField
                  label={t("Add follow up")}
                  name="hasChild"
                  onChangeHandler={handleFieldChange}
                  isChecked={parentTodo.hasChild}
                  className="document-checkbox"
                  // disable={id !== undefined && hasChild}
                  id="addFollowUp"
                  lineHeight="1vw"
                />
              </div>
              {parentTodo.hasChild && (
                <div className="form-border">
                  <FollowUp
                    data={data}
                    formData={childTodo}
                    handleChange={handleChange}
                    handleSelectChange={handleSelectChange}
                    errors={childTodoErrors}
                    handleTimeChange={handleTimeChange}
                    handleDateChange={handleDateChange}
                    type="followUp"
                    handleCheckBoxChange={handleCheckBoxChange}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="row backPadding">
            <div className="col-6 align-self-center">
              <Link
                to={destination ?? "/manage/follow-up"}
                className=" back-btn text-decoration-underline"
              >
                {t("Back")}
              </Link>
            </div>
            <div className="col-6">
              <Button
                title={t("Submit")}
                type="submit"
                handleClick={(e) => handleSubmit(e)}
                className="form-button shadow-none float-end"
              />
            </div>
          </div>
        </form>
      </div>
    </AccessControl>
  );
};
export default translate(Succession);
