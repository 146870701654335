import React, { useEffect, useState } from "react";
import * as ENDPOINTS from "../../../../../routes/ApiEndpoints";
import { ApiCall } from "components/common/services/ApiServices";
import RenderInputFields from "./RenderInputFields";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Button from "components/common/atoms/Button";
import "./defaultLowCoefficient.css";
import CustomNotify from "components/common/atoms/CustomNotify";
import {
  scrollToTop,
  validateForm,
  validateSelectField,
} from "components/common/services/ValidationService";
import { mapToSelect } from "components/common/utlis/MapToSelect";
import {
  Option,
  OptionProps,
  ValidationRules,
} from "components/common/utlis/TypeAnnotations";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import { LabelWithInputFieldNoError } from "components/common/molecules/LabelWithInputFieldNoError";
import Title from "components/common/atoms/Title";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "Constants";
import LoadingIcon from "components/common/utlis/LoadingIcon";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";

const LowCoefficientTableForm = () => {
  interface RangeProfileTypes {
    coeffName: string;
    min: string | null;
    desired: string | null;
    max: string | null;
    minStatus: boolean;
    desiredStatus: boolean;
    maxStatus: boolean;
    coeffId: number | null;
  }

  interface RegionCountry {
    region: Option | null;
    country: Option | null;
  }

  interface EmployeeTypeObj {
    categotyExist: boolean;
    category: string | null;
    employeeType: string;
    selection: string | null;
    coeffType: {
      [key: number]: RangeProfileTypes;
    };
  }

  interface EmployeeCategoryType {
    [key: number]: EmployeeTypeObj;
  }

  interface InitialState {
    [key: number]: EmployeeCategoryType;
  }

  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const isEditMode = location.pathname.includes("edit");
  const navigate = useNavigate();
  const [data, setData] = useState<InitialState>({});
  const [error, setError] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dropDowns, setDropDowns] = useState({
    regionDropdown: [] as OptionProps[],
    countryDropdown: [] as OptionProps[],
  });
  const [countryRegionData, setCountryRegionData] = useState<RegionCountry>({
    region: null,
    country: null,
  });
  const initialdataErrors: { [key: string]: string } = {};
  const [dataError, setDataError] = useState<{ [key: string]: string }>(
    initialdataErrors
  );

  useEffect(() => {
    const fetchNestedCofficientsObject = async () => {
      const url = ENDPOINTS.CONF_SETTINGS_EDIT;

      const data = {
        id: id,
        type: "lowCoeffValue",
      };
      const response = await ApiCall.service(
        url,
        "POST",
        data,
        false,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );

      if (response.status === 200) {
        const countryRegionDetails = response.data["countryRegion"];
        if (countryRegionDetails) {
          setCountryRegionData((prevData) => ({
            ...prevData,
            ...countryRegionDetails,
          }));
        }

        const regions = mapToSelect(response.data["region"]);
        const countries = response.data["country"];
        setDropDowns({
          regionDropdown: regions,
          countryDropdown: countries,
        });
        setData(response.data["data"]);
      }
    };
    fetchNestedCofficientsObject();
  }, []);

  const updateCofficientObj = (
    event: React.ChangeEvent<HTMLInputElement>,
    category: number,
    empType: string,
    payType: number,
    key: string
  ) => {
    const value = event.target.value;
    const employeeType = parseInt(empType);
    const newData = { ...data };
    const newValue = value.replace(/[^0-9,]/g, "");
    const regex = /^(\d{1,2})(,\d{0,4})?$/;
    let matches = regex.exec(newValue);
    if (matches || value === "") {
      if (key === "min") {
        newData[category][employeeType]["coeffType"][payType]["min"] = newValue;
      } else if (key === "desired") {
        newData[category][employeeType]["coeffType"][payType]["desired"] =
          newValue;
      } else {
        newData[category][employeeType]["coeffType"][payType]["max"] = newValue;
      }
    }

    const currentObject = newData[category][employeeType]["coeffType"][payType];
    const { min, desired, max } = currentObject;
    if (
      min !== null &&
      min !== "" &&
      max !== null &&
      max !== "" &&
      parseFloat(min.replace(",", ".")) >= parseFloat(max.replace(",", "."))
    ) {
      newData[category][employeeType]["coeffType"][payType]["minStatus"] = true;
      setError(true);
    } else {
      newData[category][employeeType]["coeffType"][payType]["minStatus"] =
        false;
      setError(false);
    }
    if (
      desired !== null &&
      min !== null &&
      max !== null &&
      desired !== "" &&
      min !== "" &&
      max !== "" &&
      (parseFloat(desired.replace(",", ".")) <=
        parseFloat(min.replace(",", ".")) ||
        parseFloat(desired.replace(",", ".")) >=
          parseFloat(max.replace(",", ".")))
    ) {
      newData[category][employeeType]["coeffType"][payType]["desiredStatus"] =
        true;
      setError(true);
    } else {
      newData[category][employeeType]["coeffType"][payType]["desiredStatus"] =
        false;
      setError(false);
    }
    setData(newData);
  };

  const updateSelection = (
    event: React.ChangeEvent<HTMLInputElement>,
    cat: number,
    empType: string
  ) => {
    const value = event.target.value;
    const empTyp = parseInt(empType);
    const newData = { ...data };
    const newValue = value.replace(/[^0-9,]/g, "");
    const regex = /^(\d{1,2})(,\d{0,4})?$/;
    let matches = regex.exec(newValue);
    if (matches || value === "") {
      newData[cat][empTyp]["selection"] = newValue;
    }
    setData(newData);
  };

  const dropDownsValidation = (
    name: string,
    value: string | Option | null,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      region: [validateSelectField],
      country: [validateSelectField],
    };

    const validationErrors = validateForm(
      { ...countryRegionData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation && Object.keys(error).length > 0) {
      setDataError((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setDataError(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }

    return true;
  };

  const hasTrueValue = (obj: any) => {
    for (const key in obj) {
      if (typeof obj[key] === "object") {
        if (hasTrueValue(obj[key])) {
          return true;
        } else if (
          key === "min" ||
          key === "desired" ||
          key === "max" ||
          key === "selection"
        ) {
          if (obj[key] === null) {
            return true;
          }
        }
      } else if (
        key === "minStatus" ||
        key === "desiredStatus" ||
        key === "maxStatus"
      ) {
        if (obj[key] === true) {
          return true;
        }
      }
    }
    return false;
  };

  //fetching countries list based on region
  const getCountriesForRegion = () => {
    if (selectedRegion) {
      const regionBasedCountries = dropDowns.countryDropdown.filter(
        (country) =>
          country.region_id !== undefined &&
          selectedRegion === country.region_id
      );
      return mapToSelect(regionBasedCountries);
    }
    return [];
  };
  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    if (fieldName === "region") {
      setSelectedRegion(selectedOption.value);
      setCountryRegionData((prevData) => ({
        ...prevData,
        country: null,
      }));
    }
    setCountryRegionData((prevData) => ({
      ...prevData,
      [fieldName]: selectedOption,
    }));

    dropDownsValidation(fieldName, selectedOption, true);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { name, value } = event.target as HTMLInputElement;
    if (dropDownsValidation(name, value)) {
      if (!hasTrueValue(data)) {
        setLoading(true);
        const payload = {
          countryRegionDetails: countryRegionData,
          coefficientValues: data,
          id: id ? id : null,
          type: "lowCoeffValue",
        };

        const url = ENDPOINTS.CONF_SETTINGS_ADD;
        const response = await ApiCall.service(
          url,
          "POST",
          payload,
          false,
          CENTRAL_DATA_MANAGEMENT_MICROSERVICE
        );
        if (response.status === 200) {
          CustomNotify({
            type: "success",
            message: t(response.msg),
            autoClose: 2000,
          });
          navigate("/manage/default/low-coefficients");
        } else if (response.status === 400) {
          Object.entries(response.errors).map(
            ([key, value]: [string, any]) => (
              setDataError({ country: value[0] }),
              CustomNotify({
                type: "error",
                message: t(value[0]),
                autoClose: 2000,
              })
            )
          );
        }
      } else {
        scrollToTop();
        setError(true);
        let msg = t("Please correct the errors as per error message shown");
        CustomNotify({ type: "error", message: msg, autoClose: 2000 });
      }
    } else {
      scrollToTop();
    }
    setLoading(false);
  };

  return (
    <>
      <AccessControl
        requiredPermissions={[
          {
            permission: "Candidate",
            read: true,
          },
        ]}
        renderNoAccess={true}
      >
        <Title
          title={`${
            isEditMode
              ? t("Edit low default coefficients")
              : t("Add low default coefficients")
          }`}
        />
        {error && (
          <div className="row my-3">
            <div className="col">
              <div className="text-danger error-coefficients">
                <span>
                  {t(
                    "Please fill all the empty fields or change the highlighted values, minimum value should be less than nice to have value."
                  )}
                </span>
                <br />
                <span>
                  {t(
                    "The desired value should be in between minimum and nice to have values."
                  )}
                </span>
              </div>
            </div>
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="row search-bar add-low-default-coefficient">
            <div className="col-md-6">
              <SelectWithSearch
                title={t("Region")}
                search={true}
                isMandatory={true}
                options={dropDowns.regionDropdown}
                isDisabled={isEditMode}
                onChange={(e) => handleSelectChange(e, "region")}
                isMulti={false}
                name="region"
                value={countryRegionData.region}
                error={dataError.region}
                isTranslate={true}
              />
            </div>
            <div className="col-md-6">
              <SelectWithSearch
                title={t("Country")}
                search={true}
                isMandatory={true}
                isDisabled={isEditMode}
                options={getCountriesForRegion()}
                onChange={(e) => handleSelectChange(e, "country")}
                isMulti={false}
                name="country"
                value={countryRegionData.country}
                error={dataError.country}
              />
            </div>
          </div>
          <div className="row defaultCoefficientHeight scrollBarDesign">
            <div className="col-lg-5 px-0 firstPart col-md-7 col-fixed">
              <table className="table table-bordered defaultCoefficientTableLeftSection mb-0">
                <thead>
                  <tr className="TableHeader" style={{ height: "5.99vw" }}>
                    <th rowSpan={2} className="text-center">
                      {t("Employee category types")}
                    </th>
                    <th
                      rowSpan={2}
                      className="text-center align-middle"
                      scope="rowgroup"
                    >
                      {t("Employee type")}
                    </th>
                  </tr>
                  <tr className="TableHeader"></tr>
                </thead>
                <tbody>
                  {data &&
                    Object.keys(data).map(
                      (key: any) =>
                        data[key] &&
                        Object.entries(data[key]).map(([key1, value]) => (
                          <tr
                            key={`emp-type-category-${key}-${key1}`}
                            className="align-middle"
                          >
                            {value["categotyExist"] && (
                              <th
                                className="text-center"
                                rowSpan={Object.keys(data[key]).length}
                                scope="rowgroup"
                              >
                                {value["category"]}
                              </th>
                            )}
                            <th
                              key={`emp-type-${key1}`}
                              scope="row"
                              className="employee-type-coefficient low-default-left-th"
                            >
                              {value["employeeType"]}
                            </th>
                          </tr>
                        ))
                    )}
                </tbody>
              </table>
            </div>
            <div className="col-lg-7 ps-0 col-scrollable col-md-5">
              <table className="table table-bordered defaultCoefficientTableRightSection low-default-right">
                <thead className="thead">
                  <tr className="TableHeader">
                    {data &&
                      Object.keys(data).map(
                        (key: any, index: number) =>
                          data[key] &&
                          Object.entries(data[key]).map(
                            ([key1, value]) =>
                              value["category"] &&
                              Object.entries(value["coeffType"]).map(
                                ([key2, value2]: [string, any]) =>
                                  index === 0 && (
                                    <th
                                      key={`coeffType-${key2}`}
                                      colSpan={3}
                                      className="text-center"
                                      scope="colgroup"
                                      style={{ height: "2.5vw" }}
                                    >
                                      {t(`${value2["coeffName"]}`)}
                                    </th>
                                  )
                              )
                          )
                      )}
                    <th
                      rowSpan={2}
                      className="text-center align-middle"
                      scope="rowgroup"
                      style={{ height: "5.99vw" }}
                    >
                      {t("Selection")}
                    </th>
                  </tr>
                  <tr
                    className="TableHeader border-bottom-0"
                    style={{ height: "3.45vw" }}
                  >
                    {data &&
                      Object.keys(data).map(
                        (key: any, index: number) =>
                          data[key] &&
                          Object.entries(data[key]).map(
                            ([key1, value]) =>
                              value["category"] &&
                              Object.entries(value["coeffType"]).map(
                                ([key2, value2]: [string, any]) =>
                                  index === 0 && (
                                    <React.Fragment
                                      key={`coeff-${key2}-${key1}`}
                                    >
                                      <th className="text-center">
                                        <span>{t("Min")}</span>
                                      </th>
                                      <th className="text-center">
                                        <span>{t("Desired")}</span>
                                      </th>
                                      <th className="text-center">
                                        <span>{t("Nice to have")}</span>
                                      </th>
                                    </React.Fragment>
                                  )
                              )
                          )
                      )}
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    Object.keys(data).map(
                      (key: any) =>
                        data[key] &&
                        Object.entries(data[key]).map(([key1, value]) => (
                          <tr key={`coeff-row${key}-${key1}`}>
                            {Object.entries(value.coeffType).map(
                              ([key3, value2]) => (
                                <RenderInputFields
                                  key={`emp-data-${key3}`}
                                  inputObj={value2}
                                  employeeCategoryId={key}
                                  employeeType={key1}
                                  payType={parseInt(key3)}
                                  updateCofficientObj={updateCofficientObj}
                                />
                              )
                            )}
                            <td
                              key={`selection-${key1}`}
                              className="form-group p-0"
                            >
                              <LabelWithInputFieldNoError
                                key={`label-input-${key}-${key1}`}
                                type="text"
                                className="coefficient w-100 h-100 border-0 rounded-0"
                                value={value["selection"] ?? ""}
                                handleChange={(e) =>
                                  updateSelection(e, key, key1)
                                }
                              />
                            </td>
                          </tr>
                        ))
                    )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row py-4">
            <div className="col-md-4 align-self-center">
              <Link
                to="/manage/default/low-coefficients"
                className=" back-btn text-decoration-underline"
              >
                {t("Back")}
              </Link>
            </div>
            <div className="col-md-8">
              {!loading ? (
                <Button
                  title={isEditMode ? t("Save") : t("Add")}
                  type="submit"
                  className="btn form-button float-end  rounded-3 shadow-none"
                />
              ) : (
                <LoadingIcon
                  iconType="bars"
                  color="#ff4dae"
                  className="float-end"
                  height={"3vw"}
                  width={"3vw"}
                />
              )}
            </div>
          </div>
        </form>
      </AccessControl>
    </>
  );
};

export default translate(LowCoefficientTableForm);
