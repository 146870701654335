import React, { ChangeEvent } from 'react';
import LabelField from '../atoms/LabelField';
import InputTextFieldNoError from '../atoms/InputTextFieldNoError';

interface LabelWithInputFieldProps {
  type?: string,
  className?: string,
  placeholder?: string,
  handleChange : (event: ChangeEvent<HTMLInputElement>) => void,
  label?: string,
  value?: string | number,
  name?:string,
  id?: string,
  isDisabled?: boolean,
  customStyle?: React.CSSProperties,
  isMandatory?:boolean,
  labelClassName?:string,
  min?: number,
  step?: number,
  max?:number,
  readOnly?:boolean,
  pattern?: string,
}

export const LabelWithInputFieldNoError : React.FC<LabelWithInputFieldProps> = ({
    type, 
    className = "form-control field-shadow rounded-3",
    placeholder,
    handleChange,
    label,
    value,
    name,
    id = '',
    isDisabled,
    customStyle,
    isMandatory,
    labelClassName,
    min,
    step,
    max,
    readOnly,
    pattern
}) => {
  return (
    <>
       <div className='col-md-12'>
            <LabelField
                title = {label}
                className={labelClassName}
                isMandatory={isMandatory}
                htmlfor={id}
            />
            <InputTextFieldNoError 
                id = {id}
                type = {type}
                className = {className}
                value = {value}
                isDisabled = {isDisabled}
                placeholder = {placeholder}
                customStyle = {customStyle}
                handleChange = {handleChange}
                name = {name}
                min = {min}
                step = {step}
                max = {max}
                readOnly = {readOnly}
                pattern= {pattern}
            />
        </div>
   </>
  )
}
