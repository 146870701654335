import React, { useState } from "react";
import DashboardIcon from "static/images/DashboardIcon";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import "static/css/sidebar.css";
import sideBarData from "./sidebar.json";
import RenderComponent from "dashboard/dashboardContent/RenderComponent";
import { CANDIDATE_ROLE } from "Constants";
import JobApplicationIcon from "static/images/JobApplicationIcon";

interface props {
  authData: any;
}

const Sidebar: React.FC<props> = ({ authData }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggleMenu = () => {
    setIsExpanded(!isExpanded);
  };

  const [activeMenu, setActiveMenu] = useState<string>("Dashboard");

  const handleSetActiveMenu = (menu: string) => {
    setActiveMenu(menu);
  };

  return (
    <div className={`main-menu pt-3 ${isExpanded ? "expanded" : ""}`}>
      <div>
        <div className="sidebarLogo">
          <Link to="/dashboard">
            {isExpanded ? (
              <img
                src="/static/images/logo.svg"
                alt={t("logo")}
                className="img-fluid expanded-sidebar-logo"
              />
            ) : (
              <img
                src="/static/images/AbsoluteIcon_Pink.svg"
                alt={t("logo")}
                className="img-fluid sidebar-logo"
              />
            )}
          </Link>
        </div>
        <div className="scrollbar leftMenuWrapper" id="style-1">
          <ul className="list-unstyled menuWrapper">
            <li
              className={`${
                activeMenu === "Dashboard" ? "active" : ""
              } sidebar-menu links`}
              onClick={() => handleSetActiveMenu("Dashboard")}
            >
              <Link className="" to="/dashboard">
                <div className="icon d-inline-block">
                  <div
                    className="dashboard-icon"
                    data-bs-toggle="tooltip"
                    title={t("Dashboard")}
                  >
                    <DashboardIcon />
                  </div>
                </div>
                {isExpanded && (
                  <div className="nav-text ms-3 d-inline-block">
                    {t("Dashboard")}
                  </div>
                )}
              </Link>
            </li>
            {authData.role === CANDIDATE_ROLE &&  authData.consentStatus !== 1 && authData.consentToTerms === 1 && (
              <li
                className={`${
                  activeMenu === "ConsentForm" ? "active" : ""
                } sidebar-menu links`}
                onClick={() => handleSetActiveMenu("ConsentForm")}
              >
                <Link className="" to={`/candidate-consent-form/${authData.candidateId}`}>
                  <div className="icon d-inline-block">
                    <div
                      className="dashboard-icon"
                      data-bs-toggle="tooltip"
                      title={t("Consent form")}
                    >
                      <JobApplicationIcon />
                    </div>
                  </div>
                </Link>
              </li>
            )}
            {sideBarData &&
              sideBarData.map((item: any, index: number) => {
                if (
                  authData.role === CANDIDATE_ROLE &&
                  item.permissionName === "Candidate"
                ) {
                  return null; // Skip rendering for candidates if needed
                } else {
                  return (
                    <AccessControl
                      requiredPermissions={[
                        {
                          permission: `${item.permissionName}`,
                          read: true,
                        },
                      ]}
                      key={`perm-${index}`}
                    >
                      <li
                        className={`${
                          activeMenu === item.title ? "active" : ""
                        } sidebar-menu links`}
                        key={index}
                        onClick={() => handleSetActiveMenu(item.title)}
                      >
                        <Link
                          className="d-flex align-items-center"
                          to={item.path}
                          key={`link-${index}`}
                        >
                          <div className="icon d-inline-block">
                            <div
                              className="dashboard-icon"
                              data-bs-toggle="tooltip"
                              title={t(`${item.title}`)}
                            >
                              <RenderComponent componentName={item.component} />
                            </div>
                          </div>
                          {isExpanded && (
                            <div className="nav-text ms-3 align-self-center">
                              {t(`${item.title}`)}
                            </div>
                          )}
                        </Link>
                      </li>
                    </AccessControl>
                  );
                }
              })}
          </ul>
          <ul className="list-unstyled position-absolute sidebarIcon end-0 mb-0">
            <li className="pb-3">
              <button
                onClick={handleToggleMenu}
                className="sidebar-icon d-flex align-items-center justify-content-center ms-auto me-3"
              >
                <span>
                  {isExpanded ? (
                    <FontAwesomeIcon icon={faAngleLeft} />
                  ) : (
                    <FontAwesomeIcon icon={faAngleRight} />
                  )}
                </span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default translate(Sidebar);
