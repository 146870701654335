import CompanyForm from "../create-company/formContent/CompanyForm";
import { FormProvider } from "./Context";

export const MultiFormProvider = () => {
  return (
    <>
      <FormProvider>
          <CompanyForm/>
      </FormProvider>
    </>
  );
};