import React, { useState } from "react";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import "../CandidateCreation/create-candidate/css/Candidate.css";
import { Link } from "react-router-dom";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import SalesNote from "components/CentralDataMangement/salesNote/SalesNoteModalPopups";
import { CALL_3CX } from "Constants";

export type MatchingVacancyProfileCardType = {
  companyName: string;
  website?: string | null;
  street?: string;
  number?: string;
  box?: string;
  zipCode?: string;
  city?: string;
  country?: string;
  functions: any;
  mobNumber?: string;
  email?: string;
  companyId?: number | null;
  contactId?: number | null;
  userId?: number | null;
};

export interface MatchingVacancyProfileCardProps {
  profileCardProps: MatchingVacancyProfileCardType;
}

const MatchingProfileCard: React.FC<MatchingVacancyProfileCardProps> = (
  props
) => {
  const functionList =
    props.profileCardProps.functions === undefined
      ? []
      : props.profileCardProps.functions;

  let address = [];

  if (props.profileCardProps.street) {
    address.push(props.profileCardProps.street);
  }
  if (props.profileCardProps.number) {
    address.push(props.profileCardProps.number);
  }
  if (props.profileCardProps.zipCode) {
    address.push(props.profileCardProps.zipCode);
  }

  if (props.profileCardProps.city) {
    address.push(props.profileCardProps.city);
  }

  if (props.profileCardProps.country) {
    address.push(props.profileCardProps.country);
  }

  let fullAddress = address.join(", ");

  // Sales note
  const [salesnote, setSalesNote] = useState({
    name: '',
    phone: '' as string | undefined,
    id: null,
    navigate: '',
    companyId: null,
    activeRecordingId: null as number | null,
    triggerMeeting: false as boolean,
    type: null as number | null
  });

  const handleMeeting = async (name: string, id: any | null, companyId: any, contactId?: number | null, phone?: string | null, navigate?: string) => {
    setSalesNote({
      name: name,
      phone: phone !== null ? phone : '',
      id: id,
      companyId: companyId,
      navigate: navigate ?? '',
      activeRecordingId: contactId ?? companyId,
      triggerMeeting: true,
      type: contactId ? 3 : 2 // For company contacts type is 3, candidates  is 1, companies is 2
    });
  };

  const updateSalesNote = (updatedSalesNote: any) => {
    setSalesNote(updatedSalesNote);
  };

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Candidate",
          read: true,
        },
      ]}
      renderNoAccess={true}
    >
      <div className={`px-2`}>
        <div className="row">
          <div className="col-md-12 col-lg-7">
            <div>
              <div
                className="card position-relative border-0"
                style={{
                  backgroundColor: "var(--profileBgColor)",
                  padding: "2vw",
                }}
              >
                <div className="row">
                  <div className="col-md-12 ms-auto position-relative">
                    {props.profileCardProps.companyName && (
                      <div className={`color-dark-pink name-text text-start`} style={{ marginBottom: "0.5vw" }}>
                        {props.profileCardProps.companyName}
                      </div>
                    )}
                    <div className="job-title text-start" style={{ marginBottom: "1vw" }}>
                      <span>{t("looking for ")}</span>
                      <span>
                        {functionList.length > 0 && (
                          <strong> {functionList.join(", ")}</strong>
                        )}
                      </span>
                    </div>
                    <div className="candidate-person-details">
                      {props.profileCardProps.mobNumber && (
                        <div className="d-flex align-items-center" style={{ marginBottom: "1vw" }}>
                          <a
                            onClick={() => handleMeeting(props.profileCardProps.companyName, props.profileCardProps.userId, props.profileCardProps.companyId, props.profileCardProps.contactId, props.profileCardProps.mobNumber, CALL_3CX)}
                            className="cursor-pointer link-color"
                          >
                            <img
                              src="../../static/images/mobile.png"
                              alt={t("Mobile")}
                              className="profileCardImage"
                            />
                            <span className={`ms-3`}>
                              {props.profileCardProps.mobNumber}
                            </span>
                          </a>
                        </div>
                      )}
                      {props.profileCardProps.email && (
                        <div className="d-flex align-items-center" style={{ marginBottom: "1vw" }}>
                          <a
                            rel="noreferrer"
                            href={`mailto:${props.profileCardProps.email}`}
                            target="_blank"
                            className="link-color"
                          >
                            <img
                              src="../../static/images/mail.png"
                              alt={t("Mail")}
                              className="profileCardImage"
                            />
                            <span className={`ms-3`}>
                              {props.profileCardProps.email}
                            </span>
                          </a>
                        </div>
                      )}
                      {props.profileCardProps.website && (
                        <div className="d-flex align-items-center" style={{ marginBottom: "1vw" }}>
                          <img
                            src="../../static/images/Website.svg"
                            alt={t("Website")}
                            className="profileCardImage"
                          />
                          <span className={`ms-3`}>
                            <Link
                              to={`${props.profileCardProps.website}`}
                              className="color-dark-pink"
                              target="_blank"
                            >
                              {props.profileCardProps.website}
                            </Link>
                          </span>
                        </div>
                      )}
                      {/* {fullAddress && ( */}
                      <div className="d-flex align-items-start">
                        <img
                          src="../../static/images/Company.svg"
                          alt={"Company Address"}
                          className="profileCardImage"
                        />
                        <span className={`ms-3`}>
                          <div className="mb-2 text-start">{fullAddress}</div>
                        </span>
                      </div>
                      {/* )} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Sales note */}
      <SalesNote salesnote={salesnote} setSalesNote={updateSalesNote} />
    </AccessControl>
  );
};

export default translate(MatchingProfileCard);
