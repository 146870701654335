import { t } from "components/CentralDataMangement/translation/Translation";
import { JobInterviewDetails, StarterDetails } from "../Annotations";
import TableTitle from "components/common/atoms/TableTitle";

interface JobInterviewTableProps {
    view?: "recruiter" | "tl";
    data?: JobInterviewDetails[];
    tableTitle?: string;
}
const JobInterviewTable: React.FC<JobInterviewTableProps> = ({ view = "recruiter", data, tableTitle }) => {

    return (
        <>
            {tableTitle &&
                <TableTitle title={tableTitle} />
            }
            <table className="table table-hover table-bordered targetTable">
                <thead>
                    <tr className="TableHeader">
                        <th>{t("Date")}</th>
                        <th>{t("Company")}</th>
                        <th>{t("Name UZK")}</th>
                        {view === "tl" ? (<>
                            <th>{t("Info")}</th>
                            <th>{t("FRO recruiter")}</th>
                            <th>{t("Result")}</th>
                            <th>{t("Office")}</th>
                        </>) : (<>
                            <th>{t("Reason")}</th>
                        </>)}
                    </tr>
                </thead>
                <tbody>
                    {data?.length && data?.length > 0 ? (
                        data?.map((candidate: JobInterviewDetails, index) => (
                            <tr key={index}>
                                <td className="align-middle">{candidate.date}</td>
                                <td className="align-middle">{candidate.company}</td>
                                <td className="align-middle">{candidate.candidate}</td>
                                {view === "tl" ? (<>
                                    <td className="align-middle">{candidate?.info}</td>
                                    <td className="align-middle">{candidate?.recruiter}</td>
                                    <td className="align-middle">{candidate?.result}</td>
                                    <td className="align-middle">{candidate?.office}</td>
                                </>) : (<>
                                    <td className="align-middle">{candidate?.reason}</td>
                                </>)}
                            </tr>
                        ))) : (
                        <tr>
                            <td colSpan={view === "tl" ? 7 : 4}>
                                <div className="text-danger text-center">
                                    {t("No data found")}
                                </div>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </>
    );
}

export default JobInterviewTable;