import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import Button from "components/common/atoms/Button";
import CheckBoxField from "components/common/atoms/CheckBoxField";
import { ApiCall } from "components/common/services/ApiServices";
import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CANDIDATE_MICROSERVICE } from "Constants";
import ModalPopup from "components/common/atoms/ModalPopup";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import { ValidationRules } from "components/common/utlis/TypeAnnotations";
import {
  validateEmail,
  validateForm,
  validatePassword,
  validateRequired,
} from "components/common/services/ValidationService";
import EncryptDecryptService from "components/common/services/EncryptDecryptService";
import CustomNotify from "components/common/atoms/CustomNotify";
import { addUserDetails, selectAuth } from "features/auth/AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import secureLocalStorage from "react-secure-storage";

interface Preview {
  iframeUrl: string;
  acceptTerms: number;
  candidateSign: boolean;
  signAsCandidatePopup: boolean;
  candidateVerify: boolean;
  email: string;
  password: string;
  candidateId: number | null;
  canUserId: number | null;
}

const CandidateConsentForm = () => {
  const { candidateId } = useParams<{
    candidateId: string;
  }>();
  const location = useLocation();
  const userAuth = useSelector(selectAuth);
  const candidate = userAuth.role === "CANDIDATE";
  const navigate = useNavigate();
  const lang_key = secureLocalStorage.getItem("site_lang") ?? "en";
  const dispatch = useDispatch();

  const initialState = {
    iframeUrl: `${process.env.REACT_APP_BACKEND_SERVICE}/api/get-candidate-consent-details?candidateId=${candidateId}&lang=${lang_key}`,
    acceptTerms: 0,
    candidateSign: true,
    signAsCandidatePopup: false,
    candidateVerify: candidate ?? false,
    email: "",
    password: "",
    candidateId: null,
    canUserId: null,
  };
  const [state, setState] = useState<Preview>(initialState);

  const initialErrors: { [key: string]: string } = {};
  const [errors, setErrors] = useState<{ [key: string]: string }>(
    initialErrors
  );
  const handleModalClose = (type: string) => {
    if (type === "candidateSign") {
      setState((prevData) => ({
        ...prevData,
        signAsCandidatePopup: false,
      }));
    } else {
      setState((prevData) => ({
        ...prevData,
        candidateSign: true,
      }));
    }
  };

  const validation = (
    name: string,
    value: string | boolean | number,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      email: [validateRequired, validateEmail],
      password: [validateRequired, validatePassword],
    };

    const validationErrors = validateForm(
      { ...state, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation && Object.keys(errors).length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }

    return true;
  };
  const checkCandidateSign = async () => {
    const url = `${"checkCandidateSign"}/${candidateId}`;
    const response = await ApiCall.getService(
      url,
      "GET",
      CANDIDATE_MICROSERVICE,
      true
    );

    if (response.status === 200) {
      const data = response.data;

      const canUserId = data !== null ? data["userId"] : null;
      setState((prevData) => ({
        ...prevData,
        candidateSign: data !== null,
        signAsCandidatePopup: data !== null,
        email: data !== null && data["userMail"],
        canUserId: canUserId ? canUserId : null,
        iframeUrl: `${process.env.REACT_APP_BACKEND_SERVICE}/api/get-candidate-consent-details?candidateId=${candidateId}&lang=${lang_key}`,
      }));
    }
  };

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
    let updatedValue: string | boolean | number;
    if (type === "checkbox") {
      updatedValue = checked ? 1 : 0;
      if (updatedValue === 0) {
        setState(initialState);
      }
    } else {
      updatedValue = value;
    }
    setState((prevData) => ({
      ...prevData,
      [name]: updatedValue,
      iframeUrl: `${process.env.REACT_APP_BACKEND_SERVICE}/api/get-candidate-consent-details?candidateId=${candidateId}&lang=${lang_key}`,
    }));
    validation(name, value, true);
  };

  const authenticateCandidate = async (event: React.FormEvent) => {
    event.preventDefault();
    const { name, value } = event.target as HTMLInputElement;
    const formData = {
      email: state.email,
      password: EncryptDecryptService.encryptData(
        JSON.stringify(state.password)
      ),
      candidateId: candidateId,
      canUserId: state.canUserId,
      submitType: "accept",
      lang: lang_key,
    };
    if (validation(name, value)) {
      const response = await ApiCall.service(
        "authenticateCandidate",
        "POST",
        formData,
        true,
        CANDIDATE_MICROSERVICE
      );
      if (response.status === 200) {
        setState((prevData) => ({
          ...prevData,
          candidateVerify: true,
          signAsCandidatePopup: false,
          iframeUrl: `${process.env.REACT_APP_BACKEND_SERVICE}/api/get-candidate-consent-details?candidateId=${candidateId}&lang=${lang_key}&approved=1`,
        }));
        setErrors({
          email: "",
        });
      } else {
        setErrors({
          email: response.msg,
        });
      }
    }
  };

  const handleSubmit = async (toCandidate: boolean) => {
    const formData = {
      approved: toCandidate ? false : true,
      sendToCandidate: toCandidate,
      candidateId: candidateId,
      recruiterId: !candidate ? userAuth.userId : null,
      canUserId: candidate ? userAuth.userId : state.canUserId,
      email: state.email,
      verify: 1,
      submitType: "accept",
      lang: lang_key,
    };
    const response = await ApiCall.service(
      "authenticateCandidate",
      "POST",
      formData,
      true,
      CANDIDATE_MICROSERVICE
    );

    if (response.status === 200) {
      CustomNotify({
        type: "success",
        message: t(response.msg),
      });
      if (userAuth.role === "CANDIDATE") {
        navigate(`/dashboard`);
      } else {
        navigate(`/manage-screening/${candidateId}`);
      }
      dispatch(addUserDetails({ consentStatus: 1 }));
    } else {
      const data = response.data;
      if (data) {
        setState((prevData) => ({
          ...prevData,
          candidateSign: false,
        }));
      }
      !data && CustomNotify({ type: "error", message: t(response.msg) });
    }
  };

  return (
    <div>
      <iframe
        width="100%"
        src={state.iframeUrl}
        title="Embedded Content"
        allowFullScreen
        className="iframeHeight"
      ></iframe>
      <div className="col-lg-12">
        <div className="row">
          <div className="d-flex align-items-center mb-3">
            <CheckBoxField
              name="acceptTerms"
              onChangeHandler={changeHandler}
              isChecked={state.acceptTerms === 1}
              id="acceptTerms"
              className="cursor-pointer"
            />
            <label
              htmlFor="acceptTerms"
              className="checkbox-label cursor-pointer"
              style={{ lineHeight: "26px" }}
            >
              {t(
                "I hereby declare that I have reviewed the document and confirming that I agree with all details."
              )}
            </label>
          </div>

          {state.candidateVerify === true && state.acceptTerms === 1 && (
            <div className="">
              <Button
                type="submit"
                title={t("Submit")}
                handleClick={() => handleSubmit(false)}
                className="float-end form-button shadow-none marginRightPoint5"
              />
            </div>
          )}

          {!candidate &&
            state.acceptTerms === 1 &&
            state.candidateVerify === false && (
              <div className="">
                <Button
                  type="submit"
                  title={t("Sign as candidate")}
                  handleClick={checkCandidateSign}
                  className="float-end form-button shadow-none"
                />
                <Button
                  type="submit"
                  title={t("Send to candidate")}
                  handleClick={() => handleSubmit(true)}
                  className="float-end form-button shadow-none marginRightPoint5"
                />
              </div>
            )}
        </div>
      </div>
      <ModalPopup
        show={!state.candidateSign}
        onHide={() => handleModalClose("authenticated")}
        title={t("Signature")}
        body={
          candidate
            ? t("Please add the signature to continue further")
            : t(
                "Candidate not added the signature, Please add the signature to continue further"
              )
        }
        className="modal-lg"
      />
      <ModalPopup
        show={state.signAsCandidatePopup}
        onHide={() => handleModalClose("candidateSign")}
        title={t("Sign as candidate")}
        body={
          <form onSubmit={authenticateCandidate}>
            <div>
              <LabelWithInputField
                handleChange={changeHandler}
                label={t("Email")}
                type="text"
                isDisabled={true}
                id="email"
                name="email"
                value={state.email}
                error={errors.email}
              />
              <LabelWithInputField
                handleChange={changeHandler}
                isMandatory={true}
                label={t("Password")}
                type="password"
                id="password"
                name="password"
                value={state.password}
                error={errors.password}
              />
              <Button
                type="submit"
                title={t("Authenticate")}
                className="float-end form-button shadow-none marginRightPoint5"
              />
            </div>
          </form>
        }
        className="modal-lg"
      />
    </div>
  );
};

export default translate(CandidateConsentForm);
