import AdminFicheForm from "../create-adminfiche/form-content/AdminFicheForm";
import { FormProvider } from "./Context";

const MultiFormProviderAdminFiche = () => {
  return (
    <>
      <FormProvider>
        <AdminFicheForm />
      </FormProvider>
    </>
  );
};

export default MultiFormProviderAdminFiche;
