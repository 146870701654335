import { t } from "components/CentralDataMangement/translation/Translation";
import React from "react";
import { Spinner } from "react-bootstrap";
interface Props {
  headerLength?: number;
  isDiv?: boolean;
  message?: string; // Optional message prop for customization
  className?: string;
}
const SpinnerWrapper: React.FC<Props> = ({
  headerLength,
  isDiv = false,
  className,
}) => {
  return (
    <>
      {isDiv ? (
        <div className={`text-center ${className}`}>
          <Spinner size="sm" className="me-2 spinnerIcon" /> {t("Loading")}
        </div>
      ) : (
        <tr>
          <td
            colSpan={headerLength}
            className="border text-center"
          >
            <Spinner size="sm" className="me-2 spinnerIcon" /> {t("Loading")}
          </td>
        </tr>
      )}
    </>
  );
};

export default SpinnerWrapper;
