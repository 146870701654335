import React from "react";
import SignatureField from "../atoms/SignatureField";
import { t, translate } from "components/CentralDataMangement/translation/Translation";
import ModalPopup from "../atoms/ModalPopup";
import Button from "../atoms/Button";
import { Link, useNavigate } from "react-router-dom";

interface SignatureDetailsProps {
  state: any;
  setState: React.Dispatch<React.SetStateAction<any>>;
  submitSignData: (e: any) => void;
  fromSvPreview?: number;
  noMinHeight?: number;
}

const SignatureDetails: React.FC<SignatureDetailsProps> = ({
  state,
  setState,
  submitSignData,
  fromSvPreview = 0,
  noMinHeight = 0,
}) => {
  const handleSave = (dataUrl: string) => submitSignData(dataUrl);
  const handleClose = () => setState({ ...state, showPopup: false });
  const handleEdit = () =>
    setState({ ...state, showPopup: true, disabled: false });
  const navigate = useNavigate();

  return (
    <>
      <div className="form-height-dashboard d-grid">
        <div
          className={`row`}
        >
          <ModalPopup
            size="lg"
            show={state.showPopup}
            title={t("Signature")}
            className="signature-popup"
            body={
              <SignatureField
                className={"signatureCanvas"}
                onSave={handleSave}
                disabled={state.disabled}
                dataUrl={state.sign}
                width={"100%"}
              />
            }
            onHide={handleClose}
          />
          {fromSvPreview === 0 && (
            <div className="col-md-12 col-lg-9 m-auto">
              <div className={`border p-3 rounded-4 manage-signature-border ${state.sign !== "" ? "d-grid" : "d-flex align-items-center justify-content-center flex-column"}  `}>
                {state.sign !== "" ? (
                  <>
                    <div>{t("Signature") + ":"}</div>
                    <div className="row my-4">
                      <div className="col-md-12 text-center">
                        <img src={state.sign} alt={t("Signature")} className="signature-section" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="align-self-end col-md-12">
                        <Button
                          className={`px-3 form-button float-end`}
                          handleClick={handleEdit}
                        >
                          {t("Add a new sign")}
                        </Button>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="text-center">
                      {t(
                        `You haven't added a signature yet, please add your signature.`
                      )}
                    </div>
                    <div className="row mt-5">
                      <div className="col-md-12 text-center">
                        <Button
                          handleClick={handleEdit}
                          type="button"
                          className="px-3 form-button"
                        >
                          {t(`Add signature`)}
                        </Button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 align-self-center py-4">
          <Link to="" className='back-btn text-decoration-underline' onClick={() => navigate(-1)}>{t("Back")}</Link>
        </div>
      </div>
    </>
  );
};

export default React.memo(translate(SignatureDetails));
