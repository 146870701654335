import { useEffect } from "react";

export const getNextTabId = (tabDetails: any) => {
  const currentIndex = tabDetails.findIndex((tab: any) => tab.showStatus);
  // If no tab is currently active, return null or handle as needed
  if (currentIndex === -1) {
    return null;
  }
  // Calculate the next index (cyclically)
  const nextIndex = (currentIndex + 1) % tabDetails.length;
  // Get the id of the next tab
  return tabDetails[nextIndex].id;
};

// Function to update the URL with the tabId
const updateUrlWithTabId = (tabId: string) => {
  const queryParams = new URLSearchParams(window.location.search);
  queryParams.set("tabId", tabId);
  const newUrl = window.location.pathname + "?" + queryParams.toString();
  window.history.replaceState(null, "", newUrl);
};

// Function to set the tab status based on the tabId from the URL
const setTabStatusToTrueOnTabId = (tabDetails: any, dispatch: any) => {
  const queryParams = new URLSearchParams(window.location.search);
  const tabId = queryParams.get("tabId");
  //If tabId is provided then set status true to that tab
  if (tabId) {
    const updatedTabDetails = tabDetails.map((tab: any) => ({
      ...tab,
      showStatus: tab.id === tabId,
    }));
    dispatch({ type: "UPDATE_TAB_DETAILS", tabDetails: updatedTabDetails });
    // If no tabId is provided, set the first tab's showStatus to true
  } else {
    const updatedTabDetails = tabDetails.map((tab: any, index: number) => ({
      ...tab,
      showStatus: index === 0,
    }));
    dispatch({ type: "UPDATE_TAB_DETAILS", tabDetails: updatedTabDetails });
  }
};

// Custom hook to update the URL and set tab status
export const useTabUrlUpdater = (tabDetails: any, dispatch: any) => {
  // Update the URL with the current tabId when tabDetails change i.e on click on tab , next button  , back button
  useEffect(() => {
    const currentTab = tabDetails.find((tab: any) => tab.showStatus);
    if (currentTab) {
      updateUrlWithTabId(currentTab.id);
    }
  }, [tabDetails]);

  // Set the tab status based on the tabId from the URL refresh and intial render
  useEffect(() => {
    setTabStatusToTrueOnTabId(tabDetails, dispatch);
  }, []);
};
