import * as ENDPOINTS from "routes/ApiEndpoints";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import Title from "components/common/atoms/Title";
import { ApiCall } from "components/common/services/ApiServices";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import Autocomplete from "../../../common/atoms/Autocomplete";
import Button from "components/common/atoms/Button";
import LoadingIcon from "components/common/utlis/LoadingIcon";
import {
  validateForm,
  validateRequired,
  validateSelectField,
} from "components/common/services/ValidationService";
import { ValidationRules } from "components/common/utlis/TypeAnnotations";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "Constants";
import CustomNotify from "components/common/atoms/CustomNotify";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import AutoCompleteDropdown from "components/common/atoms/AutoCompleteDropdown";

interface Suggestion {
  label: string;
  value: string;
}

interface FormData {
  id?: number;
  pcId: number | string | undefined;
  functionName: string;
  minimumSalary: string | null;
  domainName: object[];
}

interface Props {
  funcLinkId?: number;
  minSal?: string;
  enable?: boolean;
}

const ExistingFunctions: React.FC<Props> = ({ funcLinkId, minSal, enable }) => {
  const { id: pcId } = useParams<{ id: string }>();
  const [functions, setFunctions] = useState<Suggestion[]>([]);
  const [domains, setDomains] = useState<Suggestion[]>([]);
  const navigate = useNavigate();
  const [formData, setFormData] = useState<FormData>({
    pcId: pcId,
    functionName: "",
    minimumSalary: "",
    domainName: [],
  });
  const [loading, setLoading] = useState(true);
  const initialErrors: { [key: string]: string } = {};
  const [errors, setErrors] = useState<{ [key: string]: string }>(
    initialErrors
  );

  useEffect(() => {
    fetchFunctions();
  }, []);

  useEffect(() => {
    if (funcLinkId) {
      fetchData(funcLinkId);
    }
  }, [funcLinkId]);

  const fetchData = async (funcLinkId: number | undefined) => {
    const requestData = {
      id: funcLinkId,
      type: "pcFunction",
    };

    const response = await ApiCall.service(
      ENDPOINTS.CONF_SETTINGS_EDIT,
      "POST",
      requestData,
      false,
      "central-data-management"
    );

    if (response.status === 200) {
      const adaptedData = {
        pcId: pcId,
        functionName: response.data.function_name,
        minimumSalary: response.data.min_salary,
        domainName: response.data.domain_name, // Assuming domainName is an array
      };
      setFormData(adaptedData);
      setLoading(false);
    }
  };

  const fetchFunctions = async () => {
    const requestData = {
      type: "pcFunction",
    };

    const response = await ApiCall.service(
      ENDPOINTS.CONF_SETTINGS_GET,
      "POST",
      requestData,
      false,
      "central-data-management"
    );

    if (response.status === 200) {
      setFunctions(response.data["functions"]);
      setDomains(response.data["domains"]);
      setLoading(false);
    }
  };

  const validation = (
    name: string,
    value: string | boolean | null | undefined | number,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      functionName: [validateRequired],
    };

    const validationErrors = validateForm(
      { ...formData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation && Object.keys(errors).length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }
    return true;
  };

  const handleSuggestionSelected = (selectedSuggestion: Suggestion) => {
    setFormData((prevData) => ({
      ...prevData,
      ["functionName"]: selectedSuggestion.label,
    }));
    validation("functionName", selectedSuggestion.label, true);
  };

  const handleSuggestChange = (inputValue: string) => {
    setFormData((prevData) => ({
      ...prevData,
      ["functionName"]: inputValue,
    }));
    validation("functionName", inputValue, true);
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target as HTMLInputElement;

    let newValue: any = value;
    if (name === "minimumSalary") {
      const regex = /^(\d{0,2})(,\d{0,4})?$/;
      let matches = regex.exec(value);
      if (matches) {
        setFormData((prevData: any) => ({ ...prevData, [name]: newValue }));
      }
    } else {
      setFormData((prevData: any) => ({ ...prevData, [name]: newValue }));
      validation(name, newValue, true);
    }
  };

  const handleSelectChange = (selectedOption: any, fieldName: string) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: selectedOption,
    }));
    validation(fieldName, selectedOption, true);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    const { name, value } = e.target as HTMLInputElement;
    setLoading(true);
    e.preventDefault();

    const data = {
      type: "pcFunction",
      id: funcLinkId,
      ...formData,
    };

    if (validation(name, value)) {
      let response = await ApiCall.service(
        ENDPOINTS.CONF_SETTINGS_UPDATE,
        "POST",
        data,
        false,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.status === 200) {
        CustomNotify({ type: "success", message: t(response.msg) });
        await new Promise((resolve) => setTimeout(resolve, 3000));
        window.location.reload();
      } else if (response.status === 400) {
        let errors: any = [];
        for (const key in response.errors) {
          errors[key] = response.errors[key];
        }
        setErrors({ ...errors });
      }
    }
    setLoading(false);
  };

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Paritair committe",
          read: true,
        },
      ]}
      renderNoAccess={true}
    >
      <div className="add-domain col-md-12 col-lg-5 col-xxl-4 col-xl-4 my-4">
        <div className="form-border padding1">
          <div className="row">
            <div className="col-12">
              <div className="tab-subtitle">
                {funcLinkId === undefined
                  ? t("Add function")
                  : t("Edit function")}
              </div>
            </div>

            <Autocomplete
              value={formData.functionName}
              label={t("Function name")}
              suggestions={functions}
              onSuggestionSelected={handleSuggestionSelected}
              onInputValueChange={handleSuggestChange}
              className="color-dark-pink"
              isMandatory={true}
              error={errors.functionName}
            />
            <LabelWithInputField
              label={t("Minimum salary")}
              name={"minimumSalary"}
              placeholder={t("Minimum salary")}
              handleChange={handleChange}
              value={formData.minimumSalary ?? ""}
              error={errors.minimumSalary}
            />
            <div className="col-12">
              <SelectWithSearch
                search={true}
                onChange={(e) => handleSelectChange(e, "domainName")}
                title={t("Domain")}
                options={domains}
                placeHolder={"select"}
                name={"domainName"}
                error={errors.domainName}
                value={formData.domainName}
                isTranslate={true}
              />
            </div>
            <div className="col-md-12 text-end">
              {!loading ? (
                <Button
                  title={t("Save")}
                  type="submit"
                  className="float-end form-button shadow-none"
                  handleClick={handleSubmit}
                />
              ) : (
                <LoadingIcon
                  iconType="bars"
                  color="#e55496"
                  height={"3vw"}
                  width={"3vw"}
                  className="ms-auto"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </AccessControl>
  );
};

export default translate(ExistingFunctions);
