import React from "react";
import { useParams } from "react-router-dom";
import {
  CONF_SETTINGS_ADD,
  CONF_SETTINGS_EDIT,
  CONF_SETTINGS_UPDATE,
} from "routes/ApiEndpoints";
import FormBuilder from "components/common/services/form/FormBuilder";
import {
  validateMultiSelectField,
  validateRequired,
} from "components/common/services/ValidationService";
import { t, translate } from "components/CentralDataMangement/translation/Translation";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";

const Function: React.FC = () => {
  const { id: funcLinkId } = useParams<{ id: string }>();
  const FunctionFormValidation = {
    functionName: [validateRequired],
    domainName: [validateMultiSelectField],
  };

  const permissionType = funcLinkId ? "update" : "create";
  const permissionObject: any = {
    permission: "Function",
  };
  permissionObject[permissionType] = true;
  return (
    <AccessControl
      requiredPermissions={[permissionObject]}
      renderNoAccess={true}
    >
      <FormBuilder
        title={
          funcLinkId === undefined ? t("Add function") : t("Edit function")
        }
        SaveDataAPI={
          funcLinkId === undefined ? CONF_SETTINGS_ADD : CONF_SETTINGS_UPDATE
        }
        entryId={funcLinkId}
        getDataAPI={CONF_SETTINGS_EDIT}
        formName={"Function"}
        redirect={-1}
        validationRules={FunctionFormValidation}
        microService={"central-data-management"}
        backButton={true}
        type="function"
      />
    </AccessControl>
  );
};

export default translate(Function);
