import { FilterProps } from "components/common/atoms/AutoCompleteDropdown";
import { dropDownList } from "./Interface";

export const createFilterConfig = (dropDownList: dropDownList) => {
  return [
    {
      name: "office",
      options: dropDownList.offices,
      fieldType: "multiSelect",
      placeholder: "Office",
      filterType: "search",
    },
    {
      name: "candidateName",
      fieldType: "text",
      placeholder: "Candidate name",
      filterType: "search",
    },
    {
      name: "email",
      fieldType: "text",
      placeholder: "Email",
      filterType: "search",
    },
    {
      name: "phone",
      fieldType: "text",
      placeholder: "Phone",
      filterType: "search",
    },

    {
      name: "vacancyName",
      fieldType: "text",
      placeholder: "Vacancy",
      filterType: "search",
    },
    {
      name: "companyName",
      fieldType: "text",
      placeholder: "Company",
      filterType: "search",
    },
    {
      name: "from",
      fieldType: "date",
      placeholder: "Start",
      filterType: "search",
      group: "one",
    },
    {
      name: "to",
      fieldType: "date",
      placeholder: "End",
      filterType: "search",
      group: "one",
    },
    {
      name: "channel",
      fieldType: "text",
      placeholder: "Channel",
      filterType: "search",
    },
  ];
};
