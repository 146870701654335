import { t } from "components/CentralDataMangement/translation/Translation";
import LabelField from "components/common/atoms/LabelField";
import RadioField from "components/common/atoms/RadioField";
import { EcoChequeNMealVoucherData } from "./SalaryBenefit";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import InputTextfield from "components/common/atoms/InputTextField";
import SelectWithSearch from "components/common/atoms/SelectWithSearch";
import { OptionProps } from "components/common/utlis/TypeAnnotations";
import InputTextAreaField from "components/common/atoms/InputTextAreaField";

interface FormData {
  data: EcoChequeNMealVoucherData;
  ecoChequeOptions: OptionProps[];
  handleChange: (
    e: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >,
    dataOf: "ecoCheque" | "mealVoucher"
  ) => void;
  onChange: (selected: any) => void;
  errors: { [key: string]: string };
}

const MealVoucherNEcoCheque: React.FC<FormData> = ({
  data,
  handleChange,
  ecoChequeOptions,
  onChange,
  errors,
}) => {
  const getEcoChequeByValue = (value: any) => {
    return ecoChequeOptions.find((option) => option.value === value);
  };

  return (
    <table className="table table-bordered mb-0">
      <thead>
        <tr className="TableHeader">
          <th style={{ width: "50%" }}>{t("Eco cheque")}</th>
          <th style={{ width: "50%" }}>{t("Meal vouchers")}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <div className="row">
              <div className="col-md-12">
                <LabelField title={t("Eco cheque")} />
                <div className="marginBotttom1">
                  <div className="d-inline-block me-3">
                    <RadioField
                      label={t("Yes")}
                      name={`isEcoCheque`}
                      value={1}
                      ischecked={data.ecoCheque.isEcoCheque === 1}
                      handleChange={(e) => handleChange(e, "ecoCheque")}
                    />
                  </div>
                  <div className="d-inline-block">
                    <RadioField
                      label={t("No")}
                      name={`isEcoCheque`}
                      value={0}
                      ischecked={data.ecoCheque.isEcoCheque === 0}
                      handleChange={(e) => handleChange(e, "ecoCheque")}
                    />
                  </div>
                </div>
                {data.ecoCheque.isEcoCheque === 1 && (
                  <div className="col-5">
                    <div className="input-group ecoVouchers position-relative">
                      <InputTextfield
                        name="value"
                        value={data.ecoCheque.value}
                        handleChange={(e) => handleChange(e, "ecoCheque")}
                      />
                      <div className="input-group-text rounded-start-0 rounded-end-3 border-0 position-absolute">
                        €
                      </div>
                    </div>
                  </div>
                )}
                <div className="row">
                  {data.ecoCheque.isEcoCheque === 0 && (
                    <div className="col-5 ecoCheque">
                      <SelectWithSearch
                        name="chequeType"
                        value={getEcoChequeByValue(data.ecoCheque.chequeType)}
                        options={ecoChequeOptions}
                        search={true}
                        onChange={onChange}
                      />
                    </div>
                  )}
                  {data.ecoCheque.chequeType === 5 && (
                    <div className="col-7">
                      <InputTextAreaField
                        name="otherInfo"
                        value={data.ecoCheque.otherInfo ?? ""}
                        handleChange={(e) => handleChange(e, "ecoCheque")}
                        error={errors?.["otherInfo"]}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </td>
          <td>
            <div className="row">
              <div className="col-md-6">
                <div className="input-group mealVouchers position-relative">
                  <LabelWithInputField
                    label={t("Total value")}
                    name="totalValue"
                    value={data.mealVoucher.totalValue}
                    handleChange={(e) => handleChange(e, "mealVoucher")}
                  />
                  <div className="input-group-text rounded-start-0 rounded-end-3 border-0 position-absolute">
                    €
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="input-group mealVouchers position-relative">
                  <LabelWithInputField
                    label={t("Employer part")}
                    name="employerPart"
                    value={data.mealVoucher.employerPart}
                    handleChange={(e) => handleChange(e, "mealVoucher")}
                  />
                  <div className="input-group-text rounded-start-0 rounded-end-3 border-0 position-absolute">
                    €
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="input-group mealVouchers position-relative">
                  <LabelWithInputField
                    label={t("Minimum hours")}
                    name="minimumHours"
                    value={data.mealVoucher.minimumHours}
                    handleChange={(e) => handleChange(e, "mealVoucher")}
                  />
                  <div className="input-group-text rounded-start-0 rounded-end-3 border-0 position-absolute">
                    €
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};
export default MealVoucherNEcoCheque;
