import React from "react";

const PickUp: React.FC = () => {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 438 331"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M170.133 1.72711C196.4 8.12711 215.867 31.0604 218.133 58.3938C221.467 97.8604 184 132.26 145.333 125.327C111.067 119.194 87.4666 86.7938 92.9333 53.5938C98.9333 17.1938 134.8 -6.80623 170.133 1.72711Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M352.533 46.7938C356.4 35.1938 353.6 26.7938 343.867 20.1271C338.133 16.2604 329.333 16.2604 322.533 19.9938C318.667 22.2604 314.133 27.9938 302.8 45.1938C274.533 87.8604 254.667 110.26 231.333 125.86C206.267 142.527 177.2 150.394 140 150.527C116.533 150.527 89.6 157.194 73.8667 166.66C57.3333 176.527 41.3333 197.86 30.4 224.26C22.6667 243.327 5.06666 303.727 0.799988 326.127L0 330.527H25.6H51.2L54.9333 317.46C58.8 303.994 67.2 283.194 71.8667 275.594L74.5333 271.194V300.794V330.527H153.867H233.2V308.794C233.2 259.06 239.333 226.394 254 196.794C261.733 181.194 264.8 177.06 289.2 147.06C303.673 129.377 315.01 114.693 324 101.721V48H352.141C352.274 47.5984 352.404 47.1964 352.533 46.7938Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M438 46H315V194.193H438V46ZM427.627 58.5964H325.373V182.337H427.627V58.5964Z"
                fill="currentColor"
            />
            <path
                d="M370 116C385.18 116 400.36 116 416 116C416 123.26 416 130.52 416 138C400.82 138 385.64 138 370 138C370 130.74 370 123.48 370 116Z"
                fill="currentColor"
            />
            <path
                d="M337 116C344.59 116 352.18 116 360 116C360 123.26 360 130.52 360 138C352.41 138 344.82 138 337 138C337 130.74 337 123.48 337 116Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default PickUp;
