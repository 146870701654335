import React, { MouseEventHandler } from "react";
import Button from "./Button";
import { t } from "components/CentralDataMangement/translation/Translation";
import AdvancedSearchIcon from "static/images/AdvancedSearchIcon";
import CloseFile from "static/images/CloseFile";
import { isFilterDataEmpty } from "../utlis/HelperFunctions";
import ADVcloseIcon from "static/images/ADVcloseIcon";

interface props {
  handleSearch?: MouseEventHandler<HTMLButtonElement>;
  clearAdvSearchFilter: (action: any) => void;
  filterData: any;
}
const AdvancedSearchBtn: React.FC<props> = ({
  handleSearch,
  clearAdvSearchFilter,
  filterData,
}) => {
  // Check if filter data is empty
  const isDataEmpty = isFilterDataEmpty(filterData);

  return (
    <span
      title={isDataEmpty ? t("Advanced search") : t("Clear filter")}
      onClick={isDataEmpty ? handleSearch : clearAdvSearchFilter}
      className={` ${
        isDataEmpty ? "delete-btn advIcon" : "advSearchCloseIcon advClose"
      }  me-3 cursor-pointer`}
    >
      {isDataEmpty ? <AdvancedSearchIcon /> : <ADVcloseIcon />}
    </span>
  );
};

export default AdvancedSearchBtn;
