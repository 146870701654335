import { t, translate } from "components/CentralDataMangement/translation/Translation";
import Button from "components/common/atoms/Button";
import RadioField from "components/common/atoms/RadioField";
import Title from "components/common/atoms/Title";
import React, { useEffect, useState } from "react";
import "./css/todos.css";
import { OptionProps } from "components/common/utlis/TypeAnnotations";
import { useSelector } from "react-redux";
import { selectAuth } from "features/auth/AuthSlice";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { ApiCall } from "components/common/services/ApiServices";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "Constants";
import { mapToSelect } from "components/common/utlis/MapToSelect";
import "./css/todos.css";
import { GET_PRE_INTERVIEW_DETAILS, UPDATE_TODO } from "routes/ApiEndpoints";
import LabelWithTextAreaField from "components/common/molecules/LabelWithTextAreaField";
interface PreInterview {
  timeSlotDetails: OptionProps[];
  companyTodoStatus: number | null;
  companyName: string;
  candidateName: string;
  candidate: string;
  link: string;
  submitType: string;
}
interface TimeSlotData {
  timeSlot: number | null;
}

const CandidatePreInterviewPage: React.FC = () => {
  const userAuth = useSelector(selectAuth);
  const location = useLocation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<PreInterview>({
    timeSlotDetails: [],
    companyTodoStatus: 0,
    candidateName: "",
    companyName: "",
    candidate: "",
    link: "",
    submitType: "",
  });

  //get ids from url
  const {
    todoExtensionId,
    companyId,
    candidateId,
    todoId,
    functionName,
    presentationId,
  } = useParams<{
    todoExtensionId: string;
    companyId: string;
    candidateId: string;
    todoId: string;
    functionName: string;
    presentationId: string;
  }>();

  //for storing the data
  const [acceptData, setAcceptData] = useState<TimeSlotData>({
    timeSlot: null,
  });
  const [rejectData, setRejectData] = useState({
    reject: null,
    feedback: "",
  });
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchCanPreInterviewDetails = async () => {
      const data = {
        todoExtensionId: todoExtensionId,
        companyId: companyId,
        candidateId: candidateId,
        stage: "Candidate-scheduling",
        presentationId: presentationId,
      };
      const response = await ApiCall.service(
        GET_PRE_INTERVIEW_DETAILS,
        "POST",
        data,
        true,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.status === 200) {
        setFormData(response.data);
        setFormData((prevData) => ({
          ...prevData,
          timeSlotDetails: mapToSelect(
            response.data.timeSlotDetails,
            "timeinterval"
          ),
        }));
      }
    };
    fetchCanPreInterviewDetails();
  }, []);

  //setting the accept and reject type
  const handleAccept = async (event: any, type: string) => {
    event.preventDefault();
    setFormData((prevData) => ({
      ...prevData,
      submitType: type,
    }));
    if (type === "accept") {
      storePreInterviewDetils(event, type);
    }
  };

  const storePreInterviewDetils = async (
    event: React.FormEvent,
    type: string
  ) => {
    event.preventDefault();
    let isTimeSlot = true;
    if (type === "accept" && acceptData["timeSlot"] === null) {
      isTimeSlot = false;
    }
    const data = {
      submitType: type,
      formData: type === "accept" ? acceptData : rejectData,
      todoExtensionId: todoExtensionId,
      companyId: companyId,
      candidateId: candidateId,
      userId: userAuth.userId,
      todoId: todoId,
      entityType: "candidate",
      stage: "Candidate-scheduling",
      presentationId: presentationId,
      functionName: functionName,
    };
    if (isTimeSlot) {
      const response = await ApiCall.service(
        UPDATE_TODO,
        "POST",
        data,
        true,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.status === 200) {
        navigate(
          location.state?.origin === "todos" ? "/todos/overview" : "/dashboard"
        );
      }
    } else {
      setError(t("Please select atleast one time slot"));
    }
  };

  const handleFieldChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    if (name === "timeSlot") {
      setAcceptData((prevData: any) => ({
        ...prevData,
        [name]: parseInt(value),
      }));
      setError("");
    } else if (name === "reject") {
      setAcceptData((prevData: any) => ({
        ...prevData,
        timeSlot: null,
      }));
      setRejectData((prevData: any) => ({
        ...prevData,
        [name]: parseInt(value),
        feedback: "",
      }));
    } else if (name === "feedback") {
      setRejectData((prevData: any) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  return (
    <>
      <div>
        <Title title={t("Scheduling")} />
        <form>
          <div className="form-border mb-4">
            <div className="form pb-4">
              <div className="row">
                <div className="col-12">
                  <p>
                    {t(
                      "We are delighted to inform you that we have found an ideal match in company"
                    )}
                    &nbsp;
                    {formData.companyName}
                    {functionName !== '--' && (
                      <>
                        (&nbsp;{t("for function")}&nbsp;
                        {functionName})
                      </>
                    )}
                    ,&nbsp;
                  </p>
                  <p>
                    {t(
                      "If this is your dream job proceed further by clicking on accept after selecting the time slot which you are comfortable with"
                    )}
                    .
                  </p>
                </div>
                <div className="row">
                  {formData.timeSlotDetails.map((option, index) => (
                    <div className="my-1" key={index}>
                      <RadioField
                        name="timeSlot"
                        value={option.value}
                        disable={formData.submitType === "reject"}
                        ischecked={acceptData.timeSlot === option.value}
                        handleChange={(e) => handleFieldChange(e)}
                        label={option.label}
                        className="me-1"
                      />
                    </div>
                  ))}
                </div>
                {error && (
                  <div className="row mt-3">
                    <span className="text-danger">{error}</span>
                  </div>
                )}
                <div className="mt-3">
                  <div className="col-md-12 d-flex flex-row justify-content-end">
                    {formData.submitType !== "reject" &&
                      formData.submitType !== "accept" && (
                        <Button
                          title={t("Reject")}
                          type="submit"
                          handleClick={(e) => handleAccept(e, "reject")}
                          className="delete-btn shadow-none me-3"
                        />
                      )}
                    {(formData.submitType === "accept" ||
                      formData.submitType === "" ||
                      formData.submitType === undefined) && (
                      <Button
                        title={t("Accept")}
                        type="submit"
                        handleClick={(e) => handleAccept(e, "accept")}
                        className="form-button shadow-none"
                      />
                    )}
                  </div>
                </div>
              </div>
              <>
                {formData.submitType === "reject" && (
                  <div className="row mt-3">
                    <div className="col-md-12">
                      <h1 className="py-2 tab-subtitle mb-0">
                        {t("Reason for rejection")}
                      </h1>

                      {/* <LabelField
                            title=
                            key="type"
                            htmlfor={"answerId"}
                          /> */}
                    </div>
                    <div>
                      <div className="d-inline-block me-4">
                        <RadioField
                          name="reject"
                          value={0}
                          disable={false}
                          ischecked={rejectData.reject === 0}
                          handleChange={(e) => handleFieldChange(e)}
                          label={t("Request for time slots")}
                          className="mb-2"
                        />
                      </div>
                      <div className="d-inline-block">
                        <RadioField
                          name="reject"
                          value={1}
                          disable={false}
                          ischecked={rejectData.reject === 1}
                          handleChange={(e) => handleFieldChange(e)}
                          label={t("Other")}
                          className="mb-2"
                        />
                      </div>
                    </div>
                    {rejectData.reject === 1 && (
                      <div className="col-md-12 mt-3 mb-2">
                        <LabelWithTextAreaField
                          name="feedback"
                          handleChange={handleFieldChange}
                          label={t("Additional info")}
                          value={rejectData.feedback}
                        />
                      </div>
                    )}
                  </div>
                )}
              </>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 align-self-center">
              <Link
                to={
                  location.state?.origin === "todos"
                    ? "/todos/overview"
                    : "/dashboard"
                }
                className="back-btn text-decoration-underline"
              >
                {t("Back")}
              </Link>
            </div>
            {formData.submitType === "reject" && (
              <div className="col-md-6 d-flex justify-content-end">
                <Button
                  title={t("Save")}
                  type="submit"
                  className="form-button shadow-none px-3"
                  handleClick={(e) => storePreInterviewDetils(e, "reject")}
                />
              </div>
            )}
          </div>
        </form>
        {/* )} */}
      </div>
    </>
  );
};

export default translate(CandidatePreInterviewPage);
