import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import Title from "components/common/atoms/Title";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import { ApiCall } from "components/common/services/ApiServices";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import * as ENDPOINTS from "routes/ApiEndpoints";
import EditIcon from "static/images/EditIcon";
import Reset from "static/images/Reset";
import Pagination from "components/common/atoms/Pagination";
import CustomNotify from "components/common/atoms/CustomNotify";
import Button from "components/common/atoms/Button";
import { faMagnifyingGlass, faPlus } from "@fortawesome/free-solid-svg-icons";
import LinkTo from "components/common/atoms/LinkTo";
import "./css/function.css";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "Constants";
import ResetBtn from "components/common/atoms/ResetBtn";
import SpinnerWrapper from "components/common/atoms/SpinnerWrapper";
import NoRecords from "components/common/atoms/NoRecords";
interface manageData {
  id: number;
  name: string;
}

interface formDataProps {
  functionName: string;
}

const ManageFunction: React.FC = () => {
  //initiliaze the formData state for search fields
  const [formData, setFormData] = useState<formDataProps>({
    functionName: "",
  });

  const navigate = useNavigate();

  //state for to store the functions
  const [data, setData] = useState([] as manageData[]);

  //state for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchDataBySearch();
  }, []);

  //API call for to fetch Functions
  const fetchDataBySearch = async (
    formData = {},
    page = 1,
    pullFunctions = false
  ) => {
    const requestData = {
      ...formData,
      page: page,
      type: "function",
      pullFunctions: pullFunctions,
    };

    const response = await ApiCall.service(
      ENDPOINTS.CONF_SETTINGS_GET,
      "POST",
      requestData,
      true,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );

    if (response.status === 200) {
      setData(response.data["data"]);
      setTotalPages(response.data["totalPages"]);
      setLoading(false);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (formData.functionName.length === 0) {
      CustomNotify({
        type: "warning",
        message: t("Search input not provided") + ".",
      });
      fetchDataBySearch();
    } else {
      fetchDataBySearch(formData, 1);
    }
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    fetchDataBySearch(formData, newPage);
  };

  const handleFieldChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleReset = () => {
    // Clear the selected values in the search field
    setFormData({
      functionName: "",
    });
    setCurrentPage(1);
    fetchDataBySearch();
  };

  const pullFunctions = () => {
    setFormData({
      functionName: "",
    });
    setCurrentPage(1);
    fetchDataBySearch({}, 1, true);
  };

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Function",
          read: true,
        },
      ]}
      renderNoAccess={true}
    >
      <Title title={t("Functions")} />
      <div className="row search-bar">
        <div className="col-8">
          <LabelWithInputField
            handleChange={handleFieldChange}
            name="functionName"
            value={formData.functionName ?? ""}
            label={t("Function name")}
          />
        </div>
        <div className="col-4 mt-34">
          <div className="d-flex align-items-center">
            <ResetBtn handleResetClick={handleReset} />
            <Button
              title={t("Search")}
              type="submit"
              icon={faMagnifyingGlass}
              handleClick={handleSubmit}
              className="form-button shadow-none px-3 search-btns text-start position-relative"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="position-relative tableMainWrapper">
            <AccessControl
              requiredPermissions={[
                {
                  permission: "Function",
                  create: true,
                },
              ]}
            >
              <div className="row">
                <div className="ManageCreateBtn">
                  <LinkTo
                    pagelink="/add-function"
                    title={t("Add function")}
                    icon={faPlus}
                    className="marginRightPoint5"
                  />
                  <Button
                    title={t("Pull functions")}
                    handleClick={pullFunctions}
                    className="delete-btn shadow-none"
                    titleClassName="marginRightPoint5"
                    style={{ paddingLeft: "0.5vw", paddingRight: "0.5vw" }}
                  >
                    <span title={t("Pull functions")}>
                      <Reset />
                    </span>
                  </Button>
                </div>
              </div>
            </AccessControl>
            <div className="table-responsive tableSection paddingTop2">
              <table className="table table-hover">
                <thead>
                  <tr className="TableHeader">
                    <th
                      className="align-middle ps-lg-4"
                      style={{ width: "60%" }}
                    >
                      {t("Function")}
                    </th>
                    <AccessControl
                      requiredPermissions={[
                        {
                          permission: "Function",
                          update: true,
                          delete: true,
                        },
                      ]}
                      actions={true}
                      strict={false}
                    >
                      <th>{t("Actions")}</th>
                    </AccessControl>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <SpinnerWrapper headerLength={3} />
                  ) : (
                    <>
                      {data && data.length > 0 ? (
                        data.map((item, index) => (
                          <tr
                            key={index}
                            className="border-bottom mb-3 box-shadow align-middle"
                          >
                            <td
                              data-label={t("Name")}
                              className="align-middle ps-lg-4"
                            >
                              {t(`${item.name}`)}
                            </td>
                            <AccessControl
                              requiredPermissions={[
                                {
                                  permission: "Function",
                                  update: true,
                                  delete: true,
                                },
                              ]}
                              actions={true}
                              strict={false}
                            >
                              <td className="table-action-icons px-2 align-middle">
                                <AccessControl
                                  requiredPermissions={[
                                    {
                                      permission: "Function",
                                      update: true,
                                    },
                                  ]}
                                >
                                  {item.name !== "General" && (
                                    <Link
                                      to={`/edit/function/${item.id}`}
                                      title={t("Edit")}
                                    >
                                      <EditIcon />
                                    </Link>
                                  )}
                                </AccessControl>
                              </td>
                            </AccessControl>
                          </tr>
                        ))
                      ) : (
                        <NoRecords headerLength={7} />
                      )}
                    </>
                  )}
                </tbody>
              </table>
              {totalPages > 1 && (
                <div className="pagination justify-content-center align-items-center mt-4">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={(newPage) => handlePageChange(newPage)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6 align-self-center py-4">
        <Link
          to=""
          className=" back-btn text-decoration-underline"
          onClick={() => navigate(-1)}
        >
          {t("Back")}
        </Link>
      </div>
    </AccessControl>
  );
};

export default translate(ManageFunction);
