import { CONF_SETTINGS_ADD, CONF_SETTINGS_EDIT, CONF_SETTINGS_UPDATE } from "routes/ApiEndpoints";
import { validateRequired } from "components/common/services/ValidationService";
import FormBuilder from "components/common/services/form/FormBuilder";
import { useParams } from "react-router-dom";
import { t, translate } from "components/CentralDataMangement/translation/Translation";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";

interface Props {
  domainId?: number;
}

const PcDomain: React.FC<Props> = ({ domainId }) => {

  const { id: pcId } = useParams<{ id: string }>();

  const FunctionFormValidation = {
    domainName: [validateRequired],
  };

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Paritair committe",
          read: true,
        },
      ]}
      renderNoAccess={true}
    >
    <div className={`${domainId === undefined ? "add-domain " : "edit-domain "} col-md-12 col-lg-5 col-xxl-4 col-xl-4 my-4`}>
      <FormBuilder
        title={domainId === undefined ? t("Add domain") : t("Edit domain")}
        formName={"Domain"}
        validationRules={FunctionFormValidation}
        entryId={domainId}
        getDataAPI={CONF_SETTINGS_EDIT}
        SaveDataAPI={domainId === undefined ? CONF_SETTINGS_ADD : CONF_SETTINGS_UPDATE}
        microService="central-data-management"
        backButton={false}
        redirect={''}
        type="pcDomain"
        dataToInclude={{ pcId: pcId }}
        subForm={true}
        refresh={true}
      />
    </div>
    </AccessControl>
  );
}

export default translate(PcDomain);