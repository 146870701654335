import { t } from "components/CentralDataMangement/translation/Translation";
import { OfficeTarget } from "../Annotations";
import TableTitle from "components/common/atoms/TableTitle";
import RecruitmentCard from "components/common/atoms/DashboardCard";
import { CardTitle } from "react-bootstrap";

interface OfficeTargetProps {
  data?: OfficeTarget;
  tableTitle?: string;
}

const OfficeTargetTable: React.FC<OfficeTargetProps> = ({
  data,
  tableTitle,
}) => {
  const cardProps = {
    className : "card card-shadow text-center border-0"
  }
  const target = data?.target ?? 0;
  const reached = data?.reached ?? 0;
  const balance = data?.balance ?? 0;

  return (
    <>
      {tableTitle && <TableTitle title={tableTitle} tableTitleClassName="border py-2 text-center tableTitle TableHeader" />}
      <div
        className="d-grid mt-3"
        style={{ gridTemplateColumns: "repeat(3, 1fr)", gap: "1vw" }}
      >
        <div {...cardProps} style={{ padding: "1vw" }}
>
          <div className="roobert-semibold">{t("Target")}</div>
          <div className="mt-2">{target}</div>
        </div>
        <div {...cardProps} style={{ padding: "1vw" }}
>
          <div className="roobert-semibold">{t("Achieved")}</div>
          <div className="mt-2">{reached}</div>
        </div>
        <div {...cardProps} style={{ padding: "1vw" }}
>
          <div className="roobert-semibold">{t("Balance")}</div>
          <div className="mt-2 ">{balance}</div>
        </div>
      </div>
    </>
  );
};

export default OfficeTargetTable;
