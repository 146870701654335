import { faMagnifyingGlass, faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  t,
  translate,
} from "components/CentralDataMangement/translation/Translation";
import Title from "components/common/atoms/Title";
import { LabelWithInputField } from "components/common/molecules/LabelWithInputField";
import { ApiCall } from "components/common/services/ApiServices";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import * as ENDPOINTS from "routes/ApiEndpoints";
import EditIcon from "static/images/EditIcon";
import Reset from "static/images/Reset";
import Pagination from "components/common/atoms/Pagination";
import CustomNotify from "components/common/atoms/CustomNotify";
import Button from "components/common/atoms/Button";
import LinkTo from "components/common/atoms/LinkTo";
import "./css/domain.css";
import AccessControl from "components/common/services/RolesAndPermissions/AccessControl";
import ResetBtn from "components/common/atoms/ResetBtn";
import SpinnerWrapper from "components/common/atoms/SpinnerWrapper";
import NoRecords from "components/common/atoms/NoRecords";
interface manageData {
  id: number;
  name: string;
}

interface formDataProps {
  domainName: string;
}

const ManageDomain: React.FC = () => {
  //initiliaze the formData state for search fields
  const [formData, setFormData] = useState<formDataProps>({
    domainName: "",
  });

  const navigate = useNavigate();

  //state for to store the domains
  const [data, setData] = useState([] as manageData[]);

  //state for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchDataBySearch();
  }, []);

  //API call for to fetch Domain
  const fetchDataBySearch = async (formData = {}, page = 1) => {
    const requestData = {
      ...formData,
      page: page,
      type: "domain",
    };

    const response = await ApiCall.service(
      ENDPOINTS.CONF_SETTINGS_GET,
      "POST",
      requestData,
      false,
      "central-data-management"
    );

    if (response.status === 200) {
      setData(response.data["data"]);
      setTotalPages(response.data["totalPages"]);
      setLoading(false);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (formData.domainName.length === 0) {
      CustomNotify({
        type: "warning",
        message: t("Search input not provided") + ".",
      });
      fetchDataBySearch();
    } else {
      fetchDataBySearch(formData, 1);
    }
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    fetchDataBySearch(formData, newPage);
  };

  const handleFieldChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleReset = () => {
    // Clear the selected values in the dropdowns
    setFormData({
      domainName: "",
    });
    setCurrentPage(1);
    fetchDataBySearch();
  };

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Domain",
          read: true,
        },
      ]}
      renderNoAccess={true}
    >
      <Title title={t("Manage domain")} />
      <div className="row search-bar">
        <div className="col-8">
          <LabelWithInputField
            handleChange={handleFieldChange}
            name="domainName"
            value={formData.domainName ?? ""}
            label={t("Domain name")}
          />
        </div>
        <div className="col-4 mt-34">
          <div className="d-flex align-items-center">
            <ResetBtn handleResetClick={handleReset} />
            <Button
              title={t("Search")}
              type="submit"
              icon={faMagnifyingGlass}
              handleClick={handleSubmit}
              className="form-button shadow-none  px-3 search-btns text-start position-relative"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="position-relative tableMainWrapper">
            <div className="row">
              <AccessControl
                requiredPermissions={[
                  {
                    permission: "Domain",
                    create: true,
                  },
                ]}
              >
                <div className="ManageCreateBtn">
                  <LinkTo
                    pagelink="/add-domain"
                    title={t("Add domain")}
                    icon={faPlus}
                  />
                </div>
              </AccessControl>
            </div>
            <div className="table-responsive tableSection paddingTop2">
              <table className="table table-hover">
                <thead>
                  <tr className="TableHeader">
                    <th
                      className="align-middle ps-lg-4"
                      style={{ width: "60%" }}
                    >
                      {t("Domain")}
                    </th>
                    <AccessControl
                      requiredPermissions={[
                        {
                          permission: "Domain",
                          update: true,
                          delete: true,
                        },
                      ]}
                      actions={true}
                      strict={false}
                    >
                      <th>{t("Actions")}</th>
                    </AccessControl>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <SpinnerWrapper headerLength={3} />
                  ) : (
                    <>
                      {data && data.length > 0 ? (
                        data.map((item, index) => (
                          <tr
                            key={index}
                            className="border-bottom mb-3 box-shadow align-middle"
                          >
                            <td
                              data-label={t("Domain")}
                              className="align-middle ps-lg-4"
                            >
                              {t(`${item.name}`)}
                            </td>
                            <AccessControl
                              requiredPermissions={[
                                {
                                  permission: "Domain",
                                  update: true,
                                  delete: true,
                                },
                              ]}
                              actions={true}
                              strict={false}
                            >
                              <td className="table-action-icons px-2 align-middle">
                                <AccessControl
                                  requiredPermissions={[
                                    {
                                      permission: "Domain",
                                      update: true,
                                    },
                                  ]}
                                >
                                  <Link
                                    to={`/edit/domain/${item.id}`}
                                    className="btn p-0 border-0 me-2"
                                    title={t("Edit")}
                                  >
                                    <EditIcon />
                                  </Link>
                                </AccessControl>
                              </td>
                            </AccessControl>
                          </tr>
                        ))
                      ) : (
                        <NoRecords headerLength={7} />
                      )}
                    </>
                  )}
                </tbody>
              </table>
              {totalPages > 1 && (
                <div className="pagination justify-content-center align-items-center">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={(newPage) => handlePageChange(newPage)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row backPadding">
        <div className="col-md-6 align-self-center">
          <Link
            to=""
            className=" back-btn text-decoration-underline"
            onClick={() => navigate(-1)}
          >
            {t("Back")}
          </Link>
        </div>
      </div>
    </AccessControl>
  );
};

export default translate(ManageDomain);
